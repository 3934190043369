import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import AMACalculationRuleStore from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteAMACalculationRule } from './Service';
import { formatDate } from '../../../utils/functions';
import * as api from '../../../stores/api';

const columns = [
  { title: '' },
  { title: 'Calculation Name', sortKey: 'CalculationName' },
  { title: 'Calculation Rules', sortKey: 'CalculationRules' },
  { title: 'Start Effective Date' },
  { title: 'End Effective Date' },
  { title: '' },
];

@observer
class SaleTargetTable extends React.Component {
  openModal = options => {
    return AMACalculationRuleStore.toggleModal(true, options);
  };
  handleDelete = id => {
    AMACalculationRuleStore.setFieldsValue({ loading: true });
    Promise.all([deleteAMACalculationRule(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          AMACalculationRuleStore.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    const arrPercentages = record.Percentages ? record.Percentages.split(';') : [];
    var listPercentages = [];
    var idx = 1;
    arrPercentages.forEach(item => {
      if (item) {
        listPercentages.push({ idx: idx, Percentage: parseInt(item) });
        idx++;
      }
    });
    AMACalculationRuleStore.setPercentages(listPercentages);
    return AMACalculationRuleStore.toggleModal(true, {
      modalType: 'create',
      id,
      record,
    })();
  };

  handleViewAMAHistory = (id, name) => {
    console.log(id);
    return AMACalculationRuleStore.toggleModal(true, {
      modalType: 'history',
      Id: id,
      CalculationName: name,
    })();
  };

  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            <Menu.Item
              // onClick={AMACalculationRuleStore.openModal({
              //   modalType: 'confirm',
              //   message: 'Do you want to delete this item?',
              //   onOk: this.handleDelete(id),
              // })}
              onClick={() => this.handleDelete(id)}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>}
        {api.isAdmin() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record.CalculationName)}>
              {record.CalculationName}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.CalculationName}</Table.Cell>
        )}
        <Table.Cell>{record.CalculationRules}</Table.Cell>
        <Table.Cell>
          {record.StartEffectiveDate
            ? moment(formatDate(record.StartEffectiveDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.EndEffectiveDate
            ? moment(formatDate(record.EndEffectiveDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          <Link to="#" onClick={() => this.handleViewAMAHistory(Id, record.CalculationName)}>
            History
          </Link>
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    AMACalculationRuleStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = AMACalculationRuleStore;
    const colummn_ = api.isAdmin() ? columns : columns.filter(i => i.title);
    const colSpan = colummn_.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={colummn_}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={AMACalculationRuleStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={AMACalculationRuleStore.curPage}
          totalPage={AMACalculationRuleStore.totalPage}
          totalItems={AMACalculationRuleStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default SaleTargetTable;
