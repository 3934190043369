import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import * as api from '../../stores/api';
import store from './store';
import dashboard from '../../stores/dashboard';
import imeStore from '../admin-routes/IMEAssessments/IMEStore';

@observer
class DashboardHeader extends React.Component {
  componentDidMount() {
    try {
      setInterval(async () => {
        store.fetchAdminData();
      }, 300000);
    } catch (e) {}
  }

  openMainList = type => {
    imeStore.resetStore();
    switch (type) {
      case `aCount`:
        dashboard.close(`/view/ime-assessments-2`);
        setTimeout(() => {
          dashboard.open(`/view/ime-assessments-2?fromToRequest=true`);
        });
        break;
      case `vaCount`:
        dashboard.close(`/view/ime-assessments-2`);
        setTimeout(() => {
          dashboard.open(`/view/ime-assessments-2?fromToRequest=true`);
        });
        break;
      case `aCancelCount`:
        dashboard.close(`/view/ime-assessments-2`);
        setTimeout(() => {
          dashboard.open(`/view/ime-assessments-2?caseStatus=Cancelled`);
        });
        break;
      case `sCount`:
        dashboard.close(`/view/supplementary-reports-2`);
        dashboard.open(`/view/supplementary-reports-2?fromToRequest=true`);
        break;
      case `fCount`:
        dashboard.close(`/view/admin-file-reviews`);
        dashboard.open(`/view/admin-file-reviews?fromToRequest=true`);
        break;
      case `cnCount`:
        dashboard.close('/view/clinical-notes-2');
        dashboard.open(`/view/clinical-notes-2?fromToRequest=true`);
        break;
      case 'mnCount':
        dashboard.close('/view/admin-med-neg');
        dashboard.open(`/view/admin-med-neg?fromToRequest=true`);
        break;
    }
  };

  compareDates = (d1, d2) => {
    let date1 = d1.getTime();
    let date2 = d2.getTime();
    console.log(date1 < date2)
    return date1 < date2;
  };

  render() {
    const { bookingCount, statistics, todayCount, tomorrowCount, dataTarget } = store;
    const isAdmin = api.isAdminOrMagStaffOrAccounting();

    return (
      <div className="dashboard-header">
        <div className="dashboard-header-left">
          {!api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && (<React.Fragment>
            <div className="statistics statistics-inline">
              <span className="statistics-title">Today's Appointments:</span>
              <span className="statistics-count">{todayCount}</span>
              <span className="statistics-title">, Tomorrow's Appoinments:</span>
              <span className="statistics-count">{tomorrowCount}</span>
            </div>
          </React.Fragment>)}
          {isAdmin && !api.isSiteOnlyInvoiceCheck() && (
            <React.Fragment>
              <div className="statistics">
                <span className="statistics-title">Daily Assessments Target:</span>
                <span className="statistics-count">
                  {dataTarget.itemList.filter(i => i.Key === 'dailybookingtarget').map(i => i.Target)[0]}
                </span>
              </div>
              <div className="statistics">
                <span className="statistics-title">My Today Bookings:</span>
                <span className="statistics-count">{bookingCount}</span>
              </div>
            </React.Fragment>
          )}
        </div>
        {this.compareDates(new Date(), new Date(2024, 3, 6)) && <div style={{float: 'left', color: 'red', fontSize: '17px',width: '30%'}}>
          <b>IMPORTANT UPDATE – MAG Connect will be migrating to a new platform at <a style={{color: 'red'}} target='blank' href='https://mag.kawaconn.com'>https://mag.kawaconn.com</a> on April 8th 2024. Account information will be sent to you 7 days before the new system start date.</b>
        </div>}
        {!this.compareDates(new Date(), new Date(2024, 3, 6)) && <div>
            MAG Connect site has now moved to <a style={{textDecoration: "none", color: "#1fc0da", fontSize: "18px"}} href="https://mag.kawaconn.com" target="_blank">https://mag.kawaconn.com</a>
        </div>}
        <div className="dashboard-header-right">
          <h4 className="statistics-group-title">
            {isAdmin && !api.isSiteOnlyInvoiceCheck() && "Today's Bookings/Cancellations:"} {moment().format('dddd, DD MMM YYYY')}
          </h4>
          {isAdmin && !api.isSiteOnlyInvoiceCheck() && (
            <React.Fragment>
              <div className="statistics-group">
                <div className="statistics">
                  <span className="statistics-title">IME In-person:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-a-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('aCount')}
                    >
                      {statistics.aCount} | {statistics.aCountRq}
                    </span>
                  </span>
                </div>
                <div className="statistics">
                  <span className="statistics-title">IME Video:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-va-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('vaCount')}
                    >
                      {statistics.vaCount} | {statistics.vaCountRQ}
                    </span>
                  </span>
                </div>
                <div className="statistics">
                  <span className="statistics-title">Supp Reports:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-s-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('sCount')}
                    >
                      {statistics.sCount}
                    </span>
                  </span>
                </div>
                <div className="statistics">
                  <span className="statistics-title">File Reviews:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-f-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('fCount')}
                    >
                      {statistics.fCount}
                    </span>
                  </span>
                </div>
              </div>
              <div className="statistics-group">
                <div className="statistics">
                  <span className="statistics-title">CR Retrievals:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-cn-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('cnCount')}
                    >
                      {statistics.cnCount}
                    </span>
                  </span>
                </div>
                {/* <div className="statistics">
                  <span className="statistics-title">Imaging:</span>
                  <span className="statistics-count">{statistics.mriCount}</span>
                </div>
                <div className="statistics">
                  <span className="statistics-title">FI:</span>
                  <span className="statistics-count">{statistics.fiCount}</span>
                </div> */}
                <div className="statistics">
                  <span className="statistics-title">Med Neg:</span>
                  <span
                    className="statistics-count"
                    key={'link-cn-count'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.openMainList('mnCount')}
                  >
                    {statistics.mnCount}
                  </span>
                </div>
                <div className="statistics">
                  <span className="statistics-title">IME Cancellation:</span>
                  <span className="statistics-count">
                    <span
                      key={'link-ac-count'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openMainList('aCancelCount')}
                    >
                      {statistics.aCancelCount}
                    </span>
                  </span>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default DashboardHeader;
