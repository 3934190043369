import React from 'react';
import { Form, Input, notification, Spin, Select } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import './ModalCreate.scss';
import { action } from 'mobx';
import Modal from '../../shared/Modal';
import M4ModifierExclusionStore from './Store';
import { getM4ModifierExclusionInfo, saveM4ModifierExclusion, searchAMAAnaesthesia } from './Service';
import { formatDate } from '../../../utils/functions';

@observer
class ModalCreate extends React.Component {
  state = {
    loading: true,
    data: null,
  };

  handleSave = () => {
    const { data } = this.state;
    if (!data.ExclusionCode) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 3,
      });
    } else {
      var submitData = data;
      Promise.all([saveM4ModifierExclusion(submitData)])
        .then(([response]) => {
          if (response.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
              duration: 3,
            });
            M4ModifierExclusionStore.refetch({}, true);
            this.props.onCancel();
          }
        })
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
            duration: 3,
          });
        });
    }
  };
  componentDidMount() {
    const { id } = M4ModifierExclusionStore.modalParams;
    if (id) {
      Promise.all([getM4ModifierExclusionInfo(id), searchAMAAnaesthesia('')]).then(([itemInfo, searchAMAResp]) => {
        if (searchAMAResp && searchAMAResp.itemList) {
          M4ModifierExclusionStore.setListAMACode(searchAMAResp.itemList);
        }
        this.setState({
          loading: false,
          data: itemInfo.objItem,
        });
      });
    } else {
      this.setState({
        loading: false,
        data: {
          Id: 0,
          ExclusionCode: '',
        },
      });
    }
  }

  handleChangeAMACode = fieldName =>
    action(event => {
      const { data } = this.state;
      const value = event;
      this.setState({
        data: {
          ...data,
          [fieldName]: value,
        },
      });
    });

  @action handleSearchAMAFeeCode = () =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchAMAAnaesthesia(value).then(
          action(resp => {
            if (resp && resp.itemList) {
              M4ModifierExclusionStore.setListAMACode(resp.itemList);
            }
          }),
        );
      }
    });

  handleFieldChange = fieldName => event => {
    const { data } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      data: {
        ...data,
        [fieldName]: value,
      },
    });
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  render() {
    const { record, id } = M4ModifierExclusionStore.modalParams;
    const { open, lstAMACodes } = M4ModifierExclusionStore;
    const { data, loading } = this.state;

    return (
      <Modal
        width={800}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add M4 Modifier Exclusion' : record.ExclusionCode}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item
              label="Exclusion Code"
              required
              validateStatus={!data.ExclusionCode ? 'error' : null}
              help={!data.ExclusionCode ? 'This field is required' : null}
            >
              <Select
                style={{ width: '100%' }}
                key={'m4-eligible'}
                value={data.ExclusionCode}
                onChange={this.handleChangeAMACode('ExclusionCode')}
                onSearch={this.handleSearchAMAFeeCode()}
                showSearch
              >
                {lstAMACodes &&
                  lstAMACodes.map(({ Id, AMACode }) => (
                    <Select.Option value={AMACode} key={'ama-m4-modifier-exclusion-' + Id}>
                      {AMACode}
                    </Select.Option>
                  ))}
              </Select>
              {/* <Input
                  value={data.ExclusionCode}
                  onChange={this.handleFieldChange('ExclusionCode')}
                /> */}
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ModalCreate;
