import { observer } from 'mobx-react';
import React from 'react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import LMQCriteriasStore from './LMQCriteriasStore';
import * as api from '../../../stores/api';
import router from '../../../stores/router';

const statusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class LMQCriteriasToolbar extends React.Component {
  renderFilterOptions = () => {
    return statusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = statusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    LMQCriteriasStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handSubmit = e => {
    e.preventDefault();
    LMQCriteriasStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    var id = 0;
    if (localStorage.getItem(`LMQCriterias`)) {
      localStorage.removeItem(`LMQCriterias`);
    }
    iframe.forceOpenTab('PostTypesOfClaims', `?id=${id}&view=PostTypesOfClaims`, {
      id,
      view: 'PostTypesOfClaims',
    });
  };

  onOpenCaseCreateNew = () => {
    localStorage.setItem('LMQCriterias', 'Add/Edit LMQ');
    router.history.push({
      pathname: '/view/add-edit-lmq',
      state: {
        type: 'typeOfClaim',
      },
      search: `?id=${0}`,
    });
  };

  render() {
    const { keyword, loading, filter } = LMQCriteriasStore;
    return (
      <React.Fragment>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={LMQCriteriasStore.handleSearchChange} value={keyword} disabled={loading} />
            <TableToolbar.Filter
              disabled={loading}
              value={this.renderFilterText(filter)}
              label={`Status`}
              options={this.renderFilterOptions()}
              onChange={this.handleFilter}
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              className="negative"
              onClick={LMQCriteriasStore.resetStore}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right>
            {api.isAdmin() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.onOpenCaseCreateNew}>
                Add LMQ
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Right>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default LMQCriteriasToolbar;
