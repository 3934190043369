import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class LMQCriteriasStore {
  @observable loading = true;
  @observable data = null;

  @observable keyword = '';
  @observable filter = 'All';
  @observable fromTo = '';

  @observable sortKey = 'Name';
  @observable sortColumn = 'Name';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = true;

    customFetch('/LMQ/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        FilterType: this.filter,
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
      }),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchFiles(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'Name';
    this.sortKey = 'Name';
    this.sortDirection = 'ascending';

    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new LMQCriteriasStore();
