import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Button, Form, Icon, Input, Table, TableBody, Select } from 'semantic-ui-react';
import cx from 'classnames';
import orderBy from 'lodash/orderBy';

import Modal from '../../shared/Modal';
import ServiceFeeStore from './AMAServiceFeeStore';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import { uniqBy } from 'lodash';
// import { Tab } from 'bootstrap';
// import customFetch from '../../../utils/customFetch';

function ModalCheckTreatment({ open, onCancel, modalParams }) {
  const [listTreatment, setListTreatment] = useState([
    {
      idx: 1,
      treatment: '',
    },
  ]);
  const [showTable, setShowTable] = useState(false);

  const [quoteCodeFee, setQuoteCodeFee] = useState(0);

  const onAdd = () => {
    setListTreatment([...listTreatment, { idx: listTreatment.length + 1, treatment: '' }]);
  };

  useEffect(() => {
    ServiceFeeStore.setFieldsValue({
      listTreatment: [],
      searchExcludedText: '',
    });
  }, []);

  const handleCheck = () => {
    const newArray = listTreatment.filter(i => i.treatment);
    setListTreatment(newArray);
    setShowTable(true);
    ServiceFeeStore.fetchCheckTreatmentCaculations(listTreatment);
  };

  const renderHighLightText = (listExcludedAMACode, amaCode) => {
    if (listExcludedAMACode && Array.isArray(listExcludedAMACode)) {
      const dataCheckTreatment = toJS(ServiceFeeStore.listTreatment);
      const listExcludeCode = toJS(ServiceFeeStore.listTreatment)
        .map(i => i.ListExcludedAMACode)
        .filter(i => !!i.length);
      const excludeCode = dataCheckTreatment
        .map(i => i.AMACode)
        .map(i => listExcludedAMACode.find(code => code.ExcludedAMACode === i))
        .filter(i => i);
      const excludeCode_ = uniqBy([].concat.apply([], listExcludeCode), 'Id').filter(
        i => i.ExcludedAMACode === amaCode,
      );
      return !!excludeCode.length || !!excludeCode_.length;
    }
    return false;
  };

  const renderTableBody = data => {
    return data.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell
          style={{
            color: `${renderHighLightText(record.ListExcludedAMACode, record.AMACode) && 'red'}`,
            width: '200px',
          }}
        >
          {record.AMACode}
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'right', width: '150px' }}>$ {record.Fee}</Table.Cell>
        <Table.Cell>
          {record.ListExcludedAMACode ? record.ListExcludedAMACode.map(i => i.ExcludedAMACode).join(', ') : ''}
        </Table.Cell>
      </Table.Row>
    ));
  };
  const renderEmptyMessage = colSpan => {
    return <TableEmptyMessage colSpan={colSpan} />;
  };

  const handleChangeSearchDropdown = (_, { name, searchQuery }) => {
    ServiceFeeStore.refetch({
      searchExcludedText: searchQuery,
      numberPageSearch: 30,
      loadingSearch: true,
      isSelectionList: true,
    });
    const newArray = listTreatment.map(i => {
      if (i.idx === parseInt(name)) {
        return { idx: i.idx, treatment: searchQuery };
      } else {
        return { ...i };
      }
    });
    return setListTreatment(newArray);
  };

  const handleChangeDropdown = (_, { name, value }) => {
    const newArray = listTreatment.map(i => {
      if (i.idx === parseInt(name)) {
        return { idx: i.idx, treatment: value };
      } else {
        return { ...i };
      }
    });
    return setListTreatment(newArray);
  };

  const onfocus = () => {
    const element = document.getElementById('TotalQuoteFee');
    return element.focus();
  };

  const renderTableTreatment = () => {
    const dataCheckTreatment = toJS(ServiceFeeStore.listTreatment);
    const loading = toJS(ServiceFeeStore.loadingCheckAmaTreatment);
    const columns = [{ title: 'AMA Code' }, { title: 'Fee' }, { title: 'Excluded Service Codes' }];
    if (dataCheckTreatment.length !== 0) {
      return (
        <Table sortable striped celled className={cx({ disabled: loading })}>
          <TableHeader columns={columns} />
          <TableBody>{renderTableBody(dataCheckTreatment)}</TableBody>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={4}>
                <Form>
                  <Form.Group inline>
                    <Form.Field style={{ width: '190px' }}>
                      <label
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          marginRight: '10px',
                        }}
                      >
                        Total Scheduled Fee:
                      </label>
                    </Form.Field>
                    <Form.Field style={{ width: '150px' }}>
                      <Input
                        icon="dollar"
                        iconPosition="left"
                        style={{ width: '150px' }}
                        value={dataCheckTreatment.map(i => i.Fee).reduce((a, b) => a + b)}
                        onFocus={onfocus}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field style={{ width: '190px' }}>
                      <label
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          marginRight: '10px',
                        }}
                      >
                        Total Quote Fee:
                      </label>
                    </Form.Field>
                    <Form.Field>
                      <Input
                        icon="dollar"
                        iconPosition="left"
                        style={{ width: '150px' }}
                        value={quoteCodeFee}
                        id="TotalQuoteFee"
                        onChange={(_, { value }) => setQuoteCodeFee(value.replace(/\D/g, '').replace(/[^\d]/g, ''))}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <Form.Field style={{ width: '190px' }}>
                      <label
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          marginRight: '10px',
                        }}
                      >
                        Difference:
                      </label>
                    </Form.Field>
                    <Form.Field>
                      <Input
                        icon="dollar"
                        iconPosition="left"
                        style={{ width: '150px' }}
                        onFocus={onfocus}
                        value={
                          !quoteCodeFee || parseInt(quoteCodeFee) === 0
                            ? 0
                            : (
                                dataCheckTreatment.map(i => i.Fee).reduce((a, b) => a + b) - parseInt(quoteCodeFee)
                              ).toFixed(2)
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      );
    } else {
      return (
        <Table sortable striped celled className={cx({ disabled: loading })}>
          <TableHeader columns={columns} />
          <TableBody>{renderEmptyMessage(columns.length)}</TableBody>
        </Table>
      );
    }
  };

  return (
    <Modal
      visible={open}
      width={900}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={handleCheck}>
            <Icon name="search" /> Check
          </Button>
          <Button color="red" onClick={onCancel}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
      title="Calculate Treatment Fees"
    >
      <Form>
        {listTreatment.map(item => {
          return (
            <Form.Field key={item.idx}>
              <label>Service Code {item.idx}</label>
              <Select
                key={item.idx}
                label="Excluded Service Codes"
                placeholder="Excluded Service Codes"
                name={item.idx}
                search
                value={item.treatment || ''}
                onChange={handleChangeDropdown}
                disabled={ServiceFeeStore.loadingSearch}
                selectOnNavigation={false}
                options={orderBy(ServiceFeeStore.amaSelectionList || []).map(r => ({
                  key: r.value,
                  text: `${r.text}`,
                  value: r.text,
                }))}
                onSearchChange={handleChangeSearchDropdown}
                searchQuery={item.treatment}
              />
            </Form.Field>
          );
        })}
        <Button color="green" onClick={onAdd}>
          <Icon name="plus" /> Add
        </Button>
        <Button className="blue" onClick={() => setShowTable(!showTable)}>
          <Icon name={`${showTable ? 'minus' : 'plus'}`} />
          {`${showTable ? 'Hide Check Result' : 'Show Check Result'}`}
        </Button>
      </Form>
      {showTable && renderTableTreatment()}
    </Modal>
  );
}

export default observer(ModalCheckTreatment);
