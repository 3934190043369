import { Spin, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import React from 'react';
import { Button, Divider, Header, Table, Checkbox } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import AssignDoctorStore from './AssignDoctorStore';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import customFetch from '../../../utils/customFetch';
import ModalAssignDoctorToolbar from './ModalAssignDoctorToolbar';
import ModalConfirm from './ModalConfirm';

@observer
class ModalAssignDoctors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],
      cId: props.modalParams.cId,
      openModalConfirm: false,
      message: `All unsaved changes will be discarded. Do you want to continue?`,
      selectAll: false,
    };
  }

  componentDidMount() {
    if (!AssignDoctorStore.loading && AssignDoctorStore.doctors) {
      this.setState({
        doctors: AssignDoctorStore.doctors,
      });
    }
  }

  handlePageClick = page => {
    AssignDoctorStore.refetch({ curPage: page });
  };

  onChecked = id => () => {
    if (this.state.doctors.indexOf(id) !== -1) {
      this.setState({
        doctors: this.state.doctors.filter(i => i !== id),
      });
    } else {
      this.setState(state => {
        const doctors = [...state.doctors, id];
        return {
          doctors,
        };
      });
    }
  };

  renderCheckBoxSelectedValue = () => {
    let doctors = this.state.doctors.map(i => i);
    let doctors_ = AssignDoctorStore.data ? AssignDoctorStore.data.itemList.map(i => i.Id) : [];
    let selectAll = false;
    doctors_.forEach(i => {
      if (doctors.indexOf(i) >= 0) {
        selectAll = true;
      }
      if (doctors.indexOf(i) === -1) {
        selectAll = false;
      }
    });
    return selectAll;
  };

  handleSelectedAll = () => {
    let doctors = this.state.doctors.map(i => i);
    let doctors_ = AssignDoctorStore.data.itemList.map(i => i.Id);
    let selectedAll = this.renderCheckBoxSelectedValue();
    if (!selectedAll) {
      doctors_.forEach(i => {
        if (this.state.doctors.indexOf(i) === -1) {
          this.setState(state => {
            const doctors = [...state.doctors, i];
            return {
              doctors,
            };
          });
        }
      });
    } else {
      doctors_.forEach(i => {
        if (this.state.doctors.indexOf(i) >= 0) {
          doctors = doctors.filter(doctor => doctor !== i);
        }
      });
      this.setState(state => (state.doctors = doctors));
    }
  };

  renderCheckBoxValue = id => {
    if (this.state.doctors.indexOf(id) !== -1) return true;
    return false;
  };

  renderTable = () =>
    AssignDoctorStore.data.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <Checkbox checked={this.renderCheckBoxValue(record.Id)} onChange={this.onChecked(record.Id)} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{`${record.Title} ${record.FullName || ``}`}</Table.Cell>
        <Table.Cell>{record.Specialty}</Table.Cell>
        <Table.Cell>{record.ClientType}</Table.Cell>
      </Table.Row>
    ));

  handleCancel = () => {
    this.setState(state => {
      state.openModalConfirm = false;
      this.props.onCancel();
      AssignDoctorStore.setFieldsValue({ doctors: null, selectedAll: false });
      return state;
    });
  };

  fetchDoctorByList = () => {
    customFetch('/MedicalDoctor/GetDoctorByIdList', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(this.state.doctors.map(i => i)),
    }).then(
      action(data =>
        AssignDoctorStore.setFieldsValue({
          data,
          loading: false,
          totalPage: data.sumPage || AssignDoctorStore.totalPage,
          curPage: 1,
        }),
      ),
    );
  };

  handleUpdateCompanyDoctor = () => async () => {
    AssignDoctorStore.setFieldsValue({ loading: true });
    const { cId, doctors } = this.state;
    try {
      await customFetch('/Company/UpdateCompanyDoctor', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ cId, doctors }),
      });
      if (doctors.length === 0) {
        await AssignDoctorStore.refetch({}, true);
      } else {
        this.fetchDoctorByList();
      }
    } finally {
      if (doctors.length === 0) {
        AssignDoctorStore.setFieldsValue({
          doctors: doctors,
          filter: `All`,
        });
        await AssignDoctorStore.refetch({}, true);
      } else {
        AssignDoctorStore.setFieldsValue({
          doctors: doctors,
          filter: `selected`,
        });
        this.fetchDoctorByList();
      }
      AssignDoctorStore.setFieldsValue({
        loading: false,
      });
      notification.destroy();
      notification.success({
        message: 'Data has been saved successfully.',
        placement: `bottomRight`,
        duration: 3,
      });
    }
  };

  onOpenConfirm = () => {
    this.setState({ openModalConfirm: true });
  };

  onCancelConfirm = () => {
    this.setState({ openModalConfirm: false });
  };

  renderCheckBoxSelectAll = () => {
    return <Checkbox onChange={this.handleSelectedAll} checked={this.renderCheckBoxSelectedValue()} />;
  };

  render() {
    const { loading, data, curPage, totalPage } = AssignDoctorStore;
    const columns = [
      {
        title: this.renderCheckBoxSelectAll(),
      },
      { title: 'Doctor Name' },
      { title: 'Specialty' },
      { title: 'Doctor Type' },
    ];
    const colSpan = columns.length;
    const { open } = this.props;
    const { record } = this.props.modalParams;
    const { openModalConfirm, message } = this.state;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={this.onOpenConfirm}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleUpdateCompanyDoctor()}>
              Save
            </Button>
            <Button className="negative" onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-LoginHistory">
          <Header size="small">{`Doctor assigned to ${record.Name}`}</Header>
          <Divider />
          <ModalAssignDoctorToolbar />
          <ModalConfirm
            open={openModalConfirm}
            message={message}
            onCancel={this.onCancelConfirm}
            onOk={this.handleCancel}
          />
          {loading && !data ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled className={cx({ disabled: loading })}>
              <TableHeader columns={columns} />
              <Table.Body>
                {data.itemList.length ? this.renderTable() : <TableEmptyMessage colSpan={colSpan} />}
              </Table.Body>
              <TableFooterPagination
                colSpan={colSpan}
                currentPage={curPage}
                totalPage={totalPage}
                onPageClick={this.handlePageClick}
              />
            </Table>
          )}
        </div>
      </Modal>
    );
  }
}

export default ModalAssignDoctors;
