import moment from 'moment';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import DoctorReportsStore from './DoctorReportsStore';
import { TableHeader, TableEmptyMessage } from '../../shared/table';

const columns = [
  { title: 'Case No' },
  { title: 'Appointment Date', sortKey: 'assessmentDate' },
  { title: 'Specialist' },
  { title: 'Claimant' },
  { title: 'Client', sortKey: 'clientId' },
  { title: 'Case Manager' },
  { title: 'Claim Type' },
  { title: 'Case Status' },
  { title: 'Status Date' },
];
window.functionGetElementTableForPrint = () => {
  return document.getElementById(`tablePrint`).innerHTML;
};
@observer
class DoctorReportsTable extends React.Component {
  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className="Cell-nowrap">{record.CaseNo}</Table.Cell>
        <Table.Cell>
          {record.DateOfAppointment
            ? moment(formatDate(record.DateOfAppointment, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.Doctor}</Table.Cell>
        <Table.Cell>{record.Claimant}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.ClaimType}</Table.Cell>
        <Table.Cell>{record.CaseStatus}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { dataDoctorReports, loadingDoctorReports, keyword, sumItem, checkFrom, checkTo } = DoctorReportsStore;
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <div id="tablePrint">
          <span style={{ marginTop: `20px` }}>
            <h3>
              {keyword && !checkFrom && !checkTo && (
                <div>
                  Assessments performed by {keyword} {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
              {keyword && checkFrom && !checkTo && (
                <div>
                  Assessments performed by {keyword} from {checkFrom.replace('17:00:00 GMT', '')}{' '}
                  {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
              {keyword && !checkFrom && checkTo && (
                <div>
                  Assessments performed by {keyword} to {checkTo.replace('17:00:00 GMT', '')}{' '}
                  {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
              {keyword && checkFrom && checkTo && (
                <div>
                  Assessments performed by {keyword} from {checkFrom.replace('17:00:00 GMT', '')} to{' '}
                  {checkTo.replace('17:00:00 GMT', '')} {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
              {!keyword && checkFrom && checkTo && (
                <div>
                  Assessments performed by {keyword} from {checkFrom.replace('17:00:00 GMT', '')} to{' '}
                  {checkTo.replace('17:00:00 GMT', '')} {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
              {!keyword && checkFrom && !checkTo && (
                <div>
                  Assessments performed from {checkFrom.replace('17:00:00 GMT', '')}{' '}
                  {sumItem ? `(` + sumItem + `)` : ``}
                </div>
              )}
            </h3>
          </span>

          <Table sortable striped celled className={cx({ disabled: loadingDoctorReports })}>
            <TableHeader
              columns={columns}
              sortColumn={DoctorReportsStore.sortColumn}
              sortDirection={DoctorReportsStore.sortDirection}
              onSort={DoctorReportsStore.handleSort}
            />
            <Table.Body>{this.renderTableBody(dataDoctorReports, colSpan)}</Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default DoctorReportsTable;
