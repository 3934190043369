import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class Store {
  @observable loading = true;
  @observable data = null;
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';
  @observable totalItems = 0;
  @observable status = 'All';

  @observable loadingDoctor = true;
  @observable doctors = [];
  @observable specialistKeyword = '';
  @observable specialistNumItemPerPage = 500;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};
  @observable type = 'doctor';

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchData = (setTotalPage = false) => {
    this.loading = true;
    if (this.type === 'doctor') {
      customFetch(`/ExclusionDoctor/GetAll?keyword=${this.keyword}`, {
        method: 'GET',
      }).then(
        action(resp => {
          this.data = resp.itemList;
          this.totalPage = resp.sumPage;
          this.totalItems = resp.sumItem;
          this.loading = false;
        }),
      );
    } else {
      customFetch(`/ExclusionDomain/GetAll?keyword=${this.keyword}&filter=${this.status}`, {
        method: 'GET',
      }).then(
        action(data => {
          this.data = data.itemList;
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
          this.loading = false;
        }),
      );
    }
  };

  @action fetchDoctors = () => {
    this.loadingDoctor = true;
    customFetch('/MedicalDoctor/GetAllWithoutNCI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        RecruitedOrContractedOnly: true,
        Showing: 'active',
        curPage: 1,
        keyword: '',
        numItemPerPage: 300,
      }),
    }).then(
      action(resp => {
        if (resp.status === 'success') {
          this.doctors = resp.itemList;
          this.loadingDoctor = false;
        }
      }),
    );
  };

  @action fetchSearchSepecialistStatus = (_, { searchQuery }) => {
    if (searchQuery.length < 3) {
      return;
    }
    this.doctors = [];
    customFetch('/MedicalDoctor/GetAllWithoutNCI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        RecruitedOrContractedOnly: true,
        Showing: 'active',
        curPage: 1,
        keyword: searchQuery,
        numItemPerPage: 300,
      }),
    }).then(
      action(data => {
        this.doctors = data.itemList;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'ascending';
    this.curPage = 1;
    this.totalPage = 0;
    this.type = 'doctor';
  };
}

export default new Store();
