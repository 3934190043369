import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class Store {
  @observable loading = true;
  @observable data = null;

  @observable filter = 'active';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  fetchByKeword = () => {
    return customFetch('/GreetingTitle/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        status: this.filter,
        keyword: this.keyword,
        curPage: this.curPage,
        numItemPerPage: this.pageSize,
        sortBy: this.sortKey,
        sortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      }),
    });
  };

  @action fetchData = (setTotalPage = false) => {
    this.loading = true;

    this.fetchByKeword().then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'active';

    this.curPage = 1;
    this.totalPage = 0;
    this.refetch({}, true, 'byKeyword');
  };
}

export default new Store();
