import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStore from './Store';
import ModalActionNotes from './ActionRequiredForm';
import ModalRecentChange from './ModalRecentChange';
import ModalActionProgress from './ModalActionProgress';
import ModalPrintMail from './ModalMail';
import ModalDisbursementFunding from '@components/shared/ModalDisbursementFunding';
import ModalSMSHistory from './ModalSMSHistory';
import ModalCaseProgress from './ModalCaseProgress';
import ModalUpload from './UploadComponent';
import ModalConfidentialInsights from './ModalConfidentialInsights';
import ModalWarning from './ModalWarning';
import ModalCompanyRecentChange from './ModalCompanyRecentChange';
import FieldChangeHistory from './FieldChangeHistory';

function AddEditModal() {
  const { modalType, onOk, hideWarningIcon = false, onCancel } = AddEditStore.modalParams;
  const footerCloseButton = !onOk;
  if (modalType === 'confirm') {
    return (
      <ModalConfirm
        {...AddEditStore}
        functionCallbackCancel={onCancel}
        onCancel={AddEditStore.toggleModal(false)}
        footerCloseButton={footerCloseButton}
        hideWarningIcon={hideWarningIcon}
      />
    );
  } else if (modalType === 'notes') {
    return <ModalActionNotes />;
  } else if (modalType === 'warning') {
    return <ModalWarning />;
  } else if (modalType === 'recentChange') {
    return <ModalRecentChange {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'recentCompanyChange') {
    return <ModalCompanyRecentChange {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'actionProgress') {
    return <ModalActionProgress {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'printMail') {
    return <ModalPrintMail {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'disbursement') {
    return (
      <ModalDisbursementFunding
        {...AddEditStore.modalParams}
        open={AddEditStore.open}
        onCancel={AddEditStore.toggleModal(false)}
      />
    );
  } else if (modalType === 'fieldChange') {
    return <FieldChangeHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'smsHistory') {
    return <ModalSMSHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'caseProgress') {
    return <ModalCaseProgress {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'upload') {
    return <ModalUpload {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  } else if (modalType === 'confidential') {
    return <ModalConfidentialInsights />;
  } else return <></>;
}

export default AddEditModal;
