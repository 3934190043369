import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import MarketingCampaignModal from './Modal';
import MarketingCampaignStore from './store';
import MarketingCampaignTable from './Table';
import MarketingCampaignToolbar from './Toolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class MarketingCampaign extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/marketing-campaign', true);
      return;
    }

    MarketingCampaignStore.fetchCampaign(true);
    ui.on('open(/view/marketing-campaign)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    MarketingCampaignStore.fetchCampaign(true);
  }, 500);
  render() {
    const { loading, data, open } = MarketingCampaignStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <MarketingCampaignToolbar />
            <MarketingCampaignTable />
            {open && <MarketingCampaignModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default MarketingCampaign;
