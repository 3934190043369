import React from 'react';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import { Button, Divider, Form, Segment, Dimmer, Loader, Tab } from 'semantic-ui-react';
import { notification } from 'antd';
import * as api from '@stores/api';
import Modal from '../../shared/Modal/';
import MessageTemplateStore from './MessageTemplateStore';
import customFetch from '../../../utils/customFetch';
import CKEditor from './CKEditor';

const statusLabels = [
  { text: `Active (in DB)`, value: 0 },
  { text: `Inactive (in DB)`, value: 1 },
  { text: `Active (not in DB)`, value: 2 },
  { text: `Inactive (not in DB)`, value: 3 },
];

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this.props.modalParams.record.Subject,
      dataTemplate: {
        ...this.props.modalParams.record,
        Destination: this.props.modalParams.record.Destination || '',
        Logic: this.props.modalParams.record.Logic || '',
      },
      errorDataTemplate: {
        subject: '',
        TemplateKey: '',
      },
    };
  }
  renderModule = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataModuleType.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderCaseStage = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataCaseStage.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: -1, Label: 'All' });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderCaseStageText = value => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataCaseStage.itemList);

    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: -1, Label: 'All' });
    return array.filter(i => i.Id === value).map(opt => opt.Label)[0];
  };

  onValidSubmit = () => {
    const data = this.state.dataTemplate;
    for (let i in data) {
      if (i === 'TemplateKey') {
        if (!data[i]) {
          this.setState(state => (state.errorDataTemplate[i] = 'This field is required'));
        } else {
          this.setState(state => (state.errorDataTemplate[i] = ''));
        }
      }
    }
    if (!this.state.subject) {
      this.setState(state => (state.errorDataTemplate.subject = 'This field is required'));
    } else {
      this.setState(state => (state.errorDataTemplate.subject = ''));
    }
  };
  onValidForm = (name, value) => {
    if (name === 'TemplateKey') {
      if (!value) {
        this.setState(state => (state.errorDataTemplate[name] = 'This field is required'));
      } else {
        this.setState(state => (state.errorDataTemplate[name] = ''));
      }
    }
  };

  renderStatus = () => {
    return statusLabels.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };
  renderReceiverAlias = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataReceiver.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderTextModule = value => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataModuleType.itemList);
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === value).map(i => i.Label)[0];
  };
  renderTextStatus = value => {
    let label = statusLabels.filter(i => i.value === value).map(i => i.text);
    return label[0];
  };
  renderTextReceiverAlias = value => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataReceiver.itemList);
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === value).map(i => i.Label)[0];
  };

  onOpenConfirm = () => {
    this.props.onCancel();
    MessageTemplateStore.setFieldsValue({ loading: false, template: '' });
  };

  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { template } = MessageTemplateStore;
    const { dataTemplate } = this.state;
    const body = {
      ...dataTemplate,
      Body: template,
      Subject: this.state.subject,
    };
    this.onValidSubmit();
    if (!body.Subject || !body.TemplateKey) {
      notification.destroy();
      notification.error({
        message: 'Invalid',
        description: 'Your provided data is invalid',
        placement: 'topRight',
      });
    } else {
      MessageTemplateStore.setFieldsValue({ loading: true });
      try {
        await customFetch('/MailTemplate/SaveData', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            Id: id,
            ...body,
          }),
        }).then(
          action(data => {
            if (data.status === 'success') {
              MessageTemplateStore.refetch({}, true);
              this.props.onCancel();
            } else {
              if (data.errorList.TemplateKey === 'exists') {
                notification.error({
                  message: 'TemplateKey existed',
                  description: 'Template key is duplicate with an existing template. Please change it and save again',
                  placement: 'topRight',
                });
              } else {
                notification.error({
                  message: 'Error',
                  description: 'Your provided data is invalid.',
                  placement: 'topRight',
                });
              }
            }
          }),
        );
      } finally {
        MessageTemplateStore.setFieldsValue({ loading: false });
        //MessageTemplateStore.setFieldsValue({ loading: false, template: '' });
        //this.props.onCancel();
      }
    }
  };
  handleChange = (_, { value }) => {
    if (value === '') {
      this.setState(state => (state.errorDataTemplate.subject = 'This field is required'));
    } else {
      this.setState(state => (state.errorDataTemplate.subject = ''));
    }
    this.setState({ subject: value });
  };

  handleChangeDropDown = (_, { value, name }) => {
    if (name !== 'caseStage') {
      this.setState(action(state => (state.dataTemplate[name] = value)));
    }
  };

  handeChangeInput = (_, { name, value }) => {
    this.onValidForm(name, value);
    this.setState(action(state => (state.dataTemplate[name] = value)));
  };
  handleChecked = () => {
    const { dataTemplate } = this.state;
    this.setState(action(state => (state.dataTemplate.AppendSignature = !dataTemplate.AppendSignature)));
  };

  render() {
    const { open } = this.props;
    const { record, id, type } = this.props.modalParams;
    const { template, loading } = MessageTemplateStore;
    const { subject, dataTemplate, errorDataTemplate } = this.state;
    const randerTabPane = [
      {
        menuItem: 'Email Information',
        render: () => (
          <Tab.Pane>
            <div>
              <Form size="small">
                <Form.Group widths="equal">
                  <Form.Input
                    value={subject}
                    label="Subject"
                    required
                    error={
                      errorDataTemplate.subject
                        ? {
                            content: errorDataTemplate.subject,
                          }
                        : null
                    }
                    onChange={this.handleChange}
                    placeholder="Please enter subject"
                    disabled={loading}
                  />
                </Form.Group>
                <Divider />
                <Form.Checkbox
                  label="Append Signature"
                  checked={dataTemplate.AppendSignature}
                  onClick={this.handleChecked}
                  disabled={loading}
                />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Template Key"
                    required
                    error={
                      errorDataTemplate.TemplateKey
                        ? {
                            content: errorDataTemplate.TemplateKey,
                          }
                        : null
                    }
                    onChange={this.handeChangeInput}
                    placeholder="Template Key"
                    disabled={id !== 0 ? true : loading}
                    name="TemplateKey"
                    value={dataTemplate.TemplateKey}
                    style={{ width: '100%' }}
                  />
                  <Form.Select
                    style={{ width: '100%' }}
                    label="Status"
                    name="Status"
                    disabled={loading}
                    options={this.renderStatus()}
                    text={this.renderTextStatus(dataTemplate.Status)}
                    onChange={this.handleChangeDropDown}
                  />
                  <Form.Select
                    style={{ width: '100%' }}
                    label="Mail Module"
                    disabled={loading}
                    name="ModuleType"
                    options={this.renderModule()}
                    text={this.renderTextModule(dataTemplate.ModuleType)}
                    onChange={this.handleChangeDropDown}
                  />
                  <Form.Select
                    style={{ width: '100%' }}
                    label="Case Stage"
                    disabled={loading}
                    name="CaseStage"
                    options={this.renderCaseStage()}
                    text={this.renderCaseStageText(dataTemplate.CaseStage)}
                    onChange={this.handleChangeDropDown}
                  />
                </Form.Group>
                <Form.Group />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    onChange={this.handeChangeInput}
                    label="Sender"
                    placeholder="Sender"
                    name="Sender"
                    disabled={loading}
                    value={dataTemplate.Sender}
                  />
                  <Form.Input
                    fluid
                    name="Bcc"
                    disabled={loading}
                    label="Bcc"
                    placeholder="Bcc"
                    onChange={this.handeChangeInput}
                    value={dataTemplate.Bcc}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Mail Receiver"
                    disabled={loading}
                    placeholder="Destination"
                    onChange={this.handeChangeInput}
                    name="Destination"
                    value={dataTemplate.Destination}
                  />
                  <Form.Select
                    className="Select-module"
                    label="Receiver Types"
                    name="ReceiverAlias"
                    disabled={loading}
                    options={this.renderReceiverAlias()}
                    text={this.renderTextReceiverAlias(dataTemplate.ReceiverAlias)}
                    onChange={this.handleChangeDropDown}
                  />
                </Form.Group>
                <Form.TextArea
                  label="Logic"
                  placeholder="Logic"
                  name="Logic"
                  disabled={loading}
                  value={dataTemplate.Logic}
                  onChange={this.handeChangeInput}
                  rows={8}
                />
              </Form>
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Email Content',
        render: () => (
          <Tab.Pane>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <CKEditor
                html={template}
                onChangeValue={content => MessageTemplateStore.setFieldsValue({ template: content })}
              />
              <div
                style={{
                  marginTop: '20px',
                }}
              >
                <Divider />
                <div dangerouslySetInnerHTML={{ __html: template }} />
              </div>
            </Segment>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Modal
        visible={open}
        width={1400}
        bodyStyle={{ height: '1300px' }}
        onCancel={this.onOpenConfirm}
        title={`${
          type === 'duplicate' ? `Duplicate Template` : `${id === 0 ? `New Template` : `${record.TemplateKey}`}`
        }`}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button className="blue" disabled={loading} onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" disabled={loading} onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <Tab panes={randerTabPane} menu={{ pointing: true }} />
      </Modal>
    );
  }
}
export default ModalEditTemplate;
