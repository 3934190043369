import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import medNegStore from './MedNegStore';
import ModalActionNotes from './ModalActionNotes';
import ModalCaseProgress from './ModalCaseProgress';

function MedNegModal() {
  const { modalType } = medNegStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...medNegStore} onCancel={medNegStore.toggleModal(false)} />;
  } else if (modalType === 'notes') {
    return <ModalActionNotes />;
  } else {
    return <ModalCaseProgress />;
  }
}

export default MedNegModal;
