import { action, observable } from 'mobx';
import Validator from 'validatorjs';
import moment from 'moment';
import myDetailsStore from '../../admin-routes/MyDetail/store';
import { getStaffInfo, saveInfo, getActionRequired } from './service';

const INIT_STAFF = {
  Email: '',
  FirstName: '',
  Id: '',
  LastName: '',
  Roles: ['magstaff'],
  UserName: '',
};

const VALIDATE_RULES = {
  FirstName: 'required',
  LastName: 'required',
  Email: ['email', 'required'],
  UserName: 'required',
  Roles: 'required',
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
};

class AddEdditStaffStore {
  @observable loading = true;
  @observable StaffInfo = INIT_STAFF;

  @observable StaffInfo_ = INIT_STAFF;
  @observable isChanged = {};

  //Save
  @observable loadingSave = false;

  //Check duplicate
  @observable loadingCheckDuplicate = false;

  //Error
  @observable errors = {};

  //Modal
  @observable open = false;
  @observable modalParams = {};
  @observable actionRequiredList = [];
  @observable openModalAction = false;

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action toggleModalAction = isOpen => {
    this.openModalAction = isOpen;
  };
  //

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleValidate = (field, value) => {
    if (field === 'email') {
      const emailRequired = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][1] }, ERROR_MESSAGES);
      const emailValidation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][0] }, ERROR_MESSAGES);
      if (emailRequired.passes()) {
        delete this.errors[field];
        if (emailValidation.passes()) {
          delete this.errors[field];
        } else {
          this.errors[field] = emailValidation.errors.first(field);
        }
      } else {
        this.errors[field] = emailRequired.errors.first(field);
      }
    } else {
      const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);
      if (validation.passes()) {
        delete this.errors[field];
      } else {
        this.errors[field] = validation.errors.first(field);
      }
    }
  };

  @action fetchStaffData = id => {
    Promise.all([getStaffInfo(id), getActionRequired(id)]).then(
      action(([info, actionRqResp]) => {
        const user = info.User;
        const staffInfo = {
          Email: user.Email,
          FirstName: user.FirstName,
          Id: user.Id,
          LastName: user.LastName,
          Roles: user.Roles,
          UserName: user.UserName,
          Enabled: user.Enabled,
          InActiveDate: user.InActiveDate,
          DisplayId: user.DisplayId,
        };
        this.StaffInfo = staffInfo;
        this.actionRequiredList = actionRqResp.itemList;
        this.loading = false;
        this.StaffInfo_ = staffInfo;
      }),
    );
  };

  @action initStaffData = () => {
    this.loading = false;
    this.StaffInfo = INIT_STAFF;
    this.StaffInfo_ = INIT_STAFF;
  };

  handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;

      this.StaffInfo[fieldName] = value;

      if (fieldName === 'Roles') {
        const role = this.StaffInfo[fieldName].join();
        const role_ = this.StaffInfo_[fieldName].join();
        if (role !== role_) {
          this.isChanged[fieldName] = true;
        } else {
          delete this.isChanged[fieldName];
        }
      } else {
        if (this.StaffInfo[fieldName] !== this.StaffInfo_[fieldName]) {
          this.isChanged[fieldName] = true;
        } else {
          delete this.isChanged[fieldName];
        }
      }

      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.StaffInfo[fieldName]);
      }
    });

  @action resetStore = () => {};

  @action handleSaveStaff = () => {
    this.loadingSave = true;

    return saveInfo(this.StaffInfo);
  };

  refetchActionRequired = () => {
    myDetailsStore.countActionRequiredIncomplete();
    getActionRequired(this.StaffInfo.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };
}

export default new AddEdditStaffStore();
