import { action, observable } from 'mobx';
import customFetch from '../../../utils/customFetch';

class StoreCR {
  @observable loading = true;
  @observable data = null;

  @observable keyword = '';

  @observable sortColumn = 'Due Date';
  @observable sortKey = 'DueDate';
  @observable sortDirection = 'descending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable etotalItems = 0;

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action onSearchChange = keyword => {
    this.keyword = keyword;
    return this.fetchFiles();
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = true;

    return customFetch('/ClinicalNote/Document_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: 20,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      }),
    }).then(
      action(res => {
        this.data = res.itemList;
        this.totalItems = res.sumItem;
        this.totalPage = res.sumPage;
        this.etotalItems = res.eSumItem;
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    return this.fetchFiles(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Due Date';
    this.sortKey = 'DueDate';
    this.sortDirection = 'descending';
    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new StoreCR();
