import React from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react';

import AddEditStaffStore from './Store';
import { toJS } from 'mobx';
import { renderDate } from '../Shared';

const roles = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'magstaff', text: 'Staff', value: 'magstaff' },
  { key: 'casemng', text: 'Case Manager', value: 'casemng' },
  { key: 'doctor', text: 'Specialist', value: 'doctor' },
  { key: 'accounting', text: 'Accounting', value: 'accounting' },
  { key: 'typist', text: 'Typist', value: 'typist' },
];

@observer
class GeneralInformation extends React.Component {
  render() {
    const { StaffInfo } = AddEditStaffStore;
    const rolesOption = StaffInfo.Id ? roles : [{ key: 'magstaff', text: 'Staff', value: 'magstaff' }];

    return (
      <Form name="general-information">
        <Form.Item label="Account ID">
          <Input size="large" value={StaffInfo.DisplayId || StaffInfo.Id} disabled />
        </Form.Item>
        <Form.Item
          required
          label="Username"
          validateStatus={AddEditStaffStore.errors['UserName'] ? 'error' : null}
          help={AddEditStaffStore.errors['UserName']}
        >
          <Input size="large" value={StaffInfo.UserName} onChange={AddEditStaffStore.handleFieldChange('UserName')} />
        </Form.Item>
        <Form.Item
          required
          label="First Name"
          validateStatus={AddEditStaffStore.errors['FirstName'] ? 'error' : null}
          help={AddEditStaffStore.errors['FirstName']}
        >
          <Input size="large" value={StaffInfo.FirstName} onChange={AddEditStaffStore.handleFieldChange('FirstName')} />
        </Form.Item>
        <Form.Item
          label="Surname"
          validateStatus={AddEditStaffStore.errors['LastName'] ? 'error' : null}
          help={AddEditStaffStore.errors['LastName']}
          required
        >
          <Input size="large" value={StaffInfo.LastName} onChange={AddEditStaffStore.handleFieldChange('LastName')} />
        </Form.Item>
        <Form.Item
          label="Email"
          validateStatus={AddEditStaffStore.errors['Email'] ? 'error' : null}
          required
          help={AddEditStaffStore.errors['Email']}
        >
          <Input size="large" value={StaffInfo.Email} onChange={AddEditStaffStore.handleFieldChange('Email')} />
        </Form.Item>
        <Form.Item
          label="Role"
          validateStatus={AddEditStaffStore.errors['Roles'] ? 'error' : null}
          help={AddEditStaffStore.errors['Roles']}
          required
          style={{ marginBottom: 0 }}
        >
          <Select
            mode="multiple"
            showArrow
            allowClear
            value={toJS(StaffInfo.Roles)}
            onChange={AddEditStaffStore.handleFieldChange('Roles')}
          >
            {rolesOption.map(i => (
              <Select.Option key={i.value} value={i.value}>
                {i.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!StaffInfo.Enabled && (
          <Form.Item>
            <Checkbox checked={!StaffInfo.Enabled}>
              Inactive
              {!StaffInfo.Enabled && StaffInfo.InActiveDate && (
                <span> (Inactive date: {renderDate(StaffInfo.InActiveDate, false)})</span>
              )}
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default GeneralInformation;
