import React from 'react';
import { Form, Radio, Input, DatePicker, Select, Checkbox, Divider } from 'antd';

class ActionRequired extends React.Component {
  render() {
    return (
      <Form name="action-required">
        <Form.Item label="Action Type" className="fluid-field">
          <Radio.Group>
            <Radio>Action Required</Radio>
            <Radio>Urgent Report</Radio>
            <Radio>None</Radio>
          </Radio.Group>
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Due Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="PIC">
          <Select size="large" value="1">
            <Select.Option value="1">Option</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Action notes" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <div className="fluid-field checkbox-field">
          <Checkbox>Action completed</Checkbox>
        </div>

        <Divider className="fluid-field" />
        <Form.Item label="Required Date for Report">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Due Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="PIC">
          <Select size="large" value="1">
            <Select.Option value="1">Option</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Urgent Report notes" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <div className="fluid-field checkbox-field">
          <Checkbox>Action completed</Checkbox>
        </div>
      </Form>
    );
  }
}

export default ActionRequired;
