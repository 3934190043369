import './index.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Form, Checkbox, Icon, Label } from 'semantic-ui-react';
import { toJS } from 'mobx';
import cx from 'classnames';
import TableToolbar from '../../shared/tableToolbar';
import ClientReportStore from './ClientReportStore';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import * as api from '@stores/api';

@observer
class ClientReportToolbar extends React.Component {
  handleChangeRadioBox = (_, { value }) => {
    ClientReportStore.setFieldsValue({ type: value });
  };
  handleChecked = (_, { value }) => {
    if (value === 'AssessmentSelectAll') {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          AssessmentAppointmentAttended: false,
          AssessmentAppointmentConfirmed: false,
          AssessmentClientInvoice: false,
          AssessmentSpecialistInvoice: false,
          // AssessmentServiceInvoice: false,
          AssessmentCancelledNoFee: false,
          AssessmentCancelledWithFee: false,
          AssessmentDictationReceived: false,
          AssessmentIMERequested: false,
          AssessmentPaperWorkReceived: false,
          AssessmentReportCompleted: false,
          AssessmentCorrectedReportReturned: false,
          AssessmentReportDraft: false,
          AssessmentServicePaid: false,
          AssessmentSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          AssessmentAppointmentAttended: true,
          AssessmentAppointmentConfirmed: true,
          AssessmentClientInvoice: true,
          AssessmentSpecialistInvoice: true,
          // AssessmentServiceInvoice: true,
          AssessmentCancelledNoFee: true,
          AssessmentCancelledWithFee: true,
          AssessmentDictationReceived: true,
          AssessmentIMERequested: true,
          AssessmentPaperWorkReceived: true,
          AssessmentReportCompleted: true,
          AssessmentCorrectedReportReturned: true,
          AssessmentReportDraft: true,
          AssessmentServicePaid: true,
          AssessmentSelectAll: true,
        });
      }
    } else {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
          AssessmentSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
        });
        this.checkSelectAllIME();
      }
    }
  };

  checkSelectAllFR = () => {
    const {
      FileRevBilled,
      FileRevCancelledNoFee,
      FileRevCancelledWithFee,
      FileRevReportCompleted,
      FileRevReportDraftSentToDoctor,
      FileRevRequestSentToProvider,
      FileRevRequested,
      FileRevServicePaid,
      FileRevReturnedFromDoctors,
      FileRevDictationReceived,
    } = ClientReportStore;
    if (
      FileRevBilled &&
      FileRevCancelledNoFee &&
      FileRevCancelledWithFee &&
      FileRevReportCompleted &&
      FileRevReportCompleted &&
      FileRevReportDraftSentToDoctor &&
      FileRevRequestSentToProvider &&
      FileRevRequested &&
      FileRevServicePaid &&
      FileRevReturnedFromDoctors &&
      FileRevDictationReceived
    ) {
      ClientReportStore.setFieldsValue({ FileRevSelectAll: true });
    } else {
      ClientReportStore.setFieldsValue({ FileRevSelectAll: false });
    }
  };

  handleCheckedFR = (_, { value }) => {
    if (value === `FileRevSelectAll`) {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          FileRevBilled: false,
          FileRevCancelledWithFee: false,
          FileRevCancelledNoFee: false,
          FileRevReportCompleted: false,
          FileRevReportDraftSentToDoctor: false,
          FileRevRequestSentToProvider: false,
          FileRevRequested: false,
          FileRevSelectAll: false,
          FileRevServicePaid: false,
          FileRevReturnedFromDoctors: false,
          FileRevDictationReceived: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          FileRevBilled: true,
          FileRevCancelledWithFee: true,
          FileRevCancelledNoFee: true,
          FileRevReportCompleted: true,
          FileRevReportDraftSentToDoctor: true,
          FileRevRequestSentToProvider: true,
          FileRevRequested: true,
          FileRevSelectAll: true,
          FileRevServicePaid: true,
          FileRevReturnedFromDoctors: true,
          FileRevDictationReceived: true,
        });
      }
    } else {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
          FileRevSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
        });
        this.checkSelectAllFR();
      }
    }
  };

  checkSelectAllIME = () => {
    const {
      AssessmentAppointmentAttended,
      AssessmentAppointmentConfirmed,
      AssessmentClientInvoice,
      AssessmentSpecialistInvoice,
      // AssessmentServiceInvoice,
      AssessmentCancelledNoFee,
      AssessmentCancelledWithFee,
      AssessmentDictationReceived,
      AssessmentIMERequested,
      AssessmentPaperWorkReceived,
      AssessmentReportCompleted,
      AssessmentCorrectedReportReturned,
      AssessmentReportDraft,
      AssessmentServicePaid,
    } = ClientReportStore;
    if (
      AssessmentAppointmentAttended &
      AssessmentAppointmentConfirmed &
      AssessmentClientInvoice &
      AssessmentSpecialistInvoice &
      // AssessmentServiceInvoice &
      AssessmentCancelledNoFee &
      AssessmentCancelledWithFee &
      AssessmentDictationReceived &
      AssessmentIMERequested &
      AssessmentPaperWorkReceived &
      AssessmentReportCompleted &
      AssessmentCorrectedReportReturned &
      AssessmentReportDraft &
      AssessmentServicePaid
    ) {
      ClientReportStore.setFieldsValue({ AssessmentSelectAll: true });
    } else {
      ClientReportStore.setFieldsValue({ AssessmentSelectAll: false });
    }
  };

  checkSelectAllSR = () => {
    const {
      SuppBilled,
      SuppCancelledNoFee,
      SuppCancelledWithFee,
      SuppReportCompleted,
      SuppReportDraftSentToDoctor,
      SuppRequestSentToProvider,
      SuppRequested,
      SuppServicePaid,
      SuppDictationReceived,
      SuppReportReturnedFromDoctor,
    } = ClientReportStore;
    if (
      SuppBilled &&
      SuppCancelledNoFee &&
      SuppCancelledWithFee &&
      SuppReportCompleted &&
      SuppReportCompleted &&
      SuppReportDraftSentToDoctor &&
      SuppRequestSentToProvider &&
      SuppRequested &&
      SuppServicePaid &&
      SuppDictationReceived &&
      SuppReportReturnedFromDoctor
    ) {
      ClientReportStore.setFieldsValue({ SuppSelectAll: true });
    } else {
      ClientReportStore.setFieldsValue({ SuppSelectAll: false });
    }
  };

  handleCheckedSR = (_, { value }) => {
    if (value === `SuppSelectAll`) {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          SuppBilled: false,
          SuppCancelledNoFee: false,
          SuppCancelledWithFee: false,
          SuppReportCompleted: false,
          SuppReportDraftSentToDoctor: false,
          SuppRequestSentToProvider: false,
          SuppRequested: false,
          SuppSelectAll: false,
          SuppServicePaid: false,
          SuppDictationReceived: false,
          SuppReportReturnedFromDoctor: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          SuppBilled: true,
          SuppCancelledNoFee: true,
          SuppCancelledWithFee: true,
          SuppReportCompleted: true,
          SuppReportDraftSentToDoctor: true,
          SuppRequestSentToProvider: true,
          SuppRequested: true,
          SuppSelectAll: true,
          SuppServicePaid: true,
          SuppDictationReceived: true,
          SuppReportReturnedFromDoctor: true,
        });
      }
    } else {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
          SuppSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
        });
        this.checkSelectAllSR();
      }
    }
  };

  checkSelectAllCR = () => {
    const {
      CRRequestReceived,
      CRRequestSentToSpecialist,
      CRPrepaymentRequired,
      CRPrepaymentPaid,
      CRCRFilesSent,
      CRCancelledNoFee,
      CRCancelledWithFee,
      CROnHold,
      CRServiceBilled,
      CRServicePaid,
    } = ClientReportStore;
    if (
      CRRequestReceived &&
      CRRequestSentToSpecialist &&
      CRPrepaymentRequired &&
      CRPrepaymentPaid &&
      CRCRFilesSent &&
      CRCancelledNoFee &&
      CRCancelledWithFee &&
      CROnHold &&
      CRServiceBilled &&
      CRServicePaid
    ) {
      ClientReportStore.setFieldsValue({ CRSelectAll: true });
    } else {
      ClientReportStore.setFieldsValue({ CRSelectAll: false });
    }
  };

  handleCheckedCR = (_, { value }) => {
    if (value === `CRSelectAll`) {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          CRRequestReceived: false,
          CRRequestSentToSpecialist: false,
          CRPrepaymentRequired: false,
          CRPrepaymentPaid: false,
          CRCRFilesSent: false,
          CRCancelledNoFee: false,
          CRCancelledWithFee: false,
          CROnHold: false,
          CRServiceBilled: false,
          CRServicePaid: false,
          CRSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          CRRequestReceived: true,
          CRRequestSentToSpecialist: true,
          CRPrepaymentRequired: true,
          CRPrepaymentPaid: true,
          CRCRFilesSent: true,
          CRCancelledNoFee: true,
          CRCancelledWithFee: true,
          CROnHold: true,
          CRServiceBilled: true,
          CRServicePaid: true,
          CRSelectAll: true,
        });
      }
    } else {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
          CRSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
        });
        this.checkSelectAllCR();
      }
    }
  };

  checkSelectAllMN = () => {
    const {
      MNRequestReceived,
      MNRequestSentToSpecialist,
      MNCloseWithoutBookings,
      MNCloseWithBookings,
    } = ClientReportStore;
    if (MNRequestReceived && MNRequestSentToSpecialist && MNCloseWithoutBookings && MNCloseWithBookings) {
      ClientReportStore.setFieldsValue({ MNSelectAll: true });
    } else {
      ClientReportStore.setFieldsValue({ MNSelectAll: false });
    }
  };

  handleCheckedMN = (_, { value }) => {
    if (value === `MNSelectAll`) {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          MNRequestReceived: false,
          MNRequestSentToSpecialist: false,
          MNCloseWithoutBookings: false,
          MNCloseWithBookings: false,
          MNSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          MNRequestReceived: true,
          MNRequestSentToSpecialist: true,
          MNCloseWithoutBookings: true,
          MNCloseWithBookings: true,
          MNSelectAll: true,
        });
      }
    } else {
      const isChecked = ClientReportStore[value];
      if (isChecked) {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
          MNSelectAll: false,
        });
      } else {
        ClientReportStore.setFieldsValue({
          [value]: !isChecked,
        });
        this.checkSelectAllMN();
      }
    }
  };

  handleChangeSearchDropdown = (_, { searchQuery }) => {
    ClientReportStore.refetchClientSearch({
      keywordClient: searchQuery,
    });
  };

  handleChangeSearchDropdownCM = (_, { searchQuery }) => {
    ClientReportStore.refetchClientSearch({
      keywordCM: searchQuery,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(ClientReportStore.dataClientSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.map(opt => ({
      text: `${
        !opt.Id
          ? `All`
          : `${opt.Id || ``}
      ${`Name: ${opt.Name || ``}`}
      ${` - ${opt.FullAddress || ``}`}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.Name || ''}`,
        key: `${opt.Name || ''} ${opt.FullAddress}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const client = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (!client.id || value === '') {
      ClientReportStore.setFieldsValue(
        {
          CustomerId: 0,
          curPage: 1,
          keywordClient: ``,
        },
        true,
      );
    } else {
      ClientReportStore.setFieldsValue(
        {
          CustomerId: client.id,
          curPage: 1,
          numberClientSearch: 30,
          keywordClient: '',
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    if (value) {
      const array = [];
      const data = toJS(ClientReportStore.dataClientSearch.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.Name} - ${opt.FullAddress}`);
      if (labels[0]) return labels[0];
      return value.toString();
    } else {
      return ``;
    }
  };

  handleScroll = event => {
    const target = event.target;
    const number = ClientReportStore.numberClientSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ClientReportStore.refetchClientSearch({
        numberClientSearch: number + 20,
      });
    }
  };

  renderCMOptions = () => {
    const array = [];
    const data = toJS(ClientReportStore.dataCM.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.map(opt => ({
      text: `${
        !opt.Id
          ? `All`
          : `${opt.Id || ``}
      ${`Name: ${opt.FullName || ``}`}
      ${` - ${opt.Email || ``}`}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FullName || ''}`,
        key: `${opt.Email || ''} ${opt.State}`,
      }),
    }));
  };

  handleFilterActionCM = (_, { value }) => {
    const client = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (!client.id || value === '') {
      ClientReportStore.setFieldsValue(
        {
          CaseManagerId: 0,
          curPage: 1,
          keywordCM: ``,
        },
        true,
      );
    } else {
      ClientReportStore.setFieldsValue(
        {
          CaseManagerId: client.id,
          curPage: 1,
          numberClientSearch: 30,
          keywordCM: '',
        },
        true,
      );
    }
  };

  renderSearchTextCM = value => {
    if (value) {
      const array = [];
      const data = toJS(ClientReportStore.dataCM.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName || ``} - ${opt.Email}`);
      if (labels[0]) return labels[0];
      return value.toString();
    } else {
      return ``;
    }
  };

  handleScrollCM = event => {
    const target = event.target;
    const number = ClientReportStore.numberClientSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ClientReportStore.refetchCMSearch({
        numberClientSearch: number + 20,
      });
    }
  };

  createDate = date => {
    const datearr = date.split('/');
    const d = new Date(datearr[2], Number(datearr[1]) - 1, datearr[0]);
    return d;
  };
  handleChangeFromTo = (_, { value }) => {
    ClientReportStore.setFieldsValue({ fromTo: value });
  };

  handleSearch = async e => {
    e.preventDefault();
    const { fromTo } = ClientReportStore;
    const from = fromTo.split(' - ')[0];
    const to = fromTo.split(' - ')[1];
    var startDate = from ? this.createDate(from) : null;
    var endDate = to ? this.createDate(to) : null;
    var oneDay = 24 * 60 * 60 * 1000;

    if (fromTo) {
      if (endDate) {
        const diffDays = Math.abs((endDate.getTime() - startDate.getTime()) / oneDay);
        if (diffDays > 90) {
          return ClientReportStore.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please choose less than 90 days range.',
          })();
        } else {
          if (this.checkSelectAtLeastStatus()) {
            return ClientReportStore.toggleModal(true, {
              modalType: 'confirm',
              message: 'Please select at least a report status first',
            })();
          }
          return ClientReportStore.fetchData();
        }
      } else {
        return ClientReportStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select end date',
        })();
      }
    } else {
      return ClientReportStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select date range',
      })();
    }
  };

  handleExport = () => {
    const { searchOpt } = ClientReportStore;
    var qs = Object.entries(searchOpt)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open('/Report/DownloadSourceOfMagBooking?' + qs, '_blank');
  };

  openReminder = () => {
    iframe.forceOpenTab('InvoiceOverdueReminderHistory', `?view=InvoiceOverdueReminderHistory`, {
      view: 'InvoiceOverdueReminderHistory',
    });
  };

  checkSelectAtLeastStatus = () => {
    const {
      SuppBilled,
      SuppCancelledNoFee,
      SuppCancelledWithFee,
      SuppReportCompleted,
      SuppReportDraftSentToDoctor,
      SuppRequestSentToProvider,
      SuppRequested,
      SuppServicePaid,
      SuppDictationReceived,
      SuppReportReturnedFromDoctor,
      AssessmentAppointmentAttended,
      AssessmentAppointmentConfirmed,
      AssessmentClientInvoice,
      AssessmentSpecialistInvoice,
      // AssessmentServiceInvoice,
      AssessmentCancelledNoFee,
      AssessmentCancelledWithFee,
      AssessmentDictationReceived,
      AssessmentIMERequested,
      AssessmentPaperWorkReceived,
      AssessmentReportCompleted,
      AssessmentCorrectedReportReturned,
      AssessmentReportDraft,
      AssessmentServicePaid,
      FileRevBilled,
      FileRevCancelledNoFee,
      FileRevCancelledWithFee,
      FileRevReportCompleted,
      FileRevReportDraftSentToDoctor,
      FileRevRequestSentToProvider,
      FileRevRequested,
      FileRevServicePaid,
      FileRevReturnedFromDoctors,
      FileRevDictationReceived,
      type,
    } = ClientReportStore;
    if (type === 1) {
      if (
        !AssessmentAppointmentAttended &
        !AssessmentAppointmentConfirmed &
        !AssessmentClientInvoice &
        !AssessmentSpecialistInvoice &
        // !AssessmentServiceInvoice &
        !AssessmentCancelledNoFee &
        !AssessmentCancelledWithFee &
        !AssessmentDictationReceived &
        !AssessmentIMERequested &
        !AssessmentPaperWorkReceived &
        !AssessmentReportCompleted &
        !AssessmentCorrectedReportReturned &
        !AssessmentReportDraft &
        !AssessmentServicePaid
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (type === 2) {
      if (
        !FileRevBilled &&
        !FileRevCancelledNoFee &&
        !FileRevCancelledWithFee &&
        !FileRevReportCompleted &&
        !FileRevReportDraftSentToDoctor &&
        !FileRevRequestSentToProvider &&
        !FileRevRequested &&
        !FileRevServicePaid &&
        !FileRevReturnedFromDoctors &&
        !FileRevDictationReceived
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (type === 3) {
      if (
        !SuppBilled &&
        !SuppCancelledNoFee &&
        !SuppCancelledWithFee &&
        !SuppReportCompleted &&
        !SuppReportCompleted &&
        !SuppReportDraftSentToDoctor &&
        !SuppRequestSentToProvider &&
        !SuppRequested &&
        !SuppServicePaid &&
        !SuppDictationReceived &&
        !SuppReportReturnedFromDoctor
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleReset = () => {
    ClientReportStore.setFieldsValue({
      data: null,
      //type: 1,
      SuppBilled: true,
      SuppCancelledNoFee: true,
      SuppCancelledWithFee: true,
      SuppReportCompleted: true,
      SuppReportDraftSentToDoctor: true,
      SuppRequestSentToProvider: true,
      SuppRequested: true,
      SuppSelectAll: true,
      SuppServicePaid: true,
      SuppDictationReceived: true,
      SuppReportReturnedFromDoctor: true,
      FileRevBilled: true,
      FileRevCancelledWithFee: true,
      FileRevCancelledNoFee: true,
      FileRevReportCompleted: true,
      FileRevReportDraftSentToDoctor: true,
      FileRevRequestSentToProvider: true,
      FileRevReturnedFromDoctors: true,
      FileRevRequested: true,
      FileRevSelectAll: true,
      FileRevServicePaid: true,
      FileRevDictationReceived: true,
      AssessmentAppointmentAttended: true,
      AssessmentAppointmentConfirmed: true,
      AssessmentClientInvoice: true,
      AssessmentSpecialistInvoice: true,
      // AssessmentServiceInvoice: true,
      AssessmentCancelledNoFee: true,
      AssessmentCancelledWithFee: true,
      AssessmentDictationReceived: true,
      AssessmentIMERequested: true,
      AssessmentPaperWorkReceived: true,
      AssessmentReportCompleted: true,
      AssessmentCorrectedReportReturned: true,
      AssessmentReportDraft: true,
      AssessmentServicePaid: true,
      CustomerId: 0,
      CaseManagerId: 0,
      dataArray: null,
      dataArrayIME: null,
      dataArrayFR: null,
      dataArraySupp: null,
      dataIME: null,
      dataFR: null,
      dataSupp: null,
    });
  };

  handleOpenModalExport = () => {
    return ClientReportStore.toggleModal(true, { modalType: 'export' })();
  };
  handleOpenModalImport = () => {
    return ClientReportStore.toggleModal(true, { modalType: 'import' })();
  };

  handleOpenModalExportHistories = () => {
    return ClientReportStore.toggleModal(true, { modalType: 'exportHistories' })();
  };

  render() {
    const {
      fromTo,
      AssessmentAppointmentAttended,
      AssessmentAppointmentConfirmed,
      AssessmentClientInvoice,
      AssessmentSpecialistInvoice,
      // AssessmentServiceInvoice,
      AssessmentCancelledNoFee,
      AssessmentCancelledWithFee,
      AssessmentDictationReceived,
      AssessmentIMERequested,
      AssessmentPaperWorkReceived,
      AssessmentReportCompleted,
      AssessmentCorrectedReportReturned,
      AssessmentReportDraft,
      AssessmentServicePaid,
      FileRevBilled,
      FileRevCancelledNoFee,
      FileRevCancelledWithFee,
      FileRevReportDraftSentToDoctor,
      FileRevRequestSentToProvider,
      FileRevRequested,
      FileRevSelectAll,
      FileRevServicePaid,
      FileRevReturnedFromDoctors,
      FileRevReportCompleted,
      FileRevDictationReceived,
      SuppBilled,
      SuppCancelledNoFee,
      SuppCancelledWithFee,
      SuppReportCompleted,
      SuppReportDraftSentToDoctor,
      SuppRequestSentToProvider,
      SuppRequested,
      SuppSelectAll,
      SuppServicePaid,
      SuppDictationReceived,
      SuppReportReturnedFromDoctor,
      //CR
      CRSelectAll,
      CRRequestReceived,
      CRRequestSentToSpecialist,
      CRPrepaymentRequired,
      CRPrepaymentPaid,
      CRCRFilesSent,
      CRCancelledNoFee,
      CRCancelledWithFee,
      CROnHold,
      CRServiceBilled,
      CRServicePaid,
      //END CR
      //MN
      MNSelectAll,
      MNRequestReceived,
      MNRequestSentToSpecialist,
      MNCloseWithoutBookings,
      MNCloseWithBookings,
      //END MN
      loadingClientSearch,
      CustomerId,
      loadingCM,
      CaseManagerId,
      loading,
      tabIsActive,
      keywordCM,
      keywordClient,
      AssessmentSelectAll,
    } = ClientReportStore;
    return (
      <React.Fragment>
        <TableToolbar.Container className="Toolbar-container">
          <Form loading={loading}>
            {tabIsActive === 'ime' && (
              <Form.Group className="Form-group">
                <Form.Field
                  control={Checkbox}
                  label="Select All"
                  value={`AssessmentSelectAll`}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentSelectAll}
                />
                <Form.Field
                  control={Checkbox}
                  label="Appointment Requested"
                  value={`AssessmentIMERequested`}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentIMERequested}
                />
                <Form.Field
                  control={Checkbox}
                  label="Appointment Confirmed"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentAppointmentConfirmed`}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentAppointmentConfirmed}
                />
                <Form.Field
                  control={Checkbox}
                  label="Paperwork Sent to Specialist"
                  value={`AssessmentPaperWorkReceived`}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentPaperWorkReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Appointment Attended"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentAppointmentAttended`}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentAppointmentAttended}
                />
                <Form.Field
                  control={Checkbox}
                  label="Dictation Received"
                  value={`AssessmentDictationReceived`}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentDictationReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Draft Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentReportDraft`}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentReportDraft}
                />
                <Form.Field
                  control={Checkbox}
                  label="Corrected Report Returned"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentCorrectedReportReturned`}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentCorrectedReportReturned}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Sent to CMs"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentReportCompleted`}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentReportCompleted}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled-No Fee"
                  value={`AssessmentCancelledNoFee`}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleChecked}
                  checked={AssessmentCancelledNoFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled With Fee"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentCancelledWithFee`}
                  onChange={this.handleChecked}
                  width={5}
                  checked={AssessmentCancelledWithFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Client Invoiced"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentBilled`}
                  onChange={this.handleChecked}
                  width={5}
                  checked={AssessmentClientInvoice}
                />
                <Form.Field
                  control={Checkbox}
                  label="Specialist Billed"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentBilled`}
                  onChange={this.handleChecked}
                  width={5}
                  checked={AssessmentSpecialistInvoice}
                />
                {/* <Form.Field
                  control={Checkbox}
                  label="Service Invoiced"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentBilled`}
                  onChange={this.handleChecked}
                  width={5}
                  checked={AssessmentServiceInvoice}
                /> */}
                <Form.Field
                  control={Checkbox}
                  label="Service Paid"
                  style={{ marginBottom: 15 }}
                  value={`AssessmentServicePaid`}
                  onChange={this.handleChecked}
                  width={5}
                  checked={AssessmentServicePaid}
                />
                <Form.Field width={5} as="div" />
              </Form.Group>
            )}
            {tabIsActive === 'fr' && (
              <Form.Group className="Form-group">
                <Form.Field
                  control={Checkbox}
                  label="Select All"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={`FileRevSelectAll`}
                  onChange={this.handleCheckedFR}
                  checked={FileRevSelectAll}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Received"
                  value={'FileRevRequested'}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleCheckedFR}
                  checked={FileRevRequested}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  value={'FileRevRequestSentToProvider'}
                  width={5}
                  onChange={this.handleCheckedFR}
                  checked={FileRevRequestSentToProvider}
                />
                <Form.Field
                  control={Checkbox}
                  label="Dictation Received"
                  style={{ marginBottom: 15 }}
                  value={'FileRevDictationReceived'}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={FileRevDictationReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Draft Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'FileRevReportDraftSentToDoctor'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevReportDraftSentToDoctor}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Returned from Specialist"
                  value={'FileRevReturnedFromDoctors'}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleCheckedFR}
                  checked={FileRevReturnedFromDoctors}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Sent to CMs"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'FileRevReportCompleted'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevReportCompleted}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled No Fee"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'FileRevCancelledNoFee'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevCancelledNoFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled With Fee"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'FileRevCancelledWithFee'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevCancelledWithFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Billed"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'FileRevBilled'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevBilled}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Paid"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'FileRevServicePaid'}
                  onChange={this.handleCheckedFR}
                  checked={FileRevServicePaid}
                />
              </Form.Group>
            )}
            {tabIsActive === 'supp' && (
              <Form.Group className="Form-group">
                <Form.Field
                  control={Checkbox}
                  label="Select All"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'SuppSelectAll'}
                  onChange={this.handleCheckedSR}
                  checked={SuppSelectAll}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Received"
                  value={'SuppRequested'}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={SuppRequested}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  value={'SuppRequestSentToProvider'}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={SuppRequestSentToProvider}
                />
                <Form.Field
                  control={Checkbox}
                  label="Dictation Received"
                  style={{ marginBottom: 15 }}
                  value={'SuppDictationReceived'}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={SuppDictationReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Draft Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'SuppReportDraftSentToDoctor'}
                  onChange={this.handleCheckedSR}
                  checked={SuppReportDraftSentToDoctor}
                />
                <Form.Field
                  control={Checkbox}
                  label="Corrected Report Returned"
                  width={5}
                  value={'SuppReportReturnedFromDoctor'}
                  style={{ marginBottom: 15 }}
                  onChange={this.handleCheckedSR}
                  checked={SuppReportReturnedFromDoctor}
                />
                <Form.Field
                  control={Checkbox}
                  label="Report Sent to CMs"
                  width={5}
                  value={'SuppReportCompleted'}
                  style={{ marginBottom: 15 }}
                  onChange={this.handleCheckedSR}
                  checked={SuppReportCompleted}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled No Fee"
                  style={{ marginBottom: 15 }}
                  value={'SuppCancelledNoFee'}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={SuppCancelledNoFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled With Fee"
                  style={{ marginBottom: 15 }}
                  value={'SuppCancelledWithFee'}
                  width={5}
                  onChange={this.handleCheckedSR}
                  checked={SuppCancelledWithFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Billed"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'SuppBilled'}
                  onChange={this.handleCheckedSR}
                  checked={SuppBilled}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Paid"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'SuppServicePaid'}
                  onChange={this.handleCheckedSR}
                  checked={SuppServicePaid}
                />
              </Form.Group>
            )}
            {tabIsActive === 'cr' && (
              <Form.Group className="Form-group">
                <Form.Field
                  control={Checkbox}
                  label="Select All"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'CRSelectAll'}
                  onChange={this.handleCheckedCR}
                  checked={CRSelectAll}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Received"
                  value={'CRRequestReceived'}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleCheckedCR}
                  checked={CRRequestReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  value={'CRRequestSentToSpecialist'}
                  width={5}
                  onChange={this.handleCheckedCR}
                  checked={CRRequestSentToSpecialist}
                />
                <Form.Field
                  control={Checkbox}
                  label="Prepayment Required"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'CRPrepaymentRequired'}
                  onChange={this.handleCheckedCR}
                  checked={CRPrepaymentRequired}
                />
                <Form.Field
                  control={Checkbox}
                  label="Prepayment Paid"
                  width={5}
                  value={'CRPrepaymentPaid'}
                  style={{ marginBottom: 15 }}
                  onChange={this.handleCheckedCR}
                  checked={CRPrepaymentPaid}
                />
                <Form.Field
                  control={Checkbox}
                  label="CR sent to CMs"
                  style={{ marginBottom: 15 }}
                  value={'CRCRFilesSent'}
                  width={5}
                  onChange={this.handleCheckedCR}
                  checked={CRCRFilesSent}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled No Fee"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'CRCancelledNoFee'}
                  onChange={this.handleCheckedCR}
                  checked={CRCancelledNoFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="Cancelled With Fee"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'CRCancelledWithFee'}
                  onChange={this.handleCheckedCR}
                  checked={CRCancelledWithFee}
                />
                <Form.Field
                  control={Checkbox}
                  label="On-Hold"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'CROnHold'}
                  onChange={this.handleCheckedCR}
                  checked={CROnHold}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Billed"
                  style={{ marginBottom: 15 }}
                  value={'CRServiceBilled'}
                  width={5}
                  onChange={this.handleCheckedCR}
                  checked={CRServiceBilled}
                />
                <Form.Field
                  control={Checkbox}
                  label="Service Paid"
                  width={5}
                  style={{ marginBottom: 15 }}
                  value={'CRServicePaid'}
                  onChange={this.handleCheckedCR}
                  checked={CRServicePaid}
                />
              </Form.Group>
            )}
            {tabIsActive === 'mn' && (
              <Form.Group className="Form-group">
                <Form.Field
                  control={Checkbox}
                  label="Select All"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'MNSelectAll'}
                  onChange={this.handleCheckedMN}
                  checked={MNSelectAll}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Received"
                  value={'MNRequestReceived'}
                  style={{ marginBottom: 15 }}
                  width={5}
                  onChange={this.handleCheckedMN}
                  checked={MNRequestReceived}
                />
                <Form.Field
                  control={Checkbox}
                  label="Request Sent to Specialist"
                  style={{ marginBottom: 15 }}
                  value={'MNRequestSentToSpecialist'}
                  width={5}
                  onChange={this.handleCheckedMN}
                  checked={MNRequestSentToSpecialist}
                />
                <Form.Field
                  control={Checkbox}
                  label="Close Without Bookings"
                  style={{ marginBottom: 15 }}
                  width={5}
                  value={'MNCloseWithoutBookings'}
                  onChange={this.handleCheckedMN}
                  checked={MNCloseWithoutBookings}
                />
                <Form.Field
                  control={Checkbox}
                  label="Close With Bookings"
                  width={5}
                  value={'MNCloseWithBookings'}
                  style={{ marginBottom: 15 }}
                  onChange={this.handleCheckedMN}
                  checked={MNCloseWithBookings}
                />
              </Form.Group>
            )}
          </Form>
        </TableToolbar.Container>
        {tabIsActive !== 'export' && (
          <TableToolbar.Container className="Toolbar-container">
            <TableToolbar.Left>
              <TableToolbar.SearchDropdown
                disabled={loading}
                className="Toolbar-input"
                loading={loadingClientSearch}
                searchPlaceHolder="Select Clients"
                selectOnBlur={false}
                onScroll={this.handleScroll}
                search
                selectOnNavigation={false}
                value={this.renderSearchText(CustomerId)}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                clearable
                searchQuery={keywordClient}
                icon={!CustomerId ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
              <TableToolbar.SearchDropdown
                className="Toolbar-input"
                disabled={loading}
                searchPlaceHolder="Select CMs"
                loading={loadingCM}
                onScroll={this.handleScrollCM}
                selectOnBlur={false}
                search
                selectOnNavigation={false}
                allowAdditions
                additionLabel=""
                value={this.renderSearchTextCM(CaseManagerId)}
                onSearchChange={this.handleChangeSearchDropdownCM}
                options={this.renderCMOptions()}
                onChange={this.handleFilterActionCM}
                searchQuery={keywordCM}
                clearable
                icon={!CaseManagerId ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
              <TableToolbar.RangePicker
                disabled={loading}
                dateFormat="DD/MM/YYYY"
                className={`Toolbar-range-picker-client-report${!fromTo && '-error'}`}
                placeholder="From date - To date"
                value={fromTo}
                onChange={this.handleChangeFromTo}
              />

              <TableToolbar.SubmitButton onClick={this.handleSearch} className="px-2" disabled={loading}>
                Search
              </TableToolbar.SubmitButton>
              <TableToolbar.HighLightButton
                className="negative"
                style={{ marginLeft: `5px` }}
                disabled={loading}
                onClick={this.handleReset}
              >
                Clear
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
        )}
        {!fromTo && (
          <Label color="red" className="Field-Label-warning-date-range-picker" pointing>
            Please select a date range
          </Label>
        )}
        {this.checkSelectAtLeastStatus() && (
          <Label color="red" pointing className={`Field-Label-warning-btn-search${fromTo ? '-from-to-Active' : ''}`}>
            Select at least a report status first
          </Label>
        )}
        {tabIsActive === 'export' && (
          <TableToolbar.Container>
            <TableToolbar.Left>
              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className={cx({ blue: ClientReportStore.tabIsActive === 'export' })}
                onClick={this.handleOpenModalImport}
              >
                Import Clients
              </TableToolbar.HighLightButton>
              {api.isAdmin() && (
                <TableToolbar.HighLightButton
                  style={{ marginLeft: '5px' }}
                  className={cx({ blue: ClientReportStore.tabIsActive === 'export' })}
                  onClick={this.handleOpenModalExport}
                >
                  Export Clients
                </TableToolbar.HighLightButton>
              )}
              <TableToolbar.HighLightButton
                className="positive"
                onClick={this.handleExport}
                style={{ marginLeft: `5px` }}
              >
                Export Sources of Service Bookings
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                className="positive"
                onClick={this.openReminder}
                style={{ marginLeft: `5px` }}
              >
                Report of Overdue Reminders
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
        )}
      </React.Fragment>
    );
  }
}

export default ClientReportToolbar;
