import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Divider, Header, Table } from 'semantic-ui-react';
import { Spin } from 'antd';
import cx from 'classnames';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import Modal from '../../shared/Modal';
import customFetch from '../../../utils/customFetch';
import moment from 'moment';
import { formatDate } from '../../../utils/functions';

const columns = [{ title: 'Update By' }, { title: 'Update Date' }];

@observer
class ModalHistories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpen: true,
      listItem: [],
      curPage: 1,
      totalPage: 0,
      totalItems: 0,
    };
  }

  componentDidMount() {
    customFetch('/AccessCodeHistory/GetAll', {
      method: 'GET',
    }).then(resp => {
      this.setState({
        ...this.state,
        loading: false,
        listItem: resp.itemList,
        totalPage: resp.sumPage,
        totalItems: resp.sumItem,
      });
    });
  }

  onCancel = () => {
    this.props.onCancel();
  };

  handlePageClick = page => {};

  renderTableHistory = () =>
    this.state.listItem.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{record.Staff.FullName}</Table.Cell>
        <Table.Cell>
          {record.UpdateAt
            ? moment(formatDate(record.UpdateAt, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));

  render() {
    const { loading, listItem, curPage, totalItems, totalPage } = this.state;
    const colSpan = listItem.length;
    var handleCloseModal = this.props.handleCloseModal;
    return (
      <React.Fragment>
        <Modal
          visible={true}
          width={1200}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="red" onClick={() => handleCloseModal(false)}>
                Close
              </Button>
            </React.Fragment>
          }
          title="Access Code History"
        >
          <div className="ant-modal-export-history">
            {loading ? (
              <div className={`Table-loading`}>
                <Spin size="large" />
              </div>
            ) : (
              <div className={`Table-container1`}>
                <Table sortable striped celled className={cx({ disabled: loading })}>
                  <TableHeader columns={columns} />
                  <Table.Body>
                    {listItem && listItem.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
                  </Table.Body>
                  <TableFooterPagination
                    colSpan={colSpan}
                    totalItems={totalItems}
                    currentPage={curPage}
                    totalPage={totalPage}
                    onPageClick={page => this.handlePageClick(page)}
                  />
                </Table>
              </div>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalHistories;
