import { observer } from 'mobx-react';
import React from 'react';
import { Divider, Form, Button } from 'semantic-ui-react';
import ButtonSubmit from '../shared/form/ButtonSubmit';
import ReportStatusStore from './ReportStatusStore';
import ReportTable from './ReportTable';

@observer
class ReportStatus extends React.Component {
  render() {
    return (
      <Form size={'small'}>
        <section>
          <Form.Input
            fluid
            error={ReportStatusStore.contentErrorSearch ? { content: ReportStatusStore.contentErrorSearch } : null}
            label="Enter MAG Case No or Claim No"
            placeholder="Enter Mag Case No or Claim No"
            name="search"
            value={ReportStatusStore.search}
            onChange={ReportStatusStore.handleChange}
            type="text"
          />
          <div style={{ color: 'gray', marginTop: '-15px', paddingBottom: '15px' }}>
            <label>Search hint: MAG case number starts with A-, F-, S- or C-</label>
          </div>
          <Form.Input
            fluid
            error={ReportStatusStore.contentErrorEmail ? { content: ReportStatusStore.contentErrorEmail } : null}
            type="email"
            label="Enter Business Email used for Booking"
            placeholder="Email"
            name="email"
            value={ReportStatusStore.email}
            onChange={ReportStatusStore.handleChange}
          />
        </section>
        {/* ========================================================================================================= */}
        <div style={{ marginTop: '1em' }}>
          <ButtonSubmit
            icon="angle double right"
            loading={ReportStatusStore.loading}
            content="Check Status"
            onClick={ReportStatusStore.SendRequest}
          />
          <Button
            type="button"
            content="Clear"
            color="red"
            onClick={ReportStatusStore.Clear}
            style={{ marginLeft: '1em' }}
          />
          {ReportStatusStore.data && ReportStatusStore.data.length > 0 && (
            <Button
              type="button"
              content="Email Updates"
              loading={ReportStatusStore.loading}
              onClick={ReportStatusStore.SendMail}
              color="green"
              style={{ float: 'right' }}
            />
          )}
        </div>
        <br />
        <br />
        <Divider />
        {ReportStatusStore.data && ReportStatusStore.data.length > 0 && <ReportTable data={ReportStatusStore.data} />}
      </Form>
    );
  }
}

export default ReportStatus;
