import React from 'react';
import { Form, Checkbox, Input, notification, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import customFetch from '../../../utils/customFetch';

import AddEditStore from './store';
import { Button, Icon, Popup } from 'semantic-ui-react';
import * as api from '@stores/api';
import { formatDate } from '../../../utils/functions';

@observer
class LoginInformation extends React.Component {
  state = {
    passwordStrength: 'short',
    passwordStrengthCheck: {
      eightCharacter: false,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    },
    progressBarWidh: 0,
    showPassword: false,
  };
  handleShowPassword = () => {
    const isShow = this.state.showPassword;
    this.setState({ showPassword: !isShow });
  };
  checkPasswordStrength = value => {
    const value_ = value.toLowerCase();
    const regExpWeak = /[a-zA-Z]/;
    const regExpMedium = /\d+/;
    const regExpStrong = /[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
    let strength = 0;
    let passwordStrengthCheck = {
      eightCharacter: value.length >= 8,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    };
    const weakPass = value_.match(regExpWeak);
    const mediumPass = value_.match(regExpMedium);
    const strongPass = value_.match(regExpStrong);
    const password = value.split('');
    password.forEach(i => {
      if (regExpWeak.test(i)) {
        if (weakPass) {
          if (/[A-Z]/.test(i)) {
            strength += 4;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 3;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        } else {
          if (/[A-Z]/.test(i)) {
            strength += 6;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 5;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        }
      } else if (regExpMedium.test(i)) {
        if (mediumPass) {
          strength += 4;
          passwordStrengthCheck.digit = true;
        } else {
          strength += 7;
          passwordStrengthCheck.digit = true;
        }
      } else if (regExpStrong.test(i)) {
        if (strongPass) {
          passwordStrengthCheck.specialCharacter = true;
          strength += 8;
        } else {
          passwordStrengthCheck.specialCharacter = true;
          strength += 10;
        }
      }
    });
    return {
      passwordStrengthCheck,
      strength: strength > 100 ? 100 : strength,
    };
  };
  handleFieldChangeNewPW = fieldName => event => {
    const value = event.target.value;
    if (fieldName === 'NewPassword') {
      const progress = document.querySelector('.progress');
      const { strength, passwordStrengthCheck } = this.checkPasswordStrength(value);
      AddEditStore.setFieldsValue({ passwordStrengthCheck });
      this.setState({ passwordStrengthCheck });
      if (value !== '') {
        if (strength <= 3) {
          this.setState({
            passwordStrength: 'short',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
        } else if (strength <= 25) {
          this.setState({
            passwordStrength: 'weak',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
        } else if (strength <= 50) {
          this.setState({
            passwordStrength: 'medium',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FFCC33, #FF6600)';
        } else if (strength <= 75) {
          this.setState({
            passwordStrength: 'good',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #66FFFF, #3399FF)';
        } else if (strength <= 100) {
          this.setState({
            passwordStrength: 'strong',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #99FF99, #66CC00)';
        } else {
          this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
        }
      } else {
        this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
      }
    }
    AddEditStore.handleFieldChangePW(fieldName, value);
  };

  handleResetPassword = () => {
    const { CMInfo } = AddEditStore;
    AddEditStore.toggleModal(false, {})();
    setTimeout(() => {
      customFetch('/Manager/ResetPassword', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId: CMInfo.Id, cmId: CMInfo.CmId }),
      }).then(res => {
        AddEditStore.toggleModal(true, {
          modalType: 'notification',
          message: `Generated password: ${res.result}`,
        })();
      });
    });
  };

  handleResendLoginInformation = () => {
    const { CMInfo } = AddEditStore;
    customFetch('/Manager/ResendLoginInformation', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ uId: CMInfo.Id, cmId: CMInfo.CmId }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Resend login Information: Successfully!!`,
        duration: 5,
      });
    });
  };

  render() {
    const { CMInfo, errors } = AddEditStore;
    //const { passwordStrengthCheck, progressBarWidh } = this.state;
    return (
      <Form name="login-information">
        <Row className="fluid-field">
          <Col span={13}>
            <div className="fluid-field checkbox-field">
              <Checkbox
                disabled={api.isCaseManager()}
                checked={CMInfo.LoginRequired}
                onChange={AddEditStore.handleChecked('LoginRequired')}
              >
                Offer Online Account
              </Checkbox>
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '-6px' }} />}
                content="A login account offering will be sent to this account (MAIL-27)"
                position="top center"
              />
            </div>
            <div className="checkbox-field">
              <Checkbox
                disabled={api.isCaseManager()}
                checked={CMInfo.EnableLogin}
                onChange={AddEditStore.handleChecked('EnableLogin')}
              >
                Enable Login
                {CMInfo.EnableLoginDate
                  ? ' (' + moment(formatDate(CMInfo.EnableLoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                  : ''}
                {CMInfo.EnableLoginBy ? ' - ' + CMInfo.EnableLoginBy + ')' : ''}
              </Checkbox>
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '-6px' }} />}
                content="When enabling login, an activation email will be sent to this account business email (MAIL-27A)"
                position="top center"
              />
            </div>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Username"
              validateStatus={errors['duplicateEmail'] ? 'error' : null}
              help={errors['duplicateEmail']}
            >
              <Input
                size="large"
                value={CMInfo.EnableLogin ? (CMInfo.Username ? CMInfo.Username : CMInfo.Email || null) : null}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        {CMInfo.Id !== 0 && (
          <div className="fluid-field">
            <Button
              disabled={api.isCaseManager()}
              color="blue"
              onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleEditUser}
            >
              Edit Username
            </Button>
            <Button
              disabled={api.isCaseManager()}
              color="blue"
              onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleUnlockUser}
            >
              Unlock User
            </Button>
            <Button
              color="blue"
              onClick={
                !CMInfo.EnableLogin
                  ? this.notifyEnable
                  : AddEditStore.toggleModal(true, {
                      modalType: 'changePassword',
                      // message: `Do you want to reset password for user ${CMInfo.FirstName} ${CMInfo.LastName}? New password will be sent to the user automatically.`,
                      // onOk: this.handleResetPassword,
                    })
              }
            >
              Reset Password
            </Button>
            <Button color="blue" onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleResendLoginInformation}>
              Resend Login Information
            </Button>
            <Button
              color="blue"
              onClick={() =>
                AddEditStore.toggleModal(true, {
                  modalType: 'passwordRsHistory',
                })()
              }
            >
              Password Reset History
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default LoginInformation;
