import { notification, Spin } from 'antd';
import cx from 'classnames';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import ActionNoteForm from '../../shared/ActionNoteForm';
import Modal from '../../shared/Modal';
import store from './store';
import * as api from '../../../stores/api';

class ModalActionNotes extends React.Component {
  state = {
    staffs: [],
    notes: [],
    loading: true,
    toggleNewNote: false,
  };

  async componentDidMount() {
    try {
      const staffsPromise = customFetch('/Supplementary/GetStaffs', {
        method: 'POST',
      });

      const notesPromise = customFetch('/Supplementary/ActionRequired_LoadList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          suppId: store.supplementaryInfo.Id,
        }),
      });

      const [staffs, notes] = await Promise.all([staffsPromise, notesPromise]);
      this.setState({
        staffs: uniqBy(staffs.itemList, 'Id'),
        notes: notes.itemList.map(i => {
          if (i.DueDate) {
            return { ...i, DueDate: moment(i.DueDate) };
          } else {
            return { ...i };
          }
        }),
        toggleNewNote: !notes.itemList.length,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderNoteForm = () => {
    const { staffs, notes } = this.state;
    if (!notes.length) return null;

    return notes.map(note => (
      <ActionNoteForm
        key={note.Id}
        staffs={staffs}
        note={note}
        onSubmit={this.handleSubmit}
        onDelete={this.handleDelete}
      />
    ));
  };

  handleSubmit = async note => {
    this.setState({ loading: true });
    const data = omit(note, [
      'CreatedDate',
      'CreatedUserId',
      'LastUpdatedBy',
      'LastUpdatedDate',
      'StaffFirstName',
      'StaffLastName',
    ]);

    const dueDate = moment(note.DueDate);
    data.HourListActionRequired = dueDate.hour();
    data.MinuteListActionRequired = dueDate.minute();
    data.DueDate = new Date(dueDate.format('MM-DD-YYYY')).toISOString();
    data.CompletedDate = data.Completed
      ? new Date((data.CompletedDate ? moment(data.CompletedDate) : moment()).format('MM-DD-YYYY')).toISOString()
      : null;

    data.SupplementaryId = store.supplementaryInfo.Id;

    try {
      if (data.Id) {
        const response = await customFetch('/Supplementary/ActionRequired_Update', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ model: data }),
        });
        if (response.status === 'success') {
          await store.handleRefetchActionNote();
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully.',
          });
          return store.toggleModalAction(false);
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
          });
        }
      } else {
        const response = await customFetch('/Supplementary/ActionRequired_Create', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            model: {
              ...data,
              HourAR: [],
              MinuteAR: [],
              ActionRequiredPICStaff: {
                selected: this.state.staffs.find(staff => staff.Id === data.StaffAssignedId),
              },
            },
          }),
        });
        if (response.status === 'success') {
          await store.handleRefetchActionNote();
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully.',
          });
          return store.toggleModalAction(false);
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
          });
        }
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  };

  handleDelete = async id => {
    this.setState({ loading: true });

    try {
      const response = await customFetch('/Supplementary/ActionRequired_Disable', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      this.setState(prev => ({
        ...prev,
        notes: prev.notes.filter(note => note.Id !== id),
      }));

      if (response.status === 'success') {
        await store.handleRefetchActionNote();
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been deleted successfully.',
        });
        return store.toggleModalAction(false);
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  };

  handleToggleNewNote = () => {
    this.setState({ toggleNewNote: !this.state.toggleNewNote });
  };

  render() {
    const { loading, staffs, toggleNewNote } = this.state;
    const { isOpenModalAction, toggleModalAction } = store;
    const title = 'Action Notes';
    var newNote = { DueDate: moment(), StaffAssignedId: api.currentUser.data.id };

    return (
      <Modal visible={isOpenModalAction} onCancel={() => toggleModalAction(false)} title={title}>
        {loading && !staffs.length ? (
          <Spin className="modal-spin" />
        ) : (
          <div className={cx({ 'modal-form-disabled': loading })}>
            {!api.isOnlySearch() && <Button color="blue" className="mb-1" onClick={this.handleToggleNewNote}>
              <Icon name="plus" /> New note
            </Button>}
            {toggleNewNote && <ActionNoteForm staffs={staffs} note={newNote} onSubmit={this.handleSubmit} />}
            {this.renderNoteForm()}
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalActionNotes;
