import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ModalBatchUpdatePayments from './ModalBatchUpdatePayments';
import ModalPaymentDate from './ModalPaymentDate';
import DRStore from './DRStore';
import ModalActionNotes from './ModalActionNotes';

function ClientsModal() {
  const { modalType } = DRStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...DRStore} onCancel={DRStore.toggleModal(false)} />;
  }

  if (modalType === 'updatePayment') {
    return <ModalBatchUpdatePayments {...DRStore} onCancel={DRStore.toggleModal(false)} />;
  }

  if (modalType === 'paymentDate') {
    return <ModalPaymentDate {...DRStore} onCancel={DRStore.toggleModal(false)} />;
  }

  if (modalType === 'notes') {
    return <ModalActionNotes {...DRStore} onCancel={DRStore.toggleModal(false)} />;
  }
}

export default ClientsModal;
