import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';
import * as api from '@stores/api';
import ClaimantsStore from './ClaimantsStore';
import ClaimantsTable from './ClaimantsTable';
import ClaimantsModal from './ClaimantsModal';
import ClaimantsToolbar from './ClaimantsToolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';

class Claimants extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/claimants-2', true);
      return;
    }
    ClaimantsStore.fetchClaimants(true);
    ui.on('open(/view/claimants-2)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    ClaimantsStore.fetchClaimants(true);
  }, 500);

  render() {
    const { loading, data, open, loadingSearch, dataSearch } = ClaimantsStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingSearch && !dataSearch) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingSearch && !dataSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ClaimantsToolbar />
            <ClaimantsTable />
            {open && <ClaimantsModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(Claimants);
