import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import TypistStore from './TypistStore';
import ModalLoginHistory from './ModalLoginHistory';
import ModalNotification from './ModalNotification';
import ModalBookingHistory from './ModalBookingHistory';

function TypistModal() {
  const { modalType } = TypistStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...TypistStore} onCancel={TypistStore.toggleModal(false)} />;
  }

  if (modalType === 'notification') {
    return <ModalNotification {...TypistStore} onCancel={TypistStore.toggleModal(false)} />;
  }

  if (modalType === 'showLoginHistory') {
    return <ModalLoginHistory {...TypistStore} onCancel={TypistStore.toggleModal(false)} />;
  }

  if (modalType === 'bookingHistory') {
    return <ModalBookingHistory {...TypistStore} onCancel={TypistStore.toggleModal(false)} />;
  }
}

export default TypistModal;
