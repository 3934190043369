import './IframeContainer.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import dashboard from '../../../stores/dashboard';
import router from '../../../stores/router';
import Loading from '../../shared/Loading';
import iframe from './iframeData';
import { isIframePath, pathToTab, tabConfig } from './iframeMap';

@observer
class IframeContainer extends React.Component {
  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    const tabValue = Object.entries(tabConfig).map(([_, tabValue]) => tabValue.path);
    if (tabValue.indexOf(router.location.pathname) !== -1) {
      if (!dashboard.tabs.some(t => isIframePath(t.path))) {
        iframe.f = null;
      }
    }
    const path = router.location.pathname;
    const isCurrentIframeView = isIframePath(path);
    const { loading } = iframe;
    if (loading || !isCurrentIframeView) {
      return;
    }
    iframe.openTab(pathToTab[path]);
  }

  render() {
    const sidebarAutohide = dashboard.sidebar.autohide;
    const isCurrentIframeView = isIframePath(router.location.pathname);
    const { loading } = iframe;

    return (
      <div
        className={cx('IframeContainer', {
          sidebarAutohide,
          visibilityHidden: !isCurrentIframeView,
        })}
      >
        {isCurrentIframeView && loading && <Loading />}
        <div id="cirrus-iframe" className={cx({ visibilityHidden: loading || !isCurrentIframeView })} />
      </div>
    );
  }
}

export default IframeContainer;
