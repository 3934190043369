import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import MessageTemplateStore from './MessageTemplateStore';
import ModalTemplate from './ModalTemplate';
import ModalEdit from './ModalEdit';
import ModalLogic from './ModalLogic';

function MessageTemplateModal() {
  const { modalType } = MessageTemplateStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...MessageTemplateStore} onCancel={MessageTemplateStore.toggleModal(false)} />;
  }
  if (modalType === 'template') {
    return <ModalTemplate {...MessageTemplateStore} onCancel={MessageTemplateStore.toggleModal(false)} />;
  }
  if (modalType === 'edit') {
    return <ModalEdit {...MessageTemplateStore} onCancel={MessageTemplateStore.toggleModal(false)} />;
  }
  if (modalType === 'logic') {
    return <ModalLogic {...MessageTemplateStore} onCancel={MessageTemplateStore.toggleModal(false)} />;
  }
}

export default MessageTemplateModal;
