import './Tabs.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ui from '../../../stores/dashboard';
import Tab from './Tab';
import TabMore from './TabMore';
import { toJS } from 'mobx';
import router from '@stores/router';

const Tabs = () => {
  const {
    tabs,
    maxTabs,
    sidebar: { collapsed: sidebarAutohide },
  } = ui;

  return (
    <div className="Tabs">
      <div className={cx('Tabs-Outer', { sidebarAutohide })}>
        <div className="Tabs-Inner">
          {toJS(tabs)
            .slice(0, maxTabs)
            .map(({ path, id, title }, index) => (
              <Tab tabTitle={title} key={index} tabIndex={index} tabId={index} i={index} />
            ))}
          {tabs.length > maxTabs && <TabMore />}
        </div>
      </div>
    </div>
  );
};

export default observer(Tabs);
