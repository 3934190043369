import customFetch from '../../../utils/customFetch';

export const getCMInfo = (id, cId = 0) => {
  return customFetch('/Typist/TypistInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, cId }),
  });
};

export const getStaffs = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getClient = (keyword = '', numberItem = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
      Showing: 'all',
    }),
  });
};

export const getSpecificLookupConfigByType = () => {
  return customFetch('/Typist/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'job' }),
  });
};

export const getStaffAll = (keyword = '', numberItem = 30) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const checkDuplicateName = (id, lastname) => {
  return customFetch('/Typist/CheckDuplicateName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, lastname }),
  });
};

export const checkDuplicateEmail = (id, email) => {
  return customFetch('/Typist/CheckDuplicateEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, email }),
  });
};

export const getConversationLog = cmId => {
  return customFetch('/Typist/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ typistId: cmId }),
  });
};

export const allowCMViewConversationLog = (id, type = 0) => {
  return customFetch('/Typist/UpdateDoctorAndCMsView', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, type }),
  });
};

export const deleteConversationLog = id => {
  return customFetch('/Typist/DeleteConversationLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const saveConversationLog = body => {
  return customFetch('/Typist/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const recentChangeStaff = id => {
  return customFetch('/Staff/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ actionName: 'ViewCM', id }),
  });
};

export const saveCaseManager = typistParam => {
  return customFetch('/Typist/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ typistParam }),
  });
};

export const saveChangePassword = typistParam => {
  return customFetch('/Typist/SaveChangePassword', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...typistParam }),
  });
};

export const fetchGreetingTitlesByType = type => {
  return customFetch(`/GreetingTitle/GetAllByType?type=${type}`, {
    method: 'GET',
  });
};
