import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import MarketingCampaignStore from './store';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import { deleteCaseManagerCampaign } from './service';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: '' },
  { title: 'ID' },
  { title: 'Campaign Name', sortKey: 'CampaignName' },
  { title: 'Campaign Date', sortKey: 'CampaignDate' },
  { title: 'Channels', sortKey: 'Channel' },
  { title: 'Target Audience', sortKey: 'TargetAudience' },
  { title: 'Target Status', sortKey: 'TargetStatus' },
  { title: 'Location' },
  { title: 'Volume' },
  { title: 'Sent Date' },
  { title: 'Sent By' },
];

@observer
class MarketingCampaignTable extends React.Component {
  openModal = options => {
    return MarketingCampaignStore.toggleModal(true, options);
  };

  renderState = stateId => {
    switch (stateId) {
      case '108':
        return 'ACT';
      case '109':
        return 'NSW';
      case '110':
        return 'VIC';
      case '111':
        return 'QLD';
      case '112':
        return 'SA';
      case '113':
        return 'WA';
      case '114':
        return 'TAS';
      case '115':
        return 'NT';
      default:
        return '';
    }
  };

  handleDelete = async id => {
    try {
      const respone = await deleteCaseManagerCampaign(id);
      if (respone.status === 'success') {
        MarketingCampaignStore.refetch({});
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Delete Campaign successfully.',
          duration: 3,
        });
      }
    } catch (e) {}
  };

  handleToggleModal = opt => {
    return MarketingCampaignStore.toggleModal(true, opt)();
  };

  handleAddEdit = (record, id) => {
    localStorage.setItem('CampaignName', record.CampaignName);
    dashboardStore.close('/view/add-edit-marketing-campaign');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-marketing-campaign?id=${id}`);
    });
  };

  tripHtmlFromBody = htmlString => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || '';
  };

  renderTableBody = (data, colSpan) => {
    const { targetAudience, targetStatus, channels } = MarketingCampaignStore;
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell>{Id}</Table.Cell>
        <Table.Cell selectable onClick={() => this.handleAddEdit(record, Id)} className="Cell-link">
          <Link to="#" onClick={() => this.handleAddEdit(record, Id)}>
            {record.CampaignName}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.CampaignDate ? moment(formatDate(record.CampaignDate), 'DD MMM YYYY').format('DD/MM/YYYY') : ''}
        </Table.Cell>
        <Table.Cell>{record.ChannelLabel}</Table.Cell>
        <Table.Cell>
          {targetAudience.itemList
            ? targetAudience.itemList.filter(i => i.Id === record.TargetAudience).map(i => i.Label)[0]
            : null}
        </Table.Cell>
        <Table.Cell>
          {targetStatus.itemList
            ? targetStatus.itemList.filter(i => i.Id === record.TargetStatus).map(i => i.Label)[0]
            : null}
        </Table.Cell>
        <Table.Cell>
          {record.Location &&
            record.Location.split(';')
              .map(i => this.renderState(i))
              .join(', ')}
        </Table.Cell>
        <Table.Cell>{record.Volume}</Table.Cell>
        <Table.Cell>
          {record.SaveAndSendDate ? moment(formatDate(record.SaveAndSendDate), 'DD MMM YYYY').format('DD/MM/YYYY') : ''}
        </Table.Cell>
        <Table.Cell>{record.SaveAndSendBy}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => this.handleAddEdit(record, id)}>Edit</Menu.Item>
          <Menu.Item
            onClick={() =>
              this.handleToggleModal({
                modalType: 'confirm',
                onOk: () => this.handleDelete(id),
                message: 'Are you sure to delete this campaign?',
              })
            }
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    MarketingCampaignStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = MarketingCampaignStore;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={MarketingCampaignStore.handleSort}
        />
        <TableBody>{this.renderTableBody(data, columns.length)}</TableBody>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={MarketingCampaignStore.curPage}
          totalPage={MarketingCampaignStore.totalPage}
          totalItems={MarketingCampaignStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default MarketingCampaignTable;
