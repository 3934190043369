import React from 'react';
import { Divider, Icon } from 'semantic-ui-react';

const AnonymousLoading = ({ message = 'Loading' }) => (
  <React.Fragment>
    <Divider />
    <div className="clearfix">
      {message}...
      <Icon className="float-right" name="asterisk" fitted loading />
    </div>
  </React.Fragment>
);

export default AnonymousLoading;
