import { Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';

import SalesTargetStore from './Store';
import SalesTargetTable from './Table';
import SalesTargetToolbar from './Toolbar';
import SalesTargetModal from './Modal';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class SalesTarget extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/sale-target', true);
      return;
    }
    SalesTargetStore.fetchData(true);
  }
  render() {
    const { data, loading, open } = SalesTargetStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <SalesTargetToolbar />
            <SalesTargetTable />
            {open && <SalesTargetModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default SalesTarget;
