import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import TableToolbar from '../../shared/tableToolbar';

import DoctorReportsStore from './DoctorReportsStore';
import DoctorReportsTable from './DoctorReportsTable';

@observer
class ViewHistoryReport extends React.Component {
  openModal = options => {
    return DoctorReportsStore.toggleModal(true, options);
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(DoctorReportsStore.dataDoctor);
    for (var i = 0; i < data.length; i++) {
      if (data[i].FullName !== null) {
        array.push(data[i]);
      }
    }
    return array.map(opt => ({
      text: `${opt.Title || ''} ${opt.FullName || ''}`,
      value: JSON.stringify({
        Title: opt.Title,
        DoctorId: opt.Id,
        FullName: opt.FullName,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const doctor = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (doctor.Title === '' || !doctor.Title) {
      DoctorReportsStore.setFieldsValue({
        dataDoctorReports: null,
        keyword: ``,
        DoctorId: 0,
      });
    } else {
      DoctorReportsStore.refetch(
        {
          doctor,
          keyword: doctor.Title + ' ' + doctor.FullName,
          DoctorId: doctor.DoctorId,
        },
        true,
      );
    }
  };

  handleClear = () => {
    DoctorReportsStore.setFieldsValue({
      dataDoctorReports: null,
      keyword: ``,
      DoctorId: 0,
      fromTo: ``,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { DoctorId } = DoctorReportsStore;
    const { fromDate } = DoctorReportsStore.getDateRange();
    if (DoctorId) {
      DoctorReportsStore.refetch({ curPage: 1 }, true);
    } else {
      if (fromDate) {
        DoctorReportsStore.refetch({ DoctorId: 0, curPage: 1 }, true);
      } else {
        DoctorReportsStore.setFieldsValue({
          dataDoctorReports: null,
          keyword: ``,
          DoctorId: 0,
        });
      }
    }
  };

  handlePrintSearchResult = () => {
    const { dataDoctorReports } = DoctorReportsStore;
    if (dataDoctorReports) {
      var printContents = window.functionGetElementTableForPrint();
      var popupWin = window.open('', '_blank', 'width=800,height=600');
      popupWin.document.open();
      popupWin.document.write(
        '<html><head><link href="~/Content/build/style" rel="stylesheet" /></head><body onload="window.print()">' +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
    }
  };

  handleExportDoctorNationalDirectory = () => {
    window.open(`/report/ExportDoctorWithCombinedClinic`, '_blank');
  };

  handleExportClinictSessionHistory = () => {
    window.open('/Report/ExportClinicSessionHistory');
  };

  handleExportSearchResult = () => {
    const { DoctorId, dataDoctorReports } = DoctorReportsStore;
    const { fromDate, toDate } = DoctorReportsStore.getDateRange();
    const fromDate_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          // .subtract(1, 'd')
          .format('YYYY-MM-DD')
      : null;
    const toDate_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          // .subtract(1, 'd')
          .format('YYYY-MM-DD')
      : null;
    if (dataDoctorReports && dataDoctorReports.itemList.length !== 0) {
      //return DoctorReportsStore.toggleModal(true, {
      //  modalType: 'exportFilters'
      //})();
      window.open(
        '/Report/DownloadDoctorReports?DoctorId=' +
          DoctorId +
          '&From=' +
          encodeURIComponent(fromDate_) +
          '&To=' +
          encodeURIComponent(toDate_),
        '_blank',
      );
    } else {
      return DoctorReportsStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Error in export result. Data does not exist!',
      })();
    }
  };

  handleChangeFromTo = (_, { value }) => {
    DoctorReportsStore.setFieldsValue({ fromTo: value });
  };
  render() {
    const { dataDoctorReports, keyword, loadingDoctor, fromTo } = DoctorReportsStore;
    return (
      <React.Fragment>
        <form className="doctor-report-toolbar">
          <TableToolbar.Container>
            <TableToolbar.Left as="div">
              <TableToolbar.SearchDropdownSpecialist
                loading={loadingDoctor}
                clearable
                selectOnNavigation={false}
                value={keyword}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                className="Toolbar-input"
                selectOnBlur={false}
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
              <TableToolbar.RangePicker
                placeholder={'From appointment date - To appointment date'}
                value={fromTo}
                className="Toolbar-range-picker"
                onChange={this.handleChangeFromTo}
              />
              <TableToolbar.SubmitButton onClick={this.handleSubmit} className="Toolbar-highlight-button px-2">
                Search
              </TableToolbar.SubmitButton>
              <TableToolbar.HighLightButton className="negative" style={{ marginLeft: 5 }} onClick={this.handleClear}>
                Clear
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                className="positive"
                onClick={this.handleExportSearchResult}
                style={{ marginLeft: 5 }}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right></TableToolbar.Right>
          </TableToolbar.Container>
        </form>
        {dataDoctorReports && <DoctorReportsTable />}
      </React.Fragment>
    );
  }
}

export default ViewHistoryReport;
