import './DashboardSidebar.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import siteLogoOld from '../../../assets/images/logo3.png';
import siteLogo from '../../../assets/images/mag-logo4.png';
import siteLogoIVCheck from '../../../assets/images/mag-logo5.png';
import ui from '../../../stores/dashboard';
import SidebarHeader from './SidebarHeader';
import SidebarMenus from './SidebarMenus';
import * as api from '../../../stores/api';

@observer
class DashboardSidebar extends React.Component {
  sSiteInvoiceCheck = () => {
    let hotName = window.location.hostname;
    console.log(hotName);
    if(hotName.includes('invoicecheck.kawaconn.com')){
      return true;
    }
    return false;
  }

  render() {
    const {
      sidebar: { autohide, visible },
      toggleSidebarVisible,
    } = ui;
    return (
      <React.Fragment>
        {/* To show the black backdrop when the sidebar is opening in auto-hide mode */}
        <div className={cx('SidebarAutohideBackdrop', { autohide, visible })} onClick={toggleSidebarVisible} />
        <div className={cx('DashboardSidebar', { autohide, visible })}>
          <SidebarHeader />
          <div className="DashboardSidebar__site-logo">
            <div style={{width: "100%", textAlign: 'center'}}>
            <a
              href={api.isMAG() ? 'https://medicolegalassessmentsgroup.com.au' : 'http://medicolegalexpress.com.au/'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block' }}
              title="Kawaconn MLP"
            >
              <img src={this.sSiteInvoiceCheck() ? siteLogoIVCheck : siteLogo} alt="mag-logo" style={{ width: '50%' }} />
            </a>
            </div>
          </div>
          <SidebarMenus />
        </div>
        {/* To prevent the tooltip show up when animating */}
        <div className={cx('SidebarAnimationBackdrop', { autohide, visible })} />
      </React.Fragment>
    );
  }
}

export default DashboardSidebar;
