import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Header } from 'semantic-ui-react';

import customFetch from '@utils/customFetch';
import { formatDate } from '@utils/functions';
import { RoleOptions } from '@utils/constants';
import Modal from '../../shared/Modal/';
import LoginUserStore from './LoginUserStore';
import * as api from '../../../../src/stores/api';

function ModalAddOrEdit({ open, onCancel, modalParams }) {
  const { record, userId } = modalParams;

  const roles = record ? RoleOptions : [{ key: 'magstaff', text: 'MAG Staff', value: 'magstaff' }];
  const [errorUserName, setErrorUserName] = useState(!record ? 'Please enter user name at least 6 character.' : '');
  const [errorEmail, setErrorEmail] = useState(!record ? 'Please enter email.' : '');
  const [isDisabled, setIsDisabled] = useState(!record ? true : false);

  const [userInfo, setUserInfo] = useState(
    record
      ? {
          Email: record.Email,
          FirstName: record.FirstName,
          Id: userId,
          LastName: record.LastName,
          Roles: record.Roles.map(role => role),
          UserName: record.UserName,
        }
      : {
          Email: ``,
          FirstName: ``,
          Id: '',
          LastName: ``,
          Roles: ['magstaff'],
          UserName: ``,
        },
  );

  useEffect(() => {
    if (userInfo) {
      (async function setDisableFunction() {
        var fieldIsNull = [];
        for (var field in await userInfo) {
          if (field !== `Id`) {
            if (userInfo[field] === ``) {
              fieldIsNull.push(field);
            }
            if (field === `Roles` && userInfo[field].length === 0) {
              fieldIsNull.push(field);
            }
          }
        }
        if (fieldIsNull.length === 0) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      })();
      return () => null;
    }
  }, [userInfo]);

  const handleOnChangeDropDown = (e, { value }) => {
    setUserInfo({ ...userInfo, Roles: value });
  };

  const handleOnChangeUserName = (e, { value }) => {
    if (value) {
      setUserInfo({ ...userInfo, UserName: value });
      setErrorUserName(``);
    } else {
      setUserInfo({ ...userInfo, UserName: value });
      setErrorUserName(`Please enter user name at least 6 character.`);
    }
  };

  const handleOnChangeEmail = (e, { value }) => {
    if (value) {
      setUserInfo({ ...userInfo, Email: value });
      setErrorEmail(``);
    } else {
      setUserInfo({ ...userInfo, Email: value });
      setErrorEmail(`Please enter user name at least 6 character.`);
    }
  };

  const renderAddOrEditForm = () => (
    <div className="ant-modal-addOrEdit">
      <Form size={'small'}>
        <Form.Field>
          <Header size="small">User Info</Header>
          <Divider />
        </Form.Field>
        <Form.Input
          required
          error={
            errorUserName
              ? {
                  content: errorUserName,
                }
              : null
          }
          fluid
          label="User name"
          placeholder="User name"
          value={userInfo.UserName}
          onChange={handleOnChangeUserName}
        />
        <Form.Input
          required
          error={
            errorEmail
              ? {
                  content: errorEmail,
                }
              : null
          }
          fluid
          label="Email"
          placeholder="Email"
          value={userInfo.Email}
          onChange={handleOnChangeEmail}
        />
        <Form.Dropdown
          required
          error={
            userInfo.Roles.length === 0
              ? {
                  content: 'Please select at least one role for this user',
                }
              : null
          }
          fluid
          label="Role"
          placeholder="Role"
          id="form-input-first-name"
          multiple
          search
          selection
          value={userInfo.Roles.map(role => role)}
          onChange={handleOnChangeDropDown}
          options={roles}
        />
        <Form.Input
          required
          error={
            !userInfo.FirstName
              ? {
                  content: 'Please enter first name.',
                }
              : null
          }
          fluid
          label="First Name"
          placeholder="First Name"
          value={userInfo.FirstName}
          onChange={v => setUserInfo({ ...userInfo, FirstName: v.target.value })}
        />
        <Form.Input
          required
          error={
            !userInfo.LastName
              ? {
                  content: 'Please enter surname.',
                }
              : null
          }
          fluid
          label="Surname"
          placeholder="Surname"
          value={userInfo.LastName}
          onChange={v => setUserInfo({ ...userInfo, LastName: v.target.value })}
        />
      </Form>
    </div>
  );

  const handleOnCreateClick = () => async () => {
    LoginUserStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Manager/Save', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ modUserParam: { ...userInfo } }),
      })
        .then(respone => {
          if (respone.status === 'success') {
            onCancel();
          }
        })
        .catch(err => {
          const errObj = JSON.parse(JSON.stringify(err));
          for (var key in errObj) {
            if (key === `UserName`) {
              setErrorUserName(`This user name is not available.`);
            }
            if (key === `Email`) {
              setErrorEmail(`This email is not available.`);
            }
          }
        });
      await LoginUserStore.fetchLoginUser({}, true);
    } finally {
      LoginUserStore.setFieldsValue({ loading: false });
    }
  };

  const handleOnSaveClick = () => async () => {
    LoginUserStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Manager/Save', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          modUserParam: {
            ...userInfo,
            FullName: `${userInfo.FirstName} ${userInfo.LastName}`,
            StatusChangedDate: record.StatusChangedDate ? new Date(formatDate(record.StatusChangedDate)) : null,
            Activated: record.Activated,
            Address: record.Address,
            Birthday: record.Birthday,
            CaseManagerId: record.CaseManagerId,
            CityId: record.CityId,
            CreatedDate: record.CreatedDate ? new Date(formatDate(record.CreatedDate)) : null,
            Gender: record.Gender,
            LastLoginDate: record.LastLoginDate ? new Date(formatDate(record.StatusChangedDate)) : null,
            Postcode: record.Postcode,
            Enabled: record.Enabled,
            District: record.District,
          },
        }),
      })
        .then(respone => {
          if (respone.status === 'success') {
            onCancel();
          }
        })
        .catch(err => {
          const errObj = JSON.parse(JSON.stringify(err));
          for (var key in errObj) {
            if (key === `UserName`) {
              setErrorUserName(`This user name is not available.`);
            }
            if (key === `Email`) {
              setErrorEmail(`This email is not available.`);
            }
          }
        });
      await LoginUserStore.fetchLoginUser({}, true);
    } finally {
      LoginUserStore.setFieldsValue({ loading: false });
    }
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          {!api.isOnlySearch() && <Button color="blue" disabled={isDisabled} onClick={!userId ? handleOnCreateClick() : handleOnSaveClick()}>
            Save
          </Button>}
          <Button className="negative" onClick={onCancel}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      {renderAddOrEditForm()}
    </Modal>
  );
}

export default ModalAddOrEdit;
