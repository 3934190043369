import { Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';

import AMACalculationRuleStore from './Store';
import AMACalculationRuleTable from './Table';
import AMACalculationRuleToolbar from './Toolbar';
import AMACalculationRuleModal from './Modal';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class AMACalculationRule extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/ama-calculation-rule', true);
      return;
    }
    AMACalculationRuleStore.fetchData(true);
  }
  render() {
    const { data, loading, open } = AMACalculationRuleStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <AMACalculationRuleToolbar />
            <AMACalculationRuleTable />
            {open && <AMACalculationRuleModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default AMACalculationRule;
