import React from 'react';
import { Form, Input, Radio, DatePicker, Divider } from 'antd';

class ServiceProvider extends React.Component {
  render() {
    return (
      <Form name="service-provider">
        <Form.Item label="Confirms receipt of request" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Received Date">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Confirms actions taken" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Received Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Confirmation Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Investigation Completed" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Completion Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Completion Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default ServiceProvider;
