import cx from 'classnames';
import React from 'react';
import { Icon } from 'semantic-ui-react';

const CaseProgressCicle = ({ checked, label, date, currentChecked = false }) => {
  return (
    <div className="status-item">
      <div className={cx('status-box', checked ? (currentChecked ? `checked` : `checked2`) : `unchecked`)}>
        <div className="status-box-outer" />
        <div className="status-box-inner">
          {checked && <Icon name="check" className={cx(currentChecked ? `` : `green`)} size="large" />}
        </div>
      </div>
      <span className="status-label">{label}</span>
      <span className="status-date">{date}</span>
    </div>
  );
};

export default CaseProgressCicle;
