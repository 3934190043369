import React from 'react';
import { map, filter } from 'lodash';
import { notification } from 'antd';
import { Button, Segment } from 'semantic-ui-react';
import moment from 'moment';
import Modal from '@components/shared/Modal/';
import customFetch from '@utils/customFetch';
import ActionNoteForm from './ActionNoteForm';
import Store from './Store';

class ActionNotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionNotes: [],
    };
  }

  async componentDidMount() {
    await this.loadItems();
  }

  async loadItems() {
    const mailId = this.props.selectedMail.id;
    const res = await customFetch(`/api/IMSActionNote/GetAll?imsMailId=${mailId}`, {
      method: 'GET',
    });
    if (res.status === 'Success') {
      const actionCount = res.itemList.length;
      const closedAction = filter(res.itemList, a => a.status === 1);
      const closedActionCount = closedAction.length;

      if (actionCount === 0) {
        // If no notes created then open create new note form.
        this.handleOnNewNote();
      } else {
        this.setState({ actionNotes: res.itemList });
      }

      const newList = map(Store.mailData, m => {
        if (m.id === mailId) {
          return { ...m, actionCount, closedActionCount };
        }
        return m;
      });
      const selectedMail = {
        ...Store.selectedMail,
        actionCount,
        closedActionCount,
      };
      Store.setFieldsValue({ mailData: newList, selectedMail });
    }
  }

  handleOnClose = () => {
    Store.setFieldsValue({ isActionNotesModalOpen: false });
  };

  handleOnNewNote = () => {
    const newNote = {
      id: 0,
      imsMailId: this.props.selectedMail.id,
      picId: '',
      closedDate: '',
      commnent: '',
      dueDate: moment(),
      status: 0,
    };

    this.setState({ actionNotes: [newNote, ...this.state.actionNotes] });
  };

  handleOnDelete = id => async () => {
    if (id > 0) {
      const res = await customFetch(`/api/IMSActionNote/${id}`, {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json' },
      });

      notification.destroy();
      if (res.status === 'Success') {
        const newItems = filter(this.state.actionNotes, i => i.id !== id);
        this.setState({ actionNotes: newItems });

        notification.success({
          message: 'Item Deleted Successfully',
        });
      }
    } else {
      const newItems = filter(this.state.actionNotes, i => i.id !== id);
      this.setState({ actionNotes: newItems });
    }
  };

  handleOnSave = item => async () => {
    const model = { ...item };
    if (model.dueDate) {
      model.dueDate = moment(model.dueDate).format();
    }

    const res = await customFetch('/api/IMSActionNote', {
      method: item.id > 0 ? 'PUT' : 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(model),
    });

    notification.destroy();
    if (res.status === 'Success') {
      this.loadItems();
      notification.success({
        message: 'Item Saved Successfully',
      });
      Store.loadAssignedPicList();
    } else {
      notification.error({
        message: 'Cannot save this item',
        description: 'Please check logs for details.',
      });
    }
  };

  render() {
    return (
      <Modal
        visible
        width={600}
        onCancel={this.handleOnClose}
        title="Action Notes"
        footer={
          <React.Fragment>
            <Button className="negative" onClick={this.handleOnClose}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <Segment>
          <Button color="blue" icon="add" content="New Note" onClick={this.handleOnNewNote} />
        </Segment>

        {map(this.state.actionNotes, a => (
          <Segment key={a.id}>
            <ActionNoteForm data={a} onDelete={this.handleOnDelete} onSave={this.handleOnSave} />
          </Segment>
        ))}
      </Modal>
    );
  }
}
export default ActionNotesModal;
