import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import TypeOfReportStore from './TypeOfReportStore';

function TypesOfReportModal() {
  const { modalType } = TypeOfReportStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...TypeOfReportStore} onCancel={TypeOfReportStore.toggleModal(false)} />;
  }
}

export default TypesOfReportModal;
