import customFetch from '../../../utils/customFetch';

export const fetchCalendarList = (
  GoToDate = null,
  clinicId = 0,
  doctorId = 0,
  end = null,
  isHomeVisit = false,
  onlySessions = false,
  onlyTeleAssessment = false,
  start = null,
) => {
  return customFetch('/Calendar/Calendar_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      GoToDate: GoToDate,
      clinicId: clinicId,
      doctorId: doctorId,
      end: end,
      isHomeVisit: isHomeVisit,
      onlySessions: onlySessions,
      onlyTeleAssessment: onlyTeleAssessment,
      start: start,
    }),
  });
};

export const fetchClinic = (dId = 0, keyword = '', numItemPerPage = 30) => {
  return customFetch('/Clinic/GetClinicsByDoctor', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ dId, keyword }),
  });
};

export const fetchAllClinic = (keyword = '', doctorId = 0, numItemPerPage = 30, type = '') => {
  return customFetch('/Clinic/GetClinicList', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      curPage: 1,
      keyword,
      numItemPerPage,
      type,
      doctorId,
    }),
  });
};

export const fetchSpecialists = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/MedicalDoctor/GetAllWithoutNCI', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      RecruitedOrContractedOnly: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage,
    }),
  });
};

export const fetchReportTypesList = () => {
  return customFetch('/Default/GetActiveReportTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getSpecificLookupConfigByType = () => {
  return customFetch('/Clinic/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'ClinicService' }),
  });
};

export const getRooms = clinicId => {
  return customFetch('/Clinic/GetRooms', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId }),
  });
};

export const getAssessmentsAndClinicServiceType = (clinicId, doctorId) => {
  return customFetch('/Calendar/GetAssessmentsAndClinicServiceType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId, doctorId }),
  });
};

export const getClinicServicestype = clinicId => {
  return customFetch(' /Calendar/GetClinicServiceType ', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId }),
  });
};

export const updateClinicServiceType = (selectedClinicServiceTypes, clinicId) => {
  return customFetch('/Calendar/UpdateClinicServiceType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId, selectedClinicServiceTypes }),
  });
};

export const getClientList = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage,
    }),
  });
};

export const getCaseManagerList = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage,
      IsOnboarded: true,
    }),
  });
};

export const fetchClinicDoctors = clinicId => {
  return customFetch('  /MedicalDoctor/GetClinicDoctor', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId }),
  });
};

export const getDoctorInfo = doctorId => {
  return customFetch(`/api/doctorinfo/${doctorId}`, {
    method: 'GET',
  });
};

export const updateDoctorInfo = (doctorId, teleAssessment) => {
  return customFetch('/Calendar/UpdateDoctorInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId, teleAssessment }),
  });
};

export const getDailyBookingsByRoom = (bookingDate, clinicId) => {
  return customFetch('/Calendar/GetClinicBookings', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bookingDate, clinicId }),
  });
};

export const getTableFieldChangeHistory = (tableName, objectId, fieldName, startIndex = 1, maxRecords = 20) => {
  return customFetch(
    '/Calendar/GetTableFieldUpdateHistory?tableName=' +
      tableName +
      '&objectId=' +
      objectId +
      '&fieldName=' +
      fieldName +
      '&startIndex=' +
      startIndex +
      '&maxRecords=' +
      maxRecords,
    {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    },
  );
};
