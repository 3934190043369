import './index.scss';
import cx from 'classnames';
import React from 'react';
import { Accordion, Checkbox, Divider, Icon, Input, Label, Button, Form } from 'semantic-ui-react';
import { action, observable, toJS } from 'mobx';
import customFetch from '../../../utils/customFetch';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';
import TablePIC from './TablePIC';
import TablesActionRequired from './TablesActionRequired';
import ModalActionNotes from './ModalActionNotes';

import imeStore from './StoreIME';
import frStore from './StoreFR';
import suppStore from './StoreSUPP';
import crStore from './StoreCR';
import mnStore from './StoreMN';
import invStore from './StoreINV';
import cmStore from './StoreCM';
import speStore from './StoreSPE';
import clientStore from './StoreClient';

import { observer } from 'mobx-react';
import { getRouteParams } from '@utils/functions';
import router from '../../../stores/router';

@observer
class ActionRequiredSearch extends React.Component {
  @observable accordionItems = [
    {
      title: 'Client',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageClientClick2,
      handlePageClick: this.handlePageClientClick,
      handleSort: this.handleSortClient,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Case Manager',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageCMClick2,
      handlePageClick: this.handlePageCMClick,
      handleSort: this.handleSortCM,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Specialist',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageFRClick2,
      handlePageClick: this.handlePageFRClick,
      handleSort: this.handleSortFR,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'IME Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick: this.handlePageIMEClick,
      handlePageClick2: this.handlePageIMEClick2,
      handleSort: this.handleSortIME,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'File Reviews',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageFRClick2,
      handlePageClick: this.handlePageFRClick,
      handleSort: this.handleSortFR,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Supplementary Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageSUPPClick2,
      handlePageClick: this.handlePageSUPPClick,
      handleSort: this.handleSortSUPP,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Clinical Records',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageCRClick2,
      handlePageClick: this.handlePageCRClick,
      handleSort: this.handleSortCR,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Med Negs',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageMNClick2,
      handlePageClick: this.handlePageMNClick,
      handleSort: this.handleSortMN,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
    {
      title: 'Invoices',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      handlePageClick2: this.handlePageINVClick2,
      handlePageClick: this.handlePageINVClick,
      handleSort: this.handleSortINV,
      curPage2: 1,
      totalPage2: 0,
      sumItem2: 0,
      data2: [],
      openAC: false,
      openPIC: false,
      sortColumn: 'DueDate',
      sortKey: 'DueDate',
      sortDirection: 'descending',
    },
  ];
  @observable noCaseFound = false;
  @observable searchClient = true;
  @observable searchCM = true;
  @observable searchSPE = true;
  @observable searchIME = true;
  @observable searchFr = true;
  @observable searchSupp = true;
  @observable searchCr = true;
  @observable searchMN = true;
  @observable searchINV = true;
  @observable pendingAC = true;
  @observable searchStaffOnly = true;
  @observable loading = false;
  @observable openModal = false;
  @observable staff = '';
  @observable ddlStaff = [];
  @observable modalParams = {};

  state = {
    search: '',
    active: [],
    recentSearch: JSON.parse(localStorage.getItem('MAG/AR_RECENT_SEARCH')) || [],
  };

  @action clientData = (curPage, curPage2) => {
    if (curPage) {
      clientStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      clientStore.StaffId = this.staff;
    }
    if (curPage2) {
      clientStore.curPage2 = curPage2;
    }
    clientStore.searchStaffOnly = this.searchStaffOnly;
    clientStore.pendingAC = this.pendingAC;
    clientStore.keyword = this.state.search;
    clientStore.refetch({ curPage: clientStore.curPage }).then(
      action(() => {
        if (clientStore && clientStore.data && clientStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Client');
          this.accordionItems[idx].data = clientStore.data.itemList;
          this.accordionItems[idx].curPage = clientStore.data.curPage;
          this.accordionItems[idx].totalPage = clientStore.data.sumPage;
          this.accordionItems[idx].sumItem = clientStore.data.sumItem;
          if (clientStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
        this.loading = clientStore.loading;
      }),
    );
  };

  @action cmData = (curPage, curPage2) => {
    if (curPage) {
      cmStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      cmStore.StaffId = this.staff;
    }
    if (curPage2) {
      cmStore.curPage2 = curPage2;
    }
    cmStore.searchStaffOnly = this.searchStaffOnly;
    cmStore.pendingAC = this.pendingAC;
    cmStore.keyword = this.state.search;
    cmStore.refetch({ curPage: cmStore.curPage }).then(
      action(() => {
        if (cmStore && cmStore.data && cmStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Case Manager');
          this.accordionItems[idx].data = cmStore.data.itemList;
          this.accordionItems[idx].curPage = cmStore.data.curPage;
          this.accordionItems[idx].totalPage = cmStore.data.sumPage;
          this.accordionItems[idx].sumItem = cmStore.data.sumItem;
          if (cmStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
        this.loading = cmStore.loading;
      }),
    );
  };

  @action speData = (curPage, curPage2) => {
    if (curPage) {
      speStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      speStore.StaffId = this.staff;
    }
    if (curPage2) {
      speStore.curPage2 = curPage2;
    }
    speStore.searchStaffOnly = this.searchStaffOnly;
    speStore.pendingAC = this.pendingAC;
    speStore.keyword = this.state.search;
    speStore.refetch({ curPage: speStore.curPage }).then(
      action(() => {
        if (speStore && speStore.data && speStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Specialist');
          this.accordionItems[idx].data = speStore.data.itemList;
          this.accordionItems[idx].curPage = speStore.data.curPage;
          this.accordionItems[idx].totalPage = speStore.data.sumPage;
          this.accordionItems[idx].sumItem = speStore.data.sumItem;
          if (speStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
        this.loading = speStore.loading;
      }),
    );
  };

  @action imeData = (curPage, curPage2) => {
    if (curPage) {
      imeStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      imeStore.StaffId = this.staff;
    }
    if (curPage2) {
      imeStore.curPage2 = curPage2;
    }
    imeStore.searchStaffOnly = this.searchStaffOnly;
    imeStore.pendingAC = this.pendingAC;
    imeStore.keyword = this.state.search;
    imeStore.refetch({ curPage: imeStore.curPage }).then(
      action(() => {
        if (imeStore && imeStore.data && imeStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'IME Reports');
          this.accordionItems[idx].data = imeStore.data.itemList;
          this.accordionItems[idx].curPage = imeStore.data.curPage;
          this.accordionItems[idx].totalPage = imeStore.data.sumPage;
          this.accordionItems[idx].sumItem = imeStore.data.sumItem;
          if (imeStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
        this.loading = imeStore.loading;
      }),
    );
    imeStore.fetchFiles2().then(
      action(() => {
        if (imeStore && imeStore.data2 && imeStore.data2.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'IME Reports');
          this.accordionItems[idx].data2 = imeStore.data2.itemList;
          this.accordionItems[idx].curPage2 = imeStore.data2.curPage;
          this.accordionItems[idx].totalPage2 = imeStore.data2.sumPage;
          this.accordionItems[idx].sumItem2 = imeStore.data2.sumItem;
          if (imeStore.data2.sumItem > 0) {
            this.noCaseFound = false;
          }
          this.loading = imeStore.loading;
        }
      }),
    );
  };

  @action crData = (curPage, curPage2) => {
    if (curPage) {
      crStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      crStore.StaffId = this.staff;
    }
    if (curPage2) {
      crStore.curPage2 = curPage2;
    }
    crStore.searchStaffOnly = this.searchStaffOnly;
    crStore.pendingAC = this.pendingAC;
    crStore.keyword = this.state.search;
    crStore.refetch({ curPage: crStore.curPage }).then(
      action(() => {
        if (crStore && crStore.data && crStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Clinical Records');
          this.accordionItems[idx].data = crStore.data.itemList;
          this.accordionItems[idx].curPage = crStore.data.curPage;
          this.accordionItems[idx].totalPage = crStore.data.sumPage;
          this.accordionItems[idx].sumItem = crStore.data.sumItem;
          this.loading = crStore.loading;
          if (crStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
    crStore.fetchFiles2().then(
      action(() => {
        if (crStore && crStore.data2 && crStore.data2.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Clinical Records');
          this.accordionItems[idx].data2 = crStore.data2.itemList;
          this.accordionItems[idx].curPage2 = crStore.data2.curPage;
          this.accordionItems[idx].totalPage2 = crStore.data2.sumPage;
          this.accordionItems[idx].sumItem2 = crStore.data2.sumItem;
          if (crStore.data2.sumItem > 0) {
            this.noCaseFound = false;
          }
          this.loading = crStore.loading;
        }
      }),
    );
  };

  @action suppData = (curPage, curPage2) => {
    if (curPage) {
      suppStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      suppStore.StaffId = this.staff;
    }
    if (curPage2) {
      suppStore.curPage2 = curPage2;
    }
    suppStore.searchStaffOnly = this.searchStaffOnly;
    suppStore.pendingAC = this.pendingAC;
    suppStore.keyword = this.state.search;
    suppStore.refetch({ curPage: suppStore.curPage }).then(
      action(() => {
        if (suppStore && suppStore.data && suppStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Supplementary Reports');
          this.accordionItems[idx].data = suppStore.data.itemList;
          this.accordionItems[idx].curPage = suppStore.data.curPage;
          this.accordionItems[idx].totalPage = suppStore.data.sumPage;
          this.accordionItems[idx].sumItem = suppStore.data.sumItem;
          this.loading = suppStore.loading;
          if (suppStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
    suppStore.fetchFiles2().then(
      action(() => {
        if (suppStore && suppStore.data2 && suppStore.data2.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Supplementary Reports');
          this.accordionItems[idx].data2 = suppStore.data2.itemList;
          this.accordionItems[idx].curPage2 = suppStore.data2.curPage;
          this.accordionItems[idx].totalPage2 = suppStore.data2.sumPage;
          this.accordionItems[idx].sumItem2 = suppStore.data2.sumItem;
          this.loading = suppStore.loading;
          if (suppStore.data2.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
  };

  @action frData = (curPage, curPage2) => {
    if (curPage) {
      frStore.curPage = curPage;
    }
    if (curPage2) {
      frStore.curPage2 = curPage2;
    }
    if (this.searchStaffOnly) {
      frStore.StaffId = this.staff;
    }
    frStore.searchStaffOnly = this.searchStaffOnly;
    frStore.pendingAC = this.pendingAC;
    frStore.keyword = this.state.search;
    frStore.refetch({ curPage: frStore.curPage }).then(
      action(() => {
        if (frStore && frStore.data && frStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
          this.accordionItems[idx].data = frStore.data.itemList;
          this.accordionItems[idx].curPage = frStore.data.curPage;
          this.accordionItems[idx].totalPage = frStore.data.sumPage;
          this.accordionItems[idx].sumItem = frStore.data.sumItem;
          this.loading = frStore.loading;
          if (frStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
    frStore.fetchFiles2().then(
      action(() => {
        if (frStore && frStore.data2 && frStore.data2.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
          this.accordionItems[idx].data2 = frStore.data2.itemList;
          this.accordionItems[idx].curPage2 = frStore.data2.curPage;
          this.accordionItems[idx].totalPage2 = frStore.data2.sumPage;
          this.accordionItems[idx].sumItem2 = frStore.data2.sumItem;
          this.loading = frStore.loading;
          if (frStore.data2.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
  };

  @action mnData = curPage => {
    if (curPage) {
      mnStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      mnStore.StaffId = this.staff;
    }
    mnStore.searchStaffOnly = this.searchStaffOnly;
    mnStore.pendingAC = this.pendingAC;
    mnStore.keyword = this.state.search;
    mnStore.refetch({ curPage: mnStore.curPage }).then(
      action(() => {
        if (mnStore && mnStore.data && mnStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Med Negs');
          this.accordionItems[idx].data = mnStore.data.itemList;
          this.accordionItems[idx].curPage = mnStore.data.curPage;
          this.accordionItems[idx].totalPage = mnStore.data.sumPage;
          this.accordionItems[idx].sumItem = mnStore.data.sumItem;
          this.loading = mnStore.loading;
          if (mnStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
  };

  @action invData = curPage => {
    if (curPage) {
      invStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      invStore.StaffId = this.staff;
    }
    invStore.searchStaffOnly = this.searchStaffOnly;
    invStore.pendingAC = this.pendingAC;
    invStore.keyword = this.state.search;
    invStore.refetch({ curPage: invStore.curPage }).then(
      action(() => {
        if (invStore && invStore.data && invStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Invoices');
          this.accordionItems[idx].data = invStore.data.itemList;
          this.accordionItems[idx].curPage = invStore.data.curPage;
          this.accordionItems[idx].totalPage = invStore.data.sumPage;
          this.accordionItems[idx].sumItem = invStore.data.sumItem;
          this.loading = invStore.loading;
          if (invStore.data.sumItem > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
  };

  @action handleSortCM = ({ sortKey }, { column, direction }) => {
    cmStore.sortColumn = column;
    cmStore.sortDirection = direction;
    cmStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Case Manager');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.cmData();
  };

  @action handleSortSPE = ({ sortKey }, { column, direction }) => {
    speStore.sortColumn = column;
    speStore.sortDirection = direction;
    speStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Specialist');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.speData();
  };

  @action handleSortIME = ({ sortKey }, { column, direction }) => {
    imeStore.sortColumn = column;
    imeStore.sortDirection = direction;
    imeStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'IME Reports');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.imeData();
  };

  @action handleSortClient = ({ sortKey }, { column, direction }) => {
    clientStore.sortColumn = column;
    clientStore.sortDirection = direction;
    clientStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.clientData();
  };

  @action handleSortFR = ({ sortKey }, { column, direction }) => {
    frStore.sortColumn = column;
    frStore.sortDirection = direction;
    frStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.frData();
  };

  @action handleSortSUPP = ({ sortKey }, { column, direction }) => {
    suppStore.sortColumn = column;
    suppStore.sortDirection = direction;
    suppStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Supplementary Reports');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;

    this.suppData();
  };

  @action handleSortCR = ({ sortKey }, { column, direction }) => {
    crStore.sortColumn = column;
    crStore.sortDirection = direction;
    crStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Clinical Records');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;
    this.crData();
  };

  @action handleSortMN = ({ sortKey }, { column, direction }) => {
    mnStore.sortColumn = column;
    mnStore.sortDirection = direction;
    mnStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Med Negs');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;
    this.mnData();
  };

  @action handleSortINV = ({ sortKey }, { column, direction }) => {
    invStore.sortColumn = column;
    invStore.sortDirection = direction;
    invStore.sortKey = sortKey;

    var idx = this.accordionItems.findIndex(i => i.title === 'Invoices');
    this.accordionItems[idx].sortColumn = column;
    this.accordionItems[idx].sortDirection = direction;
    this.accordionItems[idx].sortKey = sortKey;
    this.invData();
  };

  @action handlePageClientClick2 = page => {
    this.loading = true;
    this.clientData(frStore.curPage, page);
  };
  @action handlePageCMClick2 = page => {
    this.loading = true;
    this.cmData(cmStore.curPage, page);
  };
  @action handlePageFRClick2 = page => {
    this.loading = true;
    this.frData(frStore.curPage, page);
  };
  @action handlePageSUPPClick2 = page => {
    this.loading = true;
    this.suppData(suppStore.curPage, page);
  };
  @action handlePageIMEClick2 = page => {
    this.loading = true;
    this.imeData(imeStore.curPage, page);
  };
  @action handlePageCRClick2 = page => {
    this.loading = true;
    this.crData(crStore.curPage, page);
  };
  @action handlePageMNClick2 = page => {
    this.loading = true;
    this.mnData(mnStore.curPage, page);
  };
  @action handlePageINVClick2 = page => {
    this.loading = true;
    this.invData(invStore.curPage, page);
  };

  @action handlePageCMClick = page => {
    this.loading = true;
    this.cmData(page);
  };
  @action handlePageSPEClick = page => {
    this.loading = true;
    this.speData(page);
  };
  @action handlePageClientClick = page => {
    this.loading = true;
    this.clientData(page);
  };
  @action handlePageFRClick = page => {
    this.loading = true;
    this.frData(page);
  };
  @action handlePageSUPPClick = page => {
    this.loading = true;
    this.suppData(page);
  };
  @action handlePageIMEClick = page => {
    this.loading = true;
    this.imeData(page);
  };
  @action handlePageCRClick = page => {
    this.loading = true;
    this.crData(page);
  };
  @action handlePageMNClick = page => {
    this.loading = true;
    this.mnData(page);
  };
  @action handlePageINVClick = page => {
    this.loading = true;
    this.invData(page);
  };
  @action onSearch = () => {
    this.loading = true;
    this.noCaseFound = true;
    if (this.searchClient) {
      this.clientData();
    } else {
      this.clearData('Client');
    }
    if (this.searchCM) {
      this.cmData();
    } else {
      this.clearData('Case Manager');
    }
    if (this.searchIME) {
      this.speData();
    } else {
      this.clearData('Specialist');
    }
    if (this.searchIME) {
      this.imeData();
    } else {
      this.clearData('IME Reports');
    }
    if (this.searchSupp) {
      this.suppData();
    } else {
      this.clearData('Supplementary Reports');
    }
    if (this.searchFr) {
      this.frData();
    } else {
      this.clearData('File Reviews');
    }
    if (this.searchCr) {
      this.crData();
    } else {
      this.clearData('Clinical Records');
    }
    if (this.searchMN) {
      this.mnData();
    } else {
      this.clearData('Med Negs');
    }
    if (this.searchINV) {
      this.invData();
    } else {
      this.clearData('Invoices');
    }
  };

  @action clearData = type => {
    this.accordionItems[this.accordionItems.findIndex(i => i.title === type)].data = [];
    this.accordionItems[this.accordionItems.findIndex(i => i.title === type)].data2 = [];
  };

  @action onClear = () => {
    this.setState({
      search: '',
    });
    this.noCaseFound = false;
    this.searchIME = true;
    this.searchFr = true;
    this.searchSupp = true;
    this.searchCr = true;
    this.searchMN = true;
    this.searchINV = true;
    this.pendingAC = true;
    this.searchStaffOnly = true;
    this.staff = '';
    this.clearData('Case Manager');
    this.clearData('Specialist');
    this.clearData('IME Reports');
    this.clearData('Supplementary Reports');
    this.clearData('File Reviews');
    this.clearData('Clinical Records');
    this.clearData('Med Negs');
    this.clearData('Invoices');
  };

  handleSearchChange = ({ target }) => {
    this.setState({ search: target.value });
  };

  handleSubmit = event => {
    event && event.preventDefault();
    if (this.state.search) {
      this.onSearch();
      this.setState(() => {
        this.handleRecentSearchChange({ newSearch: this.state.search });
      });
    }
  };

  recentSearchHandle = search => () => {
    this.setState({ search }, () => this.onSearch());
  };

  renderRecentSearchList = recentSearch => {
    return recentSearch.map((search, idx) => (
      <Label key={idx}>
        <span onClick={this.recentSearchHandle(search)} className="clickable">
          {search}
        </span>
        <Icon name="delete" onClick={() => this.handleRecentSearchChange({ idx })} />
      </Label>
    ));
  };

  handleRecentSearchChange = ({ idx, newSearch }) => {
    const { recentSearch } = this.state;
    let newRecentSearch;

    if (!!newSearch) {
      newRecentSearch = [...(recentSearch.length < 5 ? recentSearch : recentSearch.slice(1)), newSearch];
    } else {
      newRecentSearch = recentSearch.filter((_, index) => index !== idx);
    }

    this.setState({ recentSearch: newRecentSearch }, () => {
      localStorage.setItem('MAG/AR_RECENT_SEARCH', JSON.stringify(newRecentSearch));
    });
  };

  isActive = index => {
    return this.state.active.includes(index);
  };

  handleToggleActive = index => () => {
    this.setState(({ active, ...prev }) => ({
      ...prev,
      active: this.isActive(index) ? active.filter(idx => idx !== index) : [...active, index],
    }));
  };

  @action componentWillMount() {
    customFetch('/Default/GetStaffs', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }).then(
      action(data => {
        data.itemList.unshift({ Id: 'All', FullName: 'ALL' });
        this.ddlStaff = data.itemList.map(opt => ({
          key: opt.Id,
          text: opt.FullName,
          value: opt.Id,
        }));
      }),
    );
  }
  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.openModal = isOpen;
      this.modalParams = params;
    });
  };

  @action onChangeElement = (e, data) => {
    this[data.name] = data.value;
    if (!data.value) {
      return this.onClear();
    }

    this.onSearch();
  };

  @action componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/action-required-search', true);
      return;
    }

    const { search } = getRouteParams(router.location.search, router.location.state);
    if (search && search.length) {
      this.searchStaffOnly = false;
      this.searchClient = true;
      this.searchCM = true;
      this.searchSPE = true;
      this.searchIME = true;
      this.searchFr = true;
      this.searchSupp = true;
      this.searchCr = true;
      this.searchMN = true;
      this.searchINV = true;
      this.setState({ search }, () => this.handleSubmit());
    }
  }
  render() {
    const { search, recentSearch } = this.state;
    const loading = this.loading;
    return (
      <div className={cx('AC-search-page mb-1', { loading })}>
        <h1 className="search-title">Search Action Required & Next Task PICs</h1>
        <form className="search-form" onSubmit={this.handleSubmit}>
          <div className="inline">
            <div style={{ width: '150px', marginTop: '10px' }}>
              <Checkbox
                label="Search Staff Only"
                name="searchStaffOnly"
                checked={this.searchStaffOnly}
                onChange={action(() => (this.searchStaffOnly = !this.searchStaffOnly))}
              />
            </div>
            {this.searchStaffOnly && (
              <div className="search-input">
                <Form.Dropdown
                  disabled={loading}
                  loading={loading}
                  fluid
                  placeholder="Search Staff Only"
                  search
                  selection
                  name="staff"
                  value={this.staff}
                  onChange={this.onChangeElement}
                  options={toJS(this.ddlStaff)}
                  clearable={true}
                />
              </div>
            )}
            {!this.searchStaffOnly && (
              <Input
                fluid
                icon="search"
                value={search}
                onChange={this.handleSearchChange}
                placeholder="Search any keyword"
                loading={loading}
                disabled={loading}
                className="search-input"
                iconPosition="left"
                label={
                  search.length ? <Button className="clear" type="button" icon="times" onClick={this.onClear} /> : null
                }
                labelPosition={search.length ? 'right' : null}
              />
            )}
          </div>
          <div className="form-checkboxes">
            {/* {!api.isDoctor() &&
              (<Checkbox label="Doctors" checked />)
            } */}
            <Checkbox
              label=" Client"
              name="searchClient"
              checked={this.searchClient}
              onChange={action(() => (this.searchClient = !this.searchClient))}
            />
            <Checkbox
              label="Case Managers"
              name="searchCM"
              checked={this.searchCM}
              onChange={action(() => (this.searchCM = !this.searchCM))}
            />
            <Checkbox
              label="Specialists"
              name="searchSPE"
              checked={this.searchSPE}
              onChange={action(() => (this.searchSPE = !this.searchSPE))}
            />
            <Checkbox
              label="Invoices"
              name="searchINV"
              checked={this.searchINV}
              onChange={action(() => (this.searchINV = !this.searchINV))}
            />
          </div>
          <div className="form-checkboxes">
            <Checkbox
              label="IME Reports"
              name="searchIME"
              checked={this.searchIME}
              onChange={action(() => (this.searchIME = !this.searchIME))}
            />
            <Checkbox
              label="File Reviews"
              name="searchFr"
              checked={this.searchFr}
              onChange={action(() => (this.searchFr = !this.searchFr))}
            />
            <Checkbox
              label="Supplementary Reports"
              name="searchSupp"
              checked={this.searchSupp}
              onChange={action(() => (this.searchSupp = !this.searchSupp))}
            />
            <Checkbox
              label="Clinical Records"
              name="searchCr"
              checked={this.searchCr}
              onChange={action(() => (this.searchCr = !this.searchCr))}
            />
            <Checkbox
              label="Med Negs"
              name="searchMN"
              checked={this.searchMN}
              onChange={action(() => (this.searchMN = !this.searchMN))}
            />
          </div>
          <div className="form-checkboxes checkboxes-pending">
            <Checkbox
              label="Include pending actions only"
              name="pendingAC"
              checked={this.pendingAC}
              onChange={action(() => (this.pendingAC = !this.pendingAC))}
            />
          </div>
          <div className="recent-search">
            <h4>Recent searches:</h4>
            <div className="recent-search-list">
              {!recentSearch.length ? (
                <div className="recent-search-emtpy-msg">No recent search</div>
              ) : (
                this.renderRecentSearchList(recentSearch)
              )}
            </div>
          </div>
          {this.noCaseFound && !this.loading && (
            <div className="recent-search">
              <div className="recent-search-list">
                <div className="recent-search-emtpy-msg">
                  <h2>NO RESULTS</h2>
                </div>
              </div>
            </div>
          )}
        </form>
        <Accordion fluid className="mt-3">
          {this.accordionItems.map(
            (
              {
                title,
                data,
                curPage,
                totalPage,
                sumItem,
                handlePageClick,
                data2,
                curPage2,
                totalPage2,
                sumItem2,
                handlePageClick2,
                openAC,
                openPIC,
                handleSort,
                sortColumn,
                sortKey,
                sortDirection,
              },
              idx,
            ) => (
              <React.Fragment key={idx}>
                {(data.length > 0 || data2.length > 0) && (
                  <React.Fragment>
                    <Accordion.Title
                      index={idx}
                      className={cx('py-0', { 'mt-3': idx > 0 })}
                      active={this.isActive(idx)}
                      onClick={this.handleToggleActive(idx)}
                    >
                      <h3 className="mb-0">
                        <Icon name="dropdown" />
                        {title} ({sumItem + sumItem2})
                      </h3>
                    </Accordion.Title>
                    <Divider />
                    <Accordion.Content active={this.isActive(idx)}>
                      {data && data.length > 0 && (
                        <React.Fragment>
                          <Accordion.Title
                            index={idx}
                            className="mg-l-20"
                            active={openAC}
                            onClick={action(() => (this.accordionItems[idx].openAC = !this.accordionItems[idx].openAC))}
                          >
                            <h3 className="mb-0">
                              <Icon name="dropdown" />
                              Action Required ({sumItem})
                            </h3>
                          </Accordion.Title>
                          <Accordion.Content active={this.accordionItems[idx].openAC}>
                            <TablesActionRequired
                              sortColumn={sortColumn}
                              sortKey={sortKey}
                              sortDirection={sortDirection}
                              data={data}
                              curPage={curPage}
                              totalPage={totalPage}
                              sumItem={sumItem}
                              handlePageClick={handlePageClick}
                              toggleModal={this.toggleModal}
                              handleSort={handleSort}
                              title={title}
                            />
                          </Accordion.Content>
                        </React.Fragment>
                      )}

                      {/* ================================================================ */}

                      {data2 && data2.length > 0 && (
                        <React.Fragment>
                          <Accordion.Title
                            index={idx}
                            className="mg-l-20"
                            active={openPIC}
                            onClick={action(
                              () => (this.accordionItems[idx].openPIC = !this.accordionItems[idx].openPIC),
                            )}
                          >
                            <h3 className="mb-0">
                              <Icon name="dropdown" />
                              Next Task PICs ({sumItem2})
                            </h3>
                          </Accordion.Title>
                          <Accordion.Content active={this.accordionItems[idx].openPIC}>
                            <TablePIC
                              data={data2}
                              curPage={curPage2}
                              totalPage={totalPage2}
                              sumItem={sumItem2}
                              handlePageClick={handlePageClick2}
                              title={title}
                            />
                          </Accordion.Content>
                        </React.Fragment>
                      )}
                    </Accordion.Content>
                  </React.Fragment>
                )}
              </React.Fragment>
            ),
          )}
        </Accordion>
        {this.openModal && (
          <ModalActionNotes
            modalParams={this.modalParams}
            toggleModal={this.toggleModal}
            open={this.openModal}
            refetch={this.onSearch}
          />
        )}
      </div>
    );
  }
}

export default observer(ActionRequiredSearch);
