import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import typesOfReportStore from './typesOfReportStore';
import router from '../../../stores/router';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'NAME', sortKey: 'Name' },
  { title: 'REPORT DUE' },
  { title: 'STATUS', sortKey: 'IsActive' },
  { title: 'DESCRIPTION' },
];

@observer
class TypesOfReportTable extends React.Component {
  openModal = options => {
    return typesOfReportStore.toggleModal(true, options);
  };

  openCaseDetail = (id, name) => () => {
    localStorage.setItem('TypeOfReport', name);
    iframe.forceOpenTab('PostTypesOfReport', `?id=${id}&view=PostTypesOfReport`, {
      id,
      view: 'PostTypesOfReport',
    });
  };

  onOpenCaseDetail = (record, id) => () => {
    localStorage.setItem('TypeOfReport', record.Name);
    router.history.push({
      pathname: '/view/add-edit-type-of-report-2',
      state: {
        record,
      },
      search: `?id=${id}`,
    });
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell className="Cell-nowrap Cell-link">{Id}</Table.Cell>
        <Table.Cell
          selectable
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
          onClick={this.onOpenCaseDetail(record, Id)}
        >
          <Link
            onClick={this.onOpenCaseDetail(record, Id)}
            to={{
              pathname: '/view/add-edit-value-setting',
              state: {
                record,
                type: 'typeOfReport',
              },
              search: `?id=${Id}`,
            }}
          >
            {record.Name}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ReportDueDay}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
      </Table.Row>
    ));
  };

  handleDelete = id => async () => {
    typesOfReportStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/TypesOfReport/DeleteRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await typesOfReportStore.refetch({}, true);
    } finally {
      typesOfReportStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = (id, record) => async () => {
    typesOfReportStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/TypesOfReport/EditItemStatus', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });
      await typesOfReportStore.refetch({}, true);
    } finally {
      typesOfReportStore.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.onOpenCaseDetail(record, id)}>Edit</Menu.Item>}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    typesOfReportStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = typesOfReportStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={typesOfReportStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(data, colSpan)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={typesOfReportStore.curPage}
          totalItems={typesOfReportStore.totalItems}
          totalPage={typesOfReportStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default TypesOfReportTable;
