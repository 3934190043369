import moment from 'moment';
import * as api from '@stores/api';
import { AllowedFileExtensions, AUDIO_FILE_EXTENSION } from '@utils/constants';

export const AssessmentReportType = [
  {
    Paperwork: 1,
    Dictation: 2,
    Draft: 3,
    Completed: 4,
    AmendedReportCompleted: 5,
    ConsentForm: 6,
    EassessClaimantLocationChecklist: 7,
    EassessDoctorLocationChecklist: 8,
    PaperworkOther: 9,
    InvoiceAttachment: 10,
    PaymentRequestedAttachment: 11,
    PrepaymentPaidAttachment: 12,
    DictationInstruction: 13,
    DoctorSample: 14,
    DoctorReportTemplate: 15,
    PaperworkExLink: 16, //Mising field
    PaperworkOtherExLink: 17, //Mising field
    ReportReturnedFromDoctor: 18,
    TypistReport: 19,
    WiroFundingApproval: 20,
  },
];

export const LOCAL_CONSTANT = {
  ReportType_AmendedReportCompleted: 5,
  ReportType_ClientPrepaymentPaid: 21,
  ReportType_Completed: 4,
  ReportType_ConsentForm: 6,
  ReportType_Dictation: 2,
  ReportType_DictationInstruction: 13,
  ReportType_DoctorReportTemplate: 15,
  ReportType_DoctorTemplate: 14,
  ReportType_Draft: 3,
  ReportType_EassessClaimantLocationChecklist: 7,
  ReportType_EassessDoctorLocationChecklist: 8,
  ReportType_InvoiceAttachment: 10,
  ReportType_Paperwork: 1,
  ReportType_PaperworkOther: 9,
  ReportType_PaymentRequested: 11,
  ReportType_PrepaymentPaid: 12,
  ReportType_ReportReturnedFromDoctor: 18,
  ReportType_TypistReport: 19,
  ReportType_WiroFundingApproval: 20,
  ReportType_AppointmentConfirmationClaimant: 22,
  ReportType_AppointmentConfirmationCM: 23,
  ReportType_AppointmentConfirmationSpecialist: 24,
  UrgentReportCMAuthorisation: 25,
};


export const UPLOAD_FILE_RULE = [
  {
    reportType: [1],
    rule: {
      maxSize: '50MB',
      fileExtension: 'application/pdf',
      multiple: false,
    },
  },
  {
    reportType: [2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 18, 19, 20, 9],
    rule: {
      maxSize: '50MB',
      fileExtension: AllowedFileExtensions,
      multiple: true,
    },
  },
];

export const ASSESSMENT_INFO = {
  ActionCompleted: false,
  ActionNotes: null,
  ActionRequired: true,
  ActionRequiredDueDate: null,
  ActionRequiredType: 0,
  ActionRequiredUserName: null,
  ActionSubject: null,
  AdminExtraFeeAmount: 0,
  AdminExtraFeeTaxPercentage: 0,
  AllowDoctorToViewPaperwork: false,
  IsUpdatedPaperwork: false,
  AppointmentComment: null,
  AppointmentConfirmedDate: null,
  Appointments: [],
  AssessmentAttachment: [],
  AssessmentMethod: false,
  AssessmentMethodType: 'MAGVideo',
  AssessmentNumber: null,
  AssessmentServiceItems: [],
  Attendance: 0,
  IsAllowReschedule: true,
  AttendanceComment: null,
  AttendedDate: null,
  AuthorisedPurchaseAmount: null,
  AutoRemindReportCompletionDeadlineToDoctor: false,
  AutoRemindReportDraftCompletionDeadlineToMagStaff: false,
  AutoSendVideoLink: false,
  BilledDate: null,
  BodyParts: [],
  BookingSolicitor: null,
  BookingSolicitorId: null,
  CReference: null,
  CReferenceId: null,
  CancelledDate: null,
  CaseManager: null,
  CaseManagerId: null,
  ClaimNo: null,
  ClaimReqBookingComment: null,
  ClaimReqDateFrom: null,
  ClaimReqDateTo: null,
  ClaimReqLocation: null,
  ClaimReqSpecialty: null,
  ClaimType: null,
  ClaimantStatus: false,
  ClientRequestNo: null,
  Clinic: null,
  ClinicId: 0,
  ClinicOption: null,
  ClinicRoomId: 0,
  Comments: null,
  CommissionAmount: null,
  CommissionAmountGstNilEnabled: false,
  CommissionOnTopAmount: 0,
  CommissionOnTopRate: 0,
  CommissionRate: null,
  CommissionRateType: 0,
  CompletedDate: null,
  ConsentFormReceived: false,
  ConsentFormReceivedDate: null,
  Customer: null,
  CustomerId: null,
  DateFrom: null,
  DateTo: null,
  DatereadyCreateInvoice: null,
  Description: null,
  DictationComment: null,
  DictationOnedriveLinkFile: null,
  DictationOption: 1,
  DictationReceived: false,
  DictationReceivedDate: null,
  DictationSentToTypist: false,
  DictationTypistID: null,
  Doctor: null,
  DoctorAutoSendVideoLink: false,
  DoctorEassessUrl: null,
  DoctorId: null,
  DoctorInvoiced: false,
  DoctorInvoicedDate: null,
  EassessClaimantAddress: null,
  EassessClaimantEmail: null,
  EassessClaimantLocationChecklistReceived: false,
  EassessClaimantLocationChecklistReceivedDate: null,
  EassessClaimantLocationConfirmed: false,
  EassessClaimantLocationConfirmedDate: null,
  EassessClaimantLocationType: '0',
  EassessClaimantPhone: null,
  EassessDoctorAddress: null,
  EassessDoctorEmail: null,
  EassessDoctorLocationChecklistReceived: false,
  EassessDoctorLocationChecklistReceivedDate: null,
  EassessDoctorLocationConfirmed: false,
  EassessDoctorLocationConfirmedDate: null,
  EassessDoctorLocationType: '0',
  EassessDoctorPhone: null,
  EassessUrl: null,
  BackupEassessUrl: null,
  UseBackupLink: null,
  BackupIdRoom: null,
  BackupPasscode: null,
  EmailInvoiceToMAG: false,
  EmailInvoiceToMAGDateSent: null,
  EmailInvoiceToMAGStaffSent: null,
  EmailPaymentReceiptToDoctor: false,
  EmailPaymentReceiptToDoctorDateSent: null,
  EmailReportDraftToDoctor: false,
  EmailReportDraftToDoctorBE: false,
  EmailReportDraftToDoctorByUser: '',
  EmailReportDraftToDoctorDateSent: null,
  EmailReportDraftToDoctorPE: false,
  EmailToCMConfirmAppointmentByUser: '',
  EmailToCMSolicitorViewReportRecent: null,
  EmailToCMSolicitorViewReportRecentByUser: null,
  EmailToCMsViewReportRecent: null,
  EmailToCMsViewReportRecentByUser: '',
  EmailToDoctorConfirmAppointmentByUser: '',
  EmailToPaperworkReceivedRecent: null,
  EmailToPaperworkReceivedRecentByUser: '',
  IsAllowSpecialistViewReport: false,
  SentByAllowSpecialistViewReport: '',
  SentDateAllowSpecialistViewReport: null,
  EmployerOfClaimant: null,
  ExcludeCM: false,
  ExcludeCMEmail: false,
  ExcludeDoctor: false,
  ExcludeDoctorEmail: false,
  ExcludePatient: false,
  ExcludePatientEmail: false,
  F_RDCCaseManagerId: null,
  F_RDCEmail: null,
  F_RDCSameBookingCM: false,
  F_RDCfirstName: null,
  F_RDClastName: null,
  FeedbackImproveService: null,
  HasInterpretationService: false,
  HasInterpreter: null,
  HasOtherFee: false,
  HasSendClaimantEassessLink: false,
  HasSendDoctorEassessLink: false,
  HomeVisitAddress: null,
  IMSApprovalCode: null,
  Id: 0,
  IlarsRef: null,
  IlarsWiroSolicitor: null,
  IlarsWiroSolicitorObj: null,
  IlarsWiroSolicitorOld: null,
  IncludeAdminExtraFeeToDoctorCommission: false,
  IncludeManualFee1ToDoctorCommission: false,
  IncludeManualFee2ToDoctorCommission: false,
  IncludeServiceItemFeeToDoctorCommission: false,
  InjuriedDate: null,
  InjuryDescription: null,
  InquiryDate: moment(),
  InquiryReceived: true,
  InsuranceClaimNo: null,
  InterpretationServiceClient: null,
  InterpretationServiceClientId: null,
  InterpretationServiceConfirmed: false,
  InterpretationServiceConfirmedDateTime: null,
  InterpretationStatus: 0,
  Interpreter: false,
  InterpreterAdditionalInformation: null,
  InterpreterConfirmedBy: '',
  InterpreterConfirmedDateTime: null,
  InterpreterEassessUrl: null,
  InterpreterEmail: null,
  InterpreterFirstName: null,
  InterpreterLanguage: null,
  InterpreterLastName: null,
  InterpreterMobile: null,
  InterpreterPhone: null,
  InterpreterStatusCancelledDate: null,
  InterpreterStatusReasonCancellation: null,
  InvoiceDescription: null,
  InvoiceId: 0,
  IsAdHoc: false,
  IsAllowCMSolicitorViewAmendedReport: false,
  IsAllowCMSolicitorViewReport: false,
  IsAllowCMViewAmendedReport: false,
  IsAllowCMViewReport: false,
  IsAppointmentConfirmed: false,
  IsBilled: false,
  IsInvoiceUrgentReport: false,
  IsLongPaperwork: false,
  IsPaymentRequested: false,
  IsPrepaymentPaid: false,
  IsRequiredForThisBooking: false,
  IsSendPrepaymentPaidToProvider: false,
  LCF: false,
  Language: null,
  MagAccountManagerId: null,
  MagBookingManagerId: null,
  MasterClient: null,
  MasterClientId: null,
  MedNegDoctorId: 0,
  MedNegId: null,
  MedNegNumber: '',
  NonAttendedDate: null,
  NotifyClaimantConfirmedAppointment: false,
  NotifyStaffPICAppointmentConfirmed: false,
  NotifyClaimantConfirmedAppointmentByUser: '',
  NotifyCmAboutAppointmentCancellation: false,
  NotifyCmAboutDidNotAttendCase: false,
  NotifyCmAppointmentConfirmed: false,
  NotifyCmAppointmentInquiry: false,
  NotifyStaffPICAppointmentInquiry: false,
  NotifyCmSolicitorAboutDidNotAttendCase: false,
  NotifyCmSolicitorCancellation: false,
  NotifyDoctorAppointment: false,
  NotifyDoctorAppointmentConfirmed: false,
  NotifyDoctorAppointmentInquiry: false,
  NotifyCancelDoctorAM: false,
  OldBilled: false,
  OriginalMAGInfo: null,
  OriginalMAGInfo2: null,
  OriginalMAGInfoTxt: null,
  OriginalMAGInfoTxt2: null,
  OtherFee1Description: null,
  OtherFee1Label: null,
  OtherFee1TaxPercentage: 0,
  OtherFee1Value: 0,
  OtherFee2Description: null,
  OtherFee2Label: null,
  OtherFee2TaxPercentage: 0,
  OtherFee2Value: 0,
  OtherMethodSendingDate: null,
  PCaseManager: null,
  PCaseManagerId: null,
  PCustomer: null,
  PCustomerId: null,
  PaperWorkReceived: false,
  PaperworkComment: null,
  PaperworkExLink: '',
  PaperworkOtherExLink: '',
  PaperworkReceivedDate: null,
  Patient: null,
  PatientId: null,
  PaymentRequestedAttachment: null,
  PaymentRequestedDate: null,
  PaymentRequestedInvoiceAmount: null,
  PaymentRequestedInvoiceNo: null,
  PaymentRequestedInvoiceTax: null,
  PrepaymentPaidAttachment: null,
  PrepaymentPaidComment: null,
  PrepaymentPaidDate: null,
  PrepaymentRequired: false,
  PrepaymentRequiredDate: null,
  PurchaseOrderNo: null,
  ReadyCreateInvoice: false,
  ReasonCancellation: 0,
  ReportCompleted: false,
  ReportCompletedDate: null,
  ReportCompletedSentDate: null,
  ReportDraftComment: null,
  ReportDraftReceived: false,
  ReportDraftReceivedDate: null,
  ReportDraftSentDate: null,
  ReportReturnedFromDoctor: false,
  ReportSamplesAttachment: null,
  ReportTemplatesAttachment: null,
  ReportType: null,
  RequestExpectedCompletionDate: null,
  RoomName: '',
  SI_SameBookingCM: false,
  SI_SameClientAccEmail: false,
  S_RDCCaseManagerId: null,
  S_RDCEmail: null,
  S_RDCfirstName: null,
  S_RDClastName: null,
  SelectedDoctorProviderNoIdx: null,
  SelectedPaperworkToDoctorBies: [],
  SelectedSendInterpretation: false,
  SendDateEassessToClaimant: null,
  SendDateEassessToDoctor: null,
  SendDateEassessUrlToInterpreter: null,
  SendDateNotifyClaimantConfirmedAppointment: null,
  SendEassessToClaimantByUser: '',
  SendEassessToDoctorByUser: '',
  SendEassessUrlToClaimantLocation: false,
  SendEassessUrlToDoctorLocation: false,
  SendEassessUrlToInterpreter: false,
  SendEassessUrlToInterpreterByUser: null,
  SendInterpretationBy: '',
  SendInterpretationDateTime: null,
  SendInvoice: null,
  SendInvoice2: null,
  SendToWIRO: false,
  SentAmendedReportsByUser: '',
  SentAmendedReportsDate: null,
  SentByIsRequiredForThisBooking: null,
  SentByNotifyBookingCMSolicitorCancellation: null,
  SentByNotifyCmAboutDidNotAttendCase: null,
  SentByNotifyCmSolicitorAboutDidNotAttendCase: null,
  SentByNotifyDoctorAppointmentCancellation: null,
  SentDateEmailToCMConfirmAppointment: null,
  SentDateEmailToDoctorConfirmAppointment: null,
  SentDateIsRequiredForThisBooking: null,
  SentDateNotifyBookingCMAppointmentCancellation: null,
  SentDateNotifyBookingCMSolicitorCancellation: null,
  SentDateNotifyCMAppointmentInquiry: null,
  SentDateNotifyCmAboutDidNotAttendCase: null,
  SentDateNotifyCmSolicitorAboutDidNotAttendCase: null,
  SentDateNotifyDoctorAppointmentCancellation: null,
  SentDateNotifyDoctorAppointmentInquiry: null,
  SentDateToTypist: null,
  SentSolicitorAmendedReportsByUser: null,
  SentSolicitorAmendedReportsDate: null,
  SentToTypist: false,
  SentToTypistByUser: '',
  SessionAcceptsType: 0,
  SessionId: null,
  SpecialtyMedNegId: null,
  StaffId: null,
  Status: null,
  SubBodyParts: [],
  SubCaseManager1: null,
  SubCaseManager1Id: null,
  SubCaseManager2: null,
  SubCaseManager2Id: null,
  SubCaseManager3: null,
  SubCaseManager3Id: null,
  TaxTotal: 0,
  Total: 0,
  TotalAmountForCommissionCalculation: 0,
  TotalAmountForCommissionCalculationTax: 0,
  TotalWithoutTax: 0,
  Typist: null,
  TypistApproverdBy: null,
  TypistApproverdOn: null,
  TypistSubmittingDate: null,
  TypistTranscribingTime: null,
  UrgentReportCompleted: false,
  UrgentReportCompletedDate: null,
  UrgentReportStaffId: null,
  UserReadyCreateInvoice: null,
  WifroIlars: false,
  copy: false,
  isLocked: false,
  showHistoryAssessment: false,
  SpecialistNotes: null,
  SendInterpreterRqtoOperator: false,
  SendInterpreterRqtoOperatorDateTime: null,
  SendInterpreterRqtoOperatorBy: null,
  CMNotes: null,
  NotifyCorrectedReportToStaff: false,
  SentDateNotifyCorrectedReportToStaff: null,
  SentByNotifyCorrectedReportToStaff: null,
  NotifyCorrectedReportToSpecialist: false,
  SentDateNotifyCorrectedReportToSpecialist: null,
  SentByNotifyCorrectedReportToSpecialist: null,
  SendInterpreterRqtoProvider: false,
  SentDateInterpreterRqtoProvider: null,
  SentByInterpreterRqtoProvider: null,
  InterpretationServiceClientEmail: null,
  ShowConfirmGazettedRateOfDoctorRate: false,
  UrgentReportReportCompletionDate: null,
  UrgentReportConfirmedByCM: false,
  UrgentReportConfirmedByCMDate: null,
  UrgentReportConfirmedByCMBy: null,
  NotifyUrgentReportToStaff: false,
  NotifyUrgentReportToStaffDate: null,
  NotifyUrgentReportToStaffBy: null,
  NotifyUrgentReportToSpecialist: false,
  NotifyUrgentReportToSpecialistDate: null,
  NotifyUrgentReportToSpecialistBy: null,
  NotifyUrgentReportToCM: false,
  NotifyUrgentReportToCMDate: null,
  NotifyUrgentReportToCMBy: null,
  RequestAcceptedBySpecialist: false,
  RequestAcceptedBySpecialistDate: null,
  RequestAcceptedBySpecialistBy: null,
  IROFundingApproved: false,
  IROFundingApprovalDate: null,
  IROFundingApprovedDate: null,
  IROFundingApprovedBy: null,
  UrgentReportType: 0,
  UpdatedDate: moment(),
  IcareClaim: false,
  ReasonReferral: null,
  CompanyInsurer: null,
  ActualDateFrom: null,
  ActualDateTo: null,
  ActualTimeZone: null,
  CausationQualityCheck: 0,
  CausationQualityCheckComment: null,
  SendCausationQualityToDoctor: false,
  SentDateCausationQualityToDoctor: null,
  SentByCausationQualityToDoctor: null
};

export const ASSESSMENT_STATUS = {
  allowCmToViewCompletedReports: false,
  allowDoctorToViewPaperwork: false,
  appointmentConfirmedDate: null,
  assessmentId: 0,
  attended: false,
  attendedDate: null,
  autoRemindReportCompletionDeadlineToDoctor: false,
  autoRemindReportDraftCompletionDeadlineToMagStaff: false,
  billedById: null,
  billedDate: null,
  billingStatus: 0,
  cancelledDate: null,
  dictationReceived: false,
  dictationReceivedDate: null,
  f_RDCEmail: null,
  f_RDCSameBookingCM: false,
  f_RDCfirstName: null,
  f_RDClastName: null,
  fullyPaidDate: null,
  inquiryReceived: false,
  inquiryReceivedDate: null,
  isAppointmentConfirmed: false,
  isBilled: false,
  isCancelledLcf: false,
  isCancelledNoFee: false,
  isFullyPaid: false,
  isPartiallyPaid: false,
  notAttended: false,
  notAttendedDate: null,
  paperworkReceived: false,
  paperworkReceivedDate: null,
  partiallyPaidDate: null,
  pics: null,
  prepaymentPaid: false,
  prepaymentPaidDate: null,
  prepaymentRequired: false,
  prepaymentRequiredDate: null,
  reasonCancellation: 0,
  reportCompleted: false,
  reportCompletedDate: null,
  reportCompletedSentDate: null,
  reportDraftReceived: false,
  reportDraftReceivedDate: null,
  reportReturnedFromDoctor: false,
  sI_SameBookingCM: false,
  sI_SameClientAccEmail: false,
  s_RDCEmail: null,
  s_RDCfirstName: null,
  s_RDClastName: null,
  status: null,
  statusDate: null,
};

export const CONSTANTS = {
  Attendance_Attended: 1,
  Attendance_Cancelled: 3,
  Attendance_CancelledSpecialistTBP: 8,
  Attendance_LCF: 4,
  Attendance_None: 0,
  Attendance_NotAttended: 2,
  RequestCancelled: 6,
  RequestResolved: 7,
  TypeCallerReceiver: {
    CaseManager: 1,
    Provider: 2,
    Staff: 3,
  },
  CancelledSpecialistTBP: 8,
};

export const ACTION_TYPE = {
  None: 0,
  OtherAction: 1,
  UrgentReport: 2,
};

export const TITLE_LIST = [
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Master', label: 'Master' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
];

export const MAG_QUESTIONS = [
  {
    label: '-Select answer-',
    value: '',
  },
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Internet search',
    value: '3',
  },
  {
    label: 'Colleague',
    value: '4',
  },
  {
    label: 'Word of mouth',
    value: '5',
  },
  {
    label: 'Others',
    value: '6',
  },
];

export const DOCTOR_QUESTIONS = [
  {
    label: '-Select answer-',
    value: '',
  },
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Previously used specialist',
    value: '3',
  },
  {
    label: 'Others',
    value: '4',
  },
];

export const BODY_CONVERSATIONLOG = {
  AssessmentId: 0,
  CallerSender: null,
  CallerSenderType: 0,
  Content: null,
  ConversationType: 0,
  Id: 0,
  Receiver: null,
  ReceiverType: 1,
  SentDate: null,
  Subject: null,
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: null,
  hour: 0,
  minute: 0,
  receiverId: null,
  ContentCategory: 0,
  IsAutoEmail: false,
};

export const IME_STATUS_LOGS = [
  {
    id: api.imeStatus.IME_REQUESTED,
    key: api.imeStatus.IME_REQUESTED,
    group: 'Booking & Paperwork',
  },
  {
    id: api.imeStatus.APPOINTMENT_CONFIRMED,
    key: api.imeStatus.APPOINTMENT_CONFIRMED,
    group: 'Booking & Paperwork',
  },
  {
    id: api.imeStatus.PAPERWORK_RECEIVED,
    key: api.imeStatus.PAPERWORK_RECEIVED,
    group: 'Booking & Paperwork',
  },
  {
    id: api.imeStatus.ATTENDED,
    key: api.imeStatus.ATTENDED,
    group: 'Attendance',
  },
  {
    id: api.imeStatus.NOT_ATTEND,
    key: api.imeStatus.NOT_ATTEND,
    group: 'Attendance',
  },
  {
    id: api.imeStatus.DICTATION_RECEIVED,
    key: api.imeStatus.DICTATION_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.imeStatus.REPORT_DRAFT_RECEIVED,
    key: api.imeStatus.REPORT_DRAFT_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.imeStatus.REPORT_RETURNED_FROM_DOCTOR,
    key: api.imeStatus.REPORT_RETURNED_FROM_DOCTOR,
    group: 'Reporting',
  },
  {
    id: api.imeStatus.REPORT_COMPLETED,
    key: api.imeStatus.REPORT_COMPLETED,
    group: 'Reporting',
  },
  {
    id: api.imeStatus.CANCELLED,
    key: api.imeStatus.CANCELLED,
    group: 'Attendance',
  },
  {
    id: api.imeStatus.IME_CANCELLED_WITH_FEE,
    key: api.imeStatus.IME_CANCELLED_WITH_FEE,
    group: 'Attendance',
  },
  {
    id: api.imeStatus.CANCELLED_SPECIALIST_TBP,
    key: api.imeStatus.CANCELLED_SPECIALIST_TBP,
    group: 'Attendance',
  },
  {
    id: api.imeStatus.CLIENT_INVOICE,
    key: api.imeStatus.CLIENT_INVOICE,
    group: 'Billing',
  },
  {
    id: api.imeStatus.DOCTOR_INVOICE,
    key: api.imeStatus.DOCTOR_INVOICE,
    group: 'Billing',
  },
  // {
  //   id: api.imeStatus.BILLED,
  //   key: api.imeStatus.BILLED,
  //   group: 'Billing',
  // },
  {
    id: api.imeStatus.FULLY_PAID,
    key: api.imeStatus.FULLY_PAID,
    group: 'Billing',
  },
];

export const IME_STATUS = [
  {
    id: 0,
    key: 'inquiryReceived',
    label: 'Appointment Requested',
    keyPIC: 'REQUEST_RECEIVED',
    keySentDate: 'inquiryReceivedDate',
    statusText: 'Booking Inquiry Received',
    actionName: 'Status_RequestReceived',
    url: '/medicalservice/ChangeStatusToInquiryReceived',
    group: 'Booking & Paperwork',
  },
  {
    id: 1,
    key: 'isAppointmentConfirmed',
    label: 'Appointment Confirmed',
    keyPIC: 'APPOINTMENT_CONFIRMED',
    keySentDate: 'appointmentConfirmedDate',
    statusText: 'Appointment Confirmed',
    actionName: 'Status_AppointmentConfirmed',
    url: '/medicalservice/ChangeStatusToAppointmentConfirmed',
    group: 'Booking & Paperwork',
  },
  {
    id: 2,
    key: 'paperworkReceived',
    label: 'Paperwork Sent to Specialist',
    keyPIC: 'PAPERWORK_RECEIVED',
    keySentDate: 'paperworkReceivedDate',
    statusText: 'Paperwork Received',
    actionName: 'Status_PaperworkReceived',
    url: '/medicalservice/ChangeStatusToPaperworkReceived',
    group: 'Booking & Paperwork',
  },
  {
    id: 3,
    key: 'attended',
    label: 'Attended',
    keyPIC: 'ATTENDED',
    keySentDate: 'attendedDate',
    statusText: 'Attended',
    actionName: 'Status_Attended',
    url: '/medicalservice/ChangeStatusToAttended',
    group: 'Attendance',
  },
  {
    id: 4,
    key: 'notAttended',
    label: 'Did Not Attend',
    keyPIC: 'ATTENDED',
    keySentDate: 'notAttendedDate',
    statusText: 'Did Not Attend',
    actionName: 'Status_NotAttended',
    url: '/medicalservice/ChangeStatusToDidNotAttend',
    group: 'Attendance',
  },
  {
    id: 5,
    key: 'dictationReceived',
    label: 'Dictation Received',
    keyPIC: 'DICTATION_RECEIVED',
    keySentDate: 'dictationReceivedDate',
    statusText: 'Dictation Received',
    actionName: 'Status_DictationReceived',
    url: '/medicalservice/ChangeStatusToDictationReceived',
    group: 'Reporting',
  },
  {
    id: 6,
    key: 'reportDraftReceived',
    label: 'Report Draft Sent to Specialist',
    keyPIC: 'REPORT_DRAFT_RECEIVED',
    keySentDate: 'reportDraftReceivedDate',
    statusText: 'Report Draft Received',
    actionName: 'Status_ReportDraftReceived',
    url: '/medicalservice/ChangeStatusToReportDraftReceived',
    group: 'Reporting',
  },
  {
    id: 7,
    key: 'reportReturnedFromDoctor',
    label: 'Corrected Report Returned from Specialist',
    keyPIC: 'CORRECTED_REPORT',
    keySentDate: 'reportCompletedDate',
    statusText: 'Report Returned From Doctor',
    group: 'Reporting',
  },
  {
    id: 8,
    key: 'reportCompleted',
    label: 'Report Sent to CMs',
    keyPIC: 'REPORT_COMPLETED',
    keySentDate: 'reportCompletedSentDate',
    statusText: 'Report Completed',
    actionName: 'Status_ReportComplete',
    url: '/medicalservice/ChangeStatusToReportCompleted',
    group: 'Reporting',
  },
  {
    id: 9,
    key: 'isCancelledNoFee',
    label: 'Cancelled - No Fee',
    keyPIC: 'ATTENDED',
    keySentDate: 'cancelledDate',
    statusText: 'Cancelled-No Fee',
    actionName: 'Status_CancelledNoFee',
    url: '/medicalservice/ChangeStatusToCancelled',
    group: 'Attendance',
  },
  {
    id: 10,
    key: 'isCancelledLcf',
    label: 'Cancelled with Fee',
    keyPIC: 'ATTENDED',
    keySentDate: 'cancelledDate',
    statusText: 'Late Cancellation Fee',
    actionName: 'Status_CancelledLcf',
    url: '/medicalservice/ChangeStatusToCancelled',
    group: 'Attendance',
  },
  {
    id: 11,
    key: 'clientInvoiced',
    label: 'Client Invoiced',
    keyPIC: '',
    keySentDate: '',
    statusText: 'ClientInvoiced',
    group: 'Billing',
  },
  {
    id: 12,
    key: 'doctorInvoiced',
    label: 'Specialist Invoiced',
    keyPIC: '',
    keySentDate: '',
    statusText: 'DoctorInvoiced',
    group: 'Billing',
  },
  // {
  //   id: 13,
  //   key: 'isBilled',
  //   label: 'Service Billed',
  //   keyPIC: 'ServiceBill',
  //   keySentDate: 'billedDate',
  //   statusText: 'Billed',
  //   group: 'Billing',
  // },
  {
    id: 14,
    key: 'Fully Paid',
    label: 'Service Paid',
    keyPIC: 'ServicePaid',
    keySentDate: 'fullyPaidDate',
    statusText: 'Fully Paid',
    group: 'Billing',
  },
];

export const ASSESSMENT_PIC_IN_CHARGE_OF = {
  REQUEST_RECEIVED: 0,
  APPOINTMENT_CONFIRMED: 1,
  PAPERWORK_RECEIVED: 2,
  ATTENDED: 3,
  NOT_ATTENDED: 4,
  DICTATION_RECEIVED: 5,
  REPORT_DRAFT_RECEIVED: 6,
  REPORT_COMPLETED: 7,
  CANCELLED_NO_FEE: 8,
  CANCELLED_LCF: 9,
  ServiceBill: 10,
  DICTATION_RECEIVED_2: 11,
  PAYMENTRE_QUESTED: 12,
  PREPAYMENT_PAID: 13,
  CORRECTED_REPORT: 14,
  CANCELLED_SPECIALIST_TBP: 15,
};

export const DEFAULT_COLUMNS_RECENT_CHANGE = [
  { title: 'Case Manager' },
  { title: 'Old Assigned Staff' },
  { title: 'Current Assigned Staff' },
  { title: 'Assigned By' },
  { title: 'Assigned Date' },
];

export const DEFAULT_FIELD_NAME_RECENT_CHANGE = [
  'CaseManagerFullName',
  'OldAssigneeFullName',
  'NewAssigneeFullName',
  'CreatedUserFullName',
  'CreatedDate',
];

export const WIRO_INVOICE_TYPE = ['None', 'Wiro', 'WiroSolicitor', 'NonWiro'];

export const VIEW_INVOICE_GLOBAL = {
  CommissionType: {
    Mag: 0,
    NonMag: 1,
    FixedAmount: 2,
    COT: 3,
  },
};

export const FIELD_NAME_FOR_NOTIFICATION = [
  {
    key: 'bookingClient',
    text: 'client',
  },
  {
    key: 'bookingCaseManager',
    text: 'case manager',
  },
  {
    key: 'bookingSolicitor',
    text: 'case manager',
  },
  {
    key: 'subBookingClient',
    text: 'client',
  },
  {
    key: 'claimant',
    text: 'claimant',
  },
  {
    key: 'clinic',
    text: 'clinic',
  },
  {
    key: 'payingCasemanger',
    text: 'case manager',
  },
  {
    key: 'specialist',
    text: 'specialist',
  },
];

export const conversationTypes = [
  {
    label: 'Phone',
    value: 0,
    visible: true,
  },
  {
    label: 'Email',
    value: 1,
    visible: true,
  },
  {
    label: 'Email (Send Now)',
    value: 4,
    visible: false,
  },
  {
    label: 'Private Notes',
    value: 5,
    visible: true,
  },
  {
    label: 'Relationship Meeting',
    value: 2,
    visible: true,
  },
  {
    label: 'Relationship Call',
    value: 8,
    visible: true,
  },
  {
    label: 'Relationship Email',
    value: 9,
    visible: true,
  },
  {
    label: 'Others',
    value: 3,
    visible: true,
  },
];

export const conversationContentCategories = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Case Booking',
    value: 1,
  },
  {
    label: 'Paperwork',
    value: 2,
  },
  {
    label: 'Appointment Confirmation & Reschedule',
    value: 3,
  },
  {
    label: 'Appointment Attendance',
    value: 4,
  },
  {
    label: 'Appointment None-Attendance',
    value: 5,
  },
  {
    label: 'Case Cancellation',
    value: 6,
  },
  {
    label: 'Dictation',
    value: 7,
  },
  {
    label: 'Report Writing',
    value: 8,
  },
  {
    label: 'Billing',
    value: 9,
  },
];

export const CREATED_BY_ROLE = {
  CaseManager: 1,
  Specialist: 2,
  Staff: 3,
};
