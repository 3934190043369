import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import TableToolbar from '../../shared/tableToolbar';
import CMReportStore from './CMReportStore';
import * as api from '../../../stores/api';

@observer
class DoctorReportsToolbar extends React.Component {
  handleExportFreeCR = () => {
    window.open('/Staff/ExportFreeBookingOffers', '_blank');
  };
  handleOpenModalExport = () => {
    return CMReportStore.toggleModal(true, { modalType: 'export' })();
  };
  handleOpenModalImport = type => {
    return CMReportStore.toggleModal(true, { modalType: 'import', type: type })();
  };
  render() {
    return (
      <form className="CM-report-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="positive"
              onClick={() => this.handleOpenModalImport(1)}
            >
              Import CMs
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="positive"
              onClick={() => this.handleOpenModalImport(2)}
            >
              Activate CM accounts
            </TableToolbar.HighLightButton>
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className={cx({ blue: CMReportStore.tabIsActive === 'export' })}
                onClick={this.handleOpenModalExport}
              >
                Export Clients and CMs
              </TableToolbar.HighLightButton>
            )}
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="positive"
              onClick={this.handleExportFreeCR}
            >
              Export Free CR Usage
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
        </TableToolbar.Container>
      </form>
    );
  }
}

export default DoctorReportsToolbar;
