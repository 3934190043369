import TableToolbar from '@components/shared/tableToolbar';
import { DatePicker, Form, notification } from 'antd';
import { action } from 'mobx';
import moment from 'moment';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import Modal from '../../shared/Modal/';
import store from './store';
import ModalConfirm from '../../shared/Modal/ModalConfirm';
import { splitSortJoin } from '@components/dashboard-routes/SmartSearch/utils';
import customFetch from '@utils/customFetch';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import * as api from '@stores/api';

const { RangePicker } = DatePicker;

class ModalExport extends React.Component {
  state = {
    start: moment(),
    end: moment().add(5, 'days'),
    openModal: false,
    modalParams: {},
  };
  renderClinicOptions = () => {
    return uniqBy([...store.clinics, store.selectedClinic], 'Id')
      .filter(i => !!i?.Id)
      .map(({ Id, Name, FullAddress }) => ({
        key: Id,
        text: Name,
        value: Id,
        content: (
          <div className="clinic-option">
            <div>{Name}</div>
            <div>{FullAddress}</div>
          </div>
        ),
      }));
  };

  renderDoctorOptions = () => {
    return uniqBy([...store.doctors, ...store.selectedDoctors, store.selectedDoctor], 'Id')
      .filter(i => !!i?.Id)
      .map(({ Title, Id, FullName, Specialty, RegisterOption, Qualifications }) => ({
        key: Id,
        text: FullName,
        value: Id,
        content: (
          <div>
            <div style={{ fontWeight: 'bold' }} className="text-bold">
              {Title || ''} {FullName}
            </div>
            <div>
              - Specialty: {splitSortJoin(Specialty)}
              {RegisterOption && ' ' + RegisterOption}
            </div>
            <div>- Qualifications: {splitSortJoin(Qualifications)}</div>
          </div>
        ),
      }));
  };

  toggleModal = (isOpen, params = {}) => {
    this.setState({ openModal: isOpen, modalParams: params });
  };

  @action handleChange = (_, { value, name }) => {
    if (name === 'doctorIds') {
      if (value.length > 5) {
        return;
      } else {
        store.specialistKeyword = '';
        const doctors = value
          .map(i => {
            const existDoctor = store.doctors.find(d => d.Id === i);
            return existDoctor;
          })
          .filter(doctor => !!doctor);
        store.setFieldsValue({ selectedDoctors: doctors });
        if (!value.length) {
          store.setFieldsValue({ doctorId: null });
        }
        store.handleSearch();
        return;
      }
    }
    if (name === 'doctorId') {
      const doctor = store.doctors.find(i => i.Id === value);
      store.setFieldsValue({ selectedDoctor: doctor });
      store.specialistKeyword = '';
      store.handleSearch();
    }
    if (name === 'clinicId') {
      store.clinicKeyword = '';
      const clinic = store.clinics.find(i => i.Id === value);
      store.setFieldsValue({
        isReloadCalendar: store.calendarViewMode !== 'dayGridMonth',
        [name]: value || null,
        selectedClinic: clinic,
      });
      store.handleSearch();
      return store.calendarViewMode !== 'dayGridMonth' && store.handleSearch(null);
    }
    return (store[name] = value || 0);
  };

  SendUpdateClinicAvailabilityRequestToDoctor = () => {
    const { selectedClinic, selectedDoctor } = store;
    if (store.calendarViewMode !== 'dayGridMonth' && store.dayWeekViewType !== 'all') {
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please switch to Month view to send the availability update request.',
      });
      return;
    }

    if (!(selectedClinic && selectedClinic.Id > 0 && selectedDoctor && selectedDoctor.Id > 0)) {
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select a specialist and a clinic you want to send the availability update request.',
      });
      return;
    } else {
      return this.toggleModal(true, {
        modalType: 'confirm',
        message: `Do you want to request a clinic availability update from Doctor ${selectedDoctor.FullName} for Clinic ${selectedClinic.Name}?`,
        onOk: () => {
          this.toggleModal(false, {});
          customFetch('/MedicalDoctor/SendClinicAvailabilityRequest', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              doctorId: selectedDoctor.Id,
              clinicId: selectedClinic.Id,
            }),
          }).then(res => {
            if (res.status === 'success') {
              this.toggleModal(true, {
                modalType: 'confirm',
                message: `An email asking for clinic availability updates has been sent to Doctor ${selectedDoctor.FullName}`,
                hideWarningIcon: true,
                footerCloseButton: true,
              });
            } else {
              if (res.error === 'no-session') {
                this.toggleModal(true, {
                  modalType: 'confirm',
                  message: `Doctor ${selectedDoctor.FullName} has never had a session in clinic ${selectedClinic.Name}`,
                });
              } else {
                notification.destroy();
                notification.error({
                  message: 'Error',
                  description: 'Some error occurred. Please report this error to system administrator.',
                });
              }
            }
          });
        },
      });
    }
  };

  EmailDoctorBookingDaysheets = () => {
    const { isHomeVisit, selectedClinic, selectedDoctor } = store;
    const { start, end } = this.state;
    if (store.calendarViewMode !== 'dayGridMonth' && store.dayWeekViewType !== 'all') {
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please switch to Month view to email booking daysheets to specialist.',
      });
      return;
    }

    if ((!isHomeVisit && (!selectedClinic || selectedClinic.Id === 0)) || !selectedDoctor || selectedDoctor.Id === 0) {
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select a specialist and clinic to email booking daysheets to specialist.',
      });
      return;
    }
    const yearFrom = moment(start).year();
    const yearTo = moment(end).year();

    if (yearFrom === yearTo) {
      if (moment(start).isSameOrBefore(moment(end))) {
        //console.log(moment(start).diff(moment(end)))
        if (0 <= Math.abs(moment(start).diff(moment(end), 'days', true)) && Math.abs(moment(start).diff(moment(end), 'days', true)) <= 5) {
          this.toggleModal(true, {
            modalType: 'confirm',
            message: 'Do you want to email booking daysheets of the selected dates to the specialist?',
            onOk: () => {
              this.toggleModal(false, {});
              return customFetch('/MedicalService/EmailDoctorBookingDaysheets', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  doctorId: selectedDoctor.Id,
                  clinicId: selectedClinic.Id,
                  isHomeVisit: isHomeVisit,
                  fromDate: moment(start).toISOString(),
                  toDate: moment(end).toISOString(),
                }),
              })
                .then(res => {
                  if (res.status === 'success') {
                    notification.destroy();
                    notification.success({
                      message: 'Success',
                      description: `Sent to ${res.doctorEmail}.`,
                    });
                  } else {
                    notification.destroy();
                    notification.error({
                      message: 'Error',
                      description: res.message,
                    });
                  }
                })
                .catch(() => {});
            },
          });
        } else {
          this.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please select from/to dates within 5 days',
          });
        }
      } else {
        this.toggleModal(true, {
          modalType: 'confirm',
          message: 'To date must be after From date',
        })();
      }
    } else {
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Select from/to dates first',
      });
    }
  };

  printDoctorDaySheet = doctor => {
    const { isHomeVisit, selectedClinic } = store;
    const { start, end } = this.state;
    const yearFrom = moment(start).year();
    const yearTo = moment(end).year();

    if (moment(start).isSame(moment(end))) {
      let openPrintDate = moment(start);
      let strOpenPrintDate = openPrintDate.format('DDMMMMYYYY');

      window.open(
        '/MedicalService/DoctorDaySheetPrint?dId=' +
          doctor.Id +
          '&cId=' +
          selectedClinic.Id +
          '&printDate=' +
          strOpenPrintDate +
          '&isHomeVisit=' +
          isHomeVisit,
        '_blank',
      );
    } else {
      if (yearFrom === yearTo) {
        if (moment(start).isSameOrBefore(moment(end))) {
          if (Math.abs(moment(start).diff(moment(end), 'days', true)) <= 5) {
            for (var i = 1; i <= Math.abs(moment(start).diff(moment(end), 'days', true)); i++) {
              let openPrintDate = i > 1 ? moment(start).add(i - 1, 'days') : moment(start);
              let strOpenPrintDate = openPrintDate.format('DDMMMMYYYY');
              window.open(
                '/MedicalService/DoctorDaySheetPrint?dId=' +
                  doctor.Id +
                  '&cId=' +
                  selectedClinic.Id +
                  '&printDate=' +
                  strOpenPrintDate +
                  '&isHomeVisit=' +
                  isHomeVisit,
                '_blank',
              );
            }
          } else {
            this.toggleModal(true, {
              modalType: 'confirm',
              message: 'Please select from/to dates within 5 days',
            });
          }
        } else {
          this.toggleModal(true, {
            modalType: 'confirm',
            message: 'To date must be after From date',
          })();
        }
      } else {
        this.toggleModal(true, {
          modalType: 'confirm',
          message: 'Select from/to dates first',
        });
      }
    }
  };

  PrintDoctorBookingDaysheet = () => {
    const { isHomeVisit, selectedClinic, selectedDoctor, selectedDoctors } = store;
    if (store.calendarViewMode !== 'dayGridMonth') {
      if (
        !isHomeVisit &&
        (!selectedClinic || selectedClinic?.Id === 0) &&
        selectedDoctors.length !== 1 &&
        !selectedDoctor.Id
      ) {
        return this.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select a specialist and clinic to view booking daysheets',
        });
      }
      return this.printDoctorDaySheet(selectedDoctors[0] || selectedDoctor);
    } else {
      if (
        (!isHomeVisit && (!selectedClinic || selectedClinic?.Id === 0)) ||
        !selectedDoctor ||
        selectedDoctor.Id === 0
      ) {
        this.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select a specialist and clinic to view booking daysheets',
        });
        return;
      }
      return this.printDoctorDaySheet(selectedDoctor);
    }
  };

  handleSearchChange = (_, { searchQuery }) => {
    store.setFieldsValue({ specialistKeyword: searchQuery });
  };

  handleClinicSearchChange = (_, { searchQuery }) => {
    store.setFieldsValue({ clinicKeyword: searchQuery });
  };

  handleScroll = event => {
    const target = event.target;
    const number = store.specialistNumber;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      store.setFieldsValue({ specialistNumber: number + 30 });
    }
  };

  handleClinicScroll = event => {
    const target = event.target;
    const number = store.clinicNumber;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      store.setFieldsValue({ clinicNumber: number + 30 });
    }
  };

  render() {
    const {
      open,
      loading,
      selectedClinic,
      selectedDoctor,
      loadingClinics,
      loadingSpecialists,
      specialistKeyword,
      clinicKeyword,
    } = store;

    return (
      <Modal
        visible={open}
        width={875}
        bodyStyle={{ overflow: 'inherit' }}
        footer={
          <React.Fragment>
            {!api.isDoctor() && !api.isCaseManager() && (
              <Button
                style={{ marginLeft: '5px' }}
                onClick={this.SendUpdateClinicAvailabilityRequestToDoctor}
                disabled={loading}
                className="blue button-toolbar"
              >
                <Icon name="mail" />
                Request Availability{' '}
                <Popup
                  trigger={<Icon name="info circle" style={{ marginLeft: '5px' }} />}
                  content="Availability update request will be sent to specialist (MAIL-20)"
                  position="top center"
                  wide="very"
                />
              </Button>
            )}
            {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
              <>
                <Button
                  className="blue button-toolbar"
                  style={{ marginLeft: '5px' }}
                  onClick={() => this.PrintDoctorBookingDaysheet()}
                  disabled={loading}
                >
                  <Icon name="print" />
                  View Daysheets{' '}
                </Button>
                <Button
                  className="blue button-toolbar"
                  style={{ marginLeft: '5px' }}
                  onClick={this.EmailDoctorBookingDaysheets}
                  disabled={loading}
                >
                  <Icon name="mail" />
                  Email Daysheets{' '}
                  <Popup
                    trigger={<Icon name="info circle" style={{ marginLeft: '5px' }} />}
                    content="Booking daysheets will be sent to specialist (MAIL-144)"
                    position="top center"
                    wide="very"
                  />
                </Button>
              </>
            )}
            <Button
              className="negative"
              onClick={() => {
                store.toggleModal(false)();
              }}
            >
              Close
            </Button>
          </React.Fragment>
        }
      >
        <Form>
          <div className="searchbar__group-items">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '48%' }}>
                <label
                  style={{
                    marginRight: '5px',
                    fontWeight: 'bold',
                    width: '50px',
                  }}
                >
                  <span style={{ color: 'red' }}>*</span> Clinic:
                </label>
                <TableToolbar.SearchDropdown
                  fluid
                  selection
                  name="clinicId"
                  value={!selectedClinic ? '' : `${selectedClinic?.Name || ''} - ${selectedClinic?.FullAddress || ''}`}
                  className="Toolbar-input"
                  options={this.renderClinicOptions()}
                  loading={loading || loadingClinics}
                  selectOnNavigation={false}
                  selectOnBlur={false}
                  style={selectedClinic ? { height: '50px', marginBottom: 10 } : { marginBottom: 10 }}
                  clearable
                  onBlur={() => store.setFieldsValue({ clinicKeyword: '' })}
                  icon={!selectedClinic ? <Icon name="dropdown" /> : <Icon name="delete" />}
                  disabled={loading || loadingClinics}
                  onChange={this.handleChange}
                  searchQuery={clinicKeyword}
                  onSearchChange={this.handleClinicSearchChange}
                  onScroll={this.handleClinicScroll}
                  search={options => {
                    return options;
                  }}
                />
              </div>
              <div style={{ width: '48%' }}>
                <label
                  style={{
                    marginRight: '5px',
                    fontWeight: 'bold',
                    width: '75px',
                    marginTop: 10,
                  }}
                >
                  <span style={{ color: 'red' }}>*</span> Specialist:
                </label>
                <TableToolbar.SearchDropdown
                  search={opt => {
                    return opt;
                  }}
                  fluid
                  selectOnNavigation={false}
                  selectOnBlur={false}
                  name="doctorId"
                  selection
                  onChange={this.handleChange}
                  value={!!selectedDoctor ? `${selectedDoctor?.FullName || ''} - ${selectedDoctor?.Email2 || ''}` : ''}
                  className="Toolbar-input input--overridden-style"
                  style={selectedDoctor ? { height: '50px', marginBottom: 10 } : { marginBottom: 10 }}
                  options={this.renderDoctorOptions()}
                  loading={loading || loadingSpecialists}
                  disabled={loading || loadingSpecialists || api.isDoctor()}
                  clearable
                  onScroll={this.handleScroll}
                  onBlur={() => store.setFieldsValue({ specialistKeyword: '' })}
                  searchQuery={specialistKeyword}
                  onSearchChange={this.handleSearchChange}
                  icon={!selectedDoctor ? <Icon name="dropdown" /> : <Icon name="delete" />}
                />
              </div>
            </div>
            <label
              style={{
                marginRight: '5px',
                fontWeight: 'bold',
                width: '75px',
              }}
            >
              From - To: (Max 5 days)
            </label>
            <RangePicker
              format="DD/MM/YYYY"
              value={[this.state.start, this.state.end]}
              onChange={value => this.setState({ start: value[0], end: value[1] })}
              size="large"
              style={{ width: '100%' }}
            />
          </div>
        </Form>
        {this.state.openModal && (
          <ModalConfirm
            onCancel={() => this.setState({ openModal: false, loading: false })}
            modalParams={this.state.modalParams}
            open={this.state.openModal}
            footerCloseButton={!this.state.modalParams?.onOk}
          />
        )}
      </Modal>
    );
  }
}

export default observer(ModalExport);
