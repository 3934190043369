import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';
import { currencyFormatter } from '../../../utils/functions';

const paymentPeriodMonths = [];
const paymentPeriodYears = [];

for (let i = 0; i < 12; i++) {
  const momentObj = moment().subtract(i, 'month');
  paymentPeriodMonths.push(momentObj.format('MMMM'));

  const thatYear = momentObj.year().toString();
  if (paymentPeriodYears.indexOf(thatYear) < 0) {
    paymentPeriodYears.push(thatYear);
  }
}

class ClaimantsStore {
  @observable loading = true;
  @observable loadingDoctor = true;

  @observable doctorIdList = [];
  @observable data = null;
  @observable dataDoctor = null;
  @observable monthPeriod = paymentPeriodMonths[1];
  @observable yearPeriod = paymentPeriodYears[0];
  @observable paymentPeriodMonths = paymentPeriodMonths;
  @observable paymentPeriodYears = paymentPeriodYears;

  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable keywordSearch = ``;
  @observable doctorId = ``;
  @observable sortColumn = 'First Name';
  @observable sortKey = 'firstName';
  @observable totalRemittanceAmout = '$0';
  @observable fromTo =
    moment()
      .subtract(1, 'month')
      .startOf('month')
      .format(`DD/MM/YYYY`) +
    ' - ' +
    moment()
      .subtract(1, 'month')
      .endOf('month')
      .format(`DD/MM/YYYY`);

  @observable curPage = 1;
  @observable numberSearch = 30;
  @observable IsSelectionList = true;

  @observable isView = true;
  @observable paymentDate = moment();

  @observable open = false;
  @observable modalParams = {};
  @observable includeXeroUploadedInvoiceOnly = false;

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
    };
  };

  @action fetchAllWithoutNCI = () => {
    this.loadingDoctor = true;

    customFetch('/MedicalDoctor/GetAllWithoutNCI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: this.IsSelectionList,
        curPage: this.curPage,
        numItemPerPage: this.numberSearch,
        keyword: this.keywordSearch,
        orderBy: `FullName`,
        orderDirection: `descending`,
        showing: `active`,
      }),
    }).then(
      action(data => {
        this.dataDoctor = data;
        this.loadingDoctor = false;
      }),
    );
  };

  @action fetch = () => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    const from_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const to_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const to = encodeURIComponent(to_);
    const from = encodeURIComponent(from_);
    const body = {
      fullName: this.keyword,
      invoiceDateFrom: from,
      invoiceDateTo: to,
      orderBy: this.sortKey,
      orderDirection: this.sortDirection,
      excludeReferringSolicitor: true,
      includeXeroUploadedInvoiceOnly: this.includeXeroUploadedInvoiceOnly,
      excludeCreditedInvoices: true,
      NumItemPerPage: 30,
    };
    const bodyHTTPParams = Object.entries(body)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    customFetch('/api/DoctorWithInvoice?' + bodyHTTPParams).then(
      action(data => {
        this.data = data;
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetch();
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetch(setTotalPage);
  };

  @action refetchSearch = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchAllWithoutNCI();
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action changeTotalRemittanceAmout = amount => {
    var fAmount = currencyFormatter(amount);
    this.totalRemittanceAmout = fAmount;
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'active';
    this.sortColumn = 'First Name';
    this.sortKey = 'FirstName';
    this.sortDirection = 'ascending';
    this.keywordSearch = '';
    this.numberSearch = 30;
    this.fromTo =
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(`DD/MM/YYYY`) +
      ' - ' +
      moment()
        .subtract(1, 'month')
        .endOf('month')
        .format(`DD/MM/YYYY`);
    this.doctorId = ``;

    this.curPage = 1;
    this.refetch({}, true);
    this.refetchSearch({});
  };
  @action resetSearch = () => {
    this.keywordSearch = '';
    this.numberSearch = 30;
    this.doctorId = ``;

    this.refetchSearch({});
  };
}

export default new ClaimantsStore();
