import React from 'react';
import cx from 'classnames';
import { Divider } from 'antd';
import { Button } from 'semantic-ui-react';

import '../AddEditClinic/index.scss';

import ActionRequired from './ActionRequired';
import FactualInvestigationDetails from './FactualInvestigationDetails';
import ServiceProvider from './ServiceProvider';
import ReportStatus from './ReportStatus';
import RequestCancellationStatus from './RequestCancellationStatus';
import * as api from '../../../../src/stores/api';

const STEPS = [
  'Action Required',
  'Factual Investigation Details',
  'Service Provider/Investigator Status',
  'Report Status',
  'Request Cancellation Status',
];

class AddEditFactualInvestigation extends React.Component {
  state = {
    currentStep: 0,
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <FactualInvestigationDetails />;
      case 2:
        return <ServiceProvider />;
      case 3:
        return <ReportStatus />;
      case 4:
        return <RequestCancellationStatus />;
      default:
        return <ActionRequired />;
    }
  };

  render() {
    return (
      <div className="page-container">
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {this.renderForm()}
        </div>
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && <Button primary>Save</Button>}
          <Button className="negative">Close</Button>
        </div>
      </div>
    );
  }
}

export default AddEditFactualInvestigation;
