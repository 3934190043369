import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import store from './store';
import CampaignScheduleHistory from './CampaignScheduleHistory';

function CampaignModal() {
  const { modalType } = store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...store} onCancel={store.toggleModal(false)} />;
  }
  if (modalType === 'campaignHistory') {
    return <CampaignScheduleHistory {...store} onCancel={store.toggleModal(false)} />;
  }
}

export default CampaignModal;
