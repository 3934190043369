import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import Store from './clinicalNoteStore';
import uploadStore from './UploadFile/UploadStore';
import ModelUploadFile from './UploadFile/ModelUploadFile';
import UpdateModel from './Update/UpdateModel';
import ModalIMECaseProgress from './ModalIMECaseProgress';
import ModalFRCaseProgress from './ModalFRCaseProgress';
import ModalCRCaseProgress from './ModalCRCaseProgress';
import ModalSuppCaseProgress from './ModalSuppCaseProgress';
import ModalViewSuppRequest from './ModalViewSuppRequest';
import ModalActionNote from './ModalActionNotes';
import ModalActionNoteFR from './ModalActionNoteFR';
import ModalActionNoteSupp from './ModalActionNoteSupp';

function UniversalSearchModal(props) {
  const { modalType } = Store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...Store} onCancel={Store.toggleModal(false)} />;
  }
  if (modalType === 'imeCaseProgress') {
    return <ModalIMECaseProgress />;
  }
  if (modalType === 'frCaseProgress') {
    return <ModalFRCaseProgress />;
  }
  if (modalType === 'crCaseProgress') {
    return <ModalCRCaseProgress />;
  }
  if (modalType === 'suppCaseProgress') {
    return <ModalSuppCaseProgress />;
  }
  if (modalType === 'notes') {
    return <ModalActionNote />;
  }
  if (modalType === 'noteFR') {
    return <ModalActionNoteFR />;
  }
  if (modalType === 'noteSupp') {
    return <ModalActionNoteSupp />;
  }
  if (uploadStore.modalParams.modalType === 'openUploadModel') {
    return <ModelUploadFile refetchTable={props.refetchTable} />;
  }
  if (uploadStore.modalParams.modalType === 'openUpdateModel') {
    return <UpdateModel refetchTable={props.refetchTable} />;
  }
  if (modalType === 'viewSuppRequest') {
    return <ModalViewSuppRequest {...Store} onCancel={Store.toggleModal(false)} />;
  }
}

export default UniversalSearchModal;
