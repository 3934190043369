import React, { useRef, useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import { Button, Dimmer, Loader, Form, Icon, Checkbox } from 'semantic-ui-react';
import './../../DragDrop.scss';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import DragDropFile from './../../DragDropFile';
import ModalConfirm from '../../Modal/ModalConfirm';
import * as api from '@stores/api';
import uploadStore from './UploadStore';

function UploadReports(props) {
  const {
    open,
    modalParams,
    emailInfo,
    submitDocumentType,
    attachmentType_ReportCompleted,
    toggleModal,
    submitFileHandler,
  } = uploadStore;
  const { id, serviceType } = modalParams;

  const [uploadFile, setUploadFile] = useState([]);
  const [sendReportSolicitor, setSendReportSolicitor] = useState(false);
  const [sendReportCM, setSendReportCM] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [refuseSendReport, setRefuseSendReport] = useState(false);
  const [CMEmail, setCMEmail] = useState(null);
  const [solicitorEmail, setSolicitorEmail] = useState(null);
  const prevEmailInfo = useRef(emailInfo);

  const title = () => {
    return 'Upload Reports: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant;
  };

  const onSubmitFile = action(() => {
    uploadStore.loading = true;
    if (!uploadFile || uploadFile.length <= 0) {
      return;
    }

    if (!sendReportSolicitor && !sendReportCM && !refuseSendReport) {
      uploadStore.loading = false;
      return setOpenModalConfirm(true);
    }

    var uploadAttachment = [];
    uploadFile.forEach(element => {
      uploadAttachment.push({
        [`${serviceType}Id`]: id,
        FileName: element.name,
        Title: element.title,
        ReportType: element.reportType,
        Type: element.reportType,
        NumberOfPages: element.numberOfPages,
      });
    });
    const uploadData = {
      [`${serviceType}Id`]: id,
      ReportType: attachmentType_ReportCompleted[serviceType],
      [`${serviceType}Attachment`]: uploadAttachment,
      SendReportSolicitor: sendReportSolicitor,
      SendReportCM: sendReportCM,
    };
    submitFileHandler(submitDocumentType[serviceType], uploadData, props.refetchTable);
  });

  const onUploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
      });
      const data = [...uploadFile, ...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      setUploadFile(data);
    }
  };

  const onDeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      const data = [...uploadFile].filter(x => x.name !== deletedFiles.name);
      setUploadFile(data);
    }
  };

  useEffect(() => {
    refuseSendReport && onSubmitFile();
    // eslint-disable-next-line
  }, [refuseSendReport]);

  useEffect(() => {
    uploadStore.fetchInfoEmail(modalParams.id);
  }, [modalParams]);

  useEffect(
    action(() => {
      if (prevEmailInfo.current !== emailInfo) {
        if (emailInfo) {
          uploadStore.loading = false;
          setCMEmail(emailInfo.CMEmail);
          setSolicitorEmail(emailInfo.SolicitorEmail);
          emailInfo.CMEmail && setSendReportCM(true);
          emailInfo.SolicitorEmail && setSendReportSolicitor(true);
        }
      } else {
        uploadStore.loading = true;
      }
    }),
    [emailInfo],
  );

  const emailLabel = (labelType, doctorEmail) => {
    const label = `Booking ${labelType} `;
    const email = doctorEmail ? `(${doctorEmail})` : '';
    return label.concat(email);
  };

  return (
    <React.Fragment>
      <Modal
        visible={open}
        onCancel={toggleModal(false)}
        width={600}
        className="custom-modal"
        title={title()}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              disabled={!uploadFile || uploadFile.length === 0}
              onClick={onSubmitFile}
              loading={uploadStore.loading}
            >
              <Icon name="upload" /> Upload
            </Button>}
            <Button color="red" onClick={toggleModal(false)}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
      >
        <Dimmer active={uploadStore.loading}>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Field>
            <label>Send Final Report To:</label>
          </Form.Field>
          <Form.Group grouped>
            <Form.Field>
              <Checkbox
                label={emailLabel('Solicitor', solicitorEmail)}
                disabled={solicitorEmail === null}
                checked={sendReportSolicitor}
                onChange={() => setSendReportSolicitor(!sendReportSolicitor)}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={emailLabel('CM', CMEmail)}
                disabled={CMEmail === null}
                checked={sendReportCM}
                onChange={() => setSendReportCM(!sendReportCM)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <div style={{ marginTop: '10px' }}>
          <DragDropFile
            multiple={true}
            disableDragDrop={false}
            onUploadFile={onUploadFile}
            onDeleteFile={onDeleteFile}
            type={attachmentType_ReportCompleted[serviceType]}
            title="Final Report"
            maxSize="50"
          />
          <hr></hr>
        </div>
      </Modal>
      <ModalConfirm
        avoidCancelOnSubmit
        modalParams={{
          message: 'Do you want to send report to Client?',
          onOk: () => {
            setOpenModalConfirm(false);
          },
        }}
        open={openModalConfirm}
        onCancel={() => {
          setOpenModalConfirm(false);
          setRefuseSendReport(true);
        }}
      />
    </React.Fragment>
  );
}

export default observer(UploadReports);
