import React from 'react';
import { Form, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Popup, Icon as IconSemantic, Divider } from 'semantic-ui-react';

import AddEditStore from './store';
import AccountManagers from './AccountManagers';
import SubscriptionSettings from './SubscriptionSettings';

import { convertPhoneFaxNumber } from '@utils/functions';

@observer
class ReportDeliveryContact extends React.Component {
  render() {
    const { staffAllList } = AddEditStore;
    var lstcompanyCMs = staffAllList?.itemList || [];
    if (!lstcompanyCMs || lstcompanyCMs.length === 0) lstcompanyCMs = staffAllList?.itemList || [];
    const data = toJS(AddEditStore.clientInfo);
    return (
      <>
        <h3 className="form-subtitle fluid-field">Notifications</h3>
        <Form name="report-delivery-contact-form">
          <Form.Item label="Contact Name">
            <Input size="large" value={data.AccContact} onChange={AddEditStore.handleFieldChange('AccContact')} />
          </Form.Item>
          <Form.Item
            label="Accounting Email"
            validateStatus={AddEditStore.errors['AccEmail'] ? 'error' : null}
            help={AddEditStore.errors['AccEmail']}
          >
            <Input size="large" value={data.AccEmail} onChange={AddEditStore.handleFieldChange('AccEmail')} />
          </Form.Item>
          <Form.Item
            label="Phone"
            validateStatus={AddEditStore.errors['AccPhoneNumber'] ? 'error' : null}
            help={AddEditStore.errors['AccPhoneNumber']}
          >
            <Input
              size="large"
              value={convertPhoneFaxNumber(data.AccPhoneNumber)}
              onChange={AddEditStore.handleFieldChange('AccPhoneNumber')}
            />
          </Form.Item>
          {/* <Form.Item
            label={
              <React.Fragment>
                Default Contact for Receiving Completed Reports{' '}
                <Popup
                  trigger={<IconSemantic name="info circle" />}
                  content="This contact will receive completed reports when reports are marked 'Report Sent to CMs' in cases"
                  position="top center"
                  wide="very"
                />
              </React.Fragment>
            }
          >
            <Select
              size="large"
              value={data.ReportDeliveryContact}
              onChange={AddEditStore.handleFieldChange('ReportDeliveryContact')}
              showSearch
              optionFilterProp="children"
              onSearch={event => AddEditStore.refetchDeliveryReport(event)}
            >
              {lstcompanyCMs &&
                lstcompanyCMs.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            label={
              <React.Fragment>
                Default Report Receiving Email{' '}
                <Popup
                  trigger={<IconSemantic name="info circle" />}
                  content="This contact will receive completed reports when reports are marked 'Report Sent to CMs' in cases"
                  position="top center"
                  wide="very"
                />
              </React.Fragment>
            }
            validateStatus={AddEditStore.errors['ReportDeliveryContactEmail'] ? 'error' : null}
            help={AddEditStore.errors['ReportDeliveryContactEmail']}
          >
            <Input
              size="large"
              value={data.ReportDeliveryContactEmail}
              onChange={AddEditStore.handleFieldChange('ReportDeliveryContactEmail')}
            />
          </Form.Item>
          <Form.Item
            label={
              <React.Fragment>
                Default Booking Confirmation Email{' '}
                <Popup
                  trigger={<IconSemantic name="info circle" />}
                  content="Mail-01 (cancellation), Mail-04 (confirmed in-person appointment), Mail-39 (Confirmed video appointment), Mail-126 (unconfirmed appointment) will be cced to this email"
                  position="top center"
                  wide="very"
                />
              </React.Fragment>
            }
            validateStatus={AddEditStore.errors['DefaultBookingConfirmationEmail'] ? 'error' : null}
            help={AddEditStore.errors['DefaultBookingConfirmationEmail']}
          >
            <Input
              size="large"
              value={data.DefaultBookingConfirmationEmail}
              onChange={AddEditStore.handleFieldChange('DefaultBookingConfirmationEmail')}
            />
          </Form.Item>
        </Form>
        <div style={{ marginTop: '45px', marginBottom: '45px' }}>
          <Divider className="fluid-field" />
        </div>
        <h3 className="form-subtitle fluid-field">Subscriptions</h3>
        <SubscriptionSettings />
      </>
    );
  }
}

export default ReportDeliveryContact;
