import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AMATimeUnitModifyStore from './Store';
import ModalCreate from './ModalCreate';

function AMATimeUnitModifyModal() {
  const { modalType } = AMATimeUnitModifyStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AMATimeUnitModifyStore} onCancel={AMATimeUnitModifyStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...AMATimeUnitModifyStore} onCancel={AMATimeUnitModifyStore.toggleModal(false)} />;
  }
}

export default AMATimeUnitModifyModal;
