import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import SalesTargetStore from './Store';
import ModalCreate from './ModalCreate';

function SalesTargetModal() {
  const { modalType } = SalesTargetStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...SalesTargetStore} onCancel={SalesTargetStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...SalesTargetStore} onCancel={SalesTargetStore.toggleModal(false)} />;
  }
}

export default SalesTargetModal;
