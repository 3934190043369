import { action, observable } from 'mobx';

import { getSalesTarget } from './Service';

class SalesTargetStore {
  @observable loading = true;
  @observable data = null;

  @observable filter = '';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Key';
  @observable sortKey = 'Key';
  @observable totalItems = 0;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchData = (setTotalPage = false) => {
    this.loading = true;
    Promise.all([
      getSalesTarget(
        this.keyword,
        this.curPage,
        this.pageSize,
        this.sortKey,
        this.sortDirection.replace('ending', '').toUpperCase(),
      ),
    ])
      .then(
        action(([saleTarget]) => {
          if (saleTarget.curPage <= 1) {
            this.totalPage = saleTarget.sumPage;
            this.totalItems = saleTarget.sumItem;
          }
          this.data = saleTarget;
          this.loading = false;
        }),
      )
      .catch(() => {});
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'ascending';

    this.curPage = 1;
    this.totalPage = 0;
    this.fetchData(true);
  };
}

export default new SalesTargetStore();
