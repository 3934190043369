import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import SalesCallReportStore from './SalesCallReportStore';

function SalesCallModal() {
  const { modalType } = SalesCallReportStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...SalesCallReportStore} onCancel={SalesCallReportStore.toggleModal(false)} />;
  }
}

export default SalesCallModal;
