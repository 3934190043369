import './index.scss';
import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { toJS } from 'mobx';
import dashboardStore from '@stores/dashboard';
import TableToolbar from '../../shared/tableToolbar';
import SupplementaryReportsStore from './SupplementaryReportsStore';
import ModalAlert from './ModalAlert';
import * as api from '../../../stores/api';
import { getRenderText, getRenderOptions } from '../../../utils/functions';

@observer
class SupplementaryReportsToolbar extends React.Component {
  state = {
    openModalAlert: false,
    message: `You need to search or filter first before exporting results.`,
    checkedClaimtOnly: true,
  };

  openModal = options => {
    return SupplementaryReportsStore.toggleModal(true, options);
  };

  openCreateNewCase = () => {
    var id = 0;
    localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
    dashboardStore.close('add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`add-edit-supplementary-report-2?id=${id}`);
    });
  };

  handleExport = () => {
    const { fromDate, toDate } = SupplementaryReportsStore.getDateRange();
    const {
      filter,
      filterOverdue,
      NextTaskPIC,
      claimant,
      keyword,
      actionRequiredById,
      PrepaymentType,
    } = SupplementaryReportsStore;
    if (
      claimant.Id ||
      fromDate ||
      toDate ||
      NextTaskPIC ||
      filter !== `All` ||
      filterOverdue !== `All` ||
      keyword ||
      actionRequiredById ||
      !!PrepaymentType
    ) {
      this.openDownloadSearchResultsUrl();
    } else {
      this.setState({
        openModalAlert: true,
      });
    }
  };

  openDownloadSearchResultsUrl = () => {
    const { fromDate, toDate } = SupplementaryReportsStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant, PrepaymentType } = SupplementaryReportsStore;
    const searchOption = {
      ActionRequired: false,
      ActionRequiredById: SupplementaryReportsStore.actionRequiredById,
      ActionType: 'NONE',
      Claimant: Object.entries(SupplementaryReportsStore.claimant)
        .map(([key, val]) => `${key}=${val}`)
        .join('&'),
      CurPage: SupplementaryReportsStore.curPage,
      FilterOverdue: filterOverdue,
      FilterType: filter.search(/\s/) ? filter.replace(/\s+/g, '+') : filter,
      From: moment(fromDate).format('YYYY-MM-DD'),
      ClaimantId: SupplementaryReportsStore.claimant.Id,
      Keyword: SupplementaryReportsStore.keyword,
      NextPicId: NextTaskPIC,
      NumItemPerPage: 10000,
      SearchClaimantOnly: SupplementaryReportsStore.searchClaimantOnly,
      SortBy: SupplementaryReportsStore.sortKey,
      SortDirection: `DESC`,
      To: moment(toDate).format('YYYY-MM-DD'),
      UrgentReport: false,
      FilterPrepayment: PrepaymentType,
    };
    if (!claimant.Id) {
      delete searchOption.Claimant;
      delete searchOption.ClaimantId;
    }
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    if (!NextTaskPIC) {
      delete searchOption.NextPicId;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open('/Supplementary/ExportSearchResult?' + qs, '_blank');
  };

  handleFilterChange = (_, { name, value }) => {
    SupplementaryReportsStore.refetch({ [name]: value }, true);
  };

  renderFilterOption = name =>
    getRenderOptions(
      SupplementaryReportsStore[name]?.itemList,
      opt => ({
        text: `${opt.FullName}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Id,
      }),
      'FullName',
      { Id: '', FullName: 'All', Count: '' },
    );

  renderFilterLabel = (name, value) =>
    getRenderText(value, SupplementaryReportsStore[name]?.itemList, 'Id', 'FullName', {
      Id: '',
      FullName: 'All',
      Count: null,
    });

  renderStaffActionRequired = () => {
    return SupplementaryReportsStore.dataStaffAssignAction.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderCaseStatus = () =>
    getRenderOptions(
      SupplementaryReportsStore.dataDynamicSupplementaryReportStatus,
      opt => ({
        text: `${opt.Status}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Status,
      }),
      'Status',
      { Status: 'All', Count: '' },
    );

  renderCaseStatusLabel = (name, value) =>
    getRenderText(value, SupplementaryReportsStore[name], 'Status', 'Status', {
      Status: 'All',
      Count: '',
    });

  nextTaskCM = [`Service Billed`];
  notInNextTask = [`Clarification No Fee`, `Cancelled No Fee`];

  renderNextTaskLabel = value => {
    if (value === `All`) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(SupplementaryReportsStore.dataDynamicSupplementaryReportStatus);
      for (var i = 0; i < data.length; i++) {
        if (api.isAdminOrMagStaffOrAccounting()) {
          if (this.notInNextTask.indexOf(data[i].Status) === -1) {
            array.push(data[i]);
          }
        } else if (api.isCaseManager()) {
          if (this.nextTaskCM.indexOf(data[i].Status) !== -1) {
            array.push(data[i]);
          }
        }
      }
      array.unshift({ NextStatus: `All`, Count: `` });
      let labels = array.filter(opt => opt.Status === value).map(opt => opt);
      if (api.isAdminOrMagStaffOrAccounting()) {
        if (labels[0]) return `${labels[0].NextStatus} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
        return `All`;
      } else if (api.isCaseManager()) {
        if (labels[0]) return `${labels[0].NextStatus} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
        return `All`;
      }
    }
  };

  renderNextTask = () => {
    let array = [];
    const data = toJS(SupplementaryReportsStore.dataDynamicSupplementaryReportStatus);
    for (var i = 0; i < data.length; i++) {
      if (api.isAdminOrMagStaffOrAccounting()) {
        array.push(data[i]);
      } else if (api.isCaseManager()) {
        if (this.nextTaskCM.indexOf(data[i].Status) !== -1) {
          array.push(data[i]);
        }
      }
    }
    const count = toJS(SupplementaryReportsStore.dataDynamicSupplementaryReportStatus);
    const array_ = array.map(item => {
      const matchedStatus = count.find(i => item.Status === i.Status);
      if (matchedStatus) {
        return {
          ...item,
          Status: item.Status,
          Count: matchedStatus.Count,
        };
      }
      return null;
    });
    array = array_.filter(i => i !== undefined);
    array.unshift({ NextStatus: `All`, Count: `` });
    const notInNextTask = [
      api.GetSUPPStatusText(api.suppStatus.CASE_CREATED),
      api.GetSUPPStatusText(api.suppStatus.CANCELLED_NO_FEE),
      api.GetSUPPStatusText(api.suppStatus.SERVICE_BILLED),
      api.GetSUPPStatusText(api.suppStatus.CLARIFICATION_NO_FEE),
      api.GetSUPPStatusText(api.suppStatus.CLIENT_INVOICE),
      api.GetSUPPStatusText(api.suppStatus.DOCTOR_INVOICE),
    ];
    const newArray = array.filter(status => !notInNextTask.includes(status.Status));
    if (api.isAdminOrMagStaffOrAccounting()) {
      return newArray.map(opt => ({
        text: `${opt.NextStatus} ${opt.Count ? `(${opt.Count})` : ''}`,
        value: opt.Status,
      }));
    } else if (api.isCaseManager()) {
      return newArray.map(opt => ({
        text: `${opt.NextStatus} ${opt.Count ? `(${opt.Count})` : ''}`,
        value: opt.Status,
      }));
    }
  };

  renderNextTaskOverDue = () =>
    getRenderOptions(
      SupplementaryReportsStore.dataDynamicOverDueSupplementaryReportStatus,
      opt => ({
        text: `${opt.Status}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Status,
      }),
      'Status',
      { Status: `All`, Count: '' },
    );

  renderFilterPrepaymentOptions = () =>
    getRenderOptions(SupplementaryReportsStore.dataPrepayment?.itemList, opt => ({
      text: `${opt.Text}${opt.Count ? ` (${opt.Count})` : ''}`,
      value: opt.Value,
    }));

  renderFilterPrepaymentText = value => getRenderText(value, SupplementaryReportsStore.dataPrepayment?.itemList);

  handleFilterPrepayment = (_, { value }) => {
    SupplementaryReportsStore.refetch({ PrepaymentType: value, curPage: 1 }, true);
  };

  onChecked = () => {
    const { searchClaimantOnly } = SupplementaryReportsStore;
    SupplementaryReportsStore.setFieldsValue({
      searchClaimantOnly: !searchClaimantOnly,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = SupplementaryReportsStore;
    SupplementaryReportsStore.refetch({ claimant: { isFreeText: true, FullName: keyword }, curPage: 1 }, true);
  };

  handleChange = (_, { value }) => {
    SupplementaryReportsStore.setFieldsValue({ keyword: value });
  };

  handleReset = () => {
    SupplementaryReportsStore.resetStore();
  };

  handleRefresh = () => {
    SupplementaryReportsStore.refetch({}, true);
  };

  handleChangeSearchDropdown = e => {
    SupplementaryReportsStore.refetchClaimant({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };

  renderActionOptions = () =>
    getRenderOptions(
      SupplementaryReportsStore.dataClaimant?.itemList,
      opt => ({
        text: `${opt.Id ? `${opt.Id} - ` : ''}${opt.FullName || ''}`,
        value: JSON.stringify({ Id: opt.Id, FullName: opt.FullName }),
      }),
      'FullName',
      { Id: '', FullName: `All` },
    );

  renderActionText = value =>
    getRenderText(
      value.Id,
      SupplementaryReportsStore.dataClaimant?.itemList,
      'Id',
      'FullName',
      { Id: '', FullName: 'All' },
      'Search a Claimant',
    );

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const claimant = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (claimant.Id === ``) {
      SupplementaryReportsStore.refetch(
        {
          claimant: { Id: ``, FullName: `` },
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      SupplementaryReportsStore.resetSearch({}, true);
    } else {
      SupplementaryReportsStore.refetch(
        {
          claimant,
          keyword: claimant.FullName,
          curPage: 1,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  handleChangeFromTo = (_, { value }) => {
    SupplementaryReportsStore.setFieldsValue({ fromTo: value });
  };

  handleShowBookingForToday = () => {
    SupplementaryReportsStore.refetch(
      {
        fromTo: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
      },
      true,
    );
  };

  renderModal = () => {
    const { message, openModalAlert } = this.state;
    return (
      <ModalAlert
        open={openModalAlert}
        modalParams={{ message }}
        onCancel={() => this.setState({ openModalAlert: false })}
      />
    );
  };

  handleScroll = event => {
    const target = event.target;
    const number = SupplementaryReportsStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      SupplementaryReportsStore.refetchClaimant({ numberSearch: number + 20 });
    }
  };

  render() {
    const {
      keyword,
      searchClaimantOnly,
      loading,
      fromTo,
      loadingClaimant,
      totalItems,
      PrepaymentType,
    } = SupplementaryReportsStore;

    return (
      <form className="supplementary-reports-toolbar">
        {this.renderModal()}
        <TableToolbar.Container style={{ justifyContent: 'flex-start' }}>
          <TableToolbar.Left as="div">
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <Checkbox
                label="Search claimant only"
                onChange={this.onChecked}
                checked={searchClaimantOnly}
                style={{ marginRight: `10px` }}
                disabled={loading}
              />
            )} */}
            {searchClaimantOnly && api.isAdminOrMagStaffOrAccounting() ? (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                onScroll={this.handleScroll}
                allowAdditions
                additionLabel=""
                selectOnNavigation={false}
                value={keyword}
                onAddItem={this.handleSubmit}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                className="Toolbar-input"
                disabled={loading}
                clearable
                style={{ width: '20rem' }}
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
            ) : (
              <TableToolbar.Input
                value={keyword}
                style={{ width: '20rem' }}
                onChange={this.handleChange}
                disabled={loading}
              />
            )}
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              onClick={this.handleReset}
              className="negative"
              style={{ marginLeft: 5 }}
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              onClick={this.handleRefresh}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isAdminOrMagStaffOrAccounting() && !api.isTypist() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                className={`positive`}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Left as="div">
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                style={{ marginLeft: 5 }}
                onClick={this.openCreateNewCase}
                className={`positive`}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>}

              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExport}
                disabled={loading}
              >
                Export Searches
              </TableToolbar.HighLightButton>

              <TableToolbar.HighLightButton
                color={`blue`}
                onClick={this.handleShowBookingForToday}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                Show Today's Bookings
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          )}
          <TableToolbar.Left>
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>

        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.RangePicker
            value={fromTo}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Next Task PIC`}
              value={`${this.renderFilterLabel(`dataStaffAssign`, SupplementaryReportsStore.NextTaskPIC)}`}
              className="Toolbar-filter"
              name={'NextTaskPIC'}
              options={this.renderFilterOption(`dataStaffAssign`)}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <TableToolbar.Filter
            label={api.isAdminOrMagStaffOrAccounting() ? `Next Task` : `Tasks Due`}
            value={`${this.renderNextTaskLabel(SupplementaryReportsStore.filter)}`}
            className="Toolbar-filter"
            name={'filter'}
            options={this.renderNextTask()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Next Task Overdue`}
              value={SupplementaryReportsStore.filterOverdue}
              className="Toolbar-filter"
              name={'filterOverdue'}
              options={this.renderNextTaskOverDue()}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <TableToolbar.Filter
            selection
            label={`Case Status`}
            value={`${this.renderCaseStatusLabel(
              `dataDynamicSupplementaryReportStatus`,
              SupplementaryReportsStore.filter,
            )}`}
            // className="Toolbar-filter"
            name={'filter'}
            options={this.renderCaseStatus()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Action Required`}
                value={`${this.renderFilterLabel(
                  `dataStaffAssignAction`,
                  SupplementaryReportsStore.actionRequiredById,
                )}`}
                className="Toolbar-filter"
                name={'actionRequiredById'}
                options={this.renderFilterOption(`dataStaffAssignAction`)}
                onChange={this.handleFilterChange}
                disabled={loading}
                style={{ marginBottom: '1rem' }}
              />
            ) && (
              <TableToolbar.Filter
                selection
                label="Prepayment Type"
                value={this.renderFilterPrepaymentText(PrepaymentType)}
                options={this.renderFilterPrepaymentOptions()}
                onChange={this.handleFilterPrepayment}
                disabled={loading}
                style={{ marginBottom: '1rem' }}
              />
            )}
        </TableToolbar.Container>
      </form>
    );
  }
}

export default SupplementaryReportsToolbar;
