import { action, observable } from 'mobx';
import { filter, map, isEmpty, find, startCase } from 'lodash';
import moment from 'moment';
import customFetch from '@utils/customFetch';
import { Folders, InquiryStatuses } from './constants';

class Store {
  @observable selectedFolder = null;
  @observable mailData = [];
  @observable selectedMail = null;
  @observable loading = true;
  @observable sortDirection = 'DESC';
  @observable keyword = '';
  @observable sortColumn = '';
  @observable sortKey = 'CreatedDate';
  @observable currentPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable receivedDateRange = '';
  @observable closedDateRange = '';
  @observable isReplyModalOpen = false;
  @observable isActionNotesModalOpen = false;
  @observable filterPic = '';
  @observable filterInquiryType = '';
  @observable filterInquiryStatus = null;
  @observable filterCaseOwnerId = null;
  @observable picOptions = [];
  @observable assignedPicOptions = [];
  @observable inquiryTypeOptions = [];
  @observable isConfirmModalOpen = false;
  @observable assignedCaseTypeOptions = [];
  @observable assignedInquiryTypeOptions = [];
  @observable assignedInquiryStatusOptions = [];
  @observable assignedCaseOwnerOptions = [];
  @observable filterInquirySharedEmails = null;
  @observable inquirySharedEmailOptions = [];

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  getDateRange = fromTo => {
    if (isEmpty(fromTo)) {
      return { fromDate: null, toDate: null };
    }

    const [fromDate, toDate] = fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
    };
  };

  @action fetchMails = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate: fromReceivedDate, toDate: toReceivedDate } = this.getDateRange(this.receivedDateRange);
    const { fromDate: fromClosedDate, toDate: toClosedDate } = this.getDateRange(this.closedDateRange);
    if (setTotalPage) {
      this.currentPage = 1;
      this.selectedMail = null;
    }

    customFetch('/api/ims/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        Pic: this.filterPic,
        InquiryType: this.filterInquiryType,
        CurPage: this.currentPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection,
        IsGetRootItems: true,
        Folder: this.selectedFolder,
        From: fromReceivedDate,
        To: toReceivedDate,
        FromClosedDate: fromClosedDate,
        ToClosedDate: toClosedDate,
        InquiryStatus: this.filterInquiryStatus,
        CaseOwnerId: this.filterCaseOwnerId,
        ReceiverEmails: this.filterInquirySharedEmails,
      }),
    }).then(
      action(data => {
        const itemList = map(data.itemList, m => {
          const status = find(InquiryStatuses, i => i.value === m.inquiryStatus);
          const folder = find(Folders, i => i.value === m.folder);
          m.inquiryStatusText = status.text;
          m.caseTypeText = folder.text;
          m.selected = false;
          return m;
        });

        this.selectedMail = null;
        this.mailData = setTotalPage ? data.itemList : this.mailData.concat(itemList);

        if (data.currentPage <= 1) {
          this.totalPage = data.totalPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action fetchConversations = rootItem => {
    this.loading = true;
    customFetch('/api/ims/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        IsGetRootItems: false,
        ConversationId: rootItem.conversationId,
      }),
    }).then(
      action(data => {
        const conversations = filter(data.itemList, i => i.id !== rootItem.id);

        const itemList = map(conversations, m => {
          const status = find(InquiryStatuses, i => i.value === m.inquiryStatus);
          m.inquiryStatusText = status.text;
          return m;
        });

        const mails = map(this.mailData, i => {
          if (i.id === rootItem.id) {
            i.isExpanded = true;
            i.conversations = itemList;
          }

          return i;
        });
        this.mailData = mails;
        this.loading = false;
      }),
    );
  };

  @action showConversations = (rootItem, isShow) => {
    if (isShow && isEmpty(rootItem.conversations)) {
      this.fetchConversations(rootItem);
    } else {
      const mails = map(this.mailData, i => {
        if (i.id === rootItem.id) {
          i.isExpanded = isShow;
        }

        return i;
      });
      this.mailData = mails;
    }
  };

  @action nextPage = () => {
    if (this.currentPage < this.totalPage) {
      this.currentPage = this.currentPage + 1;
      this.fetchMails(false);
    }
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchMails();
  };

  @action loadPicList = () => {
    customFetch('/MedicalService/GetStaffs', {
      method: 'POST',
    }).then(
      action(data => {
        if (!isEmpty(data.itemList)) {
          this.picOptions = data.itemList.map(({ Id, FullName }) => ({
            text: FullName,
            value: Id,
          }));
        }
      }),
    );
  };

  @action loadAssignedPicList = () => {
    customFetch('/IMSData/GetAllAssignedPic', {
      method: 'POST',
    }).then(
      action(data => {
        if (!isEmpty(data.itemList)) {
          this.assignedPicOptions = data.itemList.map(({ Id, FullName, Count }) => ({
            text: FullName + ` (${Count})`,
            value: Id,
          }));
        }
      }),
    );
  };

  @action loadAssignedCaseOnwers = () => {
    customFetch('/api/ims/GetAllAssignedCaseOnwers', {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    }).then(
      action(data => {
        if (!isEmpty(data.itemList)) {
          this.assignedCaseOwnerOptions = data.itemList.map(({ Id, FullName, Count }) => ({
            fullName: FullName,
            text: FullName + ` (${Count})`,
            value: Id,
          }));

          this.assignedCaseOwnerOptions.unshift({
            fullName: 'Unassigned',
            text: `Unassigned (${data.unassignedCount})`,
            value: '-1',
          });
        }
      }),
    );
  };

  @action loadAssignedStatusCount = () => {
    customFetch('/IMSData/GetStatusCount', {
      method: 'POST',
    }).then(
      action(data => {
        if (!isEmpty(data.caseTypeList)) {
          this.assignedCaseTypeOptions = Folders.map(item => {
            const type = find(data.caseTypeList, i => i.CaseType === item.value);
            return {
              text: item.text + (type ? ` (${type.Count})` : ''),
              value: item.value,
            };
          });
        }

        if (!isEmpty(data.inquiryTypeList)) {
          this.assignedInquiryTypeOptions = data.inquiryTypeList;
        }

        if (!isEmpty(data.inquiryStatusList)) {
          this.assignedInquiryStatusOptions = InquiryStatuses.map(item => {
            const status = find(data.inquiryStatusList, i => i.InquiryStatus === item.value);
            return {
              text: item.text + (status ? ` (${status.Count})` : ''),
              value: item.value,
            };
          });
        }
      }),
    );
  };

  @action loadInquiryKeySetting = () => {
    customFetch(`/SystemConfig/GetInquirySetting`, {
      method: 'GET',
    }).then(
      action(data => {
        if (!isEmpty(data)) {
          const inquiryType = data.inquiryTypeModel;
          if (inquiryType) {
            const values = inquiryType.ConfigValue.split(';');
            this.inquiryTypeOptions = values.map(v => ({
              key: v,
              text: startCase(v),
              value: v,
            }));
          }

          const sharedEmails = data.inquirySharedEmailsModel;
          if (sharedEmails) {
            const values = sharedEmails.ConfigValue.split(';');
            this.inquirySharedEmailOptions = values.map(v => ({
              text: v.split('@')[0].trim(),
              value: v.replace(/[\s\r\n]+/g, ''),
            }));
          }
        }
      }),
    );
  };

  @action saveEmail = model => {
    this.loading = true;
    customFetch('/api/ims', {
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
      body: JSON.stringify(model),
    }).then(
      action(response => {
        if (response.status === 'Success') {
          const mails = map(this.mailData, i => {
            if (i.id === model.id) {
              const newValue = {
                ...i,
                ...model,
              };

              if (i.inquiryStatus !== model.inquiryStatus) {
                const status = find(InquiryStatuses, is => is.value === newValue.inquiryStatus);
                newValue.inquiryStatusText = status.text;
              }

              if (i.folder !== model.folder) {
                const folder = find(Folders, f => f.value === newValue.folder);
                newValue.caseTypeText = folder.text;
              }

              this.selectedMail = newValue;
              return newValue;
            }

            return i;
          });

          this.mailData = mails;

          // Refresh current caseowners data
          this.loadAssignedCaseOnwers();
        }
        this.loading = false;
      }),
    );
  };

  @action moveItems = (ids, toFolder) => {
    this.loading = true;
    customFetch(`/api/ims`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify({ itemIds: ids, toFolder }),
    }).then(
      action(response => {
        this.loading = false;
        if (response.status === 'Success') {
          this.fetchMails(true);
        }
      }),
    );
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = '';
    this.sortKey = '';
    this.sortDirection = 'descending';
    this.receivedDateRange = '';
    this.closedDateRange = '';
    this.filterInquiryType = '';
    this.filterInquiryStatus = null;
    this.filterInquirySharedEmails = null;
    this.currentPage = 1;
    this.totalPage = 0;
    this.totalItems = 0;
  };
}

export default new Store();
