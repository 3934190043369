import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { TableHeader, TableEmptyMessage } from '../../shared/table';

class RecentChangeForm extends React.Component {
  renderTableEmpty = columns => {
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          <TableEmptyMessage colSpan={columns.length} />
        </Table.Body>
      </Table>
    );
  };

  checkIsDateString = (datesString, showTime = false) => {
    const format = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YY';
    if (datesString) {
      if (datesString.startsWith('/Date')) {
        return moment(datesString).format(format);
      } else {
        return moment(datesString, 'DD/MM/YYYY HH:mm').format(format);
      }
    } else {
      return 'Invalid date';
    }
  };
  renderRecord = record => {
    if (record === 'email') {
      return 'Email';
    } else if (record === 'sms') {
      return 'SMS';
    } else return record;
  };

  renderTableCell = (record, idx) => {
    if (this.checkIsDateString(record, this.props.showDateTime) !== 'Invalid date') {
      return <Table.Cell key={idx}>{this.checkIsDateString(record, this.props.showDateTime)}</Table.Cell>;
    } else {
      return <Table.Cell key={idx}>{this.renderRecord(record)}</Table.Cell>;
    }
  };

  renderTableData = (dataRecentChange, columns, fieldNameStaffRecentChange) => {
    if (Array.isArray(dataRecentChange)) {
      if (dataRecentChange.length === 0) {
        return this.renderTableEmpty(columns);
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataRecentChange.map((record, idx) => (
              <Table.Row key={idx}>{fieldNameStaffRecentChange.map(i => this.renderTableCell(record[i], i))}</Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return this.renderTableEmpty(columns);
    }
  };
  render() {
    const { dataRecentChange, columns, fieldNameStaffRecentChange } = this.props;
    return this.renderTableData(dataRecentChange, columns, fieldNameStaffRecentChange);
  }
}
export default RecentChangeForm;
