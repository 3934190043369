import customFetch from '../../../utils/customFetch';

export const getFileReviewInfo = (id, mType, mId) => {
  return customFetch('/FileReview/PostFileReviewInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, mType, mId }),
  });
};

export const getFileReviewStatusLogs = fileReviewId => {
  return customFetch('/FileReview/GetFileReviewLogs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileReviewId }),
  });
};

export const getCompanyRecentChangeHistory = (id, actionName) => {
  return customFetch('/Default/GetAssignedCompanyHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const getAssignHistory = ({ id, actionName }) => {
  return customFetch('/FileReview/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const getStaffs = () => {
  return customFetch('/FileReview/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getDoctors = ({ keyword = '', numItemPerPage = 30 }) => {
  return customFetch('/MedicalDoctor/GetAllWithoutNCI', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      curPage: 1,
      IsSelectionList: true,
      keyword,
      numItemPerPage,
      RecruitedOrContractedOnly: true,
      Showing: 'active',
    }),
  });
};

export const getCompanies = ({ keyword = '', numItemPerPage = 30 }) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      curPage: 1,
      IsSelectionList: true,
      keyword,
      numItemPerPage,
      Showing: 'active',
    }),
  });
};

export const getCMs = ({ companyId, keyword, numItemPerPage = 1000, IsOnboarded = true }) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numItemPerPage,
      companyId,
      IsOnboarded,
    }),
  });
};

export const getClaimants = ({ keyword = '', numItemPerPage = 30 }) => {
  return customFetch('/PatientInfo/GetPatientList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      curPage: 1,
      IsSelectionList: true,
      keyword,
      numItemPerPage,
      Showing: 'active',
    }),
  });
};

export const getClaimTypes = () => {
  return customFetch('/FileReview/GetActiveClaimTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getReportTypes = () => {
  return customFetch('/FileReview/GetActiveReportTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getIMSCodes = () => {
  return customFetch('/FileReview/GetIMSApprovalCode', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getLawyers = () => {
  return customFetch('/FileReview/GetWIROPrincipleLawyer', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getSendPaperMethods = () => {
  return customFetch('/Default/GetSpecificLookupConfigOrderNoByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      type: 'PapToDoc',
    }),
  });
};

export const getTypists = () => {
  return customFetch('/Default/GetTypists', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getConversations = FileReviewId => {
  return customFetch('/FileReview/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ FileReviewId }),
  });
};

export const submitConversation = data => {
  return customFetch('/FileReview/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};

export const resendPaperwork = id => {
  return customFetch('/FileReview/ResendPaperworkToDoctor', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const resendPaperworkReadiness = id => {
  return customFetch('/FileReview/ResendPaperworkReadiness', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const submitFileReviewInfo = model => {
  return customFetch('/FileReview/PostFileReview_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...model }),
  });
};

export const getBillingCodes = (NumItemPerPage = 30) => {
  return customFetch('/MAGService/GetAllByStatus', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Showing: 'active',
      NumItemPerPage,
    }),
  });
};

export const getActionRequired = FileReviewId => {
  return customFetch('/FileReview/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ FileReviewId }),
  });
};

export const getListInvoiceID = (objectId = 0) => {
  return customFetch('/FileReview/GetListInvoiceID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectId: objectId, type: 'FileReview' }),
  });
};

export const updateFileReviewStatus = (fileReviewId, status) => {
  return customFetch('/FileReview/ChangeStatusFR', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ filereviewId: fileReviewId, caseFlow: status }),
  });
};

export const checkExcludeTypeOfService = (dId, cId, rId) => {
  return customFetch(
    '/MedicalDoctor/CheckExcludeTypeOfService?doctorId=' + dId + '&typeOfClaimId=' + cId + '&typeOfReportId=' + rId,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const getFullNameInCC = (type, keyword) => {
  return customFetch('/MedicalService/GetFullNameInCC?type=' + type + '&keyword=' + keyword, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const reSendUrgentReportRequest = (type, caseId, resendType) => {
  return customFetch('/MedicalService/ReSendUrgentReportRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type, objId: caseId, resendType: resendType }),
  });
};

export const getUserUploadInfo = id => {
  return customFetch('/Manager/GetUserById', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: id }),
  });
};

export const getCompanyInsuer = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
      IcareInsurer: true, //Insurer
    }),
  });
};

export const getLookUpConfig = (type = '') => {
  return customFetch('/Default/GetLookUpConfig', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type }),
  });
};

export const getLookupIcareReport = (
  types = [
    'IMEReportCapacity',
    'IMEReportDiagnosis',
    'IMEReportLiability',
    'IMEReportTreatment',
    'IMEReportWPI',
    'IMEReportPsyWPI',
    'IMEReportIMC',
    'IcareServiceType',
  ],
) => {
  return customFetch('/Default/GetLookupConfigByTypes2', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ types: types }),
  });
};

export const sendICareCompletionRequest = (id) => {
  return customFetch('/FileReview/SendICareCompletionRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id }),
  });
};