import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, notification, Spin, Select, InputNumber, DatePicker, TimePicker, Tooltip } from 'antd';
import { Segment, Dimmer, Loader, Button, Radio, Checkbox } from 'semantic-ui-react';
import { renderDate } from '../Shared';
import * as api from '../../../stores/api';
import store from './Store';
import DragDropFile from '../Shared/DragDropComponent';
import { saveData } from '../../admin-routes/FundingReport/Service';
import { action, toJS, observable } from 'mobx';
import { Link } from 'react-router-dom';
import iframe from '../../dashboard-routes/Iframe/iframeData';

@observer
class FormAddEdit extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};
  state = {
    canEdit: api.isAdmin(),
  };

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };
  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  dragdropUploadFile = (fileList, reportType) => {
    const { lstFileAttachments } = store;
    const attachment = {
      FileName: fileList[0].name,
      Title: fileList[0].title,
      NumberOfPages: fileList[0].numberOfPages,
      Type: 4,
      CaseId: store.fundingReportInfo.Id,
      AttachmentType: reportType,
      Id: 0,
    };
    const newAttachments = [...lstFileAttachments, attachment].map(i => ({
      ...i,
      key: i.FileName,
    }));
    store.setLstData('lstFileAttachments', newAttachments);
  };

  dragdropDeleteFile = key => {
    const { lstFileAttachments } = store;
    const newAttachments = lstFileAttachments.filter(i => i.key !== key);
    store.setLstData('lstFileAttachments', newAttachments);
  };

  renderAttachment = (Attachments = [], attachmentType) => {
    return Attachments.filter(i => i.AttachmentType === attachmentType);
  };

  @action handSave = async () => {
    const { fundingReportInfo, lstFileAttachments } = store;
    var saveDataObj = fundingReportInfo;
    if (lstFileAttachments && lstFileAttachments.length > 0) {
      saveDataObj.Attachments = lstFileAttachments;
    } else {
      saveDataObj.Attachments = [];
    }
    console.log(saveDataObj);
    saveData(saveDataObj).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again.',
        });
      }
    });
  };
  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      let arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      let i = +arr[0] + 'e' + sig + (+arr[1] + scale);
      let j = Math.round(i);
      let k = +(j + 'e-' + scale);
      return k;
    }
  }
  handleClose = () => {
    window.closeCurrentTab();
    store.onReset();
  };
  onClose = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'All unsaved changes will be discarded. Do you want to continue?',
      onOk: () => this.handleClose(),
    })();
  };

  openInvoiceDetail = record => {
    localStorage.setItem('InvoiceCaseId', `INV-${record.InvoiceNo}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${record.Id}&view=ViewInvoice`, {
      id: record.Id,
      view: 'ViewInvoice',
    });
  };

  generateInvoice = record => {
    window.open('/Invoice/ViewBilling?invoiceId=' + record.Id, '_blank');
  };

  render() {
    const { open, lstFileAttachments, fundingReportInfo } = store;
    var data = fundingReportInfo;
    return (
      <Segment size="massive">
        <Dimmer>
          <Loader />
        </Dimmer>
        <div className="form-container">
          <Form name="funding-agreement" className="grid-style estimate-form">
            <Form.Item label="Transaction No">
              <Input
                size="large"
                type="text"
                value={data.ReportNo}
                onChange={store.handleDateFieldChange('ReportNo')}
              />
            </Form.Item>
            <Form.Item label="Transaction Date">
              <DatePicker
                size="large"
                disabled={true}
                format="DD/MM/YYYY"
                value={renderDate(data.Created, true, false)}
              />
            </Form.Item>
            <Form.Item label="Invoice No">
              <Input size="large" type="text" disabled={true} value={data.InvoiceObj && data.InvoiceObj.InvoiceNo} />
            </Form.Item>
            <Form.Item label="Invoice Date">
              <DatePicker
                size="large"
                disabled={true}
                format="DD/MM/YYYY"
                value={renderDate(data.InvoiceObj && data.InvoiceObj.DateOfInvoice, true, false)}
              />
            </Form.Item>
            <Form.Item label="Case No">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.AdditionalInfo && data.AdditionalInfo.CaseNo}
              />
            </Form.Item>
            <Form.Item label="Service Type">
              <div>
                <Checkbox
                  style={{ marginRight: '30px' }}
                  disabled={true}
                  label={'IME'}
                  checked={data.ServiceType === 1}
                ></Checkbox>
                <Checkbox
                  style={{ marginRight: '30px' }}
                  disabled={true}
                  label={'FR'}
                  checked={data.ServiceType === 2}
                ></Checkbox>{' '}
                {'  '}
                <Checkbox
                  style={{ marginRight: '30px' }}
                  disabled={true}
                  label={'SUP'}
                  checked={data.ServiceType === 3}
                ></Checkbox>{' '}
                {'  '}
                <Checkbox
                  style={{ marginRight: '30px' }}
                  disabled={true}
                  label={'CR'}
                  checked={data.ServiceType === 4}
                ></Checkbox>{' '}
                {'  '}
                <Checkbox
                  style={{ marginRight: '30px' }}
                  disabled={true}
                  label={'MN'}
                  checked={data.ServiceType === 5}
                ></Checkbox>{' '}
                {'  '}
                <Checkbox disabled={true} label={'Other'} checked={data.ServiceType === 0}></Checkbox> {'  '}
              </div>
            </Form.Item>
            <Form.Item label="Claim No">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.AdditionalInfo && data.AdditionalInfo.ClaimNo}
              />
            </Form.Item>
            <Form.Item label="Claimant">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.AdditionalInfo && data.AdditionalInfo.Claimant}
              />
            </Form.Item>

            <Form.Item label="Report Status">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.AdditionalInfo && data.AdditionalInfo.ReportStatus}
              />
            </Form.Item>
            <Form.Item label="Report Status Date">
              <DatePicker
                size="large"
                disabled={true}
                format="DD/MM/YYYY"
                value={renderDate(data.AdditionalInfo && data.AdditionalInfo.ReportStatusDate, true, false)}
              />
            </Form.Item>
            <Form.Item label="Matter Type">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.AdditionalInfo && data.AdditionalInfo.MatterType}
              />
            </Form.Item>
            <Form.Item label="Report Completed Date">
              <DatePicker
                size="large"
                disabled={true}
                format="DD/MM/YYYY"
                value={renderDate(data.AdditionalInfo && data.AdditionalInfo.ReportCompletedDate, true, false)}
              />
            </Form.Item>
            <Form.Item
              label={
                <React.Fragment>
                  Invoice Amount (incl GST)
                  {/* <Link to="#" onClick={() => this.generateInvoice(data.InvoiceObj)}>
                  {' '}View Invoice
                </Link> */}
                </React.Fragment>
              }
            >
              <InputNumber
                size="large"
                disabled={true}
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={data.ReportFee}
              />
            </Form.Item>
            <Form.Item label="Payment Date">
              <DatePicker
                size="large"
                disabled={true}
                format="DD/MM/YYYY"
                value={renderDate(data.ReportPaymentDate, true, false)}
              />
            </Form.Item>
            <Form.Item label="Invoice Attachment">
              <DragDropFile
                onUploadFile={this.dragdropUploadFile}
                onDeleteFile={this.dragdropDeleteFile}
                type={2}
                maxSize={50}
                multiple={true}
                uploadTitle={'Invoice Attachment'}
                renderAttachment={this.renderAttachment}
                Attachments={lstFileAttachments}
                baseUrl="/UserFile/FundingReportAttachmentPath"
              />
            </Form.Item>
            <Form.Item label="Facility Transaction Amount">
              <InputNumber
                size="large"
                disabled={true}
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={this.roundNumber(data.ReportFee / ((100 - data.GrossMargin) / 100), 2)}
              />
            </Form.Item>
            <Form.Item label="Gross Margin">
              <InputNumber
                size="large"
                disabled={true}
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="%"
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={data.GrossMargin}
              />
            </Form.Item>
            <Form.Item label="Discount">
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                name="Discount"
                onChange={store.handleFieldChange('Discount')}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={data.Discount}
              />
            </Form.Item>
            <Form.Item label="Settlement Status">
              <Radio
                label="Unpaid"
                className="Form-field-radio"
                name="SettlementStatus"
                value={0}
                checked={data.SettlementStatus === 0}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Overdue"
                className="Form-field-radio"
                name="SettlementStatus"
                value={1}
                checked={data.SettlementStatus === 1}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Part Pay"
                className="Form-field-radio"
                name="SettlementStatus"
                value={2}
                checked={data.SettlementStatus === 2}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Full Pay"
                className="Form-field-radio"
                name="SettlementStatus"
                value={3}
                checked={data.SettlementStatus === 3}
                onChange={store.handleChangeFieldValue}
              />
            </Form.Item>
            <Form.Item label="Settlement Status Date">
              {data.SettlementStatus == 0 && (
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.UnpaidSettlementStatusDate, true, false)}
                  onChange={store.handleDateFieldChange('UnpaidSettlementStatusDate')}
                />
              )}
              {data.SettlementStatus == 1 && (
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.OverdueSettlementStatusDate, true, false)}
                  onChange={store.handleDateFieldChange('OverdueSettlementStatusDate')}
                />
              )}
              {data.SettlementStatus == 2 && (
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.PartPaySettlementStatusDate, true, false)}
                  onChange={store.handleDateFieldChange('PartPaySettlementStatusDate')}
                />
              )}
              {data.SettlementStatus == 3 && (
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.FullPaySettlementStatusDate, true, false)}
                  onChange={store.handleDateFieldChange('FullPaySettlementStatusDate')}
                />
              )}
            </Form.Item>
            <Form.Item label="Estimate Settlement Date">
              <DatePicker
                size="large"
                allowClear={false}
                format="DD/MM/YYYY"
                value={renderDate(data.EstimateSettlementDate, true, false)}
                onChange={store.handleDateFieldChange('EstimateSettlementDate')}
              />
            </Form.Item>
            <Form.Item label="Actual Settlement Date">
              <DatePicker
                size="large"
                allowClear={false}
                format="DD/MM/YYYY"
                value={renderDate(data.ActualSettlementDate, true, false)}
                onChange={store.handleDateFieldChange('ActualSettlementDate')}
              />
            </Form.Item>

            <Form.Item label="Facility Agreement No">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.FundingAgreementObj && data.FundingAgreementObj.AgreementNo}
              />
            </Form.Item>
            <Form.Item label="Lender">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={data.FundingProviderObj && data.FundingProviderObj.Id + ' - ' + data.FundingProviderObj.Name}
              />
            </Form.Item>
            <Form.Item label="Attached Files" className="fluid-field">
              <DragDropFile
                onUploadFile={this.dragdropUploadFile}
                onDeleteFile={this.dragdropDeleteFile}
                type={1}
                maxSize={50}
                multiple={true}
                uploadTitle={'Attachment'}
                renderAttachment={this.renderAttachment}
                Attachments={lstFileAttachments}
                baseUrl="/UserFile/FundingReportAttachmentPath"
              />
            </Form.Item>
            <Form.Item className="fluid-field">
              <Button floated="right" className="negative" onClick={this.onClose}>
                Close
              </Button>
              <Button floated="right" onClick={() => this.handSave()} className="btn-primary-submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Segment>
    );
  }
}

export default FormAddEdit;
