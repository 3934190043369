import React from 'react';
import { Form, Input, notification, Spin, Select } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import store from './Store';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';

const statusLabels = [
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];
const typeLabel = [
  { text: `Staff`, value: `staff` },
  { text: `Case Manager`, value: `casemanager` },
  { text: `Specialist`, value: `specialist` },
  { text: `Claimant`, value: `claimant` },
  { text: `Typist`, value: `typist` },
];

@observer
class ConversationModalForm extends React.Component {
  state = {
    loading: true,
    dataDomainExclusion: null,
    loadingUpload: false,
  };
  handleSave = async () => {
    try {
      const { dataDomainExclusion } = this.state;
      const params = { ...dataDomainExclusion };
      if (params.Id === 0) {
        delete params.Id;
      }

      if (!params.Label) {
        notification.error({
          message: 'Error',
          description: 'Domain is required',
        });
        return;
      } else {
        const labelSplit = params.Label.split('.');
        if (labelSplit && labelSplit.length < 2) {
          notification.error({
            message: 'Error',
            description: 'Domain is not valid',
          });
          return;
        }
      }

      const res = await customFetch('/MedicalDoctor/SaveLookUpConfig', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ ...params, type: 'DomainExclusion', isActive: !params.id ? true : params.isActive }),
      });
      if (res.status === 'success') {
        store.refetch({}, true);

        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data saved successfully',
        });
        return store.toggleModal(false, {})();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    } catch (err) {}
  };
  componentDidMount() {
    const { record, id } = store.modalParams;
    if (record && !!id) {
      const dataDomainExclusion = {
        Label: record.Label,
        Description: record.Description,
        Id: id,
      };
      this.setState({ dataDomainExclusion }, () => this.setState({ loading: false }));
    } else {
      const dataDomainExclusion = {
        Label: '',
        Description: '',
        Id: 0,
      };
      this.setState({ dataDomainExclusion }, () => this.setState({ loading: false }));
    }
  }

  handleFieldChange = fieldName => event => {
    const { dataDomainExclusion } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      dataDomainExclusion: {
        ...dataDomainExclusion,
        [fieldName]: value,
      },
    });
  };

  render() {
    const { record, id } = store.modalParams;
    const { open } = store;
    const { dataDomainExclusion, loading } = this.state;

    return (
      <Modal
        width={1200}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>}
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New Domain Exclusion' : record.Label}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item label="Domain">
              <Input value={dataDomainExclusion.Label} onChange={this.handleFieldChange('Label')} />
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                rows={4}
                value={dataDomainExclusion.Description}
                onChange={this.handleFieldChange('Description')}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ConversationModalForm;
