import './DoctorDetails.scss';
import cx from 'classnames';
import React from 'react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Grid, Form, Label, Segment, Radio, Checkbox, Input, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import Validator from 'validatorjs';

import DoctorDetailsStore, { DoctorAttachmentType } from './DoctorDetailsStore';
import TextArea from './TextArea';
import { Upload, File, NoFile } from './Upload';
// import { formatDate } from '../../../../utils/functions';
import customFetch from '../../../../utils/customFetch';

@observer
class GeneralInformationTab extends React.Component {
  renderLabel = array => {
    if (array) {
      if (array.length === 0) {
        return <span className="Label-item">None</span>;
      } else {
        return array.map(
          (i, idx) =>
            !!i && (
              <Label key={idx} className="Field-option">
                {i}
              </Label>
            ),
        );
      }
    }
    return <span className="Label-item">None</span>;
  };

  setSpecialties = (event, data) => {
    DoctorDetailsStore.setFieldValue(event, data);
    DoctorDetailsStore.fetchSubSpecialties();
  };

  @action setAcceptedReportTypes = (_, { value }) => {
    DoctorDetailsStore.selectedAcceptedReportTypes = value;
  };

  @action uploadCV = data => {
    const cv = data.fileList[0];
    if (cv) {
      DoctorDetailsStore.dataDoctor.CV = cv.name;
      DoctorDetailsStore.dataDoctor.CVName = cv.title;
    }
  };

  @action removeCV = () => {
    DoctorDetailsStore.dataDoctor.CV = null;
    DoctorDetailsStore.dataDoctor.CVName = null;
  };

  @action uploadLetterHead = data => {
    DoctorDetailsStore.letterHeadAttachments = [
      ...DoctorDetailsStore.letterHeadAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.LetterHead,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeLetterHead = index => {
    DoctorDetailsStore.letterHeadAttachments.splice(index, 1);
  };

  @action uploadSignatures = data => {
    DoctorDetailsStore.signatures = [
      ...DoctorDetailsStore.signatures,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.Signature,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeSignatures = index => {
    DoctorDetailsStore.signatures.splice(index, 1);
  };

  @action uploadAHRPAAccreditation = data => {
    DoctorDetailsStore.aHRPAAccreditationsAttachments = [
      ...DoctorDetailsStore.aHRPAAccreditationsAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.AHRPAAccreditation,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeAHRPAAccreditation = index => {
    DoctorDetailsStore.aHRPAAccreditationsAttachments.splice(index, 1);
  };

  uploadSampleReport = e => {
    const { files } = e.target;

    const totalLength = DoctorDetailsStore.sampleReports.length + files.length;
    if (totalLength > 3) {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'alert',
        message: `You are trying to upload too many sample reports.
        Please select up to 3 files only.`,
      })();
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });

    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.sampleReports = [
          ...DoctorDetailsStore.sampleReports,
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.SampleReport,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  @action removeSampleReport = index => {
    DoctorDetailsStore.sampleReports.splice(index, 1);
  };

  uploadRequiredConsentForm = e => {
    const { files } = e.target;
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });
    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.attRequiredConsentForm = [
          ...DoctorDetailsStore.attRequiredConsentForm,
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.RequiredConsentForm,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  @action removeRequiredConsentFormt = index => {
    DoctorDetailsStore.attRequiredConsentForm.splice(index, 1);
  };

  @action uploadReportTemplate = data => {
    DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments = [
      ...DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeReportTemplate = index => {
    DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments.splice(index, 1);
  };

  @action selectLocation = (_, { value }) => {
    DoctorDetailsStore.selectedLocationsId = value;
    if (DoctorDetailsStore.dataDoctor.ConsultingLocations.length < value.length) {
      const newLocId = value[value.length - 1];
      const newLoc = DoctorDetailsStore.locationList.find(loc => loc.id === newLocId);
      DoctorDetailsStore.dataDoctor.ConsultingLocations.push(newLoc);
    } else {
      DoctorDetailsStore.dataDoctor.ConsultingLocations = DoctorDetailsStore.dataDoctor.ConsultingLocations.filter(
        loc => {
          return value.includes(loc.id);
        },
      );
    }
  };

  @action handleAppointmentReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToAppointmentReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaSMS;
    }
  };

  @action handleCancellationReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToCancellationReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaSMS;
    }
  };

  @action handleAttendanceReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToAttendanceReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaSMS;
    }
  };

  @action handleDictationAndReportReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToDictationAndReportReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaSMS;
    }
  };

  @action resetLocations = () => {
    DoctorDetailsStore.locationList = [];
  };

  validateEmail = emailStr => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    }
    return null;
  };

  validatePhone = phoneNumber => {
    if (phoneNumber) {
      const validation = new Validator({ phone: phoneNumber }, { phone: 'max:10' }, '');
      if (validation.passes()) {
        return null;
      }
      return 'Must be less than or equal to 10 digits';
    }
    return null;
  };

  render() {
    const {
      dataDoctor,
      isEditing,
      setFieldValue,
      toggleCheckbox,
      selectedReportTypes,
      selectedLocations,
      selectedSpecialties,
      selectedSubSpecialties,
      selectedAccreditations,
    } = DoctorDetailsStore;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Profile</label>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Brief Profile{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Staff, CMs and Specialists can view"
                        position="top center"
                        wide="very"
                      />{' '}
                      {/* {!isEditing && (
                        <Checkbox
                          disabled
                          checked={dataDoctor.IsPreferenceReceived}
                          label={`${
                            dataDoctor.IsPreferenceReceived
                              ? 'Received'
                              : 'Not Received'
                          }`}
                        />
                      )} */}
                      <span className="Label-item" style={{ marginLeft: '10px', whiteSpace: 'pre-line' }}>
                        {dataDoctor.PreferenceReceivedDate ? dataDoctor.PreferenceReceivedDate : ''}
                      </span>
                    </label>
                    {!isEditing ? (
                      <p className="Form-Field-Text" style={{ whiteSpace: 'pre-line' }}>
                        {dataDoctor.Comment || 'None'}
                      </p>
                    ) : (
                      <TextArea
                        name="Comment"
                        placeholder="Please write 1 paragraph describing your medical and medico-legal expertise, including industries you have worked in. Use 3rd person pronoun such as title + surname, he/she to describe about yourself. This will be used to assist the customer in selecting the right medico-legal professional to conduct the assessment, and for promotional purposes."
                        maxLength="750"
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Accepted Report Types</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedReportTypes)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Accepted Report Types"
                        multiple
                        search
                        selection
                        name="selectedAcceptedReportTypes"
                        options={DoctorDetailsStore.dataReportTypes}
                        value={toJS(DoctorDetailsStore.selectedAcceptedReportTypes) || []}
                        onChange={this.setAcceptedReportTypes}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Consulting Locations (Suburbs or Towns)</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedLocations)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Enter at least 3 letters to search for a  locations"
                        multiple
                        search
                        selection
                        name="ConsultingLocations"
                        onSearchChange={DoctorDetailsStore.fetchLocations}
                        options={DoctorDetailsStore.dataLocations}
                        onClose={this.resetLocations}
                        value={toJS(DoctorDetailsStore.selectedLocationsId)}
                        onChange={this.selectLocation}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Consulting Locations (States)</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(dataDoctor.ConsultingLocationStates)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Consulting locations (States)"
                        multiple
                        search
                        selection
                        name="ConsultingLocationStates"
                        options={DoctorDetailsStore.dataStates}
                        value={toJS(dataDoctor.ConsultingLocationStates)}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      <React.Fragment>
                        Booking and Cancellation Terms
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Please contact staff to update Terms"
                          position="top center"
                          wide="very"
                        />{' '}
                      </React.Fragment>
                    </label>
                    {!isEditing ? (
                      <div>
                        {dataDoctor.BookingsAndCancellationTerms ? (
                          <p className="Form-Field-Text" style={{ whiteSpace: 'pre-line' }}>
                            {dataDoctor.BookingsAndCancellationTerms}
                          </p>
                        ) : (
                          <span className="Label-item">None</span>
                        )}
                      </div>
                    ) : (
                      <TextArea
                        disabled={true}
                        name="BookingsAndCancellationTerms"
                        placeholder="List service conditions for booking and cancellation"
                        maxLength="500"
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">LinkedIn</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.LinkedInUrl || 'None'}</span>
                      </div>
                    ) : (
                      <Input name="LinkedInUrl" value={dataDoctor.LinkedInUrl || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Video Profile</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.VideoProfileUrl || 'None'}</span>
                      </div>
                    ) : (
                      <Input name="VideoProfileUrl" value={dataDoctor.VideoProfileUrl || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Specialty & Accreditations</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Specialty</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedSpecialties)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Specialty"
                        multiple
                        search
                        selection
                        name="Specialties"
                        options={DoctorDetailsStore.dataSpecialties}
                        value={toJS(dataDoctor.Specialties) || []}
                        onChange={this.setSpecialties}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Qualifications{' '}
                      {/* {isEditing && (
                        <Checkbox
                          checked={dataDoctor.IsPreferenceReceived}
                          label={`${
                            dataDoctor.IsPreferenceReceived
                              ? 'Received'
                              : 'Not Received'
                          }`}
                          disabled
                        />
                        )} */}
                      <span className="Label-item" style={{ marginLeft: '10px' }}>
                        {dataDoctor.QualificationReceivedDate ? dataDoctor.QualificationReceivedDate : ''}
                      </span>
                    </label>
                    {!isEditing ? (
                      <div>{this.renderLabel(dataDoctor.Qualifications)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Qualifications"
                        multiple
                        search
                        selection
                        name="Qualifications"
                        options={DoctorDetailsStore.dataQualifications}
                        value={toJS(dataDoctor.Qualifications) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Sub-Specialty</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedSubSpecialties)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Sub-Specialty"
                        multiple
                        search
                        selection
                        name="SubSpecialties"
                        options={DoctorDetailsStore.dataSubSpecialties}
                        value={toJS(dataDoctor.SubSpecialties) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Accreditations
                      {/* {isEditing && (
                        <Checkbox
                          checked={dataDoctor.IsAccreditationReceived}
                          label={`${
                            dataDoctor.IsAccreditationReceived
                              ? 'Received'
                              : 'Not Received'
                          }`}
                          disabled
                        />
                      )} */}
                      <span className="Label-item" style={{ marginLeft: '10px' }}>
                        {dataDoctor.AccreditationReceivedDate ? dataDoctor.AccreditationReceivedDate : ''}
                      </span>
                    </label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedAccreditations)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Accreditations"
                        multiple
                        search
                        selection
                        name="AccreditationList"
                        options={DoctorDetailsStore.dataAccreditations}
                        value={toJS(dataDoctor.AccreditationList) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Additional Info</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager First Name</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryFirstName || 'None'}</span>
                      </div>
                    ) : (
                      <Input
                        name="SecretaryFirstName"
                        value={dataDoctor.SecretaryFirstName || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Surname</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretarySurname || 'None'}</span>
                      </div>
                    ) : (
                      <Input
                        name="SecretarySurname"
                        value={dataDoctor.SecretarySurname || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Email</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryEmail || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="SecretaryEmail"
                          value={dataDoctor.SecretaryEmail || ''}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.SecretaryEmail) && '-error'}`,
                          )}
                          onChange={setFieldValue}
                        />
                        {this.validateEmail(dataDoctor.SecretaryEmail) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.SecretaryEmail)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Phone</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryTelephone || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="SecretaryTelephone"
                          value={dataDoctor.SecretaryTelephone || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validatePhone(dataDoctor.SecretaryTelephone) && '-error'}`,
                          )}
                        />
                        {this.validatePhone(dataDoctor.SecretaryTelephone) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validatePhone(dataDoctor.SecretaryTelephone)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Fax</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.Fax || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="Fax"
                          value={dataDoctor.Fax || ''}
                          onChange={setFieldValue}
                          className={cx(`input-doctor-details-edit${this.validatePhone(dataDoctor.Fax) && '-error'}`)}
                        />
                        {this.validatePhone(dataDoctor.Fax) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validatePhone(dataDoctor.Fax)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Booking-Related Email</label>
                    <Checkbox
                      label="Business email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToEmail2"
                      checked={dataDoctor.IsSendBookingConfToEmail2}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Personal email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToEmail"
                      checked={dataDoctor.IsSendBookingConfToEmail}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Secretary/Practice Manager Email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToSecretaryEmail"
                      checked={dataDoctor.IsSendBookingConfToSecretaryEmail}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Promotion/mass email</label>
                    <Checkbox
                      label="Business email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail1"
                      checked={dataDoctor.IsSendPromotionMassEmail1}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Personal email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail2"
                      checked={dataDoctor.IsSendPromotionMassEmail2}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Secretary/Practice Manager Email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail3"
                      checked={dataDoctor.IsSendPromotionMassEmail3}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Video Assessment Status</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Video Assessments</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={1}
                      checked={dataDoctor.TeleAssessment === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={2}
                      checked={dataDoctor.TeleAssessment === 2}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={0}
                      checked={dataDoctor.TeleAssessment === 0}
                      onChange={setFieldValue}
                    />
                    <Link
                      to="#"
                      className="Form-Field-Link"
                      onClick={() => window.open('https://scopevideo.com.au/', '_blank')}
                    >
                      (More on Scope Video)
                    </Link>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Medical Negligence Status</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Medical Negligence</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={1}
                      checked={dataDoctor.MedNegStatus === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={4}
                      checked={dataDoctor.MedNegStatus === 4}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={0}
                      checked={dataDoctor.MedNegStatus === 0}
                      onChange={setFieldValue}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Free Preliminary Opinion Request</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={1}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={2}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 2}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={0}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 0}
                      onChange={setFieldValue}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Documents</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      CV{' '}
                      {/* {isEditing && (
                        <Checkbox
                          disabled
                          checked={dataDoctor.IsCvReceived}
                          label={`${
                            dataDoctor.IsCvReceived ? 'Received' : 'Not Received'
                          }`}
                        />
                      )} */}
                      <span className="Label-item" style={{ marginLeft: '10px' }}>
                        {dataDoctor.CvReceivedDate ? dataDoctor.CvReceivedDate : ''}
                      </span>
                    </label>
                    {!isEditing ? (
                      dataDoctor.CV === null ? (
                        <NoFile />
                      ) : (
                        <File name={dataDoctor.CVName} downloadLink={`/UserFile/DoctorCV/${dataDoctor.CV}`} />
                      )
                    ) : dataDoctor.CV === null ? (
                      <Upload onComplete={this.uploadCV} placeholder="Upload CV" />
                    ) : (
                      <File
                        name={dataDoctor.CVName}
                        downloadLink={`/UserFile/DoctorCV/${dataDoctor.CV}`}
                        onRemove={this.removeCV}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">AHPRA and Accreditations</label>
                    {!isEditing ? (
                      !DoctorDetailsStore.aHRPAAccreditationsAttachments.length ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.aHRPAAccreditationsAttachments.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            placeholder="Upload AHRPA Accreditation"
                            onComplete={this.uploadAHRPAAccreditation}
                          />
                        </div>
                        {DoctorDetailsStore.aHRPAAccreditationsAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeAHRPAAccreditation(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Required Consent Form</label>
                    {!isEditing ? (
                      DoctorDetailsStore.attRequiredConsentForm.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.attRequiredConsentForm.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            onChange={this.uploadRequiredConsentForm}
                            placeholder="Upload required consent form"
                          />
                        </div>
                        {DoctorDetailsStore.attRequiredConsentForm.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeRequiredConsentFormt(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Signature{' '}
                      {/* {isEditing && (
                        <Checkbox
                          checked={dataDoctor.IsSignatureReceived}
                          label={`${
                            dataDoctor.IsSignatureReceived
                              ? 'Received'
                              : 'Not Received'
                          }`}
                          disabled
                        />
                      )} */}
                      <span className="Label-item" style={{ marginLeft: '10px' }}>
                        {dataDoctor.SignatureReceivedDate ? dataDoctor.SignatureReceivedDate : ''}
                      </span>
                    </label>
                    {!isEditing ? (
                      DoctorDetailsStore.signatures.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.signatures.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple onComplete={this.uploadSignatures} placeholder="Upload signature" />
                        </div>
                        {DoctorDetailsStore.signatures.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeSignatures(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Report Templates</label>
                    {!isEditing ? (
                      dataDoctor.ReportTemplatesAttachments.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {dataDoctor.ReportTemplatesAttachments.map(i => (
                            <div className="mb-1" key={i.Id ? i.FileName : i.PhysicalFileName}>
                              <File
                                name={i.Id ? i.Title : i.FileDisplayName}
                                downloadLink={`
                                /UserFile/UploadFileTemp/${i.Id ? i.FileName : i.PhysicalFileName}
                              `}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            placeholder="Upload report templates"
                            onComplete={this.uploadReportTemplate}
                          />
                        </div>
                        {dataDoctor.ReportTemplatesAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.Id ? i.FileName : i.PhysicalFileName}>
                            <File
                              name={i.Id ? i.Title : i.FileDisplayName}
                              downloadLink={`/UserFile/UploadFileTemp/${i.Id ? i.FileName : i.PhysicalFileName}`}
                              onRemove={() => this.removeReportTemplate(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Report Samples</label>
                    {!isEditing ? (
                      DoctorDetailsStore.sampleReports.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.sampleReports.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple onChange={this.uploadSampleReport} placeholder="Upload sample reports" />
                        </div>
                        {DoctorDetailsStore.sampleReports.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeSampleReport(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Letter head</label>
                    {!isEditing ? (
                      !DoctorDetailsStore.letterHeadAttachments.length ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.letterHeadAttachments.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple placeholder="Upload letterhead" onComplete={this.uploadLetterHead} />
                        </div>
                        {DoctorDetailsStore.letterHeadAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeLetterHead(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Promotion and Notification</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Promotion Campaigns</label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToPromotionCampaign"
                      checked={dataDoctor.SubscribeToPromotionCampaign}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Appointment Daysheet Updates</label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentUpdateReminders"
                      checked={dataDoctor.SubscribeToAppointmentUpdateReminders}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Clinic Availability Update Reminder</label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToClinicAvailabilityReminders"
                      checked={dataDoctor.SubscribeToClinicAvailabilityReminders}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Updating Patient’s Attendance Reminders</label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceReminders"
                      checked={dataDoctor.SubscribeToAttendanceReminders}
                      onChange={this.handleAttendanceReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceRemindersViaEmail"
                      checked={dataDoctor.SubscribeToAttendanceRemindersViaEmail}
                      onChange={this.handleAttendanceReminders}
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceRemindersViaSMS"
                      checked={dataDoctor.SubscribeToAttendanceRemindersViaSMS}
                      onChange={this.handleAttendanceReminders}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Sending Dictations and Reports Reminders</label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportReminders"
                      checked={dataDoctor.SubscribeToDictationAndReportReminders}
                      onChange={this.handleDictationAndReportReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportRemindersViaEmail"
                      checked={dataDoctor.SubscribeToDictationAndReportRemindersViaEmail}
                      onChange={this.handleDictationAndReportReminders}
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportRemindersViaSMS"
                      checked={dataDoctor.SubscribeToDictationAndReportRemindersViaSMS}
                      onChange={this.handleDictationAndReportReminders}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="Form-Field-label">Cancellation Confirmations</label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentReminders"
                      checked={dataDoctor.SubscribeToCancellationReminders}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleCancellationReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentRemindersViaEmail"
                      checked={dataDoctor.SubscribeToCancellationRemindersViaEmail}
                      onChange={this.handleCancellationReminders}
                      className="Form-field-new-ui-checkbox"
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAppointmentRemindersViaSMS"
                      checked={dataDoctor.SubscribeToCancellationRemindersViaSMS}
                      onChange={this.handleCancellationReminders}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Appointment Confirmation</label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentConfirmation"
                      checked={dataDoctor.SubscribeToAppointmentConfirmation}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Appointment Reminders</label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentReminders"
                      checked={dataDoctor.SubscribeToAppointmentReminders}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleAppointmentReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentRemindersViaEmail"
                      checked={dataDoctor.SubscribeToAppointmentRemindersViaEmail}
                      onChange={this.handleAppointmentReminders}
                      className="Form-field-new-ui-checkbox"
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAppointmentRemindersViaSMS"
                      checked={dataDoctor.SubscribeToAppointmentRemindersViaSMS}
                      onChange={this.handleAppointmentReminders}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Available for Online Bookings to CMs</label>
                    <Checkbox
                      label={`${dataDoctor.AvailableForCMView ? `Available` : `Not Available`}`}
                      className="Form-field-radio"
                      name="AvailableForCMView"
                      checked={dataDoctor.AvailableForCMView}
                      disabled
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Enable login</label>
                    <Checkbox
                      label={`${dataDoctor.EnableLogin ? `Enable` : `Disable`}`}
                      className="Form-field-radio"
                      name="EnableLogin"
                      checked={dataDoctor.EnableLogin}
                      disabled
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Username</label>
                    <div>
                      <span className="Label-item">{dataDoctor.Username || 'None'}</span>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    {/* <label className="Form-Field-label">
                      Not Currently Interested (NCI)
                    </label> */}
                    <Checkbox
                      label="Not Currently Interested (NCI)"
                      className="Form-field-radio"
                      name="IsNotCurrentlyInterested"
                      disabled
                      checked={dataDoctor.IsNotCurrentlyInterested}
                      onChange={toggleCheckbox}
                      style={{ marginRight: '0px !important' }}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-30px' }} />}
                      content="Specialists marked as NCI will not be contacted or listed for bookings"
                      position="top center"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">NCI Start Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="NotCurrentlyInterestedDate"
                        animation=""
                        duration={0}
                        disabled
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.NotCurrentlyInterestedDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">NCI End Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedEndDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="NotCurrentlyInterestedEndDate"
                        animation=""
                        duration={0}
                        clearable
                        disabled
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.NotCurrentlyInterestedEndDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">NCI Comment</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedComment || 'None'}</span>
                      </div>
                    ) : (
                      <TextArea name="NotCurrentlyInterestedComment" disabled />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default GeneralInformationTab;
