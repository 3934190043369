import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { uniqBy } from 'lodash';
import { Button, Form, Checkbox, Select } from 'semantic-ui-react';

import Modal from '../../shared/Modal';
import customFetch from '../../../utils/customFetch';
import DocAndConsStore from './DocAndConsStore';
import { DatePicker, Spin } from 'antd';

const DoctorLabels = [
  { text: `ACT`, value: `ACT` },
  { text: `NSW`, value: `NSW` },
  { text: `VIC`, value: `VIC` },
  { text: `QLD`, value: `QLD` },
  { text: `SA`, value: `SA` },
  { text: `WA`, value: `WA` },
  { text: `TAS`, value: `TAS` },
  { text: `NT`, value: `NT` },
];

const datePickerFormat = 'DD/MM/YYYY';

@observer
class ModalExportDoctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exported: [],
      speItemList: [],
      accreditationList: [],
      DateFrom: null,
      DateTo: null,
      loading: true,
      IncludeRecruitingDoctors: true,
      IncludeOnboardingDoctors: true,
      IncludeOnboardedDoctors: true,
      IncludeNCIDoctors: true,
      doctorTypes1: '',
      doctorTypes2: '',
      doctorTypes3: '',
      acceptTeleAssessment: false,
      selectedSpecialties: [],
      selectedAccreditations: [],
      state: '',
    };
  }
  async componentDidMount() {
    try {
      const exportPromise = customFetch('/MedicalDoctor/GetLookUpConfig', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: 'doctor' }),
      });

      const [exportDoctor] = await Promise.all([exportPromise]);

      this.setState({
        speItemList: uniqBy(exportDoctor.speItemList),
        exported: exportDoctor,
        accreditationList: uniqBy(exportDoctor.accreditationList),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderSpeFilterOptions = () => {
    return this.state.speItemList.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderSpeFilterText = value => {
    let labels = this.state.speItemList.filter(opt => opt.Id === value).map(opt => opt.Label);
    return labels[0];
  };
  renderAccreditationFilterOptions = () => {
    return this.state.accreditationList.map(opt => ({
      text: opt.Label,
      value: opt.Label,
    }));
  };

  renderAccreditationFilterText = value => {
    let labels = this.state.accreditationList.filter(opt => opt.value === value).map(opt => opt.value);
    return labels[0];
  };

  renderDoctorFilterOptions = () => {
    return DoctorLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderDoctorFilterText = value => {
    let labels = DoctorLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  onChangeCheckBoxFalse = name => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  onChangeCheckBox = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handlePickerChange = name => momentValue => {
    this.setState({
      ...this.state,
      [name]: momentValue.format('DDMMMMYYYY'),
    });
  };
  handleOnChangeDropDown = name => (e, { value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleOnChangeAccDropDown = (e, { value }) => {
    this.setState({ ...this.state, selectedAccreditations: value });
  };

  handleExport = () => {
    const {
      IncludeRecruitingDoctors,
      IncludeOnboardingDoctors,
      IncludeOnboardedDoctors,
      IncludeNCIDoctors,
      DateFrom,
      DateTo,
      doctorTypes1,
      doctorTypes2,
      doctorTypes3,
      acceptTeleAssessment,
      state,
    } = this.state;
    const doctorTypes = [];
    if (doctorTypes1) {
      doctorTypes.push(doctorTypes1);
    }
    if (doctorTypes2) {
      doctorTypes.push(doctorTypes2);
    }
    if (doctorTypes3) {
      doctorTypes.push(doctorTypes3);
    }
    const params = Object.assign(
      {},
      {
        from: DateFrom,
        to: DateTo,
        IncludeRecruitingDoctors: IncludeRecruitingDoctors,
        IncludeOnboardingDoctors: IncludeOnboardingDoctors,
        IncludeOnboardedDoctors: IncludeOnboardedDoctors,
        IncludeNCIDoctors: IncludeNCIDoctors,
        acceptTeleAssessment: acceptTeleAssessment,
        state: state,
      },
    );
    var qs = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    var qs1 = doctorTypes.map(val => `doctorTypes=${val}`).join('&');
    var qs2 = this.state.selectedSpecialties.map(val => `speList=${val}`).join('&');
    var qs3 = this.state.selectedAccreditations.map(val => `accList=${val.replace(/\s+/g, '+')}`).join('&');
    window.open('/Report/ExportDoctor?' + qs + '&' + qs1 + '&' + qs2 + '&' + qs3, '_blank');
  };

  onCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open } = DocAndConsStore;
    const {
      exported,
      loading,
      IncludeRecruitingDoctors,
      IncludeOnboardingDoctors,
      IncludeOnboardedDoctors,
      IncludeNCIDoctors,
    } = this.state;
    return (
      <React.Fragment>
        {loading && !exported.length ? (
          <Spin size="large" className="modal-spin" />
        ) : (
          <Modal
            visible={open}
            width={900}
            onCancel={this.props.onCancel}
            footer={
              <React.Fragment>
                <Button color="blue" onClick={this.handleExport}>
                  Export
                </Button>
                <Button color="red" onClick={this.onCancel}>
                  Close
                </Button>
              </React.Fragment>
            }
            title="Export Doctor List"
          >
            <Form>
              <div className="field-date">
                <Form.Field
                  control={DatePicker}
                  label="Created from"
                  style={{ width: 250 }}
                  allowClear={false}
                  format={datePickerFormat}
                  onChange={this.handlePickerChange('DateFrom')}
                />
                <Form.Field
                  control={DatePicker}
                  label="To"
                  style={{ width: 250 }}
                  allowClear={false}
                  format={datePickerFormat}
                  onChange={this.handlePickerChange('DateTo')}
                />
              </div>
              <Form.Dropdown
                fluid
                multiple
                search
                selection
                id="form-input-first-name"
                label={{
                  children: 'Search Doctors by Specialties:',
                  htmlFor: 'form-select-control-gender',
                }}
                placeholder="Choose Doctors by Specialties"
                value={this.renderSpeFilterText()}
                options={this.renderSpeFilterOptions()}
                onChange={this.handleOnChangeDropDown('selectedSpecialties')}
              />
              <Form.Dropdown
                fluid
                multiple
                search
                selection
                id="form-input-first-name"
                label={{
                  children: 'Search Doctors by Accreditation(s):',
                  htmlFor: 'form-select-control-gender',
                }}
                placeholder="Choose Doctors by Accreditation(s)"
                value={this.renderAccreditationFilterText()}
                options={this.renderAccreditationFilterOptions()}
                onChange={this.handleOnChangeDropDown('selectedAccreditations')}
              />
              <p className="label-title">Search Doctors by Recruitment Status:</p>
              <div className="field-checkbox">
                <Checkbox
                  defaultChecked={IncludeRecruitingDoctors ? true : false}
                  label="Recruiting"
                  onChange={
                    IncludeRecruitingDoctors
                      ? () => this.onChangeCheckBoxFalse('IncludeRecruitingDoctors')
                      : () => this.onChangeCheckBox('IncludeRecruitingDoctors')
                  }
                />
                <Checkbox
                  defaultChecked={IncludeOnboardingDoctors ? true : false}
                  label="Onboarding"
                  onChange={
                    IncludeOnboardingDoctors
                      ? () => this.onChangeCheckBoxFalse('IncludeOnboardingDoctors')
                      : () => this.onChangeCheckBox('IncludeOnboardingDoctors')
                  }
                />
                <Checkbox
                  defaultChecked={IncludeOnboardedDoctors ? true : false}
                  label="Onboarded"
                  onChange={
                    IncludeOnboardedDoctors
                      ? () => this.onChangeCheckBoxFalse('IncludeOnboardedDoctors')
                      : () => this.onChangeCheckBox('IncludeOnboardedDoctors')
                  }
                />
                <Checkbox
                  defaultChecked={IncludeNCIDoctors ? true : false}
                  label="NCI"
                  onChange={
                    IncludeNCIDoctors
                      ? () => this.onChangeCheckBoxFalse('IncludeNCIDoctors')
                      : () => this.onChangeCheckBox('IncludeNCIDoctors')
                  }
                />
              </div>
              <Form.Field
                control={Select}
                label={{
                  children: 'Search Doctors by State:',
                  htmlFor: 'form-select-control-gender',
                }}
                placeholder="Choose Search Doctors by State:"
                search
                searchInput={{ id: 'form-select-control-gender' }}
                value={this.renderDoctorFilterText()}
                options={this.renderDoctorFilterOptions()}
                onChange={this.handleOnChangeDropDown('state')}
              />
              <p className="label-title">Search Doctors by type:</p>
              <div className="field-checkbox">
                <Checkbox
                  label="P"
                  onChange={
                    this.state.doctorTypes1
                      ? () => this.onChangeCheckBoxFalse('doctorTypes1')
                      : () => this.onChangeCheckBox('doctorTypes1', 'P')
                  }
                />
                <Checkbox
                  label="D"
                  onChange={
                    this.state.doctorTypes2
                      ? () => this.onChangeCheckBoxFalse('doctorTypes2')
                      : () => this.onChangeCheckBox('doctorTypes2', 'D')
                  }
                />
                <Checkbox
                  label="P&D"
                  onChange={
                    this.state.doctorTypes3
                      ? () => this.onChangeCheckBoxFalse('doctorTypes3')
                      : () => this.onChangeCheckBox('doctorTypes3', 'P%26D')
                  }
                />
              </div>
              <Checkbox
                className="checkBox"
                label="Accept Tele-assessment"
                onChange={
                  this.state.acceptTeleAssessment
                    ? () => this.onChangeCheckBoxFalse('acceptTeleAssessment')
                    : () => this.onChangeCheckBox('acceptTeleAssessment', true)
                }
              />
            </Form>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default ModalExportDoctor;
