import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import cx from 'classnames';
import TableToolbar from '../../shared/tableToolbar';
import DoctorReportsStore from './DoctorReportsStore';
import SessionAvailability from './SessionAvailability';
import * as api from '../../../stores/api';
import ModalVerifyAccessCode from './ModalVerifyAccessCode';

@observer
class DoctorReportsToolbar extends React.Component {
  state = {
    isActive: false,
    isOpenAccessCodeModal: false,
    fromToOnboardingDate:
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(`DD/MM/YYYY`) +
      ' - ' +
      moment()
        .subtract(1, 'month')
        .endOf('month')
        .format(`DD/MM/YYYY`),
    fromToAssignedDate:
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(`DD/MM/YYYY`) +
      ' - ' +
      moment()
        .subtract(1, 'month')
        .endOf('month')
        .format(`DD/MM/YYYY`),
    activeExportclinicReport: false,
  };
  openModal = options => {
    this.setState({ isActive: false });
    return DoctorReportsStore.toggleModal(true, options)();
  };

  handlePrintSearchResult = () => {
    this.setState({ isActive: false });
    const { dataDoctorReports } = DoctorReportsStore;
    if (dataDoctorReports) {
      var printContents = window.functionGetElementTableForPrint();
      var popupWin = window.open('', '_blank', 'width=800,height=600');
      popupWin.document.open();
      popupWin.document.write(
        '<html><head><link href="~/Content/build/style" rel="stylesheet" /></head><body onload="window.print()">' +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
    }
  };

  handleExportDoctorNationalDirectory = () => {
    // this.setState({ isActive: false });
    // window.open(`/report/ExportDoctorWithCombinedClinic`, '_blank');

    DoctorReportsStore.setFieldsValue({ open: true, exportUrl: '/report/ExportDoctorWithCombinedClinic' });
    this.openModal({ modalType: 'verifyAccessCode' });
  };

  handleExportAppointmentAvailability = () => {
    this.openModal({
      modalType: 'verifyAccessCode',
      showTab: () => this.setState({ isActive: true, activeExportclinicReport: false }),
    });
  };

  handleSpecialistAssignedClinics = () => {
    this.setState({ isActive: false, activeExportclinicReport: true });
    // window.open(`/report/ExportClinicReport`, '_blank');
  };

  handleExportClinictSessionHistory = () => {
    this.setState({ isActive: false });
    window.open('/Report/ExportClinicSessionHistory');
  };

  handleExportSearchResult = () => {
    this.setState({ isActive: false });
    const { DoctorId, dataDoctorReports } = DoctorReportsStore;
    const { fromDate, toDate } = DoctorReportsStore.getDateRange();
    const fromDate_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          // .subtract(1, 'd')
          .format('YYYY-MM-DD')
      : null;
    const toDate_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          // .subtract(1, 'd')
          .format('YYYY-MM-DD')
      : null;
    if (dataDoctorReports && dataDoctorReports.itemList.length !== 0) {
      window.open(
        '/Report/DownloadDoctorReports?DoctorId=' +
          DoctorId +
          '&From=' +
          encodeURIComponent(fromDate_) +
          '&To=' +
          encodeURIComponent(toDate_),
        '_blank',
      );
    }
  };

  getFromTofromDateRange = dateRange => {
    if (dateRange) {
      const [fromDate, toDate] = dateRange.split(' - ');

      return {
        fromDate: fromDate ? fromDate : null,
        toDate: toDate ? toDate : null,
      };
    } else
      return {
        fromDate: null,
        toDate: null,
      };
  };

  handleChangeRangePicker = fieldName => (_, { value }) => {
    this.setState({ [fieldName]: value });
  };

  exportClinicReport = () => {
    const { fromToOnboardingDate, fromToAssignedDate } = this.state;
    const onboardingDate = this.getFromTofromDateRange(fromToOnboardingDate);
    const assignedDate = this.getFromTofromDateRange(fromToAssignedDate);
    const fromOnboardingDate = onboardingDate.fromDate
      ? moment(onboardingDate.fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const toOnboardingDate = onboardingDate.toDate
      ? moment(onboardingDate.toDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const fromAssignedDate = assignedDate.fromDate
      ? moment(assignedDate.fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const toAssignedDate = assignedDate.toDate ? moment(assignedDate.toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';

    DoctorReportsStore.setFieldsValue({
      open: true,
      exportUrl: '/Report/ExportClinicReport',
      exportQueryParams: `FromOnboardingDate=${fromOnboardingDate}&ToOnboardingDate=${toOnboardingDate}&FromAssigned=${fromAssignedDate}&ToAssigned=${toAssignedDate}`,
    });

    this.openModal({ modalType: 'verifyAccessCode' });
    //this.setState({ ...this.state, isOpenAccessCodeModal: true });
    // window.open(
    //   `/Report/ExportClinicReport?FromOnboardingDate=${fromOnboardingDate}&ToOnboardingDate=${toOnboardingDate}&FromAssigned=${fromAssignedDate}&ToAssigned=${toAssignedDate}`,
    //   '_blank',
    // );
  };

  handleExportClinicReport = () => {
    const { fromToOnboardingDate, fromToAssignedDate } = this.state;
    const onboardingDate = this.getFromTofromDateRange(fromToOnboardingDate);
    const assignedDate = this.getFromTofromDateRange(fromToAssignedDate);
    const fromOnboardingDate = onboardingDate.fromDate
      ? moment(onboardingDate.fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const toOnboardingDate = onboardingDate.toDate
      ? moment(onboardingDate.toDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const fromAssignedDate = assignedDate.fromDate
      ? moment(assignedDate.fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
      : '';
    const toAssignedDate = assignedDate.toDate ? moment(assignedDate.toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const defaultDate = moment().format('DD/MM/YYYY');
    if (!toOnboardingDate && !toAssignedDate) {
      if (!fromAssignedDate && !fromOnboardingDate) {
        this.setState(
          {
            fromToOnboardingDate: '',
            fromToAssignedDate: '',
          },
          () => this.exportClinicReport(),
        );
      } else if (!fromAssignedDate) {
        this.setState(
          {
            fromToOnboardingDate: `${onboardingDate.fromDate} - ${defaultDate}`,
            fromToAssignedDate: '',
          },
          () => this.exportClinicReport(),
        );
      } else if (!fromOnboardingDate) {
        this.setState(
          {
            fromToOnboardingDate: '',
            fromToAssignedDate: `${assignedDate.fromDate} - ${defaultDate}`,
          },
          () => this.exportClinicReport(),
        );
      } else {
        this.setState(
          {
            fromToOnboardingDate: `${onboardingDate.fromDate} - ${defaultDate}`,
            fromToAssignedDate: `${assignedDate.fromDate} - ${defaultDate}`,
          },
          () => this.exportClinicReport(),
        );
      }
    } else if (!toAssignedDate) {
      if (!fromAssignedDate) {
        this.setState(
          {
            fromToAssignedDate: '',
          },
          () => this.exportClinicReport(),
        );
      } else {
        this.setState(
          {
            fromToAssignedDate: `${assignedDate.fromDate} - ${defaultDate}`,
          },
          () => this.exportClinicReport(),
        );
      }
    } else if (!toOnboardingDate) {
      if (!fromOnboardingDate) {
        this.setState(
          {
            fromToOnboardingDate: '',
          },
          () => this.exportClinicReport(),
        );
      } else {
        this.setState(
          {
            fromToOnboardingDate: `${onboardingDate.fromDate} - ${defaultDate}`,
          },
          () => this.exportClinicReport(),
        );
      }
    } else {
      this.exportClinicReport();
    }
  };

  handleClearDateRange = () => {
    this.setState({
      fromToOnboardingDate: '',
      fromToAssignedDate: '',
    });
  };

  handleOpenModalImport = () => {
    return DoctorReportsStore.toggleModal(true, { modalType: 'import' })();
  };

  render() {
    const { fromToOnboardingDate, fromToAssignedDate } = this.state;
    return (
      <React.Fragment>
        <form className="doctor-report-toolbar">
          <TableToolbar.Container style={{ marginTop: '5px' }}>
            <TableToolbar.Left as="div">
              {api.isAdminOrMagStaffOrAccounting() && (
                <TableToolbar.HighLightButton className={cx({ blue: DoctorReportsStore.tabIsActive === 'export' })}
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleOpenModalImport}
                >
                  Import Specialist
                </TableToolbar.HighLightButton>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <TableToolbar.HighLightButton
                  className={cx({ blue: DoctorReportsStore.tabIsActive === 'export' })}
                  style={{ marginLeft: '5px' }}
                  onClick={() =>
                    this.openModal({
                      modalType: 'exportDoctor',
                    })
                  }
                >
                  Export Specialist List
                </TableToolbar.HighLightButton>
              )}
              {api.isMAG() && api.isAdminOrMagStaffOrAccounting() && (
                <TableToolbar.HighLightButton
                  className={cx({ blue: DoctorReportsStore.tabIsActive === 'export' })}
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleSpecialistAssignedClinics}
                >
                  Export Specialists & Assigned Clinics (for UHG)
                </TableToolbar.HighLightButton>
              )}
              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExportDoctorNationalDirectory}
              >
                Export Specialist National Directory
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: '5px' }}
                //onClick={this.handleExportClinictSessionHistory}
                onClick={() =>
                  this.openModal({
                    modalType: 'exportDoctorStatus',
                  })
                }
              >
                Export Specialist & Session Status
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className={cx({ blue: DoctorReportsStore.tabIsActive === 'export' })}
                onClick={this.handleExportAppointmentAvailability}
              >
                Export Appointment Availability
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
          {this.state.activeExportclinicReport && (
            <span className="label-onboarding-date">Onboarding Dates From - To</span>
          )}
          {/* {this.state.activeExportclinicReport && (
            <span className="label-assigned-date">Clinic Assigned Dates From - To</span>
          )} */}
          {this.state.activeExportclinicReport && (
            <TableToolbar.Container>
              <TableToolbar.Left as="div">
                <TableToolbar.RangePicker
                  placeholder="From - To Onboarding Date"
                  className="Toolbar-range-picker"
                  value={fromToOnboardingDate}
                  onChange={this.handleChangeRangePicker('fromToOnboardingDate')}
                />
                {/* <TableToolbar.RangePicker
                  placeholder="From - To Clinic Assigned Date"
                  className="Toolbar-range-picker"
                  value={fromToAssignedDate}
                  onChange={this.handleChangeRangePicker('fromToAssignedDate')}
                /> */}
                <TableToolbar.HighLightButton
                  className="positive"
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleExportClinicReport}
                >
                  Export
                </TableToolbar.HighLightButton>
                <TableToolbar.HighLightButton
                  className="negative"
                  style={{ marginLeft: '5px' }}
                  onClick={() => this.handleClearDateRange()}
                >
                  Clear
                </TableToolbar.HighLightButton>
              </TableToolbar.Left>
            </TableToolbar.Container>
          )}
        </form>
        {this.state.isActive && (
          <div>
            <SessionAvailability />
          </div>
        )}
        {this.state.isOpenAccessCodeModal && (
          <ModalVerifyAccessCode {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />
        )}
      </React.Fragment>
    );
  }
}

export default DoctorReportsToolbar;
