import customFetch from '../../../utils/customFetch';

export const getAllRecords = (keyword = '', curPage = 1, numItemPerPage = 0, sortBy = '', sortDirection = '') => {
  return customFetch('/FundingRequest/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getFundingRequestInfo = (id = 0) => {
  return customFetch(`/FundingRequest/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveData = param => {
  return customFetch('/FundingRequest/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteRecord = id => {
  return customFetch('/FundingRequest/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchFundingRequest = param => {
  return customFetch('/AMAAftercareRule/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const getStatusHistory = id => {
  return customFetch(`/FundingRequest/GetStatusHistory?id=${id}`, {
    method: 'GET',
  });
};

export const getFundingProvider = keyword => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      ActionRequired: false,
      NumItemPerPage: 500,
      RecruitedOrContractedOnlyge: true,
      SortBy: 'Name',
      SortDirection: 'ASC',
      curPage: 1,
      keyword: keyword,
      ActionRequiredById: '',
      IsClientFundingProvider: true,
      IsSelectionList: true,
    }),
  });
};

export const getFundingBorrower = (keyword, numberSearch = 500) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      ActionRequired: false,
      NumItemPerPage: numberSearch,
      RecruitedOrContractedOnlyge: true,
      SortBy: 'Name',
      SortDirection: 'ASC',
      curPage: 1,
      keyword: keyword,
      ActionRequiredById: '',
      IsSelectionList: true,
    }),
  });
};

export const getApplicantByBorrower = (keyword = '', clientId = 0, numberStaff = 500) => {
  return customFetch('/Staff/GetAll?companyId=' + clientId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      numItemPerPage: numberStaff,
    }),
  });
};
