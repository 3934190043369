import Forgot from './Forgot';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import Reset from './Reset';
import RegisterDoctor from './RegisterDoctor';
import ReportStatus from './ReportStatus';
import OneTimeAccess from './OneTimeAccess';
import VideoSession from '../admin-routes/VideoSession/ViewSession';
import JaasVideoSession from '../admin-routes/VideoSession/JaasViewSession';
import JaasVideoHomePage from '../admin-routes/VideoSession/JaasVideoHomePage';

export default [
  {
    path: '/view/login',
    Component: Login,
    title: '',
  },
  {
    path: '/view/logout',
    Component: Logout,
    title: 'Login',
    ignoreAnonymous: true,
  },
  {
    path: '/view/register',
    Component: Register,
    title: 'Register Account',
    ignoreAnonymous: true,
  },
  {
    path: '/view/forgot',
    Component: Forgot,
    title: 'Forgot password',
  },
  {
    path: '/view/reset',
    Component: Reset,
    title: 'Reset password',
  },
  {
    path: '/view/register-doctor',
    Component: RegisterDoctor,
    title: 'Register Account (Specialists)',
  },
  {
    path: '/view/report',
    Component: ReportStatus,
    title: 'Check Report Status',
  },
  {
    path: '/view/onetimeaccess',
    Component: OneTimeAccess,
  },
  {
    path: '/view/video',
    Component: VideoSession,
  },
  {
    path: '/view/video-meeting',
    Component: JaasVideoSession,
  },
  {
    path: '/view/video-meeting-home',
    Component: JaasVideoHomePage,
  },
].reduce((map, route) => {
  map[route.path] = route;
  return map;
}, {});
