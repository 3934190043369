import customFetch from '../../../utils/customFetch';

export const getCampaignInfo = id => {
  return customFetch(`/ManagerCampaign/FindItem?Id=${id}`, {
    method: 'GET',
  });
};

export const getStaff = () => {
  return customFetch('/ManagerCampaign/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getConfigType = (type = 'Channel') => {
  return customFetch(`/ManagerCampaign/GetConfigData?type=${type}`, {
    method: 'GET',
  });
};

export const saveCaseManagerCampaign = bodyParam => {
  return customFetch('/ManagerCampaign/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ model: bodyParam }),
  });
};

export const saveAndSendPreivewCaseManagerCampaign = (bodyParam, isPreview = true) => {
  return customFetch('/ManagerCampaign/SaveAndSendPreview', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ model: bodyParam, isPreview }),
  });
};

export const getListCampaignScheduleHistory = (CampaignId = null, CurPage = 1, NumItemOfPage = 20) => {
  return customFetch('/ManagerCampaign/GetScheduleHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      campaignId: CampaignId,
      curPage: CurPage,
      numItemOfPage: NumItemOfPage,
    }),
  });
};
