import { showUnexpectedError } from '@utils/errors';
import { action, observable, computed } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class SalesCallReportStore {
  @observable loading = false;
  requestTimeCount = 0;
  @observable data = null;
  @observable loadingStaff = false;
  @observable dataStaff = [];
  @observable loadingClient = false;
  @observable dataClient = null;
  @observable dataCampaign = null;
  @observable loadingOfficer = false;
  @observable dataOfficer = null;

  @observable id = 0;

  @observable filter = 'All';
  @observable sortDirection = 'descending';
  @observable keywordSearch = '';
  @observable numberSearch = 30;
  @observable sortColumn = 'Case Manager';
  @observable sortKey = 'CallDate';
  @observable totalItem = 0;
  @observable dateRange = moment().format('DD/MM/YYYY - DD/MM/YYYY');

  @observable doctorName = '';
  @observable doctorEmail = '';
  @observable cmEmail = '';
  @observable cmName = '';
  @observable staffId = '';
  @observable campaignId = '';

  @observable totalItem = 0;
  @observable totalPage = 0;
  @observable callById = '';
  @observable serviceTypeId = '';

  @observable curPage = 0;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @observable activityType = 0;

  @observable activityOutcome = 0;

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action setDataFieldsValue = (itemListIndex, content = '') => {
    if (this.data?.itemList?.length > 0) {
      this.data.itemList[itemListIndex].Notes = content;
    }
  };

  @computed get searchValue() {
    const { id, dataStaff } = this;
    return dataStaff.find(i => i.Id === id);
  }

  getDateRange = () => {
    const [fromDate, toDate] = this.dateRange.split(' - ');
    return {
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
  };

  @action fetchSearchStaff = () => {
    this.loadingStaff = true;
    return customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Showing: `active`,
        Keyword: this.keywordSearch,
        NumItemPerPage: this.numberSearch,
      }),
    }).then(
      action(data => {
        this.dataStaff = data.itemList;
        this.loadingStaff = false;
      }),
    );
  };

  @action refetchSearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearchStaff();
  };

  @action fetchReport = (setTotalPage = false, checkDateRange = false) => {
    this.requestTimeCount++;

    var currentRequestCount = this.requestTimeCount;
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    if (checkDateRange && (!fromDate || !toDate)) {
      this.loading = false;
      showUnexpectedError('Please enter activity date range', true);
      return;
    }
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    return customFetch(
      `/Staff/SalesCall_LoadList?fromDate=${from}&toDate=${to}&cmEmail=${this.searchValue?.Email || ''}&cmName=${`${this
        .searchValue?.FirstName || ''} ${this.searchValue?.LastName || ''}`}&sortBy=${this.sortKey}&callById=${
        this.callById
      }&serviceTypeId=${this.serviceTypeId}&sortDirection=${this.sortDirection.replace('ending', '').toUpperCase()}&staffId=${this.staffId}&campaignId=${
        this.campaignId
      }&curPage=${this.curPage}&numItemPerPage=${this.pageSize}&activityType=${this.activityType}&activityOutcome=${
        this.activityOutcome
      }`,
      { method: 'GET' },
    ).then(
      action(data => {
        if (currentRequestCount === this.requestTimeCount) {
          this.data = data;
          if (data.curPage <= 1) {
            this.totalPage = data.sumPage;
            this.totalItem = data.sumItem;
          }
          this.loading = false;
        }
      }),
    );
  };

  @action fetchCampaign = () => {
    this.loadingClient = true;
    customFetch('/Staff/GetCampaignSalesCall').then(
      action(data => {
        this.loadingClient = false;
        this.dataCampaign = data;
      }),
    );
  };

  @action fetchClient = () => {
    this.loadingClient = true;
    customFetch('/Staff/GetStaffSalesCall?type=1').then(
      action(data => {
        this.loadingClient = false;
        this.dataClient = data;
      }),
    );
  };

  @action fetchOfficer = () => {
    this.loadingOfficer = true;
    customFetch('/Staff/GetStaffSalesCall?type=3').then(
      action(data => {
        this.loadingOfficer = false;
        this.dataOfficer = data;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchReport(true);
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchReport(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.id = 0;

    this.filter = 'All';
    this.sortDirection = 'descending';
    this.keywordSearch = '';
    this.numberSearch = 30;
    this.sortColumn = 'Case Manager';
    this.sortKey = 'CallDate';
    this.totalItem = 0;
    this.dateRange = moment().format('DD/MM/YYYY - DD/MM/YYYY');
    this.doctorName = '';
    this.doctorEmail = '';
    this.cmEmail = '';
    this.cmName = '';
    this.staffId = '';
    this.campaignId = '';
    this.totalItem = 0;
    this.totalPage = 0;
    this.callById = '';
    this.serviceTypeId = '';

    this.curPage = 0;
    this.pageSize = 20;
    this.totalPage = 0;
    this.activityType = 0;
    this.activityOutcome = 0;
    this.open = false;
    this.modalParams = {};
    this.fetchReport(true);
  };
}

export default new SalesCallReportStore();
