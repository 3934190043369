import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import M4ModifierExclusionStore from './Store';
import ModalCreate from './ModalCreate';

function M4ModifierExclusionModal() {
  const { modalType } = M4ModifierExclusionStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...M4ModifierExclusionStore} onCancel={M4ModifierExclusionStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...M4ModifierExclusionStore} onCancel={M4ModifierExclusionStore.toggleModal(false)} />;
  }
}

export default M4ModifierExclusionModal;
