import React from 'react';
import { observer } from 'mobx-react';

import AddEditStore from './Store';
import Modal from '../../shared/Modal';
import { toJS } from 'mobx';

import RecentChangeForm from './RecentChangeForm';

@observer
class ModalRecentChange extends React.Component {
  render() {
    const { open } = AddEditStore;
    const { dataRecentChange, columns, fieldNameStaffRecentChange, showDateTime = false } = AddEditStore.modalParams;
    return (
      <Modal visible={open} width={900} onCancel={AddEditStore.toggleModal(false)} title="Recent Change History">
        <RecentChangeForm
          dataRecentChange={toJS(dataRecentChange)}
          columns={toJS(columns)}
          fieldNameStaffRecentChange={toJS(fieldNameStaffRecentChange)}
          showDateTime={showDateTime}
        />
      </Modal>
    );
  }
}

export default ModalRecentChange;
