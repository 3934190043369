import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class ImagingStore {
  @observable loading = true;
  @observable loadingStaff = true;
  @observable loadingClaimant = true;
  @observable data = null;
  @observable dataStaff = null;
  @observable dataClaimant = null;

  @observable keywordClaimant = '';
  @observable numberSearch = 30;
  @observable fromTo = '';
  @observable filter = 'All';
  @observable sortDirection = 'descending';
  @observable keyword = '';
  @observable searchClaimantOnly = false;
  @observable sortColumn = 'StatusDate';
  @observable sortKey = 'StatusDate';
  @observable requestDateTimeFrom = null;
  @observable actionRequiredById = '';
  @observable showBookingToday = false;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchSuggestClaimant = () => {
    this.loadingClaimant = true;
    customFetch('/MRI/GetSuggestClaimant', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordClaimant,
        numItemPerPage: this.numberSearch,
      }),
    }).then(
      action(data => {
        this.dataClaimant = data;
        this.loadingClaimant = false;
      }),
    );
  };

  @action fetchMRIStaff = () => {
    this.loadingStaff = true;
    customFetch('/MRI/GetStaffs', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataStaff = data;
        this.loadingStaff = false;
      }),
    );
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  @action fetchJob = (setTotalPage = false) => {
    const { fromDate, toDate } = this.getDateRange();

    let from = '';
    if (fromDate) {
      from = this.showBookingToday ? fromDate.format() : fromDate.toISOString();
    }

    this.loading = true;
    customFetch('/MRI/LoadList', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        FilterType: this.filter,
        Showing: this.filter,
        Keyword: this.keyword,
        CurPage: this.curPage,
        From: from,
        SearchClaimantOnly: this.searchClaimantOnly,
        To: toDate,
        ActionRequiredById: this.actionRequiredById,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      }),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
        this.fetchMRIStaff(true);
        this.fetchSuggestClaimant(true);
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchJob();
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchJob(setTotalPage);
  };

  @action refetchClaimt = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSuggestClaimant(true);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'StatusDate';
    this.sortKey = 'StatusDate';
    this.sortDirection = 'descending';
    this.fromTo = ``;
    this.requestDateTimeFrom = null;
    this.actionRequiredById = '';
    this.showBookingToday = false;

    this.curPage = 1;
    this.totalPage = 0;
  };

  @action resetSearch = () => {
    this.numberSearch = 30;
    this.keywordClaimant = '';

    this.refetchClaimt();
  };
}

export default new ImagingStore();
