import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Select, Divider, Checkbox } from 'antd';

import ClinicStore from './store';

@observer
class OfficeMailingForm extends React.Component {
  render() {
    return (
      <Form name="office-mailing">
        <h3 className="form-subtitle fluid-field">Office Address</h3>
        <Form.Item label="Address">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.Address}
            onChange={ClinicStore.handleFieldChange('Address')}
          />
        </Form.Item>
        <Form.Item label="Suburb">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.District}
            onChange={ClinicStore.handleFieldChange('District')}
          />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.Postcode}
            onChange={ClinicStore.handleFieldChange('Postcode')}
          />
        </Form.Item>
        <Form.Item
          label="State"
          required
          validateStatus={ClinicStore.errors['CityId'] ? 'error' : null}
          help={ClinicStore.errors['CityId']}
        >
          <Select
            size="large"
            value={ClinicStore.clinicInfo.CityId ? ClinicStore.clinicInfo.CityId : null}
            disabled={ClinicStore.cityLoading}
            loading={ClinicStore.cityLoading}
            onChange={ClinicStore.handleFieldChange('CityId')}
            placeholder="--Select State--"
            optionFilterProp="children"
            showSearch
          >
            {ClinicStore.cityList[0].map(({ CityId, CityName }) => (
              <Select.Option key={CityId} value={CityId}>
                {CityName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Country">
          <Select
            size="large"
            value={ClinicStore.clinicInfo.CountryId}
            onChange={ClinicStore.handleCountryChange('CountryId')}
            showSearch
            placeholder="--Select Country--"
            optionFilterProp="children"
          >
            {ClinicStore.countryList.map(({ CountryId, CountryName }) => (
              <Select.Option key={CountryId} value={CountryId}>
                {CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Mailing Address</h3>
        <Checkbox className="fluid-field" style={{ marginBottom: '1em' }} onClick={ClinicStore.handleMailingChange}>
          Same as office address
        </Checkbox>
        <Form.Item label="Address">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.CorrespondenceAddress}
            onChange={ClinicStore.handleFieldChange('CorrespondenceAddress')}
          />
        </Form.Item>
        <Form.Item label="Suburb">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.CorrespondenceDistrict}
            onChange={ClinicStore.handleFieldChange('CorrespondenceDistrict')}
          />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input
            size="large"
            value={ClinicStore.clinicInfo.CorrespondencePostcode}
            onChange={ClinicStore.handleFieldChange('CorrespondencePostcode')}
          />
        </Form.Item>
        <Form.Item label="State">
          <Select
            size="large"
            optionFilterProp="children"
            showSearch
            value={!!ClinicStore.clinicInfo.CorrespondenceCityId ? ClinicStore.clinicInfo.CorrespondenceCityId : null}
            disabled={ClinicStore.correspondenceCityLoading}
            loading={ClinicStore.correspondenceCityLoading}
            onChange={ClinicStore.handleFieldChange('CorrespondenceCityId')}
            placeholder="--Select State--"
          >
            {ClinicStore.cityList[1].map(({ CityId, CityName }) => (
              <Select.Option key={CityId} value={CityId}>
                {CityName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Country" style={{ marginBottom: 0 }}>
          <Select
            size="large"
            value={ClinicStore.clinicInfo.CorrespondenceCountryId}
            onChange={ClinicStore.handleCountryChange('CorrespondenceCountryId')}
            placeholder="--Select Country--"
            showSearch
            optionFilterProp="children"
          >
            {ClinicStore.countryList.map(({ CountryId, CountryName }) => (
              <Select.Option key={CountryId} value={CountryId}>
                {CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default OfficeMailingForm;
