import { Menu, notification } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Grid, Segment, Table, TableBody } from 'semantic-ui-react';

import customFetch from '../../../../utils/customFetch';
import { TableEmptyMessage, TableHeader } from '../../../shared/table';
import DoctorDetailsStore from './DoctorDetailsStore';
import './DoctorDetails.scss';
import { tripHtmlFromBody } from '@utils/functions';

const columns = [
  { title: 'Date Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

@observer
class CaseCorrespondenceTab extends React.Component {
  openModal = record => {
    return DoctorDetailsStore.toggleModal(true, {
      modalType: 'addEditConversation',
      conversation: record,
    })();
  };

  handleDelete = id => {
    customFetch('/MedicalDoctor/DeleteConversationLog', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(res => {
      if (res.status === 'success') {
        DoctorDetailsStore.fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while delete data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  handleAllow = id => {
    customFetch('/MedicalDoctor/UpdateDoctorAndCMsView', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, type: 1 }),
    }).then(res => {
      if (res.status === 'success') {
        DoctorDetailsStore.fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while update data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  renderDropdownMenu = record => (
    <Menu>
      <Menu.Item onClick={() => this.openModal(record)}>Edit</Menu.Item>
      <Menu.Item
        onClick={DoctorDetailsStore.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => this.handleDelete(record.Id),
          message: 'Are you sure to delete this entry?',
        })}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  convertDateUTC = dateString => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    var utcServerTime = dataConversationLogLoadList.utcServerTime;
    var utcLocalMinutes = new Date().getTimezoneOffset();
    var addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    return moment(dateString)
      .add(addminutes, 'minutes')
      .format('DD/MM/YYYY HH:mm');
  };

  renderTableBody = () => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    const colSpan = columns.length;

    const data = toJS(dataConversationLogLoadList.itemList).filter(i => i.AllowDoctorToView);
    if (data.length === 0) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.map((i, idx) => (
      <Table.Row negative={!i.AllowDoctorToView} key={idx}>
        <Table.Cell>{this.convertDateUTC(i.SentDate)}</Table.Cell>
        <Table.Cell>
          <p className="Form-Field-Text-subject">{i.Subject}</p>
        </Table.Cell>
        <Table.Cell>
          <div className="cell-correspondence-content-body">{tripHtmlFromBody(i.Content)}</div>
          {i.Content && (
            <div className="Cell-link">
              <Link onClick={() => this.openModal(i)} to="#">
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{i.CallerSenderType}</Table.Cell>
        <Table.Cell>{i.CallerSenderName}</Table.Cell>
        <Table.Cell>{i.ReceiverType}</Table.Cell>
        <Table.Cell>{i.ReceiverName}</Table.Cell>
        <Table.Cell>{i.UserName}</Table.Cell>
        <Table.Cell>{i.IsAutoEmail ? 'Automated Email' : 'MAG Connect Email'}</Table.Cell>
      </Table.Row>
    ));
  };
  renderTable = () => {
    return (
      <Table sortable striped celled>
        <TableHeader columns={columns} />
        <TableBody>{this.renderTableBody()}</TableBody>
      </Table>
    );
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Case Correspondence</label>
                  </Form.Field>
                </Form.Group>
                {/* <Form.Group widths="equal">
                  <Button
                    className="ml-1"
                    color="blue"
                    onClick={() => this.openModal()}
                  >
                    Add New
                  </Button>
                </Form.Group> */}
                <Form.Group widths="equal">
                  <Form.Field>
                    <div className="Form-Field-table">{this.renderTable()}</div>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default CaseCorrespondenceTab;
