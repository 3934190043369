import { action, observable } from 'mobx';
import { getAllRecords, service } from './Service';

class Store {
  @observable loading = true;
  @observable data = null;

  @observable filter = '';
  @observable sortDirection = 'descending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';
  @observable totalItems = 0;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};
  @observable errors = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action addErrors = (name, message) => {
    this.errors[name] = message;
  };

  @action removeError = name => {
    delete this.errors[name];
  };

  @action fetchData = (setTotalPage = false) => {
    this.loading = true;
    Promise.all([
      getAllRecords(
        this.keyword,
        this.curPage,
        this.pageSize,
        this.sortKey,
        this.sortDirection.replace('ending', '').toUpperCase(),
      ),
    ])
      .then(
        action(([resp]) => {
          if (resp.curPage <= 1) {
            this.totalPage = resp.sumPage;
            this.totalItems = resp.sumItem;
          }
          this.data = resp;
          this.loading = false;
        }),
      )
      .catch(() => {});
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'descending';

    this.curPage = 1;
    this.totalPage = 0;
    this.fetchData(true);
  };
}

export default new Store();
