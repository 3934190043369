import './DashboardContent.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ui from '../../../stores/dashboard';
import router from '../../../stores/router';
import ContentItem from './ContentItem';

@observer
class DashboardContent extends React.Component {
  render() {
    const { pathname } = router.location;
    const {
      tabs,
      sidebar: { autohide: sidebarAutohide },
    } = ui;
    return (
      <div className={cx('DashboardContent', { sidebarAutohide })}>
        <input id="assessmentTabName" type="hidden" value="" />
        {tabs.slice(0).map(({ path }) => (
          <div key={path} className={cx('ContentItem', path === pathname && 'active')}>
            <ContentItem path={path} />
          </div>
        ))}
      </div>
    );
  }
}

export default DashboardContent;
