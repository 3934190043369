import EditableDropdown from './EditableDropdown';
import { renderDate } from './utils';

const commonInlineStyles = {
  highlightColor: { color: '#107fc9' },
  checkboxMargin: {
    marginTop: 5,
    marginBottom: 7,
  },
  checkboxBottom: {
    marginBottom: 7,
  },
  inlineBlock: {
    display: 'inline-block',
  },
  flexCenter: { display: 'flex', justifyContent: 'center' },
  marginTop5: { marginTop: 5 },
  marginLeft5: { marginLeft: 5 },
  marginTop10: { marginTop: 10 },
  marginLeft10: { marginLeft: 10 },
  marginTop_10: { marginTop: -10 },
  marginRight10: { marginRight: 10 },
  marginBottom0: { marginBottom: 0 },
  width300: { width: 300 },
  highlightInline: {
    color: '#107fc9',
    display: 'inline-block',
  },
};

//list MIME - https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc

export { EditableDropdown, renderDate, commonInlineStyles };
