import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

function TableEmptyMessage({ colSpan, message = 'No data found', className }) {
  return (
    <Table.Row>
      <Table.Cell colSpan={colSpan} className={cx('Cell-no-data-msg', className)}>
        <span>{message}</span>
      </Table.Cell>
    </Table.Row>
  );
}

TableEmptyMessage.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

export default TableEmptyMessage;
