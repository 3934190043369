import './SmartSearch.scss';

import { notification } from 'antd';
import cx from 'classnames';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { action, observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { graphql, withApollo } from 'react-apollo';
import { Button, Grid, Icon, Select } from 'semantic-ui-react';
import ModalConfirm from '@components/shared/Modal/ModalConfirm';
import customFetch from '@utils/customFetch';
import Tooltip from '../../shared/Tooltip';
import * as api from '../../../stores/api';
import ui from '../../../stores/dashboard';
import AjaxResult from '../../../utils/AjaxResult';
import Form from '../../shared/form/Form';
import { Checkbox, Radio, Search, SelectSearch, TextInput } from '../../shared/form/Input';
import accreditations from './accreditations';
import DoctorTable from './DoctorTable';
import SessionNote from './SessionNote';
import { getOptions, normalizeSubSpecialty } from './SmartSearchPrototype';
import { splitSortJoin } from './utils';
import { timezoneList } from '@utils/constants';
import moment from 'moment';

const fields = [
  { name: 'searchDoctorByType', defaultVal: 'Used' },
  { name: 'searchByDateType', defaultVal: 'AppointmentDate' },
  { name: 'startDate', defaultVal: '' },
  { name: 'datesRange', defaultVal: '' },
  { name: 'locationSearchQuery', defaultVal: '' },
  { name: 'locationDistance', defaultVal: '' },
  { name: 'onlyTeleAssessment', defaultVal: false },
];

const lookupConfigGql = gql`
  query lookUpConfigs($q: String = null) {
    lookUpConfigs(q: $q) {
      list {
        id
        label
        type
        parentId
        deleted
        description
        parent {
          id
        }
      }
      total
    }
  }
`;

const getformattedDate = date => {
  if (!date || typeof date !== 'string') return '';
  const [day, month, year] = date.split('/');
  return new Date(year, +month - 1, day).toISOString();
};

@withApollo
@graphql(lookupConfigGql, {
  name: 'lookupConfigQuery',
  options: {
    variables: {
      q: "$top=1000&$filter=deleted eq false and isActive eq true and (type eq 'spe' or type eq 'acc')",
    },
  },
})
@observer
class SmartSearch extends React.Component {
  // The main search input for doctor search
  @observable selectedDoctors = [];
  @observable arrDoctorId = [];
  @observable accreditations2 = [];
  @observable staffList = [];
  @observable searchDoctorText = '';
  @observable searchDoctorByType = 'All';
  @observable searchDoctorBatchLoading = false;
  @observable includeSurrounding = false;
  @observable lstReportTypes = [];

  searchDoctorAjaxResultAll = new AjaxResult('/Search/Doctors');
  searchDoctorAjaxResultUsed = new AjaxResult('/Search/Doctors');
  searchDoctorBatchTimeout = 0;
  @observable searchDoctorBatchLoading = false;
  @observable timeZone = moment.tz.guess();
  @observable open = false;
  @observable modalParams = {};

  renderDoctorSearchInput = () => {
    const res = this.searchDoctorByType === 'All' ? this.searchDoctorAjaxResultAll : this.searchDoctorAjaxResultUsed;

    const options = orderBy(res.data || [], ['FirstName', 'LastName']).map(r => ({
      key: r.Id,
      text: `${r.FirstName} ${r.LastName}${r.RegisterOption ? ' (' + r.RegisterOption + ')' : ''}`,
      value: r.Id,
      content: (
        <div>
          <div className="text-bold">
            {r.Title || ''} {r.FirstName} {r.LastName} {r.RegisterOption ? `(${r.RegisterOption})` : ''}
          </div>
          <div>
            - Specialties: {splitSortJoin(r.Specialties)}
            {r.RegisterOption && ' ' + r.RegisterOption}
          </div>
          <div>- Qualifications: {splitSortJoin(r.Qualifications)}</div>
        </div>
      ),
    }));
    return (
      <>
        <label className="input-label">Specialist</label>
        <SelectSearch
          search={options => {
            return options;
          }}
          multiple
          placeholder="Search"
          noResultsMessage="No doctor found"
          loading={res.loading || this.searchDoctorBatchLoading}
          options={[...this.selectedDoctors, ...options]}
          onChange={this.onDoctorSelectChange}
          value={toJS(this.arrDoctorId)}
          onSearchChange={this.onDoctorSearchChange}
          searchQuery={this.searchDoctorText}
        />
      </>
    );
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action onDoctorSearchChange = (_, { searchQuery }) => {
    this.searchDoctorText = searchQuery;
    if (this.searchDoctorBatchTimeout) {
      clearTimeout(this.searchDoctorBatchTimeout);
    }
    this.searchDoctorBatchLoading = true;
    this.searchDoctorBatchTimeout = setTimeout(() => {
      this.loadDoctorsForDropdown(searchQuery);
      this.searchDoctorBatchTimeout = 0;
    }, 680);
  };

  @action onDoctorSelectChange = (_, { value, options }) => {
    this.arrDoctorId = value;
    this.selectedDoctors = options.filter(o => !!value.find(v => v === o.value));
    this.searchDoctorText = '';
  };

  @action loadDoctorsForDropdown = (value = '') => {
    this.searchDoctorBatchLoading = false;
    if (this.searchDoctorByType === 'All') {
      this.searchDoctorAjaxResultAll.post({ used: false, keyword: value });
    } else {
      this.searchDoctorAjaxResultUsed.post({ used: true, keyword: value });
    }
  };

  // To default check the Radio `Previously used doctor`
  alreadySetSearchByUsedDoctor = false;
  @action setSearchByUsedDoctor = l => {
    if (this.alreadySetSearchByUsedDoctor || !l || !l.length) {
      return;
    }
    // this.searchDoctorByType = 'Used';
    this.alreadySetSearchByUsedDoctor = true;
  };

  @action intialLoadDataForDoctorSearch = () => {
    this.searchDoctorByType = 'Used';
    this.loadDoctorsForDropdown();
    this.searchDoctorByType = 'All';
    this.loadDoctorsForDropdown();
    this.loadReportTypes();
    // if (api.isCaseManager() && api.isMAG()) {
    //   setTimeout(() => {
    //     notification.success({
    //       message: 'Hello ' + api.currentUser.data.FullName + ',',
    //       description:
    //         'You can chat instantly with us - click on the orange bubble on the bottom right. Any feedback to improve MAG Connect experience is welcome.',
    //       duration: 0,
    //       key: 'ResetPasswordSuccess',
    //     });
    //   }, 2000);
    // }
  };

  numberPerPage = 10;

  smartSearchAjaxResult = new AjaxResult('/Search/SmartSearch');
  @observable showAdvancedSearch = true;

  formRef = null;
  @observable currentPage = 1;
  @observable subSpecialtyList = [];
  @observable specialtyList = [];
  @observable specialtyIds = [];
  @observable subSpecialtyIds = [];
  @observable accreditations = [];
  @observable accountManagers = [];
  @observable searchByDateType = 'AppointmentDate';
  @observable startDate = '';
  @observable endDate = '';

  @observable locationSearchAreas = [];
  @observable locationSearchQuery = '';
  locationLatitude = '';
  locationLongitude = '';
  @observable locationDistance = '';
  @observable locationSearchLoading = false;

  @observable searchText = '';

  @observable isMagDirect = false;
  @observable registerOption = api.isAdminOrMagStaffOrAccounting() ? 'All' : '';
  @observable onlyTeleAssessment = false;
  @observable onlyInPerson = false;
  @observable medicalNegligenceOnly = false;
  @observable includeDoctorsWithNoAppointment = true;

  componentDidMount() {
    // Override tab title for admin
    if (api.isAdminOrMagStaffOrAccounting()) {
      const tabContent = document.querySelector('.Tab-Content[title="Book a session"]');
      if (!!tabContent) {
        tabContent.title = 'Session & Doctor Search';
        const newTabTitle = tabContent.innerHTML.replace('Book a session', 'Session & Doctor Search');
        tabContent.innerHTML = newTabTitle;
      }
    }
    this.fetchSpecialTy();
    customFetch('/Default/GetSpecificLookupConfigOrderNoByType', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: 'acc' }),
    }).then(
      action(res => {
        this.accreditations2 = res.itemList;
      }),
    );
    customFetch('/MedicalDoctor/GetStaffs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.staffList = (data?.itemList || []).map(s => ({
          value: s.Id,
          text: s.FullName,
        }));
      }),
    );
    // Manually trigger all searches for initial view
    this.intialLoadDataForDoctorSearch();
    // Reload search data after book
    ui.on('open(/view/smart-search)', this._searchDebounced);
    ui.on('open(/view/book-assessment)', this._openBookAssessment);
  }

  componentDidUpdate() {
    // To default check the Radio `Previously used doctor`
    this.setSearchByUsedDoctor(this.searchDoctorAjaxResultUsed.data);
    const confs = get(this.props.lookupConfigQuery, 'lookUpConfigs.list');
    if (!accreditations.descriptions && confs) {
      runInAction(() => {
        accreditations.descriptions = confs.reduce((acc, { type, label, description }) => {
          type === 'acc' && (acc[label] = description);
          return acc;
        }, {});
      });
    }
  }

  componentWillUnmount() {
    ui.off('open(/view/smart-search)', this._searchDebounced);
    ui.off('open(/view/book-assessment)', this._openBookAssessment);
  }

  closureInputChanged = key => {
    return action((_, { value }) => {
      this[key] = value;
      if (key === 'specialtyIds') {
        this.fetchSubSpecialtyFromParents();
      } else if (key === 'searchDoctorByType') {
        this.arrDoctorId = [];
      }
    });
  };

  @action toggleAdvancedSearch = () => {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  };

  @action onAppointmentDateChanged = value => {
    const [start = '', end = ''] = value.split(' - ');
    [this.startDate, this.endDate] = [start, end];
  };

  @action onCompletionDateChange = value => {
    this.startDate = value;
  };

  @action onClinicAreaSearchChanged = value => {
    const searchParams = { searchTerm: value };
    this.searchText = value;
    clearTimeout(this.clinicAreaSearchTimeout);
    this.clinicAreaSearchTimeout = setTimeout(
      action(() => {
        this.locationSearchLoading = true;
        api.searchClinicArea.post(searchParams, res => {
          if (res && res.status === 'success') {
            const { result = [] } = res;
            console.log(result);
            this.locationSearchAreas = result;
          } else {
            this.locationSearchAreas = [];
          }
          this.locationSearchLoading = false;
        });
      }),
      1000,
    );
  };

  @action onClinicAreaSelected = ({ id }) => {
    this.searchText = id;
    this.locationSearchQuery = id;
    let area = this.locationSearchQuery;
    if (area.indexOf('2000') > -1) {
      area = 'sydney';
    }
    area = `australia+${area}`;
    window.MAG__Geocoder.geocode({ address: area }, this.onGeoCodeResponse);
    this.open = true;
    this.modalParams = {
      message: 'Do you wish to include specialists available via video appointments?',
      onOk: action(() => {
        this.onlyInPerson = false;
        this.onlyTeleAssessment = false;
      }),
      functionCallbackCancel: action(() => {
        this.onlyInPerson = true;
        this.onlyTeleAssessment = false;
      }),
    };
  };

  @action onGeoCodeResponse = (results, status) => {
    if (status === 'OK') {
      const location = results[0].geometry.location;
      this.locationLatitude = location.lat();
      this.locationLongitude = location.lng();
    } else {
      notification.error({
        message: 'Error',
        description: 'Can not get geo location from this area',
        duration: 0,
        key: 'GeocoderError',
      });
      this.locationSearchQuery = '';
      this.locationLatitude = '';
      this.locationLongitude = '';
      document.querySelector('input[name="locationSearchQuery"]').value = '';
    }
    this.locationSearchLoading = false;
  };

  onTwinInputFocus = () => {
    document.querySelector('.twinInput .Search .Input').classList.add('active');
  };

  onTwinInputBlur = () => {
    document.querySelector('.twinInput .Search .Input').classList.remove('active');
  };

  @action resetForm = () => {
    fields.forEach(({ name, defaultVal }) => {
      this.formRef.formikCtx.form.values[name] = defaultVal;
    });

    const form = document.querySelector('form#AdvancedForm');
    form.reset();
    [...form.querySelectorAll('input')].forEach(i => (i.value = ''));

    this.searchDoctorByType = 'All';
    this.selectedDoctors = [];
    this.arrDoctorId = [];
    this.searchDoctorText = '';
    this.specialtyIds = [];
    this.subSpecialtyIds = [];
    this.accreditations = [];
    this.accountManagers = [];
    this.searchByDateType = 'AppointmentDate';
    this.startDate = '';
    this.endDate = '';
    this.locationSearchQuery = '';
    this.locationLatitude = '';
    this.locationLongitude = '';
    this.locationDistance = '';
    this.registerOption = api.isAdminOrMagStaffOrAccounting() ? 'All' : '';
    this.isMagDirect = false;
    this.onlyTeleAssessment = false;
    this.onlyInPerson = false;
    this.medicalNegligenceOnly = false;
    this.includeSurrounding = false;
    this.searchText = '';
    this.loadReportTypes();
  };

  @action onSearch = () => {
    this.showAdvancedSearch = false;
    this._onSearch();
  };

  @action handleExportSearch = () => {
    this.onExportSearchResultClick();
    // if (this.state.search) {
    // const searchParams = this.getSearchParams();
    // const queryString = require('query-string');
    // const params = queryString.stringify(searchParams, { sort: false });
    // window.open('/Search/ExportIMEDoctor?' + params, '_blank');
    // }
  };

  getSearchParams = () => {
    console.log(this.searchText);
    const [district, postCode] = this.searchText != null ? this.searchText.split(',').map(str => str.trim()) : null;

    const searchParams = {
      ArrDoctorId: this.arrDoctorId.length ? this.arrDoctorId : null,
      SpecialtyIds: this.specialtyIds.length ? this.specialtyIds : null,
      SubSpecialtyIds: this.subSpecialtyIds.length ? this.subSpecialtyIds : null,
      Accreditations: this.accreditations.length ? this.accreditations : null,
      AccountManagers: this.accountManagers.length ? this.accountManagers : null,
      SearchByUsedDoctor: this.searchDoctorByType === 'Used',
      RegisterOption: this.registerOption,
      IsMagDirect: this.isMagDirect,

      PostCodeLatitude: Number(this.locationLatitude) || null,
      PostCodeLongitude: Number(this.locationLongitude) || null,
      DistanceInKm: Number(this.locationDistance) || null,

      OnlyTeleAssessment: this.onlyTeleAssessment,
      OnlyInPerson: this.onlyInPerson,
      MedicalNegligenceOnly: this.medicalNegligenceOnly,
      IncludeDoctorsWithNoAppointment: this.includeDoctorsWithNoAppointment,
      Location: this.locationSearchQuery,
      District: district,
      PostCode: postCode,
    };

    if (this.searchByDateType === 'AppointmentDate') {
      if (this.endDate) {
        searchParams.AppointmentDateTo = moment(getformattedDate(this.endDate))
          .endOf('day')
          .tz(this.timeZone)
          .format();
      }
      searchParams.AppointmentDateFrom = moment(getformattedDate(this.startDate) || new Date())
        .startOf('day')
        .tz(this.timeZone)
        .format();
    } else if (this.searchByDateType === 'ExpectedCompletionDate') {
      searchParams.ReportCompletionDate = getformattedDate(this.startDate);
    }
    return searchParams;
  };

  loadReportTypes = () => {
    customFetch('/Default/GetActiveReportTypeList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(resp => {
      this.reportTypes = resp.itemList;
    });
  };

  @action onExportSearchResultClick = () => {
    var obj = Object.entries(this.getSearchParams())
      .map(([key, val]) => {
        if (!val) {
          return null;
        }

        if (Array.isArray(toJS(val))) {
          const arr = val;
          return arr.map((value, i) => `${key}[${i}]=${encodeURIComponent(value)}`).join('&');
        }
        return `${key}=${encodeURIComponent(val)}`;
      })
      .join('&');
    const {
      Accreditations,
      ArrDoctorId,
      DistanceInKm,
      SpecialtyIds,
      SubSpecialtyIds,
      Location,
    } = this.getSearchParams();

    if (!Accreditations && !ArrDoctorId && !DistanceInKm && !SpecialtyIds && !SubSpecialtyIds && !Location) {
      return notification.error({
        message: 'Error',
        description: 'Please perform the search first',
      });
    }
    window.open(`/search/exportsearchresult?${obj}`, '_blank');
  };

  @action _onSearch = () => {
    const searchParams = this.getSearchParams();
    // Perform the ajax request
    this.smartSearchAjaxResult.post(searchParams).then(
      action(() => {
        // Insert ImmediateAvailable accordion
        this.smartSearchAjaxResult.data.splice(1, 0, {
          ImmediateAvailable: true,
        });
      }),
    );
  };

  // Reload search data after book
  openingBookAssessment = false;
  _openBookAssessment = () => {
    this.openingBookAssessment = true;
  };
  _searchDebounced = debounce(() => {
    if (!this.openingBookAssessment) {
      return;
    }
    this.openingBookAssessment = false;
    if (this.smartSearchAjaxResult.data?.length) {
      this._onSearch();
    }
  }, 1000);

  @action onPageChange = (e, { activePage }) => {
    this.currentPage = activePage;
  };

  @action onClearPostCode = () => {
    this.searchText = '';
    this.locationSearchQuery = '';
    this.locationLatitude = '';
    this.locationLongitude = '';
    document.querySelector('input[name="locationSearchQuery"]').value = '';
  };

  @action fetchSubSpecialtyFromParents = async () => {
    const res = await customFetch('/Staff/GetSpecificLookupConfigByType', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        type: 'subspe',
      }),
    });
    this.setFieldsValue({
      subSpecialtyList: (res?.itemList ?? [])
        .filter(i => !!this.specialtyIds.includes(i.ParentId))
        .map(i => ({
          ...i,
          id: i.Id,
          label: i.Label,
          parentId: i.ParentId,
        })),
    });
  };

  fetchSpecialTy = async () => {
    let lookupTypes = ['spe'];
    const res = await customFetch('/Default/GetLookupConfigByMultiTypes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ types: lookupTypes }),
    });
    this.setFieldsValue({
      specialtyList: (res?.itemList ?? []).map(i => ({
        ...i,
        id: i.Id,
        label: i.Label,
        parentId: i.ParentId,
      })),
    });
  };

  renderToolbox = () => {
    const clinicAreaResults = this.locationSearchAreas.map(ca => {
      return {
        id: ca,
        key: ca,
        title: ca,
      };
    });

    const renderAdvancedSearchForm = () => {
      const { lookupConfigQuery } = this.props;
      const lookupConfs = get(lookupConfigQuery, 'lookUpConfigs.list');
      const specialtyList = [];
      const accreditationList = [];

      (lookupConfs || []).forEach(al => {
        al.type === 'spe' ? specialtyList.push(al) : accreditationList.push(al);
      });

      // const selects = [
      //   {
      //     name: 'specialtyIds',
      //     label: 'Specialties',
      //     placeholder: 'Search specialties',
      //     options: getOptions(specialtyList),
      //     value: toJS(this.specialtyIds),
      //   },
      //   {
      //     name: 'subSpecialtyIds',
      //     label: 'Sub-specialties',
      //     placeholder: 'Search sub-specialties',
      //     options: getOptions(this.subSpecialtyList),
      //     disabled: !this.subSpecialtyList.length,
      //     value: toJS(this.subSpecialtyIds),
      //   },
      //   {
      //     name: 'accreditations',
      //     label: 'Accreditation/Report Type',
      //     placeholder: 'Search accreditations/report types',
      //     options: getOptions(accreditationList, 'acc'),
      //     value: toJS(this.accreditations),
      //   },
      // ];

      // const formattedDateRange = this.startDate
      //   ? this.endDate
      //     ? `${this.startDate} - ${this.endDate}`
      //     : `${this.startDate} -`
      //   : '';

      return (
        <div className="AdvancedForm">
          <Grid columns={3}>
            <Grid.Row className="py-0"></Grid.Row>
            <Grid.Row className="pt-0">
              <Grid.Column key="specialtyIds">
                <label className="input-label">Specialty</label>
                <SelectSearch
                  multiple
                  upward={false}
                  placeholder="Search"
                  onChange={this.closureInputChanged('specialtyIds')}
                  options={getOptions(this.specialtyList)}
                  value={toJS(this.specialtyIds)}
                  onSearchChange={() => this.fetchSpecialTy()}
                />
              </Grid.Column>
              <Grid.Column key="subSpecialtyIds">
                <label className="input-label">Sub-Specialty</label>
                <SelectSearch
                  multiple
                  upward={false}
                  placeholder="Search"
                  onChange={this.closureInputChanged('subSpecialtyIds')}
                  disabled={!this.subSpecialtyList.length}
                  options={getOptions(this.subSpecialtyList)}
                  value={toJS(this.subSpecialtyIds)}
                  onSearchChange={() => this.fetchSubSpecialtyFromParents()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="pt-0">
              <Grid.Column key="subSpecialtyIds">
                <label className="input-label">Accreditation/Report Type</label>
                <SelectSearch
                  multiple
                  upward={false}
                  placeholder="Search"
                  onChange={this.closureInputChanged('accreditations')}
                  options={getOptions(this.accreditations2, 'acc')}
                  value={toJS(this.accreditations)}
                />
              </Grid.Column>
              <Grid.Column>
                <label
                  style={{
                    display: 'inline-flex',
                    marginBottom: 5,
                    color: '#4a4a4a',
                  }}
                >
                  City/Suburb/Postcode{' '}
                  <Checkbox
                    checked={this.includeSurrounding}
                    onChange={action(() => {
                      this.includeSurrounding = !this.includeSurrounding;
                      this.locationDistance = '';
                    })}
                    className="mt-0 ml-1"
                    label="Include surrounding areas"
                    name="includeSurrounding"
                  />
                </label>
                <div className="twinInput">
                  <Search
                    ref={ref => (this.formRef = ref)}
                    name="locationSearchQuery"
                    placeholder="Search"
                    onFocus={this.onTwinInputFocus}
                    onBlur={this.onTwinInputBlur}
                    loading={this.locationSearchLoading}
                    disabled={this.locationSearchLoading}
                    results={clinicAreaResults}
                    icon={this.searchText && <Icon name="close" onClick={this.onClearPostCode} link />}
                    value={this.searchText}
                    onSearchChange={this.onClinicAreaSearchChanged}
                    onResultSelect={this.onClinicAreaSelected}
                  />
                  <TextInput
                    className="input--overridden-style"
                    placeholder="Distance in km"
                    autoComplete="off"
                    name="locationDistance"
                    onFocus={this.onTwinInputFocus}
                    onBlur={this.onTwinInputBlur}
                    disabled={!this.includeSurrounding}
                    onInput={e => this.closureInputChanged('locationDistance')(null, e.target)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="pt-0">
              <Grid.Column key="accountManager">
                <label className="input-label">Account Manager</label>
                <SelectSearch
                  multiple
                  upward={false}
                  placeholder="Search"
                  onChange={this.closureInputChanged('accountManagers')}
                  options={this.staffList}
                  value={toJS(this.accountManagers)}
                />
              </Grid.Column>
            </Grid.Row>
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <React.Fragment>
                <Grid.Row className="pt-0">
                  <Grid.Column width={16}>
                    <label>Specialists Type:</label>
                    {['P', 'D', 'P & D', 'All'].map((opt, idx) => (
                      <Radio
                        key={opt}
                        name="registerOption"
                        label={opt}
                        value={opt}
                        checked={this.registerOption === opt}
                        onChange={this.closureInputChanged('registerOption')}
                        className={cx('pr-2', { 'ml-1': !idx })}
                      />
                    ))}
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )} */}

            <label>Show</label>
            <Grid.Row className="py-0 mb-1 additional-checkbox">
              <Grid.Column width={16}>
                <Checkbox
                  name="onlyInPerson"
                  label="In-person appointments only"
                  checked={this.onlyInPerson}
                  onChange={action(() => (this.onlyInPerson = !this.onlyInPerson))}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="py-0 mb-1 additional-checkbox">
              <Grid.Column width={16}>
                <Checkbox
                  name="onlyTeleAssessment"
                  label="Telehealth appointments only"
                  checked={this.onlyTeleAssessment}
                  onChange={action(() => (this.onlyTeleAssessment = !this.onlyTeleAssessment))}
                />
              </Grid.Column>
            </Grid.Row>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Grid.Row className="py-0 mb-1 additional-checkbox">
                <Grid.Column width={16}>
                  <Checkbox
                    name="medNegStatus"
                    label="Specialists accepting Medical Negligence only"
                    checked={this.medicalNegligenceOnly}
                    onChange={action(() => (this.medicalNegligenceOnly = !this.medicalNegligenceOnly))}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Grid.Row className="py-0 mb-1 additional-checkbox">
                <Grid.Column width={16}>
                  <Checkbox
                    className="d-inline"
                    name="isMagDirect"
                    label="Direct specialists only"
                    checked={this.isMagDirect}
                    onChange={action(() => (this.isMagDirect = !this.isMagDirect))}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {/* <Grid.Row className="pb-0 text-center">
              <Grid.Column width={16}>{this.renderSearchButton()}</Grid.Column>
            </Grid.Row> */}
          </Grid>
        </div>
      );
    };

    return (
      <div>
        <React.Fragment>
          <Grid columns={3} className="mx-auto pb-1 pt-1">
            <Grid.Row className="pb-0 pt-0" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <label className="Radio-area">
                <Radio
                  name="searchDoctorByType"
                  label="All Specialists"
                  value="All"
                  checked={this.searchDoctorByType === 'All'}
                  onChange={this.closureInputChanged('searchDoctorByType')}
                  disabled={!api.isCaseManager()}
                />
                <Tooltip appear="Previously booked or recommended doctors.">
                  <p>
                    <Radio
                      name="searchDoctorByType"
                      label="Previously Booked Specialists"
                      value="Used"
                      checked={this.searchDoctorByType === 'Used'}
                      onChange={this.closureInputChanged('searchDoctorByType')}
                      className="ml-1"
                      disabled={!api.isCaseManager()}
                    />
                  </p>
                </Tooltip>
              </label>

              <label>
                Current Timezone
                <span className="time-zone-appointment-search" style={{ marginLeft: 10 }}>
                  <Select
                    value={this.timeZone}
                    options={timezoneList.map(i => ({ key: i, value: i, text: i }))}
                    search
                    onChange={(_, { value }) => this.setFieldsValue({ timeZone: value })}
                  />
                </span>
              </label>
            </Grid.Row>

            <Grid.Row className="py-0 relative search-toolbar">
              <div className="doctor-search-input">{this.renderDoctorSearchInput()}</div>
              <div style={{ marginTop: '10px' }}>{this.renderSearchButton()}</div>
            </Grid.Row>
            <Grid.Row className="px-0 py-0">
              <div
                className={cx('AdvancedFormContainer', {
                  hidden: !this.showAdvancedSearch,
                })}
              >
                <div style={{ paddingBottom: 5, paddingTop: 5 }}>
                  <span style={{ color: 'red' }} className="time-zone-appointment-search">
                    * Note: All search fields are optional
                  </span>
                </div>

                {renderAdvancedSearchForm()}
              </div>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      </div>
    );
  };

  renderAdvancedSearchButton = () => {
    let iconName = 'angle down';
    let txtBtn = 'SHOW FILTERS';
    if (this.showAdvancedSearch) {
      iconName = 'angle up';
      txtBtn = 'HIDE FILTERS';
    }

    return (
      <Button
        color="green"
        icon
        type="button"
        labelPosition="right"
        active={this.showAdvancedSearch}
        onClick={this.toggleAdvancedSearch}
        style={{ height: '2.875em' }}
      >
        {txtBtn}
        <Icon name={iconName} />
      </Button>
    );
  };

  renderSearchButton = () => (
    <React.Fragment>
      <Button
        type="button"
        color="blue"
        icon="search"
        labelPosition="right"
        content="SEARCH"
        onClick={this.onSearch}
        disabled={this.smartSearchAjaxResult.loading}
        loading={this.smartSearchAjaxResult.loading}
        style={{ height: '2.875em' }}
      />
      {this.renderAdvancedSearchButton()}

      <Button
        color="red"
        labelPosition="right"
        style={{ height: '2.875em' }}
        icon
        type="button"
        onClick={this.resetForm}
      >
        <span>CLEAR</span>
        <Icon name="close" />
      </Button>
      {api.isAdminOrMagStaffOrAccounting() && (
        <Button
          icon
          color="green"
          labelPosition="right"
          style={{ height: '2.875em' }}
          className="button-export"
          type="button"
          onClick={this.handleExportSearch}
        >
          <span>EXPORT</span>
          <Icon name="file excel" />
        </Button>
      )}
    </React.Fragment>
  );

  render() {
    return (
      <div>
        {!api.isCMInvoiceCheck() && <React.Fragment>
          <Form id="AdvancedForm" noAutoRender fields={fields}>
            {this.renderToolbox()}
          </Form>
          <SessionNote />
          <DoctorTable
            smartSearchResult={this.smartSearchAjaxResult.data}
            onlyTeleAssessment={this.onlyTeleAssessment}
            timeZone={this.timeZone}
            reportTypes={this.reportTypes}
          />
          {this.open && (
            <ModalConfirm open={this.open} modalParams={this.modalParams} onCancel={action(() => (this.open = false))} />
          )}
        </React.Fragment>}
      </div>
    );
  }
}

export default SmartSearch;
