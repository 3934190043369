import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, notification, Spin, Select, InputNumber, DatePicker, TimePicker, Tooltip } from 'antd';
import { Segment, Dimmer, Loader, Button, Icon, Checkbox } from 'semantic-ui-react';
import { renderDate } from '../Shared';
import * as api from '../../../stores/api';
import store from './Store';
import mainStore from '../../admin-routes/FundingAgreement/Store';
import DragDropFile from '../Shared/DragDropComponent';
import { saveData, getFundedCompanies, getSigningPerson } from '../../admin-routes/FundingAgreement/Service';
import { action, toJS, observable } from 'mobx';
import dashboardStore from '@stores/dashboard';

@observer
class FormAddEdit extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};
  state = {
    canEdit: api.isAdmin(),
  };

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };
  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  dragdropUploadFile = (fileList, reportType) => {
    const { lstFileAttachments } = store;
    const attachment = {
      FileName: fileList[0].name,
      Title: fileList[0].title,
      NumberOfPages: fileList[0].numberOfPages,
      Type: 3,
      CaseId: store.fundingAgreementInfo.Id,
      AttachmentType: reportType,
      Id: 0,
    };
    const newAttachments = [...lstFileAttachments, attachment].map(i => ({
      ...i,
      key: i.FileName,
    }));
    store.setLstData('lstFileAttachments', newAttachments);
  };

  dragdropDeleteFile = key => {
    const { lstFileAttachments } = store;
    const newAttachments = lstFileAttachments.filter(i => i.key !== key);
    store.setLstData('lstFileAttachments', newAttachments);
  };

  renderAttachment = (Attachments = [], attachmentType) => {
    return Attachments.filter(i => i.AttachmentType === attachmentType);
  };

  @action handSave = async () => {
    const { fundingAgreementInfo, lstFileAttachments } = store;
    if (
      !fundingAgreementInfo.FundingProvider ||
      !fundingAgreementInfo.FundedCompanyId ||
      !fundingAgreementInfo.GrossMargin ||
      !fundingAgreementInfo.FundingAmount
    ) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'All required fields must be updated',
      });
      return;
    }
    var saveDataObj = fundingAgreementInfo;
    if (lstFileAttachments && lstFileAttachments.length > 0) {
      saveDataObj.Attachments = lstFileAttachments;
    } else {
      saveDataObj.Attachments = [];
    }
    console.log(saveDataObj);
    saveData(saveDataObj).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
        });
        var record = resp.model;
        mainStore.refetch({});
        localStorage.setItem('FundingAgreement', record.AgreementNo + ' - Agreement');
        dashboardStore.close('/view/add-edit-funding-agreement');
        return setTimeout(() => {
          dashboardStore.open(`/view/add-edit-funding-agreement?id=${record.Id}`);
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again.',
        });
      }
    });
  };
  handleClose = () => {
    window.closeCurrentTab();
    store.onReset();
  };
  onClose = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'All unsaved changes will be discarded. Do you want to continue?',
      onOk: () => this.handleClose(),
    })();
  };

  @action handleSearchLender = () =>
    action(event => {
      const value = event;
      if (value.length > 2) {
        getFundedCompanies(value).then(
          action(resp => {
            store.setLstData('lstLenders', resp.itemList || []);
          }),
        );
      }
    });

  @action handleSearchBorrower = () =>
    action(event => {
      const value = event;
      if (value.length > 2) {
        getFundedCompanies(value).then(
          action(resp => {
            store.setLstData('lstFundedCompanies', resp.itemList || []);
          }),
        );
      }
    });

  @action handleSearchSignPersonProvider = () =>
    action(event => {
      const { fundingAgreementInfo } = store;
      const value = event;
      if (value.length > 2) {
        getSigningPerson(value, fundingAgreementInfo.FundingProvider).then(
          action(resp => {
            store.setLstData('lstLenderSigningPersons', resp.itemList || []);
          }),
        );
      }
    });

  @action handleSearchSignPersonLawyer = () =>
    action(event => {
      const { fundingAgreementInfo } = store;
      const value = event;
      if (value.length > 2) {
        getSigningPerson(value, fundingAgreementInfo.SignPersonLawyer).then(
          action(resp => {
            store.setLstData('lstBorrowerSigningPersons', resp.itemList || []);
          }),
        );
      }
    });

  render() {
    const {
      open,
      lstFileAttachments,
      lstFundingRequest,
      lstFundedCompanies,
      lstLenders,
      lstLenderSigningPersons,
      lstBorrowerSigningPersons,
      fundingAgreementInfo,
    } = store;
    return (
      <Segment size="massive">
        <Dimmer>
          <Loader />
        </Dimmer>
        <div className="form-container">
          <Form name="funding-agreement" className="grid-style estimate-form">
            <Form.Item label="Facility Agreement No">
              <Input
                size="large"
                type="text"
                disabled={true}
                value={fundingAgreementInfo.AgreementNo}
                onChange={store.handleFieldChange('AgreementNo')}
              />
            </Form.Item>
            <Form.Item label="Facility Application No">
              <Select
                size="large"
                style={{ width: '100%' }}
                value={fundingAgreementInfo.RequestId}
                onChange={store.handleFieldChange('RequestId')}
              >
                {lstFundingRequest &&
                  lstFundingRequest.map(i => (
                    <Select.Option value={i.Id} key={i.Id}>
                      {i.RequestNo}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Lender"
              required={true}
              validateStatus={!fundingAgreementInfo.FundingProvider ? 'error' : null}
              help={!fundingAgreementInfo.FundingProvider ? 'This field is required' : null}
            >
              <Select
                size="large"
                style={{ width: '100%' }}
                value={fundingAgreementInfo.FundingProvider}
                onChange={store.handleFieldChange('FundingProvider')}
                onSearch={this.handleSearchLender()}
                showSearch
                filterOption="children"
              >
                {fundingAgreementInfo.FundingProvider &&
                  !lstLenders.find(l => l.Id === fundingAgreementInfo.FundingProvider) &&
                  fundingAgreementInfo.FundingProviderObj && (
                    <Select.Option value={fundingAgreementInfo.FundingProvider} style={{ display: 'none' }}>
                      {fundingAgreementInfo.FundingProviderObj.Id} - {fundingAgreementInfo.FundingProviderObj.Name}
                    </Select.Option>
                  )}
                {lstLenders &&
                  lstLenders.map(i => (
                    <Select.Option value={i.Id} key={'lender-c-' + i.Id}>
                      {i.Id} - {i.Name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Borrower"
              required={true}
              validateStatus={!fundingAgreementInfo.FundedCompanyId ? 'error' : null}
              help={!fundingAgreementInfo.FundedCompanyId ? 'This field is required' : null}
            >
              <Select
                size="large"
                style={{ width: '100%' }}
                value={fundingAgreementInfo.FundedCompanyId}
                onChange={store.handleFieldChange('FundedCompanyId')}
                onSearch={this.handleSearchBorrower()}
                showSearch
                filterOption="children"
              >
                {lstFundedCompanies &&
                  lstFundedCompanies.map(i => (
                    <Select.Option value={i.Id} key={'fund-c-' + i.Id}>
                      {i.Id} - {i.Name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Signing Person (Lender)">
              <Select
                size="large"
                style={{ width: '100%' }}
                value={fundingAgreementInfo.SignPersonProvider}
                onChange={store.handleFieldChange('SignPersonProvider')}
                onSearch={this.handleSearchSignPersonProvider()}
                showSearch
                filterOption="children"
              >
                {fundingAgreementInfo.SignPersonProvider &&
                  !lstLenderSigningPersons.find(s => s.Id === fundingAgreementInfo.SignPersonProvider) &&
                  fundingAgreementInfo.SignPersonProviderObj && (
                    <Select.Option value={fundingAgreementInfo.SignPersonProvider} style={{ display: 'none' }}>
                      {fundingAgreementInfo.SignPersonProviderObj.Id} -{' '}
                      {fundingAgreementInfo.SignPersonProviderObj.FullName}
                    </Select.Option>
                  )}
                {lstLenderSigningPersons &&
                  lstLenderSigningPersons.map(i => (
                    <Select.Option value={i.Id} key={'fund-p-' + i.Id}>
                      {i.Id} - {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Signing Person (Law Firm)">
              <Select
                size="large"
                style={{ width: '100%' }}
                value={fundingAgreementInfo.SignPersonLawyer}
                onChange={store.handleFieldChange('SignPersonLawyer')}
                onSearch={this.handleSearchSignPersonLawyer()}
                showSearch
                filterOption="children"
              >
                {fundingAgreementInfo.SignPersonLawyer &&
                  !lstBorrowerSigningPersons.find(s => s.Id === fundingAgreementInfo.SignPersonLawyer) &&
                  fundingAgreementInfo.SignPersonLawyerObj && (
                    <Select.Option value={fundingAgreementInfo.SignPersonLawyer} style={{ display: 'none' }}>
                      {fundingAgreementInfo.SignPersonLawyerObj.Id} -{' '}
                      {fundingAgreementInfo.SignPersonLawyerObj.FullName}
                    </Select.Option>
                  )}
                {lstBorrowerSigningPersons &&
                  lstBorrowerSigningPersons.map(i => (
                    <Select.Option value={i.Id} key={'fund-p-' + i.Id}>
                      {i.Id} - {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
              {/* <Input
                type="text"
                size="large"
                value={fundingAgreementInfo.SignPersonLawyer}
                onChange={store.handleFieldChange('SignPersonLawyer')}
              /> */}
            </Form.Item>
            <Form.Item label="Signing Date">
              <DatePicker
                size="large"
                allowClear={false}
                format="DD/MM/YYYY"
                value={renderDate(fundingAgreementInfo.SigningDate, true, false)}
                onChange={store.handleDateFieldChange('SigningDate')}
              />
            </Form.Item>
            <Form.Item label="Facility Period">
              <React.Fragment>
                <div style={{ float: 'left' }}>
                  <div className="ant-col ant-form-item-control-wrapper">
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      value={renderDate(fundingAgreementInfo.FundingPeriodFrom, true, false)}
                      onChange={store.handleDateFieldChange('FundingPeriodFrom')}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: '10px', float: 'left' }}>
                  <div className="ant-col ant-form-item-control-wrapper">
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      value={renderDate(fundingAgreementInfo.FundingPeriodTo, true, false)}
                      onChange={store.handleDateFieldChange('FundingPeriodTo')}
                    />
                  </div>
                </div>
              </React.Fragment>
            </Form.Item>
            <Form.Item
              label="Gross Margin"
              required={true}
              validateStatus={!fundingAgreementInfo.GrossMargin ? 'error' : null}
              help={!fundingAgreementInfo.GrossMargin ? 'This field is required' : null}
            >
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={fundingAgreementInfo.GrossMargin}
                onChange={store.handleFieldChange('GrossMargin')}
              />
            </Form.Item>
            <Form.Item label="Special Conditions">
              <Input
                size="large"
                style={{ width: '100%' }}
                className="input-manual-rate"
                // addonBefore="$"
                // formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={fundingAgreementInfo.Discount}
                onChange={store.handleFieldChange('Discount')}
              />
            </Form.Item>
            <Form.Item
              label="Facility Limit"
              required={true}
              validateStatus={!fundingAgreementInfo.FundingAmount ? 'error' : null}
              help={!fundingAgreementInfo.FundingAmount ? 'This field is required' : null}
            >
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={fundingAgreementInfo.FundingAmount}
                onChange={store.handleFieldChange('FundingAmount')}
              />
            </Form.Item>
            <Form.Item label="Available Amount">
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                className="input-manual-rate"
                addonBefore="$"
                disabled={true}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={fundingAgreementInfo.FundingAmountRemaining}
                onChange={store.handleFieldChange('FundingAmountRemaining')}
              />
            </Form.Item>
            <Form.Item label="" style={{ paddingTop: '20px' }}>
              <div>
                Overfacility Limit Applicable:
                <span>
                  <Checkbox
                    style={{ paddingTop: '4px', paddingLeft: '10px' }}
                    onChange={store.handleCheckboxFieldChange('OverfundingLimitApplicable')}
                    checked={fundingAgreementInfo.OverfundingLimitApplicable}
                  ></Checkbox>
                </span>
              </div>
            </Form.Item>
            <Form.Item label="Facility Review Date">
              <DatePicker
                size="large"
                allowClear={false}
                format="DD/MM/YYYY"
                value={renderDate(fundingAgreementInfo.FacilityExpiryDate, true, false)}
                onChange={store.handleDateFieldChange('FacilityExpiryDate')}
              />
            </Form.Item>
            <Form.Item label="Attached Agreement" className="fluid-field">
              <DragDropFile
                onUploadFile={this.dragdropUploadFile}
                onDeleteFile={this.dragdropDeleteFile}
                type={1}
                maxSize={50}
                multiple={true}
                uploadTitle={'Attachment'}
                renderAttachment={this.renderAttachment}
                Attachments={lstFileAttachments}
                baseUrl="/UserFile/FundingAgreementAttachmentPath"
              />
            </Form.Item>
            <Form.Item className="fluid-field">
              <Button floated="right" className="negative" onClick={this.onClose}>
                Close
              </Button>
              <Button floated="right" onClick={() => this.handSave()} className="btn-primary-submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Segment>
    );
  }
}

export default FormAddEdit;
