import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import dashboard from '../../../stores/dashboard';
import * as api from '@stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import LoginUserStore from './LoginUserStore';
import ModalResetPassword from './ModalResetPassword';
import moment from 'moment';

const columns = [
  { title: '' },
  // { title: 'User ID' },
  { title: 'User Name' },
  { title: 'Full Name' },
  { title: 'Email' },
  { title: 'Registered Date' },
  { title: 'Roles' },
  { title: 'Account Status' },
  { title: 'Date of Status' },
  { title: 'Last Login' },
];
@observer
class LoginUserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }
  getRolesLabel = Roles => {
    let roleLabels = [];
    Roles.forEach(Roles => {
      switch (Roles) {
        case `admin`:
          return roleLabels.push('Admin');
        case `magstaff`:
          return roleLabels.push('Staff');
        case `casemng`:
          return roleLabels.push('Case Manager');
        case `doctor`:
          return roleLabels.push('Specialist');
        case `accounting`:
          return roleLabels.push('Accounting');
        case `typist`:
          return roleLabels.push('Typist');
        default:
          return '';
      }
    });
    return roleLabels.join(', ');
  };
  openModal = options => {
    return LoginUserStore.toggleModal(true, options);
  };

  handleDelete = id => async () => {
    LoginUserStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/Manager/DeleteUser', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ userId: id, isLogicalDel: false }),
      });

      await LoginUserStore.fetchLoginUser({}, true);
    } finally {
      LoginUserStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = id => async () => {
    LoginUserStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Manager/AllowAccess', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId: id }),
      });
      await LoginUserStore.fetchLoginUser({}, true);
    } finally {
      LoginUserStore.setFieldsValue({ loading: false });
    }
  };

  openNotification = message => {
    return LoginUserStore.toggleModal(true, {
      modalType: 'notification',
      message,
    });
  };

  handleUnlockUser = userId => async () => {
    LoginUserStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/manager/unlockUser', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId }),
      }).then(this.openNotification('User has been unlocked'));
      await LoginUserStore.fetchLoginUser({}, true);
    } finally {
      LoginUserStore.setFieldsValue({ loading: false });
    }
  };

  openModalAddOrEdit = (userId, record) => {
    localStorage.setItem('StaffName', `${record.FullName}`);
    dashboard.close('/view/add-edit-staff-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-staff-2?id=${userId}&action=edit`);
    });
  };

  handleResetPassword = userId => () => {
    customFetch('/Manager/ResetPassword', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(res => {
      this.setState({
        openModalNotification: true,
        message: `Resend login Information: ${res.result}`,
      });
    });
  };

  handleResendLoginInformation = userId => () => {
    customFetch('/Manager/ResendLoginInformation', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ uId: userId }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Resend login Information: Successfully!!`,
        duration: 2,
        placement: `bottomRight`,
      });
    });
  };

  handleImpersonateUser = userId => () => {
    LoginUserStore.setFieldsValue({ loading: true });
    customFetch('/Manager/ImpersonateUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      var url = window.location.protocol + '//' + window.location.host + '/view/dashboard';
      window.location = url;
    });
  };

  handlePageClick = page => {
    LoginUserStore.refetch({ curPage: page });
  };

  renderTableBody = () => {
    return LoginUserStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.Enabled}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        {/* <Table.Cell
          onClick={() => this.openModalAddOrEdit(Id, record)}
          selectable
          className={cx({ 'Cell-nowrap Cell-link': record.Enabled })}
        >
          <Link to="#">{record.DoctorId || record.CaseManagerId || Id}</Link>
        </Table.Cell> */}
        <Table.Cell
          onClick={() => this.openModalAddOrEdit(Id, record)}
          selectable
          className={cx({ 'Cell-nowrap Cell-link': record.Enabled })}
        >
          {/* {!record.Enabled ? (
            `${record.UserName}`
          ) : (
            <Link to="#" onClick={this.openModalAddOrEdit(Id, record)}>
              {record.UserName}
            </Link>
          )} */}
          <Link to="#" onClick={() => this.openModalAddOrEdit(Id, record)}>
            {record.UserName}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.FullName}</Table.Cell>
        <Table.Cell>{record.Email}</Table.Cell>
        <Table.Cell>
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell style={{ minWidth: '150px' }}>{this.getRolesLabel(record.Roles)}</Table.Cell>
        <Table.Cell>
          <p
            className={cx({
              'text-danger': !record.Enabled,
            })}
          >
            {record.Enabled ? `Active` : `InActive`}
          </p>
          {!record.Enabled && (
            <span>{record.InActiveDate ? moment(formatDate(record.InActiveDate)).format('DD/MM/YYYY') : ''}</span>
          )}
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.LastLoginDate ? moment(formatDate(record.LastLoginDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: !record.Enabled
                ? `Do you want to enable user ${record.Email}?`
                : `Do you want to disable user ${record.Email}?`,
              onOk: this.handleEditItemStatus(id, record),
            })}
          >
            {!record.Enabled ? `Enable` : `Disable`}
          </Menu.Item>
          <Menu.Divider fitted></Menu.Divider>
          <Menu.Item onClick={() => this.openModalAddOrEdit(id, record)}>Edit User</Menu.Item>
          <Menu.Item onClick={this.handleUnlockUser(id)}>Unlock User</Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: `Do you want to permanently delete user ${record.Email}?`,
              onOk: this.handleDelete(id),
            })}
          >
            Delete Permanently
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: `Do you want to reset password for user: ${record.FullName}? New password will be sent to the user automatically.`,
              onOk: this.handleResetPassword(id),
            })}
          >
            Reset Password
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'showLoginHistory',
              uId: id,
              record,
            })}
          >
            Login History
          </Menu.Item>
          <Menu.Item onClick={this.handleResendLoginInformation(id)}>Resend Login Information</Menu.Item>
          {(api.isAdmin() || api.isMagStaff()) && (
            <Menu.Item onClick={this.handleImpersonateUser(id)}>Impersonate</Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalResetPassword
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { loading, data } = LoginUserStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        {this.renderModal()}
        <TableHeader columns={columns} />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={LoginUserStore.totalItems}
          currentPage={LoginUserStore.curPage}
          totalPage={LoginUserStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default LoginUserTable;
