import './index.scss';
import React from 'react';

import DoctorDetails from './doctor-detail';
import * as api from '../../../stores/api';
import dashboardStore from '../../../stores/dashboard';

class AddEditDoctor extends React.Component {
  componentDidMount() {
    if (!api.isDoctor()) {
      dashboardStore.close('/view/dr-account-2');
    }
  }
  render() {
    return (
      <div className={'Form-Container-DR'}>
        <DoctorDetails />
      </div>
    );
  }
}
export default AddEditDoctor;
