import { observer } from 'mobx-react';
import React from 'react';
import cx from 'classnames';
import TableToolbar from '../../shared/tableToolbar';
import DoctorReportsStore from './DoctorReportsStore';
import * as api from '@stores/api';

@observer
class Toolbar extends React.Component {
  handleChangeTab = (_, { name }) => {
    DoctorReportsStore.setFieldsValue({ tabIsActive: name });
  };
  render() {
    const { tabIsActive } = DoctorReportsStore;
    return (
      <form className="doctor-report-toolbar">
        <TableToolbar.Container>
          <div className="doctor-report-toolbar-tab-name">
            <TableToolbar.HighLightButton
              style={{ width: '100%' }}
              className={cx({ blue: tabIsActive === 'specialistSalesCall' })}
              name="specialistSalesCall"
              onClick={this.handleChangeTab}
            >
              Relationship Activities
            </TableToolbar.HighLightButton>
          </div>
          <div className="doctor-report-toolbar-tab-name">
            <TableToolbar.HighLightButton
              style={{ width: '100%' }}
              className={cx({ blue: tabIsActive === 'historyReport' })}
              name="historyReport"
              onClick={this.handleChangeTab}
            >
              View Specialist Assessment History
            </TableToolbar.HighLightButton>
          </div>
          <div className="doctor-report-toolbar-tab-name">
            <TableToolbar.HighLightButton
              style={{ width: '100%' }}
              className={cx({ blue: tabIsActive === 'remittanceStatements' })}
              name="remittanceStatements"
              onClick={this.handleChangeTab}
            >
              View Remittance Statements
            </TableToolbar.HighLightButton>
          </div>

          <div className="doctor-report-toolbar-tab-name">
            <TableToolbar.HighLightButton
              style={{ width: '100%' }}
              className={cx({ blue: tabIsActive === 'otherSpecialistSalesCall' })}
              name="otherSpecialistSalesCall"
              onClick={this.handleChangeTab}
            >
              Case Initiated Relationship Calls
            </TableToolbar.HighLightButton>
          </div>
          {api.isAdmin() && (
            <div className="doctor-report-toolbar-tab-name">
              <TableToolbar.HighLightButton
                style={{ width: '100%' }}
                className={cx({ blue: tabIsActive === 'export' })}
                name="export"
                onClick={this.handleChangeTab}
              >
                Export Specialist List - National Directory - Session History
              </TableToolbar.HighLightButton>
            </div>
          )}
        </TableToolbar.Container>
      </form>
    );
  }
}
export default Toolbar;
