import './index.scss';

import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import * as api from '../../stores/api';
import { accordionItems, accordionPaths } from './accordionUtils';
import ActionRequiredSection from './ActionRequiredSection';
import DashboardBody from './DashboardBody';
import DashboardHeader from './DashboardHeader';
import NextTaskSection from './NextTaskSection';
// import QuickAccessSection from './QuickAccessSection';
import NewSpecClinicClient from './NewSpecClinicClientSection';
import store from './store';

@observer
class Dashboard extends React.Component {
  componentDidMount() {
    if (api.isCaseManager() || api.isTypist()) {
      store.fetchCmData();
    } else if (api.isAdminOrMagStaffOrAccounting()) {
      store.fetchAdminData();
    } else {
      store.fetchDrData();
    }
    store.fetchCountUploadCase();
    if (!api.isDoctor() && !api.isTypist()) {
      accordionPaths.push('/ClinicalNote');
      accordionItems.push({
        title: 'Clinical Record Retrievals',
        cmUrl: '/view/clinical-notes-2',
        adminUrl: '/view/clinical-notes-2',
      });
    }
  }

  render() {
    if (store.loading) {
      return <Spin size="large" className="loading full-screen" />;
    }

    return (
      <div className="Admin-dashboard-page">
        <DashboardHeader />
        <DashboardBody>
          <Grid.Row>
            <NextTaskSection accordionItems={accordionItems} accordionPaths={accordionPaths} />
          </Grid.Row>
          <Grid.Row className="my-2">
            <ActionRequiredSection accordionItems={accordionItems} accordionPaths={accordionPaths} />
          </Grid.Row>
          <Grid.Row>
            {api.isAdminOrMagStaffOrAccounting() && (
              // <QuickAccessSection accordionItems={accordionItems} />
              <NewSpecClinicClient />
            )}
          </Grid.Row>
        </DashboardBody>
      </div>
    );
  }
}

export default Dashboard;
