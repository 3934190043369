import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import anonymousRoutes from './components/anonymous-routes';
import simpleViewRoutes from './components/iframe-routes';
import Anonymous from './components/anonymous/Anonymous';
import dashboardRoutes from './components/dashboard-routes';
import Dashboard from './components/dashboard/WithGuard';
import Page404 from './components/errors/Page404';
import router from './stores/router';
import IframeLayout from './components/iframe-routes/layout';
import JaasVideoSession from './components/admin-routes/VideoSession/JaasViewSession';
import JaasVideoHomePage from './components/admin-routes/VideoSession/JaasVideoHomePage';
import NotificationNewSite from '@components/dashboard/NotificationNewSite';

@observer
class App extends React.Component {
  componentDidMount() {
    const { pathname } = router.location;
    this.prevPath = pathname;
  }

  componentDidUpdate() {
    const { pathname } = router.location;
    if (this.prevPath !== pathname) {
      this.prevPath = pathname;
      notification.destroy();
      window.scrollTo(0, 0);
    }
  }

  isMagConnectProduction = () => {
    return window.location.hostname === 'magconnect.com.au';
  };

  render() {
    const { pathname } = router.location;

    if (this.isMagConnectProduction()) {
      return <NotificationNewSite />;
    } else {
      if (pathname === '/view/video-meeting') {
        return <JaasVideoSession />;
      }

      if (pathname === '/view/video-meeting-home') {
        return <JaasVideoHomePage />;
      }

      if (pathname in simpleViewRoutes) {
        return <IframeLayout />;
      } else if (pathname in anonymousRoutes) {
        return <Anonymous />;
      } else if (pathname in dashboardRoutes) {
        return <Dashboard />;
      }
    }
    return <Page404 />;
  }
}

export default App;
