import React from 'react';

import ModalConfirm from '../../../shared/Modal/ModalConfirm';
import SessionAvailabilityStore from './SessionAvailabilityStore';

function SessionAvailabilityModal() {
  const { modalType } = SessionAvailabilityStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...SessionAvailabilityStore} onCancel={SessionAvailabilityStore.toggleModal(false)} />;
  }
}

export default SessionAvailabilityModal;
