import { observer } from 'mobx-react';
import React from 'react';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import Store from './Store';
import { toJS } from 'mobx';

const businessTypes = [
  { text: `All`, value: 0 },
  { text: `Law Plaintiff`, value: 1 },
  { text: `Law Defendant`, value: 2 },
  { text: `Insurer/Self Insurer`, value: 3 },
];

@observer
class Toolbar extends React.Component {
  handleReset = () => {
    Store.resetStore();
    Store.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    Store.refetch({ curPage: 1 }, true);
  };

  handleSearchChange = (_, { value }) => {
    Store.setFieldsValue({ keyword: value });
  };

  handleRefresh = () => {
    Store.refetch({ data: null }, true);
  };

  handleChangeDropdown = (_, { name, value }) => {
    Store.refetch({ [name]: value }, true);
  };

  openCaseCreate = () => () => {
    return Store.toggleModal(true, {
      modalType: 'addOrEdit',
      id: 0,
      record: null,
    })();
  };

  render() {
    const { keyword, loading, businessType, state } = Store;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            className="blue"
            disabled={loading}
            onClick={this.handleRefresh}
            style={{ marginLeft: '5px' }}
          >
            Refresh
          </TableToolbar.HighLightButton>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openCaseCreate()}
              style={{ marginLeft: '5px' }}
            >
              Add New
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Left>
        <TableToolbar.Right />
      </TableToolbar.Container>
    );
  }
}

export default Toolbar;
