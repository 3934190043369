import { Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';

import ServiceEstimateStore from './Store';
import ServiceEstimateTable from './Table';
import ServiceEstimateToolbar from './Toolbar';
import ServiceEstimateModal from './Modal';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class ServiceEstimate extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()) {
      dashboardStore.close('/view/estimate', true);
      return;
    }
    ServiceEstimateStore.fetchData(true);
    ServiceEstimateStore.fetchState();
  }
  render() {
    const { data, loading, open } = ServiceEstimateStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ServiceEstimateToolbar />
            <ServiceEstimateTable />
            {open && <ServiceEstimateModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default ServiceEstimate;
