import { notification } from 'antd';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import ClientReportStore from './ClientReportStore';
import customFetch from '../../../utils/customFetch';

class ModalImport extends React.Component {
  state = { files: null, errorMessage: '' };

  uploadFileTemplate = body => {
    return customFetch('/ExportImport/UploadFileImportClient', {
      method: 'POST',
      body,
    });
  };
  handleUpload = event => {
    const files = event.target.files;
    if (!files || !files.length) {
      return;
    } else {
      const file = files[0];
      const type = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'The file you have selected is too large. The maximum size is 5MB.',
        });
      } else if (type === 'xls' || type === 'xlsx') {
        const formData = new FormData();
        formData.append(files[0].name, files[0]);
        this.uploadFileTemplate(formData)
          .then(res => {
            if (res.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Upload file successfully',
              });
              return ClientReportStore.toggleModal(false, {})();
            } else {
              notification.destroy();
              return notification.error({
                message: 'Error',
                description: 'Upload file failed',
              });
            }
          })
          .catch(() => {});
      } else {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'File type not permitted',
        });
      }
    }
  };
  handleDowloadTemplate = () => {
    window.open('/ExportImport/DownloadClientImportTemplate', '_blank');
  };
  onCancel = () => {
    return ClientReportStore.toggleModal(false, {})();
  };
  render() {
    const { toggleModal, open } = ClientReportStore;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={toggleModal(false)}
          title={`Import Clients`}
          footer={
            <React.Fragment>
              <Button className="negative" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
        >
          {!this.state.files || !this.state.files.length ? (
            <div className="Modal-UploadProfilePicture">
              <div className="mb-1">
                <p>You can upload one .XLSX file only. Max file size is 5 MB.</p>
                <label
                  className="Button"
                  style={{ marginRight: '10px', minWidth: '200px' }}
                  onClick={this.handleDowloadTemplate}
                >
                  <Icon name="download" />
                  <span>Download Template</span>
                </label>
                <label className="Button" style={{ minWidth: '200px' }}>
                  <Icon name="upload" />
                  <span className="title-client">Upload Clients</span>
                  <input hidden type="file" onChange={this.handleUpload} />
                </label>
              </div>
            </div>
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}
export default ModalImport;
