import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import CaseManagersStore from './CaseManagersStore';
import ModalCaseManagerHistory from './ModalCaseManagerHistory';
import ModalExport from './ModalExport';
import ModalLoginHistory from './ModalLoginHistory';
import ModalNotification from './ModalNotification';
import ModalEditUser from './ModalEditUser';
import ModalBookingHistory from './ModalBookingHistory';
import ModalActionNotes from './ModalActionNotes';
import ModalDisableUser from './ModalDisableUser';
import ModalAssignCM from './ModalAssignCM';

function CaseManagersModal() {
  const { modalType } = CaseManagersStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'history') {
    return <ModalCaseManagerHistory {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'notes') {
    return <ModalActionNotes {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'bookingHistory') {
    return <ModalBookingHistory {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'export') {
    return <ModalExport {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'notification') {
    return <ModalNotification {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'showLoginHistory') {
    return <ModalLoginHistory {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'editUser') {
    return <ModalEditUser {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'disable') {
    return <ModalDisableUser {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }

  if (modalType === 'assignAM') {
    return <ModalAssignCM {...CaseManagersStore} onCancel={CaseManagersStore.toggleModal(false)} />;
  }
}

export default CaseManagersModal;
