import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Modal from '../../shared/Modal/';
import moment from 'moment';

function StatusHistory({ data, open, onCancel, onOk }) {
  const renderConfirmMessage = () => (
    <div className="modal-confirm-message">
      <table className="ui celled sortable striped table">
        <thead className="">
          <tr>
            <th className="cursor-default">Old Status</th>
            <th className="cursor-default">New Status</th>
            <th className="cursor-default">Updated</th>
            <th className="cursor-default">Update by</th>
          </tr>
        </thead>
        <tbody style={{ fontWeight: 'normal' }}>
          {data &&
            data.map(({ Id, ...record }) => (
              <tr key={'fn-his-' + Id}>
                <td>
                  {record.OldStatus === 0
                    ? 'None'
                    : record.OldStatus === 1
                    ? 'Peding'
                    : record.OldStatus === 2
                    ? 'Sent'
                    : record.OldStatus === 3
                    ? 'Reviewed'
                    : record.OldStatus === 4
                    ? 'Approved'
                    : 'Rejected'}
                </td>
                <td>
                  {record.NewStatus === 0
                    ? 'None'
                    : record.NewStatus === 1
                    ? 'Peding'
                    : record.NewStatus === 2
                    ? 'Sent'
                    : record.NewStatus === 3
                    ? 'Reviewed'
                    : record.NewStatus === 4
                    ? 'Approved'
                    : 'Rejected'}
                </td>
                <td>{record.Updated ? moment(record.Updated).format('DD/MM/YYYY') : 'N/A'}</td>
                <td>{record.UpdatedBy ? record.UpdatedBy : ''}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  const handleOnOkClick = () => {
    onOk && onOk();
    onCancel();
  };

  return (
    <Modal
      visible={open}
      width={1000}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          {/* <Button color="blue" onClick={handleOnOkClick}>
            <Icon name="check" /> Yes
          </Button> */}
          <Button color="red" onClick={onCancel}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default StatusHistory;
