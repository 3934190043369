import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import * as api from '@stores/api';
import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import Store from './Store';

const statusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
];

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: this.props.modalParams.record
        ? {
            ...this.props.modalParams.record,
            description: this.props.modalParams.record.Description || '',
            label: this.props.modalParams.record.Label || '',
            type: 'targetAudience',
            isActive: this.props.modalParams.record.IsActive || false,
            orderNo: this.props.modalParams.record.OrderNo || 0,
          }
        : {
            description: '',
            label: '',
            type: Store.filter,
            isActive: true,
            orderNo: 0,
          },
    };
  }

  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const param = {
      Label: body.label,
      OrderNo: body.orderNo,
      Description: body.description,
      IsActive: body.isActive,
      Type: body.type,
    };
    if (id === 0) {
      Store.setFieldsValue({ loading: true });
      try {
        await customFetch('/TargetAudience/Create', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            Id: id,
            ...param,
          }),
        });
        await Store.refetch({}, true);
      } catch (e) {
      } finally {
        Store.setFieldsValue({ loading: false });
        this.props.onCancel();
      }
    } else {
      Store.setFieldsValue({ loading: true });
      try {
        await customFetch('/TargetAudience/Update', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            Id: id,
            ...param,
          }),
        });
        await Store.refetch({}, true);
      } catch (e) {
      } finally {
        Store.setFieldsValue({ loading: false });
        this.props.onCancel();
      }
    }
  };
  handleCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const { loading } = Store;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`${id === 0 ? `Create New` : `${body.Label}`}`}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  error={!body.label ? 'This field is required' : null}
                  label="Target Audiences"
                  value={body.label}
                  placeholder="Label"
                  name="label"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  label="Description"
                  value={body.description}
                  placeholder="Description"
                  name="description"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Order No"
                  value={body.orderNo}
                  type="number"
                  name="orderNo"
                  onChange={this.handleChangeInput}
                />
                <Form.Select
                  label="Status"
                  options={this.renderStatusLabel()}
                  name="isActive"
                  onChange={this.handleChangeDropdown}
                  text={this.renderStatusLabelText(body.isActive)}
                />
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
