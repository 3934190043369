import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class LoginUserHistoryStore {
  @observable loading = true;
  @observable data = null;

  @observable sortKey = 'LoginDate';
  @observable sortDirection = 'ascending';
  @observable uId = '';

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalItems = 0;
  @observable totalPage = 0;

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action fetchLoginUserHistory = () => {
    this.loading = true;

    customFetch('/Manager/GetLoginHistory', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
        uId: this.uId,
      }),
    }).then(
      action(data => {
        this.data = data;
        this.totalItems = data.sumItem;
        this.totalPage = data.sumPage;
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchLoginUserHistory(setTotalPage);
  };
  @action resetStore = () => {
    this.data = null;
    this.loading = true;
    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new LoginUserHistoryStore();
