import { CKEditorConfig } from '@utils/constants';
import React, { useImperativeHandle, forwardRef } from 'react';
import { useCKEditor, CKEditorEventAction } from 'ckeditor4-react';

const Editor = forwardRef((props, ref) => {
  const { html, onChangeValue } = props;
  const [element, setElement] = React.useState();

  const { editor } = useCKEditor({
    element,
    config: CKEditorConfig,
    initContent: html,
    dispatchEvent: ({ type, payload }) => {
      if (type === CKEditorEventAction.change && onChangeValue) {
        onChangeValue(payload.editor.getData());
      }
    },
    subscribeTo: ['change'],
  });

  useImperativeHandle(ref, () => ({
    setNewHtml(newHtml, setNew = false) {
      if (editor) {
        if (setNew) {
          editor.setData(newHtml);
        } else {
          editor.insertHtml(newHtml);
        }
      }
    },
  }));

  return <div ref={setElement} {...props} />;
});
export default Editor;
