import cx from 'classnames';
// import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Table, TableBody, Label } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader } from '../../shared/table';
import store from './store';
import * as api from '../../../stores/api';

@observer
class DailyBookingTable extends React.Component {
  renderClassName = item => {
    if (item.IsSession && item.Confirmed) {
      return 'session-cell-item';
    }
    if (item.IsSession && !item.Confirmed) {
      return 'unconfirmed-session-cell-item';
    }
    if (!item.IsSession && item.Confirmed) {
      return 'appt-cell-item';
    }
    if (!item.IsSession && !item.Confirmed) {
      return 'unconfirmed-appt-cell-item';
    }
    return 'none-results-session';
  };
  renderTableBody = (data, colSpan) => {
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    } else {
    }

    return data.map(([key, value]) => (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        {value.map((sessionList, idx) => (
          <Table.Cell key={idx}>
            {!!sessionList.length &&
              sessionList.map(session => (
                <React.Fragment key={session.Id}>
                  {session.IsSession ? (
                    <Label
                      style={{
                        margin: '5px',
                      }}
                      className={this.renderClassName(session)}
                    >
                      {session.TimeRange}
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <React.Fragment>
                          <br />
                          {`${session.DoctorTitle} ${session.DoctorName}`}
                        </React.Fragment>
                      )}
                    </Label>
                  ) : (
                    <Label
                      style={{
                        margin: '5px',
                      }}
                      className={this.renderClassName(session)}
                    >
                      {session.TimeRange}
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <React.Fragment>
                          <br />
                          {`${session.DoctorTitle} ${session.DoctorName}`}
                        </React.Fragment>
                      )}
                    </Label>
                  )}
                </React.Fragment>
              ))}
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  render() {
    const { dataDailyBooking, loading } = store;
    const column =
      loading || !dataDailyBooking ? [] : [{ title: 'Time' }, ...dataDailyBooking.roomList.map(i => ({ title: i }))];
    const data = !loading && dataDailyBooking ? Object.entries(dataDailyBooking.itemList) : null;
    const colSpan = column.length;
    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={column} />
        <TableBody>{this.renderTableBody(data, colSpan)}</TableBody>
      </Table>
    );
  }
}

export default DailyBookingTable;
