import { Dropdown, Menu, notification } from 'antd';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import TableToolbar from '../../shared/tableToolbar';
import ManageSpeAccStore from './ManageSpeAccStore';
import ModalAddEdit from './ModalAddEdit';
import ModalSubSpec from './ModalSubSpec';
import dashboardStore from '@stores/dashboard';

@observer
class ManageSpeAcc extends React.Component {
  types = [
    { text: 'Specialty', value: 'spe' },
    { text: 'Qualification', value: 'qua' },
    { text: 'Accreditation', value: 'acc' },
  ];

  status = [
    { text: 'All', value: 'All' },
    { text: 'Active', value: 'active' },
    { text: 'Inactive', value: 'inactive' },
  ];

  columns = [{}, { title: 'Label' }, { title: 'Description' }];

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/manage-spe-acc', true);
      return;
    }
    ManageSpeAccStore.fetchLookupConfig();
  }

  renderFilterText = value => {
    let labels = this.types.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  renderFilterTextStatus = value => {
    let labels = this.status.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  @action changeType = (_, { value }) => {
    ManageSpeAccStore.type = value;
    ManageSpeAccStore.curPage = 1;
    ManageSpeAccStore.fetchLookupConfig();
  };

  @action changeStatus = (_, { value }) => {
    ManageSpeAccStore.status = value;
    ManageSpeAccStore.curPage = 1;
    ManageSpeAccStore.fetchLookupConfig();
  };

  @action handleSearchChange = event => {
    ManageSpeAccStore.keyword = event.target.value;
  };

  @action handleSearch = event => {
    event.preventDefault();
    ManageSpeAccStore.curPage = 1;
    ManageSpeAccStore.fetchLookupConfig();
  };

  @action openSubSpec = record => {
    ManageSpeAccStore.toggleModalList(true, record.Id);
  };

  @action handleEdit = record => {
    ManageSpeAccStore.toggleModalAddEdit(true, toJS(record));
  };

  @action handleDisable = (id, isActive) => {
    ManageSpeAccStore.changeStatusLookUpConfig(id, isActive).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          description: 'Change status successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: 'Change status failed!',
          message: 'Error',
          duration: 5,
        });
      }
      ManageSpeAccStore.fetchLookupConfig();
    });
  };

  handleDelete = id => {
    ManageSpeAccStore.deleteLookupConfig(id).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          description: 'Delete successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: 'Delete failed!',
          message: 'Error',
          duration: 5,
        });
      }
      ManageSpeAccStore.fetchLookupConfig();
    });
  };

  @action handlePageClick = page => {
    ManageSpeAccStore.curPage = page;
    ManageSpeAccStore.fetchLookupConfig(false);
  };

  renderDropdownMenu = record => (
    <Menu>
      {record.Type === 'spe' && <Menu.Item onClick={() => this.openSubSpec(record)}>Manage Sub-Specialty</Menu.Item>}
      <Menu.Item onClick={() => this.handleEdit(record)}>Edit</Menu.Item>
      <Menu.Item onClick={() => this.handleDisable(record.Id, record.IsActive)}>
        {record.IsActive ? 'Inactive' : 'Active'}
      </Menu.Item>
      <Menu.Item onClick={() => this.handleDelete(record.Id)}>Delete</Menu.Item>
    </Menu>
  );

  renderTableBody = () => {
    if (ManageSpeAccStore.data.length === 0) {
      const colSpan = this.columns.length;
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return ManageSpeAccStore.data.map((i, idx) => (
      <Table.Row negative={!i.IsActive} key={idx}>
        <Table.Cell className="Cell-actions">
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell selectable className="Cell-nowrap Cell-link" onClick={() => this.handleEdit(i)}>
          <Link to="#">{i.Label}</Link>
        </Table.Cell>
        <Table.Cell>{i.Description}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, type, status } = ManageSpeAccStore;
    const colSpan = this.columns.length;

    return (
      <div className="ManageSpeAcc">
        <h1>Specialist Specialty-Qualifications-Accreditations</h1>
        <TableToolbar.Container>
          <TableToolbar.Left onSubmit={this.handleSearch}>
            <b>Choose a type to search/add</b>
            <TableToolbar.Filter
              className="ml-1"
              disabled={loading}
              label={'Type'}
              value={this.renderFilterText(type)}
              options={this.types}
              onChange={this.changeType}
            />
            <TableToolbar.Filter
              className="ml-1"
              disabled={loading}
              label={'Status'}
              value={this.renderFilterTextStatus(status)}
              options={this.status}
              onChange={this.changeStatus}
            />
            <TableToolbar.Input
              placeholder={'Search'}
              value={ManageSpeAccStore.keyword}
              onChange={this.handleSearchChange}
              disabled={loading}
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handleSearch}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              onClick={() => ManageSpeAccStore.handleResetStore(true)}
              className="negative"
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.Right>
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={() => ManageSpeAccStore.toggleModalAddEdit(true)}
              >
                Add New {ManageSpeAccStore.typeName}
              </TableToolbar.HighLightButton>
            </TableToolbar.Right>
          )}
        </TableToolbar.Container>
        <Table striped celled>
          <TableHeader columns={this.columns} />
          <TableBody>{this.renderTableBody()}</TableBody>
          <TableFooterPagination
            colSpan={colSpan}
            currentPage={ManageSpeAccStore.curPage}
            totalPage={ManageSpeAccStore.totalPage}
            onPageClick={this.handlePageClick}
            totalItems={ManageSpeAccStore.totalItem}
          />
        </Table>
        {ManageSpeAccStore.openModalList && <ModalSubSpec />}
        {ManageSpeAccStore.openModalAddEdit && <ModalAddEdit />}
      </div>
    );
  }
}

export default ManageSpeAcc;
