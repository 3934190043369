import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../table';
import dashboardStore from '@stores/dashboard';

const columnsClient = [
  { title: 'Id' },
  { title: 'Client' },
  { title: 'Email' },
  { title: 'Phone' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
];

const columnsCM = [
  { title: 'Id' },
  { title: 'Case Manager' },
  { title: 'Email' },
  { title: 'Phone' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
];

const columnsSPE = [
  { title: 'Id' },
  { title: 'Specialist' },
  { title: 'Email' },
  { title: 'Phone' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
];

const columnsIME = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client ' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Clinic Centre' },
  { title: 'Appointment Date' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
];

const columnsFR = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
];

const columnsSUPP = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
];

const columnsCR = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'CR Provider 1' },
  { title: 'CR Provider 2' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
];

const columnsMN = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },
  { title: 'Request Date', sortKey: 'RequestReceivedDate' },
  { title: 'Case Status' },
  { title: 'Specialist 1' },
  { title: 'Status 1' },
  { title: 'Specialist 2' },
  { title: 'Status 2' },
  { title: 'Specialist 3' },
  { title: 'Status 3' },
  { title: 'Claim No' },
  { title: 'Type of Claim' },
  { title: 'Claimant Name' },
  { title: 'Client' },
  { title: 'Case Manager' },
];

const columnsINV = [
  { title: 'Case No' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Action Note', sortKey: 'ActionNote' },
  { title: 'Staff Assigned', sortKey: 'StaffAssignedName' },
  { title: 'Due Date', sortKey: 'DueDate' },
  { title: 'Action Completed Date', sortKey: 'CompletedDate' },

  // { title: 'Invoice Date' },
  // { title: 'Amount (incl GST)' },
  // { title: 'Claimant Name' },
  // { title: 'Assessing Doctor' },
  // { title: 'PN' },
  // { title: 'Commission Rate' },
  // { title: 'Case No' },
  // { title: 'Claim No' },
  // { title: 'Service Date' },
  // { title: 'Paying Client' },
  // { title: 'Booking Client' },
  // { title: 'Booking CM' },
  // { title: 'Invoice Status' },
  // { title: 'IV Status Date' },
  // { title: 'Xero Status' },
  // { title: 'Payment Status' },
];
class TablesActionRequired extends React.Component {
  openModal = record => {
    let fieldId = '';
    let name = '';
    let fieldIdUpdate = '';
    const { title } = this.props;
    let client = title;
    if (title === 'Client') {
      fieldId = 'itemId';
      name = 'Company';
      fieldIdUpdate = 'CmId';
    }
    if (title === 'Case Manager') {
      fieldId = 'itemId';
      name = 'Staff';
      fieldIdUpdate = 'CmId';
    }
    if (title === 'Specialist') {
      fieldId = 'doctorId';
      name = 'MedicalDoctor';
      fieldIdUpdate = 'doctorId';
    }
    if (title === 'IME Reports') {
      fieldId = 'AssessmentId';
      name = 'MedicalService';
      fieldIdUpdate = 'AssessmentId';
    }
    if (title === 'File Reviews') {
      fieldId = 'fileReviewId';
      name = 'FileReview';
      fieldIdUpdate = 'fileReviewId';
    }
    if (title === 'Supplementary Reports') {
      fieldId = 'suppId';
      name = 'Supplementary';
      fieldIdUpdate = 'SupplementaryId';
    }
    if (title === 'Clinical Records') {
      fieldId = 'ClinicalNoteId';
      name = 'ClinicalNote';
      fieldIdUpdate = 'ClinicalNoteId';
    }
    if (title === 'Med Negs') {
      fieldId = 'mednegId';
      name = 'MedNeg';
      fieldIdUpdate = 'medNegId';
    }
    if (title === 'Invoices') {
      fieldId = 'InvoiceID';
      name = 'Invoice';
      fieldIdUpdate = 'InvoiceID';
    }
    return this.props.toggleModal(true, {
      id: record.CaseId,
      caseNo: record.CaseNo,
      fieldId,
      name,
      client,
      fieldIdUpdate,
    })();
  };

  openCaseDetails = record => {
    const { title } = this.props;
    let caseNo = title !== 'Case Manager' && title !== 'Specialist' ? `Case ${record.CaseNo}` : record.CaseNo;
    let stograge = '';
    let tabName = '';
    if (title === 'Client') {
      stograge = 'CompanyName';
      tabName = 'add-edit-client-2';
    }
    if (title === 'Case Manager') {
      stograge = 'CmName';
      tabName = 'add-edit-case-manager-2';
    }
    if (title === 'Specialist') {
      stograge = 'DoctorName';
      tabName = 'add-edit-doctor-2';
    }
    if (title === 'IME Reports') {
      stograge = 'AssessmentCaseId';
      tabName = 'add-edit-ime-assessment-2';
    }
    if (title === 'File Reviews') {
      stograge = 'FileReviewCaseId';
      tabName = 'add-edit-file-review-2';
    }
    if (title === 'Supplementary Reports') {
      stograge = 'SupplementaryCaseId';
      tabName = 'add-edit-supplementary-report-2';
    }
    if (title === 'Clinical Records') {
      stograge = 'ClinicalRecordCaseId';
      tabName = 'add-edit-clinical-notes-2';
    }
    if (title === 'Med Negs') {
      stograge = 'MedNegCaseId';
      tabName = 'PostMedNeg';
    }
    if (title === 'Invoices') {
      stograge = 'InvoiceCaseId';
      tabName = 'ViewInvoice';
    }
    localStorage.setItem(`${stograge}`, caseNo);
    if (title !== 'Invoices' && title !== 'Med Negs') {
      dashboardStore.close(`/view/${tabName}`);
      return setTimeout(() => {
        dashboardStore.open(`/view/${tabName}?id=${record.CaseId}&step=0`);
      });
    } else {
      return iframe.forceOpenTab(`${tabName}`, `?id=${record.CaseId}&view=${tabName}`, {
        id: record.CaseId,
        view: `${tabName}`,
      });
    }
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  handleSort = (data, sortObj) => {
    this.props.handleSort(data, sortObj);
  };

  onCheckedActionNote = note => {
    if (note) {
      return note;
    } else {
      return 'Please enter action note.';
    }
  };

  renderTableClient = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseId}
        </Link>
      </Table.Cell>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Email}</Table.Cell>
      <Table.Cell>{record.Mobiphone}</Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableCM = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseId}
        </Link>
      </Table.Cell>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Email}</Table.Cell>
      <Table.Cell>{record.Mobiphone}</Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableSPE = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseId}
        </Link>
      </Table.Cell>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Email}</Table.Cell>
      <Table.Cell>{record.Mobiphone}</Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableIME = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimLabel}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        {record.IsDirect && <div>{record.CMEmail}</div>}
        {record.IsDirect && <div>{record.CMMobilePhone}</div>}
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        {record.IsDirect && <div>{record.DoctorBusinessEmail}</div>}
        {record.IsDirect && <div>{record.DoctorPhone}</div>}
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        {record.DateFrom
          ? moment(formatDate(record.DateFrom, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.StatusText}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableFR = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimantNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        {record.IsDirect && <div>{record.CMEmail}</div>}
        {record.IsDirect && <div>{record.CMMobilePhone}</div>}
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        {record.IsDirect && <div>{record.DoctorEmail}</div>}
        {record.IsDirect && <div>{record.DoctorPhone}</div>}
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableSUPP = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.DateRequest
          ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaim}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        {record.IsDirect && <div>{record.CMEmail}</div>}
        {record.IsDirect && <div>{record.CMMobilePhone}</div>}
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.AssessedDoctorName}</h5>
        {record.IsDirect && <div>{record.DoctorEmail}</div>}
        {record.IsDirect && <div>{record.DoctorPhone}</div>}
      </Table.Cell>
      <Table.Cell> {record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableCR = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimaNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableMN = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.RequestReceivedDate
          ? moment(formatDate(record.RequestReceivedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty1 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status1}</Table.Cell>
      <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty2 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status2} </Table.Cell>
      <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty3 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status3}</Table.Cell>
      <Table.Cell>{record.ClaimantNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell style={{ minWidth: 200 }}>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
    </React.Fragment>
  );

  renderTableINV = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.Subject}</Table.Cell>
      <Table.Cell>
        <span className="Body-string">{this.onCheckedActionNote(record.ActionNote)}</span>
        <div className="Cell-link">
          <Link to="#" onClick={() => this.openModal(record)}>
            View more
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>{record.StaffAssignedName}</Table.Cell>
      <Table.Cell>
        {record.DueDate ? moment(formatDate(record.DueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {record.CompletedDate
          ? moment(formatDate(record.CompletedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTable = record => {
    switch (this.props.title) {
      case 'Client':
        return this.renderTableClient(record);
      case 'Case Manager':
        return this.renderTableCM(record);
      case 'Specialist':
        return this.renderTableSPE(record);
      case 'IME Reports':
        return this.renderTableIME(record);
      case 'File Reviews':
        return this.renderTableFR(record);
      case 'Supplementary Reports':
        return this.renderTableSUPP(record);
      case 'Clinical Records':
        return this.renderTableCR(record);
      case 'Med Negs':
        return this.renderTableMN(record);
      default:
        return this.renderTableINV(record);
    }
  };

  renderHeader = () => {
    switch (this.props.title) {
      case 'Client':
        return (
          <TableHeader
            columns={columnsClient}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Case Manager':
        return (
          <TableHeader
            columns={columnsCM}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Specialist':
        return (
          <TableHeader
            columns={columnsSPE}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'IME Reports':
        return (
          <TableHeader
            columns={columnsIME}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'File Reviews':
        return (
          <TableHeader
            columns={columnsFR}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Supplementary Reports':
        return (
          <TableHeader
            columns={columnsSUPP}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Clinical Records':
        return (
          <TableHeader
            columns={columnsCR}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Med Negs':
        return (
          <TableHeader
            columns={columnsMN}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      default:
        return (
          <TableHeader
            columns={columnsINV}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
    }
  };

  renderCols = () => {
    switch (this.props.title) {
      case 'Client':
        return columnsClient;
      case 'Case Manager':
        return columnsCM;
      case 'Specialist':
        return columnsSPE;
      case 'IME Reports':
        return columnsIME;
      case 'File Reviews':
        return columnsFR;
      case 'Supplementary Reports':
        return columnsSUPP;
      case 'Clinical Records':
        return columnsCR;
      case 'Med Negs':
        return columnsMN;
      default:
        return columnsINV;
    }
  };

  render() {
    const { data, curPage, totalPage, sumItem } = this.props;
    const columns = this.renderCols();
    return (
      <Table striped celled sortable>
        {this.renderHeader()}
        <Table.Body>
          {data.length ? (
            data.map(record => (
              <Table.Row key={record.id || record.Id} negative={!record.Completed}>
                {this.renderTable(record)}
              </Table.Row>
            ))
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage}
          totalPage={totalPage}
          onPageClick={this.handlePageClick}
          totalItems={sumItem}
        />
      </Table>
    );
  }
}

export default TablesActionRequired;
