import { action, observable } from 'mobx';

class AppStore {
  /**
   * true: iframe mode, false: default mode
   */
  @observable iframeMode = false;

  @action setIframeMode = flag => {
    this.iframeMode = flag;
  };
}

export default new AppStore();
