import React from 'react';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { DatePicker, Spin } from 'antd';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';

import Modal from '../../shared/Modal/';
import Store from './DoctorReportsStore';
import customFetch from '../../../utils/customFetch';
import ModalVerifyAccessCode from './ModalVerifyAccessCode';

const datePickerFormat = 'DD/MM/YYYY';

class ModalFilterExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      includeInactive: false,
      includeUnsubscribed: false,
      includeUnverified: false,
      IsNonNookingContact: false,
      IsExcluding: false,
      IsOnboarding: true,
      IsRecruiting: true,
      IsOnboarded: true,
      Active: true,
      InActive: false,
    };
  }

  onCancel = () => {
    this.props.onCancel();
  };

  onChangeCheckBox = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onChangeCMStatus = name => {
    if (name === 'IsExcluding') {
      this.setState({
        ...this.state,
        IsExcluding: !this.state.IsExcluding,
      });
    } else if (name === 'IsOnboarding') {
      this.setState({
        ...this.state,
        IsOnboarding: !this.state.IsOnboarding,
      });
    } else if (name === 'IsRecruiting') {
      this.setState({
        ...this.state,
        IsRecruiting: !this.state.IsRecruiting,
      });
    } else if (name === 'IsOnboarded') {
      this.setState({
        ...this.state,
        IsOnboarded: !this.state.IsOnboarded,
      });
    } else if (name === 'InActive') {
      this.setState({
        ...this.state,
        InActive: !this.state.InActive,
      });
    } else if (name === 'Active') {
      this.setState({
        ...this.state,
        Active: !this.state.Active,
      });
    } else if (name === 'IsNonNookingContact') {
      this.setState({
        ...this.state,
        IsNonNookingContact: !this.state.IsNonNookingContact,
      });
    }
  };

  handlePickerChange = momentValue => {
    this.setState({
      ...this.state,
      caseManagerCreatedDate: momentValue ? momentValue.format('DDMMMMYYYY') : null,
    });
  };

  handleCMsState = (e, data) => {
    this.setState({
      caseManagerState: data.value,
    });
  };

  @action handleChangeClient = (_, { value }) => {
    this.setState({
      clientIds: value,
    });
  };

  handleExport = () => {
    const clientTypes = [];
    if (this.state.clientTypes1) {
      clientTypes.push(this.state.clientTypes1);
    }
    if (this.state.clientTypes2) {
      clientTypes.push(this.state.clientTypes2);
    }
    if (this.state.clientTypes3) {
      clientTypes.push(this.state.clientTypes3);
    }
    if (this.state.clientTypes4) {
      clientTypes.push(this.state.clientTypes4);
    }
    if (this.state.clientTypes5) {
      clientTypes.push(this.state.clientTypes5);
    }
    if (this.state.clientTypes6) {
      clientTypes.push(this.state.clientTypes6);
    }
    const searchParams = Object.assign(
      {},
      {
        clientCityId: this.state.clientCityId,
        clientTypes: clientTypes,
        clientSuburb: this.state.clientSuburb,
        clientPostcode: this.state.clientPostcode,
        clientIncludePromotionsUnsubscribedClients: this.state.clientIncludePromotionsUnsubscribedClients,
        MAGDirect: this.state.MAGDirect,
        caseManagerCreatedDate: this.state.caseManagerCreatedDate,
        caseManagerPostcode: this.state.caseManagerPostcode,
        caseManagerState: this.state.caseManagerState,
        caseManagerSuburb: this.state.caseManagerSuburb,
        caseManagerCityId: this.state.caseManagerCityId,
        caseManagerUnsubscribed: this.state.caseManagerUnsubscribed,
        isRecruiting: this.state.IsRecruiting,
        isOnboarding: this.state.IsOnboarding,
        isNonNookingContact: this.state.IsNonNookingContact,
        isExcluding: this.state.IsExcluding,
        isOnboarded: this.state.IsOnboarded,
        active: this.state.Active,
        inActive: this.state.InActive,
        caseManagerUnverified: this.state.caseManagerUnverified,
        clientIds: this.state.clientIds,
      },
    );
    var qs = Object.entries(searchParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    var qs1 = clientTypes.map(val => `clientTypes=${val}`).join('&');

    var queryParams = qs + '&' + qs1 + '&forDownloading=true&isCMExport=true';
    Store.setFieldsValue({ exportUrl: '/Report/ExportClientAndCm', exportQueryParams: queryParams });

    this.setState({ ...this.state, isOpenAccessCodeModal: true });
    //window.open('/Report/ExportClientAndCm?' + qs + '&' + qs1 + '&forDownloading=true', '_blank');
  };

  async componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { toggleModal, open } = Store;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={toggleModal(false)}
          title={`Export Clients and Case Managers`}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleExport} disabled={loading}>
                Export
              </Button>
              <Button className="negative" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
        >
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="Wrap-export-modal">
              <h2 className="Title-export">Option filters:</h2>
              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Inactive Account
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.includeInactive
                        ? () => this.onChangeCheckBox('includeInactive', false)
                        : () => this.onChangeCheckBox('includeInactive', true)
                    }
                  />
                </div>
                <div className="Title" style={{ width: '180px', marginLeft: '140px' }}>
                  Include Unsubscribed CMs
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.includeUnsubscribed
                        ? () => this.onChangeCheckBox('includeUnsubscribed', false)
                        : () => this.onChangeCheckBox('includeUnsubscribed', true)
                    }
                  />
                </div>
                <div className="Title" style={{ width: '180px', marginLeft: '140px' }}>
                  Include Unverified CMs
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.includeUnverified
                        ? () => this.onChangeCheckBox('includeUnverified', false)
                        : () => this.onChangeCheckBox('includeUnverified', true)
                    }
                  />
                </div>
              </div>

              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Recruitment Status
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    style={{ width: '95px' }}
                    label="Recruiting"
                    checked={this.state.IsRecruiting}
                    onChange={() => this.onChangeCMStatus('IsRecruiting')}
                  />
                  <Checkbox
                    label="Onboarding"
                    checked={this.state.IsOnboarding}
                    onChange={() => this.onChangeCMStatus('IsOnboarding')}
                  />
                  <Checkbox
                    label="Onboarded"
                    checked={this.state.IsOnboarded}
                    onChange={() => this.onChangeCMStatus('IsOnboarded')}
                  />
                  <Checkbox
                    label="Excluded"
                    checked={this.state.IsExcluding}
                    onChange={() => this.onChangeCMStatus('IsExcluding')}
                  />
                  <Checkbox
                    label="Non-Booking Contact"
                    checked={this.state.IsNonNookingContact}
                    onChange={() => this.onChangeCMStatus('IsNonNookingContact')}
                  />
                </div>
              </div>
              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Account Status
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    style={{ width: '95px' }}
                    label="Active"
                    checked={this.state.Active}
                    onChange={() => this.onChangeCMStatus('Active')}
                  />
                  <Checkbox
                    label="Inactive"
                    checked={this.state.InActive}
                    onChange={() => this.onChangeCMStatus('InActive')}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
        {this.state.isOpenAccessCodeModal && (
          <ModalVerifyAccessCode {...Store} onCancel={Store.toggleModal(false)} />
        )}
      </React.Fragment>
    );
  }
}

export default observer(ModalFilterExport);
