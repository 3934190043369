import { Dropdown, Menu, Modal, notification } from 'antd';
import { observable, action, toJS, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Table, TableBody } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import TableToolbar from '../../shared/tableToolbar';
import ManageSpeAccStore from './ManageSpeAccStore';
import * as api from '@stores/api';

@observer
class ModalSubSpec extends React.Component {
  @observable keyword = '';
  @observable currentPage = 1;

  types = [
    { text: 'Specialty', value: 'spe' },
    { text: 'Qualification', value: 'qua' },
    { text: 'Accreditation', value: 'acc' },
  ];

  columns = [{}, { title: 'Label' }, { title: 'Description' }];

  @action handleCancel = () => {
    ManageSpeAccStore.toggleModalList(false);
  };

  @action handleSearchChange = event => {
    this.keyword = event.target.value;
    this.currentPage = 1;
  };

  @action handleClear = () => {
    this.keyword = '';
    this.currentPage = 1;
  };

  @action handleEdit = record => {
    ManageSpeAccStore.toggleModalAddEdit(true, toJS(record));
  };

  @action handleDisable = (id, isActive) => {
    ManageSpeAccStore.changeStatusLookUpConfig(id, isActive).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          description: 'Change status successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: 'Change status failed!',
          message: 'Error',
          duration: 5,
        });
      }
      ManageSpeAccStore.fetchLookupConfig();
    });
  };

  handleDelete = id => {
    ManageSpeAccStore.deleteLookupConfig(id).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          description: 'Delete successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: 'Delete failed!',
          message: 'Error',
          duration: 5,
        });
      }
      ManageSpeAccStore.fetchLookupConfig();
    });
  };

  @action handlePageClick = page => {
    this.currentPage = page;
  };

  renderDropdownMenu = record => (
    <Menu>
      <Menu.Item onClick={() => this.handleEdit(record)}>Edit</Menu.Item>
      <Menu.Item onClick={() => this.handleDisable(record.Id, record.IsActive)}>
        {record.IsActive ? 'Disable' : 'Enable'}
      </Menu.Item>
      <Menu.Item onClick={() => this.handleDelete(record.Id)}>Delete</Menu.Item>
    </Menu>
  );

  @computed get data() {
    const { SubList } = ManageSpeAccStore.selectedSpecialty;

    const list = SubList.filter(s => s.Label.includes(this.keyword));

    const startIndex = (this.currentPage - 1) * 20;
    const paginateList = list.slice(startIndex, startIndex + 20);

    return {
      list: paginateList,
      totalItems: list.length,
      totalPage: Math.ceil(list.length / 20),
    };
  }

  renderTableBody = () => {
    if (this.data.list.length === 0) {
      const colSpan = this.columns.length;
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return this.data.list.map((i, idx) => (
      <Table.Row negative={!i.IsActive} key={idx}>
        <Table.Cell className="Cell-actions">
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell selectable className="Cell-nowrap Cell-link" onClick={() => this.handleEdit(i)}>
          <Link to="#">{i.Label}</Link>
        </Table.Cell>
        <Table.Cell>{i.Description}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, selectedSpecialty, openModalList } = ManageSpeAccStore;
    const colSpan = this.columns.length;

    return (
      <Modal
        title="Sub-Specialty"
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={1200}
        visible={openModalList}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="Modal-SubSpec">
          <h1>{selectedSpecialty.Label}'s Sub-Specialty</h1>
          <TableToolbar.Container>
            <TableToolbar.Left>
              <TableToolbar.Input
                placeholder={'Search'}
                value={this.keyword}
                onChange={this.handleSearchChange}
                disabled={loading}
              />
              <TableToolbar.HighLightButton
                onClick={this.handleClear}
                className="negative"
                style={{ marginLeft: '5px' }}
              >
                Clear
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right>
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={() => ManageSpeAccStore.toggleModalAddEdit(true)}
              >
                Add New Sub-Specialty
              </TableToolbar.HighLightButton>}
            </TableToolbar.Right>
          </TableToolbar.Container>
          <Table striped celled>
            <TableHeader columns={this.columns} />
            <TableBody>{this.renderTableBody()}</TableBody>
            <TableFooterPagination
              colSpan={colSpan}
              currentPage={this.currentPage}
              totalPage={this.data.totalPage}
              onPageClick={this.handlePageClick}
              totalItems={this.data.totalItems}
            />
          </Table>
        </div>
      </Modal>
    );
  }
}

export default ModalSubSpec;
