import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import LoginHistoryReportStore from './LoginHistoryReportStore';
import LoginHistoryReportToolbar from './LoginHistoryReportToolbar';
import LoginHistoryTable from './LoginHistoryTable';
import OnlineUserTable from './OnlineUserTable';

window.AdminStuffReact = {};
const { $ } = window;

// function onSignalrStarted() {
//   $.connection.onlineUserHub.server.refreshOnlineUserList();
//   window.AdminStuffReact.SignalrStarted = true;
// }

class LoginHistoryReport extends React.Component {
  componentDidMount() {
    LoginHistoryReportStore.fetchLoginHistory(true);
    LoginHistoryReportStore.refetchSmartSearch(true);
    if ($ && $.connection && $.connection.onlineUserHub) {
      $.connection.hub.createHubProxy('onlineUserHub').client.dummyMethod = function() {};
      const startHub = () => {
        // $.connection.hub.start({ pingInterval: null }).done(onSignalrStarted);
      };
      $.connection.hub.disconnected(stoppedNicely => {
        window.parent.AdminStuffReact.SignalrStarted = false;
        return !stoppedNicely && setTimeout(startHub, 3000);
      });
      startHub();
    }
  }

  render() {
    const { loadingLogin, dataLogin, tabIsActive, loadingSmartSearch, dataSmartSearch } = LoginHistoryReportStore;

    return (
      <div
        className={`Table-${
          (loadingLogin && !dataLogin) || (loadingSmartSearch && !dataSmartSearch) ? 'loading' : 'container'
        }`}
      >
        {(loadingLogin && !dataLogin) || (loadingSmartSearch && !dataSmartSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <LoginHistoryReportToolbar />
            {tabIsActive === `login` && <LoginHistoryTable />}
            {tabIsActive === `online` && <OnlineUserTable />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(LoginHistoryReport);
