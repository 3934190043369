import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form, DatePicker, Select, Radio, Input, Checkbox, Divider } from 'antd';
import { Popup, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import { renderDate } from '../Shared/utils';
import CKEditor from '../../shared/CKEditor';
import store from './store';
import { State } from './type';
import FieldUpload from './FieldUpload';

const METHOD_SEND = [
  { key: 'oneTime', text: 'One Time', value: 1 },
  { key: 'daily', text: 'Daily', value: 2 },
  { key: 'weekly', text: 'Weekly', value: 3 },
  { key: 'monthly', text: 'Monthly', value: 4 },
];

@observer
class FormDetails extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<Icon name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );

  openModal = options => {
    return store.toggleModal(true, options);
  };

  viewSentCampaigns = campaignId => {
    return store.toggleModal(true, {
      modalType: 'campaignHistory',
      campaignId: campaignId,
    })();
  };

  render() {
    const dataCampaign = store.data;

    return (
      <Form name="conversation" className="conversation-form">
        <Form.Item
          label="Campaign Name"
          required
          validateStatus={store.errors['CampaignName'] ? 'error' : null}
          help={store.errors['CampaignName'] ? store.errors['CampaignName'] : null}
        >
          <Input
            size="large"
            value={dataCampaign.CampaignName}
            onChange={store.handleChangeFieldInput('CampaignName')}
          />
        </Form.Item>
        <Form.Item
          label="Channel"
          required
          validateStatus={store.errors['Channel'] ? 'error' : null}
          help={store.errors['Channel'] ? store.errors['Channel'] : null}
        >
          <Select
            size="large"
            value={dataCampaign.Channel}
            showArrow
            optionFilterProp="children"
            showSearch
            allowClear
            onChange={store.handleChangeFieldSelect('Channel')}
          >
            {store.channels.map(i => (
              <Select.Option value={i.Id} key={i.Id}>
                {i.Label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* {dataCampaign.Channel !== 2738 && (
          <Form.Item label="Status">
            <Radio.Group value={dataCampaign.Status} onChange={store.handleChangeFieldRadio('Status')}>
              <Radio value={0}>Planning</Radio>
              <Radio value={1}>In progress</Radio>
              <Radio value={2}>Completed</Radio>
              <Radio value={3}>Cancelled</Radio>
            </Radio.Group>
          </Form.Item>
        )} */}

        {dataCampaign.Channel !== 2738 && (
          <Form.Item
            label="Campaign Period"
            required={dataCampaign.Channel !== 2738}
            validateStatus={dataCampaign.Channel !== 2738 && store.errors['CampaignDate'] ? 'error' : null}
            help={dataCampaign.Channel !== 2738 && store.errors['CampaignDate'] ? store.errors['CampaignDate'] : null}
            className="flex-children"
          >
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              onChange={store.handleChangeDatePicker('CampaignDate')}
              style={{ marginRight: '10px' }}
              value={renderDate(dataCampaign.CampaignDate)}
            />
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              onChange={store.handleChangeDatePicker('CampaignEndDate')}
              value={renderDate(dataCampaign.CampaignEndDate)}
            />
          </Form.Item>
        )}
        {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
          <Form.Item
            label="Subject"
            required
            validateStatus={store.errors['Subject'] ? 'error' : null}
            help={store.errors['Subject'] ? store.errors['Subject'] : null}
          >
            <Input value={dataCampaign.Subject} size="large" onChange={store.handleChangeFieldInput('Subject')} />
          </Form.Item>
        )}

        {dataCampaign.Channel === 2738 && (
          <React.Fragment>
            <Divider className="fluid-field" style={{ height: 0, margin: 0 }} />
            {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
              <React.Fragment>
                <Form.Item className="campaign-scheduled">
                  <div style={{ width: '100%' }}>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={
                        !!dataCampaign.SaveAndSendDate ||
                        !!dataCampaign.SetScheduleDate ||
                        dataCampaign.SentDateCampaignManual
                      }
                      checked={dataCampaign.NeedSendEmail}
                      onChange={store.handleChangeFieldCheckbox('NeedSendEmail')}
                    ></Checkbox>
                    {'   '}Set Campaign Schedule{'   '}
                    {/* {dataCampaign.Channel === 2713 && this.renderPopup('Campaign will be sent at 7:30AM the next day')}
                    {dataCampaign.Channel === 2738 &&
                      this.renderPopup(
                        'Sending previously booked specialist availability (Next 8 weeks) to all CMs of booking clients. Campaign will be sent at 7:30AM the next day',
                      )}
                    {'   '} */}
                    {dataCampaign.SetScheduleDate && dataCampaign.NeedSendEmail && (
                      <span>
                        - {dataCampaign.SetScheduleDate ? renderDate(dataCampaign.SetScheduleDate, false, true) : ''}
                        {dataCampaign.SetScheduleBy ? ` - By ${dataCampaign.SetScheduleBy}` : ''}
                      </span>
                    )}
                    {/* {dataCampaign.SentErrorDate && !dataCampaign.SaveAndSendDate && dataCampaign.NeedSendEmail && (
                      <text className="campaign-erorr-text">
                        {' '}
                        Sending Error: {dataCampaign.SentErrorName} -{' '}
                        {renderDate(dataCampaign.SentErrorDate, false, true)}
                      </text>
                    )} */}
                  </div>
                  <div style={{ width: '100%', marginTop: '6px' }}>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={
                        !!dataCampaign.SaveAndSendDate ||
                        !!dataCampaign.SentDateCampaignManual ||
                        !dataCampaign.SetScheduleDate
                      }
                      checked={dataCampaign.IsCancelSchedule}
                      onChange={store.handleChangeFieldCheckbox('IsCancelSchedule')}
                    ></Checkbox>
                    {'   '}Cancel Schedule{'   '}
                    {dataCampaign.IsCancelSchedule && dataCampaign.CancelScheduleDate && (
                      <span>
                        -{' '}
                        {dataCampaign.CancelScheduleDate
                          ? renderDate(dataCampaign.CancelScheduleDate, false, true)
                          : ''}
                        {dataCampaign.CancelScheduleBy ? ` - By ${dataCampaign.CancelScheduleBy}` : ''}
                      </span>
                    )}
                  </div>
                </Form.Item>
                <Form.Item
                  className="campaign-scheduled"
                  label={
                    <React.Fragment>
                      Send Immediately
                      {this.renderPopup('Click Send will send the campaign immediately without scheduling')}
                    </React.Fragment>
                  }
                >
                  <div>
                    <Button
                      className="blue"
                      disabled={dataCampaign.NeedSendEmail || dataCampaign.SentDateCampaignManual}
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          modalType: 'confirm',
                          message: `Do you want to send the campaign now?`,
                          onOk: () => store.handleSave(true, false),
                        });
                      }}
                    >
                      Send
                    </Button>
                    {dataCampaign.SentDateCampaignManual && (
                      <span>
                        Sent:{' '}
                        {dataCampaign.SentDateCampaignManual
                          ? renderDate(dataCampaign.SentDateCampaignManual, false, true)
                          : ''}
                        {dataCampaign.SentByCampaignManual ? ` - By ${dataCampaign.SentByCampaignManual}` : ''}
                      </span>
                    )}
                    {dataCampaign.SentManualErrorDate && (
                      <text className="campaign-erorr-text">
                        {' '}
                        Sending Error: {dataCampaign.SentManualErrorName} -{' '}
                        {renderDate(dataCampaign.SentManualErrorDate, false, true)}
                      </text>
                    )}
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Schedule Option{' '}
                      {!!dataCampaign.Id && (
                        <Link to="#" onClick={() => this.viewSentCampaigns(dataCampaign.Id)}>
                          View Sent Campaigns
                        </Link>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail
                    }
                    size="large"
                    showArrow
                    optionFilterProp="children"
                    showSearch
                    value={dataCampaign.ScheduledMethod}
                    onChange={store.handleChangeFieldSelect('ScheduledMethod')}
                  >
                    {METHOD_SEND.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {i.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Send Time">
                  <div className="ant-form-item-control">
                    <span className="ant-form-item-children">
                      <input
                        className="ant-input ant-input-lg ant-input-disabled"
                        type="text"
                        disabled
                        value="7:30 NSW/Sydney"
                      ></input>
                    </span>
                  </div>
                </Form.Item>
                <Form.Item
                  label="Start Date"
                  required={dataCampaign.NeedSendEmail}
                  validateStatus={store.errors['ScheduledStartDate'] ? 'error' : null}
                  help={store.errors['ScheduledStartDate'] ? store.errors['ScheduledStartDate'] : null}
                >
                  <DatePicker
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail ||
                      dataCampaign.ScheduledMethod === 0
                    }
                    size="large"
                    format="DD/MM/YYYY"
                    onChange={store.handleChangeDatePicker('ScheduledStartDate')}
                    style={{ marginRight: '10px' }}
                    value={renderDate(dataCampaign.ScheduledStartDate)}
                  />
                </Form.Item>
                <Form.Item label="End Date">
                  <DatePicker
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail ||
                      dataCampaign.ScheduledMethod === 0 ||
                      dataCampaign.ScheduledMethod === 1
                    }
                    size="large"
                    format="DD/MM/YYYY"
                    onChange={store.handleChangeDatePicker('ScheduledEndDate')}
                    style={{ marginRight: '10px' }}
                    value={renderDate(dataCampaign.ScheduledEndDate)}
                  />
                </Form.Item>
                <Divider className="fluid-field" style={{ height: 0, margin: 0 }} />
              </React.Fragment>
            )}

            <Form.Item
              label="From"
              required
              validateStatus={store.errors['From'] ? 'error' : null}
              help={store.errors['From'] ? store.errors['From'] : null}
            >
              <Select
                size="large"
                showArrow
                optionFilterProp="children"
                showSearch
                value={dataCampaign.From}
                onChange={store.handleChangeFieldSelect('From')}
              >
                {store.staffs.map(i => (
                  <Select.Option value={i.Id} key={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="To">
              <Link
                style={{ lineHeight: '35px' }}
                to="#"
                onClick={() => window.open('/ManagerCampaign/DowloadEmailPreBooked', '_blank')}
              >
                View Client List
              </Link>
            </Form.Item>
          </React.Fragment>
        )}
        {dataCampaign.Channel === 2713 && (
          <React.Fragment>
            <Divider className="fluid-field" style={{ height: 0, margin: 0 }} />
            {/* <Form.Item
              label="Subject"
              required
              validateStatus={store.errors['Subject'] ? 'error' : null}
              help={store.errors['Subject'] ? store.errors['Subject'] : null}
            >
              <Input value={dataCampaign.Subject} size="large" onChange={store.handleChangeFieldInput('Subject')} />
            </Form.Item> */}
            {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
              <React.Fragment>
                <Form.Item className="campaign-scheduled">
                  <div style={{ width: '100%' }}>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={
                        !!dataCampaign.SaveAndSendDate ||
                        !!dataCampaign.SetScheduleDate ||
                        dataCampaign.SentDateCampaignManual
                      }
                      checked={dataCampaign.NeedSendEmail}
                      onChange={store.handleChangeFieldCheckbox('NeedSendEmail')}
                    ></Checkbox>
                    {'   '}Set Campaign Schedule{'   '}
                    {/* {dataCampaign.Channel === 2713 && this.renderPopup('Campaign will be sent at 7:30AM the next day')}
                    {dataCampaign.Channel === 2738 &&
                      this.renderPopup(
                        'Sending previously booked specialist availability (Next 8 weeks) to all CMs of booking clients. Campaign will be sent at 7:30AM the next day',
                      )} */}
                    {'   '}
                    {dataCampaign.SetScheduleDate && dataCampaign.NeedSendEmail && (
                      <span>
                        - {dataCampaign.SetScheduleDate ? renderDate(dataCampaign.SetScheduleDate, false, true) : ''}
                        {dataCampaign.SetScheduleBy ? ` - By ${dataCampaign.SetScheduleBy}` : ''}
                      </span>
                    )}
                    {dataCampaign.SentErrorDate && !dataCampaign.SaveAndSendDate && dataCampaign.NeedSendEmail && (
                      <text className="campaign-erorr-text">
                        {' '}
                        Sending Error: {dataCampaign.SentErrorName} -{' '}
                        {renderDate(dataCampaign.SentErrorDate, false, true)}
                      </text>
                    )}
                  </div>
                  <div style={{ width: '100%', marginTop: '6px' }}>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={
                        !!dataCampaign.SaveAndSendDate ||
                        !!dataCampaign.SentDateCampaignManual ||
                        !dataCampaign.SetScheduleDate
                      }
                      checked={dataCampaign.IsCancelSchedule}
                      onChange={store.handleChangeFieldCheckbox('IsCancelSchedule')}
                    ></Checkbox>
                    {'   '}Cancel Schedule{'   '}
                    {dataCampaign.IsCancelSchedule && dataCampaign.CancelScheduleDate && (
                      <span>
                        -{' '}
                        {dataCampaign.CancelScheduleDate
                          ? renderDate(dataCampaign.CancelScheduleDate, false, true)
                          : ''}
                        {dataCampaign.CancelScheduleBy ? ` - By ${dataCampaign.CancelScheduleBy}` : ''}
                      </span>
                    )}
                  </div>
                </Form.Item>
                <Form.Item
                  className="campaign-scheduled"
                  label={
                    <React.Fragment>
                      Send Immediately
                      {this.renderPopup('Click Send will send the campaign immediately without scheduling')}
                    </React.Fragment>
                  }
                >
                  <div>
                    <Button
                      className="blue"
                      disabled={dataCampaign.NeedSendEmail || dataCampaign.SentDateCampaignManual}
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          modalType: 'confirm',
                          message: `Do you want to send the campaign now?`,
                          onOk: () => store.handleSave(true, false),
                        });
                      }}
                    >
                      Send
                    </Button>
                    {dataCampaign.SentDateCampaignManual && (
                      <span>
                        Sent:{' '}
                        {dataCampaign.SentDateCampaignManual
                          ? renderDate(dataCampaign.SentDateCampaignManual, false, true)
                          : ''}
                        {dataCampaign.SentByCampaignManual ? ` - By ${dataCampaign.SentByCampaignManual}` : ''}
                      </span>
                    )}
                    {dataCampaign.SentManualErrorDate && (
                      <text className="campaign-erorr-text">
                        {' '}
                        Sending Error: {dataCampaign.SentManualErrorName} -{' '}
                        {renderDate(dataCampaign.SentManualErrorDate, false, true)}
                      </text>
                    )}
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Schedule Option{' '}
                      {!!dataCampaign.Id && (
                        <Link to="#" onClick={() => this.viewSentCampaigns(dataCampaign.Id)}>
                          View Sent Campaigns
                        </Link>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail
                    }
                    size="large"
                    showArrow
                    optionFilterProp="children"
                    showSearch
                    value={dataCampaign.ScheduledMethod}
                    onChange={store.handleChangeFieldSelect('ScheduledMethod')}
                  >
                    {METHOD_SEND.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {i.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Send Time">
                  <div className="ant-form-item-control">
                    <span className="ant-form-item-children">
                      <input
                        className="ant-input ant-input-lg ant-input-disabled"
                        type="text"
                        disabled
                        value="7:30 NSW/Sydney"
                      ></input>
                    </span>
                  </div>
                </Form.Item>
                <Form.Item
                  label="Start Date"
                  required={dataCampaign.NeedSendEmail}
                  validateStatus={store.errors['ScheduledStartDate'] ? 'error' : null}
                  help={store.errors['ScheduledStartDate'] ? store.errors['ScheduledStartDate'] : null}
                >
                  <DatePicker
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail ||
                      dataCampaign.ScheduledMethod === 0
                    }
                    size="large"
                    format="DD/MM/YYYY"
                    onChange={store.handleChangeDatePicker('ScheduledStartDate')}
                    style={{ marginRight: '10px' }}
                    value={renderDate(dataCampaign.ScheduledStartDate)}
                  />
                </Form.Item>
                <Form.Item label="End Date">
                  <DatePicker
                    disabled={
                      !!dataCampaign.SaveAndSendDate ||
                      !!dataCampaign.SentDateCampaignManual ||
                      !dataCampaign.NeedSendEmail ||
                      dataCampaign.ScheduledMethod === 0 ||
                      dataCampaign.ScheduledMethod === 1
                    }
                    size="large"
                    format="DD/MM/YYYY"
                    onChange={store.handleChangeDatePicker('ScheduledEndDate')}
                    style={{ marginRight: '10px' }}
                    value={renderDate(dataCampaign.ScheduledEndDate)}
                  />
                </Form.Item>
                <Divider className="fluid-field" style={{ height: 0, margin: 0 }} />
                <Form.Item
                  label="From"
                  required
                  validateStatus={store.errors['From'] ? 'error' : null}
                  help={store.errors['From'] ? store.errors['From'] : null}
                >
                  <Select
                    size="large"
                    showArrow
                    optionFilterProp="children"
                    showSearch
                    value={dataCampaign.From}
                    onChange={store.handleChangeFieldSelect('From')}
                  >
                    {store.staffs.map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* <Form.Item className="campaign-scheduled">
                  <div>
                     <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={!!dataCampaign.SaveAndSendDate || !!dataCampaign.SentDateCampaignManual}
                      checked={dataCampaign.IsCancelSchedule}
                      onChange={store.handleChangeFieldCheckbox('IsCancelSchedule')}
                    ></Checkbox>{'   '} Cancel Schedule
                    {dataCampaign.IsCancelSchedule && dataCampaign.CancelScheduleDate && (
                      <span>
                        - Cancelled: {dataCampaign.CancelScheduleDate ? renderDate(dataCampaign.CancelScheduleDate, false, true) : ''}
                        {dataCampaign.CancelScheduleBy ? ` - By ${dataCampaign.CancelScheduleBy}` : ''}
                      </span>
                    )}
                  </div>
                </Form.Item> */}
              </React.Fragment>
            )}

            <Form.Item
              // required={!dataCampaign.Attachments || !dataCampaign.Attachments?.filter(i => i.AttachmentType === 3) || dataCampaign.Attachments?.filter(i => i.AttachmentType === 3) <= 0}
              // validateStatus={store.errors['To'] ? 'error' : null}
              // help={store.errors['To'] ? store.errors['To'] : null}
              label={<>To {this.renderPopup("Multiple emails separated by ';'")}</>}
            >
              <Input
                value={dataCampaign.To}
                onChange={store.handleChangeFieldInput('To')}
                disabled={
                  dataCampaign.Attachments && dataCampaign.Attachments?.filter(i => i.AttachmentType === 3).length > 0
                }
                size="large"
              />
            </Form.Item>
            <Form.Item label={<>Bcc {this.renderPopup("Multiple emails separated by ';'")}</>}>
              <Input value={dataCampaign.Bcc} size="large" onChange={store.handleChangeFieldInput('Bcc')} />
            </Form.Item>
            <Form.Item
              className="add-file-container"
              label={
                <React.Fragment>
                  Attached Contact List
                  {this.renderPopup('This list should contain Title, FirstName, LastName, Email in Excel format')}
                  <Link to="#" onClick={() => window.open('/ExportImport/DownloadMailMergeCampaignTemplate', '_blank')}>
                    View Contact List Template
                  </Link>
                </React.Fragment>
              }
            >
              <FieldUpload
                multiUpload={false}
                uploadTitle={'1 Excel file only'}
                option={{
                  maxFileSize: '100MB',
                  allowedExtensions:
                    'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }}
                disabled={dataCampaign.To}
                type={3}
                hideDownload={true}
              />
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item
          className="fluid-field"
          label={
            <>
              {' '}
              Campaign Content{' '}
              {dataCampaign.Channel === 2713 &&
                this.renderPopup(
                  'Parameters can be used in content: {Title}, {FirstName}, {LastName}, {Email}, {UnsubscribeLink} retrieved from the Contact list',
                )}
              {dataCampaign.Channel === 2738 &&
                this.renderPopup(
                  'Parameters can be use in content: {CMFirstName}, {CMLastName}, {ClientName}, {UnsubscribeLink}',
                )}
              {/* {(dataCampaign.Channel === 2738 || dataCampaign.Channel === 2738) &&
                'Available parameters: (Title}, (FistName), (LastName), (Email), (UnsubscribeLink)'} */}
            </>
          }
        >
          {dataCampaign.Channel === 2713 && (
            <div style={{ width: '100%', marginBottom: '10px' }}>
              Parameters can be used in content: {'{Title}, {FirstName}, {LastName}, {Email}, {UnsubscribeLink}'}{' '}
              retrieved from the Contact list
            </div>
          )}
          {dataCampaign.Channel === 2738 && (
            <div style={{ width: '100%', marginBottom: '10px' }}>
              Parameters can be use in content: {'{CMFirstName}, {CMLastName}, {ClientName}, {UnsubscribeLink}'}
            </div>
          )}
          <CKEditor
            html={dataCampaign.CampaignContent}
            handleChangeContent={store.handleChangeCKEditor('CampaignContent')}
          />
        </Form.Item>
        {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
          <Form.Item
            className="fluid-field"
            label={
              <>
                Signature {this.renderPopup('If signature is blank, default system signature will be used in emails')}
              </>
            }
          >
            <CKEditor
              html={dataCampaign.MailSignature}
              handleChangeContent={store.handleChangeCKEditor('MailSignature')}
            />
          </Form.Item>
        )}
        <Form.Item
          label={
            <>
              Campaign Attachments{' '}
              {this.renderPopup(
                'Campaign attachments will be sent in Relationship Emails from CMs or Specialists Correspondence',
              )}
            </>
          }
          className="add-file-container"
        >
          <FieldUpload multiple type={2} hideDownload={true} />
        </Form.Item>
        <Form.Item label="Target Audience">
          <Select
            size="large"
            value={dataCampaign.TargetAudience}
            onChange={store.handleChangeFieldSelect('TargetAudience')}
            showArrow
            optionFilterProp="children"
            showSearch
          >
            {store.targetAudience.map(i => (
              <Select.Option value={i.Id} key={i.Id}>
                {i.Label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<>Sample Content {this.renderPopup('Sample content is for reference only, not to be sent')}</>}
          className="add-file-container"
        >
          <FieldUpload multiple type={1} hideDownload={true} />
        </Form.Item>
        <Form.Item label="Target Status">
          <Select
            size="large"
            onChange={store.handleChangeFieldSelect('TargetStatus')}
            value={dataCampaign.TargetStatus}
            showArrow
            optionFilterProp="children"
            showSearch
          >
            {store.targetStatus.map(i => (
              <Select.Option value={i.Id} key={i.Id}>
                {i.Label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Location">
          <Select
            size="large"
            mode="multiple"
            showArrow
            onChange={store.handleChangeFieldSelectMultiple('Location')}
            optionFilterProp="children"
            value={toJS(dataCampaign.Location) || []}
            allowClear
          >
            {State.map(i => (
              <Select.Option value={i.id} key={i.id}>
                {i.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Volume (Number of Contacts Sent Out)">
          <Input size="large" value={dataCampaign.Volume} onChange={store.handleChangeFieldInput('Volume')} />
        </Form.Item>
        <Form.Item label="Success Rate (%)">
          <Input size="large" value={dataCampaign.SuccessRate} onChange={store.handleChangeFieldInput('SuccessRate')} />
        </Form.Item>
        <Form.Item label="Response Rate (%)">
          <Input
            size="large"
            value={dataCampaign.ResponsesRate}
            onChange={store.handleChangeFieldInput('ResponsesRate')}
          />
        </Form.Item>
        <Form.Item label="Comment" className="fluid-field">
          <Input.TextArea rows={4} value={dataCampaign.Comment} onChange={store.handleChangeFieldInput('Comment')} />
        </Form.Item>
      </Form>
    );
  }
}

export default FormDetails;
