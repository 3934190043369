import './Pagination.scss';

import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Pagination as SemanticPagination } from 'semantic-ui-react';

@observer
class Pagination extends React.Component {
  render() {
    const { totalPage, currentPage, onPageChange } = this.props;

    return (
      <SemanticPagination
        className="Pagination"
        activePage={currentPage}
        boundaryRange={1}
        onPageChange={onPageChange}
        siblingRange={1}
        totalPages={totalPage}
        firstItem={null}
        lastItem={null}
        prevItem={{
          content: <Icon color="blue" name="angle left" />,
          icon: true,
        }}
        nextItem={{
          content: <Icon color="blue" name="angle right" />,
          icon: true,
        }}
      />
    );
  }
}

export default Pagination;
