import { Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';

import AMATimeUnitSettingStore from './Store';
import AMATimeUnitSettingTable from './Table';
import AMATimeUnitSettingToolbar from './Toolbar';
import AMATimeUnitSettingModal from './Modal';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class AMATimeUnitSetting extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/time-unit', true);
      return;
    }
    AMATimeUnitSettingStore.fetchData(true);
  }
  render() {
    const { data, loading, open } = AMATimeUnitSettingStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <AMATimeUnitSettingToolbar />
            <AMATimeUnitSettingTable />
            {open && <AMATimeUnitSettingModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default AMATimeUnitSetting;
