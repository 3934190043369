import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import customFetch from '../../../utils/customFetch';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import IndustrySegmentsStore from './IndustrySegmentsStore';

const columns = [{ title: '' }, { title: 'Name' }, { title: 'Description' }, { title: 'Status' }];

@observer
class IndustrySegmentsTable extends React.Component {
  openModal = options => {
    return IndustrySegmentsStore.toggleModal(true, options);
  };

  handleDelete = id => async () => {
    IndustrySegmentsStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/IndustrySegment/DeleteRecord', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      await IndustrySegmentsStore.refetch({}, true);
    } finally {
      IndustrySegmentsStore.setFieldsValue({ loading: false });
    }
  };

  openModalEdit = (id, record) => {
    return IndustrySegmentsStore.toggleModal(true, {
      modalType: 'addEdit',
      id,
      record,
    });
  };

  handleEditItemStatus = (id, record) => async () => {
    IndustrySegmentsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/IndustrySegment/EditItemStatus', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });
      await IndustrySegmentsStore.refetch({}, true);
    } finally {
      IndustrySegmentsStore.setFieldsValue({ loading: false });
    }
  };

  handlePageClick = page => {
    IndustrySegmentsStore.refetch({ curPage: page });
  };

  renderTableBody = () => {
    return IndustrySegmentsStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className={cx({ 'Cell-nowrap': record.IsActive })}>
          <Link to="#" onClick={this.openModalEdit(Id, record)}>
            {record.Label}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && (
            <Menu.Item>
              <Link to="#" onClick={this.openModalEdit(id, record)}>
                Edit
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data } = IndustrySegmentsStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={IndustrySegmentsStore.sortColumn}
          sortDirection={IndustrySegmentsStore.sortDirection}
          onSort={IndustrySegmentsStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={IndustrySegmentsStore.curPage}
          totalPage={IndustrySegmentsStore.totalPage || 0}
          totalItems={IndustrySegmentsStore.totalItems || 0}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default IndustrySegmentsTable;
