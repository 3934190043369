import './index.scss';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { Dropdown, Menu, notification } from 'antd';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';
import ClientReportStore from './ClientReportStore';
import { TableHeader, TableEmptyMessage } from '../../shared/table';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { toJS } from 'mobx';
import { OpenCaseDetails } from '@utils/functions';

const IMETable = [
  'AAptRequested',
  'AAptConfirmed',
  'APaperworkReceived',
  'AAptAttended',
  'ADictationReceived',
  'AReportDraft',
  'AReportCompleted',
  'AReportCorrectedReportReturned',
  'AReportCancelledWithFee',
  'AClientInvoice',
  'ASpecialistInvoice',
  'AServicePaid',
];
const FRTable = [
  `FRequested`,
  `FRequestSentToProvider`,
  `FDictationReceived`,
  `FReportDraftSentToDoctor`,
  `FReportReturnedFromDoctors`,
  `FReportCompleted`,
  `FCancelledNoFee`,
  `FCancelledWithFee`,
  `FBilled`,
  `FServicePaid`,
];
const SRTable = [
  `SRequested`,
  `SRequestSentToProvider`,
  `SuppDictationReceived`,
  `SReportDraftSentToDoctor`,
  `SReportCompleted`,
  `SuppReportReturnedFromDoctor`,
  `SCancelledNoFee`,
  `SCancelledWithFee`,
  `SBilled`,
  `SServicePaid`,
];
const CRTable = [
  `CRRequestReceived`,
  `CRRequestSentToSpecialist`,
  `CRPrepaymentRequired`,
  `CRPrepaymentPaid`,
  `CRCRFilesSent`,
  `CRCancelledNoFee`,
  `CRCancelledWithFee`,
  `CROnHold`,
  `CRServiceBilled`,
  `CRServicePaid`,
];

const MNTable = [`MNRequestReceived`, `MNRequestSentToSpecialist`, `MNCloseWithoutBookings`, `MNCloseWithBookings`];

@observer
class DoctorReportsTable extends React.Component {
  state = {
    data: this.props.dataTable || null,
    colDate: this.props.colDate || `none`,
    type:
      IMETable.indexOf(this.props.colDate) !== -1
        ? 1
        : FRTable.indexOf(this.props.colDate) !== -1
        ? 2
        : SRTable.indexOf(this.props.colDate) !== -1
        ? 3
        : SRTable.indexOf(this.props.colDate) !== -1
        ? 4
        : CRTable.indexOf(this.props.colDate) !== -1
        ? 5
        : MNTable.indexOf(this.props.colDate) !== -1,
  };

  convertHeader = data => {
    const { colDate } = this.state;
    switch (colDate) {
      case `AAptRequested`:
        return `Assessment - Appointment Requested (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AAptConfirmed`:
        return `Assessment - Appointment Confirmed (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `APaperworkReceived`:
        return `Assessment - Appointment Paperwork Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AAptAttended`:
        return `Assessment - Appointment Attended (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `ADictationReceived`:
        return `Assessment - Dictation Received (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AReportDraft`:
        return `Assessment - Report Draft Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AReportCompleted`:
        return `Assessment - Report Sent to CMs (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AReportCorrectedReportReturned`:
        return `Assessment - Corrected Report Returned (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `SCancelledWithFee`:
        return `Supplementary - Cancelled With Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;

      case `AReportCancelledWithFee`:
        return `Assessment - Cancelled With Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `SCancelledNoFee`:
        return `Supplementary - Cancelled No Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `SBilled`:
        return `Supplementary - Service Billed (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `AClientInvoice`:
        return `Assessment - Client Invoiced  (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `ASpecialistInvoice`:
        return `Assessment - Specialist Billed  (${Array.isArray(toJS(data)) ? data.length : 0})`;
      // case `AServiceInvoiced`:
      //   return `Assessment - Service Invoiced  (${
      //     Array.isArray(toJS(data)) ? data.length : 0
      //   })`;
      case `AServicePaid`:
        return `Assessment - Service Paid  (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `SReportCompleted`:
        return `Supplementary - Reports Sent to CMs (${Array.isArray(toJS(data)) ? data.length : 0})`;
      //FR
      case `FRequested`:
        return `File Review - Request Received  (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FRequestSentToProvider`:
        return `File Review - Request Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FDictationReceived`:
        return `File Review - Dictation Received (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FReportDraftSentToDoctor`:
        return `File Review - Report Draft Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FReportReturnedFromDoctors`:
        return `File Review - Report Returned from Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FReportCompleted`:
        return `File Review - Report Sent to CMs (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FCancelledNoFee`:
        return `File Review - Cancelled No Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FCancelledWithFee`:
        return `File Review - Cancelled With Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FBilled`:
        return `File Review - Service Billed (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `FServicePaid`:
        return `File Review - Service Paid (${Array.isArray(toJS(data)) ? data.length : 0})`;
      //SUPP
      case `SReportDraftSentToDoctor`:
        return `Supplementary - Report Draft Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `SReportReturnedFromDoctor`:
        return `Supplementary - Corrected Report Returned from Specialist (${
          Array.isArray(toJS(data)) ? data.length : 0
        })`;
      case `SDictationReceived`:
        return `Supplementary - Dictation Received (${Array.isArray(toJS(data)) ? data.length : 0})`;

      case `SRequested`:
        return `Supplementary - Request Received (${Array.isArray(toJS(data)) ? data.length : 0})`;

      case `SRequestSentToProvider`:
        return `Supplementary - Request Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;

      case `SServicePaid`:
        return `Supplementary - Service Paid (${Array.isArray(toJS(data)) ? data.length : 0})`;
      //CR
      case `CRRequestReceived`:
        return `Clinical Records - Request Received (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRRequestSentToSpecialist`:
        return `Clinical Records - Request Sent to Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRPrepaymentRequired`:
        return `Clinical Records - Prepayment Required (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRPrepaymentPaid`:
        return `Clinical Records - Prepayment Paid (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRCRFilesSent`:
        return `Clinical Records - CR Files Sent (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRCancelledNoFee`:
        return `Clinical Records - Cancelled No Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRCancelledWithFee`:
        return `Clinical Records - Cancelled With Fee (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CROnHold`:
        return `Clinical Records - On-Hold (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRServiceBilled`:
        return `Clinical Records - Service Billed (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `CRServicePaid`:
        return `Clinical Records - Service Paid (${Array.isArray(toJS(data)) ? data.length : 0})`;
      //END CR
      //MN
      case `MNRequestReceived`:
        return `Medical Negligence - Request Received (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `MNRequestSentToSpecialist`:
        return `Medical Negligence - Request Sent To Specialist (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `MNCloseWithoutBookings`:
        return `Medical Negligence - Close Without Bookings (${Array.isArray(toJS(data)) ? data.length : 0})`;
      case `MNCloseWithBookings`:
        return `Medical Negligence - Close With Bookings (${Array.isArray(toJS(data)) ? data.length : 0})`;
      //END MN
      default:
        return ``;
    }
  };

  convertStatus = () => {
    const { colDate } = this.state;
    switch (colDate) {
      case `AAptRequested`:
        return `Appointment Requested`;
      case `AAptConfirmed`:
        return `Appointment Confirmed`;
      case `APaperworkReceived`:
        return `Paperwork Sent to Specialist`;
      case `AAptAttended`:
        return `Appointment Attended`;
      case `ADictationReceived`:
      case `SDictationReceived`:
        return `Dictation Received`;
      case `AReportDraft`:
        return `Report Draft Sent to Specialist`;
      case `AReportCompleted`:
        return `Report Sent to CMs`;
      case `AReportCorrectedReportReturned`:
      case `SReportReturnedFromDoctor`:
        return `Corrected Report Returned from Specialist`;
      case `AReportCancelledNoFee`:
        return `Cancelled-No Fee`;
      case `SCancelledWithFee`:
      case `AReportCancelledWithFee`:
        return `Cancelled with Fee`;
      case `SCancelledNoFee`:
        return `Cancelled No Fee`;
      case `SBilled`:
        return `Service Billed`;
      case `AClientInvoice`:
        return `Client Invoiced`;
      case `ASpecialistInvoice`:
        return `Specialist Billed`;
      // case `AServiceInvoiced`:
      //   return `Service Invoiced`;
      case `SReportCompleted`:
        return `Reports Sent to CMs`;
      case `SReportDraftSentToDoctor`:
        return `Report Draft Sent to Specialist`;
      case `SRequested`:
        return `Request Received`;
      case `SRequestSentToProvider`:
        return `Request Sent to Specialist`;
      case `AServicePaid`:
        return `Service Paid`;
      case `SServicePaid`:
        return `Service Paid`;
      //FR
      case `FRequested`:
        return `Request Received`;
      case `FRequestSentToProvider`:
        return `Request Sent to Specialist`;
      case `FDictationReceived`:
        return `Dictation Received`;
      case `FReportDraftSentToDoctor`:
        return `Report Draft Sent to Specialist`;
      case `FReportReturnedFromDoctors`:
        return `Report Returned from Specialist`;
      case `FReportCompleted`:
        return `Report Sent to CMs`;
      case `FCancelledNoFee`:
        return `Cancelled No Fee`;
      case `FCancelledWithFee`:
        return `Cancelled with Fee`;
      case `FBilled`:
        return `Service Billed`;
      case `FServicePaid`:
        return `Service Paid`;
      default:
        return ``;
    }
  };

  convertColDate = () => {
    const { colDate } = this.state;
    switch (colDate) {
      case `AAptRequested`:
        return `Request Date`;
      case `AAptConfirmed`:
        return `Confirmed Date`;
      case `APaperworkReceived`:
        return `Paperwork Sent Date`;
      case `AAptAttended`:
        return `Attended Date`;
      case `ADictationReceived`:
        return `Dictation Received Date`;
      case `AReportDraft`:
        return `Report Draft Sent Date`;
      case `AReportCompleted`:
        return `Report Sent Date`;
      case `SCancelledWithFee`:
      case `SCancelledNoFee`:
      case `FCancelledWithFee`:
      case `FCancelledNoFee`:
      case `AReportCancelledWithFee`:
        return `Cancelled Date`;
      case `SBilled`:
      case `FBilled`:
      case `SServicePaid`:
      case `FServicePaid`:
      case `ABilled`:
        return `Billed Date`;
      case `SReportCompleted`:
      case `FReportCompleted`:
        return `Report Completed Date`;
      case `SReportDraftSentToDoctor`:
      case `FReportDraftSentToDoctor`:
        return `Sent Date`;
      case `SRequested`:
      case `SRequestSentToProvider`:
      case `FRequested`:
      case `FRequestSentToProvider`:
        return `Requested Date`;
      case `FReportSentToCMs`:
        return `Date of Report Sent to CMs`;
      default:
        return `Request Date`;
    }
  };
  ///Report/SendReminder assessmentId
  handleSendReminder = async id => {
    try {
      await customFetch('/Report/SendReminder', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ assessmentId: id }),
      });
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Reminder sent.`,
        duration: 2,
      });
    } catch (e) {
      return ClientReportStore.toggleModal(true, {
        modalType: `confirm`,
        message: `Error occured while contacting server. Please report this error to system administrator and try again later`,
      })();
    }
  };

  handledSendReminderMessage = value => {
    if (value.Doctor) {
      return ClientReportStore.toggleModal(true, {
        modalType: `confirm`,
        message: `Do you want to send a paperwork reminder to the CM?`,
        onOk: () => this.handleSendReminder(value.AssessmentId),
      })();
    } else {
      return ClientReportStore.toggleModal(true, {
        modalType: `confirm`,
        message: `There is no doctor information. Please check again.`,
      })();
    }
  };

  openHistory = record => {
    return ClientReportStore.toggleModal(true, {
      modalType: `history`,
      record,
    })();
  };

  renderTableActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => this.handledSendReminderMessage(record)}>Send Reminder</Menu.Item>
          <Menu.Item onClick={() => this.openHistory(record)}>Show History</Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  convertDate = record => {
    const { colDate } = this.state;
    if (
      colDate === `AAptRequested` ||
      colDate === `FRequested` ||
      colDate === `SRequested` ||
      colDate === `FRequestSentToProvider` ||
      colDate === `SRequestSentToProvider`
    ) {
      return record.DateOfRequest ? moment(record.DateOfRequest).format('DD/MM/YYYY') : ``;
    } else if (colDate === `AAptConfirmed`) {
      return record.AppointmentConfirmedDate ? moment(record.AppointmentConfirmedDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `APaperworkReceived`) {
      return record.PaperworkReceivedDate ? moment(record.PaperworkReceivedDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `AAptAttended`) {
      return record.DateOfAppointmentAttended ? moment(record.DateOfAppointmentAttended).format('DD/MM/YYYY') : ``;
    } else if (colDate === `ADictationReceived`) {
      return record.DateOfDictationReceived ? moment(record.DateOfDictationReceived).format('DD/MM/YYYY') : ``;
    } else if (colDate === `AReportDraft`) {
      return record.DateOfReportDraftSentDate ? moment(record.DateOfReportDraftSentDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `AReportCompleted`) {
      return record.DateOfReportDraft ? moment(record.DateOfReportDraft).format('DD/MM/YYYY') : ``;
    } else if (colDate === `AReportCorrectedReportReturned`) {
      return record.ReportCompletedDate ? moment(record.ReportCompletedDate).format('DD/MM/YYYY') : ``;
    } else if (
      colDate === `AReportCancelledWithFee` ||
      colDate === 'SCancelledNoFee' ||
      colDate === 'SCancelledWithFee' ||
      colDate === 'FCancelledNoFee' ||
      colDate === 'FCancelledWithFee'
    ) {
      return record.CancelledDate ? moment(record.CancelledDate).format('DD/MM/YYYY') : ``;
    } else if (
      colDate === `AClientInvoice` ||
      colDate === `ASpecialistInvoice` ||
      colDate === `AServiceInvoiced` ||
      colDate === `SBilled` ||
      colDate === `FBilled`
    ) {
      return record.BilledDate ? moment(record.BilledDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `FReportDraftSentToDoctor` || colDate === `SReportDraftSentToDoctor`) {
      return record.PaperworkByOtherMethodDate ? moment(record.PaperworkByOtherMethodDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `FReportCompleted`) {
      return record.StatusDate ? moment(record.StatusDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `SReportCompleted`) {
      return record.ReportCompletedDate ? moment(record.ReportCompletedDate).format('DD/MM/YYYY') : ``;
    } else if (colDate === `FReportReturnedFromDoctors`) {
      return record.StatusDate ? moment(record.StatusDate).format('DD/MM/YYYY') : ``;
    }

    return ``;
  };

  openCaseDetails = (record, id) => {
    const { type } = this.state;
    const tabName =
      type === 1
        ? 'AssessmentCaseId'
        : type === 2
        ? 'FileReviewCaseId'
        : type === 3
        ? 'SupplementaryCaseId'
        : type === 4
        ? 'ClinicalRecordCaseId'
        : 'MedNegCaseId';
    const viewName =
      type === 1
        ? 'PostAssessment'
        : type === 2
        ? 'PostFileReview'
        : type === 3
        ? 'PostSupplementary'
        : type === 4
        ? 'AddEditClinicalNote'
        : 'PostMedNeg';
    localStorage.setItem(`${tabName}`, `Case ${record.CaseNo}`);
    iframe.forceOpenTab(`${viewName}`, `?id=${id}&view=${viewName}`, {
      id,
      view: `${viewName}`,
    });
  };

  renderTableBody = (data, colSpan) => {
    const { type } = this.state;
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }
    return data.map(({ Id, ...record }, index) => (
      <Table.Row key={index}>
        {type === 1 && <Table.Cell className="Cell-actions">{this.renderTableActions(record)}</Table.Cell>}
        <Table.Cell className="Cell-link" onClick={() => this.openCaseDetails(record, Id)}>
          <Link to="#">{record.CaseNo}</Link>
        </Table.Cell>
        <Table.Cell>{record.CreatedDate ? moment(record.CreatedDate).format('DD/MM/YYYY') : ``}</Table.Cell>
        {/* <Table.Cell>{this.convertDate(record)}</Table.Cell> */}
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'specialist',
                id: record.DoctorId,
                localStorageName: 'DoctorName',
                localStorageValue: record.Doctor,
              })
            }
          >
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.Doctor}{' '}
            {record.DoctorId > 0 ? `${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}` : ``}
          </Link>
          <div style={{ whiteSpace: 'nowrap' }}>{record.DoctorId > 0 ? `Specialty:` + record.DoctorSepcialty : ``}</div>
          <div className={cx(record.DoctorContractReady ? 'text-success' : 'text-danger')}>
            {record.DoctorId > 0 ? `Contract Ready: ${!record.DoctorContractReady ? 'No' : 'Yes'}` : ``}
          </div>
          <div>
            {record.ClinicId > 0 ? (
              <Link
                to="#"
                onClick={() =>
                  OpenCaseDetails({
                    tabName: 'clinic',
                    id: record.ClinicId,
                    localStorageName: 'ClinicName',
                    localStorageValue: record.ClinicName,
                  })
                }
              >
                ({record.ClinicId}) {record.ClinicName}
              </Link>
            ) : (
              ``
            )}
          </div>
          <div>{record.ClinicId > 0 ? record.ClinicEmail : ``}</div>
          <div>{record.ClinicId > 0 ? record.ClinicTelephone : ``}</div>
        </Table.Cell>
        <Table.Cell>
          {record.ClaimType ? record.ClaimType : ``}
          <div>{record.ReportType ? record.ReportType : ``}</div>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.Claimant}</Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: record.CMId,
                localStorageName: 'CmName',
                localStorageValue: record.CaseManager,
              })
            }
          >
            {record.CMId > 0 ? `(${record.CMId})` : ''} {record.CaseManager}
          </Link>
          <div>{record.CMEmail ? `${record.CMEmail}` : ''}</div>
          <div>{record.CMPhone ? `${record.CMPhone}` : ''}</div>
        </Table.Cell>
        {/* <Table.Cell></Table.Cell> */}
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{this.convertStatus()}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate ? moment(record.StatusDate).format('DD/MM/YYYY') : ``}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading } = ClientReportStore;
    const { type } = ClientReportStore;
    const { data } = this.state;
    // const colName = this.convertColDate();
    const columnsIME = [
      { title: '' },
      { title: 'Case No' },
      //{ title: `${colName}` },
      { title: 'Request Date' },
      { title: 'Specialist' },
      { title: 'Claim/Report Type' },
      { title: 'Claimant' },
      { title: 'Claim Number' },
      { title: 'Case Manager' },
      { title: 'Client' },
      { title: 'Status' },
      { title: `Status Date` }, // Change col name
    ];
    const columnsFRAndSR = [
      { title: 'Case No' },
      //{ title: `${colName}` },
      { title: 'Request Date' },
      { title: 'Specialist' },
      { title: 'Claim/Report Type' },
      { title: 'Claimant' },
      { title: 'Claim Number' },
      { title: 'Case Manager' },
      { title: 'Client' },
      { title: 'Status' },
      { title: 'Status Date' }, // Change col name
    ];

    const columnsCR = [
      { title: 'Case No' },
      { title: 'Request Date' },
      { title: 'CR Provider' },
      { title: 'Claim/Report Type' },
      { title: 'Claimant' },
      { title: 'Claim Number' },
      { title: 'Case Manager' },
      { title: 'Client' },
      { title: 'Status' },
      { title: 'Status Date' }, // Change col name
    ];

    const colSpan = columnsIME.length;

    return (
      <React.Fragment>
        <div style={{ marginTop: `20px` }}>
          <span>
            <h3 className={cx({ Header: loading })}>{this.convertHeader(data)}</h3>
          </span>
          <Table sortable striped celled className={cx({ disabled: loading })}>
            <TableHeader
              columns={
                type === 1
                  ? columnsIME
                  : type === 2
                  ? columnsFRAndSR
                  : type === 3
                  ? columnsFRAndSR
                  : type === 4
                  ? columnsCR
                  : columnsFRAndSR
              }
            />
            <Table.Body>{this.renderTableBody(data, colSpan)}</Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default DoctorReportsTable;
