import './index.scss';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import CMReportStore from './CMReportStore';
import CMReportModal from './CMReportModal';
import Toolbar from './Toolbar';
import CMTable from './CMReportTable';
import CMNotBookedTable from './CMNotBookedTable';
import SalesCall from '../SalesCallReportForCM';
import OtherSalesCall from '../OtherSalesCallReportForCM';
import IMESearch from './IMESearch';
import CMExport from './CMExport';

@observer
class CMReports extends React.Component {
  componentDidMount() {
    CMReportStore.fetchCM();
    CMReportStore.fetchClientSearch();
  }

  render() {
    const { loadingCM, dataCM, open, tabIsActive, loadingClientSearch, dataClientSearch } = CMReportStore;

    return (
      <div
        className={`Table-${
          (loadingCM && !dataCM) || (loadingClientSearch && !dataClientSearch) ? 'loading' : 'container'
        }`}
      >
        {(loadingCM && !dataCM) || (loadingClientSearch && !dataClientSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            {open && <CMReportModal />}
            {tabIsActive === 'bookingHistory' && <CMTable />}
            {tabIsActive === 'lastBookings' && <CMNotBookedTable />}
            {tabIsActive === 'export' && <CMExport />}
            {tabIsActive === 'saleCall' && <SalesCall type="cm" />}
            {tabIsActive === 'otherSaleCall' && <OtherSalesCall type="cm" />}
            {tabIsActive === 'imeSearch' && <IMESearch />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CMReports;
