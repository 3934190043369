import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import SupplementaryReportsStore from './SupplementaryReportsStore';
import ModalActionNotes from './ModalActionNotes';
import ModalCaseProgress from './ModalCaseProgress';
import ModalConfirmNewUI from './ModalConfirmNewUI';

function SupplementaryReportsModal() {
  const { modalType } = SupplementaryReportsStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...SupplementaryReportsStore} onCancel={SupplementaryReportsStore.toggleModal(false)} />;
  } else if (modalType === 'notes') {
    return <ModalActionNotes />;
  } else if (modalType === 'newUI') {
    return <ModalConfirmNewUI {...SupplementaryReportsStore} onCancel={SupplementaryReportsStore.toggleModal(false)} />;
  } else {
    return <ModalCaseProgress />;
  }
}

export default SupplementaryReportsModal;
