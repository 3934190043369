import './configureValidator';

import { notification } from 'antd';
import { Formik } from 'formik';
import omit from 'lodash/omit';
import React from 'react';
import Validator from 'validatorjs';

import arrToMap from '../../../utils/arrToMap';
import { showInvalidFormError } from '../../../utils/errors';
import ButtonSubmit from './ButtonSubmit';
import * as inputMap from './Input';

class Form extends React.Component {
  // Not allow to update fields
  // enableReinitialize={false}
  fields = this.props.fields;
  rules = arrToMap(
    this.fields.filter(f => f.rule),
    'name',
    'rule',
  );
  initialValues = arrToMap(this.fields, 'name', f => f.defaultValue || '');
  isInitialValid = new Validator(this.initialValues, this.rules).passes();

  validate = values => {
    const v = new Validator(values, this.rules);
    const errors = {};
    if (!v.passes()) {
      Object.keys(values).forEach(k => {
        if (v.errors.has(k)) {
          errors[k] = v.errors.first(k);
        }
      });
    }
    return errors;
  };

  setTouched = false;
  closureOnFormSubmit = p => e => {
    e.preventDefault();
    notification.destroy();

    if (!this.setTouched) {
      p.setTouched(arrToMap(this.fields, 'name'));
      this.setTouched = true;
    }

    const { onInvalidSubmit, onValidSubmit } = this.props;
    if (!p.isValid) {
      showInvalidFormError();
      onInvalidSubmit && onInvalidSubmit();
    } else if (onValidSubmit) {
      onValidSubmit(p.values);
    }
  };

  renderFields = () => {
    const { noAutoRender, children, submit } = this.props;
    return noAutoRender ? (
      children
    ) : (
      <React.Fragment>
        {this.fields.map(({ type, ...f }) => {
          const Input = type ? inputMap[type] : inputMap.TextInput;
          if (!Input) {
            console.error(`Render Error: Couldn't find any components with the type of ${type}`);
            return null;
          }
          return <Input key={f.label || f.name} {...f} />;
        })}
        {submit && (
          <div className="d-flex flex-end">
            <ButtonSubmit icon="angle double right" content={submit.label || 'Login'} {...omit(submit, 'label')} />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderFormik = p => {
    if (this.props.formikRef) {
      this.props.formikRef.current = p;
    }
    return (
      <form
        {...omit(
          this.props,
          'fields',
          'submit',
          'noAutoRender',
          'onValidSubmit',
          'onInvalidSubmit',
          'innerRef',
          'children',
          'formikRef',
        )}
        ref={this.props.innerRef}
        onSubmit={this.closureOnFormSubmit(p)}
      >
        {this.renderFields()}
      </form>
    );
  };

  render() {
    return (
      <Formik
        enableReinitialize={false}
        initialValues={this.initialValues}
        isInitialValid={this.isInitialValid}
        validate={this.validate}
        children={this.renderFormik}
      />
    );
  }
}

export default Form;
