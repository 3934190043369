import customFetch from '../../../utils/customFetch';

export const getClientInfo = id => {
  return customFetch('/Company/CompanyInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getStaffAll = ({ keyword, numItemPerPage = 50 }) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numItemPerPage,
      companyId: 0,
    }),
  });
};

export const getCMs = ({ companyId, keyword, numItemPerPage = 1000, IsOnboarded = true }) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numItemPerPage,
      companyId,
      IsOnboarded,
    }),
  });
};

export const getStaffByClientId = (clientId = 0, numberStaff = 10000) => {
  return customFetch('/Staff/GetAll?companyId=' + clientId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      numItemPerPage: numberStaff,
    }),
  });
};

export const getCountryList = () => {
  return customFetch('/Company/Country_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getCityList = (id = 16) => {
  return customFetch('/Company/City_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ countryId: id }),
  });
};

export const getClientOfficer = (getAll = false) => {
  return customFetch('/Company/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ getAll }),
  });
};

export const getLookupConfig = () => {
  return customFetch('/Company/GetLookUpConfig', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ type: 'client' }),
  });
};

export const getStaff = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const getBussinesUnit = () => {
  return customFetch('/BusinessUnit/GetBusinessUnits', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const uploadFileTemplate = body => {
  return customFetch('/File/UploadFileTemp', {
    method: 'POST',
    body,
  });
};

export const saveCompany = clientParam => {
  return customFetch('/Company/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientParam),
  });
};

export const checkDuplicateCompany = (id, name) => {
  return customFetch('/Company/CheckDuplicateName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, name }),
  });
};

export const viewBookingHistory = (
  clientId,
  sortKeyBooking,
  sortDirectionBooking,
  currentPage = 1,
  numItemPerPage = 50,
) => {
  return customFetch(
    `/Company/GetBookingHistory?clientId=${clientId}&sortBy=${sortKeyBooking}&sortDirection=${sortDirectionBooking
      .replace('ending', '')
      .toUpperCase()}&currentPage=${currentPage}&numItemPerPage=${numItemPerPage}`,
    {
      method: 'GET',
    },
  );
};

//
export const getAllSpecialist = body => {
  return customFetch('/MedicalDoctor/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const getRegisteredSpecialistDetails = (cId, arrId) => {
  return customFetch('/MedicalDoctor/GetDoctorByIdList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ arrId, clientId: cId }),
  });
};
export const getRegisteredSpecialists = cId => {
  return customFetch('/Company/GetCompanyDoctorIds', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ cId }),
  });
};

export const registeredSpecialists = (cId, doctors) => {
  return customFetch('/Company/UpdateCompanyDoctor', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ cId, doctors }),
  });
};

//Case Correspondence
export const getConversationLog = itemId => {
  return customFetch('/Company/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ companyId: itemId }),
  });
};

export const saveConversationLog = body => {
  return customFetch('/Company/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const allowCMViewConversationLog = (id, type = 0) => {
  return customFetch('/Company/UpdateDoctorAndCMsView', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, type }),
  });
};

export const deleteConversationLog = id => {
  return customFetch('/Company/DeleteConversationLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getActionRequired = ItemId => {
  return customFetch('/Company/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ItemId }),
  });
};

export const sendOnboardedNoticeToCMs = dId => {
  return customFetch('/MedicalDoctor/SendOnboardedNoticeToCMs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId: dId }),
  });
};

export const getLookupConfigByType = (type) => {
  return customFetch('/Company/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ type: type }),
  });
};