import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';

import TableToolbar from '../../shared/tableToolbar';
import LoginHistoryReportStore from './LoginHistoryReportStore';
import { toJS } from 'mobx';

const { $ } = window;
const optionsFilter = [
  {
    text: `None`,
    value: `none`,
  },
  {
    text: `Today`,
    value: `today`,
  },
  {
    text: `This week`,
    value: `tWeek`,
  },
  {
    text: `Last week`,
    value: `lWeek`,
  },
  {
    text: `Last 7 days`,
    value: `l7days`,
  },
  {
    text: `This month`,
    value: `tMonth`,
  },
  {
    text: `Last month`,
    value: `lMonth`,
  },
  {
    text: `Last 30 days`,
    value: `l30days`,
  },
];

@observer
class LoginHistoryReportToolbar extends React.Component {
  state = {
    opt: `tMonth`,
  };
  renderOptions = () => {
    return optionsFilter.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  convertValueToLabel = value => {
    const label = optionsFilter
      .filter(i => i.value === value)
      .map(opt => ({
        text: opt.text,
        value: opt.value,
      }));
    return label[0].text;
  };

  onFilterChange = value => {
    switch (value) {
      case `today`:
        return {
          timeFrom: moment()
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };
      case `tWeek`:
        return {
          timeFrom: moment()
            .startOf('isoWeek')
            .toDate(),
          timeTo: moment()
            .endOf('isoWeek')
            .toDate(),
        };
      case `lWeek`:
        return {
          timeFrom: moment()
            .subtract(7, 'day')
            .startOf('isoWeek')
            .toDate(),
          timeTo: moment()
            .subtract(7, 'day')
            .endOf('isoWeek')
            .toDate(),
        };

      case `l7days`:
        return {
          timeFrom: moment()
            .subtract(7, 'day')
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };

      case `tMonth`:
        return {
          timeFrom: moment()
            .startOf('month')
            .toDate(),
          timeTo: moment()
            .endOf('month')
            .toDate(),
        };

      case `lMonth`:
        return {
          timeFrom: moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          timeTo: moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate(),
        };

      case `l30days`:
        return {
          timeFrom: moment()
            .subtract(30, 'day')
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };

      default:
        // QuickFilterValues.NONE
        return {
          timeFrom: ``,
          timeTo: ``,
        };
    }
  };

  handleChangeDropdown = (_, { name, value }) => {
    const { timeFrom, timeTo } = this.onFilterChange(value);
    const fromDate = timeFrom ? moment(timeFrom).format('DD/MM/YYYY') : timeFrom;
    const toDate = timeTo ? moment(timeTo).format(`DD/MM/YYYY`) : timeTo;
    if (name === `login`) {
      this.setState({ opt: value }, () => {
        LoginHistoryReportStore.refetchLoginHistory({
          fromTo: value === `none` ? `` : fromDate + ' - ' + toDate,
        });
      });
    } else if (name === `search`) {
      this.setState({ opt: value }, () => {
        LoginHistoryReportStore.refetchSmartSearch({
          fromTo: value === `none` ? `` : fromDate + ' - ' + toDate,
        });
      });
    }
  };

  handleItemClick = (_, { name }) => {
    LoginHistoryReportStore.setFieldsValue({ tabIsActive: name });
    if (name === `search`) {
      this.setState({ opt: `today` });
      LoginHistoryReportStore.refetchSmartSearch({
        fromTo:
          moment(
            moment()
              .startOf('day')
              .toDate(),
          ).format(`DD/MM/YYYY`) +
          ' - ' +
          moment(
            moment()
              .startOf('day')
              .toDate(),
          ).format(`DD/MM/YYYY`),
      });
    } else if (name === `login`) {
      this.setState({ opt: `tMonth` });
      LoginHistoryReportStore.refetchLoginHistory({
        fromTo:
          moment(
            moment()
              .startOf('month')
              .toDate(),
          ).format(`DD/MM/YYYY`) +
          ' - ' +
          moment(
            moment()
              .endOf('month')
              .toDate(),
          ).format(`DD/MM/YYYY`),
      });
    }
  };
  handleChangeFromToCaseManager = (_, { value }) => {
    LoginHistoryReportStore.setFieldsValue({ fromTo: value });
  };

  handleChangeFromToHistory = (_, { value }) => {
    LoginHistoryReportStore.refetchLoginHistory({ fromTo: value });
  };
  handleExportSearchResult = () => {
    const { keywordCM } = LoginHistoryReportStore;
    const { fromDate, toDate } = LoginHistoryReportStore.getDateRange();
    const fromDate_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const toDate_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    window.open(
      `/api/smartsearchhistory/export-search-result?${$.param({
        searchedFrom: fromDate_,
        searchedTo: toDate_,
        searcherName: keywordCM,
        orderBy: `SearchedAtUtc`,
        orderAsc: false,
      })}`,
      '_blank',
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    LoginHistoryReportStore.fetchSmartSearch(true);
  };
  handleChangeInput = (_, { value }) => {
    LoginHistoryReportStore.setFieldsValue({ keywordCM: value });
  };
  handleChangeLoginInput = (_, { value }) => {
    const { dataLogin } = LoginHistoryReportStore;
    if (value) {
      const count = toJS(dataLogin).filter(function filterUser(user) {
        if (value) {
          const username = user.username.toUpperCase();
          const fullName = user.fullName.toUpperCase();
          if (username.search(value.toUpperCase()) !== -1 || fullName.search(value.toUpperCase()) !== -1) {
            return user;
          }
          return null;
        } else {
          return user;
        }
      }).length;
      return LoginHistoryReportStore.setFieldsValue({
        keywordLogin: value,
        userCount: count,
      });
    } else {
      return LoginHistoryReportStore.setFieldsValue({
        keywordLogin: value,
        userCount: dataLogin.length,
      });
    }
  };
  render() {
    const { tabIsActive, dataSmartSearch, fromTo, keywordLogin } = LoginHistoryReportStore;
    const { opt } = this.state;

    return (
      <form className="login-history-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Container>
            <div style={{ width: 'calc(50% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === `online` })}
                style={{ width: `100%` }}
                onClick={this.handleItemClick}
                name="online"
              >
                Currently Logged-in Users
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(50% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === `login` })}
                style={{ width: `100%` }}
                onClick={this.handleItemClick}
                name="login"
              >
                Login History
              </TableToolbar.HighLightButton>
            </div>
            {/* <div style={{ width: `33%` }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === `search` })}
                style={{ width: `100%` }}
                onClick={this.handleItemClick}
                name="search"
              >
                IME Specialist Searches
              </TableToolbar.HighLightButton>
            </div> */}
          </TableToolbar.Container>
        </TableToolbar.Container>
        {tabIsActive === `login` && (
          <TableToolbar.Container style={{ marginTop: `5px` }}>
            <TableToolbar.Left as="div" style={{ width: `30%` }}>
              <TableToolbar.Input
                className="Toolbar-input-login"
                placeholder={'Search User'}
                onChange={this.handleChangeLoginInput}
                value={keywordLogin}
              />
              <TableToolbar.RangePicker
                placeholder="From Date - To Date"
                className="Toolbar-range-picker"
                onChange={this.handleChangeFromToHistory}
                value={fromTo}
              />
              <TableToolbar.Filter
                value={this.convertValueToLabel(opt)}
                label={`Status`}
                options={this.renderOptions()}
                onChange={this.handleChangeDropdown}
                name="login"
              />
              <TableToolbar.Right />
            </TableToolbar.Left>
          </TableToolbar.Container>
        )}
        {tabIsActive === `search` && (
          <TableToolbar.Container style={{ marginTop: `5px` }}>
            <TableToolbar.Left as="div">
              <TableToolbar.Input
                className="Toolbar-input"
                placeholder={'Search User'}
                onChange={this.handleChangeInput}
              />
              <TableToolbar.RangePicker
                placeholder="From Date - To Date"
                className="Toolbar-range-picker"
                onChange={this.handleChangeFromToCaseManager}
                value={fromTo}
              />
              <TableToolbar.Filter
                value={this.convertValueToLabel(opt)}
                label={`Status`}
                options={this.renderOptions()}
                name="search"
                onChange={this.handleChangeDropdown}
              />
              <TableToolbar.SubmitButton onClick={this.handleSubmit} className="px-2">
                Search
              </TableToolbar.SubmitButton>
              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: `5px` }}
                onClick={this.handleExportSearchResult}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
        )}
        <TableToolbar.Container style={{ marginTop: `10px` }}>
          <TableToolbar.Left as="div">
            {tabIsActive === `search` && (
              <span style={{ marginLeft: `5px` }}>
                <b className="title-table">
                  Showing last {dataSmartSearch ? dataSmartSearch.length : 0} searches (date time displays in UTC+07:00)
                </b>
              </span>
            )}
            {tabIsActive === `login` && (
              <span style={{ marginLeft: `5px` }}>
                <b className="title-table">User login history</b>
              </span>
            )}
          </TableToolbar.Left>
        </TableToolbar.Container>
      </form>
    );
  }
}

export default LoginHistoryReportToolbar;
