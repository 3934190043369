import { observer } from 'mobx-react';
import React from 'react';
import { Button, Form, Icon, Input, Select, TextArea } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal';
import ServiceFeeStore from './ServiceFeeStore';

@observer
class ModalCreateEditServiceFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: ServiceFeeStore.modalParams.id,
      ItemCode: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.ItemCode : '',
      Name: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.Name : '',
      Description:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Description
          ? ServiceFeeStore.modalParams.record.Description
          : '',
      ApplicablePrice: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.ApplicablePrice : '',
      Tax: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.Tax : '',
      ServiceGroupId: ServiceFeeStore.modalParams.record
        ? ServiceFeeStore.modalParams.record.ServiceGroupId
        : ServiceFeeStore.dataServiceGroupId,
      ServiceGroupName: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.ServiceGroupName : '',
      canEdit: api.isAdmin(),
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    if (name === 'Tax') {
      return this.setState({
        ...this.state,
        [name]: value.replace(/\D/g, '').replace(/[^\d]/g, ''),
      });
    }
    return this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleCreateServiceItem = () => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MAGService/SaveServiceItem', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(this.state),
      }).then(respone => {
        if (respone.status === 'success') {
          this.props.onCancel();
        }
      });
      await ServiceFeeStore.fetchFiles({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  handleEditServiceItem = () => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MAGService/SaveServiceItem', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(this.state),
      }).then(respone => {
        if (respone.status === 'success') {
          this.props.onCancel();
        }
      });
      await ServiceFeeStore.fetchFiles({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  renderServiceGroupOptions = () => {
    return ServiceFeeStore.dataServiceGroup.map(opt => ({
      text: opt.Name,
      value: opt.Id,
    }));
  };
  handleFilterServiceGroup = (_, { value }) => {
    ServiceFeeStore.refetchGroup({ dataServiceGroupId: value }, true);
    this.setState({
      ServiceGroupId: ServiceFeeStore.dataServiceGroupId,
    });
  };
  onCancel = () => {
    this.props.onCancel();
    ServiceFeeStore.setFieldsValue({ dataServiceGroupId: `` });
  };
  render() {
    const { open, modalParams, dataServiceGroupId } = ServiceFeeStore;
    const { record } = modalParams;
    const { canEdit } = this.state;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              onClick={record ? this.handleEditServiceItem() : this.handleCreateServiceItem()}
              disabled={!canEdit}
            >
              <Icon name="save" /> Save
            </Button>}
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title="Service Item"
      >
        <Form onChange={this.onChange}>
          <Form.Field
            control={Input}
            label="Item Code"
            placeholder="Item Code"
            value={this.state.ItemCode}
            name="ItemCode"
          />
          <Form.Field control={Input} label="Item Name" placeholder="Item Name" value={this.state.Name} name="Name" />
          <Form.Field
            control={TextArea}
            label="Item Description"
            placeholder="Item Description"
            value={this.state.Description}
            name="Description"
          />
          <Form.Field
            control={Input}
            label="Service Rate (exclude GST - $)"
            placeholder="Service Rate"
            type="number"
            value={this.state.ApplicablePrice}
            name="ApplicablePrice"
          />
          <Form.Field
            control={Input}
            label="GST Rate (%)"
            placeholder="GST Rate"
            type="number"
            value={this.state.Tax}
            name="Tax"
          />
          <Form.Field
            control={Select}
            options={this.renderServiceGroupOptions()}
            label={{
              children: 'Service Group',
              htmlFor: 'form-select-control-gender',
            }}
            placeholder="Choose Service Group"
            search
            searchInput={{ id: 'form-select-control-gender' }}
            name="ServiceGroupId"
            value={record ? this.state.ServiceGroupId : dataServiceGroupId}
            onChange={this.handleFilterServiceGroup}
          />
        </Form>
      </Modal>
    );
  }
}

export default ModalCreateEditServiceFee;
