import React from 'react';
import { Form, Checkbox, Input, Select, notification, Spin } from 'antd';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import '../../AddEditCaseManager/ConversationModalForm.scss';
import Modal from '../../../shared/Modal';
import BusinessUnitStore from './store';
import { action, toJS } from 'mobx';
import { saveBusinessInfo } from './service';
import { debounce } from 'lodash';

@observer
class ModalAddEdit extends React.Component {
  componentDidMount() {
    const { id } = BusinessUnitStore.modalParams;
    const { companyId } = BusinessUnitStore;
    if (id === 0) {
      BusinessUnitStore.initBUInfo(companyId);
    } else {
      BusinessUnitStore.fetchBUInfo(id, companyId);
    }
  }
  componentWillUnmount() {
    BusinessUnitStore.initBUInfo(BusinessUnitStore.companyId);
  }

  handleSave = () => {
    const bodyParam = toJS(BusinessUnitStore.dataBUInfo);
    const { companyId } = BusinessUnitStore;
    const cmBU = bodyParam.cmBU.map(i => {
      const exist = bodyParam.cmBUManager.find(cm => cm.id === i.id);
      if (exist) {
        return {
          ...exist,
          isManager: true,
        };
      } else return { ...i, isManager: false };
    });
    const cmBUManager = bodyParam.cmBUManager.map(i => ({
      id: i.id,
      fullName: i.fullName,
      isManager: true,
    }));
    const bodyParam_ = {
      ...bodyParam,
      cmBU,
      cmBUManager,
      CompanyId: companyId,
    };
    BusinessUnitStore.setFieldsValue({ loadingBUInfo: true });
    if (!bodyParam_.Title || !bodyParam_.Description) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 3,
      });
    } else {
      Promise.all([saveBusinessInfo(bodyParam_)])
        .then(
          action(([responeSave]) => {
            if (responeSave.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Data has been saved successfully.',
                duration: 3,
              });
              return this.handleCancel();
            }
          }),
        )
        .catch(() => {
          BusinessUnitStore.setFieldsValue({ loadingBUInfo: false });
        });
    }
  };

  handleCancel = () => {
    BusinessUnitStore.refetch({});
    return BusinessUnitStore.toggleModal(false, {})();
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  render() {
    const { loadingBUInfo, dataBUInfo, open, dataStaff, dataBUManager, dataExternalCM } = BusinessUnitStore;
    const data = toJS(dataBUInfo);
    const { record, id } = BusinessUnitStore.modalParams;

    return (
      <Modal
        width={600}
        onCancel={this.handleCancel}
        visible={open}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button color="red" onClick={this.handleCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={`${record?.Title || 'Add New Business Unit'}`}
      >
        {loadingBUInfo ? (
          <Spin size="large" />
        ) : (
          <Form name="add-edit-bu" className="conversation-form">
            <div className="checkbox-field">
              <Checkbox checked={data.IsActive} onChange={BusinessUnitStore.handleCheckedBUInfo('IsActive')}>
                Active
              </Checkbox>
            </div>
            <Form.Item
              label="Business Unit Name"
              validateStatus={!data.Title ? 'error' : null}
              help={!data.Title && 'This field is required'}
              required
            >
              <Input value={data.Title} onChange={BusinessUnitStore.handleFieldChangeBUINfo('Title')} />
            </Form.Item>
            <Form.Item
              validateStatus={!data.Description ? 'error' : null}
              help={!data.Description && 'This field is required'}
              label="Description"
              required
            >
              <Input.TextArea
                value={data.Description}
                onChange={BusinessUnitStore.handleFieldChangeBUINfo('Description')}
              />
            </Form.Item>

            <Form.Item label="BU Members">
              <Select
                size="large"
                allowClear
                value={data.cmBU.map(i => ({
                  key: i.id,
                  label: i.fullName,
                }))}
                onChange={BusinessUnitStore.handleFieldChangeBUINfo('cmBU')}
                mode="multiple"
                showSearch
                showArrow
                labelInValue
                optionFilterProp="children"
              >
                {dataStaff &&
                  this.checkIsJSON(dataStaff) &&
                  JSON.parse(dataStaff).itemList.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.id} - {i.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <React.Fragment>
                  <span style={{ marginRight: '3px' }}>BU Managers</span>
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="BU managers can view/update bookings of case managers of the same BU"
                    position="top center"
                    wide="very"
                  />
                </React.Fragment>
              }
            >
              <Select
                size="large"
                allowClear
                value={data.cmBUManager.map(i => ({
                  key: i.id,
                  label: i.fullName,
                }))}
                labelInValue
                onChange={BusinessUnitStore.handleFieldChangeBUINfo('cmBUManager')}
                mode="multiple"
                showSearch
                showArrow
                optionFilterProp="children"
              >
                {dataStaff &&
                  this.checkIsJSON(dataStaff) &&
                  JSON.parse(dataStaff).itemList.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.id} - {i.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <React.Fragment>
                  <span style={{ marginRight: '3px' }}>External BU Managers</span>
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Managers from CMs of other clients"
                    position="top center"
                    wide="very"
                  />
                </React.Fragment>
              }
            >
              <Select
                size="large"
                allowClear
                value={data.cmBUExternal?.map(i => ({
                  key: i.id,
                  label: i.fullName,
                }))}
                onChange={BusinessUnitStore.handleFieldChangeBUINfo('cmBUExternal')}
                search
                onSearch={debounce(BusinessUnitStore.handleChangeSearchDropdown, 500)}
                searchQuery={BusinessUnitStore.clientSearch}
                mode="multiple"
                showSearch
                showArrow
                labelInValue
                optionFilterProp="children"
              >
                {dataExternalCM &&
                  dataExternalCM.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Id} - {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ModalAddEdit;
