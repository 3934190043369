import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import AddEditStore from './Store';
import Modal from '../../shared/Modal';

import CKEditor from './CKEditor';
import { toJS } from 'mobx';

@observer
class ModalWarning extends React.Component {
  state = {
    title: AddEditStore.modalParams?.title,
    content: AddEditStore.modalParams?.message,
  };
  render() {
    const { open } = AddEditStore;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={AddEditStore.toggleModal(false)}
        title={this.state.title}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={AddEditStore.toggleModal(false)}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="modal-confirm-message">{this.state.content}</div>
      </Modal>
    );
  }
}

export default ModalWarning;
