import React from 'react';
import { Form, DatePicker, Select, Divider, Input, Checkbox } from 'antd';

class ClaimDetailsForm extends React.Component {
  render() {
    return (
      <Form name="action-required">
        <Form.Item label="Request Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              PIC 1<button className="field-actions">Recent Changes</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Booking Client</h3>
        <Form.Item
          required
          label={
            <React.Fragment>
              Booking Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          label={
            <React.Fragment>
              Booking Case Manager / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Report Delivery Contact 1">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Report Delivery Contact 2">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Sub-Booking Case Manager / Solicitor">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying Case Manager / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you originally hear about us?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you hear about the doctor for today booking?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Claimant Details</h3>
        <Form.Item
          required
          label={
            <React.Fragment>
              Claimant
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Claimant Title">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Claimant Name">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Date of Birth">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Mobile phone">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Reference/Claim No" required>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Client Request No">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Type of Claim" required>
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Type of Report" required>
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Date of Injury">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Injury Description" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Employer Of Claimant">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Expected Completion Date" required>
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Report Funded by IRO/ILARS</Checkbox>
        </div>
        <Form.Item label="ILARS Ref">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Billing Item Code">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="IRO Principal Lawyer">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default ClaimDetailsForm;
