import { action, computed, observable, toJS } from 'mobx';

import customFetch from '../../../utils/customFetch';

class ManageSpeAccStore {
  @observable loading = true;
  @observable data = [];

  @observable type = 'spe';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Name';
  @observable sortKey = 'Label';
  @observable totalItem = 0;

  @observable status = 'All';

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable openModalAddEdit = false;
  @observable openModalList = false;
  @observable editData = null;
  @observable specId = null;

  @action toggleModalAddEdit = (isOpen, data = null) => {
    this.openModalAddEdit = isOpen;
    this.editData = data;
  };

  @action toggleModalList = (isOpen, data = null) => {
    this.openModalList = isOpen;
    this.specId = data;
  };

  @action fetchLookupConfig = (setTotalPage = true) => {
    this.loading = true;

    let FilterLabel = 'Specialty';
    switch (this.type) {
      case 'qua':
        FilterLabel = 'Qualification';
        break;
      case 'acc':
        FilterLabel = 'Accreditation';
        break;
      default:
        break;
    }

    customFetch('/MedicalDoctor/LookUpConfig_LoadList', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keyword,
        curPage: this.curPage,
        FilterLabel: FilterLabel,
        FilterButtonLabel: FilterLabel,
        type: this.type,
        numItemPerPage: this.pageSize,
        filterType: this.status,
      }),
    }).then(
      action(res => {
        if (res.status === 'success') {
          this.curPage = res.curPage;
          if (res.curPage <= 1) {
            this.totalPage = res.sumPage;
            this.totalItem = res.sumItem;
          }
          this.data = res.itemList;
        }
        this.loading = false;
      }),
    );
  };

  saveLookupConfig = data => {
    return customFetch('/MedicalDoctor/SaveLookUpConfig', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data),
    });
  };

  changeStatusLookUpConfig = (id, isActive) => {
    return customFetch('/MedicalDoctor/LookUpConfig_ChangeStatus', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        id,
        isActive: !isActive,
      }),
    });
  };

  deleteLookupConfig = lucId => {
    return customFetch('/MedicalDoctor/RemoveLookUpConfig', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ lucId }),
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchLookupConfig();
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @computed get typeName() {
    const types = [
      { text: 'Specialty', value: 'spe' },
      { text: 'Qualification', value: 'qua' },
      { text: 'Accreditation', value: 'acc' },
    ];
    return types.find(t => t.value === this.type).text;
  }

  @computed get selectedSpecialty() {
    return toJS(this.data.find(s => s.Id === this.specId));
  }

  @action handleResetStore = (setTotalPage = true) => {
    this.type = 'spe';
    this.sortDirection = 'ascending';
    this.keyword = '';
    this.sortColumn = 'Name';
    this.sortKey = 'Label';

    this.fetchLookupConfig(setTotalPage);
  };
}

export default new ManageSpeAccStore();
