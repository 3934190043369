import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import JobTitleModal from './JobTitleModal';
import JobTitleStore from './JobTitleStore';
import JobTitleTable from './JobTitleTable';
import JobTitleToolbar from './JobTitleToolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class JobTitle extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/job-title-2', true);
      return;
    }

    JobTitleStore.fetchJob(1);
    ui.on('open(/view/job-title-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    JobTitleStore.fetchJob(1);
  }, 500);

  render() {
    const { loading, data, open } = JobTitleStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <JobTitleToolbar />
            <JobTitleTable />
            {open && <JobTitleModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(JobTitle);
