import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { uploadFileBase } from '../../utils/uploadFileBase';
import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import { Dimmer, Loader, Icon } from 'semantic-ui-react';
import './DragDrop.scss';
import { Link } from 'react-router-dom';
import { GetFileExtention } from '@utils/functions';
import { AllowedFileExtensions } from '@utils/constants';

function DragDropFile(props) {
  const [uploadFile, setUploadFile] = useState({ status: '', fileList: [] });
  const [loading, setLoading] = useState(false);
  //list MIME - https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
  const acceptFile = AllowedFileExtensions;

  useEffect(() => {
    const fetchFileList = () => {
      if (props.fileList) {
        const data = { ...uploadFile, fileList: props.fileList };
        // console.log(data)
        setUploadFile(data);
        return;
      }
    };
    fetchFileList();
    // eslint-disable-next-line
  }, [props.fileList]);

  const onDrop = async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLoading(true);
      var res = await uploadFileBase(acceptedFiles, props.maxSize);
      if (res != null) {
        let data = { ...uploadFile, fileList: uploadFile.fileList.concat(res.fileList) };
        if (!props.multiple) {
          data = { fileList: res.fileList };
        }
        setUploadFile(data);
        props.onUploadFile(data.fileList, props.type, props.multiple);
        setLoading(false);
      }
    }
  };
  const removeAtt = file => {
    if (uploadFile && uploadFile.fileList && uploadFile.fileList.length > 0) {
      const data = { ...uploadFile, fileList: uploadFile.fileList.filter(x => x !== file) };
      setUploadFile(data);
      props.onDeleteFile(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: props.accept ? props.accept : acceptFile,
    maxSize: props.maxSize * 1021000,
  });

  const files = () => {
    if (uploadFile && uploadFile.fileList && uploadFile.fileList.length > 0) {
      return uploadFile.fileList.map(file => {
        return (
          <li className="list-file" key={file.name || file.FileName}>
            {file.title || file.Title}
            {GetFileExtention(file.name || file.FileName)}{' '}
            <Link to="#" onClick={() => removeAtt(file, uploadFile)} style={{ color: `red` }}>
              Delete
            </Link>
          </li>
        );
      });
    }
    return;
  };

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 10px',
        fontSize: '15px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#1db4cd',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        fontWeight: 'bold',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
      },
      ...(isDragActive ? { borderColor: '#2196f3', backgroundColor: 'rgb(194, 243, 194)' } : {}),
      ...(isDragAccept ? { borderColor: '#00e676' } : {}),
      ...(isDragReject ? { backgroundColor: 'rgb(255 192 192)' } : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const getProps = useCallback(() => {
    if (props.disableDragDrop)
      return { ...getRootProps({ style }), onDragEnter: null, onDragOver: null, onDragLeave: null, onDrop: null };
    return getRootProps({ style });
  }, [props.disableDragDrop, style, getRootProps]);

  const textboxTitle = useMemo(
    () => (props.disableDragDrop ? 'Click to browse' : 'Drag-n-drop here or Click to browse'),
    [props.disableDragDrop],
  );

  return (
    <section>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      {!(!!uploadFile.length && !props.multiple) && !props.disabled && (
        <div {...getProps()}>
          <input {...getInputProps()} multiple={props.multiple} disabled={props.disabled} />
          <Icon name="upload" className="upload-icon"></Icon>
          <span style={{ color: '#1db4cd' }}>{textboxTitle}</span>
          <br />
          <span>
            {props.title} {props.maxSize > 0 && <span>(Max {props.maxSize}Mb)</span>}
          </span>
        </div>
      )}
      <aside>
        <ul className="list-file-ul">{files()}</ul>
      </aside>
    </section>
  );
}
DragDropFile.defaultProps = {
  disableDragDrop: false,
};

export default observer(DragDropFile);
