import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import moment from 'moment';
import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import InvoicesStore from './InvoicesStore';
import ModalNotification from './ModalNotification';

@observer
class InvoicesToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ``,
    };
  }
  componentDidMount() {
    InvoicesStore.fetchInvoicesStaff(true);
  }

  handleFromRequestDate = (_, { value }) => {
    if (value) {
      InvoicesStore.setFieldsValue({
        fromRequestDate: moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z',
        requestDateTimeFrom: new Date().toISOString(),
      });
    } else {
      InvoicesStore.setFieldsValue({
        fromRequestDate: null,
        requestDateTimeFrom: new Date().toISOString(),
      });
    }
  };

  convertLabelStatus = (item, type) => {
    if (type === `paymentStatus`) {
      switch (item) {
        case `peCount`:
          return `Pending`;
        case `uCount`:
          return `Unpaid`;
        case `fCount`:
          return `Partially Paid`;
        case `paCount`:
          return `Fully Paid`;
        case `creditCount`:
          return `Credited`;
        default:
          return `All`;
      }
    }
    if (type === `invoiceStatus`) {
      switch (item) {
        case `cancelled`:
          return `Cancelled`;
        case `created`:
          return `Created`;
        case `sent`:
          return `Sent`;
        default:
          return `All`;
      }
    }
    if (type === `xero`) {
      switch (item) {
        case `billed`:
          return `Not Uploaded`;
        case `billedClient`:
          return `Not Uploaded - Client Invoices`;
        case `billedDoctor`:
          return `Not Uploaded - Specialist Bill`;
        case `uploadedForInvoiceOnly`:
          return `Uploaded - Client Invoice`;
        case `uploadedForBillingOnly`:
          return `Uploaded - Specialist Bill`;
        case `xero`:
          return `Uploaded - Case Invoices`;
        default:
          return `All`;
      }
    }
    if (type === `serviceType`) {
      switch (item) {
        case `aCount`:
          return `IMEs`;
        case `cnInvoiceCount`:
          return `Clinical Records`;
        case `fCount`:
          return `File Reviews`;
        case `fiInvoiceCount`:
          return `Factual Investigation`;
        case `mCount`:
          return `MRI`;
        case `sCount`:
          return `Supplementary Reports`;
        case `mnCount`:
          return `Med Neg`;
        default:
          return `All`;
      }
    }
    return `All`;
  };

  convertValueFilter = (item, type) => {
    if (type === `paymentStatus`) {
      switch (item) {
        case `peCount`:
          return `Pending`;
        case `uCount`:
          return `Unpaid`;
        case `fCount`:
          return `PartiallyPaid`;
        case `paCount`:
          return `FullyPaid`;
        case `creditCount`:
          return `Credited`;
        default:
          return `Unknown`;
      }
    }
    if (type === `invoiceStatus`) {
      switch (item) {
        case `cancelled`:
          return `Cancelled`;
        case `created`:
          return `Created`;
        case `sent`:
          return `Sent`;
        default:
          return `All`;
      }
    }
    if (type === `xero`) {
      switch (item) {
        case `billed`:
          return `Not Uploaded`;
        case `billedClient`:
          return `Not Uploaded-Client`;
        case `billedDoctor`:
          return `Not Uploaded-Doctor`;
        case `uploadedForInvoiceOnly`:
          return `Uploaded-Client`;
        case `uploadedForBillingOnly`:
          return `Uploaded-Doctor`;
        case `xero`:
          return `Uploaded-Client/Doctor`;
        default:
          return `All`;
      }
    }
    if (type === `serviceType`) {
      switch (item) {
        case `aCount`:
          return `Assessment`;
        case `cnInvoiceCount`:
          return `ClinicalNote`;
        case `fCount`:
          return `FileReview`;
        case `fiInvoiceCount`:
          return `FactualInvestigation`;
        case `mCount`:
          return `MRI`;
        case `sCount`:
          return `Supplementary`;
        case `mnCount`:
          return `MedNeg`;
        default:
          return `All`;
      }
    }
    return `All`;
  };

  convertFilterToLabel = (keyword, name) => {
    if (name === `paymentFilterType`) {
      switch (keyword) {
        case `Pending`:
          return `Pending`;
        case `Unpaid`:
          return `Unpaid`;
        case `PartiallyPaid`:
          return `Partially Paid`;
        case `FullyPaid`:
          return `Fully Paid`;
        case `Credited`:
          return `Credited`;
        default:
          return `All`;
      }
    }
    if (name === `filterInvoiceStatus`) {
      switch (keyword) {
        case `Cancelled`:
          return `Cancelled`;
        case `Created`:
          return `Created`;
        case `Sent`:
          return `Sent`;
        default:
          return `All`;
      }
    }
    if (name === `filterStatus`) {
      switch (keyword) {
        case `Not Uploaded`:
          return `Not Uploaded`;
        case `Not Uploaded-Client`:
          return `Not Uploaded - Client Invoice`;
        case `Not Uploaded-Doctor`:
          return `Not Uploaded - Specialist Bill`;
        case `Uploaded-Client`:
          return `Uploaded - Client Invoice`;
        case `Uploaded-Doctor`:
          return `Uploaded - Specialist Bill`;
        case `Uploaded-Client/Doctor`:
          return `Uploaded-Client/Specialist`;
        default:
          return `All`;
      }
    }
    if (name === `filterType`) {
      switch (keyword) {
        case `Assessment`:
          return `IMEs`;
        case `ClinicalNote`:
          return `Clinical Note`;
        case `FileReview`:
          return `File Reviews`;
        case `FactualInvestigation`:
          return `Factual Investigation`;
        case `MRI`:
          return `MRI`;
        case `Supplementary`:
          return `Supplementary Reports`;
        case `MedNeg`:
          return `Med Neg`;
        default:
          return `All`;
      }
    }
    return keyword;
  };

  renderOptionFilter = (filterType, type) => {
    let option = [
      {
        key: `All`,
        value: ``,
      },
    ];

    const notInXero = [`cancelled`, `credited`];
    for (let i in InvoicesStore[filterType]) {
      if (i !== `status`) {
        option.push({
          key: i,
          value: InvoicesStore[filterType][i],
        });
      }
    }
    if (type === `xero`) {
      option = option.filter(i => notInXero.indexOf(i.key) === -1);
    }
    return option.map((opt, index) => ({
      text: `${this.convertLabelStatus(opt.key, type)} ${`${
        opt.key !== `All` ? (opt.value ? `(${opt.value})` : `(0)`) : ``
      }`}`,
      value: JSON.stringify({
        id: index,
        value: this.convertValueFilter(opt.key, type),
      }),
    }));
  };

  handleToRequestDate = (_, { value }) => {
    if (value) {
      InvoicesStore.setFieldsValue({
        toRequestDate: moment(value).format('YYYY-MM-DD'),
        requestDateTimeFrom: new Date().toISOString(),
      });
    } else {
      InvoicesStore.setFieldsValue({
        toRequestDate: null,
        requestDateTimeFrom: new Date().toISOString(),
      });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    InvoicesStore.refetch({ curPage: 1 }, true);
  };

  handleReset = () => {
    InvoicesStore.resetStore();
    InvoicesStore.refetch({}, true);
  };

  handleRefresh = () => {
    InvoicesStore.refetch({}, true);
  };

  handleChangeFromTo = (_, { value }) => {
    InvoicesStore.setFieldsValue({ fromTo: value });
  };

  onActionRequired = () => {
    const { actionRequired, keyword } = InvoicesStore;
    if (!keyword) {
      InvoicesStore.setFieldsValue({
        actionRequired: !actionRequired,
        keyword: ``,
        actionRequiredById: ``,
      });
    } else {
      InvoicesStore.setFieldsValue({
        actionRequired: !actionRequired,
        keyword: ``,
        actionRequiredById: ``,
      });
    }
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(InvoicesStore.dataStaff.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', FullName: `All` });
    return array.map(opt => ({
      text: `${opt.FullName} ${opt.Count ? '(' + opt.Count + ')' : ''}`,
      value: opt.Id,
    }));
  };

  renderActionText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(InvoicesStore.dataStaff.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: 'All', FullName: `All` });
      let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName}`);
      return labels[0];
    }
  };

  handleFilterAction = (_, { value }) => {
    if (value === `All`) {
      InvoicesStore.setFieldsValue({ actionRequiredById: ``, curPage: 1 });
    } else {
      InvoicesStore.setFieldsValue({ actionRequiredById: value, curPage: 1 });
    }
  };

  handleExportSearchResult = () => {
    const { fromDate, toDate } = InvoicesStore.getDateRange();
    const time = moment().format('HH:mm:ss');
    const from = moment(moment(fromDate).format(`YYYY-MM-DD`) + time, 'YYYY-MM-DD hh:mm:ss').toISOString();
    const to = moment(moment(toDate).format(`YYYY-MM-DD`) + time, 'YYYY-MM-DD hh:mm:ss').toISOString();
    const searchParams = Object.assign(
      {},
      {
        FilterInvoiceStatus: InvoicesStore.filterInvoiceStatus,
        FilterStatus: InvoicesStore.filterStatus,
        FilterType: InvoicesStore.filterType,
        FilterTypeText: InvoicesStore.filterTypeText,
        PaymentFilterType: InvoicesStore.paymentFilterType,
        CurPage: InvoicesStore.curPage,
        Keyword: InvoicesStore.keyword,
        NumItemPerPage: InvoicesStore.pageSize,
        SortBy: InvoicesStore.sortKey,
        From: from,
        To: to,
        SortDirection: InvoicesStore.sortDirection.replace('ending', '').toUpperCase(),
      },
      {
        GetAllRecords: true,
      },
    );
    if (
      !fromDate &&
      !toDate &&
      !InvoicesStore.keyword &&
      searchParams.FilterInvoiceStatus === 'All' &&
      searchParams.PaymentFilterType === 'Unknown' &&
      searchParams.FilterStatus === 'All' &&
      searchParams.FilterType === 'All' &&
      searchParams.FilterTypeText === 'All'
    ) {
      this.setState({
        openModalNotification: true,
        message: `Please enter a date range within a month or any other search conditions`,
      });
    } else {
      // let fromDate = moment(fromDate);
      // let toDate = moment(toDate);
      const date1 = moment(moment(fromDate).format(`YYYY-MM-DD`) + time, 'YYYY-MM-DD hh:mm:ss');
      const date2 = moment(moment(toDate).format(`YYYY-MM-DD`) + time, 'YYYY-MM-DD hh:mm:ss');
      let diffDays = date2.diff(date1, 'days');
      let diffMonths = date2.diff(date1, 'months');
      if (diffDays > 90 || diffMonths > 3) {
        this.setState({
          openModalNotification: true,
          message: `Please enter a date range within 90 days or 3 months or any other search conditions`,
        });
      } else {
        var qs = Object.entries(searchParams)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
        window.open('/Invoice/ExportSearchResult?' + qs, '_blank');
      }
    }
  };

  handleExportWiroInvoice = () => {
    const { fromDate, toDate } = InvoicesStore.getDateRange();
    // const time = moment().format('HH:mm:ss');
    const from = moment(moment(fromDate).format(`YYYY-MM-DD`), 'YYYY-MM-DD').toISOString();
    const to = moment(moment(toDate).format(`YYYY-MM-DD`), 'YYYY-MM-DD').toISOString();
    const searchParams = Object.assign(
      {},
      {
        FilterInvoiceStatus: InvoicesStore.filterInvoiceStatus,
        FilterStatus: InvoicesStore.filterStatus,
        FilterType: InvoicesStore.filterType,
        FilterTypeText: InvoicesStore.filterTypeText,
        PaymentFilterType: InvoicesStore.paymentFilterType,
        CurPage: InvoicesStore.curPage,
        Keyword: InvoicesStore.keyword,
        From: from,
        To: to,
        NumItemPerPage: InvoicesStore.pageSize,
        SortBy: InvoicesStore.sortKey,
        SortDirection: InvoicesStore.sortDirection.replace('ending', '').toUpperCase(),
      },
      {
        GetAllRecords: true,
      },
    );
    if (
      !fromDate &&
      !toDate &&
      !InvoicesStore.keyword &&
      searchParams.FilterInvoiceStatus === 'All' &&
      searchParams.PaymentFilterType === 'Unknown' &&
      searchParams.FilterStatus === 'All' &&
      searchParams.FilterType === 'All' &&
      searchParams.FilterTypeText === 'All'
    ) {
      this.setState({
        openModalNotification: true,
        message: `Please enter a date range within a month or any other search conditions`,
      });
    } else {
      let fromMonth = moment(fromDate).month();
      let toMonth = moment(toDate).month();

      if (fromMonth != toMonth) {
        this.setState({
          openModalNotification: true,
          message: `Please enter a date range within a month or any other search conditions`,
        });
      } else {
        var qs = Object.entries(searchParams)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
        window.open('/Invoice/ExportWIROInvoices?' + qs, '_blank');
      }
    }
  };

  handleExportIcareReport = () => {
    const timeZone = moment.tz.guess();
    const { fromDate, toDate } = InvoicesStore.getDateRange();
    // const time = moment().format('HH:mm:ss');
    const from = moment(moment(fromDate).format(`YYYY-MM-DD`), 'YYYY-MM-DD').toISOString();
    const to = moment(moment(toDate).format(`YYYY-MM-DD`), 'YYYY-MM-DD').toISOString();
    const searchParams = Object.assign(
      {},
      {
        FilterInvoiceStatus: InvoicesStore.filterInvoiceStatus,
        FilterStatus: InvoicesStore.filterStatus,
        FilterType: InvoicesStore.filterType,
        FilterTypeText: InvoicesStore.filterTypeText,
        PaymentFilterType: InvoicesStore.paymentFilterType,
        CurPage: InvoicesStore.curPage,
        Keyword: InvoicesStore.keyword,
        From: from,
        To: to,
        NumItemPerPage: InvoicesStore.pageSize,
        SortBy: InvoicesStore.sortKey,
        SortDirection: InvoicesStore.sortDirection.replace('ending', '').toUpperCase(),
        TimeZone: timeZone,
      },
      {
        GetAllRecords: true,
      },
    );
    if (
      !fromDate &&
      !toDate &&
      !InvoicesStore.keyword &&
      searchParams.FilterInvoiceStatus === 'All' &&
      searchParams.PaymentFilterType === 'Unknown' &&
      searchParams.FilterStatus === 'All' &&
      searchParams.FilterType === 'All' &&
      searchParams.FilterTypeText === 'All'
    ) {
      this.setState({
        openModalNotification: true,
        message: `Please enter a date range within a month or any other search conditions`,
      });
    } else {
      let fromMonth = moment(fromDate).month();
      let toMonth = moment(toDate).month();

      if (fromMonth != toMonth) {
        this.setState({
          openModalNotification: true,
          message: `Please enter a date range within a month or any other search conditions`,
        });
      } else {
        var qs = Object.entries(searchParams)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
        window.open('/Invoice/ExportIcareReport?' + qs, '_blank');
      }
    }
  };

  openModal = options => {
    return InvoicesStore.toggleModal(true, options);
  };

  checkIsJSON = json => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterChange = (_, { name, value }) => {
    const value_ = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (InvoicesStore[name] === value_.value) {
      return;
    }
    if (value_.value === `All`) {
      InvoicesStore.setFieldsValue({
        [name]: `All`,
      });
    } else if (value_.value === `Unknown`) {
      InvoicesStore.setFieldsValue({
        [name]: `Unknown`,
      });
    } else {
      InvoicesStore.setFieldsValue({
        [name]: value_.value,
      });
    }
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalNotification
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const {
      loading,
      keyword,
      fromTo,
      filterInvoiceStatus,
      paymentFilterType,
      filterStatus,
      filterType,
      actionRequiredById,
      Total,
      StrTotal,
    } = InvoicesStore;
    return (
      <React.Fragment>
        {this.renderModal()}
        {/* <TableToolbar.Container>
          <TableToolbar.Left>
            <div></div>
          </TableToolbar.Left>
        </TableToolbar.Container> */}
        <TableToolbar.Container style={{ marginTop: '5px' }}>
          <TableToolbar.Left>
            <TableToolbar.Input
              placeholder={'Search invoices'}
              disabled={loading}
              value={keyword}
              onChange={InvoicesStore.handleSearchChange}
            />
            <TableToolbar.RangePicker
              value={fromTo}
              placeholder={'From Invoice Date - To Invoice Date'}
              onChange={this.handleChangeFromTo}
              className="Toolbar-range-picker"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.handleSubmit}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.handleReset}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              onClick={this.handleRefresh}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ marginTop: '5px' }}>
          <TableToolbar.Left>
            <TableToolbar.Filter
              label={`Invoices Status`}
              disabled={loading}
              name={`filterInvoiceStatus`}
              value={this.convertFilterToLabel(filterInvoiceStatus, `filterInvoiceStatus`)}
              options={this.renderOptionFilter(`dataXeroStatus`, `invoiceStatus`)}
              onChange={this.handleFilterChange}
            />
            <TableToolbar.Filter
              disabled={loading}
              label={`Xero Status`}
              name={'filterStatus'}
              value={this.convertFilterToLabel(filterStatus, `filterStatus`)}
              options={this.renderOptionFilter(`dataInvoiceStatus`, `xero`)}
              onChange={this.handleFilterChange}
            />
            <TableToolbar.Filter
              disabled={loading}
              label={`Payment Status`}
              name={'paymentFilterType'}
              value={this.convertFilterToLabel(paymentFilterType, `paymentFilterType`)}
              options={this.renderOptionFilter(`dataPaymentStatus`, `paymentStatus`)}
              onChange={this.handleFilterChange}
            />
            <TableToolbar.Filter
              disabled={loading}
              label={`Service Type`}
              name={'filterType'}
              value={this.convertFilterToLabel(filterType, `filterType`)}
              options={this.renderOptionFilter(`dataServiceType`, `serviceType`)}
              onChange={this.handleFilterChange}
            />
            <TableToolbar.Filter
              disabled={loading}
              label={`Action Required`}
              value={this.renderActionText(actionRequiredById)}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
            />
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ marginTop: '5px' }}>
          <TableToolbar.Left>
            {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.handleExportSearchResult}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            )}
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="positive"
              disabled={loading}
              onClick={this.handleExportWiroInvoice}
            >
              Export IRO Invoices
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="positive"
              disabled={loading}
              onClick={this.handleExportIcareReport}
            >
              Export Icare Invoices
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="blue"
              disabled={loading}
              onClick={this.openModal({
                modalType: 'xero',
              })}
            >
              Sync Xero Payments
            </TableToolbar.HighLightButton>
            <div style={{ marginLeft: '10px' }}>
              {' '}
              {Total !== undefined && `Total Amount (incl GST) ${Total !== undefined && StrTotal}`}
            </div>
          </TableToolbar.Left>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default InvoicesToolbar;
