import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input } from 'semantic-ui-react';

import Modal from '../../shared/Modal';
import customFetch from '../../../utils/customFetch';
import Store from './CMReportStore';
import { notification } from 'antd';
import { action } from 'mobx';

@observer
class ModalVerifyAccessCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accessCode: '',
    };
  }

  handleVerifyAccessCode = () => async () => {
    var url = Store.exportUrl;
    var queryParams = Store.exportQueryParams;
    const checkAccessCode = customFetch('/ExportImport/VerifyAccessCode?accessCode=' + this.state.accessCode, {
      method: 'GET',
    });
    const [isAccess] = await Promise.all([checkAccessCode]);
    if (isAccess) {
      let isExport = isAccess.result;
      if (isExport) {
        window.open(url + '?' + queryParams + '&forDownloading=true', '_blank');
        this.props.onCancel();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Incorrect access code.',
          duration: 3,
        });
      }
    }
  };

  handleChangeFieldValue = (_, { name, value }) => {
    this.setState(state => (state.accessCode = value));
  };

  handleKeyDown = () =>
    action(event => {
      if (event.key === 'Enter') {
        this.handleVerifyAccessCode();
      }
    });

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { open } = Store;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={500}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleVerifyAccessCode()}>
                OK
              </Button>
              <Button color="red" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
          title="Enter Access Code"
        >
          <div>
            <div style={{ marginBottom: 10 }}>
              <Form className="export-access-code">
                <Input
                  type="password"
                  autoComplete="off"
                  style={{ width: '100%' }}
                  name="accessCode"
                  value={this.state.accessCode}
                  onChange={this.handleChangeFieldValue}
                  className="Input-field"
                  onKeyDown={this.handleKeyDown}
                />
              </Form>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalVerifyAccessCode;
