import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import Modal from '../../shared/Modal';
import { Spin } from 'antd';

import FormRequestFromService from './FormRequestFromService';
import FormRequestNonService from './FormRequestNonService';
import store from './store';

@observer
class ModalDisbursementFunding extends React.Component {
  componentDidMount() {
    store.setFieldsValue({ claimantId: this.props.claimantId });
    store.setFieldsValue({ claimant: this.props.claimant });
    store.setFieldsValue({ caseNo: this.props.caseNo });
    store.setFieldsValue({ caseId: this.props.caseId });
    store.setFieldsValue({ fromService: this.props.fromService });

    store.fetchData({ clientId: this.props.clientId, isManual: this.props.isManual });
  }

  handleSend = () => {
    store.setFieldsValue({ isFromMainMenu: this.props.isFromMainMenu });
    if (this.props.isFromMainMenu) {
      store.sendRequestNonService(this.afterSave);
    } else {
      store.sendRequestWithService(this.afterSave);
    }
  };

  componentWillUnmount = () => {
    store.setFieldsValue({
      legalCompanyName: '',
      position: 159,
      contactName: '',
      email: '',
      phone: '',
      mobile: '',
      error: {},
      loading: false,
      data: {},
      DisbursementAttachment: [],
      positions: [],
      TypeOfDisbursementId: null,
      OtherDisbursementType: '',
      disbursementType: [],
      isFromMainMenu: false,
      caseId: '',
      caseNo: '',
      fromService: '',
      claimantId: '',
      AdditionalInfo: '',
      claimantName: '',
    });
  };

  afterSave = () => {
    this.props.functionCallbackCancel && this.props.functionCallbackCancel();
    this.props.onCancel();
  };

  handleUpload = (fileList = []) => {
    this.setState({ DisbursementAttachment: fileList });
  };

  handleClose = () => {
    this.props.functionCallbackCancel && this.props.functionCallbackCancel();
    this.props.onCancel();
  };
  handleCancel = () => this.props.onCancel();

  handleRemove = key => {
    const attachments = store.DisbursementAttachment.filter(i => i.key !== key);
    this.setState({ DisbursementAttachment: attachments });
  };

  render() {
    const { open } = this.props;
    return (
      <Modal
        visible={open}
        width={1200}
        title={`Request Disbursement Funding${this.props.caseNo ? ` - Case No: ${this.props.caseNo}` : ''}${
          this.props.claimant ? ` - Claimant: ${this.props.claimant}` : ''
        }`}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSend}>
              Submit Request
            </Button>
            <Button className="negative" onClick={this.handleClose}>
              Cancel
            </Button>
          </React.Fragment>
        }
      >
        {store.loading ? (
          <div className="page-loading">
            <Spin size="large" />
          </div>
        ) : (
          <>{this.props.isFromMainMenu ? <FormRequestNonService /> : <FormRequestFromService />}</>
        )}
      </Modal>
    );
  }
}

export default ModalDisbursementFunding;
