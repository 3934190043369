import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import DocAndConsStore from './DocAndConsStore';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';

const columns = [
  { title: 'Login Date' },
  { title: 'Logout Date' },
  { title: 'IP Address' },
  { title: 'Additional Info' },
];

@observer
class ModalCaseManagerHistory extends React.Component {
  componentDidMount() {
    DocAndConsStore.fetchHistory(true);
  }

  renderTableBody = () => {
    const array = DocAndConsStore.dataHistory.itemList;
    let newArray = array.map(i => ({
      ...i,
      LoginDate: moment(i.LoginDate).toISOString(),
    }));
    newArray.sort((d1, d2) => (d2.LoginDate > d1.LoginDate ? 1 : -1));
    return newArray.map(({ ...record }, id) => (
      <Table.Row key={id}>
        <Table.Cell>
          {record.LoginDate
            ? moment(formatDate(record.LoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.LogoutDate
            ? moment(formatDate(record.LogoutDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.IPAddress}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loadingHistory, dataHistory, toggleModal, open } = DocAndConsStore;
    const colSpan = columns.length;
    const { record } = this.props.modalParams;

    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={700}
          onCancel={toggleModal(false)}
          title={`Login History - ${record.FirstName} ${record.LastName}`}
        >
          {loadingHistory && !dataHistory ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columns} />
              <Table.Body>
                {dataHistory.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
              </Table.Body>
            </Table>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalCaseManagerHistory;
