import React from 'react';
import cx from 'classnames';
import { Divider, notification, Spin } from 'antd';
import { Button, Dimmer } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import moment from 'moment';

import '../AddEditClinic/index.scss';
import './index.scss';

import LoginInformation from './LoginInformation';
import ContactInformation from './ContactInformation';
import FreeOffers from './FreeOffers';
import CaseCorrespondence from './CaseCorrespondence';
import PaymentInfomation from './PaymentImformation';
import AddEditStore from './store';
import AddEditModal from './Modal';
import { saveCaseManager, registeredSpecialists } from './service';
import Chart from './Chart';
import { ERROR_FIELDNAME, ERROR_MESSAGES } from './type';

import router from '../../../stores/router';
import dashboard from '../../../stores/dashboard';
import { formatDate } from '../../../utils/functions';
import ActionRequired from './ActionRequired';
import ActionRequiredModal from './ActionRequiredModal';
import BookingHistory from './BookingHistory';
import FavouriteSpecialists from './FavouriteSpecialists';
import * as api from '@stores/api';

const STEPS = [
  'General Information',
  'Login Information',
  'Payment Information',
  'Free Offers',
  'Case Correspondence',
  'Booking History',
  'Favourite Specialists',
  'Activity Dashboard',
  'Action Required',
];

const STEPS2 = [
  'General Information',
];
@observer
class AddEditCaseManager extends React.Component {
  state = {
    currentStep: 0,
    action: 'view',
    isRerender: false,
    pageGetter: null,
    fieldName: null,
    followUpNeeded: false,
  };

  @action componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboard.close('/view/add-edit-case-manager-2', true);
      return;
    }

    const { id, action, step = 0, pageGetter, fieldName, followUpNeeded } = this.getRouteParams();
    if (pageGetter && fieldName) {
      this.setState({ pageGetter, fieldName });
    }
    this.setState({ id, action, currentStep: +step, followUpNeeded }, () => {
      if (!!parseInt(id)) {
        AddEditStore.fetchCMData(id, followUpNeeded);
      } else {
        const { companyId } = this.getRouteParams();
        AddEditStore.initCMData(companyId);
      }
    });
    AddEditStore.userId = router.location.state?.userId || null;
    //console.log(followUpNeeded);
  }
  getClientHeight = () => {
    const tbl = document.getElementById('page-container-add-edit-case-manager');
    if (tbl) {
      return tbl.clientHeight * 2;
    }
    return window.innerHeight;
  };
  componentWillUnmount() {
    AddEditStore.resetStore();
  }

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 5,
    });
  };

  convertDate = datestr => {
    if (datestr) {
      if (typeof datestr === 'string') {
        if (datestr.search('/Date') !== -1) {
          const date = formatDate(datestr, true);
          return moment(date, 'DD MMM YYYY, HH:mm').toDate();
        }
        return moment(datestr, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      return datestr.toDate();
    }
    return null;
  };

  getKeywordErrorMessage = message => {
    return message
      ? message.toLowerCase().search('invalid') !== -1
        ? 'invalid'
        : message.toLowerCase().search('required') !== -1
        ? 'required'
        : message.toLowerCase().search('digits') !== -1
        ? 'digits'
        : null
      : null;
  };

  retrunErrorMessage = (keywordFieldName, message) => {
    if (keywordFieldName === 'RecruitmentStatus') {
      return this.showFormErrorNoti(message);
    } else if (this.getKeywordErrorMessage(message) && keywordFieldName) {
      if (keywordFieldName === 'Email' && this.getKeywordErrorMessage(message) === 'invalid') {
        return this.showFormErrorNoti('Invalid email address');
      } else {
        return this.showFormErrorNoti(
          `${ERROR_FIELDNAME[keywordFieldName]} ${ERROR_MESSAGES[this.getKeywordErrorMessage(message)]}`,
        );
      }
    } else {
      return this.showFormErrorNoti('An error occurred, please try again');
    }
  };

  getParamForSave = () => {
    const { CMInfo } = AddEditStore;
    const body = {
      ...CMInfo,
      AssignedDate: this.convertDate(CMInfo.AssignedDate),
      UnsubscribedDate: CMInfo.Unsubscribed ? moment().toDate() : null,
      Position: CMInfo.Position ? CMInfo.Position.Id : null,
      CompanyName: CMInfo.CompanyName || null,
      CompanyAddress: CMInfo.CompanyAddress || null,
      DateCheckedFreeBooking: this.convertDate(CMInfo.DateCheckedFreeBooking),
      FreeBookingFrom: this.convertDate(CMInfo.FreeBookingFrom),
      FreeBookingTo: this.convertDate(CMInfo.FreeBookingTo),
      HourlyRate1From: this.convertDate(CMInfo.HourlyRate1From),
      HourlyRate1To: this.convertDate(CMInfo.HourlyRate1To),
      HourlyRate2From: this.convertDate(CMInfo.HourlyRate2From),
      HourlyRate2To: this.convertDate(CMInfo.HourlyRate2To),
      HourlyRate3From: this.convertDate(CMInfo.HourlyRate3From),
      HourlyRate3To: this.convertDate(CMInfo.HourlyRate3To),
      OnboardingDate: this.convertDate(CMInfo.OnboardingDate),
      RecruitingDate: this.convertDate(CMInfo.RecruitingDate),
      ExcludingDate: this.convertDate(CMInfo.ExcludingDate),
      OnboardedDate: this.convertDate(CMInfo.OnboardedDate),
      SubscribeSpecialistInvoiceCheckDate: this.convertDate(CMInfo.SubscribeSpecialistInvoiceCheckDate),
      UnSubscribeSpecialistInvoiceCheckDate: this.convertDate(CMInfo.UnSubscribeSpecialistInvoiceCheckDate),
      InActiveDate: this.convertDate(CMInfo.InActiveDate),
      FullName: CMInfo.FirstName + ' ' + CMInfo.LastName,
    };
    delete body.UserName;
    delete body.FullName;
    delete body.FreeBookingApprovedByName;
    delete body.EnableLoginDate;
    delete body.EnableLoginBy;
    delete body.EmailConfirmed;
    delete body.DateCheckedFreeBooking;
    delete body.CreateFreeBookingByName;
    delete body.AssignedDate;
    if (!CMInfo.Telephone) {
      delete body.Telephone;
    }
    if (!CMInfo.OfficePhone) {
      delete body.OfficePhone;
    }
    if (!CMInfo.Comments) {
      delete body.Comments;
    }

    if (CMInfo.Id === 0) {
      return body;
    } else {
      return {
        ...body,
        DateCheckedFreeBooking: this.convertDate(CMInfo.DateCheckedFreeBooking),
        FreeBookingFrom: this.convertDate(CMInfo.FreeBookingFrom),
        FreeBookingTo: this.convertDate(CMInfo.FreeBookingTo),
        HourlyRate1From: this.convertDate(CMInfo.HourlyRate1From),
        HourlyRate1To: this.convertDate(CMInfo.HourlyRate1To),
        HourlyRate2From: this.convertDate(CMInfo.HourlyRate2From),
        HourlyRate2To: this.convertDate(CMInfo.HourlyRate2To),
        HourlyRate3From: this.convertDate(CMInfo.HourlyRate3From),
        HourlyRate3To: this.convertDate(CMInfo.HourlyRate3To),
        OnboardingDate: this.convertDate(CMInfo.OnboardingDate),
        RecruitingDate: this.convertDate(CMInfo.RecruitingDate),
        OnboardedDate: this.convertDate(CMInfo.OnboardedDate),
        ExcludingDate: this.convertDate(CMInfo.ExcludingDate),
        FullName: CMInfo.FirstName + ' ' + CMInfo.LastName,
      };
    }
  };

  detectInvalidField = fieldName => {
    const { CMInfo } = AddEditStore;
    if (fieldName === 'Email') {
      if (!CMInfo[fieldName] && AddEditStore.CMInfo.EnableLogin) {
        AddEditStore.handleAddError(fieldName, 'This field is required');
        const { isRerender } = this.state;
        this.setState({ isRerender: !isRerender });
        AddEditStore.setFieldsValue({ loadingSave: false });
        return;
      }
    } else {
      if (!CMInfo[fieldName]) {
        AddEditStore.handleAddError(fieldName, 'This field is required');
        const { isRerender } = this.state;
        this.setState({ isRerender: !isRerender });
        AddEditStore.setFieldsValue({ loadingSave: false });
        return;
      }
    }
  };

  validateHourlyFromTo = (periodFirstRate, periodSecondRate) => {
    const periodFirstRate_ = AddEditStore.checkDateString(periodFirstRate);
    const periodSecondRate_ = AddEditStore.checkDateString(periodSecondRate);
    const firstDate = AddEditStore.createDate(periodFirstRate_);
    const secondDate = AddEditStore.createDate(periodSecondRate_);
    if (firstDate.getTime() > secondDate.getTime()) {
      return false;
    } else {
      return true;
    }
  };

  handleSave = () => {
    const { errors, CMInfo } = AddEditStore;
    const periodFirstRate = AddEditStore.checkValidEffectiveFromTo(CMInfo.HourlyRate1From, CMInfo.HourlyRate1To);
    const periodSecondRate = AddEditStore.checkValidEffectiveFromTo(CMInfo.HourlyRate2From, CMInfo.HourlyRate2To);
    const periodThirdRate = AddEditStore.checkValidEffectiveFromTo(CMInfo.HourlyRate3From, CMInfo.HourlyRate3To);
    if (!periodFirstRate) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return AddEditStore.showErrorEffectiveDate('Invalid effective period of Rate 1');
    }
    if (!periodSecondRate) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return AddEditStore.showErrorEffectiveDate('Invalid effective period of Rate 2');
    }
    if (!periodThirdRate) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return AddEditStore.showErrorEffectiveDate('Invalid effective period of Rate 3');
    }

    AddEditStore.setFieldsValue({ loadingSave: true });
    const bodyParam = this.getParamForSave();
    if (!CMInfo.IsTypist) {
      this.detectInvalidField('CompanyId');
    } else {
      delete AddEditStore.errors['CompanyId'];
    }

    if (bodyParam.HourlyRate1 && !bodyParam.HourlyRate1From) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return this.showFormErrorNoti();
    }
    if (bodyParam.HourlyRate2 && !bodyParam.HourlyRate2From) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return this.showFormErrorNoti();
    }
    if (bodyParam.HourlyRate3 && !bodyParam.HourlyRate3From) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      return this.showFormErrorNoti();
    }
    if (bodyParam.HourlyRate2 && bodyParam.HourlyRate2From) {
      const isValid = this.validateHourlyFromTo(CMInfo.HourlyRate1To || CMInfo.HourlyRate1From, CMInfo.HourlyRate2From);
      if (!isValid) {
        AddEditStore.setFieldsValue({ loadingSave: false });
        return AddEditStore.showErrorEffectiveDate(
          'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 1.',
        );
      }
    }
    if (bodyParam.HourlyRate3 && bodyParam.HourlyRate3From) {
      const isValid = this.validateHourlyFromTo(CMInfo.HourlyRate2To || CMInfo.HourlyRate2From, CMInfo.HourlyRate3From);
      if (!isValid) {
        AddEditStore.setFieldsValue({ loadingSave: false });
        return AddEditStore.showErrorEffectiveDate(
          'Effective date of Hourly Rate 3 must be after effective period of Hourly Rate 2.',
        );
      }
    }

    this.detectInvalidField('Email');

    if (!!Object.keys(errors).length) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      const errorList = Object.entries(errors);
      notification.destroy();
      return errorList.map(([key, value]) => this.retrunErrorMessage(key, value));
    }

    const { fieldName, pageGetter } = this.state;
    const { listPageGetters } = dashboard;
    const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);

    bodyParam.SubscribedDate = null;
    bodyParam.UnsubscribedDate = null;
    bodyParam.VerifiedDate = null;
    bodyParam.UnVerifiedDate = null;
    var lstFavourite = AddEditStore.registeredSpecialistObjs.filter(i => i.isChecked);
    console.log(lstFavourite);

    Promise.all([
      saveCaseManager(bodyParam, bodyParam.CompanyBusinessUnit),
      registeredSpecialists(bodyParam.Id, lstFavourite),
    ])
      .then(([res, updateFavouriteResp]) => {
        if (res.status === 'success') {
          if (fieldName && pageGetter) {
            dashboard.close(router.location.pathname);
            dashboard.transferData({
              fieldName: fieldName,
              pageGetter: pageGetter,
              dataTransfer: {
                ...bodyParam,
                Id: res.newId,
                FullName: `${bodyParam.FirstName} ${bodyParam.LastName}`,
              },
            });
          }
          if (urlPageGetter && urlPageGetter.pathname) {
            setTimeout(() => {
              dashboard.open(urlPageGetter.pathname);
            });
            return;
          }
          notification.destroy();
          notification.success({
            message: 'Success',
            description: ' Data has been saved successfully.',
          });
          if (!bodyParam.Id) {
            let firstName = bodyParam.FirstName.charAt(0).toUpperCase() + bodyParam.FirstName.slice(1);
            let lastName = bodyParam.LastName.charAt(0).toUpperCase() + bodyParam.LastName.slice(1);
            localStorage.setItem('CmName', `${firstName || ''} ${lastName || ''}`);
            dashboard.close('/view/add-edit-case-manager-2');
            setTimeout(() => {
              dashboard.open(`/view/add-edit-case-manager-2?id=${res.newId}`);
            }, 1000);
            return;
          }
          return AddEditStore.fetchCMData(res.newId);
        } else {
          const { errorList } = res;
          const errors = Object.entries(errorList);
          AddEditStore.setFieldsValue({ loadingSave: false });
          AddEditStore.handleUpdateCMInfo('EnableLogin', false);
          if (errorList.Email === 'exists') {
            return AddEditStore.handleAddError(
              'duplicateEmail',
              'The registered email has been used in another account. Please check for duplicate account under Login Accounts section',
            );
          }
          return errors.map(([key, value]) => {
            if (key === 'Email' && value === 'exists') {
              this.showFormErrorNoti(
                `The registered email has been used in another account. Please check for duplicate account under Login Accounts section`,
              );
            } else {
              this.showFormErrorNoti(`${key} is ${value}`);
            }
          });
        }
        AddEditStore.setFieldsValue({ loadingSave: false });
      })
      .catch(() => {});
  };
  handleClose = () => {
    return AddEditStore.toggleModal(true, {
      modalType: 'confirm',
      onOk: () => dashboard.close(router.location.pathname),
      message: 'Are you sure not to save changes?',
    })();
  };

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <LoginInformation />;
      case 2:
        return <PaymentInfomation />;
      case 3:
        return <FreeOffers />;
      case 4:
        return <CaseCorrespondence />;
      case 5:
        return <BookingHistory />;
      case 6:
        return <FavouriteSpecialists />;
      case 7:
        return <Chart />;
      case 8:
        return <ActionRequired />;
      default:
        return <ContactInformation isRerender={this.state.isRerender} />;
    }
  };

  render() {
    const { loading, open, CMInfo, totalBooking } = AddEditStore;
    return (
      <div id="page-container-add-edit-case-manager" className="page-container add-edit-case-manager">
        <Dimmer active={loading} style={{ height: `${this.getClientHeight()}px` }} inverted>
          <Spin size="large" />
        </Dimmer>
        {open && <AddEditModal />}
        <div className="form-container">
          {this.state.currentStep === 3 ? (
            <h1 className="form-title">
              {`Booking History - ${CMInfo.FirstName} ${CMInfo.LastName} (${totalBooking})`}
            </h1>
          ) : (
            <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          )}
          {this.renderForm()}
        </div>
        <div className="step-container step-container-sticky">
          {!api.isSiteOnlyInvoiceCheck() ? STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              disabled={
                ((idx === 4 || idx === 5 || idx === 6) && CMInfo.Id === 0) ||
                (idx === 2 && !CMInfo.IsTypist) ||
                (idx === 3 && CMInfo.IsTypist)
              }
            >
              {idx + 1}. {step}
            </Button>
          )) : STEPS2.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              disabled={
                ((idx === 4 || idx === 5 || idx === 6) && CMInfo.Id === 0) ||
                (idx === 2 && !CMInfo.IsTypist) ||
                (idx === 3 && CMInfo.IsTypist)
              }
            >
              {idx + 1}. {step}
            </Button>
          ))}
          {AddEditStore.openModalAction && <ActionRequiredModal />}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && !api.isSiteOnlyInvoiceCheck() && <Button primary onClick={this.handleSave}>
            Save
          </Button>}
          <Button onClick={this.handleClose} className="negative">
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditCaseManager;
