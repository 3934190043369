import { action, observable } from 'mobx';
import moment from 'moment';

import { getListCampaign, getConfigType, getStaff } from './service';

class MarketingCampaignStore {
  @observable loading = false;
  @observable data = null;
  @observable channels = null;
  @observable targetAudience = null;
  @observable targetStatus = null;

  @observable keyword = '';
  @observable filterStatus = 0;
  @observable filterAudience = 0;
  @observable filterChannel = 0;
  @observable filterMarketingStatus = '';
  @observable fromTo = '';
  @observable staffs = [];

  @observable sortKey = '';
  @observable sortColumn = '';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };
  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : '',
    };
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchCampaign();
  };

  @action fetchCampaign = (setTotalPage = true) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    Promise.all([
      getListCampaign(
        this.curPage,
        20,
        this.sortKey,
        this.sortDirection,
        this.keyword,
        fromDate,
        toDate,
        this.filterAudience,
        this.filterStatus,
        this.filterChannel,
      ),
      getConfigType('TargetAudience'),
      getConfigType('Channel'),
      getConfigType('TargetStatus'),
      getStaff(),
    ]).then(
      action(([listCampaign, listAudiences, listChannels, listStatus, listStaffs]) => {
        this.data = listCampaign;
        this.targetAudience = listAudiences;
        this.channels = listChannels;
        this.targetStatus = listStatus;
        this.staffs = listStaffs.itemList;
        this.loading = false;
        this.totalPage = !!listCampaign.sumPage ? listCampaign.sumPage : this.totalPage;
        this.totalItems = !!listCampaign.sumItem ? listCampaign.sumItem : this.totalItems;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.open = isOpen;
    this.modalParams = modalParams;
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage = true) => {
    this.loading = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    const { fromDate, toDate } = this.getDateRange();
    Promise.all([
      getListCampaign(
        this.curPage,
        20,
        this.sortKey,
        this.sortDirection,
        this.keyword,
        fromDate,
        toDate,
        this.filterAudience,
        this.filterStatus,
        this.filterChannel,
        this.filterMarketingStatus,
      ),
    ]).then(
      action(([listCampaign]) => {
        this.data = listCampaign;
        this.loading = false;
        this.totalPage = !!listCampaign.sumPage ? listCampaign.sumPage : this.totalPage;
        this.totalItems = !!listCampaign.sumItem ? listCampaign.sumItem : this.totalItems;
      }),
    );
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filterStatus = 0;
    this.filterAudience = 0;
    this.filterMarketingStatus = '';
    this.sortColumn = '';
    this.sortKey = '';
    this.sortDirection = 'ascending';
    this.filterChannel = 0;

    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new MarketingCampaignStore();
