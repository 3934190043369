import { observer } from 'mobx-react';
import React from 'react';

import TableToolbar from '../../shared/tableToolbar';
import LoginUserStore from './LoginUserStore';
import dashboardStore from '../../../stores/dashboard';
import * as api from '../../../../src/stores/api';

const statusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

const rolesLabels = [
  { text: `All Roles`, value: `All` },
  { text: `Admin`, value: `admin` },
  { text: `Staff`, value: `magstaff` },
  { text: `Case Manager`, value: `casemng` },
  { text: `Specialist`, value: `doctor` },
  { text: `Accounting`, value: `accounting` },
  { text: `Typist`, value: `typist` },
];

@observer
class LoginUserToolbar extends React.Component {
  renderFilterOptions = optionArray => {
    return optionArray.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = (value, optionArray) => {
    let labels = optionArray.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  openModalAddOrEdit = () => {
    // return LoginUserStore.toggleModal(true, {
    //   modalType: 'addOrEdit',
    // });
    localStorage.removeItem('StaffName');
    dashboardStore.close('/view/add-edit-staff-2', true);
    setTimeout(() => {
      dashboardStore.open('/view/add-edit-staff-2');
    });
  };

  handleFilter = (_, { value }) => {
    LoginUserStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleFilterRole = (_, { value }) => {
    LoginUserStore.refetch({ role: value, curPage: 1 }, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    LoginUserStore.refetch({ curPage: 1 }, true);
  };

  handleReset = () => {
    LoginUserStore.resetStore();
    LoginUserStore.fetchLoginUser(true);
  };

  handleRefresh = () => {
    LoginUserStore.refetch(
      {
        data: null,
      },
      true,
    );
  };

  render() {
    const { keyword, loading, filter, role, totalItems } = LoginUserStore;
    return (
      <>
        <TableToolbar.Container style={{ marginBottom: '0' }}>
          <TableToolbar.Left>
            <TableToolbar.Input
              placeholder={'Search User'}
              value={keyword}
              onChange={LoginUserStore.handleSearchChange}
              disabled={loading}
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: `5px` }}
              className="negative"
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: `5px` }}
              className="blue"
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              disabled={loading}
              className="positive"
              onClick={() => this.openModalAddOrEdit()}
              style={{ marginLeft: `5px` }}
            >
              Add Staff Account
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Filter
              disabled={loading}
              value={this.renderFilterText(filter, statusLabels)}
              options={this.renderFilterOptions(statusLabels)}
              label={`Account Status`}
              onChange={this.handleFilter}
            />
            <TableToolbar.Filter
              disabled={loading}
              value={this.renderFilterText(role, rolesLabels)}
              label={`Account Role`}
              options={this.renderFilterOptions(rolesLabels)}
              onChange={this.handleFilterRole}
            />
          </TableToolbar.Left>
        </TableToolbar.Container>
      </>
    );
  }
}

export default LoginUserToolbar;
