import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import Anonymous from '../anonymous/Anonymous';

const Page404 = () => (
  <Anonymous
    title="404 Not found"
    description="The page you are looking for doesn't exist in the system."
    Component={() => (
      <React.Fragment>
        <Divider />
        <Link to="/view/login" className="link">
          Back to login
        </Link>
      </React.Fragment>
    )}
  />
);

export default Page404;
