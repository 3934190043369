import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import IndustrySegmentsStore from './IndustrySegmentsStore';
import IndustrySegmentsAddEdit from './IndustrySegmentsAddEdit';

function IndustrySegmentsModal() {
  const { modalType } = IndustrySegmentsStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...IndustrySegmentsStore} onCancel={IndustrySegmentsStore.toggleModal(false)} />;
  }
  if (modalType === 'addEdit') {
    return <IndustrySegmentsAddEdit {...IndustrySegmentsStore} onCancel={IndustrySegmentsStore.toggleModal(false)} />;
  }
}

export default IndustrySegmentsModal;
