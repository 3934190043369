import { observer } from 'mobx-react';
import React from 'react';

import store from './store';
import Table from './Table';

class UserUpdateHistory extends React.Component {
  componentDidMount() {
    const { userId, username, type } = this.props;
    store.refetch({ userId: userId, keyword: username, filter: type });
  }

  render() {
    return <Table />;
  }
}

export default observer(UserUpdateHistory);
