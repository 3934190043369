import axios from 'axios';

export function uploadFileBase(uploadFile, maxSize) {
  const formData = new FormData();
  for (var i = 0; i < uploadFile.length; i++) {
    if (maxSize && uploadFile[i].size > maxSize * 1021000) {
      continue;
    }

    formData.append(uploadFile[i].name, uploadFile[i]);
  }
  return axios({
    method: 'post',
    url: '/File/UploadFileTemp',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function(response) {
      //handle success
      return response.data;
    })
    .catch(function(response) {
      //handle error
      return response;
    });
}
