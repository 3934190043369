import './NotificationNewSite.scss';

import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import * as api from '../../stores/api';
import ui from '../../stores/dashboard';
import router from '../../stores/router';
import routes from '../dashboard-routes';
import IframeContainer from '../dashboard-routes/Iframe/IframeContainer';
import DashboardContent from './content/DashboardContent';
import DashboardHeader from './header/DashboardHeader';
import DashboardSidebar from './sidebar/DashboardSidebar';
import siteLogo from '../../assets/images/mag-logo4.png';

@observer
class NotificationNewSite extends React.Component {

  componentDidMount() {
    const { pathname } = router.location;
    if(pathname.includes("/view/video")){
      window.location.href = this. getLinkRedirect();
    }
  }

  getLinkRedirect = () => {
    let strLocalHef = window.location.href;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    return "https://mag.kawaconn.com/video-meeting?token="  + token +  "&redirectFromMAG=true"
  }

  getWith = () => window.screen.width;
  getHeight = () => {
    var body = document.body;
    var html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );

    return height+ 999;
  }

  render() {
    return (
      <React.Fragment>
        <div className='container' style={{width: '100%', height: this.getHeight()}}>
          <div className="header" style={{width: "100%", textAlign: 'center'}}>
            <a href="https://medicolegalassessmentsgroup.com.au" target="_blank" rel="noopener noreferrer" title="Kawaconn MLP" style={{display: 'block'}}>
              <img src={siteLogo} alt="mag-logo" style={{width: '250px'}} />
            </a>
          </div>
          <div className='notification' style={{width: '100%', textAlign: 'center'}}>
            <div>MAG Connect site has now moved to <a href="https://mag.kawaconn.com" target="_blank" style={{}}>https://mag.kawaconn.com</a>.</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotificationNewSite;