import './index.scss';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import DoctorReportsStore from './DoctorReportsStore';
import ViewHistoryReport from './ViewHistoryReport';
import DoctorReportModal from './DoctorReportModal';
import DoctorRemittance from '../DoctorRemittance';
import Toolbar from './Toolbar';
import ExportToolbar from './ExportToolbar';
import SalesCall from '../SalesCallReportForSpecialist';
import OtherSalesCall from '../OtherSalesCallReportForSpecialist';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class DoctorReports extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/doctor-reports-2', true);
      return;
    }
    DoctorReportsStore.fetchDoctor(true);
  }

  render() {
    const {
      loadingDoctor,
      dataDoctor,
      loadingDoctorReports,
      dataDoctorReports,
      keyword,
      open,
      tabIsActive,
    } = DoctorReportsStore;

    return (
      <div className={`Table-${loadingDoctor && !dataDoctor ? 'loading' : 'container'}`}>
        {(!dataDoctor && loadingDoctor) || (!dataDoctorReports && loadingDoctorReports && keyword) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            {open && <DoctorReportModal />}
            {tabIsActive === 'historyReport' && <ViewHistoryReport />}
            {tabIsActive === 'specialistSalesCall' && <SalesCall type="specialist" />}
            {tabIsActive === 'otherSpecialistSalesCall' && <OtherSalesCall type="specialist" />}
            {tabIsActive === 'remittanceStatements' && <DoctorRemittance />}
            {tabIsActive === 'export' && <ExportToolbar />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DoctorReports;
