import { observer } from 'mobx-react';
import React from 'react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import JobTitleStore from './JobTitleStore';
import router from '../../../stores/router';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class JobTitleToolbar extends React.Component {
  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    JobTitleStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleReset = () => {
    JobTitleStore.resetStore();
    JobTitleStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    JobTitleStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    var id = 0;
    if (localStorage.getItem(`JobTitle`)) {
      localStorage.removeItem(`JobTitle`);
    }
    iframe.forceOpenTab('AddOrEditJobTitle', `?id=${id}&view=AddOrEditJobTitle`, {
      id,
      view: 'AddOrEditJobTitle',
    });
  };

  onOpenCaseCreateNew = () => {
    localStorage.setItem('JobTitle', 'Add/Edit Job Title');
    router.history.push({
      pathname: '/view/add-edit-job-title-2',
      state: {
        type: 'jobTitle',
      },
      search: `?id=${0}`,
    });
  };

  render() {
    const { keyword, loading, filter } = JobTitleStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder={'Search job title'}
            value={keyword}
            onChange={JobTitleStore.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`Status`}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton className="positive" disabled={loading} onClick={this.onOpenCaseCreateNew}>
              Add job title
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default JobTitleToolbar;
