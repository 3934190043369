import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import fileReviewStore from './fileReviewStore';
import FileReviewTable from './FileReviewTable';
import FileReviewToolbar from './FileReviewToolbar';
import ModalCaseProgress from './ModalCaseProgress';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';

// /FileReview/_RenderPartialFileReview2?id=${id}

class FileReview extends React.Component {
  renderCase = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case `Corrected Report Sent to MAG`:
        return `Corrected Report Returned from Provider`;
      default:
        return caseStatus;
    }
  };
  renderNextTask = task => {
    switch (task) {
      case 'Submit Case Documents':
        return 'Request Received';
      case 'Pay Invoices':
        return 'Service Billed';
      default:
        return task;
    }
  };
  componentDidMount() {
    this._searchDebounced();
    ui.on('open(/view/doctor-file-reviews)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?nextTaskDue=') !== -1) {
        const id = search.replace('?nextTaskDue=', '').replaceAll('%20', ' ');
        fileReviewStore.refetch({ filterNextStatus: this.renderCase(id) }, true);
      } else {
        const id = search.replaceAll('%20', ' ').replace('?caseStatus=', '');
        fileReviewStore.refetch({ filter: this.renderCase(id) }, true);
      }
    } else {
      fileReviewStore.refetch({}, true);
    }
  }, 1000);

  render() {
    const { loading, data, open } = fileReviewStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <FileReviewToolbar />
            <FileReviewTable />
            {open && <ModalCaseProgress />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(FileReview);
