import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'antd';
import { Icon, Message } from 'semantic-ui-react';

import store from './store';

export const Upload = ({
  multiple = false,
  placeholder,
  reportType,
  attachments,
  option,
  functionCallback,
  disabled,
  disabledDelete,
  uploadTitle,
  isViewMergeFile,
}) => {
  const handleOpenModalUpload = () => {
    store.toggleModalModalUpload(true, {
      reportType,
      multiple,
      attachments,
      placeholder,
      option,
      functionCallback,
      disabled,
      disabledDelete,
      uploadTitle,
      isViewMergeFile,
    });
  };
  const styleBtnDownload = attachments => {
    return !!attachments.length ? { cursor: 'pointer', width: 150 } : { cursor: 'not-allowed', width: 150 };
  };
  const handleDownloadFiles = () => {
    return window.open(
      `/ClinicalNote/ExportPaperworkAttachToZip?service=C&itemId=${store.clinicalNoteInfo.Id}&type=${reportType}`,
    );
  };
  return (
    <div className="Upload-File" style={{ cursor: 'pointer' }}>
      <label className="Button" style={{ cursor: 'pointer' }} onClick={handleOpenModalUpload}>
        <span>{!isViewMergeFile ? (disabled ? 'View' : 'Upload/View') : 'View'}</span>
      </label>
      <label
        className="Button"
        style={styleBtnDownload(attachments)}
        onClick={() => {
          !!attachments.length && handleDownloadFiles();
        }}
      >
        <Icon name="download" />
        <span>Download All</span>
      </label>
      <p style={{ cursor: 'pointer' }} onClick={handleOpenModalUpload}>
        {placeholder}
      </p>
    </div>
  );
};

export const File = ({ name, downloadLink, onRemove }) => {
  return (
    <Message className="upload-box">
      <div className="file-info">
        <div className="file-info-inner">
          <Icon name="file alternate" />
          <div className="file-name">{name}</div>
        </div>
        <div className="file-actions">
          <Icon name="download" onClick={() => window.open(downloadLink, '_blank')} />
          <Icon name="delete" color="red" onClick={onRemove} />
        </div>
      </div>
    </Message>
  );
};

export default observer(
  ({
    type,
    option,
    multiple = false,
    functionCallback,
    disabled,
    label,
    disabledDelete,
    uploadTitle,
    isViewMergeFile = false,
  }) => {
    const { Attachments } = store.clinicalNoteInfo;

    const attachments = Attachments.filter(({ ReportType }) => ReportType === type);

    const placeholder =
      attachments && !!attachments.length
        ? `${attachments.length} ${attachments.length > 1 ? 'attachments' : 'attachment'} ${
            attachments.length > 1 ? 'are' : 'is'
          } found`
        : !isViewMergeFile
        ? disabled
          ? 'No files found'
          : `Upload your attachment`
        : 'No Merge File Found';

    return (
      <Form.Item label={label} className="add-file-container">
        <Upload
          multiple={multiple}
          placeholder={placeholder}
          reportType={type}
          attachments={attachments}
          option={option}
          functionCallback={functionCallback}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          isViewMergeFile={isViewMergeFile}
        />
      </Form.Item>
    );
  },
);
