import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import VideoSessionStore from './VideoSessionStore';

function VideoModal() {
  const { modalType } = VideoSessionStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...VideoSessionStore} onCancel={VideoSessionStore.toggleModal(false)} />;
  }
}

export default VideoModal;
