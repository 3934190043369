import { observer } from 'mobx-react';
import React from 'react';

import TableToolbar from '@components/shared/tableToolbar';
import * as api from '@stores/api';
import NotificationStore from './NotificationStore';
import { NotificationTypes } from './constants';

@observer
class NotificationToolbar extends React.Component {
  handleSubmit = event => {
    NotificationStore.fetchNotifications(true);
  };

  handleReset = () => {
    NotificationStore.resetStore();
    NotificationStore.fetchNotifications(true);
  };

  handleRefresh = () => {
    NotificationStore.fetchNotifications(false);
  };

  handleChangeFromTo = (_, { value }) => {
    NotificationStore.setFieldsValue({ fromTo: value });
  };

  handleSearchChange = ({ target }) => {
    NotificationStore.setFieldsValue({ keyword: target.value });
  };

  handleNotificationTypeChanged = (_, { value }) => {
    NotificationStore.setFieldsValue({ notificationType: value });
    NotificationStore.fetchNotifications(true);
  };

  handleAddNewItem = () => {
    const record = {
      Id: 0,
      Summary: '',
      Details: '',
      CreatedBy: '',
      CreatedAt: '',
      ReceipientRole: '',
      NotificationType: NotificationTypes[0].key,
    };
    NotificationStore.setFieldsValue({
      modalParams: {
        record,
        isOpen: true,
        type: 'add',
      },
    });
  };

  render() {
    const { keyword, loading, fromTo, notificationType } = NotificationStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left>
          <TableToolbar.Input
            placeholder={'Search'}
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.RangePicker
            value={fromTo}
            placeholder={'From Sent Date - To Sent Date'}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
          />
          <TableToolbar.Filter
            value={notificationType}
            label="Notification Type"
            options={NotificationTypes}
            onChange={this.handleNotificationTypeChanged}
          />
          <TableToolbar.SubmitButton
            className="Toolbar-highlight-button px-2"
            onClick={this.handleSubmit}
            disabled={loading}
          >
            Search
          </TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: `5px` }}
            className="negative"
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            onClick={this.handleRefresh}
            style={{ marginLeft: `5px` }}
            className="blue"
          >
            Refresh
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.handleAddNewItem}>
              Add Notification
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default NotificationToolbar;
