import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AMACalculationRuleStore from './Store';
import ModalCreate from './ModalCreate';
import CreateCalculationRule from './CreateCalculationRule';
import AMAUpdateHistory from './AMAUpdateHistory';

function AMACalculationRuleModal() {
  const { modalType } = AMACalculationRuleStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AMACalculationRuleStore} onCancel={AMACalculationRuleStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...AMACalculationRuleStore} onCancel={AMACalculationRuleStore.toggleModal(false)} />;
  }
  if (modalType === 'addCalculationRule') {
    return <CreateCalculationRule {...AMACalculationRuleStore} onCancel={AMACalculationRuleStore.toggleModal(false)} />;
  }
  if (modalType === 'history') {
    return <AMAUpdateHistory {...AMACalculationRuleStore} onCancel={AMACalculationRuleStore.toggleModal(false)} />;
  }
}

export default AMACalculationRuleModal;
