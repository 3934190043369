module.exports = {
  // PROFFILE
  MyAccount: {
    path: '/view/admin-account',
    controller: 'Account',
    partialView: 'MyAccountPartialView',
  },
  OnlineDoctorMyProfile: {
    path: '/view/dr-account',
    controller: 'OnlineDoctorMyProfile',
    partialView: '_RenderedView',
  },
  CmAccount: {
    path: '/view/cm-account',
    controller: 'Account',
    partialView: 'CmAccountPartialView',
  },

  // COMMON
  ManageSupplementary: {
    path: '/view/supplementary-reports',
    controller: 'Supplementary',
    partialView: '_RenderedView',
  },
  ManageFileReview: {
    path: '/view/file-reviews',
    controller: 'FileReview',
    partialView: '_RenderedView',
  },
  ManageMedNeg: {
    path: '/view/med-neg',
    controller: 'MedNeg',
    partialView: '_RenderedView',
  },
  ManageClinicalNote: {
    path: '/view/clinical-notes',
    controller: 'ClinicalNote',
    partialView: '_RenderedView',
  },
  ManageMRI: {
    path: '/view/mri-cases',
    controller: 'MRI',
    partialView: '_RenderedView',
  },
  ViewClaimant: {
    path: '/view/add-edit-claimant',
    controller: 'PatientInfo',
    partialView: '_RenderPartialClaimant',
  },
  ViewClinic: {
    path: '/view/add-edit-clinic',
    controller: 'Clinic',
    partialView: '_RenderPartialClinic',
  },
  ViewDoctor: {
    path: '/view/add-edit-doctor',
    controller: 'MedicalDoctor',
    partialView: '_RenderPartialDoctor',
  },
  ManageCalendar: {
    path: '/view/calendar',
    controller: 'Calendar',
    partialView: '_RenderedView',
  },
  PostAssessment: {
    path: '/view/book-assessment',
    controller: 'MedicalService',
    partialView: '_RenderPartialAssessment',
  },
  PostMedNeg: {
    path: '/view/new-med-neg',
    controller: 'MedNeg',
    partialView: '_RenderPartialMedNeg',
  },
  PostFileReview: {
    path: '/view/new-file-review',
    controller: 'FileReview',
    partialView: '_RenderPartialFileReview',
  },
  PostSupplementary: {
    path: '/view/new-supplementary-report',
    controller: 'Supplementary',
    partialView: '_RenderPartialSupplementary',
  },
  AddEditClinicalNote: {
    path: '/view/new-clinical-note',
    controller: 'ClinicalNote',
    partialView: '_RenderPartialClinicalNote',
  },
  PostMRI: {
    path: '/view/new-mri-case',
    controller: 'MRI',
    partialView: 'RenderPostMRI',
  },

  // CASE MANAGER TABS
  ViewCMReports: {
    path: '/view/ime-cases',
    controller: 'CMReports',
    partialView: '_RenderedView',
  },
  SendFeedback: {
    path: '/view/send-feedback',
    controller: 'SendFeedback',
    partialView: '_RenderedView',
  },

  // ADMIN TABS
  ManageUser: {
    path: '/view/login-users',
    controller: 'Manager',
    partialView: '_RenderedView',
  },
  ManageCompany: {
    path: '/view/clients',
    controller: 'Company',
    partialView: '_RenderedView',
  },
  ManageCaseManagers: {
    path: '/view/case-managers',
    controller: 'Staff',
    partialView: '_RenderPartialCM',
  },
  ManageDoctor: {
    path: '/view/specialists',
    controller: 'MedicalDoctor',
    partialView: '_RenderedView',
  },
  ManageClinic: {
    path: '/view/medical-centres',
    controller: 'Clinic',
    partialView: '_RenderedView',
  },
  ManagePatients: {
    path: '/view/claimants',
    controller: 'PatientInfo',
    partialView: '_RenderedView',
  },
  ManageJobTitle: {
    path: '/view/job-title',
    controller: 'JobTitle',
    partialView: '_RenderedView',
  },
  ManageTypesOfClaims: {
    path: '/view/types-of-claims',
    controller: 'TypesOfClaims',
    partialView: '_RenderedView',
  },
  ManageTypesOfReport: {
    path: '/view/types-of-report',
    controller: 'TypesOfReport',
    partialView: '_RenderedView',
  },
  ManageIndustrySegment: {
    path: '/view/industry-segments',
    controller: 'IndustrySegment',
    partialView: '_RenderedView',
  },
  ManageIMSApprovalCodeWIRO: {
    path: '/view/ims-approval',
    controller: 'IMSApprovalCodeWIRO',
    partialView: '_RenderedView',
  },
  ManageRateChangePasswords: {
    path: '/view/rate-change-passwords',
    controller: 'ManageRateChangePasswords',
    partialView: '_RenderedView',
  },
  StaffSmartClinicSearch: {
    path: '/view/smart-clinic-search',
    controller: 'StaffSmartClinicSearch',
    partialView: '_RenderedView',
  },
  ManageAssessment: {
    path: '/view/ime-assessments',
    controller: 'MedicalService',
    partialView: '_RenderedView',
  },
  ManageFactualInvestigation: {
    path: '/view/factual-investigations',
    controller: 'FactualInvestigation',
    partialView: '_RenderedView',
  },
  ManageInvoice: {
    path: '/view/invoices',
    controller: 'Invoice',
    partialView: '_RenderedView',
  },
  ManageServiceItems: {
    path: '/view/service-fee',
    controller: 'MAGService',
    partialView: '_RenderedView',
  },
  DoctorReports: {
    path: '/view/doctor-reports',
    controller: 'Report',
    partialView: '_RenderedView',
  },
  ClientReports: {
    path: '/view/client-reports',
    controller: 'Report',
    partialView: '_RenderedView',
  },
  CmModuleUsageReport: {
    path: '/view/login-history',
    controller: 'Report',
    partialView: '_RenderedView',
  },
  ViewCompany: {
    path: '/view/add-edit-client',
    controller: 'Company',
    partialView: '_RenderPartialCompany',
  },
  ViewCM: {
    path: '/view/add-edit-case-manager',
    controller: 'Staff',
    partialView: '_RenderPartialCMDetail',
  },
  AddOrEditJobTitle: {
    path: '/view/add-edit-job-title',
    controller: 'JobTitle',
    partialView: '_RenderPartialJobTitle',
  },
  PostTypesOfClaims: {
    path: '/view/add-edit-type-of-claim',
    controller: 'TypesOfClaims',
    partialView: '_RenderPartialTypesOfClaims',
  },
  PostTypesOfReport: {
    path: '/view/add-edit-type-of-report',
    controller: 'TypesOfReport',
    partialView: '_RenderPartialTypeOfReport',
  },
  AddOrEditIMSApprovalCodeWIRO: {
    path: '/view/add-edit-ims-approval',
    controller: 'IMSApprovalCodeWIRO',
    partialView: '_RenderPartialIMSApprovalCodeWIRO',
  },
  PostFactualInvestigation: {
    path: '/view/view-factual-investigation',
    controller: 'FactualInvestigation',
    partialView: '_RenderPartialFactualInvestigation',
  },
  ViewInvoice: {
    path: '/view/view-invoice',
    controller: 'Invoice',
    partialView: '_RenderPartialInvoice',
  },
  DoctorCommissionRemittanceList: {
    path: '/view/generate-doctor-payment',
    controller: 'DoctorCommissionRemittanceList',
    partialView: '_RenderedView',
  },
  ManageBusinessUnits: {
    path: '/view/business-units',
    controller: 'BusinessUnit',
    partialView: '_RenderPartialBusinessUni',
  },
  ViewBU: {
    path: '/view/add-edit-business-unit',
    controller: '_RenderPartialBUDetail',
    partialView: '_RenderPartialBusinessUni',
  },
};
