import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ClientReportStore from './ClientReportStore';
import ModalHistory from './ModalReportHistory';
import ModalExport from './ModalExport';
import ModalImport from './ModalImport';
import ModalExportHistories from './ModalExportHistories';

function CaseManagersModal() {
  const { modalType } = ClientReportStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...ClientReportStore} onCancel={ClientReportStore.toggleModal(false)} />;
  }
  if (modalType === 'history') {
    return <ModalHistory {...ClientReportStore} onCancel={ClientReportStore.toggleModal(false)} />;
  }
  if (modalType === 'export') {
    return <ModalExport {...ClientReportStore} onCancel={ClientReportStore.toggleModal(false)} />;
  }
  if (modalType === 'import') {
    return <ModalImport {...ClientReportStore} onCancel={ClientReportStore.toggleModal(false)} />;
  }
  if (modalType === 'exportHistories') {
    return <ModalExportHistories {...ClientReportStore} onCancel={ClientReportStore.toggleModal(false)} />;
  }
}

export default CaseManagersModal;
