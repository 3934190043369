import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStore from './store';
import ModalDuplicate from './ModalDuplicate';
import ModalRecentChange from './ModalRecentChange';
import ModalNotification from './ModalNotification';
import ModalEditUser from './ModalEditUser';
import ModalPasswordResetHistory from './ModalPasswordResetHistory';
import ModalUserUpdateHistory from './ModalUserUpdateHistory';
import ModelShowTalkingPoint from './ModelShowTalkingPoint';
import ModalCaseManagerHistory from './ModalCaseManagerHistory';
import ModalDisableUser from './ModalDisableUser';
import ModalCreatedByHistory from './ModalCreatedByHistory';

function AddEditModal() {
  const { modalType } = AddEditStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'duplicate') {
    return <ModalDuplicate {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'recentChange') {
    return <ModalRecentChange {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'notification') {
    return <ModalNotification {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'editUser') {
    return <ModalEditUser onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'passwordRsHistory') {
    return <ModalPasswordResetHistory onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'userUpdateHistory') {
    return <ModalUserUpdateHistory onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'showTalkingPoints') {
    return <ModelShowTalkingPoint onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'history') {
    return <ModalCaseManagerHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'disable') {
    return <ModalDisableUser {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'createdByHistory') {
    return <ModalCreatedByHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
}

export default AddEditModal;
