import React from 'react';
import {
  Form,
  Select,
  Radio,
  Checkbox,
  DatePicker,
  Input,
  Divider,
  notification,
  Menu,
  Dropdown,
  Icon,
  TimePicker,
} from 'antd';
import { observer } from 'mobx-react';
import { toJS, action } from 'mobx';
import moment from 'moment';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CONSTANTS, LOCAL_CONSTANT } from './type';
import store from './Store';
import { formatDate } from '@utils/functions';
import { getStaffRecentChangeHistory, sendAttendanceRemider } from './service';
import * as api from '../../../stores/api';
import { renderDate, commonInlineStyles } from '../Shared';
import customFetch from '@utils/customFetch';
import { timezoneList } from '@components/doctor-routes/AddEditMySessions/type';
const momentBusinessDays = require('moment-business-days');

const styles = {
  ...commonInlineStyles,
  highlightSpan10: { ...commonInlineStyles.highlightColor, marginLeft: 10 },
  highlightSpan39: { ...commonInlineStyles.highlightColor, marginLeft: 39 },
  highlightSpan58: { ...commonInlineStyles.highlightColor, marginLeft: 58 },
  radioBlock: { display: 'block' },
  radioBlockBottom: { display: 'block', marginBottom: 7 },
};

@observer
class AppointmentAttendanceAndCaseCancellation extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  renderAttendedDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        const date = formatDate(dateStr);
        return moment(date, 'DD MMM, YYYY');
      }
      return dateStr;
    }
    return null;
  };
  handleChangeReasonCancelled = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });
  handleChangeTextInput = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

    calcBusinessDays(firstDate, secondDate){
      //Initiallize variables
      var day1 = moment(firstDate);
      var day2 = moment(secondDate);
      var diffDays = day1.businessDiff(day2);
      var adjust = 0;
      var revertDate = 1;
      
      if((day1.dayOfYear() === day2.dayOfYear()) && (day1.year() === day2.year())){
        return 0;
      }
      
      // //Check if second date is before first date to switch
      if(day2.isBefore(day1)){
        day2 = moment(firstDate);
        day1 = moment(secondDate);
        revertDate = -1;
      }
    
      //Check if first date starts on weekends
      if(day1.day() === 6) { //Saturday
        //Move date to next week monday
        day1.day(8).hour(0).minute(0).second(0);
      } else if(day1.day() === 0) { //Sunday
        //Move date to current week monday
        day1.day(1).hour(0).minute(0).second(0);
      }
    
      //Check if second date starts on weekends
      if(day2.day() === 6) { //Saturday
        //Move date to current week friday
        day2.day(5).hour(0).minute(0).second(0);
      } else if(day2.day() === 0) { //Sunday
        //Move date to previous week friday
        day2.day(-2).hour(0).minute(0).second(0);
      }
    
      var day1Week = day1.week();
      var day2Week = day2.week();
    
      //Check if two dates are in different week of the year
      if(day1Week !== day2Week){
        //Check if second date's year is different from first date's year
        if (day2Week < day1Week){
          day2Week += day1Week;
        }
        //Calculate adjust value to be substracted from difference between two dates
        adjust = -2 * (day2Week - day1Week);
      }
      //return (day2.diff(day1, 'days') + adjust) * revertDate;
      var diffTime = day2.diff(day1, 'hours') + adjust;
      return diffTime;
      // return (diffTime /(1000 * 60 * 60 * 24));
    }

  acceptCancelledWithFee = () => {
    const itemModel = toJS(store.assessmentInfo);
    var typeOfReport = store.reportType?.Name || '';
    var appointmentDate = itemModel.DateFrom;
    if (itemModel.IsAppointmentConfirmed) {
      var currentTime = moment();
      var diffDays = this.calcBusinessDays(currentTime, appointmentDate);
      if (typeOfReport === 'VWA - Eligibility Claim') {
        if (diffDays < 24) {
          return true;
        }
      } else if (diffDays < 48) {
        return true;
      }
    }
    return false;
  };

  acceptCancelledNoFee = () => {
    var typeOfReport = store.reportType?.Name || '';
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.IsAppointmentConfirmed) {
      return true;
    }
    if (itemModel.IsAppointmentConfirmed) {
      var appointmentDate = itemModel.DateFrom;
      var currentTime = moment();
      var diffDays = this.calcBusinessDays(currentTime, appointmentDate);
      if (typeOfReport === 'VWA - Eligibility Claim') {
        if (diffDays > 24) {
          return true;
        }
      } else if (diffDays > 48) {
        return true;
      }
    }
    return false;
  };
  beforeAttendanceChanged = () => {
    const { submitData } = store;
    store.handleChangeValue('NonAttendedDate', null);
    store.handleChangeValue('CancelledDate', null);
    store.setFieldsValue({
      submitData: {
        ...submitData,
        notifyCmAboutDidNotAttendCase: false,
        notifyCmAboutAppointmentCancellation: false,
        sendDaysheetToDoctor: false,
      },
    });
  };
  checkIntepreterBeforeCancellingCase = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (itemModel.Interpreter && itemModel.Language && itemModel.InterpretationStatus === 0) {
      setTimeout(() => {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to cancel interpreter booking?',
          onOk: () => {
            store.handleChangeValue('InterpretationStatus', 1);
            store.handleChangeValue('InterpreterStatusCancelledDate', moment());
            store.handleChangeValue('InterpreterStatusReasonCancellation', 2);
          },
        })();
      });
    }
  };
  clearDictationAndReportInformation = () => {
    store.handleChangeValue('DictationReceived', false);
    store.handleChangeValue('DictationReceivedDate', null);
    store.handleChangeValue('ReportDraftReceived', false);
    store.handleChangeValue('ReportDraftReceivedDate', null);
    store.handleChangeValue('ReportCompletedDate', null);
    store.handleChangeValue('ReportCompleted', false);

    const assessmentInfo = toJS(store.assessmentInfo);
    const attachment = assessmentInfo.AssessmentAttachment
      ? assessmentInfo.AssessmentAttachment.map(i => {
          if (
            i.ReportType !== LOCAL_CONSTANT.ReportType_Dictation &&
            i.ReportType !== LOCAL_CONSTANT.ReportType_DictationInstruction &&
            i.ReportType !== LOCAL_CONSTANT.ReportType_Draft &&
            i.ReportType !== LOCAL_CONSTANT.ReportType_Completed &&
            i.ReportType !== LOCAL_CONSTANT.ReportType_AmendedReportCompleted &&
            i.ReportType !== LOCAL_CONSTANT.ReportType_ReportReturnedFromDoctor
          ) {
            return i;
          }
          return null;
        }).filter(i => i)
      : [];
    store.handleChangeValue('AssessmentAttachment', attachment);
  };
  handleCancelWithFee = oldValue => {
    var acceptCancel = this.acceptCancelledWithFee();
    if (!acceptCancel) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'This case should be Cancelled - No Fee. Do you want to continue with "Cancelled with Fee"?',
        onOk: () => {
          this.beforeAttendanceChanged();
          if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
            this.checkIntepreterBeforeCancellingCase();
          }
          const { submitData } = store;
          store.setFieldsValue({
            submitData: {
              ...submitData,
              notifyCmAboutAppointmentCancellation: true,
              sendDaysheetToDoctor: false,
            },
          });
          store.handleChangeValue('CancelledDate', moment());
          store.handleChangeValue('AttendedDate', null);
          store.handleChangeValue('ReasonCancellation', 1);
          this.clearDictationAndReportInformation();
          const { specialist, bookingSolicitor } = store;
          console.log(specialist);
          const assessmentInfo = toJS(store.assessmentInfo);
          if (assessmentInfo.IsAppointmentConfirmed) {
            store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
            if (!!bookingSolicitor?.Id) {
              store.handleChangeValue('NotifyCmSolicitorCancellation', true);
            }
            this.handleSendNotificationToSpecialist(specialist);
          }
        },
        onCancel: () => {
          store.handleChangeValue('Attendance', oldValue);
        },
      })();
    } else {
      this.beforeAttendanceChanged();
      if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
        this.checkIntepreterBeforeCancellingCase();
      }
      store.handleChangeValue('CancelledDate', moment());
      store.handleChangeValue('AttendedDate', null);
      store.handleChangeValue('ReasonCancellation', 1);
      const { submitData } = store;
      store.setFieldsValue({
        submitData: {
          ...submitData,
          notifyCmAboutAppointmentCancellation: true,
          sendDaysheetToDoctor: false,
        },
      });
      this.clearDictationAndReportInformation();
      const { specialist, bookingSolicitor } = store;
      const assessmentInfo = toJS(store.assessmentInfo);
      if (assessmentInfo.IsAppointmentConfirmed) {
        if (!!bookingSolicitor?.Id) {
          store.handleChangeValue('NotifyCmSolicitorCancellation', true);
        }
        store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
        this.handleSendNotificationToSpecialist(specialist);
      }
    }
  };

  handleCancelNoFee = oldValue => {
    const acceptCancel = this.acceptCancelledNoFee();
    if (!acceptCancel) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'This case should be Cancelled with Fee. Do you want to continue with "Cancelled - No Fee"?',
        onOk: () => {
          this.beforeAttendanceChanged();
          if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
            this.checkIntepreterBeforeCancellingCase();
          }

          const { submitData } = store;
          store.setFieldsValue({
            submitData: {
              ...submitData,
              notifyCmAboutAppointmentCancellation: true,
              sendDaysheetToDoctor: false,
            },
          });
          store.handleChangeValue('CancelledDate', moment());
          store.handleChangeValue('AttendedDate', null);
          store.handleChangeValue('ReasonCancellation', 1);
          this.clearDictationAndReportInformation();
          const { specialist, bookingSolicitor } = store;
          const assessmentInfo = toJS(store.assessmentInfo);
          if (assessmentInfo.IsAppointmentConfirmed) {
            store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
            if (!!bookingSolicitor?.Id) {
              store.handleChangeValue('NotifyCmSolicitorCancellation', true);
            }
            this.handleSendNotificationToSpecialist(specialist);
          }
        },
        onCancel: () => {
          store.handleChangeValue('Attendance', oldValue);
        },
      })();
    } else {
      this.beforeAttendanceChanged();
      if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
        this.checkIntepreterBeforeCancellingCase();
      }
      const { submitData } = store;
      store.setFieldsValue({
        submitData: {
          ...submitData,
          notifyCmAboutAppointmentCancellation: true,
          sendDaysheetToDoctor: false,
        },
      });
      store.handleChangeValue('CancelledDate', moment());
      store.handleChangeValue('AttendedDate', null);
      store.handleChangeValue('ReasonCancellation', 1);
      this.clearDictationAndReportInformation();
      const { specialist, bookingSolicitor } = store;
      const assessmentInfo = toJS(store.assessmentInfo);
      if (assessmentInfo.IsAppointmentConfirmed) {
        store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
        if (!!bookingSolicitor?.Id) {
          store.handleChangeValue('NotifyCmSolicitorCancellation', true);
        }
        this.handleSendNotificationToSpecialist(specialist);
      }
    }
  };

  handleCancelledSpecialistTBP = oldValue => {
    var acceptCancel = this.acceptCancelledWithFee();
    if (!acceptCancel) {
      this.beforeAttendanceChanged();
      // if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
      //   this.checkIntepreterBeforeCancellingCase();
      // }
      const { submitData } = store;
      store.setFieldsValue({
        submitData: {
          ...submitData,
          notifyCmAboutAppointmentCancellation: true,
          sendDaysheetToDoctor: false,
        },
      });
      store.handleChangeValue('CancelledDate', moment());
      store.handleChangeValue('AttendedDate', null);
      store.handleChangeValue('ReasonCancellation', 5);
      this.clearDictationAndReportInformation();
      // const { clinic, specialist } = store;
      // const assessmentInfo = toJS(store.assessmentInfo);
      // if (clinic && !clinic?.Name.includes('MAG ') && !!specialist?.Id && assessmentInfo.IsAppointmentConfirmed) {
      //   store.handleChangeValue('NotifyDoctorAppointment', true);
      //   store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
      // }
    } else {
      this.beforeAttendanceChanged();
      // if (oldValue !== CONSTANTS.Attendance_LCF && oldValue !== CONSTANTS.Attendance_Cancelled) {
      //   this.checkIntepreterBeforeCancellingCase();
      // }
      store.handleChangeValue('CancelledDate', moment());
      store.handleChangeValue('AttendedDate', null);
      store.handleChangeValue('ReasonCancellation', 5);
      const { submitData } = store;
      store.setFieldsValue({
        submitData: {
          ...submitData,
          notifyCmAboutAppointmentCancellation: true,
          sendDaysheetToDoctor: false,
        },
      });
      this.clearDictationAndReportInformation();
      //const { clinic, specialist } = store;
      //const assessmentInfo = toJS(store.assessmentInfo);
      // if (clinic && !clinic?.Name.includes('MAG ') && !!specialist?.Id && assessmentInfo.IsAppointmentConfirmed) {
      //   store.handleChangeValue('NotifyDoctorAppointment', true);
      //   store.handleChangeValue('NotifyCmAboutAppointmentCancellation', true);
      // }
    }
  };

  handleSendNotificationToSpecialist = specialist => {
    if (!!specialist?.Id) {
      if (specialist.SubscribeToCancellationReminders) {
        store.handleChangeValue('NotifyDoctorAppointment', true);
      } else {
        if (api.isAdminOrMagStaffOrAccounting()) {
          setTimeout(() => {
            store.toggleModal(true, {
              modalType: 'confirm',
              message:
                'This specialist does not want to receive appointment cancellation notice. Do you still want to send it?',
              onOk: () => {
                store.handleChangeValue('NotifyDoctorAppointment', true);
              },
              onCancel: () => {
                store.handleChangeValue('NotifyDoctorAppointment', false);
              },
            })();
          }, 500);
        } else {
          store.handleChangeValue('NotifyDoctorAppointment', true);
        }
      }
    }
  };

  handleChangeField = fieldName =>
    action(event => {
      //console.log(fieldName);
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'Attendance') {
        if (
          value === CONSTANTS.Attendance_Cancelled ||
          value === CONSTANTS.Attendance_LCF ||
          value === CONSTANTS.CancelledSpecialistTBP
        ) {
          var findARIncompleted =
            store.listActionRequired && store.listActionRequired.itemList
              ? store.listActionRequired.itemList.filter(i => !i.Completed)
              : 0;
          if (findARIncompleted.length > 0) {
            notification.destroy();
            notification.info({
              message: 'Action Required Reminder',
              description: 'Please check and clear pending required actions as needed.',
              duration: 15,
            });
          }
        }
      }
      if (value === CONSTANTS.Attendance_Attended) {
        this.beforeAttendanceChanged();
        store.handleChangeValue('NotifyCmSolicitorAboutDidNotAttendCase', false);
        store.handleChangeValue('NotifyCmAboutDidNotAttendCase', false);
        store.assessmentInfo.NotifyCmSolicitorCancellation = false;
        store.assessmentInfo.NotifyCmAboutAppointmentCancellation = false;
        store.assessmentInfo.NotifyCmSolicitorCancellation = false;
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          AttendedDate: itemModel.DateFrom,
          ReasonCancellation: null,
          CancelledDate: null,
          ActualDateFrom: itemModel.DateFrom,
          ActualDateTo: itemModel.DateTo,
          ActualTimeZone: itemModel.AppointmentTimeZone,
        });
      }
      if (value === CONSTANTS.Attendance_NotAttended) {
        store.assessmentInfo.NotifyCmSolicitorCancellation = false;
        store.assessmentInfo.NotifyCmAboutAppointmentCancellation = false;
        store.assessmentInfo.NotifyCmSolicitorCancellation = false;
        this.beforeAttendanceChanged();
        const { submitData } = store;
        store.setFieldsValue({
          submitData: {
            ...submitData,
          },
        });
        if (!!store.bookingSolicitor?.Id) {
          store.handleChangeValue('NotifyCmSolicitorAboutDidNotAttendCase', true);
        }
        if (!!store.bookingCaseManager?.Id) {
          store.handleChangeValue('NotifyCmAboutDidNotAttendCase', true);
        }
        store.handleChangeValue(fieldName, value);
        store.handleChangeValue('NonAttendedDate', moment());
        store.handleChangeValue('ReasonCancellation', null);
        store.handleChangeValue('CancelledDate', null);
        store.handleChangeValue('AttendedDate', null);
        return this.clearDictationAndReportInformation();
      }
      if (
        value === CONSTANTS.Attendance_Cancelled ||
        value === CONSTANTS.Attendance_LCF ||
        value === CONSTANTS.CancelledSpecialistTBP
      ) {
        store.handleChangeValue('NotifyCmSolicitorAboutDidNotAttendCase', false);
        store.handleChangeValue('NotifyCmAboutDidNotAttendCase', false);
        this.beforeAttendanceChanged();
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          CancelledDate: moment(),
          ReasonCancellation: 1,
          NotifyCancelDoctorAM: true,
        };
        if (value === CONSTANTS.Attendance_LCF) {
          this.handleCancelWithFee(itemModel.Attendance);
        }
        if (value === CONSTANTS.Attendance_Cancelled) {
          this.handleCancelNoFee(itemModel.Attendance);
        }
        if (value === CONSTANTS.CancelledSpecialistTBP) {
          this.handleCancelledSpecialistTBP(itemModel.Attendance);
        }
        store.assessmentInfo.NotifyCmAboutAppointmentCancellation = true;
        if (store.assessmentInfo.bookingSolicitor && itemModel.bookingSolicitor.Id > 0) {
          store.assessmentInfo.NotifyCmSolicitorCancellation = true;
        }
        if (store.assessmentInfo.specialist && itemModel.specialist.Id > 0) {
          store.assessmentInfo.NotifyCmSolicitorCancellation = true;
        }
        return;
      }
      if (value === CONSTANTS.Attendance_None) {
        var checkedSendAppointmentCM = itemModel.NotifyCmAboutAppointmentCancellation;
        var checkedSendAppointmentSpec = itemModel.NotifyDoctorAppointment;
        if (!itemModel.SentDateNotifyBookingCMAppointmentCancellation) {
          checkedSendAppointmentCM = false;
        }
        if (!itemModel.SentDateNotifyBookingCMAppointmentCancellation) {
          checkedSendAppointmentSpec = false;
        }
        this.beforeAttendanceChanged();
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          CancelledDate: null,
          NonAttendedDate: null,
          AttendedDate: null,
          NotifyCmAboutAppointmentCancellation: checkedSendAppointmentCM,
          NotifyDoctorAppointment: checkedSendAppointmentSpec,
        });
      }
    });

  handleChangeDatePicker = fieldName =>
    action(event => {
      return (store.assessmentInfo[fieldName] = event);
    });

  handleChecked = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'NotifyDoctorAppointment' && value) {
        const { specialist } = store;
        //console.log(specialist);
        this.handleSendNotificationToSpecialist(specialist);
        return;
      }
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      return;
    });

  handleRecentChange = async (record, actionName, columns, fieldNameStaffRecentChange) => {
    try {
      const response = await getStaffRecentChangeHistory(record.Id, actionName);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };
  @action handleChangeObj = (_, event, fieldName) => {
    const { data } = event.props;
    const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
    if (fieldName === 'PIC_4') {
      return (store[fieldName] = {
        picId: data_.Id,
        picName: data_.FullName,
        inChargeOf: 3,
      });
    }
    return (store[fieldName] = data_);
  };
  handleRenderStaffName = id => {
    const staffs = toJS(store.listStaff);
    if (id && staffs.itemList && staffs.itemList.length) {
      const existStaff = staffs.itemList.find(i => i.Id === id);
      if (existStaff) {
        return existStaff.FullName;
      } else {
        const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
        return nameIfCM ? nameIfCM : id;
      }
    }
    return '';
  };
  handleAttendanceRemider = id => async () => {
    const itemModel = toJS(store.assessmentInfo);
    try {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to remind specialist to update claimant atttendance?',
        onOk: () => {
          sendAttendanceRemider(id).then(res => {
            if (res.status === 'success') {
              var sentDate = res.sentDate;
              var sentBy = res.sentBy;
              store.setFieldsValue({
                assessmentInfo: {
                  ...itemModel,
                  SendAttendanceStatusReminderDate: moment(),
                  SendAttendanceStatusReminderBy: sentBy,
                },
              });
              notification.destroy();
              notification.success({
                message: 'Success',
                description: `Attendance reminder was sent`,
                duration: 2,
              });
            } else {
              notification.destroy();
              notification.error({
                message: 'Fail',
                description: `Attendance reminder can't be sent`,
                duration: 2,
              });
            }
          });
        },
      })();
    } catch (e) {}
  };

  handleResendCancellationConfirmation = (sendtype = 1) => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend cancellation confirmation?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendCancellationConfirmation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, sendtype }),
        })
          .then(res => {
            if (res.status === 'success') {
              if (sendtype === 2) {
                store.handleChangeValue('SentDateNotifyDoctorAppointmentCancellation', moment());
                store.handleChangeValue('SentByNotifyDoctorAppointmentCancellation', res.sentBy);
              } else if (sendtype === 3) {
                store.handleChangeValue('SentDateNotifyBookingCMSolicitorCancellation', moment());
                store.handleChangeValue('SentByNotifyBookingCMSolicitorCancellation', res.sentBy);
              } else {
                store.handleChangeValue('SentDateNotifyBookingCMAppointmentCancellation', moment());
                store.handleChangeValue('SentByNotifyBookingCMSolicitorCancellation', res.sentBy);
              }

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend cancellation confirmation',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend cancellation confirmation',
            });
          });
      },
    })();
  };

  handleResendNonAttendanceConfirmation = (sendtype = 1) => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend non-attendance confirmation?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendNonAttendanceConfirmation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, sendtype }),
        })
          .then(res => {
            if (res.status === 'success') {
              if (sendtype === 1) {
                store.handleChangeValue('SentDateNotifyCmSolicitorAboutDidNotAttendCase', moment());
                store.handleChangeValue('SentByNotifyCmSolicitorAboutDidNotAttendCase', res.sentBy);
              } else {
                store.handleChangeValue('SentDateNotifyCmAboutDidNotAttendCase', moment());
                store.handleChangeValue('SentByNotifyCmAboutDidNotAttendCase', res.sentBy);
              }

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend non-attendance confirmation',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend non-attendance confirmation',
            });
          });
      },
    })();
  };

  handleChangeRangePicker = fieldName =>
    action(event => {
      //console.log(133);
      const itemModel = toJS(store.assessmentInfo);
      const hourList = store.generateTimeRange(24);
      const minuteList = store.generateTimeRange(60);
      if (fieldName === 'ActualDateFrom') {
        const dateTo = event ? moment(event).add(30, 'minutes') : null;
        store.assessmentInfo = {
          ...itemModel,
          AttendedDate: event,
          ActualDateFrom: event,
          ActualDateTo: dateTo,
        };
        return this.ChangeAssDate();
      } else if (fieldName === 'ActualDateFromHour') {
        const dateFrom = event;
        store.assessmentInfo = {
          ...itemModel,
          AttendedDate: event,
          ActualDateFrom: event,
          ActualDateFromHour: hourList[dateFrom.hour()],
          ActualDateFromMinute: minuteList[dateFrom.minutes()],
        };
        //this.checkNotifyCMAppointment();
        return this.ChangeAssDate();
      } else if (fieldName === 'ActualDateToHour') {
        const dateTo = event;
        store.assessmentInfo = {
          ...itemModel,
          ActualDateTo: event,
          ActualDateToHour: hourList[dateTo.hour()],
          ActualDateToMinute: minuteList[dateTo.minutes()],
        };
        //this.checkNotifyCMAppointment();
        return this.ChangeAssDate();
      } else {
        return this.ChangeAssDate();
      }
    });

  @action ChangeAssDate = () => {
    const itemModel = toJS(store.assessmentInfo);
    const hourList = store.generateTimeRange(24);
    const minuteList = store.generateTimeRange(60);
    //chay lai moi lan doi appoint date
    if (itemModel.ActualDateFrom) {
      if (!(itemModel.ActualDateFromHour && itemModel.ActualDateFromHour.value > 0)) {
        this.handleChangeValue('ActualDateFromHour', hourList[9]);
        this.handleChangeValue('ActualDateFromMinute', minuteList[0]);
        this.handleChangeValue('ActualDateToHour', hourList[9]);
        this.handleChangeValue('ActualDateToMinute', minuteList[0]);
      }
    }
  };

  @action handleChangeValue = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  render() {
    const itemModel = toJS(store.assessmentInfo);
    const staffs = toJS(store.listStaff);
    const pic_4 = toJS(store.PIC_4);
    const columsStaffRecentChange = [
      { title: 'Case Manager' },
      { title: 'Old Assigned Staff' },
      { title: 'Current Assigned Staff' },
      { title: 'Assigned By' },
      { title: 'Assigned Date' },
    ];
    const { lockTypist, oldCancell } = store.handleLockReportCompleteCM(itemModel);
    const fieldNameStaffRecentChange = [
      'CaseManagerFullName',
      'OldAssigneeFullName',
      'NewAssigneeFullName',
      'CreatedUserFullName',
      'CreatedDate',
    ];
    const isOverAppointmentDate = moment().isAfter(itemModel.DateFrom, 'date');
    return (
      <Form name="appointment-attendance-and-case-cancellation">
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 4
                <button
                  className="field-actions"
                  onClick={() =>
                    this.handleRecentChange(
                      itemModel,
                      'Status_Attended',
                      columsStaffRecentChange,
                      fieldNameStaffRecentChange,
                    )
                  }
                >
                  Recent changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              size="large"
              value={pic_4?.picId}
              showSearch
              optionFilterProp="children"
              onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_4')}
            >
              {staffs.itemList &&
                staffs.itemList.length &&
                staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          className="checkbox-field"
          label={
            <React.Fragment>
              Attendance Status
              {api.isAdminOrMagStaffOrAccounting() && itemModel.Id ? (
                <Link to="#" onClick={this.handleAttendanceRemider(itemModel.Id)}>
                  &nbsp;&nbsp;&nbsp;Send Attendance Update Reminder{' '}
                  {this.renderPopup('Send attendance reminder to specialist (MAIL-09)')}
                </Link>
              ) : (
                ''
              )}
              {api.isAdminOrMagStaffOrAccounting() && itemModel.SendAttendanceStatusReminderDate && (
                <span>
                  &nbsp;&nbsp;&nbsp;
                  {this.renderAttendedDate(itemModel.SendAttendanceStatusReminderDate).format('DD/MM/YYYY - HH:MM')} -
                  by {itemModel.SendAttendanceStatusReminderBy}
                </span>
              )}
            </React.Fragment>
          }
        >
          <Radio.Group value={itemModel.Attendance} onChange={this.handleChangeField('Attendance')}>
            <Radio
              value={CONSTANTS.Attendance_None}
              disabled={
                oldCancell ||
                (!itemModel.IsAllowCMCancellnoFee && !itemModel.IsAllowCMCancellwithFee) ||
                lockTypist ||
                (api.isCaseManager() && itemModel.IsCancelled) ||
                (api.isDoctor && itemModel.IsCancelled)
              }
            >
              None
            </Radio>
            <Radio
              disabled={
                (itemModel.isLocked && !api.isDoctor()) ||
                itemModel.Appointments.length ||
                (api.isDoctor && itemModel.IsCancelled)
              }
              value={CONSTANTS.Attendance_Attended}
            >
              Attended
            </Radio>
            <Radio
              value={CONSTANTS.Attendance_NotAttended}
              disabled={
                (itemModel.isLocked && !api.isDoctor()) ||
                itemModel.Appointments.length ||
                (api.isDoctor && itemModel.IsCancelled)
              }
            >
              Did not attend
            </Radio>
            <Radio
              value={CONSTANTS.Attendance_Cancelled}
              disabled={
                !itemModel.IsAllowCMCancellnoFee ||
                api.isDoctor() ||
                lockTypist ||
                (api.isCaseManager() && itemModel.IsCancelled)
              }
            >
              Cancelled – No Fee{' '}
              {this.renderPopup('No fee applied when appointments are cancelled more than 48 hours prior')}
            </Radio>
            <Radio
              value={CONSTANTS.Attendance_LCF}
              disabled={
                !itemModel.IsAllowCMCancellwithFee ||
                api.isDoctor() ||
                lockTypist ||
                (api.isCaseManager() && isOverAppointmentDate)
              }
            >
              Cancelled with Fee{' '}
              {this.renderPopup(
                'Fees applied when appointments are cancelled less than 48 business hours prior EXCEPT in the case of report type of VWA-Eligibility Claim in which a cancellation fee is charged if cancelled  less than 24 hours prior.',
              )}
            </Radio>
            <Radio
              value={CONSTANTS.CancelledSpecialistTBP}
              disabled={
                itemModel.isLocked ||
                api.isDoctor() ||
                lockTypist ||
                !api.isAdminOrMagStaffOrAccounting() ||
                (api.isCaseManager() && isOverAppointmentDate)
              }
            >
              Cancelled – Specialist TBP{' '}
              {this.renderPopup('Appointment cancelled due to operator fault, specialist to be paid')}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {(itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
          itemModel.Attendance === CONSTANTS.Attendance_LCF ||
          itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP) && (
          <>
            <Form.Item label="Date of Cancellation">
              <DatePicker
                size="large"
                value={this.renderAttendedDate(itemModel.CancelledDate)}
                disabled={itemModel.isLocked}
                format="DD/MM/YYYY"
                onChange={this.handleChangeDatePicker('CancelledDate')}
              />
            </Form.Item>
            <Form.Item label="Reasons">
              <Radio.Group
                disabled={itemModel.isLocked || itemModel.Attendance === CONSTANTS.Attendance_LCF || api.isDoctor()}
                value={itemModel.ReasonCancellation}
                onChange={
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled
                    ? this.handleChangeReasonCancelled('ReasonCancellation')
                    : this.handleChangeField('ReasonCancellation')
                }
              >
                <Radio value={1} style={styles.radioBlockBottom}>
                  The request of the Case Manager
                </Radio>
                <Radio value={2} style={styles.radioBlockBottom}>
                  Specialist being unable to undertake assessment
                </Radio>
                <Radio value={3} style={styles.radioBlockBottom}>
                  Specialist being unable to undertake assessment (new appointment details to follow)
                </Radio>
                <Radio value={5} style={styles.radioBlockBottom}>
                  Medicolegal Provider At-Fault
                </Radio>
                <Radio value={4} style={styles.radioBlock}>
                  Late confirmation from booking CM (over 10 days)
                  {this.renderPopup('Auto reminders to confirm appointment will be sent to booking CM (MAIL-1B)')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {itemModel.Attendance === CONSTANTS.Attendance_Attended && !(api.isCaseManager() && !itemModel.Id) && (
          <>
            <Form.Item
              label="Actual Appointment Date/Time"
              required={itemModel.Attendance === CONSTANTS.Attendance_Attended}
              validateStatus={
                itemModel.Attendance === CONSTANTS.Attendance_Attended && !itemModel.ActualDateFrom ? 'error' : null
              }
              help={
                itemModel.Attendance === CONSTANTS.Attendance_Attended && !itemModel.ActualDateFrom
                  ? 'This field is required'
                  : ''
              }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={renderDate(itemModel.ActualDateFrom, true, false)}
                disabled={api.isTypist()}
                //disabled={itemModel.isLocked || !!itemModel.SessionId || itemModel.IsCancelled}
                onChange={this.handleChangeRangePicker('ActualDateFrom')}
                style={{ width: '175px' }}
              />
              <TimePicker
                format="HH:mm"
                showTime
                placeholder="Start Time"
                size="large"
                disabled={api.isTypist()}
                //disabled={itemModel.isLocked || !!itemModel.SessionId || itemModel.IsCancelled}
                allowClear={false}
                style={{ marginLeft: '10px', width: '125px' }}
                value={store.getMomentDateTime(itemModel.ActualDateFrom, itemModel.ActualTimeZone)}
                onChange={this.handleChangeRangePicker('ActualDateFromHour')}
              />
              <TimePicker
                value={store.getMomentDateTime(itemModel.ActualDateTo, itemModel.ActualTimeZone)}
                disabled={api.isTypist()}
                //disabled={itemModel.isLocked || itemModel.SessionId > 0 || itemModel.IsCancelled}
                onChange={this.handleChangeRangePicker('ActualDateToHour')}
                size="large"
                placeholder="End Time"
                showTime
                format="HH:mm"
                style={{ marginLeft: '10px', width: '125px' }}
                allowClear={false}
              />
              <Select
                showSearch
                size="large"
                disabled={itemModel.isLocked || itemModel.IsCancelled}
                optionFilterProp="children"
                value={itemModel.ActualTimeZone}
                style={{ width: '200px', marginLeft: '10px' }}
                onChange={event => {
                  store.handleChangeValue('ActualTimeZone', event);
                  // store.setFieldsValue({ timeZone: event });
                }}
              >
                {timezoneList.map(i => (
                  <Select.Option key={i} value={i}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        {itemModel.Attendance === CONSTANTS.Attendance_Attended && (
          <React.Fragment>
            <Divider className="fluid-field" />
            {/* <h3 className="form-subtitle fluid-field">Attended</h3> */}
            <div className="fluid-field checkbox-field">
              <Checkbox
                disabled={itemModel.isLocked}
                checked={itemModel.AutoRemindReportCompletionDeadlineToDoctor}
                onChange={this.handleChecked('AutoRemindReportCompletionDeadlineToDoctor')}
              >
                Send Report Due Date Notice to Specialist{' '}
                {api.isAdminOrMagStaffOrAccounting() &&
                  this.renderPopup('Completing report reminder will be sent to specialist (MAIL-12, DIRECT-12)')}
              </Checkbox>
            </div>
            {/* <Form.Item label="Date of Attendance" required>
              <DatePicker
                size="large"
                disabled={true}
                value={this.renderAttendedDate(itemModel.AttendedDate)}
                onChange={this.handleChangeDatePicker('AttendedDate')}
                format="DD/MM/YYYY"
              />
            </Form.Item> */}
          </React.Fragment>
        )}
        {itemModel.Attendance === CONSTANTS.Attendance_NotAttended && (
          <React.Fragment>
            <Divider className="fluid-field" />
            <Form.Item className="fluid-field cancellation-label" label="Send Non-Attendance Confirmation to">
              <Checkbox
                checked={itemModel.NotifyCmSolicitorAboutDidNotAttendCase}
                onChange={this.handleChecked('NotifyCmSolicitorAboutDidNotAttendCase')}
                style={styles.checkboxMargin}
                disabled={
                  itemModel.isLocked ||
                  !!!store.solicitor?.Id ||
                  itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase
                }
              >
                Booking Solicitor{' '}
                {this.renderPopup('Non-attendance notice will be sent to booking Solicitor (MAIL-1C, DIRECT-1C)')}
                {!!itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase && (
                  <>
                    <span style={styles.highlightSpan10}>
                      Sent
                      {itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase
                        ? renderDate(itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightSpan10}>
                      - By
                      {this.handleRenderStaffName(itemModel.SentByNotifyCmSolicitorAboutDidNotAttendCase)}
                    </span>
                  </>
                )}
              </Checkbox>
              <br />
              <Checkbox
                checked={itemModel.NotifyCmAboutDidNotAttendCase}
                disabled={itemModel.isLocked || itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase}
                onChange={this.handleChecked('NotifyCmAboutDidNotAttendCase')}
              >
                Booking CM {this.renderPopup('Non-attendance notice will be sent to CMs (MAIL-1C, DIRECT-1C)')}
                {!!itemModel.SentDateNotifyCmAboutDidNotAttendCase && (
                  <>
                    <span style={styles.highlightSpan39}>
                      Sent
                      {itemModel.SentDateNotifyCmAboutDidNotAttendCase
                        ? renderDate(itemModel.SentDateNotifyCmAboutDidNotAttendCase, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightSpan10}>
                      - By {this.handleRenderStaffName(itemModel.SentByNotifyCmAboutDidNotAttendCase)}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item className="fluid-field">
              <Dropdown
                disabled={
                  (!itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase &&
                    !itemModel.SentDateNotifyCmAboutDidNotAttendCase) ||
                  lockTypist
                }
                overlay={
                  <Menu>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyCmSolicitorAboutDidNotAttendCase}
                      onClick={() => this.handleResendNonAttendanceConfirmation(1)}
                    >
                      Resend to Booking Solicitor{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyCmAboutDidNotAttendCase}
                      onClick={() => this.handleResendNonAttendanceConfirmation(2)}
                    >
                      Resend to Booking CM{' '}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className="blue">
                  Resend Non-Attendance Confirmation <Icon type="down" />
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item label="Date of Non-Attendance">
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                disabled={itemModel.isLocked}
                value={this.renderAttendedDate(itemModel.NonAttendedDate)}
                onChange={this.handleChangeDatePicker('NonAttendedDate')}
              />
            </Form.Item>
            <Form.Item className="fluid-field">
              <Checkbox
                checked={itemModel.SendReschedulingReminder}
                disabled={itemModel.isLocked || itemModel.SendReschedulingReminderDate}
                onChange={this.handleChecked('SendReschedulingReminder')}
              >
                Send Rescheduling Reminders{' '}
                {this.renderPopup('Rescheduling reminders will be sent to staff (MAIL-148) and booking CM (MAIL-149)')}
                {!!itemModel.SendReschedulingReminderDate && (
                  <>
                    <span style={styles.highlightSpan39}>
                      Sent
                      {itemModel.SendReschedulingReminderDate
                        ? renderDate(itemModel.SendReschedulingReminderDate, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightSpan10}>
                      - By {this.handleRenderStaffName(itemModel.SendReschedulingReminderBy)}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
          </React.Fragment>
        )}
        {(itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
          itemModel.Attendance === CONSTANTS.Attendance_CancelledSpecialistTBP ||
          itemModel.Attendance === CONSTANTS.Attendance_LCF) && (
          <React.Fragment>
            <Divider className="fluid-field" />
            <Form.Item className="fluid-field cancellation-label" label="Send Appointment Cancellation Notice to">
              <Checkbox
                style={styles.checkboxMargin}
                checked={itemModel.NotifyCmSolicitorCancellation}
                disabled={
                  (!itemModel.IsAllowCMCancellnoFee && !itemModel.IsAllowCMCancellwithFee) ||
                  !store.bookingSolicitor?.Id ||
                  !!itemModel.SentDateNotifyBookingCMSolicitorCancellation
                }
                onChange={this.handleChecked('NotifyCmSolicitorCancellation')}
              >
                Booking Solicitor{' '}
                {this.renderPopup('Appointment cancellation notice will be sent to Solicitor (MAIL-01, DIRECT-01)')}
                {!!itemModel.SentDateNotifyBookingCMSolicitorCancellation && (
                  <>
                    <span style={styles.highlightSpan10}>
                      Sent{' '}
                      {itemModel.SentDateNotifyBookingCMSolicitorCancellation
                        ? renderDate(itemModel.SentDateNotifyBookingCMSolicitorCancellation, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightColor}>
                      {' '}
                      - By {this.handleRenderStaffName(itemModel.SentByNotifyBookingCMSolicitorCancellation)}
                    </span>
                  </>
                )}
              </Checkbox>
              <br />
              <Checkbox
                style={styles.checkboxBottom}
                checked={itemModel.NotifyCmAboutAppointmentCancellation}
                onChange={this.handleChecked('NotifyCmAboutAppointmentCancellation')}
                disabled={
                  (!itemModel.IsAllowCMCancellnoFee && !itemModel.IsAllowCMCancellwithFee) ||
                  itemModel.SentDateNotifyBookingCMAppointmentCancellation
                }
              >
                Booking CM{' '}
                {this.renderPopup('Appointment cancellation notice will be sent to CMs (MAIL-01, DIRECT-01)')}
                {!!itemModel.SentDateNotifyBookingCMAppointmentCancellation && (
                  <>
                    <span style={styles.highlightSpan39}>
                      Sent{' '}
                      {itemModel.SentDateNotifyBookingCMAppointmentCancellation
                        ? renderDate(itemModel.SentDateNotifyBookingCMAppointmentCancellation, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightColor}>
                      {' '}
                      - By {this.handleRenderStaffName(itemModel.SentByNotifyBookingCMSolicitorCancellation)}
                    </span>
                  </>
                )}
              </Checkbox>
              <br />
              <Checkbox style={styles.checkboxBottom}
                checked={itemModel.NotifyDoctorAppointment}
                onChange={this.handleChecked('NotifyDoctorAppointment')}
                disabled={
                  //(!itemModel.IsAllowCMCancellnoFee && !itemModel.IsAllowCMCancellwithFee) ||
                  !store.specialist?.Id ||
                  itemModel.SentDateNotifyDoctorAppointmentCancellation ||
                  !api.isAdminOrMagStaffOrAccounting()
                }
              >
                Specialist{' '}
                {this.renderPopup('Appointment cancellation notice will be sent to Specialist (MAIL-81 for confirmed appointment, MAIL-81A for appointment inquiry)')}
                {!!itemModel.SentDateNotifyDoctorAppointmentCancellation && (
                  <>
                    <span style={styles.highlightSpan58}>
                      Sent{' '}
                      {itemModel.SentDateNotifyDoctorAppointmentCancellation
                        ? renderDate(itemModel.SentDateNotifyDoctorAppointmentCancellation, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightColor}>
                      {' '}
                      - By {this.handleRenderStaffName(itemModel.SentByNotifyDoctorAppointmentCancellation)}
                    </span>
                  </>
                )}
              </Checkbox>
              <br/>
              <Checkbox
                checked={itemModel.NotifyCancelDoctorAM}
                onChange={this.handleChecked('NotifyCancelDoctorAM')}
                disabled={
                  //(!itemModel.IsAllowCMCancellnoFee && !itemModel.IsAllowCMCancellwithFee) ||
                  !store.specialist?.Id ||
                  itemModel.SentDateNotifyDoctorAppointmentCancellation ||
                  !api.isAdminOrMagStaffOrAccounting()
                }
              >
                Specialist AM{' '}
                {this.renderPopup('Appointment cancellation notice will be sent to Specialist AM (MAIL-173)')}
                {!!itemModel.SentNotifyCancelDoctorAMDate && (
                  <>
                    <span style={styles.highlightSpan58}>
                      Sent{' '}
                      {itemModel.SentNotifyCancelDoctorAMDate
                        ? renderDate(itemModel.SentNotifyCancelDoctorAMDate, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightColor}>
                      {' '}
                      - By {this.handleRenderStaffName(itemModel.SentNotifyCancelDoctorAMBy)}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item className="fluid-field">
              <Dropdown
                disabled={
                  (!itemModel.SentDateNotifyBookingCMSolicitorCancellation &&
                    !itemModel.SentDateNotifyBookingCMAppointmentCancellation &&
                    !itemModel.SentDateNotifyDoctorAppointmentCancellation) ||
                  lockTypist ||
                  api.isDoctor()
                }
                overlay={
                  <Menu>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyBookingCMSolicitorCancellation}
                      onClick={() => this.handleResendCancellationConfirmation(3)}
                    >
                      Resend to Booking Solicitor{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyBookingCMAppointmentCancellation}
                      onClick={() => this.handleResendCancellationConfirmation(1)}
                    >
                      Resend to Booking CM{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyDoctorAppointmentCancellation}
                      onClick={() => this.handleResendCancellationConfirmation(2)}
                    >
                      Resend to Specialist{' '}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className="blue">
                  Resend Cancellation Confirmation <Icon type="down" />
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item className="fluid-field">
              <Checkbox
                checked={itemModel.SendReschedulingReminder}
                disabled={itemModel.isLocked || itemModel.SendReschedulingReminderDate}
                onChange={this.handleChecked('SendReschedulingReminder')}
              >
                Send Rescheduling Reminders{' '}
                {this.renderPopup('Rescheduling reminders will be sent to staff (MAIL-148) and booking CM (MAIL-149)')}
                {!!itemModel.SendReschedulingReminderDate && (
                  <>
                    <span style={styles.highlightSpan39}>
                      Sent
                      {itemModel.SendReschedulingReminderDate
                        ? renderDate(itemModel.SendReschedulingReminderDate, false, true)
                        : ''}
                    </span>
                    <span style={styles.highlightSpan10}>
                      - By {this.handleRenderStaffName(itemModel.SendReschedulingReminderBy)}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
          </React.Fragment>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <React.Fragment>
            <Divider className="fluid-field" />
            <Form.Item label="Comments" className="fluid-field">
              <Input.TextArea
                rows={3}
                value={itemModel.AttendanceComment}
                onChange={this.handleChangeTextInput('AttendanceComment')}
              />
            </Form.Item>
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default AppointmentAttendanceAndCaseCancellation;
