import customFetch from '../../../utils/customFetch';

export const getAMACalculationRule = (
  keyword = '',
  curPage = 1,
  numItemPerPage = 0,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/AMACalculationRule/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getAMACalculationRuleInfo = (id = 0) => {
  return customFetch(`/AMACalculationRule/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveAMACalculationRule = param => {
  return customFetch('/AMACalculationRule/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteAMACalculationRule = id => {
  return customFetch('/AMACalculationRule/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchAMAFeeCode = keyword => {
  return customFetch('/MAGService/SearchAMACode?keyword=' + keyword, {
    method: 'GET',
  });
};

export const searchAMACalculationRule = param => {
  return customFetch('/AMACalculationRule/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const addAMACalculationRule = param => {
  return customFetch('/MAGService/SaveAMACalculationRule', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const viewAMAUpdateHistory = id => {
  return customFetch('/AMACalculationRule/GetAMAUpdateHistory?id=' + id, {
    method: 'GET',
  });
};
