import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';

import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import Store from './Store';

const statusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
];

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: Store.modalParams.record
        ? {
            ...Store.modalParams.record,
            doctorId: Store.modalParams.record.DoctorId || '',
            isActive: Store.modalParams.record.IsActive,
          }
        : {
            doctorId: 0,
            isActive: true,
          },
    };
  }

  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  renderSpeFilterOptions = () => {
    return Store.doctors.map(opt => ({
      text: opt.FullName,
      value: opt.Id,
    }));
  };

  renderSpeFilterText = (id, doctor) => {
    let findDoctor = Store.doctors.find(i => i.Id === id);
    if (!findDoctor) {
      Store.setFieldsValue({ doctors: [...Store.doctors, doctor] });
    }
    return id;
  };
  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };

  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    if (!body.label) {
    }
    const param = {
      DoctorId: body.doctorId,
      IsActive: body.isActive,
    };
    Store.setFieldsValue({ loading: true });
    try {
      await customFetch('/ExclusionDoctor/Save', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Id: id,
          ...param,
        }),
      });
      await Store.refetch({}, true);
    } catch (e) {
    } finally {
      Store.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const { loading } = Store;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`${id === 0 ? `Create New` : `Exclusion ${body.Doctor.FullName}`}`}
        footer={
          <React.Fragment>
            <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div style={{ height: '350px' }}>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label="Specialist"
                  placeholder="Enter at least 3 letters to search specialist"
                  search
                  selection
                  clearable
                  name="doctorId"
                  onSearchChange={Store.fetchSearchSepecialistStatus}
                  options={this.renderSpeFilterOptions()}
                  value={this.state.body.doctorId}
                  onChange={this.handleChangeDropdown}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Select
                  label="Status"
                  options={this.renderStatusLabel()}
                  name="isActive"
                  onChange={this.handleChangeDropdown}
                  value={body.isActive}
                />
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
