import './index.scss';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import ClientReportStore from './ClientReportStore';
import ClientReportToolbar from './ClientReportToolbar';
import ClientReportModal from './ClientReportModal';
import ClientReportTable from './ClientReportTable';
import Toolbar from './Toolbar';

class ClientReport extends React.Component {
  componentDidMount() {
    ClientReportStore.fetchClientSearch();
    ClientReportStore.fetchCMSearch();
  }

  render() {
    const {
      dataClientSearch,
      loadingClientSearch,
      loadingCM,
      dataCM,
      open,
      dataArrayFR,
      tabIsActive,
      dataArrayIME,
      dataArraySupp,
      dataArrayCR,
      dataArrayMN,
    } = ClientReportStore;
    return (
      <div
        className={`Table-${
          (loadingClientSearch && !dataClientSearch) || (loadingCM && !dataCM) ? 'loading' : 'container'
        }`}
      >
        {(loadingClientSearch && !dataClientSearch) || (loadingCM && !dataCM) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            <ClientReportToolbar />
            {tabIsActive === 'ime' && (
              <React.Fragment>
                {dataArrayIME && (
                  <div>
                    {dataArrayIME.map(([key, value]) => (
                      <div key={key}>{value && <ClientReportTable dataTable={value} colDate={key} />}</div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}
            {tabIsActive === 'supp' && (
              <React.Fragment>
                {dataArraySupp && (
                  <div>
                    {dataArraySupp.map(([key, value]) => (
                      <div key={key}>{value && <ClientReportTable dataTable={value} colDate={key} />}</div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}
            {tabIsActive === 'fr' && (
              <React.Fragment>
                {dataArrayFR && (
                  <div>
                    {dataArrayFR.map(([key, value]) => (
                      <div key={key}>{value && <ClientReportTable dataTable={value} colDate={key} />}</div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}
            {tabIsActive === 'cr' && (
              <React.Fragment>
                {dataArrayCR && (
                  <div>
                    {dataArrayCR.map(([key, value]) => (
                      <div key={key}>{value && <ClientReportTable dataTable={value} colDate={key} />}</div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}
            {tabIsActive === 'mn' && (
              <React.Fragment>
                {dataArrayMN && (
                  <div>
                    {dataArrayMN.map(([key, value]) => (
                      <div key={key}>{value && <ClientReportTable dataTable={value} colDate={key} />}</div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}
            {open && <ClientReportModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(ClientReport);
