import './index.scss';
import cx from 'classnames';
import React from 'react';
import { Input, Button, Icon } from 'semantic-ui-react';
import { action } from 'mobx';
import TableToolbar from '../../shared/tableToolbar';
import store from './store';
import { observer } from 'mobx-react';

@observer
class ChatGPT extends React.Component {
  chatMessagesRef = React.createRef();

  componentDidUpdate() {
    this.chatMessagesRef.current.scrollTop = this.chatMessagesRef.current.scrollHeight;
  }

  @action componentDidMount() {
    const messageGPT = localStorage.getItem(`ChatMessageGPT`);
    if (messageGPT) {
      store.messages = JSON.parse(messageGPT);
    }
  }

  handleSubmit = event => {
    event && event.preventDefault();
    if (store.keyword) {
      store.onSearchChange(store.keyword).then(action(() => {}));
    }
  };

  render() {
    const { loading } = store;

    return (
      <div className={cx('Documents-search-page mb-1', { loading })}>
        <div className="chat-box">
          <div className="chat-header">
            <h3>Chat</h3>
          </div>
          <div className="chat-messages" ref={this.chatMessagesRef}>
            {store.messages.map((message, index) => (
              <div className={`chat-message-container ${message.type === 'user' ? 'user-message' : ''}`} key={index}>
                <div className="chat-message-content">
                  {message.type === 'user' ? (
                    <p>{message.text}</p>
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: message.text }}></p>
                  )}
                </div>
                <div className="chat-message-info">
                  <span>{message.type === 'user' ? 'You' : 'Kawaconn'}</span>
                  <span>{message.sentDate}</span>
                </div>
              </div>
            ))}
          </div>
          <form className="chat-input-container" onSubmit={this.handleSubmit}>
            <div className="inline">
              <span title='Put in "" for exact words'>
                <Input
                  fluid
                  value={store.keyword}
                  onChange={store.handleSearchChange}
                  loading={loading}
                  disabled={loading}
                  className="search-input"
                />
              </span>

              <Button
                disabled={loading}
                style={{ marginLeft: '5px' }}
                type="button"
                color="blue"
                icon="search"
                labelPosition="right"
                content="Send"
                onClick={this.handleSubmit}
              />
              <Button
                style={{ marginLeft: '5px' }}
                color="red"
                labelPosition="right"
                icon
                type="button"
                onClick={store.resetStore}
              >
                <span>CLEAR</span>
                <Icon name="close" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default observer(ChatGPT);
