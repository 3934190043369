import { action, observable } from 'mobx';
import customFetch from '../../../utils/customFetch';

class StoreCM {
  @observable loading = true;
  @observable data = null;

  @observable keyword = '';
  @observable searchStaffOnly = false;
  @observable StaffId = 0;
  @observable fromTo = '';
  @observable pendingAC = true;

  @observable sortColumn = 'Due Date';
  @observable sortKey = 'DueDate';
  @observable sortDirection = 'descending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable data2 = null;
  @observable curPage2 = 1;
  @observable totalPage2 = 0;
  @observable totalItems2 = 0;

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action onSearchChange = keyword => {
    this.keyword = keyword;
    return this.fetchFiles();
  };

  @action handleDateChange = (_, { value }) => {
    this.fromTo = value;
  };

  getDateRange = () => {
    const [from, to] = this.fromTo.split(' - ');
    const [fromDay, fromMonth, fromYear] = from ? from.split('-') : [];
    const [toDay, toMonth, toYear] = to ? to.split('-') : [];
    return {
      from: from ? new Date([fromMonth, fromDay, fromYear].join('-')).toISOString() : null,
      to: to ? new Date([toMonth, toDay, toYear].join('-')).toISOString() : null,
    };
  };

  @action fetchFiles = (setTotalPage = false) => {
    const { from, to } = this.getDateRange();
    this.loading = true;

    return customFetch('/Default/CM_ActionRequiredSearch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        SearchStaffOnly: this.searchStaffOnly,
        StaffId: this.StaffId,
        PendingAC: this.pendingAC,
        CurPage: this.curPage,
        NumItemPerPage: 20,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        From: from,
        To: to,
      }),
    }).then(
      action(data => {
        this.data = data;
        this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
        this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    return this.fetchFiles(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.fromTo = '';
    this.from = undefined;
    this.to = undefined;
    this.sortColumn = 'Due Date';
    this.sortKey = 'DueDate';
    this.sortDirection = 'descending';
    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new StoreCM();
