import { Form, Select, Radio, Input, Checkbox, DatePicker, Dropdown, Menu } from 'antd';
import { Popup, Icon, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import React from 'react';

import * as api from '@stores/api';

import store from './store';
import { renderDate } from '../Shared';
import { CONSTANTS } from './type';

@observer
class CaseCancellationForm extends React.Component {
  handleCancelOption = () => {
    store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to send report to CMs?',
      onOk: () => {},
    });
  };

  render() {
    //CancelledChargeStatus --- Case Status Cancel
    const {
      supplementaryInfo,
      handleChangeFieldCheckbox,
      handleChangeDatePicker,
      handleChangeFieldSelect,
      handleChangeFieldRadio,
      handleChangeFieldInput,
      handleResendNotifyCancellation,
    } = store;
    return (
      <Form name="case-cancellation">
        {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 4
                <button className="field-actions" onClick={() => store.openModalHistory('PICcancelled')}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={supplementaryInfo.PICcancelled || undefined}
              onChange={handleChangeFieldSelect('PICcancelled')}
            >
              {store.staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div className="fluid-field" />
        <Form.Item label="Case Status">
          <Radio.Group
            value={supplementaryInfo.CancelledChargeStatus}
            onChange={handleChangeFieldRadio('CancelledChargeStatus')}
            //disabled={supplementaryInfo.isLocked || !api.isAdminOrMagStaffOrAccounting()}
          >
            <Radio
              value={CONSTANTS.CaseStatus_None}
              disabled={supplementaryInfo.IsLockedCancelledNoFee && supplementaryInfo.IsLockedCancelledWithFee}
            >
              None
            </Radio>
            <Radio disabled={supplementaryInfo.IsLockedCancelledNoFee} value={CONSTANTS.CaseStatus_CancelledNoCharge}>
              Cancelled No Fee{' '}
              {/* <Popup
                trigger={<Icon name="info circle" />}
                content="Once a case is cancelled, it can’t be undone."
                position="top center"
              /> */}
            </Radio>
            <Radio disabled={supplementaryInfo.IsLockedCancelledWithFee} value={CONSTANTS.CaseStatus_CancelledWithFee}>
              Cancelled With Fee{' '}
              {/* <Popup
                trigger={<Icon name="info circle" />}
                content="Once a case is cancelled, it can’t be undone."
                position="top center"
              /> */}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {supplementaryInfo.CancelledChargeStatus !== 4 && (
          <>
            <Form.Item label="Date of Cancellation">
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                disabled={supplementaryInfo.isLocked || !api.isAdminOrMagStaffOrAccounting()}
                value={renderDate(supplementaryInfo.CancelledDate)}
                onChange={handleChangeDatePicker('CancelledDate')}
              />
            </Form.Item>
            <Form.Item label="Reasons">
              <Radio.Group
                value={supplementaryInfo.ReasonCancellation}
                disabled={supplementaryInfo.isLocked || !api.isAdminOrMagStaffOrAccounting()}
                onChange={handleChangeFieldRadio('ReasonCancellation')}
              >
                <Radio value={1}>The request of the Case Manager</Radio>
                <br />
                <Radio value={2}>Specialist being unable to undertake assessment</Radio>
              </Radio.Group>
            </Form.Item>
            <br />
            <Form.Item label="Send Cancellation Confirmation to" className="cancellation-label"></Form.Item>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={supplementaryInfo.NotifyCancellationToSolictor}
                onChange={handleChangeFieldCheckbox('NotifyCancellationToSolictor')}
                disabled={
                  api.isCaseManager()
                    ? supplementaryInfo.SentDateNotifyCancellationToSolictor || false
                    : supplementaryInfo.isLocked ||
                      !api.isAdminOrMagStaffOrAccounting() ||
                      supplementaryInfo.SentDateNotifyCancellationToSolictor ||
                      !store.bookingSolicitorSelected?.Id
                }
              >
                Booking Solicitor{' '}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to CMs (Message-70)"
                    position="top center"
                  />
                )}
              </Checkbox>
              {supplementaryInfo.SentDateNotifyCancellationToSolictor && (
                <span style={{ color: '#107fc9', marginLeft: '2px' }}>
                  {supplementaryInfo.SentDateNotifyCancellationToSolictor
                    ? renderDate(supplementaryInfo.SentDateNotifyCancellationToSolictor, false, true)
                    : ''}
                  {supplementaryInfo.SentByNotifyCancellationToSolictor
                    ? ` - By ${
                        store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyCancellationToSolictor)
                          ? store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyCancellationToSolictor)
                              .FullName
                          : supplementaryInfo.SentByNotifyCancellationToSolictor
                      }`
                    : ''}
                </span>
              )}
            </div>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={supplementaryInfo.NotifyCancellationToCM}
                onChange={handleChangeFieldCheckbox('NotifyCancellationToCM')}
                disabled={
                  api.isCaseManager()
                    ? supplementaryInfo.SentDateNotifyBookingCMCancellation || false
                    : supplementaryInfo.isLocked ||
                      !api.isAdminOrMagStaffOrAccounting() ||
                      supplementaryInfo.SentDateNotifyBookingCMCancellation
                }
              >
                Booking CM
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to CMs (Message-70)"
                    position="top center"
                  />
                )}
                {supplementaryInfo.SentDateNotifyBookingCMCancellation && (
                  <span style={{ color: '#107fc9', marginLeft: '50px' }}>
                    {supplementaryInfo.SentDateNotifyBookingCMCancellation
                      ? renderDate(supplementaryInfo.SentDateNotifyBookingCMCancellation, false, true)
                      : ''}
                    {supplementaryInfo.SentByNotifyBookingCMCancellation
                      ? ` - By ${
                          store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyBookingCMCancellation)
                            ? store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyBookingCMCancellation)
                                .FullName
                            : supplementaryInfo.SentByNotifyBookingCMCancellation
                        }`
                      : ''}
                  </span>
                )}
              </Checkbox>
            </div>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={supplementaryInfo.NotifyDoctorCancelled}
                onChange={handleChangeFieldCheckbox('NotifyDoctorCancelled')}
                disabled={
                  supplementaryInfo.isLocked ||
                  !api.isAdminOrMagStaffOrAccounting() ||
                  !!supplementaryInfo.SentDateNotifyDoctorCancellation
                }
              >
                Specialist{' '}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to Specialist (MAIL-82)"
                    position="top center"
                  />
                )}
                {supplementaryInfo.SentDateNotifyDoctorCancellation && (
                  <span style={{ color: '#107fc9', marginLeft: '66px' }}>
                    {supplementaryInfo.SentDateNotifyDoctorCancellation
                      ? renderDate(supplementaryInfo.SentDateNotifyDoctorCancellation, false, true)
                      : ''}
                    {supplementaryInfo.SentByNotifyDoctorCancellation
                      ? ` - By ${
                          store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyDoctorCancellation)
                            ? store.staffs.find(i => i.Id === supplementaryInfo.SentByNotifyDoctorCancellation).FullName
                            : supplementaryInfo.SentByNotifyDoctorCancellation
                        }`
                      : ''}
                  </span>
                )}
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item className="fluid-field">
                <Dropdown
                  disabled={
                    !supplementaryInfo.SentDateNotifyCancellationToSolictor &&
                    !supplementaryInfo.SentDateNotifyBookingCMCancellation &&
                    !supplementaryInfo.SentDateNotifyDoctorCancellation
                  }
                  overlay={
                    <Menu>
                      <Menu.Item
                        disabled={
                          !supplementaryInfo.SentDateNotifyCancellationToSolictor ||
                          !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(0)}
                      >
                        Resend Cancellation Confirmation to Booking Solicitor{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !supplementaryInfo.SentDateNotifyBookingCMCancellation || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(1)}
                      >
                        Resend Cancellation Confirmation to Booking CM{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !supplementaryInfo.SentDateNotifyDoctorCancellation || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(2)}
                      >
                        Resend Cancellation Confirmation to Specialist{' '}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button className="blue">
                    Resend Cancellation Confirmation <Icon type="down" />
                  </Button>
                </Dropdown>
              </Form.Item>
            )}
          </>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Comments" className="fluid-field">
            <Input.TextArea
              rows={3}
              value={supplementaryInfo.CancelledComment}
              onChange={handleChangeFieldInput('CancelledComment')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default CaseCancellationForm;
