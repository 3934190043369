import React from 'react';
import {
  Form,
  Checkbox,
  Divider,
  Radio,
  Select,
  Menu,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  DatePicker,
  notification,
  Collapse,
} from 'antd';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import moment from 'moment';

import store from './store';
import Upload from './FieldUpload';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';
import ListInvoice from './ListInvoice';
import { Header } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';

@observer
class ClientInvoicingForm extends React.Component {
  @action handleChangeValueFRInfo = (fieldName, value) => {
    const itemModel = toJS(store.fileReviewInfo);
    return (store.fileReviewInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };
  componentDidMount() {
    return store.calculateInvoice();
  }
  @action handleChangeModeMultipleObj = (_, event, fieldName, lengthLimit) => {
    const data = event.map(i => i.props.data);
    const data_ = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null)).filter(i => i);

    store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = data_;
    return store.calculateTotalServiceFee();
  };
  @action handleChangeObj = (_, event, fieldName, key) => {
    if (event) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      if (fieldName === 'CReference' && key === 'CReferenceId') {
        if (key) {
          store.fileReviewInfo.ServiceNotesandFeeObj[key] = data_.Id;
        }
        return (store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = data_);
      }
      if (key) {
        store.fileReviewInfo[key] = data_.Id;
      }
      return (store.fileReviewInfo[fieldName] = data_);
    } else return (store.fileReviewInfo[fieldName] = {});
  };
  resendInvoice = () => {
    const itemModel = store.fileReviewInfo;
    customFetch('/FileReview/ResendInvoice', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(res => {
        if (res.status === 'success') {
          store.handleChangeValue('EmailInvoiceToMAGDateSent', moment());
          notification.destroy();
          notification.success({
            description: 'Resend Invoice',
            message: 'Success',
          });
        } else {
          notification.destroy();
          notification.success({
            description: 'Resend Invoice',
            message: 'Error',
          });
        }
      })
      .catch(() => {});
  };
  handleResendInvoice = () => {
    store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to resend invoice?',
      onOk: () => this.resendInvoice(),
    });
  };
  handleDoctorPrepaymentRequiredChange = () => {
    const attachments = store.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 15);
    store.toggleModalConfirm(true, {
      message: 'Do you want to send invoice to Medicolegal Provider?',
      onOk: () => {
        if (!!attachments.length) {
          store.handleChangeValue('EmailPaymentReceiptToDoctor', true);
        } else {
          notification.error({
            message: 'Error',
            description: 'Provider invoice must be attached first',
          });
        }
      },
    });
  };
  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      if (fieldName === 'SameBookingCM') {
        store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value;
        return this.SameBCMSendInvoice();
      }
      if (fieldName === 'SpecialistPrepaymentPaid') {
        store.fileReviewInfo[fieldName] = value;
        return (store.fileReviewInfo.PrepaymentPaidDate = value ? moment() : null);
      }
      if (fieldName === 'ClientPrepaymentReceived') {
        store.fileReviewInfo[fieldName] = value;
        return (store.fileReviewInfo.ClientPrepaymentPaidDate = value ? moment() : null);
      }
      if (fieldName === 'EmailInvoiceToMAG') {
        const { FileReviewAttachment } = store.fileReviewInfo;
        const attachments = FileReviewAttachment.filter(({ ReportType }) => ReportType === 15);
        if (value && attachments.length <= 0) {
          store.showError('Provider invoice must be attached first');
          return (store.fileReviewInfo[fieldName] = false);
        } else return (store.fileReviewInfo[fieldName] = value);
      }
      if (fieldName === 'DoctorPrepaymentRequired') {
        if (value) {
          this.handleDoctorPrepaymentRequiredChange();
        }
        store.fileReviewInfo[fieldName] = value;
        return (store.fileReviewInfo.PrepaymentRequiredDate = value ? moment() : null);
      }
      if (fieldName === 'EmailPaymentReceiptToClient') {
        if (
          !store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice &&
          !store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice2
        ) {
          store.showError('Please input email for Email For Sending Invoices.');
          return (store.fileReviewInfo[fieldName] = false);
        } else {
          return (store.fileReviewInfo[fieldName] = value);
        }
      }
      if (fieldName === 'SameClientAccEmail') {
        store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value;
        return this.SameACESendInvoice();
      }
      if (fieldName === 'ClientPrepaymentRequired') {
        if (value) {
          this.handleChangeValueFRInfo('ClientPrepaymentRequiredDate', moment());
          const itemModel = toJS(store.fileReviewInfo);
          if (!itemModel.DelayPrepaymentRequired) {
            store.toggleModalConfirm(true, {
              modalType: 'confirm',
              message: 'Do you want to delay sending completed reports to client until payment is made',
              onOk: () =>
                action(() => {
                  store.fileReviewInfo.DelayPrepaymentRequired = true;
                })(),
            });
          }
          return this.handleChangeValueFRInfo(fieldName, value);
        } else return this.handleChangeValueFRInfo(fieldName, value);
      }
      if (
        fieldName === 'IncludeAdminExtraFeeToDoctorCommission' ||
        fieldName === 'HasOtherFee' ||
        fieldName === 'IncludeServiceItemFeeToDoctorCommission'
      ) {
        store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value;
        return store.calculateInvoice();
      }
      return this.handleChangeValueFRInfo(fieldName, value);
    });
  handleSearch = key =>
    action(value => {
      store[key] = value;
    });

  handleScroll = key =>
    action(event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
        store[key] += 20;
      }
    });
  @action SameACESendInvoice = () => {
    const { PCustomerId } = store.fileReviewInfo;
    if (!PCustomerId) {
      store.showError('Please choose Paying Client first.');
      store.fileReviewInfo.ServiceNotesandFeeObj.SameClientAccEmail = false;
    } else {
      if (store.fileReviewInfo.ServiceNotesandFeeObj.SameClientAccEmail) {
        customFetch('/MedicalService/GetCustomerEmail', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ id: PCustomerId }),
        }).then(
          action(res => {
            if (res.status === 'success') {
              store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice2 = res.email;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.SameClientAccEmail = false;
              const { errorList } = res;
              if (!errorList || !!!errorList.length) {
                store.showError('Error occured while sending invoice, please try again later.');
              } else {
                errorList.map(i => store.showError(i));
              }
            }
          }),
        );
      } else {
        store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice2 = '';
      }
    }
  };
  handleResendEmailClientPaymentReceipt = () => {
    const itemModel = toJS(store.fileReviewInfo);
    return customFetch('/FileReview/ResendEmailClientPaymentReceipt', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            description: 'Resend email payment receipt',
            message: 'Success',
          });
          store.handleChangeValue('EmailPaymentReceiptToClientDateSent', moment());
          response.sentBy && store.handleChangeValue('EmailPaymentReceiptToClientBy', response.sentBy);
        } else {
          notification.destroy();
          notification.Error({
            description: 'Resend email payment receipt',
            message: ' Error',
          });
        }
      })
      .catch(() => {});
  };
  ResendEmailClientPaymentReceipt = () => {
    return store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to resend payment receipt?',
      onOk: () => this.handleResendEmailClientPaymentReceipt(),
    });
  };
  @action SameBCMSendInvoice = () => {
    const { fileReviewInfo, selectedCM } = store;
    const { CaseManager } = fileReviewInfo;
    if (CaseManager || selectedCM) {
      if (store.fileReviewInfo.ServiceNotesandFeeObj.SameBookingCM) {
        store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice = CaseManager?.Email || selectedCM.Email;
      } else {
        store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice = null;
        store.fileReviewInfo.ServiceNotesandFeeObj.SameBookingCM = false;
      }
    } else {
      store.showError('Please choose CM first.');
      store.fileReviewInfo.ServiceNotesandFeeObj.SI_SameBookingCM = false;
    }
  };
  handleChangeDatePicker = fieldName =>
    action(event => {
      const value = event;
      return (store.fileReviewInfo[fieldName] = value);
    });

  handleChangeField = fieldName =>
    action(event => {
      const value = event?.target ? event.target.value : event;
      if (
        fieldName === 'AdminExtraFeeAmount' ||
        fieldName === 'AdminExtraFeeTaxPercentage' ||
        fieldName === 'OtherFee1Label' ||
        fieldName === 'OtherFee1TaxPercentage' ||
        fieldName === 'OtherFee1Value' ||
        fieldName === 'OtherFee1Description' ||
        fieldName === 'OtherFee2Value' ||
        fieldName === 'OtherFee2TaxPercentage' ||
        fieldName === 'OtherFee2Description' ||
        fieldName === 'Description' ||
        fieldName === 'CommissionRate' ||
        fieldName === 'CommissionOnTopRate' ||
        fieldName === 'CommissionAmount'
      ) {
        store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value;
        return store.calculateInvoice();
      } else if (fieldName === 'SendInvoice2' || fieldName === 'SendInvoice') {
        return (store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value);
      } else return (store.fileReviewInfo[fieldName] = value);
    });
  onCommissionRateTypeChange = () => {
    const { momentToJS, inRangeDate, inRangeDate2 } = store;
    const commissionRateType = store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType;
    switch (commissionRateType) {
      default:
        break;
      case 0:
        if (store.selectedDoctor) {
          let dObj = store.selectedDoctor;
          let compareDate = store.fileReviewInfo.ReportCompletedDate;
          if (!compareDate) {
            compareDate = store.fileReviewInfo.RequestDate;
          }
          if (!!dObj.MAGChargeRate && !!dObj.MAGChargeRate2) {
            let isSetValue = false;

            if (
              !!dObj.MAGChargeRateTo &&
              store.inRangeDate(
                compareDate,
                store.momentToJS(dObj.MAGChargeRateFrom),
                store.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate;
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRateTo &&
              store.inRangeDate2(compareDate, store.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate;
              isSetValue = true;
            }
            if (
              !!dObj.MAGChargeRate2To &&
              store.inRangeDate(
                compareDate,
                store.momentToJS(dObj.MAGChargeRate2From),
                store.momentToJS(dObj.MAGChargeRate2To),
              )
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate2;
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRate2To &&
              store.inRangeDate2(compareDate, store.momentToJS(dObj.MAGChargeRate2From))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate2;
              isSetValue = true;
            }
            if (!isSetValue) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.MAGChargeRateTo &&
              store.inRangeDate(
                compareDate,
                store.momentToJS(dObj.MAGChargeRateFrom),
                store.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate;
            } else if (
              !dObj.MAGChargeRateTo &&
              store.inRangeDate2(compareDate, store.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.MAGChargeRate2To &&
              inRangeDate(compareDate, momentToJS(dObj.MAGChargeRate2From), momentToJS(dObj.MAGChargeRate2To))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate2;
            } else if (!dObj.MAGChargeRate2To && inRangeDate2(compareDate, momentToJS(dObj.MAGChargeRate2From))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.MAGChargeRate2;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else {
            store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
          }
        } else {
          store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
        }
        break;
      case 1:
        if (store.selectedDoctor) {
          let dObj = store.selectedDoctor;
          let compareDate = store.fileReviewInfo.ReportCompletedDate;
          if (!compareDate) {
            compareDate = store.fileReviewInfo.RequestDate;
          }
          if (!!dObj.ChargeRate && !!dObj.ChargeRate2) {
            let isSetValue = false;
            if (
              !!dObj.ChargeRateTo &&
              inRangeDate(compareDate, momentToJS(dObj.ChargeRateFrom), momentToJS(dObj.ChargeRateTo))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate;
              isSetValue = true;
            } else if (!dObj.ChargeRateTo && inRangeDate2(compareDate, momentToJS(dObj.ChargeRateFrom))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate;
              isSetValue = true;
            }
            if (
              !!dObj.ChargeRate2To &&
              inRangeDate(compareDate, momentToJS(dObj.ChargeRate2From), momentToJS(dObj.ChargeRate2To))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate2;
              isSetValue = true;
            } else if (!dObj.ChargeRate2To && inRangeDate2(compareDate, momentToJS(dObj.ChargeRate2From))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate2;
              isSetValue = true;
            }
            if (!isSetValue) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.ChargeRateTo &&
              inRangeDate(compareDate, momentToJS(dObj.ChargeRateFrom), momentToJS(dObj.ChargeRateTo))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate;
            } else if (!dObj.ChargeRateTo && inRangeDate2(compareDate, momentToJS(dObj.ChargeRateFrom))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.ChargeRate2To &&
              inRangeDate(compareDate, momentToJS(dObj.ChargeRate2From), momentToJS(dObj.ChargeRate2To))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate2;
            } else if (!dObj.ChargeRate2To && inRangeDate2(compareDate, momentToJS(dObj.ChargeRate2From))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = dObj.ChargeRate2;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
            }
          } else {
            store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
          }
        } else {
          store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 0;
        }
        break;
      case 2:
        store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount =
          store.selectedDoctor?.MAGChargeRateFixedAmount || 0;
        break;
      case 3:
        store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate = 100;
        if (store.selectedDoctor) {
          let dObj = store.selectedDoctor;
          let compareDate = store.fileReviewInfo.ReportCompletedDate;
          if (!compareDate) {
            compareDate = store.fileReviewInfo.RequestDate;
          }
          if (!!dObj.CommissionOnTop && !!dObj.CommissionOnTop2) {
            let isSetValue = false;
            if (
              !!dObj.CommissionOnTopTo &&
              inRangeDate(compareDate, momentToJS(dObj.CommissionOnTopFrom), momentToJS(dObj.CommissionOnTopTo))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop;
              isSetValue = true;
            } else if (!dObj.CommissionOnTopTo && inRangeDate2(compareDate, momentToJS(dObj.CommissionOnTopFrom))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop;
              isSetValue = true;
            }
            if (
              !!dObj.CommissionOnTop2To &&
              inRangeDate(compareDate, momentToJS(dObj.CommissionOnTop2From), momentToJS(dObj.CommissionOnTop2To))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop2;
              isSetValue = true;
            } else if (
              !dObj.CommissionOnTop2To &&
              inRangeDate2(compareDate, store.momentToJS(dObj.CommissionOnTop2From))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop2;
              isSetValue = true;
            }
            if (!isSetValue) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = 0;
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.CommissionOnTopTo &&
              inRangeDate(compareDate, momentToJS(dObj.CommissionOnTopFrom), momentToJS(dObj.CommissionOnTopTo))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop;
            } else if (
              !dObj.CommissionOnTopTo &&
              inRangeDate2(compareDate, store.momentToJS(dObj.CommissionOnTopFrom))
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = 0;
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.CommissionOnTop2To &&
              inRangeDate(
                compareDate,
                store.momentToJS(dObj.CommissionOnTop2From),
                store.momentToJS(dObj.CommissionOnTop2To),
              )
            ) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop2;
            } else if (!dObj.CommissionOnTop2To && inRangeDate2(compareDate, momentToJS(dObj.CommissionOnTop2From))) {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = dObj.CommissionOnTop2;
            } else {
              store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = 0;
            }
          } else {
            store.fileReviewInfo.ServiceNotesandFeeObjCommissionOnTopRate = 0;
          }
        } else {
          store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate = 0;
        }
        break;
    }
    store.calculateInvoice();
  };
  handleChangeFieldRadio = fieldName =>
    action(event => {
      const value = event.target ? event.target.value : event;
      store.fileReviewInfo.ServiceNotesandFeeObj[fieldName] = value;
      this.onCommissionRateTypeChange();
      return store.calculateInvoice();
    });
  @action adminExtraFocusOut = () => {
    let tax = store.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount * 0.1;
    store.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage = parseFloat(tax.toFixed(2));
    return store.calculateInvoice();
  };
  @action otherFee1ValueFocusOut = () => {
    let tax = store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Value * 0.1;
    store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage = parseFloat(tax.toFixed(2));
    return store.calculateInvoice();
  };
  @action otherFee2ValueFocusOut = () => {
    let tax = store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee2Value * 0.1;
    store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee2TaxPercentage = parseFloat(tax.toFixed(2));
    return store.calculateInvoice();
  };
  checkCurrentRate = () => {
    customFetch(`/medicaldoctor/getdoctordetails?id=${store.selectedDoctor.Id}`, {
      method: 'GET',
    }).then(
      action(d => {
        store.selectedDoctor.MAGChargeRate = d.MAGChargeRate;
        store.selectedDoctor.MAGChargeRateFrom = d.MAGChargeRateFrom;
        store.selectedDoctor.MAGChargeRateTo = d.MAGChargeRateTo;
        store.selectedDoctor.MAGChargeRate2 = d.MAGChargeRate2;
        store.selectedDoctor.MAGChargeRate2From = d.MAGChargeRate2From;
        store.selectedDoctor.MAGChargeRate2To = d.MAGChargeRate2To;
        store.selectedDoctor.ChargeRate = d.ChargeRate;
        store.selectedDoctor.ChargeRateFrom = d.ChargeRateFrom;
        store.selectedDoctor.ChargeRateTo = d.ChargeRateTo;
        store.selectedDoctor.ChargeRate2 = d.ChargeRate2;
        store.selectedDoctor.ChargeRate2From = d.ChargeRate2From;
        store.selectedDoctor.ChargeRate2To = d.ChargeRate2To;
        store.selectedDoctor.CommissionOnTop = d.CommissionOnTop;
        store.selectedDoctor.CommissionOnTopFrom = d.CommissionOnTopFrom;
        store.selectedDoctor.CommissionOnTopTo = d.CommissionOnTopTo;
        store.selectedDoctor.CommissionOnTop2 = d.CommissionOnTop2;
        store.selectedDoctor.CommissionOnTop2From = d.CommissionOnTop2From;
        store.selectedDoctor.CommissionOnTop2To = d.CommissionOnTop2To;
        this.onCommissionRateTypeChange();
      }),
    );
  };
  handleConfirmEmailInvoiceToOperator = () => {
    return store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to email invoice to Medicolegal Provider?',
      onOk: () => action(() => (store.fileReviewInfo.EmailInvoiceToMAG = true))(),
    });
  };
  handleConfirmEmailAttachedReceiptSpecialist = () => {
    if (!store.fileReviewInfo.EmailPaymentReceiptToDoctor) {
      store.toggleModalModalUpload(false, {});
      return store.toggleModalConfirm(true, {
        modalType: 'confirm',
        message: 'Do you want to email receipt to the provider?',
        onOk: () => action(() => (store.fileReviewInfo.EmailPaymentReceiptToDoctor = true))(),
      });
    }
    return;
  };
  handleSendPaymentReceipt = () => {
    return store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to send receipt?',
      onOk: () => action(() => (store.fileReviewInfo.EmailPaymentReceiptToClient = true))(),
    });
  };

  handleResendEmailPaymentReceipt = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend payment receipt to Specialist?',
      onOk: () => {
        return customFetch('/FileReview/ResendEmailPaymentReceipt', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.fileReviewInfo.Id }),
        })
          .then(response => {
            if (response.status === 'success') {
              notification.success({
                description: 'Resend email payment receipt to Specialist',
                message: 'Success',
              });
              store.handleChangeValue('EmailPaymentReceiptToDoctorDateSent', moment());
            } else {
              notification.success({
                description: 'Resend email payment receipt to Specialist',
                message: 'Error',
              });
            }
          })
          .catch(() => {});
      },
    });
  };

  render() {
    const itemModel = toJS(store.fileReviewInfo);
    const { setFieldValue, fileReviewInfo } = store;
    const { PCustomer } = store.fileReviewInfo;
    const cReference = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.CReference);
    const sameBookingCM = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.SameBookingCM);
    const sameClientAccEmail = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.SameClientAccEmail);
    const sendInvoice = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice);
    const sendInvoice2 = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.SendInvoice2);
    const includeServiceItemFeeToDoctorCommission = toJS(
      store.fileReviewInfo.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission,
    );
    const billingCode = toJS(store.billingCodes);
    const listServiceItems = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.ListServiceItems);
    const adminExtraFeeAmount = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount);
    const adminExtraFeeTaxPercentage = toJS(store.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage);

    if (api.isCaseManager()) {
      return <ListInvoice />;
    } else {
      return (
        <Form name="client-invoicing-information" className="grid-style">
          <Collapse bordered={false} defaultActiveKey={api.isDoctor() ? 3 : null}>
            {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && [
              <Collapse.Panel
                key="1"
                header={
                  <Header
                    title="Client Invoicing Information"
                    isChecked={listServiceItems?.length > 0 ?? false}
                    text="Billing Items Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Paying Client">
                  <Select
                    showSearch
                    size="large"
                    filterOption={false}
                    placeholder="Select a paying client"
                    value={fileReviewInfo.PCustomerId || undefined}
                    onSearch={this.handleSearch('companiesKeyword')}
                    onPopupScroll={this.handleScroll('companiesPerPage')}
                    onChange={setFieldValue('PCustomerId')}
                    allowClear
                  >
                    {PCustomer && !store.companies.find(({ Id }) => Id === PCustomer.Id) && (
                      <Select.Option style={{ display: 'none' }} value={PCustomer.Id}>
                        {PCustomer.Id} - {PCustomer.Name} - {PCustomer.StateName}
                      </Select.Option>
                    )}
                    {store.companies.map(({ Id, Name, StateName }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="C/-Reference">
                  <Select
                    showSearch
                    size="large"
                    filterOption={false}
                    placeholder="Select a C/-Reference"
                    value={cReference?.Id || undefined}
                    onSearch={this.handleSearch('companiesKeyword')}
                    onPopupScroll={this.handleScroll('companiesPerPage')}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'CReference', 'CReferenceId')}
                    allowClear
                  >
                    {cReference && !store.companies.find(({ Id }) => Id === cReference.Id) && (
                      <Select.Option
                        style={{ display: 'none' }}
                        value={cReference.Id}
                        data={JSON.stringify(cReference)}
                      >
                        {cReference.Id} - {cReference.Name} - {cReference.StateName}
                      </Select.Option>
                    )}
                    {store.companies.map(i => (
                      <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                        {i.Id} - {i.Name} - {i.StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Case Manager / Solicitor
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item>Add Case Manager</Menu.Item>
                              <Menu.Item>Edit Case Manager</Menu.Item>
                            </Menu>
                          }
                        >
                          <button className="field-actions">
                            Add/Edit <Icon type="down" />
                          </button>
                        </Dropdown>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    placeholder="Select a case manager/solicitor"
                    value={fileReviewInfo.PCaseManagerId || undefined}
                    onChange={setFieldValue('PCaseManagerId')}
                    allowClear
                  >
                    {store.pCompanyCMs.map(({ Id, FullName, Email }) => (
                      <Select.Option key={Id} value={Id}>
                        No. {Id} - {FullName} - {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div />
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 1:</span>
                      {!api.isDoctor() && (
                        <Checkbox
                          style={{ marginTop: 10 }}
                          onChange={this.handleChangeCheckBox('SameBookingCM')}
                          checked={sameBookingCM}
                          className="checkbox-IsTypist"
                        >
                          Same as Booking CM
                        </Checkbox>
                      )}
                    </span>
                  }
                  validateStatus={!store.validateField(sendInvoice, 'email') ? 'error' : null}
                  help={!store.validateField(sendInvoice, 'email') ? 'Please input valid email' : ''}
                >
                  <Input
                    size="large"
                    value={sendInvoice}
                    disabled={sameBookingCM}
                    onChange={this.handleChangeField('SendInvoice')}
                  />
                </Form.Item>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 2:</span>
                      {!api.isDoctor() && (
                        <Checkbox
                          style={{ marginTop: 10 }}
                          onChange={this.handleChangeCheckBox('SameClientAccEmail')}
                          checked={sameClientAccEmail}
                          className="checkbox-IsTypist"
                        >
                          {`Same as Client Accounting Email`}
                        </Checkbox>
                      )}
                    </span>
                  }
                  validateStatus={!store.validateField(sendInvoice2, 'email') ? 'error' : null}
                  help={!store.validateField(sendInvoice2, 'email') ? 'Please input valid email' : ''}
                >
                  <Input
                    size="large"
                    disabled={sameClientAccEmail}
                    value={sendInvoice2}
                    onChange={this.handleChangeField('SendInvoice2')}
                  />{' '}
                </Form.Item>
                <Form.Item label="Billing Item Codes">
                  <Select
                    size="large"
                    value={listServiceItems?.map(i => i.Id) ?? []}
                    mode="multiple"
                    showArrow
                    disabled={itemModel.isLocked}
                    onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'ListServiceItems')}
                    showSearch
                    optionFilterProp="children"
                    onPopupScroll={this.handleScroll('numberBillingCodePerPage')}
                  >
                    {listServiceItems &&
                      listServiceItems
                        .filter(i => !billingCode.find(s => s.Id === i.Id))
                        .map(i => (
                          <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                            {i.Name} - Fee: {i.ApplicablePrice}
                          </Select.Option>
                        ))}
                    {billingCode &&
                      billingCode.length &&
                      billingCode.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Name} - Fee: {i.ApplicablePrice}
                        </Select.Option>
                      ))}
                  </Select>
                  <Checkbox
                    checked={includeServiceItemFeeToDoctorCommission}
                    onChange={this.handleChangeCheckBox('IncludeServiceItemFeeToDoctorCommission')}
                    style={{ marginTop: 10 }}
                  >
                    Specialist Service
                  </Checkbox>
                </Form.Item>
                <div />
                <Form.Item label="Billing Item Fees (excl GST)">
                  <InputNumber
                    size="large"
                    value={toJS(store.serviceItemTotalFee)}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    value={store.serviceItemTotalTax}
                    disabled
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Admin Extra (excl GST)">
                  <InputNumber
                    size="large"
                    value={adminExtraFeeAmount || 0}
                    onChange={this.handleChangeField('AdminExtraFeeAmount')}
                    onBlur={this.adminExtraFocusOut}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                  <Checkbox
                    style={{ marginTop: 10 }}
                    checked={store.fileReviewInfo.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission}
                    onChange={this.handleChangeCheckBox('IncludeAdminExtraFeeToDoctorCommission')}
                  >
                    Specialist Service
                  </Checkbox>
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={adminExtraFeeTaxPercentage}
                    onChange={this.handleChangeField('AdminExtraFeeTaxPercentage')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <div className="fluid-field checkbox-field">
                  <Checkbox
                    checked={store.fileReviewInfo.ServiceNotesandFeeObj.HasOtherFee}
                    onChange={this.handleChangeCheckBox('HasOtherFee')}
                    disabled={itemModel.isLocked}
                  >
                    Other Fees (excl GST)
                  </Checkbox>
                </div>
                {store.fileReviewInfo.ServiceNotesandFeeObj.HasOtherFee && (
                  <React.Fragment>
                    <Form.Item label="Fee Name">
                      <Input
                        size="large"
                        value={store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Label}
                        disabled={itemModel.isLocked}
                        onChange={this.handleChangeField('OtherFee1Label')}
                      />
                    </Form.Item>
                    <Form.Item label="Fee">
                      <InputNumber
                        value={store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Value}
                        onChange={this.handleChangeField('OtherFee1Value')}
                        disabled={itemModel.isLocked}
                        onBlur={this.otherFee1ValueFocusOut}
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    <Form.Item label="GST">
                      <InputNumber
                        value={store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage}
                        onChange={this.handleChangeField('OtherFee1TaxPercentage')}
                        disabled={itemModel.isLocked}
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                      <Checkbox style={{ marginTop: 10 }}>Doctor Service</Checkbox>
                    </Form.Item>
                    <div />
                    <Form.Item className="fluid-field" label="Fee 1 Description">
                      <Input.TextArea
                        rows={3}
                        value={store.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Description}
                        onChange={this.handleChangeField('OtherFee1Description')}
                      />
                    </Form.Item>
                  </React.Fragment>
                )}
                <Divider className="fluid-field" />
                <Form.Item label="Total Billing Amount (excl GST)">
                  <InputNumber
                    size="large"
                    disabled
                    value={store.fileReviewInfo.ServiceNotesandFeeObj.TotalWithoutTax}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total GST">
                  <InputNumber
                    size="large"
                    disabled
                    value={(store.fileReviewInfo.ServiceNotesandFeeObj.TaxTotal || 0).toFixed(2)}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total Billing Amount (incl GST)">
                  <InputNumber
                    size="large"
                    disabled
                    value={(store.fileReviewInfo.ServiceNotesandFeeObj.Total || 0).toFixed(2)}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item className="fluid-field" label="Description">
                  <Input.TextArea
                    rows={3}
                    value={store.fileReviewInfo.ServiceNotesandFeeObj.Description}
                    onChange={this.handleChangeField('Description')}
                  />
                </Form.Item>
                <Form.Item label="Attach Client Files" className="add-file-container">
                  <Upload multiple type={18} />
                </Form.Item>
              </Collapse.Panel>,
              <Collapse.Panel
                key="2"
                header={
                  <Header
                    title="Client Prepayment"
                    isChecked={itemModel.ClientPrepaymentReceived || itemModel.ClientPrepaymentRequired}
                    text={
                      itemModel.ClientPrepaymentReceived
                        ? 'Prepayment Received'
                        : itemModel.ClientPrepaymentRequired
                        ? 'Prepayment Required'
                        : ''
                    }
                    date={itemModel.ClientPrepaymentReceived ? itemModel.ClientPrepaymentPaidDate : null}
                    isRequired={false}
                  />
                }
              >
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    disabled={itemModel.isLocked}
                    checked={itemModel.ClientPrepaymentRequired}
                    className="checkbox-session"
                    onChange={this.handleChangeCheckBox('ClientPrepaymentRequired')}
                  >
                    Client Prepayment Required
                  </Checkbox>
                </div>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item label="">
                    <Checkbox disabled checked={itemModel.ClientInvoiced}>
                      Client Invoice Created
                      {itemModel.InvoiceNo ? ` - Invoice No: ${itemModel.InvoiceNo}` : ''}
                    </Checkbox>
                  </Form.Item>
                )}
                <div className="checkbox-field">
                  <Checkbox
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    checked={itemModel.DelayPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('DelayPrepaymentRequired')}
                  >
                    Delay Sending Reports{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Do not allow sending completed report to client until payment is made"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </div>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item label="Invoice Date">
                    <DatePicker
                      size="large"
                      value={renderDate(itemModel.ClientInvoicedDate)}
                      disabled
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
                {!store.isServicePrepaymentLocked && (
                  <Form.Item
                    validateStatus={
                      itemModel.ClientPrepaymentRequired && !itemModel.ClientPrepaymentRequiredDate ? 'error' : null
                    }
                    help={
                      itemModel.ClientPrepaymentRequired && !itemModel.ClientPrepaymentRequiredDate
                        ? 'Prepayment Required Date is required'
                        : ''
                    }
                    label="To be paid by"
                    required={itemModel.ClientPrepaymentRequired}
                  >
                    <DatePicker
                      disabled={!itemModel.ClientPrepaymentRequired}
                      size="large"
                      value={renderDate(itemModel.ClientPrepaymentRequiredDate)}
                      onChange={this.handleChangeDatePicker('ClientPrepaymentRequiredDate')}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
                <div className="checkbox-field">
                  <Checkbox
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    checked={itemModel.ClientPrepaymentReceived}
                    onChange={this.handleChangeCheckBox('ClientPrepaymentReceived')}
                  >
                    Client Prepayment Received
                  </Checkbox>
                </div>
                <Form.Item
                  required={itemModel.ClientPrepaymentReceived}
                  label="Payment Date"
                  validateStatus={
                    itemModel.ClientPrepaymentReceived && !itemModel.ClientPrepaymentPaidDate ? 'error' : null
                  }
                  help={
                    itemModel.ClientPrepaymentReceived && !itemModel.ClientPrepaymentPaidDate
                      ? 'Payment Date is required'
                      : ''
                  }
                >
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.ClientPrepaymentPaidDate)}
                    onChange={this.handleChangeDatePicker('ClientPrepaymentPaidDate')}
                    format="DD/MM/YYYY"
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentReceived}
                  />
                </Form.Item>
                <Form.Item label="Attached Payment Receipt" className="add-file-container">
                  <Upload multiple type={19} functionCallback={this.handleSendPaymentReceipt} />
                </Form.Item>
                <div className="checkbox-field fluid-field">
                  <Checkbox
                    disabled={itemModel.isLocked || !!itemModel.EmailPaymentReceiptToClientDateSent}
                    checked={itemModel.EmailPaymentReceiptToClient}
                    onChange={this.handleChangeCheckBox('EmailPaymentReceiptToClient')}
                  >
                    Email Payment Receipt to Client
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Payment receipt will be sent to Client(MAIL-141)"
                        position="top center"
                        wide="very"
                      />
                    )}
                  </Checkbox>
                  {itemModel.EmailPaymentReceiptToClientDateSent && (
                    <>
                      <span
                        style={{
                          color: '#107fc9',
                          display: 'inline-block',
                          marginRight: 5,
                        }}
                      >
                        {itemModel.EmailPaymentReceiptToClientDateSent
                          ? renderDate(itemModel.EmailPaymentReceiptToClientDateSent, false, true)
                          : ''}
                        {itemModel.EmailPaymentReceiptToClientBy
                          ? ` - by: ${store.renderStaffName(itemModel.EmailPaymentReceiptToClientBy)}`
                          : ''}
                      </span>
                      {!!itemModel.EmailPaymentReceiptToClientDateSent && (
                        <Button
                          className="blue"
                          style={{ marginTop: 10 }}
                          onClick={() => this.ResendEmailClientPaymentReceipt()}
                        >
                          Resend Payment Receipt
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <Form.Item className="fluid-field" label="Note">
                  <Input.TextArea
                    rows={3}
                    onChange={this.handleChangeField('ClientPrepaymentNote')}
                    value={itemModel.ClientPrepaymentNote}
                  />
                </Form.Item>
              </Collapse.Panel>,
            ]}
            {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && [
              <Collapse.Panel
                key="3"
                header={
                  <Header
                    title="Specialist Billing Information"
                    isChecked={(store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType ?? -1) > -1}
                    text="Specialist Rate Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Booking Fee Type">
                  <Radio.Group
                    value={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType}
                    onChange={this.handleChangeFieldRadio('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={0} className="radio">
                      Medicolegal Provider Add-On Rate
                    </Radio>
                    <Radio value={1} className="radio">
                      Medicolegal Provider Standard Rate
                    </Radio>
                  </Radio.Group>
                  <Radio.Group
                    value={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType}
                    onChange={this.handleChangeFieldRadio('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={3} className="radio">
                      Gross Margin COT
                    </Radio>
                    <Radio value={2} className="radio">
                      Fixed Amount
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType !== 2 && (
                  <Form.Item
                    label={
                      <React.Fragment>
                        Commission Rate (%)
                        <button
                          className="field-actions"
                          onClick={this.checkCurrentRate}
                          disabled={!store.selectedDoctor?.Id}
                        >
                          Check Current Rate
                        </button>
                      </React.Fragment>
                    }
                  >
                    <InputNumber
                      size="large"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      value={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate}
                      onChange={this.handleChangeField('CommissionRate')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                )}
                <Form.Item label="Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={(
                      store.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculation || 0
                    ).toFixed(2)}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={(
                      store.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax || 0
                    ).toFixed(2)}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    value={(
                      store.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculation -
                      store.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax
                    ).toFixed(2)}
                    disabled
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType === 3 ? (
                  <Form.Item label="Gross Margin COT Rate (%)">
                    <InputNumber
                      value={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate}
                      onChange={this.handleChangeField('CommissionOnTopRate')}
                      size="large"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                ) : (
                  <div />
                )}
                <Form.Item label="Eligible Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount}
                    disabled={store.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType !== 2}
                    onChange={this.handleChangeField('CommissionAmount')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    value={((store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount || 0) * 0.1).toFixed(2)}
                    disabled
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Eligible Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={
                      store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmountGstNilEnabled
                        ? store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount
                        : (store.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount * 1.1).toFixed(2)
                    }
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Collapse.Panel>,
              <Collapse.Panel
                key="4"
                header={
                  <Header
                    title="Specialist Prepayment"
                    isChecked={itemModel.SpecialistPrepaymentPaid || itemModel.DoctorPrepaymentRequired}
                    text={
                      itemModel.SpecialistPrepaymentPaid
                        ? 'Prepayment Paid'
                        : itemModel.DoctorPrepaymentRequired
                        ? 'Prepayment Required'
                        : ''
                    }
                    date={itemModel.SpecialistPrepaymentPaid ? itemModel.PrepaymentPaidDate : null}
                    isRequired={false}
                  />
                }
              >
                {!store.isServicePrepaymentLocked && (
                  <React.Fragment>
                    <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Checkbox
                        disabled={store.isServicePrepaymentLocked}
                        checked={itemModel.DoctorPrepaymentRequired}
                        onChange={this.handleChangeCheckBox('DoctorPrepaymentRequired')}
                        className="checkbox-session"
                      >
                        Specialist Prepayment Required{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" style={{ marginLeft: '5px' }} />}
                          content="Prepayment notice will be sent to staff (MAIL-108)"
                          position="top center"
                          wide="very"
                        />
                      </Checkbox>
                    </div>
                    <div className="checkbox-field">
                      <Checkbox disabled checked={store.selectedDoctor?.ApprovedPrepayment}>
                        Approved Prepayment
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="This doctor has been approved for payment before report is completed"
                          position="top center"
                          wide="very"
                        />
                      </Checkbox>
                    </div>
                    <Form.Item label="Valid Until">
                      <DatePicker
                        size="large"
                        value={renderDate(store.selectedDoctor?.ValidUntil)}
                        disabled
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </React.Fragment>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <React.Fragment>
                    <Form.Item className="checkbox-field">
                      <Checkbox disabled checked={itemModel.DoctorInvoiced}>
                        Specialist Bill Created {itemModel.InvoiceNo ? ` - Invoice No: ${itemModel.InvoiceNo}` : ''}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item label="Bill Date">
                      <DatePicker
                        size="large"
                        value={renderDate(itemModel.DoctorInvoicedDate)}
                        disabled
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </React.Fragment>
                )}
                <Form.Item
                  validateStatus={itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'error' : null}
                  help={
                    itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'Invoice Date is required' : ''
                  }
                  label="Invoice Date"
                  required={itemModel.PrepaymentRequired}
                >
                  <DatePicker
                    size="large"
                    disabled={store.isServicePrepaymentLocked}
                    value={renderDate(itemModel.PaymentRequestedDate)}
                    format="DD/MM/YYYY"
                    onChange={this.handleChangeDatePicker('PaymentRequestedDate')}
                  />
                </Form.Item>
                {!store.isServicePrepaymentLocked && (
                  <Form.Item
                    validateStatus={
                      itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate ? 'error' : null
                    }
                    help={
                      itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate
                        ? 'Prepayment Required Date is required'
                        : ''
                    }
                    label="To be paid by"
                    required={itemModel.DoctorPrepaymentRequired}
                  >
                    <DatePicker
                      size="large"
                      disabled={store.isServicePrepaymentLocked}
                      value={renderDate(itemModel.PrepaymentRequiredDate)}
                      onChange={this.handleChangeDatePicker('PrepaymentRequiredDate')}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}

                <Form.Item label="Attached Specialist Invoice" className="add-file-container">
                  <Upload multiple type={15} functionCallback={this.handleConfirmEmailInvoiceToOperator} />
                </Form.Item>
                <div className="checkbox-field fluid-field">
                  <Checkbox
                    disabled={store.isServicePrepaymentLocked || !!itemModel.EmailInvoiceToMAGDateSent}
                    checked={itemModel.EmailInvoiceToMAG}
                    onChange={this.handleChangeCheckBox('EmailInvoiceToMAG')}
                  >
                    Email Invoice to Medicolegal Provider
                    {!store.servicePrepaymentLocked && (
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Specialist invoice will be sent to staff (MAIL-135)"
                        position="top center"
                        wide="very"
                      />
                    )}
                    {!!itemModel.EmailInvoiceToMAGDateSent && (
                      <span
                        style={{
                          display: 'inline-block',
                          // width: '300px',
                          color: '#107fc9',
                          marginRight: 5,
                        }}
                      >
                        {itemModel.EmailInvoiceToMAGDateSent
                          ? renderDate(itemModel.EmailInvoiceToMAGDateSent, false, true)
                          : ''}{' '}
                        - By: {store.renderStaffName(itemModel.EmailInvoiceToMAGStaffSent) || ''}
                      </span>
                    )}
                    {!!itemModel.EmailInvoiceToMAGDateSent && (
                      <Button className="blue" onClick={this.handleResendInvoice} style={{ marginTop: 10 }}>
                        Resend Invoice
                      </Button>
                    )}
                  </Checkbox>
                </div>
                <Form.Item className="checkbox-field">
                  <Checkbox
                    disabled={itemModel.isLocked}
                    checked={itemModel.SpecialistPrepaymentPaid}
                    onChange={this.handleChangeCheckBox('SpecialistPrepaymentPaid')}
                  >
                    Specialist Prepayment Paid
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="Payment Date"
                  validateStatus={itemModel.SpecialistPrepaymentPaid && !itemModel.PrepaymentPaidDate ? 'error' : null}
                  help={
                    itemModel.SpecialistPrepaymentPaid && !itemModel.PrepaymentPaidDate
                      ? 'Prepayment Paid Date is required'
                      : ''
                  }
                  required={itemModel.SpecialistPrepaymentPaid}
                >
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.PrepaymentPaidDate)}
                    onChange={this.handleChangeDatePicker('PrepaymentPaidDate')}
                    disabled={itemModel.isLocked || !itemModel.SpecialistPrepaymentPaid}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                {fileReviewInfo.DoctorPrepaymentRequired && (
                  <>
                    <Form.Item label="Attached Payment Receipt" className="add-file-container">
                      <Upload
                        multiple
                        type={16}
                        functionCallback={this.handleConfirmEmailAttachedReceiptSpecialist}
                        disabled={itemModel.isLocked}
                        disabledDelete={itemModel.isLocked}
                      />
                    </Form.Item>
                    <div className="checkbox-field fluid-field">
                      <Checkbox
                        disabled={itemModel.isLocked || !!itemModel.EmailPaymentReceiptToDoctorDateSent}
                        checked={itemModel.EmailPaymentReceiptToDoctor}
                        onChange={this.handleChangeCheckBox('EmailPaymentReceiptToDoctor')}
                      >
                        Email Payment Receipt to Specialist
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <Popup
                            trigger={<IconSemantic name="info circle" />}
                            content="Payment receipt will be sent to Specialist (MAIL-107, DIRECT-107)"
                            position="top center"
                            wide="very"
                          />
                        )}
                        {!!itemModel.EmailPaymentReceiptToDoctorDateSent && (
                          <span
                            style={{
                              display: 'inline-block',
                              color: '#107fc9',
                              marginRight: 5,
                            }}
                          >
                            {itemModel.EmailPaymentReceiptToDoctorDateSent
                              ? renderDate(itemModel.EmailPaymentReceiptToDoctorDateSent, false, true)
                              : ''}{' '}
                          </span>
                        )}
                        {!!itemModel.EmailPaymentReceiptToDoctorDateSent && (
                          <Button
                            className="blue"
                            style={{ marginTop: '10px' }}
                            onClick={this.handleResendEmailPaymentReceipt}
                          >
                            Resend Payment Receipt
                          </Button>
                        )}
                      </Checkbox>
                    </div>
                  </>
                )}
                <Form.Item className="fluid-field" label="Note">
                  <Input.TextArea
                    rows={3}
                    onChange={this.handleChangeField('SpecialistPrepaymentNote')}
                    value={itemModel.SpecialistPrepaymentNote}
                  />
                </Form.Item>
              </Collapse.Panel>,
            ]}
          </Collapse>
        </Form>
      );
    }
  }
}

export default ClientInvoicingForm;
