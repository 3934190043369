import React from 'react';
import { Button, Divider, Form, Header, Icon } from 'semantic-ui-react';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import InvoicesStore from './InvoicesStore';
import TableToolbar from '../../shared/tableToolbar';
import ModalNotification from './ModalNotification';

const { $ } = window;

class ModalXero extends React.Component {
  state = {
    openModalNotification: false,
    message: `Request sent. This is a long-running task, you will see results in the Notifications box (below account name).`,
    checkedClaimtOnly: true,
    type: null,
    fromTo: ``,
  };

  getDateRange = () => {
    const { fromTo } = this.state;
    const [fromDate, toDate] = fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    const { fromDate, toDate } = this.getDateRange();
    const time = moment().format('HH:mm:ss');
    const from = moment(fromDate + time, 'YYYY-MM-DD hh:mm:ss').toISOString();
    const to = moment(toDate + time, 'YYYY-MM-DD hh:mm:ss').toISOString();
    if (!fromDate || !toDate) {
      this.setState({
        openModalNotification: true,
        message: `Invalid date range.`,
      });
    } else {
      customFetch('/invoice/SyncWithXero', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          notificationReceiverId: $.connection.hub.id,
          paymentDateFrom: from,
          paymentDateTo: to,
        }),
      }).then(() =>
        this.setState({
          openModalNotification: true,
          message: `Request sent. This is a long-running task, you will see results in the Notifications box (below account name).`,
        }),
      );
    }
  };

  handleChangeFromTo = (_, { value }) => {
    this.setState({ fromTo: value });
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalNotification
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { open, toggleModal } = InvoicesStore;
    const { fromTo } = this.state;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={toggleModal(false)}
        footer={
          <React.Fragment>
            {this.renderModal()}
            <Button onClick={this.handleSubmit} color="blue">
              <Icon name="sync alternate" />
              Sync
            </Button>
            <Button className="negative" onClick={toggleModal(false)}>
              <Icon name="delete" />
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-addOrEdit">
          <Form size={'small'}>
            <Form.Field>
              <Header size="medium">Sync With Xero</Header>
              <Divider />
              <TableToolbar.RangePicker
                value={fromTo}
                placeholder={`From Invoice Date - To Invoice Date`}
                onChange={this.handleChangeFromTo}
                className="Toolbar-range-picker"
              />
            </Form.Field>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ModalXero;
