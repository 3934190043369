import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ModalAddEdit from './ModalAddEdit';
import store from './Store';

function DomainExclusionModal() {
  const { modalType } = store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...store} onCancel={store.toggleModal(false)} />;
  } else if (modalType === 'addEdit') {
    return <ModalAddEdit {...store} onCancel={store.toggleModal(false)} />;
  }
}

export default DomainExclusionModal;
