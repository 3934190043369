import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import typesOfReportStore from './typesOfReportStore';

function TypesOfReportModal() {
  const { modalType } = typesOfReportStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...typesOfReportStore} onCancel={typesOfReportStore.toggleModal(false)} />;
  }
}

export default TypesOfReportModal;
