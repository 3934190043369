import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ClaimantsStore from './ClaimantsStore';
import TableHistoryModal from './TableHistoryModal';

function ClaimantsModal() {
  const { modalType } = ClaimantsStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...ClaimantsStore} onCancel={ClaimantsStore.toggleModal(false)} />;
  }

  if (modalType === 'history') {
    return <TableHistoryModal {...ClaimantsStore} onCancel={ClaimantsStore.toggleModal(false)} />;
  }
}

export default ClaimantsModal;
