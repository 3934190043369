import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';
import moment from 'moment';

class MedNegStore {
  @observable loading = true;
  @observable loadingClaimant = true;
  @observable loadingStaff = true;
  @observable loadingStaffAssignAction = true;
  @observable loadingStaffAssign = true;
  @observable loadingDynamicMNStatus = true;
  @observable loadingDynamicOverDueMNStatus = true;
  @observable loadingDynamicMNNextTask = true;

  @observable data = null;
  @observable dataClaimant = null;
  @observable dataStaff = null;
  @observable dataStaffAssignAction = null;
  @observable dataStaffAssign = null;
  @observable dataDynamicMedNegStatus = null;
  @observable dataDynamicMedNegNextTask = null;
  @observable dataDynamicOverDueMedNegStatus = null;
  @observable showBookingToday = false;

  @observable keyword = '';
  @observable keywordClaimant = ``;
  @observable numberSearch = 30;

  @observable sortKey = 'StatusDate';
  @observable sortColumn = 'Status Date';
  @observable sortDirection = 'descending';
  @observable filter = `All`;
  @observable filterNextTask = `All`;
  @observable searchClaimantOnly = false;
  @observable filterOverdue = `All`;
  @observable actionRequiredById = '';
  @observable fromTo = ``;
  @observable appointmentFromTo = ``;
  @observable NextTaskPIC = null;
  @observable claimant = { Id: ``, FullName: `` };
  @observable includeAttachment = false;
  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @observable bodyForm = null;

  @observable assignStaffs = [];

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action fetchClaimant = () => {
    this.loadingClaimant = true;
    customFetch('/MedNeg/GetSuggestClaimant', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordClaimant,
        Showing: 'active',
        numItemPerPage: this.numberSearch,
        IsSelectionList: true,
        curPage: 1,
      }),
    }).then(
      action(data => {
        this.dataClaimant = data;
        this.loadingClaimant = false;
      }),
    );
  };

  @action fetchStaff = () => {
    this.loadingStaff = true;
    customFetch('/MedNeg/GetStaffs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataStaff = data;
        this.loadingStaff = false;
      }),
    );
  };
  @action fetchStaffAssignAction = () => {
    this.loadingStaffAssignAction = true;
    customFetch('/MedNeg/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        // Action Required
        this.dataStaffAssignAction = data;
        this.loadingStaffAssignAction = false;
      }),
    );
  };
  @action fetchStaffAssign = () => {
    this.loadingStaffAssign = true;
    customFetch('/MedNeg/GetStaffsAssign', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        // NextTaskPIC
        this.dataStaffAssign = data;
        this.loadingStaffAssign = false;
      }),
    );
  };
  @action fetchDynamicMedNegStatus = () => {
    this.loadingDynamicMNStatus = true;
    customFetch('/MedNeg/GetDynamicMedNegStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        // CaseStatus
        this.dataDynamicMedNegStatus = data;
        this.loadingDynamicMNStatus = false;
      }),
    );
  };

  @action fetchDynamicMedNegNextTask = () => {
    this.loadingDynamicMNStatus = true;
    customFetch('/MedNeg/GetDynamicMedNegNextTask', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataDynamicMedNegNextTask = data;
        this.loadingDynamicMNNextTask = false;
      }),
    );
  };

  @action fetchDynamicOverDueMedNegStatus = () => {
    this.loadingDynamicOverDueMNStatus = true;
    customFetch('/MedNeg/GetDynamicOverDueMedNegStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        // Over Due
        this.dataDynamicOverDueMedNegStatus = data;
        this.loadingDynamicOverDueMNStatus = false;
      }),
    );
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  getAppointmentDateRange = () => {
    const [fromDate, toDate] = this.appointmentFromTo.split(' - ');
    return {
      fromAppointmentDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toAppointmentDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = true;
    const { fromAppointmentDate, toAppointmentDate } = this.getAppointmentDateRange();
    const { fromDate, toDate } = this.getDateRange();

    let from = '';
    if (fromDate) {
      from = this.showBookingToday ? fromDate.format() : fromDate.toISOString();
    }

    const body = JSON.stringify({
      ActionRequired: false,
      ActionRequiredById: this.actionRequiredById,
      ActionType: 'NONE',
      From: from,
      To: toDate,
      FromAppointmentDate: fromAppointmentDate,
      ToAppointmentDate: toAppointmentDate,
      CurPage: this.curPage,
      FilterOverdue: this.filterOverdue,
      FilterType: this.filter,
      FilterNextStatus: this.filterNextTask,
      ClaimantId: this.claimant.Id,
      Keyword: this.keyword,
      NextPicId: this.NextTaskPIC,
      NumItemPerPage: 20,
      Claimant: this.claimant,
      SearchClaimantOnly: this.searchClaimantOnly,
      SortBy: this.sortKey,
      SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      UrgentReport: false,
      IncludeAttachment: this.includeAttachment,
    });

    return customFetch('/MedNeg/MedNeg_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then(
      action(data => {
        this.data = data;
        this.bodyForm = body;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.fetchClaimant();
        this.fetchDynamicMedNegStatus();
        this.fetchStaff();
        this.fetchDynamicOverDueMedNegStatus();
        this.fetchStaffAssign();
        this.fetchStaffAssignAction();
        this.loading = false;
      }),
    );
  };

  @action getData = () => {
    this.loading = true;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action refetch = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchFiles(setTotalPage);
  };

  @action onSearchChange = keyword => {
    this.keyword = keyword;
    return this.fetchFiles();
  };

  @action refetchClaimant = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClaimant(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'Status Date';
    this.sortKey = 'StatusDate';
    this.sortDirection = 'descending';
    this.searchClaimantOnly = false;
    this.claimant = { Id: ``, FullName: `` };
    this.fromTo = ``;
    this.appointmentFromTo = ``;
    this.NextTaskPIC = null;
    this.actionRequiredById = ``;
    this.filterOverdue = `All`;
    this.showBookingToday = false;
    this.filterNextTask = `All`;

    this.curPage = 1;
    this.totalPage = 0;

    this.fetchFiles(true);
  };
  @action resetSearch = () => {
    this.keywordClaimant = '';
    this.numberSearch = 30;

    this.refetchClaimant();
  };
}

export default new MedNegStore();
