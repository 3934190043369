import React from 'react';

import ModalConfirm from '../../../shared/Modal/ModalConfirm';
import ModalAddEdit from './ModalAddEdit';
import BusinessUnitStore from './store';

function BusinessUnitModal() {
  const { modalType } = BusinessUnitStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...BusinessUnitStore} onCancel={BusinessUnitStore.toggleModal(false)} />;
  }
  if (modalType === 'addEdit') {
    return <ModalAddEdit {...BusinessUnitStore} onCancel={BusinessUnitStore.toggleModal(false)} />;
  }
}

export default BusinessUnitModal;
