// import { Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import dashboardStore from '../../../stores/dashboard';
import TableToolbar from '../../shared/tableToolbar';
import DocAndConsStore from './DocAndConsStore';
import * as api from '../../../../src/stores/api';

const RecruitmentStatusLabels = [
  { text: `All`, value: `All` },
  { text: `Recruiting`, value: `Recruiting` },
  { text: `Onboarding`, value: `Onboarding` },
  { text: `Onboarded`, value: `Onboarded` },
];

const DoctorStatusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
  { text: `Specialists With Clinic`, value: `wClinic` },
  { text: `Specialists Without Clinic`, value: `woClinic` },
];

const DoctorMNFreePOLabels = [
  { text: `All`, value: `All` },
  { text: `Yes`, value: `Yes` },
  { text: `No`, value: `No` },
  { text: `Unsure`, value: `Unsure` },
];

@observer
class DocAndConsToolbar extends React.Component {
  componentDidMount() {
    DocAndConsStore.fetchStaff();
  }

  openModal = options => {
    return DocAndConsStore.toggleModal(true, options);
  };
  openCaseCreate = () => () => {
    if (localStorage.getItem(`DoctorName`)) {
      localStorage.removeItem(`DoctorName`);
    }
    dashboardStore.close('/view/add-edit-doctor-2', true);
    setTimeout(() => {
      dashboardStore.open('/view/add-edit-doctor-2');
    });
  };

  handleScroll = event => {
    const target = event.target;
    const number = DocAndConsStore.numberPageSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      DocAndConsStore.refetchSearch({ numberPageSearch: number + 20 });
    }
  };

  renderRecruitmentOptions = () => {
    return RecruitmentStatusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderRecruitmentText = value => {
    let labels = RecruitmentStatusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  renderDoctorOptions = () => {
    return DoctorStatusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderPICOptions = () => {
    const array = [];
    const data = toJS(DocAndConsStore.picData);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: `${opt.FullName} (${opt.Count})`,
          value: opt.Id,
        };
      }
    });
  };

  renderPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(DocAndConsStore.picData);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return `${opt.FullName} (${opt.Count})`;
          }
        });
      return labels[0];
    }
  };

  renderDoctorText = value => {
    let labels = DoctorStatusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilterRecruitmentPIC = (_, { value }) => {
    DocAndConsStore.refetch({ filterRecruitmentPic: value, curPage: 1 }, true);
  };

  handleRecruitment = (_, { value }) => {
    DocAndConsStore.refetch({ filterRecruitment: value, curPage: 1 }, true);
  };

  handleDoctor = (_, { value }) => {
    DocAndConsStore.refetch({ filterStatus: value, curPage: 1 }, true);
  };

  handleSubmit = e => {
    e.preventDefault();
    DocAndConsStore.refetch({ curPage: 1 }, true);
  };

  changeActionRequired = () => {
    const { ActionRequired } = DocAndConsStore;
    DocAndConsStore.refetch({ ActionRequired: !ActionRequired, curPage: 1 }, true);
  };
  handleChangeSearchDropdown = e => {
    DocAndConsStore.refetchSearch({
      keywordSearch: e.target.value,
      keyword: e.target.value,
      numberPageSearch: 30,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(DocAndConsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      FullName: ``,
      ClientType: ``,
      Specialty: ``,
      Accreditations: ``,
    });
    return array.map((opt, index) => ({
      text: `${
        opt.Id === `All`
          ? `All`
          : `${opt.Id || ''} ${opt.FullName ? `- ${opt.FullName}` : ``}
      ${opt.ClientType !== `` ? `| Doctor Type: ` : ``}
      ${
        opt.ClientType === 0
          ? 'MAG Ultimate'
          : opt.ClientType === 1
          ? 'MAG Direct-Essential'
          : opt.ClientType !== ``
          ? 'MAG Direct-Extra'
          : ``
      }
      ${opt.Specialty ? `| Specialty:` : ``}
      ${opt.Specialty || ``}
      ${opt.Accreditations ? `| Accreditations: ` : ``} ${opt.Accreditations || ``}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        key: `${opt.Id} ${index}`,
        value: `${opt.FullName}`,
      }),
    }));
  };

  renderActionPICOptions = () => {
    const array = [];
    const data = toJS(DocAndConsStore.dataActionPIC?.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: `${opt.FullName} (${opt.Count})`,
          value: opt.Id,
        };
      }
    });
  };

  renderActionPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(DocAndConsStore.dataActionPIC?.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return `${opt.FullName} (${opt.Count})`;
          }
        });
      return labels[0];
    }
  };

  handleFilterActionPIC = (_, { value }) => {
    DocAndConsStore.refetch({ ActionRequiredById: value, curPage: 1 }, true);
  };

  handleChangeFromToSaleDate = (_, { value }) => {
    DocAndConsStore.refetch({ fromToSaleDate: value, curPage: 1 }, true);
  };

  handleChangeFromToRequestingDate = (_, { value }) => {
    DocAndConsStore.refetch({ fromToRqtingDate: value, curPage: 1 }, true);
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const doctor = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (doctor.id === `All`) {
      DocAndConsStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      DocAndConsStore.resetSearch();
    } else {
      DocAndConsStore.refetch(
        {
          keyword: doctor.id,
          curPage: 1,
          numberPageSearch: 30,
          keywordSearch: `${doctor.id}`,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(DocAndConsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      FullName: ``,
    });
    let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName || ``}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleRefresh = () => {
    DocAndConsStore.refetch(
      {
        data: null,
        dataSearch: null,
      },
      true,
    );
  };

  renderCallerFilterText = value => {
    const { dataStaff } = DocAndConsStore;
    if (value && dataStaff && dataStaff.length > 0) {
      let staff = dataStaff.filter(opt => opt.Id === value);
      if (staff && staff.length > 0) return staff[0].FullName + (staff[0].Count ? ` (` + staff[0].Count + `)` : ``);
      //return staff[0].FullName;
      return 'All';
    }
    return 'All';
  };

  handleCallerFilter = (_, { value }) => {
    DocAndConsStore.refetch({ filterCaller: value, curPage: 1 }, true);
  };

  renderCallerFilterOptions = () => {
    const { dataStaff } = DocAndConsStore;
    if (dataStaff && dataStaff.length > 0) {
      return dataStaff.map(opt => ({
        text: opt.FullName + (opt.Count ? ` (` + opt.Count + `)` : ``),
        value: opt.Id,
      }));
    }
  };

  renderMNFreePOFilterText = val => {
    let obj = DoctorMNFreePOLabels.filter(opt => opt.value === val);
    if (obj && obj.length > 0) return obj[0].text;
    return 'All';
  };

  renderMNFreePOFilterOptions = () => {
    return DoctorMNFreePOLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  handleMNFreePOFilter = (_, { value }) => {
    DocAndConsStore.refetch({ filterMNFreePO: value, curPage: 1 }, true);
  };

  render() {
    const {
      keyword,
      loading,
      filterRecruitment,
      filterStatus,
      loadingSearch,
      filterRecruitmentPic,
      ActionRequiredById,
      fromToSaleDate,
      fromToRqtingDate,
      filterCaller,
      totalItems,
      filterMNFreePO,
    } = DocAndConsStore;

    return (
      <div>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              onScroll={this.handleScroll}
              allowAdditions
              additionLabel=""
              disabled={loading}
              selectOnNavigation={false}
              value={this.renderSearchText(keyword)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              style={{ marginLeft: 5 }}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={DocAndConsStore.resetStore}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={this.handleRefresh}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              className="positive"
              style={{ width: 250, marginLeft: 5 }}
              onClick={this.openCaseCreate()}
              disabled={loading}
            >
              Add Specialist
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            value={this.renderDoctorText(filterStatus)}
            options={this.renderDoctorOptions()}
            onChange={this.handleDoctor}
            label="Account Status"
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            value={this.renderRecruitmentText(filterRecruitment)}
            options={this.renderRecruitmentOptions()}
            onChange={this.handleRecruitment}
            label="Recruitment Status"
          />
          <TableToolbar.RangePicker
            style={{ marginBottom: '1em' }}
            value={fromToRqtingDate}
            onChange={this.handleChangeFromToRequestingDate}
            className="Toolbar-range-picker"
            placeholder="Dates of Specialist Request"
            disabled={loading}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            value={this.renderPICText(filterRecruitmentPic)}
            options={this.renderPICOptions()}
            onChange={this.handleFilterRecruitmentPIC}
            label="Recruitment PIC"
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            label="Action PIC"
            value={this.renderActionPICText(ActionRequiredById)}
            options={this.renderActionPICOptions()}
            onChange={this.handleFilterActionPIC}
            disabled={loading}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Relationship PIC`}
            value={this.renderCallerFilterText(filterCaller)}
            options={this.renderCallerFilterOptions()}
            onChange={this.handleCallerFilter}
          />
          <TableToolbar.RangePicker
            style={{ marginBottom: '1em' }}
            value={fromToSaleDate}
            onChange={this.handleChangeFromToSaleDate}
            className="Toolbar-range-picker"
            placeholder="Relationship Follow-up Due"
            disabled={loading}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Med Neg-Free PO`}
            value={this.renderMNFreePOFilterText(filterMNFreePO)}
            options={this.renderMNFreePOFilterOptions()}
            onChange={this.handleMNFreePOFilter}
          />
        </TableToolbar.Container>
      </div>
    );
  }
}

export default DocAndConsToolbar;
