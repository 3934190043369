import React from 'react';
import {
  Form,
  Checkbox,
  DatePicker,
  Input,
  Select,
  Divider,
  notification,
  Collapse,
  Dropdown,
  Menu,
  Icon,
  Radio,
  Tooltip,
} from 'antd';
import { Icon as IconSemantic, Popup, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';

import FieldUpload from './FieldUpload';
import store from './Store';
import { DEFAULT_COLUMNS_RECENT_CHANGE, DEFAULT_FIELD_NAME_RECENT_CHANGE, CONSTANTS } from './type';
import { getStaffRecentChangeHistory, viewFieldChangeHistory } from './service';

import customFetch from '@utils/customFetch';
import * as api from '../../../stores/api';
import { renderDate, commonInlineStyles } from '../Shared';
import { Header } from '../../shared/Collapse/Panel';
import AudioTranscript from '../../shared/AudioTranscript';
import { TRANSCRIPT_AUDIO_FILE_EXTENSION } from '@utils/constants';

const LOCAL_CONSTANT = {
  ReportType_AmendedReportCompleted: 5,
  ReportType_ClientPrepaymentPaid: 21,
  ReportType_Completed: 4,
  ReportType_ConsentForm: 6,
  ReportType_Dictation: 2,
  ReportType_DictationInstruction: 13,
  ReportType_DoctorReportTemplate: 15,
  ReportType_DoctorTemplate: 14,
  ReportType_Draft: 3,
  ReportType_EassessClaimantLocationChecklist: 7,
  ReportType_EassessDoctorLocationChecklist: 8,
  ReportType_InvoiceAttachment: 10,
  ReportType_Paperwork: 1,
  ReportType_PaperworkOther: 9,
  ReportType_PaymentRequested: 11,
  ReportType_PrepaymentPaid: 12,
  ReportType_ReportReturnedFromDoctor: 18,
  ReportType_TypistReport: 19,
  ReportType_WiroFundingApproval: 20,
};

const styles = {
  emailPaperworkReceivedDate: {
    ...commonInlineStyles.inlineBlock,
    ...commonInlineStyles.highlightColor,
    ...commonInlineStyles.width300,
  },
};

@observer
class DictationsAndReportInformation extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  renderStaffName = id => {
    const { listStaff } = store;
    const rs = listStaff.itemList.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };
  @action handleFillCurrentDate = (fieldToCheck, fieldToFill) => {
    const itemModel = toJS(store.assessmentInfo);
    if (itemModel[fieldToCheck]) {
      store.assessmentInfo = { ...itemModel, [fieldToFill]: moment() };
    } else {
      store.assessmentInfo = { ...itemModel, [fieldToFill]: null };
    }
  };
  handleRecentChange = async (record, actionName, columns, fieldNameStaffRecentChange) => {
    try {
      const response = await getStaffRecentChangeHistory(record.Id, actionName);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };
  @action handleChangeObj = (_, event, fieldName) => {
    const data = event?.props?.data;
    // PIC 7 & PIC 8
    const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
    if (fieldName === 'PIC_5') {
      return (store[fieldName] = {
        picId: data_?.Id,
        picName: data_?.FullName,
        inChargeOf: 4,
      });
    }
    if (fieldName === 'PIC_6') {
      return (store[fieldName] = {
        picId: data_?.Id,
        picName: data_?.FullName,
        inChargeOf: 6,
      });
    }
    if (fieldName === 'PIC_7') {
      return (store[fieldName] = {
        picId: data_?.Id,
        picName: data_?.FullName,
        inChargeOf: 14,
      });
    }
    if (fieldName === 'PIC_8') {
      return (store[fieldName] = {
        picId: data_?.Id,
        picName: data_?.FullName,
        inChargeOf: 7,
      });
    }
    if (fieldName === 'TypistApproverdBy') {
      return (store.assessmentInfo = {
        ...store.assessmentInfo,
        TypistApproverdBy: { Id: data_?.Id, FullName: data_?.FullName },
      });
    }
    return (store[fieldName] = data_);
  };

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'DictationReceived') {
        const dictationAttachments = store.renderAttachment(itemModel.AssessmentAttachment, 2);
        if (value && !dictationAttachments.length) {
          if (api.isDoctor()) {
            store.showNotificationError('Dictation files must be attached first.');
            return (store.assessmentInfo = {
              ...itemModel,
              [fieldName]: false,
              DictationReceivedDate: null,
              SendConfirmationToSpecialist: false,
              DictationSentToTypist: false,
              DictationOption: 0,
            });
          } else {
            store.toggleModal(true, {
              modalType: 'confirm',
              message: 'Remember to attach dictation files to send to typist.',
            })();
          }
        }
        if (
          value &&
          store.assessmentInfo.IsAppointmentConfirmed &&
          store.assessmentInfo.AppointmentConfirmedDate &&
          store.assessmentInfo.Attendance === 0
        ) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            Attendance: 1,
            AttendedDate: store.assessmentInfo.AppointmentConfirmedDate,
            DictationReceivedDate: moment(),
            SendConfirmationToSpecialist: true,
            DictationSentToTypist: false,
            DictationOption: 0,
          });
        }
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          DictationReceivedDate: moment(),
          SendConfirmationToSpecialist: true,
          DictationSentToTypist: false,
          DictationOption: 0,
        });
      }

      if (fieldName === 'F_RDCSameBookingCM') {
        if (value === true) {
          store.Report_Delivery_Contact_1 = store.bookingCaseManager;
          store.handleRefetchlistRDC_1_CM({
            listRDC_1_CMKeyword: store.bookingCaseManager.Id,
          });
        }
      }

      if (fieldName === 'DictationSentToTypist') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          DictationReceivedDate: moment(),
        };
        return this.handleDictationSendToTypist();
      }
      if (fieldName === 'ReportCompleted') {
        if (
          value &&
          store.assessmentInfo.IsAppointmentConfirmed &&
          store.assessmentInfo.AppointmentConfirmedDate &&
          store.assessmentInfo.Attendance === 0
        ) {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            Attendance: 1,
            AttendedDate: store.assessmentInfo.AppointmentConfirmedDate,
          };
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          };
        }

        return store.handleReportCompletedChange();
      }
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });
  handleChangeCheckBoxAllowCMViewAmendedReportChanged = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(
        itemModel.AssessmentAttachment,
        LOCAL_CONSTANT.ReportType_AmendedReportCompleted,
      );
      if (!store.checkDelayPrepaymentRequired()) {
        return;
      }

      if (value) {
        if (!store.Report_Delivery_Contact_1?.Email && !store.Report_Delivery_Contact_2?.Email) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'At least one Report Delivery Contact needs to be completed.',
          })();
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          };
          return;
        }
        if (!attachmentModel.length) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'You must attach amended reports for Report Receiving Contact 2 to view.',
          })();
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          });
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Are you sure to allow Report Receiving Contact 2 to view amended completed reports?',
            onOk: () =>
              action(() => {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                };
              })(),
          })();
        }
      } else {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
      }
    });
  handleChangeCheckBoxAllowSolicitorViewAmendedReportChanged = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(
        itemModel.AssessmentAttachment,
        LOCAL_CONSTANT.ReportType_AmendedReportCompleted,
      );
      if (!store.checkDelayPrepaymentRequired()) {
        return;
      }
      if (value) {
        if (!attachmentModel.length) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'You must attach amended reports for Report Receiving Contact 1 to view.',
          })();
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          };
          return;
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Are you sure to allow Report Receiving Contact 1 to view amended completed reports?',
            onOk: () =>
              action(() => {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                };
              })(),
          })();
        }
      } else {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
      }
    });
  handleAllowCMViewReportChanged = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(
        itemModel.AssessmentAttachment,
        LOCAL_CONSTANT.ReportType_Completed,
      );
      if (!store.checkDelayPrepaymentRequired()) {
        return;
      }

      if (value) {
        if (!store.Report_Delivery_Contact_1?.Email && !store.Report_Delivery_Contact_2?.Email) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'At least one Report Delivery Contact needs to be completed.',
          })();
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          });
        }
        if (attachmentModel.length === 0) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'You must attach reports for Report Receiving Contact 2 to view.',
          })();
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          });
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Are you sure to allow Report Receiving Contact 2 to view completed reports?',
            onOk: () =>
              action(() => {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                };
              })(),
          })();
        }
      } else {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        });
      }
    });
  handleAllowCMSolicitorViewReportChanged = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(
        itemModel.AssessmentAttachment,
        LOCAL_CONSTANT.ReportType_Completed,
      );
      if (!store.checkDelayPrepaymentRequired()) {
        return;
      }

      if (value) {
        if (attachmentModel.length === 0) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'You must attach reports for Report Receiving Contact 1 to view.',
          })();
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          };
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Are you sure to allow Report Receiving Contact 1 to view completed reports?',
            onOk: () =>
              action(() => {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                };
              })(),
          })();
        }
      } else {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: false,
        };
      }
    });
  handleChangeField = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'TypistTranscribingTime') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value ? value.replace(/\D/g, '').replace(/[^\d]/g, '') : 0,
        };
        return;
      }
      if (fieldName === 'DictationOnedriveLinkFile') {
        store.validateInput(value, 'url', fieldName);
      }
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      return;
    });

  handleReportSendToTypist = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      if (value) {
        if (itemModel.DictationTypistID) {
          store.assessmentInfo = {
            ...itemModel,
            DictationSentToTypist: true,
            [fieldName]: true,
          };
        } else {
          store.showNotificationError('Please select a Typist');
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          };
        }
      } else {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
      }
    });
  handleDictationSendToTypist = fieldName =>
    action(async event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);

      if (!itemModel.DictationTypistID) {
        store.showNotificationError('Please select a typist.');
        return;
      }
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      if (value && !itemModel.SentToTypist) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to send dictation files, report templates and previous reports to the typist?',
          onOk: () =>
            action(() => {
              store.assessmentInfo = {
                ...itemModel,
                DictationSentToTypist: true,
                SentToTypist: true,
              };
              this.handleReportSendToTypist('SentToTypist');
            })(),
        })();
      }
    });
  validateLinkIsValid = link => {
    const v = new Validator({ link }, { link: 'url' });
    return v.passes();
  };

  handleReportDraftReceivedChange = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(itemModel.AssessmentAttachment, 3);

      if (value && attachmentModel.length === 0) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please remember to attach report draft',
        })();
      } else if (value && (!itemModel.EmailReportDraftToDoctorBE || !itemModel.EmailReportDraftToDoctor)) {
        return store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to attach report draft to notification email to provider?',
          onOk: () =>
            action(() => {
              if (
                itemModel.IsAppointmentConfirmed &&
                itemModel.AppointmentConfirmedDate &&
                itemModel.Attendance === 0
              ) {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                  EmailReportDraftToDoctorBE: true,
                  Attendance: 1,
                  AttendedDate: itemModel.AppointmentConfirmedDate,
                  EmailReportDraftToDoctor: true,
                };
              } else {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                  EmailReportDraftToDoctorBE: true,
                  EmailReportDraftToDoctor: true,
                };
              }
              return ['ReportDraftReceivedDate', 'ReportDraftSentDate'].map(i =>
                this.handleFillCurrentDate('ReportDraftReceived', i),
              );
            })(),
          onCancel: () =>
            action(() => {
              if (
                itemModel.IsAppointmentConfirmed &&
                itemModel.AppointmentConfirmedDate &&
                itemModel.Attendance === 0
              ) {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                  Attendance: 1,
                  AttendedDate: itemModel.AppointmentConfirmedDate,
                };
              } else {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: true,
                };
              }
              return ['ReportDraftReceivedDate', 'ReportDraftSentDate'].map(i =>
                this.handleFillCurrentDate('ReportDraftReceived', i),
              );
            })(),
        })();
      }
      if (!value) {
        store.assessmentInfo = {
          ...store.assessmentInfo,
          EmailReportDraftToDoctorBE: false,
          EmailReportDraftToDoctor: false,
          EmailReportDraftToDoctorPE: false,
          [fieldName]: value,
        };
        return ['ReportDraftReceivedDate', 'ReportDraftSentDate'].map(i =>
          this.handleFillCurrentDate('ReportDraftReceived', i),
        );
      }
      store.assessmentInfo = {
        ...store.assessmentInfo,
        [fieldName]: value,
      };
      return ['ReportDraftReceivedDate', 'ReportDraftSentDate'].map(i =>
        this.handleFillCurrentDate('ReportDraftReceived', i),
      );
    });
  handleChangeEmailReportDraftToDoctor = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      const attachmentModel = store.renderAttachment(itemModel.AssessmentAttachment, 3);
      const specialistModel = toJS(store.specialist);
      if (value && attachmentModel.length === 0) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'You must attach report draft to send to Specialist.',
        })();
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: false,
        };
        return;
      } else {
        if (specialistModel && specialistModel.Email2 && value) {
          store.assessmentInfo = {
            ...itemModel,
            EmailReportDraftToDoctorBE: true,
            [fieldName]: value,
          };
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          };
        }
      }
    });

  handleCheckReportReturnedFromDoctorChanged = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      if (value) {
        if (itemModel.IsAppointmentConfirmed && itemModel.AppointmentConfirmedDate && itemModel.Attendance === 0) {
          store.assessmentInfo = {
            ...itemModel,
            ReportCompletedDate: moment(),
            [fieldName]: value,
            Attendance: 1,
            AttendedDate: itemModel.AppointmentConfirmedDate,
          };
        } else {
          store.assessmentInfo = {
            ...itemModel,
            ReportCompletedDate: moment(),
            [fieldName]: value,
          };
        }
        if (store.CheckWiRo()) {
          store.handleProcessWiroWarning(1);
        }
      } else
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
    });

  handResendReportDraft = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (
      itemModel.EmailReportDraftToDoctor &&
      !itemModel.EmailReportDraftToDoctorBE &&
      !itemModel.EmailReportDraftToDoctorPE &&
      !itemModel.EmailReportDraftToDoctorDateSent
    ) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'At least one specialist email should be selected.',
      });
    } else {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to resend report draft to provider/specialist?',
        onOk: () => {
          store.toggleModal(false, {})();
          customFetch('/MedicalService/ResendEmailDraftReportToDoctor', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: itemModel.Id,
              emailReportDraftToDoctor: itemModel.EmailReportDraftToDoctor,
              sendToBE: itemModel.EmailReportDraftToDoctorBE,
              sendToPE: itemModel.EmailReportDraftToDoctorPE,
            }),
          })
            .then(res => {
              if (res.status === 'success') {
                notification.destroy();
                notification.success({
                  message: 'Success',
                  description: 'Resend report draft to specialist',
                });
                store.handleChangeValue('EmailReportDraftToDoctorDateSent', moment());
              }
            })
            .catch(() => {
              notification.destroy();
              notification.error({
                message: 'Error',
                description: 'Resend report draft to specialist',
              });
            });
        },
      })();
    }
  };

  handleResendReport = reportType => {
    const itemModel = toJS(store.assessmentInfo);
    var msg = 'Do you want to resend completed report to Report Receiving Contact 1?';
    if (reportType === 2) {
      msg = 'Do you want to resend completed report to Report Receiving Contact 2?';
    }
    if (reportType === 3) {
      msg = 'Do you want to resend completed report to Specialist?';
    }
    store.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => {
        customFetch('/MedicalService/ResendReports', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, type: reportType }),
        })
          .then(res => {
            if (res.status === 'success') {
              notification.success({
                message: 'Success',
                description: 'Resend report',
              });
              if (reportType === 1) {
                store.handleChangeValue('EmailToCMSolicitorViewReportRecent', moment(res.ResendDatetime));
              } else if (reportType === 2) {
                store.handleChangeValue('EmailToCMsViewReportRecent', moment(res.ResendDatetime));
              } else if (reportType === 3) {
                store.handleChangeValue('SentDateAllowSpecialistViewReport', moment(res.ResendDatetime));
              }
            }
          })
          .catch(() => {
            notification.error({
              message: 'Error',
              description: 'Resend report',
            });
          });
      },
    })();
  };

  handleResendAmendedReport = reportType => {
    const itemModel = toJS(store.assessmentInfo);
    var msg = 'Do you want to resend amended report to Report Receiving Contact 1?';
    if (reportType === 2) {
      msg = 'Do you want to resend amended report to Report Receiving Contact 2?';
    }
    store.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => {
        customFetch('/MedicalService/ResendAmendedReports', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, type: reportType }),
        })
          .then(res => {
            if (res.status === 'success') {
              notification.success({
                message: 'Success',
                description: 'Resend Amended report',
              });
              if (reportType === 1) {
                store.handleChangeValue('SentSolicitorAmendedReportsDate', moment(res.ResendDatetime));
              } else {
                store.handleChangeValue('SentAmendedReportsDate', moment(res.ResendDatetime));
              }
            }
          })
          .catch(() => {
            notification.error({
              message: 'Error',
              description: 'Resend Amended report',
            });
          });
      },
    })();
  };

  onclickCheckReportSampleAndTemplate = async () => {
    try {
      const itemModel = toJS(store.assessmentInfo);
      const assessmentDoctorTemplate = store.assessmentInfo.AssessmentAttachment
        ? toJS(store.assessmentInfo.AssessmentAttachment).filter(
            i => i.ReportType === LOCAL_CONSTANT.ReportType_DoctorReportTemplate,
          )
        : [];
      const assessmentDoctorSample = store.assessmentInfo.AssessmentAttachment
        ? toJS(store.assessmentInfo.AssessmentAttachment).filter(
            i => i.ReportType === LOCAL_CONSTANT.ReportType_DoctorTemplate,
          )
        : [];
      if (!!assessmentDoctorTemplate.length && !!assessmentDoctorSample.length) {
        notification.destroy();
        notification.error({
          message: 'Error',
          description:
            'Please remove current attached report templates and samples before copying from specialist profile.',
        });
        return;
      }
      const response = await customFetch('/MedicalService/GetDoctorTemplateAndSample', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: itemModel.Id }),
      });

      if (response.status === 'success') {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Matching report samples are found. Do you want to send to typist? ',
          onOk: () => {
            const { doctorSample, doctorTemplate } = response;
            const doctorSample_ = doctorSample
              ? doctorSample.map(i => ({
                  Id: 0,
                  Title: i.Title,
                  FileName: i.FileName,
                  ReportType: LOCAL_CONSTANT.ReportType_DoctorTemplate,
                }))
              : [];
            const DoctorSampleAttachment = [...doctorSample_];
            const doctorTemplate_ = doctorTemplate
              ? doctorTemplate.map(i => ({
                  Id: 0,
                  Title: i.Title,
                  FileName: i.FileName,
                  ReportType: LOCAL_CONSTANT.ReportType_DoctorReportTemplate,
                }))
              : [];
            const DoctorReportTemplateAttachment = [...doctorTemplate_];

            if (!assessmentDoctorTemplate.length) {
              const assessmentAttachment = store.assessmentInfo.AssessmentAttachment
                ? toJS(store.assessmentInfo.AssessmentAttachment)
                : [];
              store.handleChangeValue(
                'AssessmentAttachment',
                [...assessmentAttachment, ...DoctorReportTemplateAttachment].map((i, idx) => ({
                  ...i,
                  key: idx,
                })),
              );
            }
            if (!assessmentDoctorSample.length) {
              const assessmentAttachment = store.assessmentInfo.AssessmentAttachment
                ? toJS(store.assessmentInfo.AssessmentAttachment)
                : [];
              store.handleChangeValue(
                'AssessmentAttachment',
                [...assessmentAttachment, ...DoctorSampleAttachment].map((i, idx) => ({
                  ...i,
                  key: idx,
                })),
              );
            }
          },
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'No matching report samples found.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'No matching report samples found.',
      });
    }
  };

  onclickResendSentDateToTypist = () => {
    const itemModel = toJS(store.assessmentInfo);
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend dictation files, report templates and previous reports to the typist?',
      onOk: () => {
        customFetch('/MedicalService/ResendNotifyTypistAvailableDictationFiles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.setFieldsValue({
              assessmentInfo: {
                ...itemModel,
                SentDateToTypist: moment(response.ResendDatetime),
              },
            });
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend report',
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend report',
            });
          }
        });
      },
    })();
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      if (Object.keys(item).length > 1) {
        const arr_ = arr.filter(i => i[key] !== item[key]);
        const item_ = toJS(item);
        return [...arr_, item_];
      } else {
        return arr;
      }
    } else if (key && item && item[key] && !!!arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  handleSearch = fieldName =>
    action(value => {
      if (fieldName === 'bookingCaseManager') {
        return value
          ? store.handleRefetchBookingCM({ bookingCMKeyword: value })
          : store.handleRefetchBookingCM({
              bookingCMKeyword: store.bookingCaseManager?.Id || '',
              bookingCMNumItemPerPage: 30,
            });
      }

      if (fieldName === 'Report_Delivery_Contact_2') {
        return value
          ? store.handleRefetchlistRDC_2_CM({ listRDC_2_CMKeyword: value })
          : store.handleRefetchlistRDC_2_CM({
              listRDC_2_CMKeyword: store.bookingCaseManager?.Id || '',
              bookingCMNumItemPerPage: 30,
            });
      }

      if (fieldName === 'Report_Delivery_Contact_1') {
        return value
          ? store.handleRefetchlistRDC_1_CM({ listRDC_1_CMKeyword: value })
          : store.handleRefetchlistRDC_1_CM({
              listRDC_1_CMKeyword: store.bookingCaseManager?.Id || '',
              bookingCMNumItemPerPage: 30,
            });
      }
    });

  handleScroll = key =>
    action(event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
        store[key] += 20;
      }
    });

  handleChangeStatusAttendance = () => {
    console.log('handleChangeStatusAttendance');
  };

  handleResendNotice = (sendtype = 1) => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend reviewed/completed report notice?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendReviewedNotice', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, sendtype }),
        })
          .then(res => {
            if (res.status === 'success') {
              if (sendtype === 2) {
                store.handleChangeValue('SentDateNotifyCorrectedReportToSpecialist', moment());
                store.handleChangeValue('SentByNotifyCorrectedReportToSpecialist', res.sentBy);
              } else {
                store.handleChangeValue('SentDateNotifyCorrectedReportToStaff', moment());
                store.handleChangeValue('SentByNotifyCorrectedReportToStaff', res.sentBy);
              }

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Reviewed/Completed Report Notice',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Reviewed/Completed Report Notice',
            });
          });
      },
    })();
  };

  handleChangeFieldRadioBox = fieldName =>
    action(event => {
      const value = event.target.value;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'DictationOption') {
        store.bindCommisionRate();
      }
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  handleFieldRecentChange = async (record, fieldName) => {
    try {
      let title = '';
      if (fieldName === 'DictationOption') title = 'Dictation and Report Typing by';
      if (fieldName === 'CausationQualityCheck') title = 'Causation Quality Check';
      const response = await viewFieldChangeHistory(record.Id, fieldName, 1, 1000);
      const dataFieldChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, { modalType: 'fieldChange', title: title, dataFieldChange })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  getAudioTranscription = attachment => {
    // const fileIds = attachments.filter(i => i.Id).map(i => i.Id);
    store.getAudioTranscription(attachment.Id);
  };

  render() {
    const staffs = toJS(store.listStaff);
    const itemModel = toJS(store.assessmentInfo);
    const pic_5 = toJS(store.PIC_5);
    const pic_6 = toJS(store.PIC_6);
    const pic_7 = toJS(store.PIC_7);
    const pic_8 = toJS(store.PIC_8);
    const typists = toJS(store.listTypist);
    //const solicitor = toJS(store.bookingSolicitor);
    const lockRule = store.handleLockReportCompleteCM(itemModel);
    const listRDC1CM = toJS(store.listRDC_1_CM);
    const listRDC2CM = toJS(store.listRDC_2_CM);
    const icareLookupList = toJS(store.icareLookupList);
    const insurerCompanies = toJS(store.listCompanyInsurer);
    const reasonReferral = toJS(store.listReasonReferral);

    const { handleLockReportCompleteCM } = store;
    const { lockDoctorDirect, lockDoctor, lockTypist } = handleLockReportCompleteCM(itemModel);

    const dictationFiles = store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Dictation);
    //
    return (
      <Form name="dictations-and-report-information" className="grid-style">
        <Collapse bordered={false} defaultActiveKey={api.isCaseManager() ? ['4'] : api.isTypist() ? ['1'] : []}>
          {// !api.isDoctor() &&
          !api.isCaseManager() && (
            <Collapse.Panel
              key="1"
              header={
                <Header
                  title="Dictation & Typist"
                  statuses={[
                    {
                      key: 'ime-dictation-opt',
                      isChecked: itemModel.DictationOption === 0,
                      text: itemModel.DictationOption === 0 ? 'Dictation by Medicolegal Provider' : '',
                    },
                    {
                      key: 'ime-dictation-received',
                      isChecked: itemModel.DictationReceived,
                      text: api.isDoctor() ? 'Dictations Sent' : 'Received from Specialist',
                      date: itemModel.DictationReceivedDate,
                      shouldFormatDate: true,
                    },
                    {
                      key: 'ime-dictation-sent-typist',
                      isChecked: itemModel.DictationSentToTypist,
                      text: 'Sent To Typist',
                      date: itemModel.SentDateToTypist,
                      shouldFormatDate: true,
                    },
                  ]}
                  isRequired={false}
                />
              }
            >
              {!(!itemModel.isMagDirect && api.isCaseManager() && !lockTypist) && (
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    onChange={this.handleChangeCheckBox('DictationReceived')}
                    className="checkbox-session"
                    checked={itemModel.DictationReceived}
                    disabled={(lockDoctor && itemModel.isLocked) || api.isDoctor()}
                  >
                    {api.isDoctor() ? 'Dictations Sent to Medicolegal Provider' : 'Dictation Received'}
                  </Checkbox>
                </div>
              )}
              <div className="fluid-field" />
              {!api.isCaseManager() && (
                <>
                  <Form.Item
                    label={
                      <React.Fragment>
                        Dictation and Report Typing by
                        <button
                          className="field-actions"
                          onClick={() => this.handleFieldRecentChange(itemModel, 'DictationOption')}
                        >
                          View changes
                        </button>
                      </React.Fragment>
                    }
                  >
                    <Radio.Group
                      value={itemModel.DictationOption}
                      className="dictation-type-radio-group"
                      onChange={this.handleChangeFieldRadioBox('DictationOption')}
                      disabled={(lockDoctor || lockDoctorDirect) && itemModel.isLocked}
                    >
                      <Radio value={0} className="dictation-type-radio">
                        Medicolegal Provider
                      </Radio>
                      <Radio value={1} className="dictation-type-radio">
                        Specialist
                      </Radio>
                      <Radio value={2} className="dictation-type-radio">
                        Not Sure
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Divider className="fluid-field" />
                </>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 5
                      <button
                        className="field-actions"
                        onClick={() =>
                          this.handleRecentChange(
                            itemModel,
                            'Status_DictationReceived',
                            DEFAULT_COLUMNS_RECENT_CHANGE,
                            DEFAULT_FIELD_NAME_RECENT_CHANGE,
                          )
                        }
                      >
                        Recent changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    value={pic_5?.picId}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_5')}
                  >
                    {staffs.itemList &&
                      staffs.itemList.length &&
                      staffs.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.FullName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {!(!itemModel.isMagDirect && api.isCaseManager() && !lockTypist) && api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item
                  label={api.isDoctor() ? 'Dictation Sent Date' : 'Dictation Received Date'}
                  required={itemModel.DictationReceived}
                  validateStatus={itemModel.DictationReceived && !itemModel.DictationReceivedDate ? 'error' : null}
                  help={itemModel.DictationReceived && !itemModel.DictationReceivedDate ? 'This field is required' : ''}
                >
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.DictationReceivedDate)}
                    onChange={this.handleChangeField('DictationReceivedDate')}
                    disabled={itemModel.isLocked}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              )}
              <div className="fluid-field" />
              <FieldUpload
                uploadLoading={store.loadingUpload}
                attachments={dictationFiles}
                reportType={LOCAL_CONSTANT.ReportType_Dictation}
                label={
                  <>
                    <span>Attached Dictations</span>
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content={
                        <div>
                          <div>
                            {`Only ${TRANSCRIPT_AUDIO_FILE_EXTENSION.join(
                              ', ',
                            ).toUpperCase()} dictation files can be transcribed. Dictation app only shows after dictation
                            files are saved.`}
                          </div>
                          <div>
                            <b>Dictation commands: </b>New paragraph, new line, open bracket <code>(</code>, close
                            bracket <code>)</code>, full stop <code>.</code>, open quote <code>"</code>, close quote{' '}
                            <code>"</code>
                          </div>
                        </div>
                      }
                      position="top center"
                      wide="very"
                    />
                  </>
                }
                multiUpload={true}
                disabled={itemModel.isLocked && !api.isDoctor()}
                functionCallback={() => store.updateFileNameAttachDictation()}
              />

              {!(!itemModel.isMagDirect && api.isCaseManager() && !lockTypist) && !api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item
                  label={api.isDoctor() ? 'Dictation Sent Date' : 'Dictation Received Date'}
                  required={itemModel.DictationReceived}
                  validateStatus={itemModel.DictationReceived && !itemModel.DictationReceivedDate ? 'error' : null}
                  help={itemModel.DictationReceived && !itemModel.DictationReceivedDate ? 'This field is required' : ''}
                >
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.DictationReceivedDate)}
                    onChange={this.handleChangeField('DictationReceivedDate')}
                    disabled={itemModel.isLocked}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              )}

              {(api.isAdminOrMagStaffOrAccounting() || lockTypist) && (
                <>
                  <Form.Item
                    className="fluid-field"
                    validateStatus={!store.validateField(itemModel.DictationOnedriveLinkFile, 'url') ? 'error' : null}
                    help={
                      !store.validateField(itemModel.DictationOnedriveLinkFile, 'url')
                        ? 'Download link is not valid'
                        : ''
                    }
                    label={
                      <>
                        Or Share Download Link
                        <button
                          className="field-actions"
                          disabled={
                            !store.validateField(itemModel.DictationOnedriveLinkFile, 'url') ||
                            !itemModel.DictationOnedriveLinkFile
                          }
                          onClick={() => {
                            window.open(itemModel.DictationOnedriveLinkFile, '_blank');
                          }}
                        >
                          Open Link
                        </button>
                      </>
                    }
                  >
                    <Input.TextArea
                      rows={3}
                      value={itemModel.DictationOnedriveLinkFile}
                      onChange={this.handleChangeField('DictationOnedriveLinkFile')}
                      disabled={itemModel.isLocked}
                    />
                  </Form.Item>

                  <AudioTranscript
                    files={dictationFiles}
                    getAudioTranscription={store.getAudioTranscription}
                    isLoading={store.isLoadingAudioTranscribe}
                  />
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      disabled={itemModel.isLocked || !!itemModel.SendConfirmationToSpecialistDate}
                      checked={itemModel.SendConfirmationToSpecialist}
                      onChange={this.handleChangeCheckBox('SendConfirmationToSpecialist')}
                    >
                      Send Confirmation to Specialist{' '}
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Dictation receipt confirmation will be sent to specialist (MAIL-121)"
                        position="top center"
                        wide="very"
                      />{' '}
                    </Checkbox>
                    <span style={{ color: '#107fc9' }}>
                      {!!itemModel.SendConfirmationToSpecialistDate &&
                        ` ${renderDate(itemModel.SendConfirmationToSpecialistDate, false, true)}`}
                      {!!itemModel.SendConfirmationToSpecialistDateByUser &&
                        ` - By ${this.renderStaffName(itemModel.SendConfirmationToSpecialistDateByUser)}`}
                    </span>
                  </div>
                  <Form.Item className="fluid-field" label="Comments">
                    <Input.TextArea
                      rows={3}
                      value={itemModel.DictationComment}
                      onChange={this.handleChangeField('DictationComment')}
                      disabled={itemModel.isLocked}
                    />
                  </Form.Item>
                </>
              )}
              {((api.isAdminOrMagStaffOrAccounting() && itemModel.DictationReceived) || lockTypist) && (
                <>
                  <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                      checked={itemModel.DictationSentToTypist}
                      disabled={api.isTypist()}
                      className="checkbox-session"
                      onChange={this.handleDictationSendToTypist('DictationSentToTypist')}
                    >
                      Dictation Sent To Typist
                    </Checkbox>
                  </div>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      label="Typist"
                      required={itemModel.DictationSentToTypist}
                      validateStatus={itemModel.DictationSentToTypist && !itemModel.DictationTypistID ? 'error' : null}
                      help={
                        itemModel.DictationSentToTypist && !itemModel.DictationTypistID ? 'This field is required' : ''
                      }
                    >
                      <Select
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        value={itemModel.DictationTypistID}
                        onChange={this.handleChangeField('DictationTypistID')}
                      >
                        {typists.itemList &&
                          typists.itemList.length &&
                          typists.itemList.map(i => (
                            <Select.Option key={i.Id} value={i.Id}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  <div className="fluid-field" />
                  {(itemModel.DictationReceived || api.isDoctor()) && (
                    <>
                      <FieldUpload
                        uploadLoading={store.loadingUpload}
                        label="Attached Dictation Instruction"
                        attachments={store.renderAttachment(
                          itemModel.AssessmentAttachment,
                          LOCAL_CONSTANT.ReportType_DictationInstruction,
                        )}
                        reportType={LOCAL_CONSTANT.ReportType_DictationInstruction}
                        multiUpload={true}
                        disabled={itemModel.isLocked}
                        disabledDelete={itemModel.isLocked}
                      />
                      <Form.Item>
                        <Button
                          disabled={!itemModel.DictationReceived}
                          onClick={() => {
                            this.onclickCheckReportSampleAndTemplate();
                          }}
                          style={{ marginTop: '25px', width: '100%' }}
                        >
                          Attach Report Templates/Samples from Specialist Profile
                        </Button>
                      </Form.Item>
                      <FieldUpload
                        uploadLoading={store.loadingUpload}
                        label="Attached Report Templates"
                        disabled={itemModel.isLocked}
                        attachments={store.renderAttachment(
                          itemModel.AssessmentAttachment,
                          LOCAL_CONSTANT.ReportType_DoctorReportTemplate,
                        )}
                        reportType={LOCAL_CONSTANT.ReportType_DoctorReportTemplate}
                        multiUpload={true}
                        disabledDelete={itemModel.isLocked}
                      />
                      <FieldUpload
                        uploadLoading={store.loadingUpload}
                        attachments={store.renderAttachment(
                          itemModel.AssessmentAttachment,
                          LOCAL_CONSTANT.ReportType_DoctorTemplate,
                        )}
                        reportType={LOCAL_CONSTANT.ReportType_DoctorTemplate}
                        label="Attached Report Samples"
                        disabledDelete={itemModel.isLocked}
                        multiUpload={true}
                        disabled={itemModel.isLocked}
                      />
                    </>
                  )}
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      disabled={
                        itemModel.isLocked ||
                        (!itemModel.DictationSentToTypist && !itemModel.DictationTypistID) ||
                        !!itemModel.SentDateToTypist
                      }
                      checked={itemModel.SentToTypist}
                      onChange={this.handleReportSendToTypist('SentToTypist')}
                    >
                      Send Files to Typist{' '}
                      {this.renderPopup('Dictation files will be sent to typist (MAIL-102, 102-Resend)')}
                    </Checkbox>
                    {!!itemModel.SentDateToTypist && (
                      <span style={{ color: '#107fc9' }}>{renderDate(itemModel.SentDateToTypist, false, true)}</span>
                    )}
                    {!!itemModel.SentToTypistByUser && (
                      <span style={{ color: '#107fc9' }}>
                        - By {this.renderStaffName(itemModel.SentToTypistByUser)}
                      </span>
                    )}
                    {!!itemModel.SentDateToTypist && (
                      <Button
                        className="blue"
                        disabled={!itemModel.SentDateToTypist}
                        onClick={() => {
                          this.onclickResendSentDateToTypist();
                        }}
                        style={{ marginLeft: 5 }}
                      >
                        Resend Dictation Files
                      </Button>
                    )}
                  </div>
                  {(itemModel.DictationReceived || api.isDoctor()) && (
                    <>
                      <FieldUpload
                        uploadLoading={store.loadingUpload}
                        label="Attached Typed Report"
                        attachments={store.renderAttachment(
                          itemModel.AssessmentAttachment,
                          LOCAL_CONSTANT.ReportType_TypistReport,
                        )}
                        reportType={LOCAL_CONSTANT.ReportType_TypistReport}
                        multiUpload={true}
                        disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isTypist()}
                      />
                    </>
                  )}
                  <div className="fluid-field" />
                  <Form.Item label="Typist's transcribing time (minutes)">
                    <Input
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                      value={itemModel.TypistTranscribingTime}
                      onChange={this.handleChangeField('TypistTranscribingTime')}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item label="Typist's submitting date">
                    <DatePicker
                      size="large"
                      value={renderDate(itemModel.TypistSubmittingDate)}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                      onChange={this.handleChangeField('TypistSubmittingDate')}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item label="Approved by">
                    <Select
                      size="large"
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.TypistApproverdBy?.Id}
                      onChange={(value, event) => this.handleChangeObj(value, event, 'TypistApproverdBy')}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                    >
                      {staffs.itemList &&
                        staffs.itemList.length &&
                        staffs.itemList.map(i => (
                          <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                            {i.FullName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Approved on">
                    <DatePicker
                      size="large"
                      value={renderDate(itemModel.TypistApproverdOn)}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      onChange={this.handleChangeField('TypistApproverdOn')}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Divider className="fluid-field" />
                </>
              )}
            </Collapse.Panel>
          )}
          {!api.isCaseManager() && (
            <Collapse.Panel
              key="2"
              header={
                <Header
                  title="Report Draft to Specialist"
                  isChecked={itemModel.ReportDraftReceived}
                  text="Sent to Specialist"
                  date={itemModel.ReportDraftReceivedDate}
                  isRequired={false}
                />
              }
            >
              {!(!itemModel.isMagDirect && api.isCaseManager() && !lockTypist) && (
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    disabled={itemModel.isLocked || itemModel.Attendance !== CONSTANTS.Attendance_Attended}
                    className="checkbox-session"
                    checked={itemModel.ReportDraftReceived}
                    onChange={this.handleReportDraftReceivedChange('ReportDraftReceived')}
                  >
                    Report Draft Sent to Specialist
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 6
                      <button
                        onClick={() =>
                          this.handleRecentChange(
                            itemModel,
                            'Status_ReportDraftReceived',
                            DEFAULT_COLUMNS_RECENT_CHANGE,
                            DEFAULT_FIELD_NAME_RECENT_CHANGE,
                          )
                        }
                        className="field-actions"
                      >
                        Recent changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    value={pic_6?.picId}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_6')}
                  >
                    {staffs.itemList &&
                      staffs.itemList.length &&
                      staffs.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.FullName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {!api.isDoctor() && !api.isTypist() && (
                <Form.Item label="Report Draft Received Date">
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.ReportDraftReceivedDate)}
                    onChange={this.handleChangeField('ReportDraftReceivedDate')}
                    format="DD/MM/YYYY"
                    disabled={itemModel.isLocked || !itemModel.ReportDraftReceived}
                  />
                </Form.Item>
              )}
              {(api.isTypist() || api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
                <FieldUpload
                  uploadLoading={store.loadingUpload}
                  label="Attached Report Draft"
                  attachments={store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Draft)}
                  multiUpload={true}
                  disabled={itemModel.isLocked}
                  disabledDelete={itemModel.isLocked}
                  reportType={LOCAL_CONSTANT.ReportType_Draft}
                />
              )}
              <Form.Item label={<React.Fragment>Report Draft Sent Date</React.Fragment>}>
                <DatePicker
                  size="large"
                  value={renderDate(itemModel.ReportDraftSentDate)}
                  onChange={this.handleChangeField('ReportDraftSentDate')}
                  format="DD/MM/YYYY"
                  disabled={itemModel.isLocked || !itemModel.ReportDraftReceived}
                />
              </Form.Item>
              {api.isAdminOrMagStaffOrAccounting() || api.isTypist() ? (
                <></>
              ) : (
                <Form.Item className="fluid-field checkbox-field">
                  <Checkbox
                    disabled={
                      itemModel.isLocked ||
                      !itemModel.ReportDraftReceived ||
                      !!itemModel.EmailReportDraftToBEDoctorDateSent ||
                      !(store.specialist && store.specialist.BusinessEmail)
                    }
                    checked={itemModel.EmailReportDraftToDoctorBE}
                    onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctorBE')}
                    style={{ marginTop: 25 }}
                  >
                    {itemModel.ReportDraftReceived &&
                      !itemModel.EmailReportDraftToDoctorBE &&
                      !itemModel.EmailReportDraftToDoctor &&
                      !itemModel.EmailReportDraftToDoctorPE && <span style={{ color: 'red' }}>*</span>}
                    Send to Specialist Business Email{' '}
                    {store.specialist?.BusinessEmail ? '(' + store.specialist?.BusinessEmail + ')' : ''}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                    {itemModel.EmailReportDraftToBEDoctorDateSent && (
                      <>
                        <span style={{ color: '#107fc9' }}>
                          {renderDate(itemModel.EmailReportDraftToBEDoctorDateSent, false, true)}
                        </span>
                        {itemModel.EmailReportDraftToBEDoctorByUser && (
                          <span style={{ color: '#107fc9' }}>
                            - By {this.renderStaffName(itemModel.EmailReportDraftToBEDoctorByUser)}
                          </span>
                        )}
                      </>
                    )}
                  </Checkbox>
                  <br />
                  <Checkbox
                    disabled={
                      itemModel.isLocked ||
                      !itemModel.ReportDraftReceived ||
                      !!itemModel.EmailReportDraftToDoctorDateSent ||
                      !(store.specialist && store.specialist.PersonalEmail)
                    }
                    checked={itemModel.EmailReportDraftToDoctorPE}
                    onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctorPE')}
                    style={{ marginTop: 10 }}
                  >
                    {itemModel.ReportDraftReceived &&
                      !itemModel.EmailReportDraftToDoctorBE &&
                      !itemModel.EmailReportDraftToDoctor &&
                      !itemModel.EmailReportDraftToDoctorPE && <span style={{ color: 'red' }}>*</span>}
                    Send to Specialist Personal Email{' '}
                    {store.specialist?.PersonalEmail ? '(' + store.specialist?.PersonalEmail + ')' : ''}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                    {itemModel.EmailReportDraftToPEDoctorDateSent && (
                      <>
                        <span style={{ color: '#107fc9' }}>
                          {renderDate(itemModel.EmailReportDraftToPEDoctorDateSent, false, true)}
                        </span>
                        {itemModel.EmailReportDraftToBEDoctorByUser && (
                          <span style={{ color: '#107fc9' }}>
                            - By {this.renderStaffName(itemModel.EmailReportDraftToPEDoctorByUser)}
                          </span>
                        )}
                      </>
                    )}
                  </Checkbox>
                  <br />
                  <Checkbox
                    disabled={
                      itemModel.isLocked ||
                      !itemModel.ReportDraftReceived ||
                      (!itemModel.EmailReportDraftToDoctorBE && !itemModel.EmailReportDraftToDoctorPE)
                    }
                    checked={itemModel.EmailReportDraftToDoctor}
                    onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctor')}
                    style={{ marginTop: 10 }}
                  >
                    Send as Attachment{' '}
                    {itemModel.EmailReportDraftToDoctorDateSent && (
                    <>
                      {itemModel.EmailReportDraftToDoctorDateSent && (
                        <>
                          <span style={{ color: '#107fc9' }}>
                            {renderDate(itemModel.EmailReportDraftToDoctorDateSent, false, true)}
                          </span>
                          {itemModel.EmailReportDraftToDoctorByUser && (
                            <span style={{ color: '#107fc9' }}>
                              - By {this.renderStaffName(itemModel.EmailReportDraftToDoctorByUser)}
                            </span>
                          )}
                          <Button
                            className="blue"
                            disabled={itemModel.isLocked}
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              !itemModel.isLocked && this.handResendReportDraft();
                            }}
                          >
                            Resend Report Draft
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  </Checkbox>
                </Form.Item>
              )}
              <Divider className="fluid-field" />
              <Form.Item label={
                <React.Fragment>
                  <b>Causation Clarity Check{' '}</b>
                  <button
                    className="field-actions"
                    onClick={() => this.handleFieldRecentChange(itemModel, 'CausationQualityCheck')}
                  >
                    View changes
                  </button>
                </React.Fragment>
              }>
                <Radio.Group value={itemModel.CausationQualityCheck} onChange={this.handleChangeField('CausationQualityCheck')}>
                  <Radio
                    value={0}
                  >
                    Adequate
                  </Radio>
                  <Radio
                    value={1}
                  >
                    Inadequate
                  </Radio>
                  <Radio
                    value={2}
                  >
                    Unclear
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="fluid-field" label="Causation Description" style={{marginBottom: 10}}>
                <Input.TextArea
                  rows={3}
                  value={itemModel.CausationQualityCheckComment}
                  onChange={this.handleChangeField('CausationQualityCheckComment')}
                />
              </Form.Item>
              <Form.Item className="fluid-field checkbox-field">
                <Checkbox
                  disabled={itemModel.isLocked || itemModel.CausationQualityCheck == 0 || !store.specialist || itemModel.SentDateCausationQualityToDoctor}
                  checked={itemModel.SendCausationQualityToDoctor}
                  onChange={this.handleChangeEmailReportDraftToDoctor('SendCausationQualityToDoctor')}
                >
                  Send for Causation Review{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" />}
                    content=" Send for Causation Review to Specialist (MAIL-177)"
                    position="top center"
                    wide="very"
                  />{' '}
                  {itemModel.SentDateCausationQualityToDoctor && (
                    <>
                      <span style={{ color: '#107fc9' }}>
                        {renderDate(itemModel.SentDateCausationQualityToDoctor, false, true)}
                      </span>
                      {itemModel.SentByCausationQualityToDoctor && (
                        <span style={{ color: '#107fc9' }}>
                          - By {this.renderStaffName(itemModel.SentByCausationQualityToDoctor)}
                        </span>
                      )}
                    </>
                  )}
                </Checkbox>
              </Form.Item>
                {api.isAdminOrMagStaffOrAccounting() && (
                <React.Fragment>
                  <Divider className="fluid-field" />
                  <Form.Item label="Report Draft Readiness" className="fluid-field cancellation-label">
                    <Checkbox
                      disabled={
                        itemModel.isLocked ||
                        !itemModel.ReportDraftReceived ||
                        !!itemModel.EmailReportDraftToBEDoctorDateSent ||
                        !(store.specialist && store.specialist.BusinessEmail)
                      }
                      checked={itemModel.EmailReportDraftToDoctorBE}
                      onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctorBE')}
                      style={{ marginTop: 10 }}
                    >
                      {itemModel.ReportDraftReceived &&
                        !itemModel.EmailReportDraftToDoctorBE &&
                        !itemModel.EmailReportDraftToDoctor &&
                        !itemModel.EmailReportDraftToDoctorPE && <span style={{ color: 'red' }}>*</span>}
                      Send to Specialist Business Email{' '}
                      {store.specialist?.BusinessEmail ? '(' + store.specialist?.BusinessEmail + ')' : ''}
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                        position="top center"
                        wide="very"
                      />{' '}
                      {itemModel.EmailReportDraftToBEDoctorDateSent && (
                        <>
                          <span style={{ color: '#107fc9' }}>
                            {renderDate(itemModel.EmailReportDraftToBEDoctorDateSent, false, true)}
                          </span>
                          {itemModel.EmailReportDraftToBEDoctorByUser && (
                            <span style={{ color: '#107fc9' }}>
                              - By {this.renderStaffName(itemModel.EmailReportDraftToBEDoctorByUser)}
                            </span>
                          )}
                        </>
                      )}
                    </Checkbox>
                    <br />
                    <Checkbox
                      disabled={
                        itemModel.isLocked ||
                        !itemModel.ReportDraftReceived ||
                        !!itemModel.EmailReportDraftToDoctorDateSent ||
                        !(store.specialist && store.specialist.PersonalEmail)
                      }
                      checked={itemModel.EmailReportDraftToDoctorPE}
                      onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctorPE')}
                      style={{ marginTop: 10 }}
                    >
                      {itemModel.ReportDraftReceived &&
                        !itemModel.EmailReportDraftToDoctorBE &&
                        !itemModel.EmailReportDraftToDoctor &&
                        !itemModel.EmailReportDraftToDoctorPE && <span style={{ color: 'red' }}>*</span>}
                      Send to Specialist Personal Email{' '}
                      {store.specialist?.PersonalEmail ? '(' + store.specialist?.PersonalEmail + ')' : ''}
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                        position="top center"
                        wide="very"
                      />{' '}
                      {itemModel.EmailReportDraftToPEDoctorDateSent && (
                        <>
                          <span style={{ color: '#107fc9' }}>
                            {renderDate(itemModel.EmailReportDraftToPEDoctorDateSent, false, true)}
                          </span>
                          {itemModel.EmailReportDraftToBEDoctorByUser && (
                            <span style={{ color: '#107fc9' }}>
                              - By {this.renderStaffName(itemModel.EmailReportDraftToPEDoctorByUser)}
                            </span>
                          )}
                        </>
                      )}
                    </Checkbox>
                    <br />
                    <Checkbox
                      disabled={
                        itemModel.isLocked ||
                        !itemModel.ReportDraftReceived ||
                        (!itemModel.EmailReportDraftToDoctorBE && !itemModel.EmailReportDraftToDoctorPE)
                      }
                      checked={itemModel.EmailReportDraftToDoctor}
                      onChange={this.handleChangeEmailReportDraftToDoctor('EmailReportDraftToDoctor')}
                      style={{ marginTop: 10 }}
                    >
                      Send as Attachment{' '}
                      {itemModel.EmailReportDraftToDoctorDateSent && (
                      <>
                        {itemModel.EmailReportDraftToDoctorDateSent && (
                          <>
                            <span style={{ color: '#107fc9' }}>
                              {renderDate(itemModel.EmailReportDraftToDoctorDateSent, false, true)}
                            </span>
                            {itemModel.EmailReportDraftToDoctorByUser && (
                              <span style={{ color: '#107fc9' }}>
                                - By {this.renderStaffName(itemModel.EmailReportDraftToDoctorByUser)}
                              </span>
                            )}
                            <Button
                              className="blue"
                              disabled={itemModel.isLocked}
                              style={{ marginLeft: '10px' }}
                              onClick={() => {
                                !itemModel.isLocked && this.handResendReportDraft();
                              }}
                            >
                              Resend Report Draft
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    </Checkbox>
                  </Form.Item>
                </React.Fragment>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item className="fluid-field" label="Comments">
                  <Input.TextArea
                    rows={3}
                    value={itemModel.ReportDraftComment}
                    onChange={this.handleChangeField('ReportDraftComment')}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>
          )}
          {!(!itemModel.isMagDirect && api.isCaseManager()) && !api.isCaseManager() && (
            <Collapse.Panel
              key="3"
              header={
                <Header
                  title="Reviewed Report from Specialist"
                  isChecked={itemModel.ReportReturnedFromDoctor}
                  text={api.isDoctor() ? 'Report Sent' : 'Received from Specialist'}
                  date={itemModel.ReportCompletedDate}
                  isRequired={false}
                />
              }
            >
              <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                <Checkbox
                  disabled={(lockDoctor && itemModel.isLocked) || api.isDoctor()}
                  checked={itemModel.ReportReturnedFromDoctor}
                  onChange={this.handleCheckReportReturnedFromDoctorChanged('ReportReturnedFromDoctor')}
                  className="checkbox-session"
                  style={{ width: '400px' }}
                >
                  {api.isDoctor()
                    ? 'Reviewed/Completed Report Sent to Medicolegal Provider'
                    : 'Reviewed/Completed Report Received'}
                </Checkbox>
              </div>
              {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 7{/* error */}
                      <button
                        onClick={() =>
                          this.handleRecentChange(
                            itemModel,
                            'Status_ReportDraftReceived',
                            DEFAULT_COLUMNS_RECENT_CHANGE,
                            DEFAULT_FIELD_NAME_RECENT_CHANGE,
                          )
                        }
                        className="field-actions"
                      >
                        Recent changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    value={pic_7?.picId}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_7')}
                  >
                    {staffs.itemList &&
                      staffs.itemList.length &&
                      staffs.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.FullName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <div className="fluid-field" />
              <Form.Item className="fluid-field">
                <React.Fragment>
                  <Checkbox
                    className="checkbox-IsTypist"
                    style={{ marginTop: '1px' }}
                    checked={itemModel.IcareClaim}
                    disabled={api.isTypist() || api.isDoctor()}
                    onChange={this.handleChangeCheckBox('IcareClaim')}
                  >
                    <b>
                      This is iCare Claim{' '}
                      {this.renderPopup('Send iCare reminders to Staff (MAIL-170), Specialist (MAIL-169)')}
                    </b>{' '}
                    {itemModel.IcareClaim && itemModel.IcareClaimDate && (
                      <span style={styles.emailPaperworkReceivedDate}>
                        {itemModel.IcareClaimDate ? renderDate(itemModel.IcareClaimDate, false, true) : ''}
                        {' - By ' + store.renderStaffName(itemModel.IcareClaimBy)}
                      </span>
                    )}
                  </Checkbox>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      //disabled={(!itemModel.SentDateNotifyCMAppointmentInquiry && !itemModel.SentDateNotifyDoctorAppointmentInquiry) || lockTypist}
                      overlay={
                        <Menu>
                          <Menu.Item
                            disabled={!itemModel.IcareClaim}
                            onClick={() => store.handlSendICareCompletionRequest(1)}
                          >
                            To Specialist{' '}
                          </Menu.Item>
                          <Menu.Item
                            disabled={!itemModel.IcareClaim}
                            onClick={() => store.handlSendICareCompletionRequest(2)}
                          >
                            To Staff{' '}
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button className="blue">
                        Send iCare Reminder <Icon type="down" />
                      </Button>
                    </Dropdown>
                  )}
                </React.Fragment>
              </Form.Item>
              {/* ICARE REPORT */}
              {itemModel.IcareClaim && (
                <>
                  <Form.Item label="Insurer">
                    <Select
                      size="large"
                      value={
                        store.companyInsurer
                          ? store.companyInsurer.Id !== 0
                            ? store.companyInsurer?.Id
                            : undefined
                          : undefined
                      }
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={(value, event) => this.handleChangeObj(value, event, 'companyInsurer')}
                      onSearch={this.handleSearch('companyInsurer')}
                      onPopupScroll={this.handleScroll('companyInsurer')}
                      disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
                    >
                      {insurerCompanies.itemList &&
                        this.itemToArray(store.companyInsurer, insurerCompanies.itemList, 'Id').map(i => (
                          <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                            {i.Id} - {i.Name} - {i.StateName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Reason for Referral"
                    // required={itemModel.IcareClaim}
                    // validateStatus={!!store.reasonReferral?.Id || !itemModel.IcareClaim ? null : 'error'}
                    // help={!!store.reasonReferral?.Id || !itemModel.IcareClaim ? '' : 'This field is required'}
                  >
                    <Select
                      size="large"
                      disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
                      value={store.reasonReferral?.Id || undefined}
                      onChange={(value, event) => this.handleChangeObj(value, event, 'reasonReferral')}
                      showSearch
                      optionFilterProp="children"
                    >
                      {(reasonReferral?.reasonReferralItemList || []).map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Service Type">
                    <Select
                      size="large"
                      disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
                      value={itemModel.IcareServiceTypeId || undefined}
                      onChange={this.handleChangeField('IcareServiceTypeId')}
                      showSearch
                      optionFilterProp="children"
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[7] &&
                        icareLookupList.itemList[7].length > 0 &&
                        icareLookupList.itemList[7].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item></Form.Item>
                  <Form.Item label="Report Outcome - Capacity">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportCapacityId}
                      onChange={this.handleChangeField('IcareReportCapacityId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[0] &&
                        icareLookupList.itemList[0].length > 0 &&
                        icareLookupList.itemList[0].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Report Outcome - Diagnosis">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportDiagnosisId}
                      onChange={this.handleChangeField('IcareReportDiagnosisId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[1] &&
                        icareLookupList.itemList[1].length > 0 &&
                        icareLookupList.itemList[1].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Report Outcome - Liability">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportLiabilityId}
                      onChange={this.handleChangeField('IcareReportLiabilityId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[2] &&
                        icareLookupList.itemList[2].length > 0 &&
                        icareLookupList.itemList[2].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Report Outcome - Treatment">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportTreatmentId}
                      onChange={this.handleChangeField('IcareReportTreatmentId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[3] &&
                        icareLookupList.itemList[3].length > 0 &&
                        icareLookupList.itemList[3].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Report Outcome - WPI %">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportWPIId}
                      onChange={this.handleChangeField('IcareReportWPIId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[4] &&
                        icareLookupList.itemList[4].length > 0 &&
                        icareLookupList.itemList[4].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="For Psychological WPI Assessments only - was the PIRS form completed and included within the report?">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportPsychologicalWPIId}
                      onChange={this.handleChangeField('IcareReportPsychologicalWPIId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[5] &&
                        icareLookupList.itemList[5].length > 0 &&
                        icareLookupList.itemList[5].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="IMC Outcome">
                    <Select
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      showSearch
                      optionFilterProp="children"
                      value={itemModel.IcareReportIMCId}
                      onChange={this.handleChangeField('IcareReportIMCId')}
                    >
                      {icareLookupList &&
                        icareLookupList.itemList &&
                        icareLookupList.itemList.length &&
                        icareLookupList.itemList[6] &&
                        icareLookupList.itemList[6].length > 0 &&
                        icareLookupList.itemList[6].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <div className="fluid-field" />
                </>
              )}
              <FieldUpload
                uploadLoading={store.loadingUpload}
                label="Attached Reviewed/Completed Report"
                attachments={store.renderAttachment(
                  itemModel.AssessmentAttachment,
                  LOCAL_CONSTANT.ReportType_ReportReturnedFromDoctor,
                )}
                disabled={!api.isDoctor() && itemModel.isLocked}
                disabledDelete={!api.isDoctor() && itemModel.isLocked}
                reportType={LOCAL_CONSTANT.ReportType_ReportReturnedFromDoctor}
                multiUpload={true}
                functionCallback={() => {
                  if (!itemModel.ReportReturnedFromDoctor) {
                    store.handleChangeValue('ReportReturnedFromDoctor', true);
                  }
                  if (!itemModel.NotifyCorrectedReportToStaff) {
                    store.handleChangeValue('NotifyCorrectedReportToStaff', true);
                  }
                  if (!itemModel.NotifyCorrectedReportToSpecialist) {
                    store.handleChangeValue('NotifyCorrectedReportToSpecialist', true);
                  }
                }}
              />
              <Form.Item
                label={
                  api.isDoctor() ? 'Reviewed/Completed Report Sent Date' : 'Reviewed/Completed Report Received Date'
                }
              >
                <DatePicker
                  size="large"
                  disabled={itemModel.isLocked}
                  format="DD/MM/YYYY"
                  value={renderDate(itemModel.ReportCompletedDate)}
                  onChange={this.handleChangeField('ReportCompletedDate')}
                />
              </Form.Item>
              <Form.Item className="fluid-field cancellation-label" label="Send Reviewed/Completed Report Notice To">
                <br />
                <div style={{ marginBottom: '6px' }}>
                  <Checkbox
                    checked={itemModel.NotifyCorrectedReportToStaff}
                    onChange={this.handleChangeCheckBox('NotifyCorrectedReportToStaff')}
                    disabled={
                      !!itemModel.SentDateNotifyCorrectedReportToStaff ||
                      (api.isDoctor() && itemModel.NotifyCorrectedReportToStaff) ||
                      api.isTypist()
                    }
                  >
                    Staff{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Reviewed/Completed report notice will be sent to Staff (MAIL-24)"
                      position="top center"
                      wide="very"
                    />
                    <span>
                      {`${
                        itemModel.SentDateNotifyCorrectedReportToStaff
                          ? `${renderDate(itemModel.SentDateNotifyCorrectedReportToStaff, false, true)}`
                          : ''
                      } ${
                        itemModel.SentByNotifyCorrectedReportToStaff
                          ? ` - By ${this.renderStaffName(itemModel.SentByNotifyCorrectedReportToStaff)}`
                          : ''
                      }`}
                    </span>
                  </Checkbox>
                </div>
                <br />
                <Checkbox
                  checked={itemModel.NotifyCorrectedReportToSpecialist}
                  onChange={this.handleChangeCheckBox('NotifyCorrectedReportToSpecialist')}
                  disabled={!!itemModel.SentDateNotifyCorrectedReportToSpecialist || api.isTypist()}
                >
                  Specialist{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" />}
                    content="Reviewed/Completed report notice will be sent to Specialist (MAIL-122)"
                    position="top center"
                    wide="very"
                  />
                  <span>
                    {`${
                      itemModel.SentDateNotifyCorrectedReportToSpecialist
                        ? `${renderDate(itemModel.SentDateNotifyCorrectedReportToSpecialist, false, true)}`
                        : ''
                    } ${
                      itemModel.SentByNotifyCorrectedReportToSpecialist
                        ? ` - By ${this.renderStaffName(itemModel.SentByNotifyCorrectedReportToSpecialist)}`
                        : ''
                    }`}
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item className="fluid-field">
                <Dropdown
                  disabled={
                    !itemModel.SentDateNotifyCorrectedReportToStaff &&
                    !itemModel.SentDateNotifyCorrectedReportToSpecialist
                  }
                  overlay={
                    <Menu>
                      <Menu.Item
                        disabled={
                          !itemModel.SentDateNotifyCorrectedReportToStaff || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => this.handleResendNotice(1)}
                      >
                        Resend Notice to Staff{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !itemModel.SentDateNotifyCorrectedReportToSpecialist || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => this.handleResendNotice(2)}
                      >
                        Resend Notice to Specialist{' '}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button className="blue">
                    Resend Notice <Icon type="down" />
                  </Button>
                </Dropdown>
              </Form.Item>
              <Divider className="fluid-field" />
            </Collapse.Panel>
          )}
          {!api.isTypist() && (
            <Collapse.Panel
              key="4"
              header={
                <Header
                  title="Report to CMs"
                  isChecked={itemModel.ReportCompleted}
                  text="Sent to CMs"
                  date={itemModel.ReportCompletedSentDate}
                  isRequired={false}
                />
              }
            >
              {!(!itemModel.isMagDirect && api.isCaseManager()) && (
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={itemModel.ReportCompleted}
                    onChange={this.handleChangeCheckBox('ReportCompleted')}
                    disabled={(lockDoctorDirect && itemModel.isLocked) || api.isDoctor()}
                    className="checkbox-session"
                  >
                    Report Sent to CMs
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 8
                      <button
                        onClick={() =>
                          this.handleRecentChange(
                            itemModel,
                            'Status_ReportDraftReceived',
                            DEFAULT_COLUMNS_RECENT_CHANGE,
                            DEFAULT_FIELD_NAME_RECENT_CHANGE,
                          )
                        }
                        className="field-actions"
                      >
                        Recent changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    value={pic_8?.picId}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_8')}
                  >
                    {staffs.itemList &&
                      staffs.itemList.length &&
                      staffs.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.FullName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {(api.isAdminOrMagStaffOrAccounting() ||
                api.isDoctor() ||
                (api.isCaseManager() && itemModel.ReportCompleted)) && (
                <React.Fragment>
                  <Form.Item label="Date of Report Sent to CMs">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={(lockDoctorDirect && itemModel.isLocked) || !itemModel.ReportCompleted}
                      value={renderDate(itemModel.ReportCompletedSentDate)}
                      onChange={this.handleChangeField('ReportCompletedSentDate')}
                    />
                  </Form.Item>
                  <div className="fluid-field" />
                  <div>
                    <FieldUpload
                      uploadLoading={store.loadingUpload}
                      label="Attach Final Report"
                      attachments={store.renderAttachment(
                        itemModel.AssessmentAttachment,
                        LOCAL_CONSTANT.ReportType_Completed,
                      )}
                      reportType={LOCAL_CONSTANT.ReportType_Completed}
                      multiUpload={true}
                      disabled={(lockDoctorDirect && itemModel.isLocked) || api.isDoctor()}
                      disabledDelete={lockDoctorDirect && itemModel.isLocked}
                    />
                  </div>
                  <div>
                    <Form.Item
                      className="fluid-field"
                      validateStatus={
                        !store.validateField(itemModel.FinalReportOnedriveLinkFile, 'url') ? 'error' : null
                      }
                      help={
                        !store.validateField(itemModel.FinalReportOnedriveLinkFile, 'url')
                          ? 'Download link is not valid'
                          : ''
                      }
                      label={
                        <>
                          Or Share Download Link
                          <button
                            className={
                              this.validateLinkIsValid(itemModel.FinalReportOnedriveLinkFile)
                                ? 'field-actions'
                                : 'btn-disbled'
                            }
                            onClick={() => {
                              window.open(itemModel.FinalReportOnedriveLinkFile, '_blank');
                            }}
                            disabled={
                              !this.validateLinkIsValid(itemModel.FinalReportOnedriveLinkFile) ||
                              !itemModel.FinalReportOnedriveLinkFile
                            }
                          >
                            Open Link
                          </button>
                        </>
                      }
                    >
                      <Input.TextArea
                        rows={3}
                        value={itemModel.FinalReportOnedriveLinkFile}
                        onChange={this.handleChangeField('FinalReportOnedriveLinkFile')}
                        disabled={(lockDoctorDirect && itemModel.isLocked) || api.isDoctor()}
                      />
                    </Form.Item>
                    <Form.Item label="Password" className="fluid-field">
                      <Input
                        size="large"
                        disabled={(lockDoctorDirect && itemModel.isLocked) || api.isDoctor()}
                        value={itemModel.FinalReportOnedriveFilePassword}
                        onChange={this.handleChangeField('FinalReportOnedriveFilePassword')}
                      />
                    </Form.Item>
                  </div>
                  {!api.isDoctor() && (
                    <>
                      <Form.Item
                        label="Send Final Report to"
                        style={{ marginBottom: '0px', marginTop: '1rem' }}
                        className="fluid-field"
                      ></Form.Item>

                      <Form.Item className="fluid-field checkbox-field">
                        <Checkbox
                          disabled={
                            itemModel.isLocked || !store.specialist || itemModel.SentDateAllowSpecialistViewReport
                          }
                          checked={itemModel.IsAllowSpecialistViewReport}
                          onChange={this.handleChangeCheckBox('IsAllowSpecialistViewReport')}
                          style={{ marginTop: 25 }}
                        >
                          Specialist{' '}
                          <Popup
                            trigger={<IconSemantic name="info circle" />}
                            content="Send copy of final report to specialist (MAIL-59)"
                            position="top center"
                            wide="very"
                          />{' '}
                          {itemModel.SentDateAllowSpecialistViewReport && (
                            <>
                              {itemModel.SentDateAllowSpecialistViewReport && (
                                <>
                                  <span style={{ color: '#107fc9' }}>
                                    {renderDate(itemModel.SentDateAllowSpecialistViewReport, false, true)}
                                  </span>
                                  {itemModel.SentByAllowSpecialistViewReport && (
                                    <span style={{ color: '#107fc9' }}>
                                      - By {this.renderStaffName(itemModel.SentByAllowSpecialistViewReport)}
                                    </span>
                                  )}
                                  <Button
                                    className="blue"
                                    disabled={itemModel.isLocked}
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                      !itemModel.isLocked && this.handleResendReport(3);
                                    }}
                                  >
                                    Resend
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </Checkbox>
                      </Form.Item>
                      <div className="fluid-field" />
                      <div className="ant-row ant-form-item">
                        <div style={{ minHeight: '40px', lineHeight: '40px' }}>
                          <Checkbox
                            checked={itemModel.IsAllowCMSolicitorViewReport}
                            onChange={this.handleAllowCMSolicitorViewReportChanged('IsAllowCMSolicitorViewReport')}
                            style={{ marginTop: 10 }}
                            disabled={
                              (lockDoctorDirect && itemModel.isLocked) ||
                              !!itemModel.EmailToCMSolicitorViewReportRecent ||
                              !store.Report_Delivery_Contact_1?.Id
                            }
                          >
                            <span>
                              Report Receiving Contact 1
                              {this.renderPopup(
                                'Final report will be sent to report receiving contact 1 (MAIL-59, DIRECT-59)',
                              )}
                            </span>
                            {!!itemModel.EmailToCMSolicitorViewReportRecent && (
                              <>
                                <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                                  {itemModel.EmailToCMSolicitorViewReportRecent
                                    ? renderDate(itemModel.EmailToCMSolicitorViewReportRecent, false, true)
                                    : ''}
                                </span>
                                <span style={{ color: '#107fc9' }}>
                                  {itemModel.EmailToCMSolicitorViewReportRecentByUser
                                    ? ` - By ${this.renderStaffName(
                                        itemModel.EmailToCMSolicitorViewReportRecentByUser,
                                      )}`
                                    : ''}
                                </span>
                              </>
                            )}
                          </Checkbox>
                          {!!itemModel.EmailToCMSolicitorViewReportRecent && (
                            <Button
                              className="blue"
                              disabled={
                                (lockDoctorDirect && itemModel.isLocked) || !api.isAdminOrMagStaffOrAccounting()
                              }
                              onClick={() => {
                                this.handleResendReport(1);
                              }}
                            >
                              Resend
                            </Button>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={{ marginTop: '6px' }}
                          allowClear
                          value={store.Report_Delivery_Contact_1?.Id || undefined}
                          disabled={lockRule.lockReportComplete_CM}
                          onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_1')}
                          showSearch
                          optionFilterProp="children"
                          onSearch={this.handleSearch('bookingCaseManager')}
                          onPopupScroll={this.handleScroll('bookingCaseManager')}
                        >
                          {this.itemToArray(store.bookingCaseManager, listRDC1CM?.itemList || [], 'Id').map(i => (
                            <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={{ minHeight: '40px', lineHeight: '40px' }}>
                          <Checkbox
                            disabled={
                              (lockDoctorDirect && itemModel.isLocked) || !!itemModel.EmailToCMsViewReportRecent
                            }
                            style={{ marginTop: 10 }}
                            checked={itemModel.IsAllowCMViewReport}
                            onChange={this.handleAllowCMViewReportChanged('IsAllowCMViewReport')}
                          >
                            Report Receiving Contact 2{' '}
                            {this.renderPopup(
                              'Final report will be sent to report receiving contact 2 (MAIL-59, DIRECT-59)',
                            )}
                            {!!itemModel.EmailToCMsViewReportRecent && (
                              <>
                                <span style={{ color: '#107fc9', marginLeft: '34px' }}>
                                  {itemModel.EmailToCMsViewReportRecent
                                    ? renderDate(itemModel.EmailToCMsViewReportRecent, false, true)
                                    : ''}
                                </span>
                                <span style={{ color: '#107fc9' }}>
                                  {itemModel.EmailToCMsViewReportRecentByUser
                                    ? ` - By ${this.renderStaffName(itemModel.EmailToCMsViewReportRecentByUser)}`
                                    : ''}
                                </span>
                              </>
                            )}
                          </Checkbox>
                          {!!itemModel.EmailToCMSolicitorViewReportRecent && (
                            <Button
                              className="blue"
                              disabled={
                                (lockDoctorDirect && itemModel.isLocked) || !api.isAdminOrMagStaffOrAccounting()
                              }
                              onClick={() => {
                                this.handleResendReport(2);
                              }}
                            >
                              Resend
                            </Button>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={{ marginTop: '6px' }}
                          value={store.Report_Delivery_Contact_2?.Id || undefined}
                          onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_2')}
                          onPopupScroll={this.handleScroll('Report_Delivery_Contact_2')}
                          showSearch
                          allowClear
                          disabled={lockRule.lockReportComplete_CM}
                          optionFilterProp="children"
                          onSearch={this.handleSearch('Report_Delivery_Contact_2')}
                        >
                          {listRDC2CM.itemList.map(i => (
                            <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                  {!api.isTypist() && <div className="fluid-field" />}
                  <FieldUpload
                    uploadLoading={store.loadingUpload}
                    label="Attached Amended Report"
                    attachments={store.renderAttachment(
                      itemModel.AssessmentAttachment,
                      LOCAL_CONSTANT.ReportType_AmendedReportCompleted,
                    )}
                    reportType={LOCAL_CONSTANT.ReportType_AmendedReportCompleted}
                    multiUpload={true}
                    disabled={
                      (lockDoctorDirect && itemModel.isLocked) ||
                      (!api.isAdminOrMagStaffOrAccounting() && !!itemModel.SentAmendedReportsDate) ||
                      api.isDoctor()
                    }
                    disabledDelete={
                      itemModel.isLocked || (!api.isAdminOrMagStaffOrAccounting() && !!itemModel.SentAmendedReportsDate)
                    }
                  />
                  {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                    <>
                      <Form.Item
                        label="Send Amended Reports to"
                        style={{ marginBottom: '0px' }}
                        className="fluid-field"
                      ></Form.Item>
                      <div className="ant-row ant-form-item">
                        <div style={{ minHeight: '40px', lineHeight: '40px' }}>
                          <Checkbox
                            disabled={
                              (lockDoctorDirect && itemModel.isLocked) ||
                              !!itemModel.SentSolicitorAmendedReportsDate ||
                              !store.Report_Delivery_Contact_1?.Id
                            }
                            style={{ marginTop: 10 }}
                            checked={itemModel.IsAllowCMSolicitorViewAmendedReport}
                            onChange={this.handleChangeCheckBoxAllowSolicitorViewAmendedReportChanged(
                              'IsAllowCMSolicitorViewAmendedReport',
                            )}
                          >
                            Report Receiving Contact 1
                            {this.renderPopup(
                              'Amended final report will be sent to booking solicitor (MAIL-100, DIRECT-100)',
                            )}
                            {!!itemModel.SentSolicitorAmendedReportsDate && (
                              <>
                                <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                                  {itemModel.SentSolicitorAmendedReportsDate
                                    ? renderDate(itemModel.SentSolicitorAmendedReportsDate, false, true)
                                    : ''}
                                </span>
                                <span style={{ color: '#107fc9' }}>
                                  {itemModel.SentSolicitorAmendedReportsByUser
                                    ? ` - By ${this.renderStaffName(itemModel.SentSolicitorAmendedReportsByUser)}`
                                    : ''}
                                </span>
                              </>
                            )}
                          </Checkbox>
                          {!!itemModel.SentSolicitorAmendedReportsDate && (
                            <Button
                              className="blue"
                              disabled={
                                (lockDoctorDirect && itemModel.isLocked) || !api.isAdminOrMagStaffOrAccounting()
                              }
                              onClick={() => {
                                this.handleResendAmendedReport(1);
                              }}
                            >
                              Resend
                            </Button>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={{ marginTop: '6px' }}
                          allowClear
                          value={store.Report_Delivery_Contact_1?.Id || undefined}
                          disabled={lockRule.lockReportComplete_CM}
                          onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_1')}
                          showSearch
                          optionFilterProp="children"
                          onSearch={this.handleSearch('bookingCaseManager')}
                          onPopupScroll={this.handleScroll('bookingCaseManager')}
                        >
                          {this.itemToArray(store.bookingCaseManager, listRDC1CM?.itemList || [], 'Id').map(i => (
                            <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={{ minHeight: '40px', lineHeight: '40px' }}>
                          <Checkbox
                            disabled={(lockDoctorDirect && itemModel.isLocked) || !!itemModel.SentAmendedReportsDate}
                            style={{ marginTop: 10 }}
                            checked={itemModel.IsAllowCMViewAmendedReport}
                            onChange={this.handleChangeCheckBoxAllowCMViewAmendedReportChanged(
                              'IsAllowCMViewAmendedReport',
                            )}
                          >
                            Report Receiving Contact 2
                            {this.renderPopup('Amended final report will be sent to CMs (MAIL-100, DIRECT-100)')}
                            {!!itemModel.SentAmendedReportsDate && (
                              <>
                                <span style={{ color: '#107fc9', marginLeft: '34px' }}>
                                  {itemModel.SentAmendedReportsDate
                                    ? renderDate(itemModel.SentAmendedReportsDate, false, true)
                                    : ''}
                                </span>
                                <span style={{ color: '#107fc9' }}>
                                  {itemModel.SentAmendedReportsByUser
                                    ? ` - By ${this.renderStaffName(itemModel.SentAmendedReportsByUser)}`
                                    : ''}
                                </span>
                              </>
                            )}
                          </Checkbox>
                          {!!itemModel.SentAmendedReportsDate && (
                            <Button
                              className="blue"
                              disabled={
                                (lockDoctorDirect && itemModel.isLocked) || !api.isAdminOrMagStaffOrAccounting()
                              }
                              onClick={() => {
                                this.handleResendAmendedReport(2);
                              }}
                            >
                              Resend
                            </Button>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={{ marginTop: '6px' }}
                          value={store.Report_Delivery_Contact_2?.Id || undefined}
                          onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_2')}
                          onPopupScroll={this.handleScroll('Report_Delivery_Contact_2')}
                          showSearch
                          allowClear
                          disabled={lockRule.lockReportComplete_CM}
                          optionFilterProp="children"
                          onSearch={this.handleSearch('Report_Delivery_Contact_2')}
                        >
                          {listRDC2CM.itemList.map(i => (
                            <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                </React.Fragment>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item className="fluid-field" label="Comments" style={{ marginBottom: 0 }}>
                  <Input.TextArea rows={3} value={itemModel.Comments} onChange={this.handleChangeField('Comments')} />
                </Form.Item>
              )}
            </Collapse.Panel>
          )}
        </Collapse>
      </Form>
    );
  }
}

export default DictationsAndReportInformation;
