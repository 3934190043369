import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import CMReportStore from './CMReportStore';
import ModalExport from './ModalExport';
import ModalImport from './ModalImport';
import ModalBookingHistory from './ModalBookingHistory';

function CaseManagersModal() {
  const { modalType } = CMReportStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...CMReportStore} onCancel={CMReportStore.toggleModal(false)} />;
  }

  if (modalType === 'export') {
    return <ModalExport {...CMReportStore} onCancel={CMReportStore.toggleModal(false)} />;
  }

  if (modalType === 'history') {
    return <ModalBookingHistory {...CMReportStore} onCancel={CMReportStore.toggleModal(false)} />;
  }
  if (modalType === 'import') {
    return <ModalImport {...CMReportStore} onCancel={CMReportStore.toggleModal(false)} />;
  }
}

export default CaseManagersModal;
