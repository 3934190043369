import _ from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class DocAndConsStore {
  @observable loading = true;
  @observable loadingSearch = true;
  @observable loadingHistory = true;

  @observable picData = [];
  @observable data = null;
  @observable dataSearch = null;
  @observable dataFiles = null;
  @observable dataStaffs = null;
  @observable dataExport = null;
  @observable dataUser = null;
  @observable dataHistory = null;
  @observable dataLog = null;

  @observable ActionRequired = false;

  @observable keyword = '';
  @observable keywordSearch = '';
  @observable filterRecruitment = 'All';
  @observable filterStatus = 'active';
  @observable filterRecruitmentPic = '';
  @observable fromTo = '';
  @observable statusId = 0;
  @observable serviceGroupId = 'All';
  @observable fromToSaleDate = '';
  @observable filterCaller = 'All';
  @observable dataStaff = null;
  @observable fromToRqtingDate = '';

  @observable filterMNFreePO = '';

  @observable sortKey = 'FirstName';
  @observable sortColumn = 'Name';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable numberPageSearch = 30;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @observable loadingActionPIC = true;
  @observable dataActionPIC = null;
  @observable ActionRequiredById = '';

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchMain();
  };

  @action fetchStaff = () => {
    this.loadingStaff = true;
    customFetch('/MedicalDoctor/GetDoctorSalesCallNeedFollowUp', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        if (data.status === 'success') {
          this.dataStaff = _.sortBy(data.itemList, 'FullName');
          this.dataStaff.unshift({ Id: undefined, FullName: 'All', Email: '' });
        }
        this.loadingStaff = false;
      }),
    );
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = true;
    customFetch('/MedicalDoctor/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
      }),
    }).then(
      action(data => {
        this.dataFiles = data;
        // this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
        this.loading = false;
      }),
    );
  };

  @action fetchSearch = () => {
    this.loadingSearch = true;
    customFetch('/MedicalDoctor/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordSearch,
        CurPage: 1,
        NumItemPerPage: this.numberPageSearch,
        Showing: this.filterStatus,
        IsSelectionList: true,
      }),
    }).then(
      action(data => {
        this.dataSearch = data;
        this.loadingSearch = false;
      }),
    );
  };

  @action fetchMain = (setTotalPage = false) => {
    this.loading = true;
    const { fromSaleDate, toSaleDate } = this.getAppointmentDateRange();
    const { fromRequestingDate, toRequestingDate } = this.getRequestingDateRange();

    customFetch('/MedicalDoctor/GetForMainListing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: this.ActionRequired,
        UrgentReport: false,
        ActionRequiredById: this.ActionRequiredById,
        ShowingRecruitment: this.filterRecruitment,
        Showing: this.filterStatus,
        RecruitmentPic: this.filterRecruitmentPic,
        FromSaleDate: fromSaleDate,
        ToSaleDate: toSaleDate,
        Caller: this.filterCaller,
        FromRequestingDate: fromRequestingDate,
        ToRequestingDate: toRequestingDate,
        MNFreePO: this.filterMNFreePO,
      }),
    }).then(
      action(data => {
        this.fetchSearch();
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
    this.fetchActionPIC();
  };

  @action fetchHistory = () => {
    this.loadingHistory = true;
    customFetch('/Manager/GetLoginHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        uId: this.modalParams.record.UserId,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: 'LoginDate',
        SortDirection: 'descending'.replace('ending', '').toUpperCase(),
      }),
    }).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action fetchStatusHistory = () => {
    this.loadingHistory = true;
    customFetch('/MedicalDoctor/GetDoctorHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id: this.modalParams.id }),
    }).then(
      action(data => {
        this.dataLog = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action fetchActionPIC = () => {
    this.loadingActionPIC = true;
    customFetch('/MedicalDoctor/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataActionPIC = data;
        this.loadingActionPIC = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  serviceItemCreate = (service = {}) => {
    return action(() => {
      this.serviceItem = service;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };
  @action handleSearchChangeClinic = ({ target }) => {
    this.keywordClinic = target.value;
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchMain(setTotalPage);
  };

  @action refetchSearch = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearch(true);
  };

  @action refetchStaffs = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchStaffs();
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filterRecruitment = 'All';
    this.filterStatus = 'All';
    this.sortColumn = 'Name';
    this.sortKey = 'FirstName';
    this.ActionRequired = false;
    this.keywordSearch = '';
    this.ActionRequiredById = '';
    this.filterRecruitmentPic = '';

    this.numberPageSearch = 30;
    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };

  @action resetSearch = () => {
    this.keywordSearch = '';
    this.numberPageSearch = 30;

    this.refetchSearch({});
  };

  @action fetchPIC = () => {
    this.loading = true;

    return customFetch('/MedicalDoctor/GetStaffAssign', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(res => {
        if (res.status === 'success') {
          this.picData = res.itemList;
        }
      }),
    );
  };

  getAppointmentDateRange = () => {
    const [fromDate, toDate] = this.fromToSaleDate.split(' - ');
    return {
      fromSaleDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').toISOString() : '',
      toSaleDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  getRequestingDateRange = () => {
    const [fromDate, toDate] = this.fromToRqtingDate.split(' - ');
    return {
      fromRequestingDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').toISOString() : '',
      toRequestingDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };
}

export default new DocAndConsStore();
