import React from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import { debounce, isNil } from 'lodash';
import NotificationStore from './NotificationStore';
import NotificationTable from './NotificationTable';
import NotificationToolbar from './NotificationToolbar';
import ModalAddOrView from './ModalAddOrView';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class Notification extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/notification', true);
      return;
    }
    this._searchDebounced();
    dashboardStore.on('open(/view/notification)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    NotificationStore.fetchNotifications(true);
  }, 500);

  render() {
    const { loading, itemList, modalParams } = NotificationStore;

    return (
      <div className={`Table-${loading && isNil(itemList) ? 'loading' : 'container'}`}>
        {loading && isNil(itemList) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <NotificationToolbar />
            <NotificationTable />
            {modalParams.isOpen && <ModalAddOrView {...modalParams} />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default Notification;
