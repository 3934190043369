import { action, observable, computed } from 'mobx';
import moment from 'moment';
import customFetch from '../../../utils/customFetch';
import {
  getFundingProvider,
  getFundingBorrower,
  getApplicantByBorrower,
  getFundingRequestInfo,
} from '../../admin-routes/FundingRequest/Service';

const INIT_FUNDING_REQ = {
  RequestNo: null,
  RequestDate: moment(),
  NumberOfPersonInjuryLawyer: null,
  NumberOfAccreditedSpecialistPersonIL: null,
  NumberOfOtherLawyer: null,
  NumberOfPAs: null,
  NumberOfSupportStaff: null,
  NumberOfCurrentOpenMatters: null,
  AvgNumberOfMattersPerLawyer: null,
  AvgPracticingExperiencePerLawyer: null,
  GrowthStatus: 0,
  GrowthPerAnmun: null,
  ContractingPerAnmun: null,
  SuccessRateLast3Year: null,
  AvgWIPPerMatter: null,
  AnnualWriteOfAmountLast3Year: null,
  InMotorVehicleAccidents: null,
  InWorkerCompensation: null,
  InMedicalNegligence: null,
  InLiability: null,
  FundingProvider: null,
  FundingAmountMonth1: null,
  FundingAmountMonth2: null,
  FundingAmountMonth3: null,
  FundingAmountMonth4: null,
  FundingAmountMonth5: null,
  FundingAmountMonth6: null,
  FundingAmountMonth7: null,
  FundingAmountMonth8: null,
  FundingAmountMonth9: null,
  FundingAmountMonth10: null,
  FundingAmountMonth11: null,
  FundingAmountMonth12: null,
  FundingAmountMonth13: null,
  FundingAmountMonth14: null,
  FundingAmountMonth15: null,
  FundingAmountMonth16: null,
  FundingAmountMonth17: null,
  FundingAmountMonth18: null,
  FundingAmountMonth19: null,
  FundingAmountMonth20: null,
  FundingAmountMonth21: null,
  FundingAmountMonth22: null,
  FundingAmountMonth23: null,
  FundingAmountMonth24: null,
  FundingPeriodFrom: null,
  FundingPeriodTo: null,
  FundingRequestStatus: 1,
  FundingStatusDatePending: moment(),
  FundingStatusDateSent: null,
  FundingStatusDateReviewed: null,
  FundingStatusDateApproved: null,
  FundingStatusDateRejected: null,
  LatestFundingStatusDate: null,
  Attachments: [],
  Borrower: null,
  Applicant: null,
  ClassAction: null,
  PracticeAreaOther: null,
  BorrowingFacilityAmount: null,
};

class Store {
  @observable loading = false;
  @observable loadingAcc = true;
  @observable dataAcc = null;

  @observable activeIdx = 0;
  @observable type = null;

  @observable fundingReqInfo = INIT_FUNDING_REQ;

  @observable open = false;
  @observable modalParams = {};
  @observable arrProvider = [];
  @observable arrBorrower = [];
  @observable arrApplicant = [];
  @observable lstFileAttachments = [];
  @observable lstManagementTeams = [];

  @computed get dataFundingProvider() {
    return (this.arrProvider || []).map(s => ({
      value: s.Id,
      text: s.Name,
    }));
  }

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action setListData = (lstName, data) => {
    this[lstName] = data;
  };

  getApplicant = clientId => {
    getApplicantByBorrower('', clientId).then(resp => {
      if (resp && resp.itemList) {
        this.setListData('arrApplicant', resp.itemList);
      }
    });
  };

  @action handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'Borrower') {
        this.getApplicant(value);
      }
      this.fundingReqInfo[fieldName] = value;
    });

  @action handleChangeFieldValue = (_, { name, value }) => {
    if (name === 'FundingRequestStatus') {
      if (value === 1) {
        this.fundingReqInfo.FundingStatusDatePending = moment();
      } else if (value === 2) {
        this.fundingReqInfo.FundingStatusDateSent = moment();
      } else if (value === 3) {
        this.fundingReqInfo.FundingStatusDateReviewed = moment();
      } else if (value === 4) {
        this.fundingReqInfo.FundingStatusDateApproved = moment();
      } else if (value === 5) {
        this.fundingReqInfo.FundingStatusDateRejected = moment();
      }
    }
    this.fundingReqInfo[name] = value;
  };

  @action setFileAttachment = files => {
    this.lstFileAttachments = files;
  };

  @action setManagementTeams = teams => {
    this.lstManagementTeams = teams;
  };

  @action fetchData = id => {
    this.loading = true;
    Promise.all([getFundingProvider(), getFundingBorrower(), getFundingRequestInfo(id)]).then(
      action(([fundingProviderResp, borrowerResp, info]) => {
        if (fundingProviderResp && fundingProviderResp.itemList) {
          this.arrProvider = fundingProviderResp.itemList;
        }
        if (borrowerResp && borrowerResp.itemList) {
          this.arrBorrower = borrowerResp.itemList;
        }
        this.fundingReqInfo = {
          ...info.objItem,
          RequestDate: this.momentToJS(info.objItem.RequestDate),
          FundingPeriodFrom: this.momentToJS(info.objItem.FundingPeriodFrom),
          FundingPeriodTo: this.momentToJS(info.objItem.FundingPeriodTo),
          FundingStatusDatePending: this.momentToJS(info.objItem.FundingStatusDatePending),
          FundingStatusDateSent: this.momentToJS(info.objItem.FundingStatusDateSent),
          FundingStatusDateReviewed: this.momentToJS(info.objItem.FundingStatusDateReviewed),
          FundingStatusDateApproved: this.momentToJS(info.objItem.FundingStatusDateApproved),
          FundingStatusDateRejected: this.momentToJS(info.objItem.FundingStatusDateRejected),
          LatestFundingStatusDate: this.momentToJS(info.objItem.LatestFundingStatusDate),
        };
        if (this.fundingReqInfo.Borrower) {
          this.getApplicant(this.fundingReqInfo.Borrower);
        }
        if (
          this.fundingReqInfo &&
          this.fundingReqInfo.ManagementTeams &&
          this.fundingReqInfo.ManagementTeams.length > 0
        ) {
          this.setManagementTeams(this.fundingReqInfo.ManagementTeams);
        } else {
          this.setManagementTeams([]);
        }
        if (this.fundingReqInfo && this.fundingReqInfo.Attachments && this.fundingReqInfo.Attachments.length > 0) {
          const newAttachments = [...this.fundingReqInfo.Attachments].map(i => ({
            ...i,
            key: i.FileName,
          }));
          this.setFileAttachment(newAttachments);
        } else {
          this.setFileAttachment([]);
        }
        console.log(this.fundingReqInfo);
        this.loading = false;
      }),
    );
  };

  @action initData = () => {
    this.fundingReqInfo = { ...INIT_FUNDING_REQ };
    this.setManagementTeams([]);
    Promise.all([getFundingProvider(''), getFundingBorrower()]).then(
      action(([fundingProviderResp, borrowerResp]) => {
        if (fundingProviderResp && fundingProviderResp.itemList) {
          this.arrProvider = fundingProviderResp.itemList;
        }
        if (borrowerResp && borrowerResp.itemList) {
          this.arrBorrower = borrowerResp.itemList;
        }
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action onReset = () => {
    this.fundingReqInfo = INIT_FUNDING_REQ;
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };
}

export default new Store();
