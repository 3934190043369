import './PasswordInput.scss';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import TextInput from './TextInput';

@observer
class PasswordInput extends React.Component {
  @observable visible = false;

  @action toggleVisible = () => {
    this.visible = !this.visible;
  };

  @action resetVisible = () => {
    this.visible = false;
  };

  componentWillUnmount() {
    this.resetVisible();
  }

  render() {
    return (
      <div className="PasswordInput">
        <TextInput {...this.props} type={this.visible ? 'text' : 'password'}>
          <div className="Input-Icon" onClick={this.toggleVisible}>
            <span>{this.visible ? 'HIDE' : 'SHOW'}</span>
          </div>
        </TextInput>
      </div>
    );
  }
}

export default PasswordInput;
