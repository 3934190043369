import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';

import TableToolbar from '../../shared/tableToolbar';
import CMReportStore from './CMReportStore';

@observer
class Toolbar extends React.Component {
  handleChangeTab = (_, { name }) => {
    if (name === 'lastBookings') {
      const { dataCMNotBooked } = CMReportStore;
      if (!dataCMNotBooked) {
        CMReportStore.fetchLastBooking();
      }
    }
    if (name === 'imeSearch') {
      CMReportStore.refetchIMESearch({
        fromToIMESearch:
          moment(
            moment()
              .startOf('day')
              .toDate(),
          ).format(`DD/MM/YYYY`) +
          ' - ' +
          moment(
            moment()
              .startOf('day')
              .toDate(),
          ).format(`DD/MM/YYYY`),
      });
    }
    return CMReportStore.setFieldsValue({ tabIsActive: name });
  };
  render() {
    const { tabIsActive } = CMReportStore;
    return (
      <form className="CM-report-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Container>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'saleCall' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="saleCall"
              >
                Relationship Activities
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'bookingHistory' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="bookingHistory"
              >
                View CM Booking History
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'lastBookings' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="lastBookings"
              >
                View CM Last Bookings
              </TableToolbar.HighLightButton>
            </div>

            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'otherSaleCall' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="otherSaleCall"
              >
                Case Initiated Relationship Calls
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'imeSearch' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="imeSearch"
              >
                IME Specialist Search History
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'export' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="export"
              >
                Import - Export CM Lists
              </TableToolbar.HighLightButton>
            </div>
          </TableToolbar.Container>
        </TableToolbar.Container>
      </form>
    );
  }
}

export default Toolbar;
