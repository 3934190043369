import './ShowSidebarIcon.scss';

import React from 'react';
import { Icon } from 'semantic-ui-react';

import ui from '../../../stores/dashboard';

class ShowSidebarIcon extends React.Component {
  render() {
    const { toggleSidebarVisible } = ui;
    return (
      <div className="DashboardHeaderIcon ShowSidebarIcon" onClick={toggleSidebarVisible}>
        <Icon name="bars" fitted />
      </div>
    );
  }
}

export default ShowSidebarIcon;
