export const ERROR_FIELDNAME = {
  FirstName: 'First name',
  LastName: 'Last name',
  Email: 'Email address',
  UserName: 'User name',
  Roles: 'Roles',
};

export const ERROR_MESSAGES = {
  required: 'is required',
  invalid: 'is invalid',
};
