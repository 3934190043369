import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { toJS } from 'mobx';
import dashboardStore from '@stores/dashboard';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import fileReviewStore from './fileReviewStore';
import moment from 'moment';
import ModalAlert from './ModalAlert';
import { getRenderText, getRenderOptions } from '../../../utils/functions';

@observer
class FileReviewToolbar extends React.Component {
  state = {
    openModalAlert: false,
    message: `You need to search or filter first before exporting results.`,
    checkedClaimtOnly: true,
  };

  openCreateNewCase = () => {
    localStorage.setItem(`FileReviewCaseId`, 'New File Review');
    dashboardStore.close('/view/add-edit-file-review-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-file-review-2?id=0`);
    });
  };

  handleExport = () => {
    const { fromDate, toDate } = fileReviewStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant, keyword, actionRequiredById } = fileReviewStore;
    if (
      claimant.Id ||
      fromDate ||
      toDate ||
      NextTaskPIC ||
      filter !== `All` ||
      filterOverdue !== `All` ||
      keyword ||
      actionRequiredById
    ) {
      this.openDownloadSearchResultsUrl();
    } else {
      this.setState({
        openModalAlert: true,
      });
    }
  };

  openDownloadSearchResultsUrl = () => {
    const { fromDate, toDate } = fileReviewStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant } = fileReviewStore;
    const searchOption = {
      ActionRequired: false,
      ActionRequiredById: fileReviewStore.actionRequiredById,
      ActionType: 'NONE',
      Claimant: Object.entries(fileReviewStore.claimant)
        .map(([key, val]) => `${key}=${val}`)
        .join('&'),
      CurPage: fileReviewStore.curPage,
      FilterOverdue: filterOverdue,
      FilterType: filter.search(/\s/) ? filter.replace(/\s+/g, '+') : filter,
      From: moment(fromDate)
        .startOf('day')
        .toISOString(),
      GetAllRecords: true,
      ClaimantId: fileReviewStore.claimant.Id,
      Keyword: fileReviewStore.keyword,
      NextPicId: NextTaskPIC,
      NumItemPerPage: 10000,
      SearchClaimantOnly: fileReviewStore.searchClaimantOnly,
      SortBy: fileReviewStore.sortKey,
      SortDirection: `DESC`,
      To: moment(toDate)
        .endOf('day')
        .toISOString(),
      UrgentReport: false,
    };
    if (!claimant.Id) {
      delete searchOption.Claimant;
      delete searchOption.ClaimantId;
    }
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    if (!NextTaskPIC) {
      delete searchOption.NextPicId;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open('/FileReview/ExportSearchResult?' + qs, '_blank');
  };

  handleFilterChange = (_, { name, value }) => {
    fileReviewStore.refetch({ [name]: value }, true);
  };

  renderFilterOption = name =>
    getRenderOptions(
      fileReviewStore[name]?.itemList,
      opt => ({
        text: `${opt.FullName}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Id,
      }),
      'FullName',
      { Id: '', FullName: 'All', Count: '' },
    );

  renderFilterLabel = (name, value) =>
    getRenderText(value, fileReviewStore[name]?.itemList, 'Id', 'FullName', {
      Id: '',
      FullName: 'All',
      Count: null,
    });

  renderStaffActionRequired = () => {
    return fileReviewStore.dataStaffAssignAction.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderCaseStatus = () =>
    getRenderOptions(
      fileReviewStore.dataDynamicFileReviewStatus,
      opt => ({
        text: `${opt.Status}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Status,
      }),
      'Status',
      { Status: 'All', Count: '' },
    );

  renderCaseStatusLabel = (name, value) =>
    getRenderText(value, fileReviewStore[name], 'Status', 'Status', {
      Status: 'All',
      Count: '',
    });

  notInNextTask = [
    api.GetFRStatusText(api.frStatus.CASE_CREATED),
    api.GetFRStatusText(api.frStatus.CANCELLED_NO_FEE),
    api.GetFRStatusText(api.frStatus.SERVICE_BILLED),
    api.GetFRStatusText(api.frStatus.CLIENT_INVOICE),
    api.GetFRStatusText(api.frStatus.DOCTOR_INVOICE),
    api.GetFRStatusText(api.frStatus.SERVICE_PAID),
  ];

  renderNextTaskLabel = value => {
    if (value === `All`) {
      return `All`;
    } else {
      let array = [];
      const data = toJS(fileReviewStore.dataDynamicFileReviewStatus);
      for (var i = 0; i < data.length; i++) {
        if (api.isAdminOrMagStaffOrAccounting()) {
          if (this.notInNextTask.indexOf(data[i].Status) === -1) {
            array.push(data[i]);
          }
        } else if (api.isCaseManager()) {
          if (data[i].Status === `Service Billed`) {
            array.push(data[i]);
          }
        }
      }
      array.unshift({ Status: `All`, Count: `` });
      let labels = array.filter(opt => opt.Status === value).map(opt => opt);
      if (api.isAdminOrMagStaffOrAccounting()) {
        if (labels[0]) return `${labels[0].NextStatus} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
        return `All`;
      } else if (api.isCaseManager()) {
        if (labels[0]) return `${labels[0].NextStatus} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
        return `All`;
      }
    }
  };

  renderNextTask = () => {
    let array = [];
    const data = toJS(fileReviewStore.dataDynamicFileReviewStatus);
    for (var i = 0; i < data.length; i++) {
      if (api.isAdminOrMagStaffOrAccounting()) {
        if (this.notInNextTask.indexOf(data[i].Status) === -1) {
          array.push(data[i]);
        }
      } else if (api.isCaseManager()) {
        if (data[i].Status === `Service Billed`) {
          array.push(data[i]);
        }
      }
    }
    array.unshift({ NextStatus: `All`, Count: `` });
    if (api.isAdminOrMagStaffOrAccounting()) {
      return array.map((opt, idx) => ({
        text: `${opt.NextStatus} ${opt.Count ? `(${opt.Count})` : ''}`,
        value: opt.Status,
        key: idx,
      }));
    } else if (api.isCaseManager()) {
      return array.map((opt, idx) => ({
        text: `${opt.NextStatus} ${opt.Count ? `(${opt.Count})` : ''}`,
        value: opt.Status,
        key: idx,
      }));
    }
  };

  renderNextTaskOverDue = () =>
    getRenderOptions(
      fileReviewStore.dataDynamicOverDueFileReviewStatus,
      opt => ({
        text: `${opt.Status}${opt.Count ? ` (${opt.Count})` : ''}`,
        value: opt.Status,
      }),
      'Status',
      { Status: `All`, Count: '' },
    );

  onChecked = () => {
    const { searchClaimantOnly } = fileReviewStore;
    fileReviewStore.setFieldsValue({ searchClaimantOnly: !searchClaimantOnly });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = fileReviewStore;

    if (fileReviewStore.claimant === null || !fileReviewStore.searchClaimantOnly) {
      fileReviewStore.refetch(
        {
          claimant: {
            isFreeText: true,
            FullName: keyword,
          },
          curPage: 1,
        },
        true,
      );
    } else {
      fileReviewStore.refetch(
        {
          keyword: fileReviewStore.claimant.FullName,
          curPage: 1,
          numberSearch: 30,
        },
        true,
      );
    }
    fileReviewStore.resetSearch();
  };

  handleChange = (_, { value }) => {
    fileReviewStore.setFieldsValue({ keyword: value });
  };

  handleReset = () => {
    fileReviewStore.resetStore();
  };
  handleRefetch = () => {
    fileReviewStore.refetch({}, true);
  };
  handleChangeSearchDropdown = e => {
    fileReviewStore.refetchClaimant({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };
  renderActionOptions = () =>
    getRenderOptions(
      fileReviewStore.dataClaimant?.itemList,
      opt => ({
        text: `${opt.Id ? `${opt.Id} - ` : ''}${opt.FullName || ''}`,
        value: JSON.stringify({ Id: opt.Id, FullName: opt.FullName }),
      }),
      'FullName',
      { Id: '', FullName: `All` },
    );

  renderActionText = value =>
    getRenderText(
      value.Id,
      fileReviewStore.dataClaimant?.itemList,
      'Id',
      'FullName',
      { Id: '', FullName: 'All' },
      'Search a Claimant',
    );

  renderFilterPrepaymentOptions = () =>
    getRenderOptions(fileReviewStore.dataPrepayment?.itemList, opt => ({
      text: `${opt.Text}${opt.Count ? ` (${opt.Count})` : ''}`,
      value: opt.Value,
    }));

  renderFilterPrepaymentText = value => getRenderText(value, fileReviewStore.dataPrepayment?.itemList);

  handleFilterPrepayment = (_, { value }) => {
    fileReviewStore.refetch({ PrepaymentType: value, curPage: 1 }, true);
  };

  checkIsJSON = json => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const claimant = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (claimant.Id === `` || value === `All`) {
      fileReviewStore.refetch(
        {
          claimant: { Id: ``, FullName: `` },
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      fileReviewStore.resetSearch();
    } else {
      fileReviewStore.refetch(
        {
          claimant,
          keyword: claimant.FullName,
          curPage: 1,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  handleChangeFromTo = (_, { value }) => {
    fileReviewStore.setFieldsValue({ fromTo: value, showBookingToday: false });
  };

  handleShowBookingForToday = () => {
    fileReviewStore.refetch(
      {
        fromTo: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
      },
      true,
    );
  };

  handleScroll = event => {
    const target = event.target;
    const number = fileReviewStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      fileReviewStore.refetchClaimant({ numberSearch: number + 20 });
    }
  };

  renderModal = () => {
    const { message, openModalAlert } = this.state;
    return (
      <ModalAlert
        open={openModalAlert}
        modalParams={{ message }}
        onCancel={() => this.setState({ openModalAlert: false })}
      />
    );
  };

  render() {
    const {
      keyword,
      searchClaimantOnly,
      loadingClaimant,
      loading,
      fromTo,
      totalItems,
      PrepaymentType,
    } = fileReviewStore;

    return (
      <form className="file-review-toolbar">
        {this.renderModal()}
        <TableToolbar.Container style={{ justifyContent: 'flex-start' }}>
          <TableToolbar.Left as="div">
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <Checkbox
                label="Search claimant only"
                onChange={this.onChecked}
                checked={searchClaimantOnly}
                style={{ marginRight: `10px` }}
                disabled={loading}
              />
            )} */}
            {searchClaimantOnly && api.isAdminOrMagStaffOrAccounting() ? (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                onScroll={this.handleScroll}
                allowAdditions
                additionLabel=""
                selectOnNavigation={false}
                closeOnChange
                value={keyword}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                className="Toolbar-input"
                disabled={loading}
                clearable
                style={{ width: '20rem' }}
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
            ) : (
              <TableToolbar.Input
                value={keyword}
                style={{ width: '20rem' }}
                onChange={this.handleChange}
                disabled={loading}
              />
            )}
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: '5px' }}
              onClick={this.handleReset}
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefetch}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isAdminOrMagStaffOrAccounting() && !api.isTypist() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                className={`positive`}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Left as="div">
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                style={{ marginLeft: `5px` }}
                className={`positive`}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>}

              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExport}
                disabled={loading}
              >
                Export Searches
              </TableToolbar.HighLightButton>

              <TableToolbar.HighLightButton
                color={`blue`}
                onClick={this.handleShowBookingForToday}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                Show Today's Bookings
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          )}{' '}
          <TableToolbar.Left>
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>

        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.RangePicker
            value={fromTo}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Next Task PIC`}
              value={`${this.renderFilterLabel(`dataStaffAssign`, fileReviewStore.NextTaskPIC)}`}
              name={'NextTaskPIC'}
              options={this.renderFilterOption(`dataStaffAssign`)}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <TableToolbar.Filter
            selection
            text={
              api.isAdminOrMagStaffOrAccounting()
                ? `Next Task: ${this.renderNextTaskLabel(fileReviewStore.filter)}`
                : `Tasks Due: ${this.renderNextTaskLabel(fileReviewStore.filter)}`
            }
            name={'filter'}
            options={this.renderNextTask()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Next Task Overdue`}
              value={`${this.renderCaseStatusLabel(
                `dataDynamicOverDueFileReviewStatus`,
                fileReviewStore.filterOverdue,
              )}`}
              name={'filterOverdue'}
              options={this.renderNextTaskOverDue()}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <TableToolbar.Filter
            selection
            label={`Case Status`}
            value={`${this.renderCaseStatusLabel(`dataDynamicFileReviewStatus`, fileReviewStore.filter)}`}
            name={'filter'}
            options={this.renderCaseStatus()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Action Required`}
                value={`${this.renderFilterLabel(`dataStaffAssignAction`, fileReviewStore.actionRequiredById)}`}
                name={'actionRequiredById'}
                options={this.renderFilterOption(`dataStaffAssignAction`)}
                onChange={this.handleFilterChange}
                disabled={loading}
                style={{ marginBottom: '1rem' }}
              />
            ) && (
              <TableToolbar.Filter
                selection
                label="Prepayment Type"
                value={this.renderFilterPrepaymentText(PrepaymentType)}
                options={this.renderFilterPrepaymentOptions()}
                onChange={this.handleFilterPrepayment}
                disabled={loading}
                style={{ marginBottom: '1rem' }}
              />
            )}
        </TableToolbar.Container>
      </form>
    );
  }
}

export default FileReviewToolbar;
