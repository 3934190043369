import './index.scss';

import { Spin, Radio } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import SessionNote from '../../dashboard-routes/SmartSearch/SessionNote';
import Searchbar from './Searchbar';
import SessionCalendar from './SessionCalendar';
import CalendarModal from './CalendarModal';
import store from './store';
import moment from 'moment-timezone';
import router from '@stores/router';
import customFetch from '../../../utils/customFetch';
import * as api from '../../../stores/api';
import { formatDate } from '../../../utils/functions';

@observer
class AddEditMySession extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  async componentWillMount() {
    const dsdSelected = localStorage.getItem('Data:DoctorSessionDateSelected');
    const { pageGetter, fieldName, onlySessions } = this.getRouteParams();
    if (pageGetter && fieldName) {
      store.setFieldsValue({ pageGetter, fieldName, onlySessions });
    }
    if (dsdSelected) {
      const data = JSON.parse(dsdSelected);
      store.setFieldsValue({
        clinicId: data.clinicId,
        doctorId: data.doctorId,
        selectedDoctors: data.doctor ? [{ ...data.doctor }] : [],
        selectedDoctor: data.doctor || null,
        doctorAppointmentSelected: data.doctorId,
      });
      store.updateTimeRange(moment(data.selectedDate), 'timeGridDay');
      return store.fetchCalendarList();
    } else {
      store.updateTimeRange(moment().tz(store.timeZone));
    }
    if (api.isAdminOrMagStaffOrAccounting()) {
      const userSettingResp = await customFetch('/Account/GetUserSetting', {
        method: 'POST',
      });
      if (userSettingResp.model) {
        var resResult = {
          ...userSettingResp.model,
          dateFrom: userSettingResp.model.dateFrom
            ? moment(formatDate(userSettingResp.model.dateFrom), 'DD MMM, YYYY')
            : null,
          dateTo: userSettingResp.model.dateTo
            ? moment(formatDate(userSettingResp.model.dateTo), 'DD MMM, YYYY')
            : null,
        };
        if (!!resResult) {
          const { dateFrom } = resResult;
          if (!!dateFrom) {
            store.updateTimeRange(dateFrom);
          } else {
            store.updateTimeRange(moment());
          }
        }
      } else {
        store.updateTimeRange(moment());
      }
    }
    if (api.isDoctor()) {
      store.setFieldsValue({
        selectedDoctor: { Id: api.currentUser.data.DoctorId, FullName: api.currentUser.data.FullName },
        doctorId: api.currentUser.data.DoctorId,
      });
    }
    return store.fetchCalendarList();
  }

  componentWillUnmount() {
    store.clearStore(true);
  }

  render() {
    const { loading, open, loadingClients, loadingClinics, loadingSpecialists } = store;
    const isFirstLoading = loading && loadingClients && loadingClinics && loadingSpecialists;

    return (
      <div>
        {!api.isCMInvoiceCheck() && <React.Fragment>
          <div
            className={cx('AddEditMySession', {
              'Table-loading': isFirstLoading,
            })}
          >
            {isFirstLoading ? (
              <Spin />
            ) : (
              <React.Fragment>
                <Searchbar />
                <div style={{ marginBottom: '10px', width: '100%', display: 'flex' }}>
                  <SessionNote />
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                  >
                    <div>
                      <label style={{ margin: '0 10px', fontWeight: 'bold' }}>Show Weekend:</label>
                      <Radio.Group
                        value={store.showWeekend}
                        onChange={event => store.setFieldsValue({ showWeekend: event.target.value })}
                        disabled={store.loading}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                    <div>
                      <label style={{ margin: '0 10px', fontWeight: 'bold' }}>Show Columned View:</label>
                      <Radio.Group
                        value={store.dayWeekViewType}
                        onChange={event => {
                          if (event.target.value === 'all') {
                            if (!!store.selectedDoctors.length) {
                              store.setFieldsValue({
                                doctorId: store.selectedDoctors[0]?.Id,
                                selectedDoctor: store.selectedDoctors[0],
                                selectedDoctors: [],
                              });
                            }
                            store.handleSearch(store.doctorId);
                          }
                          if (event.target.value === 'column' && store.calendarViewMode !== 'dayGridMonth') {
                            store.handleSearch();
                            if (!!store.selectedDoctor) {
                              store.setFieldsValue({ selectedDoctors: [store.selectedDoctor] });
                            }
                          }
                          store.setFieldsValue({ dayWeekViewType: event.target.value, isReloadCalendar: true });
                        }}
                        disabled={store.loading}
                      >
                        <Radio value={'column'}>Yes</Radio>
                        <Radio value={'all'}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <SessionCalendar />
              </React.Fragment>
            )}
            {open && <CalendarModal />}
          </div>
        </React.Fragment>}
      </div>
    );
  }
}

export default AddEditMySession;
