import { Checkbox, DatePicker, Form, Input, Modal, Radio, Select, notification } from 'antd';
import { action, observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import customFetch from '../../../../utils/customFetch';
import CKEditor from './../../../admin-routes/MessageTemplate/CKEditor';
import { formatDate } from '../../../../utils/functions';
import DoctorDetailsStore from './DoctorDetailsStore';
import * as api from '@stores/api';
import DragDropFile from './../../../shared/DragDropFile';
import ModalConfirm from './ModalConfirm';
import { File, NoFile } from './Upload';
import { SpecRelationshipActivityResults } from '@utils/constants';
import { getCampaignInfo } from '@components/case-details-routes/AddEditMarketingCampaign/service';

@observer
class ModalConversation extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };

  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  @observable conversation = this.props.data || {
    Id: 0,
    DoctorId: DoctorDetailsStore.dataDoctor.Id,
    SentDate: moment(),
    Subject: '',
    Content: '',
    ReceiverType: 2,
    Receiver: api.currentUser.data.id,
    ReceiverName: api.currentUser.data.FullName,
    CallerSenderType: 3,
    CallerSender: api.currentUser.data.id,
    CallerSenderName: api.currentUser.data.FullName,
    ConversationType: 5,
    allowCMToView: false,
    allowDoctorToView: false,
    CampaignId: null,
    CampaignAttachments: null,
    CallEmailResultId: null,
    FollowUpNeeded: false,
    NextDueDate: null,
    Content: '',
  };

  @observable callerOrSenderHasTypeCM = false;
  @observable callerIsCM = 0;
  @observable receiverIsCM = 0;
  @observable uploadFile = [];
  @observable searchStringCM = '';
  @observable campaignMergeAttachments = [];

  @observable campaignDetail = {};

  @action getCampaignDetail = campaignId => {
    getCampaignInfo(campaignId).then(action(res => (this.campaignDetail = res?.objItem)));
  };

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'FollowUpNeeded' && value) {
        this.conversation.NextDueDate = moment();
      }
      this.conversation[fieldName] = value;
    });

  callerReceiverType = [
    { text: 'CaseManager', value: 1 },
    { text: 'Specialist', value: 2 },
    { text: 'Staff', value: 3 },
  ];

  conversationType = [
    //{ text: 'Phone', value: 0 },
    //{ text: 'Email', value: 1 },
    // { text: 'Email (Send Now)', value: 4 },
    { text: 'Private Notes', value: 5 },
    { text: 'Relationship Meeting', value: 2 },
    { text: 'Relationship Call', value: 8 },
    { text: 'Relationship Email', value: 9 },
    { text: 'Relationship General Activity', value: 3 },
  ];

  convertDateUTC = dateString => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    var utcServerTime = dataConversationLogLoadList.utcServerTime;
    var utcLocalMinutes = new Date().getTimezoneOffset();
    var addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    return moment(formatDate(dateString, true), 'DD MMM YYYY, HH:mm').add(addminutes, 'minutes');
  };

  @action componentDidMount() {
    if (!!this.conversation.Id) {
      this.conversation = {
        ...this.conversation,
        SentDate: this.convertDateUTC(this.conversation.SentDate),
        ReceiverType:
          this.callerReceiverType.find(t => {
            return t.text === this.conversation.ReceiverType;
          })?.value || 0,
        Receiver: this.conversation.Receiver || null,
        CallerSenderType:
          this.callerReceiverType.find(t => {
            return t.text === this.conversation.CallerSenderType;
          })?.value || 0,
        CallerSender: this.conversation.CallerSender || null,
        ConversationType: this.conversation.IsAutoEmail
          ? 1
          : this.conversationType?.find(t => {
              return t.text === this.conversation.TypeLog;
            })?.value || 0,
        allowCMToView: this.conversation.AllowCMToView,
        allowDoctorToView: this.conversation.AllowDoctorToView,
      };
      if (this.conversation.ReceiverType !== 3) {
        this.conversation.Receiver = parseInt(this.conversation.Receiver)
          ? parseInt(this.conversation.Receiver)
          : this.conversation.Receiver;
      }
      if (this.conversation.CallerSenderType !== 3) {
        this.conversation.CallerSender = parseInt(this.conversation.CallerSender)
          ? parseInt(this.conversation.CallerSender)
          : this.conversation.CallerSender;
      }
      if (this.conversation.CallerSenderType === 1 || this.conversation.ReceiverType === 1) {
        this.callerOrSenderHasTypeCM = true;
        if (this.conversation.CallerSenderType === 1) {
          this.callerIsCM = this.conversation.CallerSender;
        }
        if (this.conversation.ReceiverType === 1) {
          this.receiverIsCM = this.conversation.Receiver;
        }
      }
      if (this.conversation.CampaignId && this.conversation.CampaignId > 0) {
        this.campaignMergeAttachments = this.conversation.campaignMergeAttachments
          ? this.conversation.campaignMergeAttachments
          : [];
        this.getCampaignDetail(this.conversation.CampaignId);
      }
      if (this.conversation.Attachments && this.conversation.Attachments.length > 0) {
        this.uploadFile = this.conversation.Attachments;
      }
    }
  }

  @action handleCancel = () => {
    DoctorDetailsStore.toggleModal(false)();
  };

  handleSave = isSendMail => {
    const data = toJS(this.conversation);
    DoctorDetailsStore.setFieldsValue({ loading: true });
    if (this.isRelationshipType(data.ConversationType)) {
      if(!data.CallEmailResultId){
        DoctorDetailsStore.setFieldsValue({ loading: false });
        notification.error({
          message: 'Error',
          description: 'Please select Relationship Activity Result',
          duration: 5,
        });
        return;
      }
      if(data.FollowUpNeeded && !data.NextDueDate){
        notification.error({
          message: 'Error',
          description: 'Please input Follow-up Due Date',
          duration: 5,
        });
        DoctorDetailsStore.setFieldsValue({ loading: false });
        return;
      }
    }

    if (!data.Subject) {
      this.handleCancelConfirm();
      DoctorDetailsStore.setFieldsValue({ loading: false });
      return notification.error({
        message: 'Error',
        description: 'Please enter subject',
        duration: 5,
      });
    }
    if (!data.Receiver) {
      this.handleCancelConfirm();
      DoctorDetailsStore.setFieldsValue({ loading: false });
      return notification.error({
        message: 'Error',
        description: 'Please select a Receiver',
        duration: 5,
      });
    }
    if (!data.CallerSender) {
      this.handleCancelConfirm();
      DoctorDetailsStore.setFieldsValue({ loading: false });
      return notification.error({
        message: 'Error',
        description: 'Please select a Caller/Sender',
        duration: 5,
      });
    }
    if (!data.SentDate) {
      this.handleCancelConfirm();
      DoctorDetailsStore.setFieldsValue({ loading: false });
      return notification.error({
        message: 'Error',
        description: 'Please enter a Sent Date',
        duration: 5,
      });
    }
    const { dataDoctor, dataCMs, dataStaffs, fetchConversations } = DoctorDetailsStore;
    let receiver, callerSender;
    if (data.ReceiverType === 1) {
      receiver = dataCMs.find(i => i.value === data.Receiver);
    } else if (data.ReceiverType === 2) {
      receiver = {
        text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
        value: dataDoctor.Id,
      };
    } else if (data.ReceiverType === 3) {
      receiver = dataStaffs.find(i => i.value === data.Receiver);
    }
    if (data.CallerSenderType === 1) {
      callerSender = dataCMs.find(i => i.value === data.CallerSender);
    } else if (data.CallerSenderType === 2) {
      callerSender = {
        text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
        value: dataDoctor.Id,
      };
    } else if (data.CallerSenderType === 3) {
      callerSender = dataStaffs.find(i => i.value === data.CallerSender);
    }
    customFetch(isSendMail ? '/MedicalDoctor/ConversationLog_SubmitAndSend' : '/MedicalDoctor/ConversationLog_Submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        CallerSender: data.CallerSender,
        CallerSenderType: data.CallerSenderType,
        Content: data.Content,
        ConversationType: data.ConversationType,
        DoctorId: data.DoctorId,
        Id: data.Id,
        Receiver: data.Receiver,
        ReceiverType: data.ReceiverType,
        Subject: data.Subject,
        allowCMToView: data.allowCMToView,
        allowDoctorToView: data.allowDoctorToView,
        SentDate: data.SentDate.format('YYYY-MM-DD'),
        hour: data.SentDate.hour(),
        minute: data.SentDate.minute(),
        ReceiverName: receiver.text,
        CallerSenderName: callerSender.text,
        receiverId: receiver.value,
        callerSenderId: callerSender.value,
        attachment: this.uploadFile,
        campaignId: data.CampaignId,
        CallEmailResultId: data.CallEmailResultId,
        FollowUpNeeded: data.FollowUpNeeded,
        NextDueDate: data.NextDueDate,
      }),
    }).then(res => {
      DoctorDetailsStore.setFieldsValue({ loading: false });
      if (res.status === 'success') {
        fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Add Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while saving data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
      this.handleCancel();
    });
  };

  setFieldValue = name =>
    action(event => {
      const { campaginList } = DoctorDetailsStore;
      const value = event ? (event.target ? event.target.value : event) : null;
      if (name === 'CampaignId') {
        this.campaignMergeAttachments = campaginList
          ? campaginList.find(i => i.Id === value).CampaignMergeAttachments
          : [];
        this.conversation[name] = value;
      } else {
        this.conversation[name] = value;
      }
    });

  @action toggleAllowDoctorToView = e => {
    this.conversation.allowDoctorToView = e.target.checked;
  };

  @action setReceiverType = event => {
    const type = event.target.value;
    this.conversation.ReceiverType = type;
    if (type === 2) {
      this.conversation.Receiver = DoctorDetailsStore.dataDoctor.Id;
    } else if (type === 1) {
      this.conversation.Receiver = this.receiverIsCM;
    } else {
      this.conversation.Receiver = api.currentUser.data.id;
      this.conversation.ReceiverName = api.currentUser.data.FullName;
    }
  };

  @action setCallerSenderType = event => {
    const type = event.target.value;
    this.conversation.CallerSenderType = type;
    if (type === 2) {
      this.conversation.CallerSender = DoctorDetailsStore.dataDoctor.Id;
    } else if (type === 1) {
      this.conversation.CallerSender = this.callerIsCM;
    } else {
      this.conversation.CallerSender = api.currentUser.data.id;
      this.conversation.CallerSenderName = api.currentUser.data.FullName;
    }
  };

  @action searchCM = keyword => {
    const { fetchCaseManager, dataCaseManager } = DoctorDetailsStore;
    fetchCaseManager(keyword, dataCaseManager.length);
    this.searchStringCM = keyword;
  };

  @action UploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.attachmentType = reportType;
        element.physicalFileName = element.name;
        element.fileDisplayName = element.title;
      });
      this.uploadFile = [...this.uploadFile, ...acceptedFiles].filter(
        (v, i, a) => a.findIndex(t => t.name === v.name) === i,
      );
    }
  };

  @action DeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      this.uploadFile = [...this.uploadFile].filter(x => x.name !== deletedFiles.name);
    }
  };

  handleScroll = event => {
    const { fetchCaseManager, dataCaseManager } = DoctorDetailsStore;
    if (this.conversation.ReceiverType !== 1) {
      return;
    }
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      fetchCaseManager(this.searchStringCM, dataCaseManager.length + 20);
    }
  };

  renderReceiverSelect = () => {
    const { dataDoctor } = DoctorDetailsStore;
    const { ReceiverType } = this.conversation;
    let options = [];
    switch (ReceiverType) {
      case 1:
        options = DoctorDetailsStore.dataCMs;
        break;
      case 2:
        options = [
          {
            text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
            value: dataDoctor.Id,
          },
        ];
        if (!this.conversation.Id) {
          this.setFieldValue('Receiver')(dataDoctor.Id);
        }
        break;
      case 3:
        options = DoctorDetailsStore.dataStaffs;
        break;
      default:
        return null;
    }

    return (
      <Select
        size="large"
        value={this.conversation.Receiver}
        onChange={this.setFieldValue('Receiver')}
        onPopupScroll={this.handleScroll}
        onSearch={this.searchCM}
        optionFilterProp="children"
        showSearch={ReceiverType === 1}
      >
        {options.map(i => (
          <Select.Option key={i.value} value={i.value}>
            {i.text}
          </Select.Option>
        ))}
      </Select>
    );
  };

  renderCallerSenderSelect = () => {
    const { dataDoctor } = DoctorDetailsStore;
    const { CallerSenderType } = this.conversation;
    let options = [];
    switch (CallerSenderType) {
      case 1:
        options = DoctorDetailsStore.dataCMs;
        break;
      case 2:
        options = [
          {
            text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
            value: dataDoctor.Id,
          },
        ];
        break;
      case 3:
        options = DoctorDetailsStore.dataStaffs;
        break;
      default:
        return null;
    }

    return (
      <Select
        size="large"
        value={this.conversation.CallerSender}
        onChange={this.setFieldValue('CallerSender')}
        onPopupScroll={this.handleScroll}
        onSearch={this.searchCM}
        optionFilterProp="children"
        showSearch={CallerSenderType === 1}
      >
        {options.map(i => (
          <Select.Option key={i.value} value={i.value}>
            {i.text}
          </Select.Option>
        ))}
      </Select>
    );
  };

  isRelationshipType = type => {
    return type === 2 || type === 8 || type === 9;
  };

  converDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString, true);
          const newDate = moment(dateFormat, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
          return moment(newDate, 'DD/MM/YYYY - HH:mm');
        }
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      } else {
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      }
    }
    return null;
  };

  ckEditorRef = React.createRef();
  render() {
    const { open } = DoctorDetailsStore;
    return (
      <Modal
        title={this.conversation.Id ? 'Case Correspondence' : 'Add New'}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={1000}
        visible={open}
        footer={
          <React.Fragment>
            {!(this.props.action === 'view') && api.isAdminOrMagStaffOrAccounting() && !api.isOnlySearch() && (
              <Button
                color="blue"
                // onClick={() => this.handleSave(true)}
                onClick={() => {
                  this.handleOpenConfirm(true, {
                    modalType: 'confirm',
                    message: `Are you sure to save and send email to the receiver?`,
                    onOk: () => this.handleSave(true),
                  });
                }}
                disabled={this.conversation.CallerSenderType !== 3}
              >
                Save & Send
              </Button>
            )}
            {!(this.props.action === 'view') && !api.isOnlySearch() && (
              <Button color="blue" onClick={() => this.handleSave(false)}>
                Save
              </Button>
            )}
            <Button className="negative" onClick={this.handleCancel}>
              Cancel
            </Button>
          </React.Fragment>
        }
      >
        <div className="Modal-Conversation">
          <Form>
            <Checkbox
              className="mb-1"
              checked={this.conversation.allowDoctorToView}
              onChange={this.toggleAllowDoctorToView}
            >
              Allow Specialists to view
              <Popup
                trigger={<Icon name="info circle" />}
                content="Specialists can view correspondence"
                position="top center"
                wide="very"
              />
            </Checkbox>
            <Form.Item label="Correspondence Type">
              <Select
                size="large"
                value={this.conversation.ConversationType}
                onChange={this.setFieldValue('ConversationType')}
              >
                {this.conversationType.map(i => (
                  <Select.Option key={i.value} value={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {this.isRelationshipType(this.conversation.ConversationType) && (
              <>
                <Form.Item
                  label="Relationship Activity Result"
                  className="fluid-field"
                  required
                  validateStatus={!this.conversation.CallEmailResultId ? 'error' : null}
                  help={!this.conversation.CallEmailResultId ? 'This field is required' : null}
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={this.conversation.CallEmailResultId}
                    onChange={this.setFieldValue('CallEmailResultId')}
                    //placeholder="Select a campaign"
                  >
                    {/* {this.conversation?.CallEmailResultId &&
                      RelationshipActivityResults.filter(i => !i.Visible).find(
                        i => i.Id === this.conversation.CallEmailResultId,
                      ) && (
                        <Select.Option
                          key={
                            RelationshipActivityResults.filter(i => !i.Visible).find(
                              i => i.Id === this.conversation.CallEmailResultId,
                            ).Id
                          }
                          value={
                            RelationshipActivityResults.filter(i => !i.Visible).find(
                              i => i.Id === this.conversation.CallEmailResultId,
                            ).Id
                          }
                        >
                          {
                            RelationshipActivityResults.filter(i => !i.Visible).find(
                              i => i.Id === this.conversation.CallEmailResultId,
                            ).Name
                          }
                        </Select.Option>
                      )} */}
                    {SpecRelationshipActivityResults.map(({ Id, Name }) => (
                      <Select.Option key={Id} value={Id}>
                        {Name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item className="fluid-field">
                  <Checkbox
                    checked={this.conversation.FollowUpNeeded}
                    onChange={this.handleCheckedConversation('FollowUpNeeded')}
                  >
                    Follow-up Activity Needed
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="Follow-up Due Date"
                  required={this.conversation.FollowUpNeeded}
                  validateStatus={this.conversation.FollowUpNeeded && !this.conversation.NextDueDate ? 'error' : null}
                  help={
                    this.conversation.FollowUpNeeded && !this.conversation.NextDueDate ? 'This field is required' : null
                  }
                  className="fluid-field"
                >
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    value={this.converDate(this.conversation.NextDueDate)}
                    onChange={this.setFieldValue('NextDueDate')}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item label="Link to Marketing Campaign" className="fluid-field">
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                value={this.conversation.CampaignId}
                onChange={value => {
                  if (value) {
                    this.getCampaignDetail(value);
                  } else {
                    this.campaignDetail = {};
                  }
                  this.setFieldValue('CampaignId')(value);
                }}
                //onSearch={AddEditStore.handleSearchCampaign()}
                placeholder="Select a campaign"
              >
                {DoctorDetailsStore.campaginList &&
                  this.conversation.CampaignId &&
                  this.conversation.CampaignObj &&
                  !DoctorDetailsStore.campaginList.find(i => i.Id === this.conversation.CampaignId) && (
                    <Select.Option
                      style={{ display: 'none' }}
                      value={this.conversation.CampaignId}
                      key="campaign-default-id"
                    >
                      {`${this.conversation.CampaignObj.Id} - ${
                        this.conversation.CampaignObj.CampaignName
                      } | Start: ${moment(this.conversation.CampaignObj.CampaignDate).format('DD/MM/YYYY')} ${
                        this.conversation.CampaignObj.CampaignEndDate
                          ? '- End: ' + moment(this.conversation.CampaignObj.CampaignEndDate).format('DD/MM/YYYY')
                          : ''
                      }`}
                    </Select.Option>
                  )}
                {DoctorDetailsStore.campaginList.map(({ Id, CampaignName, CampaignDate, CampaignEndDate }) => (
                  <Select.Option key={Id} value={Id}>
                    {`${Id} - ${CampaignName} | Start: ${moment(CampaignDate).format('DD/MM/YYYY')} ${
                      CampaignEndDate ? '- End: ' + moment(CampaignEndDate).format('DD/MM/YYYY') : ''
                    }`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {this.conversation.CampaignId > 0 && this.campaignMergeAttachments && (
              <Form.Item label="Campaign Attachments" className="fluid-field campaignAttachments">
                {this.campaignMergeAttachments.length <= 0 ? (
                  <NoFile />
                ) : (
                  <div>
                    {this.campaignMergeAttachments &&
                      this.campaignMergeAttachments?.map(i => (
                        <div className="mb-1" key={i.FileName}>
                          <File name={i.Title} downloadLink={`/UserFile/CampaignAttachment/${i.FileName}`} />
                        </div>
                      ))}
                  </div>
                )}
              </Form.Item>
            )}
            <Form.Item
              label="Date/Time"
              required
              validateStatus={!this.conversation.SentDate ? 'error' : null}
              help={!this.conversation.SentDate ? 'This field is required' : null}
            >
              <DatePicker
                size="large"
                showTime={{ format: 'HH:mm' }}
                format="DD/MM/YYYY - HH:mm"
                value={this.conversation.SentDate}
                onChange={this.setFieldValue('SentDate')}
              />
            </Form.Item>
            <Form.Item
              label="Subject"
              required
              validateStatus={!this.conversation.Subject ? 'error' : null}
              help={!this.conversation.Subject ? 'This field is required' : null}
            >
              <Input size="large" value={this.conversation.Subject} onChange={this.setFieldValue('Subject')} />
            </Form.Item>
            {/* <Form.Item label="Content">
              <Input.TextArea
                rows={4}
                value={this.conversation.Content}
                onChange={this.setFieldValue('Content')}
              />
            </Form.Item> */}

            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Activity Content">
                  Activity Content
                </label>
                <span
                  style={{ cursor: 'pointer', color: '#2185d0' }}
                  onClick={() => {
                    if (this.campaignDetail?.CampaignContent) {
                      this.ckEditorRef.current.setNewHtml(this.campaignDetail?.CampaignContent);
                    }
                  }}
                >
                  Copy Campaign Content
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="This will copy the selected marketing campaign content to activity content blow"
                    position="top center"
                    wide="very"
                  />
                </span>
              </div>
              <CKEditor
                ref={this.ckEditorRef}
                html={this.conversation.Content}
                onChangeValue={this.setFieldValue('Content')}
              />
            </div>
            {!(this.props.action === 'view') && api.isAdminOrMagStaffOrAccounting() && (
              <div style={{ marginTop: '10px' }}>
                <DragDropFile
                  onUploadFile={this.UploadFile}
                  onDeleteFile={this.DeleteFile}
                  fileList={this.uploadFile}
                  type="7"
                  title="Attachment"
                  maxSize="50"
                  multiple={true}
                />
                <hr></hr>
              </div>
            )}
            <Form.Item
              label="Caller/Sender"
              required
              validateStatus={!this.conversation.CallerSender ? 'error' : null}
              help={!this.conversation.CallerSender ? 'This field is required' : null}
            >
              <Radio.Group value={this.conversation.CallerSenderType} onChange={this.setCallerSenderType}>
                {this.callerOrSenderHasTypeCM && <Radio value={1}>Case Manager</Radio>}
                <Radio value={2}>Specialist</Radio>
                <Radio value={3}>Staff</Radio>
              </Radio.Group>
              {this.renderCallerSenderSelect()}
            </Form.Item>
            <Form.Item
              label="Receiver"
              required
              validateStatus={!this.conversation.Receiver ? 'error' : null}
              help={!this.conversation.Receiver ? 'This field is required' : null}
            >
              <Radio.Group value={this.conversation.ReceiverType} onChange={this.setReceiverType}>
                {this.callerOrSenderHasTypeCM && <Radio value={1}>Case Manager</Radio>}
                <Radio value={2}>Specialist</Radio>
                <Radio value={3}>Staff</Radio>
              </Radio.Group>
              {this.renderReceiverSelect()}
            </Form.Item>
          </Form>
        </div>
        {this.openConfirm && (
          <ModalConfirm {...this.confirmParams} open={this.openConfirm} onCancel={this.handleCancelConfirm} />
        )}
      </Modal>
    );
  }
}

export default ModalConversation;
