import { action, observable } from 'mobx';
import moment from 'moment';
import customFetch from '@utils/customFetch';

class NotificationStore {
  @observable loading = true;
  @observable itemList = null;
  @observable sortDirection = 'descending';
  @observable keyword = '';
  @observable sortColumn = '';
  @observable sortKey = '';
  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable fromTo = '';
  @observable notificationType = '';
  @observable modalParams = {
    record: null,
    isOpen: false,
    type: 'view',
  };

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
    };
  };

  @action fetchNotifications = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();

    customFetch('/Notification/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        From: fromDate,
        To: toDate,
        NotificationType: this.notificationType,
      }),
    }).then(
      action(data => {
        this.itemList = data.itemList;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action refetch = newPage => {
    this.curPage = newPage;
    this.fetchNotifications(false);
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchNotifications();
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = '';
    this.sortKey = '';
    this.sortDirection = 'descending';
    this.fromTo = '';
    this.curPage = 1;
    this.totalPage = 0;
    this.totalItems = 0;
    this.notificationType = '';
  };
}

export default new NotificationStore();
