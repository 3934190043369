import EventEmitter from 'eventemitter3';

export const EVENT_TYPE = {
  CASE_SEARCH_UPDATE: 'CASE_SEARCH_UPDATE',
};

class EventBus {
  _eventBusInstance;
  constructor() {
    this._eventBusInstance = new EventEmitter();
  }

  emit(eventName, data) {
    return this._eventBusInstance.emit(eventName, data);
  }

  on(eventName, callback) {
    return this._eventBusInstance.on(eventName, callback);
  }

  off(eventName, callback) {
    this._eventBusInstance.off(eventName, callback);
  }
}

export default new EventBus();
