import { action, observable, computed } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import { notification } from 'antd';
import * as api from '../../../stores/api';

import {
  getClientInfo,
  getBussinesUnit,
  getCityList,
  getClientOfficer,
  getCountryList,
  getLookupConfig,
  getStaff,
  getStaffAll,
  uploadFileTemplate,
  viewBookingHistory,
  getAllSpecialist,
  getRegisteredSpecialistDetails,
  getRegisteredSpecialists,
  getConversationLog,
  deleteConversationLog,
  allowCMViewConversationLog,
  saveConversationLog,
  getStaffByClientId,
  getActionRequired,
  getCMs,
  getLookupConfigByType,
} from './service';
// import { formatDate } from '../../../utils/functions';

const INIT_CLIENT = {
  ABN: null,
  AccContact: null,
  AccEmail: null,
  AccPhoneNumber: null,
  ActionCompleted: false,
  ActionNotes: null,
  ActionRequired: false,
  Address: null,
  AppointmentBookingReminderDueDay: 0,
  ApprovedFundingDate: null,
  AssignedToUserFullName: null,
  AssignedToUserId: null,
  CityId: 108,
  ClientAdvisors: null,
  ClientType: 3,
  ClientType2: 0,
  CommentsFunding: null,
  CompanyBusinessUnit: null,
  CompletedDate: null,
  CorrespondenceAddress: null,
  CorrespondenceCityId: 108,
  CorrespondenceCountryId: 16,
  CorrespondenceDistrict: null,
  CorrespondenceFullAddress: null,
  CorrespondencePostcode: null,
  CountryId: 16,
  District: null,
  DoctorTypesForSearching: null,
  DueDate: null,
  EffectiveMAGDirectEssentialFrom: null,
  EffectiveMAGDirectEssentialTo: null,
  EffectiveMAGDirectExtraFrom: null,
  EffectiveMAGDirectExtraTo: null,
  EffectiveMAGUltimateFrom: moment().format('DD/MM/YYYY'),
  EffectiveMAGUltimateTo: null,
  Email: null,
  DXEmail: null,
  EndFundingTerm: null,
  Fax: null,
  FullAddress: null,
  FundingApprovedByEmail: null,
  FundingApprovedById: null,
  FundingApprovedByName: null,
  FundingAttachments: null,
  Id: 0,
  IsDisbursementFunding: false,
  MAGDirectEssentialServiceFees: null,
  MAGDirectEssentialServiceFeesGST: null,
  MAGDirectExtraServiceFees: null,
  MAGDirectExtraServiceFeesGST: null,
  MAGUltimateServiceFees: null,
  MAGUltimateServiceFeesGST: null,
  Name: null,
  OtherClientType: null,
  Postcode: null,
  PrimarySegments: null,
  RDCCaseManagerEmail: null,
  RDCCaseManagerName: null,
  ReportCompletionReminderDueDay: 0,
  ReportDeliveryContact: null,
  SecondarySegments: null,
  StaffId: null,
  StartFundingTerm: null,
  SubscribeToInvoiceReminders: false,
  SubscriptionNotes: null,
  SubscribeToPromotions: true,
  Telephone: null,
  Website: null,
  DefaultBookingConfirmationEmail: null,
  ReportDeliveryContactEmail: null,
  SubscribeSpecialistInvoiceCheck: false,
  PreApprovedDisbursementFundingTaken: true,
  PreApprovedDisbursementFundingAmount: 100000,
  DisbursmentFundingAmount: 100000,
  CreditTerm: 0,
  CreditTermDate: null,
  CreditTermBy: null,
  IcareInsurer: false,
  ClientProfileId: 0,
  ClientProfileOther: null,
};

const BODY_CONVERSATIONLOG = {
  CallerSender: '',
  CallerSenderName: '',
  CallerSenderType: 1,
  CompanyId: 0,
  Content: '',
  ConversationType: 1,
  Id: 0,
  Receiver: '',
  ReceiverName: '',
  ReceiverType: 1,
  SentDate: moment(),
  Subject: '',
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: '',
  hour: 0,
  minute: 0,
  receiverId: 0,
};

const VALIDATE_RULES = {
  Name: 'required',
  //ClientType2: 'required',
  ClientType: 'required',
  OtherClientType: 'required',
  Telephone: 'max:10',
  Email: 'email',
  //EffectiveMAGDirectEssentialFrom: 'required',
  //EffectiveMAGDirectExtraFrom: 'required',
  //EffectiveMAGUltimateFrom: 'required',
  CityId: 'required',
  Fax: ['max:10', 'min:6'],
  AccPhoneNumber: 'max:10',
  AccEmail: 'email',
  DefaultBookingConfirmationEmail: 'email',
  ReportDeliveryContactEmail: 'email',
  PreApprovedDisbursementFundingAmount: 'required',
  DisbursmentFundingAmount: 'required',
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to 10 digits',
  },
  min: {
    string: 'Must be more than or equal to 6 digits',
  },
};

const EFFECTIVE_FROM_FIELD_NAME = [
  'EffectiveMAGDirectEssentialFrom',
  'EffectiveMAGDirectExtraFrom',
  'EffectiveMAGUltimateFrom',
];

class AddEditClientStore {
  @observable loading = true;
  @observable loadingBH = true;

  @observable loadingUpload = false;
  @observable loadingSave = false;
  @observable loadingCheckDuplicate = false;

  @observable deliveryKeyword = '';
  @observable keywordBH = true;
  @observable filterBH = 'All';

  //Data
  @observable clientInfo = INIT_CLIENT;
  @observable businessUnitList = null;
  @observable cityList = null;
  @observable clientOfficerList = null;
  @observable staffList = null;
  @observable staffAllList = null;
  @observable lookupConfig = null;
  @observable countryList = null;
  @observable clientProfilePMS = null;
  @observable clientProfileCMS = null;

  @observable countryCorrespondenceList = null;
  @observable cityCorrespondenceList = null;
  @observable actionRequiredList = [];

  //Correspondence
  @observable loadingConversation = false;
  @observable conversationParam = BODY_CONVERSATIONLOG;
  @observable conversationList = null;
  @observable staffAllByClientList = null;
  //Error
  @observable errors = {};

  //Modal
  @observable open = false;
  @observable modalParams = {};

  //Modal booking history
  @observable dataBookingHistory = null;
  @observable dataBookingHistoryView = null;
  @observable loadingBookingHistory = true;
  @observable totalBooking = 0;

  //Booking history
  @observable curPageBookingHistory = 1;
  @observable pageSizeBookingHistory = 50;
  @observable totalPageBookingHistory = 0;
  @observable totalItemsBookingHistory = 0;

  @observable sortColumnBookingHistory = 'Request Date';
  @observable sortDirectionBookingHistory = 'ascending';
  @observable sortKeyBookingHistory = 'RequestDate';
  @observable companyCMs = [];
  @observable selectedSearchSpecialistValue = 1;
  @observable isDuplicateComp = false;

  @action handleFetchBookingHistory = () => {
    Promise.all([
      viewBookingHistory(
        this.clientInfo.Id,
        this.sortKeyBookingHistory,
        this.sortDirectionBookingHistory,
        this.curPageBookingHistory,
        this.pageSizeBookingHistory,
      ),
    ]).then(
      action(([dataBooking]) => {
        this.loadingBookingHistory = false;
        this.dataBookingHistory = dataBooking;
        this.dataBookingHistoryView = dataBooking && dataBooking.itemList ? dataBooking.itemList : [];
        this.loadingBH = false;
        this.totalBooking = dataBooking.sumItem;
        this.totalPageBookingHistory = dataBooking.sumPage;
        this.totalItemsBookingHistory = dataBooking.sumItem;
      }),
    );
  };

  @action refetchBookingHistory = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([
      viewBookingHistory(
        this.clientInfo.Id,
        this.sortKeyBookingHistory,
        this.sortDirectionBookingHistory,
        this.curPageBookingHistory,
        this.pageSizeBookingHistory,
      ),
    ]).then(
      action(([dataBooking]) => {
        this.loadingBookingHistory = false;
        this.dataBookingHistory = dataBooking;
        this.dataBookingHistoryView = dataBooking && dataBooking.itemList ? dataBooking.itemList : [];
        this.loadingBH = false;
        this.totalBooking = dataBooking.sumItem;
        this.totalPageBookingHistory = dataBooking.sumPage;
        this.totalItemsBookingHistory = dataBooking.sumItem;
      }),
    );
  };

  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBookingHistory = column;
    this.sortDirectionBookingHistory = direction;
    this.sortKeyBookingHistory = sortKey;
    this.handleFetchBookingHistory();
  };

  //Assign Doctor
  @observable specialistLoading = false;
  @observable registeredSpecialistIds = [];
  @observable specialists = [];
  @observable sumItemSpecialist = 0;
  @observable sumPageSpecialist = 0;
  @observable curPageSpecialist = 1;
  @observable searchSpecialist = '';
  @observable selectedSpecialist = 'selected';
  @observable openModalAction = false;

  @action toggleModalAction = isOpen => {
    this.openModalAction = isOpen;
  };

  refetchActionRequired = () => {
    getActionRequired(this.clientInfo.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };

  @action handlePageClickSpecialist = page => {
    if (this.selectedSpecialist === 'All') {
      this.curPageSpecialist = page;
      this.specialistLoading = true;

      getAllSpecialist({
        CurPage: page,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList }) => {
          this.specialists = itemList;
          this.specialistLoading = false;
        }),
      );
    }
  };

  @action handleClearSearchSpecialist = () => {
    this.searchSpecialist = '';
    this.handleSearchSpecialist();
  };

  @action handleSearchChange = event => {
    this.searchSpecialist = event.target.value;
  };

  fetchSpecialists = id => {
    return getRegisteredSpecialists(id).then(
      action(({ itemList }) => {
        if (itemList.length !== 0) {
          this.registeredSpecialistIds = itemList;
          this.selectedSpecialist = 'selected';
          return getRegisteredSpecialistDetails(id, itemList);
        } else {
          this.selectedSpecialist = 'All';
          return getAllSpecialist({
            CurPage: 1,
            IsSelectionList: true,
            Keyword: '',
          });
        }
      }),
    );
  };

  @action handleSearchSpecialist = () => {
    this.specialistLoading = true;

    if (this.selectedSpecialist === 'selected') {
      setTimeout(
        action(() => {
          this.specialistLoading = false;
        }),
        300,
      );
    } else {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.searchSpecialist,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          this.specialists = itemList;
          this.sumItemSpecialist = sumItem;
          this.sumPageSpecialist = sumPage;
          this.curPageSpecialist = 1;
          this.specialistLoading = false;
        }),
      );
    }
  };
  @action handleChangeSearchSpecialist = fieldName => event => {
    this.selectedSpecialist = value;
    this.specialistLoading = true;
    const value = event ? (event.target ? event.target.value : event) : null;
    console.log(value);
    this.setFieldsValue({ selectedSearchSpecialistValue: value });
    if (value === 0) {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          this.specialists = itemList;
          this.sumItemSpecialist = sumItem;
          this.sumPageSpecialist = sumPage;
          this.curPageSpecialist = 1;
          this.specialistLoading = false;
        }),
      );
    } else {
      getRegisteredSpecialists(this.clientInfo.Id)
        .then(({ itemList }) => getRegisteredSpecialistDetails(this.clientInfo.Id, itemList))
        .then(
          action(({ itemList, sumPage, sumItem }) => {
            this.specialists = itemList;
            this.sumItemSpecialist = sumItem;
            this.sumPageSpecialist = sumPage;
            this.curPageSpecialist = 1;
            this.specialistLoading = false;
          }),
        );
    }
  };
  @action handleSelectChangeSpecialist = value => {
    this.selectedSpecialist = value;
    this.specialistLoading = true;

    if (value === 'All') {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          this.specialists = itemList;
          this.sumItemSpecialist = sumItem;
          this.sumPageSpecialist = sumPage;
          this.curPageSpecialist = 1;
          this.specialistLoading = false;
        }),
      );
    } else {
      getRegisteredSpecialists(this.clientInfo.Id)
        .then(({ itemList }) => getRegisteredSpecialistDetails(this.clientInfo.Id, itemList))
        .then(
          action(({ itemList, sumPage, sumItem }) => {
            this.specialists = itemList;
            this.sumItemSpecialist = sumItem;
            this.sumPageSpecialist = sumPage;
            this.curPageSpecialist = 1;
            this.specialistLoading = false;
          }),
        );
    }
  };

  /////////////////////////////////////////

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };
  //

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleAddError = (fieldName, errorMessage) => {
    this.errors[fieldName] = errorMessage;
  };

  @action fetchAll = clientId => {
    this.loading = true;
    Promise.all([
      getClientInfo(clientId),
      getBussinesUnit(),
      getCityList(),
      getClientOfficer(false),
      getStaff(),
      getLookupConfig(),
      getLookupConfigByType('cpPMS'),
      getLookupConfigByType('cpCMS'),
      getCountryList(),
      this.fetchSpecialists(clientId),
      getConversationLog(clientId),
      getStaffByClientId(clientId),
      getActionRequired(clientId),
    ]).then(
      action(
        ([
          clientInfo,
          businessUnitList,
          cityList,
          clientOfficerList,
          staffList,
          lookupConfig,
          clientProfilePMS,
          clientProfileCMS,
          countryList,
          specialist,
          conversation,
          staffByClientId,
          actionRequiredList,
        ]) => {
          this.clientInfo = clientInfo;
          Object.keys(VALIDATE_RULES)
            .filter(
              i =>
                i !== 'EffectiveMAGDirectEssentialFrom' &&
                i !== 'EffectiveMAGDirectExtraFrom' &&
                i !== 'EffectiveMAGUltimateFrom' &&
                i !== 'OtherClientType' &&
                i !== 'DisbursmentFundingAmount' &&
                i !== 'PreApprovedDisbursementFundingAmount',
            )
            .map(i => this.handleValidate(i, this.clientInfo[i]));
          this.countryCorrespondenceList = countryList;
          this.cityCorrespondenceList = cityList;
          this.businessUnitList = businessUnitList;
          this.cityList = cityList;
          this.clientOfficerList = clientOfficerList;
          this.staffList = staffList;
          this.actionRequiredList = actionRequiredList.itemList;
          this.lookupConfig = lookupConfig;
          this.clientProfilePMS = clientProfilePMS;
          this.clientProfileCMS = clientProfileCMS;
          this.countryList = countryList;
          this.specialists = specialist.itemList;
          this.sumItemSpecialist = specialist.sumItem;
          this.sumPageSpecialist = specialist.sumPage;
          this.curPageSpecialist = 1;
          this.handleFetchBookingHistory();
          this.conversationList = conversation;
          this.staffAllByClientList = staffByClientId;
          this.loading = false;
          // getStaffAll({ keyword: clientInfo.ReportDeliveryContact }).then(
          //   action(res => {
          //     this.loading = false;
          //     this.staffAllList = res;
          //   }),
          // );
          getCMs({ companyId: this.clientInfo.Id }).then(
            action(res => {
              if (this.clientInfo.Id) {
                this.companyCMs = res.itemList;
              } else {
                this.companyCMs = [];
              }
            }),
          );
        },
      ),
    );
  };

  @action refetchDeliveryReport = ({ keyword = '' }) => {
    this.deliveryKeyword = keyword;
    getStaffAll({ keyword: keyword }).then(
      action(res => {
        this.staffAllList = res;
      }),
    );
  };

  @action handleValidate = (field, value) => {
    const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);
    if (field === 'Fax') {
      const minDigits = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][1] }, ERROR_MESSAGES);
      const maxDigits = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][0] }, ERROR_MESSAGES);
      if (minDigits.passes()) {
        delete this.errors[field];
        if (maxDigits.passes()) {
          delete this.errors[field];
        } else {
          this.errors[field] = maxDigits.errors.first(field);
        }
      } else {
        this.errors[field] = minDigits.errors.first(field);
      }
    } else {
      if (validation.passes()) {
        delete this.errors[field];
      } else {
        this.errors[field] = validation.errors.first(field);
      }
    }
  };

  @action handleChecked = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'PreApprovedDisbursementFunding' && !value) {
        delete this.errors.PreApprovedDisbursementFundingAmount;
      }
      if (fieldName === 'PreApprovedDisbursementFunding' && value) {
        this.handleValidate(
          'PreApprovedDisbursementFundingAmount',
          this.clientInfo.PreApprovedDisbursementFundingAmount,
        );
      }
      if (fieldName === 'SubscribeToPromotions' && !value) {
        return this.setFieldsValue({
          open: true,
          modalParams: {
            message: 'Unsubscribing a client will unsubscribe all CMs assigned to this client, do you want to proceed?',
            onOk: action(() => (this.clientInfo[fieldName] = value)),
            modalType: 'confirm',
          },
        });
      }
      if (fieldName === 'SubscribeToPromotions' && value) {
        return this.setFieldsValue({
          open: true,
          modalParams: {
            message: 'Subscribing a client will subscribe all CMs assigned to this client, do you want to proceed?',
            onOk: action(() => (this.clientInfo[fieldName] = value)),
            modalType: 'confirm',
          },
        });
      }
      if (fieldName === 'IsDisbursementFunding' && !value) {
        delete this.errors.DisbursmentFundingAmount;
      }
      if (fieldName === 'IsDisbursementFunding' && value) {
        this.handleValidate('DisbursmentFundingAmount', this.clientInfo.DisbursmentFundingAmount);
      }
      this.clientInfo[fieldName] = value;
    });

  @action handleUploadFile = file => {
    this.loadingUpload = true;

    const formData = new FormData();
    formData.append(file.name, file);

    uploadFileTemplate(formData).then(
      action(({ fileList }) => {
        const attachments = this.clientInfo.FundingAttachments ? JSON.parse(this.clientInfo.FundingAttachments) : [];
        const attachment = {
          FileName: fileList[0].name,
          Title: fileList[0].title,
          NumberOfPages: fileList[0].numberOfPages,
          Id: attachments.length,
        };

        const newFundingAttachments = [...attachments, attachment];
        this.clientInfo.FundingAttachments = JSON.stringify(newFundingAttachments);
        this.loadingUpload = false;
      }),
    );
  };

  @action initData = () => {
    this.loading = true;
    Promise.all([
      getBussinesUnit(),
      getCityList(),
      getClientOfficer(),
      getStaff(),
      getLookupConfig(),
      getCountryList(),
    ]).then(
      action(([businessUnitList, cityList, clientOfficerList, staffList, lookupConfig, countryList]) => {
        this.clientInfo = INIT_CLIENT;
        this.businessUnitList = businessUnitList;
        this.cityList = cityList;
        this.clientOfficerList = clientOfficerList;
        this.staffList = staffList;
        this.staffAllList = { itemList: [] };
        this.countryCorrespondenceList = countryList;
        this.cityCorrespondenceList = cityList;
        this.lookupConfig = lookupConfig;
        this.countryList = countryList;
        this.loading = false;
        this.loadingBookingHistory = false;
      }),
    );
  };

  @action handleDeleteFile = index => {
    const attachments = JSON.parse(this.clientInfo.FundingAttachments).filter((_, idx) => idx !== index);
    this.clientInfo.FundingAttachments = JSON.stringify(attachments);
  };

  renderErrorFieldName = type => {
    switch (type) {
      case 0:
        return 'EffectiveMAGUltimateFrom';
      case 1:
        return 'EffectiveMAGDirectEssentialFrom';
      case 2:
        return 'EffectiveMAGDirectExtraFrom';
      default:
        return '';
    }
  };

  removeErrorEffective = key => {
    EFFECTIVE_FROM_FIELD_NAME.filter(i => i !== this.renderErrorFieldName(key)).map(i => this.removeError(i));
  };

  @action addError = (key, errorMessage) => {
    this.errors = { ...this.errors, [key]: errorMessage };
  };

  handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if(fieldName === 'Name') this.isDuplicateComp = false;
      if (
        fieldName === 'DoctorTypesForSearching' ||
        fieldName === 'PrimarySegments' ||
        fieldName === 'SecondarySegments'
      ) {
        this.clientInfo[fieldName] = value.join();
      } else if (fieldName === 'ClientType2') {
        if (!this.clientInfo[this.renderErrorFieldName(value)]) {
          this.addError(this.renderErrorFieldName(value), 'This field is required');
        }
        EFFECTIVE_FROM_FIELD_NAME.filter(i => i !== this.renderErrorFieldName(value)).map(i => this.removeError(i));
        this.clientInfo[fieldName] = value;
      } else if (fieldName === 'ClientType') {
        if (value === 5 && !this.clientInfo.OtherClientType) {
          this.addError('OtherClientType', 'This field is required');
        } else {
          delete this.errors['OtherClientType'];
        }
        this.clientInfo[fieldName] = value;
      } else if (fieldName === 'ClientAdvisors') {
        const exist = value.map(i => {
          const existStaff = this.clientOfficerList.itemList.find(staff => staff.Id === i);
          if (existStaff)
            return {
              fullName: existStaff.FullName,
              id: existStaff.Id,
            };
          return undefined;
        });
        this.clientInfo[fieldName] = exist.filter(i => i !== undefined);
      } else if (fieldName === 'CompanyBusinessUnit') {
        const exist = value.map(i => {
          const existBusinessUnit = JSON.parse(this.businessUnitList).itemList.find(unit => unit.id === i);
          if (existBusinessUnit)
            return {
              title: existBusinessUnit.title,
              id: existBusinessUnit.id,
            };
          return undefined;
        });
        this.clientInfo[fieldName] = exist.filter(i => i !== undefined);
      } else if (fieldName === 'CountryId') {
        Promise.all([getCityList(value)]).then(action(([cityList]) => (this.cityList = cityList)));
        this.clientInfo[fieldName] = value;
      } else if (fieldName === 'CorrespondenceCountryId') {
        Promise.all([getCityList(value)]).then(action(([cityList]) => (this.cityCorrespondenceList = cityList)));
        this.clientInfo[fieldName] = value;
      } else if (fieldName === 'AccPhoneNumber' || fieldName === 'Telephone' || fieldName === 'Fax') {
        this.clientInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      } else {
        this.clientInfo[fieldName] = value;
      }

      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.clientInfo[fieldName]);
      }
      if (fieldName === 'PreApprovedDisbursementFundingAmount' && !this.clientInfo.PreApprovedDisbursementFunding) {
        delete this.errors.PreApprovedDisbursementFundingAmount;
      }
      if (fieldName === 'DisbursmentFundingAmount' && !this.clientInfo.IsDisbursementFunding) {
        delete this.errors.DisbursmentFundingAmount;
      }
      if (fieldName === 'CityId' && this.clientInfo.CountryId === 16) {
        this.handleValidate(fieldName, this.clientInfo[fieldName]);
      } else {
        delete this.errors.CityId;
      }
    });

  @action onChangeCheckbox = fieldName => {
    console.log(fieldName);
    this.clientInfo[fieldName] = !this.clientInfo[fieldName];
  };

  @action removeError = key => {
    delete this.errors[key];
  };

  @computed get staffs() {
    return this.staffList && this.staffList.itemList
      ? this.staffList.itemList.map(i => ({ text: i.FullName, value: i.Id }))
      : [];
  }

  //Case Correspondence
  @action handleReloadConversation = clientId => {
    this.loadingConversation = true;
    Promise.all([getConversationLog(clientId)]).then(
      action(([list]) => {
        this.conversationList = list;
        this.loadingConversation = false;
      }),
    );
  };

  @action handleDeleteConversation = id => {
    this.loadingConversation = true;
    deleteConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.clientInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAllowConversation = id => {
    this.loadingConversation = true;
    allowCMViewConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.clientInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleResetBodyConversation = () => {
    this.conversationParam = {
      ...BODY_CONVERSATIONLOG,
      CallerSender: this.clientInfo.Id,
      CallerSenderName: this.clientInfo.Name,
      Receiver: this.clientInfo.Id,
      ReceiverName: this.clientInfo.Name,
    };
  };

  @action handleAddConversation = body => {
    this.loadingConversation = true;
    if (this.conversationParam.Receiver && this.conversationParam.CallerSender) {
      saveConversationLog(body).then(res => {
        if (res.status === 'success') {
          this.handleReloadConversation(this.clientInfo.Id);
          notification.destroy();
          notification.success({
            description: body.Id === 0 ? 'Add Conversation Log successfully!' : 'Update Conversation Log successfully!',
            message: 'Success',
            duration: 5,
          });
        }
      });
    } else {
      notification.error({
        description: 'Invalid form',
        message: 'Error',
        duration: 5,
      });
    }
  };

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.conversationParam[fieldName] = value;
    });

  handleFieldChangeConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'ConversationType') {
        this.conversationParam[fieldName] = event;
      } else if (fieldName === 'Receiver') {
        this.conversationParam.Receiver = value.key;
        this.conversationParam.ReceiverName = value.label;
      } else if (fieldName === 'CallerSender') {
        this.conversationParam.CallerSender = value.key;
        this.conversationParam.CallerSenderName = value.label;
      } else {
        if (fieldName === 'CallerSenderType') {
          if (value === 1) {
            this.conversationParam.CallerSender = this.clientInfo.Id;
            this.conversationParam.CallerSenderName = this.clientInfo.Name;
          }
          if (value === 3) {
            this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
            this.conversationParam.CallerSender = api.currentUser.data.id;
          }
        }
        if (fieldName === 'ReceiverType') {
          if (value === 1) {
            this.conversationParam.Receiver = this.clientInfo.Id;
            this.conversationParam.ReceiverName = this.clientInfo.Name;
          }
          if (value === 3) {
            this.conversationParam.ReceiverName = api.currentUser.data.FullName;
            this.conversationParam.Receiver = api.currentUser.data.id;
          }
        }
        this.conversationParam[fieldName] = value;
      }
    });

  @action resetStore = () => {
    this.loading = true;
    this.loadingBH = true;
    this.filterBH = '';
    this.keywordBH = '';
    this.clientInfo = INIT_CLIENT;
    this.errors = {};

    this.dataBookingHistory = null;
    this.loadingBookingHistory = true;
    this.totalBooking = 0;
    this.registeredSpecialistIds = [];

    this.sortColumnBookingHistory = 'Request Date';
    this.sortDirectionBookingHistory = 'ascending';
    this.sortKeyBookingHistory = 'RequestDate';
    this.selectedSpecialist = 'selected';
  };

  @action filterBookingHistory = type => {
    this.filterBH = type;
    if (type === 'All') {
      this.dataBookingHistoryView =
        this.dataBookingHistory && this.dataBookingHistory.itemList ? this.dataBookingHistory.itemList : [];
    } else {
      if (this.dataBookingHistory && this.dataBookingHistory.itemList && this.dataBookingHistory.itemList.length > 0) {
        this.dataBookingHistoryView = this.dataBookingHistory.itemList.filter(i => i.Type === this.filterBH);
      } else {
        this.dataBookingHistoryView = [];
      }
    }
  };

  @action resetStoreBH = () => {
    this.filterBH = 'All';
    this.dataBookingHistoryView =
      this.dataBookingHistory && this.dataBookingHistory.itemList ? this.dataBookingHistory.itemList : [];
  };
}

export default new AddEditClientStore();
