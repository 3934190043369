import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import Modal from '../../shared/Modal/';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import CaseManagersStore from './CaseManagersStore';
import moment from 'moment';

const columns = [
  { title: 'Login Date' },
  { title: 'Logout Date' },
  { title: 'IP Address' },
  { title: 'Additional info' },
];

@observer
class ModalLoginUserHistory extends React.Component {
  componentDidMount() {
    CaseManagersStore.fetchLoginHistory(true);
  }
  renderTableHistory = () =>
    CaseManagersStore.data1.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>
          {record.LoginDate
            ? moment(formatDate(record.LoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.LogoutDate
            ? moment(formatDate(record.LogoutDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.IPAddress}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { data1, loading1, toggleModal, open } = CaseManagersStore;
    const { record } = this.props.modalParams;
    const colSpan = columns.length;

    return (
      <Modal visible={open} width={700} onCancel={toggleModal(false)} title={`Login History - ${record.Email}`}>
        {loading1 && !data1 ? (
          <div className={`Table-loading`}>
            <Spin size="large" />
          </div>
        ) : (
          <Table sortable striped celled className={cx({ disabled: loading1 })}>
            <TableHeader columns={columns} />
            <Table.Body>
              {data1.itemList.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
            </Table.Body>
          </Table>
        )}
      </Modal>
    );
  }
}

export default ModalLoginUserHistory;
