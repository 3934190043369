export const NotificationTypes = [
  {
    key: 'Standard',
    text: 'Standard Message',
    value: 'Standard',
  },
  {
    key: 'Auto',
    text: 'Auto Message',
    value: 'Auto',
  },
];
