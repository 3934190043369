import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { notification, Select } from 'antd';
import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import Store from './Store';
import { getCities, getAllCategories } from './Service';
import * as api from '@stores/api';

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      categories: [],
      body: this.props.modalParams.record
        ? {
            ...this.props.modalParams.record,
            CategoryId: this.props.modalParams.record.CategoryId || null,
            TalkingPointText: this.props.modalParams.record.TalkingPointText || '',
            Description: this.props.modalParams.record.Description || '',
            IsLawPlaintiff: this.props.modalParams.record.IsLawPlaintiff || false,
            IsLawDefendant: this.props.modalParams.record.IsLawDefendant || false,
            IsInsurerOrSelfInsurer: this.props.modalParams.record.IsInsurerOrSelfInsurer || false,
            TalkingPointState: this.props.modalParams.record.TalkingPointState || [],
          }
        : {
            Id: 0,
            CategoryId: null,
            TalkingPointText: '',
            Description: '',
            IsLawPlaintiff: false,
            IsLawDefendant: false,
            IsInsurerOrSelfInsurer: false,
            TalkingPointState: [],
          },
    };
  }

  componentDidMount() {
    getCities().then(resp => {
      this.setState({ cities: resp.itemList });
    });
    getAllCategories().then(resp => {
      this.setState({ categories: resp.itemList });
    });
  }

  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    console.log(name);
    this.setState(state => (state.body[name] = value));
  };

  @action toggleCheckbox = (_, { name, checked }) => {
    console.log(name);
    this.setState(state => (state.body[name] = checked));
  };

  handleSelectChange = fieldName => event => {
    const { body } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      body: {
        ...body,
        [fieldName]: value,
      },
    });
  };

  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    if (!body.TalkingPointText) {
      return;
    }
    const param = {
      CategoryId: body.CategoryId,
      TalkingPointText: body.TalkingPointText,
      Description: body.Description,
      IsLawPlaintiff: body.IsLawPlaintiff,
      IsLawDefendant: body.IsLawDefendant,
      IsInsurerOrSelfInsurer: body.IsInsurerOrSelfInsurer,
      TalkingPointState: body.TalkingPointState,
    };
    Store.setFieldsValue({ loading: true });
    try {
      var resp = await customFetch('/TalkingPoint/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Id: id,
          ...param,
        }),
      });

      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Successfully',
          description: 'Data has been saved successfully.',
          duration: 3,
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }

      await Store.refetch({}, true);
    } catch (e) {
    } finally {
      Store.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body, cities, categories } = this.state;
    const { loading } = Store;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`Talking Point`}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  error={!body.TalkingPointText ? 'This field is required' : null}
                  label="Talking Point"
                  value={body.TalkingPointText}
                  placeholder="Talking Point"
                  name="TalkingPointText"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <div className="equal width fields">
                <div className="field">
                  <label style={{ marginBottom: '-15px' }}>Category</label>
                </div>
              </div>
              <Form.Group>
                <div style={{ padding: '8px', width: '100%' }}>
                  <Select
                    size="large"
                    value={body.CategoryId}
                    onChange={this.handleSelectChange('CategoryId')}
                    showArrow
                    optionFilterProp="children"
                  >
                    {categories.map(i => (
                      <Select.Option value={i.Id} key={'city' + i.Id}>
                        {i.Title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  rows={3}
                  label="Description"
                  value={body.Description}
                  placeholder="Description"
                  name="Description"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <div className="equal width fields">
                <div className="field">
                  <label style={{ marginBottom: '-15px' }}>Business Type</label>
                </div>
              </div>
              <Form.Group widths="equal">
                <Form.Checkbox
                  label="Law Plaintiff"
                  name="IsLawPlaintiff"
                  checked={body.IsLawPlaintiff}
                  onChange={this.toggleCheckbox}
                />
                <Form.Checkbox
                  label="Law Defendant"
                  name="IsLawDefendant"
                  checked={body.IsLawDefendant}
                  onChange={this.toggleCheckbox}
                />
                <Form.Checkbox
                  label="Insurer/Self Insurer"
                  name="IsInsurerOrSelfInsurer"
                  checked={body.IsInsurerOrSelfInsurer}
                  onChange={this.toggleCheckbox}
                />
              </Form.Group>
              <div className="equal width fields">
                <div className="field">
                  <label style={{ marginBottom: '-15px' }}>State</label>
                </div>
              </div>
              <Form.Group>
                <div style={{ padding: '8px', width: '100%' }}>
                  <Select
                    size="large"
                    mode="multiple"
                    value={toJS(body.TalkingPointState)}
                    onChange={this.handleSelectChange('TalkingPointState')}
                    showArrow
                    optionFilterProp="children"
                  >
                    {cities.map(i => (
                      <Select.Option value={i.CityId} key={'city' + i.CityId}>
                        {i.CityName}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
