import React from 'react';
import { observer } from 'mobx-react';

import AddEditStore from './store';
import Modal from '../../shared/Modal';
import UserUpdateHistory from '../../admin-routes/UserUpdateHistory';

@observer
class ModalUserUpdateHistory extends React.Component {
  render() {
    const { open } = AddEditStore;
    return (
      <Modal visible={open} width={1200} onCancel={AddEditStore.toggleModal(false)} title="Account Update History">
        <UserUpdateHistory userId={AddEditStore.CMInfo.UserId} username={AddEditStore.CMInfo.Username} type={2} />
      </Modal>
    );
  }
}

export default ModalUserUpdateHistory;
