import React from 'react';
import { Divider, Spin, notification } from 'antd';
import { Button, Dimmer } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import router from '../../../stores/router';
import dashboard from '../../../stores/dashboard';

import '../AddEditClient/index.scss';
import GeneralInformation from './GeneralInformation';
import AssessmentHistory from './AssessmentHistory';
import CaseCorrespondence from './CaseCorrespondence';
import AddEditClaimantStore from './Store';
import AddEditModal from './Modal';
import { action, toJS } from 'mobx';
import { ERROR_FIELDNAME, ERROR_MESSAGES } from './type';
import appStore from 'stores/app';
import * as api from '@stores/api';

const STEPS = ['General Information', 'Service History', 'Case Correspondence'];
@observer
class AddEditClaimant extends React.Component {
  state = {
    action: 'view',
    currentStep: 0,
    isRerender: false,
    pageGetter: null,
    fieldName: null,
  };

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  renderForm = () => {
    const { dataHistory, loadingHistory } = AddEditClaimantStore;
    switch (this.state.currentStep) {
      case 1:
        return loadingHistory && !dataHistory ? null : <AssessmentHistory />;
      case 2:
        return <CaseCorrespondence />;
      default:
        return <GeneralInformation isRerender={this.state.isRerender} />;
    }
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  componentWillUnmount() {
    AddEditClaimantStore.initClamantData();
  }

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()) {
      dashboard.close('/view/add-edit-claimant-2', true);
      return;
    }

    const { id, action, step = 0, pageGetter, fieldName } = this.getRouteParams();
    if (pageGetter && fieldName) {
      this.setState({ pageGetter, fieldName });
    }
    this.setState({ id, action, currentStep: +step }, () => {
      if (!!parseInt(id)) {
        AddEditClaimantStore.fetchAll(id);
      } else {
        AddEditClaimantStore.initClamantData();
      }
    });
  }

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return params;
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 5,
    });
  };

  getKeywordErrorMessage = message => {
    return message
      ? message.toLowerCase().search('invalid') !== -1
        ? 'invalid'
        : message.toLowerCase().search('required') !== -1
        ? 'required'
        : message.toLowerCase().search('digits') !== -1
        ? 'digits'
        : null
      : null;
  };

  retrunErrorMessage = (keywordFieldName, message) => {
    if (this.getKeywordErrorMessage(message) && keywordFieldName) {
      if (keywordFieldName === 'Email' && this.getKeywordErrorMessage(message) === 'invalid') {
        return this.showFormErrorNoti('Invalid email address');
      } else {
        return this.showFormErrorNoti(
          `${ERROR_FIELDNAME[keywordFieldName]} ${ERROR_MESSAGES[this.getKeywordErrorMessage(message)]}`,
        );
      }
    } else {
      return this.showFormErrorNoti('An error occurred, please try again');
    }
  };

  handleSave = () => {
    const { claimantInfo, errors } = AddEditClaimantStore;
    if (!claimantInfo.LastName) {
      action(() => {
        errors.LastName = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!claimantInfo.FirstName) {
      action(() => {
        errors.FirstName = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }

    if (!!Object.keys(AddEditClaimantStore.errors).length) {
      const errorList = Object.entries(toJS(AddEditClaimantStore.errors));
      notification.destroy();
      return errorList.map(([key, value]) => this.retrunErrorMessage(key, value));
    }
    const { fieldName, pageGetter } = this.state;
    const { listPageGetters } = dashboard;
    const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);
    AddEditClaimantStore.handleSaveClaimant().then(({ newId, status }) => {
      if (status === 'success') {
        if (fieldName && pageGetter) {
          if (!appStore.iframeMode) {
            dashboard.close(router.location.pathname);
          }
          let transferData = {
            fieldName: fieldName,
            pageGetter: pageGetter,
            dataTransfer: {
              ...claimantInfo,
              Id: newId,
              FullName: `${claimantInfo.FirstName} ${claimantInfo.LastName}`,
            },
          };

          if (appStore.iframeMode) {
            window.parent.postMessage({ action: 'TRANSER_DATA', data: transferData }, '*');
          } else {
            dashboard.transferData(transferData);
          }
        }
        if (urlPageGetter && urlPageGetter.pathname) {
          if (appStore.iframeMode) {
            setTimeout(() => {
              window.parent.postMessage({ action: 'CLOSE_CURRENT_TAB' }, '*');
              if (urlPageGetter.pathname === '/view/add-edit-ime-assessment-2') {
                dashboard.open(urlPageGetter.pathname);
              }
            }, 500);
          } else {
            setTimeout(() => {
              dashboard.open(urlPageGetter.pathname);
            });
          }
          return;
        }
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully',
        });
        if (!claimantInfo.Id) {
          let firstName = claimantInfo.FirstName.charAt(0).toUpperCase() + claimantInfo.FirstName.slice(1);
          let lastName = claimantInfo.LastName.charAt(0).toUpperCase() + claimantInfo.LastName.slice(1);
          localStorage.setItem('ClaimantName', `${firstName || ''} ${lastName || ''}`);
          dashboard.close('/view/add-edit-claimant-2');
          setTimeout(() => {
            dashboard.open(`/view/add-edit-claimant-2?id=${newId}`);
          });
          return;
        }
        return AddEditClaimantStore.fetchAll(newId);
      } else {
        return this.showFormErrorNoti('An error occurred, please try again');
      }
    });
  };
  handleClose = () => {
    const { isChanged } = AddEditClaimantStore;
    if (!!Object.keys(isChanged).length) {
      return AddEditClaimantStore.toggleModal(true, {
        modalType: 'confirm',
        onOk: () => {
          dashboard.close(router.location.pathname);
        },
        message: 'Are you sure not to save changes?',
      })();
    } else {
      return dashboard.close(router.location.pathname);
    }
  };
  render() {
    const { loading, open, claimantInfo } = AddEditClaimantStore;
    return (
      <div className="page-container add-edit-client">
        <Dimmer active={loading} inverted>
          <Spin size="large" />
        </Dimmer>
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {this.renderForm()}
        </div>
        {open && <AddEditModal />}
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              disabled={idx === 1 && claimantInfo.Id === 0}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && <Button primary onClick={this.handleSave}>
            Save
          </Button>}
          <Button className="negative" onClick={this.handleClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditClaimant;
