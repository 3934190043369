import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import AddEditStore from './Store';
import Modal from '../../shared/Modal';

import CKEditor from './CKEditor';
import { toJS } from 'mobx';

@observer
class ModalPrintMail extends React.Component {
  state = {
    content: toJS(AddEditStore.modalParams?.content),
  };
  handleChange = value => {
    this.setState({ content: value });
  };
  handlePrint = () => {
    var printContents = this.state.content;
    var popupWin = window.open('', '_blank', 'width=600,height=800');
    popupWin.document.open();
    popupWin.document.write(
      '<html><head><link href="~/Content/build/style" rel="stylesheet" /></head><body onload="window.print()">' +
        printContents +
        '</body></html>',
    );
    popupWin.document.close();
  };
  render() {
    const { open } = AddEditStore;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={AddEditStore.toggleModal(false)}
        title="Print Mail"
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handlePrint}>
              Print
            </Button>
            <Button className="negative" onClick={AddEditStore.toggleModal(false)}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <CKEditor html={this.state.content} handleChangeContent={this.handleChange} />
      </Modal>
    );
  }
}

export default ModalPrintMail;
