import './index.scss';
import 'draft-js/dist/Draft.css';
import React from 'react';
import { find } from 'lodash';
import { Button, Label, Dimmer, Loader, Tab, Icon, Menu, TextArea, Input, Checkbox, Form } from 'semantic-ui-react';
import { notification, Spin } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import customFetch from '../../../utils/customFetch';
import CKEditor from '../../shared/CKEditor';
import { Editor, EditorState, ContentState, convertFromHTML } from 'draft-js';
import ModalHistories from './ModalHistories';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

const ConfigKeys = {
  MagSignature: 'MagSignature',
  DirectSignature: 'DirectSignature',
  Office365: 'Office365',
  Xero: 'Xero',
  ContactPhone: 'SystemContactPhone',
  ContactEmail: 'SystemContactEmail',
  OnlinePortal: 'SystemOnlinePortal',
  PortalName: 'SystemPortalName',
  InquiryType: 'InquiryType',
  InquirySharedEmails: 'InquirySharedEmails',
  TwilioSetting: 'TwilioSetting',
  TwilioId: 'TwilioId',
  TwilioToken: 'TwilioToken',
  TwilioFromPhone: 'FromPhone',
  SMSProvider: 'SMSProvider',
  MednegCOT: 'MednegCOT',
  ExportFilePassword: 'ExportFilePassword',
};

class SystemConfig extends React.Component {
  state = {
    user: null,
    loading: true,
    isOpenModelHistories: false,
    magSignatureEditor: '',
    directSignatureEditor: EditorState.createEmpty(),
    office365Config: null,
    xeroConfig: null,
    contactPhoneConfig: null,
    contactEmailConfig: null,
    onlinePortalConfig: null,
    smsProviderConfig: null,
    twilioSettingValueConfig: null,
    twilioSettingConfig: false,
    twilioIdConfig: null,
    twilioTokenConfig: null,
    twilioPhoneConfig: null,
    portalNameConfig: null,
    inquiryTypeConfig: {
      ConfigKey: ConfigKeys.InquiryType,
      ConfigValue: '',
    },
    inquirySharedEmailsConfig: {
      ConfigKey: ConfigKeys.InquirySharedEmails,
      ConfigValue: '',
    },
    mednegCOT: 0.35,
    exportFilePasswordConfig: 'MagConnectSystem',
  };

  async componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/system-config', true);
      return;
    }
    await customFetch('/SystemConfig/GetAll', {
      method: 'GET',
    }).then(res => {
      const magSignatureConfig = find(res, item => item.ConfigKey === ConfigKeys.MagSignature);
      const directSignatureConfig = find(res, item => item.ConfigKey === ConfigKeys.DirectSignature);
      const office365Config = find(res, item => item.ConfigKey === ConfigKeys.Office365);
      const xeroConfig = find(res, item => item.ConfigKey === ConfigKeys.Xero);
      const contactPhoneConfig = find(res, item => item.ConfigKey === ConfigKeys.ContactPhone);
      const contactEmailConfig = find(res, item => item.ConfigKey === ConfigKeys.ContactEmail);
      const onlinePortalConfig = find(res, item => item.ConfigKey === ConfigKeys.OnlinePortal);
      const portalNameConfig = find(res, item => item.ConfigKey === ConfigKeys.PortalName);
      const inquiryTypeConfig =
        find(res, item => item.ConfigKey === ConfigKeys.InquiryType) || this.state.inquiryTypeConfig;
      const inquirySharedEmailsConfig =
        find(res, item => item.ConfigKey === ConfigKeys.InquirySharedEmails) || this.state.inquirySharedEmailsConfig;

      const twilioSettingValueConfig = find(res, item => item.ConfigKey === ConfigKeys.TwilioSetting);
      var twilioSettingConfig = false;
      if (twilioSettingValueConfig.ExtraValue === 'True') {
        twilioSettingConfig = true;
      }
      const twilioIdConfig = find(res, item => item.ConfigKey === ConfigKeys.TwilioId);
      const twilioTokenConfig = find(res, item => item.ConfigKey === ConfigKeys.TwilioToken);
      const twilioPhoneConfig = find(res, item => item.ConfigKey === ConfigKeys.TwilioFromPhone);
      const smsProviderConfig = find(res, item => item.ConfigKey === ConfigKeys.SMSProvider);
      const mednegCOT = find(res, item => item.ConfigKey === ConfigKeys.MednegCOT);
      const exportFilePasswordConfig = find(res, item => item.ConfigKey === ConfigKeys.ExportFilePassword);
      this.setState({
        magSignatureEditor: magSignatureConfig.ConfigValue ? magSignatureConfig.ConfigValue : null,
        directSignatureEditor: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(directSignatureConfig.ConfigValue)),
        ),
        office365Config,
        xeroConfig,
        contactPhoneConfig,
        contactEmailConfig,
        onlinePortalConfig,
        portalNameConfig,
        inquiryTypeConfig,
        inquirySharedEmailsConfig,
        twilioSettingValueConfig,
        twilioSettingConfig,
        twilioIdConfig,
        twilioTokenConfig,
        twilioPhoneConfig,
        loading: false,
        smsProviderConfig,
        mednegCOT,
        exportFilePasswordConfig,
      });
    });
  }

  handleUpdateConfig = async config => {
    this.setState({ loading: true });

    const res = await customFetch('/SystemConfig/Update', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(config),
    });

    notification.destroy();
    if (res.status === 'fail') {
      notification.error({
        message: 'Update Failed',
        description: 'Error occurred while updating data, please try again later.',
      });
    } else {
      notification.success({
        message: 'Update Completed',
        description: `Data has been updated successfully.`,
      });
    }

    this.setState({ loading: false });
  };

  handleUpdateConfigs = async configs => {
    this.setState({ loading: true });
    const res = await customFetch('/SystemConfig/UpdateConfigs', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(configs),
    });

    notification.destroy();
    if (res.status === 'fail') {
      notification.error({
        message: 'Update Failed',
        description: 'Error occurred while updating data, please try again later.',
      });
    } else {
      notification.success({
        message: 'Update Completed',
        description: `Data has been updated successfully.`,
      });
    }

    this.setState({ loading: false });
  };

  onChangeMagSignature = magSignatureEditor => this.setState({ magSignatureEditor });

  onChangeDirectSignature = directSignatureEditor => this.setState({ directSignatureEditor });

  onChangeInquiryType = ({ target }) => {
    const value = { ...this.state.inquiryTypeConfig };
    value.ConfigValue = target.value;
    this.setState({ inquiryTypeConfig: value });
  };

  onChangeInquirySharedEmails = ({ target }) => {
    const value = { ...this.state.inquirySharedEmailsConfig };
    value.ConfigValue = target.value;
    this.setState({ inquirySharedEmailsConfig: value });
  };

  handleOffice365ChangeInput = (_, { name, value }) => {
    this.setState(state => (state.office365Config[name] = value));
  };

  handleXeroChangeInput = (_, { name, value }) => {
    this.setState(state => (state.xeroConfig[name] = value));
  };

  handleContactChangeInput = (_, { name, value }) => {
    if (name === 'PhoneExtraValue') {
      this.setState(state => (state.contactPhoneConfig['ExtraValue'] = value));
    } else if (name === 'EmailExtraValue') {
      this.setState(state => (state.contactEmailConfig['ExtraValue'] = value));
    } else if (name === 'OPExtraValue') {
      this.setState(state => (state.onlinePortalConfig['ExtraValue'] = value));
    } else {
      this.setState(state => (state.portalNameConfig['ExtraValue'] = value));
    }
  };

  handleTwilioChangeInput = (_, { name, value }) => {
    if (name === 'twilioSettingConfig') {
      this.setState(state => (state.twilioSettingConfig = !state.twilioSettingConfig));
    } else if (name === 'twilioIdConfig') {
      this.setState(state => (state.twilioIdConfig['ExtraValue'] = value));
    } else if (name === 'twilioTokenConfig') {
      this.setState(state => (state.twilioTokenConfig['ExtraValue'] = value));
    } else if (name === 'smsProviderConfig') {
      this.setState(state => (state.smsProviderConfig['ExtraValue'] = value));
    } else {
      this.setState(state => (state.twilioPhoneConfig['ExtraValue'] = value));
    }
  };

  hangdleSubmitMagSignature = async e => {
    e.preventDefault();
    const configModel = {
      ConfigKey: ConfigKeys.MagSignature,
      ConfigValue: this.state.magSignatureEditor,
    };
    await this.handleUpdateConfig(configModel);
  };

  hangdleSubmitDirectSignature = async e => {
    e.preventDefault();
    const configModel = {
      ConfigKey: ConfigKeys.DirectSignature,
      ConfigValue: stateToHTML(this.state.directSignatureEditor.getCurrentContent()),
    };
    await this.handleUpdateConfig(configModel);
  };

  handleSubmitOffice365 = async e => {
    await this.handleUpdateConfig(this.state.office365Config);
  };

  handleSubmitXero = async e => {
    await this.handleUpdateConfig(this.state.xeroConfig);
  };

  handleSubmitTwilio = async e => {
    await this.handleUpdateConfigs([
      this.state.contactEmailConfig,
      this.state.contactPhoneConfig,
      this.state.onlinePortalConfig,
      this.state.portalNameConfig,
    ]);
  };

  handleSubmitContact = async e => {
    var twilioSetting = this.state.twilioSettingValueConfig;
    if (this.state.twilioSettingConfig) {
      twilioSetting.ExtraValue = 'True';
    } else {
      twilioSetting.ExtraValue = 'False';
    }
    await this.handleUpdateConfigs([
      twilioSetting,
      this.state.smsProviderConfig,
      this.state.twilioIdConfig,
      this.state.twilioTokenConfig,
      this.state.twilioPhoneConfig,
    ]);
  };

  handleSubmitInquiryType = async e => {
    await this.handleUpdateConfig(this.state.inquiryTypeConfig);
  };

  handleSubmitInquirySharedEmails = async e => {
    await this.handleUpdateConfig(this.state.inquirySharedEmailsConfig);
  };

  handleChangeMednegCOT = (_, { name, value }) => {
    this.setState(state => (state.mednegCOT.ConfigValue = value));
  };

  handleSubmitMednegCOT = async e => {
    await this.handleUpdateConfig(this.state.mednegCOT);
  };

  handleChangeExportFilePassword = (_, { name, value }) => {
    this.setState(state => (state.exportFilePasswordConfig.ConfigValue = value));
  };

  handleSubmitExportFilePassword = async e => {
    await this.handleUpdateConfig(this.state.exportFilePasswordConfig);
  };

  handleViewExportHistory = () => {
    this.setState({
      ...this.state,
      isOpenModelHistories: true,
    });
  };

  handleCloseModal(someArg) {
    //alert('We pass argument from Child to Parent: ' + someArg);
    this.setState({
      ...this.state,
      isOpenModelHistories: false,
    });
  }

  render() {
    const {
      loading,
      user,
      office365Config,
      xeroConfig,
      contactPhoneConfig,
      contactEmailConfig,
      onlinePortalConfig,
      portalNameConfig,
      smsProviderConfig,
      twilioSettingConfig,
      twilioIdConfig,
      twilioTokenConfig,
      twilioPhoneConfig,
      mednegCOT,
      exportFilePasswordConfig,
    } = this.state;
    var handleCloseModal = this.handleCloseModal;
    const panes = [
      {
        menuItem: (
          <Menu.Item key="magSignature">
            Business Signature <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              Business Signature
            </Label>
            <Form>
              <CKEditor html={this.state.magSignatureEditor} handleChangeContent={this.onChangeMagSignature} />
              {/* <Editor editorState={this.state.magSignatureEditor} onChange={this.onChangeMagSignature} /> */}
              <br />
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.hangdleSubmitMagSignature}>
                  Update
                </Button>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="directSignature">
            Business Direct Signature <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              Business Direct Signature
            </Label>
            <Form>
              <Editor editorState={this.state.directSignatureEditor} onChange={this.onChangeDirectSignature} />
              <br />
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.hangdleSubmitDirectSignature}>
                  Update
                </Button>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="inquiryType">
            Inquiry Centre <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              Inquiry Centre
            </Label>
            <Form>
              <Form.Field>
                <label>Inquiry Types</label>
                <TextArea
                  rows={4}
                  value={this.state.inquiryTypeConfig.ConfigValue}
                  onChange={this.onChangeInquiryType}
                />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitInquiryType}>
                  Update
                </Button>
              </div>
            </Form>
            <Form>
              <Form.Field>
                <label>Shared Receiver Emails</label>
                <TextArea
                  rows={4}
                  value={this.state.inquirySharedEmailsConfig.ConfigValue}
                  onChange={this.onChangeInquirySharedEmails}
                />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitInquirySharedEmails}>
                  Update
                </Button>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="office365">
            Office 365 Service <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              Office 365 Service
            </Label>
            <Form>
              <Form.Field>
                <label>Settings</label>
                <TextArea
                  name="ConfigValue"
                  value={office365Config.ConfigValue}
                  onChange={this.handleOffice365ChangeInput}
                  rows={8}
                />
              </Form.Field>
              <Form.Field>
                <label>Protected Settings</label>
                <TextArea
                  name="ExtraValue"
                  value={office365Config.ExtraValue}
                  onChange={this.handleOffice365ChangeInput}
                  rows={8}
                />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitOffice365}>
                  Update
                </Button>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="xero">
            Xero Service <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              Xero Service
            </Label>
            <Form className="ui form">
              <Form.Field>
                <label>Settings</label>
                <TextArea name="ConfigValue" value={xeroConfig.ConfigValue} onChange={this.handleXeroChangeInput} />
              </Form.Field>
              <Form.Field>
                <label>Protected Settings</label>
                <TextArea name="ExtraValue" value={xeroConfig.ExtraValue} onChange={this.handleXeroChangeInput} />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitXero}>
                  Update
                </Button>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="contact">
            System Contacts <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" style={{ marginBottom: '20px' }} size="medium" ribbon>
              System Contacts
            </Label>
            <form className="ui form">
              <Form.Field>
                <label>
                  Contact Phone -{' '}
                  <span style={{ color: 'gray' }}>
                    {'{'}
                    {contactPhoneConfig.ConfigKey}
                    {'}'}{' '}
                  </span>
                </label>
                <Input
                  name="PhoneExtraValue"
                  value={contactPhoneConfig.ExtraValue}
                  onChange={this.handleContactChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  Contact Email -{' '}
                  <span style={{ color: 'gray' }}>
                    {'{'}
                    {contactEmailConfig.ConfigKey}
                    {'}'}{' '}
                  </span>
                </label>
                <Input
                  name="EmailExtraValue"
                  value={contactEmailConfig.ExtraValue}
                  onChange={this.handleContactChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  Online Portal -{' '}
                  <span style={{ color: 'gray' }}>
                    {'{'}
                    {onlinePortalConfig.ConfigKey}
                    {'}'}{' '}
                  </span>
                </label>
                <Input
                  name="OPExtraValue"
                  value={onlinePortalConfig.ExtraValue}
                  onChange={this.handleContactChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  Portal Name -{' '}
                  <span style={{ color: 'gray' }}>
                    {'{'}
                    {portalNameConfig.ConfigKey}
                    {'}'}{' '}
                  </span>
                </label>
                <Input
                  name="PortalName"
                  value={portalNameConfig.ExtraValue}
                  onChange={this.handleContactChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitTwilio}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="provider">
            SMS Setting <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon="left" style={{ marginBottom: '20px' }} size="medium">
              SMS Setting
            </Label>
            <form className="ui form">
              <Form.Field>
                <label>Provider</label>
                <Input
                  name="smsProviderConfig"
                  value={smsProviderConfig.ExtraValue}
                  onChange={this.handleTwilioChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <label>Service Status</label>
                <Checkbox
                  name="twilioSettingConfig"
                  label="Active"
                  checked={twilioSettingConfig}
                  onChange={this.handleTwilioChangeInput}
                />
              </Form.Field>
              <Form.Field>
                <label>Provider SID</label>
                <Input
                  name="twilioIdConfig"
                  value={twilioIdConfig.ExtraValue}
                  onChange={this.handleTwilioChangeInput}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <label>Provider Token</label>
                <Input
                  name="twilioTokenConfig"
                  value={twilioTokenConfig.ExtraValue}
                  onChange={this.handleTwilioChangeInput}
                  className="Input-field"
                />
              </Form.Field>

              <Form.Field>
                <label>Sent From</label>
                <Input
                  name="twilioPhoneConfig"
                  value={twilioPhoneConfig.ExtraValue}
                  onChange={this.handleTwilioChangeInput}
                  className="Input-field"
                />
              </Form.Field>

              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitContact}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="sms">
            COT <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon="left" style={{ marginBottom: '20px' }} size="medium">
              COT
            </Label>
            <form className="ui form">
              <Form.Field>
                <label>COT</label>
                <Input
                  type="number"
                  name="mednegCOT"
                  value={mednegCOT?.ConfigValue || ''}
                  onChange={this.handleChangeMednegCOT}
                  className="Input-field"
                />
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitMednegCOT}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="exportFilePassword">
            Export file control <Icon name="signup" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon="left" style={{ marginBottom: '20px' }} size="medium">
              Export file control
            </Label>
            <form className="ui form">
              <Form.Field>
                {this.state.isOpenModelHistories && <ModalHistories handleCloseModal={handleCloseModal.bind(this)} />}
                <label>
                  Access Code <span style={{ color: 'red' }}>*</span>
                </label>
                <Input
                  type="password"
                  autoComplete="off"
                  name="exportFilePassword"
                  value={exportFilePasswordConfig.ConfigValue}
                  onChange={this.handleChangeExportFilePassword}
                  className="Input-field"
                />
              </Form.Field>
              <Form.Field>
                <br />
                <div>
                  <a onClick={this.handleViewExportHistory}>View Access Code History</a>
                </div>
              </Form.Field>
              <div className="Footer">
                <Button className="blue" type="submit" onClick={this.handleSubmitExportFilePassword}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <div className={`Form-${loading && !user ? 'Loading' : 'Container'}`}>
        {loading && !user ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Dimmer active={loading}>
              <Loader />
            </Dimmer>
            <Tab
              panes={panes}
              menu={{ pointing: true, vertical: true }}
              menuPosition="right"
              onTabChange={this.onChangeTab}
              className="setting-tabs"
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default SystemConfig;
