import React from 'react';
import cx from 'classnames';
import { Divider, Spin } from 'antd';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { debounce } from 'lodash';

import '../AddEditClinic/index.scss';
import './index.scss';

import ClaimDetails from './ClaimDetails';
import CaseDocuments from './CaseDocuments';
import CaseCancellation from './CaseCancellation';
import ClientInvoicing from './ClientInvoicing';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';
import ProgressStatus from './ProgressStatus';
import ModalHistory from './ModalHistory';
import ModalCompanyHistory from './ModalCompanyHistory';
import ModalConfirm from './ModalConfirm';
import ModalUpload from './UploadComponent';
import ModalActionNotes from './ActionRequiredForm';
import store from './store';
import ModalDisbursementFunding from '@components/shared/ModalDisbursementFunding';
import { FIELD_NAME_FOR_ADD_EDIT } from './type';

import * as api from '@stores/api';
import dashboardStore from '@stores/dashboard';
import router from '@stores/router';

const momentBusinessDays = require('moment-business-days');

const STEPS = [
  'Claim Details',
  'Paperwork, Dictations & Report',
  'Cancellation',
  'Client & Specialist Payments',
  'Case Correspondence',
  'Action Required',
];

@observer
class AddEditSupplementaryReport extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };
  handleStepChange = step => () => {
    store.setFieldsValue({ currentStep: step });
  };

  _reloadPageToGetNewInfo = debounce(
    action(() => {
      const { pageGetter, fieldName, dataTransfer, listPageGetters } = dashboardStore;
      const page = listPageGetters.find(i => i.pathname === router.location.pathname);

      if (page && page.pathname) {
        if (pageGetter === page.key) {
          if (fieldName && dataTransfer) {
            const fieldNotified = FIELD_NAME_FOR_ADD_EDIT.find(i => i.fieldName === fieldName);
            store.toggleModalConfirm(true, {
              message: `Do you want to add this ${fieldNotified?.name} (${dataTransfer?.FullName ||
                dataTransfer?.Name}) to the current booking?`,
              onOk: () => {
                const newArr = store.itemToArray(dataTransfer, store[fieldNotified.listName], 'Id');
                store.setFieldsValue({ [fieldNotified.listName]: newArr });
                store.handleChangeSuppValue({
                  [fieldName]: dataTransfer?.Id || dataTransfer?.ID,
                });
              },
            });
            return dashboardStore.transferData({
              dataTransfer: null,
              fieldName: null,
              pageGetter: null,
            });
          }
          return;
        } else {
          return;
        }
      }
    }),
    500,
  );

  componentDidMount() {
    const { id = 0, step = 0, caseNo = '', mType = '', mId = 0 } = this.getRouteParams();

    store.setFieldsValue({ currentStep: +step });
    store.fetchSupplementaryData(parseInt(id), caseNo, mType, mId);
    dashboardStore.on('open(/view/add-edit-supplementary-report-2)', this._reloadPageToGetNewInfo);
  }

  componentWillUnmount() {
    store.handleResetStore();
  }

  renderForm = () => {
    const { currentStep } = store;
    switch (currentStep) {
      case 1:
        return <CaseDocuments />;
      case 2:
        return <CaseCancellation />;
      case 3:
        return <ClientInvoicing />;
      case 4:
        return <CaseCorrespondence />;
      case 5:
        return <ActionRequired />;
      default:
        return <ClaimDetails />;
    }
  };

  renderStep = currentStep => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return STEPS.map((step, idx) => (
        <Button
          key={step}
          fluid
          size="large"
          className={cx('step', {
            active: currentStep === idx,
          })}
          onClick={this.handleStepChange(idx)}
        >
          {idx + 1}. {step}
        </Button>
      ));
    }
    if (api.isTypist()) {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 &&
          idx !== 4 &&
          idx !== 3 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ),
      );
    } else if (api.isCaseManager()) {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
              disabled={!store.supplementaryInfo.Id && (idx === 2 || idx === 3 || idx === 4)}
            >
              {idx + 1}.{' '}
              {idx === 1 && api.isCaseManager()
                ? 'Paperwork and Report'
                : idx === 3 && api.isCaseManager()
                ? 'Invoices'
                : idx === 3 && api.isDoctor()
                ? 'Specialist Payment'
                : step}
            </Button>
          ),
      );
    } else {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}.{' '}
              {idx === 1 && api.isCaseManager()
                ? 'Paperwork and Report'
                : idx === 3 && api.isCaseManager()
                ? 'Invoices'
                : idx === 3 && api.isDoctor()
                ? 'Specialist Payment'
                : step}
            </Button>
          ),
      );
    }
  };

  render() {
    const { loading, currentStep } = store;
    return (
      <div className={loading ? 'page-loading' : 'page-container'}>
        {loading ? (
          <Spin size="large" />
        ) : (!api.isCMInvoiceCheck() &&
          <>
            <ProgressStatus />
            <div className="form-container">
              <h1 className="form-title">
                {currentStep === 1 && api.isCaseManager()
                  ? 'Paperwork and Report'
                  : currentStep === 3 && api.isCaseManager()
                  ? 'Invoices'
                  : currentStep === 3 && api.isDoctor()
                  ? 'Specialist Payment'
                  : STEPS[currentStep]}
              </h1>
              {this.renderForm()}
            </div>
            <div className="step-container step-container-sticky">
              {this.renderStep(currentStep)}
              <ModalHistory />
              <ModalCompanyHistory />
              <ModalConfirm
                open={store.isOpenModalConfirm}
                onCancel={() => store.toggleModalConfirm(false)}
                {...store.modalParams}
                modalParams={store.modalParams}
                cancelText={store.modalParams.cancelText}
                hideWarningIcon={store.modalParams.hideWarningIcon}
              />{' '}
              {store.isOpenModalDisbursementFunding && (
                <ModalDisbursementFunding
                  {...store.modalDisbursementFundingParams}
                  open={store.isOpenModalDisbursementFunding}
                  onCancel={() =>
                    store.setFieldsValue({
                      isOpenModalDisbursementFunding: false,
                      modalDisbursementFundingParams: {},
                    })
                  }
                />
              )}
              {store.isOpenModalUpload && <ModalUpload />}
              {store.isOpenModalAction && <ModalActionNotes />}
            </div>
            <div className="form-footer">
              <Divider />
              {!!store.supplementaryInfo.Id && api.isCaseManager() && store.bookingClientSelected?.ClientType === 3 && (
                <Button primary onClick={() => store.handleRequestFunding()}>
                  Request Funding for this Report
                </Button>
              )}
              <Button primary onClick={store.handleSave}>
                Save
              </Button>
              <Button className="negative" onClick={() => dashboardStore.close(window.location.pathname)}>
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AddEditSupplementaryReport;
