import './index.scss';

import { DatePicker, notification, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { Button, Checkbox, Dropdown, Icon, Input, TextArea } from 'semantic-ui-react';

import { showInvalidFormError } from '../../../utils/errors';
import { formatDate } from '../../../utils/functions';
import ModalConfirm from './ModalConfirm';
import * as api from '@stores/api';

const datePickerFormat = 'DD/MM/YYYY';
const timePickerFormat = 'HH:mm';

class ActionNoteForm extends React.Component {
  state = {
    note: this.props.note ? { ...this.props.note, ActionNote: this.props.note.ActionNote || '' } : {},
    // Fix warning TextArea null
    message: '',
    open: false,
    type: ``,
  };

  picOptions = this.props.staffs.map(({ Id, FullName }) => ({
    text: FullName,
    value: Id,
  }));

  getUserById = id => {
    const staff = this.props.staffs.find(staffs => staffs.Id === id); // Fix
    return staff ? staff.FullName : '';
  };

  handleInputChange = field => (_, { value }) => {
    this.setState(prev => ({
      note: {
        ...prev.note,
        [field]: value,
      },
    }));
  };

  handlePickerChange = field => momentValue => {
    this.setState(prev => ({
      note: {
        ...prev.note,
        [field]: momentValue,
      },
    }));
  };

  handleCheckboxChange = (_, { checked }) => {
    const newNote = {
      ...this.state.note,
      Completed: checked,
      CompletedDate: checked ? moment() : null,
    };

    this.setState({ note: newNote });
  };

  validateForm = () => {
    const { Subject, StaffAssignedId, DueDate } = this.state.note;
    if (!Subject || !StaffAssignedId || !DueDate) {
      notification.destroy();
      showInvalidFormError();
      return false;
    }
    return true;
  };

  handleSave = () => {
    if (!this.validateForm()) return;
    this.props.onSubmit(this.state.note);
  };

  handleDelete = () => {
    this.setState({
      open: true,
      message: `Do you want to delete this action note?`,
      type: `delete`,
    });
  };

  handleOnConfirm = () => {
    const { type } = this.state;
    if (type === `delete`) {
      return this.props.onDelete(this.state.note.Id);
    }
    return this.setState({ open: false });
  };

  renderModalConfirm = () => {
    const { message, open } = this.state;
    return (
      <ModalConfirm
        message={message}
        open={open}
        onCancel={() => this.setState({ open: false })}
        onOk={this.handleOnConfirm}
      />
    );
  };

  render() {
    const { note } = this.state;
    return (
      <div className="form-action-note">
        {this.props.note && !api.isOnlySearch() && <Icon name="delete" color="red" onClick={this.handleDelete} />}
        {this.renderModalConfirm()}
        <div className="form-group Input">
          <label className="Input-Label required">Action Subject</label>
          <Input
            fluid
            className="input--overridden-style"
            maxLength="50"
            value={note.Subject || ''}
            onChange={this.handleInputChange('Subject')}
          />
        </div>
        <div className="form-group Input">
          <label className="Input-Label required">Due Date (UTC+07:00)</label>
          <div className="datetime-picker">
            <DatePicker
              allowClear={false}
              format={datePickerFormat}
              value={note.DueDate ? moment(note.DueDate) : null}
              onChange={this.handlePickerChange('DueDate')}
            />
            <TimePicker
              style={{ display: 'none' }}
              allowClear={false}
              format={timePickerFormat}
              value={note.DueDate ? moment(moment(note.DueDate).format('HH:mm'), timePickerFormat) : null}
              onChange={this.handlePickerChange('DueDate')}
            />
          </div>
        </div>
        <div className="form-group Input">
          <label className="Input-Label required">PIC</label>
          <Dropdown
            fluid
            search
            selection
            options={this.picOptions}
            value={note.StaffAssignedId}
            className="input--overridden-style"
            onChange={this.handleInputChange('StaffAssignedId')}
          />
        </div>
        <div className="form-group Input">
          <label className="Input-Label">Action Notes</label>
          <TextArea className="TextArea" value={note.ActionNote} onChange={this.handleInputChange('ActionNote')} />
        </div>
        <div className="form-group Checkbox">
          <Checkbox label="Action completed" checked={note.Completed} onChange={this.handleCheckboxChange} />
        </div>
        {note.Completed && (
          <div className="form-group Input">
            <label className="Input-Label">Completed Date</label>
            <div className="datetime-picker date-picker">
              <DatePicker
                allowClear={false}
                format={datePickerFormat}
                value={moment(note.CompletedDate)}
                onChange={this.handlePickerChange('CompletedDate')}
              />
            </div>
          </div>
        )}
        {note.CreatedDate && note.CreatedByName && (
          <div className="action-note-info">
            Created by: {note.CreatedByName} at{' '}
            {note.CreatedDate
              ? moment(formatDate(note.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
              : 'N/A'}
          </div>
        )}
        {note.LastUpdatedBy && note.LastUpdatedDate && (
          <div className="action-note-info">
            Last updated by: {note.UpdatedByName} at{' '}
            {note.LastUpdatedDate
              ? moment(formatDate(note.LastUpdatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
              : 'N/A'}
          </div>
        )}
        {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
          Save
        </Button>}
      </div>
    );
  }
}

export default ActionNoteForm;
