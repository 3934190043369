import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import CMReportStore from './CMReportStore';
import { TableHeader, TableEmptyMessage } from '../../shared/table';

@observer
class IMESearchTable extends React.Component {
  renderTableBody = (data, colSpan) => {
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.map(({ Id, ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{record.searcherFullName}</Table.Cell>
        <Table.Cell>{record.searcherUsername}</Table.Cell>
        <Table.Cell>{record.searcherPhone}</Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.doctorName}</Table.Cell>
        <Table.Cell>{record.specialties.map(i => i.label).join(`\t`)}</Table.Cell>
        <Table.Cell>{record.subSpecialties.map(i => i.label).join(`\t`)}</Table.Cell>
        <Table.Cell>{record.accreditations}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.appointmentDateFrom ? moment(record.appointmentDateFrom).format(`DD/MM/YYYY`) : ''}
        </Table.Cell>
        <Table.Cell>{record.appointmentDateTo ? moment(record.appointmentDateTo).format('DD/MM/YYYY') : ''}</Table.Cell>
        <Table.Cell>{record.location}</Table.Cell>
        <Table.Cell>{record.reportDueDate ? moment(record.reportDueDate).format('DD/MM/YYYY') : ''}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.searchedAtUtc ? moment(record.searchedAtUtc).format('DD/MM/YYYY - HH:mm:ss') : ''}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { dataIMESearch, loadingIMESearch } = CMReportStore;
    const columns = [
      {
        title: `Case manager`,
      },
      { title: 'CM Email' },
      { title: 'CM Phone' },
      { title: 'Specialist' },
      { title: 'Specialty' },
      { title: 'Sub-specialty' },
      { title: 'Accreditation' },
      { title: 'Date from' },
      { title: 'Date to' },
      { title: 'Location' },
      { title: 'Expected report complete date' },
      { title: 'Searched at' },
    ];
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <Table sortable striped celled className={cx({ disabled: loadingIMESearch })}>
          <TableHeader columns={columns} />
          <Table.Body>{this.renderTableBody(dataIMESearch, colSpan)}</Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default IMESearchTable;
