import React from 'react';
import { Link } from 'react-router-dom';

export default class Content extends React.Component {
  state = {
    viewMore: false,
  };

  tripHtmlFromBody = html => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  calculateLength = html => {
    const canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    ctx.font = '16px Arial';
    const width = ctx.measureText(html).width;
    return width;
  };

  render() {
    return (
      <>
        {this.state.viewMore && (
          <div className="Cell-link">
            <Link
              to="#"
              onClick={() => {
                if (!this.state.viewMore && this.props?.onViewMore) {
                  this.props.onViewMore();
                }
                this.setState({ viewMore: !this.state.viewMore });
              }}
            >
              {'Show less'}
            </Link>
          </div>
        )}
        {this.state.viewMore ? (
          <div dangerouslySetInnerHTML={{ __html: this.props.Notes }} />
        ) : (
          <span className="Body-string">{this.tripHtmlFromBody(this.props.Notes)}</span>
        )}
        {this.props.Notes && this.calculateLength(this.props.Notes) > 320 && !this.state.viewMore && (
          <div className="Cell-link">
            <Link
              to="#"
              onClick={() => {
                if (!this.state.viewMore && this.props?.onViewMore) {
                  this.props.onViewMore();
                }
                this.setState({ viewMore: !this.state.viewMore });
              }}
            >
              {'Show more'}
            </Link>
          </div>
        )}
      </>
    );
  }
}
