import React from 'react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

function ModalResetPassword({ message, open, onCancel }) {
  const handleOnOkClick = () => {
    onCancel();
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button onClick={handleOnOkClick}>Close</Button>
        </React.Fragment>
      }
    >
      <div className="modal-confirm-message">{message}</div>
    </Modal>
  );
}

export default ModalResetPassword;
