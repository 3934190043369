import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import IMECaseProgress from './IMECaseProgress';
import Modal from '../../shared/Modal';
import Store from './clinicalNoteStore';
import * as api from '../../../stores/api';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = Store;

    const caseNo = `Case A-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('PostAssessment', caseNo);
    iframe.forceOpenTab('PostAssessment', `?id=${id}&view=PostAssessment#Corespondence`, {
      id,
      view: 'PostAssessment',
    });

    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = Store;
  const title = `Status History: ${modalParams.caseNo}`;
  const url = '/MedicalService/GetAssessmentLogs';
  const body = { assessmentId: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1600}
      title={title}
      footer={
        <React.Fragment>
          {!api.isTypist() && (
            <Button color="blue" onClick={openCaseCorrespondence}>
              <Icon name="table" /> View Case Correspondence
            </Button>
          )}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <IMECaseProgress progressList={api.IME_CaseProgress.data.itemList} url={url} body={body} showHistory />
    </Modal>
  );
}

export default ModalCaseProgress;
