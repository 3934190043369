import { DatePicker, notification } from 'antd';
import React from 'react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import dashboard from '../../../../stores/dashboard';
import { Checkbox, Dropdown, Form, Grid, Icon, Input, Popup, Segment, Label, Button } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { formatDate } from '../../../../utils/functions';
import customFetch from '../../../../utils/customFetch';
import iframe from '../../../dashboard-routes/Iframe/iframeData';
import DoctorDetailsStore from './DoctorDetailsStore';
import TextArea from './TextArea';
import { Upload, File, NoFile } from './Upload';
import './DoctorDetails.scss';
import { renderDate } from '@components/case-details-routes/Shared';
import { uniqBy } from 'lodash-es';
import moment from 'moment';
import { getCMsOfClientList, sendOnboardedNoticeToCMs } from './service';
import * as api from '@stores/api';
@observer
class RecruitmentLoginInformationTab extends React.Component {
  renderClientType = type => {
    switch (type) {
      case 0:
        return 'Business Ultimate';
      case 1:
        return 'Business Direct-Essential';
      case 2:
        return 'Business Direct-Extra';
      default:
        return 'None';
    }
  };

  @action uploadContract = data => {
    const contract = data.fileList[0];
    if (contract) {
      DoctorDetailsStore.dataDoctor.ContractAttachmentDisplayName = contract.title;
      DoctorDetailsStore.dataDoctor.ContractAttachmentPhysicalFileName = contract.name;
    }
  };

  @action removeContract = () => {
    DoctorDetailsStore.dataDoctor.ContractAttachmentDisplayName = null;
    DoctorDetailsStore.dataDoctor.ContractAttachmentPhysicalFileName = null;
  };

  openClinicCalendar = () => {
    const { dataDoctor } = DoctorDetailsStore;
    iframe.forceOpenTab('ManageCalendar', `?doctorId=${dataDoctor.Id}&view=ManageCalendar`, {
      doctorId: dataDoctor.Id,
      view: 'ManageCalendar',
    });
  };

  notifyEnable = () => {
    DoctorDetailsStore.toggleModal(true, {
      modalType: 'alert',
      message: 'This user is not yet enabled for login. Enable login first.',
      cancelText: 'Cancel',
    })();
  };

  handleEditUser = () => {
    const { userId } = DoctorDetailsStore;
    customFetch('/Manager/GetUserById', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    }).then(
      action(data => {
        DoctorDetailsStore.dataUser = data;
        DoctorDetailsStore.toggleModal(true, { modalType: 'editUser' })();
      }),
    );
  };

  handleUnlockUser = () => {
    const { userId } = DoctorDetailsStore;
    customFetch('/manager/unlockUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `User has been unlocked`,
        duration: 5,
      });
    });
  };

  handleResetPassword = () => {
    const userId = DoctorDetailsStore.dataDoctor.UserId;
    const doctorId = DoctorDetailsStore.dataDoctor.Id;

    customFetch('/Manager/ResetPassword', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId, doctorId }),
    }).then(res => {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'alert',
        withoutIcon: true,
        message: `Generated password: ${res.result}`,
        cancelText: 'Cancel',
      })();
    });
  };

  handleResendLoginInformation = () => {
    const userId = DoctorDetailsStore.dataDoctor.UserId;
    const doctorId = DoctorDetailsStore.dataDoctor.Id;
    customFetch('/Manager/ResendLoginInformation', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ uId: userId, doctorId }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Resend login Information: Successfully!!`,
        duration: 5,
      });
    });
  };

  renderLabelRequestedByClients = (obj = {}) => {
    if (obj && obj.ClientList && !!obj.ClientList.length) {
      const myArray = obj.ClientList;
      return myArray.map(
        (i, idx) =>
          !!i && (
            <Label key={idx} className="Field-option">
              {`${i.Id} - ${i.Name}`}
            </Label>
          ),
      );
    } else {
      return <span className="Label-item">None</span>;
    }
  };

  handleChangeRequestedByClients = action((_, { value, name, options }) => {
    const { doctorClientRequestings } = DoctorDetailsStore;
    //console.log(DoctorDetailsStore[name]);
    const array = (DoctorDetailsStore[name]
      ? uniqBy(
          [
            ...options,
            ...(DoctorDetailsStore[name].ClientList || []).map((c, idx) => ({
              value: c.Id,
              key: idx,
              text: `${c.Id} - ${c.Name} - ${c.Email}`,
              data: c,
            })),
          ],
          'value',
        )
      : options
    )
      .map(i => {
        if (value.find(id => id === i.value)) {
          return { ...i.data };
        } else {
          return null;
        }
      })
      .filter(i => !!i);

    if (array && array.length > 0) {
      var newLst = [];
      array.forEach(cl => {
        const findItem = doctorClientRequestings.find(i => i.ClientId === cl.Id);
        if (!findItem) {
          getCMsOfClientList(cl.Id).then(resp => {
            if (resp.status === 'success') {
              newLst.push({
                Id: 0,
                DoctorId: DoctorDetailsStore.dataDoctor.Id,
                ClientId: cl.Id,
                Client: cl,
                RequestingDate: moment(),
                RequestingCMs: null,
                LstRequestingCMs: [],
                LstCMsOfClient: (resp.itemList || []).map(s => ({
                  value: s.Id,
                  text: s.FullName,
                })),
              });
              DoctorDetailsStore.setDoctorClientRequesting(newLst);
            } else {
              newLst.push({
                Id: 0,
                DoctorId: DoctorDetailsStore.dataDoctor.Id,
                ClientId: cl.Id,
                Client: cl,
                RequestingDate: moment(),
                RequestingCMs: null,
                LstRequestingCMs: [],
                LstCMsOfClient: [],
              });
            }
          });
        } else {
          newLst.push(findItem);
        }
      });
      DoctorDetailsStore.setDoctorClientRequesting(newLst);
    } else {
      DoctorDetailsStore.setDoctorClientRequesting([]);
    }
    DoctorDetailsStore[name] = {
      ...DoctorDetailsStore[name],
      ClientIds: value.join(';'),
      ClientList: array,
    };
  });

  handleChangeRqtDatePicker = (clientId, fieldName) =>
    action(data => {
      var lstItem = DoctorDetailsStore.doctorClientRequestings;
      var findItem = lstItem.find(i => i.ClientId === clientId);
      if (findItem) {
        findItem[fieldName] = data;
      }
    });

  renderLabelRqtCMs = (obj = {}) => {
    if (obj && obj.LstRequestingCMs && !!obj.LstRequestingCMs.length) {
      const myArray = obj.LstRequestingCMs;
      return myArray.map(
        (i, idx) =>
          !!i && (
            <Label key={idx} className="Field-option">
              {i.FullName}
            </Label>
          ),
      );
    } else {
      return <span className="Label-item">None</span>;
    }
  };

  handleChangeRequestingCMs = action((_, { value, name, options }) => {
    var lstItem = DoctorDetailsStore.doctorClientRequestings;
    var clientId = parseInt(name.split('_')[1]);
    var findItem = lstItem.find(i => i.ClientId === clientId);
    if (findItem) {
      findItem.RequestingCMs = value.join(';');
      const array = options
        .map(i => {
          if (value.find(id => id === i.value)) {
            return { ...i.data };
          } else {
            return null;
          }
        })
        .filter(i => !!i);
      findItem.LstRequestingCMs = array;
    }
  });

  sendOnboardedNoticeToCMs = () => {
    sendOnboardedNoticeToCMs(DoctorDetailsStore.dataDoctor.Id).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Send Onboarded Notice to CMs successfully.`,
          duration: 5,
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Send Onboarded Notice to CMs error.`,
          duration: 5,
        });
      }
    });
  };

  openClientDetail = (id, record) => {
    localStorage.setItem('CompanyName', record.Name);
    dashboard.close('/view/add-edit-client-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-client-2?id=${id}&action=edit`);
    });
  };

  render() {
    const {
      isEditing,
      dataDoctor,
      setFieldValue,
      toggleCheckbox,
      requestedByClients,
      doctorClientRequestings,
    } = DoctorDetailsStore;
    const dateFormat = 'DD/MM/YYYY';
    var disableBtnSendOnboardedNoticetoCMs = true;
    doctorClientRequestings.forEach(el => {
      if (el.RequestingCMs) disableBtnSendOnboardedNoticetoCMs = false;
    });
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Recruitment Information</label>
                  </Form.Field>
                </Form.Group>
                {/* {false && <Form.Field inline>
                  <label className="Form-Field-label">Service Type: </label>
                  {!isEditing ? (
                    <span>{this.renderClientType(dataDoctor.ClientType)}</span>
                  ) : (
                    <Dropdown
                      selection
                      name="ClientType"
                      options={[
                        {
                          value: 0,
                          text: 'Business Ultimate',
                        },
                        {
                          value: 1,
                          text: 'Business Direct-Essential',
                        },
                        {
                          value: 2,
                          text: 'Business Direct-Extra',
                        },
                      ]}
                      value={dataDoctor.ClientType}
                      onChange={setFieldValue}
                    />
                  )}
                </Form.Field>} */}
                <Form.Group className="ml-0">
                  <div className="checkbox-field">
                    <Checkbox
                      label="Recruiting"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="IsRecruiting"
                      checked={dataDoctor.IsRecruiting}
                      onChange={toggleCheckbox}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-1.5rem', marginRight: '2rem' }} />}
                      content="Specialists who have not had any discussion with the operator or have not indicated their acceptance or ability to work with the operator."
                      position="top center"
                    />
                  </div>

                  <div className="checkbox-field">
                    <Checkbox
                      label="Onboarding"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="IsOnboarding"
                      checked={dataDoctor.IsOnboarding}
                      onChange={toggleCheckbox}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-1.5rem', marginRight: '2rem' }} />}
                      content="Specialists who have informally (verbally or in written) agreed to work with the operator, to start taking bookings. Next step is to send them service contracts to sign. Only onboarding and onboarded specialists are available for bookings by CMs or staff."
                      position="top center"
                    />
                  </div>

                  <span className="checkbox-field">
                    <Checkbox
                      label="Onboarded"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="HasContractWithMag"
                      checked={dataDoctor.HasContractWithMag}
                      onChange={toggleCheckbox}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-1.5rem', marginRight: '2rem' }} />}
                      content="Specialists who have signed service agreement/contract with the operator. Should attach signed contracts for these specialists. Only onboarding and onboarded specialists are available for bookings by CMs or staff."
                      position="top center"
                    />
                  </span>
                  <span>
                    <Button
                      disabled={
                        (!dataDoctor.HasContractWithMag && DoctorDetailsStore.dataDoctor.Id > 0) ||
                        !requestedByClients?.ClientIds ||
                        disableBtnSendOnboardedNoticetoCMs
                      }
                      className="blue"
                      onClick={() => this.sendOnboardedNoticeToCMs()}
                    >
                      Send Onboarded Notice to CMs
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '10px', color: 'white' }} />}
                        content="Send Onboarded Notice (Mail-159) to all Requesting CMs"
                        position="top center"
                      />
                    </Button>
                  </span>
                </Form.Group>
                {!dataDoctor.IsActive && (
                  <Form.Field>
                    <Checkbox checked={!dataDoctor.IsActive} label="Inactive "></Checkbox>
                    {!dataDoctor.IsActive && dataDoctor.InActiveDate && (
                      <span> (Inactive date: {renderDate(dataDoctor.InActiveDate, false)})</span>
                    )}
                  </Form.Field>
                )}
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Recruiting Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.RecruitingDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="RecruitingDate"
                        animation=""
                        duration={0}
                        clearable
                        dateFormat="DD/MM/YYYY"
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.RecruitingDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>

                  <Form.Field>
                    <label className="Form-Field-label">Campain Name</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.CampaignName || 'None'}</span>
                      </div>
                    ) : (
                      <Input name="CampaignName" value={dataDoctor.CampaignName || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Recruitment PIC{' '}
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Recruitment assignment email will be sent to assigned PIC (MAIL-111)"
                        position="top center"
                      />
                    </label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.AssignedToUserFullName || 'None'}</span>
                      </div>
                    ) : (
                      <Dropdown
                        search
                        selection
                        name="AssignedToId"
                        options={DoctorDetailsStore.dataStaffs}
                        value={dataDoctor.AssignedToId}
                        onChange={setFieldValue}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        icon={
                          !dataDoctor.AssignedToId ? (
                            <Icon name="dropdown" />
                          ) : (
                            <Icon
                              name="delete"
                              color="#1fc0da"
                              onClick={() => {
                                setFieldValue(null, { name: 'AssignedToId', value: null });
                              }}
                            />
                          )
                        }
                      />
                    )}
                  </Form.Field>
                  {api.isAdminOrMagStaffOrAccounting() ? (
                    <Form.Field>
                      <label className="Form-Field-label">Account Manager </label>
                      {!isEditing ? (
                        <div>
                          <span className="Label-item">{dataDoctor.AccountManagerName || 'None'}</span>
                        </div>
                      ) : (
                        <Dropdown
                          search
                          selection
                          name="AccountManagerId"
                          options={DoctorDetailsStore.dataStaffs}
                          value={dataDoctor.AccountManagerId}
                          onChange={setFieldValue}
                          selectOnBlur={false}
                          selectOnNavigation={false}
                          icon={
                            !dataDoctor.AccountManagerId ? (
                              <Icon name="dropdown" />
                            ) : (
                              <Icon
                                name="delete"
                                color="#1fc0da"
                                onClick={() => {
                                  setFieldValue(null, { name: 'AccountManagerId', value: null });
                                }}
                              />
                            )
                          }
                        />
                      )}
                    </Form.Field>
                  ) : (
                    <Form.Field></Form.Field>
                  )}
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Requested By
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Show clients who are requesting services from this specialist"
                        position="top center"
                      />
                    </label>
                    {!isEditing ? (
                      <div>{this.renderLabelRequestedByClients(requestedByClients)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Select clients"
                        multiple
                        search
                        selection
                        name="requestedByClients"
                        options={DoctorDetailsStore.dataRequestedByClients}
                        value={
                          requestedByClients?.ClientIds
                            ? toJS(requestedByClients?.ClientIds)
                                .split(';')
                                .map(i => +i)
                            : null
                        }
                        onChange={this.handleChangeRequestedByClients}
                        onSearchChange={(_, { searchQuery }) => DoctorDetailsStore.fetchAllClients(searchQuery, 2)}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>&nbsp;</Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          <th className="cursor-default" style={{ width: '250px' }}>
                            Client
                          </th>
                          <th className="cursor-default" style={{ width: '200px' }}>
                            First Requesting Date
                          </th>
                          <th className="cursor-default">Requesting CMs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(DoctorDetailsStore.doctorClientRequestings) &&
                          toJS(DoctorDetailsStore.doctorClientRequestings).map(item => {
                            return (
                              <tr key={'tr-rqtitem' + item.Id}>
                                <td>
                                  <Link to="#" onClick={() => this.openClientDetail(item.Client.Id, item.Client)}>
                                    {item.Client?.Name}
                                  </Link>
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <div>
                                      <span className="Label-item">
                                        {item.RequestingDate?.format('DD/MM/YYYY') || 'None'}
                                      </span>
                                    </div>
                                  ) : (
                                    <DatePicker
                                      value={item.RequestingDate}
                                      format={dateFormat}
                                      name="RequestingDate"
                                      onChange={this.handleChangeRqtDatePicker(item.ClientId, 'RequestingDate')}
                                    />
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <div>{this.renderLabelRqtCMs(item)}</div>
                                  ) : (
                                    <Dropdown
                                      style={{ width: '100%' }}
                                      placeholder="Select CMs"
                                      multiple
                                      search
                                      selection
                                      name={'LstCMs_' + item.ClientId}
                                      //options={DoctorDetailsStore.dataCMs}
                                      options={item.LstCMsOfClient}
                                      value={
                                        item.RequestingCMs
                                          ? toJS(item.RequestingCMs)
                                              .split(';')
                                              .map(i => +i)
                                          : null
                                      }
                                      onChange={this.handleChangeRequestingCMs}
                                      //onSearchChange={(_, { searchQuery }) => DoctorDetailsStore.fetchAllClients(searchQuery, 2)}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Form.Field>
                  {/* <Form.Field>&nbsp;</Form.Field> */}
                </Form.Group>
                <Form.Group widths="equal"></Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Recruiting Comment</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.RecruitingComment || 'None'}</span>
                      </div>
                    ) : (
                      <TextArea name="RecruitingComment" maxLength="2000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Onboarding Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.OnboardingDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="OnboardingDate"
                        animation=""
                        dateFormat="DD/MM/YYYY"
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.OnboardingDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>&nbsp;</Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Onboarding Comment</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.OnboardingComment || 'None'}</span>
                      </div>
                    ) : (
                      <TextArea name="OnboardingComment" maxLength="2000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Onboarded Date (Contract Date)</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.ContractWithMagDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="ContractWithMagDate"
                        animation=""
                        duration={0}
                        dateFormat="DD/MM/YYYY"
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ContractWithMagDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Contract End Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.ContractWithMagEndDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="ContractWithMagEndDate"
                        dateFormat="DD/MM/YYYY"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ContractWithMagEndDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field width="8">
                  <label className="Form-Field-label">Attach Contract</label>
                  {!isEditing ? (
                    dataDoctor.ContractAttachmentPhysicalFileName === null ? (
                      <NoFile />
                    ) : (
                      <File
                        name={dataDoctor.ContractAttachmentDisplayName}
                        downloadLink={`/UserFile/DoctorContract/${dataDoctor.ContractAttachmentPhysicalFileName}`}
                      />
                    )
                  ) : dataDoctor.ContractAttachmentPhysicalFileName === null ? (
                    <Upload onComplete={this.uploadContract} placeholder="Upload your contract" />
                  ) : (
                    <File
                      name={dataDoctor.ContractAttachmentDisplayName}
                      downloadLink={`/UserFile/DoctorContract/${dataDoctor.ContractAttachmentPhysicalFileName}`}
                      onRemove={this.removeContract}
                    />
                  )}
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
          {this.props.children}
        </Grid.Row>
      </Grid>
    );
  }
}
export default RecruitmentLoginInformationTab;
