import { observer } from 'mobx-react';
import React from 'react';

import * as api from '../../stores/api';
import router from '../../stores/router';
import connect from '../../utils/apiConnectQuery';
import routes from './index';
import IframeLogin from './IframeLogin';
import appStore from '../../stores/app';
import { Button, Icon } from 'semantic-ui-react';
import dashboard from 'stores/dashboard';
import './layout.scss';

@connect(api.currentUser)
@connect(api.IME_CaseProgress)
@connect(api.FR_CaseProgress)
@connect(api.SUPP_CaseProgress)
@connect(api.CR_CaseProgress)
@connect(api.MN_CaseProgress)
@connect(api.currentUser)
@observer
class IframeLayout extends React.Component {
  constructor(props) {
    super(props);
    appStore.setIframeMode(true);
    this.state = { loaded: false };
  }

  logout = () => {
    api.logout.post(null, () => {
      window.location.reload();
    });
  };

  onReceiveMessage = event => {
    if (event.data.action === 'TRANSER_DATA') {
      dashboard.transferData(event.data.data);
      console.log('Transer data', event.data.data);
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 1000);
    window.addEventListener('message', this.onReceiveMessage);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.onReceiveMessage);
  }
  render() {
    const { data } = api.currentUser;
    const Component = routes[router.location.pathname].Component;

    return (
      <div className="iframe-layout" style={{ visibility: this.state.loaded ? 'visible' : 'hidden' }}>
        {!data && (
          <div className="login-wrapper">
            <IframeLogin />
          </div>
        )}
        {data && (
          <div>
            <Button onClick={this.logout} size="mini" className="logout-button">
              <Icon name="log out" />
              Logout MAG
            </Button>
            <Component />
          </div>
        )}
      </div>
    );
  }
}

export default IframeLayout;
