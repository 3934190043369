import cx from 'classnames';
import React, { useState } from 'react';
import { Grid, Image, Dropdown, Button, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import * as api from '../../stores/api';
import dashboardStore from '../../stores/dashboard';
import ThisMonthSessionsSection from './ThisMonthSessionsSection';
import ChartSession from './ChartSession';
import store from './store';
import PerformanceDashboard from './PerformanceDashboard';
import { ReactComponent as CalendarIcon } from '@assets/svg/calendar.svg';
// import { ReactComponent as ClinicIcon } from '@assets/svg/clinic.svg';
import { ReactComponent as PencilIcon } from '@assets/svg/pencil.svg';
import { ReactComponent as SearchIcon } from '@assets/svg/search.svg';
import TableToolbar from '../shared/tableToolbar';
import { convertCamelCaseToUrl } from '@utils/functions';

const PERFORMANCE_ITEM = [
  {
    key: 'specialist',
    label: 'Specialist Performance',
  },
  {
    key: 'staff',
    label: 'Staff Performance',
  },
  {
    key: 'cm',
    label: 'Case Manager Performance',
  },
];

const ServiceGroup = ({ title, services }) => (
  <React.Fragment>
    {/* <div className="doctor-service-group"> */}
    <div
      className="col"
      style={{
        flex: `0 0 calc(20% * ${services.length}`,
        maxWidth: `calc(20% * ${services.length}`,
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
      }}
    >
      <div
        style={{
          flex: '0 0 100%',
          maxWidth: '100%',
          display: 'flex',
          height: '60px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 style={{ fontSize: '1vw', textAlign: 'center' }}>{title}</h3>
      </div>
      {services.map(({ onClick, title, icon, SVG, active }, index, arr) => (
        <div
          key={index}
          style={{
            flex: `0 0 calc(100% / ${arr.length})`,
            maxWidth: `calc(100% / ${arr.length})`,
            paddingRight: '5px',
          }}
        >
          <div onClick={onClick} className={cx('button', { active })}>
            {SVG ? <SVG /> : <Image src={`https://img.icons8.com/120/ios/ffffff/${icon}`} />}
            <b>{title}</b>
          </div>
        </div>
      ))}
    </div>
    {/* </div> */}
  </React.Fragment>
);

const ServiceButton = ({ onClick, title, icon, SVG, active, count }) => {
  return (
    <div className="col">
      <div onClick={onClick} className={cx('button', { active })}>
        {SVG ? <SVG /> : <Image src={`https://img.icons8.com/120/ios/ffffff/${icon}`} />}
        <b>{title}</b>
        {count > 0 && <span>({count})</span>}
      </div>
    </div>
  );
};

const SearchOptions = [
  { key: 'sac', text: 'Case Search', value: 'universalSearch' },
  { key: 'stcd', text: 'Document Search', value: 'docsSearch' },
  { key: 'satd', text: 'Action Required & PICs Search', value: 'actionRequiredSearch' },
];

function DashboardBody({ children }) {
  const [showServices, setShowServices] = useState(true);

  const openCalendar = () => {
    const user = api.currentUser.data;
    localStorage.setItem('DocAndCons', user.FullName);
    dashboardStore.open('/view/calendar-2');
  };

  const openSearch = (service, tabName, searchOption = '', filter = '') => {
    let url = '/view';
    if (searchOption.length) {
      url = `${url}/${convertCamelCaseToUrl(searchOption)}`;
    } else {
      url = `${url}/universal-search`;
    }

    dashboardStore.close(url, true);
    if (!!tabName) {
      if (searchOption.length === 0 || searchOption === 'universalSearch') {
        setTimeout(() => localStorage.setItem('FindCases', tabName));
      }
    }

    url = `${url}?service=${service}`;
    if (filter.length > 0) {
      url = `${url}&search=${filter}`;
    }

    setTimeout(() => dashboardStore.open(url));
  };

  const doctorService = [
    {
      key: 0,
      title: 'My Availability and Upcoming Appointments',
      icon: 'calendar',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 60 60">
          <g transform="translate(0.25 0.25)">
            <path
              d="M53,60H7a7.008,7.008,0,0,1-7-7V7A7.007,7.007,0,0,1,7,0H53a7.007,7.007,0,0,1,7,7V53A7.008,7.008,0,0,1,53,60ZM2,16.043V53a5.005,5.005,0,0,0,5,5H53a5.005,5.005,0,0,0,5-5V16.043ZM7,2A5,5,0,0,0,2,7v7.045H58V7a5,5,0,0,0-5-5Z"
              transform="translate(0 0)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M1.019,2.027a1.087,1.087,0,0,0,.733-.306,1.018,1.018,0,0,0,.285-.713A1.067,1.067,0,0,0,1.752.275a1.088,1.088,0,0,0-1.446,0A1.048,1.048,0,0,0,0,1.008,1.044,1.044,0,0,0,1.019,2.027"
              transform="translate(17.894 8.163)"
              fill="#fff"
            />
            <path
              d="M1.019,2.032a1.128,1.128,0,0,0,.733-.306,1.077,1.077,0,0,0,.306-.713A1.128,1.128,0,0,0,1.752.28,1.04,1.04,0,0,0,0,1.014a1.043,1.043,0,0,0,.326.733,1.006,1.006,0,0,0,.693.285"
              transform="translate(39.964 8.158)"
              fill="#fff"
            />
            <path
              d="M5.933.064.667,2.01a1.018,1.018,0,1,0,.7,1.911l3.9-1.438V23.721a1.019,1.019,0,1,0,2.037,0V1.02A1.019,1.019,0,0,0,6.868.184a1.008,1.008,0,0,0-.935-.12"
              transform="translate(34.741 24.982)"
              fill="#fff"
            />
            <path
              d="M8.524,10.537l7.234-8.874A1.019,1.019,0,0,0,14.968,0H1.807a1.019,1.019,0,1,0,0,2.037H12.823l-7.2,8.829a1.016,1.016,0,0,0-.112,1.116,1,1,0,0,0,.98.542c1.22-.09,4.763.051,6.472,1.842a4.338,4.338,0,0,1,.99,3.751A4.384,4.384,0,0,1,10.109,22.1c-3.1.583-7.008-.878-8.2-3.07A1.019,1.019,0,0,0,.123,20c1.371,2.532,5.01,4.268,8.528,4.268a9.988,9.988,0,0,0,1.836-.167,6.4,6.4,0,0,0,5.492-5.733,6.316,6.316,0,0,0-1.538-5.409,9.43,9.43,0,0,0-5.916-2.424"
              transform="translate(14.777 25.725)"
              fill="#fff"
            />
          </g>
        </svg>
      ),
      onClick: () => openCalendar(),
    },
    {
      key: 2,
      title: 'Update Attendance',
      icon: 'attendance-mark',
      onClick: () => openSearch('UpdateAttendance', 'Update Attendance'),
      count: store.UploadCases ? store.UploadCases.doctorUpdateAttendance : null,
    },
    {
      key: 3,
      title: 'Upload Dictations',
      icon: 'audio-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadDictations', 'Upload Dictations'),
      count: store.UploadCases ? store.UploadCases.doctorUpdateDictation : null,
    },
    {
      key: 4,
      title: 'Upload Completed Report',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadCorrectedReport', 'Upload Completed Report'),
      count: store.UploadCases ? store.UploadCases.doctorUpdateReport : null,
    },
  ];

  const typistService = [
    {
      key: 0,
      title: 'Download Dictations',
      icon: 'open-document',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="120" height="120" viewBox="0 0 24 24">
          <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
        </svg>
      ),
      onClick: () => openSearch('DownloadDictations', 'Download Dictations'),
    },
    {
      key: 1,
      title: 'Upload Typed Reports',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadTypedReports', 'Upload Typed Reports'),
    },
  ];

  const cmService = [
    {
      key: 0,
      title: 'Book a Service',
      icon: 'calendar',
      SVG: () => <CalendarIcon />,
      active: showServices,
      onClick: () => setShowServices(!showServices),
    },
    {
      key: 1,
      title: 'Change a Booking',
      icon: 'clock-settings',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 62">
          <path
            d="M53.979,61.106H7.129A7.137,7.137,0,0,1,0,53.977V7.127A7.136,7.136,0,0,1,7.129,0h46.85a7.136,7.136,0,0,1,7.129,7.127v46.85A7.137,7.137,0,0,1,53.979,61.106ZM2.037,16.339V53.977a5.1,5.1,0,0,0,5.092,5.092h46.85a5.1,5.1,0,0,0,5.092-5.092V16.339Zm5.092-14.3a5.1,5.1,0,0,0-5.092,5.09V14.3H59.071V7.127a5.1,5.1,0,0,0-5.092-5.09Z"
            transform="translate(0.25 0.25)"
            fill="#fff"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            d="M1.019,2.027a1.087,1.087,0,0,0,.733-.306,1.018,1.018,0,0,0,.285-.713A1.067,1.067,0,0,0,1.752.275a1.088,1.088,0,0,0-1.446,0A1.048,1.048,0,0,0,0,1.008,1.044,1.044,0,0,0,1.019,2.027"
            transform="translate(17.894 8.163)"
            fill="#fff"
          />
          <path
            d="M1.019,2.032a1.128,1.128,0,0,0,.733-.306,1.077,1.077,0,0,0,.306-.713A1.128,1.128,0,0,0,1.752.28,1.04,1.04,0,0,0,0,1.014a1.043,1.043,0,0,0,.326.733,1.006,1.006,0,0,0,.693.285"
            transform="translate(39.964 8.158)"
            fill="#fff"
          />
          <path
            d="M19.5,2.9l-2.3-2.3c-0.7-0.7-1.9-0.7-2.6,0l-2.2,2.2l5,5l2.2-2.2C20.2,4.8,20.2,3.6,19.5,2.9z"
            transform="translate(20.75 30.25)"
            fill="#fff"
          />
          <path
            d="M0.5,14.5l-0.5,4.5c0,0.1,0,0.1,0,0.2c0,0.5,0.5,0.9,1,0.8l4.5-0.5L16.4,8.6l-5-5L0.5,14.5z"
            transform="translate(20.75 30.25)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('ChangeABooking', 'Change a Booking'),
    },
    {
      key: 2,
      title: 'Upload Paperwork',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadPaperwork', 'Upload Paperwork'),
      count: store.UploadCases ? store.UploadCases.countPaperwork : null,
    },
    {
      key: 3,
      title: 'View Completed Reports',
      icon: 'clipboard',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="103.905" height="120" viewBox="0 0 54 60">
          <defs>
            <clipPath id="clip-path">
              <path d="M0,0H53.118V61.346H0Z" fill="none" />
            </clipPath>
          </defs>
          <g clipPath="url(#clip-path)">
            <path
              d="M43.937,61.345H1.023A1.024,1.024,0,0,1,0,60.322V1.023A1.024,1.024,0,0,1,1.023,0H52.1a1.024,1.024,0,0,1,1.023,1.023V19.4A1.024,1.024,0,0,1,52.1,20.418H44.96v39.9A1.024,1.024,0,0,1,43.937,61.345ZM2.046,2.046V59.3H42.914V19.4a1.024,1.024,0,0,1,1.023-1.023h7.136V2.046Z"
              transform="translate(0 0)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M3.61,0H1.023a1.023,1.023,0,0,0,0,2.047H2.587V14.326H1.023a1.023,1.023,0,1,0,0,2.047H3.61a1.024,1.024,0,0,0,1.023-1.023V1.023A1.024,1.024,0,0,0,3.61,0"
              transform="translate(48.441 44.973)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M3.61,0H1.023a1.023,1.023,0,0,0,0,2.047H2.587V14.326H1.023a1.023,1.023,0,1,0,0,2.047H3.61a1.024,1.024,0,0,0,1.023-1.023V1.023A1.024,1.024,0,0,0,3.61,0"
              transform="translate(48.441 24.508)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M1.023,2.047H23.535a1.023,1.023,0,1,0,0-2.047H1.023a1.023,1.023,0,0,0,0,2.047"
              transform="translate(10.054 51.119)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M1.023,2.047H23.535a1.023,1.023,0,1,0,0-2.047H1.023a1.023,1.023,0,0,0,0,2.047"
              transform="translate(10.054 42.933)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </svg>
      ),
      onClick: () => openSearch('ViewCompletedReports', 'Completed Reports'),
      count: store.UploadCases ? store.UploadCases.completedReportCM : null,
    },
  ];

  const bookingServices = api.isCaseManager() ? api.isCMInvoiceCheck() ? [] : [
    {
      title: 'IME & FITNESS FOR DUTY',
      services: [
        {
          key: 0,
          title: 'Appointment Search',
          SVG: () => <SearchIcon />,
          onClick: () => dashboardStore.open('/view/smart-search'),
        },
        {
          key: 0,
          title: 'Appointment Calendar',
          SVG: () => <CalendarIcon />,
          onClick: () => dashboardStore.open('/view/calendar-2'),
        },
        {
          key: 0,
          title: 'Single & Multi-Appointment Booking',
          SVG: () => <PencilIcon />,
          onClick: () => {
            localStorage.setItem('AssessmentCaseId', 'Book Assessment');
            dashboardStore.close('/view/add-edit-ime-assessment-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-ime-assessment-2`);
            });
          },
        },
      ],
    },
    {
      title: 'FILE REVIEWS',
      services: [
        {
          key: 0,
          title: 'File Review',
          icon: 'view-file',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 59.261">
              <path
                d="M2.777,59.261h38.89a2.781,2.781,0,0,0,2.777-2.777V42.594a.293.293,0,0,0-.012-.053c.1-.065.207-.126.307-.2L59.529,57.136a.925.925,0,1,0,1.311-1.307L46.186,41.175a11.993,11.993,0,0,0-1.75-18.9c0-.019.012-.034.012-.053V17.594c0-.032-.015-.058-.017-.087a.946.946,0,0,0-.024-.121.9.9,0,0,0-.166-.353c-.014-.017-.012-.037-.024-.054L29.4.312C29.4.307,29.389.307,29.384.3a.908.908,0,0,0-.413-.248.532.532,0,0,0-.1-.02A.953.953,0,0,0,28.7,0H2.777A2.781,2.781,0,0,0,0,2.777v53.7a2.781,2.781,0,0,0,2.777,2.777ZM48.15,32.409A10.186,10.186,0,1,1,37.965,22.223,10.2,10.2,0,0,1,48.15,32.409ZM29.631,3.361,41.458,16.667h-10.9c-.407,0-.925-.777-.925-1.389ZM1.852,2.777a.925.925,0,0,1,.925-.925h25V15.278c0,1.5,1.214,3.241,2.777,3.241H42.594V21.3a11.992,11.992,0,0,0-13.3,2.777H10.186a.926.926,0,1,0,0,1.852H27.839a11.931,11.931,0,0,0-1.862,5.556H10.186a.926.926,0,1,0,0,1.852H25.973a11.953,11.953,0,0,0,1.862,5.556H10.186a.926.926,0,1,0,0,1.852H29.168a.793.793,0,0,0,.11-.022,11.993,11.993,0,0,0,13.316,2.8V56.484a.925.925,0,0,1-.925.925H2.779a.917.917,0,0,1-.925-.925V2.779Z"
                fill="#fff"
              />
              <path
                d="M6,10.909h6.545a.545.545,0,0,0,0-1.091H6a.545.545,0,0,0,0,1.091Z"
                transform="translate(4.829 7.07)"
                fill="#fff"
              />
              <path
                d="M6,28.364H20.182a.545.545,0,0,0,0-1.091H6a.545.545,0,1,0,0,1.091Z"
                transform="translate(6.609 19.639)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem(`FileReviewCaseId`, 'New File Review');
            dashboardStore.close('/view/add-edit-file-review-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-file-review-2`);
            });
          },
        },
        {
          key: 1,
          title: 'Supplementary Report',
          icon: 'file',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 48.015 61.11">
              <path d="M11.25,20.25h9V22.5h-9Z" transform="translate(2.684 15.971)" fill="#fff" />
              <path d="M11.25,14.625H48.015v2.25H11.25Z" transform="translate(-5.625 10.277)" fill="#fff" />
              <path d="M11.25,25.875h5.625v2.25H11.25Z" transform="translate(1.218 21.666)" fill="#fff" />
              <path
                d="M49.275,8.8H42.728V6.615A4.365,4.365,0,0,0,38.363,2.25H20.9a4.365,4.365,0,0,0-4.365,4.365V8.8H9.99a4.365,4.365,0,0,0-4.365,4.365V58.995A4.365,4.365,0,0,0,9.99,63.36H49.275a4.365,4.365,0,0,0,4.365-4.365V13.163A4.365,4.365,0,0,0,49.275,8.8ZM20.9,6.615h17.46v8.73H20.9Zm28.373,52.38H9.99V13.163h6.548V19.71h26.19V13.163h6.547Z"
                transform="translate(-5.625 -2.25)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
            dashboardStore.close('/view/add-edit-supplementary-report-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-supplementary-report-2`);
            });
          },
        },
      ],
    },
    {
      title: 'MEDICAL NEGLIGENCE',
      services: [
        {
          key: 0,
          title: 'Medical Negligence Triage Service',
          icon: 'hospital-folder',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 61.11">
              <path
                d="M42.883,1.125H20.477V20.477H1.125V42.883H20.477V62.235H42.883V42.883H62.235V20.477H42.883ZM58.161,24.551V38.809H38.809V58.161H24.551V38.809H5.2V24.551H24.551V5.2H38.809V24.551Z"
                transform="translate(-1.125 -1.125)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            dashboardStore.open('/view/new-med-neg');
          },
        },
      ],
    },
    {
      title: 'DOCUMENT RETRIEVALS',
      services: [
        {
          key: 0,
          title: 'Clinical Record Retrieval',
          icon: 'live-folder',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 61.11">
              <path d="M9,20.25h9v4.5H9Z" transform="translate(8.37 16.142)" fill="#fff" />
              <path
                d="M61.11,7.639H57.291V0H19.1V7.639H11.458v4.775L9.167,15.278H3.819v6.685L0,26.736V61.11H45.833l15.278-19.1ZM7.639,19.1H38.194v7.639H7.639ZM42.013,57.291H3.819V30.555H42.013Zm3.819-30.555H42.013V15.278H15.278V11.458H45.833Zm7.639-9.548-3.819,4.775V7.639H22.916V3.819H53.471Z"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem('ClinicalRecordCaseId', 'New CR Retrieval');
            dashboardStore.close('/view/add-edit-clinical-notes-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-clinical-notes-2`);
            });
          },
        },
      ],
    },
  ] : [
    {
      title: 'IME & FITNESS FOR DUTY',
      services: [
        {
          key: 0,
          title: 'Appointment Search',
          SVG: () => <SearchIcon />,
          onClick: () => dashboardStore.open('/view/smart-search'),
        },
        {
          key: 0,
          title: 'Appointment Calendar',
          SVG: () => <CalendarIcon />,
          onClick: () => dashboardStore.open('/view/calendar-2'),
        },
        {
          key: 0,
          title: 'Single & Multi-Appointment Booking',
          SVG: () => <PencilIcon />,
          onClick: () => {
            localStorage.setItem('AssessmentCaseId', 'Book Assessment');
            dashboardStore.close('/view/add-edit-ime-assessment-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-ime-assessment-2`);
            });
          },
        },
      ],
    },
    {
      title: 'FILE REVIEWS',
      services: [
        {
          key: 0,
          title: 'File Review',
          icon: 'view-file',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 59.261">
              <path
                d="M2.777,59.261h38.89a2.781,2.781,0,0,0,2.777-2.777V42.594a.293.293,0,0,0-.012-.053c.1-.065.207-.126.307-.2L59.529,57.136a.925.925,0,1,0,1.311-1.307L46.186,41.175a11.993,11.993,0,0,0-1.75-18.9c0-.019.012-.034.012-.053V17.594c0-.032-.015-.058-.017-.087a.946.946,0,0,0-.024-.121.9.9,0,0,0-.166-.353c-.014-.017-.012-.037-.024-.054L29.4.312C29.4.307,29.389.307,29.384.3a.908.908,0,0,0-.413-.248.532.532,0,0,0-.1-.02A.953.953,0,0,0,28.7,0H2.777A2.781,2.781,0,0,0,0,2.777v53.7a2.781,2.781,0,0,0,2.777,2.777ZM48.15,32.409A10.186,10.186,0,1,1,37.965,22.223,10.2,10.2,0,0,1,48.15,32.409ZM29.631,3.361,41.458,16.667h-10.9c-.407,0-.925-.777-.925-1.389ZM1.852,2.777a.925.925,0,0,1,.925-.925h25V15.278c0,1.5,1.214,3.241,2.777,3.241H42.594V21.3a11.992,11.992,0,0,0-13.3,2.777H10.186a.926.926,0,1,0,0,1.852H27.839a11.931,11.931,0,0,0-1.862,5.556H10.186a.926.926,0,1,0,0,1.852H25.973a11.953,11.953,0,0,0,1.862,5.556H10.186a.926.926,0,1,0,0,1.852H29.168a.793.793,0,0,0,.11-.022,11.993,11.993,0,0,0,13.316,2.8V56.484a.925.925,0,0,1-.925.925H2.779a.917.917,0,0,1-.925-.925V2.779Z"
                fill="#fff"
              />
              <path
                d="M6,10.909h6.545a.545.545,0,0,0,0-1.091H6a.545.545,0,0,0,0,1.091Z"
                transform="translate(4.829 7.07)"
                fill="#fff"
              />
              <path
                d="M6,28.364H20.182a.545.545,0,0,0,0-1.091H6a.545.545,0,1,0,0,1.091Z"
                transform="translate(6.609 19.639)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem(`FileReviewCaseId`, 'New File Review');
            dashboardStore.close('/view/add-edit-file-review-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-file-review-2`);
            });
          },
        },
        {
          key: 1,
          title: 'Supplementary Report',
          icon: 'file',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 48.015 61.11">
              <path d="M11.25,20.25h9V22.5h-9Z" transform="translate(2.684 15.971)" fill="#fff" />
              <path d="M11.25,14.625H48.015v2.25H11.25Z" transform="translate(-5.625 10.277)" fill="#fff" />
              <path d="M11.25,25.875h5.625v2.25H11.25Z" transform="translate(1.218 21.666)" fill="#fff" />
              <path
                d="M49.275,8.8H42.728V6.615A4.365,4.365,0,0,0,38.363,2.25H20.9a4.365,4.365,0,0,0-4.365,4.365V8.8H9.99a4.365,4.365,0,0,0-4.365,4.365V58.995A4.365,4.365,0,0,0,9.99,63.36H49.275a4.365,4.365,0,0,0,4.365-4.365V13.163A4.365,4.365,0,0,0,49.275,8.8ZM20.9,6.615h17.46v8.73H20.9Zm28.373,52.38H9.99V13.163h6.548V19.71h26.19V13.163h6.547Z"
                transform="translate(-5.625 -2.25)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
            dashboardStore.close('/view/add-edit-supplementary-report-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-supplementary-report-2`);
            });
          },
        },
      ],
    },
    {
      title: 'MEDICAL NEGLIGENCE',
      services: [
        {
          key: 0,
          title: 'Medical Negligence Triage Service',
          icon: 'hospital-folder',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 61.11">
              <path
                d="M42.883,1.125H20.477V20.477H1.125V42.883H20.477V62.235H42.883V42.883H62.235V20.477H42.883ZM58.161,24.551V38.809H38.809V58.161H24.551V38.809H5.2V24.551H24.551V5.2H38.809V24.551Z"
                transform="translate(-1.125 -1.125)"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            if (api.isAdminOrMagStaffOrAccounting()) {
              dashboardStore.open('/view/new-med-neg');
            }
          },
        },
      ],
    },
    {
      title: 'DOCUMENT RETRIEVALS',
      services: [
        {
          key: 0,
          title: 'Clinical Record Retrieval',
          icon: 'live-folder',
          SVG: () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 61.11 61.11">
              <path d="M9,20.25h9v4.5H9Z" transform="translate(8.37 16.142)" fill="#fff" />
              <path
                d="M61.11,7.639H57.291V0H19.1V7.639H11.458v4.775L9.167,15.278H3.819v6.685L0,26.736V61.11H45.833l15.278-19.1ZM7.639,19.1H38.194v7.639H7.639ZM42.013,57.291H3.819V30.555H42.013Zm3.819-30.555H42.013V15.278H15.278V11.458H45.833Zm7.639-9.548-3.819,4.775V7.639H22.916V3.819H53.471Z"
                fill="#fff"
              />
            </svg>
          ),
          onClick: () => {
            localStorage.setItem('ClinicalRecordCaseId', 'New CR Retrieval');
            dashboardStore.close('/view/add-edit-clinical-notes-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-clinical-notes-2`);
            });
          },
        },
      ],
    },
  ];

  const staffService = [
    {
      key: 0,
      title: 'Book a Service',
      icon: 'calendar',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 60 60">
          <g transform="translate(0.25 0.25)">
            <path
              d="M53,60H7a7.008,7.008,0,0,1-7-7V7A7.007,7.007,0,0,1,7,0H53a7.007,7.007,0,0,1,7,7V53A7.008,7.008,0,0,1,53,60ZM2,16.043V53a5.005,5.005,0,0,0,5,5H53a5.005,5.005,0,0,0,5-5V16.043ZM7,2A5,5,0,0,0,2,7v7.045H58V7a5,5,0,0,0-5-5Z"
              transform="translate(0 0)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              d="M1.019,2.027a1.087,1.087,0,0,0,.733-.306,1.018,1.018,0,0,0,.285-.713A1.067,1.067,0,0,0,1.752.275a1.088,1.088,0,0,0-1.446,0A1.048,1.048,0,0,0,0,1.008,1.044,1.044,0,0,0,1.019,2.027"
              transform="translate(17.894 8.163)"
              fill="#fff"
            />
            <path
              d="M1.019,2.032a1.128,1.128,0,0,0,.733-.306,1.077,1.077,0,0,0,.306-.713A1.128,1.128,0,0,0,1.752.28,1.04,1.04,0,0,0,0,1.014a1.043,1.043,0,0,0,.326.733,1.006,1.006,0,0,0,.693.285"
              transform="translate(39.964 8.158)"
              fill="#fff"
            />
            <path
              d="M5.933.064.667,2.01a1.018,1.018,0,1,0,.7,1.911l3.9-1.438V23.721a1.019,1.019,0,1,0,2.037,0V1.02A1.019,1.019,0,0,0,6.868.184a1.008,1.008,0,0,0-.935-.12"
              transform="translate(34.741 24.982)"
              fill="#fff"
            />
            <path
              d="M8.524,10.537l7.234-8.874A1.019,1.019,0,0,0,14.968,0H1.807a1.019,1.019,0,1,0,0,2.037H12.823l-7.2,8.829a1.016,1.016,0,0,0-.112,1.116,1,1,0,0,0,.98.542c1.22-.09,4.763.051,6.472,1.842a4.338,4.338,0,0,1,.99,3.751A4.384,4.384,0,0,1,10.109,22.1c-3.1.583-7.008-.878-8.2-3.07A1.019,1.019,0,0,0,.123,20c1.371,2.532,5.01,4.268,8.528,4.268a9.988,9.988,0,0,0,1.836-.167,6.4,6.4,0,0,0,5.492-5.733,6.316,6.316,0,0,0-1.538-5.409,9.43,9.43,0,0,0-5.916-2.424"
              transform="translate(14.777 25.725)"
              fill="#fff"
            />
          </g>
        </svg>
      ),
      active: showServices,
      onClick: () => setShowServices(!showServices),
    },
    {
      key: 1,
      title: 'Change a Booking',
      icon: 'clock-settings',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 62">
          <path
            d="M53.979,61.106H7.129A7.137,7.137,0,0,1,0,53.977V7.127A7.136,7.136,0,0,1,7.129,0h46.85a7.136,7.136,0,0,1,7.129,7.127v46.85A7.137,7.137,0,0,1,53.979,61.106ZM2.037,16.339V53.977a5.1,5.1,0,0,0,5.092,5.092h46.85a5.1,5.1,0,0,0,5.092-5.092V16.339Zm5.092-14.3a5.1,5.1,0,0,0-5.092,5.09V14.3H59.071V7.127a5.1,5.1,0,0,0-5.092-5.09Z"
            transform="translate(0.25 0.25)"
            fill="#fff"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            d="M1.019,2.027a1.087,1.087,0,0,0,.733-.306,1.018,1.018,0,0,0,.285-.713A1.067,1.067,0,0,0,1.752.275a1.088,1.088,0,0,0-1.446,0A1.048,1.048,0,0,0,0,1.008,1.044,1.044,0,0,0,1.019,2.027"
            transform="translate(17.894 8.163)"
            fill="#fff"
          />
          <path
            d="M1.019,2.032a1.128,1.128,0,0,0,.733-.306,1.077,1.077,0,0,0,.306-.713A1.128,1.128,0,0,0,1.752.28,1.04,1.04,0,0,0,0,1.014a1.043,1.043,0,0,0,.326.733,1.006,1.006,0,0,0,.693.285"
            transform="translate(39.964 8.158)"
            fill="#fff"
          />
          <path
            d="M19.5,2.9l-2.3-2.3c-0.7-0.7-1.9-0.7-2.6,0l-2.2,2.2l5,5l2.2-2.2C20.2,4.8,20.2,3.6,19.5,2.9z"
            transform="translate(20.75 30.25)"
            fill="#fff"
          />
          <path
            d="M0.5,14.5l-0.5,4.5c0,0.1,0,0.1,0,0.2c0,0.5,0.5,0.9,1,0.8l4.5-0.5L16.4,8.6l-5-5L0.5,14.5z"
            transform="translate(20.75 30.25)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('ChangeABooking', 'Change a Booking'),
    },
    {
      key: 2,
      title: 'Update Attendance',
      icon: 'attendance-mark',
      onClick: () => openSearch('UpdateAttendance', 'Update Attendance'),
      count: store.UploadCases ? store.UploadCases.updateAttendance : null,
    },
    {
      key: 3,
      title: 'Bill Services',
      icon: 'receipt-terminal',
      onClick: () => openSearch('BillServices', 'Bill Services'),
      count: store.UploadCases ? store.UploadCases.countBill : null,
    },
    {
      key: 4,
      title: 'Upload Paperwork',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadPaperwork', 'Upload Paperwork'),
      count: store.UploadCases ? store.UploadCases.countPaperwork : null,
    },
    {
      key: 5,
      title: 'Upload Dictations',
      icon: 'audio-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadDictations', 'Upload Dictations'),
      count: store.UploadCases ? store.UploadCases.countDic : null,
    },
    {
      key: 6,
      title: 'Upload Report Drafts',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadReportDrafts', 'Upload Report Drafts'),
      count: store.UploadCases ? store.UploadCases.countDraft : null,
    },
    {
      key: 7,
      title: 'Upload Reports',
      icon: 'import-file',
      SVG: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 62 40">
          <path
            d="M49.27,21.379a19.078,19.078,0,0,0-35.647-5.092A15.271,15.271,0,0,0,15.278,46.74h33.1a12.695,12.695,0,0,0,.891-25.361ZM35.647,28.916V39.1H25.462V28.916H17.824L30.555,16.185,43.286,28.916Z"
            transform="translate(0 -6)"
            fill="#fff"
          />
        </svg>
      ),
      onClick: () => openSearch('UploadReports', 'Upload Reports'),
      count: store.UploadCases ? store.UploadCases.countComplete : null,
    },
  ];

  const [filter, setFilter] = useState('');
  const [searchOption, setSearchOption] = useState('universalSearch');

  const handleSearchChange = event => {
    event.preventDefault();
    setFilter(event.target.value);
  };

  const handleDropdownChange = (e, { value }) => {
    setSearchOption(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if ((filter?.trim() ?? '').length === 0) {
      return;
    }

    const so = SearchOptions.find(s => s.value === searchOption);
    openSearch('SearchAll', so?.text ?? 'Case Search', searchOption, filter);
  };

  const handleRemove = event => {
    event.preventDefault();
    setFilter('');
  };

  const { isShowChart } = store;

  return (
    <div
      className={cx('dashboard-body', {
        'mt-5': !api.isAdminOrMagStaffOrAccounting(),
      })}
    >
      <div className="greeting">{/* <h2 className="convers-text"></h2> */}</div>
      {!api.isCMInvoiceCheck ? 
        <React.Fragment>
          <Grid className="dashboard-search-form">
            <Grid.Row>
              <Grid.Column width="13">
                <form className="ui form" style={{ margin: '0 10px 0 -5px' }}>
                  <div className="fields">
                    <Input
                      size="large"
                      icon="search"
                      iconPosition="left"
                      label={
                        filter.length ? (
                          <Button type="button" className="clear" icon="times" onClick={handleRemove} />
                        ) : null
                      }
                      labelPosition={filter.length ? 'right' : null}
                      value={filter}
                      onChange={handleSearchChange}
                      placeholder="Search any keyword"
                      // className="ten wide field"
                      style={{ border: '1px solid black', width: '60%', marginRight: '5px', marginLeft: '10px' }}
                    />
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Dropdown
                        selection
                        options={SearchOptions}
                        value={searchOption}
                        onChange={handleDropdownChange}
                        style={{
                          marginLeft: filter.length ? 40 : 0,
                          borderColor: 'black',
                          flexGrow: 1,
                          lineHeight: 'unset',
                        }}
                      />
                    )}
                    <TableToolbar.SubmitButton
                      className="Toolbar-highlight-button px-2"
                      style={{
                        marginLeft: !api.isAdminOrMagStaffOrAccounting() && filter.length ? 45 : 5,
                        height: '47px',
                        width: '80px',
                        flexGrow: 1,
                      }}
                      onClick={handleSubmit}
                    >
                      Search
                    </TableToolbar.SubmitButton>
                  </div>
                </form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment> : 
      <div></div>}
      {!api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && <Grid>
        <Grid.Row>
          <Grid.Column width="13">
            <div
              className="doctor-service-group"
              style={{
                width: api.isTypist() && '63%',
              }}
            >
              {api.isAdminOrMagStaffOrAccounting() && staffService.map(service => <ServiceButton {...service} />)}
              {api.isCaseManager() && !api.isTypist() && cmService.map(service => <ServiceButton {...service} />)}
              {api.isTypist() && typistService.map(service => <ServiceButton {...service} />)}
              {api.isDoctor() && doctorService.map(service => <ServiceButton {...service} />)}
            </div>
            {showServices && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
              <React.Fragment>
                <div className="greeting">
                  <h2>What type of service would you like to book?</h2>
                </div>
                <div className="doctor-service-group">
                  {bookingServices.map(group => (
                    <ServiceGroup key={group.title} title={group.title} services={group.services} />
                  ))}
                </div>
              </React.Fragment>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Grid.Row>
                <Grid.Column>
                  <ThisMonthSessionsSection />
                </Grid.Column>
                <Grid.Column>{isShowChart && <ChartSession />}</Grid.Column>
              </Grid.Row>
            )}
            <Grid columns="3" className="my-2">
              <Grid.Row>
                {PERFORMANCE_ITEM.map(
                  i =>
                    api.isAdminOrMagStaffOrAccounting() &&
                    api.isMAG() && (
                      <Grid.Column key={i.key}>
                        <PerformanceDashboard label={i.label} keyword={i.key} />
                      </Grid.Column>
                    ),
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width="3">{children}</Grid.Column>
        </Grid.Row>
      </Grid>}
    </div>
  );
}

export default observer(DashboardBody);
