import React from 'react';

export default class PhoneNumber extends React.Component {
  state = {
    phoneNumber: this.props.phoneNumber,
    mobilePhoneNumber: this.props.mobilePhoneNumber,
    officePhoneNumber: this.props.officePhoneNumber,
  };

  formatOfficePN = () => {
    const { officePhoneNumber } = this.state;
    if (officePhoneNumber) {
      let officePhone = officePhoneNumber.replace(/\D/g, '').replace(/[^\d]/g, '');
      return (
        <span>
          <span>{officePhone.slice(0, 2)}</span>
          <span style={{ marginLeft: 5 }}>{officePhone.slice(2, officePhone.length)}</span>
        </span>
      );
    }
    return '';
  };

  fortmatTelePhone = () => {
    const { mobilePhoneNumber } = this.state;
    if (mobilePhoneNumber) {
      let telePhone = mobilePhoneNumber.replace(/\D/g, '').replace(/[^\d]/g, '');
      return (
        <span>
          <span>{telePhone.slice(0, 4)}</span>
          <span style={{ marginLeft: 5 }}>{telePhone.slice(4, telePhone.length)}</span>
        </span>
      );
    }
    return '';
  };

  fortmatPhoneNumber = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber) {
      const phone = phoneNumber.split(`/`);
      const officePhone = phone[0].replace(/\D/g, '').replace(/[^\d]/g, '');
      const telePhone = phone.length === 2 ? phone[1].replace(/\D/g, '').replace(/[^\d]/g, '') : null;
      return (
        <span>
          {officePhone && (
            <React.Fragment>
              <span>{officePhone.slice(0, 2)}</span>
              <span style={{ marginLeft: 5 }}>{officePhone.slice(2, officePhone.length)}</span>
            </React.Fragment>
          )}
          {telePhone && (
            <React.Fragment>
              <span>{` / ${telePhone.slice(0, 4)}`}</span>
              <span style={{ marginLeft: 5 }}>{telePhone.slice(4, telePhone.length)}</span>
            </React.Fragment>
          )}
        </span>
      );
    }
    return '';
  };

  render() {
    const { phoneNumber, officePhoneNumber, mobilePhoneNumber } = this.state;
    return (
      <React.Fragment>
        <span>
          {phoneNumber && <span>{this.fortmatPhoneNumber()}</span>}
          {officePhoneNumber && <span>{this.formatOfficePN()}</span>}
          {officePhoneNumber && mobilePhoneNumber && <span style={{ marginLeft: '5px', marginRight: '5px' }}>/</span>}
          {mobilePhoneNumber && <span>{this.fortmatTelePhone()}</span>}
        </span>
      </React.Fragment>
    );
  }
}
