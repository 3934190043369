import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { action } from 'mobx';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/functions';

import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
// import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import ModalResetPassword from './ModalResetPassword';
import TypistStore from './TypistStore';
import dashboardStore from '../../../stores/dashboard';
import moment from 'moment';
import router from '../../../stores/router';
import * as api from '@stores/api';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'Title', sortKey: 'Title' },
  { title: 'First Name', sortKey: 'FirstName' },
  { title: 'Last Name', sortKey: 'LastName' },
  { title: 'Job Title' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Office Phone', sortKey: 'OfficePhone' },
  { title: 'Mobile Phone', sortKey: 'Telephone' },
  { title: 'Email', sortKey: 'Email' },
  { title: 'Username Login' },
  { title: 'State', sortKey: 'State' },
  { title: 'Client Officer' },
  { title: 'Account Status', sortKey: 'IsActive' },
  { title: 'Date Of Status', sortKey: 'StatusChangedDate' },
  { title: 'Created At', sortKey: 'CreatedDate' },
  { title: 'Created By', sortKey: 'CreatedByUsername' },
];

@observer
class TypistTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }

  openModal = options => {
    return TypistStore.toggleModal(true, options);
  };

  handlePageClick = page => {
    TypistStore.refetch({ curPage: page });
  };

  openCaseDetail = (id, fName, lName, userId, type) => () => {
    localStorage.setItem('TypistName', `${fName} ${lName}`);
    dashboardStore.close(`/view/add-edit-typist`, true);
    let step = 0;
    switch (type) {
      case 'Login':
        step = 1;
        break;
      case 'Correspondence':
        step = 3;
        break;
      default:
        break;
    }
    setTimeout(() => {
      router.history.push({
        pathname: '/view/add-edit-typist',
        state: { userId },
        search: `?id=${id}&action=edit&step=${step}`,
      });
    });
  };

  handleDelete = id => async () => {
    TypistStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Typist/Delete', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      await TypistStore.fetchCase({}, true);
    } finally {
      TypistStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = id => async () => {
    TypistStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/Typist/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });

      await TypistStore.fetchCase({}, true);
    } finally {
      TypistStore.setFieldsValue({ loading: false });
    }
  };

  handleImpersonateUser = userId => () => {
    TypistStore.setFieldsValue({ loading: true });
    customFetch('/Manager/ImpersonateUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      var url = window.location.protocol + '//' + window.location.host + '/view/dashboard';
      window.location = url;
    });
  };

  openNotification = message => {
    return TypistStore.toggleModal(true, {
      modalType: 'notification',
      message,
    });
  };

  handleUnlockUser = record => async () => {
    TypistStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/manager/unlockUser', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId: record.UserId }),
      }).then(this.openNotification('User has been unlocked'));
      await TypistStore.fetchCase({}, true);
    } finally {
      TypistStore.setFieldsValue({ loading: false });
    }
  };

  handleResetPassword = (id, record) => () => {
    customFetch('/Manager/ResetPassword', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId: record.UserId, cmId: id }),
    }).then(res => {
      this.setState({
        openModalNotification: true,
        message: `Generated password: ${res.result}`,
      });
    });
  };

  handleResendLoginInformation = (id, record) => () => {
    if (!record.UserId) {
      this.setState({
        openModalNotification: true,
        message: 'This user is not yet enabled for login. Open the user details and enable login first',
      });
    } else {
      customFetch('/Manager/ResendLoginInformation', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ uId: record.UserId, typistId: id }),
      }).then(() => {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Resend login Information: Successfully!!`,
          duration: 2,
          placement: `bottomRight`,
        });
      });
    }
  };

  handleEditUser = (Id, record) => () => {
    customFetch('/Manager/GetUserById', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: record.UserId }),
    })
      .then(
        action(data => {
          TypistStore.dataUser = data;
        }),
      )
      .then(
        this.openModal({
          modalType: 'editUser',
          Id,
          record,
        }),
      );
  };

  renderTableBody = () => {
    return TypistStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          style={{ cursor: 'pointer' }}
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
        >
          <Link to="#" onClick={this.openCaseDetail(Id, record.FirstName, record.LastName, record.UserId, `General`)}>
            {Id}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Title}</Table.Cell>
        <Table.Cell>{record.FirstName}</Table.Cell>
        <Table.Cell>{record.LastName}</Table.Cell>
        <Table.Cell>{record.PositionName}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          <FormatPhoneNumber officePhoneNumber={record.OfficePhone} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          <FormatPhoneNumber mobilePhoneNumber={record.Telephone} />
        </Table.Cell>
        <Table.Cell>{record.Email}</Table.Cell>
        <Table.Cell>{record.UserName}</Table.Cell>
        <Table.Cell>{record.State}</Table.Cell>
        <Table.Cell>{record.AssignedStaff}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': record.Status === 'Inactive' })}>{record.Status}</p>
          {record.Status === 'Inactive' && (
            <span>{record.InActiveDate ? moment(formatDate(record.InActiveDate)).format('DD/MM/YYYY') : ''}</span>
          )}
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedByUsername}</Table.Cell>
      </Table.Row>
    ));
  };

  openViewBookingHistory = (id, record) => {
    TypistStore.setFieldsValue({
      cmId: id,
      dataBookingHistory: null,
      totalBooking: 0,
    });
    TypistStore.fetchBookingHistory(id);
    return TypistStore.toggleModal(true, {
      modalType: 'bookingHistory',
      record,
    })();
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && (
            <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId)}>
              Update Detail
            </Menu.Item>
          )}
          <Menu.Item onClick={() => this.openViewBookingHistory(id, record)}>View Booking History</Menu.Item>
          <Menu.Item
            onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Correspondence`)}
          >
            Update Correspondence
          </Menu.Item>
          <Menu.Divider fitted />
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Edit Username
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Unlock User
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Reset Password
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Resend Login Information
          </Menu.Item>
          <Menu.Item
            onClick={
              !record.UserId
                ? this.openModal({
                    modalType: 'notification',
                    message: 'This user is not yet enabled for login. Open the user details and enable login first',
                  })
                : this.openModal({
                    modalType: 'showLoginHistory',
                    uId: id,
                    record,
                  })
            }
          >
            Login History
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to enable typist '${record.FirstName} ${record.LastName}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `${record.LastName}`
                  ? `Do you want to disable typist '${record.FirstName} ${record.LastName}' ?`
                  : `Do you want to disable typist '${record.FirstName}' ?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Disable
            </Menu.Item>
          )}
          {record.UserId && (api.isAdmin() || api.isMagStaff()) && (
            <Menu.Item onClick={this.handleImpersonateUser(record.UserId)}>Impersonate</Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalResetPassword
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { loading, data } = TypistStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        {this.renderModal()}
        <TableHeader
          columns={columns}
          sortColumn={TypistStore.sortColumn}
          sortDirection={TypistStore.sortDirection}
          onSort={TypistStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={TypistStore.curPage}
          totalPage={TypistStore.totalPage}
          totalItems={TypistStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default TypistTable;
