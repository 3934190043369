import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import VideoSessionStore from './VideoSessionStore';
import router from '@stores/router';
// import Jitsi from './Jitsi'

@observer
class ViewSession extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };
  async componentWillMount() {
    const { token } = this.getRouteParams();
    window.location.href = `https://mag.kawaconn.com/video-meeting?token=${token}&redirectFromMAG=true`;
    return;
    // await VideoSessionStore.viewSession(token);
  }
  render() {
    const { loading, dataSession } = VideoSessionStore;

    return loading && !dataSession ? (
      <Spin size="large" />
    ) : (
      <React.Fragment>{/* <Jitsi dataSession={dataSession} /> */}</React.Fragment>
    );
  }
}

export default ViewSession;
