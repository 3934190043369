import React from 'react';
import cx from 'classnames';
import { Divider } from 'antd';
import { Button } from 'semantic-ui-react';

import ClaimDetails from './ClaimDetails';
import MedNegProcessing from './MedNegProcessing';
import CaseStatus from './CaseStatus';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';

const STEPS = [
  'Claim Details',
  'Med Neg Processing Information',
  'Case Status Information',
  'Case Correspondence',
  'Action Required',
];

class AddEditMedNeg extends React.Component {
  state = {
    currentStep: 0,
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <MedNegProcessing />;
      case 2:
        return <CaseStatus />;
      case 3:
        return <CaseCorrespondence />;
      case 4:
        return <ActionRequired />;
      default:
        return <ClaimDetails />;
    }
  };

  render() {
    return (
      <div className="page-container">
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {this.renderForm()}
        </div>
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        <div className="form-footer">
          <Divider />
          <Button primary>Save</Button>
          <Button className="negative">Close</Button>
        </div>
      </div>
    );
  }
}

export default AddEditMedNeg;
