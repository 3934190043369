import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../shared/Modal';
import uploadStore from '../UploadFile/UploadStore';
import { Button, Dimmer, Loader, Form, Icon, Popup, TextArea, Radio, Checkbox } from 'semantic-ui-react';
import './../../DragDrop.scss';
import { action } from 'mobx';
import { timezoneList } from '@components/doctor-routes/AddEditMySessions/type';
import { renderDate } from '../../../case-details-routes/Shared';
import { observer } from 'mobx-react';
import { DatePicker, TimePicker, Select } from 'antd';
import moment from 'moment';
import * as api from '@stores/api';

function UpdateAttendace(props) {
  const { open, modalParams, emailInfo, submitUpdateAttendance, toggleModal, updateAttendanceHandler } = uploadStore;
  const { serviceType, id } = modalParams;
  const [attended, setAttended] = useState('');
  const [sendRDDNToSpecialist, setSendRDDNToSpecialist] = useState(false);
  const [attendedDate, setAttendedDate] = useState(moment().format('YYYY-MM-DD'));
  const [nonAttendedDate, setNonAttendedDate] = useState(moment().format('YYYY-MM-DD'));
  const [attendanceComment, setAttendanceComment] = useState('');
  const [sendReportSolicitor, setSendReportSolicitor] = useState(false);
  const [sendReportCM, setSendReportCM] = useState(false);
  const [CMEmail, setCMEmail] = useState(null);
  const [solicitorEmail, setSolicitorEmail] = useState(null);
  const prevEmailInfo = useRef(emailInfo);
  const [actualDateFromChange, setActualDateFromChange] = useState(null);
  const [actualDateFrom, setActualDateFrom] = useState(null);
  const [actualDateTo, setActualDateTo] = useState(null);
  const [actualTimeZone, setActualTimeZone] = useState(null);
  const [actualDateFromTime, setActualDateFromTime] = useState(null);
  const [actualDateToTime, setActualDateToTime] = useState(null);
  const hourList = uploadStore.generateTimeRange(24);
  const minuteList = uploadStore.generateTimeRange(60);

  const title = () => {
    return 'Update Attendance: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant;
  };

  useEffect(() => {
    uploadStore.fetchInfoEmail(modalParams.id);
  }, [modalParams]);

  const onSubmitUpdateAttendance = action(() => {
    uploadStore.loading = true;
    let updateData = {};
    updateData = {
      id: id,
      attendance: attended,
      attendedDate: attendedDate,
      nonAttendedDate: nonAttendedDate,
      attendanceComment: attendanceComment,
      autoRemindReportCompletionDeadlineToDoctor: sendRDDNToSpecialist,
      sendtoSolitor: sendReportSolicitor,
      sendtoCM: sendReportCM,
      actualDateFrom: attended === 1 ? uploadStore.getMomentDateTime(actualDateFrom).toISOString() : null,
      actualDateTo: attended === 1 ? uploadStore.getMomentDateTime(actualDateTo).toISOString() : null,
      actualTimeZone: attended === 1 ? actualTimeZone ?? null : null,
    };
    updateAttendanceHandler(submitUpdateAttendance[serviceType], updateData, props.refetchTable);
  });

  useEffect(() => {
    if (attended === 1 && !actualDateFrom && !actualDateTo) {
      setAttendedDate(uploadStore.getMomentDateTime(modalParams.record?.DateFrom) ?? null);
      setActualDateFrom(uploadStore.getMomentDateTime(modalParams.record?.DateFrom) ?? null);
      setActualDateTo(uploadStore.getMomentDateTime(modalParams.record?.DateTo) ?? null);
      setActualTimeZone(modalParams.record?.AppointmentTimeZone ?? uploadStore.timeZone);
    }
  }, [attended]);

  useEffect(() => {
    setAttendedDate(uploadStore.getMomentDateTime(actualDateFromChange) ?? null);
    setActualDateFrom(uploadStore.getMomentDateTime(actualDateFromChange) ?? null);
  }, [actualDateFromChange]);

  useEffect(() => {
    setAttendedDate(uploadStore.getMomentDateTime(actualDateFromTime) ?? null);
    setActualDateFrom(uploadStore.getMomentDateTime(actualDateFromTime) ?? null);
  }, [actualDateFromTime]);

  useEffect(() => {
    console.log(actualDateToTime);
    setActualDateTo(uploadStore.getMomentDateTime(actualDateToTime) ?? null);
  }, [actualDateToTime]);

  const emailLabel = (labelType, doctorEmail) => {
    const label = `Booking ${labelType} `;
    const email = doctorEmail ? `(${doctorEmail})` : '';
    return label.concat(email);
  };

  const renderPopup = (popupMessage = '') => (
    <Popup trigger={<Icon name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );

  useEffect(
    action(() => {
      if (prevEmailInfo.current !== emailInfo) {
        if (emailInfo) {
          uploadStore.loading = false;
          setCMEmail(emailInfo.CMEmail);
          setSolicitorEmail(emailInfo.SolicitorEmail);
          emailInfo.CMEmail && setSendReportCM(true);
          emailInfo.SolicitorEmail && setSendReportSolicitor(true);
          //setAttendedDate(new Date(emailInfo.DateRequest));
          setNonAttendedDate(new Date(emailInfo.DateRequest));
        }
      } else {
        uploadStore.loading = true;
      }
    }),
    [emailInfo],
  );

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={800}
      className="custom-modal"
      title={title()}
      footer={
        <React.Fragment>
          {!api.isOnlySearch() && <Button
            color="blue"
            disabled={attended === '' || api.isTypist() || api.isCaseManager()}
            onClick={onSubmitUpdateAttendance}
            loading={uploadStore.loading}
          >
            <Icon name="upload" /> Save
          </Button>}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <Dimmer active={uploadStore.loading}>
        <Loader />
      </Dimmer>
      <Form>
        <Form.Group inline>
          <Radio
            label={'Attended'}
            checked={attended === 1}
            onChange={() => setAttended(1)}
            disabled={api.isTypist() || api.isCaseManager()}
          />
          <div style={{ width: '20%' }}></div>
          <Radio
            label={'Did not attend'}
            checked={attended === 2}
            onChange={() => setAttended(2)}
            disabled={api.isTypist() || api.isCaseManager()}
          />
        </Form.Group>
        {attended !== '' && (
          <React.Fragment>
            {attended === 1 ? (
              <React.Fragment>
                <Form.Field>
                  <label>Actual Appointment Time:</label>
                  <React.Fragment>
                    <div>
                      <DatePicker
                        style={{ display: 'inline-block', float: 'left' }}
                        size="large"
                        format="DD/MM/YYYY"
                        //disabled={true}
                        onChange={value => {
                          setActualDateFromChange(value);
                        }}
                        //value={moment(attendedDate) || null}
                        value={renderDate(actualDateFrom, true, false)}
                      />
                      <TimePicker
                        format="HH:mm"
                        showTime
                        placeholder="From"
                        size="large"
                        allowClear={false}
                        style={{ marginLeft: '10px', width: '125px', display: 'inline-block', float: 'left' }}
                        value={uploadStore.getMomentDateTime(actualDateFrom, actualTimeZone)}
                        onChange={value => {
                          setActualDateFromTime(value);
                        }}
                      />
                      <TimePicker
                        format="HH:mm"
                        showTime
                        placeholder="To"
                        size="large"
                        allowClear={false}
                        style={{ marginLeft: '10px', width: '125px', display: 'inline-block', float: 'left' }}
                        value={uploadStore.getMomentDateTime(actualDateTo, actualTimeZone)}
                        onChange={value => {
                          setActualDateToTime(value);
                        }}
                      />
                      <span
                        className="ant-time-picker ant-time-picker-large"
                        style={{ display: 'inline-block', float: 'left' }}
                      >
                        <Select
                          showSearch
                          size="large"
                          optionFilterProp="children"
                          value={actualTimeZone}
                          style={{ width: '200px', marginLeft: '10px' }}
                          onChange={value => {
                            setActualTimeZone(value);
                          }}
                        >
                          {timezoneList.map(i => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </span>
                    </div>
                  </React.Fragment>
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    // disabled={solicitorEmail === null}
                    checked={sendRDDNToSpecialist}
                    onChange={() => {
                      setSendRDDNToSpecialist(!sendRDDNToSpecialist);
                    }}
                    label={{
                      children: (
                        <>
                          Send Report Due Date Notice to Specialist{' '}
                          {api.isAdminOrMagStaffOrAccounting() &&
                            renderPopup('Completing report reminder will be sent to specialist (MAIL-12, DIRECT-12)')}
                        </>
                      ),
                    }}
                  />
                </Form.Field>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Form.Group grouped>
                  <label>Send Non-Attendance Notice to:</label>
                  <Form.Field>
                    <Checkbox
                      label={emailLabel('Solicitor', solicitorEmail)}
                      disabled={solicitorEmail === null}
                      checked={sendReportSolicitor}
                      onChange={() => setSendReportSolicitor(!sendReportSolicitor)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label={emailLabel('CM', CMEmail)}
                      disabled={CMEmail === null}
                      checked={sendReportCM}
                      onChange={() => setSendReportCM(!sendReportCM)}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label>Date of Non-Attendance:</label>
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    onChange={value => {
                      setNonAttendedDate(moment(value).format('YYYY-MM-DD'));
                    }}
                    value={moment(nonAttendedDate) || null}
                  />
                </Form.Field>
              </React.Fragment>
            )}
            <Form.Field>
              <label>Comments</label>
              <TextArea
                className="text-area"
                name="comments"
                value={attendanceComment}
                onChange={(_, { value }) => {
                  setAttendanceComment(value);
                }}
              />
            </Form.Field>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
}

export default observer(UpdateAttendace);
