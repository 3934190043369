import { Spin } from 'antd';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React from 'react';

import TypeOfClaimsModal from './TypeOfClaimsModal';
import TypeOfClaimsStore from './TypeOfClaimsStore';
import TypeOfClaimsTable from './TypeOfClaimsTable';
import TypeOfClaimsToolbar from './TypeOfClaimsToolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class TypeOfClaims extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/types-of-claims-2', true);
      return;
    }

    TypeOfClaimsStore.fetchFiles(true);
    ui.on('open(/view/types-of-claims-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    TypeOfClaimsStore.fetchFiles(true);
  }, 500);
  render() {
    const { loading, data, open } = TypeOfClaimsStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <TypeOfClaimsToolbar />
            <TypeOfClaimsTable />
            {open && <TypeOfClaimsModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TypeOfClaims;
