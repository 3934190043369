import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import uploadStore from './UploadStore';
import { Button, Dimmer, Loader, Form, Icon, TextArea } from 'semantic-ui-react';
import './../../DragDrop.scss';
import { action } from 'mobx';
import DragDropFile from './../../DragDropFile';
import { observer } from 'mobx-react';
import * as api from '@stores/api';

function UploadDictations(props) {
  const {
    open,
    modalParams,
    submitDocumentType,
    attachmentType_Dictations,
    toggleModal,
    submitFileHandler,
  } = uploadStore;
  const { serviceType, id } = modalParams;
  const [uploadFile, setUploadFile] = useState([]);
  const [shareLink, setShareLink] = useState('');

  const title = () => {
    return 'Upload Dictations: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant;
  };

  useEffect(() => {
    uploadStore.fetchInfoEmail(modalParams.id);
  }, [modalParams]);

  const onSubmitFile = action(() => {
    uploadStore.loading = true;
    if (!uploadFile || uploadFile.length <= 0) {
      return;
    }

    var uploadAttachment = [];
    uploadFile.forEach(element => {
      uploadAttachment.push({
        [`${serviceType}Id`]: id,
        FileName: element.name,
        Title: element.title,
        ReportType: element.reportType,
        Type: element.reportType,
        NumberOfPages: element.numberOfPages,
      });
    });
    const uploadData = {
      [`${serviceType}Id`]: id,
      ReportType: attachmentType_Dictations[serviceType],
      [`${serviceType}Attachment`]: uploadAttachment,
      Otherlink: shareLink,
    };
    submitFileHandler(submitDocumentType[serviceType], uploadData, props.refetchTable);
  });

  const onUploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element, index) {
        element.reportType = reportType;
        element.title = uploadStore.ReplaceFileNameUpload(
          serviceType,
          uploadStore.emailInfo.CaseNo,
          uploadStore.emailInfo.ClaimantFullName,
          uploadStore.emailInfo.DoctorTitle,
          uploadStore.emailInfo.DoctorName,
          uploadStore.emailInfo.DateRequest,
          uploadStore.emailInfo.AssessmentMethod,
          uploadStore.emailInfo.ClinicName,
          index,
        );
      });
      const data = [...uploadFile, ...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      setUploadFile(data);
    }
  };

  const onDeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      const data = [...uploadFile].filter(x => x.name !== deletedFiles.name);
      setUploadFile(data);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={600}
      className="custom-modal"
      title={title()}
      footer={
        <React.Fragment>
          {!api.isOnlySearch() && <Button
            color="blue"
            disabled={!uploadFile || uploadFile.length === 0}
            onClick={onSubmitFile}
            loading={uploadStore.loading}
          >
            <Icon name="upload" /> Upload
          </Button>}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <Dimmer active={uploadStore.loading}>
        <Loader />
      </Dimmer>
      <Form>
        <Form.Field>
          <label>Share Download Links for Dictation Files</label>
          <TextArea
            placeholder="Copy download links here. One line for each link."
            className="text-area"
            name="shareLink"
            value={shareLink}
            onChange={(_, { value }) => setShareLink(value)}
          />
        </Form.Field>
      </Form>

      <div style={{ marginTop: '10px' }}>
        <DragDropFile
          multiple={true}
          disableDragDrop={false}
          onUploadFile={onUploadFile}
          onDeleteFile={onDeleteFile}
          type={attachmentType_Dictations[serviceType]}
          title="Dictation Files"
          maxSize="50"
        />
        <hr></hr>
      </div>
    </Modal>
  );
}

export default observer(UploadDictations);
