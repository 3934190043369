import { Checkbox, DatePicker, Divider, Form, Input, notification, Select, Collapse, Menu, Dropdown, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Popup, Button } from 'semantic-ui-react';
import Upload from './FieldUpload';
import {
  resendPaperwork,
  resendPaperworkReadiness,
  reSendUrgentReportRequest,
  sendICareCompletionRequest,
} from './service';
import store from './store';
import * as api from '@stores/api';
import moment from 'moment';
import { CONSTANTS } from './type';
import customFetch from '@utils/customFetch';
import { action, toJS } from 'mobx';
import { Header } from '../../shared/Collapse/Panel';
import { renderDate, commonInlineStyles } from '../Shared';
import AudioTranscript from '../../shared/AudioTranscript';
var momentBusinessDays = require('moment-business-days');

const styles = {
  marginLeftHighlightInline: {
    ...commonInlineStyles.highlightInline,
    marginLeft: 25,
  },
  minHeight40: {
    minHeight: '40px',
    lineHeight: '40px',
  },
  requiredColor: { color: 'red' },
  minHeightAuto: { minHeight: 'auto' },
};

@observer
class CaseDocumentsForm extends React.Component {
  handleAfterUploadFinalReport = () => {
    const itemModel = toJS(store.fileReviewInfo);
    if (!itemModel.ReportCompleted) {
      store.toggleModalConfirm(true, {
        modalType: 'confirm',
        message: 'Do you want to send report to CMs?',
        onOk: () => {
          store.handleChangeValue('ReportCompleted', true);
          if (store.fileReviewInfo.F_RDCCaseManagerId) {
            store.handleChangeValue('IsAllowSolicitorViewReport', true);
          }
          if (store.fileReviewInfo.S_RDCCaseManagerId) {
            store.handleChangeValue('IsAllowCMViewReport', true);
          }
          //store.AllowCMViewReportChanged(true);
        },
      });
    }
  };

  onclickResendSentDateToTypist = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend dictation files, report templates and previous reports to the typist?',
      onOk: () => {
        return customFetch('/FileReview/ResendNotifyTypistAvailableDictationFiles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.fileReviewInfo.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeValue('SentDateToTypist', moment(response.ResendDatetime));
            store.showNotification('Success', 'Resend report', 'success');
          } else {
            store.showNotification('Error', 'Resend report', 'error');
          }
        });
      },
    });
  };

  handleAfterUploadDictation = () => {
    const itemModel = toJS(store.fileReviewInfo);
    store.handleChangeValue('DictationOption', 0);
    if (itemModel.DictationReceived === false) {
      if (api.isDoctor()) {
        store.handleChangeValue('DictationReceived', true);
      } else {
        // store.toggleModalConfirm(true, {
        //   message: 'Do you want to mark the case as Dictation Received?',
        //   onOk: () => {

        //   },
        // });
        store.handleChangeValue('DictationReceived', true);
      }
    }
    if (api.isDoctor()) {
      store.toggleModalConfirm(true, {
        message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
        hideWarningIcon: true,
      });
    }
  };

  onClickResendReportDraft = () => {
    const attachment = store.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 3);
    if (!attachment.length) {
      store.showNotification('Error', 'You must attach report draft to send to Specialist.');
      return;
    }
    store.toggleModalConfirm(true, {
      message: 'You must attach report draft to send to Specialist.',
      onOk: () => {
        return customFetch('/FileReview/ResendEmailDraftReportToDoctor', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: store.fileReviewInfo.Id,
            emailReportDraftToDoctor: store.fileReviewInfo.EmailReportDraftToDoctor,
            sendToBE: store.fileReviewInfo.EmailReportDraftToDoctorBE,
            sendToPE: store.fileReviewInfo.EmailReportDraftToDoctorPE,
          }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeValue('EmailReportDraftToDoctorDateSent', moment());
            store.showNotification('Success', 'Resend report draft', 'success');
          } else {
            store.showNotification('Error', 'Resend report draft!', 'error');
          }
        });
      },
    });
  };

  handleSearch = key =>
    action(value => {
      store[key] = value;
    });

  handleScroll = key =>
    action(event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
        store[key] += 20;
      }
    });

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.fileReviewInfo);
      if (fieldName === 'IsInvoiceUrgentReport') {
        if (value) {
          store.toggleModalConfirm(true, {
            message: 'Specialist may charge a higher rate for urgent reports. Do you want to continue?',
            onOk: () => this.handleUrgentReport(fieldName),
          });
          return;
        }
      }
      // if(fieldName === 'IsInvoiceUrgentReport'){
      //   if(value){
      //     store.toggleModalConfirm(true, {
      //       modalType: 'confirm',
      //       message: 'Specialist may charge a higher rate for urgent reports. Do you want to continue?',
      //       onOk: () => this.handleUrgentReport(fieldName),
      //     })();
      //   }
      // }
      return (store.fileReviewInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  @action handleUrgentReport = fieldName => {
    const itemModel = toJS(store.fileReviewInfo);
    store.fileReviewInfo = {
      ...itemModel,
      [fieldName]: true,
    };
  };

  @action handleChangeFieldUrgentReportType = fieldName =>
    action(event => {
      const itemModel = toJS(store.fileReviewInfo);
      const value = event ? (event.target ? event.target.value : event) : event;
      if (fieldName === 'UrgentReportType' && value === 1) {
        var dfCompletionDate = !!itemModel.PaperworkByOtherMethodDate
          ? momentBusinessDays(itemModel.PaperworkByOtherMethodDate.clone()).businessAdd(2)
          : null;
        store.fileReviewInfo = {
          ...itemModel,
          [fieldName]: value,
          UrgentReportReportCompletionDate: dfCompletionDate,
        };
      } else {
        store.fileReviewInfo = {
          ...itemModel,
          [fieldName]: value,
        };
      }
    });

  @action handleChangeDatePicker = (fieldName, value) => {
    const itemModel = toJS(store.fileReviewInfo);
    return (store.fileReviewInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  handleNotifyUrgentReport = type => {
    const itemModel = toJS(store.fileReviewInfo);
    reSendUrgentReportRequest('FR', itemModel.Id, type).then(resp => {
      if (resp.status === 'success') {
        if (type === 1) {
          store.handleChangeValue('NotifyUrgentReportToStaffDate', moment());
          store.handleChangeValue('NotifyUrgentReportToStaffBy', resp.resendBy);
        } else if (type === 2) {
          store.handleChangeValue('NotifyUrgentReportToSpecialistDate', moment());
          store.handleChangeValue('NotifyUrgentReportToSpecialistBy', resp.resendBy);
        } else if (type === 3) {
          store.handleChangeValue('NotifyUrgentReportToCMDate', moment());
          store.handleChangeValue('NotifyUrgentReportToCMBy', resp.resendBy);
        }
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Resend Urgent Report Request',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Resend Urgent Report Request',
        });
      }
    });
  };

  render() {
    const { fileReviewInfo, setFieldValue, toggleCheckbox, totalPages } = store;

    return (
      <Form name="case-documents" className="grid-style">
        <Collapse bordered={false} defaultActiveKey={api.isTypist() ? ['2'] : []}>
          <Collapse.Panel
            key="1"
            header={
              <Header
                title="Paperwork"
                isChecked={fileReviewInfo.IsPaperworkReceived}
                text={api.isDoctor() ? 'Paperwork Sent' : 'Sent to Specialist'}
                date={fileReviewInfo.PaperworkByOtherMethodDate}
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
              <Checkbox
                checked={fileReviewInfo.IsPaperworkReceived}
                onChange={toggleCheckbox('IsPaperworkReceived')}
                className="checkbox-session"
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  (fileReviewInfo.WifroIlars && !fileReviewInfo.IROFundingApproved)
                }
              >
                {api.isDoctor() ? 'Paperwork Sent' : 'Paperwork Sent to Specialist'}
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
              <Form.Item
                label={
                  <React.Fragment>
                    PIC 2
                    <button className="field-actions" onClick={() => store.openModalHistory('PICsentToProvider')}>
                      Recent Changes
                    </button>
                  </React.Fragment>
                }
              >
                <Select
                  showSearch
                  size="large"
                  optionFilterProp="children"
                  value={fileReviewInfo.PICsentToProvider || undefined}
                  onChange={setFieldValue('PICsentToProvider')}
                  disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                >
                  {store.staffs.map(({ Id, FullName }) => (
                    <Select.Option key={Id} value={Id}>
                      {FullName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() &&
                <Form.Item label="Paperwork Received Date">
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    value={fileReviewInfo.PaperworkReceivedDate}
                    disabled={store.isLockedPaperword || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    onChange={setFieldValue('PaperworkReceivedDate')}
                  />
                </Form.Item>
            }
            <Form.Item label="Paperwork Notes" className="fluid-field">
              <Input.TextArea
                rows={3}
                value={fileReviewInfo.SpecialistPaperworkNotes}
                disabled={true}
              />
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item
                  label="Paperwork Sent Date"
                  required={fileReviewInfo.IsPaperworkReceived}
                  validateStatus={
                    fileReviewInfo.IsPaperworkReceived && !fileReviewInfo.PaperworkByOtherMethodDate ? 'error' : null
                  }
                  help={
                    fileReviewInfo.IsPaperworkReceived && !fileReviewInfo.PaperworkByOtherMethodDate
                      ? 'This field is required'
                      : ''
                  }
                >
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    value={fileReviewInfo.PaperworkByOtherMethodDate}
                    disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    onChange={setFieldValue('PaperworkByOtherMethodDate')}
                  />
                </Form.Item>
              </>
            )}
            
            {!api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label={api.isCaseManager() ? 'Paperwork Uploaded Date' : 'Paperwork Sent Date'}>
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={fileReviewInfo.PaperworkReceivedDate}
                  disabled={store.isLockedPaperword || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  onChange={setFieldValue('PaperworkReceivedDate')}
                />
              </Form.Item>
            )}
            <Form.Item
              label={
                <React.Fragment>
                  Standard Completion Date{' '}
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="Report completion reminder (MAIL-89) will be sent 3 days before the expected due date"
                    position="top center"
                  />
                </React.Fragment>
              }
            >
              <DatePicker
                size="large"
                disabled={
                  ((fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None) &&
                    !!fileReviewInfo.Id) ||
                  !fileReviewInfo.IsPaperworkReceived ||
                  api.isCaseManager()
                }
                format="DD/MM/YYYY"
                value={fileReviewInfo.RequestExpectedCompletionDate}
                onChange={setFieldValue('RequestExpectedCompletionDate')}
              />
            </Form.Item>
            {!(api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id) && <div className="fluid-field" />}
            <Form.Item
              label={
                <span>
                  {api.isDoctor() ? 'Letter of Instruction (LOI)' : 'Attach Letter of Instruction (LOI)'}: (Only 1
                  document allowed)
                </span>
              }
              className="add-file-container posstion-IsTypist-label"
            >
              <Upload
                type={1}
                option={{
                  maxFileSize: '100MB',
                  allowedExtensions: 'application/pdf',
                }}
                functionCallback={store.functionCallbackAfterUploadPaperwork}
                disabledDelete={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  api.isTypist() ||
                  (api.isCaseManager() &&
                    (fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                disabled={
                  !(
                    !api.isDoctor() &&
                    !(!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) &&
                    fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_None &&
                    !api.isTypist()
                  ) ||
                  (api.isCaseManager() &&
                    (fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                uploadTitle={'1 PDF file only'}
                disableDragDrop={true}
              />
            </Form.Item>
            <Form.Item label="Special Request">
              <React.Fragment>
                <Checkbox
                  style={{ marginTop: '8px' }}
                  checked={fileReviewInfo.IsLongPaperwork}
                  onChange={toggleCheckbox('IsLongPaperwork')}
                  disabled={api.isCaseManager() || api.isTypist()}
                >
                  {' > '} 20 pages
                </Checkbox>
                <div className={'ant-checkbox-wrapper'}>Total pages: {totalPages}</div>
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={fileReviewInfo.IsInvoiceUrgentReport}
                  onChange={this.handleChangeCheckBox('IsInvoiceUrgentReport')}
                  disabled={api.isTypist()}
                >
                  Urgent Report Completion
                </Checkbox>
                <br/>
                {fileReviewInfo.IsInvoiceUrgentReport && (
                  <Radio.Group
                    style={{ marginTop: '13px' }}
                    disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                    value={fileReviewInfo.UrgentReportType}
                    onChange={this.handleChangeFieldUrgentReportType('UrgentReportType')}
                  >
                    <Radio value={1} style={styles.radioBlockBottom}>
                      VWA Report{' '}
                      <Popup
                        trigger={<Icon name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports between 2-7 business days of appointment date, additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                    <Radio value={2} style={styles.radioBlockBottom}>
                      Other Report
                      <Popup
                        trigger={<Icon name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports within 2-7 business days of paperwork sent sate,no  additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                  </Radio.Group>
                )}
                {fileReviewInfo.IsInvoiceUrgentReport && <br />}
                {fileReviewInfo.IsInvoiceUrgentReport && (
                  <Form.Item label="Requested Completion Date Before" style={{ marginTop: '13px', marginBottom: '0' }}>
                    <DatePicker
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      format="DD/MM/YYYY"
                      value={renderDate(fileReviewInfo.UrgentReportReportCompletionDate)}
                      onChange={value => this.handleChangeDatePicker('UrgentReportReportCompletionDate', value)}
                    />
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={fileReviewInfo.UrgentReportConfirmedByCM}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      onChange={this.handleChangeCheckBox('UrgentReportConfirmedByCM')}
                    >
                      Request Authorised by CM
                    </Checkbox>
                    {!!fileReviewInfo.UrgentReportConfirmedByCMDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {fileReviewInfo.UrgentReportConfirmedByCMDate
                            ? renderDate(fileReviewInfo.UrgentReportConfirmedByCMDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {fileReviewInfo.UrgentReportConfirmedByCMBy
                            ? ` - By ${fileReviewInfo.UrgentReportConfirmedByCMBy}`
                            : ''}
                        </span>
                      </>
                    )}
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={fileReviewInfo.RequestAcceptedBySpecialist}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      onChange={this.handleChangeCheckBox('RequestAcceptedBySpecialist')}
                    >
                      Request Accepted by Specialist{' '}
                      {/* <Popup
                          trigger={<Icon name="info circle" />}
                          content="Urgent Report Request will be sent (Mail-163)"
                          position="top center"
                        /> */}
                    </Checkbox>
                    {!!fileReviewInfo.RequestAcceptedBySpecialistDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {fileReviewInfo.RequestAcceptedBySpecialistDate
                            ? renderDate(fileReviewInfo.RequestAcceptedBySpecialistDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {fileReviewInfo.RequestAcceptedBySpecialistBy
                            ? ` - By ${fileReviewInfo.RequestAcceptedBySpecialistBy}`
                            : ''}
                        </span>
                      </>
                    )}
                  </Form.Item>
                )}
                {false && fileReviewInfo.IsInvoiceUrgentReport && (
                  <Form.Item
                    label="Attached CM Authorisation"
                    className="add-file-container add-files-urgent-report"
                    style={{ marginTop: '13px', marginBottom: '0' }}
                  >
                    <Upload
                      type={20}
                      multiple
                      disabledDelete={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disableDragDrop={true}
                    />
                  </Form.Item>
                )}
                {false && fileReviewInfo.IsInvoiceUrgentReport && (
                  <div>
                    <div>
                      <b>Send Urgent Report Request to{' : '}</b>
                      <Dropdown
                        disabled={
                          !fileReviewInfo.NotifyUrgentReportToStaffDate &&
                          !fileReviewInfo.NotifyUrgentReportToSpecialistDate &&
                          !fileReviewInfo.NotifyUrgentReportToCMDate
                        }
                        overlay={
                          <Menu>
                            <Menu.Item
                              disabled={!fileReviewInfo.NotifyUrgentReportToStaffDate}
                              onClick={() => this.handleNotifyUrgentReport(1)}
                            >
                              MLP Staff{' '}
                            </Menu.Item>
                            <Menu.Item
                              disabled={!fileReviewInfo.NotifyUrgentReportToCMDate}
                              onClick={() => this.handleNotifyUrgentReport(3)}
                            >
                              Case Manager{' '}
                            </Menu.Item>
                            <Menu.Item
                              disabled={!fileReviewInfo.NotifyUrgentReportToSpecialistDate}
                              onClick={() => this.handleNotifyUrgentReport(2)}
                            >
                              Specialist{' '}
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button className="blue">Resend Urgent Report Request</Button>
                      </Dropdown>
                    </div>
                    <Checkbox
                      style={{ marginTop: '8px' }}
                      checked={fileReviewInfo.NotifyUrgentReportToStaff}
                      disabled={
                        !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                        !!fileReviewInfo.NotifyUrgentReportToStaffDate
                      }
                      onChange={this.handleChangeCheckBox('NotifyUrgentReportToStaff')}
                    >
                      MLP Staff{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Urgent Report Request will be sent (Mail-163)"
                        position="top center"
                      />
                    </Checkbox>
                    {!!fileReviewInfo.NotifyUrgentReportToStaffDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {fileReviewInfo.NotifyUrgentReportToStaffDate
                            ? renderDate(fileReviewInfo.NotifyUrgentReportToStaffDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {fileReviewInfo.NotifyUrgentReportToStaffBy
                            ? ` - By ${fileReviewInfo.NotifyUrgentReportToStaffBy}`
                            : ''}
                        </span>
                      </>
                    )}
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={fileReviewInfo.NotifyUrgentReportToCM}
                      disabled={
                        !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                        !!fileReviewInfo.NotifyUrgentReportToCMDate
                      }
                      onChange={this.handleChangeCheckBox('NotifyUrgentReportToCM')}
                    >
                      Case Manager{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Urgent Report Request will be sent (Mail-165)"
                        position="top center"
                      />
                    </Checkbox>
                    {!!fileReviewInfo.NotifyUrgentReportToCMDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {fileReviewInfo.NotifyUrgentReportToCMDate
                            ? renderDate(fileReviewInfo.NotifyUrgentReportToCMDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {fileReviewInfo.NotifyUrgentReportToCMBy
                            ? ` - By ${fileReviewInfo.NotifyUrgentReportToCMBy}`
                            : ''}
                        </span>
                      </>
                    )}
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={fileReviewInfo.NotifyUrgentReportToSpecialist}
                      disabled={
                        !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                        !!fileReviewInfo.NotifyUrgentReportToSpecialistDate
                      }
                      onChange={this.handleChangeCheckBox('NotifyUrgentReportToSpecialist')}
                    >
                      Specialist{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Urgent Report Request will be sent (Mail-164)"
                        position="top center"
                      />
                    </Checkbox>
                    {!!fileReviewInfo.NotifyUrgentReportToSpecialistDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {fileReviewInfo.NotifyUrgentReportToSpecialistDate
                            ? renderDate(fileReviewInfo.NotifyUrgentReportToSpecialistDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {fileReviewInfo.NotifyUrgentReportToSpecialistBy
                            ? ` - By ${fileReviewInfo.NotifyUrgentReportToSpecialistBy}`
                            : ''}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </React.Fragment>
            </Form.Item>
            
            {!api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item
                label="Paperwork Sent Date"
                required={fileReviewInfo.IsPaperworkReceived}
                validateStatus={
                  fileReviewInfo.IsPaperworkReceived && !fileReviewInfo.PaperworkByOtherMethodDate ? 'error' : null
                }
                help={
                  fileReviewInfo.IsPaperworkReceived && !fileReviewInfo.PaperworkByOtherMethodDate
                    ? 'This field is required'
                    : ''
                }
              >
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={fileReviewInfo.PaperworkByOtherMethodDate}
                  disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  onChange={setFieldValue('PaperworkByOtherMethodDate')}
                />
              </Form.Item>
            )}
            <Form.Item
              label={
                <>
                  Or Share Download Link
                  <button
                    className={'field-actions'}
                    disabled={
                      !store.validateField(fileReviewInfo.PaperworkExLink, 'url') || !fileReviewInfo.PaperworkExLink
                    }
                    onClick={() => {
                      window.open(fileReviewInfo.PaperworkExLink, '_blank');
                    }}
                  >
                    Open Link
                  </button>
                </>
              }
              validateStatus={!store.validateField(fileReviewInfo.PaperworkExLink, 'url') ? 'error' : null}
              help={!store.validateField(fileReviewInfo.PaperworkExLink, 'url') ? 'Download link is not valid' : ''}
              className="fluid-field"
            >
              <Input.TextArea
                rows={3}
                value={fileReviewInfo.PaperworkExLink}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  api.isTypist()
                }
                onChange={setFieldValue('PaperworkExLink')}
              />
            </Form.Item>
            <Form.Item label="Share Password 1" className="fluid-field">
              <Input
                size="large"
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.SharePassword1) ||
                  api.isTypist()
                }
                value={fileReviewInfo.SharePassword1}
                onChange={setFieldValue('SharePassword1')}
              />
            </Form.Item>
            <Form.Item
              label={api.isDoctor() ? 'Original Reports' : 'Attached Other Documents'}
              className="add-file-container"
            >
              <Upload
                multiple
                type={6}
                option={{
                  maxFileSize: '100MB',
                }}
                functionCallback={store.functionCallbackAfterUploadPaperwork}
                disabled={
                  !(
                    !api.isDoctor() &&
                    !(!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) &&
                    fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_None &&
                    !api.isTypist()
                  ) ||
                  (api.isCaseManager() &&
                    (fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                disabledDelete={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  api.isTypist() ||
                  (api.isCaseManager() &&
                    (fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Or Share Download Link
                  <button
                    className={'field-actions'}
                    disabled={
                      !store.validateField(fileReviewInfo.PaperworkOtherExLink, 'url') ||
                      !fileReviewInfo.PaperworkOtherExLink
                    }
                    onClick={() => {
                      window.open(fileReviewInfo.PaperworkOtherExLink, '_blank');
                    }}
                  >
                    Open Link
                  </button>
                </>
              }
              className="fluid-field"
              validateStatus={!store.validateField(fileReviewInfo.PaperworkOtherExLink, 'url') ? 'error' : null}
              help={
                !store.validateField(fileReviewInfo.PaperworkOtherExLink, 'url') ? 'Download link is not valid' : ''
              }
            >
              <Input.TextArea
                rows={3}
                value={fileReviewInfo.PaperworkOtherExLink}
                onChange={setFieldValue('PaperworkOtherExLink')}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  api.isTypist()
                }
              />
            </Form.Item>
            <Form.Item label="Share Password 2" className="fluid-field">
              <Input
                size="large"
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  api.isTypist()
                }
                value={fileReviewInfo.SharePassword2}
                onChange={setFieldValue('SharePassword2')}
              />
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Comments" className="fluid-field">
                <Input.TextArea
                  rows={3}
                  value={fileReviewInfo.BookingComment}
                  disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  onChange={setFieldValue('BookingComment')}
                />
              </Form.Item>
            )}

            <div className="checkbox-field">
              <Checkbox
                checked={fileReviewInfo.NotifyPaperworkReadinessToStaff}
                onChange={toggleCheckbox('NotifyPaperworkReadinessToStaff')}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  !!fileReviewInfo.SentDateNotifyPaperworkReadinessToStaff ||
                  api.isTypist() ||
                  (api.isCaseManager() &&
                    (fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      fileReviewInfo.CaseStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
              >
                <h4 style={commonInlineStyles.inlineBlock}>Send Paperwork Readiness to MLP Staff</h4>
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="A notice will be sent to staff to review paperwork before sending to specialist (MAIL-PAPERWORK-READINESS)"
                  position="top center"
                  wide="very"
                />
              </Checkbox>
              {fileReviewInfo.SentDateNotifyPaperworkReadinessToStaff && (
                <>
                  <span style={commonInlineStyles.highlightInline}>
                    {fileReviewInfo.SentDateNotifyPaperworkReadinessToStaff
                      ? renderDate(fileReviewInfo.SentDateNotifyPaperworkReadinessToStaff, false, true)
                      : ''}
                    {fileReviewInfo.SentByNotifyPaperworkReadinessToStaff
                      ? ' - By ' + store.renderStaffName(fileReviewInfo.SentByNotifyPaperworkReadinessToStaff)
                      : ''}
                  </span>
                  {fileReviewInfo.SentDateNotifyPaperworkReadinessToStaff && (
                    <Button
                      className="blue"
                      disabled={
                        !fileReviewInfo.NotifyPaperworkReadinessToStaff ||
                        api.isDoctor() ||
                        (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                        api.isTypist() ||
                        api.isCaseManager()
                      }
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to resend Paperwork readiness to Staff?',
                          onOk: () => {
                            resendPaperworkReadiness(fileReviewInfo.Id)
                              .then(response => {
                                if (response.status === 'success') {
                                  notification.destroy();
                                  notification.success({
                                    description: 'Resend paperwork readiness',
                                    message: 'Success',
                                  });
                                  store.handleChangeValue('SentDateNotifyPaperworkReadinessToStaff', moment());
                                  store.handleChangeValue('SentByNotifyPaperworkReadinessToStaff', response.sentBy);
                                } else {
                                  notification.destroy();
                                  notification.Error({
                                    description: 'Resend paperwork readiness',
                                    message: 'Error',
                                  });
                                }
                              })
                              .catch(() => {});
                          },
                        });
                      }}
                    >
                      Resend Paperwork Readiness
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="ant-row ant-form-item fluid-field">
              <React.Fragment>
                <Checkbox
                  checked={fileReviewInfo.IcareClaim}
                  onChange={toggleCheckbox('IcareClaim')}
                  disabled={api.isDoctor() || api.isTypist()}
                >
                  <h4 style={commonInlineStyles.inlineBlock}>
                    This is iCare Claim{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Send iCare reminders to Staff (MAIL-169)"
                      position="top center"
                      wide="very"
                    />
                  </h4>
                  {fileReviewInfo.IcareClaim && fileReviewInfo.IcareClaimDate && (
                    <span style={styles.highlightInline}>
                      {fileReviewInfo.IcareClaimDate
                        ? ' ' + renderDate(fileReviewInfo.IcareClaimDate, false, true)
                        : ''}
                      {' - By ' + store.renderStaffName(fileReviewInfo.IcareClaimBy)}
                    </span>
                  )}
                </Checkbox>
                {api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.IcareClaim && (
                  <Button
                    className="blue"
                    onClick={() => {
                      store.toggleModalConfirm(true, {
                        message: 'Do you want to send iCare report reminder to Staff?',
                        onOk: () => {
                          sendICareCompletionRequest(fileReviewInfo.Id)
                            .then(response => {
                              if (response.status === 'success') {
                                notification.destroy();
                                notification.success({
                                  description: 'Sent iCare report reminder successfully!',
                                  message: 'Success',
                                });
                              }
                            })
                            .catch(() => {});
                        },
                      });
                    }}
                  >
                    Send iCare Reminder To Staff
                  </Button>
                )}
              </React.Fragment>
            </div>
            {fileReviewInfo.IcareClaim && (
              <>
                <Form.Item label="Insurer">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.InsurerId || undefined}
                    onChange={setFieldValue('InsurerId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.listCompanyInsurer.map(({ Id, Name, StateName }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Reason for Referral">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.ReasonReferralId || undefined}
                    onChange={setFieldValue('ReasonReferralId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.listReasonReferral.map(({ Id, Label }) => (
                      <Select.Option key={Id} value={Id}>
                        {Label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Service Type">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.IcareServiceTypeId || undefined}
                    onChange={setFieldValue('IcareServiceTypeId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.icareLookupList &&
                      store.icareLookupList.itemList &&
                      store.icareLookupList.itemList.length &&
                      store.icareLookupList.itemList[7] &&
                      store.icareLookupList.itemList[7].length > 0 &&
                      store.icareLookupList.itemList[7].map(i => (
                        <Select.Option key={i.Id} value={i.Id}>
                          {i.Label}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item></Form.Item>
              </>
            )}
            <div className="checkbox-field">
              <Checkbox
                checked={fileReviewInfo.AllowDoctorToViewPaperwork}
                onChange={toggleCheckbox('AllowDoctorToViewPaperwork')}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  !!fileReviewInfo.SentDateDoctorViewPaperwork ||
                  api.isTypist() ||
                  (fileReviewInfo.WifroIlars && !fileReviewInfo.IROFundingApproved) ||
                  api.isCaseManager()
                }
              >
                <h4 style={commonInlineStyles.inlineBlock}>Send Paperwork to Specialist</h4>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case documents will be sent to specialist (Message-73, Message-73-Resend)"
                    position="top center"
                  />
                )}
              </Checkbox>
              {fileReviewInfo.SentDateDoctorViewPaperwork && (
                <>
                  <span style={commonInlineStyles.highlightInline}>
                    {fileReviewInfo.SentDateDoctorViewPaperwork
                      ? renderDate(fileReviewInfo.SentDateDoctorViewPaperwork, false, true)
                      : ''}
                    {fileReviewInfo.DoctorViewPaperworkByUser
                      ? ' - By ' + store.renderStaffName(fileReviewInfo.DoctorViewPaperworkByUser)
                      : ''}
                  </span>
                  {fileReviewInfo.SentDateDoctorViewPaperwork && (
                    <Button
                      className="blue"
                      disabled={
                        !fileReviewInfo.AllowDoctorToViewPaperwork ||
                        api.isDoctor() ||
                        (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.ReportCompleted) ||
                        api.isTypist() ||
                        api.isCaseManager()
                      }
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to resend Paperwork to Specialist?',
                          onOk: () => {
                            resendPaperwork(fileReviewInfo.Id)
                              .then(response => {
                                if (response.status === 'success') {
                                  notification.destroy();
                                  notification.success({
                                    description: 'Resend paperwork',
                                    message: 'Success',
                                  });
                                  store.handleChangeValue('SentDateDoctorViewPaperwork', moment());
                                  store.handleChangeValue('DoctorViewPaperworkByUser', response.sentBy);
                                } else {
                                  notification.destroy();
                                  notification.Error({
                                    description: 'Resend paperwork',
                                    message: 'Error',
                                  });
                                }
                              })
                              .catch(() => {});
                          },
                        });
                      }}
                    >
                      Resend Paperwork
                    </Button>
                  )}
                </>
              )}
            </div>
            <Divider className="fluid-field" />
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item label="Send Paperwork by Other Methods">
                  <Checkbox.Group
                    onChange={setFieldValue('SelectedPaperworkToDoctorBies')}
                    value={[...fileReviewInfo.SelectedPaperworkToDoctorBies]}
                  >
                    {store.sendPaperMethods.map(({ Id, Label }) => (
                      <Checkbox key={Id} value={Id}>
                        {Label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
                <div className="checkbox-field">
                  <Checkbox
                    checked={fileReviewInfo.DisableReportReminder}
                    onChange={toggleCheckbox('DisableReportReminder')}
                    disabled={fileReviewInfo.isLocked || !api.isAdminOrMagStaffOrAccounting()}
                  >
                    Disable Report Reminders
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Stop sending report completion reminders (Mail-86, Mail-87, Mail-88, Mail-89, Mail-90)"
                      position="top center"
                    />
                  </Checkbox>
                </div>
              </>
            )}
          </Collapse.Panel>
          {!api.isCaseManager() && [
            <Collapse.Panel
              key="2"
              header={
                <Header
                  title="Dictation & Typist"
                  statuses={[
                    {
                      key: 'fr-dictation-received',
                      isChecked: fileReviewInfo.DictationReceived,
                      text: api.isDoctor() ? 'Dictations Sent' : 'Received from Specialist',
                      date: fileReviewInfo.DictationReceivedDate,
                      shouldFormatDate: true,
                    },
                    {
                      key: 'fr-dictation-sent-typist',
                      isChecked: fileReviewInfo.DictationSentToTypist,
                      text: 'Sent To Typist',
                      date: fileReviewInfo.SentDateToTypist,
                      shouldFormatDate: true,
                    },
                  ]}
                  isRequired={false}
                />
              }
            >
              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) && !api.isCaseManager() && (
                <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                  <Checkbox
                    className="checkbox-session"
                    checked={fileReviewInfo.DictationReceived}
                    onChange={toggleCheckbox('DictationReceived')}
                    disabled={
                      store.isLockDictation || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None || api.isDoctor()
                    }
                  >
                    {api.isDoctor() ? 'Dictations Sent to Operator' : 'Dictation Received from Specialist'}
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 3
                      <button className="field-actions" onClick={() => store.openModalHistory('PICcancelled')}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.PICDictationReceived || undefined}
                    onChange={setFieldValue('PICDictationReceived')}
                  >
                    {store.staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) &&
                !api.isCaseManager() &&
                api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item
                    label={api.isDoctor() ? 'Sent Date' : 'Dictation Received Date'}
                    required={fileReviewInfo.DictationReceived}
                    validateStatus={
                      fileReviewInfo.DictationReceived && !fileReviewInfo.DictationReceivedDate ? 'error' : null
                    }
                    help={
                      fileReviewInfo.DictationReceived && !fileReviewInfo.DictationReceivedDate
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={
                        fileReviewInfo.isLocked ||
                        fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                        !fileReviewInfo.DictationReceived
                      }
                      value={fileReviewInfo.DictationReceivedDate}
                      onChange={setFieldValue('DictationReceivedDate')}
                    />
                  </Form.Item>
                )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor() || api.isTypist()) && (
                <Form.Item label="Attached Dictations" className="add-file-container">
                  <Upload
                    multiple
                    type={2}
                    // disabled={store.isLockDictation || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    disabled={fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    functionCallback={this.handleAfterUploadDictation}
                    disabledDelete={store.isLockDictation || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  />
                </Form.Item>
              )}

              {api.isAdminOrMagStaffOrAccounting() && (
                <AudioTranscript
                  files={store.fileReviewInfo.FileReviewAttachment.filter(item => item.ReportType === 2)}
                  getAudioTranscription={store.getAudioTranscription}
                  isLoading={store.isLoadingAudioTranscribe}
                />
              )}

              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) &&
                !api.isCaseManager() &&
                !api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item
                    label={api.isDoctor() ? 'Sent Date' : 'Dictation Received Date'}
                    required={fileReviewInfo.DictationReceived}
                    validateStatus={
                      fileReviewInfo.DictationReceived && !fileReviewInfo.DictationReceivedDate ? 'error' : null
                    }
                    help={
                      fileReviewInfo.DictationReceived && !fileReviewInfo.DictationReceivedDate
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={
                        fileReviewInfo.isLocked ||
                        fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                        !fileReviewInfo.DictationReceived
                      }
                      value={fileReviewInfo.DictationReceivedDate}
                      onChange={setFieldValue('DictationReceivedDate')}
                    />
                  </Form.Item>
                )}
              {!((!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) || api.isDoctor) && (
                <div className="checkbox-field">
                  <Checkbox
                    checked={fileReviewInfo.AutoRemindReportDraftCompletionDeadlineToMagStaff}
                    disabled={fileReviewInfo.isLocked || !fileReviewInfo.DictationReceived}
                    onChange={toggleCheckbox('AutoRemindReportDraftCompletionDeadlineToMagStaff')}
                  >
                    Remind Report Draft completion deadline to staff
                  </Checkbox>
                </div>
              )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isTypist()) && (
                <Form.Item label="Or Share Download Link" className="fluid-field">
                  <Input.TextArea
                    rows={3}
                    value={fileReviewInfo.DictationOnedriveLinkFile}
                    onChange={setFieldValue('DictationOnedriveLinkFile')}
                    disabled={fileReviewInfo.isLocked}
                  />
                </Form.Item>
              )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isTypist()) && (
                <div className="checkbox-field fluid-field">
                  <Checkbox
                    checked={fileReviewInfo.SendConfirmationToSpecialist}
                    disabled={fileReviewInfo.isLocked}
                    onChange={toggleCheckbox('SendConfirmationToSpecialist')}
                  >
                    <h4 style={commonInlineStyles.inlineBlock}>Send Confirmation to Specialist</h4>{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Dictation receipt confirmation will be sent to specialist (MAIL-121)"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                  {fileReviewInfo.SendConfirmationToSpecialistDate && (
                    <>
                      <span style={commonInlineStyles.highlightInline}>
                        {fileReviewInfo.SendConfirmationToSpecialistDate
                          ? renderDate(fileReviewInfo.SendConfirmationToSpecialistDate, false, true)
                          : ''}
                        {fileReviewInfo.SendConfirmationToSpecialistDateByUser
                          ? ' - By ' + store.renderStaffName(fileReviewInfo.SendConfirmationToSpecialistDateByUser)
                          : ''}
                      </span>
                    </>
                  )}
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item label="Dictation Comments" className="fluid-field">
                  <Input.TextArea
                    rows={3}
                    value={fileReviewInfo.DictationComment}
                    onChange={setFieldValue('DictationComment')}
                    disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  />
                </Form.Item>
              )}
              {(api.isTypist() || (api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.DictationReceived)) && (
                <>
                  <Divider className="fluid-field" />
                  <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                    <Checkbox
                      checked={fileReviewInfo.DictationSentToTypist}
                      onChange={toggleCheckbox('DictationSentToTypist')}
                      className="checkbox-session"
                      disabled={fileReviewInfo.isLocked}
                    >
                      Dictation Sent To Typist
                    </Checkbox>
                  </div>
                  <Form.Item
                    label="Typist"
                    required={fileReviewInfo.DictationSentToTypist}
                    validateStatus={
                      fileReviewInfo.DictationSentToTypist && !fileReviewInfo.DictationTypistID ? 'error' : null
                    }
                    help={
                      fileReviewInfo.DictationSentToTypist && !fileReviewInfo.DictationTypistID
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      placeholder="Select a typist"
                      value={fileReviewInfo.DictationTypistID || undefined}
                      disabled={fileReviewInfo.isLocked && api.isCaseManager()}
                      onChange={setFieldValue('DictationTypistID')}
                    >
                      {store.typists.map(({ Id, FullName, Email }) => (
                        <Select.Option key={Id} value={Id}>
                          {Id} - {FullName} - {Email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="fluid-field" />
                  {(fileReviewInfo.DictationReceived || api.isDoctor() || api.isTypist()) && (
                    <>
                      <Form.Item label="Attached Dictation Instruction" className="add-file-container">
                        <Upload
                          multiple
                          type={11}
                          disabledDelete={fileReviewInfo.isLocked}
                          disabled={fileReviewInfo.isLocked}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          disabled={!fileReviewInfo.DictationReceived}
                          onClick={() => {
                            store.onCheckReportSampleAndTemplate();
                          }}
                          style={{ marginTop: '25px', width: '100%' }}
                        >
                          Attach Specialist's Report Templates & Samples
                        </Button>
                      </Form.Item>

                      <Form.Item label="Attached Report Templates" className="add-file-container">
                        <Upload
                          multiple
                          type={13}
                          disabledDelete={fileReviewInfo.isLocked}
                          disabled={fileReviewInfo.isLocked}
                        />
                      </Form.Item>
                      <Form.Item label="Attached Report Samples" className="add-file-container">
                        <Upload
                          multiple
                          type={12}
                          disabledDelete={fileReviewInfo.isLocked}
                          disabled={fileReviewInfo.isLocked}
                        />
                      </Form.Item>
                    </>
                  )}
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={fileReviewInfo.SentToTypist}
                      onChange={toggleCheckbox('SentToTypist')}
                      disabled={
                        fileReviewInfo.isLocked ||
                        (!fileReviewInfo.DictationSentToTypist && !fileReviewInfo.DictationTypistID) ||
                        fileReviewInfo.SentDateToTypist
                      }
                    >
                      <h4 style={commonInlineStyles.inlineBlock}>Send Files to Typist</h4>
                    </Checkbox>
                    {fileReviewInfo.SentDateToTypist && (
                      <>
                        <span style={commonInlineStyles.highlightInline}>
                          {fileReviewInfo.SentDateToTypist
                            ? renderDate(fileReviewInfo.SentDateToTypist, false, true)
                            : ''}
                          {fileReviewInfo.ToTypistByUser
                            ? ` - By ${store.renderStaffName(fileReviewInfo.ToTypistByUser)}`
                            : ''}
                        </span>
                        {!!fileReviewInfo.SentDateToTypist && (
                          <Button
                            className="blue"
                            style={commonInlineStyles.marginLeft10}
                            onClick={this.onclickResendSentDateToTypist}
                            disabled={!fileReviewInfo.SentDateToTypist}
                          >
                            Resend Dictation Files
                          </Button>
                        )}
                      </>
                    )}
                  </div>{' '}
                  {(fileReviewInfo.DictationReceived || api.isDoctor() || api.isTypist()) && (
                    <Form.Item label="Attached Typed Report" className="add-file-container">
                      <Upload
                        multiple
                        type={17}
                        disabledDelete={fileReviewInfo.isLocked}
                        disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isTypist()}
                      />
                    </Form.Item>
                  )}
                  <div className="fluid-field" />
                  <Form.Item label="Typist's transcribing time (hours)">
                    <Input
                      size="large"
                      value={fileReviewInfo.TypistTranscribingTime}
                      onChange={setFieldValue('TypistTranscribingTime')}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                    />
                  </Form.Item>
                  <Form.Item label="Typist's submitting date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={fileReviewInfo.TypistSubmittingDate}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                      onChange={setFieldValue('TypistSubmittingDate')}
                    />
                  </Form.Item>
                  <Form.Item label="Approved by">
                    <Select
                      showSearch
                      size="large"
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      optionFilterProp="children"
                      placeholder="Select a staff"
                      value={fileReviewInfo.TypistApproverdBy || undefined}
                      onChange={setFieldValue('TypistApproverdBy')}
                    >
                      {store.staffs.map(({ Id, FullName }) => (
                        <Select.Option key={Id} value={Id}>
                          {FullName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Approved on">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      value={fileReviewInfo.TypistApproverdOn}
                      onChange={setFieldValue('TypistApproverdOn')}
                    />
                  </Form.Item>
                  <Divider className="fluid-field" />
                </>
              )}
            </Collapse.Panel>,
            <Collapse.Panel
              key="3"
              header={
                <Header
                  title="Report Draft to Specialist"
                  isChecked={fileReviewInfo.ReportDraftReceived}
                  text="Sent to Specialist"
                  date={
                    api.isTypist() || api.isDoctor()
                      ? fileReviewInfo.ReportDraftReceivedDate
                      : fileReviewInfo.ReportDraftSentDate
                  }
                  isRequired={false}
                />
              }
            >
              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) && !api.isCaseManager() && (
                <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                  <Checkbox
                    className="checkbox-session"
                    checked={fileReviewInfo.ReportDraftReceived}
                    onChange={toggleCheckbox('ReportDraftReceived')}
                    disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  >
                    Report Draft Sent to Specialist
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 4
                      <button className="field-actions" onClick={() => store.openModalHistory('PICcancelled')}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.PICReportDraftReceived || undefined}
                    onChange={setFieldValue('PICReportDraftReceived')}
                  >
                    {store.staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) &&
                api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item label="Report Draft Received Date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={fileReviewInfo.ReportDraftReceivedDate}
                      onChange={setFieldValue('ReportDraftReceivedDate')}
                    />
                  </Form.Item>
                )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isTypist() || api.isDoctor()) && (
                <Form.Item label="Attached Report Draft" className="add-file-container">
                  <Upload
                    multiple
                    type={3}
                    functionCallback={store.reportDraftUploadCompleted}
                    disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    disabledDelete={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                  />
                </Form.Item>
              )}
              {!(!fileReviewInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) &&
                !api.isCaseManager() &&
                !api.isTypist() && (
                  <Form.Item label="Report Draft Sent Date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={fileReviewInfo.ReportDraftSentDate}
                      onChange={setFieldValue('ReportDraftSentDate')}
                      disabled={fileReviewInfo.isLocked || !fileReviewInfo.ReportDraftReceived}
                    />
                  </Form.Item>
                )}
              {api.isAdminOrMagStaffOrAccounting() && !api.isTypist() && (
                <>
                  <Checkbox
                    checked={fileReviewInfo.EmailReportDraftToDoctorBE}
                    onChange={toggleCheckbox('EmailReportDraftToDoctorBE')}
                    disabled={
                      !api.isAdminOrMagStaffOrAccounting() ||
                      !!fileReviewInfo.EmailReportDraftToDoctorDateSent ||
                      !fileReviewInfo.ReportDraftReceived ||
                      !store.selectedDoctor?.BusinessEmail
                    }
                  >
                    {fileReviewInfo.ReportDraftReceived &&
                      !fileReviewInfo.EmailReportDraftToDoctorBE &&
                      !fileReviewInfo.EmailReportDraftToDoctor &&
                      !fileReviewInfo.EmailReportDraftToDoctorPE && <span style={styles.requiredColor}>*</span>}
                    Send to Specialist Business Email{' '}
                    {store.selectedDoctor?.BusinessEmail ? '(' + store.selectedDoctor?.BusinessEmail + ')' : ''}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={fileReviewInfo.EmailReportDraftToDoctorPE}
                    onChange={toggleCheckbox('EmailReportDraftToDoctorPE')}
                    disabled={
                      !api.isAdminOrMagStaffOrAccounting() ||
                      (!!fileReviewInfo.EmailReportDraftToDoctorDateSent &&
                        fileReviewInfo.EmailReportDraftToDoctorPE) ||
                      !fileReviewInfo.ReportDraftReceived ||
                      !store.selectedDoctor?.PersonalEmail
                    }
                  >
                    {fileReviewInfo.ReportDraftReceived &&
                      !fileReviewInfo.EmailReportDraftToDoctorBE &&
                      !fileReviewInfo.EmailReportDraftToDoctor &&
                      !fileReviewInfo.EmailReportDraftToDoctorPE && <span style={styles.requiredColor}>*</span>}
                    Send to Specialist Personal Email{' '}
                    {store.selectedDoctor?.PersonalEmail ? '(' + store.selectedDoctor?.PersonalEmail + ')' : ''}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                  </Checkbox>
                  <br />
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      <Checkbox
                        checked={fileReviewInfo.EmailReportDraftToDoctor}
                        disabled={
                          !fileReviewInfo.EmailReportDraftToDoctorPE && !fileReviewInfo.EmailReportDraftToDoctorBE
                        }
                        onChange={toggleCheckbox('EmailReportDraftToDoctor')}
                      >
                        Send as Attachment
                      </Checkbox>
                      <br />
                      {fileReviewInfo.EmailReportDraftToDoctorDateSent && (
                        <>
                          <span style={{ color: '#107fc9' }}>
                            {fileReviewInfo.EmailReportDraftToDoctorDateSent
                              ? renderDate(fileReviewInfo.EmailReportDraftToDoctorDateSent, false, true)
                              : ''}
                            {fileReviewInfo.EmailReportDraftToDoctorByUser
                              ? ` - By ${store.renderStaffName(fileReviewInfo.EmailReportDraftToDoctorByUser)}`
                              : ''}
                            {fileReviewInfo.EmailReportDraftToDoctorDateSent && (
                              <Button
                                className="blue"
                                style={{ marginLeft: '5px' }}
                                onClick={this.onClickResendReportDraft}
                              >
                                Resend Report Draft
                              </Button>
                            )}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item label="Comments" className="fluid-field">
                  <Input.TextArea
                    rows={3}
                    value={fileReviewInfo.ReportDraftComment}
                    onChange={setFieldValue('ReportDraftComment')}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>,
          ]}
          {!api.isCaseManager() && (
            <Collapse.Panel
              key="4"
              header={
                <Header
                  title="Reviewed Report from Specialist"
                  isChecked={fileReviewInfo.ReportReturnedFromDoctor}
                  text={api.isDoctor() ? 'Report Sent' : 'Received from Specialist'}
                  date={fileReviewInfo.ReportCompletedDate}
                  isRequired={false}
                />
              }
            >
              {!(api.isCaseManager() && !fileReviewInfo.isMagDirect) && !api.isCaseManager() && (
                <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                  <Checkbox
                    checked={fileReviewInfo.ReportReturnedFromDoctor}
                    onChange={toggleCheckbox('ReportReturnedFromDoctor')}
                    className="checkbox-session"
                    disabled={
                      store.isLockReturnedReport ||
                      fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                      api.isDoctor()
                    }
                    style={{ width: '400px' }}
                  >
                    {api.isDoctor()
                      ? 'Reviewed/Completed Report Sent to Operator'
                      : 'Reviewed/Completed Report Received'}{' '}
                    {/* {api.isAdminOrMagStaffOrAccounting() && (
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Report returned from specialist will be notified to staff (MAIL-24)"
                        position="top center"
                      />
                    )} */}
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 5
                      <button className="field-actions" onClick={() => store.openModalHistory('PICcorrectedReport')}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={fileReviewInfo.PICcorrectedReport || undefined}
                    onChange={setFieldValue('PICcorrectedReport')}
                  >
                    {store.staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <div className="ant-row ant-form-item fluid-field">
                <React.Fragment>
                  <Checkbox
                    checked={fileReviewInfo.IcareClaim}
                    onChange={toggleCheckbox('IcareClaim')}
                    disabled={api.isDoctor() || api.isTypist()}
                  >
                    <h4 style={commonInlineStyles.inlineBlock}>
                      This is iCare Claim
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Send iCare reminders to Staff (MAIL-169)"
                        position="top center"
                        wide="very"
                      />
                    </h4>
                    {fileReviewInfo.IcareClaim && fileReviewInfo.IcareClaimDate && (
                      <span style={styles.highlightInline}>
                        {fileReviewInfo.IcareClaimDate
                          ? ' ' + renderDate(fileReviewInfo.IcareClaimDate, false, true)
                          : ''}
                        {' - By ' + store.renderStaffName(fileReviewInfo.IcareClaimBy)}
                      </span>
                    )}
                  </Checkbox>
                  {api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.IcareClaim && (
                    <Button
                      className="blue"
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to send iCare report reminder to Staff?',
                          onOk: () => {
                            sendICareCompletionRequest(fileReviewInfo.Id)
                              .then(response => {
                                if (response.status === 'success') {
                                  notification.destroy();
                                  notification.success({
                                    description: 'Sent iCare report reminder successfully!',
                                    message: 'Success',
                                  });
                                }
                              })
                              .catch(() => {});
                          },
                        });
                      }}
                    >
                      Send iCare Reminder To Staff
                    </Button>
                  )}
                </React.Fragment>
              </div>
              {fileReviewInfo.IcareClaim && (
                <>
                  <Form.Item label="Insurer">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={fileReviewInfo.InsurerId || undefined}
                      onChange={setFieldValue('InsurerId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.listCompanyInsurer.map(({ Id, Name, StateName }) => (
                        <Select.Option key={Id} value={Id}>
                          {Id} - {Name} - {StateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Reason for Referral">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={fileReviewInfo.ReasonReferralId || undefined}
                      onChange={setFieldValue('ReasonReferralId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.listReasonReferral.map(({ Id, Label }) => (
                        <Select.Option key={Id} value={Id}>
                          {Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Service Type">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={fileReviewInfo.IcareServiceTypeId || undefined}
                      onChange={setFieldValue('IcareServiceTypeId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.icareLookupList &&
                        store.icareLookupList.itemList &&
                        store.icareLookupList.itemList.length &&
                        store.icareLookupList.itemList[7] &&
                        store.icareLookupList.itemList[7].length > 0 &&
                        store.icareLookupList.itemList[7].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item></Form.Item>
                </>
              )}
              {!(api.isCaseManager() && !fileReviewInfo.isMagDirect) &&
                !api.isCaseManager() &&
                api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item
                    label={
                      api.isDoctor() ? 'Reviewed/Completed Report Sent Date' : 'Reviewed/Completed Report Received Date'
                    }
                    required={fileReviewInfo.ReportReturnedFromDoctor}
                    validateStatus={
                      fileReviewInfo.ReportReturnedFromDoctor && !fileReviewInfo.ReportCompletedDate ? 'error' : null
                    }
                    help={
                      fileReviewInfo.ReportReturnedFromDoctor && !fileReviewInfo.ReportCompletedDate
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={fileReviewInfo.ReportCompletedDate}
                      onChange={setFieldValue('ReportCompletedDate')}
                      disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    />
                  </Form.Item>
                )}
              {!(api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id) && <div className="fluid-field" />}
              {!(api.isCaseManager() && !fileReviewInfo.isMagDirect) && !api.isCaseManager() && (
                <Form.Item label="Attached Reviewed/Completed Report from Specialist" className="add-file-container">
                  <Upload
                    multiple
                    type={14}
                    functionCallback={() => {
                      if (!fileReviewInfo.ReportReturnedFromDoctor) {
                        store.handleChangeValue('ReportReturnedFromDoctor', true);
                      }
                      if (!fileReviewInfo.NotifyCorrectedReportToStaff) {
                        store.handleChangeValue('NotifyCorrectedReportToStaff', true);
                      }
                      if (!fileReviewInfo.NotifyCorrectedReportToSpecialist) {
                        store.handleChangeValue('NotifyCorrectedReportToSpecialist', true);
                      }
                    }}
                    disabledDelete={
                      (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor()) ||
                      (!api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.EmailToCMsViewReportRecent)
                    }
                    disabled={store.isLockReturnedReport}
                  />
                </Form.Item>
              )}

              {!(api.isCaseManager() && !fileReviewInfo.isMagDirect) &&
                !api.isCaseManager() &&
                !api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item
                    label={
                      api.isDoctor() ? 'Reviewed/Completed Report Sent Date' : 'Reviewed/Completed Report Received Date'
                    }
                    required={fileReviewInfo.ReportReturnedFromDoctor}
                    validateStatus={
                      fileReviewInfo.ReportReturnedFromDoctor && !fileReviewInfo.ReportCompletedDate ? 'error' : null
                    }
                    help={
                      fileReviewInfo.ReportReturnedFromDoctor && !fileReviewInfo.ReportCompletedDate
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={fileReviewInfo.ReportCompletedDate}
                      onChange={setFieldValue('ReportCompletedDate')}
                      disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
                    />
                  </Form.Item>
                )}
              <Form.Item className="fluid-field cancellation-label" label="Send Reviewed/Completed Report Notice To">
                <br />
                <div style={{ marginBottom: '6px' }}>
                  <Checkbox
                    checked={fileReviewInfo.NotifyCorrectedReportToStaff}
                    onChange={toggleCheckbox('NotifyCorrectedReportToStaff')}
                    disabled={
                      !!fileReviewInfo.SentDateNotifyCorrectedReportToStaff ||
                      (api.isDoctor() && fileReviewInfo.NotifyCorrectedReportToStaff) ||
                      api.isTypist()
                    }
                  >
                    Staff{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Reviewed/Completed report notice will be sent to Staff (MAIL-24)"
                      position="top center"
                      wide="very"
                    />
                    <span>
                      {`${
                        fileReviewInfo.SentDateNotifyCorrectedReportToStaff
                          ? `${renderDate(fileReviewInfo.SentDateNotifyCorrectedReportToStaff, false, true)}`
                          : ''
                      } ${
                        fileReviewInfo.SentByNotifyCorrectedReportToStaff
                          ? ` - By ${fileReviewInfo.SentByNotifyCorrectedReportToStaff}`
                          : ''
                      }`}
                    </span>
                  </Checkbox>
                </div>
                <br />
                <Checkbox
                  checked={fileReviewInfo.NotifyCorrectedReportToSpecialist}
                  onChange={toggleCheckbox('NotifyCorrectedReportToSpecialist')}
                  disabled={!!fileReviewInfo.SentDateNotifyCorrectedReportToSpecialist || api.isTypist()}
                >
                  Specialist{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Reviewed/Completed report notice will be sent to Specialist (MAIL-122)"
                    position="top center"
                    wide="very"
                  />
                  <span>
                    {`${
                      fileReviewInfo.SentDateNotifyCorrectedReportToSpecialist
                        ? `${renderDate(fileReviewInfo.SentDateNotifyCorrectedReportToSpecialist, false, true)}`
                        : ''
                    } ${
                      fileReviewInfo.SentByNotifyCorrectedReportToSpecialist
                        ? ` - By ${fileReviewInfo.SentByNotifyCorrectedReportToSpecialist}`
                        : ''
                    }`}
                  </span>
                </Checkbox>
              </Form.Item>
            </Collapse.Panel>
          )}
          {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() || api.isDoctor()) && (
            <Collapse.Panel
              key="5"
              header={
                <Header
                  title="Report to CMs"
                  isChecked={fileReviewInfo.ReportCompleted}
                  text="Sent to CMs"
                  date={fileReviewInfo.ReportCompletedSentDate}
                  isRequired={false}
                />
              }
            >
              {!store.isLockReportCompleted && (
                <>
                  <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                    <Checkbox
                      checked={fileReviewInfo.ReportCompleted}
                      onChange={toggleCheckbox('ReportCompleted')}
                      className="checkbox-session"
                      disabled={
                        store.isLockedReport ||
                        fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                        api.isDoctor()
                        // (api.isDoctor() && !store.selectedDoctor && !store.selectedDoctor?.ClientType)
                      }
                    >
                      Report Sent to CMs
                    </Checkbox>
                  </div>
                  {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
                    <Form.Item
                      label={
                        <React.Fragment>
                          PIC 6
                          <button className="field-actions" onClick={() => store.openModalHistory('PICcompleted')}>
                            Recent Changes
                          </button>
                        </React.Fragment>
                      }
                    >
                      <Select
                        showSearch
                        size="large"
                        optionFilterProp="children"
                        value={fileReviewInfo.PICcompleted || undefined}
                        onChange={setFieldValue('PICcompleted')}
                      >
                        {store.staffs.map(({ Id, FullName }) => (
                          <Select.Option key={Id} value={Id}>
                            {FullName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {!(!fileReviewInfo.ReportCompleted && api.isCaseManager()) && (
                    <>
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Form.Item label="Date of Report Sent to CMs">
                          <DatePicker
                            size="large"
                            format="DD/MM/YYYY"
                            value={fileReviewInfo.ReportCompletedSentDate}
                            onChange={setFieldValue('ReportCompletedSentDate')}
                            disabled={!fileReviewInfo.ReportCompleted || api.isCaseManager()}
                          />
                        </Form.Item>
                      )}
                      {!(api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id) && <div className="fluid-field" />}
                      <Form.Item
                        label="Attach Final Report"
                        className="add-file-container"
                        style={styles.minHeightAuto}
                      >
                        <Upload
                          multiple
                          type={4}
                          functionCallback={this.handleAfterUploadFinalReport}
                          disabled={!api.isAdminOrMagStaffOrAccounting()}
                          disabledDelete={
                            store.isLockedReport || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                          }
                        />
                      </Form.Item>{' '}
                      <Form.Item
                        label={
                          <>
                            Or Share Download Link
                            <button
                              className={'field-actions'}
                              disabled={!api.isAdminOrMagStaffOrAccounting()}
                              onClick={() => {
                                window.open(fileReviewInfo.FinalReportOnedriveLinkFile, '_blank');
                              }}
                            >
                              Open Link
                            </button>
                          </>
                        }
                        validateStatus={
                          !store.validateField(fileReviewInfo.FinalReportOnedriveLinkFile, 'url') ? 'error' : null
                        }
                        help={
                          !store.validateField(fileReviewInfo.FinalReportOnedriveLinkFile, 'url')
                            ? 'Download link is not valid'
                            : ''
                        }
                        className="fluid-field"
                      >
                        <Input.TextArea
                          rows={3}
                          value={fileReviewInfo.FinalReportOnedriveLinkFile}
                          disabled={!api.isAdminOrMagStaffOrAccounting()}
                          onChange={setFieldValue('FinalReportOnedriveLinkFile')}
                        />
                      </Form.Item>
                      <Form.Item label="Password" className="fluid-field">
                        <Input
                          size="large"
                          disabled={
                            api.isDoctor() ||
                            (!api.isAdminOrMagStaffOrAccounting() && fileReviewInfo.FinalReportOnedriveFilePassword) ||
                            api.isTypist()
                          }
                          value={fileReviewInfo.FinalReportOnedriveFilePassword}
                          onChange={setFieldValue('FinalReportOnedriveFilePassword')}
                        />
                      </Form.Item>
                      {!api.isAdminOrMagStaffOrAccounting() && (
                        <Form.Item label="Date of Report Sent to CMs">
                          <DatePicker
                            size="large"
                            format="DD/MM/YYYY"
                            value={fileReviewInfo.ReportCompletedSentDate}
                            onChange={setFieldValue('ReportCompletedSentDate')}
                            disabled={!fileReviewInfo.ReportCompleted || api.isCaseManager()}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      <Form.Item
                        label="Send Final Report to"
                        style={commonInlineStyles.marginBottom0}
                        className="fluid-field"
                      ></Form.Item>
                      <div className="checkbox-field fluid-field">
                        <Checkbox
                          checked={fileReviewInfo.IsAllowSpecialistViewReport}
                          disabled={fileReviewInfo.isLocked || !!fileReviewInfo.SentDateAllowSpecialistViewReport}
                          onChange={toggleCheckbox('IsAllowSpecialistViewReport')}
                        >
                          <span style={commonInlineStyles.inlineBlock}>Specialist</span>{' '}
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content="Send copy of final report to specialist (MAIL-59)"
                            position="top center"
                            wide="very"
                          />
                        </Checkbox>
                        {fileReviewInfo.SentDateAllowSpecialistViewReport && (
                          <>
                            <span style={commonInlineStyles.highlightInline}>
                              {fileReviewInfo.SentDateAllowSpecialistViewReport
                                ? renderDate(fileReviewInfo.SentDateAllowSpecialistViewReport, false, true)
                                : ''}
                              {fileReviewInfo.SentByAllowSpecialistViewReport
                                ? ' - By ' + store.renderStaffName(fileReviewInfo.SentByAllowSpecialistViewReport)
                                : ''}
                            </span>
                            <Button
                              className="blue"
                              disabled={
                                !fileReviewInfo.ReportCompleted ||
                                !fileReviewInfo.IsAllowSpecialistViewReport ||
                                !fileReviewInfo.SentDateAllowSpecialistViewReport
                              }
                              onClick={() => {
                                store.toggleModalConfirm(true, {
                                  message: 'Do you want to resend completed report to specialist?',
                                  onOk: () => {
                                    customFetch('/FileReview/ResendReports', {
                                      headers: { 'Content-Type': 'application/json' },
                                      method: 'POST',
                                      body: JSON.stringify({ id: fileReviewInfo.Id, toSpecialist: true }),
                                    })
                                      .then(response => {
                                        if (response.status === 'success') {
                                          notification.destroy();
                                          notification.success({
                                            description: 'Resend report',
                                            message: 'Success',
                                          });
                                          store.handleChangeValue(
                                            'SentDateAllowSpecialistViewReport',
                                            moment(response.ResendDatetime),
                                          );
                                        } else {
                                          notification.destroy();
                                          notification.Error({
                                            description: 'Resend report',
                                            message: 'Error',
                                          });
                                        }
                                      })
                                      .catch(() => {});
                                  },
                                });
                              }}
                            >
                              Resend
                            </Button>
                          </>
                        )}
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={fileReviewInfo.IsAllowSolicitorViewReport}
                            onChange={toggleCheckbox('IsAllowSolicitorViewReport')}
                            disabled={
                              fileReviewInfo.isLocked ||
                              fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                              !!fileReviewInfo.SentDateAllowSolicitorViewReport ||
                              //!fileReviewInfo.BookingSolicitorId
                              !fileReviewInfo.F_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 1{' '}
                            <Popup
                              trigger={<Icon name="info circle" />}
                              content="Final report will be sent to Client Receiving Contact 1 (MAIL-59, DIRECT-59)"
                              position="top center"
                            />
                          </Checkbox>
                          {fileReviewInfo.SentDateAllowSolicitorViewReport && (
                            <>
                              <span style={commonInlineStyles.highlightInline}>
                                {fileReviewInfo.SentDateAllowSolicitorViewReport
                                  ? renderDate(fileReviewInfo.SentDateAllowSolicitorViewReport, false, true)
                                  : ''}
                                {fileReviewInfo.SentByAllowSolicitorViewReport
                                  ? ` - By ${store.renderStaffName(fileReviewInfo.SentByAllowSolicitorViewReport)}`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  !fileReviewInfo.ReportCompleted ||
                                  !fileReviewInfo.IsAllowSolicitorViewReport ||
                                  !fileReviewInfo.SentDateAllowSolicitorViewReport
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend completed report to Client Receiving Contact 1?',
                                    onOk: () => {
                                      customFetch('/FileReview/ResendReportToSolicitor', {
                                        headers: { 'Content-Type': 'application/json' },
                                        method: 'POST',
                                        body: JSON.stringify({ id: fileReviewInfo.Id }),
                                      })
                                        .then(response => {
                                          if (response.status === 'success') {
                                            notification.destroy();
                                            notification.success({
                                              description: 'Resend report',
                                              message: 'Success',
                                            });
                                            store.handleChangeValue(
                                              'SentDateAllowSolicitorViewReport',
                                              moment(response.ResendDatetime),
                                            );
                                          } else {
                                            notification.destroy();
                                            notification.Error({
                                              description: 'Resend report',
                                              message: 'Error',
                                            });
                                          }
                                        })
                                        .catch(() => {});
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          showSearch
                          size="large"
                          optionFilterProp="children"
                          placeholder="Select a case manager"
                          value={fileReviewInfo.F_RDCCaseManagerId || undefined}
                          onChange={setFieldValue('F_RDCCaseManagerId')}
                          allowClear
                          disabled={
                            store.isLockReportCompleted_CM ||
                            fileReviewInfo.F_RDCSameBookingCM ||
                            fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                          }
                        >
                          {store.companyCMs.map(({ Id, FullName, Email }) => (
                            <Select.Option key={Id} value={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={fileReviewInfo.IsAllowCMViewReport}
                            onChange={toggleCheckbox('IsAllowCMViewReport')}
                            disabled={
                              fileReviewInfo.isLocked ||
                              fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                              //!!fileReviewInfo.EmailToCMsViewReportRecent
                              !fileReviewInfo.S_RDCCaseManagerId ||
                              !!fileReviewInfo.EmailToCMsViewReportRecent
                            }
                          >
                            Client Receiving Contact 2{' '}
                            <Popup
                              trigger={<Icon name="info circle" />}
                              content="Final report will be sent to Client Receiving Contact 2 (MAIL-59, DIRECT-59)"
                              position="top center"
                            />
                          </Checkbox>
                          {fileReviewInfo.EmailToCMsViewReportRecent && (
                            <>
                              <span style={styles.marginLeftHighlightInline}>
                                {fileReviewInfo.EmailToCMsViewReportRecent
                                  ? renderDate(fileReviewInfo.EmailToCMsViewReportRecent, false, true)
                                  : ''}
                                {fileReviewInfo.EmailToCMsViewReportRecentByUser
                                  ? ` - By ${
                                      store.staffs.find(i => i.Id === fileReviewInfo.EmailToCMsViewReportRecentByUser)
                                        ? store.staffs.find(
                                            i => i.Id === fileReviewInfo.EmailToCMsViewReportRecentByUser,
                                          ).FullName
                                        : fileReviewInfo.EmailToCMsViewReportRecentByUser
                                    }`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  !fileReviewInfo.ReportCompleted ||
                                  !fileReviewInfo.IsAllowCMViewReport ||
                                  !fileReviewInfo.EmailToCMsViewReportRecent
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend completed report to Client Receiving Contact 2?',
                                    onOk: () => {
                                      if (!fileReviewInfo.F_RDCEmail && !fileReviewInfo.S_RDCEmail) {
                                        store.showError('At least one Report Delivery Contact needs to be completed');
                                      } else {
                                        customFetch('/FileReview/ResendReports', {
                                          headers: { 'Content-Type': 'application/json' },
                                          method: 'POST',
                                          body: JSON.stringify({ id: fileReviewInfo.Id }),
                                        })
                                          .then(response => {
                                            if (response.status === 'success') {
                                              notification.destroy();
                                              notification.success({
                                                message: 'Success',
                                                description: 'Resend report',
                                              });
                                              store.handleChangeValue(
                                                'EmailToCMsViewReportRecent',
                                                moment(response.ResendDatetime),
                                              );
                                            } else {
                                              notification.destroy();
                                              notification.Error({
                                                message: 'Error',
                                                description: 'Resend report',
                                              });
                                            }
                                          })
                                          .catch(() => {});
                                      }
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          showSearch
                          size="large"
                          filterOption={false}
                          placeholder="Select a case manager"
                          disabled={store.isLockReportCompleted_CM}
                          value={fileReviewInfo.S_RDCCaseManagerId || undefined}
                          onSearch={this.handleSearch('CMsKeyword')}
                          onPopupScroll={this.handleScroll('CMsPerPage')}
                          onChange={setFieldValue('S_RDCCaseManagerId')}
                          allowClear
                        >
                          {store.CMs.map(({ Id, FullName, Email }) => (
                            <Select.Option key={Id} value={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}

                  {!(!fileReviewInfo.ReportCompleted && api.isCaseManager()) && (
                    <>
                      <Form.Item
                        label="Attach Amended Reports"
                        className="add-file-container"
                        style={styles.minHeightAuto}
                      >
                        <Upload
                          multiple
                          type={7}
                          disabled={!api.isAdminOrMagStaffOrAccounting()}
                          disabledDelete={
                            store.isLockedReport || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      {' '}
                      <Form.Item
                        label="Send Amended Reports to"
                        style={commonInlineStyles.marginBottom0}
                        className="fluid-field"
                      ></Form.Item>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={fileReviewInfo.IsAllowSolicitorViewAmendedReport}
                            onChange={toggleCheckbox('IsAllowSolicitorViewAmendedReport')}
                            disabled={
                              fileReviewInfo.isLocked ||
                              fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                              !!fileReviewInfo.SentDateAllowSolicitorViewAmendedReport ||
                              //!fileReviewInfo.BookingSolicitorId
                              !fileReviewInfo.F_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 1{' '}
                            <Popup
                              trigger={<Icon name="info circle" />}
                              content="Amended Final report will be sent to Client Receiving Contact 1 (MAIL-100, DIRECT-100)"
                              position="top center"
                            />
                          </Checkbox>
                          {fileReviewInfo.SentDateAllowSolicitorViewAmendedReport && (
                            <>
                              <span style={commonInlineStyles.highlightInline}>
                                {fileReviewInfo.SentDateAllowSolicitorViewAmendedReport
                                  ? renderDate(fileReviewInfo.SentDateAllowSolicitorViewAmendedReport, false, true)
                                  : ''}
                                {fileReviewInfo.SentByAllowSolicitorViewAmendedReport
                                  ? ` - By ${store.renderStaffName(
                                      fileReviewInfo.SentByAllowSolicitorViewAmendedReport,
                                    )}`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  !fileReviewInfo.ReportCompleted ||
                                  !fileReviewInfo.IsAllowSolicitorViewAmendedReport ||
                                  !fileReviewInfo.SentDateAllowSolicitorViewAmendedReport
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend amended report to Client Receiving Contact 1?',
                                    onOk: () => {
                                      customFetch('/FileReview/ResendAmendedReportToSolicitor', {
                                        headers: { 'Content-Type': 'application/json' },
                                        method: 'POST',
                                        body: JSON.stringify({ id: fileReviewInfo.Id }),
                                      })
                                        .then(response => {
                                          if (response.status === 'success') {
                                            notification.destroy();
                                            notification.success({
                                              message: 'Success',
                                              description: 'Resend Amended report',
                                            });
                                            store.handleChangeValue(
                                              'SentDateAllowSolicitorViewAmendedReport',
                                              moment(response.ResendDatetime),
                                            );
                                          } else {
                                            notification.destroy();
                                            notification.Error({
                                              message: 'Error',
                                              description: 'Resend Amended report',
                                            });
                                          }
                                        })
                                        .catch(() => {});
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          showSearch
                          size="large"
                          optionFilterProp="children"
                          placeholder="Select a case manager"
                          value={fileReviewInfo.F_RDCCaseManagerId || undefined}
                          onChange={setFieldValue('F_RDCCaseManagerId')}
                          disabled={
                            store.isLockReportCompleted_CM ||
                            fileReviewInfo.F_RDCSameBookingCM ||
                            fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                          }
                          allowClear
                        >
                          {store.companyCMs.map(({ Id, FullName, Email }) => (
                            <Select.Option key={Id} value={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={fileReviewInfo.IsAllowCMViewAmendedReport}
                            onChange={toggleCheckbox('IsAllowCMViewAmendedReport')}
                            disabled={
                              fileReviewInfo.isLocked ||
                              fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                              !!fileReviewInfo.SentAmendedReportsDate ||
                              !fileReviewInfo.S_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 2{' '}
                            <Popup
                              trigger={<Icon name="info circle" />}
                              content="Amended Final report will be sent to Client Receiving Contact 2 (MAIL-100, DIRECT-100)"
                              position="top center"
                            />
                          </Checkbox>
                          {fileReviewInfo.SentAmendedReportsDate && (
                            <>
                              <span style={styles.marginLeftHighlightInline}>
                                {fileReviewInfo.SentAmendedReportsDate
                                  ? renderDate(fileReviewInfo.SentAmendedReportsDate, false, true)
                                  : ''}
                                {fileReviewInfo.SentAmendedReportsByUser
                                  ? ` - By ${
                                      store.staffs.find(i => i.Id === fileReviewInfo.SentAmendedReportsByUser)
                                        ? store.staffs.find(i => i.Id === fileReviewInfo.SentAmendedReportsByUser)
                                            .FullName
                                        : fileReviewInfo.SentAmendedReportsByUser
                                    }`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  !fileReviewInfo.ReportCompleted ||
                                  !fileReviewInfo.IsAllowCMViewAmendedReport ||
                                  !fileReviewInfo.SentAmendedReportsDate
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend amended report to Client Receiving Contact 2?',
                                    onOk: () => {
                                      if (!fileReviewInfo.F_RDCEmail && !fileReviewInfo.S_RDCEmail) {
                                        store.showError('At least one Report Delivery Contact needs to be completed');
                                      } else {
                                        customFetch('/FileReview/ResendAmendedReports', {
                                          headers: { 'Content-Type': 'application/json' },
                                          method: 'POST',
                                          body: JSON.stringify({ id: fileReviewInfo.Id }),
                                        })
                                          .then(response => {
                                            if (response.status === 'success') {
                                              notification.destroy();
                                              notification.success({
                                                message: 'Success',
                                                description: 'Resend Amended report',
                                              });
                                              store.handleChangeValue(
                                                'SentAmendedReportsDate',
                                                moment(response.ResendDatetime),
                                              );
                                            } else {
                                              notification.destroy();
                                              notification.Error({
                                                message: 'Error',
                                                description: 'Resend Amended report',
                                              });
                                            }
                                          })
                                          .catch(() => {});
                                      }
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          showSearch
                          size="large"
                          filterOption={false}
                          placeholder="Select a case manager"
                          disabled={store.isLockReportCompleted_CM}
                          value={fileReviewInfo.S_RDCCaseManagerId || undefined}
                          onSearch={this.handleSearch('CMsKeyword')}
                          onPopupScroll={this.handleScroll('CMsPerPage')}
                          onChange={setFieldValue('S_RDCCaseManagerId')}
                          allowClear
                        >
                          {store.CMs.map(({ Id, FullName, Email }) => (
                            <Select.Option key={Id} value={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <Form.Item label="Comments" className="fluid-field">
                        <Input.TextArea rows={3} value={fileReviewInfo.Comments} onChange={setFieldValue('Comments')} />
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Collapse.Panel>
          )}
        </Collapse>
      </Form>
    );
  }
}

export default CaseDocumentsForm;
