import customFetch from '../../../utils/customFetch';

export const getServiceEstimate = (keyword = '', from = null, to = null, cmId = 0, curPage = 1, numItemPerPage = 0, sortBy = '', sortDirection = '') => {
  return customFetch('/ServiceEstimate/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      FromDate: from,
      ToDate: to,
      CaseManagerId: cmId, 
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};

export const getEstimateLink = (keyword, clientId) => {
  return customFetch('/ServiceEstimate/GetEstimateLink', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: 1,
      NumItemPerPage: 1000,
      ClientId: clientId,
    }),
  });
};

export const getServiceEstimateInfo = (id = 0) => {
  return customFetch(`/ServiceEstimate/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveServiceEstimate = param => {
  return customFetch('/ServiceEstimate/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteServiceEstimate = id => {
  return customFetch('/ServiceEstimate/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const uploadFileTemplate = body => {
  return customFetch('/File/UploadFileTemp', {
    method: 'POST',
    body,
  });
};

export const searchAMAFee = keyword => {
  return customFetch('/MAGService/SearchAMACode?keyword=' + keyword, {
    method: 'GET',
  });
};

export const getListAMASpecific = () => {
  return customFetch('/MAGService/GetListAMASpecific', {
    method: 'GET',
  });
};

export const getM4ModifierExclusionCode = () => {
  return customFetch('/M4ModifierExclusion/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: '',
      CurPage: 1,
      NumItemPerPage: 1000,
      SortBy: 'Id',
      SortDirection: 'ASC',
    }),
  });
};

export const searchAMAProfessionalAttendance = keyword => {
  return customFetch('/MAGService/SearchAMACode?keyword=' + keyword + '&onlyProfessionalAttendance=true', {
    method: 'GET',
  });
};

export const searchAMACaculation = param => {
  return customFetch('/AMACalculationRule/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const searchIMEAsssessment = param => {
  return customFetch('/MedicalService/Assessment_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const searchFileReview = param => {
  return customFetch('/FileReview/FileReview_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const searchSupplementary = param => {
  return customFetch('/Supplementary/Supplementary_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const getAllTypeOfWork = keyword => {
  return customFetch('/TypeOfWork/GetAll?keyword=' + keyword + '&status=active', {
    method: 'GET',
  });
};

export const getAllBodyParts = keyword => {
  return customFetch('/BodyParts/GetAll?keyword=' + keyword + '&status=active', {
    method: 'GET',
  });
};

export const getStaffGetAll = (keyword = '', numItemPerPage = 50, companyId = 0, IsOnboarded = true) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numItemPerPage,
      companyId,
      IsOnboarded,
    }),
  });
};

export const getAllCompany = (keyword = '', numItemPerPage = 50) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
    }),
  });
};

export const getCompanyList = (keyword = '', numItemPerPage = 50) => {
  return customFetch('/Company/GetClientInvoiceCheck', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
    }),
  });
};

export const getSpecialtyList = () => {
  return customFetch('/ServiceEstimate/LookUpConfig_LoadList', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      keyword: '',
      curPage: 1,
      FilterLabel: 'Specialty',
      FilterButtonLabel: 'Specialty',
      type: 'spe',
      numItemPerPage: 1000,
    }),
  });
};

export const getGetAllCaculationRate = id => {
  return customFetch('/AMACalculationRule/GetAllCaculationRate?id=' + id, {
    method: 'GET',
  });
};

export const getFeeByDateOfService = (id, dateOfService) => {
  return customFetch('/MAGService/GetFeeByDateOfService?amaCodeId=' + id + '&dateOfService=' + dateOfService, {
    method: 'GET',
  });
};

export const getAllAMAAftercareRule = () => {
  return customFetch('/AMAAftercareRule/GetAll', {
    method: 'POST',
    body: JSON.stringify({
      Keyword: '',
      CurPage: 1,
      NumItemPerPage: 1000,
    }),
  });
};

export const getAllTimeUnit = () => {
  return customFetch('/AMATimeUnit/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: '',
      CurPage: 1,
      NumItemPerPage: 1000,
    }),
  });
};
export const getTimeUnit = value => {
  return customFetch('/AMATimeUnit/GetTimeUnit?valueCheck=' + value, {
    method: 'GET',
  });
};

export const getAllModifiesUnit = () => {
  return customFetch('/AMATimeUnitModify/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: '',
      CurPage: 1,
      NumItemPerPage: 1000,
    }),
  });
};

export const getCheckType = () => {
  return customFetch('/ServiceEstimate/LookUpConfig_LoadList', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      keyword: '',
      curPage: 1,
      type: 'estCheckType',
      numItemPerPage: 1000,
    }),
  });
};

export const getMZ900Calculations = () => {
  return customFetch('/MZ900Calculations/GetAll', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      keyword: "",
      curPage: 1,
      numItemPerPage: 1000,
      sortBy: 'Id',
      sortDirection: 'ASC',
      status: 'active'
    }),
  });
};

export const GetListCaseManager = () => {
  return customFetch('/ServiceEstimate/GetListCaseManager', {
    method: 'GET',
    headers: { 'Content-type': 'application/json' },
  });
};

export const getStateList = () => {
  return customFetch('/ServiceEstimate/GetStates', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: {},
  });
};

export const searchMBSCode = keyword => {
  return customFetch('/MBSCode/SearchMBSCode?keyword=' + keyword, {
    method: 'GET',
  });
};

export const getMBSCodeFeeByDateOfService = (id, state, dateOfService) => {
  return customFetch('/MBSCode/GetMBSCodeFeeByDateOfService?mbsCodeId=' + id + '&state=' + state + '&dateOfService=' + dateOfService, {
    method: 'GET',
  });
};

export const searchMBSProfessionalAttendance = keyword => {
  return customFetch('/MBSCode/SearchMBSCode?keyword=' + keyword + '&onlyProfessionalAttendance=true', {
    method: 'GET',
  });
};

export const searchAnaesthesiaAMAFee = keyword => {
  return customFetch('/MAGService/SearchAMACode?onlyAnaesthesia=true&keyword=' + keyword, {
    method: 'GET',
  });
};