import { observer } from 'mobx-react';
import React from 'react';
import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import Store from './Store';
import { action, toJS } from 'mobx';

const STATUST = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];
@observer
class Toolbar extends React.Component {
  handSubmit = e => {
    e.preventDefault();
    Store.refetch({ curPage: 1 }, true);
  };

  openModalCreate = () => {
    return Store.toggleModal(true, {
      modalType: 'addOrEdit',
    });
  };

  openModalAddCalculationRule = () => {
    return Store.toggleModal(true, {
      modalType: 'addCalculationRule',
    });
  };

  exportSearches = () => {
    Store.exportSearchResults();
  };

  openModalImport = () => {
    return Store.toggleModal(true, {
      modalType: 'import',
    });
  };

  openModalTreatment = () => {
    return Store.toggleModal(true, {
      modalType: 'checkTreatment',
    });
  };

  @action onChangeCategory = (_, { value }) => {
    Store.filterCategory = value;
    Store.filterGroup = '';
    Store.refetch({ curPage: 1 }, true);
  };

  @action onChangeGroup = (_, { value }) => {
    Store.filterGroup = value;
    Store.refetch({ curPage: 1 }, true);
  };

  @action onChangeStatus = (_, { value }) => {
    Store.filterStatus = value;
    Store.refetch({ curPage: 1 }, true);
  };

  render() {
    const { loading, keyword } = Store;
    return (
      <div>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={Store.handleSearchChange} value={keyword} disabled={loading} />
            <TableToolbar.SearchDropdown
              disabled={loading}
              options={toJS(Store.lstCategories) || []}
              value={Store.filterCategory}
              onChange={this.onChangeCategory}
              searchPlaceHolder="Category"
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              options={toJS(Store.lstGroups) || []}
              value={Store.filterGroup}
              onChange={this.onChangeGroup}
              searchPlaceHolder="Group"
            />
            <TableToolbar.Filter
              disabled={loading}
              options={STATUST.map(i => ({ value: i.value, text: i.label }))}
              value={STATUST.find(i => i.value === Store.filterStatus).label}
              onChange={this.onChangeStatus}
              label="Status"
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={Store.resetStore}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={() => Store.refetch({}, true)}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalCreate()}
                style={{ marginLeft: 5 }}
              >
                Add Code
              </TableToolbar.HighLightButton>
            )}
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={() => this.exportSearches()}
                style={{ marginLeft: 5 }}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            )}
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalImport()}
                style={{ marginLeft: 5 }}
              >
                Import Codes
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>
        </TableToolbar.Container>
      </div>
    );
  }
}

export default Toolbar;
