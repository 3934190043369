import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
// import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import ModuleStore from './ModuleStore';
import * as api from '../../../stores/api';

const columns = [{ title: '' }, { title: 'Id' }, { title: 'Name' }, { title: 'Status' }, { title: 'Description' }];

@observer
class JobTitleTable extends React.Component {
  openModal = (id, record) => () => {
    return ModuleStore.toggleModal(true, {
      modalType: 'addOrEdit',
      id,
      record,
    })();
  };

  openModalConfirm = opt => () => {
    return ModuleStore.toggleModal(true, opt)();
  };

  handlePageClick = page => {
    ModuleStore.refetch({ curPage: page });
  };

  renderTableBody = () => {
    return ModuleStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell selectable className={cx('Cell-nowrap Cell-link')}>
          <Link to="#" onClick={this.openModal(Id, record)}>
            {Id}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Label}</Table.Cell>
        <Table.Cell>{record.IsActive ? 'Active' : 'Inactive'}</Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
      </Table.Row>
    ));
  };

  handleDeleteItem = async id => {
    ModuleStore.setFieldsValue({ loading: true });

    try {
      await customFetch(`/MailTemplate/DeleteLockupConfig?id=${id}`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        // body: JSON.stringify({ id, status: !record.IsActive }),
      });

      await ModuleStore.refetch({}, true);
    } catch (e) {
    } finally {
      ModuleStore.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openModal(id, record)}>Edit</Menu.Item>
          <Menu.Item
            onClick={this.openModalConfirm({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: () => this.handleDeleteItem(id),
            })}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data, totalItems } = ModuleStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={ModuleStore.sortColumn}
          sortDirection={ModuleStore.sortDirection}
          //   onSort={ModuleStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ModuleStore.curPage}
          totalPage={ModuleStore.totalPage || 0}
          totalItems={totalItems || 0}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default JobTitleTable;
