import React from 'react';
import UpdateAttendace from './UpdateAttendance';

function UpdateModel(props) {
  const renderModel = () => {
    return <UpdateAttendace refetchTable={props.refetchTable} />;
  };

  return renderModel();
}

export default UpdateModel;
