import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal';

import AddEditStore from './Store';
import {
  ProgressActionFormAppoinmentRequested,
  ProgressActionFormAppoinmentConfirmed,
  ProgressActionFormAttended,
  ProgressActionFormCanceledNoFee,
  ProgressActionFormCanceledWithFee,
  ProgressActionFormDictationReceived,
  ProgressActionFormNotAttended,
  ProgressActionFormPaperworkSendToSpecialist,
  ProgressActionFormReportDraftSendToSpecialist,
  ProgressActionFormReportSentToCMs,
  ProgressActionFormServiceBilled,
} from './ProgressActionForm';
import { DEFAULT_COLUMNS_RECENT_CHANGE, DEFAULT_FIELD_NAME_RECENT_CHANGE } from './type';
import { getStaffRecentChangeHistory, getAssessmentInvoices } from './service';
import { notification } from 'antd';
import customFetch from '@utils/customFetch';

const DEFAULT_COLUMNS_INVOICE = [
  { title: 'Invoice No' },
  { title: 'Invoice Date' },
  { title: 'Amount (RXCl GST)' },
  { title: 'Amount (INCL GST)' },
  { title: 'Issuer Name' },
  { title: 'Xero Uploaded' },
  { title: 'Uploaded Date' },
  { title: 'Fully Paid' },
  { title: 'Last Payment Date' },
];

@observer
class ModalActionProgress extends React.Component {
  state = {
    stepInfo: toJS(AddEditStore.modalParams.stepInfo),
    picId: '',
    loading: true,
    recentChangeData: {},
    sendAppointmentRequestReceivedNotificationToCM: true, //Appointment Reuest
    sendDaysheetToDoctor: true, //Appointment Reuest + Appointment confirmed + Caneceled No Fee + Canceled With Fee
    sendAppointmentConfirmedNotificationToCM: true, //Appointment confirmed
    sendPaperworkReceivedNotificationToDoctor: false, //Paperwork send to Specialist
    autoRemindReportCompletionDeadlineToDoctor: false, //Attended
    sendDidNotAttendNotificationToCM: true, //Did not attended
    autoRemindReportDraftCompletionDeadlineToMagStaff: false, //Dictation Received
    sendReportCompletedNotificationToCm: true, //Report send to CMs
    sendCancellationNotificationToCm: true, //Caneceled No Fee
    icf: true,
    invoiceData: {},
  };
  componentDidMount() {
    const assessmentInfo = toJS(AddEditStore.assessmentInfo);
    const { stepInfo } = this.state;
    if (stepInfo.id !== 13) {
      Promise.all([getStaffRecentChangeHistory(assessmentInfo.Id, stepInfo.actionName)])
        .then(([dataRecentChange]) => {
          this.setState({
            recentChangeData: {
              dataRecentChange: dataRecentChange.itemList,
              columns: DEFAULT_COLUMNS_RECENT_CHANGE,
              fieldNameStaffRecentChange: DEFAULT_FIELD_NAME_RECENT_CHANGE,
            },
            sendPaperworkReceivedNotificationToDoctor: assessmentInfo.AllowDoctorToViewPaperwork,
            picId: stepInfo.pic.picId,
            loading: false,
            icf: stepInfo.id === 10,
          });
        })
        .catch(() => {});
    } else {
      Promise.all([getAssessmentInvoices(assessmentInfo.Id)])
        .then(([data]) => {
          this.setState({
            invoiceData: {
              invoiceData: data,
              columns: DEFAULT_COLUMNS_INVOICE,
            },
            loading: false,
          });
        })
        .catch(() => {});
    }
  }
  handleChangeField = fieldName => event => {
    const value = event.target ? (event.target.value ? event.target.value : '') : event;
    this.setState({ [fieldName]: value });
  };

  handleChangeCheckBox = fieldName => event => {
    const value = event.target.checked;
    this.setState({ [fieldName]: value });
  };

  handleSave = async () => {
    const {
      stepInfo,
      picId,
      sendAppointmentRequestReceivedNotificationToCM,
      sendDaysheetToDoctor,
      sendAppointmentConfirmedNotificationToCM,
      sendPaperworkReceivedNotificationToDoctor,
      autoRemindReportCompletionDeadlineToDoctor,
      sendDidNotAttendNotificationToCM,
      autoRemindReportDraftCompletionDeadlineToMagStaff,
      sendReportCompletedNotificationToCm,
      sendCancellationNotificationToCm,
      icf,
    } = this.state;
    const assessmentId = toJS(AddEditStore.assessmentInfo).Id;
    try {
      if (stepInfo.id === 10 || stepInfo.id === 9) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendCancellationNotificationToCm,
            icf,
            sendDaysheetToDoctor,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 8) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendReportCompletedNotificationToCm,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 6) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 5) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            autoRemindReportDraftCompletionDeadlineToMagStaff,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 4) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendDidNotAttendNotificationToCM,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 3) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            autoRemindReportCompletionDeadlineToDoctor,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 2) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendPaperworkReceivedNotificationToDoctor,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 1) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendAppointmentConfirmedNotificationToCM,
            sendDaysheetToDoctor,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
      if (stepInfo.id === 0) {
        const response = await customFetch(`${stepInfo.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId,
            picId,
            sendAppointmentRequestReceivedNotificationToCM,
            sendDaysheetToDoctor,
          }),
        });
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          AddEditStore.fetchAssessmentInfo(assessmentId);
        }
      }
    } catch (e) {}
  };

  render() {
    const { open } = AddEditStore;
    const { stepInfo } = this.state;
    const staffs = toJS(AddEditStore.listStaff);
    return (
      <Modal
        visible={open}
        width={900}
        onCancel={AddEditStore.toggleModal(false)}
        title={`Set Case Status To ${stepInfo.label}`}
        footer={
          <React.Fragment>
            {stepInfo.id !== 13 && (
              <Button color="blue" onClick={this.handleSave}>
                Save
              </Button>
            )}
            <Button className="negative" onClick={AddEditStore.toggleModal(false)}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        {stepInfo.key === 'inquiryReceived' && (
          <ProgressActionFormAppoinmentRequested
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendAppointmentRequestReceivedNotificationToCM')}
            checkedCheckbox={this.state.sendAppointmentRequestReceivedNotificationToCM}
          />
        )}
        {stepInfo.key === 'isAppointmentConfirmed' && (
          <ProgressActionFormAppoinmentConfirmed
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendAppointmentRequestReceivedNotificationToCM')}
            checkedCheckbox={this.state.sendAppointmentRequestReceivedNotificationToCM}
          />
        )}
        {stepInfo.key === 'attended' && (
          <ProgressActionFormAttended
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('autoRemindReportCompletionDeadlineToDoctor')}
            checkedCheckbox={this.state.autoRemindReportCompletionDeadlineToDoctor}
          />
        )}
        {stepInfo.key === 'isCancelledNoFee' && (
          <ProgressActionFormCanceledNoFee
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendCancellationNotificationToCm')}
            checkedCheckbox={this.state.sendCancellationNotificationToCm}
          />
        )}
        {stepInfo.key === 'isCancelledLcf' && (
          <ProgressActionFormCanceledWithFee
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendCancellationNotificationToCm')}
            checkedCheckbox={this.state.sendCancellationNotificationToCm}
          />
        )}
        {stepInfo.key === 'dictationReceived' && (
          <ProgressActionFormDictationReceived
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
          />
        )}
        {stepInfo.key === 'notAttended' && (
          <ProgressActionFormNotAttended
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendDidNotAttendNotificationToCM')}
            checkedCheckbox={this.state.sendDidNotAttendNotificationToCM}
          />
        )}
        {stepInfo.key === 'paperworkReceived' && (
          <ProgressActionFormPaperworkSendToSpecialist
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendPaperworkReceivedNotificationToDoctor')}
            checkedCheckbox={this.state.sendPaperworkReceivedNotificationToDoctor}
          />
        )}
        {stepInfo.key === 'reportDraftReceived' && (
          <ProgressActionFormReportDraftSendToSpecialist
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
          />
        )}
        {stepInfo.key === 'reportCompleted' && (
          <ProgressActionFormReportSentToCMs
            stepInfo={stepInfo}
            handleChangeField={this.handleChangeField('picId')}
            picId={this.state.picId}
            staffs={staffs}
            loading={this.state.loading}
            recentChangeData={this.state.recentChangeData}
            handleChangeCheckBox={this.handleChangeCheckBox('sendReportCompletedNotificationToCm')}
            checkedCheckbox={this.state.sendReportCompletedNotificationToCm}
          />
        )}
        {stepInfo.key === 'isBilled' && (
          <ProgressActionFormServiceBilled loading={this.state.loading} invoiceData={this.state.invoiceData} />
        )}
      </Modal>
    );
  }
}

export default ModalActionProgress;
