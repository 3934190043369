import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import InvoicesStore from './InvoicesStore';
import InvoicesTable from './InvoicesTable';
import InvoicesModal from './InvoicesModal';
import InvoicesToolbar from './InvoicesToolbar';
import './index.scss';

class Invoices extends React.Component {
  componentDidMount() {
    InvoicesStore.fetchInvoices(1);
  }

  render() {
    const {
      loading,
      data,
      open,
      loadingInvoiceStatus,
      loadingPaymentStatus,
      loadingServiceType,
      loadingStaff,
      loadingXeroStatus,
      dataInvoiceStatus,
      dataPaymentStatus,
      dataServiceType,
      dataStaff,
      dataXeroStatus,
    } = InvoicesStore;
    return (
      <div
        className={`Table-${
          (loading && !data) ||
          (loadingStaff && !dataStaff) ||
          (loadingInvoiceStatus && !dataInvoiceStatus) ||
          (loadingPaymentStatus && !dataPaymentStatus) ||
          (loadingServiceType && !dataServiceType) ||
          (loadingXeroStatus && !dataXeroStatus)
            ? 'loading'
            : 'container'
        }`}
      >
        {(loading && !data) ||
        (loadingStaff && !dataStaff) ||
        (loadingInvoiceStatus && !dataInvoiceStatus) ||
        (loadingPaymentStatus && !dataPaymentStatus) ||
        (loadingServiceType && !dataServiceType) ||
        (loadingXeroStatus && !dataXeroStatus) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <InvoicesToolbar />
            <InvoicesTable />
            {open && <InvoicesModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(Invoices);
