import './DoctorDetails.scss';
import { observer } from 'mobx-react';
import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Grid, Segment, Table, TableBody, Button } from 'semantic-ui-react';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../../shared/table';
import * as api from '@stores/api';
import DoctorDetailsStore from './DoctorDetailsStore';

const columns = [
  { title: 'Action Notes' },
  { title: 'Subject' },
  { title: 'Request Date' },
  { title: 'Due Date' },
  { title: 'PIC' },
  { title: 'Action Complete' },
  { title: 'Completed Date' },
];
@observer
class ActionRequiredTab extends React.Component {
  formatDateForActionRequired = (datesString, showTime = false) => {
    if (datesString) {
      return moment(datesString).format(showTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY');
    }
    return 'None';
  };
  renderTableBody = (actionRequiredList, colSpan) => {
    const { isEditing } = DoctorDetailsStore;
    if (actionRequiredList && !!actionRequiredList.length) {
      return actionRequiredList.map((i, idx) => (
        <Table.Row key={idx}>
          <Table.Cell
            selectable={isEditing}
            className={isEditing ? 'Cell-nowrap Cell-link' : ''}
            onClick={() => {
              isEditing && this.handleAddActionRequired();
            }}
          >
            {isEditing ? (
              <Link
                to="#"
                onClick={() => {
                  isEditing && this.handleAddActionRequired();
                }}
                style={{ color: `#9f3a38` }}
              >
                {i.Subject}
              </Link>
            ) : (
              i.Subject
            )}
          </Table.Cell>
          <Table.Cell>{i.ActionNote}</Table.Cell>
          <Table.Cell>{this.formatDateForActionRequired(i.CreatedDate, true)}</Table.Cell>
          <Table.Cell>{this.formatDateForActionRequired(i.DueDate, true)}</Table.Cell>
          <Table.Cell>{`${i.StaffFirstName || ''} ${i.StaffLastName || ''}`}</Table.Cell>
          <Table.Cell>{i.Completed ? 'Yes' : 'No'}</Table.Cell>
          <Table.Cell>{this.formatDateForActionRequired(i.CompletedDate)}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return <TableEmptyMessage colSpan={colSpan} />;
    }
  };
  handleAddActionRequired = () => {
    return DoctorDetailsStore.toggleModal(true, {
      modalType: 'actionRequired',
    })();
  };
  render() {
    const { loading, isEditing, actionRequiredList } = DoctorDetailsStore;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              {!api.isOnlySearch() && <Button
                style={{ marginTop: '10px', cursor: `${isEditing ? 'pointer' : 'not-allowed'}` }}
                className={isEditing ? 'blue' : 'gray'}
                onClick={() => {
                  isEditing && this.handleAddActionRequired();
                }}
              >
                Add Action Required
              </Button>}
              <Table sortable striped celled className={cx({ disabled: loading })}>
                <TableHeader columns={columns} />
                <TableBody>{this.renderTableBody(actionRequiredList, columns.length)}</TableBody>
                <TableFooterPagination
                  colSpan={columns.length}
                  totalItems={actionRequiredList && !!actionRequiredList.length ? actionRequiredList.length : 0}
                  currentPage={actionRequiredList && !!actionRequiredList.length ? 1 : 0}
                  totalPage={actionRequiredList && !!actionRequiredList.length ? 1 : 0}
                  onPageClick={() => {}}
                />
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default ActionRequiredTab;
