import React from 'react';
import { Tab, Button, Dimmer, Loader, Popup, Icon, Dropdown } from 'semantic-ui-react';
import { Form, Radio, Select, Checkbox, DatePicker, Input, TimePicker, notification, Divider } from 'antd';
import dashboardStore from '@stores/dashboard';
import moment from 'moment-timezone';
import Modal from '../../shared/Modal/';
import ModalConfirm from '../../shared/Modal/ModalConfirm';
import TableFieldChangeHistory from './TableFieldChangeHistory';
import store from './store';
import customFetch from '@utils/customFetch';
import * as api from '../../../stores/api';
import {
  getSpecificLookupConfigByType,
  getRooms,
  getAssessmentsAndClinicServiceType,
  updateClinicServiceType,
  fetchClinic,
  getDoctorInfo,
  updateDoctorInfo,
  getClinicServicestype,
  fetchSpecialists,
  fetchAllClinic,
  getTableFieldChangeHistory,
} from './service';
import appStore from 'stores/app';
import { debounce, uniqBy } from 'lodash-es';
import { timezoneList } from './type';
import { toJS, action } from 'mobx';
import { getClinicInfo } from '@components/case-details-routes/AddEditClinic/services';
import { formatDate } from '../../../utils/functions';

class ModalSessionDetails extends React.Component {
  state = {
    sessionDetails: null,
    loading: true,
    openModal: false,
    openModalHistory: false,
    modalParams: {},
    rooms: [],
    clinicServiceTypes: [],
    clinicServiceTypesSeleted: [],
    numberOfDaysBeforeSessionDateThatClientReservationExpire: 10,
    clientReservationExpiresOn: 0,
    sessionExpiresOn: 0,
    numberOfDaysBeforeSessionDateThatSessionExpire: 0,
    clinics: [],
    specialists: [],
    fieldNameUpdated: null,
    doctorInfo: null,
    doctors: [],
    selectedAcceptedReportTypeIds: [],
    sessionDuration: 60,
    repeatOpt: 0,
    moreDetailOpt: 0,
    repeatTime: null,
    numberOfWeek: null,
    isSaved: false,
    isLocked: false,
    speacialistKeyword: '',
    clinicKeyword: '',
    specialistNumber: 30,
    isCopySession: false,
    viewAppointmentStatus: false,
  };
  getClientRevert = calendarId => {
    return customFetch('/Calendar/GetReservedForClientsAsync', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ calendarId }),
    });
  };
  getWaitingList = calendarId => {
    return customFetch('/Calendar/GetWaitingList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ calendarId }),
    });
  };
  componentDidMount() {
    const { id } = store.modalParams;
    var session = store.sessions.find(i => i.AssessmentId === parseInt(id));
    if (!session) {
      session = store.sessions.find(i => i.Id === parseInt(id));
    }
    if (session) {
      const doctorId = !api.isDoctor() ? session.DoctorId : api.currentUser.data.DoctorId;
      if ((api.isDoctor() && session.PatientId > 0) || api.isCaseManager()) {
        this.setState({ isLocked: true });
      } else {
        this.setState({ isLocked: false });
      }
      this.setDefaultSessionDuration(session.DateFrom, session.DateTo, session.TimeZone);
      Promise.all([
        getSpecificLookupConfigByType(),
        getRooms(session.ClinicId),
        getAssessmentsAndClinicServiceType(session.ClinicId, session.DoctorId),
        getDoctorInfo(doctorId),
        this.getClientRevert(session.Id),
        this.getWaitingList(session.SessionId),
        this.filterClinic(session.ClinicOption ?? 'doctor', true, session.ClinicId),
      ])
        .then(([specificLookupConfig, rooms, clinicServiceTypesSeleted, doctorInfo, clientRevert, waiting]) => {
          if (api.isCaseManager() && !!session.AssessmentId) {
            if (
              (!waiting ||
                !waiting.waitingList ||
                !waiting.waitingList.find(i => i.Id === api.currentUser.data.CaseManagerId)) &&
              session.CaseManagerId !== api.currentUser.data.CaseManagerId
            ) {
              this.handleUpdateBookedSession(true);
            }
          }
          if (!!waiting && !!waiting.waitingList) {
            const { casemanagers } = store;
            waiting.waitingList.forEach(el => {
              if (!casemanagers.find(i => i.Id === el.Id)) {
                store.updateListCasemanagers(el);
              }
            });
          }
          return this.setState(
            {
              sessionDetails: {
                ...session,
                ReservedForClients: clientRevert.reservedForClients
                  ? clientRevert.reservedForClients.map(i => i.Id)
                  : [],
                WaitingList: waiting.waitingList ? waiting.waitingList.map(i => i.Id) : [],
                DoctorSubSpecialty:
                  doctorInfo && doctorInfo.subSpecialties && doctorInfo.subSpecialties.length > 0
                    ? doctorInfo.subSpecialties.map(i => i.label).join(', ')
                    : '',
                ClinicOption: session.ClinicOption ?? 'doctor',
              },
              rooms: rooms.itemList,
              clinicServiceTypes: specificLookupConfig.itemList,
              clinicServiceTypesSeleted: clinicServiceTypesSeleted.clinicServiceTypesSeleted,
              clientReservationExpiresOn: moment(session.DateFrom)
                .subtract(10, 'day')
                .startOf('day')
                .toDate(),
              sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
              numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                : null,
              numberOfDaysBeforeSessionDateThatClientReservationExpire: session.ClientReservationExpiresOn
                ? moment(session.DateFrom).diff(moment(session.ClientReservationExpiresOn), 'days')
                : 10,
              appDateFrom: session.DateFrom,
              appDateTo: session.DateTo,
              doctorInfo,
              selectedAcceptedReportTypeIds: JSON.parse(
                session.selectedAcceptedReportTypeIds || session.SpecialistAcceptsReportTypes,
              ),
            },
            () => {
              store.setFieldsValue({
                clients: uniqBy([...(clientRevert.reservedForClients || []), ...toJS(store.clients)], 'Id').filter(
                  i => !!i?.Id,
                ),
              });
              this.setState({ loading: false });
            },
          );
        })
        .catch(() => {});
    } else {
      const { currentDate, start, selectedClinic, selectedDoctor, addByClickTimeSlot, endDateSelected } = store;
      const doctorId = !api.isDoctor() ? null : api.currentUser.data.DoctorId;
      const date_ = currentDate ? currentDate : moment();
      const fromHour = addByClickTimeSlot ? +moment(currentDate).format('HH') : 9;
      const fromMinute = addByClickTimeSlot ? +moment(currentDate).format('mm') : 0;
      const toHour = addByClickTimeSlot
        ? endDateSelected
          ? +moment(endDateSelected).format('HH')
          : +moment(currentDate)
              .add(1, 'hour')
              .format('HH')
        : 9;
      const toMinutes = endDateSelected ? +moment(endDateSelected).format('mm') : 0;
      const session = {
        AssessmentId: 0,
        AssessmentMethod: false,
        AssessmentNumber: null,
        ClientReservationExpiresOn: null, //Date
        ClinicId: null,
        ClinicName: null,
        Confirmed: false,
        ConfirmedComment: null,
        CreatedByUser: null,
        CreatedDate: null,
        DateFrom: date_
          ? moment(date_, 'DD/MM/YYYY')
              .hours(fromHour)
              .minute(fromMinute)
          : moment()
              .hours(9)
              .minute(0),
        DateTo: date_
          ? moment(date_, 'DD/MM/YYYY')
              .hours(toHour)
              .minute(toMinutes)
          : moment()
              .hours(17)
              .minute(),
        DictationOption: 1,
        DoctorBookingsAndCancellationTerms: null,
        DoctorContractFileName: null,
        DoctorId: doctorId,
        DoctorName: null,
        DoctorProviderNo: null,
        DoctorProviderNo2: null,
        DoctorProviderNo2Note: null,
        DoctorProviderNo3: null,
        DoctorProviderNo3Note: null,
        DoctorProviderNo4: null,
        DoctorProviderNo4Note: null,
        DoctorProviderNoNote: null,
        DoctorServiceExclusion: null,
        DoctorSpecialty: null,
        DoctorSubSpecialty: null,
        DoctorTitle: null,
        EassessClaimantLocationChecklistReceived: false,
        EventSerie: null,
        Id: 0,
        IsAdminOrMagStaff: true,
        IsMagDirectSession: false,
        IsMultiOptionAppointment: false,
        IsReserved: false,
        IsTeleAssessment: false,
        ItemType: 0,
        PatientId: null,
        PatientName: null,
        ReservedForClients: [],
        RoomId: null,
        RoomName: null,
        SelectedDoctorProviderNoIdx: 1,
        SessionAcceptsType: 0,
        SessionExpiresOn: null,
        SessionId: null,
        SpecialistAcceptsReportTypes: '',
        TimeZone: store.timeZone || moment.tz.guess(),
        ClinicOption: 'mag',
      };

      // this.setDefaultSessionDuration(session.DateFrom, session.DateTo);

      if (selectedDoctor?.Id) {
        session['DoctorId'] = selectedDoctor?.Id;
        session['ClinicOption'] = 'doctor';
        if (selectedClinic?.Id) {
          session['ClinicId'] = selectedClinic?.Id;
        }
      }
      if (api.isDoctor() && session.PatientId > 0) {
        this.setState({ isLocked: true });
      } else {
        this.setState({ isLocked: false });
      }

      if (api.isDoctor()) {
        return Promise.all([getSpecificLookupConfigByType(), fetchClinic(doctorId), getDoctorInfo(doctorId)])
          .then(([specificLookupConfig, clinics, doctorInfo]) => {
            if (clinics.itemList.length > 0) {
              var clinic = clinics.itemList[0].Id;

              if (clinics.itemList.length > 1) {
                var nonMagClinic = clinics.itemList.find(x => x.Type === 'non-mag');
                if (nonMagClinic) {
                  clinic = nonMagClinic.Id;
                }
              }
              getRooms(clinic).then(room => {
                if (room && room.itemList) {
                  return this.setState(
                    {
                      sessionDetails: {
                        ...session,
                        DoctorProviderNo: doctorInfo.providerNumber,
                        DoctorProviderNo2: doctorInfo.providerNumber2Note,
                        DoctorProviderNo2Note: doctorInfo.providerNumber2,
                        DoctorProviderNo3: doctorInfo.providerNumber2Note,
                        DoctorProviderNo3Note: doctorInfo.providerNumber3,
                        DoctorProviderNo4: doctorInfo.providerNumber4Note,
                        DoctorProviderNo4Note: doctorInfo.providerNumber4,
                        DoctorProviderNoNote: doctorInfo.providerNumberNote,
                        RoomId: room.itemList[0].Id,
                        ClinicId: clinic,
                      },
                      rooms: room.itemList,
                      clinicServiceTypes: specificLookupConfig.itemList,
                      clientReservationExpiresOn: moment(session.DateFrom)
                        .subtract(10, 'day')
                        .startOf('day')
                        .toDate(),
                      sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
                      numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                        ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                        : null,
                      appDateFrom: session.DateFrom,
                      appDateTo: session.DateTo,
                      clinics: clinics.itemList,
                      doctorInfo,
                      //selectedAcceptedReportTypeIds: !!doctorInfo ? doctorInfo.accreditationList.map(d => d.Id) : [],
                    },
                    () => this.setState({ loading: false }),
                  );
                }
              });
            } else {
              return this.setState(
                {
                  sessionDetails: {
                    ...session,
                    DoctorProviderNo: doctorInfo.providerNumber,
                    DoctorProviderNo2: doctorInfo.providerNumber2Note,
                    DoctorProviderNo2Note: doctorInfo.providerNumber2,
                    DoctorProviderNo3: doctorInfo.providerNumber2Note,
                    DoctorProviderNo3Note: doctorInfo.providerNumber3,
                    DoctorProviderNo4: doctorInfo.providerNumber4Note,
                    DoctorProviderNo4Note: doctorInfo.providerNumber4,
                    DoctorProviderNoNote: doctorInfo.providerNumberNote,
                  },
                  clinicServiceTypes: specificLookupConfig.itemList,
                  clientReservationExpiresOn: moment(session.DateFrom)
                    .subtract(10, 'day')
                    .startOf('day')
                    .toDate(),
                  sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
                  numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                    ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                    : null,
                  appDateFrom: session.DateFrom,
                  appDateTo: session.DateTo,
                  clinics: clinics.itemList,
                  doctorInfo,
                },
                () => this.setState({ loading: false }),
              );
            }
          })
          .catch(() => {});
      } else {
        if (selectedDoctor) {
          //console.log(selectedDoctor);
          if (selectedClinic) {
            return Promise.all([
              getSpecificLookupConfigByType(),
              fetchClinic(selectedDoctor?.Id),
              fetchSpecialists(),
              getRooms(selectedClinic?.Id),
            ])
              .then(([specificLookupConfig, clinics, doctors, rooms]) => {
                return this.setState(
                  {
                    sessionDetails: {
                      ...session,
                      RoomId: (rooms.itemList || [])[0].Id,
                    },
                    doctors: doctors.itemList,
                    rooms: rooms.itemList,
                    clinicServiceTypesSeleted: [],
                    clinicServiceTypes: specificLookupConfig.itemList,
                    clientReservationExpiresOn: moment(session.DateFrom)
                      .subtract(10, 'day')
                      .startOf('day')
                      .toDate(),
                    sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
                    numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                      ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                      : null,
                    appDateFrom: session.DateFrom,
                    appDateTo: session.DateTo,
                    clinics: clinics.itemList,
                    //selectedAcceptedReportTypeIds: acceptedTypes,
                    ClinicOption: 'specialist',
                  },
                  () => this.setState({ loading: false }),
                );
              })
              .catch(() => {});
          }
          return Promise.all([getSpecificLookupConfigByType(), fetchClinic(selectedDoctor?.Id), fetchSpecialists()])
            .then(([specificLookupConfig, clinics, doctors]) => {
              return this.setState(
                {
                  sessionDetails: {
                    ...session,
                    RoomId: null,
                  },
                  doctors: doctors.itemList,
                  rooms: [],
                  clinicServiceTypesSeleted: [],
                  clinicServiceTypes: specificLookupConfig.itemList,
                  clientReservationExpiresOn: moment(session.DateFrom)
                    .subtract(10, 'day')
                    .startOf('day')
                    .toDate(),
                  sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
                  numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                    ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                    : null,
                  appDateFrom: session.DateFrom,
                  appDateTo: session.DateTo,
                  clinics: clinics.itemList,
                  ClinicOption: 'specialist',
                },
                () => this.setState({ loading: false }),
              );
            })
            .catch(() => {});
        }
        return Promise.all([getSpecificLookupConfigByType(), fetchSpecialists(), fetchAllClinic('', null, 30, 'mag')])
          .then(([specificLookupConfig, doctors, clinics]) => {
            return this.setState(
              {
                sessionDetails: session,
                clinicServiceTypes: specificLookupConfig.itemList,
                clientReservationExpiresOn: moment(session.DateFrom)
                  .subtract(10, 'day')
                  .startOf('day')
                  .toDate(),
                sessionExpiresOn: session.SessionExpiresOn ? moment(session.SessionExpiresOn) : null,
                numberOfDaysBeforeSessionDateThatSessionExpire: session.SessionExpiresOn
                  ? moment(session.DateFrom).diff(moment(session.SessionExpiresOn), 'days')
                  : null,
                appDateFrom: session.DateFrom,
                appDateTo: session.DateTo,
                clinics: clinics.itemList,
                doctors: doctors.itemList,
                ClinicOption: 'mag',
              },
              () => this.setState({ loading: false }),
            );
          })
          .catch(() => {});
      }
    }
  }

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  setDefaultSessionDuration = (from, to, timeZone) => {
    if (!from || !to) {
      return;
    }
    let fromDate = null;
    if (typeof from === 'string' && from.search('/Date') !== -1){
      fromDate = this.convertDate(from).tz(timeZone || this.timeZone);
    } else {
      fromDate = moment(from).tz(timeZone || this.timeZone);
    }
    let toDate = null;
    if (typeof to === 'string' && to.search('/Date') !== -1){
      toDate = this.convertDate(to).tz(timeZone || this.timeZone);
    } else {
      toDate = moment(to).tz(timeZone || this.timeZone);
    }

    const diff = Math.abs(moment(fromDate).diff(moment(toDate), 'minutes'));

    this.setState({
      sessionDuration: diff,
    });

    if (!this.sessionDurationList.includes(diff)) {
      this.sessionDurationList.push(diff);
      this.sessionDurationList.sort();
    }
  };
  sessionDurationList = [15, 20, 30, 45, 60, 75, 90, 105, 120, 150, 180, 240];
  componentDidUpdate() {
    const { fieldNameUpdated, doctors, sessionDetails } = this.state;
    if (fieldNameUpdated) {
      if (fieldNameUpdated.key === 'clinicId') {
        Promise.all([getRooms(fieldNameUpdated.value), getClinicServicestype(fieldNameUpdated.value)])
          .then(([rooms, clinicServiceTypesSeleted]) => {
            this.setState({
              rooms: rooms.itemList,
              fieldNameUpdated: null,
              clinicServiceTypesSeleted: clinicServiceTypesSeleted.clinicServiceTypesSeleted,
              sessionDetails: {
                ...sessionDetails,
                RoomId: !!rooms.itemList.length ? rooms.itemList[0].Id : null,
              },
            });
          })
          .catch(() => {});
        return this.setState({ fieldNameUpdated: null });
      } else if (fieldNameUpdated.key === 'specialistId') {
        const specialistSelected = doctors.find(i => i.Id === fieldNameUpdated.value);
        if (specialistSelected) {
          let acceptedTypes =
            specialistSelected &&
            specialistSelected.AcceptedReportTypes &&
            specialistSelected.AcceptedReportTypes.length > 0
              ? specialistSelected.AcceptedReportTypes.map(t => t.Id)
              : [];
          console.log(acceptedTypes);
          Promise.all([getDoctorInfo(specialistSelected.Id), fetchClinic(specialistSelected?.Id)])
            .then(([doctor, clinics]) => {
              if (clinics.itemList.length > 0) {
                var clinic = clinics.itemList[0].Id;

                if (clinics.itemList.length > 1) {
                  var nonMagClinic = clinics.itemList.find(x => x.Type === 'non-mag');
                  if (nonMagClinic) {
                    clinic = nonMagClinic.Id;
                  }
                }
                getRooms(clinic).then(room => {
                  if (room && room.itemList) {
                    this.setState({
                      rooms: room.itemList,
                      sessionDetails: { ...sessionDetails, RoomId: room.itemList[0].Id, ClinicId: clinic },
                    });
                  }
                });
              }

              this.setState({
                doctorInfo: doctor,
                fieldNameUpdated: null,
                clinics: clinics.itemList,
              });
            })
            .catch(() => {});
        }
        return this.setState({ fieldNameUpdated: null });
      }
      return;
    } else return;
  }
  componentWillUnmount() {
    store.setFieldsValue({ addByClickTimeSlot: false, endDateSelected: null });
  }
  parseTimeRangeItem = time => {
    if (time < 10) {
      return { label: '0' + time, value: '0' + time };
    } else {
      return { label: time, value: time };
    }
  };

  showError = (message = '') => {
    notification.destroy();
    notification.error({
      message: 'Error',
      description: message,
    });
  };

  handleValidate = () => {
    const { sessionDetails, repeatTime, numberOfWeek } = this.state;
    if (!sessionDetails.ClinicId) {
      this.showError('Please select a clinic');
      return false;
    }
    if (!sessionDetails.DoctorId) {
      this.showError('Please select a specialist');
      return false;
    }
    if (!sessionDetails.RoomId) {
      this.showError('Please select a room');
      return false;
    }
    if (!sessionDetails.DateFrom) {
      this.showError('Please select starting date');
      return false;
    }
    if (this.state.repeatOpt !== 0 && !repeatTime) {
      this.showError('Please enter no of occurrences');
      return false;
    }
    if (this.state.repeatOpt === 4 && !numberOfWeek) {
      this.showError('Please enter number of week interval');
      return false;
    }
    return true;
  };

  handleUpdateSession = type => {
    const {
      sessionDetails,
      appDateTo,
      appDateFrom,
      numberOfDaysBeforeSessionDateThatClientReservationExpire,
      numberOfDaysBeforeSessionDateThatSessionExpire,
      selectedAcceptedReportTypeIds,
    } = this.state;
    let typeStr = '';
    if (type === 1) {
      typeStr = 'only this session?';
    } else if (type === 2) {
      typeStr = 'this and other available sessions in the series?';
    }

    return this.setState({
      openModal: true,
      modalParams: {
        message: `Are you sure to update ${typeStr}`,
        onOk: () => {
          this.setState({ loading: true });

          if (!this.handleValidate()) {
            return this.setState({ loading: false });
          }

          customFetch('/Calendar/Update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              OldDateFrom: moment(appDateFrom).toISOString(),
              OldDateTo: moment(appDateTo).toISOString(),
              AppDateFrom: moment(sessionDetails.DateFrom),
              AppDateTo: moment(sessionDetails.DateTo),
              TimeZone: sessionDetails.TimeZone,
              Id: sessionDetails.Id,
              NewRoomId: sessionDetails.RoomId,
              ClinicOption: sessionDetails.ClinicOption,
              Type: type,
              EventSerie: sessionDetails.EventSerie,
              Confirmed: sessionDetails.Confirmed,
              ConfirmedComment: sessionDetails.ConfirmedComment,
              SelectedDoctorProviderNoIdx: sessionDetails.SelectedDoctorProviderNoIdx,
              IsTeleAssessment: sessionDetails.IsTeleAssessment,
              DictationOption: parseInt(sessionDetails.DictationOption),
              SessionAcceptsType: parseInt(sessionDetails.SessionAcceptsType),
              ReservedForCompanyIds: [...sessionDetails.ReservedForClients],
              NumberOfDaysBeforeSessionDateThatClientReservationExpire: numberOfDaysBeforeSessionDateThatClientReservationExpire,
              NumberOfDaysBeforeSessionDateThatSessionExpire: numberOfDaysBeforeSessionDateThatSessionExpire || -1,
              SpecialistAcceptsReportTypes: JSON.stringify(selectedAcceptedReportTypeIds),
            }),
          })
            .then(res => {
              if (res.status === 'success') {
                notification.destroy();
                notification.success({
                  message: 'Success',
                  description: 'Data has been saved successfully.',
                });
                this.setState({ isSaved: true, loading: false });
              } else {
                notification.destroy();
                notification.error({
                  message: 'Error',
                  description: 'Error occured while updating data, please try again later.',
                });
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        },
      },
    });
  };
  handleUpdateBookedSession = (addWaitingList = false) => {
    this.setState({
      openModal: true,
      modalParams: {
        message: addWaitingList
          ? 'Notify me if this session becomes available again'
          : 'Do you want to save changes to this appointment?',
        onOk: () => {
          this.setState({ loading: true });
          const { sessionDetails } = this.state;
          const newStartTime = store.getMomentDateTime(sessionDetails.DateFrom, sessionDetails.TimeZone);

          const newEndTime = store.getMomentDateTime(sessionDetails.DateTo, sessionDetails.TimeZone);

          if (!this.handleValidate()) {
            return this.setState({ loading: false });
          }
          let waitlist = sessionDetails.WaitingList;
          if (addWaitingList) {
            waitlist = [...waitlist, api.currentUser.data.CaseManagerId];
          }

          customFetch('/MedicalService/UpdateBookedSession', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: sessionDetails.AssessmentId,
              appDateFrom: newStartTime,
              appDateTo: newEndTime,
              timeZone: sessionDetails.TimeZone,
              newClinicId: sessionDetails.ClinicId,
              newRoomId: sessionDetails.RoomId,
              clinicConfirmed: sessionDetails.Confirmed,
              confirmedComment: sessionDetails.ConfirmedComment,
              sessionId: sessionDetails.SessionId,
              isMultiOption: sessionDetails.IsMultiOptionAppointment,
              selectedDoctorProviderNoIdx: sessionDetails.SelectedDoctorProviderNoIdx,
              waitingList: waitlist,
            }),
          })
            .then(res => {
              if (res.status === 'success') {
                notification.destroy();
                notification.success({
                  message: 'Success',
                  description: 'Data has been saved successfully.',
                });
                this.setState({ isSaved: true, loading: false });
              } else {
                this.setState({ loading: false });
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        },
      },
    });
  };
  handleDeleteSessions = type => {
    let typeStr = '';
    if (type === 1) {
      typeStr = 'only this session?';
    } else if (type === 2) {
      typeStr = 'this and other available sessions in the series?';
    } else if (type === 3) {
      typeStr = 'this and other available sessions in the series on this day?';
    }
    const { sessionDetails } = this.state;
    this.setState({ loading: true });
    const bodyParam = {
      AppDateFrom: moment(sessionDetails.DateFrom).toISOString(),
      AppDateTo: moment(sessionDetails.DateTo).toISOString(),
      ClinicId: sessionDetails.ClinicId,
      DoctorId: sessionDetails.DoctorId,
      EventSerie: sessionDetails.EventSerie,
      Id: sessionDetails.Id,
      NewRoomId: sessionDetails.RoomId,
      Type: type,
    };
    return this.setState({
      openModal: true,
      modalParams: {
        message: `Do you want to delete ${typeStr}`,
        onOk: () =>
          customFetch('/Calendar/Delete', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyParam),
          })
            .then(res => {
              if (res.status === 'success') {
                notification.destroy();
                notification.success({
                  message: 'Success',
                  description: 'Data has been delete successfully.',
                });
                store.toggleModal(false)();
                return store.calendarViewMode === 'dayGridMonth' || !!store.clinicId || store.dayWeekViewType === 'all'
                  ? store.handleSearch(store.doctorId)
                  : store.handleSearch();
              } else {
                notification.destroy();
                notification.error({
                  message: 'Error',
                  description: 'Error occured while updating data, please try again later.',
                });
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            }),
      },
    });
  };
  renderDeleteSessionTab = () => {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
          <label>Would you like to delete only this session or future sessions in the series</label>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Button
            style={{ width: '500px', marginRight: '10px' }}
            className="negative"
            onClick={() => this.handleDeleteSessions(1)}
          >
            Delete only this session
          </Button>
          <span>All other available sessions in the series will remain unchanged.</span>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Button
            className="negative"
            style={{ width: '500px', marginRight: '10px' }}
            onClick={() => this.handleDeleteSessions(3)}
          >
            Delete this and other available sessions on this day
          </Button>
          <span>This and other available sessions in the series on this day will be deleted.</span>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Button
            className="negative"
            style={{ width: '500px', marginRight: '10px' }}
            onClick={() => this.handleDeleteSessions(2)}
          >
            Delete this and others available sessions
          </Button>
          <span>This and other available sessions in the series will be deleted.</span>
        </div>
      </React.Fragment>
    );
  };
  compareDate = (date1, date2) => {
    return moment.isMoment(date1) ? date1.isBefore(date2) : moment(date1).isBefore(date2);
  };

  calculateSessionExpiresOn = () => {
    const { numberOfDaysBeforeSessionDateThatSessionExpire, sessionDetails } = this.state;
    const sessionDate = sessionDetails.DateTo;
    if (!sessionDetails.Confirmed || !sessionDate || !numberOfDaysBeforeSessionDateThatSessionExpire) {
      return null;
    }
    let result = moment(sessionDate)
      .subtract(numberOfDaysBeforeSessionDateThatSessionExpire, 'day')
      .startOf('day');
    return result;
  };
  calculateClientReservationExpiresOn = () => {
    const { numberOfDaysBeforeSessionDateThatClientReservationExpire, sessionDetails } = this.state;
    const sessionDate = sessionDetails.DateFrom;
    if (!sessionDate || !numberOfDaysBeforeSessionDateThatClientReservationExpire) {
      return null;
    }
    let clientReservationExpiresOn = moment(sessionDate)
      .subtract(numberOfDaysBeforeSessionDateThatClientReservationExpire, 'day')
      .startOf('day');
    return clientReservationExpiresOn;
  };

  filterClinic = async (options, loadData = false, clinicId = null) => {
    if (!loadData) {
      this.setState({
        sessionDetails: {
          ...this.state.sessionDetails,
          ClinicId: null,
        },
        clinics: [],
      });
    }
    if (options === 'doctor') {
      if (this.state.sessionDetails?.DoctorId > 0) {
        const res = await fetchClinic(this.state.sessionDetails?.DoctorId);
        this.setState({ clinics: res.itemList });
      } else {
        this.setState({
          open: true,
          modalParams: { message: 'Please select a specialist first!' },
        });
      }
    } else {
      const allClinics = await fetchAllClinic('', this.state.sessionDetails?.DoctorId, 30, options);
      this.setState({ clinics: allClinics.itemList });
    }
    if (loadData) {
      const data = await getClinicInfo(clinicId);
      this.setState({ clinics: uniqBy([...this.state.clinics, { ...data, Id: data.ID }], 'Id').filter(i => !!i?.Id) });
    }
  };

  handleChangeField = (fieldName, fieldType = 'textInput') => event => {
    const { sessionDetails, doctorInfo } = this.state;
    if (fieldName === 'teleAssessment') {
      return updateDoctorInfo(sessionDetails.DoctorId, event.target.value)
        .then(res => {
          if (res.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
            });
            return this.setState({
              doctorInfo: { ...doctorInfo, teleAssessment: event.target.value },
            });
          }
        })
        .catch(() => {});
    } else if (fieldName === 'numberOfWeek' || fieldName === 'repeatTime') {
      const value = event.target.value;
      return this.setState({
        [fieldName]: value ? parseInt(value.replace(/\D/g, '').replace(/[^\d]/g, '')) : null,
      });
    } else if (fieldName === 'repeatOpt') {
      const value =
        fieldType === 'checkbox'
          ? event.target.checked
          : fieldType === 'textInput'
          ? event.target.value
          : fieldType === 'select'
          ? event
          : '';
      if (value === 1) {
        return this.setState({ [fieldName]: value, moreDetailOpt: 6 });
      } else {
        return this.setState({ [fieldName]: value, moreDetailOpt: 0 });
      }
    } else if (fieldName === 'DoctorId') {
      const doctorSelected = this.state.doctors.find(i => i.Id === event);
      if (doctorSelected) {
        let isOnboarded =
          doctorSelected.HasContractWithMag &&
          moment(doctorSelected.ContractWithMagDate).isBefore(moment()) &&
          (!doctorSelected.ContractWithMagEndDate || moment(doctorSelected.ContractWithMagEndDate).isAfter(moment()));

        let acceptedTypes =
          doctorSelected && doctorSelected.AcceptedReportTypes && doctorSelected.AcceptedReportTypes.length > 0
            ? doctorSelected.AcceptedReportTypes.map(t => t.Id)
            : [];

        if (!isOnboarded)
          return this.setState({
            openModal: true,
            modalParams: {
              message: 'This specialist does not have a contract with us. Do you want to continue?',
              onOk: () => {
                //console.log(doctorSelected);
                return this.setState({
                  sessionDetails: {
                    ...sessionDetails,
                    [fieldName]: doctorSelected.Id,
                    DoctorProviderNo: doctorSelected.ProviderNumber,
                    DoctorProviderNo2: doctorSelected.ProviderNumber2Note,
                    DoctorProviderNo2Note: doctorSelected.ProviderNumber2,
                    DoctorProviderNo3: doctorSelected.ProviderNumber2Note,
                    DoctorProviderNo3Note: doctorSelected.ProviderNumber3,
                    DoctorProviderNo4: doctorSelected.ProviderNumber4Note,
                    DoctorProviderNo4Note: doctorSelected.ProviderNumber4,
                    DoctorProviderNoNote: doctorSelected.ProviderNumberNote,
                    DoctorSubSpecialty: doctorSelected.SubSpecialty,
                  },
                  //selectedAcceptedReportTypeIds: acceptedTypes,
                  fieldNameUpdated: {
                    value: event,
                    key: 'specialistId',
                  },
                });
              },
            },
          });
        return this.setState({
          sessionDetails: {
            ...sessionDetails,
            [fieldName]: doctorSelected.Id,
            DoctorProviderNo: doctorSelected.ProviderNumber,
            DoctorProviderNo2: doctorSelected.ProviderNumber2Note,
            DoctorProviderNo2Note: doctorSelected.ProviderNumber2,
            DoctorProviderNo3: doctorSelected.ProviderNumber2Note,
            DoctorProviderNo3Note: doctorSelected.ProviderNumber3,
            DoctorProviderNo4: doctorSelected.ProviderNumber4Note,
            DoctorProviderNo4Note: doctorSelected.ProviderNumber4,
            DoctorProviderNoNote: doctorSelected.ProviderNumberNote,
            DoctorSubSpecialty: doctorSelected.SubSpecialty,
          },
          //selectedAcceptedReportTypeIds: acceptedTypes,
          fieldNameUpdated: {
            value: event,
            key: 'specialistId',
          },
        });
      }
      return;
    } else if (fieldName === 'ClinicId') {
      return this.setState({
        sessionDetails: {
          ...sessionDetails,
          [fieldName]: event,
        },
        fieldNameUpdated: {
          value: event,
          key: 'clinicId',
        },
      });
    } else if (fieldName === 'sessionDuration') {
      return this.setState({ sessionDuration: parseInt(event) });
    } else if (fieldName === 'availableDate') {
      const { DateFrom, DateTo } = sessionDetails;
      const dateFromHour = moment(DateFrom).hour();
      const dateFromMinute = moment(DateFrom).minute();
      const dateToHour = moment(DateTo).hour();
      const dateToMinute = moment(DateTo).minute();
      const dfrom = moment(event, 'DD/MM/YYYY')
        .hours(dateFromHour)
        .minute(dateFromMinute);
      const dto = moment(event, 'DD/MM/YYYY')
        .hours(dateToHour)
        .minute(dateToMinute);
      return this.setState(
        {
          sessionDetails: {
            ...sessionDetails,
            DateFrom: dfrom,
            DateTo: dto,
          },
        },
        () =>
          this.setState({
            sessionExpiresOn: this.calculateSessionExpiresOn(),
            clientReservationExpiresOn: this.calculateClientReservationExpiresOn(),
          }),
      );
    } else if (fieldName === 'numberOfDaysBeforeSessionDateThatSessionExpire') {
      const value = event.target.value;
      return this.setState(
        {
          [fieldName]: value ? parseInt(value.replace(/\D/g, '').replace(/[^\d]/g, '')) : null,
        },
        () => this.setState({ sessionExpiresOn: this.calculateSessionExpiresOn() }),
      );
    } else if (fieldName === 'numberOfDaysBeforeSessionDateThatClientReservationExpire') {
      const value = event.target.value || 0;
      return this.setState({
        [fieldName]: value ? parseInt(value.replace(/\D/g, '').replace(/[^\d]/g, '')) : null,
      });
    } else if (fieldName === 'ReservedForClients') {
      store.setFieldsValue({ numberClient: 30 });
      if (event.length > 10) return;
      return this.setState({
        sessionDetails: {
          ...sessionDetails,
          ReservedForClients: event,
        },
      });
    } else if (fieldName === 'DateTo' || fieldName === 'DateFrom') {
      if (fieldName === 'DateFrom') {
        if (!this.compareDate(sessionDetails.DateTo, event)) {
          return this.setState({
            sessionDetails: { ...sessionDetails, [fieldName]: event },
          });
        } else {
          return this.setState({
            sessionDetails: {
              ...sessionDetails,
              [fieldName]: event,
              DateTo: event,
            },
          });
        }
      } else {
        if (this.compareDate(sessionDetails.DateFrom, event)) {
          return this.setState({
            sessionDetails: { ...sessionDetails, [fieldName]: event },
          });
        } else {
          return this.setState({
            sessionDetails: {
              ...sessionDetails,
              [fieldName]: event,
              DateFrom: event,
            },
          });
        }
      }
    } else if (fieldName === 'clinicServiceTypesSeleted') {
      this.setState({ loading: true });
      const { clinicServiceTypes } = this.state;
      const selectedClinicServiceTypes = event
        .map(s => {
          const matchItem = clinicServiceTypes.find(i => i.Id === s);
          if (matchItem) {
            return matchItem;
          } else {
            return null;
          }
        })
        .filter(i => i);
      return updateClinicServiceType(selectedClinicServiceTypes, sessionDetails.ClinicId)
        .then(res => {
          if (res.status === 'success') {
            this.setState({ [fieldName]: event, loading: false });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else if (fieldName === 'moreDetailOpt') {
      const value =
        fieldType === 'checkbox'
          ? event.target.checked
          : fieldType === 'textInput'
          ? event.target.value
          : fieldType === 'select'
          ? event
          : '';
      return this.setState({ moreDetailOpt: value });
    } else if (fieldName === 'TimeZone') {
      const newTimeZone = event;
      return this.setState({
        sessionDetails: {
          ...sessionDetails,
          DateFrom: store.getMomentDateTime(sessionDetails.DateFrom, newTimeZone),
          DateTo: store.getMomentDateTime(sessionDetails.DateTo, newTimeZone),
          [fieldName]: newTimeZone,
        },
      });
    } else if (fieldName === 'ClinicOption') {
      const value =
        fieldType === 'checkbox'
          ? event.target.checked
          : fieldType === 'textInput'
          ? event.target.value
          : fieldType === 'select'
          ? event
          : '';
      return this.setState(
        {
          sessionDetails: { ...sessionDetails, [fieldName]: value },
        },
        () => this.filterClinic(value),
      );
    } else {
      const value =
        fieldType === 'checkbox'
          ? event.target.checked
          : fieldType === 'textInput'
          ? event.target.value
          : fieldType === 'select'
          ? event
          : '';
      return this.setState({
        sessionDetails: { ...sessionDetails, [fieldName]: value },
      });
    }
  };

  getClientReservationExpiresOnString = () => {
    const { sessionDetails, numberOfDaysBeforeSessionDateThatClientReservationExpire } = this.state;
    return moment(
      moment(sessionDetails.DateFrom)
        .subtract(numberOfDaysBeforeSessionDateThatClientReservationExpire, 'day')
        .startOf('day')
        .toDate(),
    ).format('DD/MM/YYYY');
  };
  getSessionExpiresOnString = () =>
    this.state.sessionExpiresOn ? moment(this.state.sessionExpiresOn).format('DD/MM/YYYY') : 'Never';

  renderAvailableDate = () => {
    const { sessionDetails } = this.state;
    const { DateFrom } = sessionDetails;
    return DateFrom ? (moment.isMoment(DateFrom) ? DateFrom : moment(DateFrom)) : null;
  };
  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return new Date(result.toISOString());
      }
    }
  };

  handleCopySession = () => {
    const { sessionDetails } = this.state;

    this.setState(
      {
        sessionDetails: {
          ...sessionDetails,
          Id: null,
        },
        isCopySession: true,
      },
      () => {
        this.setDefaultSessionDuration(sessionDetails.DateFrom, sessionDetails.DateTo, sessionDetails.TimeZone);
      },
    );
  };

  handleCreateSession = () => {
    const { sessionDetails, selectedAcceptedReportTypeIds } = this.state;

    const appointment = {
      DateFrom: sessionDetails.DateFrom,
      DateTo: sessionDetails.DateTo,
      TimeZone: sessionDetails.TimeZone,
      RoomId: sessionDetails.RoomId,
      DoctorId: sessionDetails.DoctorId,
      Confirmed: sessionDetails.Confirmed,
      ConfirmedComment: sessionDetails.ConfirmedComment,
      SelectedDoctorProviderNoIdx: sessionDetails.SelectedDoctorProviderNoIdx,
      IsTeleAssessment: sessionDetails.IsTeleAssessment,
      ClientReservationExpiresOn: this.state.clientReservationExpiresOn,
      SessionExpiresOn: this.state.sessionExpiresOn,
      //CIR-798
      DictationOption: parseInt(sessionDetails.DictationOption),
      //End CIR-798
      SessionAcceptsType: parseInt(sessionDetails.SessionAcceptsType),
      SpecialistAcceptsReportTypes: JSON.stringify(selectedAcceptedReportTypeIds),
      ClinicOption: sessionDetails.ClinicOption,
    };

    const option = {
      Option1: this.state.repeatOpt,
      Option2: this.state.moreDetailOpt,
      Times: this.state.repeatTime,
      sessionDuration: this.state.sessionDuration,
      NumberOfWeek: this.state.numberOfWeek || 0,
    };
    this.setState({ loading: true });
    const params = {
      cal: appointment,
      option: option,
      reservedForCompanyIds: [...sessionDetails.ReservedForClients],
    };
    if (!this.handleValidate()) {
      return this.setState({ loading: false });
    }
    return customFetch('/Calendar/CheckDuplicateSession', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    })
      .then(response => {
        if (response.status === 'success') {
          if (response.isDuplicated) {
            this.setState({
              openModal: true,
              modalParams: {
                message:
                  'There is already an session for the same doctor and on the same date. Do you want to continue?',
                onOk: () => {
                  return customFetch('/Calendar/BulkAddCalendar', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(params),
                  })
                    .then(res => {
                      if (res.status === 'success') {
                        notification.destroy();
                        notification.success({
                          message: 'Success',
                          description: 'Data has been saved successfully.',
                        });
                        store.calendarViewMode === 'dayGridMonth' || !!store.clinicId || store.dayWeekViewType === 'all'
                          ? store.handleSearch(store.doctorId)
                          : store.handleSearch();
                        return store.toggleModal(false, {})();
                      }
                    })
                    .catch(() => {
                      this.setState({ loading: false });
                    });
                },
              },
            });
          } else {
            return customFetch('/Calendar/BulkAddCalendar', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(params),
            })
              .then(res => {
                if (res.status === 'success') {
                  notification.destroy();
                  notification.success({
                    message: 'Success',
                    description: 'Data has been saved successfully.',
                  });
                  store.calendarViewMode === 'dayGridMonth' || !!store.clinicId || store.dayWeekViewType === 'all'
                    ? store.handleSearch(store.doctorId)
                    : store.handleSearch();
                  return store.toggleModal(false, {})();
                }
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }
        } else {
          if (response.status === 'sysError') {
            this.setState({ loading: false });
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Error occured while checking for duplicate sessions, please try again later.',
            });
          } else {
            this.setState({ loading: false });
            notification.destroy();
            notification.error({
              message: 'Error',
              description:
                'Error occured while checking for duplicate sessions, please check your input and try again.',
            });
          }
        }
      })
      .catch(() => {
        this.setState({ loading: false, isCopySession: false });
      });
  };

  SendRequestAddAppointment = sId => {
    localStorage.setItem('AddingAppointment', true);
    if (appStore.iframeMode) {
      let iframeData = {
        id: sId,
      };

      if (!api.isAdminOrMagStaffOrAccounting()) {
        iframeData.view = 'PostAssessment#Appointment';
      }
      window.parent.postMessage({ action: 'OPEN_CREATE_IME', data: iframeData }, '*');
    } else {
      localStorage.setItem('AssessmentCaseId', 'Book Assessment');
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?sId=${sId}`);
      });
    }
    return store.toggleModal(false, {})();
  };

  AddAppointment = type => {
    const { sessionDetails, selectedAcceptedReportTypeIds } = this.state;
    let exclusionTypesText = ''
    if(!!selectedAcceptedReportTypeIds && !!selectedAcceptedReportTypeIds.length){
      selectedAcceptedReportTypeIds.forEach(el => {
        let findObj = store.reportTypes.find(i => i.Id === el);
        if(!!findObj){
          exclusionTypesText += findObj.Name + ', ';
        }
      });
    }
    if(exclusionTypesText){
      exclusionTypesText = exclusionTypesText.substring(0, exclusionTypesText.length -2);
    }
    //console.log(exclusionTypesText)
    if (type === 'add') {
      if(!!exclusionTypesText && (api.isCaseManager() || api.isAdminOrMagStaffOrAccounting())){
        this.setState({
          openModal: true,
          modalParams: {
            message: 'This appointment does not accept the following report types: ' + exclusionTypesText + '. Do you want to continue?',
            onOk: () => {
              if (!sessionDetails.Confirmed) {
                this.setState({
                  openModal: true,
                  modalParams: {
                    message: api.isCaseManager()
                      ? 'This is an unconfirmed session. MAG staff will contact you within 24 hours to confirm the booking.'
                      : 'This is an unconfirmed availability session. Remember to contact specialist to confirm its availability and advise Booking CM.',
                    onOk: () => this.SendRequestAddAppointment(sessionDetails.Id),
                  },
                });
              } else {
                this.SendRequestAddAppointment(sessionDetails.Id);
              }
            },
          },
        });
      } else {
        if (!sessionDetails.Confirmed) {
          this.setState({
            openModal: true,
            modalParams: {
              message: api.isCaseManager()
                ? 'This is an unconfirmed session. MAG staff will contact you within 24 hours to confirm the booking.'
                : 'This is an unconfirmed availability session. Remember to contact specialist to confirm its availability and advise Booking CM.',
              onOk: () => this.SendRequestAddAppointment(sessionDetails.Id),
            },
          });
        } else {
          this.SendRequestAddAppointment(sessionDetails.Id);
        }
      }
    }
    if (type === 'view') {
      const caseNo = `Case ${sessionDetails.AssessmentNumber}`;
      const id = sessionDetails.AssessmentId;

      if (appStore.iframeMode) {
        let iframeData = {
          id: sessionDetails.AssessmentId,
        };

        if (!api.isAdminOrMagStaffOrAccounting()) {
          iframeData.view = 'PostAssessment#Appointment';
        }
        window.parent.postMessage({ action: 'OPEN_IME_DETAIL', data: iframeData }, '*');
      } else {
        localStorage.setItem('AssessmentCaseId', caseNo);
        dashboardStore.close('/view/add-edit-ime-assessment-2');
        setTimeout(() => {
          dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}`);
        });
      }

      return store.toggleModal(false, {})();
    }
  };
  handleSearch = fieldName =>
    debounce(async value => {
      if (fieldName === 'DoctorId') {
        this.setState({ speacialistKeyword: value });
        return fetchSpecialists(value, 30).then(res => {
          this.setState({ doctors: res.itemList });
        });
      }
      if (fieldName === 'ClinicId') {
        if (this.state.sessionDetails.ClinicOption === 'doctor') {
          if (this.state.sessionDetails?.DoctorId > 0) {
            const res = await fetchClinic(this.state.sessionDetails?.DoctorId, value);
            this.setState({ clinics: res.itemList });
          } else {
            this.setState({
              open: true,
              modalParams: { message: 'Please select a specialist first!' },
            });
          }
        } else {
          const allClinics = await fetchAllClinic(
            value,
            this.state.sessionDetails?.DoctorId,
            30,
            this.state.sessionDetails.ClinicOption,
          );
          this.setState({ clinics: allClinics.itemList });
        }
      }
      if (fieldName === 'clients') {
        return store.setFieldsValue({ keywordClient: value, numberClient: 30 });
      }

      if (fieldName === 'casemanagers') {
        return store.setFieldsValue({ keywordCaseManager: value, numberCaseManager: 30 });
      }
    }, 1000);
  handleScroll = fieldName => event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      if (fieldName === 'casemanagers') {
        return store.setFieldsValue({ keywordCaseManager: store.numberCaseManager + 30 });
      }
      if (fieldName === 'clients') {
        return store.setFieldsValue({ numberClient: store.numberClient + 30 });
      }
      const { specialistNumber } = this.state;
      return fetchSpecialists(this.state.speacialistKeyword, +specialistNumber + 30).then(res => {
        this.setState({ doctors: res.itemList });
        this.setState({ specialistNumber: +specialistNumber + 30 });
      });
    }
  };

  @action setAcceptedReportTypes = (_, { value }) => {
    this.setState({ selectedAcceptedReportTypeIds: value });
  };

  handleFieldRecentChange = async (record, fieldName) => {
    try {
      let title = '';
      if (fieldName === 'DictationOption') title = 'Dictation and Report Typing by';
      const response = await getTableFieldChangeHistory('Calendar', record.Id, fieldName, 1, 1000);
      const dataFieldChange = response.itemList;
      if (response.status === 'success') {
        //console.log(dataFieldChange);
        store.setFieldsValue({
          openModalHistory: true,
          tableFileChangeTitle: title,
          tableDataFieldChange: dataFieldChange,
        });
        return this.setState({ ...this.state, openModalHistory: true });
        //return store.setFieldsValue({ openModalHistory: true, tableFileChangeTitle: title, tableDataFieldChange: dataFieldChange });
        //return store.toggleModal(true, { modalType: 'fieldChange', title: title, dataFieldChange })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  convertDate = dateStr => {
    if (dateStr) {
      return dateStr ? (moment.isMoment(dateStr) ? dateStr : moment(dateStr)) : null;
    }
    return null;
  };

  renderFormEditSession = () => {
    const {
      sessionDetails,
      rooms,
      clinicServiceTypes,
      clinicServiceTypesSeleted,
      doctorInfo,
      sessionDuration,
      clinics,
      doctors,
      isLocked,
      isCopySession,
      selectedAcceptedReportTypeIds,
    } = this.state;
    //console.log(selectedAcceptedReportTypeIds)
    const { clients, casemanagers, timeZone } = store;
    return (
      <Form className="form-container-add-edit-sessions">
        <Form.Item
          label={
            <>
              Specialist
              {!!doctorInfo?.id && api.isAdminOrMagStaffOrAccounting() && (
                <span
                  onClick={() => {
                    if (api.isDoctor()) {
                      dashboardStore.open('/view/dr-account-2');
                    }
                    if (api.isAdminOrMagStaffOrAccounting()) {
                      dashboardStore.open(`/view/add-edit-doctor-2?id=${doctorInfo?.id ?? doctorInfo.Id}`);
                    }
                  }}
                  className="field-actions"
                >
                  {' '}
                  View Video Appointment Status
                </span>
              )}
            </>
          }
          required
          className="fluid-field"
        >
          <Select
            showSearch
            size="large"
            optionFilterProp="children"
            disabled={api.isDoctor() || !!sessionDetails.Id || isCopySession}
            value={sessionDetails.DoctorId}
            onChange={this.handleChangeField('DoctorId', 'select')}
            placeholder="Select a specialist"
            onSearch={this.handleSearch('DoctorId')}
            onPopupScroll={this.handleScroll('DoctorId')}
          >
            {!api.isDoctor() && !!!sessionDetails.Id ? (
              doctors.map(({ Id, FullName, Title, Specialty }) => (
                <Select.Option key={Id} value={Id}>
                  {`${Id} - ${Title} ${FullName} - ${Specialty}`}
                </Select.Option>
              ))
            ) : (
              <Select.Option value={doctorInfo.id}>
                {doctorInfo.id} - {doctorInfo.title} {doctorInfo.firstName} {doctorInfo.lastName}{' '}
                {`${doctorInfo.specialties ? ` - ${doctorInfo.specialties.map(i => i.label).join(', ')}` : ''}`}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        {doctorInfo?.doctorBookingsAndCancellationTerms && (
          <Form.Item label="Booking and Cancellation Terms" className="fluid-field">
            <Input.TextArea disabled value={doctorInfo.doctorBookingsAndCancellationTerms} rows={3} />
          </Form.Item>
        )}
        {doctorInfo?.DoctorSubSpecialty && (
          <div className="ant-row ant-form-item fluid-field">Sub-Specialty: {sessionDetails.DoctorSubSpecialty}</div>
        )}
        {doctorInfo && doctorInfo?.isNotCurrentlyInterested && (this.convertDate(doctorInfo?.notCurrentlyInterestedEndDate) > moment() || !doctorInfo?.notCurrentlyInterestedEndDate) && (
          <div style={{ width: '100%', display: 'inline-block' }}>
          <React.Fragment>
            <Form.Item label={`NCI Start Date`} style={{ width: '49%', marginRight: '2%', float: 'left' }}>
              <DatePicker
                style={{ width: '266px' }}
                size="large"
                format="DD/MM/YYYY"
                disabled={true}
                value={this.convertDate(doctorInfo?.notCurrentlyInterestedDate)}
              />
            </Form.Item>
            <Form.Item label={`NCI End Date`} style={{ width: '49%', float: 'right' }}>
              <DatePicker
                style={{ width: '266px' }}
                size="large"
                format="DD/MM/YYYY"
                disabled={true}
                value={this.convertDate(doctorInfo?.notCurrentlyInterestedEndDate)}
              />
            </Form.Item>
          </React.Fragment>
          </div>
        )}
        <Divider className="fluid-field" />
        <Form.Item required label={`${!!sessionDetails.Id ? 'Date' : 'Starting Date'}`}>
          <DatePicker
            style={{ width: '266px' }}
            size="large"
            format="DD/MM/YYYY"
            disabled={isLocked || !!sessionDetails.AssessmentNumber || !!sessionDetails.PatientId}
            value={this.renderAvailableDate()}
            onChange={this.handleChangeField('availableDate')}
          />
        </Form.Item>
        <div style={{ width: '100%', display: 'inline-block' }}>
          {/* <Form.Item required label={`${!!sessionDetails.Id ? 'Date' : 'Starting Date'}`}>
            
          </Form.Item> */}
          {/* <Form.Item>
            <Input size="large" value={sessionDetails.DateFrom} />
          </Form.Item> */}
          <Form.Item
            label={`${!!sessionDetails.Id ? 'Time' : 'Available Time'}`}
            style={{ width: '49%', marginRight: '2%', float: 'left' }}
          >
            <TimePicker
              format="HH:mm"
              showTime
              size="large"
              disabled={isLocked || !!sessionDetails.AssessmentNumber || !!sessionDetails.PatientId}
              allowClear={false}
              style={{ width: '128px', marginRight: '10px' }}
              value={store.getMomentDateTime(sessionDetails.DateFrom, sessionDetails.TimeZone)}
              onChange={this.handleChangeField('DateFrom')}
            />
            <TimePicker
              style={{ width: '128px' }}
              format="HH:mm"
              showTime
              allowClear={false}
              size="large"
              disabled={isLocked || !!sessionDetails.AssessmentNumber || !!sessionDetails.PatientId}
              value={store.getMomentDateTime(sessionDetails.DateTo, sessionDetails.TimeZone)}
              onChange={this.handleChangeField('DateTo')}
            />
            <Select
              showSearch
              size="large"
              required
              optionFilterProp="children"
              disabled={isLocked || !!sessionDetails.AssessmentNumber || !!sessionDetails.PatientId}
              value={sessionDetails.TimeZone}
              style={{ width: '200px', marginLeft: '10px' }}
              onChange={this.handleChangeField('TimeZone')}
            >
              {timezoneList.map(i => (
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!!!sessionDetails.Id && (
            <Form.Item label="Session Duration (minutes)" style={{ width: '49%', float: 'right' }}>
              <Select
                size="large"
                onChange={this.handleChangeField('sessionDuration', 'select')}
                value={sessionDuration}
                disabled={isLocked}
                onSearch={e => {
                  if (e.length > 0 && !isNaN(+e)) {
                    this.setFieldValue({ sessionDuration: e });
                  }
                }}
                filterOption={(input, option) => {
                  return option.key.indexOf(input) >= 0;
                }}
              >
                <Select.Option style={{ display: 'none' }} key={15} value={15}>
                  15
                </Select.Option>
                {this.sessionDurationList.map(i => (
                  <Select.Option key={i} value={i}>
                    {i}
                  </Select.Option>
                ))}
                {this.sessionDuration && !this.sessionDurationList.filter(o => o === +this.sessionDuration).length && (
                  <Select.Option key={this.sessionDuration} value={this.sessionDuration}>
                    {this.sessionDuration}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          )}
        </div>
        {!!!sessionDetails.Id && (
          <Form.Item label="Appointment Recurrence" className="fluid-field" required>
            <Radio.Group
              onChange={this.handleChangeField('repeatOpt')}
              value={this.state.repeatOpt}
              disabled={isLocked || isCopySession}
            >
              <Radio value={0}>None</Radio>
              <Radio value={1}>Daily</Radio>
              <Radio value={2}>Weekly</Radio>
              <Radio value={3}>Fortnightly</Radio>
              <Radio value={4}>Number of Week Interval</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {!!!sessionDetails.Id && this.state.repeatOpt === 4 && (
          <Form.Item label="Number of Week Interval" className="fluid-field" required>
            <Input
              value={this.state.numberOfWeek}
              disabled={isCopySession}
              onChange={this.handleChangeField('numberOfWeek')}
            />
          </Form.Item>
        )}
        {!!!sessionDetails.Id && this.state.repeatOpt !== 0 && (
          <Form.Item label="No of Occurrences" className="fluid-field" required>
            <Input
              value={this.state.repeatTime}
              disabled={isCopySession}
              onChange={this.handleChangeField('repeatTime')}
            />
          </Form.Item>
        )}
        <Divider className="fluid-field" />
        <Form.Item label="Appointment Type" className="fluid-field">
          <Radio.Group
            value={sessionDetails.SessionAcceptsType}
            onChange={this.handleChangeField('SessionAcceptsType')}
            disabled={isLocked || isCopySession}
          >
            <Radio value={0}>
              In-person Assessment Only{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="This session accepts in-person appointment only. Video appointment is not accepted."
                position="top center"
                wide="very"
                style={{ marginLeft: '10px' }}
              />
            </Radio>
            <Radio value={1}>
              Video Assessment Only{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="This session accepts video appointment only. Make sure to update video session details in “Require Video Assessment” section."
                position="top center"
                wide="very"
                style={{ marginLeft: '10px' }}
              />
            </Radio>
            <Radio value={2}>
              Either In-person or Video Assessment{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="This session accepts either in-person or video appointment. If video apointment is requested, make sure to update video session details in “Require Video Assessment” section."
                position="top center"
                wide="very"
                style={{ marginLeft: '10px' }}
              />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Appointment Availability Type" className="fluid-field">
          <Radio.Group
            value={sessionDetails.Confirmed}
            onChange={this.handleChangeField('Confirmed')}
            disabled={isLocked || !!sessionDetails.AssessmentId || isCopySession}
          >
            <Radio disabled={(api.isDoctor() && sessionDetails.PatientId > 0) || api.isCaseManager()} value={true}>
              Confirmed Available{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Both date and time are confirmed available"
                position="top center"
                wide="very"
                style={{ marginLeft: '10px' }}
              />
            </Radio>
            <Radio disabled={(api.isDoctor() && sessionDetails.PatientId > 0) || api.isCaseManager()} value={false}>
              Unconfirmed Available{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Date is confirmed, time is not yet confirmed available"
                position="top center"
                wide="very"
                style={{ marginLeft: '10px' }}
              />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Divider className="fluid-field" />
        <Form.Item label="Clinic Type" className="fluid-field" style={{ display: api.isDoctor() ? 'none' : 'block' }}>
          <Radio.Group
            style={{ display: 'flex' }}
            value={sessionDetails.ClinicOption}
            onChange={this.handleChangeField('ClinicOption')}
            disabled={isLocked || isCopySession || api.isDoctor()}
          >
            <Radio value="mag" className="appointment-clinic-type">
              <span>MLP's Owned Clinic</span>
            </Radio>
            <Radio value="doctor" className="appointment-clinic-type">
              Specialist's Owned Clinic
            </Radio>
            <Radio value="obtain" className="appointment-clinic-type">
              <span>Obtained Appt</span>
            </Radio>
            <Radio value="home" className="appointment-clinic-type">
              Home Visit
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div style={{ width: '100%', display: 'inline-block' }}>
          <Form.Item
            required
            label="Clinic Centre (including for Telehealth)"
            style={{ width: '49%', marginRight: '2%', float: 'left' }}
          >
            <Select
              showSearch
              disabled={isLocked || isCopySession}
              size="large"
              optionFilterProp="children"
              value={sessionDetails.ClinicId}
              onChange={this.handleChangeField('ClinicId', 'select')}
              placeholder="Select a Clinic"
              onSearch={this.handleSearch('ClinicId')}
            >
              {clinics.map(({ ID, Id, Name }) => (
                <Select.Option key={ID ?? Id} value={ID ?? Id}>
                  {Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Room" style={{ width: '49%', float: 'right', display: api.isDoctor() ? 'none' : 'block' }}>
            <Select
              showSearch
              size="large"
              disabled={isLocked || isCopySession}
              optionFilterProp="children"
              value={sessionDetails.RoomId}
              onChange={this.handleChangeField('RoomId', 'select')}
            >
              {rooms.map(({ Id, Name }) => (
                <Select.Option key={Id} value={Id}>
                  {Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {doctorInfo && (
          <Form.Item
            label="Specialist Provider No"
            className="fluid-field"
            style={{ display: api.isDoctor() ? 'none' : 'block' }}
          >
            <Radio.Group
              disabled={isLocked || isCopySession}
              onChange={this.handleChangeField('SelectedDoctorProviderNoIdx')}
              value={sessionDetails.SelectedDoctorProviderNoIdx}
            >
              {sessionDetails.DoctorProviderNo && (
                <Radio value={1}>
                  {sessionDetails.DoctorProviderNo} - {sessionDetails.DoctorProviderNoNote}
                </Radio>
              )}
              {sessionDetails.DoctorProviderNo2 && (
                <Radio value={2}>
                  {sessionDetails.DoctorProviderNo2} - {sessionDetails.DoctorProviderNo2Note}
                </Radio>
              )}
              {sessionDetails.DoctorProviderNo3 && (
                <Radio value={3}>
                  {sessionDetails.DoctorProviderNo3} - {sessionDetails.DoctorProviderNo3Note}
                </Radio>
              )}
              {sessionDetails.DoctorProviderNo4 && (
                <Radio value={4}>
                  {sessionDetails.DoctorProviderNo4} - {sessionDetails.DoctorProviderNo4Note}
                </Radio>
              )}
            </Radio.Group>
          </Form.Item>
        )}
        {api.isAdminOrMagStaffOrAccounting() && !!sessionDetails.ClinicId && (
          <Form.Item label="Clinic Service" style={{ marginBottom: '0px' }}>
            <Checkbox.Group
              value={clinicServiceTypesSeleted}
              className="checkbox-field"
              disabled={isLocked || isCopySession}
              onChange={this.handleChangeField('clinicServiceTypesSeleted')}
            >
              {clinicServiceTypes.map((i, idx) => (
                <Checkbox style={{ marginLeft: '10px' }} key={idx} value={i.Id}>
                  {i.Label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
        <Divider className="fluid-field" />
        <Form.Item
          label={
            <React.Fragment>
              Dictation and Report Typing by
              {sessionDetails.Id > 0 && (
                <button
                  className="field-actions"
                  onClick={() => this.handleFieldRecentChange(sessionDetails, 'DictationOption')}
                >
                  Recent changes
                </button>
              )}
            </React.Fragment>
          }
          className="fluid-field"
        >
          <Radio.Group
            value={sessionDetails.DictationOption}
            onChange={this.handleChangeField('DictationOption')}
            disabled={isLocked || isCopySession}
          >
            <Radio disabled={(api.isDoctor() && sessionDetails.PatientId > 0) || api.isCaseManager()} value={0}>
              Medicolegal Provider
            </Radio>
            <Radio disabled={(api.isDoctor() && sessionDetails.PatientId > 0) || api.isCaseManager()} value={1}>
              Specialist
            </Radio>
            <Radio disabled={(api.isDoctor() && sessionDetails.PatientId > 0) || api.isCaseManager()} value={2}>
              Not Sure
            </Radio>
          </Radio.Group>
        </Form.Item>
        {doctorInfo && (
          <Form.Item label="Report Types excluded for this session" className="fluid-field">
            <Dropdown
              style={{ width: '100%' }}
              placeholder="Select Report Types"
              disabled={isLocked || isCopySession}
              multiple
              search
              selection
              name="selectedAcceptedReportTypes"
              options={store.reportTypes.map(t => ({
                value: t.Id,
                text: t.Name,
              }))}
              value={toJS(selectedAcceptedReportTypeIds) || []}
              onChange={this.setAcceptedReportTypes}
            />
          </Form.Item>
        )}
        {api.isAdminOrMagStaffOrAccounting() && !!sessionDetails.AssessmentId && (
          <React.Fragment>
            <Form.Item
              label={
                <React.Fragment>
                  Waiting List{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Mail-147 will be sent to waiting-list CMs if appointment is cancelled"
                    position="top center"
                    wide="very"
                    style={{ marginLeft: '10px' }}
                  />
                </React.Fragment>
              }
              className="fluid-field"
            >
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                mode="multiple"
                showArrow
                onChange={this.handleChangeField('WaitingList', 'select')}
                value={sessionDetails.WaitingList ? sessionDetails.WaitingList.map(i => i) : null}
                onSearch={this.handleSearch('casemanagers')}
                onPopupScroll={this.handleScroll('casemanagers')}
              >
                {casemanagers.map(({ Id, FullName, FirstName, LastName, Email }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName || `${FirstName || ''} ${LastName || ''}`} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </React.Fragment>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <>
            {sessionDetails.Confirmed && (
              <Form.Item label="Available session expires on:">
                <Input
                  size="large"
                  addonBefore={this.getSessionExpiresOnString()}
                  value={this.state.numberOfDaysBeforeSessionDateThatSessionExpire}
                  disabled={!!sessionDetails.AssessmentId || isCopySession}
                  onChange={this.handleChangeField('numberOfDaysBeforeSessionDateThatSessionExpire')}
                  addonAfter="days before session date, then converting to on-demand sessions"
                />
              </Form.Item>
            )}
          </>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <React.Fragment>
            <Form.Item
              label={
                <React.Fragment>
                  Reserved for clients{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Max 10 clients can be reserved"
                    position="top center"
                    wide="very"
                    style={{ marginLeft: '10px' }}
                  />
                </React.Fragment>
              }
              className="fluid-field"
            >
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                mode="multiple"
                showArrow
                onChange={this.handleChangeField('ReservedForClients', 'select')}
                value={sessionDetails.ReservedForClients ? sessionDetails.ReservedForClients.map(i => i) : null}
                disabled={!!sessionDetails.AssessmentId || isCopySession}
                onSearch={this.handleSearch('clients')}
                onPopupScroll={this.handleScroll('clients')}
              >
                {clients.map(({ Id, Name }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reservation expires on"
              validateStatus={!!!this.state.numberOfDaysBeforeSessionDateThatClientReservationExpire ? 'error' : null}
              help={
                !!!this.state.numberOfDaysBeforeSessionDateThatClientReservationExpire
                  ? 'Please enter an integer number that equals or greater than 1'
                  : ''
              }
            >
              <Input
                addonBefore={this.getClientReservationExpiresOnString()}
                value={this.state.numberOfDaysBeforeSessionDateThatClientReservationExpire}
                disabled={!!sessionDetails.AssessmentId || isCopySession}
                onChange={this.handleChangeField('numberOfDaysBeforeSessionDateThatClientReservationExpire')}
                addonAfter="days before session date"
              />
            </Form.Item>
          </React.Fragment>
        )}
        {!!!sessionDetails.Id && this.state.repeatOpt === 1 && (
          <Form.Item label="More Option" className="fluid-field">
            <Radio.Group
              value={this.state.moreDetailOpt}
              disabled={isCopySession}
              onChange={this.handleChangeField('moreDetailOpt')}
            >
              <Radio value={5}>Include Weekend</Radio>
              <Radio value={6}>Exclude Weekend</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {!api.isCaseManager() && (
          <Form.Item label="Comment">
            <Input.TextArea
              rows={3}
              disabled={isLocked || isCopySession}
              value={sessionDetails.ConfirmedComment}
              onChange={this.handleChangeField('ConfirmedComment')}
            />
          </Form.Item>
        )}
        {!!sessionDetails.Id && api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Session created by">
            {sessionDetails.CreatedByUser && <span>{sessionDetails.CreatedByUser}</span>}
            {sessionDetails.CreatedByUser && sessionDetails.CreatedDate && (
              <span style={{ marginLeft: '10px', marginRight: '10px' }}>-</span>
            )}
            {sessionDetails.CreatedDate && <span>{moment(sessionDetails.CreatedDate).format('DD/MM/YYYY HH:mm')}</span>}
          </Form.Item>
        )}
        {/* {!!sessionDetails.Id && api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Session updated by">
            {sessionDetails.CreatedByUser && <span>{sessionDetails.CreatedByUser}</span>}
            {sessionDetails.CreatedByUser && sessionDetails.CreatedDate && (
              <span style={{ marginLeft: '10px', marginRight: '10px' }}>-</span>
            )}
            {sessionDetails.CreatedDate && <span>{moment(sessionDetails.CreatedDate).format('DD/MM/YYYY HH:mm')}</span>}
          </Form.Item>
        )} */}
      </Form>
    );
  };
  render() {
    const { open } = store;
    const { sessionDetails, loading, isSaved } = this.state;
    const IsSelectingAppointmentForAssessment = !loading && !!!sessionDetails.Id;
    const title = loading
      ? 'Add New Session'
      : sessionDetails.AssessmentNumber
      ? `Appointment Details - Case No ${sessionDetails.AssessmentNumber} - Specialist: ${
          sessionDetails.DoctorName
        } - Claimant: ${sessionDetails.PatientName} - Appointment Type: ${
          sessionDetails.SessionAcceptsType === 0
            ? 'In-person only'
            : sessionDetails.SessionAcceptsType === 1
            ? 'Video Assessment only'
            : 'Either In-person or Video Assessment'
        }`
      : !!sessionDetails.Id
      ? 'Session Details'
      : 'Add New Session';
    const panes = loading
      ? (api.isCaseManager() || api.isOnlySearch())
        ? []
        : [
            {
              menuItem: !!sessionDetails?.Id ? 'Edit Session' : 'Sesion Information',
              render: () => <Tab.Pane attached={false}></Tab.Pane>,
            },
            !!sessionDetails?.Id
              ? {
                  menuItem: 'Delete Session',
                  render: () => <Tab.Pane attached={false}></Tab.Pane>,
                }
              : '',
          ]
      : api.isCaseManager()
      ? [
          {
            menuItem: !!sessionDetails?.Id ? 'Edit Session' : 'Sesion Information',
            render: () => (
              <Tab.Pane className="edit-session-tab" attached={false}>
                {this.renderFormEditSession()}
              </Tab.Pane>
            ),
          },
        ]
      : sessionDetails.PatientId > 0
      ? [
          {
            menuItem: !!sessionDetails?.Id ? 'Edit Session' : 'Sesion Information',
            render: () => (
              <Tab.Pane className="edit-session-tab" attached={false}>
                {this.renderFormEditSession()}
              </Tab.Pane>
            ),
          },
        ]
      : [
          {
            menuItem: !!sessionDetails?.Id ? 'Edit Session' : 'Sesion Information',
            render: () => (
              <Tab.Pane className="edit-session-tab" attached={false}>
                {this.renderFormEditSession()}
              </Tab.Pane>
            ),
          },
          !!sessionDetails?.Id && !api.isOnlySearch()
            ? {
                menuItem: 'Delete Session',
                render: () => <Tab.Pane attached={false}>{this.renderDeleteSessionTab()}</Tab.Pane>,
              }
            : '',
        ];
    return (
      <Modal
        visible={open}
        width={1200}
        footer={
          <React.Fragment>
            {!!sessionDetails?.Id && !sessionDetails?.PatientId && !api.isCaseManager() && !api.isOnlySearch() && (
              <React.Fragment>
                <Button className="blue" onClick={() => this.handleUpdateSession(1)}>
                  Save only this session{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="All other available sessions in the series will remain unchanged."
                    position="top center"
                    wide="very"
                    style={{ marginLeft: '10px', color: 'white' }}
                  />
                </Button>
                <Button className="blue" onClick={() => this.handleUpdateSession(2)}>
                  Save this and other sessions{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="This and other available sessions in the series will be updated."
                    position="top center"
                    wide="very"
                    style={{ marginLeft: '10px', color: 'white' }}
                  />
                </Button>
              </React.Fragment>
            )}
            {!loading && !!sessionDetails.Id && sessionDetails.ItemType === 0 && !store.pageGetter && !store.fieldName && !api.isOnlySearch() && (
              <React.Fragment>
                {!api.isCaseManager() && (
                  <Button
                    className="blue"
                    onClick={() => {
                      this.handleCopySession();
                    }}
                  >
                    Copy this session
                  </Button>
                )}
              </React.Fragment>
            )}
            {store.pageGetter && store.fieldName && !loading && !!sessionDetails.Id ? (
              <Button
                onClick={() => {
                  const { fieldName, pageGetter } = store;
                  const { listPageGetters } = dashboardStore;
                  const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);
                  if (fieldName && pageGetter) {
                    dashboardStore.transferData({
                      fieldName: fieldName,
                      pageGetter: pageGetter,
                      dataTransfer: {
                        SessionsId: sessionDetails.Id,
                        Appointment: {
                          ...sessionDetails,
                          SessionId: sessionDetails.Id,
                          Doctor: { FullName: sessionDetails.DoctorName, Id: sessionDetails.DoctorId },
                          Clinic: { Name: sessionDetails.ClinicName, Id: sessionDetails.ClinicId },
                          DateFrom: sessionDetails.DateFrom,
                          DateTo: sessionDetails.DateTo,
                          ClinicType: sessionDetails.ClinicOption,
                          AppointmentDate: `${moment(sessionDetails.DateFrom).format('DD/MM/YYYY HH:mm')} - ${moment(
                            sessionDetails.DateTo,
                          ).format('HH:mm')}`,
                        },
                      },
                    });
                  }
                  if (urlPageGetter && urlPageGetter.pathname) {
                    dashboardStore.close(window.location.pathname);
                    setTimeout(() => {
                      dashboardStore.open(urlPageGetter.pathname);
                    });
                    store.setFieldsValue({ fieldName: null, pageGetter: null });

                    store.toggleModal(false)();
                  }
                }}
                disabled={
                  store.doctorAppointmentSelected !== sessionDetails.DoctorId && !!store.doctorAppointmentSelected
                }
                className="blue"
              >
                Select This Session
              </Button>
            ) : (
              <>
                {!loading && !!!sessionDetails.Id && !api.isOnlySearch() && (
                  <Button
                    className="blue"
                    onClick={() => {
                      this.handleCreateSession();
                    }}
                  >
                    Create
                  </Button>
                )}

                {!loading && (
                  <React.Fragment>
                    {!api.isDoctor() && !api.isOnlySearch() && 
                      (!sessionDetails.PatientId || sessionDetails.PatientId === 0) &&
                      !IsSelectingAppointmentForAssessment && (
                        <Button
                          className="blue"
                          onClick={() => {
                            this.AddAppointment('add');
                          }}
                        >
                          Book this session
                        </Button>
                      )}
                  </React.Fragment>
                )}
                {!loading && !!sessionDetails.PatientId && (
                  <Button
                    className="blue"
                    onClick={() => {
                      this.AddAppointment('view');
                    }}
                  >
                    View case
                  </Button>
                )}
                {!loading && !!sessionDetails.PatientId && !api.isDoctor() && !api.isCaseManager() && !api.isOnlySearch() && (
                  <Button
                    className="blue"
                    onClick={() => {
                      this.handleUpdateBookedSession();
                    }}
                  >
                    Save
                  </Button>
                )}
              </>
            )}
            <Button
              className="negative"
              onClick={() => {
                store.toggleModal(false)();
                isSaved &&
                  (store.calendarViewMode === 'dayGridMonth' || !!store.clinicId || store.dayWeekViewType === 'all'
                    ? store.handleSearch(store.doctorId)
                    : store.handleSearch());
              }}
            >
              Close
            </Button>
          </React.Fragment>
        }
        className="modal-add-edit-session"
        title={title}
      >
        <Dimmer active={this.state.loading}>
          <Loader />
        </Dimmer>
        <Tab menu={{ pointing: true }} panes={panes} />
        {this.state.openModal && (
          <ModalConfirm
            onCancel={() => this.setState({ openModal: false, loading: false })}
            modalParams={this.state.modalParams}
            open={this.state.openModal}
          />
        )}
        {this.state.openModalHistory && (
          <TableFieldChangeHistory
            {...store}
            onCancel={() => this.setState({ openModalHistory: false, loading: false })}
          />
        )}
        {/* {this.state.viewAppointmentStatus && this.state.doctorInfo && (
          <Modal
            visible={this.state.viewAppointmentStatus}
            width={300}
            onCancel={() => this.setState({ viewAppointmentStatus: false })}
          >
            <Form>
              <Form.Item label="Specialist accepts Video Assessments" className="fluid-field">
                <Radio.Group value={this.state.doctorInfo?.teleAssessment}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={0}>Unsure</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Modal>
        )} */}
      </Modal>
    );
  }
}

export default ModalSessionDetails;
