import createMutation from '../utils/createMutation';
import createQuery from '../utils/createQuery';

export const registerDoctor = createMutation('/Account2/RegisterDoctor');
export const reportStatus = createMutation('/Account2/ReportCaseAsync');
export const SendReportStatus = createMutation('/Account2/SendReportStatus');
export const register = createMutation('/Account2/Register');
export const login = createMutation('/Account2/Login');
export const loginAsTourist = createMutation('/Account2/LoginAsTourist');
export const logout = createMutation('/Account2/Logout');
export const forgot = createMutation('/Account2/Forgot');
export const resetCheckCode = createQuery(() => `/Account2/Reset${window.location.search}`);
export const reset = createMutation(() => `/Account2/Reset${window.location.search}`);

export const currentUser = createQuery('/Account2/CurrentUser');

// MedicalService.Constants/UserRoleName.cs
const ADMIN = 'admin';
const MAG_STAFF = 'magstaff';
const ACCOUNTING = 'accounting';
const CASE_MANAGER = 'casemng';
const TOURIST = 'tourist';
const DOCTOR = 'doctor';
const TYPIST = 'typist';

const checkRole = role => () => currentUser.data?.roles.some(r => r === role);
const checkRoles = (role1, role2) => () => currentUser.data?.roles.some(r => r === role1 || r === role2);
export const isAdmin = checkRole(ADMIN);
export const isTourist = checkRole(TOURIST);
export const isCaseManager = checkRoles(CASE_MANAGER);
export const isDoctor = checkRole(DOCTOR);
export const isMagStaff = checkRole(MAG_STAFF);
export const isAccounting = checkRole(ACCOUNTING);
export const isTypist = checkRole(TYPIST);
export const isCMInvoiceCheck = () => (checkRoles(CASE_MANAGER) && currentUser.data?.IsOnlyInvoiceCheck);
export const isOnlySearch = () => (currentUser.data?.IsOnlySearch);
export const isAdminOrMagStaffOrAccounting = () =>
  currentUser.data?.roles.some(r => r === ADMIN || r === MAG_STAFF || r === ACCOUNTING);
export const isImpersonated = () => currentUser.data?.IsImpersonated;
export const isLoginAndAdminOrMagStaffOrAccounting = () =>
  currentUser && currentUser.data?.roles
    ? currentUser.data?.roles.some(r => r === ADMIN || r === MAG_STAFF || r === ACCOUNTING)
    : false;

export const MAG = 'MAG';
export const MLE = 'MLE';
const checkBranch = br => () => currentUser.data?.branch === br;
export const isMAG = checkBranch(MAG);
export const isMLE = checkBranch(MLE);
export const isSiteOnlyInvoiceCheck = () => {
  //https://invoicecheck.kawaconn.com/
  let hotName = window.location.hostname;
  console.log(hotName);
  if(hotName.includes('invoicecheck.kawaconn.com')){
    return true;
  }
  return false;
}
