import React from 'react';
import { Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { TableEmptyMessage, TableHeader } from '../../shared/table';

import AddEditStore from './store';
import { renderDate } from '../Shared';

const columns = [{ title: 'Invoice No' }, { title: 'Received Date' }];

@observer
class ListInvoice extends React.Component {
  renderTableBody = () => {
    const data = toJS(AddEditStore.listInvoice);
    return data.map((i, idx) => (
      <Table.Row key={idx}>
        <Table.Cell className="cell-correspondence cell-link">
          <span
            className="field-actions"
            onClick={() => window.open(`/Invoice/DownloadBilling?invoiceId=${i.Id}`, '_blank')}
          >
            {i.InvoiceNo}
          </span>
        </Table.Cell>
        <Table.Cell>{renderDate(i.SentDateCMViewInvoice, false, true)}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const data = toJS(AddEditStore.listInvoice);
    return (
      <div className="fluid-field">
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={columns} />
            <Table.Body>
              {data && data.length && data.length !== 0 ? (
                this.renderTableBody()
              ) : (
                <TableEmptyMessage colSpan={columns.length} />
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

export default ListInvoice;
