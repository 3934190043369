import { Divider, Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import dashboardStore from '@stores/dashboard';
import customFetch from '../../../utils/customFetch';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import SupplementaryReportsStore from './SupplementaryReportsStore';
import ModalNotification from './ModalNotification';
import * as api from '../../../stores/api';

import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'SuppNo' },
  { title: 'Case Status', sortKey: 'CaseProgress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'DateRequest' },
  { title: 'Action Required' },
  { title: 'Original Case', sortKey: 'CaseNo' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Type of Claim', sortKey: 'TypeofClaim' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Assessing Specialist', sortKey: 'AssessedDoctorName' },
  { title: 'Next Task' },
  { title: 'Next Task PIC' },
  { title: 'Created By', sortKey: 'CreatedByName' },
];

const columnsCM = [
  { title: '' },
  { title: 'Case No', sortKey: 'SuppNo' },
  { title: 'Case Progress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'StatusDate' },
  { title: 'Original Case', sortKey: 'CaseNo' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Type Of Claim', sortKey: 'TypeOfClaim' },
  { title: 'Claimant', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'ClientId' },
  { title: 'Case Manager', sortKey: 'CaseManagerId' },
  { title: 'Specialist', sortKey: 'DoctorName' },
];

@observer
class SupplementReportsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }

  isShowDetailTypist = record => {
    if (api.isTypist()) {
      if (api.isTypist()) {
        switch (record.CaseProgress) {
          case api.suppStatusEnum.CANCELLED_WITH_FEE:
          case api.suppStatusEnum.CANCELLED_NO_FEE:
          case api.suppStatusEnum.SERVICE_BILLED:
          case api.suppStatusEnum.SERVICE_PAID:
            return false;
          default:
            break;
        }
      }
    }
    return true;
  };

  openModal = options => {
    return SupplementaryReportsStore.toggleModal(true, options);
  };

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=S&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'S',
      view: 'ViewInvoice',
    });
  };

  openViewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  renderClass = record => {
    if (record.CaseProgress === 6) {
      return 'negative';
    }
    return '';
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link" onClick={this.openCaseDetail(Id, ``, record)}>
          <Link to="#">{record.SuppNo}</Link>
        </Table.Cell>
        <Table.Cell className={record.CaseProgress === 6 ? 'Cell-link-Red' : 'Cell-link'}>
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.SuppNo,
              status: record.CaseProgressText,
            })}
          >
            {record.StatusStaffText}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.DateRequest ? moment(formatDate(record.DateRequest), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.SuppNo,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          {record.ActionOverview && (
            <div>
              {record.ActionSubject}
              <br />
              {record.ActionSubject ? record.ActionOverview.slice(12) : record.ActionOverview.slice(14)}
              <br />
              <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{record.CaseNo}</Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.TypeOfClaim}</Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'claimant',
                id: record.ClaimantId,
                localStorageName: 'ClaimantName',
                localStorageValue: record.Claimant,
              })
            }
          >
            ({record.ClaimantId}) {record.Claimant}
          </Link>
          <br /> DOB:{' '}
          {record.ClaimantDOB ? moment(formatDate(record.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
          <br />
          <FormatPhoneNumber
            officePhoneNumber={record.ClaimantHomePhone}
            mobilePhoneNumber={record.ClaimantTelePhone}
          />
        </Table.Cell>
        <Table.Cell className={cx(`${record.BookingClient && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              {record.ClientId ? `(${record.ClientId}) ${record.Client}` : ''}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: record.CaseManagerId,
                localStorageName: 'CmName',
                localStorageValue: record.CaseManager,
              })
            }
          >
            {record.CaseManagerId > 0 ? `(${record.CaseManagerId})` : ``} {record.CaseManager}
          </Link>
          <div>{record.CMEmail}</div>
          <FormatPhoneNumber phoneNumber={record.CMMobilePhone} type="office" />
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.AssessedDoctorName && (
            <React.Fragment>
              <div style={{ whiteSpace: 'nowrap' }}>
                {record.AssessedDoctorName && (
                  <div>
                    <Link
                      to="#"
                      onClick={() =>
                        OpenCaseDetails({
                          tabName: 'specialist',
                          id: record.DoctorId,
                          localStorageName: 'DoctorName',
                          localStorageValue: record.DoctorName,
                        })
                      }
                    >
                      {record.DoctorId > 0 ? `(${record.DoctorId})` : ``} {record.AssessedDoctorName}
                      {record.AssessedDoctorRegisterOption && `(${record.AssessedDoctorRegisterOption})`}
                    </Link>
                  </div>
                )}

                {record.AssessedDoctorSpecialty && <div>Specialty: {record.AssessedDoctorSpecialty}</div>}
                <div>{record.DoctorEmail}</div>
              </div>
              <div>
                <span>
                  <FormatPhoneNumber
                    mobilePhoneNumber={record.DoctorPhone}
                    officePhoneNumber={record.DoctorOfficePhone}
                  />
                </span>
              </div>
              <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
                Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
              </div>
            </React.Fragment>
          )}
        </Table.Cell>
        <Table.Cell className={cx({ 'text-danger': record.IsOverdue })}>{record.NextStatusStaffText}</Table.Cell>
        <Table.Cell>{this.getPICName(record)}</Table.Cell>
        <Table.Cell>{record.CreatedByName}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyCM = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">
          {this.isShowDetailTypist(record) ? this.renderTableActionsCM(Id, record) : ``}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          {this.isShowDetailTypist(record) ? (
            <Link to="#" onClick={this.openCaseDetail(Id, 'CaseDetails', record)}>
              {record.SuppNo}
            </Link>
          ) : (
            record.SuppNo
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.SuppNo,
            })}
          >
            {record.StatusStaffText}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate ? moment(formatDate(record.DateRequest), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CaseNo}</Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>{record.TypeOfClaim}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          ({record.ClaimantId}) {record.Claimant}
        </Table.Cell>
        <Table.Cell className={cx(`${record.BookingClient && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className={cx(`${record.IsDirect && `Cell-Client-with-mail`}`)}>
          <h5 className="ma-0">{record.CaseManager}</h5>
          {record.IsDirect && <div>{record.CMEmail}</div>}
          {record.IsDirect && <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />}
        </Table.Cell>
        <Table.Cell className={cx(`${record.IsDirect && `Cell-Client-with-mail`}`)}>
          <h5 className="ma-0">{record.AssessedDoctorName}</h5>
          {record.IsDirect && <div>{record.DoctorEmail}</div>}
          {record.IsDirect && (
            <div>
              <span>
                <FormatPhoneNumber
                  mobilePhoneNumber={record.DoctorPhone}
                  officePhoneNumber={record.DoctorOfficePhone}
                />
              </span>
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActionsCM = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        !api.isTypist() ? (
          <Menu>
            <Menu.Item onClick={this.openCaseDetail(id, `CaseDetails`, record)}>Update Request Details</Menu.Item>
            <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record)}>Upload Case Documents</Menu.Item>
            <Menu.Item onClick={this.openCaseDetail(id, `Cancellation`, record)}>Cancel Case Request</Menu.Item>
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record)}>View Completed Report</Menu.Item>
            )}
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Invoices`, record)}>View Invoice</Menu.Item>
            )}
            <Menu.Item onClick={this.openCaseDetail(id, `Corespondence`, record)}>View Case Correspondence</Menu.Item>
          </Menu>
        ) : (
          <Menu>
            <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record)}>Download Dictations</Menu.Item>
            <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record)}>Upload Typed Reports</Menu.Item>
          </Menu>
        )
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(id, ``, record)}>Update Claim Details</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, 'Paperwork', record)}>Update Case Documents</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, 'Paperwork', record)}>Update Dictations & Reports</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, 'ServicePrepaymentSection', record)}>
            Update Provider Payment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, 'Corespondence', record)}>Update Correspondence</Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to send report status update to booking CM?',
              onOk: this.handleSendStatusUpdate(id, 0),
            })}
          >
            Send Status Update (CMs) (MAIL-105)
          </Menu.Item>
          {record.AssessedDoctorName && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to assessing specialist?',
                onOk: this.handleSendStatusUpdate(id, 1),
              })}
            >
              Send Status Update (Specialists) (MAIL-106)
            </Menu.Item>
          )}
          {(record.IsBilled || record.CaseProgress === 7 || record.CaseProgress === 9) && (
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
          )}
          {/* {(record.CaseProgress === 7 || record.CaseProgress === 9 || record.ClientPrepaymentRequired) && ( */}
          <Menu.Item onClick={this.handleAddCopyInvoice(id, 0)}>
            <Icon name="plus" size="small" /> Generate Invoice
          </Menu.Item>
          {/* )} */}
          {record.IsBilled && (
            <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
          )}
          {record.InvoiceId > 0 && <Menu.Item onClick={this.openViewInvoice(record.InvoiceId)}>View invoice</Menu.Item>}
          {record.InvoiceId > 0 && (
            <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print invoice
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message:
                'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
              onOk: this.handleDuplicateAndRemove(id, record, this.duplicate, `duplicate`),
            })}
          >
            Rebook this Case
          </Menu.Item>
          {/* {api.isAdmin() && <Menu.Item onClick={() => this.delete(id, record)}>Delete this Case</Menu.Item>} */}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  getPICName = ({ CaseProgress, ...record }) => {
    switch (CaseProgress) {
      case api.suppStatusEnum.REPORT_REVIEW_REQUESTED:
        return record.PICsentToProviderName;
      case api.suppStatusEnum.REQUEST_SENT_TO_PROVIDER:
        return record.PICcompletedName;
      case api.suppStatusEnum.DICTATION_RECEIVED:
        return record.PICDictationReceivedName;
      case api.suppStatusEnum.REPORT_DRAFT_RECEIVED:
        return record.PICReportDraftReceivedName;
      case api.suppStatusEnum.REPORT_RETURNED_FROMDOCTOR:
        return record.PICcorrectedReportName;
      case api.suppStatusEnum.REPORT_COMPLETED:
      case api.suppStatusEnum.CANCELLED_WITH_FEE:
        return record.PICServiceBilledName;
      default:
        return '';
    }
  };

  handlePageClick = page => {
    SupplementaryReportsStore.refetch({ curPage: page });
  };
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Paperwork':
        return 1;
      case 'Cancellation':
        return 2;
      case 'ServicePrepaymentSection':
      case 'Invoices':
        return 3;
      case 'Corespondence':
        return 4;
      case 'ActionRequired':
        return 5;
      default:
        return 0;
    }
  };

  openCaseDetail = (id, type, record) => () => {
    localStorage.setItem('SupplementaryCaseId', `Case ${record.SuppNo}`);

    dashboardStore.close('/view/add-edit-supplementary-report-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/Supplementary/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 2, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } finally {
    }
  };

  handleDuplicateAndRemove = (id, record, fn, act) => async () => {
    SupplementaryReportsStore.setFieldsValue({ loading: true });

    try {
      await fn(id, record);
      await SupplementaryReportsStore.refetch({}, true);

      // notification.destroy();
      // notification.success({
      //   message: 'Success',
      //   description: `Supplementary Report is ${act}d`,
      //   duration: 2,
      // });
    } finally {
      SupplementaryReportsStore.setFieldsValue({ loading: false });
    }
  };

  duplicate = (id, record) => {
    return customFetch('/Supplementary/Supplementary_CopyDetail', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(data => {
      if (data.status === 'fail') {
        this.setState({
          openModalNotification: true,
          message: 'Error occured while saving data.Please report this to system administrator and try again later.',
        });
      } else {
        notification.info({
          message: 'Success',
          description: 'Supplementary is duplicated.',
          key: 'requestDoctorInfoModalSaved',
        });
      }
    });
  };

  delete = (id, record) => {
    if (record.CaseProgress === api.suppStatusEnum.CANCELLED_NO_FEE) {
      SupplementaryReportsStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to delete this supplementary?',
        onOk: async () => {
          try {
            SupplementaryReportsStore.setFieldsValue({ loading: true });
            const response = await customFetch('/Supplementary/Delete', {
              method: 'POST',
              headers: { 'Content-type': 'application/json' },
              body: JSON.stringify({ id }),
            });
            if (response.status === 'success') {
              SupplementaryReportsStore.refetch({}, true);
            }
          } catch (e) {
          } finally {
            SupplementaryReportsStore.setFieldsValue({ loading: false });
          }
        },
      })();
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalNotification
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = SupplementaryReportsStore;
    const colSpan = api.isAdminOrMagStaffOrAccounting() ? columns.length : columnsCM.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: '0' }}>
        <TableHeader
          columns={api.isAdminOrMagStaffOrAccounting() ? columns : columnsCM}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={SupplementaryReportsStore.handleSort}
        />
        <Table.Body>
          {api.isAdminOrMagStaffOrAccounting()
            ? this.renderTableBody(data, colSpan)
            : this.renderTableBodyCM(data, colSpan)}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={SupplementaryReportsStore.curPage}
          totalPage={SupplementaryReportsStore.totalPage}
          totalItems={SupplementaryReportsStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default SupplementReportsTable;
