import React from 'react';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import Modal from '../../shared/Modal/';
import store from './ClinicalRecordStore';
import dashboardStore from '@stores/dashboard';
import iframe from '@components/dashboard-routes/Iframe/iframeData';

function ModalAlert({ onCancel }) {
  const { open, modalParams } = store;
  const openNewUI = () => {
    const { id, step = 0 } = modalParams;
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}&step=${step}`);
    });
    onCancel();
  };
  const openOldUI = () => {
    const { id, type = '' } = modalParams;
    iframe.forceOpenTab('AddEditClinicalNote', `?id=${id}&view=AddEditClinicalNote#${type}`, {
      id,
      view: 'AddEditClinicalNote',
    });
    onCancel();
  };
  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="red" onClick={onCancel}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      <div className="Modal-confirm-open-newui">
        <Button className="blue" style={{ marginRight: '10px' }} onClick={openNewUI}>
          Case Detail New UI (Beta)
        </Button>
        <Button className="blue" onClick={openOldUI}>
          Case Detail Old UI
        </Button>
      </div>
    </Modal>
  );
}

export default observer(ModalAlert);
