import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import ImagingStore from './ImagingStore';
import ImagingTable from './ImagingTable';
import ImagingToolBar from './ImagingToolBar';
import ImagingModal from './ImagingModal';

window.reloadImagingService = () => {
  ImagingStore.refetch({}, true);
};

class ImagingServices extends React.Component {
  componentDidMount() {
    ImagingStore.fetchJob(1);
  }

  render() {
    const { loading, data, dataStaff, loadingStaff, dataClaimant, loadingClaimant, open } = ImagingStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {(loading && !data) || (!dataStaff && loadingStaff) || (!dataClaimant && loadingClaimant) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ImagingToolBar />
            {/* <ImagingToolbarMid />
            <ImagingToolbarUnder /> */}
            <ImagingTable />
            {open && <ImagingModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(ImagingServices);
