import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class HistoryReportStore {
  @observable loadingLogin = true;
  @observable dataLogin = null;
  @observable loadingSmartSearch = true;
  @observable dataSmartSearch = null;

  @observable userCount = 0;

  @observable tabIsActive = 'login';

  @observable keyword = '';
  @observable keywordLogin = '';
  @observable keywordCM = '';
  @observable filter = 'All';
  @observable role = 'All';
  @observable fromTo =
    moment(
      moment()
        .startOf('month')
        .toDate(),
    ).format(`DD/MM/YYYY`) +
    ' - ' +
    moment(
      moment()
        .endOf('month')
        .toDate(),
    ).format(`DD/MM/YYYY`);

  @observable from = moment(
    moment()
      .startOf('month')
      .toDate(),
  ).format(`DD/MM/YYYY`);
  @observable to = moment(
    moment()
      .endOf('month')
      .toDate(),
  ).format(`DD/MM/YYYY`);

  @observable sortKey = 'Id';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItem = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
  };

  @action fetchLoginHistory = (setTotalPage = false) => {
    customFetch('/ExportHistory/GetAll?filterType=all', {
      method: 'GET',
    }).then(
      action(data => {
        this.dataLogin = data.itemList;
        this.totalPage = data.sumPage;
        this.totalItems = data.sumItem;
        this.loadingLogin = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetchLoginHistory = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchLoginHistory(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortKey = 'Id';
    this.role = 'All';
    this.sortDirection = 'ascending';

    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new HistoryReportStore();
