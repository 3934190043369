import { observer } from 'mobx-react';
// import { action } from 'mobx';
import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import customFetch from '../../../utils/customFetch';
import Store from './DocAndConsStore';

const reasons = [
  { key: 'duplicate', text: 'Duplicate Specialist', value: 'Duplicate Specialist' },
  { key: 'incorectInfo', text: 'Incorrect information', value: 'Incorrect information' },
  { key: 'notInterested', text: 'Not Interested', value: 'Not Interested' },
  { key: 'noLonger', text: 'No Longer Qualified', value: 'No Longer Qualified' },
  { key: 'retired', text: 'Retired', value: 'Retired' },
  { key: 'passedAway', text: 'Passed Away', value: 'Passed Away' },
  { key: 'other', text: 'Other', value: 'Other' },
];

@observer
class ModalDisableUser extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.modalParams);
    this.state = {
      Id: this.props.modalParams.modalParams.Id,
      record: this.props.modalParams.modalParams.Record,
      Reason: 'Duplicate Specialist',
      OtherDetail: '',
      errorReason: 'This field is required.',
    };
  }
  onCancel = () => {
    this.props.onCancel();
  };

  handleDisableUser = async () => {
    const { Id, Reason, OtherDetail } = this.state;
    Store.setFieldsValue({ loading: true });
    if (Reason === 'Other' && !OtherDetail) return;
    try {
      await customFetch('/MedicalDoctor/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ Id: Id, Reason: Reason, OtherDetail: OtherDetail }),
      });

      await Store.refetch({}, true);
    } finally {
      Store.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };

  handleOnChangeDropDown = (e, { value }) => {
    this.setState({ ...this.state, Reason: value });
  };

  handleOnChangOtherDetail = (e, { value }) => {
    if (value) {
      this.setState({ ...this.state, OtherDetail: value });
      this.setState({ errorReason: '' });
    } else {
      this.setState({ ...this.state, OtherDetail: value });
      this.setState({
        errorReason: `This field is required.`,
      });
    }
  };

  render() {
    const { open } = Store;
    const { record, Reason, OtherDetail } = this.state;
    console.log(record);
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={600}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleDisableUser}>
                <Icon name="save" /> Save
              </Button>
              <Button color="red" onClick={this.onCancel}>
                <Icon name="close" /> Close
              </Button>
            </React.Fragment>
          }
          title="Disable Specialist"
        >
          <Form>
            <div style={{ minHeight: '350px' }}>
              <div>
                Please provide reason for disabling specialist {record?.FirstName} {record?.LastName}
              </div>
              <Form.Dropdown
                required
                fluid
                label="Reason"
                placeholder="Reason"
                id="form-input-first-name"
                search
                selection
                value={Reason}
                onChange={this.handleOnChangeDropDown}
                options={reasons}
              />
              {Reason === 'Other' && (
                <Form.Field
                  required
                  control={Input}
                  label="Reason"
                  fluid
                  error={this.state.errorReason ? { content: this.state.errorReason } : null}
                  placeholder="Reason"
                  value={OtherDetail}
                  onChange={this.handleOnChangOtherDetail}
                />
              )}
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDisableUser;
