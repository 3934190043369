import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import IMSApprovalCodeModal from './IMSApprovalCodeModal';
import IMSApprovalCodeStore from './IMSApprovalCodeStore';
import IMSApprovalCodeTable from './IMSApprovalCodeTable';
import IMSApprovalCodeToolbar from './IMSApprovalCodeToolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class IMSApprovalCode extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/ims-approval-2', true);
      return;
    }
    IMSApprovalCodeStore.fetchFiles(true);
  }

  render() {
    const { loading, data, open } = IMSApprovalCodeStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <IMSApprovalCodeToolbar />
            <IMSApprovalCodeTable />
            {open && <IMSApprovalCodeModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default IMSApprovalCode;
