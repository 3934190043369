import React from 'react';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import Modal from '../../shared/Modal/';
import IMEStore from './IMEStore';
import dashboardStore from '@stores/dashboard';
import iframe from '@components/dashboard-routes/Iframe/iframeData';
import * as api from '../../../stores/api';

function ModalAlert({ onCancel }) {
  const { open, modalParams } = IMEStore;
  const openNewUI = () => {
    const { id, step = 0, name = '', type = '' } = modalParams;
    if (name) {
      localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
      dashboardStore.close('/view/add-edit-supplementary-report-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
      });
    } else {
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        if (api.isTypist() && type === 'Completion') {
          dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=4`);
        } else {
          dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${step}`);
        }
      });
    }

    onCancel();
  };
  const openOldUI = () => {
    const { id, name = '' } = modalParams;
    if (name) {
      return iframe.forceOpenTab('PostSupplementary', `?mId=${id}&mType=A&view=PostSupplementary`, {
        mId: id,
        mType: 'A',
        view: 'PostSupplementary',
      });
    }
    iframe.forceOpenTab('PostAssessment', `?id=${id}&view=PostAssessment`, {
      id,
      view: 'PostAssessment',
    });
    onCancel();
  };
  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="red" onClick={onCancel}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      <div className="Modal-confirm-open-newui">
        <Button className="blue" style={{ marginRight: '10px' }} onClick={openNewUI}>
          IME Case Detail New UI (Beta)
        </Button>
        <Button className="blue" onClick={openOldUI}>
          IME Case Detail Old UI
        </Button>
      </div>
    </Modal>
  );
}

export default observer(ModalAlert);
