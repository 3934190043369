import { Divider, Dropdown, Menu, notification } from 'antd';
import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import * as api from '../../../stores/api';
import ShortcutTable from './ShortcutTable';
import Store from './clinicalNoteStore';
import uploadStore from './UploadFile/UploadStore';
import customFetch from '../../../utils/customFetch';
import { toJS } from 'mobx';
import moment from 'moment';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '@utils/constants';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';

const shortcutOptionsTypist = [
  {
    key: 0,
    title: 'Download Dictations',
    type: 'Paperwork',
  },
  {
    key: 1,
    title: 'Upload Typed Reports',
    type: 'Paperwork',
  },
];

const shortcutOptions = [
  {
    key: 0,
    title: 'Update Request Details',
    type: 'CaseDetails',
  },
  {
    key: 1,
    title: 'Upload Case Documents',
    type: 'Paperwork',
  },
  {
    key: 2,
    title: 'Cancellation Case Request',
    type: 'Cancellation',
  },
  {
    key: 3,
    title: 'View Completed Report',
    type: 'Paperwork',
  },
  {
    key: 4,
    title: 'View Invoice',
    type: 'Invoices',
  },
  {
    key: 5,
    title: 'View Case Correspondence',
    type: 'Corespondence',
  },
];

const shortcutOptionsDR = [
  {
    key: 0,
    title: 'View Request Details',
    type: 'CaseDetails',
  },
  {
    key: 1,
    title: 'View Case Documents',
    type: 'Paperwork',
  },
  {
    key: 2,
    title: 'Upload Dictations & Reports',
    type: 'Paperwork',
  },
  {
    key: 3,
    title: 'View Case Correspondence',
    type: 'Corespondence',
  },
];

class TablesSupp extends React.Component {
  openCaseDetail = (record, type) => () => {
    const caseNo = record.SuppNo;
    const id = record.Id;
    localStorage.setItem('SupplementaryCaseId', caseNo);
    //if (api.isAdminOrMagStaffOrAccounting()) {
    dashboardStore.close('add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`add-edit-supplementary-report-2?id=${id}&step=${type}`);
    });
    //}
    // iframe.forceOpenTab('PostSupplementary', `?id=${id}&view=PostSupplementary#${type}`, {
    //   id,
    //   view: 'PostSupplementary',
    // });
  };

  renderShortCutArray = record => {
    const options = api.isTypist()
      ? shortcutOptionsTypist.map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }))
      : api.isCaseManager()
      ? shortcutOptions.map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }))
      : api.isDoctor()
      ? shortcutOptionsDR.map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }))
      : [];
    return options;
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  openCaseProgress = (id, caseNo) => {
    return Store.toggleModal(true, {
      modalType: 'suppCaseProgress',
      id,
      caseNo,
    })();
  };

  openUploadModel = (id, caseNo, status, claimant) => {
    return uploadStore.toggleModal(true, {
      modalType: 'openUploadModel',
      id,
      caseNo,
      status,
      claimant,
      serviceType: 'Supplementary',
      label: 'Supp Report',
      service: this.props.service,
    })();
  };

  onInsertCaseNoToImsHandler = caseNo => () => {
    PubSub.publish(PubSubTopics.SelectCaseNo, caseNo);
    router.history.push('/view/ims');
  };

  renderUploadActions = record => {
    var arrUploadStatus = [
      api.suppStatusEnum.REQUEST_SENT_TO_PROVIDER,
      api.suppStatusEnum.DICTATION_RECEIVED,
      api.suppStatusEnum.REPORT_REVIEW_REQUESTED,
      api.suppStatusEnum.REPORT_RETURNED_FROMDOCTOR,
    ];
    if (api.isDoctor()) {
      arrUploadStatus = [
        api.suppStatusEnum.REQUEST_SENT_TO_PROVIDER,
        api.suppStatusEnum.DICTATION_RECEIVED,
        api.suppStatusEnum.REPORT_DRAFT_RECEIVED,
      ];
    } else if (api.isTypist()) {
      arrUploadStatus = [];
    } else if (api.isCaseManager()) {
      arrUploadStatus = [api.suppStatusEnum.REPORT_REVIEW_REQUESTED];
    }

    if (arrUploadStatus.includes(record.CaseProgress)) {
      return (
        <Table.Cell
          selectable
          className="Cell-link"
          style={{ textAlign: 'center' }}
          onClick={() =>
            this.openUploadModel(
              record.id || record.Id,
              record.SuppNo || record.AssessmentNumber || record.MagRefNo,
              record.StatusStaffText || record.StatusStaffText,
              record.claimant || record.Claimant,
            )
          }
        >
          <Link
            to="#"
            onClick={() =>
              this.openUploadModel(
                record.id || record.Id,
                record.SuppNo || record.AssessmentNumber || record.MagRefNo,
                record.StatusStaffText || record.StatusStaffText,
                record.claimant || record.Claimant,
              )
            }
          >
            <Icon name="upload" size="small"></Icon>UPLOAD
          </Link>
        </Table.Cell>
      );
    } else {
      return <Table.Cell></Table.Cell>;
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(record, 0)}>Update Claim Details</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(record, 1)}>Update Case Documents</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(record, 1)}>Update Dictations & Reports</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(record, 3)}>Update Provider Payment</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(record, 4)}>Update Correspondence</Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to booking CM?',
                onOk: this.handleSendStatusUpdate(id, 0),
              })
            }
          >
            Send Status Update (CMs) (MAIL-105)
          </Menu.Item>
          {record.AssessedDoctorName && (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to send report status update to assessing specialist?',
                  onOk: this.handleSendStatusUpdate(id, 1),
                })
              }
            >
              Send Status Update (Specialists) (MAIL-106)
            </Menu.Item>
          )}
          {(record.IsBilled || record.CaseProgress === 7 || record.CaseProgress === 9) && (
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
          )}
          {/* {(record.CaseProgress === 7 || record.CaseProgress === 9) && ( */}
          <Menu.Item onClick={this.handleAddCopyInvoice(id, 0)}>
            <Icon name="plus" size="small" /> Generate Invoice
          </Menu.Item>
          {/* )} */}
          {record.IsBilled && (
            <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
          )}
          {record.InvoiceId > 0 && <Menu.Item onClick={this.openViewInvoice(record.InvoiceId)}>View invoice</Menu.Item>}
          {record.InvoiceId > 0 && (
            <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print invoice
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message:
                  'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
                onOk: this.handleDuplicateAndRemove(id, record, this.duplicate, `duplicate`),
              })
            }
          >
            Rebook this Case
          </Menu.Item>
          {api.isAdmin() && <Menu.Item onClick={() => this.delete(id, record)}>Delete this Case</Menu.Item>}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.onInsertCaseNoToImsHandler(record.SuppNo)}>Link Inquiry to this Case</Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/Supplementary/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 2, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
          this.props.refetchTable();
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } catch (e) {}
  };

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=S&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'S',
      view: 'ViewInvoice',
    });
  };

  openViewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  handleDuplicateAndRemove = (id, record, fn, act) => async () => {
    try {
      await fn(id, record);

      // notification.destroy();
      // notification.success({
      //   message: 'Success',
      //   description: `Supplementary Report is ${act}d`,
      //   duration: 2,
      // });
      this.props.refetchTable();
    } catch (e) {}
  };

  duplicate = (id, record) => {
    return customFetch('/Supplementary/Supplementary_CopyDetail', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(res => {
      if (res.status === 'success') {
        this.props.refetchTable();
      }
    });
  };

  delete = (id, record) => {
    if (record.CaseProgress === api.suppStatusEnum.CANCELLED_NO_FEE) {
      this.openModal({
        modalType: 'confirm',
        message: 'Do you want to delete this supplementary?',
        onOk: () => {
          return customFetch('/Supplementary/Delete', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({ id }),
          }).then(res => {
            if (res.status === 'success') {
              this.props.refetchTable();
            }
          });
        },
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  openModal = options => {
    return Store.toggleModal(true, options)();
  };

  uploadTilte = () => {
    if (this.props.service) {
      switch (this.props.service) {
        case 'UploadPaperwork':
          return 'Upload Paperwork';
        case 'UploadDictations':
          return 'Upload Dictations';
        case 'UploadReportDrafts':
          return 'Upload Report Drafts';
        case 'UploadReports':
          return 'Upload Reports';
        case 'UploadCorrectedReport':
          return 'Upload Corrected Report';
        default:
          return '';
      }
    }
  };

  openModalNote = options => {
    return Store.toggleModal(true, options);
  };

  convertEmailCreatedAt = email => {
    const email_ = email ? email.replace(/^(.+)@(.+)$/g, '$1') : email;
    return email_;
  };

  render() {
    const { data, curPage, totalPage, sumItem, searchAttachment } = this.props;
    let columns = [];

    if (api.isAdminOrMagStaffOrAccounting()) {
      columns = [
        { title: '' },
        { title: 'Upload' },
        { title: 'Case No' },
        { title: 'Request Date' },
        { title: 'Action Required' },
        { title: 'Original Case' },
        { title: 'Claim No' },
        { title: 'Type Of Claim' },
        { title: 'Claimant' },
        { title: 'Client' },
        { title: 'Case Manager' },
        { title: 'Specialist' },
        { title: 'Case Progress' },
        { title: 'Status Date' },
        { title: 'Created By' },
      ];
      if (this.props.service === 'UploadCorrectedReport' || this.props.service === 'UploadDictations') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Case No' },
          { title: 'Request Date' },
          { title: 'Action Required' },
          { title: 'Original Case' },
          { title: 'Claim No' },
          { title: 'Type Of Claim' },
          { title: 'Claimant' },
          { title: 'Client' },
          { title: 'Case Manager' },
          { title: 'Specialist' },
          { title: 'Case Progress' },
          { title: 'Status Date' },
          { title: 'Created By' },
        ];
      }
    } else {
      columns = [
        { title: '' },
        { title: 'Upload' },
        { title: 'Case No' },
        { title: 'Request Date' },
        { title: 'Origin Case No' },
        { title: 'Claim No' },
        { title: 'Type Of Claim' },
        { title: 'Claimant' },
        { title: 'Client' },
        { title: 'Case Manager' },
        { title: 'Specialist' },
        { title: 'Case Progress' },
      ];
      if (this.props.service === 'UploadCorrectedReport' || this.props.service === 'UploadDictations') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Case No' },
          { title: 'Request Date' },
          { title: 'Type Of Claim' },
          { title: 'Claimant' },
          { title: 'Client' },
          { title: 'Case Progress' },
        ];
      }
    }

    if (searchAttachment) {
      columns = [
        ...columns,
        {
          title: 'Case Documents',
          style: { minWidth: '500px', maxWidth: '500px' },
        },
        {
          title: 'Attachment Type',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded By',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded Date',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
      ];
    }

    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {data.length ? (
            data.map(record => (
              !api.isDoctor() ? <Table.Row key={record.Id}>
                <Table.Cell className="Cell-action">
                  {api.isAdminOrMagStaffOrAccounting() && this.renderTableActions(record.Id, record)}
                  {(api.isCaseManager() || api.isTypist() || api.isDoctor()) && (
                    <ShortcutTable shortcutOptions={this.renderShortCutArray(record)} />
                  )}
                </Table.Cell>
                {this.renderUploadActions(record)}
                <Table.Cell className="Cell-nowrap Cell-link">
                  <Link to="#" onClick={this.openCaseDetail(record, 'CaseDetails')}>
                    {record.SuppNo}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {record.DateRequest
                    ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Table.Cell
                    className={cx(
                      `${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`,
                    )}
                  >
                    <Link
                      to="#"
                      onClick={this.openModalNote({
                        modalType: 'noteSupp',
                        id: record.Id,
                        caseNo: record.FileReviewNumber,
                        client: record.Client,
                      })}
                    >
                      {record.ActionRequired === 0 ? (
                        <span className="link-add-action">(Add Action)</span>
                      ) : (
                        <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                          {record.ActionRequiredType === 0
                            ? '(Add Action)'
                            : record.ActionRequiredType === 1
                            ? '(Action Required)'
                            : '(View Actions)'}
                        </span>
                      )}
                    </Link>
                    {record.ActionOverview && (
                      <div>
                        {record.ActionSubject}
                        <br />
                        {record.ActionSubject ? record.ActionOverview.slice(12) : record.ActionOverview.slice(14)}
                        <br />
                        <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
                      </div>
                    )}
                  </Table.Cell>
                )}
                <Table.Cell>{record.CaseNo}</Table.Cell>
                <Table.Cell>{record.ClaimNo}</Table.Cell>
                <Table.Cell>{record.TypeOfClaim}</Table.Cell>
                <Table.Cell>{record.Claimant}</Table.Cell>
                <Table.Cell>{record.Client}</Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">
                    {!api.isDoctor() && record.CaseManagerId > 0 ? `(${record.CaseManagerId}) ` : ''}
                    {record.CaseManager}
                  </h5>
                  {!api.isDoctor() && <div>{record.CMEmail}</div>}
                  {!api.isDoctor() && <div>{record.CMMobilePhone}</div>}
                </Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">
                    {record.DoctorId > 0 ? `(${record.DoctorId}) ` : ''}
                    {record.AssessedDoctorName}
                  </h5>
                  <div>{record.DoctorEmail}</div>
                  <div>{record.DoctorPhone}</div>
                </Table.Cell>
                <Table.Cell
                  selectable
                  onClick={() => this.openCaseProgress(record.Id, record.SuppNo)}
                  className="Cell-link"
                >
                  <Link onClick={() => this.openCaseProgress(record.Id, record.SuppNo)} to="#">
                    {record.StatusStaffText}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {record.StatusDate
                    ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                <Table.Cell>{record.CreatedByName}</Table.Cell>
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell className="Cell-link">
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx} style={{ minWidth: '500px', maxWidth: '500px', overflow: 'hidden' }}>
                        {!item.IsHyperlink && (
                          <a
                            href={item.PhysicalPath}
                            target="_blank"
                            download={item.FileTitle}
                            rel="noopener noreferrer"
                          >
                            {item.FileTitle}
                          </a>
                        )}
                        {item.IsHyperlink && (
                          <a href={item.FileName} target="_blank" rel="noopener noreferrer">
                            {item.FileName}
                          </a>
                        )}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.ReportTypeName}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedBy}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}

                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedDate
                          ? moment(formatDate(item.UplodatedDate, true), 'DD MMM YYYY, HH:mm').format(
                              'DD/MM/YYYY - HH:mm',
                            )
                          : 'N/A'}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
              </Table.Row> :
              //DOCTOR ROLE
              <Table.Row key={record.Id}>
                <Table.Cell className="Cell-action">
                  {api.isAdminOrMagStaffOrAccounting() && this.renderTableActions(record.Id, record)}
                  {(api.isCaseManager() || api.isTypist() || api.isDoctor()) && (
                    <ShortcutTable shortcutOptions={this.renderShortCutArray(record)} />
                  )}
                </Table.Cell>
                {this.renderUploadActions(record)}
                <Table.Cell className="Cell-nowrap Cell-link">
                  <Link to="#" onClick={this.openCaseDetail(record, 'CaseDetails')}>
                    {record.SuppNo}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {record.DateRequest
                    ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Table.Cell
                    className={cx(
                      `${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`,
                    )}
                  >
                    <Link
                      to="#"
                      onClick={this.openModalNote({
                        modalType: 'noteSupp',
                        id: record.Id,
                        caseNo: record.FileReviewNumber,
                        client: record.Client,
                      })}
                    >
                      {record.ActionRequired === 0 ? (
                        <span className="link-add-action">(Add Action)</span>
                      ) : (
                        <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                          {record.ActionRequiredType === 0
                            ? '(Add Action)'
                            : record.ActionRequiredType === 1
                            ? '(Action Required)'
                            : '(View Actions)'}
                        </span>
                      )}
                    </Link>
                    {record.ActionOverview && (
                      <div>
                        {record.ActionSubject}
                        <br />
                        {record.ActionSubject ? record.ActionOverview.slice(12) : record.ActionOverview.slice(14)}
                        <br />
                        <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
                      </div>
                    )}
                  </Table.Cell>
                )}
                {this.props.service !== 'UploadCorrectedReport' && this.props.service !== 'UploadDictations' && <Table.Cell>{record.CaseNo}</Table.Cell>}
                {this.props.service !== 'UploadCorrectedReport' && this.props.service !== 'UploadDictations' && <Table.Cell>{record.ClaimNo}</Table.Cell>}
                <Table.Cell>{record.TypeOfClaim}</Table.Cell>
                <Table.Cell>{record.Claimant}</Table.Cell>
                <Table.Cell>{record.Client}</Table.Cell>
                {this.props.service !== 'UploadCorrectedReport' && this.props.service !== 'UploadDictations' && <Table.Cell>
                  <h5 className="ma-0">
                    {!api.isDoctor() && record.CaseManagerId > 0 ? `(${record.CaseManagerId}) ` : ''}
                    {record.CaseManager}
                  </h5>
                  {!api.isDoctor() && <div>{record.CMEmail}</div>}
                  {!api.isDoctor() && <div>{record.CMMobilePhone}</div>}
                </Table.Cell>}
                {this.props.service !== 'UploadCorrectedReport' && this.props.service !== 'UploadDictations' && <Table.Cell>
                  <h5 className="ma-0">
                    {record.DoctorId > 0 ? `(${record.DoctorId}) ` : ''}
                    {record.AssessedDoctorName}
                  </h5>
                  <div>{record.DoctorEmail}</div>
                  <div>{record.DoctorPhone}</div>
                </Table.Cell>}
                <Table.Cell
                  selectable
                  onClick={() => this.openCaseProgress(record.Id, record.SuppNo)}
                  className="Cell-link"
                >
                  <Link onClick={() => this.openCaseProgress(record.Id, record.SuppNo)} to="#">
                    {record.StatusStaffText}
                  </Link>
                </Table.Cell>
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell className="Cell-link">
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx} style={{ minWidth: '500px', maxWidth: '500px', overflow: 'hidden' }}>
                        {!item.IsHyperlink && (
                          <a
                            href={item.PhysicalPath}
                            target="_blank"
                            download={item.FileTitle}
                            rel="noopener noreferrer"
                          >
                            {item.FileTitle}
                          </a>
                        )}
                        {item.IsHyperlink && (
                          <a href={item.FileName} target="_blank" rel="noopener noreferrer">
                            {item.FileName}
                          </a>
                        )}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.ReportTypeName}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedBy}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}

                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedDate
                          ? moment(formatDate(item.UplodatedDate, true), 'DD MMM YYYY, HH:mm').format(
                              'DD/MM/YYYY - HH:mm',
                            )
                          : 'N/A'}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage || 1}
          totalPage={totalPage || 1}
          onPageClick={this.handlePageClick}
          totalItems={sumItem || 0}
        />
      </Table>
    );
  }
}

export default TablesSupp;
