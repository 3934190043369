import React from 'react';
import { Dropdown, Form, Menu } from 'antd';
import { Table, Icon, Button } from 'semantic-ui-react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { formatDate, getConversationLogContent, tripHtmlFromBody } from '../../../utils/functions';
import dashboardStore from '../../../stores/dashboard';
import { TableHeader, TableEmptyMessage } from '../../shared/table';
import ConversationModalForm from './ConversationModalForm';
import AddEditStore from './store';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Input } from '@components/shared/tableToolbar/ToolbarComponents';
import { getClientById, loadTalkingPoint } from './service';
import * as api from '@stores/api';

const columns = [
  {},
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Correspondence Type' },
  { title: 'Relationship Follow-Up Due' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
];

@observer
class CaseCorrespondence extends React.Component {
  state = {
    visible: false,
  };

  toggleModal = visible => () => {
    AddEditStore.handleResetBody();
    this.setState({ visible });
  };

  showTalkingPoint = () => {
    const { CMInfo } = AddEditStore;
    //console.log(CMInfo);
    if (CMInfo && CMInfo.CompanyId) {
      dashboardStore.close(`/view/talking-point`, true);
      dashboardStore.open(`/view/talking-point?clientId=` + CMInfo.CompanyId, true);
      // loadTalkingPoint(CMInfo.CompanyId).then(resp => {
      //   if (resp.status === 'success') {
      //     AddEditStore.setFieldsValue({ clientInfo: resp.clientModel });
      //     AddEditStore.setTalkingPoint(resp.itemList);
      //     AddEditStore.setFieldsValue({ loadingTalkingPoint: false });
      //     AddEditStore.toggleModal(true, {
      //       modalType: 'showTalkingPoints',
      //     })();
      //   }
      // });
      // getClientById(CMInfo.CompanyId).then(companyInfo => {
      //     var client = companyInfo;
      //     if(client && client.ClientType){
      //         loadTalkingPoint(client.Id, client.ClientType, client.CityId).then(resp => {
      //             if(resp.status === 'success'){
      //                 AddEditStore.setFieldsValue({clientInfo: resp.clientModel});
      //                 AddEditStore.setTalkingPoint(resp.itemList);
      //                 AddEditStore.setFieldsValue({loadingTalkingPoint: false});
      //                 AddEditStore.toggleModal(true, {
      //                   modalType: 'showTalkingPoints',
      //                 })()
      //             }
      //         })
      //     }
      // });
    } else {
      // AddEditStore.setFieldsValue({ loadingTalkingPoint: false });
      // AddEditStore.setTalkingPoint([]);
      // AddEditStore.toggleModal(true, {
      //   modalType: 'showTalkingPoints',
      // })();
    }
  };

  updateDimensions = () => {
    const tbl = document.getElementById('Table-correspondence');
    const formSize = window.functionGetElementFormForResize();
    const screenWidth = window.innerWidth;
    if (tbl) {
      if (formSize) {
        const percent = formSize / screenWidth;
        return (tbl.style.width = `${screenWidth * percent}px`);
      }
      return (tbl.style.width = `${screenWidth * 0.575}px`);
    }
    return null;
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  converDate = (datestr, showTime = true) => {
    const { conversationList } = AddEditStore;
    const utcServerTime = conversationList.utcServerTime;
    const utcLocalMinutes = new Date().getTimezoneOffset();
    const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr, true);
        return showTime
          ? moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY - HH:mm')
          : moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY');
      }
      return datestr;
    }
    return datestr;
  };

  converMinuteOrHour = (datestr, type) => {
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr);
        if (type === 'hour') {
          return moment(date, 'DD MMM,YYYY').format('HH');
        } else {
          return moment(date, 'DD MMM,YYYY').format('MM');
        }
      }
      return 0;
    }
    return 0;
  };

  converDate1 = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString, true);
          const newDate = moment(dateFormat, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
          return moment(newDate, 'DD/MM/YYYY - HH:mm');
        }
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      } else {
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      }
    }
    return null;
  };

  toggleModalEdit = (visible, record) => async () => {
    const conversationParam = {
      CallerSender: record.CallerSender,
      CallerSenderName: record.CallerSenderName,
      CallerSenderType: record.CallerSenderType === 'Staff' ? 3 : 1,
      CaseManagerId: record.CaseManagerId,
      Content: await getConversationLogContent(record.Id, 'Staff').then(res => res?.content),
      ConversationType: record.CorrespondenceType,
      Id: record.Id,
      Receiver: record.Receiver,
      ReceiverName: record.ReceiverName,
      ReceiverType: record.ReceiverType === 'Staff' ? 3 : 1,
      SentDate: this.converDate1(record.SentDate),
      Subject: record.Subject,
      allowCMToView: record.AllowCMToView,
      allowDoctorToView: record.AllowDoctorToView,
      callerSenderId: record.StaffId,
      hour: this.converMinuteOrHour(record.SentDate, 'hour'),
      minute: this.converMinuteOrHour(record.SentDate, 'minute'),
      receiverId: record.Receiver,
      CampaignId: record.CampaignId,
      CampaignObj: record.CampaignObj,
      Attachments: record.Attachments,
      CampaignAttachments: record.CampaignAttachments,
      CallEmailResultId: record.CallEmailResultId,
      FollowUpNeeded: record.FollowUpNeeded,
      NextDueDate: this.converDate1(record.NextDueDate),
      CCServiceTypeId: record.CCServiceTypeId,
      CCServiceTypeOther: record.CCServiceTypeOther,
      CategoriesIds: record.CategoriesIds
        ? record.CategoriesIds.includes(',')
          ? record.CategoriesIds.replace('[', '')
              .replace(']', '')
              .split(',')
              .map(i => Number(i))
          : [Number(record.CategoriesIds.replace('[', '').replace(']', ''))]
        : null,
    };
    if (record.Attachments) {
      record.Attachments.forEach(function(element) {
        element.reportType = 0;
        element.name = element.FileName;
        element.title = element.Title;
      });
    }

    AddEditStore.setCampaignMergeAttachment(record.CampaignAttachments);

    if (record.Attachments) {
      AddEditStore.UploadFile(
        record.Attachments.map(a => ({
          id: a.Id,
          name: a.FileName,
          filename: a.FileName,
          title: a.Title,
          numberOfPages: a.NumberOfPages,
          reportType: 0,
        })),
        0,
      );
    }

    AddEditStore.setFieldsValue({ conversationParam });
    if (conversationParam.CampaignId) {
      AddEditStore.getCampaignDetail(conversationParam.CampaignId);
    }
    this.setState({ visible });
  };

  openModal = opt => {
    return AddEditStore.toggleModal(true, opt)();
  };

  renderDropdownMenu = record => (
    <Menu>
      <Menu.Item
        onClick={() =>
          this.openModal({
            modalType: 'confirm',
            onOk: () => AddEditStore.handleAllow(record.Id),
            message: `Are you sure to ${!record.AllowCMToView ? 'allow' : 'disallow'} CMs to view this entry?`,
          })
        }
      >
        {!record.AllowCMToView ? 'Allow CMs to view' : 'Disallow CMs to view'}
      </Menu.Item>
      <Menu.Item onClick={this.toggleModalEdit(true, record)}>Edit</Menu.Item>
      <Menu.Item
        onClick={() =>
          this.openModal({
            modalType: 'confirm',
            onOk: () => AddEditStore.handleDelete(record.Id),
            message: 'Are you sure to delete this entry?',
          })
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  renderTableBody = data => {
    return data.map((record, idx) => (
      <Table.Row negative={!record.AllowCMToView} key={idx}>
        <Table.Cell className="Cell-actions">
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(record)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell className="cell-correspondence">
          <div className="Cell-link">
            <Link onClick={this.toggleModalEdit(true, record)} to="#">
              {record.SentDate ? this.converDate1(record.SentDate).format('DD/MM/YYYY HH:mm') : ''}
            </Link>
          </div>
        </Table.Cell>
        <Table.Cell className="cell-correspondence">{record.Subject}</Table.Cell>
        <Table.Cell>
          <div className="cell-correspondence-content-body">{tripHtmlFromBody(record.Content)}</div>
          {record.Content && (
            <div className="Cell-link">
              <Link onClick={this.toggleModalEdit(true, record)} to="#">
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell className="cell-correspondence">{record.TypeLog}</Table.Cell>
        <Table.Cell className="cell-correspondence">
          {record.NextDueDate ? this.converDate1(record.NextDueDate).format('DD/MM/YYYY HH:mm') : ''}
        </Table.Cell>
        <Table.Cell className="cell-correspondence">{record.CallerSenderType}</Table.Cell>
        <Table.Cell className="cell-correspondence">{record.CallerSenderName}</Table.Cell>
        <Table.Cell className="cell-correspondence">{record.ReceiverType}</Table.Cell>
        <Table.Cell className="cell-correspondence">{record.ReceiverName}</Table.Cell>
        <Table.Cell className="cell-correspondence">{record.UserName}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { conversationList, loading, CMInfo } = AddEditStore;
    if (CMInfo.Id === 0) return null;
    return (
      <React.Fragment>
        <div className="fluid-field" style={{ marginBottom: '1em', display: '-webkit-inline-box' }}>
          <Form
            onKeyPress={event => {
              if (event.key === 'Enter') {
                AddEditStore.handleReloadConversation(event.target.value);
              }
            }}
          >
            <Input
              fluid
              loading={AddEditStore.loadingConversation}
              icon="search"
              placeholder="Search any keyword"
              iconPosition="left"
              size={'small'}
            />
          </Form>
          <Button color="blue" disabled={CMInfo.Id === 0} onClick={() => this.showTalkingPoint()}>
            Open Talking Points
          </Button>
          {!api.isOnlySearch() && <Button color="blue" disabled={CMInfo.Id === 0} onClick={this.toggleModal(true)}>
            Add New
          </Button>}
        </div>
        <div className="fluid-field table-correspondence" id="Table-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={columns} />
            <Table.Body>
              {loading || !conversationList ? (
                <TableEmptyMessage colSpan={columns.length} />
              ) : (
                this.renderTableBody(toJS(conversationList.itemList))
              )}
            </Table.Body>
          </Table>
        </div>
        <ConversationModalForm visible={this.state.visible} onCancel={this.toggleModal(false)} />
      </React.Fragment>
    );
  }
}

export default CaseCorrespondence;
