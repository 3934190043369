import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Menu, Table, Input } from 'semantic-ui-react';

import { calcPages } from '../../../utils/functions';
import TableToolbar from '../tableToolbar';

function TableFooterPagination({ totalItems, colSpan, currentPage, totalPage, onPageClick }) {
  const [page, setPage] = React.useState(0);
  const handleClick = page => () => {
    if (typeof page === 'string') {
      const nextPage = currentPage + (page === 'prev' ? -1 : 1);
      if (nextPage > 0 && nextPage <= totalPage) {
        onPageClick(nextPage);
      }
    } else {
      if (page !== currentPage) {
        onPageClick(page);
      }
    }
  };

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan}>
          <div className="Wrap-count-page">
            <p>
              <b>{totalItems}</b>
              {totalItems > 1 ? ' items' : ' item'}{' '}
            </p>
            <p>
              <b>{totalPage}</b> {totalPage > 1 ? ' pages' : 'page'}
            </p>
          </div>
          <Menu pagination className="Table-footer-pagination" style={{ position: 'sticky', left: '10px' }}>
            <Menu.Item as="a" icon onClick={handleClick('prev')}>
              <Icon name="chevron left" />
            </Menu.Item>
            {calcPages(currentPage, totalPage).map(page => (
              <Menu.Item as="a" key={page} active={page === currentPage} onClick={handleClick(page)}>
                {page}
              </Menu.Item>
            ))}
            <Menu.Item as="a" icon onClick={handleClick('next')}>
              <Icon name="chevron right" />
            </Menu.Item>
            {totalPage > 5 && (
              <>
                {' '}
                <Menu.Item as="div">
                  <span>Page: </span>
                  <Input
                    value={page}
                    type="number"
                    onChange={e => setPage(e.target.value)}
                    style={{ width: 100, marginLeft: '5px' }}
                  />
                </Menu.Item>
                <Menu.Item
                  as="a"
                  icon
                  disabled={!page || page < 0 || page > totalPage}
                  onClick={() => {
                    onPageClick(+page);
                  }}
                >
                  Go
                </Menu.Item>
              </>
            )}
          </Menu>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
}

TableFooterPagination.propTypes = {
  colSpan: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
};

export default TableFooterPagination;
