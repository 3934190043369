import EventEmitter from 'eventemitter3';
import { action, observable } from 'mobx';
import Url from 'url-parse';

import { isSpecialMouseEvent } from '../utils/events';
import router from './router';
import appStore from './app';

class DashboardStore extends EventEmitter {
  //
  // State to render tabs on top with a dropdown for overflow tabs
  @observable
  tabs = [];
  @observable
  maxTabs = 1;
  @observable
  tabDropdownActive = false;
  @observable
  loading = false;

  // constructor() {
  //   super();
  //   this.readTabsFromLocalStorage();
  // }

  // readTabsFromLocalStorage = () => {
  //   try {
  //     const tabs = JSON.parse(localStorage.getItem('@tabs') || '[]');
  //     if (Array.isArray(tabs)) {
  //       this.tabs = tabs;
  //     }
  //   } catch(err) {}
  // };
  // writeTabsToLocalStorage = () => {
  //   localStorage.setItem('@tabs', JSON.stringify(this.tabs));
  // };

  //
  // State to handle drag/drop reorder tabs with animation
  @observable
  tabDragdropState = {
    dragging: false,
    index: -1,
    x: {},
  };
  //
  // State to handle sidebar auto-hide toggle
  @observable
  sidebar = {
    autohide: window.innerWidth < 1300,
    visible: false,
  };
  //
  // Data which does not directly effect the rendering
  //
  // To save and keep scroll position when reopen a tab
  prevPath = '';
  savedScroll = {};
  // To save and calculate drag/drop data correctly
  tabWidth = 0;
  tabDragdropData = null;
  // To calculate the maximum number of tabs via DOM selectors before they get overflow
  tabSelector = '';
  tabsSelector = '';
  // To make sure the max tabs get updated at least once
  updateMaxTabsIntervalId = 0;

  didmount = (tabSelector, tabsSelector) => {
    this.tabSelector = tabSelector;
    this.tabsSelector = tabsSelector;
    // To make sure the max tabs get updated at least once
    this.updateMaxTabsIntervalId = setInterval(this.updateMaxTabs, 170);
    window.addEventListener('resize', this.updateMaxTabs, true);
  };
  unmount = () => {
    // Cleanup possible listeners
    if (this.updateMaxTabsIntervalId) {
      clearInterval(this.updateMaxTabsIntervalId);
      this.updateMaxTabsIntervalId = 0;
    }
    window.removeEventListener('resize', this.updateMaxTabs, true);
  };

  @action
  updateMaxTabs = () => {
    const tab = document.querySelector(this.tabSelector);
    const tabs = document.querySelector(this.tabsSelector);
    if (!tab || !tabs) {
      return;
    }
    this.tabWidth = tab.clientWidth;
    this.maxTabs = Math.floor(tabs.clientWidth / this.tabWidth) || 1;
    // Clear the registered interval from initializing
    if (this.updateMaxTabsIntervalId) {
      clearInterval(this.updateMaxTabsIntervalId);
      this.updateMaxTabsIntervalId = 0;
    }
  };

  @observable dataTransfer = null;
  @observable pageGetter = null;
  @observable fieldName = null;
  @observable listPageGetters = [
    {
      key: 'addEditIME',
      pathname: '/view/add-edit-ime-assessment-2',
    },
    {
      key: 'addEditFR',
      pathname: '/view/add-edit-file-review-2',
    },
    {
      key: 'addEditSupp',
      pathname: '/view/add-edit-supplementary-report-2',
    },
    {
      key: 'addEditCR',
      pathname: '/view/add-edit-clinical-notes-2',
    },
  ];

  @action transferData = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };
  /**
   * Open/close tabs logic
   */
  @action
  open = (
    absUrl,
    { scrollTop = document.documentElement.scrollTop, scrollLeft = document.documentElement.scrollLeft } = {},
  ) => {
    const url = new Url(absUrl);
    const path = url.pathname;
    this.emit(`open(${path})`);
    if (appStore.iframeMode) {
      window.parent.postMessage(
        {
          action: 'OPEN_TAB',
          data: {
            pathName: path,
          },
        },
        '*',
      );
      return;
    }

    //
    const newTab = { path, absUrl };
    const index = this.tabs.findIndex(t => t.path === path);
    // If the tab is not rendered, insert it to the tabs list
    if (index < 0) {
      // If it's not reached the max tabs simply insert the new tab to the end
      if (this.tabs.length < this.maxTabs) {
        this.tabs.push(newTab);
        // Otherwise, insert it at the max tabs index position
      } else {
        this.tabs = [...this.tabs.slice(0, this.maxTabs - 1), newTab, ...this.tabs.slice(this.maxTabs - 1)];
      }
      // Otherwise if the tab is already rendered, and currently hidden (out of max tabs index)
      //    we will move it to the max tabs index position
    } else if (index >= this.maxTabs) {
      const tabs = this.tabs.filter(t => t.path !== path);
      this.tabs = [...tabs.slice(0, this.maxTabs - 1), newTab, ...tabs.slice(this.maxTabs - 1)];
    }
    // this.writeTabsToLocalStorage();
    // Check if the path is not the current pathname in location
    // We manually push the history to keep it in sync
    this.pushHistoryIfNotCurrent(absUrl);
    //
    // Close the sidebar if in auto hide mode
    if (this.sidebar.autohide && this.sidebar.visible) {
      this.toggleSidebarVisible();
    }
    // Save and update scroll value
    if (path !== this.prevPath) {
      if (this.prevPath) {
        this.savedScroll[this.prevPath] = {
          scrollTop,
          scrollLeft,
        };
      }
      requestAnimationFrame(() => {
        const s = this.savedScroll[path];
        document.documentElement.scrollTop = s?.scrollTop || 0;
        document.documentElement.scrollLeft = s?.scrollLeft || 0;
      });
    }
    this.prevPath = path;
  };
  @action
  close = (absUrl, useInclude = false) => {
    const url = new Url(absUrl);
    const path = url.pathname;

    if (appStore.iframeMode) {
      window.parent.postMessage(
        {
          action: 'CLOSE_TAB',
          data: {
            pathName: path,
          },
        },
        '*',
      );
      return;
    }

    const index = this.tabs.findIndex(t => {
      if (useInclude) {
        return t.path.includes(path);
      }
      return t.path === path;
    });

    this.tabs = this.tabs.filter(t => t.path !== path);
    // this.writeTabsToLocalStorage();
    // Open dashboard tab if there's no tab left
    if (!this.tabs.length) {
      this.open('/');
      // Open the tab next to the closed one if it's the current tab
    } else if (path === router.location.pathname) {
      const newIndex = Math.min(index, this.tabs.length - 1);
      this.open(this.tabs[newIndex].absUrl);
    }
    delete this.savedScroll[path];
  };
  closureCloseTab = absUrl => e => {
    e.preventDefault();
    this.close(absUrl);
  };
  closureCloseDropdownTab = absUrl => e => {
    e.preventDefault();
    this.close(absUrl);
    // Keep the dropdown visible by not propagate the event to the listener in the window object
    e.stopPropagation();
  };
  pushHistoryIfNotCurrent = absUrl => {
    const { pathname, search } = router.location;
    if (absUrl !== pathname + search) {
      router.history.push(absUrl);
    }
  };

  /**
   * Dropdown tabs open/close logic
   */
  @action
  setTabTitle = (absUrl, title) => {
    const url = new Url(absUrl);
    const path = url.pathname;
    let index = this.tabs.findIndex(t => t.path === path);

    if (index >= 0) {
      this.tabs[index] = { ...this.tabs[index], title };
    }
    console.log(this.tabs[index]);
  };
  @action
  openTabDropdown = () => {
    if (this.tabDropdownActive) {
      return;
    }
    this.tabDropdownActive = true;
    requestAnimationFrame(() => {
      window.removeEventListener('click', this.closeTabDropdown);
      window.addEventListener('click', this.closeTabDropdown);
    });
  };
  @action
  closeTabDropdown = () => {
    this.tabDropdownActive = false;
    window.removeEventListener('click', this.closeTabDropdown);
  };

  /**
   * Drag/drop reorder tabs logic
   */
  closureOnTabMouseDown = index =>
    action(e => {
      if (isSpecialMouseEvent(e)) {
        return;
      }
      // Need to manually close the dropdown tabs
      // Because we prevent default the mouse down event so the click event will never fire
      this.closeTabDropdown();
      //
      e.preventDefault();
      if (this.tabDragdropData) {
        return;
      }
      this.cleanupTabDragdropListeners();
      this.registerTabDragdropListeners();
      //
      this.tabDragdropData = {
        index,
        newIndex: index,
        hasMoved: false,
        pageX: e.pageX,
      };
      this.tabDragdropState.dragging = true;
      this.tabDragdropState.index = index;
    });
  @action
  onTabMouseMove = nativeEvent => {
    nativeEvent.preventDefault();
    this.tabDragdropData.hasMoved = true;
    //
    const { index } = this.tabDragdropData;
    const maxIndex = Math.min(this.maxTabs, this.tabs.length);
    //
    let x = nativeEvent.pageX - this.tabDragdropData.pageX;
    const minX = -(index + 0.1) * this.tabWidth;
    const maxX = (maxIndex - index - 0.9) * this.tabWidth;
    if (x < minX) {
      x = minX;
    } else if (x > maxX) {
      x = maxX;
    }
    //
    const f = Math.abs(x) / this.tabWidth;
    let d = Math.floor(f);
    if (f - d >= 0.5) {
      d += 1;
    }
    if (x < 0) {
      d = -d;
    }
    //
    let newIndex = index + d;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= maxIndex) {
      newIndex = maxIndex - 1;
    }
    this.tabDragdropData.newIndex = newIndex;
    //
    this.tabDragdropState.x = {};
    const fr = newIndex > index ? index + 1 : newIndex;
    const to = newIndex > index ? newIndex : index - 1;
    const rate = newIndex > index ? -1 : 1;
    for (let i = fr; i <= to; i++) {
      this.tabDragdropState.x[i] = rate * this.tabWidth;
    }
    this.tabDragdropState.x[index] = x;
    //
    this.pushHistoryIfNotCurrent(this.tabs[index].absUrl);
  };
  @action
  onTabMouseUp = nativeEvent => {
    this.cleanupTabDragdropListeners();
    //
    this.tabDragdropState.index = -1;
    if (!this.tabDragdropData.hasMoved) {
      this.pushHistoryIfNotCurrent(this.tabs[this.tabDragdropData.index].absUrl);
      this.endTabDropAnimation();
      return;
    }
    //
    requestAnimationFrame(this.startTabDropAnimation);
  };
  @action
  startTabDropAnimation = () => {
    const { index, newIndex } = this.tabDragdropData;
    this.tabDragdropState.x[index] = (newIndex - index) * this.tabWidth;
    setTimeout(this.endTabDropAnimation, 217); // 200ms in Tab.scss + 1 tick
  };
  @action
  endTabDropAnimation = () => {
    this.reorderTabs();
    this.tabDragdropData = null;
    this.tabDragdropState.dragging = false;
    this.tabDragdropState.x = {};
  };
  reorderTabs = () => {
    if (this.tabs.length === 1) {
      return;
    }
    const { index, newIndex } = this.tabDragdropData;
    const tab = this.tabs[index];
    const fr =
      newIndex > index ? this.tabs.slice(0, newIndex + 1).filter(t => t !== tab) : this.tabs.slice(0, newIndex);
    const to = newIndex > index ? this.tabs.slice(newIndex + 1) : this.tabs.slice(newIndex).filter(t => t !== tab);
    this.tabs = [...fr, tab, ...to];
    // this.writeTabsToLocalStorage();
  };
  registerTabDragdropListeners = () => {
    window.addEventListener('mousemove', this.onTabMouseMove, true);
    window.addEventListener('mouseup', this.onTabMouseUp, true);
  };
  cleanupTabDragdropListeners = () => {
    window.removeEventListener('mousemove', this.onTabMouseMove, true);
    window.removeEventListener('mouseup', this.onTabMouseUp, true);
  };

  /**
   * Toggle sidebar logic
   */
  @action
  toggleSidebarAutohide = () => {
    this.sidebar.autohide = !this.sidebar.autohide;
    if (this.sidebar.visible) {
      this.toggleSidebarVisible();
    }
    requestAnimationFrame(this.updateMaxTabs);
  };
  @action
  toggleSidebarVisible = () => {
    this.sidebar.visible = !this.sidebar.visible;
    const fn = this.sidebar.visible ? 'add' : 'remove';
    document.body.classList[fn]('overflow-hidden');
  };

  /**
   * Page loading for waiting async request
   */
  @action
  toggleLoading = () => {
    this.loading = !this.loading;
  };
}

const dashboardStore = new DashboardStore();
window.closeCurrentTab = () => {
  const { pathname, search } = router.location;
  dashboardStore.close(pathname + search);
};

window.openNewUI = url => {
  dashboardStore.open(url);
};

export default dashboardStore;
