import './Header.scss';
import React from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';

const Header = ({
  title,
  isChecked,
  text,
  date,
  statuses = [],
  shouldFormatDate = true,
  isRequired = true,
  showGreenTick = true,
}) => (
  <div className="ant-collapse-header-content">
    <span className={cx('title', { required: isRequired })}>{title}</span>
    <div className="status">
      {statuses?.length > 0
        ? statuses
            .filter(s => s.isChecked)
            .map(s => (
              <div key={s.key ?? s.id}>
                <Icon name="check" color="green" />
                <span className="text">
                  {s.text}
                  {s.date ? ` (${s.shouldFormatDate ? moment(s.date).format('DD/MM') : s.date})` : ''}
                </span>
              </div>
            ))
        : isChecked && (
            <React.Fragment>
              {showGreenTick && <Icon name="check" color="green" />}
              <span className="text">
                {text}
                {date ? ` (${shouldFormatDate ? moment(date).format('DD/MM') : date})` : ''}
              </span>
            </React.Fragment>
          )}
    </div>
  </div>
);

export default React.memo(Header);
