import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Icon, TextArea, Input, Select, Checkbox } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { InputNumber, DatePicker, Input as InputText } from 'antd';
import moment from 'moment';
import Modal from '../../shared/Modal';
import ServiceFeeStore from './AMAServiceFeeStore';
import customFetch from '../../../utils/customFetch';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/functions';
// import {  SelectSearch,} from '../../shared/form/Input';

import { uniqBy } from 'lodash';

const statusOptions = [
  {
    text: 'Active',
    value: 0,
  },
  {
    text: 'Inactive',
    value: 1,
  },
];

@observer
class ModalCreateEditServiceFee extends React.Component {
  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  constructor(props) {
    ServiceFeeStore.fetchExcludedAMACodeFiles(ServiceFeeStore.modalParams.id);
    ServiceFeeStore.fetchCaluationRule();
    ServiceFeeStore.refetch({ isSelectionList: true, arrExcludedId: [] }, true);
    super(props);
    this.state = {
      Id: ServiceFeeStore.modalParams.id,
      AMACode:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.AMACode
          ? ServiceFeeStore.modalParams.record.AMACode
          : '',
      ServiceType:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.ServiceType
          ? ServiceFeeStore.modalParams.record.ServiceType
          : '',
      MBSCode:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.MBSCode
          ? ServiceFeeStore.modalParams.record.MBSCode
          : '',
      Description:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Description
          ? ServiceFeeStore.modalParams.record.Description
          : '',
      TypeProcedures:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.TypeProcedures
          ? ServiceFeeStore.modalParams.record.TypeProcedures
          : '',
      Anaes:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Anaes
            ? ServiceFeeStore.modalParams.record.Anaes
            : false,
      Assist:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Assist
            ? ServiceFeeStore.modalParams.record.Assist
            : false,
      Fee:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Fee
          ? ServiceFeeStore.modalParams.record.Fee
          : '',
      PublishedYear:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.PublishedYear
          ? ServiceFeeStore.modalParams.record.PublishedYear
          : '',
      BaseUnit:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.BaseUnit
          ? ServiceFeeStore.modalParams.record.BaseUnit
          : '',
      UnitRate:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.UnitRate
          ? ServiceFeeStore.modalParams.record.UnitRate
          : '',
      Status: ServiceFeeStore.modalParams.record ? ServiceFeeStore.modalParams.record.Status : 0,
      Keyword:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.Keyword
          ? ServiceFeeStore.modalParams.record.Keyword
          : '',
      ClinicalIndication:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.ClinicalIndication
          ? ServiceFeeStore.modalParams.record.ClinicalIndication
          : '',
      AdditionalNotes:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.AdditionalNotes
          ? ServiceFeeStore.modalParams.record.AdditionalNotes
          : '',
      ExclutionRange:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.ExclutionRange
          ? ServiceFeeStore.modalParams.record.ExclutionRange
          : '',
      DefaultCanculationRule:
        ServiceFeeStore.modalParams.record && ServiceFeeStore.modalParams.record.DefaultCanculationRule
          ? ServiceFeeStore.modalParams.record.DefaultCanculationRule
          : null,
    };

    if (
      ServiceFeeStore.modalParams.record &&
      ServiceFeeStore.modalParams.record.ExcusionCodeRanges &&
      ServiceFeeStore.modalParams.record.ExcusionCodeRanges.length > 0
    ) {
      var idxCount = 1;
      var arrExRange = [];
      ServiceFeeStore.modalParams.record.ExcusionCodeRanges.forEach(ex => {
        arrExRange.push({ idx: idxCount, Id: ex.Id, AmaCodeId: ex.AmaCodeId, ExclusionRange: ex.ExclusionRange });
        idxCount++;
      });
      ServiceFeeStore.setListExclusionRange(arrExRange);
    } else {
      ServiceFeeStore.setListExclusionRange([]);
    }

    if (
      ServiceFeeStore.modalParams.record &&
      ServiceFeeStore.modalParams.record.ListAMACodeFee &&
      ServiceFeeStore.modalParams.record.ListAMACodeFee.length > 0
    ) {
      var idxCount = 1;
      var arrFees = [];
      ServiceFeeStore.modalParams.record.ListAMACodeFee.forEach(f => {
        arrFees.push({
          idx: idxCount,
          Id: f.Id,
          AMACodeId: f.AMACodeId,
          Fee: f.Fee,
          FromDate: this.momentToJS(f.FromDate),
          EndDate: this.momentToJS(f.EndDate),
          Description: f.Description,
        });
        idxCount++;
      });
      ServiceFeeStore.setListAMACodeFee(arrFees);
    } else {
      ServiceFeeStore.setListAMACodeFee([]);
    }
  }

  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  handleResetDataModal = () => {
    ServiceFeeStore.setFieldsValue({ isSelectionList: false, arrExcludedId: [], searchExcludedText: '' }, true);
  };

  componentWillUnmount() {
    this.handleResetDataModal();
  }

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  @action onChangeExcludedAMA = (_, { value }) => {
    ServiceFeeStore.listExcludedAMA = value;
  };

  @action onDoctorSelectChange = (_, { value, options }) => {
    ServiceFeeStore.arrExcludedId = value;
    ServiceFeeStore.selectedExcluded = options.filter(o => !!value.find(v => v === o.value));
    ServiceFeeStore.searchExcludedText = '';
  };

  handleEditServiceItem = () => async () => {
    if (!this.state.AMACode) {
      return;
    }

    // if (!this.state.Fee) {
    //   return;
    // }

    ServiceFeeStore.setFieldsValue({ loading: true });
    try {
      var request = { ...this.state };
      request.ListExcludedAMA = ServiceFeeStore.arrExcludedId;
      request.ExcusionCodeRanges = ServiceFeeStore.lstExclusionRange;
      request.ListAMACodeFee = ServiceFeeStore.lstAMACodeFee;

      await customFetch('/MAGService/SaveAMAFeeCode', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(request),
      }).then(respone => {
        if (respone.status === 'success') {
          this.props.onCancel();
        }
      });
      await ServiceFeeStore.refetch({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  handleChangeDropdown = (_, { name, value }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleChecked = (_, { value }) => {
    if (value === 'Anaes') {
      this.setState({ ...this.state, Anaes: !this.state.Anaes });
    }
    if (value === 'Assist') {
      this.setState({ ...this.state, Assist: !this.state.Assist });
    }
  }

  onChange = event => {
    const { name, value } = event.target;
    if (name === 'Fee') {
      const value_ = (value || '').replace(/\D/g, '').replace(/[^\d]/g, '');
      return this.setState({ ...this.state, [name]: +value_ || 0 });
    }
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  handleChangeSearchDropdown = (_, { searchQuery }) => {
    return ServiceFeeStore.refetch({
      searchExcludedText: searchQuery,
      numberPageSearch: 30,
      loadingSearch: true,
      isSelectionList: true,
    });
  };

  onAddExclusionRange = () => {
    var newArr = toJS(ServiceFeeStore.lstExclusionRange);
    newArr.push({ idx: newArr.length + 1, Id: 0, AmaCodeId: this.state.Id, ExclusionRange: null });
    ServiceFeeStore.setListExclusionRange(newArr);
  };

  handleOnChangeDropDown = name => (e, { value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  onDeleteExclusionRange = idx => {
    var oldArr = toJS(ServiceFeeStore.lstExclusionRange);
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(i => {
      if (i.idx !== idx) {
        newArr.push({ idx: countIdx, Id: i.Id, AmaCodeId: i.AmaCodeId, ExclusionRange: i.ExclusionRange });
        countIdx++;
      }
    });
    ServiceFeeStore.setListExclusionRange(newArr);
  };

  handleExclusionRange = idx => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var newArray = toJS(ServiceFeeStore.lstExclusionRange);
    newArray.forEach(i => {
      if (i.idx === idx) {
        i.ExclusionRange = value;
      }
    });
    ServiceFeeStore.setListExclusionRange(newArray);
  };

  renderExcludedAMA = () => {
    const amaSelectionList = uniqBy(
      [...toJS(ServiceFeeStore.amaSelectionList), ...toJS(ServiceFeeStore.selectedExcluded)],
      'key',
    );
    const options = orderBy(amaSelectionList || []).map(r => ({
      key: r.value,
      text: `${r.text}`,
      value: r.value,
      content: (
        <div>
          <div className="text-bold">{r.text}</div>
        </div>
      ),
    }));

    return (
      <React.Fragment>
        <Form.Field
          control={Select}
          label="Exclusion Codes"
          placeholder="Exclusion Codes"
          name="ExcludedAMACodes"
          multiple
          search
          allowAdditions
          additionLabel=""
          disabled={ServiceFeeStore.loadingSearch}
          selectOnNavigation={false}
          options={[...ServiceFeeStore.selectedExcluded, ...options]}
          value={toJS(ServiceFeeStore.arrExcludedId)}
          onChange={this.onDoctorSelectChange}
          onSearchChange={this.handleChangeSearchDropdown}
          searchQuery={ServiceFeeStore.searchExcludedText}
        />
        {/* <SelectSearch
          label="Excluded Service Codes 2"
          multiple
          placeholder="Select AMA Fee Code"
          noResultsMessage="No AMA Fee Code found"
          disabled={ServiceFeeStore.loadingSearch}
          options={[...ServiceFeeStore.selectedExcluded, ...options]}
          value={toJS(ServiceFeeStore.arrExcludedId)}
          onChange={this.onDoctorSelectChange}
          onSearchChange={this.handleChangeSearchDropdown}
          searchQuery={ServiceFeeStore.searchExcludedText}
        /> */}
      </React.Fragment>
    );
  };

  onAddAMACodeFee = () => {
    var newArr = toJS(ServiceFeeStore.lstAMACodeFee);
    newArr.push({
      idx: newArr.length + 1,
      Id: 0,
      AMACodeId: this.state.Id,
      Fee: null,
      FromDate: null,
      EndDate: null,
      Description: null,
    });
    //console.log(newArr);
    ServiceFeeStore.setListAMACodeFee(newArr);
  };

  handleChangeAMACodeFee = (idx, fieldName) => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var newArray = toJS(ServiceFeeStore.lstAMACodeFee);
    newArray.forEach(i => {
      if (i.idx === idx) {
        if (fieldName === 'Fee') i.Fee = value;
        if (fieldName === 'Description') i.Description = value;
      }
    });
    ServiceFeeStore.setListAMACodeFee(newArray);
  };

  handleChangeUnitRate = () => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var name = 'UnitRate';
    this.setState({ ...this.state, [name]: value });
  };

  onDeleteAMACodeFee = idx => {
    var oldArr = toJS(ServiceFeeStore.lstAMACodeFee);
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(i => {
      if (i.idx !== idx) {
        newArr.push({
          idx: countIdx,
          Id: i.Id,
          AMACodeId: i.AMACodeId,
          Fee: i.Fee,
          FromDate: i.FromDate,
          EndDate: i.EndDate,
          Description: i.Description,
        });
        countIdx++;
      }
    });
    ServiceFeeStore.setListAMACodeFee(newArr);
  };

  @action setAMACodeDateValue = (idx, name) =>
    action(event => {
      const value = event;
      var newArray = toJS(ServiceFeeStore.lstAMACodeFee);
      newArray.forEach(i => {
        if (i.idx === idx) {
          if (name === 'FromDate') i.FromDate = value;
          if (name === 'EndDate') i.EndDate = value;
        }
      });
      ServiceFeeStore.setListAMACodeFee(newArray);
    });

  render() {
    const { open, lstExclusionRange, lstAMACodeFee } = ServiceFeeStore;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleEditServiceItem()}>
              <Icon name="save" /> Save
            </Button>
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title="AMA Fee Code"
      >
        <Form onChange={this.onChange}>
          <Form.Field
            control={Input}
            label="AMA Code"
            placeholder="AMA Code"
            value={this.state.AMACode}
            required
            error={
              this.state.AMACode === '' || !this.state.AMACode
                ? {
                    content: 'This field is required',
                  }
                : null
            }
            name="AMACode"
          />
          <Form.Field
            control={Input}
            label="MBS Code"
            placeholder="MBS Code"
            value={this.state.MBSCode}
            name="MBSCode"
          />
          <Form.Field
            control={Input}
            label="Service Type"
            placeholder="Service Type"
            value={this.state.ServiceType}
            name="ServiceType"
          />
          <Form.Field
            control={Input}
            label="Type of Procedures"
            placeholder="Type of Procedures"
            value={this.state.TypeProcedures}
            name="TypeProcedures"
          />
          <Form.Field
            control={Checkbox}
            label="Anaes"
            value={`Anaes`}
            style={{ marginBottom: 15, marginLeft: 2 }}
            width={5}
            onChange={this.handleChecked}
            checked={this.state.Anaes}
          />
          <Form.Field
            control={Checkbox}
            label="Assist"
            value={`Assist`}
            style={{ marginBottom: 15, marginLeft: 2 }}
            width={5}
            onChange={this.handleChecked}
            checked={this.state.Assist}
          />
          <Form.Field
            control={Select}
            label="Calculation Rule"
            placeholder="Calculation Rule"
            name="CalculationName"
            options={ServiceFeeStore.lstCalculationRule.map(opt => ({
              text: opt.CalculationName,
              value: opt.Id,
            }))}
            value={this.state.DefaultCanculationRule}
            onChange={this.handleOnChangeDropDown('DefaultCanculationRule')}
          />
          {this.renderExcludedAMA()}
          {!!toJS(lstExclusionRange) &&
            toJS(lstExclusionRange).map(item => {
              return (
                <React.Fragment>
                  <div className="field">
                    <label>
                      Exclusion Range {item.idx}{' '}
                      <Link key={'link-' + item.idx} to="#" onClick={() => this.onDeleteExclusionRange(item.idx)}>
                        {' '}
                        Delete
                      </Link>
                    </label>
                  </div>
                  <Input
                    style={{ width: '100%' }}
                    type="text"
                    key={'ex-' + item.idx}
                    name={'ex-' + item.idx}
                    value={item.ExclusionRange || ''}
                    onChange={this.handleExclusionRange(item.idx)}
                  />
                </React.Fragment>
              );
            })}
          <Button color="green" onClick={this.onAddExclusionRange} style={{ marginTop: '8px' }}>
            <Icon name="plus" />
            Add Exclusion Range
          </Button>
          <Form.Field
            control={TextArea}
            label="Item Description"
            placeholder="Item Description"
            value={this.state.Description}
            name="Description"
          />
          <Form.Field
            control={TextArea}
            label="Clinical Indication"
            placeholder="Clinical Indication"
            value={this.state.ClinicalIndication}
            name="ClinicalIndication"
          />
          <Form.Field
            control={TextArea}
            label="Additional Notes"
            placeholder="Additional Notes"
            value={this.state.AdditionalNotes}
            name="AdditionalNotes"
          />
          <Form.Field
            control={Input}
            type="number"
            label="Base Unit"
            placeholder="Base Unit"
            value={this.state.BaseUnit}
            name="BaseUnit"
          />
          {/* <div className="field">
            <label>Unit Rate</label>
            <InputNumber
                addonBefore="$"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={this.state.UnitRate}
                onChange={this.handleChangeUnitRate()}
              />
          </div> */}
          <div className="field" style={{ marginBottom: '0px' }}>
            <label>Fee Schedule</label>
          </div>
          {!!toJS(lstAMACodeFee) &&
            toJS(lstAMACodeFee).map(item => {
              return (
                <React.Fragment>
                  <div style={{ width: '15%', float: 'left' }}>
                    <label>
                      Fee {item.idx}{' '}
                      <Link key={'fee-' + item.idx} to="#" onClick={() => this.onDeleteAMACodeFee(item.idx)}>
                        {' '}
                        Delete
                      </Link>
                    </label>
                    :{' '}
                    <InputNumber
                      addonBefore="$"
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      value={item.Fee}
                      onChange={this.handleChangeAMACodeFee(item.idx, 'Fee')}
                    />
                  </div>
                  <div style={{ width: '30%', float: 'left', marginLeft: '5%' }}>
                    <label>Description</label>:{' '}
                    <InputText
                      type="text"
                      value={item.Description}
                      onChange={this.handleChangeAMACodeFee(item.idx, 'Description')}
                    />
                  </div>
                  <div style={{ width: '20%', float: 'left', marginLeft: '5%' }}>
                    <label>From Date</label>:
                    <DatePicker
                      value={this.convertDate(item.FromDate)}
                      format="DD/MM/YYYY"
                      onChange={this.setAMACodeDateValue(item.idx, 'FromDate')}
                    />
                  </div>
                  <div style={{ width: '20%', float: 'left', marginLeft: '5%' }}>
                    <label>To Date</label>:
                    <DatePicker
                      value={this.convertDate(item.EndDate)}
                      format="DD/MM/YYYY"
                      onChange={this.setAMACodeDateValue(item.idx, 'EndDate')}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          <Button color="green" onClick={this.onAddAMACodeFee} style={{ marginTop: '8px' }}>
            <Icon name="plus" />
            Add Fee
          </Button>
          {/* <Form.Field
            control={Input}
            label="Fee"
            placeholder="Scheduled Fee (excl GST)"
            value={`$ ${this.state.Fee}`}
            name="Fee"
            required
            error={
              this.state.Fee === '' || !this.state.Fee
                ? {
                    content: 'This field is required',
                  }
                : null
            }
          /> */}
          <Form.Field
            control={Select}
            label="Status"
            placeholder="Status"
            name="Status"
            value={this.state.Status}
            options={this.renderStatusLabel()}
            onChange={this.handleChangeDropdown}
          />
          <Form.Field
            control={Input}
            label="Published Year"
            placeholder="Published Year"
            value={this.state.PublishedYear}
            name="PublishedYear"
          />
          <Form.Field control={Input} label="Keyword" placeholder="Keyword" value={this.state.Keyword} name="Keyword" />
        </Form>
      </Modal>
    );
  }
}

export default ModalCreateEditServiceFee;
