import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import JobTitleStore from './JobTitleStore';

function JobTitleModal() {
  const { modalType } = JobTitleStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...JobTitleStore} onCancel={JobTitleStore.toggleModal(false)} />;
  }
}

export default JobTitleModal;
