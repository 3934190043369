import React from 'react';

import dashboard from '../../stores/dashboard';

class DashboardRedirect extends React.Component {
  open = path => {
    dashboard.open(path);
    dashboard.close(this.props.path);
    dashboard.open(path);
  };

  componentDidMount() {
    this.open('/view/dashboard');
  }

  render() {
    return null;
  }
}

export default DashboardRedirect;
