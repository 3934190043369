import './Table.scss';
// import dashboardStore from '@stores/dashboard';
import cx from 'classnames';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Container, Grid, Icon, Table as SemanticTable } from 'semantic-ui-react';
import ModalConfirm from '@components/shared/Modal/ModalConfirm';
import * as api from '../../../stores/api';
import AjaxResult from '../../../utils/AjaxResult';
import Pagination from '../../shared/Pagination';
import iframe from '../Iframe/iframeData';
import moment from 'moment';
import dashboardStore from '../../../stores/dashboard';
import appStore from 'stores/app';

const SORT_ASC = 'ascending';
const SORT_DSC = 'descending';

@observer
class Table extends React.Component {
  numberPerPage = 10;

  @observable sortColumn = '';
  @observable sortDirection = SORT_ASC;
  @observable currentPage = 1;
  @observable activeItem = '';
  @observable expanded = true;
  @observable open = false;
  @observable modalParams = {};

  @action toggleModal = (isOpen, params = {}) => {
    this.open = isOpen;
    this.modalParams = params;
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { list } = this.props;
    if (!list || !this.expanded || list.some(i => i.Id === this.activeItem)) {
      return;
    }
    const containsImmediateAvailable = list.some(i => i.Id === 'ImmediateAvailable');
    const l = containsImmediateAvailable ? 2 : 1;
    if (list.length > l) {
      runInAction(() => {
        this.activeItem = this.props.list[l].Id;
      });
    }
  }

  closureHandleSort = clickedColumn =>
    action(() => {
      if (this.sortColumn !== clickedColumn) {
        this.sortColumn = clickedColumn;
        this.sortDirection = SORT_ASC;
        return;
      }

      this.sortDirection = this.sortDirection === SORT_ASC ? SORT_DSC : SORT_ASC;
    });

  /**
   * Action change pagination
   */
  @action
  onPageChange = (e, { activePage }) => {
    this.currentPage = activePage;
  };

  onExpand = activeItem =>
    action(() => {
      if (activeItem === 'ImmediateAvailable') {
        return;
      }
      if (this.expanded && activeItem === this.activeItem) {
        this.activeItem = '';
        this.expanded = false;
      } else {
        this.activeItem = activeItem;
        this.expanded = true;
      }
    });

  onMouseEnter = ({ Id, FirstName, LastName }) => event => {
    this.props.onMouseEnter(event, {
      id: Id,
      title: 'Dr',
      firstName: FirstName,
      lastName: LastName,
    });
  };

  /**
   * PURE Helper method to render header cell(s)
   * based on TABLE_FIELDS
   * Ctrl + F TABLE_FIELDS for understanding what item MUST be
   */
  renderHeaderCell = ({ id, sortable = false, text }) => {
    if (sortable) {
      const sorted = this.sortColumn === id ? this.sortDirection : null;
      return (
        <SemanticTable.HeaderCell key={id} sorted={sorted} onClick={this.closureHandleSort(id)}>
          {text}
        </SemanticTable.HeaderCell>
      );
    }

    return <SemanticTable.HeaderCell key={id}>{text}</SemanticTable.HeaderCell>;
  };

  /**
   * Pure Helper method to render header
   * It will call renderHeaderCell above
   *
   * Requirement: fieldList @type {TABLE_FIELDS}
   */
  renderTableHeader = () => (
    <SemanticTable.Header>
      <SemanticTable.Row>{this.props.fieldList.map(this.renderHeaderCell)}</SemanticTable.Row>
    </SemanticTable.Header>
  );

  formatTime = timeStr => {
    return moment(timeStr)
      .tz(this.props.timeZone)
      .format('HH:mm');
  };

  /**
   * Pure Helper method to render an table row
   *
   * Requirement: fieldList @type {TABLE_FIELDS}
   */
  renderTableRow = item => {
    const { fieldList, accordion, keyList, accordionRenderer } = this.props;
    const childList = item[keyList];
    const isExpanded = this.activeItem === item.Id && this.expanded;
    const iconName = isExpanded ? 'angle up' : 'angle down';

    if (accordion) {
      return (
        <React.Fragment key={item.Id}>
          <SemanticTable.Row onClick={this.onExpand(item.Id)}>
            <SemanticTable.Cell colSpan={fieldList.length} className="Table__AccordionTile">
              {accordionRenderer(item)}
              <Icon name={iconName} className="Table__AccordionIcon" />
            </SemanticTable.Cell>
          </SemanticTable.Row>
          {isExpanded &&
            childList.map(child => (
              <React.Fragment key={child.uniqueId}>
                <SemanticTable.Row>
                  {fieldList.map(field => {
                    if (field.renderRer) {
                      return <SemanticTable.Cell key={field.id}>{field.renderRer(child)}</SemanticTable.Cell>;
                    } else {
                      return <SemanticTable.Cell key={field.id}>{child[field.valueField]}</SemanticTable.Cell>;
                    }
                  })}
                </SemanticTable.Row>
                {!!child.Sessions.length && (
                  <SemanticTable.Row>
                    <SemanticTable.Cell colSpan={this.props.fieldList.length} className="td-Sessions">
                      {(api.isCaseManager() ? child.Sessions.filter(x => x.AvailableSession > 0) : child.Sessions).map(
                        s => (
                          <div key={s.Date} className="Session-container">
                            <div className="Session-label">
                              {s.DateText}
                              {!api.isCaseManager() && `(${s.AvailableSession}/${s.TotalSession})`}
                            </div>
                            <div className="Appointments">
                              {s.Appointments.map((a, idx) => (
                                <div
                                  key={a.Id}
                                  onClick={this.handleAppointmentClick(a)}
                                  className={cx('Appointments-detail', {
                                    booked: a.Booked,
                                    reserved: a.IsReserved && !a.Booked,
                                    available: !a.Booked && a.Confirmed && !a.IsReserved,
                                    onDemand: !a.Booked && !a.Confirmed && !a.IsReserved,
                                    inperson: a.SessionAcceptsType === 0 && !a.IsReserved,
                                    video: a.SessionAcceptsType === 1 && !a.IsReserved,
                                    both: a.SessionAcceptsType === 2 && !a.IsReserved,
                                    'new-line': idx + 1 > 10,
                                    'ml-0': idx % 10 === 0,
                                  })}
                                  onMouseEnter={this.onMouseEnter(child)}
                                  onMouseLeave={this.props.onMouseLeave}
                                >
                                  <div className="Appointments-detail-content">
                                    <span>{this.formatTime(a.FromDate)}</span>
                                    {a.SessionAcceptsType === 1 ? (
                                      <Icon name="video camera" />
                                    ) : a.SessionAcceptsType === 2 ? (
                                      <>
                                        <Icon name="video camera" /> <Icon name="doctor" />
                                      </>
                                    ) : (
                                      <Icon name="doctor" />
                                    )}
                                    <span>{this.formatTime(a.ToDate)}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ),
                      )}
                    </SemanticTable.Cell>
                  </SemanticTable.Row>
                )}
              </React.Fragment>
            ))}
            {this.open && (
              <ModalConfirm open={this.open} modalParams={this.modalParams} onCancel={action(() => (this.open = false))} />
            )}
        </React.Fragment>
      );
    }

    return (
      <SemanticTable.Row key={item.Id}>
        {fieldList.map(field => {
          if (field.renderRer) {
            return <SemanticTable.Cell key={field.id}>{field.renderRer(item)}</SemanticTable.Cell>;
          } else {
            return <SemanticTable.Cell key={field.id}>{item[field.valueField]}</SemanticTable.Cell>;
          }
        })}
      </SemanticTable.Row>
    );
  };

  OpenBookSession = id => {
    localStorage.setItem('AssessmentCaseId', 'Book Assessment');
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?sId=${id}`);
    });
  };

  OpenSessionDetailIntervalId = 0;
  OpenSessionDetail = id => {
    dashboardStore.open('/view/calendar');
    clearInterval(this.OpenSessionDetailIntervalId);
    this.OpenSessionDetailIntervalId = setInterval(async () => {
      const f = iframe.f?.contentWindow?.openSessionDialog;
      const f2 = iframe.f?.contentWindow?.mapCalendarItem;
      if (typeof f !== 'function' || typeof f2 !== 'function') {
        return;
      }
      clearInterval(this.OpenSessionDetailIntervalId);
      this.OpenSessionDetailIntervalId = 0;
      const a = new AjaxResult('/Calendar/Calendar_LoadList');
      await a.post({ SessionId: id });
      const cal = a.data.itemList?.[0];
      if (!cal) {
        return;
      }
      f(f2(cal));
    }, 500);
  };
  OpenAssessmentDetail = (id, sId, caseNo) => {
    if (id > 0) {
      localStorage.setItem('AssessmentCaseId', caseNo);
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}`);
      });
    } else {
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?sId=${sId}`);
      });
      localStorage.setItem('AssessmentCaseId', 'Book Assessment');
    }
  };

  getExclusionReportTypes = (SelectedAcceptedReportTypeIds) => {
    const { reportTypes } = this.props;
    let exclusionReportTypesIds = [];
    if(SelectedAcceptedReportTypeIds){
      if(SelectedAcceptedReportTypeIds.includes('[')){
        exclusionReportTypesIds = JSON.parse(SelectedAcceptedReportTypeIds);
      } else {
        exclusionReportTypesIds = JSON.parse('[' + SelectedAcceptedReportTypeIds + ']');
      }
    }
    let exclusionTypesText = ''
    if(!!exclusionReportTypesIds && !!exclusionReportTypesIds.length){
      exclusionReportTypesIds.forEach(el => {
        let findObj = reportTypes.find(i => i.Id === el);
        if(!!findObj){
          exclusionTypesText += findObj.Name + ', ';
        }
      });
    }
    if(exclusionTypesText){
      exclusionTypesText = exclusionTypesText.substring(0, exclusionTypesText.length -2);
    }
    return exclusionTypesText;
  }

  handleAppointmentClick = ({ Id, AssessmentId, Booked, AssessmentNumber, SelectedAcceptedReportTypeIds }) => () => {
    let exclusionTypesText = this.getExclusionReportTypes(SelectedAcceptedReportTypeIds);
    if(!Booked && exclusionTypesText && (api.isCaseManager() || api.isAdminOrMagStaffOrAccounting())){
      this.toggleModal(true, {
        modalType: 'confirm',
        message: 'This appointment does not accept the following report types: ' + exclusionTypesText + '. Do you want to continue?',
        onOk: () => {
          console.log("OK");
          if (appStore.iframeMode) {
            this.handleAppointmentClickOnIframe({ Id, AssessmentId, Booked });
            return;
          }
      
          if (api.isCaseManager() && !Booked) {
            this.OpenBookSession(Id);
          }
          if (api.isAdminOrMagStaffOrAccounting()) {
            this.OpenAssessmentDetail(AssessmentId, Id, AssessmentNumber);
          }
        }
      });
    }else{
      if (appStore.iframeMode) {
        this.handleAppointmentClickOnIframe({ Id, AssessmentId, Booked });
        return;
      }
  
      if (api.isCaseManager() && !Booked) {
        this.OpenBookSession(Id);
      }
      if (api.isAdminOrMagStaffOrAccounting()) {
        this.OpenAssessmentDetail(AssessmentId, Id, AssessmentNumber);
        // if (Booked) {
        //   this.OpenAssessmentDetail(AssessmentId);
        // } else {
        //   this.OpenSessionDetail(Id);
        // }
      }
    }
  };

  handleAppointmentClickOnIframe = ({ Id, AssessmentId, Booked }) => {
    if (AssessmentId > 0) {
      const caseNo = `Case A-${(AssessmentId + '').padStart(6, 0)}`;
      localStorage.setItem('AssessmentCaseId', caseNo);
      window.parent.postMessage({ action: 'OPEN_IME_DETAIL', data: { id: AssessmentId } }, '*');
    } else {
      localStorage.setItem('AssessmentCaseId', 'Book Assessment');
      window.parent.postMessage({ action: 'OPEN_CREATE_IME', data: { id: Id } }, '*');
    }
  };

  renderMainTableBody = () => {
    const { list, accordion, keyList } = this.props;
    const sortFunc = (a, b) => {
      const compareString = (str1, str2) => {
        if (!(str1 && str2)) return 1;
        const str1Lower = str1.toLowerCase();
        const str2Lower = str2.toLowerCase();
        return str1Lower < str2Lower ? -1 : str1Lower > str2Lower ? 1 : 0;
      };

      const sortColumn = this.sortColumn;
      const sortDirection = this.sortDirection;
      const firstValue = sortDirection === SORT_ASC ? a[sortColumn] : b[sortColumn];
      const lastValue = sortDirection === SORT_ASC ? b[sortColumn] : a[sortColumn];
      const dataType = typeof firstValue;
      if (typeof firstValue !== typeof lastValue) {
        return -1;
      }
      if (dataType === 'number') {
        return firstValue - lastValue;
      }
      if (dataType === 'string') {
        return compareString(firstValue, lastValue);
      }
      if (dataType === 'boolean') {
        return firstValue === lastValue ? 0 : firstValue ? -1 : 1;
      }
      return 0;
    };

    const doSorting = l => (this.sortColumn ? l.sort(sortFunc) : l);
    const startIndex = (this.currentPage - 1) * this.numberPerPage;
    const stopIndex = this.currentPage * this.numberPerPage;
    let finalList = list.slice(startIndex, stopIndex);

    if (accordion) {
      finalList = finalList.map(item => {
        const childList = item[keyList];
        const isActive = item.Id === this.activeItem;
        return {
          ...item,
          [keyList]: isActive ? doSorting([...childList]) : childList,
        };
      });
    } else {
      finalList = doSorting(finalList);
    }

    return <SemanticTable.Body>{finalList.map(this.renderTableRow)}</SemanticTable.Body>;
  };

  renderNoResult = () => {
    const { fieldList, list, hasList } = this.props;
    if (list && !!list.length) return null;

    return (
      <SemanticTable.Body>
        <SemanticTable.Row>
          <SemanticTable.Cell colSpan={fieldList.length} textAlign="center">
            <div>{hasList ? 'No result found, try a different search' : 'Click Search button to perform a search'}</div>
          </SemanticTable.Cell>
        </SemanticTable.Row>
      </SemanticTable.Body>
    );
  };

  /**
   * Pagination for data table
   * Only show if totalPage > 1
   */
  renderPagination = () => {
    const { list = [] } = this.props;
    const totalPage = Math.ceil(list.length / this.numberPerPage);
    if (!(totalPage > 1)) return null;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Pagination currentPage={this.currentPage} onPageChange={this.onPageChange} totalPage={totalPage} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    const containerStyles = {
      overflowX: 'auto',
      marginTop: '1rem',
      marginBottom: '1rem',
    };

    return (
      <React.Fragment>
        <Container fluid style={containerStyles}>
          <SemanticTable className="no-stick-header smartSearch-table" sortable celled striped selectable>
            {this.renderTableHeader()}
            {this.renderMainTableBody()}
            {this.renderNoResult()}
          </SemanticTable>
        </Container>
        {this.renderPagination()}
      </React.Fragment>
    );
  }
}

export default Table;
