import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification, Form } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { formatDate } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';

import ConversationModalForm from './NewConversationModalForm';
import AddEditStore from './Store';
import { BODY_CONVERSATIONLOG } from './type';
import * as api from '../../../stores/api';
import { renderDate } from '../Shared';
import { Input } from '@components/shared/tableToolbar/ToolbarComponents';

const columns = [
  {},
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

const columns1 = [
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Receiver Type' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

@observer
class CaseCorrespondence extends React.Component {
  state = {
    visible: false,
  };

  toggleModal = visible => () => {
    this.setState({ visible });
  };

  handleDeleteConversation = async id => {
    try {
      const response = await customFetch('/MedicalService/DeleteConversationLog', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      if (response.status === 'success') {
        await AddEditStore.handleRefetchConversation();
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been deleted successfully.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  };

  handleAllowCMsView = async id => {
    try {
      const response = await customFetch('/MedicalService/UpdateDoctorAndCMsView', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, type: 0 }),
      });
      if (response.status === 'success') {
        await AddEditStore.handleRefetchConversation();
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been updated successfully.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  };

  handleAllowSpecialistsView = async id => {
    try {
      const response = await customFetch('/MedicalService/UpdateDoctorAndCMsView', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, type: 1 }),
      });
      if (response.status === 'success') {
        await AddEditStore.handleRefetchConversation();
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been updated successfully.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  };

  handleOpenConfirm = opt => {
    return AddEditStore.toggleModal(true, opt)();
  };

  renderDropdownMenu = record => (
    <Menu>
      <Menu.Item
        onClick={() =>
          this.handleOpenConfirm({
            modalType: 'confirm',
            message: `Are you sure to ${!record.AllowCMToView ? 'allow' : 'disallow'} CMs to view this entry?`,
            onOk: () => this.handleAllowCMsView(record.Id),
          })
        }
      >
        {record.AllowCMToView ? 'Disallow CMs to view' : 'Allow CMs to view'}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          this.handleOpenConfirm({
            modalType: 'confirm',
            message: `Are you sure to ${
              !record.AllowDoctorToView ? 'allow' : 'disallow'
            } Specialists/Clinics to view this entry?`,
            onOk: () => this.handleAllowSpecialistsView(record.Id),
          })
        }
      >
        {record.AllowDoctorToView ? 'Disallow Specialists to view' : 'Allow Specialists to view'}
      </Menu.Item>
      {!record.IsAutoEmail && <Menu.Item onClick={this.toggleModalEdit(true, record)}>Edit</Menu.Item>}
      <Menu.Item
        onClick={() =>
          this.handleOpenConfirm({
            modalType: 'confirm',
            message: 'Are you sure to delete this entry?',
            onOk: () => this.handleDeleteConversation(record.Id),
          })
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  toggleModalEdit = (visible, record) => () => {
    const assessmentInfo = toJS(AddEditStore.assessmentInfo);
    if (record) {
      const conversationParam = {
        AssessmentId: AddEditStore.assessmentInfo.Id,
        CallerSender: record.CallerSender,
        CallerSenderType: record.CallerSenderType,
        Content: record.Content,
        ConversationType: record.TypeLog,
        Id: record.Id,
        Receiver: record.Receiver,
        ReceiverType: record.ReceiverType,
        SentDate: renderDate(record.SentDate, true, true),
        Subject: record.Subject,
        AllowCMToView: record.AllowCMToView,
        AllowDoctorToView: record.AllowDoctorToView,
        hour: record.hour || 0,
        minute: record.minute || 0,
        ContentCategory: record.ContentCategory || 0,
        callerSenderId: record.CallerSenderId || null,
        receiverId: record.ReceiverId || null,
        IsAutoEmail: record.IsAutoEmail || false,
        AllowStaffToView: record.AllowStaffToView,
        IsSentNotice: record.IsSentNotice,
        CreatedByRole: record.CreatedByRole,
      };
      AddEditStore.setFieldsValue({ conversationParam });
    } else {
      AddEditStore.setFieldsValue({
        conversationParam: {
          ...BODY_CONVERSATIONLOG,
          Receiver:
            api.isCaseManager() || api.isAdminOrMagStaffOrAccounting()
              ? assessmentInfo.Doctor?.BusinessEmail || ''
              : assessmentInfo.CaseManager?.Email || '',
          CallerSender:
            api.isCaseManager() || api.isAdminOrMagStaffOrAccounting()
              ? assessmentInfo.CaseManager?.Email || ''
              : assessmentInfo.Doctor?.BusinessEmail || '',
          ReceiverType: api.isCaseManager() ? 'Provider' : 'Case Manager',
          CallerSenderType: api.isCaseManager() || api.isAdminOrMagStaffOrAccounting() ? 'Case Manager' : 'Provider',
          SentDate: moment(),
          Subject: `Case No: ${assessmentInfo.AssessmentNumber} - Claimant: ${assessmentInfo.Patient?.FullName}`,
          ContentCategory: 0,
          IsAutoEmail: false,
        },
      });
    }
    return this.setState({ visible });
  };

  tripHtmlFromBody = htmlString => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || '';
  };

  renderDateTime = (datestr, showTime) => {
    const { conversationList } = AddEditStore;
    const utcServerTime = conversationList.utcServerTime;
    const utcLocalMinutes = new Date().getTimezoneOffset();
    const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr, true);
        return showTime
          ? moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY - HH:mm')
          : moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY');
      }
      return datestr;
    }
    return datestr;
  };

  renderTableBody = () => {
    const data = toJS(AddEditStore.conversationList.itemList);
    return data.map((i, idx) => (
      <Table.Row key={idx} negative={!i.AllowCMToView && !i.AllowDoctorToView}>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Table.Cell>
            <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
              <Icon name="ellipsis vertical" />
            </Dropdown>
          </Table.Cell>
        )}
        <Table.Cell>{this.renderDateTime(i.SentDate, true)}</Table.Cell>
        <Table.Cell>
          <div className="cell-correspondence-content-body">{i.Subject}</div>
          {i.Subject && (
            <div className="Cell-link">
              <Link to="#" onClick={this.toggleModalEdit(true, i)}>
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          <div className="cell-correspondence-content-body">{this.tripHtmlFromBody(i.Content)}</div>
          {i.Content && (
            <div className="Cell-link">
              <Link to="#" onClick={this.toggleModalEdit(true, i)}>
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell className="cell-correspondence">{i.CallerSenderType}</Table.Cell>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Table.Cell className="cell-correspondence">{i.CallerSender}</Table.Cell>
        )}
        <Table.Cell className="cell-correspondence">{i.ReceiverType}</Table.Cell>
        {api.isAdminOrMagStaffOrAccounting() && <Table.Cell className="cell-correspondence">{i.Receiver}</Table.Cell>}
        <Table.Cell className="cell-correspondence">{i.UserName}</Table.Cell>
        <Table.Cell className="cell-correspondence">{i.TypeLog}</Table.Cell>
      </Table.Row>
    ));
  };

  handleExport = () => {
    const itemModel = toJS(AddEditStore.assessmentInfo);
    window.open('/MedicalService/ConversationLog_Export?Id=' + itemModel.Id, '_blank');
  };

  render() {
    const data = toJS(AddEditStore.conversationList.itemList);
    return (
      <div className="fluid-field">
        <div className="fluid-field" style={{ marginBottom: '1em', display: '-webkit-inline-box' }}>
        {!api.isOnlySearch() && <Button color="blue" onClick={this.toggleModalEdit(true)}>
            Add New
          </Button>}
          <Button color="blue" onClick={this.handleExport}>
            Export Case Correspondence
          </Button>
          <Form
            onKeyPress={event => {
              if (event.key === 'Enter') {
                AddEditStore.handleRefetchConversation(event.target.value);
              }
            }}
          >
            <Input
              fluid
              loading={AddEditStore.loadingConversation}
              icon="search"
              placeholder="Search any keyword"
              iconPosition="left"
              size={'small'}
            />
          </Form>
        </div>
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={api.isAdminOrMagStaffOrAccounting() ? columns : columns1} />
            <Table.Body>
              {data && data.length && data.length !== 0 ? (
                this.renderTableBody()
              ) : (
                <TableEmptyMessage colSpan={(api.isAdminOrMagStaffOrAccounting() ? columns : columns1).length} />
              )}
            </Table.Body>
            <TableFooterPagination
              colSpan={(api.isAdminOrMagStaffOrAccounting() ? columns : columns1).length}
              totalItems={data?.length}
              currentPage={1}
              totalPage={1}
              onPageClick={() => {}}
            />
          </Table>
        </div>
        {this.state.visible && (
          <ConversationModalForm visible={this.state.visible} onCancel={this.toggleModal(false)} />
        )}
      </div>
    );
  }
}

export default CaseCorrespondence;
