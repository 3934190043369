import cx from 'classnames';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Form } from 'semantic-ui-react';
import ButtonSubmit from '../shared/form/ButtonSubmit';
import registerStore from './RegisterStore';
import FormGroupTitle from '../shared/FormGroupTitle';

@observer
class RegisterDoctor extends React.Component {
  @action componentWillMount() {
    registerStore.Clear();
    registerStore.renderOptions();
  }

  renderForm = () => (
    <Form size={'small'}>
      <FormGroupTitle>User Info</FormGroupTitle>
      <section>
        {/* Title -------------------------------------------------------------------------------------------------------- */}
        <Form.Dropdown
          error={registerStore.contentErrorTitle ? { content: registerStore.contentErrorTitle } : null}
          fluid
          label="Title"
          placeholder="Title"
          search
          selection
          name="title"
          value={registerStore.title}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlTitle)}
        />
        <div className="d-flex multi-fields block-display">
          <div className="multi-item-left">
            {/* FirstName-------------------------------------------------------------------------------------------------------- */}
            <Form.Input
              fluid
              error={registerStore.contentErrorFirstName ? { content: registerStore.contentErrorFirstName } : null}
              label="First Name"
              placeholder="First Name"
              name="firstName"
              value={registerStore.firstName}
              onChange={registerStore.handleChange}
            />
          </div>
          <div className="multi-item-right">
            {/* LastName -------------------------------------------------------------------------------------------------------- */}
            <Form.Input
              fluid
              error={registerStore.contentErrorLastName ? { content: registerStore.contentErrorLastName } : null}
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              value={registerStore.lastName}
              onChange={registerStore.handleChange}
            />
          </div>
        </div>
        {/* Email-------------------------------------------------------------------------------------------------------- */}
        <Form.Input
          error={registerStore.contentErrorEmail ? { content: registerStore.contentErrorEmail } : null}
          fluid
          label="Business Email"
          placeholder="Email"
          name="email"
          value={registerStore.email}
          onChange={registerStore.handleChange}
        />
        <div className="d-flex multi-fields block-display-pading">
          {/* Phone-------------------------------------------------------------------------------------------------------- */}
          <div className="multi-item-left">
            <Form.Input
              fluid
              type="number"
              label="Phone"
              placeholder="phone"
              name="phone"
              value={registerStore.phone}
              onChange={registerStore.handleChange}
            />
          </div>
          <div className="multi-item-right">
            {/* Mobile-------------------------------------------------------------------------------------------------------- */}
            <Form.Input
              fluid
              type="number"
              label="Mobile"
              placeholder="Mobile"
              name="mobile"
              value={registerStore.mobile}
              onChange={registerStore.handleChange}
            />
          </div>
        </div>
      </section>
      <br />
      <FormGroupTitle>Office Address</FormGroupTitle>
      {/* <Form.Field>
        <Header size="small">Office Address</Header>
        <Divider />
      </Form.Field> */}
      <section>
        {/* Address-------------------------------------------------------------------------------------------------------- */}
        <Form.Input
          fluid
          label="Street Address"
          placeholder="Street Address"
          name="address"
          value={registerStore.address}
          onChange={registerStore.handleChange}
        />
        <div className="d-flex multi-fields block-display-pading">
          <div className="multi-item-left">
            {/* Suburb-------------------------------------------------------------------------------------------------------- */}
            <Form.Input
              fluid
              label="Suburb"
              placeholder="Suburb"
              name="suburb"
              value={registerStore.suburb}
              onChange={registerStore.handleChange}
            />
          </div>
          <div className="multi-item-right">
            {/* Postcode-------------------------------------------------------------------------------------------------------- */}
            <Form.Input
              fluid
              label="Postcode"
              placeholder="Postcode"
              name="postCode"
              type="number"
              value={registerStore.postCode}
              onChange={registerStore.handleChange}
            />
          </div>
        </div>
        {/* State-------------------------------------------------------------------------------------------------------- */}
        <Form.Dropdown
          error={registerStore.contentErrorState ? { content: registerStore.contentErrorState } : null}
          fluid
          label="State"
          placeholder="State"
          search
          selection
          name="state"
          value={registerStore.state}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlState)}
        />
        {/* -------------------------------------------------------------------------------------------------------- */}
      </section>
      <br />
      <FormGroupTitle>Professional Information & Service Exclusions</FormGroupTitle>
      {/* <Form.Field>
        <Divider />
        <Header size="small">Professional Information & Service Exclusions</Header>
      </Form.Field> */}
      <section>
        <div className="block-display-pading">
          <label className=" label-title-from">Preferences of work</label>
          <div className="d-flex multi-fields block-display">
            <div className="multi-item-left">
              {/* For Applicants-------------------------------------------------------------------------------------------------------- */}
              <Form.Radio
                label="For Applicants (P)"
                name="preferenceWork"
                value="P"
                onChange={registerStore.onChangeElement}
                checked={registerStore.preferenceWork === 'P'}
              />
            </div>
            <div className="multi-item-right">
              {/* For Insurers (D)-------------------------------------------------------------------------------------------------------- */}
              <Form.Radio
                label="For Insurers (D)"
                name="preferenceWork"
                value="D"
                onChange={registerStore.onChangeElement}
                checked={registerStore.preferenceWork === 'D'}
              />
            </div>
          </div>
          <div className="d-flex multi-fields block-display">
            {/* Phone-------------------------------------------------------------------------------------------------------- */}
            <div className="multi-item-left">
              {/* For Both (P & D)-------------------------------------------------------------------------------------------------------- */}
              <Form.Radio
                label="For Both (P&D)"
                name="preferenceWork"
                value="P&D"
                onChange={registerStore.onChangeElement}
                checked={registerStore.preferenceWork === 'P&D'}
              />
            </div>
            <div className="multi-item-right">
              {/* Unknown-------------------------------------------------------------------------------------------------------- */}
              <Form.Radio
                label="Unknown"
                name="preferenceWork"
                value=""
                onChange={registerStore.onChangeElement}
                checked={registerStore.preferenceWork === ''}
              />
            </div>
          </div>
        </div>
        <Form.Dropdown
          fluid
          label="Specialty"
          placeholder="Specialty"
          multiple
          search
          selection
          name="specialty"
          value={registerStore.specialty.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlSpecialty)}
        />
        <Form.Dropdown
          fluid
          label="Sub-Specialty"
          placeholder="Sub-Specialty"
          multiple
          search
          selection
          name="subSpecialty"
          value={registerStore.subSpecialty.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlSubSpecialty)}
        />
        <Form.Dropdown
          fluid
          label="Qualifications"
          placeholder="Qualifications"
          multiple
          search
          selection
          name="qualifications"
          value={registerStore.qualifications.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlQualifications)}
        />
        <Form.Dropdown
          fluid
          label="Accreditations"
          placeholder="Accreditations"
          multiple
          search
          selection
          name="accreditations"
          value={registerStore.accreditations.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlAccreditations)}
        />
        <Form.Dropdown
          fluid
          label="Accepted Report Types"
          placeholder="Accepted Report Types"
          multiple
          search
          selection
          name="acceptedReportTypes"
          value={registerStore.acceptedReportTypes.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlAcceptedReportTypes)}
        />
        <Form.TextArea
          label="Brief Profile"
          placeholder="Brief Profile"
          name="briefProfile"
          maxLength="750"
          value={registerStore.briefProfile}
          onChange={registerStore.handleChange}
        />
        <Form.Dropdown
          fluid
          label="Consulting Locations (Suburbs or Towns)"
          placeholder="Enter at least 3 letters to search for location"
          multiple
          search
          selection
          name="consultingLocations"
          value={registerStore.consultingLocations.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlConsultingLocations)}
          onSearchChange={registerStore.onSearchConsulting}
        />
        {/* State-------------------------------------------------------------------------------------------------------- */}
        <Form.Dropdown
          fluid
          label="Consulting Locations (States)"
          placeholder="Please select consulting locations (States)"
          search
          selection
          multiple
          name="consultingState"
          value={registerStore.consultingState.map(x => x)}
          onChange={registerStore.onChangeElement}
          options={toJS(registerStore.ddlConsultingState)}
        />
        <Form.TextArea
          label="Booking and Cancellation Terms"
          placeholder="List service conditions for booking and cancellation"
          name="bookingsAndCancellationTerms"
          maxLength="300"
          value={registerStore.bookingsAndCancellationTerms}
          onChange={registerStore.handleChange}
        />
        <Form.Input
          fluid
          error={registerStore.contentErrorLinkedIn ? { content: registerStore.contentErrorLinkedIn } : null}
          label="LinkedIn"
          placeholder="LinkedIn"
          name="linkedIn"
          value={registerStore.linkedIn}
          onChange={registerStore.handleChange}
          type="link"
        />
        <Form.Input
          fluid
          error={registerStore.contentErrorVideoLink ? { content: registerStore.contentErrorVideoLink } : null}
          type="link"
          label="Video Profile"
          placeholder="Video Profile"
          name="videoLink"
          value={registerStore.videoLink}
          onChange={registerStore.handleChange}
        />
      </section>
      <Divider />
      {/* ========================================================================================================= */}
      <div className={cx('d-flex', 'flex-between')} style={{ marginTop: '1em' }}>
        <React.Fragment>
          <Button type="button" color="red" content="Clear" onClick={registerStore.Clear} />
          <ButtonSubmit
            icon="angle double right"
            loading={registerStore.loading}
            content="Register"
            onClick={registerStore.RegisterDoctor}
          />
        </React.Fragment>
      </div>
    </Form>
  );

  renderSuccessMessage = () => (
    <React.Fragment>
      <p>You have registered an account successfully. We will contact you shortly to confirm and open your account. </p>
    </React.Fragment>
  );

  render() {
    return (
      <div className="form-register">
        {registerStore.registerSuccessfully ? this.renderSuccessMessage() : this.renderForm()}
        <Divider />
        <div>
          <Link to="/view/login" className="link">
            Back to login
          </Link>
        </div>
      </div>
    );
  }
}

export default RegisterDoctor;
