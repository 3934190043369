import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class ServiceFeeStore {
  @observable loading = true;
  @observable loadingServiceGroup = true;
  @observable data = null;
  @observable dataServiceGroup = null;
  @observable dataServiceGroupId = '';
  @observable serviceItem = null;

  @observable keyword = '';
  @observable filter = 1;
  @observable filterStatus = 'All';
  @observable fromTo = '';
  @observable statusId = 0;
  @observable serviceGroupId = 'All';

  @observable sortKey = 'Name';
  @observable sortColumn = 'Name';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = true;
    customFetch('/MAGService/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
        ServiceStatusId: this.filter,
        ServiceGroupId: this.serviceGroupId,
      }),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };
  @action fetchService = () => {
    this.loadingServiceGroup = true;

    customFetch('/MAGService/GetServiceGroup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataServiceGroup = data;
        this.loadingServiceGroup = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  serviceItemCreate = (service = {}) => {
    return action(() => {
      this.serviceItem = service;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchFiles(setTotalPage);
  };

  @action refetchGroup = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchService();
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'Name';
    this.sortKey = 'Name';
    this.sortDirection = 'ascending';

    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new ServiceFeeStore();
