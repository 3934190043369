import { action, observable, computed } from 'mobx';
import moment from 'moment';
import {
  getFundingRequest,
  getFundedCompanies,
  getFundingProvider,
  getSigningPerson,
  getFundingAgreementInfo,
} from '../../admin-routes/FundingAgreement/Service';

const INIT_FUNDING_AGREE = {
  Id: 0,
  RequestId: null,
  AgreementNo: null,
  FundingRqNo: null,
  FundingProvider: null,
  FundingProviderObj: null,
  SigningDate: null,
  SignPersonProvider: null,
  SignPersonProviderObj: null,
  SignPersonLawyer: null,
  FundedCompanyId: null,
  FundedCompanyObj: null,
  FundingPeriodFrom: null,
  FundingPeriodTo: null,
  GrossMargin: null,
  Discount: null,
  FundingAmount: null,
  FundingAmountRemaining: null,
  OverfundingLimitApplicable: false,
  FacilityExpiryDate: null,
  Attachments: [],
};

class Store {
  @observable loading = false;
  @observable open = false;
  @observable fundingAgreementInfo = INIT_FUNDING_AGREE;
  @observable modalParams = {};
  @observable lstFileAttachments = [];
  @observable lstFundingRequest = [];
  @observable lstFundedCompanies = [];
  @observable lstLenders = [];
  @observable lstFundingProvider = [];
  @observable lstLenderSigningPersons = [];
  @observable lstBorrowerSigningPersons = [];

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };
  @action setLstData = (name, lst) => {
    this[name] = lst;
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action fetchData = id => {
    this.loading = true;
    Promise.all([
      getFundingAgreementInfo(id),
      getFundingRequest(''),
      getFundedCompanies(''),
      getFundingProvider(''),
    ]).then(
      action(([infoResp, fundingRqResp, fundedCompaniesResp, fundingProviderResp]) => {
        if (fundingRqResp.status === 'success') {
          this.setLstData('lstFundingRequest', fundingRqResp.itemList);
        }
        if (fundedCompaniesResp.status === 'success') {
          this.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
        }
        if (fundingProviderResp.status === 'success') {
          this.setLstData('lstLenders', fundingProviderResp.itemList);
        }
        this.fundingAgreementInfo = {
          ...infoResp.objItem,
          SigningDate: infoResp.objItem.SigningDate ? this.momentToJS(infoResp.objItem.SigningDate) : null,
          FundingPeriodFrom: infoResp.objItem.FundingPeriodFrom
            ? this.momentToJS(infoResp.objItem.FundingPeriodFrom)
            : null,
          FundingPeriodTo: infoResp.objItem.FundingPeriodTo ? this.momentToJS(infoResp.objItem.FundingPeriodTo) : null,
          FacilityExpiryDate: infoResp.objItem.FacilityExpiryDate
            ? this.momentToJS(infoResp.objItem.FacilityExpiryDate)
            : null,
        };
        if (
          this.fundingAgreementInfo &&
          this.fundingAgreementInfo.Attachments &&
          this.fundingAgreementInfo.Attachments.length > 0
        ) {
          const newAttachments = [...this.fundingAgreementInfo.Attachments].map(i => ({
            ...i,
            key: i.FileName,
          }));
          this.setLstData('lstFileAttachments', newAttachments);
        } else {
          this.setLstData('lstFileAttachments', []);
        }
        console.log(this.fundingAgreementInfo);
        this.loading = false;
      }),
    );
  };

  @action initData = () => {
    this.fundingAgreementInfo = { ...INIT_FUNDING_AGREE };
    this.setLstData('lstFileAttachments', []);
    Promise.all([getFundingRequest(''), getFundedCompanies(''), getFundingProvider('')]).then(
      action(([fundingRqResp, fundedCompaniesResp, fundingProviderResp]) => {
        if (fundingRqResp.status === 'success') {
          this.setLstData('lstFundingRequest', fundingRqResp.itemList);
        }
        if (fundedCompaniesResp.status === 'success') {
          this.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
          this.setLstData('lstFundingProvider', fundedCompaniesResp.itemList);
        }
        if (fundingProviderResp.status === 'success') {
          this.setLstData('lstLenders', fundingProviderResp.itemList);
        }
      }),
    );
  };

  @action onReset = () => {
    this.fundingAgreementInfo = INIT_FUNDING_AGREE;
  };

  @action handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'RequestId') {
        var fundingRq = this.lstFundingRequest.find(i => i.Id === value);
        if (fundingRq) {
          this.fundingAgreementInfo.FundingProvider = fundingRq.FundingProvider;
          this.fundingAgreementInfo.FundingProviderObj = fundingRq.FundingProviderObj;
          this.fundingAgreementInfo.SignPersonProvider = fundingRq.SignPersonProvider;
          this.fundingAgreementInfo.SignPersonProviderObj = fundingRq.SignPersonProviderObj;

          this.fundingAgreementInfo.SignPersonLawyer = fundingRq.SignPersonLawyer;
          this.fundingAgreementInfo.SignPersonLawyerObj = fundingRq.SignPersonLawyerObj;

          this.fundingAgreementInfo.FundedCompanyId = fundingRq.Borrower;
          this.fundingAgreementInfo.FundedCompanyObj = fundingRq.BorrowerObj;

          getSigningPerson('', fundingRq.FundingProvider).then(singerPersonResp => {
            if (singerPersonResp.status === 'success') {
              this.setLstData('lstLenderSigningPersons', singerPersonResp.itemList);
            }
          });
          getSigningPerson('', fundingRq.Borrower).then(singerPersonResp => {
            if (singerPersonResp.status === 'success') {
              this.setLstData('lstBorrowerSigningPersons', singerPersonResp.itemList);
            }
          });
        }
      }
      if (fieldName === 'FundingProvider') {
        getSigningPerson('', value).then(singerPersonResp => {
          if (singerPersonResp.status === 'success') {
            this.setLstData('lstLenderSigningPersons', singerPersonResp.itemList);
          }
        });
      }
      if (fieldName === 'FundedCompanyId') {
        getSigningPerson('', value).then(singerPersonResp => {
          if (singerPersonResp.status === 'success') {
            this.setLstData('lstBorrowerSigningPersons', singerPersonResp.itemList);
          }
        });
      }
      this.fundingAgreementInfo[fieldName] = value;
    });

  @action handleDateFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      this.fundingAgreementInfo[fieldName] = value;
    });

  @action handleCheckboxFieldChange = fieldName =>
    action(event => {
      const value = !this.fundingAgreementInfo[fieldName];
      this.fundingAgreementInfo[fieldName] = value;
    });
}

export default new Store();
