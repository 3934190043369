import React from 'react';
import { observer } from 'mobx-react';
import { Button, TextArea } from 'semantic-ui-react';

import store from './Store';
import Modal from '../../shared/Modal';

import customFetch from '@utils/customFetch';
import { notification, Spin } from 'antd';

@observer
class ModalPrintMail extends React.Component {
  state = {
    doctorInfo: null,
    loading: true,
  };
  async componentDidMount() {
    try {
      const { specialist } = store;
      customFetch(`/api/DoctorInfo/${specialist?.Id}`, {
        method: 'GET',
      }).then(response => {
        this.setState({ doctorInfo: response, loading: false });
      });
    } catch (_) {
      return store.toggleModal(false, {})();
    }
  }
  handleChange = value => {
    const doctorInfo_ = { ...this.state.doctorInfo, preferences: value };
    this.setState({ doctorInfo: doctorInfo_ });
  };

  handleSave = () => {
    const { specialist } = store;
    customFetch(`/api/DoctorInfo/${specialist?.Id}`).then(response => {
      if (response.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    });
  };

  render() {
    const { open } = store;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={store.toggleModal(false)}
        title="Confidential Insights"
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button className="negative" onClick={store.toggleModal(false)}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        {this.state.loading && !this.state.doctorInfo ? (
          <div
            style={{
              height: '250px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <TextArea
            rows={8}
            value={this.state.doctorInfo.preferences}
            name="preferences"
            style={{ width: '100%' }}
            onChange={this.handleChange}
          />
        )}
      </Modal>
    );
  }
}

export default ModalPrintMail;
