import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import uploadStore from './UploadStore';
import {
  Button,
  Dimmer,
  Loader,
  Form,
  Icon,
  Checkbox,
  TextArea,
  Input,
  Icon as IconSemantic,
  Popup,
} from 'semantic-ui-react';
import ModalConfirm from '../../Modal/ModalConfirm';
import './../../DragDrop.scss';
import { action } from 'mobx';
import DragDropFile from './../../DragDropFile';
import { observer } from 'mobx-react';
import { DatePicker, Radio, notification } from 'antd';
import { renderDate, commonInlineStyles } from '../../../case-details-routes/Shared';
import * as api from '../../../../stores/api';

const styles = {
  emailPaperworkReceivedDate: {
    ...commonInlineStyles.inlineBlock,
    ...commonInlineStyles.highlightColor,
    ...commonInlineStyles.width300,
  },
};

function UploadPaperwork(props) {
  const {
    open,
    modalParams,
    submitDocumentType,
    attachmentType_Paperwork,
    toggleModal,
    submitFileHandler,
  } = uploadStore;

  const { serviceType, id, label } = modalParams;
  const [uploadFile, setUploadFile] = useState([]);
  const [otherLink, setOtherLink] = useState('');
  const [otherLink2, setOtherLink2] = useState('');
  const [emailtoStaff, setemailtoStaff] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [askedsendMail, setAskedSendEmail] = useState(false);
  const [isInvoiceUrgentReport, setInvoiceUrgentReport] = useState(modalParams.isInvoiceUrgentReport || false);
  const [urgentReportType, setUrgentReportType] = useState(modalParams.urgentReportType || 0);
  const [disableReportReportCompletionDate, setdisableReportReportCompletionDate] = useState(false);
  const [urgentReportReportCompletionDate, setReportReportCompletionDate] = useState(
    renderDate(modalParams.urgentReportReportCompletionDate) || null,
  );

  const title = () => {
    var strAppointmentDate = '';
    const dateFrom = renderDate(modalParams.dateFrom);
    if (label === 'IME' && dateFrom) {
      strAppointmentDate = ' - Appointment Date: ' + dateFrom.format('DD/MM/YYYY');
    }
    return (
      label + ' - Upload Paperwork: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant + strAppointmentDate
    );
  };

  const onSubmitFile = action(() => {
    if (label === 'IME') {
      const dateFrom = renderDate(modalParams.dateFrom);
      if (urgentReportType !== 0) {
        if (!!urgentReportReportCompletionDate) {
          if (!!dateFrom && !!urgentReportReportCompletionDate) {
            var momentBusinessDays = require('moment-business-days');
            var dfCompletionDate = dateFrom.businessDiff(urgentReportReportCompletionDate);
            if (dfCompletionDate >= 2 || dfCompletionDate <= 7) {
              notification.error({
                message: 'Error',
                description: 'Urgent report completion date must be between 2-7 business days of appointment date.',
              });
              return;
            }
          }
        } else if (urgentReportType === 2) {
          notification.error({
            message: 'Error',
            description: 'Urgent report completion date must be between 2-7 business days of appointment date.',
          });
          return;
        }
      }
    }
    uploadStore.loading = true;
    if (!uploadFile || uploadFile.length <= 0) {
      return;
    }

    if (!emailtoStaff && !askedsendMail && !api.isLoginAndAdminOrMagStaffOrAccounting()) {
      uploadStore.loading = false;
      return setOpenModalConfirm(true);
    }

    var uploadAttachment = [];
    uploadFile.forEach(element => {
      uploadAttachment.push({
        [`${serviceType}Id`]: id,
        FileName: element.name,
        Title: element.title,
        ReportType: element.reportType,
        Type: element.reportType,
        NumberOfPages: element.numberOfPages,
      });
    });
    const uploadData = {
      [`${serviceType}Id`]: id,
      ReportType: attachmentType_Paperwork[serviceType],
      [`${serviceType}Attachment`]: uploadAttachment,
      Otherlink: otherLink,
      Otherlink2: otherLink2,
      SendPaperWork: emailtoStaff,
      Token: modalParams.token,
      Type: modalParams.type,
      IsInvoiceUrgentReport: isInvoiceUrgentReport,
      UrgentReportType: urgentReportType,
      UrgentReportReportCompletionDate: !!urgentReportReportCompletionDate
        ? urgentReportReportCompletionDate.format('YYYY/MM/DD')
        : null,
    };
    submitFileHandler(
      modalParams.token ? submitDocumentType['IME_Paperwork_Onetimeacess'] : submitDocumentType[serviceType],
      uploadData,
      props.refetchTable,
    );
  });

  const onUploadFile = (acceptedFiles, reportType, isMulti) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
      });
      let data = [...uploadFile, ...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      if (!isMulti) {
        data = [...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      }
      setUploadFile(data);
    }
  };

  const onDeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      const data = [...uploadFile].filter(x => x.name !== deletedFiles.name);
      setUploadFile(data);
    }
  };

  const onChangeurgentReportType = value => {
    //console.log(value);
    setUrgentReportType(value);
    if (value === 1) {
      const dateFrom = renderDate(modalParams.dateFrom);
      //console.log(dateFrom);
      var momentBusinessDays = require('moment-business-days');
      var dfCompletionDate = !!dateFrom ? momentBusinessDays(dateFrom).businessAdd(2) : null;
      setReportReportCompletionDate(dfCompletionDate);
      setdisableReportReportCompletionDate(true);
    } else {
      setdisableReportReportCompletionDate(false);
    }
  };

  const handleEmailConfirm = () => {
    setemailtoStaff(true);
    setAskedSendEmail(true);
  };

  useEffect(() => {
    askedsendMail && onSubmitFile();
    // eslint-disable-next-line
  }, [askedsendMail]);

  return (
    <React.Fragment>
      <Modal
        visible={open}
        onCancel={toggleModal(false)}
        width={600}
        className="custom-modal"
        title={title()}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              disabled={!uploadFile || uploadFile.length === 0}
              onClick={onSubmitFile}
              loading={uploadStore.loading}
            >
              <Icon name="upload" /> Upload
            </Button>}
            <Button color="red" onClick={toggleModal(false)}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
      >
        <Dimmer active={uploadStore.loading}>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Group inline>
            <Form.Field>
              <label>
                {api.isLoginAndAdminOrMagStaffOrAccounting()
                  ? 'Send Paperwork to Specialist'
                  : 'Send Paperwork Readiness to MLP Staff'}
              </label>
            </Form.Field>
            <Form.Field>
              <Checkbox checked={emailtoStaff} onChange={() => setemailtoStaff(!emailtoStaff)} />
            </Form.Field>
          </Form.Group>
          {serviceType === 'Assessment' && (
            <React.Fragment>
              <Form.Group inline>
                <Form.Field>
                  <label>Urgent Report Completion </label>
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={isInvoiceUrgentReport}
                    onChange={() => setInvoiceUrgentReport(!isInvoiceUrgentReport)}
                  />
                </Form.Field>
              </Form.Group>
              {isInvoiceUrgentReport && (
                <div>
                  <Radio.Group value={urgentReportType}>
                    <Radio value={1} onClick={() => onChangeurgentReportType(1)} style={styles.radioBlockBottom}>
                      VWA Report{' '}
                      <Popup
                        trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports between 2-7 business days of appointment date, additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                    <Radio value={2} onClick={() => onChangeurgentReportType(2)} style={styles.radioBlockBottom}>
                      Other Report
                      <Popup
                        trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports between 2-7 business days of appointment date, no additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                  </Radio.Group>
                </div>
              )}
              {isInvoiceUrgentReport && (
                <div>
                  <Form.Group inline>
                    <Form.Field>
                      <label>Requested Completion Date Before</label>
                    </Form.Field>
                    <Form.Field>
                      <DatePicker
                        disabled={disableReportReportCompletionDate || urgentReportType === 1}
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(urgentReportReportCompletionDate)}
                        onChange={value => setReportReportCompletionDate(value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              )}
            </React.Fragment>
          )}
          {serviceType !== 'Assessment' && (
            <Form.Field>
              <label>Share Download Link (for LOI)</label>
              <Input
                placeholder="Share Download Link (for LOI)"
                className="text-area"
                name="otherLink"
                value={otherLink}
                onChange={(_, { value }) => setOtherLink(value)}
              />
            </Form.Field>
          )}
        </Form>

        <div style={{ marginTop: '10px' }}>
          <DragDropFile
            multiple={false}
            disableDragDrop={true}
            onUploadFile={onUploadFile}
            onDeleteFile={onDeleteFile}
            type={attachmentType_Paperwork[serviceType]}
            title="Letter of Instruction (LOI) (1 PDF file only) "
            accept="application/pdf"
            maxSize="50"
          />
          <hr></hr>
        </div>

        <Form>
          {serviceType === 'Assessment' && (
            <Form.Field>
              <label>Share Download Links (for Other Documents)</label>
              <TextArea
                placeholder="Share Download Links (for Other Documents)"
                className="text-area"
                name="otherLink"
                value={otherLink2}
                onChange={(_, { value }) => setOtherLink2(value)}
              />
            </Form.Field>
          )}
        </Form>

        {serviceType === 'Assessment' && (
          <div style={{ marginTop: '10px' }}>
            <DragDropFile
              multiple={true}
              disableDragDrop={false}
              onUploadFile={onUploadFile}
              onDeleteFile={onDeleteFile}
              type={attachmentType_Paperwork.Assessment_Other}
              title="Other Documents"
              maxSize="50"
            />
            <hr />
          </div>
        )}

        {serviceType !== 'Assessment' && (
          <React.Fragment>
            <Form>
              <Form.Field>
                <label>Share Download Links (for Original Reports)</label>
                <TextArea
                  placeholder="Share Download Links (for Original Reports)"
                  className="text-area"
                  name="otherLink2"
                  value={otherLink2}
                  onChange={(_, { value }) => setOtherLink2(value)}
                />
              </Form.Field>
            </Form>

            <div style={{ marginTop: '10px' }}>
              <DragDropFile
                multiple={true}
                disableDragDrop={false}
                onUploadFile={onUploadFile}
                onDeleteFile={onDeleteFile}
                type={attachmentType_Paperwork[`${serviceType}_Other`]}
                title="Original Reports"
                maxSize="50"
              />
              <hr></hr>
            </div>
          </React.Fragment>
        )}
      </Modal>
      <ModalConfirm
        modalParams={{
          message: 'Are these all the documents you would like the specialist to review?',
          onOk: () => handleEmailConfirm(),
        }}
        open={openModalConfirm}
        onCancel={() => {
          setOpenModalConfirm(false);
          setAskedSendEmail(true);
        }}
        hideWarningIcon
      />
    </React.Fragment>
  );
}

export default observer(UploadPaperwork);
