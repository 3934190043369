import React from 'react';
import { isEmpty } from 'lodash';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { Button, Form } from 'semantic-ui-react';

import Store from './Store';
import { ActionNoteStatuses } from './constants';

class ActionNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props.data };
  }

  handleOnChangePic = (_, { value }) => {
    this.setState({ picId: value });
  };

  handleOnChangeDueDate = momentValue => {
    this.setState({ dueDate: momentValue });
  };

  handleOnChangeClosedDate = momentValue => {
    this.setState({ closedDate: momentValue });
  };

  handleOnChangeComment = ({ target }) => {
    this.setState({ comment: target.value });
  };

  handleOnChangeStatus = (_, { value }) => {
    this.setState({ status: value });
    if (value !== 0) {
      this.setState({ closedDate: moment() });
    }
  };

  handleOnChangeSubject = (_, { value }) => {
    this.setState({ subject: value });
  };

  render() {
    const { picOptions } = Store;
    return (
      <Form size={'small'}>
        <Form.Input required label="Subject" value={this.state.subject} onChange={this.handleOnChangeSubject} />
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Due Date (UTC+07:00)</label>
            <DatePicker
              allowClear={false}
              size="large"
              format="DD/MM/YYYY"
              value={this.state.dueDate ? moment(this.state.dueDate) : null}
              onChange={this.handleOnChangeDueDate}
            />
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <TimePicker
              size="large"
              allowClear={false}
              format="HH:mm"
              value={this.state.dueDate ? moment(moment(this.state.dueDate).format('HH:mm'), 'HH:mm') : null}
              onChange={this.handleOnChangeDueDate}
            />
          </Form.Field>
        </Form.Group>
        <Form.Dropdown
          fluid
          required
          label="PIC"
          search
          selection
          value={this.state.picId}
          onChange={this.handleOnChangePic}
          options={picOptions}
        />
        <Form.TextArea
          label="Action Notes"
          rows={4}
          value={this.state.comment || ''}
          onChange={this.handleOnChangeComment}
        />
        <Form.Dropdown
          fluid
          label="Status"
          selection
          upward
          value={this.state.status}
          onChange={this.handleOnChangeStatus}
          options={ActionNoteStatuses}
          width="5"
        />
        <Form.Field width="5" disabled={this.state.status === 0}>
          <label>Closed Date</label>
          <DatePicker
            allowClear={false}
            format="DD/MM/YYYY"
            value={this.state.closedDate ? moment(this.state.closedDate) : null}
            onChange={this.handleOnChangeClosedDate}
          />
        </Form.Field>
        {this.state.createdDate && (
          <Form.Field>
            <span>
              Created by: {this.state.createdBy} at {moment(this.state.createdDate).format('DD/MM/YYYY')}
            </span>
          </Form.Field>
        )}
        {this.state.updatedDate && (
          <Form.Field>
            <span>
              Last updated by: {this.state.updatedBy} at {moment(this.state.updatedDate).format('DD/MM/YYYY')}
            </span>
          </Form.Field>
        )}
        <Button
          disabled={isEmpty(this.state.subject) || isEmpty(this.state.dueDate) || isEmpty(this.state.picId)}
          color="blue"
          icon="save outline"
          labelPosition="left"
          content="Save"
          onClick={this.props.onSave({ ...this.state })}
        />
        <Button
          color="red"
          icon="delete"
          labelPosition="left"
          content="Delete"
          onClick={this.props.onDelete(this.state.id)}
        />
      </Form>
    );
  }
}

export default ActionNoteForm;
