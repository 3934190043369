import * as api from '@stores/api';

export const DOCTOR_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Previously used specialist',
    value: '3',
  },
  {
    label: 'Others',
    value: '4',
  },
];
export const MAG_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Internet search',
    value: '3',
  },
  {
    label: 'Colleague',
    value: '4',
  },
  {
    label: 'Word of mouth',
    value: '5',
  },
  {
    label: 'Others',
    value: '6',
  },
];

export const conversationTypes = [
  {
    label: 'Phone',
    value: 0,
    visible: true,
  },
  {
    label: 'Email',
    value: 1,
    visible: true,
  },
  {
    label: 'Email (Send Now)',
    value: 4,
    visible: false,
  },
  {
    label: 'Private Notes',
    value: 5,
    visible: true,
  },
  {
    label: 'Relationship Meeting',
    value: 2,
    visible: true,
  },
  {
    label: 'Relationship Call',
    value: 8,
    visible: true,
  },
  {
    label: 'Relationship Email',
    value: 9,
    visible: true,
  },
  {
    label: 'Others',
    value: 3,
    visible: true,
  },
];

export const INIT_CLINICAL_NOTE = {
  ActionCompleted: false,
  ActionCompletedDate: null,
  ActionNotes: null,
  ActionRequiredDueDate: null,
  ActionRequiredPICStaffName: null,
  ActionRequiredType: 0,
  ActionRequiredUserName: null,
  ActionSubject: null,
  AssignedStaffId: null,
  Attachments: null,
  AuthorisedPurchaseAmount: null,
  BillingDate: null,
  CRFilesExLink: null,
  CancellationStatus: 0,
  CancelledComments: null,
  CancelledDate: null,
  CaseManager: null,
  CaseManagerClinicName: null,
  CaseManagerDoctorName: null,
  CaseManagerId: null,
  CaseStatus: 0,
  ClaimType: null,
  ClaimantNo: null,
  ClientFileNo: null,
  Clinic: null,
  ClinicId: null,
  ClinicInvoiceNo: null,
  ClinicalNoteNumber: null,
  ClinicalNotesReceived: false,
  ClinicalNotesReceivedDate: null,
  ClinicalNotesSentDate: null,
  CreatedDate: null,
  Customer: null,
  CustomerId: null,
  Doctor: null,
  DoctorId: null,
  EmailCNDocReq1: null,
  EmailCNDocReq2: null,
  EmailInvoiceToMAG: false,
  EmailInvoiceToMAGDateSent: null,
  EmailInvoiceToMAGStaffSent: null,
  EmailLoAServiceProvider: false,
  EmailToCMsViewReportRecent: null,
  EmailToCMsViewReportUserBy: null,
  EmployerOfClaimant: null,
  ExpectedCompletionDate: null,
  F_RDCCaseManagerId: null,
  F_RDCEmail: null,
  F_RDCSameBookingCM: false,
  F_RDCfirstName: null,
  F_RDClastName: null,
  Id: 0,
  IlarsRef: null,
  IlarsWiroSolicitor: null,
  ImsApprovalCode: null,
  InjuriedDate: null,
  InjuryDescription: null,
  InquiryDate: null,
  InquiryReceived: false,
  InvoicePaid: false,
  InvoicePaidComment: null,
  InvoicePaidDate: null,
  IsActionRequired: true,
  IsAllowCMsViewReports: false,
  IsBilled: false,
  IsEmail1ForSendingCN: false,
  IsEmail2ForSendingCN: false,
  IsExportMode: false,
  IsLocked: false,
  IsNewClaimant: false,
  IsPaperworkReceived: false,
  IsPaymentRequested: false,
  IsSendLOATemplate: false,
  IsSendPaymentToProvider: false,
  IsUrgentReport: false,
  NotifyCancellationToCM: false,
  NotifyDoctorCancelled: false,
  NotifyDoctorOnHold: false,
  NotifyOnHoldToCM: false,
  OriginalMAGInfo: null,
  OriginalMAGInfo2: null,
  OriginalMAGInfoTxt: null,
  OriginalMAGInfoTxt2: null,
  PCaseManager: null,
  PCaseManagerId: null,
  PCustomer: null,
  PCustomerId: null,
  PICCancelled: null,
  PICPrepaymentPaid: null,
  PICPrepaymentRequired: null,
  PICReceivedFromProvider: null,
  PICRequestSentProvider: null,
  PICReturnedToClient: null,
  PICServiceBilled: null,
  PaperworkReceivedDate: null,
  PasswordCRFilesExLink: null,
  Patient: null,
  PaymentRequestedDate: null,
  PaymentRequestedInvoiceAmount: 0,
  PaymentRequestedInvoiceTax: 0,
  PrepaymentRequiredDate: null,
  PurchaseOrderNo: null,
  ReasonCancellation: 0,
  ReportId: null,
  ReportRequiredDate: null,
  RequestExpectedCompletionDate: null,
  RequestReceived: false,
  RequestReceivedComment: null,
  RequestReceivedDate: null,
  RequestReceivedThrough: 0,
  RequestSendToGpMethods: [],
  RequestSentToGp: false,
  RequestSentToGpDate: null,
  RequestedCRRange: 0,
  RequestedCRRangeFrom: null,
  RequestedCRRangeTo: null,
  S_RDCCaseManagerId: null,
  S_RDCEmail: null,
  S_RDCfirstName: null,
  S_RDClastName: null,
  SendLOATemplateByUser: null,
  SendLOATemplateSendDate: null,
  SendPaymentToProviderDate: null,
  SendToWIRO: false,
  SentDateLoAServiceProvider: null,
  SentLoAServiceProviderBy: null,
  SentDateNotifyBookingCMCancellation: null,
  SentDateNotifyBookingCMOnHold: null,
  SentDateNotifyDoctorCancellation: null,
  SentDateNotifyDoctorOnHold: null,
  SessionId: 0,
  StaffId: null,
  Status: null,
  StatusDate: null,
  SubCaseManager1: null,
  SubCaseManager1Id: null,
  SubCaseManager2: null,
  SubCaseManager2Id: null,
  SubCaseManager3: null,
  SubCaseManager3Id: null,
  UrgentReportCompleted: false,
  UrgentReportCompletedDate: null,
  UrgentReportDueDate: null,
  UrgentReportNotes: null,
  UrgentReportPICStaffName: null,
  UrgentReportStaffId: null,
  WiroIlars: false,
  ClinicInfo: null,
  SendPaymentToProviderSendBy: null,
  SameAsBusinessEmail: false,
  SameAsBusinessEmailSend: null,
  IROFundingApproved: false,
  IROFundingApprovalDate: null,
  IROFundingApprovedDate: null,
  IROFundingApprovedBy: null,
  UpdatedDate: null,
};

export const CONSTANTS = {
  CancellationStatus: {
    None: 0,
    CancelledNoCharge: 1,
    CancelledWithFee: 2,
    OnHold: 3,
  },
  CaseStatus: {
    CNRequestReceived: 0,
    CNRequestDocsSentToProvider: 1,
    PrepaymentRequired: 2,
    PrepaymentPaid: 3,
    CNFilesReceived: 4,
    ClientInvoiced: 11,
    DoctorInvoiced: 12,
    ServiceBilled: 13,
    ServicePaid: 14,
    CancelledWithFee: 8,
    CancelledNoCharge: 7,
    OnHold: 10,
  },
  TypeCallerReceiver: {
    CaseManager: 1,
    Provider: 2,
    Staff: 3,
  },
};

export const CR_CASE_PROGRESS = [
  {
    id: api.crStatusEnum.CN_Request_Received,
    key: api.crStatus.CN_REQUEST_RECEIVED,
    label: 'Request Received',
    group: 'Booking & Retrievals',
  },
  {
    id: api.crStatusEnum.CN_Request_Docs_Sent_To_Provider,
    key: api.crStatus.CN_REQUEST_DOCS_SENT_TO_PROVIDER,
    label: 'Request Sent to Provider',
    group: 'Booking & Retrievals',
  },
  {
    id: api.crStatusEnum.Prepayment_Required,
    key: api.crStatus.PREPAYMENT_REQUIRED,
    label: 'Prepayment Required',
    group: 'Billing',
  },
  {
    id: api.crStatusEnum.Prepayment_Paid,
    key: api.crStatus.PREPAYMENT_PAID,
    label: 'Prepayment Paid',
    group: 'Billing',
  },
  {
    id: api.crStatusEnum.CN_Files_Received,
    key: api.crStatus.CN_FILES_RECEIVED,
    label: 'CR Files Sent to CMs',
    group: 'Booking & Retrievals',
  },
  {
    id: api.crStatusEnum.Cancelled_No_Charge,
    key: api.crStatus.CANCELLED_NO_CHARGE,
    label: 'Cancelled No Fee',
    group: 'Cancellation & On-Hold',
  },
  {
    id: api.crStatusEnum.Cancelled_With_Fee,
    key: api.crStatus.CANCELLED_WITH_FEE,
    label: 'Cancelled with Fee',
    group: 'Cancellation & On-Hold',
  },
  {
    id: api.crStatusEnum.On_Hold,
    key: api.crStatus.ON_HOLD,
    label: 'On-Hold',
    group: 'Cancellation & On-Hold',
  },
  {
    id: api.crStatusEnum.Client_Invoiced,
    key: api.crStatus.CLIENT_INVOICE,
    label: 'Client Invoiced',
    group: 'Billing',
  },
  {
    id: api.crStatusEnum.Service_Billed,
    key: api.crStatus.SERVICE_BILLED,
    label: 'CR Provider Billed',
    group: 'Billing',
  },
  // {
  //   id: 11,
  //   key: 13,
  //   label: 'Service Invoiced',
  //   group: 'Billing',
  // },
  {
    id: api.crStatusEnum.Service_Paid,
    key: api.crStatus.SERVICE_PAID,
    label: 'Service Paid',
    group: 'Billing',
  },
];

export const FIELD_NAME_FOR_ADD_EDIT = [
  {
    fieldName: 'CustomerId',
    pathname: '/view/add-edit-client-2',
    computedName: 'bookingClientSelected',
    storageName: 'CompanyName',
    name: 'client',
    listName: 'bookingClients',
  },
  {
    fieldName: 'CaseManagerId',
    pathname: '/view/add-edit-case-manager-2',
    computedName: 'bookingCMSelected',
    storageName: 'CmName',
    name: 'case manager',
    listName: 'bookingCMs',
  },
  {
    fieldName: 'PCustomerId',
    pathname: '/view/add-edit-client-2',
    computedName: 'payingClientSelected',
    storageName: 'CompanyName',
    name: 'client',
    listName: 'payingClients',
  },
  {
    fieldName: 'PCaseManagerId',
    pathname: '/view/add-edit-case-manager-2',
    computedName: 'payingCMSelected',
    storageName: 'CmName',
    name: 'case manager',
    listName: 'payingCMs',
  },
  {
    fieldName: 'PatientId',
    pathname: '/view/add-edit-claimant-2',
    computedName: 'claimantSelected',
    storageName: 'ClaimantName',
    name: 'claimant',
    listName: 'claimants',
  },
  {
    fieldName: 'ClinicId',
    pathname: '/view/add-edit-clinic-2',
    computedName: 'crProviderFirstSelected',
    storageName: 'ClinicName',
    name: 'clinic',
    listName: 'crProviderFirst',
  },
  {
    fieldName: 'DoctorId',
    pathname: '/view/add-edit-doctor-2',
    computedName: 'crProviderSecondSelected',
    storageName: 'DoctorName',
    name: 'specialist',
    listName: 'crProviderSecond',
  },
];
