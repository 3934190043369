import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import DRStore from './DRStore';
import DRTable from './DRTable';
import DRToolbar from './DRToolbar';
import DRModal from './DRModal';
import './index.scss';

class DoctorRemittance extends React.Component {
  componentDidMount() {
    DRStore.fetchAllWithoutNCI(true);
    DRStore.fetch(true);
  }

  render() {
    const { loading, data, loadingDoctor, dataDoctor, open } = DRStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingDoctor && !dataDoctor) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingDoctor && !dataDoctor) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <DRToolbar />
            <DRTable />
            {open && <DRModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(DoctorRemittance);
