import React from 'react';
import { Button, Checkbox, Radio } from 'antd';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import './ProgressStatus.scss';

class ProgressStatus extends React.Component {
  state = {
    isExpanding: false,
  };

  toggleExpand = () => {
    this.setState({
      isExpanding: !this.state.isExpanding,
    });
  };

  render() {
    return (
      <div className="progress-statuses-container">
        <div className="progress-statuses">
          <div className={`progress-statuses__sections ${this.state.isExpanding ? 'expanding' : ''}`}>
            {this.props.statusGroups.map((group, index) => (
              <div key={index} style={{ width: 100 / this.props.statusGroups.length + '%' }}>
                <div
                  className={`status-group ${group.completed ? 'completed' : group.inprogress ? 'inprogress' : ''} ${
                    group.currentActive ? 'active' : ''
                  }`}
                  onClick={this.toggleExpand}
                >
                  <span className={`group-icon`}>
                    <Icon name={`check circle${group.active ? '' : ' outline'}`} />
                  </span>
                  {group.name}
                  <span className="group-summary">
                    ({group.completedTaskCount}/{group.totalTask})
                  </span>
                </div>
                <div className="tasks-section">
                  <div className="title">TASKS</div>
                  {this.props.statuses
                    .filter(i => i.group === group.name)
                    .map((statusItem, taskIndex) => (
                      <div className="task-status" key={taskIndex}>
                        <div
                          className={`task-status__label${statusItem.notPerformed ? ' not-performed' : ''}${
                            statusItem.isCurrent ? ' current' : ''
                          }`}
                        >
                          {group.type === 'radio' ? (
                            <Radio checked={statusItem.checked} onClick={() => this.props.onClickStatus(statusItem)}>
                              {statusItem.label}
                            </Radio>
                          ) : (
                            <Checkbox checked={statusItem.checked} onClick={() => this.props.onClickStatus(statusItem)}>
                              {statusItem.label}
                            </Checkbox>
                          )}
                        </div>
                        <div className="task-info">
                          {statusItem.changeByUser && <div>{statusItem.changeByUser}</div>}
                          {statusItem.statusDate && <div>{moment(statusItem.statusDate).format('DD/MM/YYYY')}</div>}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          {!this.state.isExpanding && this.props.description && this.props.description()}
          <div className="expand-section">
            <Button
              title={this.toggleExpand ? 'Hide detail statuses' : 'Show detail statuses'}
              shape="circle"
              icon={`${this.state.isExpanding ? 'up' : 'down'}`}
              onClick={this.toggleExpand}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressStatus;
