import './caseProgress.scss';

import { Spin } from 'antd';
import cx from 'classnames';
import React from 'react';
import { Divider, Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';

class CaseProgress extends React.Component {
  state = {
    loading: true,
    toggleViewHistory: false,
    progressList: this.props.progressList,
    history: null,
  };

  async componentDidMount() {
    try {
      const { url, body } = this.props;

      const response = await customFetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      this.setState({ history: response.itemList });
      const newProgressList = this.state.progressList.map(item => {
        const matchedStatus = response.itemList.find(i => item.Key === i.Status);

        if (matchedStatus) {
          return {
            ...item,
            Checked: true,
            statusDate: matchedStatus.StatusDate,
            createdDate: matchedStatus.CreatedDate,
            personInCharge: matchedStatus.UserName,
            remark: matchedStatus.Remark,
          };
        }
        return item;
      });

      this.setState({ progressList: newProgressList });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderProgressList = () => {
    return this.state.progressList.map(({ Key, Label, Checked }, index) => (
      <div key={`${index}`} className="status-item">
        <div className={cx('status-box', Checked ? `checked` : `unchecked`)}>
          <div className="status-box-outer" />
          <div className="status-box-inner">{Checked && <Icon name="check" size="large" />}</div>
        </div>
        <span className="status-label">{Label}</span>
      </div>
    ));
  };

  convertPersonInCharge = person => {
    const person_ = person ? person.replace(/^(.+)@(.+)$/g, '$1') : person;
    return person_;
  };

  renderPersonInChargeList = () => {
    return this.state.progressList.map(({ Key, personInCharge, checked }, index) => {
      return (
        <div key={index} className="person-in-charge">
          {Key === 'Service Paid'
            ? ''
            : (personInCharge === 'system@medicolegalassessmentsgroup.com.au'
                ? 'system'
                : this.convertPersonInCharge(personInCharge)) ||
              (checked && 'N/a')}
        </div>
      );
    });
  };

  renderStatusDateList = () => {
    return this.state.progressList.map(({ Key, statusDate, checked }, index) => (
      <div key={index} className="status-date">
        {Key === 'Service Paid' ? '' : formatDate(statusDate) || (checked && 'N/a')}
      </div>
    ));
  };

  handleToggleViewHistory = () => {
    this.setState(prev => ({
      ...prev,
      toggleViewHistory: !prev.toggleViewHistory,
    }));
  };

  checkUpdatedBy = user => {
    return user === 'system@medicolegalassessmentsgroup.com.au' ? 'system' : this.convertPersonInCharge(user) || 'N/a';
  };
  convertStatusName = status => {
    const statusName = this.state.progressList.find(i => i.Key === status);
    if (statusName) {
      return statusName.Label;
    } else {
      if (status === 'Prepayment Required') return 'Prepayment Required';
      if (status === 'Prepayment Paid') return 'Prepayment Paid';
      if (status === 'Billed') return 'Service Billed';
      return status;
    }
    // const statusName = this.state.progressList.find(i => i.Key === status)?.Label;
    // return statusName;
  };

  renderStatusHistory = () => (
    <Table striped className="no-stick-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Status Date</Table.HeaderCell>

          <Table.HeaderCell>Updated At</Table.HeaderCell>
          <Table.HeaderCell>Updated By</Table.HeaderCell>
          <Table.HeaderCell>Remark</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.history.map((log, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>{this.convertStatusName(log.Status || log.StatusName)}</Table.Cell>
            <Table.Cell>
              {log.StatusDate || log.statusDate
                ? moment(formatDate(log.StatusDate || log.statusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              {log.CreatedDate || log.createdDate
                ? moment(formatDate(log.CreatedDate || log.createdDate, true), 'DD MMM YYYY, HH:mm').format(
                    'DD/MM/YYYY @ HH:mm',
                  )
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>{this.checkUpdatedBy(log.UserName)}</Table.Cell>
            <Table.Cell>{log.Remark || 'N/a'}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
  render() {
    const { loading, toggleViewHistory } = this.state;
    const { showHistory } = this.props;
    if (loading) {
      return <Spin className="modal-spin" />;
    }

    return (
      <div className="Case-progress">
        <div className="status-list">{this.renderProgressList()}</div>
        <div className="status-details">
          <div className="person-in-charge-list">
            <h4 className="mb-0">Person in charge:</h4>
            <Divider />
            <div className="person-in-charge-list-item">{this.renderPersonInChargeList()}</div>
          </div>
          <div className="status-date-list">
            <h4 className="mb-0">Status date:</h4>
            <Divider />
            <div className="status-date-list-item">{this.renderStatusDateList()}</div>
          </div>
        </div>
        {showHistory && (
          <div className="Case-progress-status-history">
            <h4 onClick={this.handleToggleViewHistory}>
              View status history
              <Icon name={`angle ${toggleViewHistory ? 'down' : 'right'}`} />
            </h4>
            {toggleViewHistory && this.renderStatusHistory()}
          </div>
        )}
      </div>
    );
  }
}

export default CaseProgress;
