import customFetch from '../../../utils/customFetch';

export const getAMATimeUnitModify = (
  keyword = '',
  curPage = 1,
  numItemPerPage = 0,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/AMATimeUnitModify/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getAMATimeUnitModifyInfo = (id = 0) => {
  return customFetch(`/AMATimeUnitModify/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveAMATimeUnitModify = param => {
  return customFetch('/AMATimeUnitModify/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteAMATimeUnitModify = id => {
  return customFetch('/AMATimeUnitModify/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchAMATimeUnitModify = param => {
  return customFetch('/AMATimeUnitModify/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};
