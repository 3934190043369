import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

import TableToolbar from '../../shared/tableToolbar';
import imeStore from './IMEStore';

@observer
class IMEToolbar extends React.Component {
  @action handleDateChange = (_, { value }) => {
    imeStore.fromTo = value;
  };

  handleFilter = (_, { value }) => {
    imeStore.refetch({ filter: value, currentPage: 1 }, true);
  };

  handleFilterNextStatus = (_, { value }) => {
    imeStore.refetch({ filterNextStatus: value, currentPage: 1 }, true);
  };

  onSearch = event => {
    event.preventDefault();
    imeStore.refetch({ currentPage: 1 }, true);
  };

  @action handleReset = () => {
    imeStore.sortBy = 'bookingDate';
    imeStore.currentPage = 1;
    imeStore.keyword = '';
    imeStore.iconCol = '';
    imeStore.iconDire = '';
    imeStore.iconDire = '';
    imeStore.filter = 'All';
    imeStore.filterTypes = null;
    imeStore.filterNextStatus = 'All';
    imeStore.sortDirection = 'descending';
    imeStore.fromTo = '';
    imeStore.fetchReports(1);
  };

  @action handRefresh = () => {
    imeStore.data = null;
    imeStore.fetchReports(1);
  };

  render() {
    const {
      loading,
      keyword,
      fromTo,
      filter,
      filterNextStatus,
      dynamicStatus,
      dynamicNextStatus,
      totalItems,
    } = imeStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.onSearch}>
          <TableToolbar.Input value={keyword} onChange={imeStore.handleSearchChange} disabled={loading} />
          <TableToolbar.RangePicker
            disabled={loading}
            placeholder="From Appointment Date - To Appointment Date"
            value={fromTo}
            onChange={this.handleDateChange}
          />
          <div className="pd-l-10">
            <TableToolbar.SubmitButton disabled={loading}>SEARCH</TableToolbar.SubmitButton>
          </div>
          <div className="pd-l-10">
            <TableToolbar.HighLightButton color="red" onClick={this.handleReset} disabled={loading}>
              CLEAR
            </TableToolbar.HighLightButton>
          </div>
          <div className="pd-l-10">
            <TableToolbar.HighLightButton className="blue" onClick={this.handRefresh} disabled={loading}>
              REFRESH
            </TableToolbar.HighLightButton>
          </div>
        </TableToolbar.Left>
        <TableToolbar.Right>
          <span style={{ marginRight: `10px` }} className="nowrap">
            <p>
              <b>Total Cases: {totalItems}</b>
            </p>
          </span>
          <TableToolbar.Filter
            disabled={loading}
            label="Tasks Due"
            style={{ minWidth: '400px' }}
            value={filterNextStatus}
            options={dynamicNextStatus}
            onChange={this.handleFilterNextStatus}
          />
          <TableToolbar.Filter
            disabled={loading}
            label="Case Status"
            style={{ minWidth: '400px' }}
            value={filter}
            options={dynamicStatus}
            onChange={this.handleFilter}
          />
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default withRouter(observer(IMEToolbar));
