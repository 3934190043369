import dashboardStore from '@stores/dashboard';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import OtherSalesCallReportStore from './OtherSalesCallReportStore';
import Content from '../SalesCallReportForCM/Content';
import { getActivityType } from '../../../utils/functions';

const columnsCM = [
  { title: 'Service' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Activity Type' },
  { title: 'Marketing Campaign' },
  { title: 'Content' },
  { title: 'Staff' },
  { title: 'Activity Date', sortKey: `CallDate` },
  // { title: 'Account Manager' },
];

@observer
class OtherSalesCallReportTable extends React.Component {
  openCaseDetails = record => e => {
    e.preventDefault();
    const id = record.CMId;
    const stogrageName = 'CmName';
    const url = '/view/add-edit-case-manager-2';

    const value = `${record.CMFullName}`;
    localStorage.setItem(stogrageName, value);
    dashboardStore.close(url, true);
    setTimeout(() => {
      dashboardStore.open(`${url}?id=${id}`);
    });
  };
  renderTableBodyCM = () => {
    return OtherSalesCallReportStore.data.itemList.map((record, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>{record.Service}</Table.Cell>
        <Table.Cell>{record.Subject}</Table.Cell>
        <Table.Cell>{record.Caller}</Table.Cell>
        <Table.Cell>{getActivityType(record.TypeLog)}</Table.Cell>
        <Table.Cell>{record.Receiver}</Table.Cell>
        <Table.Cell className="Cell-Body">
          <Content Notes={record.Content} />
        </Table.Cell>
        <Table.Cell>{record.CallBy}</Table.Cell>
        <Table.Cell>{moment(record.CallDate).format('DD/MM/YYYY')}</Table.Cell>
        {/* <Table.Cell>{record.ClientServiceOfficer}</Table.Cell> */}
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    OtherSalesCallReportStore.refetch({ curPage: page - 1 });
  };

  render() {
    const { loading, data } = OtherSalesCallReportStore;
    const columns = columnsCM;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={OtherSalesCallReportStore.sortColumn}
          sortDirection={OtherSalesCallReportStore.sortDirection}
          onSort={OtherSalesCallReportStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBodyCM() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={OtherSalesCallReportStore.totalItem}
          currentPage={OtherSalesCallReportStore.curPage + 1}
          totalPage={OtherSalesCallReportStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default OtherSalesCallReportTable;
