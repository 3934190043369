import React from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react';
import { Spin, Form, Select, Checkbox, Tooltip, notification } from 'antd';
import { action } from 'mobx';
import Modal from '../../shared/Modal';
import ServiceFeeStore from './AMAServiceFeeStore';
import { searchAMAFee, searchAMACaculation, saveAMACalculationRule } from './Service';

@observer
class CreateCalculationRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ApplyForRange: false,
      AMAFeeId: undefined,
      FromAMAFeeId: undefined,
      ToAMAFeeId: undefined,
      CalculationId: undefined,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Promise.all([searchAMAFee(''), searchAMACaculation({})]).then(([respAMA, respCalculationRule]) => {
      if (respAMA.status === 'success') {
        ServiceFeeStore.setListAMACodeFrom(respAMA.itemList);
        ServiceFeeStore.setListAMACodeTo(respAMA.itemList);
      } else {
        ServiceFeeStore.setListAMACodeFrom([]);
        ServiceFeeStore.setListAMACodeTo([]);
      }
      if (respCalculationRule.status === 'success') {
        ServiceFeeStore.setListCalculationRule(respCalculationRule.itemList);
      } else {
        ServiceFeeStore.setListCalculationRule(respCalculationRule.itemList);
      }
      this.setState({ loading: false });
    });
  }

  // componentWillUnmount() {
  //   this.handleResetDataModal();
  // }

  onCancel = () => {
    this.props.onCancel();
  };

  handleChangeFieldCheckbox = fieldName =>
    action(event => {
      const value = event.target.checked;
      this.setState({
        ...this.state,
        [fieldName]: value,
      });
    });

  @action handleSearchAMAFeeCode = type =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchAMAFee(value).then(
          action(resp => {
            if (type === 1) {
              ServiceFeeStore.setListAMACodeFrom(resp.itemList);
            } else {
              ServiceFeeStore.setListAMACodeTo(resp.itemList);
            }
          }),
        );
      }
    });

  @action handleSearchCalculationRule = () =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        var params = { keyword: value };
        searchAMACaculation(params).then(
          action(resp => {
            ServiceFeeStore.setListCalculationRule(resp.itemList);
          }),
        );
      }
    });

  @action handleDropdowlistValue = fieldName =>
    action(event => {
      const value = event;
      this.setState({
        ...this.state,
        [fieldName]: value,
      });
    });

  handleSaveCalculationRUle = () => {
    var saveData = this.state;
    console.log(saveData);
    const { lstAMACodeFrom, lstAMACodeTo } = ServiceFeeStore;
    if (saveData.ApplyForRange) {
      var fromCode = lstAMACodeFrom.filter(i => i.Id === saveData.FromAMAFeeId);
      var toCode = lstAMACodeTo.filter(i => i.Id === saveData.ToAMAFeeId);
      if (fromCode && fromCode.length > 0 && fromCode && fromCode.length > 0) {
        var prefixFromCode = fromCode[0].AMACode.substring(0, 2);
        var prefixToCode = toCode[0].AMACode.substring(0, 2);
        var subfixFromCode = fromCode[0].AMACode.substring(2, fromCode[0].AMACode.length);
        var subfixToCode = toCode[0].AMACode.substring(2, toCode[0].AMACode.length);
        if (prefixFromCode !== prefixToCode) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Please select same prefix AMACode',
            duration: 3,
          });
          return;
        } else if (parseInt(subfixFromCode) >= parseInt(subfixToCode)) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Please select from AMACode less than to AMACode',
            duration: 3,
          });
          return;
        }
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Please select from AMACode and to AMACode',
          duration: 3,
        });
        return;
      }
      saveData.FromAMAFeeCode = fromCode[0].AMACode;
      saveData.ToAMAFee = toCode[0].AMACode;
    }
    saveAMACalculationRule(saveData).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Save successfully',
          duration: 3,
        });
        this.props.onCancel();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Please check data',
          duration: 3,
        });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { open, lstAMACodeFrom, lstAMACodeTo, lstCalculationRule } = ServiceFeeStore;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={() => this.handleSaveCalculationRUle()}>
              <Icon name="save" /> Save
            </Button>
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title="Add Calculation Rule"
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Form name="conversation" className="conversation-form">
              <Form.Item>
                <Checkbox
                  style={{ marginTop: '10px' }}
                  checked={this.state.ApplyForRange}
                  onChange={this.handleChangeFieldCheckbox('ApplyForRange')}
                >
                  {' '}
                  Applicable Range
                </Checkbox>
              </Form.Item>
              {!this.state.ApplyForRange && (
                <Form.Item label="AMA Code">
                  <Select
                    value={this.state.AMAFeeId || undefined}
                    onChange={this.handleDropdowlistValue('AMAFeeId')}
                    onSearch={this.handleSearchAMAFeeCode(1)}
                    showSearch
                    filterOption="children"
                  >
                    {lstAMACodeFrom &&
                      lstAMACodeFrom.map(({ Id, AMACode, Description }) => (
                        <Select.Option value={Id} key={Id}>
                          <Tooltip title={Description} placement="right">
                            <span
                              style={{
                                width: '175',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {AMACode}
                            </span>
                          </Tooltip>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {this.state.ApplyForRange && (
                <Form.Item label="From">
                  <Select
                    value={this.state.FromAMAFeeId || undefined}
                    onChange={this.handleDropdowlistValue('FromAMAFeeId')}
                    onSearch={this.handleSearchAMAFeeCode(1)}
                    showSearch
                    filterOption="children"
                  >
                    {lstAMACodeFrom &&
                      lstAMACodeFrom.map(({ Id, AMACode, Description }) => (
                        <Select.Option value={Id} key={Id}>
                          <Tooltip title={Description} placement="right">
                            <span
                              style={{
                                width: '175',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {AMACode}
                            </span>
                          </Tooltip>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {this.state.ApplyForRange && (
                <Form.Item label="To">
                  <Select
                    value={this.state.ToAMAFeeId || undefined}
                    onChange={this.handleDropdowlistValue('ToAMAFeeId')}
                    onSearch={this.handleSearchAMAFeeCode(2)}
                    showSearch
                    filterOption="children"
                  >
                    {lstAMACodeTo &&
                      lstAMACodeTo.map(({ Id, AMACode, Description }) => (
                        <Select.Option value={Id} key={Id}>
                          <Tooltip title={Description} placement="right">
                            <span
                              style={{
                                width: '175',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {AMACode}
                            </span>
                          </Tooltip>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label="Calculation Rule">
                <Select
                  value={this.state.CalculationId || undefined}
                  onChange={this.handleDropdowlistValue('CalculationId')}
                  onSearch={this.handleSearchCalculationRule()}
                  showSearch
                  filterOption="children"
                >
                  {lstCalculationRule &&
                    lstCalculationRule.map(({ Id, CalculationName, CalculationRules }) => (
                      <Select.Option value={Id} key={Id}>
                        <Tooltip title={CalculationRules} placement="right">
                          <span
                            style={{
                              width: '175',
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {CalculationName}
                          </span>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    );
  }
}

export default CreateCalculationRule;
