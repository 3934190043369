import React from 'react';
import { Form, InputNumber, Checkbox, Input } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { renderDate } from '../../case-details-routes/Shared';

import AddEditStore from './store';

@observer
class SubscriptionSettings extends React.Component {
  render() {
    const data = toJS(AddEditStore.clientInfo);
    return (
      <Form name="subscription-settings">
        <Form.Item className="fluid-field">
          <div className="fluid-field subscribe-box">
            <Checkbox
              onChange={AddEditStore.handleChecked('SubscribeToPromotions')}
              checked={data.SubscribeToPromotions}
            >
              Subscribe to Promotions
            </Checkbox>
            {data.SubscribeToPromotions && data.SubscribeToPromotionsDate && (
              <span>
                {data.SubscribeToPromotionsDate
                  ? 'Subscribed: ' + renderDate(data.SubscribeToPromotionsDate, false, true)
                  : ''}
                {data.SubscribeToPromotionsBy ? ` - By ${data.SubscribeToPromotionsBy}` : ''}
              </span>
            )}
            {!data.SubscribeToPromotions && data.UnsubscribeToPromotionsDate && (
              <span>
                {data.UnsubscribeToPromotionsDate
                  ? 'Unsubscribed: ' + renderDate(data.UnsubscribeToPromotionsDate, false, true)
                  : ''}
                {data.UnsubscribeToPromotionsBy ? ` - By ${data.UnsubscribeToPromotionsBy}` : ''}
              </span>
            )}
          </div>
        </Form.Item>
        <Form.Item label="Subscription Notes" className="fluid-field">
          <Input.TextArea
            rows={3}
            value={data.SubscriptionNotes}
            onChange={AddEditStore.handleFieldChange('SubscriptionNotes')}
          />
        </Form.Item>
        <Form.Item label="Appointment Confirmed Due Days">
          <InputNumber
            size="large"
            onChange={AddEditStore.handleFieldChange('AppointmentBookingReminderDueDay')}
            value={data.AppointmentBookingReminderDueDay}
          />
        </Form.Item>
        <Form.Item label="Report Completed Due Days">
          <InputNumber
            size="large"
            onChange={AddEditStore.handleFieldChange('ReportCompletionReminderDueDay')}
            value={data.ReportCompletionReminderDueDay}
          />
        </Form.Item>
        <Form.Item>
          <div className="fluid-field subscribe-box">
            <Checkbox
              checked={data.SubscribeToInvoiceReminders}
              onChange={AddEditStore.handleChecked('SubscribeToInvoiceReminders')}
            >
              Overdue Invoice Reminders
            </Checkbox>
          </div>
        </Form.Item>
        {/* <div className="subscribe-box">
          <Checkbox
            checked={data.SubscribeSpecialistInvoiceCheck}
            onChange={AddEditStore.handleChecked('SubscribeSpecialistInvoiceCheck')}
          >
            Subscribe to Specialist Invoice Check
          </Checkbox>
          {data.SubscribeSpecialistInvoiceCheckDate && (
            <span>
              <br></br>
              {data.SubscribeSpecialistInvoiceCheckDate
                ? 'Updated: ' + renderDate(data.SubscribeSpecialistInvoiceCheckDate, false, true)
                : ''}
              {data.SubscribeSpecialistInvoiceCheckBy ? ` - By ${data.SubscribeSpecialistInvoiceCheckBy}` : ''}
            </span>
          )}
        </div> */}
      </Form>
    );
  }
}

export default SubscriptionSettings;
