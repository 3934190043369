import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';
import uploadStore from '../../shared/UniversalSearch/UploadFile/UploadStore';
import UniversalSearchModal from '../../shared/UniversalSearch/Modal';
import IMEToolbar from './IMEToolbar';
import IMETable from './IMETable';
import IMEModal from './IMEModal';
import IMEStore from './IMEStore';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';
import * as api from '../../../stores/api';
import moment from 'moment';

@observer
class IME extends React.Component {
  renderCaseForCM = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case 'Corrected Report Sent to MAG':
        return 'Corrected Report Returned from Provider';
      default:
        return caseStatus;
    }
  };
  componentDidMount() {
    this._searchDebounced();
  }
  componentWillUnmount() {
    IMEStore.resetStore();
  }
  _searchDebounced = () => {
    IMEStore.fetchPicStaff();
    IMEStore.fetchOverdue();
    IMEStore.fetchCase();
    IMEStore.fetchActionPIC();
    IMEStore.fetchPrepayment();
    IMEStore.fetchGetSuggestClaimant();
    IMEStore.fetchTypeOfReport();
    IMEStore.fetchTypeOfClaim();
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?actionRequired=') !== -1) {
        const id = search.replace('?actionRequired=', '');
        IMEStore.refetch({ FilterType: 'All', ActionRequiredById: id }, true);
        return;
      } else if (search.indexOf('?caseStatus=') !== -1) {
        const id = decodeURI(search.replace('?caseStatus=', ''));
        if (api.isCaseManager()) {
          IMEStore.refetch({ FilterType: this.renderCaseForCM(id) }, true);
        } else {
          if (!search.includes('caseStatus=Cancelled')) {
            IMEStore.refetch({ fromToRequest: `${moment().format('DD/MM/YYYY')} - `, FilterType: id }, true);
          } else {
            IMEStore.refetch(
              {
                fromToStatus: `${moment()
                  .startOf('month')
                  .format('DD/MM/YYYY')} - ${moment()
                  .endOf('month')
                  .format('DD/MM/YYYY')}`,
                FilterType: id,
              },
              true,
            );
          }
        }
        return;
      } else if (search.indexOf('?fromToRequest') !== -1) {
        IMEStore.refetch({ fromToRequest: `${moment().format('DD/MM/YYYY')} - ` }, true);
      } else {
        const id = search.replace('?nextTaskDue=', '');
        if (api.isCaseManager() || api.isTypist()) {
          IMEStore.refetch({ FilterType: this.renderCaseForCM(id) }, true);
        } else {
          IMEStore.refetch({ FilterOverdue: id }, true);
        }
        return;
      }
    } else {
      IMEStore.refetch({}, true);
      return;
    }
  };
  render() {
    const {
      loading,
      data,
      open,
      loadingClaimant,
      dataSuggestClaimant,
      loadingActionPIC,
      loadingCase,
      loadingOverDue,
      loadingStaff,
      dataActionPIC,
      dataCase,
      dataOverdue,
      dataStaff,
    } = IMEStore;
    return (
      <div
        className={`Table-${
          (loading && !data) ||
          (loadingClaimant && !dataSuggestClaimant) ||
          (loadingActionPIC && !dataActionPIC) ||
          (loadingStaff && !dataStaff) ||
          (loadingOverDue && !dataOverdue) ||
          (loadingCase && !dataCase)
            ? 'loading'
            : 'container'
        }`}
      >
        {(loading && !data) ||
        (loadingClaimant && !dataSuggestClaimant) ||
        (loadingActionPIC && !dataActionPIC) ||
        (loadingStaff && !dataStaff) ||
        (loadingOverDue && !dataOverdue) ||
        (loadingCase && !dataCase) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <IMEToolbar />
            <IMETable />
            {open && <IMEModal />}
            {uploadStore.open && <UniversalSearchModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default IME;
