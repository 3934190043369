import { Spin } from 'antd';
import React, { Fragment } from 'react';
import { Accordion, Card, Grid, Image, Menu, Popup } from 'semantic-ui-react';

import { getStatisticsCMBooking, getStatisticsDoctorBooking, getStatisticsStaffBooking } from './service';

class NavigateSection extends React.Component {
  state = {
    loading: true,
    dataCM: [],
    dataStaff: [],
    dataSpecialist: [],
    specialistPerformanceType: 2,
    staffPerformanceType: 2,
    caseManagerPerformanceType: 2,
  };

  componentDidMount() {
    this.handleFetchData(2);
  }

  handleFetchData = type => {
    const { keyword } = this.props;
    return keyword === 'specialist'
      ? this.handleFetchForSpecialist(type)
      : keyword === 'staff'
      ? this.handleFetchForStaff(type)
      : keyword === 'cm' && this.handleFetchForCm(type);
  };

  handleFetchForCm = type => {
    Promise.all([getStatisticsCMBooking(type)])
      .then(([statisticCM]) => {
        this.setState({
          dataCM: statisticCM?.data || [],
          loading: false,
          caseManagerPerformanceType: type,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  detectImageNotFound = e => {
    e.target.onerror = null;
    e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg';
  };

  handleFetchForStaff = type => {
    Promise.all([getStatisticsStaffBooking(type)])
      .then(([statisticStaff]) => {
        this.setState({
          dataStaff: statisticStaff?.data || [],
          loading: false,
          staffPerformanceType: type,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleFetchForSpecialist = type => {
    Promise.all([getStatisticsDoctorBooking(type)])
      .then(([statisDoctor]) => {
        this.setState({
          dataSpecialist: statisDoctor?.data || [],
          loading: false,
          specialistPerformanceType: type,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleFilterActive = type => {
    const { keyword } = this.props;
    const { specialistPerformanceType, staffPerformanceType, caseManagerPerformanceType } = this.state;
    return keyword === 'specialist'
      ? specialistPerformanceType === type
      : keyword === 'staff'
      ? staffPerformanceType === type
      : keyword === 'cm' && caseManagerPerformanceType === type;
  };

  handleClick = idx => {
    const { active } = this.state;

    if (active.includes(idx)) {
      this.setState({ active: active.filter(i => i !== idx) });
    } else {
      this.setState({ active: [...active, idx] });
    }
  };

  renderMenuPerfomance = () => {
    return (
      <Menu pointing secondary>
        <Menu.Item onClick={() => this.handleFetchData(1)} active={this.handleFilterActive(1)}>
          This Week
        </Menu.Item>
        <Menu.Item onClick={() => this.handleFetchData(2)} active={this.handleFilterActive(2)}>
          Last Month
        </Menu.Item>
        <Menu.Item onClick={() => this.handleFetchData(3)} active={this.handleFilterActive(3)}>
          All Time
        </Menu.Item>
      </Menu>
    );
  };

  renderTopRankData = (data, key) => {
    if (data.rank === 1) {
      console.log(data);
      return (
        <Grid.Column key={key}>
          <Card>
            {this.props.keyword === 'specialist' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(1, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="top center"
              >
                Specialty: {data.Specialties}
              </Popup>
            )}
            {this.props.keyword === 'cm' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(1, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="top center"
              >
                Client: {data.ClientName}
              </Popup>
            )}
            {this.props.keyword === 'staff' && (
              <Image src={this.getProfileImageUrl(1, data.Avata)} avatar onError={this.detectImageNotFound} />
            )}
            <div className="header">
              <Popup
                content={
                  <>
                    {data.FullName}
                    {this.props.keyword === 'specialist' && <> - Specialty: {data.Specialties}</>}
                    {this.props.keyword === 'cm' && <> - Client: {data.ClientName}</>}
                  </>
                }
                position="top center"
                trigger={<label>{data.FullName}</label>}
              />
            </div>
            <div className="description">{data.Bookings}</div>
          </Card>
        </Grid.Column>
      );
    } else if (data.rank === 2) {
      return (
        <Grid.Column key={key} style={{ marginTop: '50px' }}>
          <Card>
            {this.props.keyword === 'specialist' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(2, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="left center"
              >
                Specialty: {data.Specialties}
              </Popup>
            )}
            {this.props.keyword === 'cm' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(2, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="left center"
              >
                Client: {data.ClientName}
              </Popup>
            )}
            {this.props.keyword === 'staff' && (
              <Image src={this.getProfileImageUrl(2, data.Avata)} avatar onError={this.detectImageNotFound} />
            )}
            <div className="header">
              <Popup
                content={
                  <>
                    {data.FullName}
                    {this.props.keyword === 'specialist' && <> - Specialty: {data.Specialties}</>}
                    {this.props.keyword === 'cm' && <> - Client: {data.ClientName}</>}
                  </>
                }
                position="left center"
                trigger={<label>{data.FullName}</label>}
              />
            </div>
            <div className="description">{data.Bookings}</div>
            {/* <Label className="rank-2">{data.rank}</Label> */}
          </Card>
        </Grid.Column>
      );
    } else if (data.rank === 3) {
      return (
        <Grid.Column key={key} style={{ marginTop: '50px' }}>
          <Card>
            {this.props.keyword === 'specialist' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(3, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="right center"
              >
                Specialty: {data.Specialties}
              </Popup>
            )}
            {this.props.keyword === 'cm' && (
              <Popup
                trigger={
                  <Image src={this.getProfileImageUrl(3, data.Avata)} avatar onError={this.detectImageNotFound} />
                }
                position="right center"
              >
                Client: {data.ClientName}
              </Popup>
            )}
            {this.props.keyword === 'staff' && (
              <Image src={this.getProfileImageUrl(3, data.Avata)} avatar onError={this.detectImageNotFound} />
            )}
            <div className="header">
              <Popup
                content={
                  <>
                    {data.FullName}
                    {this.props.keyword === 'specialist' && <> - Specialty: {data.Specialties}</>}
                    {this.props.keyword === 'cm' && <> - Client: {data.ClientName}</>}
                  </>
                }
                position="right center"
                trigger={<label>{data.FullName}</label>}
              />
            </div>
            <div className="description">{data.Bookings}</div>
            {/* <Label className="rank-3">{data.rank}</Label> */}
          </Card>
        </Grid.Column>
      );
    } else return null;
  };

  changePositionElement = (array, indexOne, indexTwo) => {
    if (indexTwo >= array.length) {
      let k = indexTwo - array.length + 1;
      while (k--) {
        array = [...array, undefined];
      }
    }
    array.splice(indexTwo, 0, array.splice(indexOne, 1)[0]);
    return array.filter(i => i);
  };

  renderAccordionContent = (isTopRank = true) => {
    const { keyword } = this.props;
    const { dataCM, dataSpecialist, dataStaff } = this.state;
    const data =
      keyword === 'specialist' ? dataSpecialist : keyword === 'staff' ? dataStaff : keyword === 'cm' ? dataCM : [];
    const topRankData = data
      .filter((_, idx) => idx < 3)
      .map((i, idx) => ({
        ...i,
        rank: idx + 1,
      }));
    const notTopRankData = data.filter((_, idx) => idx >= 3);
    if (isTopRank)
      return (
        <Accordion.Content>
          <div className="top-rank">
            <Grid columns="equal">
              {this.changePositionElement(topRankData, 0, 1).map((i, idx) => this.renderTopRankData(i, idx))}
            </Grid>
          </div>
          {this.renderMenuPerfomance()}
        </Accordion.Content>
      );
    else
      return (
        <Accordion.Content>
          <React.Fragment>{notTopRankData.map((i, index) => this.renderContent(i, index))}</React.Fragment>
        </Accordion.Content>
      );
  };

  getProfileImageUrl = (_, avatarUrl) => {
    if (!avatarUrl) {
      return 'https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg';
    }
    return `${avatarUrl}`;
  };

  renderContent = (data, index) => {
    return (
      <div key={index} className="perfomance-item">
        <div className="rank">{index + 4}</div>
        {this.props.keyword === 'specialist' && (
          <Popup
            trigger={
              <Image
                src={this.getProfileImageUrl(2, data?.Avata || '')}
                size="small"
                avatar
                className="atavar"
                onError={this.detectImageNotFound}
              />
            }
            position="top left"
          >
            Specialty: {data.Specialties}
          </Popup>
        )}
        {this.props.keyword === 'cm' && (
          <Popup
            trigger={
              <Image
                src={this.getProfileImageUrl(2, data?.Avata || '')}
                size="small"
                avatar
                className="atavar"
                onError={this.detectImageNotFound}
              />
            }
            position="top left"
          >
            Client: {data.ClientName}
          </Popup>
        )}
        {this.props.keyword === 'staff' && (
          <Image
            src={this.getProfileImageUrl(2, data?.Avata || '')}
            size="small"
            avatar
            className="atavar"
            onError={this.detectImageNotFound}
          />
        )}
        <span className="Text-truncate">
          <Popup
            content={
              <>
                {data.FullName}
                {this.props.keyword === 'specialist' && <> - Specialty: {data.Specialties}</>}
                {this.props.keyword === 'cm' && <> - Client: {data.ClientName}</>}
              </>
            }
            position="top left"
            trigger={<label>{data.FullName}</label>}
          />
        </span>
        <div className="count">({data.Bookings})</div>
      </div>
    );
  };

  renderAccordion = () => {
    return (
      <Accordion>
        <React.Fragment>{this.renderAccordionContent(false)}</React.Fragment>
      </Accordion>
    );
  };

  renderAccordionTopRank = () => {
    return (
      <Accordion>
        <React.Fragment>{this.renderAccordionContent(true)}</React.Fragment>
      </Accordion>
    );
  };
  renderEmptyMessage = () => {
    return (
      <div className="section-empty-message" key="empty-message">
        Empty
      </div>
    );
  };
  render() {
    return (
      <Fragment key={this.props.label}>
        <div className="perfomance-section">
          <div className="top-rank-section">
            <h3 className="section-title">{this.props.label ? this.props.label.toUpperCase() : ''}</h3>
            {this.state.loading ? <Spin /> : this.renderAccordionTopRank()}
          </div>
          {this.state.loading ? <Spin /> : this.renderAccordion()}
        </div>
      </Fragment>
    );
  }
}

export default NavigateSection;
