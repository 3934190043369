import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import './index.scss';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../../shared/table';
import * as api from '../../../../stores/api';
import BusinessUnitStore from './store';
import { handleEditStatusBU } from './service';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'BU Name' },
  { title: 'Status', sortKey: 'IsActive' },
  { title: 'Description', sortKey: 'Description' },
];

@observer
class BusinessUnitTable extends React.Component {
  openCaseDetail = (id, record) => () => {
    return BusinessUnitStore.toggleModal(true, {
      modalType: 'addEdit',
      id,
      record,
    })();
  };

  openModal = options => {
    return BusinessUnitStore.toggleModal(true, options)();
  };

  handleEditItemStatus = (id, record) => async () => {
    BusinessUnitStore.setFieldsValue({ loading: true });

    try {
      const responeEdit = await handleEditStatusBU(id);
      if (responeEdit.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
          duration: 3,
        });
      }
      await BusinessUnitStore.refetch({});
    } catch (e) {
    } finally {
      BusinessUnitStore.setFieldsValue({ loading: false });
    }
  };

  renderTableBody = (data, colSpan) => {
    if (!data.itemList || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }, idx) => (
      <Table.Row key={`${Id}-${idx}`} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell selectable className="Cell-link" onClick={this.openCaseDetail(Id, record)}>
          <Link onClick={this.openCaseDetail(Id, record)} to="#">
            {Id}
          </Link>
        </Table.Cell>
        <Table.Cell
          selectable
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
          onClick={this.openCaseDetail(Id, record)}
        >
          <Link onClick={this.openCaseDetail(Id, record)} to="#">
            {record.Title}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.openCaseDetail(id, record)}>Edit</Menu.Item>}
          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message: `Do you want to ${!record.IsActive ? 'enable' : 'disable'} business unit?`,
                onOk: this.handleEditItemStatus(id, record),
              })
            }
          >
            {!record.IsActive ? 'Enable' : 'Disable'}
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    BusinessUnitStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = BusinessUnitStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;
    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={BusinessUnitStore.handleSort}
        />
        <TableBody>{this.renderTableBody(data, colSpan)}</TableBody>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={BusinessUnitStore.curPage}
          totalPage={BusinessUnitStore.totalPage}
          totalItems={BusinessUnitStore.totalItem}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default BusinessUnitTable;
