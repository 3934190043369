export const ERROR_FIELDNAME = {
  FirstName: 'First name',
  CompanyId: 'Client',
  State: 'State',
  Email: 'Email address',
  FreeBookingApprovedBy: 'Free booking approved by',
  OfficePhone: 'Office phone',
  Telephone: 'Telephone',
};

export const ERROR_MESSAGES = {
  required: 'is required',
  invalid: 'is invalid',
  digits: 'must be less than or equal to 10 digits',
};
