import React from 'react';

import ModalAlert from './ModalConfirm';
import ModalConfirm from '../../../shared/Modal/ModalConfirm';
import DoctorDetailsStore, { DoctorAttachmentType } from './DoctorDetailsStore';
import ModalAddClinic from './ModalAddClinic';
import ModalProfilePicture from './ModalProfilePicture';
import ModalConversation from './ModalConversation';
import ModalEditUser from './ModalEditUser';
import ActionRequiredModal from './ActionRequiredModal';
import ModalPasswordResetHistory from './ModalPasswordResetHistory';
import ModalDuplicateEmail from './ModalDuplicateEmail';
import ModalUserUpdateHistory from './ModalUserUpdateHistory';
import FieldChangeHistory from './FieldChangeHistory';

function DoctorDetailsModal() {
  const { modalType } = DoctorDetailsStore.modalParams;
  if (modalType === 'alert') {
    return (
      <ModalAlert
        open={DoctorDetailsStore.open}
        {...DoctorDetailsStore.modalParams}
        onCancel={DoctorDetailsStore.toggleModal(false)}
      />
    );
  }
  if (modalType === 'confirm') {
    return <ModalConfirm {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'checkDuplicateEmail') {
    return <ModalDuplicateEmail {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'uploadPicture') {
    return (
      <ModalProfilePicture
        open={DoctorDetailsStore.open}
        attachmentType={DoctorAttachmentType.ProfilePicture}
        toggleModal={DoctorDetailsStore.toggleModal}
        uploadFile={DoctorDetailsStore.uploadFile}
        uploadFileFromBase64={DoctorDetailsStore.uploadFileFromBase64}
      />
    );
  }
  if (modalType === 'addEditClinic') {
    return <ModalAddClinic {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'addEditConversation') {
    return (
      <ModalConversation
        data={DoctorDetailsStore.modalParams.conversation}
        action={DoctorDetailsStore.modalParams.action}
      />
    );
  }
  if (modalType === 'editUser') {
    return <ModalEditUser onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'actionRequired') {
    return <ActionRequiredModal onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'passwordRsHistory') {
    return <ModalPasswordResetHistory onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'userUpdateHistory') {
    return <ModalUserUpdateHistory onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'fieldChange') {
    return <FieldChangeHistory {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
}

export default DoctorDetailsModal;
