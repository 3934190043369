import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { formatDate } from '../../../utils/functions';
import Store from './HistoryReportStore';
import { TableHeader, TableEmptyMessage } from '../../shared/table';

@observer
class HistoryTable extends React.Component {
  filterUser = user => {
    const { keywordLogin } = Store;
    if (keywordLogin) {
      const username = user.username.toUpperCase();
      const fullName = user.fullName.toUpperCase();
      if (username.search(keywordLogin.toUpperCase()) !== -1 || fullName.search(keywordLogin.toUpperCase()) !== -1) {
        return user;
      }
      return null;
    } else {
      return user;
    }
  };
  renderTableBody = (data, colSpan) => {
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.map(({ Id, ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>
          {record.TypeOfExport === 1 ? 'Client' : record.TypeOfExport === 2 ? 'CM' : 'Specialist'}
        </Table.Cell>
        <Table.Cell>{record.Staff.FullName}</Table.Cell>
        <Table.Cell>
          {record.ExportAt
            ? moment(formatDate(record.ExportAt, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { dataLogin, loadingLogin } = Store;
    const columns = [{ title: `Type` }, { title: 'Full name' }, { title: 'Export time' }];
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <Table sortable striped celled className={cx({ disabled: loadingLogin })}>
          <TableHeader columns={columns} />
          <Table.Body>{this.renderTableBody(dataLogin, colSpan)}</Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default HistoryTable;
