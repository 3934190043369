import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import OtherSalesCallReportStore from './OtherSalesCallReportStore';
import OtherSalesCallReportToolbar from './OtherSalesCallReportToolbar';
import OtherSalesCallReportTable from './OtherSalesCallReportTable';
import OtherSalesCallModal from './OtherSalesCallModal';

class OtherSalesCallReport extends React.Component {
  componentDidMount() {
    OtherSalesCallReportStore.fetchReport(true);
    OtherSalesCallReportStore.fetchClient();
    OtherSalesCallReportStore.fetchOfficer();
    OtherSalesCallReportStore.fetchSearch();
  }

  componentWillUnmount() {
    OtherSalesCallReportStore.resetStore();
  }

  render() {
    const { loading, data, loadingStaff, dataStaff, loadingClient, dataClient, open } = OtherSalesCallReportStore;
    return (
      <div
        className={`Table-${
          (loading && !data) || (loadingStaff && !dataStaff) || (loadingClient && !dataClient) ? 'loading' : 'container'
        }`}
      >
        {(loading && !data) || (loadingStaff && !dataStaff) || (loadingClient && !dataClient) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <OtherSalesCallReportToolbar />
            <OtherSalesCallReportTable />
            {open && <OtherSalesCallModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(OtherSalesCallReport);
