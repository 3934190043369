import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import ServiceFeeModal from './AMAServiceFeeModal';
import amaStore from './AMAServiceFeeStore';
import ServiceFeeTable from './AMAServiceFeeTable';
import ServiceFeeToolbar from './AMAServiceFeeToolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class AMAFeeCodes extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/ama-service-fee', true);
      return;
    }

    // amaStore.fetchService(true);
    amaStore.fetchFiles(true);
    amaStore.fetchServiceTypeAMACodeFiles();
    amaStore.fetchTypeProcedureAMACodeFiles();
  }
  render() {
    const { loading, loadingServiceGroup, data, open, dataServiceGroup } = amaStore;
    return (
      <div className={`Table-${loadingServiceGroup && !data && !dataServiceGroup ? 'loading' : 'container'}`}>
        {loading && loadingServiceGroup && !dataServiceGroup ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ServiceFeeToolbar />
            <ServiceFeeTable />
            {open && <ServiceFeeModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default AMAFeeCodes;
