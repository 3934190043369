import { notification } from 'antd';
import { action, observable } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import { formatDate } from '@utils/functions';
import * as api from '../../../stores/api';
import { getCampaignInfo } from '../AddEditMarketingCampaign/service';
import {
  getAllSpecialist,
  getRegisteredSpecialistDetails,
  getRegisteredSpecialists,
  registeredSpecialists,
  getCMInfo,
  getClient,
  getSpecificLookupConfigByType,
  getStaffAll,
  getStaffs,
  getConversationLog,
  deleteConversationLog,
  allowCMViewConversationLog,
  saveConversationLog,
  fetchChartDate,
  fetchGreetingTitlesByType,
  getActionRequired,
  getCampaignAll,
  viewBookingHistory,
} from './service';
import customFetch from '../../../utils/customFetch';

const INIT_CM = {
  AssignedDate: '',
  AssignedStaffId: '',
  AssignedStaff: null,
  Comments: null,
  CompanyAddress: '',
  CompanyBusinessUnit: [],
  CompanyId: null,
  CompanyName: '',
  CompanyState: null,
  CreateFreeBookingByName: null,
  DateCheckedFreeBooking: null,
  Email: '',
  EmailConfirmed: false,
  EnableLogin: false,
  EnableLoginBy: '',
  EnableLoginDate: null,
  FirstName: '',
  FreeBookingApprovedBy: '',
  FreeBookingApprovedByName: null,
  FreeBookingFrom: null,
  FreeBookingTo: null,
  FullName: null,
  Id: 0,
  IsFreeBooking: false,
  LastName: '',
  LoginRequired: false,
  NumberOfFreeBookings: null,
  OfficePhone: '',
  Position: null,
  Postcode: '',
  LinkedIn: '',
  State: '',
  Suburb: '',
  Telephone: null,
  Title: null,
  Unsubscribed: false,
  UnsubscribedDate: moment(),
  Username: '',
  IsTypist: false,
  HourlyRate1: null,
  HourlyRate1From: null,
  HourlyRate1To: null,
  HourlyRate2: null,
  HourlyRate2From: null,
  HourlyRate2To: null,
  HourlyRate3: null,
  HourlyRate3From: null,
  HourlyRate3To: null,
  IsExcluding: false,
  IsOnboarding: true,
  IsNonNookingContact: false,
  IsRecruiting: false,
  IsOnboarded: true,
  ExcludingDate: null,
  OnboardingDate: moment(),
  RecruitingDate: null,
  OnboardedDate: moment(),
  ABN: null,
  BusinessABN: null,
  IsVerified: true,
  CMSource: 0,
  RecruitmentComment: null,
  IsSubscribed: true,
  SubscribedDate: null,
  SubscribedBy: null,
  CreatedById: null,
  IsActive: true,
  SubscribeSpecialistInvoiceCheck: false,
  UnSubscribeSpecialistInvoiceCheckDate: moment(),
  SubscribeSpecialistInvoiceCheckDate: moment(),
};
const BODY_CONVERSATIONLOG = {
  CallerSender: '',
  CallerSenderName: '',
  CallerSenderType: 3,
  CaseManagerId: 0,
  Content: '',
  ConversationType: 5,
  Id: 0,
  Receiver: '',
  ReceiverName: '',
  ReceiverType: 1,
  SentDate: moment(),
  Subject: '',
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: '',
  hour: 0,
  minute: 0,
  receiverId: 0,
  CampaignId: null,
  Attachments: [],
  CallEmailResultId: null,
  FollowUpNeeded: false,
  NextDueDate: null,
  CCServiceTypeId: 1,
  CCServiceTypeOther: null,
  CategoriesIds: null,
};

const VALIDATE_RULES = {
  // FirstName: 'required',
  CompanyId: 'required',
  FreeBookingApprovedBy: 'required',
  Email: ['email', 'required'],
  OfficePhone: 'max:10',
  Telephone: 'max:10',
  // State: 'required',
  IsRecruiting: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsOnboarding: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsOnboarded: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsExcluding: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
  required_without: {
    string: 'Must select at least one recruitment status',
  },
};

class AddEditStore {
  @observable loading = true;
  @observable loadingConversation = false;
  @observable loadingTalkingPoint = false;
  @observable CMInfo = INIT_CM;
  @observable dataChart = null;

  @observable dataUser = null;
  @observable userId = null;

  @observable CMInfo_ = INIT_CM;
  @observable isChanged = {};

  @observable conversationParam = BODY_CONVERSATIONLOG;

  @observable conversationSubReceiver = '';
  @observable conversationSubSender = '';

  //data
  @observable campaginList = null;
  @observable staffList = null;
  @observable staffAllList = null;
  @observable specificlookupList = null;
  @observable clientList = null;
  @observable conversationList = null;
  @observable cmCampaign = null;
  @observable uploadFile = [];

  //Search
  @observable keywordClient = '';
  @observable numberItemClient = 30;
  @observable loadingSearchClient = false;

  @observable keywordCM = '';
  @observable numberItemCM = 30;
  @observable loadingSearchCM = false;

  //Save
  @observable loadingSave = false;

  //Check duplicate
  @observable loadingCheckDuplicate = false;

  //Error
  @observable errors = {};
  //Modal
  @observable open = false;
  @observable modalParams = {};
  @observable actionRequiredList = [];
  @observable openModalAction = false;
  @observable campaignMergeAttachments = [];
  @observable loadingHistory = false;
  @observable dataHistory = null;
  @observable campaignDetail = {};
  @observable dataCreatedByHistory = null;

  //booking history
  @observable dataBookingHistory = null;
  @observable loadingBookingHistory = true;
  @observable totalBooking = 0;
  @observable sortColumnBookingHistory = 'Request Date';
  @observable sortDirectionBookingHistory = 'ascending';
  @observable sortKeyBookingHistory = 'RequestDate';

  @observable dataTalkingPoints = null;
  @observable clientInfo = null;
  //CIR-3328
  @observable specialistLoading = false;
  @observable registeredSpecialistIds = [];
  @observable registeredSpecialistObjs = [];
  @observable specialists = [];
  @observable sumItemSpecialist = 0;
  @observable sumPageSpecialist = 0;
  @observable curPageSpecialist = 1;
  @observable searchSpecialist = '';
  @observable selectedSpecialist = 'selected';

  @action toggleModalAction = isOpen => {
    this.openModalAction = isOpen;
  };

  @action handleChangeCMValue = (fieldName, value) => {
    this.CMInfo[fieldName] = value;
  };

  @action setRegisteredSpecialistObjs = arr => {
    this.registeredSpecialistObjs = arr;
  };

  @action setLstSpecialist = arr => {
    this.specialists = arr;
  };

  @action setTalkingPoint = arr => {
    this.dataTalkingPoints = arr;
  };

  refetchActionRequired = () => {
    getActionRequired(this.CMInfo.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };
  //

  @action fetchHistory = () => {
    this.loadingHistory = true;
    customFetch('/Staff/GetCaseManagerHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        id: this.modalParams.id,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
      }),
    }).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action handleEnableCase = (id, reason, otherDetail) => {
    customFetch('/Staff/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(resp => {
      this.handleUpdateCMInfo('IsActive', true);
    });
  };

  @action handleDisableCase = (id, reason, otherDetail) => {
    customFetch('/Staff/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ Id: id, Reason: reason, OtherDetail: otherDetail }),
    }).then(resp => {
      this.handleUpdateCMInfo('IsActive', false);
    });
  };

  @action handleReloadConversation = (searchKey = '') => {
    this.loadingConversation = true;
    Promise.all([getConversationLog(this.CMInfo.Id)])
      .then(
        action(([conversationList]) => {
          if (searchKey !== '' && conversationList?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            conversationList.itemList = conversationList.itemList.filter(
              t => t.Content.toLowerCase().indexOf(searchKey) > -1,
            );
          }
          this.conversationList = conversationList;
          this.loadingConversation = false;
        }),
      )
      .catch(() => (this.loadingConversation = false));
  };

  @action handleDelete = id => {
    this.loadingConversation = true;
    deleteConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation();
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAllow = id => {
    this.loadingConversation = true;
    allowCMViewConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation();
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAdd = body => {
    this.loadingConversation = true;
    saveConversationLog(body).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation();
        notification.destroy();
        notification.success({
          description: body.Id === 0 ? 'Add Conversation Log successfully!' : 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleResetBody = () => {
    this.conversationParam = {
      ...BODY_CONVERSATIONLOG,
      SentDate: moment(),
      CallerSenderName: api.currentUser.data.FullName,
      CallerSender: api.currentUser.data.id,
      Receiver: this.CMInfo.Id,
      ReceiverName: this.CMInfo.FirstName + ' ' + this.CMInfo.LastName,
    };
    this.uploadFile = [];
  };

  @action refetchSearchClient = data => {
    this.loadingSearchClient = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getClient(this.keywordClient, this.numberItemClient)]).then(
      action(([client]) => {
        this.clientList = client;
        this.loadingSearchClient = false;
      }),
    );
  };

  @action refetchSearchCM = data => {
    this.loadingSearchCM = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getStaffAll(this.keywordCM, this.numberItemCM)]).then(
      action(([cm]) => {
        this.staffAllList = cm;
        this.loadingSearchCM = false;
      }),
    );
  };

  @action handleAddError = (fieldName, errorMessage) => {
    this.errors = { ...this.errors, [fieldName]: errorMessage };
  };

  @action handleUpdateCMInfo = (key, value) => {
    const updatedCMInfo = { ...this.CMInfo };
    updatedCMInfo[key] = value;
    this.CMInfo = updatedCMInfo;
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleValidate = (field, value) => {
    if (field === 'Email') {
      const emailRequired = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][1] }, ERROR_MESSAGES);
      const emailValidation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][0] }, ERROR_MESSAGES);
      if (this.CMInfo.EnableLogin) {
        if (emailRequired.passes()) {
          delete this.errors[field];
          if (emailValidation.passes()) {
            delete this.errors[field];
          } else {
            this.errors[field] = emailValidation.errors.first(field);
          }
        } else {
          this.errors[field] = emailRequired.errors.first(field);
        }
      } else {
        if (emailValidation.passes()) {
          delete this.errors[field];
        } else {
          this.errors[field] = emailValidation.errors.first(field);
        }
      }
    } else {
      const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);
      if (validation.passes()) {
        delete this.errors[field];
      } else {
        this.errors[field] = validation.errors.first(field);
      }
    }
  };
  @observable greetingTitle = [];
  @action fetchCMData = (id, followUpNeeded = false) => {
    this.loading = true;
    Promise.all([
      getCMInfo(id),
      getSpecificLookupConfigByType(),
      getStaffs(),
      getConversationLog(id, followUpNeeded),
      fetchChartDate(id),
      fetchGreetingTitlesByType('casemanager'),
      getActionRequired(id),
      getCampaignAll(),
      this.fetchSpecialists(id),
    ]).then(
      action(
        ([
          info,
          specificlookup,
          staff,
          conversation,
          dataChart,
          greetingTitle,
          actionRequiredList,
          campaigns,
          specialists,
        ]) => {
          Promise.all([getClient(info.CompanyId), getStaffAll()]).then(
            action(([client, staffAll]) => {
              if (!!info.Id) {
                this.dataChart = dataChart.itemList.reduce(function(r, a) {
                  r[a.Type] = r[a.Type] || [];
                  r[a.Type].push(a);
                  return r;
                }, Object.create(null));
                this.loading = false;
                this.clientList = client;
                this.conversationList = conversation;
                this.specificlookupList = specificlookup;
                this.staffAllList = staffAll;
                this.staffList = staff;
                this.CMInfo = info;
                this.CMInfo_ = info;
                this.greetingTitle = greetingTitle.itemList;
                this.actionRequiredList = actionRequiredList.itemList;
                this.campaginList = campaigns.itemList;
                this.handleFetchBookingHistory();
                // this.specialists = specialists ? (specialists.itemList || []).map(d=> ({...d, RequestingDate: null})) : [];
                // this.sumItemSpecialist = specialists ? specialists.sumItem : 0;
                // this.sumPageSpecialist = specialists ? specialists.sumPage : 0;
                // this.curPageSpecialist = 1;
              } else {
                notification.error({
                  message: 'Error',
                  description: 'This case manager does not exist or has been deleted',
                });
                return this.initCMData();
              }
            }),
          );
        },
      ),
    );
  };

  @action initCMData = (companyId = 0) => {
    this.loading = true;
    this.CMInfo = INIT_CM;
    this.CMInfo_ = INIT_CM;
    if (companyId === 0 || companyId === '0') {
      //console.log(api.currentUser.data);
      this.CMInfo.CreatedById = api.currentUser.data.id;
    }
    Promise.all([
      getClient(companyId),
      getSpecificLookupConfigByType(),
      getStaffs(),
      getStaffAll(),
      fetchGreetingTitlesByType('casemanager'),
      getCampaignAll(),
    ]).then(
      action(([client, specificlookup, staff, staffAll, greetingTitle, campaigns]) => {
        this.CMInfo.CompanyId = companyId !== '0' ? parseInt(companyId) : null;
        this.clientList = client;
        if (!!companyId && this.clientList && this.clientList.itemList && this.clientList.itemList.length > 0) {
          let cObj = this.clientList.itemList.find(i => i.Id == companyId);
          console.log(cObj);
          if (!!cObj) this.CMInfo.IsSubscribed = cObj.SubscribeToPromotions;
        }
        this.specificlookupList = specificlookup;
        this.staffAllList = staffAll;
        this.staffList = staff;
        this.loading = false;
        this.greetingTitle = greetingTitle.itemList;
        // if (this.greetingTitle && this.greetingTitle.length > 0) {
        //   //this.CMInfo.Title = this.greetingTitle[0].Title;
        // }
        this.campaginList = campaigns.itemList;
      }),
    );
  };

  @action handleChecked = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'IsFreeBooking') {
        const freeBookingApprovedBy = this.CMInfo.FreeBookingApprovedBy;
        if (value) {
          if (!freeBookingApprovedBy) {
            this.errors['FreeBookingApprovedBy'] = 'This field is required';
          }
        } else {
          delete this.errors['FreeBookingApprovedBy'];
        }
      }
      if (fieldName === 'EnableLogin') {
        if (!this.CMInfo.Email && value) {
          this.handleAddError('Email', 'This field is required');
        } else {
          if (this.errors.Email === 'This field is required') {
            delete this.errors.Email;
          }
          if (this.errors['duplicateEmail']) {
            delete this.errors['duplicateEmail'];
          }
        }
      }
      if (fieldName === 'IsRecruiting') {
        if (value) {
          this.CMInfo.RecruitingDate = moment();
        } else {
          this.CMInfo.RecruitingDate = null;
        }
      }
      if (fieldName === 'IsOnboarding') {
        if (value) {
          this.CMInfo.OnboardingDate = moment();
        } else {
          this.CMInfo.OnboardingDate = null;
        }
      }

      if (fieldName === 'IsExcluding') {
        if (value) {
          this.CMInfo.ExcludingDate = moment();
        } else {
          this.CMInfo.ExcludingDate = null;
        }
      }
      if (fieldName === 'IsOnboarded') {
        if (value) {
          this.CMInfo.OnboardedDate = moment();
        } else {
          this.CMInfo.OnboardedDate = null;
        }
      }
      if (fieldName === 'Unsubscribed') {
        if (this.CMInfo.UnsubscribedDate) {
          this.CMInfo.UnsubscribedDate = null;
          return (this.CMInfo.Unsubscribed = false);
        } else {
          this.CMInfo.UnsubscribedDate = moment().toDate();
          return (this.CMInfo.Unsubscribed = true);
        }
      }
      if (fieldName === 'IsVerified') {
        this.CMInfo.IsVerified = !this.CMInfo.IsVerified;
      }

      if (fieldName === 'IsTypist' && value) {
        delete this.errors['CompanyId'];
      }
      this.CMInfo[fieldName] = value;

      if (fieldName === 'EnableLogin') {
        this.CMInfo.IsOnboarded = value;
        this.CMInfo.OnboardedDate = moment();
      }

      if (
        fieldName === 'IsRecruiting' ||
        fieldName === 'IsOnboarding' ||
        fieldName === 'IsOnboarded' ||
        fieldName === 'IsExcluding'
      ) {
        const validation = new Validator(
          {
            IsRecruiting: this.CMInfo.IsRecruiting || '',
            IsOnboarding: this.CMInfo.IsOnboarding || '',
            IsOnboarded: this.CMInfo.IsOnboarded || '',
            IsExcluding: this.CMInfo.IsExcluding || '',
          },
          {
            IsRecruiting: VALIDATE_RULES.IsRecruiting,
            IsOnboarding: VALIDATE_RULES.IsOnboarding,
            IsOnboarded: VALIDATE_RULES.IsOnboarded,
            IsExcluding: VALIDATE_RULES.IsExcluding,
          },
          ERROR_MESSAGES,
        );
        validation.passes();
        const { errors } = validation;
        const errorList = Object.entries(errors.errors);
        if (errorList.length === 4) {
          this.errors.RecruitmentStatus = validation.errors.first(fieldName);
        } else {
          delete this.errors.RecruitmentStatus;
        }
      }
    });

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'FollowUpNeeded' && value) {
        this.conversationParam.NextDueDate = moment();
      }
      this.conversationParam[fieldName] = value;
    });

  @action handleSearchCampaign = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      getCampaignAll(value).then(action(res => (this.campaginList = res.itemList)));
    });

  handleFieldChangeConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      var strCMFullName = this.CMInfo.FirstName + ' ' + this.CMInfo.LastName;
      if (this.CMInfo.Telephone) strCMFullName += ' - ' + this.CMInfo.Telephone;
      if (this.CMInfo.Email) strCMFullName += ' - ' + this.CMInfo.Email;
      if (fieldName === 'ConversationType') {
        if (value === 2 || value === 8 || value === 9) {
          this.conversationParam.CallerSenderType = 3;
          this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
          this.conversationParam.CallerSender = api.currentUser.data.id;
          this.conversationParam.Receiver = this.CMInfo.Id;
          this.conversationParam.ReceiverName = strCMFullName;
        }
        this.conversationParam[fieldName] = event;
      } else if (fieldName == 'CategoriesIds') {
      } else if (fieldName === 'Receiver') {
        this.conversationParam.Receiver = value.key;
        this.conversationParam.ReceiverName = value.label;
      } else if (fieldName === 'CallerSender') {
        this.conversationParam.CallerSender = value.key;
        this.conversationParam.CallerSenderName = value.label;
      } else if (fieldName === 'CampaignId') {
        this.campaignMergeAttachments =
          this.campaginList && value ? this.campaginList.find(i => i.Id === value)?.CampaignMergeAttachments : [];
        this.conversationParam[fieldName] = value;
      } else if (fieldName === 'CallEmailResultId') {
        var currentDate = moment();
        var dayOfWeek = currentDate.day();
        //var isWeekend = (dayOfWeek  === 6) || (dayOfWeek  === 0);
        if (value === 1) {
          this.conversationParam.FollowUpNeeded = true;
          if (dayOfWeek === 5) this.conversationParam.NextDueDate = currentDate.add('days', 3);
          else this.conversationParam.NextDueDate = currentDate.add('days', 1);
        }
        if (value === 2 || value === 4 || value === 5 || value === 6) {
          this.conversationParam.FollowUpNeeded = false;
          this.conversationParam.NextDueDate = null;
        }
        if (value === 3) {
          this.conversationParam.FollowUpNeeded = true;
          this.conversationParam.NextDueDate = currentDate.add('days', 7);
        }
        if (value === 7) {
          this.conversationParam.FollowUpNeeded = true;
          this.conversationParam.NextDueDate = currentDate.add('days', 28);
        }
        if (value === 8) {
          this.conversationParam.FollowUpNeeded = false;
          this.conversationParam.NextDueDate = null;
        }
        this.conversationParam[fieldName] = value;
      } else {
        if (fieldName === 'CallerSenderType') {
          if (value === 1) {
            this.conversationParam.CallerSender = this.CMInfo.Id;
            this.conversationParam.CallerSenderName = strCMFullName;
          }
          if (value === 3) {
            this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
            this.conversationParam.CallerSender = api.currentUser.data.id;
          }
        }
        if (fieldName === 'ReceiverType') {
          if (value === 1) {
            this.conversationParam.Receiver = this.CMInfo.Id;
            this.conversationParam.ReceiverName = strCMFullName;
          }
          if (value === 3) {
            this.conversationParam.ReceiverName = api.currentUser.data.FullName;
            this.conversationParam.Receiver = api.currentUser.data.id;
          }
        }
        this.conversationParam[fieldName] = value;
      }
    });

  createDate = date => {
    const datearr = date.split('/');
    const d = new Date(datearr[2], Number(datearr[1]) - 1, datearr[0]);
    return d;
  };

  showErrorEffectiveDate = message => {
    this.toggleModal(true, {
      modalType: 'confirm',
      message,
    })();
  };

  checkDateString = dateStr => {
    if (typeof dateStr === 'string') {
      const date = formatDate(dateStr);
      return moment(date, 'DD MMM,YYYY').format('DD/MM/YYYY');
    } else {
      return dateStr.format('DD/MM/YYYY');
    }
  };

  checkValidEffectiveFromTo = (dateFrom, dateTo) => {
    if (dateTo) {
      if (dateFrom) {
        const dateFrom_ = this.checkDateString(dateFrom);
        const dateTo_ = this.checkDateString(dateTo);
        const from = this.createDate(dateFrom_).getTime();
        const to = this.createDate(dateTo_).getTime();
        if (to < from) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  validationHourlyFromTo = (fieldName, value) => {
    if (!value) {
      return (this.CMInfo[fieldName] = value);
    } else {
      const date = this.createDate(value.format('DD/MM/YYYY'));
      if (fieldName === 'HourlyRate2From') {
        if (!this.CMInfo.HourlyRate1To && !this.CMInfo.HourlyRate1From) {
          return this.showErrorEffectiveDate('Please enter effective period of Hourly Rate 1');
        } else {
          if (this.CMInfo.HourlyRate1To) {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate1To));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 1.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          } else {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate1From));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 1.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          }
        }
      } else if (fieldName === 'HourlyRate3From') {
        if (!this.CMInfo.HourlyRate2To && !this.CMInfo.HourlyRate2From) {
          return this.showErrorEffectiveDate('Please enter effective period of Hourly Rate 2');
        } else {
          if (this.CMInfo.HourlyRate2To) {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate2To));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 2.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          } else {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate2From));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 2.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          }
        }
      }
    }
  };

  handleFieldChange = fieldName =>
    action(event => {
      let value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'CMSource' && event === 0) {
        value = 0;
      }
      if (fieldName === 'AssignedStaffId') {
        this.CMInfo.AssignedDate = moment().format('DD/MM/YYYY');
        this.CMInfo[fieldName] = value;
      } else if (fieldName === 'HourlyRate3From' || fieldName === 'HourlyRate2From') {
        this.validationHourlyFromTo(fieldName, value, 'checkForHandleChange');
      } else if (
        fieldName === 'Telephone' ||
        fieldName === 'OfficePhone' ||
        fieldName === 'HourlyRate1' ||
        fieldName === 'HourlyRate2' ||
        fieldName === 'HourlyRate3'
      ) {
        this.CMInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      } else if (fieldName === 'HourlyRate1To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate1From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'HourlyRate2To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate2From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'HourlyRate3To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate3From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'Position') {
        const exist = this.specificlookupList.itemList.find(i => i.Id === value);
        this.CMInfo[fieldName] = exist;
      } else if (fieldName === 'CompanyId') {
        if (this.clientList && this.clientList.itemList && this.clientList.itemList.length > 0) {
          let client = this.clientList.itemList.find(i => i.Id === value);
          if (!!client && this.CMInfo.Id === 0) {
            this.CMInfo.IsSubscribed = client.SubscribeToPromotions;
          }
        }
        if (this.CMInfo.CompanyBusinessUnit && this.CMInfo.CompanyBusinessUnit.length > 0) {
          return notification.error({
            message: 'Error',
            description: 'Please remove this CM from Business Units before changing client',
          });
        }
        this.CMInfo[fieldName] = value;
      } else {
        this.CMInfo[fieldName] = value;
      }

      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.CMInfo[fieldName]);
      }
    });

  @action UploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
        element.filename = element.name;
      });
      this.uploadFile = [...this.uploadFile, ...acceptedFiles].filter(
        (v, i, a) => a.findIndex(t => t.name === v.name) === i,
      );
    }
  };

  @action setCampaignMergeAttachment = attachs => {
    this.campaignMergeAttachments = attachs;
  };

  @action DeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      this.uploadFile = [...this.uploadFile].filter(x => x.name !== deletedFiles.name);
    }
  };
  @action resetStore = () => {
    this.loading = true;
    this.CMInfo = INIT_CM;
    this.CMInfo_ = INIT_CM;
    this.errors = {};

    this.dataBookingHistory = null;
    this.loadingBookingHistory = true;
    this.totalBooking = 0;
    this.registeredSpecialistIds = [];
    this.selectedSpecialist = 'selected';
  };

  @action handleSaveStaff = () => {
    this.loadingSave = true;
  };

  @action getCampaignDetail = campaignId => {
    getCampaignInfo(campaignId).then(action(res => (this.campaignDetail = res?.objItem)));
  };

  @action handleFetchBookingHistory = () => {
    Promise.all([
      viewBookingHistory(this.CMInfo.Id, this.sortKeyBookingHistory, this.sortDirectionBookingHistory),
    ]).then(
      action(([dataBooking]) => {
        this.loadingBookingHistory = false;
        this.dataBookingHistory = dataBooking;
        this.totalBooking = dataBooking.sumItem;
      }),
    );
  };

  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBookingHistory = column;
    this.sortDirectionBookingHistory = direction;
    this.sortKeyBookingHistory = sortKey;
    this.handleFetchBookingHistory();
  };

  //CIR-2338
  @action handlePageClickSpecialist = page => {
    if (this.selectedSpecialist === 'All') {
      this.curPageSpecialist = page;
      this.specialistLoading = true;

      getAllSpecialist({
        CurPage: page,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList }) => {
          this.specialists = itemList;
          this.specialistLoading = false;
        }),
      );
    }
  };

  @action handleClearSearchSpecialist = () => {
    this.searchSpecialist = '';
    this.handleSearchSpecialist();
  };

  @action handleSearchChange = event => {
    this.searchSpecialist = event.target.value;
  };

  fetchSpecialists = id => {
    return getRegisteredSpecialists(id).then(
      action(({ itemList }) => {
        if (itemList.length !== 0) {
          this.registeredSpecialistIds = itemList;
          this.selectedSpecialist = 'selected';
          return getRegisteredSpecialistDetails(id, itemList).then(
            action(({ itemList, sumPage, sumItem }) => {
              var lstItems = (itemList || []).map(o => ({
                ...o,
                isChecked: true,
                RequestingDate: this.convertDate(o.RequestingDate),
              }));
              this.registeredSpecialistObjs = lstItems;
              this.specialists = lstItems;
              this.specialistLoading = false;
            }),
          );
        } else {
          this.selectedSpecialist = 'All';
          getAllSpecialist({
            CurPage: 1,
            IsSelectionList: true,
            Keyword: '',
          }).then(resp => {
            var lstItems = resp
              ? (resp.itemList || []).map(d => ({ ...d, isChecked: false, RequestingDate: null }))
              : [];
            this.setLstSpecialist(lstItems);
            this.setFieldsValue({
              sumItemSpecialist: resp ? resp.sumItem : 0,
              sumPageSpecialist: resp ? resp.sumPage : 0,
              curPageSpecialist: 1,
            });
          });
        }
      }),
    );
  };

  @action handleSearchSpecialist = () => {
    this.specialistLoading = true;

    if (this.selectedSpecialist === 'selected') {
      setTimeout(
        action(() => {
          this.specialistLoading = false;
        }),
        300,
      );
    } else {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.searchSpecialist,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          var lstSpec = (itemList || []).map(d => ({ ...d, isChecked: false, RequestingDate: null }));
          lstSpec.forEach(el => {
            var obj = this.registeredSpecialistObjs.find(i => i.Id == el.Id);
            if (obj) {
              el.isChecked = true;
              el.RequestingDate = obj.RequestingDate;
            }
          });
          this.specialists = lstSpec;
          this.sumItemSpecialist = sumItem;
          this.sumPageSpecialist = sumPage;
          this.curPageSpecialist = 1;
          this.specialistLoading = false;
        }),
      );
    }
  };

  @action handleSelectChangeSpecialist = value => {
    this.selectedSpecialist = value;
    this.specialistLoading = true;

    if (value === 'All') {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          var lstSpec = (itemList || []).map(d => ({ ...d, RequestingDate: null }));
          lstSpec.forEach(el => {
            var obj = this.registeredSpecialistObjs.find(i => i.Id == el.Id);
            if (obj) {
              el.isChecked = true;
              el.RequestingDate = obj.RequestingDate;
            }
          });
          this.specialists = lstSpec;
          this.sumItemSpecialist = sumItem;
          this.sumPageSpecialist = sumPage;
          this.curPageSpecialist = 1;
          this.specialistLoading = false;
        }),
      );
    } else {
      getRegisteredSpecialists(this.CMInfo.Id)
        .then(({ itemList }) => getRegisteredSpecialistDetails(this.CMInfo.Id, itemList))
        .then(
          action(({ itemList, sumPage, sumItem }) => {
            this.setRegisteredSpecialistObjs(
              (itemList || []).map(o => ({
                ...o,
                isChecked: true,
                RequestingDate: this.convertDate(o.RequestingDate),
              })),
            );
            this.specialistLoading = false;
          }),
        );
    }
  };
}

export default new AddEditStore();
