import './index.scss';

import { Spin } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { Divider, Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import Circle from './CaseProgressCircle';

class CaseProgress extends React.Component {
  state = {
    loading: true,
    toggleViewHistory: false,
    progressList: this.props.progressList,
    history: null,
    prepaymentStatuses: [],
  };

  async componentDidMount() {
    try {
      const { url, body, param, keyName } = this.props;

      const responseSupp =
        url === '/Supplementary/GetSupplementaryLogs'
          ? await customFetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body),
            })
          : null;

      const checkresponseSupp = responseSupp ? responseSupp.itemList.map(checked => checked.Status) : null;

      if (checkresponseSupp) {
        if (checkresponseSupp.indexOf('ServiceBilled') > 1) {
          responseSupp.itemList.push({
            Status: 'Service Paid',
          });
        }
        var progressSupp = this.state.progressList;
        if (checkresponseSupp.indexOf('ClarificationNoFee') === -1) {
          progressSupp = progressSupp.filter(i => i.Key !== 'ClarificationNoFee');
        }
        if (checkresponseSupp.indexOf('ReportCompleted') === -1) {
          progressSupp = progressSupp.filter(i => i.Key !== 'ReportCompleted');
        }
        if (
          checkresponseSupp.indexOf('ClarificationNoFee') === -1 &&
          checkresponseSupp.indexOf('ReportCompleted') === -1
        ) {
          progressSupp.splice(6, 0, {
            Key: '',
            Label: 'Reports Sent to CMs',
            Checked: false,
          });
        }
        this.setState({ progressList: progressSupp });
      }

      const response = param
        ? await customFetch(url, param)
        : await customFetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
          });
      this.setState({ history: response.itemList });
      this.bindPrepaymentStatusesFromApi(response);
      const newProgressList = this.state.progressList.map(item => {
        const matchedStatus = responseSupp
          ? responseSupp.itemList.find(i => item.Key === (i[keyName] || i.Status || i.status))
          : response.itemList.find(i => item.Key === (i[keyName] || i.Status || i.status));
        if (matchedStatus) {
          return {
            ...item,
            Checked: true,
            currentChecked: this.props.currentStatus == item.Key,
            statusDate: matchedStatus.StatusDate || matchedStatus.statusDate,
            createdDate: matchedStatus.CreatedDate || matchedStatus.createdDate,
            personInCharge: matchedStatus.UserName || matchedStatus.UpdatedUserName || matchedStatus.updatedUserName,
          };
        }
        return item;
      });

      this.setState({ progressList: newProgressList });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderProgressList = () => {
    return this.state.progressList.map(({ Label, Checked, currentChecked }, index) => (
      <div key={index} className="status-item">
        <div className={cx('status-box', Checked ? (currentChecked ? `checked` : `checked2`) : `unchecked`)}>
          <div className="status-box-outer" />
          <div className="status-box-inner">
            {Checked && <Icon name="check" className={cx(currentChecked ? `` : `green`)} size="large" />}
          </div>
        </div>
        <span className="status-label">{Label}</span>
      </div>
    ));
  };

  convertPersonInCharge = person => {
    const person_ = person ? person.replace(/^(.+)@(.+)$/g, '$1') : person;
    return person_;
  };

  convertStatusName = status => {
    const statusName = this.state.progressList.find(i => i.Key === status);
    if (statusName) {
      return statusName.Label;
    } else {
      if (status === 'Paperwork Received') return 'Request Sent to Provider';
      return status;
    }
  };

  renderPersonInChargeList = () => {
    const { url } = this.props;
    return this.state.progressList.map(({ Key, personInCharge, Checked }, index) => {
      return (
        <div key={index} className="person-in-charge">
          {Key === 'Service Paid' && url === '/Supplementary/GetSupplementaryLogs'
            ? ''
            : this.convertPersonInCharge(personInCharge) || (Checked && 'N/a')}
        </div>
      );
    });
  };

  renderStatusDateList = () => {
    const { url } = this.props;
    return this.state.progressList.map(({ Key, statusDate, Checked }, index) => (
      <div key={index} className="status-date">
        {Key === 'Service Paid' && url === '/Supplementary/GetSupplementaryLogs'
          ? ''
          : formatDate(statusDate) || (Checked && 'N/a')}
      </div>
    ));
  };

  handleToggleViewHistory = () => {
    this.setState(prev => ({
      ...prev,
      toggleViewHistory: !prev.toggleViewHistory,
    }));
  };

  renderStatusHistory = () => (
    <Table striped className="no-stick-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Status Date</Table.HeaderCell>
          <Table.HeaderCell>Updated At</Table.HeaderCell>
          <Table.HeaderCell>Updated By</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.history.map((log, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>{this.convertStatusName(log.Status || log.StatusName || log.statusName)}</Table.Cell>

            <Table.Cell>
              {log.StatusDate || log.statusDate
                ? moment(formatDate(log.StatusDate || log.statusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              {log.CreatedDate || log.createdDate
                ? moment(formatDate(log.CreatedDate || log.createdDate, true), 'DD MMM YYYY, HH:mm').format(
                    'DD/MM/YYYY @ HH:mm',
                  )
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              {this.convertPersonInCharge(log.UserName || log.UpdatedUserName || log.updatedUserName) || 'N/a'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  bindPrepaymentStatusesFromApi(response) {
    let { prepaymentStatuses = [] } = response;
    prepaymentStatuses = prepaymentStatuses.map(x => {
      x.date = formatDate(x.date);
      return x;
    });
    this.setState({ prepaymentStatuses });
  }

  render() {
    const { loading, toggleViewHistory, prepaymentStatuses } = this.state;
    const { showHistory, showPrepaymentStatuses } = this.props;

    if (loading) {
      return <Spin className="modal-spin" />;
    }

    return (
      <div className="Case-progress">
        <div className="status-list">{this.renderProgressList()}</div>
        <div className="status-details">
          <div className="person-in-charge-list">
            <h4 className="mb-0">Person in charge:</h4>
            <Divider />
            <div className="person-in-charge-list-item">{this.renderPersonInChargeList()}</div>
          </div>
          <div className="status-date-list">
            <h4 className="mb-0">Status date:</h4>
            <Divider />
            <div className="status-date-list-item">{this.renderStatusDateList()}</div>
          </div>
          <Divider />
          {showPrepaymentStatuses && (
            <div className="mt-1">
              <div className="ant-modal-title">Prepayment Status</div>
              <Divider />
              <div className="status-list" style={{ maxWidth: '480px' }}>
                {prepaymentStatuses.map(({ label, checked, date }, i) => (
                  <Circle key={i} checked={checked} label={label} date={date} />
                ))}
              </div>
            </div>
          )}
        </div>
        {showHistory && (
          <div className="Case-progress-status-history">
            <h4 onClick={this.handleToggleViewHistory}>
              View status history
              <Icon name={`angle ${toggleViewHistory ? 'down' : 'right'}`} />
            </h4>
            {toggleViewHistory && this.renderStatusHistory()}
          </div>
        )}
      </div>
    );
  }
}

export default CaseProgress;
