import React, { useState } from 'react';
import { Button, Checkbox, Form, Icon } from 'semantic-ui-react';
import * as api from '@stores/api';
import Modal from '../../shared/Modal';
import ServiceFeeStore from './ServiceFeeStore';
import customFetch from '../../../utils/customFetch';

function ModalCreate({ open, onCancel, modalParams }) {
  const { id } = modalParams;
  const data = ServiceFeeStore.dataServiceGroup;
  const group = data.find(s => s.Id === id);
  const [checked, setChecked] = useState(group ? (group.Status ? 0 : 1) : 0);
  const [serviceGroup, setServiceGroup] = useState(
    id
      ? group
      : {
          Id: '',
          Name: '',
          Status: true,
        },
  );

  const onChange = event => {
    const { name, value } = event.target;
    setServiceGroup({
      ...serviceGroup,
      [name]: value,
    });
  };
  const onChangeCheck = () => {
    setChecked(checked + 1);
    const status = checked % 2 ? true : false;
    setServiceGroup({
      ...serviceGroup,
      Status: status,
    });
  };
  const handleCreateServiceGroup = () => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MAGService/SaveServiceGroup', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ serviceGroup }),
      }).then(respone => {
        if (respone.status === 'success') {
          onCancel();
        }
      });
      await ServiceFeeStore.fetchService({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  const handleEditServiceGroup = () => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MAGService/SaveServiceGroup', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ serviceGroup }),
      }).then(respone => {
        if (respone.status === 'success') {
          onCancel();
        }
      });
      await ServiceFeeStore.fetchService({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          {!api.isOnlySearch() && <Button color="blue" onClick={id ? handleEditServiceGroup() : handleCreateServiceGroup()}>
            <Icon name="save" /> Save
          </Button>}
          <Button color="red" onClick={onCancel}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
      title="Add New Service Group"
    >
      <Form onChange={onChange}>
        <Form.Field>
          <label>Service Group Name</label>
          <input
            placeholder="Service Group Name"
            name="Name"
            value={serviceGroup.Name}
            onChange={v => setServiceGroup({ ...serviceGroup, Name: v.target })}
          />
        </Form.Field>
        <Form.Field>
          <label>Active</label>
          <Checkbox defaultChecked={serviceGroup.Status === true ? true : false} onChange={onChangeCheck} />
        </Form.Field>
      </Form>
    </Modal>
  );
}

export default ModalCreate;
