import React from 'react';
import { Form, Checkbox, DatePicker, Input, Select, Divider, Radio, Icon, Dropdown, Menu, Collapse } from 'antd';
import { observer } from 'mobx-react';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import { toJS } from 'mobx';
import moment from 'moment';

import store from './store';
import { CONSTANTS } from './type';
import FieldUpload from './FieldUpload';

import * as api from '@stores/api';
import customFetch from '@utils/customFetch';
import { Header } from '../../shared/Collapse/Panel';
import { renderDate, commonInlineStyles } from '../Shared';
import cx from 'classnames';
import { resendPaperworkReadiness } from './service';

@observer
class CaseDocumentsForm extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );

  onclickResendMailCMDoctorRequest = () => {
    if (!store.clinicalNoteInfo.EmailCNDocReq2 && !store.clinicalNoteInfo.EmailCNDocReq1) {
      store.showNotification('Error', 'At least email of one CR Provider must be updated to send request.');
      return;
    }
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend Letter of Authority?',
      onOk: () => {
        customFetch('/ClinicalNote/ResendMailCMDoctorRequestAsync', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: store.clinicalNoteInfo.Id,
            email1: store.clinicalNoteInfo.EmailCNDocReq1,
            email2: store.clinicalNoteInfo.EmailCNDocReq2,
          }),
        }).then(res => {
          if (res.status === 'success') {
            store.showNotification('Success', 'Resend LOA to CR Provider', 'success');
            store.handleChangeCRValue({
              SentDateLoAServiceProvider: moment(),
              ExpectedCompletionDate: moment().add(7, 'days'),
            });
          } else {
            store.showNotification('Error', 'Resend LOA to CR Provider', 'error');
          }
        });
      },
    });
  };

  onclickResendPaperworkReadiness = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend Paperwork readiness to Staff?',
      onOk: () => {
        resendPaperworkReadiness(store.clinicalNoteInfo.Id).then(res => {
          if (res.status === 'success') {
            store.showNotification('Success', 'Resend paperwork readiness', 'success');
            store.handleChangeCRValue('SentDateNotifyPaperworkReadinessToStaff', moment());
            store.handleChangeCRValue('SentByNotifyPaperworkReadinessToStaff', res.sentBy);
          } else {
            store.showNotification('Error', 'Resend paperwork readiness', 'error');
          }
        });
      },
    });
  };

  handlePrintLOA = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    if (!!itemModel.Id) {
      window.open(`/ClinicalNote/MAGLOATemplatePdf?clinicalNoteId=${itemModel.Id}&printNow=true`, '_blank');
    } else {
      window.open(`/ClinicalNote/MAGLOATemplatePdf?clinicalNoteId=0&printNow=true`, '_blank');
    }
  };

  onclickResendLOATemplate = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend LOA template to Booking CM?',
      onOk: () => {
        customFetch('/ClinicalNote/ResendLOATemplateAsyncAsync', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.clinicalNoteInfo.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.showNotification('Success', 'Resend LOA template to Booking CM', 'success');
            store.handleChangeCRValue({
              SendLOATemplateSendDate: moment(),
            });
          } else {
            store.showNotification('Error', 'Resend LOA template to Booking CM');
          }
        });
      },
    });
  };

  SendEmailTemplate109 = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    if (!itemModel.ExpectedCompletionDate) {
      store.showNotification('Error', 'You need to update expected completed date first');
    } else {
      return store.toggleModalConfirm(true, {
        message: 'Do you want to send completion update to CMs?',
        onOk: () =>
          customFetch('/ClinicalNote/SendEmailTemplate109', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: itemModel.Id }),
          }).then(res => {
            if (res.status === 'success') {
              store.handleChangeCRValue({ SendCompletionUpdateToCMDate: moment() });
              store.handleChangeCRValue({ SendCompletionUpdateToCMBy: res.UserSent });
              store.showNotification('Success', 'Send completion update to CMs', 'success');
            } else {
              store.showNotification('Error', 'Send completion update to CMs', 'error');
            }
          }),
      });
    }
  };
  uploadOriginalCRFileComplete = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    const attachmentList = store.renderAttachment(itemModel.Attachments, 1);
    const PaymentRequestedAttachment = store.renderAttachment(itemModel.Attachments, 2);
    store.setFieldsValue({
      ClinicalNotesReceivedAttachmentUploadNotSaved: true,
    });
    if (!!attachmentList.length && !PaymentRequestedAttachment.length) {
      store.toggleModalConfirm(true, {
        message: 'Please contact CR Provider to acquire the service invoice at the soonest.',
        hideWarningIcon: true,
      });
    }

    store.handleChangeCRValue({ ClinicalNotesReceivedDate: moment() });
    if (!!attachmentList.length && !itemModel.ClinicalNotesReceived) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to email CR files to CMs?',
        onOk: () => {
          store.handleChangeCRValue({ ClinicalNotesReceived: true });
          // if (!store.deliveryContactFirstSelected?.Email && !store.deliveryContactSecondSelected?.Email) {
          //   store.showNotification('Error', 'At least one Report Receiving Contact needs to be completed');
          //   store.handleChangeCRValue({ ClinicalNotesReceived: false, IsAllowCMsViewReports: false });
          // } else {
          // }
        },
      });
    }
  };

  uploadLOAComplete = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    const LOAAttachment = store.renderAttachment(itemModel.Attachments, 5);
    store.handleChangeCRValue({ PaperworkReceivedDate: moment() });
    store.handleChangeCRValue({ IsUpdatedPaperwork: true });
    if (LOAAttachment?.length > 0 && !(itemModel.WiroIlars && !itemModel.IROFundingApproved) && !api.isCaseManager()) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to send paperwork to CR Provider ?',
        onOk: () => {
          store.handleChangeCRValue({ IsPaperworkReceived: true, EmailLoAServiceProvider: true });
        },
      });
    }
  };

  MergeFiles = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    const attachmentList = store.renderAttachment(itemModel.Attachments, 1);
    if (!attachmentList.length) {
      store.showNotification('Error', 'No attached files to merge.');
      return;
    }
    if (store.ClinicalNotesReceivedAttachmentUploadNotSaved) {
      store.showNotification('Error', 'Attached CR files must be saved before they can be merged.');
      return;
    }
    store.toggleModalConfirm(true, {
      message: 'Merging files may take some time. Do you want to continue?',
      onOk: () => {
        customFetch('/ClinicalNote/MergeCRFiles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            clinicalNoteId: itemModel.Id,
            type: 'AttachedCRFiles',
          }),
        }).then(res => {
          const data = res;
          switch (data.status) {
            case 'success': {
              var lstMergeFiles = [];
              data.FileAttachs.map(i => {
                if (i.IsMergeFile) {
                  lstMergeFiles = [
                    ...lstMergeFiles,
                    {
                      CreatedDate: i.CreatedDate,
                      FileName: i.FileName,
                      IsMergeFile: i.IsMergeFile,
                      IsMerged: i.IsMerged,
                      IsSendEmail: i.IsSendEmail,
                      NumberOfPages: i.NumberOfPages,
                      ReportType: i.ReportType,
                      Title: i.Title,
                      Id: i.Id,
                    },
                  ];
                }
                return i;
              });
              const crAttachment = itemModel.Attachments.filter(i => i.ReportType !== 7);
              const newAttachments = [...crAttachment, ...lstMergeFiles].filter((i, idx) => ({ ...i, key: idx }));
              store.handleChangeCRValue({ Attachments: newAttachments });
              store.showNotification('Success', 'CR files have been merged successfully', 'success');
              break;
            }
            default:
              store.showNotification(
                'Error',
                'Error occured while saving data. Please report this to system administrator and try again later.',
              );
              break;
          }
        });
      },
    });
  };
  handleResendCRFiles = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    if (store.deliveryContactFirstSelected || store.deliveryContactSecondSelected) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to resend CR files to CMs?',
        onOk: () => {
          customFetch('/ClinicalNote/ResendReports', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: itemModel.Id }),
          }).then(res => {
            if (res.status === 'success') {
              store.handleChangeCRValue({
                EmailToCMsViewReportRecent: moment(res.ResendDatetime),
              });
              store.handleChangeCRValue({
                EmailToCMsViewReportUserBy: res.EmailToCMsViewReportUserBy,
              });
              store.showNotification('Success', 'Resend report', 'success');
            } else {
              store.showNotification('Error', 'Resend report');
            }
          });
        },
      });
    } else {
      store.showNotification('Error', 'At least one Report Delivery Contact needs to be completed');
    }
  };
  render() {
    const {
      clinicalNoteInfo,
      crProviderFirst,
      crProviderSecond,
      crProviderFirstSelected,
      crProviderSecondSelected,
      clinicalNoteRequest,
    } = store;
    const {
      handleChangeFieldCheckbox,
      handleChangeFieldSelect,
      itemToArray,
      handleChangeFieldInput,
      getMessageError,
      handleChangeDatePicker,
      handleSearch,
      handleScroll,
      handleChangeFieldRadio,
      handleOpenAddEdit,
    } = store;
    const itemModel = toJS(clinicalNoteInfo);

    return (
      <Form name="case-documents" className="grid-style">
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <Header
                title="Provider of Clinical Records"
                isChecked={itemModel.IsEmail1ForSendingCN || itemModel.IsEmail2ForSendingCN}
                text={
                  itemModel.IsEmail1ForSendingCN
                    ? crProviderFirstSelected?.Name ?? ''
                    : itemModel.IsEmail2ForSendingCN
                    ? crProviderSecondSelected
                      ? `${crProviderSecondSelected.Title} ${crProviderSecondSelected.FullName}`
                      : ''
                    : ''
                }
                isRequired={false}
              />
            }
          >
            <Form.Item
              label={
                <React.Fragment>
                  CR Provider (Clinic)
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'ClinicId')}>Add Clinic</Menu.Item>
                          {!!crProviderFirstSelected && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'ClinicId')}>Edit Clinic</Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                  {api.isCaseManager() && (
                    <button className="field-actions" onClick={() => handleOpenAddEdit('add', 'ClinicId')}>
                      Add New
                    </button>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={!!itemModel.ClinicId ? itemModel.ClinicId : undefined}
                onChange={handleChangeFieldSelect('ClinicId')}
                disabled={itemModel.IsLocked}
                onSearch={handleSearch('crProviderFirstSearchOpt')}
                onPopupScroll={handleScroll('crProviderFirstSearchOpt')}
                showSearch
                filterOption="children"
                allowClear
              >
                {itemToArray(crProviderFirstSelected, crProviderFirst).map(i => (
                  <Select.Option value={i.ID} key={i.ID}>
                    {i.ID} - {i.Name} - {i.City}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {itemModel.DoctorId && (
              <Form.Item
                label={
                  <React.Fragment>
                    CR Provider 2 (Specialist){' '}
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => handleOpenAddEdit('add', 'DoctorId')}>Add Specialist</Menu.Item>
                            {!!crProviderSecondSelected && (
                              <Menu.Item onClick={() => handleOpenAddEdit('edit', 'DoctorId')}>
                                Edit Specialist
                              </Menu.Item>
                            )}
                          </Menu>
                        }
                      >
                        <button className="field-actions">
                          Add/Edit <Icon type="down" />
                        </button>
                      </Dropdown>
                    )}
                  </React.Fragment>
                }
              >
                <Select
                  size="large"
                  value={!!itemModel.DoctorId ? itemModel.DoctorId : undefined}
                  onChange={handleChangeFieldSelect('DoctorId')}
                  disabled={itemModel.IsLocked}
                  onSearch={handleSearch('crProviderSecondSearchOpt')}
                  onPopupScroll={handleScroll('crProviderSecondSearchOpt')}
                  showSearch
                  filterOption="children"
                  allowClear
                >
                  {itemToArray(crProviderSecondSelected, crProviderSecond).map(i => (
                    <Select.Option value={i.Id} key={i.Id}>
                      {i.Id} - {i.Title} {i.FullName}
                      {api.isAdminOrMagStaffOrAccounting() ? ' - ' + i.BusinessEmail : ''}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Email for Sending CR Request"
              validateStatus={getMessageError('EmailCNDocReq1', 'field') ? 'error' : null}
              help={getMessageError('EmailCNDocReq1', 'field') ? getMessageError('EmailCNDocReq1', 'field') : ''}
            >
              <Input
                size="large"
                value={itemModel.EmailCNDocReq1}
                onChange={handleChangeFieldInput('EmailCNDocReq1')}
                disabled={itemModel.IsLocked}
              />
            </Form.Item>
            {itemModel.DoctorId && (
              <Form.Item
                style={api.isAdminOrMagStaffOrAccounting() ? { display: 'block' } : { display: 'none' }}
                label="Email 2 for Sending CR Request"
                validateStatus={getMessageError('EmailCNDocReq2', 'field') ? 'error' : null}
                help={getMessageError('EmailCNDocReq2', 'field') ? getMessageError('EmailCNDocReq2', 'field') : ''}
              >
                <Input
                  size="large"
                  value={itemModel.EmailCNDocReq2}
                  onChange={handleChangeFieldInput('EmailCNDocReq2')}
                  disabled={itemModel.IsLocked}
                />
              </Form.Item>
            )}
            <Form.Item
              style={!api.isAdminOrMagStaffOrAccounting() ? { display: 'block' } : { display: 'none' }}
            ></Form.Item>
            {itemModel.DoctorId && (
              <>
                {' '}
                <div className="checkbox-field" style={commonInlineStyles.marginTop_10}>
                  <Checkbox
                    onChange={handleChangeFieldCheckbox('IsEmail1ForSendingCN')}
                    checked={itemModel.IsEmail1ForSendingCN}
                    disabled={itemModel.IsLocked}
                  >
                    Sending Request to This Provider
                  </Checkbox>
                </div>
                <div className="checkbox-field" style={commonInlineStyles.marginTop_10}>
                  <Checkbox
                    onChange={handleChangeFieldCheckbox('IsEmail2ForSendingCN')}
                    checked={itemModel.IsEmail2ForSendingCN}
                    disabled={itemModel.IsLocked}
                  >
                    Sending Request to This Provider
                  </Checkbox>
                </div>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <Header
                title="Paperwork"
                isChecked={itemModel.IsPaperworkReceived}
                text="Paperwork Sent"
                date={itemModel.RequestSentToGpDate}
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
              <Checkbox
                disabled={
                  !itemModel.RequestReceived ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (itemModel.WiroIlars && !itemModel.IROFundingApproved)
                }
                onChange={handleChangeFieldCheckbox('IsPaperworkReceived')}
                checked={itemModel.IsPaperworkReceived}
                className="checkbox-session"
              >
                Paperwork Sent to CR Provider
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
              <>
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 2
                      <button className="field-actions" onClick={store.openModalHistory}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    optionFilterProp="children"
                    value={store.checkStaffIsExist(itemModel.PICRequestSentProvider) || undefined}
                    onChange={handleChangeFieldSelect('PICRequestSentProvider')}
                    showSearch
                  >
                    {store.staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div />
              </>
            )}
            <Form.Item
              label="Paperwork Received Date"
              // required={itemModel.IsPaperworkReceived}
              // validateStatus={getMessageError('PaperworkReceivedDate', 'field') ? 'error' : null}
              // help={
              //   getMessageError('PaperworkReceivedDate', 'field')
              //     ? getMessageError('PaperworkReceivedDate', 'field')
              //     : ''
              // }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  // store.oldPaperwordReceived ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
                value={renderDate(itemModel.PaperworkReceivedDate)}
                onChange={handleChangeDatePicker('PaperworkReceivedDate')}
              />
            </Form.Item>
            <Form.Item
              label="Paperwork Sent Date"
              // required={itemModel.IsPaperworkReceived}
              // validateStatus={getMessageError('RequestSentToGpDate', 'field') ? 'error' : null}
              // help={
              //   getMessageError('RequestSentToGpDate', 'field') ? getMessageError('RequestSentToGpDate', 'field') : ''
              // }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                // disabled={
                //   !api.isAdminOrMagStaffOrAccounting() ||
                //   (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                //     itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                // }
                disabled
                value={renderDate(itemModel.RequestSentToGpDate)}
                onChange={handleChangeDatePicker('RequestSentToGpDate')}
              />
            </Form.Item>
            <div
              className={cx('checkbox-field', {
                'fluid-field': !store.LOATemplate.IsDisplay,
              })}
              style={commonInlineStyles.marginTop10}
            >
              <Checkbox
                checked={itemModel.IsSendLOATemplate}
                onChange={handleChangeFieldCheckbox('IsSendLOATemplate')}
                disabled={
                  itemModel.IsLocked ||
                  (itemModel.IsSendLOATemplate && !!itemModel.SendLOATemplateSendDate) ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
              >
                Send LOA template to Booking CM{' '}
                {api.isAdminOrMagStaffOrAccounting() &&
                  this.renderPopup(
                    "LOA template will be sent to booking CM to acquire patient's authorisation (MAIL-35)",
                  )}
              </Checkbox>
              {itemModel.SendLOATemplateSendDate && (
                <>
                  <span className="field-actions">
                    {itemModel.SendLOATemplateSendDate
                      ? renderDate(itemModel.SendLOATemplateSendDate, false, true)
                      : ''}
                    {itemModel.SendLOATemplateByUser
                      ? ` - By ${store.renderStaffName(itemModel.SendLOATemplateByUser)}`
                      : ''}
                  </span>
                  <Button
                    className="blue"
                    disabled={!itemModel.IsSendLOATemplate && !itemModel.SendLOATemplateSendDate}
                    onClick={this.onclickResendLOATemplate}
                  >
                    Resend
                  </Button>
                </>
              )}
              <br />
              <button className="field-actions" onClick={() => this.handlePrintLOA()}>
                View Our LOA template <IconSemantic name="print" />
              </button>
            </div>
            {store.LOATemplate.IsDisplay && (
              <Form.Item
                label={
                  <span>
                    <span>CR Provider's LOA template</span>
                    <span>{store.LOATemplate.FileTitle}</span>
                  </span>
                }
              ></Form.Item>
            )}
            <FieldUpload
              type={5}
              label={<>Attach Letter of Authority (LOA) {this.renderPopup('Only one file can be attached')} </>}
              disabled={
                (itemModel.CaseStatus === CONSTANTS.CaseStatus.ServiceBilled && !api.isAdminOrMagStaffOrAccounting()) ||
                (api.isCaseManager() &&
                  (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                    itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
              }
              disabledDelete={
                (itemModel.CaseStatus === CONSTANTS.CaseStatus.ServiceBilled && !api.isAdminOrMagStaffOrAccounting()) ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                (api.isCaseManager() &&
                  (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                    itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
              }
              option={{
                maxFileSize: '100MB',
                allowedExtensions: 'application/pdf',
              }}
              uploadTitle={'PDF file only'}
              multiple={false}
              functionCallback={this.uploadLOAComplete}
            />
            <FieldUpload
              type={0}
              label="Attach Other Documents"
              multiple
              disabled={
                (store.oldPaperwordReceived && !api.isAdminOrMagStaffOrAccounting()) ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                (api.isCaseManager() &&
                  (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                    itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
              }
              disabledDelete={
                (store.oldPaperwordReceived && !api.isAdminOrMagStaffOrAccounting()) ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                (api.isCaseManager() &&
                  (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                    itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
              }
            />
            <Divider className="fluid-field" />
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={itemModel.NotifyPaperworkReadinessToStaff}
                disabled={
                  //itemModel.IsLocked ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                  (!!itemModel.SentDateNotifyPaperworkReadinessToStaff && itemModel.NotifyPaperworkReadinessToStaff) //||
                  // (itemModel.WiroIlars && !itemModel.IROFundingApproved)
                }
                onChange={handleChangeFieldCheckbox('NotifyPaperworkReadinessToStaff')}
              >
                <h4 style={commonInlineStyles.inlineBlock}>Send Paperwork Readiness to MLP Staff</h4>
                {this.renderPopup(
                  'A notice will be sent to staff to review paperwork before sending to specialist (MAIL-PAPERWORK-READINESS)',
                )}
                <span className="field-actions">
                  {itemModel.SentDateNotifyPaperworkReadinessToStaff
                    ? `${renderDate(itemModel.SentDateNotifyPaperworkReadinessToStaff, false, true)}`
                    : ''}
                  {itemModel.SentByNotifyPaperworkReadinessToStaff
                    ? ` - By ${store.renderStaffName(itemModel.SentByNotifyPaperworkReadinessToStaff)}`
                    : ''}
                </span>
              </Checkbox>
              {itemModel.SentDateNotifyPaperworkReadinessToStaff && (
                <Button
                  className="blue"
                  disabled={
                    !itemModel.NotifyPaperworkReadinessToStaff ||
                    (api.isCaseManager() &&
                      (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                        itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
                  }
                  onClick={this.onclickResendPaperworkReadiness}
                >
                  Resend Paperwork Readiness
                </Button>
              )}
            </div>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={itemModel.EmailLoAServiceProvider}
                disabled={
                  itemModel.IsLocked ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                  (!!itemModel.SentDateLoAServiceProvider && itemModel.EmailLoAServiceProvider) ||
                  (itemModel.WiroIlars && !itemModel.IROFundingApproved) ||
                  api.isCaseManager()
                }
                onChange={handleChangeFieldCheckbox('EmailLoAServiceProvider')}
              >
                <h4 style={commonInlineStyles.inlineBlock}>Email LOA to CR Provider</h4>
                {this.renderPopup(
                  'Retrieval request and LOA will be sent to CR provider (Mail-63, Mail-63A (Resend & Update)), CR forwarding confirmation will be sent to CM (Mail-35A)',
                )}
                <span className="field-actions">
                  {itemModel.SentDateLoAServiceProvider
                    ? `${renderDate(itemModel.SentDateLoAServiceProvider, false, true)}`
                    : ''}
                  {itemModel.SentLoAServiceProviderBy
                    ? ` - By ${store.renderStaffName(itemModel.SentLoAServiceProviderBy)}`
                    : ''}
                </span>
              </Checkbox>
              {itemModel.SentDateLoAServiceProvider && (
                <Button
                  className="blue"
                  disabled={
                    !itemModel.IsPaperworkReceived ||
                    !itemModel.EmailLoAServiceProvider ||
                    (api.isCaseManager() &&
                      (itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledNoCharge ||
                        itemModel.CaseStatus === CONSTANTS.CancellationStatus.CancelledWithFee))
                  }
                  onClick={this.onclickResendMailCMDoctorRequest}
                >
                  Resend LOA
                </Button>
              )}
            </div>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Send Paperwork by Other Methods" className="fluid-field">
                <Checkbox.Group
                  value={itemModel.RequestSendToGpMethods}
                  onChange={handleChangeFieldSelect('RequestSendToGpMethods')}
                >
                  {clinicalNoteRequest.map(i => (
                    <Checkbox value={i.id} key={i.id}>
                      {i.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
            <Form.Item
              label={
                <React.Fragment>
                  Expected Completion Date{' '}
                  {this.renderPopup('Expected completion of CR retrieval will be sent to booking CMs (Mail-109)')}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <button className="field-actions" onClick={this.SendEmailTemplate109}>
                      Send Completion Update to CMs
                    </button>
                  )}
                  <span className="field-actions">
                    {itemModel.SendCompletionUpdateToCMDate
                      ? `: ${renderDate(itemModel.SendCompletionUpdateToCMDate, false, true)}`
                      : ''}
                    {itemModel.SendCompletionUpdateToCMBy
                      ? ` - By ${store.renderStaffName(itemModel.SendCompletionUpdateToCMBy)}`
                      : ''}
                  </span>
                </React.Fragment>
              }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={renderDate(itemModel.ExpectedCompletionDate)}
                onChange={handleChangeDatePicker('ExpectedCompletionDate')}
                disabled={
                  (store.oldPaperwordReceived && !api.isAdminOrMagStaffOrAccounting()) ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
              />
            </Form.Item>
            <Form.Item label="Requested Completion Date">
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={renderDate(itemModel.RequestExpectedCompletionDate)}
                onChange={handleChangeDatePicker('RequestExpectedCompletionDate')}
                disabled={
                  (store.oldPaperwordReceived && !api.isAdminOrMagStaffOrAccounting()) ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <Header
                title="Completion of CR Retrieval"
                isChecked={itemModel.ClinicalNotesReceived}
                text="Sent to CMs"
                date={itemModel.ClinicalNotesSentDate}
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
              <Checkbox
                disabled={
                  itemModel.IsLocked ||
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                  !itemModel.IsPaperworkReceived
                }
                onChange={handleChangeFieldCheckbox('ClinicalNotesReceived')}
                checked={itemModel.ClinicalNotesReceived}
                className="checkbox-session"
              >
                CR Files Sent to CMs
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
              <Form.Item
                label={
                  <React.Fragment>
                    PIC 3
                    <button className="field-actions" onClick={store.openModalHistory}>
                      Recent Changes
                    </button>
                  </React.Fragment>
                }
              >
                <Select
                  size="large"
                  optionFilterProp="children"
                  value={store.checkStaffIsExist(itemModel.PICReceivedFromProvider) || undefined}
                  onChange={handleChangeFieldSelect('PICReceivedFromProvider')}
                  showSearch
                >
                  {store.staffs.map(({ Id, FullName }) => (
                    <Select.Option key={Id} value={Id}>
                      {FullName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item label="CR Files Received by">
                  <Radio.Group
                    disabled={
                      itemModel.IsLocked ||
                      (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                        itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                    }
                    value={itemModel.RequestReceivedThrough}
                    onChange={handleChangeFieldRadio('RequestReceivedThrough')}
                  >
                    <Radio value={0}>Email or electronic medium ($65 flat rate)</Radio>
                    <br />
                    <Radio value={1}>Hard copy (postage) ($38, +$1.4/pp from 33rd page onwards)</Radio>
                    <br />
                    <Radio value={2}>Fax</Radio>
                  </Radio.Group>
                </Form.Item>
                {!itemModel.Id && <div />}
              </>
            )}
            <Form.Item label="CR Files Received Date">
              <DatePicker
                size="large"
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
                format="DD/MM/YYYY"
                value={renderDate(itemModel.ClinicalNotesReceivedDate)}
                onChange={handleChangeDatePicker('ClinicalNotesReceivedDate')}
              />
            </Form.Item>
            <Form.Item
              label="CR File Sent Date"
              required={itemModel.ClinicalNotesReceived}
              validateStatus={itemModel.ClinicalNotesReceived && !itemModel.ClinicalNotesSentDate ? 'error' : null}
              help={itemModel.ClinicalNotesReceived && !itemModel.ClinicalNotesSentDate ? 'This field is required' : ''}
            >
              <DatePicker
                size="large"
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
                format="DD/MM/YYYY"
                value={renderDate(itemModel.ClinicalNotesSentDate)}
                onChange={handleChangeDatePicker('ClinicalNotesSentDate')}
              />
            </Form.Item>
            <Divider className="fluid-field" />
            <FieldUpload
              type={1}
              label={
                <>
                  Original CR Files{' '}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <button className="field-actions" onClick={this.MergeFiles}>
                      Merge files{' '}
                      {this.renderPopup(
                        'Only file types: .doc|.docx|.rtf|.tiff|.jpg|.jpeg|.png|.htm|.html|.txt|.pdf can be merged and converted to PDF file. Check for non-merged files. Every 30 files will be merged into 1 converted file.',
                      )}
                    </button>
                  )}
                </>
              }
              multiple
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                !api.isAdminOrMagStaffOrAccounting()
              }
              disabledDelete={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                !api.isAdminOrMagStaffOrAccounting()
              }
              functionCallback={this.uploadOriginalCRFileComplete}
            />
            <FieldUpload
              type={7}
              label="Merged Files"
              multiple
              disabled
              disabledDelete={!api.isAdminOrMagStaffOrAccounting()}
              isViewMergeFile={true}
            />
            <Form.Item
              label={api.isAdminOrMagStaffOrAccounting() ? 'Or Share Download Link' : 'Or View Files via Shared Links'}
              className="fluid-field"
            >
              <Input.TextArea
                rows={3}
                value={itemModel.CRFilesExLink}
                onChange={handleChangeFieldInput('CRFilesExLink')}
                disabled={itemModel.IsLocked}
              />
            </Form.Item>
            {itemModel.CRFilesExLink && (
              <Form.Item label="Download Link Password">
                <Input
                  rows={3}
                  value={itemModel.PasswordCRFilesExLink}
                  onChange={handleChangeFieldInput('PasswordCRFilesExLink')}
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                  }
                />
              </Form.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <div className="checkbox-field">
                <Checkbox
                  style={commonInlineStyles.marginTop10}
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold) ||
                    !!itemModel.EmailToCMsViewReportRecent
                  }
                  checked={itemModel.IsAllowCMsViewReports}
                  onChange={handleChangeFieldCheckbox('IsAllowCMsViewReports')}
                  placeholder="Copy links here. One line for"
                >
                  <h4 style={commonInlineStyles.inlineBlock}>Email CR Files to CMs</h4>
                  {this.renderPopup(
                    'Retrieved CR files will be sent to CMs (only download links) (MAIL-98, MAIL-99 (Resend), MAIL-98-ILARS, MAIL-99-ILARS)',
                  )}
                </Checkbox>
                {!!itemModel.EmailToCMsViewReportRecent && (
                  <>
                    <span className="field-actions">
                      {itemModel.EmailToCMsViewReportRecent
                        ? renderDate(itemModel.EmailToCMsViewReportRecent, false, true)
                        : ''}
                      {itemModel.EmailToCMsViewReportUserBy ? ` - By ${itemModel.EmailToCMsViewReportUserBy}` : ''}
                    </span>
                    <Button
                      className="blue"
                      disabled={
                        !itemModel.ClinicalNotesReceived ||
                        !itemModel.IsAllowCMsViewReports ||
                        !itemModel.EmailToCMsViewReportRecent
                      }
                      onClick={this.handleResendCRFiles}
                    >
                      Resend CR Files
                    </Button>
                  </>
                )}
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
        {itemModel.RequestReceived && api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Request Comment" className="fluid-field">
            <Input.TextArea
              rows={3}
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
              onChange={handleChangeFieldInput('RequestReceivedComment')}
              value={itemModel.RequestReceivedComment}
            />
          </Form.Item>
        )}
        {/* <Form.Item 
          label={<React.Fragment>
            Specialist Notes
            {this.renderPopup(
              'Specialist can view/add notes in this section',
            )}
          </React.Fragment>}
          className="fluid-field">
            <Input.TextArea
              rows={3}
              disabled={api.isCaseManager() || api.isTypist()}
              onChange={handleChangeFieldInput('SpecialistNotes')}
              value={itemModel.SpecialistNotes}
            />
          </Form.Item> */}
      </Form>
    );
  }
}

export default CaseDocumentsForm;
