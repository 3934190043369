import { observer } from 'mobx-react';
import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import customFetch from '../../../utils/customFetch';
import DocAndConsStore from './DocAndConsStore';

const roles = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'magstaff', text: 'MAG Staff', value: 'magstaff' },
  { key: 'casemng', text: 'Case Manager', value: 'casemng' },
  { key: 'doctor', text: 'Doctor', value: 'doctor' },
  { key: 'accounting', text: 'Accounting', value: 'accounting' },
];
@observer
class ModalEditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: DocAndConsStore.dataUser.User.UserName,
      Email: DocAndConsStore.dataUser.User.Email,
      Roles: DocAndConsStore.dataUser.User.Roles.map(m => m),
      FirstName: DocAndConsStore.dataUser.User.FirstName,
      LastName: DocAndConsStore.dataUser.User.LastName,
      Id: DocAndConsStore.dataUser.User.Id,
      errorUserName: '',
      errorEmail: '',
    };
  }

  handleOnChangeDropDown = (e, { value }) => {
    this.setState({ ...this.state, Roles: value });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  handleUpdateUserName = async () => {
    DocAndConsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Manager/Save', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          modUserParam: {
            ...this.state,
            UserName: this.state.UserName,
            Email: this.state.Email,
            Roles: this.state.Roles.map(i => i),
          },
        }),
      }).then(respone => {
        if (respone.status === 'success') {
          this.props.onCancel();
        }
      });
      await DocAndConsStore.fetchMain({}, true);
    } finally {
      DocAndConsStore.setFieldsValue({ loading: false });
    }
  };

  handleOnChangeUserName = (e, { value }) => {
    if (value) {
      this.setState({ ...this.state, UserName: value });
      this.setState({ errorUserName: '' });
    } else {
      this.setState({ ...this.state, UserName: value });
      this.setState({
        errorUserName: `Please enter user name at least 6 character.`,
      });
    }
  };

  handleOnChangeEmail = (e, { value }) => {
    if (value) {
      this.setState({ ...this.state, Email: value });
      this.setState({ errorEmail: '' });
    } else {
      this.setState({ ...this.state, Email: value });
      this.setState({
        errorEmail: `Please enter user name at least 6 character.`,
      });
    }
  };

  render() {
    const { open } = DocAndConsStore;
    const { UserName, Email } = this.state;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={600}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleUpdateUserName}>
                <Icon name="save" /> Save
              </Button>
              <Button color="red" onClick={this.onCancel}>
                <Icon name="close" /> Close
              </Button>
            </React.Fragment>
          }
          title="User Info"
        >
          <Form>
            <Form.Field
              required
              control={Input}
              label="UserName"
              fluid
              error={
                this.state.errorUserName
                  ? {
                      content: this.state.errorUserName,
                    }
                  : null
              }
              placeholder="Item Code"
              value={UserName}
              onChange={this.handleOnChangeUserName}
            />
            <Form.Field
              required
              control={Input}
              label="Email"
              fluid
              error={
                this.state.errorEmail
                  ? {
                      content: this.state.errorEmail,
                    }
                  : null
              }
              placeholder="Email"
              value={Email}
              onChange={this.handleOnChangeEmail}
            />
            <Form.Dropdown
              required
              error={
                this.state.Roles.length === 0
                  ? {
                      content: 'Please select at least one role for this user',
                    }
                  : null
              }
              fluid
              label="Role"
              placeholder="Role"
              id="form-input-first-name"
              multiple
              search
              selection
              value={this.state.Roles.map(role => role)}
              onChange={this.handleOnChangeDropDown}
              options={roles}
            />
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditUser;
