import React from 'react';
import { observer } from 'mobx-react';
import { Form, Segment } from 'semantic-ui-react';

import { convertMobileNumber } from '@utils/functions';
import store from './store';

@observer
class FormRequestFromService extends React.Component {
  formatNumber = value => {
    return !!value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  };

  renderMessage = () => {
    const { data } = store;

    if (data.IsDisbursementFunding) {
      return `Your company has been approved for same-day disbursement funding of $${this.formatNumber(
        (data.DisbursmentFundingAmount || 0).toString(),
      )} from MAG Capital. Click "Submit Request" to access funding for this report.`;
    } else if (data.PreApprovedDisbursementFunding) {
      return `Your company has been pre-approved for same-day disbursement funding of $${this.formatNumber(
        (data.PreApprovedDisbursementFundingAmount || 0).toString(),
      )} from MAG Capital. Click "Submit Request" to access funding for this report.`;
    } else {
      return 'Kawaconn Capital offers same-day disbursement funding. For more information, click "Submit Request" below.';
    }
  };

  render() {
    const { handleChangeValue } = store;

    return (
      <>
        <h3>{this.renderMessage()}</h3>
        <Form>
          <Segment>
            <Form.Group widths="equal">
              <Form.Input
                label="Additional Information"
                value={store.AdditionalInfo || ''}
                placeholder="Additional Information"
                name="AdditionalInfo"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={!store.legalCompanyName ? 'This field is required' : null}
                label="Company name"
                value={store.legalCompanyName || ''}
                placeholder="Company name"
                name="legalCompanyName"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={!store.contactName ? 'This field is required' : null}
                label="Contact Full Name"
                value={store.contactName || ''}
                placeholder="Contact Full Name"
                name="contactName"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                required
                error={!store.position ? 'This field is required' : null}
                label="Position"
                value={store.position || ''}
                placeholder="Position"
                name="position"
                options={(store.positions || []).map(i => ({ text: i.Label, value: i.Id }))}
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={
                  store.error.email
                    ? 'Please enter a valid email address'
                    : !store.email
                    ? 'This field is required'
                    : null
                }
                label="Email"
                value={store.email || ''}
                placeholder="Email"
                name="email"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={
                  store.error.phone
                    ? 'Must be less than or equal to 10 digits'
                    : !store.phone
                    ? 'This field is required'
                    : null
                }
                label="Phone"
                value={convertMobileNumber(store.phone) || ''}
                placeholder="Phone"
                name="phone"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Mobile"
                value={convertMobileNumber(store.mobile) || ''}
                error={store.error.mobile ? 'Must be less than or equal to 10 digits' : null}
                placeholder="Mobile"
                name="mobile"
                onChange={handleChangeValue}
              />
            </Form.Group>
          </Segment>
        </Form>
      </>
    );
  }
}
export default FormRequestFromService;
