import React from 'react';
import { Form, DatePicker, Select, Divider, Input, Checkbox } from 'antd';

class ClaimDetailsForm extends React.Component {
  render() {
    return (
      <Form name="action-required">
        <Form.Item label="Request Date">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Booking Client</h3>
        <Form.Item
          required
          label={
            <React.Fragment>
              Booking Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          label={
            <React.Fragment>
              Booking CM / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Sub-Booking CM / Solicitor">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying CM / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you originally hear about us?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you hear about MAG for todays booking?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="What else could we do to improve our service offering to you?" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Claimant Details</h3>
        <Form.Item
          required
          label={
            <React.Fragment>
              Claimant
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Claimant Title">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Claimant Name">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Date of Birth">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Claimant Email">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Mobile phone">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Reference/Claim No" required>
          <Input size="large" />
        </Form.Item>
        <Form.Item required label="Type of Claim">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Date of Injury">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Injury Description" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Is this IRO/ILARS</Checkbox>
        </div>
        <Form.Item label="ILARS Ref">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="IMS Approval Code">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="IRO Principal Lawyer">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Employer Of Claimant">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Insurance Claim No">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Appointment Preference">
          <DatePicker.RangePicker size="large" />
        </Form.Item>
        <Form.Item label="Location">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Specialties">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Booking comment" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default ClaimDetailsForm;
