import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ServiceEstimateStore from './Store';
import ModalCreate from './ModalCreate';

function ServiceEstimateModal() {
  const { modalType } = ServiceEstimateStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ServiceEstimateStore} onCancel={ServiceEstimateStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...ServiceEstimateStore} onCancel={ServiceEstimateStore.toggleModal(false)} />;
  }
}

export default ServiceEstimateModal;
