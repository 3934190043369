import React from 'react';
import { Form, Input, Select, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';

import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import { toJS } from 'mobx';

const titleOptions = [
  {
    value: 'Mrs',
  },
  {
    value: 'Mr',
  },
  {
    value: 'Ms',
  },
];
const stateOptions = [
  {
    value: 'ACT',
  },
  {
    value: 'NSW',
  },
  {
    value: 'VIC',
  },
  {
    value: 'QLD',
  },
  {
    value: 'SA',
  },
  {
    value: 'WA',
  },
  {
    value: 'TAS',
  },
  {
    value: 'NT',
  },
];

window.functionGetElementFormForResize = () => {
  return document.getElementById('contact-information-form');
};

@observer
class ContactInformation extends React.Component {
  handleSearch = val => {
    AddEditStore.refetchSearchClient({ keywordClient: val });
  };
  handleFieldAssignDate = dateStr => {
    if (dateStr) {
      if (dateStr.search('/Date') !== -1) {
        const date = formatDate(dateStr);
        return moment(date, 'DD MMM,YYYY').format('DD/MM/YYYY');
      }
      return dateStr;
    }
    return dateStr;
  };
  handleUnsubscribedDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
        }
        return dateStr;
      }
      return moment(dateStr).format('DD/MM/YYYY - HH:mm');
    }
    return '';
  };
  handleScroll = event => {
    const target = event.target;
    const number = AddEditStore.numberItemClient;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      AddEditStore.refetchSearchClient({ numberItemClient: number + 20 });
    }
  };

  render() {
    const { CMInfo, specificlookupList } = AddEditStore;
    return (
      <Form id="contact-information-form" name="contact-information">
        <Form.Item label="CM ID">
          <Input size="large" value={CMInfo.CmId} disabled />
        </Form.Item>
        <Form.Item label="Title">
          <Select size="large" value={CMInfo.Title} onChange={AddEditStore.handleFieldChange('Title')}>
            {titleOptions.map((i, idx) => (
              <Select.Option key={idx} value={i.value}>
                {i.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="First Name"
          validateStatus={AddEditStore.errors['FirstName'] ? 'error' : null}
          help={AddEditStore.errors['FirstName']}
          required
        >
          <Input size="large" value={CMInfo.FirstName} onChange={AddEditStore.handleFieldChange('FirstName')} />
        </Form.Item>
        <Form.Item label={<React.Fragment>Surname</React.Fragment>}>
          <Input size="large" value={CMInfo.LastName} onChange={AddEditStore.handleFieldChange('LastName')} />
        </Form.Item>
        <Form.Item
          label="Phone"
          validateStatus={AddEditStore.errors['OfficePhone'] ? 'error' : null}
          help={AddEditStore.errors['OfficePhone']}
        >
          <Input size="large" value={CMInfo.OfficePhone} onChange={AddEditStore.handleFieldChange('OfficePhone')} />
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['Telephone'] ? 'error' : null}
          help={AddEditStore.errors['Telephone']}
          label="Mobile"
        >
          <Input size="large" value={CMInfo.Telephone} onChange={AddEditStore.handleFieldChange('Telephone')} />
        </Form.Item>
        <Form.Item
          required
          validateStatus={AddEditStore.errors['Email'] ? 'error' : null}
          help={AddEditStore.errors['Email']}
          label={<React.Fragment>Email</React.Fragment>}
        >
          <Input size="large" value={CMInfo.Email} onChange={AddEditStore.handleFieldChange('Email')} />
        </Form.Item>
        <Form.Item
          className="posstion-IsTypist-label"
          label={
            <span>
              <span style={{ marginRight: '10px' }}>Position:</span>
            </span>
          }
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            value={CMInfo.Position}
            onChange={AddEditStore.handleFieldChange('Position')}
          >
            {specificlookupList &&
              specificlookupList.itemList.map(i => (
                <Select.Option value={i.Id} key={i.Id}>
                  {i.Label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Suburb">
          <Input size="large" value={CMInfo.District} onChange={AddEditStore.handleFieldChange('District')} />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input size="large" value={CMInfo.Postcode} onChange={AddEditStore.handleFieldChange('Postcode')} />
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['CityId'] ? 'error' : null}
          help={AddEditStore.errors['CityId']}
          required
          label="State"
        >
          <Select size="large" value={CMInfo.CityId} allowClear onChange={AddEditStore.handleFieldChange('CityId')}>
            {stateOptions.map((i, idx) => (
              <Select.Option key={idx} value={idx + 1}>
                {i.value === 0 ? '' : i.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={<React.Fragment>Client</React.Fragment>}>
          <Input
            size="large"
            value={CMInfo.CompanyName}
            onChange={AddEditStore.handleFieldChange('Postcode')}
            disabled
          />
        </Form.Item>
        <div className="fluid-field">
          <Checkbox checked={toJS(CMInfo.Unsubscribed)} onChange={AddEditStore.handleChecked('Unsubscribed')}>
            Unsubscribe to Newsletters
          </Checkbox>
        </div>
      </Form>
    );
  }
}

export default ContactInformation;
