import * as api from '@stores/api';

export const SUPP_STATUS = [
  {
    id: api.suppStatusEnum.REPORT_REVIEW_REQUESTED,
    key: api.suppStatus.REPORT_REVIEW_REQUESTED,
    group: 'Booking & Paperwork',
  },
  {
    id: api.suppStatusEnum.REQUEST_SENT_TO_PROVIDER,
    key: api.suppStatus.REQUEST_SENT_TO_PROVIDER,
    group: 'Booking & Paperwork',
  },
  {
    id: api.suppStatusEnum.DICTATION_RECEIVED,
    key: api.suppStatus.DICTATION_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.suppStatusEnum.REPORT_DRAFT_RECEIVED,
    key: api.suppStatus.REPORT_DRAFT_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.suppStatusEnum.REPORT_RETURNED_FROMDOCTOR,
    key: api.suppStatus.REPORT_RETURNED_FROMDOCTOR,
    group: 'Reporting',
  },
  {
    id: api.suppStatusEnum.CLARIFICATION_NO_FEE,
    key: api.suppStatus.CLARIFICATION_NO_FEE,
    group: 'Reporting',
  },
  {
    id: api.suppStatusEnum.REPORT_COMPLETED,
    key: api.suppStatus.REPORT_COMPLETED,
    group: 'Reporting',
  },
  {
    id: api.suppStatusEnum.CANCELLED_NO_FEE,
    key: api.suppStatus.CANCELLED_NO_FEE,
    group: 'Cancellation',
  },
  {
    id: api.suppStatusEnum.CANCELLED_WITH_FEE,
    key: api.suppStatus.CANCELLED_WITH_FEE,
    group: 'Cancellation',
  },
  {
    id: api.suppStatusEnum.CLIENT_INVOICE,
    key: api.suppStatus.CLIENT_INVOICE,
    group: 'Billing',
  },
  {
    id: api.suppStatusEnum.DOCTOR_INVOICE,
    key: api.suppStatus.DOCTOR_INVOICE,
    group: 'Billing',
  },
  // {
  //   id: api.suppStatusEnum.SERVICE_BILLED,
  //   key: api.suppStatus.SERVICE_BILLED,
  //   group: 'Billing',
  // },
  {
    id: api.suppStatusEnum.SERVICE_PAID,
    key: api.suppStatus.SERVICE_PAID,
    group: 'Billing',
  },
];

export const SUPP_INIT_DATA = {
  AcceptanceDate: null,
  AcceptanceEvidenceAttachments: [],
  ActionCompleted: false,
  ActionNotes: null,
  AssessedDoctorId: null,
  ActionRequired: true,
  ActionRequiredDueDate: null,
  ActionRequiredType: 0,
  ActionRequiredUserName: null,
  ActionSubject: null,
  AllowDoctorToViewPaperwork: false,
  AppointmentDate: null,
  AssessedDoctor: null,
  AssessmentId: null,
  AssignedStaffId: null,
  Attachments: [],
  AuthorisedPurchaseAmount: null,
  AutoRemindReportDraftCompletionDeadlineToMagStaff: false,
  BookingSolicitor: null,
  BookingSolicitorId: null,
  CancelledChargeStatus: 0,
  CancelledComment: null,
  CancelledDate: null,
  CaseManager: null,
  CaseManagerId: null,
  CaseNo: null,
  CaseStatus: 0,
  ChargeStatus: 0,
  ClaimNo: null,
  Claimant: null,
  Client: null,
  ClientId: null,
  ClientInvoicePrepaymentAttachments: [],
  ClientInvoiced: false,
  ClientInvoicedDate: null,
  ClientPrepaymentPaid: false,
  ClientPrepaymentPaidAttachments: [],
  ClientPrepaymentPaidDate: null,
  ClientPrepaymentReceived: false,
  ClientPrepaymentRequired: false,
  ClientPrepaymentRequiredDate: null,
  ClientRequestNo: null,
  Comment: null,
  CompletedAmendedReportAttachments: [],
  CompletedDate: null,
  CompletedReportAttachments: [],
  CompletionDate: null,
  CreatedUserId: null,
  DateRequest: null,
  DatereadyCreateInvoice: null,
  DelayPrepaymentRequired: false,
  DictationComment: null,
  DictationInstructionAttachments: [],
  DictationOnedriveLinkFile: null,
  DictationOption: 0,
  DictationReceived: false,
  DictationReceivedAttachments: [],
  DictationReceivedDate: null,
  DictationSentToTypist: false,
  DictationTypistID: null,
  DisableReportReminder: false,
  DisableReportReminderBy: null,
  DisableReportReminderDate: null,
  Doctor: null,
  DoctorInvoiced: false,
  DoctorInvoicedDate: null,
  DoctorPrepaymentPaid: false,
  DoctorPrepaymentRequired: false,
  DoctorReportTemplateAttachments: [],
  DoctorSampleAttachments: [],
  DoctorViewPaperworkByUser: null,
  EmailInvoiceClient: false,
  EmailInvoiceToMAG: false,
  EmailInvoiceToMAGDateSent: null,
  EmailInvoiceToMAGStaffSent: null,
  EmailPaymentReceiptToClient: false,
  EmailPaymentReceiptToClientBy: null,
  EmailPaymentReceiptToClientDateSent: null,
  EmailPaymentReceiptToDoctor: false,
  EmailPaymentReceiptToDoctorDateSent: null,
  EmailQuoteAcceptedToDoctor: false,
  EmailReportDraftToDoctor: false,
  EmailReportDraftToDoctorBE: false,
  EmailReportDraftToDoctorByUser: null,
  EmailReportDraftToDoctorDateSent: null,
  EmailReportDraftToDoctorPE: false,
  EmailRequestRate: false,
  EmailToCMsViewReportRecent: null,
  EmailToCMsViewReportRecentByUser: null,
  EmployerOfClaimant: null,
  F_RDCCaseManagerId: null,
  F_RDCEmail: null,
  F_RDCSameBookingCM: false,
  F_RDCfirstName: null,
  F_RDClastName: null,
  FactualInvestigationId: null,
  FileReviewId: 0,
  FullReportRate: null,
  FullReportRateTo: null,
  IMSApprovalCode: null,
  Id: 0,
  IlarsRef: null,
  IlarsWiroSolicitor: null,
  IlarsWiroSolicitorOld: null,
  InjuriedDate: null,
  InjuryDescription: null,
  InvoiceAttachmentExLink: null,
  InvoiceAttachments: [],
  InvoiceNo: null,
  IsAllowCMViewAmendedReport: false,
  IsAllowCMViewReport: false,
  IsAllowSolicitorViewAmendedReport: false,
  IsAllowSolicitorViewReport: false,
  IsInvoiceUrgentReport: false,
  IsLocked: false,
  IsLongPaperwork: false,
  IsNoneSupplementary: true,
  IsPaperworkReceived: false,
  IsPaymentRequested: false,
  IsPrepaymentPaid: false,
  IsSendPrepaymentPaidToProvider: false,
  MAGFullReportRate: null,
  MAGFullReportRateTo: null,
  MAGRateConfiguration: 0.675,
  NoChargeReason: 0,
  NoFeeReasonComment: null,
  NotifyCancellationToCM: false,
  NotifyCancellationToSolictor: false,
  NotifyDoctorCancelled: false,
  OriginAppointmentDate: null,
  OriginalCaseNoInput: null,
  OriginalMAGInfo: null,
  OriginalMAGInfo2: null,
  OriginalMAGInfoTxt: null,
  OriginalMAGInfoTxt2: null,
  PCaseManager: null,
  PClient: null,
  PICDictationReceived: null,
  PICReportDraftReceived: null,
  PICServiceBilled: null,
  PICcancelled: null,
  PICcompleted: null,
  PICcorrectedReport: null,
  PICsentToProvider: null,
  PaperworkAttachments: [],
  PaperworkByOtherMethod: false,
  PaperworkByOtherMethodDate: null,
  PaperworkComment: null,
  PaperworkExLink: null,
  PaperworkOtherAttachments: [],
  PaperworkOtherExLink: null,
  PaperworkReceivedDate: null,
  PaymentRequestedAttachments: [],
  PaymentRequestedDate: null,
  PaymentRequestedInvoiceAmount: null,
  PaymentRequestedInvoiceNo: null,
  PaymentRequestedInvoiceTax: null,
  PrepaymentPaidAttachments: [],
  PrepaymentPaidComment: null,
  PrepaymentPaidDate: null,
  PrepaymentRequired: false,
  PrepaymentRequiredDate: null,
  PurchaseOrderNo: null,
  QuoteAccepted: false,
  QuoteAcceptedDate: null,
  QuoteAcceptedUpdateBy: '',
  ReadyCreateInvoice: false,
  ReasonCancellation: 0,
  ReportCompleted: false,
  ReportCompletedSentDate: null,
  ReportDraftComment: null,
  ReportDraftReceived: false,
  ReportDraftReceivedAttachments: [],
  ReportDraftReceivedDate: null,
  ReportDraftSentDate: null,
  ReportId: 1155,
  ReportRequiredDate: null,
  ReportReturnedFromDoctor: false,
  ReportReturnedFromDoctorAttachments: [],
  RequestAttachments: [],
  RequestExpectedCompletionDate: null,
  S_RDCCaseManagerId: null,
  S_RDCEmail: null,
  S_RDCfirstName: null,
  S_RDClastName: null,
  SelectedAssessedDoctorProviderNoIdx: 1,
  SelectedDoctorProviderNoIdx: 1,
  SelectedPaperworkToDoctorBies: null,
  SendAttachComplete: false,
  SendConfirmationToSpecialist: false,
  SendConfirmationToSpecialistDate: null,
  SendConfirmationToSpecialistDateByUser: null,
  IsAllowSpecialistViewReport: false,
  SentByAllowSpecialistViewReport: null,
  SentDateAllowSpecialistViewReport: null,
  SendToWIRO: false,
  SentAmendedReportsByUser: null,
  SentAmendedReportsDate: null,
  SentByAllowSolicitorViewAmendedReport: null,
  SentByAllowSolicitorViewReport: null,
  SentByEmailInvoiceClient: '',
  SentByNotifyBookingCMCancellation: null,
  SentByNotifyCancellationToSolictor: null,
  SentByNotifyDoctorCancellation: null,
  SentByQuoteAcceptedToDoctor: '',
  SentByRequestRate: '',
  SentDateAllowSolicitorViewAmendedReport: null,
  SentDateAllowSolicitorViewReport: null,
  SentDateDoctorViewPaperwork: null,
  SentDateEmailInvoiceClient: null,
  SentDateNotifyBookingCMCancellation: null,
  SentDateNotifyCancellationToSolictor: null,
  SentDateNotifyDoctorCancellation: null,
  SentDateQuoteAcceptedToDoctor: null,
  SentDateRequestRate: null,
  SentDateToTypist: null,
  SentToTypist: false,
  ServiceNotesandFeeObj: {
    AdminExtraFeeAmount: null,
    AdminExtraFeeTaxPercentage: null,
    CReferenceId: null,
    CommissionOnTopRate: null,
    CommissionRate: null,
    CommissionRateType: 0,
    Description: null,
    HasOtherFee: false,
    IncludeAdminExtraFeeToDoctorCommission: false,
    IncludeManualFee1ToDoctorCommission: false,
    IncludeServiceItemFeeToDoctorCommission: false,
    InvoiceAttachment: null,
    OtherFee1Description: null,
    OtherFee1Label: null,
    OtherFee1TaxPercentage: null,
    OtherFee1Value: null,
    OtherFee2Description: null,
    OtherFee2Label: null,
    OtherFee2TaxPercentage: null,
    OtherFee2Value: null,
    PayingCaseManagerId: null,
    PayingClientId: null,
    SameBookingCM: false,
    SameClientAccEmail: false,
    SendInvoice: null,
    SendInvoice2: null,
    SelectedServiceItemIds: '[]',
    ListServiceItems: null,
  },
  SpecialistPrepaymentPaid: false,
  StaffId: null,
  SubCaseManager1: null,
  SubCaseManager2: null,
  SubCaseManager3: null,
  SuppNo: null,
  ToTypistByUser: null,
  TypistApproverdBy: null,
  TypistApproverdOn: null,
  TypistReportAttachments: [],
  TypistSubmittingDate: null,
  TypistTranscribingTime: null,
  UrgentReport: false,
  UrgentReportCompleted: false,
  UrgentReportCompletedDate: null,
  UrgentReportDueDate: null,
  UrgentReportNotes: null,
  UrgentReportStaffId: null,
  UserReadyCreateInvoice: null,
  WiroFundingApprovalAttachments: [],
  WiroIlars: false,
  isDirect: false,
  PCSameBookingClient: false,
  PMSameBookingCM: false,
  ClientPrepaymentNote: null,
  SpecialistPrepaymentNote: null,
  ClaimantStatus: false,
  UrgentReportReportCompletionDate: null,
  UrgentReportConfirmedByCM: false,
  UrgentReportConfirmedByCMDate: null,
  UrgentReportConfirmedByCMBy: null,
  NotifyUrgentReportToStaff: false,
  NotifyUrgentReportToStaffDate: null,
  NotifyUrgentReportToStaffBy: null,
  NotifyUrgentReportToSpecialist: false,
  NotifyUrgentReportToSpecialistDate: null,
  NotifyUrgentReportToSpecialistBy: null,
  NotifyUrgentReportToCM: false,
  NotifyUrgentReportToCMDate: null,
  NotifyUrgentReportToCMBy: null,
  RequestAcceptedBySpecialist: false,
  RequestAcceptedBySpecialistDate: null,
  RequestAcceptedBySpecialistBy: null,
  IROFundingApproved: false,
  IROFundingApprovalDate: null,
  IROFundingApprovedDate: null,
  IROFundingApprovedBy: null,
  ModiifedDate: null,
  UrgentReportType: 0,
};
export const DOCTOR_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Previously used specialist',
    value: '3',
  },
  {
    label: 'Others',
    value: '4',
  },
];
export const MAG_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Internet search',
    value: '3',
  },
  {
    label: 'Colleague',
    value: '4',
  },
  {
    label: 'Word of mouth',
    value: '5',
  },
  {
    label: 'Others',
    value: '6',
  },
];

export const conversationTypes = [
  {
    label: 'Phone',
    value: 0,
    visible: true,
  },
  {
    label: 'Email',
    value: 1,
    visible: true,
  },
  {
    label: 'Email (Send Now)',
    value: 4,
    visible: false,
  },
  {
    label: 'Private Notes',
    value: 5,
    visible: true,
  },
  {
    label: 'Relationship Meeting',
    value: 2,
    visible: true,
  },
  {
    label: 'Relationship Call',
    value: 8,
    visible: true,
  },
  {
    label: 'Relationship Email',
    value: 9,
    visible: true,
  },
  {
    label: 'Others',
    value: 3,
    visible: true,
  },
];

export const bodyTest = {
  AcceptanceDate: null,
  AcceptanceEvidenceAttachments: [],
  ActionCompleted: false,
  ActionNotes: null,
  ActionRequired: false,
  ActionRequiredDueDate: null,
  ActionRequiredType: 0,
  ActionRequiredUserName: null,
  ActionSubject: null,
  ActionType: 0,
  AllowDoctorToViewPaperwork: false,
  AppointmentDate: null,
  AssessedDoctor: {
    ApprovedPrepayment: false,
    BookingsAndCancellationTerms: null,
    BusinessEmail: 'tes@gmail.com',
    ChargeRate: null,
    ChargeRate2: null,
    ChargeRate2From: null,
    ChargeRate2To: null,
    ChargeRateFrom: null,
    ChargeRateTo: null,
    ClientType: 0,
    CommissionOnTop: null,
    CommissionOnTop2: null,
    CommissionOnTop2From: null,
    CommissionOnTop2To: null,
    CommissionOnTopFrom: null,
    CommissionOnTopTo: null,
    ConsultingLocations: null,
    CreatedByUserId: '104ec811-d290-4923-b407-e8e4747e9cd3',
    DictationOption: 0,
    DictationOptionEndDate: null,
    DictationOptionStartDate: null,
    Email2: 'tes@gmail.com',
    FullAddress: '',
    FullName: '(Peter) James 123',
    Id: 6720,
    MAGChargeRate: null,
    MAGChargeRate2: null,
    MAGChargeRate2From: null,
    MAGChargeRate2To: null,
    MAGChargeRateFixedAmount: null,
    MAGChargeRateFrom: null,
    MAGChargeRateTo: null,
    PersonalEmail: 'tes@gmail.com',
    ProfileAndPreferences: 'tes@gmail.com',
    ProviderNumber: null,
    ProviderNumber2: null,
    ProviderNumber2Note: null,
    ProviderNumber3: null,
    ProviderNumber3Note: null,
    ProviderNumber4: null,
    ProviderNumber4Note: null,
    ProviderNumberNote: null,
    Qualifications: null,
    ServiceExclusion: null,
    Specialty: '',
    Telephone: '0414 442407',
    Title: 'Dr',
    UserId: null,
    ValidUntil: null,
  },
  AssessmentId: null,
  AssignedStaffId: '104ec811-d290-4923-b407-e8e4747e9cd3',
  Attachments: [],
  AuthorisedPurchaseAmount: null,
  AutoRemindReportDraftCompletionDeadlineToMagStaff: false,
  BookingSolicitor: null,
  BookingSolicitorId: null,
  CancelledChargeStatus: 4,
  CancelledComment: null,
  CancelledDate: null,
  CaseManager: {
    AssignedStaffId: null,
    CompanyId: 2767,
    Email: 'hiep@yahoo.com.vn',
    FullName: '53w5 reqrqrq',
    Id: 13888,
    OfficePhone: null,
    State: null,
  },
  CaseNo: null,
  CaseStatus: 1,
  ChargeStatus: 4,
  ClaimNo: '123',
  Claimant: {
    Address: null,
    Created: '/Date(1614145716547)/',
    CreatedBy: '104ec811-d290-4923-b407-e8e4747e9cd3',
    DOB: null,
    Email: null,
    FirstName: '1123',
    FullName: '1123 123123',
    HomePhone: '123123',
    Id: 24699,
    LastName: '123123',
    NonDOB: false,
    Telephone: null,
    Title: 'Mr.',
  },
  Client: {
    AccEmail: '3m@3m.com',
    Address: '3M Australia,Building A, 1 Rivett Road',
    AssignedToId: null,
    CityName: 'NSW',
    ClientType: 2,
    ClientType2: 0,
    District: 'North Ryde',
    Email: 'hiep@yahoo.com.au',
    FullAddress: '3M Australia,Building A, 1 Rivett Road, North Ryde, 2113, NSW',
    Id: 2767,
    IsDisbursementFunding: true,
    Name: '3M',
    PostCode: '2113',
    ReportDeliveryContact: 10000,
    ReportDeliveryContactObj: null,
    State: 'NSW',
    StateName: 'NSW',
    Telephone: '0402531859',
  },
  ClientInvoicePrepaymentAttachments: [],
  ClientInvoiced: false,
  ClientInvoicedDate: null,
  ClientPrepaymentPaid: false,
  ClientPrepaymentPaidAttachments: [],
  ClientPrepaymentPaidDate: null,
  ClientPrepaymentReceived: false,
  ClientPrepaymentRequired: false,
  ClientPrepaymentRequiredDate: null,
  ClientRequestNo: null,
  ClinicOption: 0,
  Comment: null,
  CompletedAmendedReportAttachments: [],
  CompletedDate: null,
  CompletedReportAttachments: [],
  CompletionDate: null,
  CreatedUserId: null,
  DateRequest: '2021-03-31T11:27:46.817Z',
  DatereadyCreateInvoice: null,
  DelayPrepaymentRequired: false,
  DictationComment: null,
  DictationInstructionAttachments: [],
  DictationOnedriveLinkFile: null,
  DictationOption: 0,
  DictationReceived: false,
  DictationReceivedAttachments: [],
  DictationReceivedDate: null,
  DictationSentToTypist: false,
  DictationTypistID: null,
  DisableReportReminder: false,
  DisableReportReminderBy: null,
  DisableReportReminderDate: null,
  Doctor: {
    ApprovedPrepayment: false,
    BookingsAndCancellationTerms: null,
    BusinessEmail: 'sdadsa@gmail.com',
    ChargeRate: null,
    ChargeRate2: null,
    ChargeRate2From: null,
    ChargeRate2To: null,
    ChargeRateFrom: null,
    ChargeRateTo: null,
    ClientType: 0,
    CommissionOnTop: null,
    CommissionOnTop2: null,
    CommissionOnTop2From: null,
    CommissionOnTop2To: null,
    CommissionOnTopFrom: null,
    CommissionOnTopTo: null,
    ConsultingLocations: null,
    CreatedByUserId: '104ec811-d290-4923-b407-e8e4747e9cd3',
    DictationOption: 0,
    DictationOptionEndDate: null,
    DictationOptionStartDate: null,
    Email2: 'sdadsa@gmail.com',
    FullAddress: '',
    FullName: '123 123123',
    Id: 6715,
    MAGChargeRate: null,
    MAGChargeRate2: null,
    MAGChargeRate2From: null,
    MAGChargeRate2To: null,
    MAGChargeRateFixedAmount: null,
    MAGChargeRateFrom: null,
    MAGChargeRateTo: null,
    PersonalEmail: null,
    ProfileAndPreferences: null,
    ProviderNumber: null,
    ProviderNumber2: null,
    ProviderNumber2Note: null,
    ProviderNumber3: null,
    ProviderNumber3Note: null,
    ProviderNumber4: null,
    ProviderNumber4Note: null,
    ProviderNumberNote: null,
    Qualifications: null,
    ServiceExclusion: null,
    Specialty: '',
    Telephone: null,
    Title: 'Other',
    UserId: null,
    ValidUntil: null,
  },
  DoctorInvoiced: false,
  DoctorInvoicedDate: null,
  DoctorPrepaymentPaid: false,
  DoctorPrepaymentRequired: false,
  DoctorReportTemplateAttachments: [],
  DoctorSampleAttachments: [],
  DoctorViewPaperworkByUser: null,
  EmailInvoiceClient: false,
  EmailInvoiceToMAG: false,
  EmailInvoiceToMAGDateSent: null,
  EmailInvoiceToMAGStaffSent: '',
  EmailPaymentReceiptToClient: false,
  EmailPaymentReceiptToClientBy: '',
  EmailPaymentReceiptToClientDateSent: null,
  EmailPaymentReceiptToDoctor: false,
  EmailPaymentReceiptToDoctorDateSent: null,
  EmailQuoteAcceptedToDoctor: false,
  EmailReportDraftToDoctor: false,
  EmailReportDraftToDoctorBE: false,
  EmailReportDraftToDoctorByUser: null,
  EmailReportDraftToDoctorDateSent: null,
  EmailReportDraftToDoctorPE: false,
  EmailRequestRate: false,
  EmailToCMsViewReportRecent: null,
  EmailToCMsViewReportRecentByUser: null,
  EmployerOfClaimant: null,
  F_RDCCaseManagerId: 13888,
  F_RDCEmail: 'hiep@yahoo.com.vn',
  F_RDCSameBookingCM: true,
  F_RDCfirstName: '53w5',
  F_RDClastName: 'reqrqrq',
  FactualInvestigationId: null,
  FileReviewId: 0,
  FullReportRate: null,
  FullReportRateTo: null,
  IMSApprovalCode: null,
  Id: 3765,
  IlarsRef: null,
  IlarsWiroSolicitor: null,
  IlarsWiroSolicitorOld: null,
  InjuriedDate: null,
  InjuryDescription: null,
  InvoiceAttachmentExLink: null,
  InvoiceAttachments: [],
  InvoiceNo: null,
  IsAllowCMViewAmendedReport: false,
  IsAllowCMViewReport: false,
  IsAllowSolicitorViewAmendedReport: false,
  IsAllowSolicitorViewReport: false,
  IsInvoiceUrgentReport: false,
  IsLocked: false,
  IsLongPaperwork: false,
  IsNoneSupplementary: false,
  IsPaperworkReceived: false,
  IsPaymentRequested: false,
  IsPrepaymentPaid: false,
  IsSendPrepaymentPaidToProvider: false,
  MAGFullReportRate: null,
  MAGFullReportRateTo: null,
  MAGRateConfiguration: 0.675,
  NoChargeReason: 0,
  NoFeeReasonComment: null,
  NotifyCancellationToCM: true,
  NotifyCancellationToSolictor: false,
  NotifyDoctorCancelled: false,
  OriginAppointmentDate: null,
  OriginalCaseNoInput: null,
  OriginalMAGInfo: null,
  OriginalMAGInfo2: null,
  OriginalMAGInfoTxt: null,
  OriginalMAGInfoTxt2: null,
  PCaseManager: null,
  PClient: null,
  PICDictationReceived: '9679de7e-06d8-40de-87bd-480f33834c98',
  PICReportDraftReceived: '9679de7e-06d8-40de-87bd-480f33834c98',
  PICServiceBilled: '4fe10bb2-6011-40e4-bae9-e5ca27badd33',
  PICcancelled: '104ec811-d290-4923-b407-e8e4747e9cd3',
  PICcompleted: '9679de7e-06d8-40de-87bd-480f33834c98',
  PICcorrectedReport: '2776369c-c30b-4aea-bcb0-d9509b176cf1',
  PICsentToProvider: 'bc3962a8-be88-4266-9fa5-9635c15047af',
  PaperworkAttachments: [],
  PaperworkByOtherMethod: false,
  PaperworkByOtherMethodDate: null,
  PaperworkComment: null,
  PaperworkExLink: null,
  PaperworkOtherAttachments: [],
  PaperworkOtherExLink: null,
  PaperworkReceivedDate: null,
  PaymentRequestedAttachments: [],
  PaymentRequestedDate: null,
  PaymentRequestedInvoiceAmount: null,
  PaymentRequestedInvoiceNo: null,
  PaymentRequestedInvoiceTax: null,
  PrepaymentPaidAttachments: [],
  PrepaymentPaidComment: null,
  PrepaymentPaidDate: null,
  PrepaymentRequired: false,
  PrepaymentRequiredDate: null,
  PurchaseOrderNo: null,
  QuoteAccepted: false,
  QuoteAcceptedDate: null,
  QuoteAcceptedUpdateBy: '',
  ReadyCreateInvoice: false,
  ReasonCancellation: 1,
  ReportCompleted: false,
  ReportCompletedSentDate: null,
  ReportDraftComment: null,
  ReportDraftReceived: false,
  ReportDraftReceivedAttachments: [],
  ReportDraftReceivedDate: null,
  ReportDraftSentDate: null,
  ReportId: 1055,
  ReportRequiredDate: null,
  ReportReturnedFromDoctor: false,
  ReportReturnedFromDoctorAttachments: [],
  RequestAttachments: [],
  RequestExpectedCompletionDate: '2021-04-07T11:27:46.817Z',
  S_RDCCaseManagerId: null,
  S_RDCEmail: null,
  S_RDCfirstName: null,
  S_RDClastName: null,
  SelectedAssessedDoctorProviderNoIdx: 1,
  SelectedDoctorProviderNoIdx: 1,
  SelectedPaperworkToDoctorBies: [],
  SendAttachComplete: false,
  SendConfirmationToSpecialist: false,
  SendConfirmationToSpecialistDate: null,
  SendConfirmationToSpecialistDateByUser: null,
  SendToWIRO: false,
  SentAmendedReportsByUser: null,
  SentAmendedReportsDate: null,
  SentByAllowSolicitorViewAmendedReport: '',
  SentByAllowSolicitorViewReport: '',
  SentByEmailInvoiceClient: '',
  SentByNotifyBookingCMCancellation: '',
  SentByNotifyCancellationToSolictor: '',
  SentByNotifyDoctorCancellation: '',
  SentByQuoteAcceptedToDoctor: '',
  SentByRequestRate: '',
  SentDateAllowSolicitorViewAmendedReport: null,
  SentDateAllowSolicitorViewReport: null,
  SentDateDoctorViewPaperwork: null,
  SentDateEmailInvoiceClient: null,
  SentDateNotifyBookingCMCancellation: null,
  SentDateNotifyCancellationToSolictor: null,
  SentDateNotifyDoctorCancellation: null,
  SentDateQuoteAcceptedToDoctor: null,
  SentDateRequestRate: null,
  SentDateToTypist: null,
  SentToTypist: false,
  ServiceNotesAndFee: {
    AdminExtraFeeAmount: null,
    AdminExtraFeeTaxPercentage: null,
    CReference: null,
    CReferenceId: null,
    CommissionAmount: '0.00',
    CommissionOnTopAmount: 0,
    CommissionOnTopRate: null,
    CommissionRate: null,
    CommissionRateType: 0,
    Description: null,
    HasOtherFee: false,
    IncludeAdminExtraFeeToDoctorCommission: false,
    IncludeManualFee1ToDoctorCommission: false,
    IncludeServiceItemFeeToDoctorCommission: false,
    InvoiceAttachment: null,
    ListServiceItems: null,
    OtherFee1Description: null,
    OtherFee1Label: null,
    OtherFee1TaxPercentage: null,
    OtherFee1Value: null,
    OtherFee2Description: null,
    OtherFee2Label: null,
    OtherFee2TaxPercentage: null,
    OtherFee2Value: null,
    PayingCaseManager: null,
    PayingCaseManagerId: null,
    PayingClient: null,
    PayingClientId: null,
    SameBookingCM: false,
    SameClientAccEmail: false,
    SelectedServiceItemIds: '[]',
    SendInvoice: null,
    SendInvoice2: null,
    TaxTotal: 0,
    Total: 0,
    TotalAmountForCommissionCalculation: 0,
    TotalAmountForCommissionCalculationTax: 0,
    TotalWithoutTax: '0.00',
  },
  ServiceNotesandFeeObj: {
    AdminExtraFeeAmount: null,
    AdminExtraFeeTaxPercentage: null,
    CReference: null,
    CReferenceId: null,
    CommissionAmount: '0.00',
    CommissionOnTopAmount: 0,
    CommissionOnTopRate: null,
    CommissionRate: null,
    CommissionRateType: 0,
    Description: null,
    HasOtherFee: false,
    IncludeAdminExtraFeeToDoctorCommission: false,
    IncludeManualFee1ToDoctorCommission: false,
    IncludeServiceItemFeeToDoctorCommission: false,
    InvoiceAttachment: null,
    ListServiceItems: null,
    OtherFee1Description: null,
    OtherFee1Label: null,
    OtherFee1TaxPercentage: null,
    OtherFee1Value: null,
    OtherFee2Description: null,
    OtherFee2Label: null,
    OtherFee2TaxPercentage: null,
    OtherFee2Value: null,
    PayingCaseManager: null,
    PayingCaseManagerId: null,
    PayingClient: null,
    PayingClientId: null,
    SameBookingCM: false,
    SameClientAccEmail: false,
    SelectedServiceItemIds: '[]',
    SendInvoice: null,
    SendInvoice2: null,
    TaxTotal: 0,
    Total: 0,
    TotalAmountForCommissionCalculation: 0,
    TotalAmountForCommissionCalculationTax: 0,
    TotalWithoutTax: '0.00',
  },
  SpecialistPrepaymentPaid: false,
  StaffId: '',
  SubCaseManager1: '',
  SubCaseManager2: '',
  SubCaseManager3: '',
  SuppNo: 'S-003765',
  ToTypistByUser: null,
  TypistApproverdBy: null,
  TypistApproverdOn: null,
  TypistReportAttachments: [],
  TypistSubmittingDate: null,
  TypistTranscribingTime: null,
  UrgentReport: false,
  UrgentReportCompleted: false,
  UrgentReportCompletedDate: null,
  UrgentReportDueDate: null,
  UrgentReportNotes: null,
  UrgentReportStaffId: '',
  UserReadyCreateInvoice: '',
  WiroFundingApprovalAttachments: [],
  WiroIlars: false,
  isDirect: false,
  FinalReportOnedriveLinkFile: null,
  FinalReportOnedriveFilePassword: null,
};

export const FIELD_NAME_FOR_ADD_EDIT = [
  {
    fieldName: 'AssessedDoctorId',
    pathname: '/view/add-edit-doctor-2',
    computedName: 'specialistSelected',
    storageName: 'DoctorName',
    name: 'specialist',
    listName: 'specialists',
  },
  {
    fieldName: 'ClientId',
    pathname: '/view/add-edit-client-2',
    computedName: 'bookingClientSelected',
    storageName: 'CompanyName',
    name: 'client',
    listName: 'bookingClients',
  },
  {
    fieldName: 'CaseManagerId',
    pathname: '/view/add-edit-case-manager-2',
    computedName: 'bookingCMSelected',
    storageName: 'CmName',
    name: 'case manager',
    listName: 'bookingCMs',
  },
  {
    fieldName: 'PClientId',
    pathname: '/view/add-edit-client-2',
    computedName: 'pClientSelected',
    storageName: 'CompanyName',
    name: 'client',
    listName: 'pClients',
  },
  {
    fieldName: 'PCaseManagerId',
    pathname: '/view/add-edit-case-manager-2',
    computedName: 'pCMSelected',
    storageName: 'CmName',
    name: 'case manager',
    listName: 'pCMs',
  },
  {
    fieldName: 'ClaimantId',
    pathname: '/view/add-edit-claimant-2',
    computedName: 'claimantSelected',
    storageName: 'ClaimantName',
    name: 'claimant',
    listName: 'claimants',
  },
  {
    fieldName: 'PayingCaseManagerId',
    pathname: '/view/add-edit-case-manager-2',
    computedName: 'payingCMSelected',
    storageName: 'CmName',
    name: 'case manager',
    listName: 'payingClients',
  },
];

export const SERVICE_NOTE_FEE_INIT = {
  AdminExtraFeeAmount: null,
  AdminExtraFeeTaxPercentage: null,
  CReference: null,
  CReferenceId: null,
  CommissionAmount: '0.00',
  CommissionOnTopAmount: 0,
  CommissionOnTopRate: null,
  CommissionRate: null,
  CommissionRateType: 0,
  Description: null,
  HasOtherFee: false,
  IncludeAdminExtraFeeToDoctorCommission: false,
  IncludeManualFee1ToDoctorCommission: false,
  IncludeServiceItemFeeToDoctorCommission: false,
  InvoiceAttachment: null,
  ListServiceItems: null,
  OtherFee1Description: null,
  OtherFee1Label: null,
  OtherFee1TaxPercentage: null,
  OtherFee1Value: null,
  OtherFee2Description: null,
  OtherFee2Label: null,
  OtherFee2TaxPercentage: null,
  OtherFee2Value: null,
  PayingCaseManager: null,
  PayingCaseManagerId: null,
  PayingClient: null,
  PayingClientId: null,
  SameBookingCM: false,
  SameClientAccEmail: false,
  SelectedServiceItemIds: '[]',
  SendInvoice: null,
  SendInvoice2: null,
  TaxTotal: 0,
  Total: 0,
  TotalAmountForCommissionCalculation: 0,
  TotalAmountForCommissionCalculationTax: 0,
  TotalWithoutTax: '0.00',
};

export const CONSTANTS = {
  CaseStatus_None: 4,
  CaseStatus_CancelledNoCharge: 2,
  CaseStatus_CancelledWithFee: 3,
};
