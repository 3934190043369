import _ from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';
import customFetch from '../../../utils/customFetch';

class CaseManagersStore {
  @observable loading = true;
  @observable loadingHistory = true;
  @observable loadingSearch = true;
  @observable loadingClient = true;
  @observable dataClient = null;
  @observable dataSearch = null;
  @observable data = null;
  @observable dataHistory = null;
  @observable dataUser = null;
  @observable staffListAM = null;

  @observable cmId = 0;

  @observable client = 'All';
  @observable caseManager = 'All';

  @observable filter = 'active';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable keywordSearch = '';
  @observable sortColumn = 'First Name';
  @observable sortKey = 'FirstName';

  @observable sortDirectionBooking = 'ascending';
  @observable sortColumnBooking = 'Request Date';
  @observable sortKeyBooking = 'CaseManager';

  @observable loadingBookingHistory = true;
  @observable dataBookingHistory = null;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable listSize = 30;
  @observable companyId = 0;
  @observable numberSearch = 30;
  @observable numberClientPage = 30;
  @observable id = '';
  @observable IsSelectionList = true;
  @observable keywordClient = '';
  @observable cmState = '';
  @observable filterType = 'All';
  @observable clientOfficerId = '';
  @observable verified = 'All';
  @observable searchOnlyId = false;
  @observable fromToSaleDate = '';
  @observable filterCaller = '';
  @observable dataStaff = null;
  @observable dataAccountManager = null;
  @observable dataToVerified = null;
  @observable filterSubcription = 'All';

  @observable totalBooking = 0;

  @observable fromToRqtingDate = '';

  @observable uId = '';
  @observable data1 = null;
  @observable loading1 = true;

  @observable open = false;
  @observable modalParams = {};

  //Action required
  @observable loadingActionPIC = true;
  @observable dataActionPIC = null;
  @observable ActionRequiredById = '';

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchSearch = () => {
    this.loadingSearch = true;
    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Showing: this.filter,
        Keyword: this.keywordSearch,
        CurPage: this.curPage,
        NumItemPerPage: this.numberSearch,
        SearchOnlyId: this.searchOnlyId,
        //ActionRequiredById : this.ActionRequiredById,
      }),
    }).then(
      action(data => {
        this.dataSearch = data;
        this.loadingSearch = false;
      }),
    );
  };

  @action fetchBookingHistory = cmId => {
    this.loadingBookingHistory = true;
    customFetch(
      `/Staff/GetBookingHistory?caseManagerId=${cmId}&sortBy=${
        this.sortKeyBooking
      }&sortDirection=${this.sortDirectionBooking.replace('ending', '').toUpperCase()}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataBookingHistory = data;
        this.loadingBookingHistory = false;
        this.totalBooking = data.sumItem;
      }),
    );
  };

  @action fetchStaffAM = () => {
    this.setFieldsValue({ loading: true });
    customFetch('/Staff/GetStaffs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(resp => {
      if (resp.status === 'success') {
        this.setFieldsValue({ staffListAM: (resp.itemList || []).map(i => ({ text: i.FullName, value: i.Id })) });
      } else {
        this.setFieldsValue({ staffListAM: [] });
      }
      this.setFieldsValue({ loading: false });
    });
  };

  @action fetchClient = () => {
    this.loadingClient = true;
    customFetch('/Staff/GetStaffs', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        NumItemPerPage: this.numberClientPage,
        curPage: 1,
        keyword: this.keywordClient,
      }),
    }).then(
      action(data => {
        this.loadingClient = false;
        this.dataClient = data;
      }),
    );
  };

  @action fetchActionPIC = () => {
    this.loadingActionPIC = true;
    customFetch('/Staff/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataActionPIC = data;
        this.loadingActionPIC = false;
      }),
    );
  };

  @action fetchStaff = () => {
    this.loadingStaff = true;
    customFetch('/Staff/GetStaffSalesCallNeedFollowUp', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        if (data.status === 'success') {
          this.dataStaff = _.sortBy(data.itemList, 'FullName');
          this.dataStaff.unshift({ Id: undefined, FullName: 'All', Email: '' });
        }
        this.loadingStaff = false;
      }),
    );
  };

  @action fetchAccountManager = () => {
    this.loadingStaff = true;
    customFetch('/Staff/GetStaffAccountManager', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        if (data.status === 'success') {
          this.dataAccountManager = _.sortBy(data.itemList || [], 'FullName');
          this.dataAccountManager.unshift({ Id: undefined, FullName: 'All', Count: null });
        }
        this.loadingStaff = false;
      }),
    );
  };

  @action fetchToVerified = () => {
    this.loadingStaff = true;
    customFetch('/Staff/GetToVerify', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        if (data.status === 'success') {
          this.dataToVerified = data.itemList;
        }
        this.loadingStaff = false;
      }),
    );
  };

  getAppointmentDateRange = () => {
    const [fromDate, toDate] = this.fromToSaleDate.split(' - ');
    return {
      fromSaleDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').toISOString() : '',
      toSaleDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  @action fetchCase = (setTotalPage = false) => {
    this.loading = true;
    this.fetchActionPIC();
    const { fromSaleDate, toSaleDate } = this.getAppointmentDateRange();
    const { fromRequestingDate, toRequestingDate } = this.getRequestingDateRange();

    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        // FilterType: this.filter,
        Showing: this.filter,
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        companyId: this.companyId,
        SortBy: this.sortKey,
        State: this.cmState,
        FilterType: this.filterType,
        AssignedStaffId: this.clientOfficerId,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        ActionRequiredById: this.ActionRequiredById,
        Verified: this.verified,
        SearchOnlyId: this.searchOnlyId,
        FromSaleDate: fromSaleDate,
        ToSaleDate: toSaleDate,
        Caller: this.filterCaller,
        FromRequestingDate: fromRequestingDate,
        ToRequestingDate: toRequestingDate,
        FilterSubcription: this.filterSubcription,
      }),
    }).then(
      action(data => {
        this.loading = false;
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.fetchSearch();
      }),
    );
  };

  @action fetchHistory = () => {
    this.loadingHistory = true;
    customFetch('/Staff/GetCaseManagerHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        id: this.modalParams.id,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
      }),
    }).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action fetchLoginHistory = () => {
    this.loading1 = true;
    customFetch('/Manager/GetLoginHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        uId: this.modalParams.record.UserId,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
      }),
    }).then(
      action(data => {
        this.data1 = data;
        this.loading1 = false;
      }),
    );
  };

  @action fetchCMGroup = () => {
    this.loadingCMGroup = true;

    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: this.IsSelectionList,
        numItemPerPage: this.listSize,
      }),
    }).then(
      action(data => {
        this.dataCMGroup = data;
        this.loadingCMGroup = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchCase();
  };

  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBooking = column;
    this.sortDirectionBooking = direction;
    this.sortKeyBooking = sortKey;
    this.fetchBookingHistory(this.cmId);
  };

  @action setSearchOnlyId = value => {
    this.searchOnlyId = value;
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCase(setTotalPage);
  };

  @action refetchClient = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClient();
  };

  @action refetchSearch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearch(setTotalPage);
  };

  @action refetchGroup = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCase(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  getRequestingDateRange = () => {
    const [fromDate, toDate] = this.fromToRqtingDate.split(' - ');
    return {
      fromRequestingDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').toISOString() : '',
      toRequestingDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'active';
    this.sortColumn = 'First Name';
    this.sortKey = 'FirstName';
    this.sortDirection = 'ascending';
    this.companyId = 0;
    this.clientOfficerId = '';
    this.cmState = '';
    this.verified = 'All';
    this.filterSubcription = 'All';

    this.curPage = 1;
    this.totalPage = 0;
    this.refetch({}, true);
    this.searchOnlyId = false;
    this.fromToSaleDate = '';
    this.filterCaller = '';
    this.ActionRequiredById = '';
  };
  @action resetSearch = () => {
    this.keywordSearch = '';
    this.numberSearch = 30;

    this.refetchSearch({});
  };
}

export default new CaseManagersStore();
