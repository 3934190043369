import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../table';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: 'Case No' },
  { title: 'Task PIC' },
  { title: 'Status' },
  { title: 'Status Date' },
  { title: 'Next Task' },
];

const columnsIME = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client ' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Clinic Centre' },
  { title: 'Appointment Date' },
  { title: 'Status' },
  { title: 'Status Date' },
  { title: 'Next Task' },
  { title: 'Task PIC' },
];

const columnsFR = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Status' },
  { title: 'Status Date' },
  { title: 'Next Task' },
  { title: 'Task PIC' },
];

const columnsSUPP = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Status' },
  { title: 'Status Date' },
  { title: 'Next Task' },
  { title: 'Task PIC' },
];

const columnsCR = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'CR Provider 1' },
  { title: 'CR Provider 2' },
  { title: 'Status' },
  { title: 'Status Date' },
  { title: 'Next Task' },
  { title: 'Task PIC' },
];

class TablePIC extends React.Component {
  openCaseDetails = record => {
    const { title } = this.props;
    let caseNo = `Case ${record.CaseNo}`;
    let stograge = '';
    let tabName = '';
    if (title === 'IME Reports') {
      stograge = 'AssessmentCaseId';
      tabName = 'add-edit-ime-assessment-2';
    }
    if (title === 'File Reviews') {
      stograge = 'FileReviewCaseId';
      tabName = 'add-edit-file-review-2';
    }
    if (title === 'Supplementary Reports') {
      stograge = 'SupplementaryCaseId';
      tabName = 'add-edit-supplementary-report-2';
    }
    if (title === 'Clinical Records') {
      stograge = 'ClinicalRecordCaseId';
      tabName = 'add-edit-clinical-notes-2';
    }
    if (title === 'Med Negs') {
      stograge = 'MedNegCaseId';
      tabName = 'PostMedNeg';
    }
    if (title === 'Invoices') {
      stograge = 'InvoiceCaseId';
      tabName = 'ViewInvoice';
    }
    localStorage.setItem(`${stograge}`, caseNo);

    if (title !== 'Invoices' && title !== 'Med Negs') {
      dashboardStore.close(`/view/${tabName}`);
      return setTimeout(() => {
        dashboardStore.open(`/view/${tabName}?id=${record.CaseId}&step=0`);
      });
    } else {
      return iframe.forceOpenTab(`${tabName}`, `?id=${record.CaseId}&view=${tabName}`, {
        id: record.CaseId,
        view: `${tabName}`,
      });
    }
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  renderTableIME = record => (
    <React.Fragment>
      <Table.Cell>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimLabel}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">
          {record.DoctorTitle} {record.DoctorName}
        </h5>
        <div>{record.DoctorBusinessEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        {record.DateFrom ? moment(formatDate(record.DateFrom, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY') : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.StatusText}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.NextTask}</Table.Cell>
      <Table.Cell>{record.StaffName}</Table.Cell>
    </React.Fragment>
  );

  renderTableFR = record => (
    <React.Fragment>
      <Table.Cell>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimantNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">
          {record.DoctorTitle} {record.DoctorName}
        </h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.NextTask}</Table.Cell>
      <Table.Cell>{record.StaffName}</Table.Cell>
    </React.Fragment>
  );

  renderTableSUPP = record => (
    <React.Fragment>
      <Table.Cell>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.DateRequest
          ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaim}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.AssessedDoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.NextTask}</Table.Cell>
      <Table.Cell>{record.StaffName}</Table.Cell>
    </React.Fragment>
  );

  renderTableCR = record => (
    <React.Fragment>
      <Table.Cell>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimaNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.NextTask}</Table.Cell>
      <Table.Cell>{record.StaffName}</Table.Cell>
    </React.Fragment>
  );

  renderTableOther = record => (
    <React.Fragment>
      <Table.Cell>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>{record.StaffName}</Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.NextTask}</Table.Cell>
    </React.Fragment>
  );

  renderTable = record => {
    switch (this.props.title) {
      case 'IME Reports':
        return this.renderTableIME(record);
      case 'File Reviews':
        return this.renderTableFR(record);
      case 'Supplementary Reports':
        return this.renderTableSUPP(record);
      case 'Clinical Records':
        return this.renderTableCR(record);
      default:
        return this.renderTableOther(record);
    }
  };

  renderHeader = () => {
    switch (this.props.title) {
      case 'IME Reports':
        return <TableHeader columns={columnsIME} />;
      case 'File Reviews':
        return <TableHeader columns={columnsFR} />;
      case 'Supplementary Reports':
        return <TableHeader columns={columnsSUPP} />;
      case 'Clinical Records':
        return <TableHeader columns={columnsCR} />;
      default:
        return <TableHeader columns={columns} />;
    }
  };

  render() {
    const { data, curPage, totalPage, sumItem } = this.props;
    return (
      <Table striped celled>
        {this.renderHeader()}
        <Table.Body>
          {data && data.length ? (
            data.map(record => <Table.Row key={record.Id}>{this.renderTable(record)}</Table.Row>)
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage}
          totalPage={totalPage}
          onPageClick={this.handlePageClick}
          totalItems={sumItem}
        />
      </Table>
    );
  }
}

export default TablePIC;
