import React from 'react';
import { Form, Checkbox, Input, notification, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';

import AddEditStore from './store';
import { Button, Icon, Popup } from 'semantic-ui-react';
import customFetch from '../../../utils/customFetch';
import { action } from 'mobx';
// import router from '../../../stores/router';
import { formatDate } from '../../../utils/functions';
import * as api from '@stores/api';
// import { toJS } from 'mobx';

@observer
class LoginInformation extends React.Component {
  notifyEnable = () => {
    AddEditStore.toggleModal(true, {
      modalType: 'notification',
      message: 'This user is not yet enabled for login. Enable Login first.',
    })();
  };

  handleEditUser = () => {
    var currentUserId;
    const { userId } = AddEditStore;
    currentUserId = userId;
    if (!currentUserId) {
      currentUserId = api.currentUser.data.id;
    }
    customFetch('/Manager/GetUserById', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: currentUserId }),
    }).then(
      action(data => {
        AddEditStore.dataUser = data;
        AddEditStore.toggleModal(true, { modalType: 'editUser' })();
      }),
    );
  };

  handleUnlockUser = () => {
    const { userId } = AddEditStore;
    customFetch('/manager/unlockUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `User has been unlocked`,
        duration: 5,
      });
    });
  };

  handleResetPassword = () => {
    const { userId, Id } = AddEditStore;
    AddEditStore.toggleModal(false, {})();
    setTimeout(() => {
      customFetch('/Manager/ResetPassword', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId, cmId: Id }),
      }).then(res => {
        AddEditStore.toggleModal(true, {
          modalType: 'notification',
          message: `Generated password: ${res.result}`,
        })();
      });
    });
  };

  handleResendLoginInformation = () => {
    const { userId, CMInfo } = AddEditStore;
    customFetch('/Manager/ResendLoginInformation', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ uId: userId, typistId: CMInfo.Id }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Resend login Information: Successfully!!`,
        duration: 5,
      });
    });
  };

  render() {
    const { CMInfo, errors } = AddEditStore;
    return (
      <Form name="login-information">
        <Row className="fluid-field">
          <Col span={13}>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={CMInfo.LoginRequired}
                onChange={AddEditStore.handleChecked('LoginRequired')}
                disabled={api.isTypist()}
              >
                Offer Online Account
              </Checkbox>
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '-6px' }} />}
                content="A login account offering will be sent to this account (MAIL-27)"
                position="top center"
              />
            </div>
            <div className="checkbox-field">
              <Checkbox
                checked={CMInfo.EnableLogin}
                onChange={AddEditStore.handleChecked('EnableLogin')}
                disabled={api.isTypist()}
              >
                Enable Login
                {CMInfo.EnableLoginDate
                  ? ' (' + moment(formatDate(CMInfo.EnableLoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                  : ''}
                {CMInfo.EnableLoginBy ? ' - ' + CMInfo.EnableLoginBy + ')' : ''}
              </Checkbox>
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '-6px' }} />}
                content="When enabling login, an activation email will be sent to this account business email (MAIL-27A)"
                position="top center"
              />
            </div>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Username"
              validateStatus={errors['duplicateEmail'] ? 'error' : null}
              help={errors['duplicateEmail']}
            >
              <Input
                size="large"
                value={CMInfo.EnableLogin ? (CMInfo.Username ? CMInfo.Username : CMInfo.Email || null) : null}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        {CMInfo.Id !== 0 && (
          <div className="fluid-field">
            <Button
              disabled={api.isTypist()}
              color="blue"
              onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleEditUser}
            >
              Edit Username
            </Button>
            <Button
              disabled={api.isTypist()}
              color="blue"
              onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleUnlockUser}
            >
              Unlock User
            </Button>
            <Button
              color="blue"
              onClick={() => {
                if (api.isTypist()) {
                  return AddEditStore.toggleModal(true, {
                    modalType: 'changePassword',
                  })();
                } else {
                  return !CMInfo.EnableLogin
                    ? this.notifyEnable
                    : AddEditStore.toggleModal(true, {
                        modalType: 'confirm',
                        message: `Do you want to reset password for user ${CMInfo.FirstName} ${CMInfo.LastName}? New password will be sent to the user automatically.`,
                        onOk: this.handleResetPassword,
                      })();
                }
              }}
            >
              Reset Password
            </Button>
            <Button color="blue" onClick={!CMInfo.EnableLogin ? this.notifyEnable : this.handleResendLoginInformation}>
              Resend Login Information
            </Button>
            {CMInfo.Username && (
              <Button
                color="blue"
                onClick={() =>
                  AddEditStore.toggleModal(true, {
                    modalType: 'passwordRsHistory',
                  })()
                }
              >
                Password Reset History
              </Button>
            )}
          </div>
        )}
      </Form>
    );
  }
}

export default LoginInformation;
