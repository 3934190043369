import { Spin } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Button, Divider, Header, Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import Modal from '../../shared/Modal/';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import LoginUserHistoryStore from './LoginUserHistoryStore';

const columns = [
  { title: 'Login Date' },
  { title: 'Logout Date' },
  { title: 'IP Address' },
  { title: 'Additional info' },
];

@observer
class ModalLoginUserHistory extends React.Component {
  componentDidMount() {
    LoginUserHistoryStore.refetch({ uId: this.props.modalParams.uId, curPage: 1 }, true);
  }
  renderTableHistory = () =>
    LoginUserHistoryStore.data.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>
          {record.LoginDate
            ? moment(formatDate(record.LoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.LogoutDate
            ? moment(formatDate(record.LogoutDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.IPAddress}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo}</Table.Cell>
      </Table.Row>
    ));
  handlePageClick = page => {
    LoginUserHistoryStore.refetch({ curPage: page });
  };
  render() {
    const { data, loading, curPage, totalItems, totalPage } = LoginUserHistoryStore;
    const { open, onCancel } = this.props;
    const { record } = this.props.modalParams;
    const colSpan = columns.length;

    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={onCancel}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={onCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-LoginHistory">
          <Header size="small">{`Login History - ${record.Email}`}</Header>
          <Divider />
          {loading && !data ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <div className={`Table-container`}>
              <Table sortable striped celled className={cx({ disabled: loading })}>
                <TableHeader columns={columns} />
                <Table.Body>
                  {data.itemList.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
                </Table.Body>
                <TableFooterPagination
                  colSpan={colSpan}
                  totalItems={totalItems}
                  currentPage={curPage}
                  totalPage={totalPage}
                  onPageClick={page => this.handlePageClick(page)}
                />
              </Table>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default ModalLoginUserHistory;
