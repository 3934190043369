import React from 'react';
import { Form, Input, notification } from 'antd';
import { observer } from 'mobx-react';

import AddEditStore from './store';
import { Icon, Button } from 'semantic-ui-react';
import Modal from '../../shared/Modal';
import { saveCaseManager } from './service';

@observer
class ModalChangePassword extends React.Component {
  state = {
    passwordStrength: 'short',
    passwordStrengthCheck: {
      eightCharacter: false,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    },
    progressBarWidh: 0,
    showPassword: false,
  };
  handleShowPassword = () => {
    const isShow = this.state.showPassword;
    this.setState({ showPassword: !isShow });
  };
  checkPasswordStrength = value => {
    const value_ = value.toLowerCase();
    const regExpWeak = /[a-zA-Z]/;
    const regExpMedium = /\d+/;
    const regExpStrong = /[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
    let strength = 0;
    let passwordStrengthCheck = {
      eightCharacter: value.length >= 8,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    };
    const weakPass = value_.match(regExpWeak);
    const mediumPass = value_.match(regExpMedium);
    const strongPass = value_.match(regExpStrong);
    const password = value.split('');
    password.forEach(i => {
      if (regExpWeak.test(i)) {
        if (weakPass) {
          if (/[A-Z]/.test(i)) {
            strength += 4;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 3;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        } else {
          if (/[A-Z]/.test(i)) {
            strength += 6;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 5;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        }
      } else if (regExpMedium.test(i)) {
        if (mediumPass) {
          strength += 4;
          passwordStrengthCheck.digit = true;
        } else {
          strength += 7;
          passwordStrengthCheck.digit = true;
        }
      } else if (regExpStrong.test(i)) {
        if (strongPass) {
          passwordStrengthCheck.specialCharacter = true;
          strength += 8;
        } else {
          passwordStrengthCheck.specialCharacter = true;
          strength += 10;
        }
      }
    });
    return {
      passwordStrengthCheck,
      strength: strength > 100 ? 100 : strength,
    };
  };
  handleFieldChangeNewPW = fieldName => event => {
    const value = event.target.value;
    if (fieldName === 'NewPassword') {
      const progress = document.querySelector('.progress');
      const { strength, passwordStrengthCheck } = this.checkPasswordStrength(value);
      AddEditStore.setFieldsValue({ passwordStrengthCheck });
      this.setState({ passwordStrengthCheck });
      if (value !== '') {
        if (strength <= 3) {
          this.setState({
            passwordStrength: 'short',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
        } else if (strength <= 25) {
          this.setState({
            passwordStrength: 'weak',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
        } else if (strength <= 50) {
          this.setState({
            passwordStrength: 'medium',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #FFCC33, #FF6600)';
        } else if (strength <= 75) {
          this.setState({
            passwordStrength: 'good',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #66FFFF, #3399FF)';
        } else if (strength <= 100) {
          this.setState({
            passwordStrength: 'strong',
            progressBarWidh: strength,
          });
          progress.style.background = 'linear-gradient(45deg, #99FF99, #66CC00)';
        } else {
          this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
        }
      } else {
        this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
      }
    }
    AddEditStore.handleFieldChangePW(fieldName, value);
  };

  handleSave = () => {
    const { errors, CMInfo } = AddEditStore;

    AddEditStore.setFieldsValue({ loadingSave: true });

    if (CMInfo.OldPassword || CMInfo.NewPassword) {
      if (CMInfo.NewPassword !== CMInfo.ConfirmNewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Password and confirm password does not match`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (!CMInfo.OldPassword && CMInfo.NewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Please enter your old password`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (CMInfo.OldPassword && !CMInfo.NewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Please enter your new password`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (CMInfo.NewPassword.length < 8) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Must contain at least 8 characters`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else {
        const { passwordStrengthCheck } = AddEditStore;
        if (!passwordStrengthCheck.digit) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.lowerCaseCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one lowercase letter.`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.upperCaseCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.specialCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,) `,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else {
          if (!!Object.keys(errors).length) {
            AddEditStore.setFieldsValue({ loadingSave: false });
            return this.showFormErrorNoti();
          } else {
            return this.actionSave({ ...CMInfo, IsChangePassword: true });
          }
        }
      }
    } else {
      if (!!Object.keys(errors).length) {
        AddEditStore.setFieldsValue({ loadingSave: false });
        return this.showFormErrorNoti();
      } else {
        return this.actionSave({ ...CMInfo, IsChangePassword: false });
      }
    }
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.destroy();
    notification.error({
      message: 'Error',
      description: message,
    });
  };

  actionSave = async body => {
    try {
      const respone = await saveCaseManager(body);
      if (respone.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Data has been saved successfully.`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false, loading: true });
        AddEditStore.handleFieldChangePW('OldPassword', null);
        AddEditStore.handleFieldChangePW('NewPassword', null);
        AddEditStore.handleFieldChangePW('ConfirmNewPassword', null);
        AddEditStore.toggleModal(false)();
        return AddEditStore.fetchCMData();
      }
    } catch (e) {
      AddEditStore.setFieldsValue({ loadingSave: false });
    }
  };

  handleCancel = () => {
    AddEditStore.handleFieldChangePW('OldPassword', null);
    AddEditStore.handleFieldChangePW('NewPassword', null);
    AddEditStore.handleFieldChangePW('ConfirmNewPassword', null);
    AddEditStore.toggleModal(false)();
  };

  render() {
    const { CMInfo, open } = AddEditStore;
    const { progressBarWidh, passwordStrengthCheck } = this.state;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={AddEditStore.toggleModal(false)}
        title="Reset Password"
        footer={
          <>
            <Button className="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button className="negative" onClick={this.handleCancel}>
              Cancel
            </Button>
          </>
        }
      >
        <div className="Modal-ChangePassword">
          <Form>
            <Form.Item label="Old Password">
              <Input
                size="large"
                value={CMInfo.OldPassword}
                onChange={AddEditStore.handleChangeFieldForPW('OldPassword')}
                type="password"
              />
            </Form.Item>
            <Form.Item label="New Password">
              <Input
                size="large"
                value={CMInfo.NewPassword}
                onChange={this.handleFieldChangeNewPW('NewPassword')}
                type={`${this.state.showPassword ? '' : 'password'}`}
                suffix={<Icon name="eye slash" inverted link onClick={this.handleShowPassword} />}
              />
              <div className="indicator">
                <div className="percent">
                  <div className="progress" style={{ width: `${progressBarWidh}%` }}></div>
                </div>
              </div>
              {CMInfo.NewPassword && (
                <p
                  className={`Field-text-password-${
                    this.state.passwordStrength === 'short'
                      ? 'short'
                      : this.state.passwordStrength === 'weak'
                      ? 'weak'
                      : this.state.passwordStrength === 'medium'
                      ? 'medium'
                      : this.state.passwordStrength === 'good'
                      ? 'good'
                      : this.state.passwordStrength === 'strong' && 'strong'
                  }`}
                >
                  {this.state.passwordStrength === 'short'
                    ? 'Your password is too short'
                    : this.state.passwordStrength === 'weak'
                    ? 'Your password is too week'
                    : this.state.passwordStrength === 'medium'
                    ? 'Your password is medium'
                    : this.state.passwordStrength === 'good'
                    ? 'Your password is good'
                    : this.state.passwordStrength === 'strong'
                    ? 'Your password is strong'
                    : ''}
                </p>
              )}
              <div className="List-password-strength" style={{ marginTop: '10px' }}>
                <label>
                  <input type="checkbox" name="" disabled checked={passwordStrengthCheck.eightCharacter} />
                  <i className="checked-icon"></i>
                  <span>Must contain at least 8 characters</span>
                </label>
                <label>
                  <input type="checkbox" name="" disabled checked={passwordStrengthCheck.specialCharacter} />
                  <i className="checked-icon"></i>
                  <span>Must contain at least one special character (!@#$%&*())</span>
                </label>
                <label>
                  <input type="checkbox" name="" disabled checked={passwordStrengthCheck.upperCaseCharacter} />
                  <i className="checked-icon"></i>
                  <span>Must contain at least one Uppercase letter (A through Z)</span>
                </label>
                <label>
                  <input type="checkbox" name="" disabled checked={passwordStrengthCheck.lowerCaseCharacter} />
                  <i className="checked-icon"></i>
                  <span>Must contain at least one lowercase letter (a through z)</span>
                </label>
                <label>
                  <input type="checkbox" name="" disabled checked={passwordStrengthCheck.digit} />
                  <i className="checked-icon"></i>
                  <span>Must contain at least one number (0 through 9)</span>
                </label>
              </div>
            </Form.Item>
            <Form.Item label="Confirm Password">
              <Input
                size="large"
                value={CMInfo.ConfirmNewPassword}
                onChange={AddEditStore.handleChangeFieldForPW('ConfirmNewPassword')}
                type="password"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ModalChangePassword;
