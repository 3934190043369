import { observer } from 'mobx-react';
import cx from 'classnames';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import AddEditStore from './store';
import { toJS } from 'mobx';
import Modal from '../../shared/Modal';
import { getClientById, loadTalkingPoint } from './service';

const columns = [{ title: 'Category' }, { title: 'Talking Point' }];

@observer
class ModelShowTalkingPoint extends React.Component {
  renderModalBody = (dataTalkingPoints, clientInfo) => {
    if (Array.isArray(dataTalkingPoints)) {
      if (dataTalkingPoints.length === 0) {
        return 'No Talking Points';
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataTalkingPoints.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{record.CategoryTitle}</Table.Cell>
                <Table.Cell>{record.TalkingPointText}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return 'No Talking Points';
    }
  };

  render() {
    const { open } = AddEditStore;
    const { dataTalkingPoints, clientInfo } = AddEditStore;
    return (
      <Modal visible={open} width={800} onCancel={AddEditStore.toggleModal(false)} title={'Talking Points'}>
        <div className={cx({ disabled: AddEditStore.loadingTalkingPoint })}>
          <div style={{ width: '100%' }}>
            Client Name: {clientInfo.Name} - Business Type:{' '}
            {clientInfo.ClientType == 1
              ? 'Insurer'
              : clientInfo.ClientType == 2
              ? 'Law Defendant'
              : clientInfo.ClientType == 3
              ? 'Law Plaintiff'
              : clientInfo.ClientType == 4
              ? 'Law Practice'
              : clientInfo.ClientType == 6
              ? 'Self Insurer'
              : clientInfo.ClientType == 7
              ? 'Interpreter Provider'
              : clientInfo.ClientType == 78
              ? 'Funding Provider'
              : 'Other'}{' '}
            - State: {clientInfo.State}
          </div>
          {this.renderModalBody(toJS(dataTalkingPoints, clientInfo))}
        </div>
      </Modal>
    );
  }
}

export default ModelShowTalkingPoint;
