import { RangePicker } from '@components/shared/tableToolbar/ToolbarComponents';
import { debounce, find, isNil } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';

import { Folders, InquiryStatuses } from './constants';
import Store from './Store';

@observer
class MailSearch extends React.Component {
  componentDidMount() {
    // Set Default filterInquiryStatus = Open when opening MailSearch page
    Store.setFieldsValue({ filterInquiryStatus: 0 });
  }

  handleChangeKeyword = (_, { value }) => {
    Store.setFieldsValue({ keyword: value });
  };

  handleOnChangePic = (_, { value }) => {
    Store.setFieldsValue({ filterPic: value });
  };

  handleOnChangeInquiryType = (_, { value }) => {
    Store.setFieldsValue({ filterInquiryType: value });
  };

  handleOnChangeInquiryStatus = (_, { value }) => {
    Store.setFieldsValue({ filterInquiryStatus: value });
  };

  handleOnChangeCaseType = (_, { value }) => {
    Store.setFieldsValue({ selectedFolder: value });
  };

  handleFilterReceivedDateChanged = (_, { value }) => {
    Store.setFieldsValue({ receivedDateRange: value });
  };

  handleFilterClosedDateChanged = (_, { value }) => {
    Store.setFieldsValue({ closedDateRange: value });
  };

  handleOnChangeCaseOwner = (_, { value }) => {
    Store.setFieldsValue({ filterCaseOwnerId: value });
  };

  handleOnChangeInquirySharedEmails = (_, { value }) => {
    Store.setFieldsValue({ filterInquirySharedEmails: value });
  };

  handleOnSearch = () => {
    Store.fetchMails(true);
  };

  handleOnClear = () => {
    Store.setFieldsValue({
      keyword: '',
      filterPic: '',
      filterInquiryType: '',
      receivedDateRange: '',
      closedDateRange: '',
      filterInquiryStatus: null,
      filterInquirySharedEmails: [],
      filterCaseOwnerId: null,
      selectedFolder: null,
    });
    Store.fetchMails(true);
  };

  renderLabel = (prefix, value, options) => {
    let text = null;
    if (!isNil(value)) {
      const item = find(options, o => o.value === value);
      if (item) {
        text = item.text;
      }
    }
    return `${prefix}: ${text || 'All'}`;
  };

  render() {
    const {
      keyword,
      assignedPicOptions,
      inquiryTypeOptions,
      assignedCaseTypeOptions,
      assignedInquiryTypeOptions,
      assignedInquiryStatusOptions,
      filterPic,
      filterInquiryType,
      filterInquiryStatus,
      filterCaseOwnerId,
      receivedDateRange,
      closedDateRange,
      selectedFolder,
      assignedCaseOwnerOptions,
      filterInquirySharedEmails,
      inquirySharedEmailOptions,
    } = Store;

    return (
      <Form className="mail-search">
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            icon="search"
            name="keyword"
            value={keyword}
            onChange={debounce(this.handleChangeKeyword, 500, {
              leading: true,
            })}
            placeholder="Search any keyword"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            selection
            value={selectedFolder}
            onChange={this.handleOnChangeCaseType}
            options={assignedCaseTypeOptions}
            clearable
            text={this.renderLabel('Case Type', selectedFolder, Folders)}
          />
          <Form.Dropdown
            selection
            value={filterInquiryStatus}
            onChange={this.handleOnChangeInquiryStatus}
            options={assignedInquiryStatusOptions}
            clearable
            text={this.renderLabel('Inquiry Status', filterInquiryStatus, InquiryStatuses)}
          />
          <Form.Dropdown
            search
            selection
            value={filterInquiryType}
            onChange={this.handleOnChangeInquiryType}
            options={assignedInquiryTypeOptions}
            clearable
            text={this.renderLabel('Inquiry Type', filterInquiryType, inquiryTypeOptions)}
          />
          <Form.Field>
            <Form.Dropdown
              search
              selection
              value={filterCaseOwnerId}
              onChange={this.handleOnChangeCaseOwner}
              options={assignedCaseOwnerOptions}
              clearable
              text={this.renderLabel('Case Owner', filterCaseOwnerId, assignedCaseOwnerOptions)}
            />
          </Form.Field>
          <Form.Dropdown
            search
            selection
            value={filterPic}
            onChange={this.handleOnChangePic}
            options={assignedPicOptions}
            clearable
            text={this.renderLabel('Action PIC', filterPic, assignedPicOptions)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field style={{ width: '20%' }}>
            <label>Received Dates:</label>
            <RangePicker
              placeholder="Filter by received date"
              value={receivedDateRange}
              className="Toolbar-range-picker"
              onChange={this.handleFilterReceivedDateChanged}
            />
          </Form.Field>
          <Form.Field style={{ width: '20%' }}>
            <label>Closed Dates:</label>
            <RangePicker
              placeholder="Filter by closed date"
              value={closedDateRange}
              className="Toolbar-range-picker"
              onChange={this.handleFilterClosedDateChanged}
            />
          </Form.Field>
          <Form.Field style={{ width: '40%' }}>
            <label>Receiver Emails</label>
            <Form.Dropdown
              className="receiver-emails-filter"
              search
              multiple
              selection
              placeholder="Filter by receiver emails"
              value={filterInquirySharedEmails}
              onChange={this.handleOnChangeInquirySharedEmails}
              options={inquirySharedEmailOptions}
              clearable
            />
          </Form.Field>
          <Form.Field className="button-container">
            <Button color="blue" content="Search" onClick={this.handleOnSearch} />
            <Button color="red" content="Clear" onClick={this.handleOnClear} />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default MailSearch;
