import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import SupplementaryReportsStore from './SupplementaryReportsStore';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = SupplementaryReportsStore;

    const caseNo = `Case S-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('PostSupplementary', caseNo);
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${id}&step=4`);
    });
    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = SupplementaryReportsStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/Supplementary/GetSupplementaryLogs';
  const body = { Id: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1250}
      title={title}
      footer={
        <React.Fragment>
          {!api.isTypist() && (
            <Button color="blue" onClick={openCaseCorrespondence}>
              <Icon name="table" /> View Case Correspondence
            </Button>
          )}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress
        progressList={api.SUPP_CaseProgress.data.itemList}
        currentStatus={modalParams.status}
        url={url}
        body={body}
        showHistory
        showPrepaymentStatuses
      />
    </Modal>
  );
}

export default ModalCaseProgress;
