import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { TableHeader, TableEmptyMessage } from '../../../shared/table';

class RecentCompanyChangeForm extends React.Component {
  renderTableEmpty = columns => {
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          <TableEmptyMessage colSpan={columns.length} />
        </Table.Body>
      </Table>
    );
  };

  checkIsDateString = (datesString, showTime = true) => {
    const format = 'DD/MM/YYYY HH:mm';
    if (datesString) {
      if (datesString.startsWith('/Date')) {
        return moment(datesString).format(format);
      } else {
        return moment(datesString, 'DD/MM/YYYY HH:mm').format(format);
      }
    } else {
      return 'Invalid date';
    }
  };
  renderRecord = record => {
    if (record === 'email') {
      return 'Email';
    } else if (record === 'sms') {
      return 'SMS';
    } else return record;
  };

  renderTableData = (dataRecentChange, columns) => {
    console.log('dataRecentChange', dataRecentChange);
    console.log('columns', columns);
    if (Array.isArray(dataRecentChange)) {
      if (dataRecentChange.length === 0) {
        return this.renderTableEmpty(columns);
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataRecentChange.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{this.renderRecord(record.CompanyName)}</Table.Cell>
                <Table.Cell>{this.renderRecord(record.CMFullName)}</Table.Cell>
                <Table.Cell>{this.renderRecord(record.CreatedByName)}</Table.Cell>
                <Table.Cell key={idx}>{this.checkIsDateString(record.CreatedDate, this.props.showDateTime)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return this.renderTableEmpty(columns);
    }
  };
  render() {
    const { dataRecentChange, columns } = this.props;
    return this.renderTableData(dataRecentChange, columns);
  }
}
export default RecentCompanyChangeForm;
