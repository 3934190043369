import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import store from './store';

export const Upload = ({
  multiple = false,
  placeholder,
  reportType,
  attachments,
  type,
  option,
  functionCallback,
  disabled,
  disabledDelete,
  uploadTitle,
  disableDragDrop,
  hideDownload,
}) => {
  const handleOpenModalUpload = () => {
    store.toggleModalModalUpload(true, {
      reportType,
      multiple,
      attachments,
      placeholder,
      type,
      option,
      functionCallback,
      disabled,
      disabledDelete,
      uploadTitle,
      disableDragDrop,
      hideDownload,
    });
  };
  const styleBtnDownload = attachments => {
    return !!attachments.length ? { cursor: 'pointer', width: 150 } : { cursor: 'not-allowed', width: 150 };
  };
  const handleDownloadFiles = () => {
    return;
  };
  return (
    <div className="Upload-File">
      <label
        className="Button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleOpenModalUpload();
        }}
      >
        <span>{disabled ? 'View' : 'Upload/View'}</span>
      </label>
      {!hideDownload && (
        <label
          className="Button"
          style={styleBtnDownload(attachments)}
          onClick={() => {
            !!attachments.length && handleDownloadFiles();
          }}
          disabled
        >
          <Icon name="download" />
          <span>Download All</span>
        </label>
      )}
      <p>{placeholder}</p>
    </div>
  );
};

export default observer(
  ({
    type,
    option,
    multiple = false,
    functionCallback,
    disabled = false,
    disabledDelete = false,
    uploadTitle,
    disableDragDrop,
    hideDownload,
  }) => {
    const { Attachments = [] } = store.data;

    const attachments = Attachments.filter(({ AttachmentType }) => AttachmentType === type);

    const placeholder =
      attachments && !!attachments.length
        ? `${attachments.length} ${attachments.length > 1 ? 'attachments' : 'attachment'} ${
            attachments.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;

    return (
      <React.Fragment>
        <Upload
          multiple={multiple}
          placeholder={placeholder}
          reportType={type}
          attachments={attachments}
          type={type}
          option={option}
          functionCallback={functionCallback}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          disableDragDrop={disableDragDrop}
          hideDownload={hideDownload}
        />
      </React.Fragment>
    );
  },
);
