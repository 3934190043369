import './DashboardHeader.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ui from '../../../stores/dashboard';
import FullscreenIcon from './FullscreenIcon';
import ShowSidebarIcon from './ShowSidebarIcon';
import Tabs from './Tabs';

@observer
class DashboardHeader extends React.Component {
  render() {
    const {
      sidebar: { autohide: sidebarAutohide },
    } = ui;
    return (
      <div className={cx('DashboardHeader', { sidebarAutohide })}>
        <Tabs />
        <FullscreenIcon />
        {sidebarAutohide && <ShowSidebarIcon />}
      </div>
    );
  }
}

export default DashboardHeader;
