import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import store from './store';

const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

@observer
class ThisMonthSessionsSection extends React.Component {
  handleOpenChartSession = name => {
    return store.refetchDataChart({
      isShowChart: true,
      stateDataChart: name,
      curPageChart: 1,
      totalPageChart: 0,
      totalItemChart: 0,
    });
  };
  render() {
    const { statistics, dataTarget } = store;

    return (
      <section className="this-month-sessions-statistics">
        <h6 className="session-title">THIS MONTH SESSIONS</h6>
        <p className="ma-0">(Monthly Slot Target/This Month/Today Slots Added)</p>
        <div className="session-details-group">
          {STATES.map((i, idx) => (
            <div key={idx} className="session-details">
              <div className="session-details-title" onClick={() => this.handleOpenChartSession(i)}>
                {i}
              </div>
              <div className="session-details-count" onClick={() => this.handleOpenChartSession(i)}>
                {toJS(dataTarget.itemList)
                  .filter(item => item.Key === i)
                  .map(item => item.Target)[0] || 0}
                /{statistics.myThisMonthSessions?.[i] || 0}/{statistics.myTodaySessions?.[i] || 0}
              </div>
            </div>
          ))}
          {/* <div className="session-details">
            <div
              className="session-details-title"
              onClick={() => this.handleOpenChartSession('NSW')}
            >
              NSW
            </div>
            <div className="session-details-count">
              300/{statistics.myThisMonthSessions?.NSW || 0}/
              {statistics.myTodaySessions?.NSW || 0}
            </div>
          </div>
          <div className="session-details">
            <div
              className="session-details-title"
              onClick={() => this.handleOpenChartSession('VIC')}
            >
              VIC
            </div>
            <div className="session-details-count">
              800/{statistics.myThisMonthSessions?.VIC || 0}/
              {statistics.myTodaySessions?.VIC || 0}
            </div>
          </div>
          <div className="session-details">
            <div
              className="session-details-title"
              onClick={() => this.handleOpenChartSession('ACT')}
            >
              ACT
            </div>
            <div className="session-details-count">
              100/{statistics.myThisMonthSessions?.ACT || 0}/
              {statistics.myTodaySessions?.ACT || 0}
            </div>
          </div>
          <div className="session-details">
            <div
              className="session-details-title"
              onClick={() => this.handleOpenChartSession('QLD')}
            >
              QLD
            </div>
            <div className="session-details-count">
              180/{statistics.myThisMonthSessions?.QLD || 0}/
              {statistics.myTodaySessions?.QLD || 0}
            </div>
          </div>
          <div className="session-details">
            <div
              className="session-details-title"
              onClick={() => this.handleOpenChartSession('WA')}
            >
              WA
            </div>
            <div className="session-details-count">
              100/{statistics.myThisMonthSessions?.WA || 0}/
              {statistics.myTodaySessions?.WA || 0}
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default ThisMonthSessionsSection;
