import { observer } from 'mobx-react';
import React from 'react';
import TableToolbar from '../../shared/tableToolbar';
import AMATimeUnitModifyStore from './Store';
import * as api from '../../../stores/api';

@observer
class AMATimeUnitModifyToolbar extends React.Component {
  handleSearchChange = (_, { value }) => {
    AMATimeUnitModifyStore.setFieldsValue({ keyword: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    AMATimeUnitModifyStore.refetch({}, true);
  };

  handleReset = () => {
    AMATimeUnitModifyStore.resetStore();
  };

  handleCreate = () => {
    return AMATimeUnitModifyStore.toggleModal(true, { modalType: 'create' })();
  };

  render() {
    const { keyword, loading } = AMATimeUnitModifyStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.HighLightButton className="positive" disabled={loading} onClick={this.handleCreate}>
              Add Code
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default AMATimeUnitModifyToolbar;
