import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import store from './store';

const columns = [
  // { title: 'UserId' },
  { title: 'Username' },
  { title: 'Action Type' },
  { title: 'Old Value' },
  { title: 'New Value' },
  { title: 'Updated At' },
  { title: 'Updated By' },
];

@observer
class TableData extends React.Component {
  handlePageClick = page => {
    store.refetch({ curPage: page });
  };

  renderTableBody = data => {
    return data.map(record => (
      <Table.Row key={record.Id}>
        {/* <Table.Cell>{record.UserId}</Table.Cell> */}
        <Table.Cell>{record.UserName}</Table.Cell>
        <Table.Cell>{record.ActionType}</Table.Cell>
        <Table.Cell>{record.OldValue}</Table.Cell>
        <Table.Cell>{record.NewValue}</Table.Cell>
        <Table.Cell>{moment(record.Updated).format('DD/MM/YYYY - HH:mm')}</Table.Cell>
        <Table.Cell>{record.UpdateBy}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, data, totalItems } = store;
    const colSpan = columns.length;

    return (
      <Table striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={columns} />
        <Table.Body>
          {!loading && !!data.length ? this.renderTableBody(data) : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage}
          totalPage={store.totalPage}
          onPageClick={this.handlePageClick}
          totalItems={totalItems}
        />
      </Table>
    );
  }
}

export default TableData;
