import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import ClinicalRecordStore from './ClinicalRecordStore';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const { toggleModal, modalParams } = ClinicalRecordStore;
    localStorage.setItem('ClinicalRecordCaseId', modalParams.caseNo);
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${modalParams.id}&step=4`);
    });
    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = ClinicalRecordStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = `/api/ClinicalNoteLog?clinicalNoteId=${modalParams.id}`;
  const param = { method: 'GET' };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1250}
      title={title}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={openCaseCorrespondence}>
            <Icon name="table" /> View Case Correspondence
          </Button>
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress
        progressList={api.CR_CaseProgress.data.itemList}
        currentStatus={modalParams.status}
        param={param}
        showHistory
        url={url}
      />
    </Modal>
  );
}

export default ModalCaseProgress;
