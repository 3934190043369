import React from 'react';

const Icon8 = ({ name, title, materialIcon }) => {
  if (name === '') {
    return '';
  }

  if (materialIcon) {
    const iconName = require(`assets/icon/${name}.png`);
    return <img src={iconName} title={title} alt={title} />;
  }

  return <img src={`https://img.icons8.com/dusk/65/000000/${name}.png`} title={title} />; // eslint-disable-line jsx-a11y/alt-text
};

export default Icon8;
