import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';

import dashboard from '../../../stores/dashboard';
import TableToolbar from '../../shared/tableToolbar';
import ClaimantsStore from './ClaimantsStore';
import * as api from '../../../stores/api';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class CaseManagersToolbar extends React.Component {
  componentDidMount() {
    ClaimantsStore.fetchClaimantGroup(true);
  }

  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    ClaimantsStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleReset = () => {
    ClaimantsStore.resetStore();
    ClaimantsStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    ClaimantsStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    localStorage.removeItem('ClaimantName');
    dashboard.close('/view/add-edit-claimant-2', true);
    setTimeout(() => {
      dashboard.open('/view/add-edit-claimant-2?action=create');
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    ClaimantsStore.refetch({ curPage: 1 }, true);
  };

  handleChangeSearchDropdown = e => {
    ClaimantsStore.refetchSearch({
      keywordSearch: e.target.value,
      keyword: e.target.value,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(ClaimantsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      FullName: ``,
      Telephone: ``,
      Email: ``,
    });
    return array.map(opt => ({
      text: `${
        opt.Id === `All`
          ? `All`
          : `${opt.Id || ``}
      ${`Name: ${opt.FullName || ``}`}
      ${`Phone: ${opt.Telephone || ``}`}
      ${`Email: ${opt.Email || ``}`}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FullName || ''}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const claimant = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (claimant.id === `All` || value === `All`) {
      ClaimantsStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      ClaimantsStore.resetSearch();
    } else {
      ClaimantsStore.refetch(
        {
          keyword: claimant.id,
          curPage: 1,
          numberSearch: 30,
          keywordSearch: `${claimant.id}`,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(ClaimantsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', Name: ``, Email: `` });
    let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleScroll = event => {
    const target = event.target;
    const number = ClaimantsStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ClaimantsStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  handleRefresh = () => {
    ClaimantsStore.refetch(
      {
        dataSearch: null,
        data: null,
      },
      true,
    );
  };

  render() {
    const { loading, filter, keyword, loadingSearch, totalItems } = ClaimantsStore;
    return (
      <>
        <TableToolbar.Container>
          <TableToolbar.Left onSubmit={this.handleSubmit}>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              selectOnNavigation={false}
              search
              disabled={loading}
              allowAdditions
              onScroll={this.handleScroll}
              additionLabel=""
              value={this.renderSearchText(keyword)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: '5px' }}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() &&  <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.openCaseCreate()}
            >
              Add New Claimant
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            disabled={loading}
            label={`Filter by`}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
        </TableToolbar.Container>
      </>
    );
  }
}

export default CaseManagersToolbar;
