import { action, observable } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import { notification } from 'antd';
import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import {
  getClaimInfo,
  saveClaimant,
  getAssessmentHistory,
  fetchGreetingTitlesByType,
  getConversationLog,
  saveConversationLog,
  allowCMViewConversationLog,
  deleteConversationLog,
  getStaff,
  getStaffAll,
  getCityList,
} from './service';
import { formatDate } from '../../../utils/functions';

const INIT_CLAIM = {
  Address: null,
  Suburb: null,
  Postcode: null,
  DOB: null,
  Email: null,
  FirstName: null,
  FullName: null,
  HomePhone: null,
  Id: 0,
  LastName: null,
  NonDOB: true,
  Telephone: null,
  Title: 'Mr',
};

const BODY_CONVERSATIONLOG = {
  CallerSender: '',
  CallerSenderName: '',
  CallerSenderType: 1,
  ClaimantId: 0,
  Content: '',
  ConversationType: 1,
  Id: 0,
  Receiver: '',
  ReceiverName: '',
  ReceiverType: 1,
  SentDate: moment(),
  Subject: '',
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: '',
  hour: 0,
  minute: 0,
  receiverId: 0,
};

const VALIDATE_RULES = {
  FirstName: 'required',
  LastName: 'required',
  Telephone: 'max:10',
  HomePhone: 'max:10',
  Email: 'email',
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
};

class AddEdditClaimStore {
  @observable loading = true;
  @observable claimantInfo = INIT_CLAIM;

  @observable claimantInfo_ = INIT_CLAIM;
  @observable isChanged = {};

  //Save
  @observable loadingSave = false;

  //History
  @observable loadingHistory = true;
  @observable dataHistory = null;

  //Check duplicate
  @observable loadingCheckDuplicate = false;
  cityList;
  //Error
  @observable errors = {};

  //Correspondence
  @observable loadingConversation = false;
  @observable conversationParam = BODY_CONVERSATIONLOG;
  @observable conversationList = null;
  @observable staffAllByClientList = null;
  @observable staffList = null;
  @observable staffAllList = null;
  @observable cityList = null;

  //status histories
  @observable loadingHistory = false;
  @observable dataHistory = null;

  //Modal
  @observable open = false;
  @observable modalParams = {};

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };
  //

  @action fetchHistory = id => {
    this.loadingHistory = true;
    customFetch('/PatientInfo/GetStatusHistory?type=2&itemId=' + id, {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    }).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  renderDOB = date => {
    const date_ = date ? date : `N/A`;
    if (date_ === `N/A`) {
      return date_;
    } else {
      return moment(formatDate(date_), 'DD MMM YYYY');
    }
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };
  @observable greetingTitle = [];
  @action fetchAll = claimantId => {
    this.loading = true;
    Promise.all([
      getClaimInfo(claimantId),
      getAssessmentHistory(claimantId),
      fetchGreetingTitlesByType(),
      getConversationLog(claimantId),
      getStaff(),
      getStaffAll(),
      getCityList(),
    ]).then(
      action(([info, assessmentHistory, greetingTitle, conversation, staffList, staffAllList, cityList]) => {
        this.greetingTitle = greetingTitle.itemList;
        this.claimantInfo = { ...info, DOB: this.renderDOB(info.DOB) };
        this.dataHistory = assessmentHistory;
        this.loadingHistory = false;
        this.claimantInfo_ = { ...info, DOB: this.renderDOB(info.DOB) };
        this.conversationList = conversation;
        this.staffList = staffList;
        this.staffAllList = staffAllList;
        this.cityList = cityList;
        this.loading = false;
      }),
    );
  };

  @action handleValidate = (field, value) => {
    const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);

    if (validation.passes()) {
      delete this.errors[field];
    } else {
      this.errors[field] = validation.errors.first(field);
    }
  };

  @action initClamantData = () => {
    this.claimantInfo = INIT_CLAIM;
    this.claimantInfo_ = INIT_CLAIM;
    this.loading = true;
    this.errors = {};
    Promise.all([fetchGreetingTitlesByType()]).then(
      action(([greetingTitle]) => {
        this.greetingTitle = greetingTitle.itemList;
        this.loading = false;
      }),
    );
  };

  handleFieldChange = fieldName =>
    action(event => {
      if (fieldName === 'HomePhone' || fieldName === 'Telephone') {
        const value = event ? (event.target ? event.target.value : event) : null;

        this.claimantInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');

        if (this.claimantInfo[fieldName] !== this.claimantInfo_[fieldName]) {
          this.isChanged[fieldName] = true;
        } else {
          delete this.isChanged[fieldName];
        }
      } else if (fieldName === 'DOB') {
        const value = event ? (event.target ? event.target.value : event) : null;
        const today = moment();
        const isBefore = moment(value).isBefore(today);
        this.claimantInfo[fieldName] = value;
        if (this.claimantInfo[fieldName] !== this.claimantInfo_[fieldName]) {
          this.isChanged[fieldName] = true;
        } else {
          delete this.isChanged[fieldName];
        }
        if (!isBefore && !!value) {
          this.errors = {
            ...this.errors,
            DOB: 'Date of Birth cannot be a future date',
          };
        } else {
          delete this.errors[fieldName];
        }
        return;
      } else {
        const value = event ? (event.target ? event.target.value : event) : null;

        this.claimantInfo[fieldName] = value;

        if (this.claimantInfo[fieldName] !== this.claimantInfo_[fieldName]) {
          this.isChanged[fieldName] = true;
        } else {
          delete this.isChanged[fieldName];
        }
      }
      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.claimantInfo[fieldName]);
      }
    });

  handleChangeRequiresGuardian = fieldName =>
    action(event => {
      this.claimantInfo[fieldName] = !this.claimantInfo[fieldName];
    });

  @action resetStore = () => {
    this.loading = true;
    this.dataHistory = null;
    this.claimantInfo = INIT_CLAIM;
    this.claimantInfo_ = INIT_CLAIM;
    this.loadingHistory = true;
  };

  convertBirthDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };

  @action handleSaveClaimant = () => {
    this.loadingSave = true;
    const birthDate = this.convertBirthDate(this.claimantInfo.DOB);
    const body = {
      ...this.claimantInfo,
      DOB: birthDate,
    };
    return saveClaimant(body);
  };

  //Case Correspondence
  @action handleReloadConversation = claimantId => {
    this.loadingConversation = true;
    Promise.all([getConversationLog(claimantId)]).then(
      action(([list]) => {
        this.conversationList = list;
        this.loadingConversation = false;
      }),
    );
  };

  @action handleDeleteConversation = id => {
    this.loadingConversation = true;
    deleteConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.claimantInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAllowConversation = id => {
    this.loadingConversation = true;
    allowCMViewConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.claimantInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleUpdateInfo = (key, value) => {
    const updatedInfo = { ...this.claimantInfo };
    updatedInfo[key] = value;
    this.claimantInfo = updatedInfo;
  };

  @action handleEnableCase = id => {
    customFetch('/PatientInfo/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(resp => {
      if (resp.status === 'success') {
        this.handleUpdateInfo('IsActive', true);
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: resp.message,
          duration: 5,
        });
      }
    });
  };

  @action handleDisableCase = id => {
    customFetch('/PatientInfo/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(resp => {
      if (resp.status === 'success') {
        this.handleUpdateInfo('IsActive', false);
      } else {
        notification.destroy();
        notification.error({
          description: 'Error',
          message: resp.message,
          duration: 5,
        });
      }
    });
  };

  @action handleResetBodyConversation = () => {
    this.conversationParam = {
      ...BODY_CONVERSATIONLOG,
      CallerSender: this.claimantInfo.Id,
      CallerSenderName: this.claimantInfo.FirstName + ' ' + this.claimantInfo.LastName,
      Receiver: this.claimantInfo.Id,
      ReceiverName: this.claimantInfo.FirstName + ' ' + this.claimantInfo.LastName,
    };
  };

  @action handleAddConversation = body => {
    this.loadingConversation = true;
    if (this.conversationParam.Receiver && this.conversationParam.CallerSender) {
      saveConversationLog(body).then(res => {
        if (res.status === 'success') {
          this.handleReloadConversation(this.claimantInfo.Id);
          notification.destroy();
          notification.success({
            description: body.Id === 0 ? 'Add Conversation Log successfully!' : 'Update Conversation Log successfully!',
            message: 'Success',
            duration: 5,
          });
        }
      });
    } else {
      notification.error({
        description: 'Invalid form',
        message: 'Error',
        duration: 5,
      });
    }
  };

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.conversationParam[fieldName] = value;
    });

  handleFieldChangeConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'ConversationType') {
        this.conversationParam[fieldName] = event;
      } else if (fieldName === 'Receiver') {
        this.conversationParam.Receiver = value.key;
        this.conversationParam.ReceiverName = value.label;
      } else if (fieldName === 'CallerSender') {
        this.conversationParam.CallerSender = value.key;
        this.conversationParam.CallerSenderName = value.label;
      } else {
        if (fieldName === 'CallerSenderType') {
          if (value === 1) {
            this.conversationParam.CallerSender = this.claimantInfo.Id;
            this.conversationParam.CallerSenderName = this.claimantInfo.FirstName + ' ' + this.claimantInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
            this.conversationParam.CallerSender = api.currentUser.data.id;
          }
        }
        if (fieldName === 'ReceiverType') {
          if (value === 1) {
            this.conversationParam.Receiver = this.claimantInfo.Id;
            this.conversationParam.ReceiverName = this.claimantInfo.FirstName + ' ' + this.claimantInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.ReceiverName = api.currentUser.data.FullName;
            this.conversationParam.Receiver = api.currentUser.data.id;
          }
        }
        this.conversationParam[fieldName] = value;
      }
    });
  //End Case Correspondence
}

export default new AddEdditClaimStore();
