import './Header.scss';
import React from 'react';
import cx from 'classnames';

const Header = ({ title, text, statuses = [], isRequired = true }) => (
  <div className="ant-collapse-header-content">
    <span className={cx('title', { required: isRequired })}>{title}</span>
    <div className="status">
      {statuses?.length > 0 ? (
        statuses.map(s => (
          <div key={s.key ?? s.id}>
            <span className="text">{s.text}</span>
          </div>
        ))
      ) : (
        <React.Fragment>
          <span className="text">{text}</span>
        </React.Fragment>
      )}
    </div>
  </div>
);

export default React.memo(Header);
