import { observer } from 'mobx-react';
// import { action } from 'mobx';
import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { Spin, notification } from 'antd';
import Modal from '../../shared/Modal/';
import customFetch from '../../../utils/customFetch';
import CaseManagersStore from './CaseManagersStore';

const stateOptions = [
  {
    text: 'ACT',
    value: 'ACT',
  },
  {
    text: 'NSW',
    value: 'NSW',
  },
  {
    text: 'VIC',
    value: 'VIC',
  },
  {
    text: 'QLD',
    value: 'QLD',
  },
  {
    text: 'SA',
    value: 'SA',
  },
  {
    text: 'WA',
    value: 'WA',
  },
  {
    text: 'TAS',
    value: 'TAS',
  },
  {
    text: 'NT',
    value: 'NT',
  },
];

const roles = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'magstaff', text: 'MAG Staff', value: 'magstaff' },
  { key: 'casemng', text: 'Case Manager', value: 'casemng' },
  { key: 'doctor', text: 'Doctor', value: 'doctor' },
  { key: 'accounting', text: 'Accounting', value: 'accounting' },
  { key: 'typist', text: 'Typist', value: 'typist' },
];
@observer
class ModalAssignCM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      State: '',
      AssignAMId: '',
    };
  }

  componentDidMount() {
    CaseManagersStore.fetchStaffAM();
  }

  handleOnChangeDropDownState = (e, { value }) => {
    this.setState({ ...this.state, State: value });
  };

  handleOnChangeDropDownAssignAM = (e, { value }) => {
    this.setState({ ...this.state, AssignAMId: value });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  handleUpdate = async () => {
    CaseManagersStore.setFieldsValue({ loading: true });
    const { State, AssignAMId } = this.state;
    try {
      await customFetch('/Staff/BulkAssignAccountManager', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ state: State, assignAMId: AssignAMId }),
      }).then(respone => {
        if (respone.status === 'success') {
          notification.destroy();
          notification.success({
            description: 'Assign Account Manager successfully!',
            message: 'Success',
            duration: 5,
          });
          this.props.onCancel();
        }
      });
      await CaseManagersStore.fetchCase({}, true);
    } finally {
      CaseManagersStore.setFieldsValue({ loading: false });
    }
  };

  render() {
    const { open, staffListAM, loading } = CaseManagersStore;
    const { UserName, Email } = this.state;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={600}
          min-height={800}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleUpdate}>
                <Icon name="save" /> Save
              </Button>
              <Button color="red" onClick={this.onCancel}>
                <Icon name="close" /> Close
              </Button>
            </React.Fragment>
          }
          title="Assign Account Manager"
        >
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form>
              <Form.Dropdown
                required
                error={
                  !this.state.State
                    ? {
                        content: 'Please select a state',
                      }
                    : null
                }
                fluid
                label="State"
                placeholder="Select a state"
                id="form-input-first-name"
                search
                selection
                value={this.state.State}
                onChange={this.handleOnChangeDropDownState}
                options={stateOptions}
              />
              <Form.Dropdown
                required
                error={
                  !this.state.AssignAMId
                    ? {
                        content: 'Please select a Account Manager',
                      }
                    : null
                }
                fluid
                label="Account Manager"
                placeholder="Select a Account Manager"
                id="form-input-first-name"
                search
                selection
                value={this.state.AssignAMId}
                onChange={this.handleOnChangeDropDownAssignAM}
                options={staffListAM}
              />
              <div style={{ width: '100%', height: '250px' }}></div>
            </Form>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalAssignCM;
