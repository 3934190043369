import React from 'react';
import uploadStore from './UploadStore';
import { observer } from 'mobx-react';
import * as api from '@stores/api';
import UploadPaperwork from './UploadPaperwork';
import UploadDictations from './UploadDictations';
import UploadReportDrafts from './UploadReportDrafts';
import UploadReports from './UploadReports';
import UploadCorrectedReport from './UploadCorrectedReport';

function ModelUploadFile(props) {
  const renderModel = () => {
    if (uploadStore.modalParams.serviceType === 'Assessment') {
      switch (uploadStore.modalParams.status) {
        case api.imeStatus.APPOINTMENT_CONFIRMED:
          return <UploadPaperwork refetchTable={props.refetchTable} />;
        case api.imeStatus.ATTENDED:
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadDictations refetchTable={props.refetchTable} />;
        case api.imeStatus.DICTATION_RECEIVED:
          if (api.isDoctor()) {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadReportDrafts refetchTable={props.refetchTable} />;
        case api.imeStatus.REPORT_RETURNED_FROM_DOCTOR:
          return <UploadReports refetchTable={props.refetchTable} />;
        case api.imeStatus.REPORT_DRAFT_RECEIVED:
          return <UploadCorrectedReport refetchTable={props.refetchTable} />;
        default:
          return null;
      }
    }
    if (uploadStore.modalParams.serviceType === 'FileReview') {
      switch (uploadStore.modalParams.status) {
        case api.GetFRStatusText(api.frStatus.REPORT_REVIEW_REQUESTED):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadPaperwork refetchTable={props.refetchTable} />;
        case api.GetFRStatusText(api.frStatus.REQUEST_SENT_PROVIDER):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadDictations refetchTable={props.refetchTable} />;
        case api.GetFRStatusText(api.frStatus.DICTATION_RECEIVED):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadReportDrafts refetchTable={props.refetchTable} />;
        case api.GetFRStatusText(api.frStatus.REPORT_RETURNED_FROM_DOCTOR):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadReports refetchTable={props.refetchTable} />;
        case api.GetFRStatusText(api.frStatus.REPORT_DRAFT_RECEIVED):
          return <UploadCorrectedReport refetchTable={props.refetchTable} />;
        default:
          return null;
      }
    }
    if (uploadStore.modalParams.serviceType === 'Supplementary') {
      switch (uploadStore.modalParams.status) {
        case api.GetSUPPStatusText(api.suppStatus.REPORT_REVIEW_REQUESTED):
          return <UploadPaperwork refetchTable={props.refetchTable} />;
        case api.GetSUPPStatusText(api.suppStatus.REQUEST_SENT_TO_PROVIDER):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadDictations refetchTable={props.refetchTable} />;
        case api.GetSUPPStatusText(api.suppStatus.DICTATION_RECEIVED):
          if (api.isDoctor() && uploadStore.modalParams.service === 'UploadCorrectedReport') {
            return <UploadCorrectedReport refetchTable={props.refetchTable} />;
          }
          return <UploadReportDrafts refetchTable={props.refetchTable} />;
        case api.GetSUPPStatusText(api.suppStatus.REPORT_RETURNED_FROMDOCTOR):
          return <UploadReports refetchTable={props.refetchTable} />;
        case api.GetSUPPStatusText(api.suppStatus.REPORT_DRAFT_RECEIVED):
          return <UploadCorrectedReport refetchTable={props.refetchTable} />;
        default:
          return null;
      }
    }
  };

  return renderModel();
}

export default observer(ModelUploadFile);
