import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';
import moment from 'moment';

class InvoicesStore {
  @observable loading = true;
  @observable data = null;
  @observable loadingStaff = true;
  @observable dataStaff = null;
  @observable dataHistory = null;
  @observable loadingHistory = true;

  @observable loadingInvoiceStatus = true;
  @observable loadingXeroStatus = true;
  @observable loadingPaymentStatus = true;
  @observable loadingServiceType = true;
  @observable dataInvoiceStatus = null;
  @observable dataXeroStatus = null;
  @observable dataServiceType = null;
  @observable dataPaymentStatus = null;

  @observable actionRequiredById = '';
  @observable paymentFilterType = 'Unknown';
  @observable filterInvoiceStatus = 'All';
  @observable filterStatus = 'All';
  @observable filterType = 'All';
  @observable filterTypeText = 'All';
  @observable sortDirection = 'descending';
  @observable keyword = '';
  @observable sortColumn = 'Last Update';
  @observable sortKey = 'UpdatedDate';
  @observable actionRequired = false;
  @observable fromTo = ``;
  @observable requestDateTimeFrom = null;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable Total = 0;
  @observable StrTotal = '$0';
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  ///Invoice/GetInvoiceCount //ServiceType
  ///Invoice/GetInvoicePaymentStatusCount //Payment
  ///Invoice/GetInvoiceStatusCount  //InvoiceStatus
  ///Invoice/GetFilterInvoiceStatusCount //Xero

  @action fetchServiceType = () => {
    this.loadingServiceType = true;
    customFetch(`/Invoice/GetInvoiceCount`, {
      method: `POST`,
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.loadingServiceType = false;
        this.dataServiceType = data;
      }),
    );
  };

  @action fetchPaymentStatus = () => {
    this.loadingPaymentStatus = true;
    customFetch(`/Invoice/GetInvoicePaymentStatusCount`, {
      method: `POST`,
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.loadingPaymentStatus = false;
        this.dataPaymentStatus = data;
      }),
    );
  };

  @action fetchInvoiceStatus = () => {
    this.loadingInvoiceStatus = true;
    customFetch(`/Invoice/GetInvoiceStatusCount`, {
      method: `POST`,
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.loadingInvoiceStatus = false;
        this.dataInvoiceStatus = data;
      }),
    );
  };

  @action fetchXeroStatus = () => {
    this.loadingXeroStatus = true;
    customFetch(`/Invoice/GetFilterInvoiceStatusCount`, {
      method: `POST`,
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.loadingXeroStatus = false;
        this.dataXeroStatus = data;
      }),
    );
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
    };
  };

  @action fetchInvoicesStaff = () => {
    this.loadingStaff = true;
    customFetch('/Invoice/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        // Action Required
        this.dataStaff = data;
        this.loadingStaff = false;
      }),
    );
  };

  @action fetchInvoices = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    customFetch('/Invoice/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        FilterInvoiceStatus: this.filterInvoiceStatus,
        FilterStatus: this.filterStatus,
        FilterType: this.filterType,
        FilterTypeText: this.filterTypeText,
        Keyword: this.keyword,
        CurPage: this.curPage,
        ActionRequired: this.actionRequiredById ? true : false,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        From: fromDate,
        To: toDate,
        RequestDateTimeFrom: this.requestDateTimeFrom,
        PaymentFilterType: this.paymentFilterType,
        ActionRequiredById: this.actionRequiredById,
      }),
    }).then(
      action(data => {
        this.fetchInvoiceStatus();
        this.fetchInvoicesStaff();
        this.fetchXeroStatus();
        this.fetchServiceType();
        this.fetchPaymentStatus();
        this.Total = data.sumTotal;
        this.StrTotal = data.strSumTotal;
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  ///Invoice/GetInvoiceCount

  @action fetchInvoicesHistory = (reminderType, objectId) => {
    reminderType = 0;
    this.loadingHistory = true;
    customFetch(`/api/ReminderHistory?reminderType=${0}&objectId=${objectId}`).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchInvoices();
  };

  @action refetch = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchInvoices(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filterInvoiceStatus = 'All';
    this.filterStatus = 'All';
    this.filterType = 'All';
    this.filterTypeText = 'All';
    this.paymentFilterType = 'Unknown';
    this.sortColumn = 'Last Update';
    this.sortKey = 'UpdatedDate';
    this.sortDirection = 'descending';
    this.fromTo = ``;
    this.requestDateTimeFrom = null;
    this.actionRequiredById = '';

    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new InvoicesStore();
