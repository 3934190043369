import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class DoctorClinicStore {
  @observable loading = true;
  @observable data = null;
  @observable filter = `selected`;
  @observable clinics = null;

  @observable keyword = '';

  @observable curPage = 1;
  @observable totalPage = 0;

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchClinic();
  };

  @action fetchDoctor = (setTotalPage = false) => {
    this.loading = true;

    customFetch('/Clinic/GetClinicList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        IsSelectionList: true,
      }),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchDoctor(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';

    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new DoctorClinicStore();
