import { Spin } from 'antd';
import cx from 'classnames';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import ActionNoteForm from '../../shared/ActionNoteForm';
import Modal from '../../shared/Modal';
import * as api from '../../../stores/api';

class ModalActionNotes extends React.Component {
  state = {
    staffs: [],
    notes: [],
    loading: true,
    toggleNewNote: false,
  };

  async componentDidMount() {
    try {
      const staffsPromise = customFetch(`/${this.props.modalParams.name}/GetStaffs`, {
        method: 'POST',
      });

      const notesPromise = customFetch(`/${this.props.modalParams.name}/ActionRequired_LoadList`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          [this.props.modalParams.fieldId]: this.props.modalParams.id,
        }),
      });
      const [staffs, notes] = await Promise.all([staffsPromise, notesPromise]);
      this.setState({
        staffs: uniqBy(staffs.itemList, 'Id'),
        notes: notes.itemList,
        toggleNewNote: !notes.itemList.length,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  refetchAfterUpdate = async () => {
    try {
      const notesPromise = customFetch(`/${this.props.modalParams.name}/ActionRequired_LoadList`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          [this.props.modalParams.fieldId]: this.props.modalParams.id,
        }),
      });
      const [notes] = await Promise.all([notesPromise]);
      this.setState({
        notes: notes.itemList,
        toggleNewNote: !notes.itemList.length,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  renderNoteForm = () => {
    const { staffs, notes } = this.state;
    if (!notes.length) return null;

    return notes.map(note => (
      <ActionNoteForm
        key={note.Id}
        staffs={staffs}
        note={note}
        onSubmit={this.handleSubmit}
        onDelete={this.handleDelete}
      />
    ));
  };

  handleSubmit = async note => {
    this.setState({ loading: true });
    const data = omit(note, [
      'CreatedDate',
      'CreatedUserId',
      'LastUpdatedBy',
      'LastUpdatedDate',
      'StaffFirstName',
      'StaffLastName',
    ]);

    const dueDate = moment(note.DueDate);
    data.HourListActionRequired = dueDate.hour();
    data.MinuteListActionRequired = dueDate.minute();
    data.DueDate = new Date(dueDate.format('MM-DD-YYYY')).toISOString();
    data.CompletedDate = data.Completed
      ? new Date((data.CompletedDate ? moment(data.CompletedDate) : moment()).format('MM-DD-YYYY')).toISOString()
      : null;

    try {
      if (data.Id) {
        await customFetch(`/${this.props.modalParams.name}/ActionRequired_Update`, {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ model: data }),
        }).then(res => {
          if (res.status === 'success') {
            // this.handleCancel();
            this.refetchAfterUpdate();
            this.props.refetch();
          }
        });
      } else {
        await customFetch(`/${this.props.modalParams.name}/ActionRequired_Create`, {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            model: {
              ...data,
              [this.props.modalParams.fieldIdUpdate]: this.props.modalParams.id,
              HourAR: [],
              MinuteAR: [],
              ActionRequiredPICStaff: {
                selected: this.state.staffs.find(staff => staff.Id === data.StaffAssignedId),
              },
            },
          }),
        }).then(res => {
          if (res.status === 'success') {
            // this.handleCancel();
            this.refetchAfterUpdate();
            this.props.refetch();
          }
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  handleDelete = async id => {
    this.setState({ loading: true });

    try {
      await customFetch(`/${this.props.modalParams.name}/ActionRequired_Disable`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      }).then(res => {
        if (res.status === 'success') {
          // this.handleCancel();
          this.refetchAfterUpdate();
          this.props.refetch();
        }
      });

      this.setState(prev => ({
        ...prev,
        notes: prev.notes.filter(note => note.Id !== id),
      }));
    } finally {
      this.setState({ loading: false });
    }
  };

  handleToggleNewNote = () => {
    this.setState({ toggleNewNote: !this.state.toggleNewNote });
  };

  handleCancel = () => {
    return this.props.toggleModal(false, {})();
  };

  render() {
    const { loading, staffs, toggleNewNote } = this.state;
    const { open, modalParams } = this.props;
    const title = 'Action Notes: ' + modalParams.caseNo + ' - ' + modalParams.client || '';
    var newNote = { DueDate: moment(), StaffAssignedId: api.currentUser.data.id };

    return (
      <Modal visible={open} onCancel={() => this.handleCancel()} width={800} title={title}>
        {loading && !staffs.length ? (
          <Spin className="modal-spin" />
        ) : (
          <div className={cx({ 'modal-form-disabled': loading })}>
            {!api.isOnlySearch() && <Button color="blue" className="mb-1" onClick={this.handleToggleNewNote}>
              <Icon name="plus" /> New note
            </Button>}
            {toggleNewNote && <ActionNoteForm staffs={staffs} note={newNote} onSubmit={this.handleSubmit} />}
            {this.renderNoteForm()}
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalActionNotes;
