import { Form, Input, Modal, notification, Select } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import ManageSpeAccStore from './ManageSpeAccStore';

@observer
class ModalAddEdit extends React.Component {
  state = {
    Label: ManageSpeAccStore.editData?.Label || '',
    Description: ManageSpeAccStore.editData?.Description || '',
    IsActive: ManageSpeAccStore.editData?.IsActive ?? true,
    canEdit: api.isAdmin(),
  };

  @action handleCancel = () => {
    ManageSpeAccStore.toggleModalAddEdit(false);
  };

  handleSave = () => {
    const { Label, Description, IsActive } = this.state;

    const Id = ManageSpeAccStore.editData?.Id || 0;
    const ParentId = ManageSpeAccStore.specId || null;

    let Type = ManageSpeAccStore.type;
    if (ManageSpeAccStore.specId) {
      Type = 'subspe';
    } else if (ManageSpeAccStore.editData?.Type) {
      Type = ManageSpeAccStore.editData?.Type;
    }

    ManageSpeAccStore.saveLookupConfig({
      Id,
      Label,
      Description,
      Type,
      ParentId,
      IsActive,
    })
      .then(res => {
        notification.destroy();
        if (res.status === 'success') {
          notification.success({
            description: ManageSpeAccStore.editData?.Id ? 'Edit successfully!' : 'Add successfully!',
            message: 'Success',
            duration: 5,
          });
          ManageSpeAccStore.toggleModalAddEdit(false);
          ManageSpeAccStore.fetchLookupConfig();
        }
      })
      .catch(err => {
        notification.error({
          description: err,
          message: ManageSpeAccStore.editData?.Id ? 'Edit failed!' : 'Add failed!',
          duration: 5,
        });
      });
  };

  setFieldValue = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  setFieldValueSelect = fieldName => event => {
    const value = event;
    this.setState({ [fieldName]: value });
  };

  render() {
    const { editData, specId, typeName, openModalAddEdit } = ManageSpeAccStore;
    const { canEdit } = this.state;
    return (
      <Modal
        title={`${editData ? 'Edit' : 'Add New'} ${specId ? 'Sub-Specialty' : typeName}`}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={600}
        visible={openModalAddEdit}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave} disabled={!canEdit}>
              Save
            </Button>}
            <Button className="negative" onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="Modal-AddEdit">
          <Form>
            <Form.Item
              label="Label"
              required
              validateStatus={!this.state.Label ? 'error' : null}
              help={!this.state.Label ? 'This field is required' : null}
            >
              <Input size="large" name="Label" value={this.state.Label} onChange={this.setFieldValue} />
            </Form.Item>
            <Form.Item label="Status">
              <Select onChange={this.setFieldValueSelect('IsActive')} value={this.state.IsActive}>
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>Inactive</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                rows={4}
                name="Description"
                value={this.state.Description}
                onChange={this.setFieldValue}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ModalAddEdit;
