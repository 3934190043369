import React from 'react';
import { action, toJS } from 'mobx';
import cx from 'classnames';
import { Divider, Spin, notification } from 'antd';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './index.scss';
import ClinicStore from './store';
import router from '../../../stores/router';
import dashboard from '../../../stores/dashboard';

import ViewClinicDetails from './ViewClinicDetails';
import GeneralInfoForm from './GeneralInfoForm';
import RegisteredSpecialists from './RegisteredSpecialists';
import ReceivingDailyBookingOpt from './ReceivingDailyBookingOpt';
import ModalDuplicate from './ModalDuplicate';
import { ERROR_FIELDNAME, ERROR_MESSAGES } from './type';
import Modal from './Modal';
import CaseCorrespondence from './CaseCorrespondence';
import * as api from '@stores/api';

const STEPS = ['General Information', 'Registered Specialists', 'Daysheet Receiving Contact', 'Case Correspondence'];

@observer
class AddEditClinic extends React.Component {
  state = {
    currentStep: 0,
    action: 'view',
    isRerender: false,
    pageGetter: null,
    fieldName: null,
  };

  componentDidMount() {
    const { id = 0, action, step = 0, pageGetter, fieldName } = this.getRouteParams();
    if (!api.isAdminOrMagStaffOrAccounting()) {
      if (api.isCaseManager() && !id) {
        if (pageGetter && fieldName) {
          this.setState({ pageGetter, fieldName });
        }
        this.setState({ id, action, currentStep: +step }, () => {
          ClinicStore.setFieldsValue({ pageGetter });
          if (id !== 0) {
            ClinicStore.fetchClinicInfo(id);
          } else {
            ClinicStore.initClinicData();
          }
        });
        return;
      }
      dashboard.close('/view/add-edit-clinic-2', true);
      return;
    }

    if (pageGetter && fieldName) {
      this.setState({ pageGetter, fieldName });
    }
    this.setState({ id, action, currentStep: +step }, () => {
      ClinicStore.setFieldsValue({ pageGetter });
      if (id !== 0) {
        ClinicStore.fetchClinicInfo(id);
      } else {
        ClinicStore.initClinicData();
      }
    });
  }

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <RegisteredSpecialists />;
      case 2:
        return <ReceivingDailyBookingOpt />;
      case 3:
        return <CaseCorrespondence />;
      default:
        return <GeneralInfoForm isRerender={this.state.isRerender} state={this.state} />;
    }
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 5,
    });
  };

  getKeywordErrorMessage = message => {
    return message
      ? message.toLowerCase().search('invalid') !== -1
        ? 'invalid'
        : message.toLowerCase().search('required') !== -1
        ? 'required'
        : message.toLowerCase().search('10 digits') !== -1
        ? 'maxdigits'
        : message.toLowerCase().search('6 digits') !== -1
        ? 'mindigits'
        : null
      : null;
  };

  retrunErrorMessage = (keywordFieldName, message) => {
    if (keywordFieldName === 'RecruitmentStatus') {
      return this.showFormErrorNoti(message);
    } else if (this.getKeywordErrorMessage(message) && keywordFieldName) {
      if (
        (keywordFieldName === 'Email' || keywordFieldName === 'EmailReceiveDailyBookings') &&
        this.getKeywordErrorMessage(message) === 'invalid'
      ) {
        return this.showFormErrorNoti('Invalid email address');
      } else {
        return this.showFormErrorNoti(
          `${ERROR_FIELDNAME[keywordFieldName]} ${ERROR_MESSAGES[this.getKeywordErrorMessage(message)]}`,
        );
      }
    } else {
      return this.showFormErrorNoti('An error occurred, please try again');
    }
  };

  handleSave = () => {
    const { errors, clinicInfo, rooms, clinicServices } = ClinicStore;
    const body = {
      ...clinicInfo,
      DoctorId: 0,
      newRoom: '',
      listRooms: rooms,
      loaAttachment: clinicInfo.LOAFileName
        ? [
            {
              Id: 0,
              FileName: clinicInfo.LOAFileName,
              FileType: 'LOLTemplate',
              NumberOfPages: clinicInfo.LOANumberOfPages,
              Title: clinicInfo.LOAFileTitle,
            },
          ]
        : [],
      selectedClinicServiceTypes: clinicServices.reduce((acc, cur) => {
        if (clinicInfo.ClinicServiceTypes.find(id => id === cur.Id)) {
          acc.push({ ...cur, Selected: true });
        }
        return acc;
      }, []),
    };
    if (!ClinicStore.clinicInfo.CityId) {
      action(() => {
        errors.CityId = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!ClinicStore.clinicInfo.Name) {
      action(() => {
        errors.Name = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    const { fieldName, pageGetter } = this.state;
    const { listPageGetters } = dashboard;
    const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);
    if (!!Object.keys(ClinicStore.errors).length) {
      const errorList = Object.entries(toJS(ClinicStore.errors));
      notification.destroy();
      if (ClinicStore.rooms.length === 0) {
        this.showFormErrorNoti('Rooms is required');
      }
      return errorList.map(([key, value]) => this.retrunErrorMessage(key, value));
    } else if (ClinicStore.clinicInfo.EnableReceving && !ClinicStore.clinicInfo.EmailReceiveDailyBookings) {
      return this.showFormErrorNoti('Email Receive Daily Bookings is required');
    } else {
      if (ClinicStore.rooms.length === 0) {
        return this.showFormErrorNoti('Rooms is required');
      } else
        return ClinicStore.handleSave().then(({ status, newId }) => {
          if (status === 'success') {
            ClinicStore.handleSaveSpecialists(newId).then(() => {
              if (fieldName && pageGetter) {
                dashboard.close(router.location.pathname);
                dashboard.transferData({
                  fieldName: fieldName,
                  pageGetter: pageGetter,
                  dataTransfer: {
                    ...body,
                    ID: newId,
                  },
                });
              }
              if (urlPageGetter && urlPageGetter.pathname) {
                setTimeout(() => {
                  dashboard.open(urlPageGetter.pathname);
                });
                return;
              }
              if (!ClinicStore.clinicInfo.Id) {
                localStorage.setItem(`ClinicName`, ClinicStore.clinicInfo.Name);
                dashboard.close(router.location.pathname);
                setTimeout(() => {
                  dashboard.open(`/view/add-edit-clinic-2?id=${newId}`);
                });
              }
            });
          } else {
            ClinicStore.setFieldsValue({ saveLoading: false });
            return this.showFormErrorNoti('An error occurred, please try again');
          }
        });
    }
  };

  handleCloseTab = () => {
    if (this.state.action === 'edit') {
      this.setState({ action: 'view' });
      return;
    }
    dashboard.close(router.location.pathname);
  };

  handleParamsChange = params => {
    this.setState(params);
  };

  render() {
    const { open } = ClinicStore;
    if (ClinicStore.loading) {
      return <Spin className="loading-spin" />;
    }

    return (
      <React.Fragment>
        {this.state.action === 'view' ? (
          <ViewClinicDetails onParamsChange={this.handleParamsChange} />
        ) : (
          <div className="page-container">
            {open && <Modal />}
            <div className="form-container">
              <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
              {this.renderForm()}
            </div>
            <div className="step-container step-container-sticky">
              {STEPS.map((step, idx) => (
                <Button
                  key={step}
                  fluid
                  size="large"
                  disabled={this.state.pageGetter && idx > 0}
                  className={cx('step', {
                    active: this.state.currentStep === idx,
                  })}
                  onClick={this.handleStepChange(idx)}
                >
                  {idx + 1}. {step}
                </Button>
              ))}
            </div>
            <div className="form-footer">
              <Divider />
              {!api.isOnlySearch() && <Button primary onClick={this.handleSave} disabled={ClinicStore.saveLoading}>
                Save
              </Button>}
              <Button className="negative" disabled={ClinicStore.saveLoading} onClick={this.handleCloseTab}>
                Close
              </Button>
            </div>
            <ModalDuplicate />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AddEditClinic;
