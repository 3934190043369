const FormID = 150000002124;

let isInitWidget = false;
export const initHelpDeskSystem = currentUser => {
  if (!currentUser) return;
  if (isInitWidget) return;

  isInitWidget = true;
  global.fwSettings = {
    widget_id: FormID,
  };

  const script = document.createElement('script');

  script.src = `https://widget.freshworks.com/widgets/${FormID}.js`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  setTimeout(() => {
    if ('function' != typeof window.FreshworksWidget) {
      const n = function() {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }
    initDefaultInputData(currentUser);
  }, 1000);
};

const initDefaultInputData = currentUser => {
  global.FreshworksWidget(
    'identify',
    'ticketForm',
    {
      name: currentUser.FullName,
      email: currentUser.email, // Inquiry/Booking Email from Tenant Setting
    },
    {
      formId: FormID, // Ticket Form ID
    },
  );
};
