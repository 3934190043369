import React from 'react';
import { Form, Select } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Icon, Popup } from 'semantic-ui-react';
import AddEditStore from './store';

@observer
class AccountManagers extends React.Component {
  renderClientAdvisorsValue = clientAdvisorsValue => {
    if (clientAdvisorsValue) {
      if (clientAdvisorsValue.length === 0) {
        return toJS(clientAdvisorsValue);
      } else if (typeof clientAdvisorsValue[0] === 'object') {
        return toJS(clientAdvisorsValue).map(i => i.id);
      } else {
        return toJS(clientAdvisorsValue);
      }
    }
    return [];
  };
  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };
  render() {
    const data = toJS(AddEditStore.clientInfo);
    const { staffList, clientOfficerList } = AddEditStore;
    return (
      <Form name="account-managers">
        <Form.Item
          label={
            <React.Fragment>
              Business Account Manager{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="The assigned staff will receive an assignment notification (MAIL-162)"
                position="top center"
                wide="very"
              />{' '}
              <button className="check-duplicate">Recent Changes</button>
            </React.Fragment>
          }
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            value={data.AssignedToUserId}
            onChange={AddEditStore.handleFieldChange('AssignedToUserId')}
            allowClear
          >
            {clientOfficerList &&
              clientOfficerList.itemList.map(i => (
                <Select.Option key={i.Id} value={i.Id}>
                  {i.FullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Business Support Officer">
          <Select
            size="large"
            allowClear
            value={this.renderClientAdvisorsValue(data.ClientAdvisors)}
            onChange={AddEditStore.handleFieldChange('ClientAdvisors')}
            mode="multiple"
            showArrow
          >
            {staffList &&
              this.checkIsJSON(staffList) &&
              JSON.parse(staffList).itemList.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.fullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default AccountManagers;
