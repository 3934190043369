import React from 'react';
import { DatePicker, Input } from 'antd';
import cx from 'classnames';
import { toJS } from 'mobx';
import moment from 'moment';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
// import moment from 'moment';

import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';

@observer
class PaymentInfomation extends React.Component {
  validateFrom = (rate, from) => {
    if (!rate) {
      return null;
    } else {
      if (!!rate && !from) {
        return 'This field is required';
      }
      return null;
    }
  };
  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };
  checkIsDisable = (firstRate, secondRate) => {
    if (firstRate) {
      if (secondRate) return false;
      return true;
    }
    return true;
  };
  render() {
    const data = toJS(AddEditStore.CMInfo);
    if (data.IsTypist)
      return (
        <React.Fragment>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Business Name</label>
                <Input
                  size="large"
                  value={data.BusinessABN}
                  onChange={AddEditStore.handleFieldChange('BusinessABN')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
              <Form.Field>
                <label>ABN</label>
                <Input
                  size="large"
                  value={data.ABN}
                  onChange={AddEditStore.handleFieldChange('ABN')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Hourly Rate 1 ($) </label>
                <Input size="large" value={data.HourlyRate1} onChange={AddEditStore.handleFieldChange('HourlyRate1')} />
              </Form.Field>
              <Form.Field disabled={!data.HourlyRate1}>
                <label>
                  Effective From
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Rates will apply based on effective period and typists' report draft  submitting dates"
                    position="top center"
                    wide="very"
                  />
                </label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate1From)}
                  onChange={AddEditStore.handleFieldChange('HourlyRate1From')}
                  format="DD/MM/YYYY"
                  className={cx(
                    `input-doctor-details-edit${
                      this.validateFrom(data.HourlyRate1, data.HourlyRate1From) ? '-error' : ''
                    }`,
                  )}
                />
                {this.validateFrom(data.HourlyRate1, data.HourlyRate1From) && (
                  <span className="invalid-label-eror-doctor-details">
                    {this.validateFrom(data.HourlyRate1, data.HourlyRate1From)}
                  </span>
                )}
              </Form.Field>
              <Form.Field disabled={!data.HourlyRate1}>
                <label>Effective To</label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate1To)}
                  onChange={AddEditStore.handleFieldChange('HourlyRate1To')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Group widths="equal">
              <Form.Field disabled={!data.HourlyRate1}>
                <label>Hourly Rate 2 ($) </label>
                <Input
                  size="large"
                  value={data.HourlyRate2}
                  onChange={AddEditStore.handleFieldChange('HourlyRate2')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
              <Form.Field disabled={this.checkIsDisable(data.HourlyRate1, data.HourlyRate2)}>
                <label>
                  Effective From
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Rates will apply based on effective period and typists' report draft  submitting dates"
                    position="top center"
                    wide="very"
                  />
                </label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate2From)}
                  onChange={AddEditStore.handleFieldChange('HourlyRate2From')}
                  format="DD/MM/YYYY"
                  className={cx(
                    `input-doctor-details-edit${
                      this.validateFrom(data.HourlyRate2, data.HourlyRate2From) ? '-error' : ''
                    }`,
                  )}
                />
                {this.validateFrom(data.HourlyRate2, data.HourlyRate2From) && (
                  <span className="invalid-label-eror-doctor-details">
                    {this.validateFrom(data.HourlyRate2, data.HourlyRate2From)}
                  </span>
                )}
              </Form.Field>
              <Form.Field disabled={this.checkIsDisable(data.HourlyRate1, data.HourlyRate2)}>
                <label>Effective To</label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate2To)}
                  onChange={AddEditStore.handleFieldChange('HourlyRate2To')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Group widths="equal">
              <Form.Field disabled={!data.HourlyRate2}>
                <label>Hourly Rate 3 ($) </label>
                <Input
                  size="large"
                  value={data.HourlyRate3}
                  onChange={AddEditStore.handleFieldChange('HourlyRate3')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
              <Form.Field disabled={this.checkIsDisable(data.HourlyRate2, data.HourlyRate3)}>
                <label>
                  Effective From
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Rates will apply based on effective period and typists' report draft  submitting dates"
                    position="top center"
                    wide="very"
                  />
                </label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate3From)}
                  format="DD/MM/YYYY"
                  onChange={AddEditStore.handleFieldChange('HourlyRate3From')}
                  className={cx(
                    `input-doctor-details-edit${
                      this.validateFrom(data.HourlyRate3, data.HourlyRate3From) ? '-error' : ''
                    }`,
                  )}
                />
                {this.validateFrom(data.HourlyRate3, data.HourlyRate3From) && (
                  <span className="invalid-label-eror-doctor-details">
                    {this.validateFrom(data.HourlyRate3, data.HourlyRate3From)}
                  </span>
                )}
              </Form.Field>
              <Form.Field disabled={this.checkIsDisable(data.HourlyRate2, data.HourlyRate3)}>
                <label>Effective To</label>
                <DatePicker
                  size="large"
                  value={this.convertDate(data.HourlyRate3To)}
                  onChange={AddEditStore.handleFieldChange('HourlyRate3To')}
                  format="DD/MM/YYYY"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </React.Fragment>
      );
    return null;
  }
}

export default PaymentInfomation;
