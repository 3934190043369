import React from 'react';
import { Form, Input, notification, Spin, Select } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import store from './Store';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';

const statusLabels = [
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];
const typeLabel = [
  { text: `Staff`, value: `staff` },
  { text: `Case Manager`, value: `casemanager` },
  { text: `Specialist`, value: `specialist` },
  { text: `Claimant`, value: `claimant` },
  { text: `Typist`, value: `typist` },
];

@observer
class ConversationModalForm extends React.Component {
  state = {
    loading: true,
    dataGreetingTitle: null,
    loadingUpload: false,
  };
  handleSave = async () => {
    try {
      const { dataGreetingTitle } = this.state;
      const params = { ...dataGreetingTitle };
      if (params.Id === 0) {
        delete params.Id;
      }
      const res = await customFetch('/GreetingTitle/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(params),
      });
      if (res.status === 'success') {
        store.refetch({}, true);

        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data saved successfully',
        });
        return store.toggleModal(false, {})();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    } catch (err) {}
  };
  componentDidMount() {
    const { record, id } = store.modalParams;
    if (record && !!id) {
      const dataGreetingTitle = {
        Title: record.Title,
        Description: record.Description,
        Type: record.Type,
        Status: record.Status,
        Id: id,
      };
      this.setState({ dataGreetingTitle }, () => this.setState({ loading: false }));
    } else {
      const dataGreetingTitle = {
        Title: '',
        Description: '',
        Type: 'staff',
        Status: 'active',
        Id: 0,
      };
      this.setState({ dataGreetingTitle }, () => this.setState({ loading: false }));
    }
  }

  handleFieldChange = fieldName => event => {
    const { dataGreetingTitle } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      dataGreetingTitle: {
        ...dataGreetingTitle,
        [fieldName]: fieldName === 'Type' ? value.join() : value,
      },
    });
  };

  render() {
    const { record, id } = store.modalParams;
    const { open } = store;
    const { dataGreetingTitle, loading } = this.state;

    return (
      <Modal
        width={1200}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>}
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New Greeting Title' : record.Title}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item label="Title">
              <Input value={dataGreetingTitle.Title} onChange={this.handleFieldChange('Title')} />
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                rows={4}
                value={dataGreetingTitle.Description}
                onChange={this.handleFieldChange('Description')}
              />
            </Form.Item>
            <Form.Item label="Status">
              <Select
                size="large"
                value={dataGreetingTitle.Status}
                onChange={this.handleFieldChange('Status')}
                style={{ marginTop: '10px' }}
                optionFilterProp="children"
              >
                {statusLabels.map(i => (
                  <Select.Option value={i.value} key={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Type">
              <Select
                size="large"
                value={dataGreetingTitle.Type ? dataGreetingTitle.Type.split(',') : []}
                onChange={this.handleFieldChange('Type')}
                style={{ marginTop: '10px' }}
                optionFilterProp="children"
                mode="multiple"
                showArrow
              >
                {typeLabel.map(i => (
                  <Select.Option value={i.value} key={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ConversationModalForm;
