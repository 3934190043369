import './index.scss';

import * as api from '@stores/api';
import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import dashboardStore from '../../../stores/dashboard';
import router from '../../../stores/router';
import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import DocAndConsStore from './DocAndConsStore';
import DoctorClinicStore from './DoctorClinicStore';
import ModalResetPassword from './ModalResetPassword';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'Title' },
  { title: 'First Name', sortKey: 'FirstName' },
  { title: 'Last Name', sortKey: 'LastName' },
  { title: 'Action Required' },
  //{ title: 'Relationship PIC' },
  // { title: 'Relationship Follow-Up' },
  // { title: 'Relationship History' },
  // { title: 'Contract Date', sortKey: 'ContractWithMagDate' },
  { title: 'Recruitment Status', sortKey: 'RecruitmentStatus' },
  { title: 'Recruitment PIC', sortKey: 'PiCFullName' },
  { title: 'Med Neg-Free PO' },
  { title: 'Account Manager' },
  // { title: 'Username Login' },
  { title: 'Specialties' },
  { title: 'Provider No' },
  // { title: 'Qualifications' },
  { title: 'Accreditations' },
  { title: 'Mobile' },
  { title: 'Office Phone' },
  { title: 'Business Email' },
  { title: 'Suburb' },
  { title: 'Account Status' },
  { title: 'Created At', sortKey: 'CreatedDate' },
  { title: 'Created By', sortKey: 'CreatedByUsername' },
];

@observer
class DocAndConsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }

  openModal = options => {
    return DocAndConsStore.toggleModal(true, options);
  };

  openCaseDetail = (id, record, type = 'view') => () => {
    localStorage.setItem('DoctorName', `${record.FirstName} ${record.LastName}`);
    dashboardStore.close('/view/add-edit-doctor-2', true);
    let step = 0;
    let type_ = 'view';
    switch (type) {
      case 'details':
        step = 0;
        type_ = 'edit';
        break;
      case 'loginInfo':
        step = 0;
        type_ = 'edit';
        break;
      case 'assisgnMed':
        step = 4;
        type_ = 'edit';
        break;
      case 'correspondence':
        step = 6;
        type_ = 'edit';
        break;
      default:
        break;
    }
    setTimeout(() => {
      router.history.push({
        pathname: '/view/add-edit-doctor-2',
        state: { userId: record.UserId },
        search: `?id=${id}&action=${type_}&step=${step}`,
      });
    });
  };

  openCaseManageCalendar = (id, name) => () => {
    let sessionDate = moment();
    const doctorSession = {
      doctorId: id || 0,
      selectedDate: sessionDate,
    };
    localStorage.setItem('Data:DoctorSessionDateSelected', JSON.stringify(doctorSession));
    dashboardStore.close('/view/calendar-2');
    setTimeout(() => {
      dashboardStore.open(`/view/calendar-2?pageGetter=addEditIME&fieldName=SessionId&onlySessions=true`);
    });

    return;
  };
  handleEditItemStatus = (id, record) => async () => {
    DocAndConsStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MedicalDoctor/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });
      await DocAndConsStore.refetch({}, true);
    } finally {
      DocAndConsStore.setFieldsValue({ loading: false });
    }
  };

  handleDelete = id => async () => {
    DocAndConsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MedicalDoctor/Delete', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await DocAndConsStore.refetch({}, true);
    } finally {
      DocAndConsStore.setFieldsValue({ loading: false });
    }
  };

  handleImpersonateUser = userId => () => {
    DocAndConsStore.setFieldsValue({ loading: true });
    customFetch('/Manager/ImpersonateUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      var url = window.location.protocol + '//' + window.location.host + '/view/dashboard';
      window.location = url;
    });
  };

  fetchClinicDoctor = async clinicDoctors => {
    DoctorClinicStore.setFieldsValue({
      loading: true,
      clinics: clinicDoctors,
    });
    await customFetch('/Clinic/GetClinicByIdList', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(clinicDoctors.map(i => i)),
    }).then(
      action(data =>
        DoctorClinicStore.setFieldsValue({
          data,
          loading: false,
          totalPage: data.sumPage || DoctorClinicStore.totalPage,
          curPage: 1,
        }),
      ),
    );
  };

  handleEditUser = (Id, record) => () => {
    customFetch('/Manager/GetUserById', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: record.UserId }),
    })
      .then(
        action(data => {
          DocAndConsStore.dataUser = data;
        }),
      )
      .then(
        this.openModal({
          modalType: 'editUser',
          Id,
          record,
        }),
      );
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  renderRecruitmentStatus = record => {
    if (record.IsNotCurrentlyInterested) {
      var currentDate = moment();
      if (
        moment(record.NotCurrentlyInterestedDate) <= currentDate &&
        (currentDate <= moment(record.NotCurrentlyInterestedEndDate) || !record.NotCurrentlyInterestedEndDate)
      ) {
        return (
          <div>
            {' '}
            NCI <br />
            <span>
              Start: {moment(formatDate(record.NotCurrentlyInterestedDate), 'DD MMM YYYY').format('DD/MM/YYYY')}
            </span>
            <br />
            <span>
              End:{' '}
              {record.NotCurrentlyInterestedEndDate
                ? moment(formatDate(record.NotCurrentlyInterestedEndDate), 'DD MMM YYYY').format('DD/MM/YYYY')
                : 'N/A'}
            </span>
          </div>
        );
      }
    }
    return record.RecruitmentStatus;
  };

  handleUnlockUser = record => async () => {
    DocAndConsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/manager/unlockUser', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId: record.UserId }),
      }).then(
        this.openModal({
          modalType: 'alert',
          message: 'User has been unlocked',
        }),
      );
      await DocAndConsStore.refetch({}, true);
    } finally {
      DocAndConsStore.setFieldsValue({ loading: false });
    }
  };

  handleResetPassword = (id, record) => () => {
    customFetch('/Manager/ResetPassword', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId: record.UserId, doctorId: id }),
    }).then(res => {
      this.setState({
        openModalNotification: true,
        message: `Generated password: ${res.result}`,
      });
    });
  };

  handleResendLoginInformation = (id, record) => () => {
    customFetch('/Manager/ResendLoginInformation', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ uId: record.UserId, doctorId: id }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Resend login Information: Successfully!!`,
        duration: 2,
        placement: `bottomRight`,
      });
    });
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          onClick={this.openCaseDetail(Id, record)}
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
        >
          <Link to="#">{Id}</Link>
        </Table.Cell>
        <Table.Cell>{record.Title}</Table.Cell>
        <Table.Cell>{record.FirstName}</Table.Cell>
        <Table.Cell>{record.LastName}</Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              modalType: 'notes',
              modalTitle: `${record.FirstName} ${record.LastName}`,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          <br />
          <div>
            {record.ActionRequiredType > 0 && record.ActionOverview
              ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
              : ''}
          </div>
        </Table.Cell>
        {/* <Table.Cell>
          {record.ClientType === 0
            ? 'MAG Ultimate'
            : record.ClientType === 1
            ? 'MAG Direct-Essential'
            : 'MAG Direct-Extra'}
        </Table.Cell> */}

        {/* <Table.Cell>
          {record?.FromSaleDate ? moment(record?.FromSaleDate).format('DD/MM/YYYY') : ''}
          {record?.ToSaleDate ? moment(record?.ToSaleDate).format(' - DD/MM/YYYY') : ''}
        </Table.Cell> */}
        {/* <Table.Cell>
          {record?.NextDueDate
            ? moment(record?.NextDueDate).format('DD/MM/YYYY') + ' - ' + record?.RelationshipPIC
            : ''}
        </Table.Cell> */}
        {/* <Table.Cell>
          {record?.ActivityPICs?.map(i => (
            <div>{i}</div>
          ))}
        </Table.Cell> */}
        
        
        {/* <Table.Cell>
          {record.ContractWithMagDate
            ? moment(formatDate(record.ContractWithMagDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell> */}
        <Table.Cell>{this.renderRecruitmentStatus(record)}</Table.Cell>
        <Table.Cell>{record.PiCFullName}</Table.Cell>
        <Table.Cell>
          {record.MedNegStatus === 1 && record.FreePreliminaryOpinionRequest === 1
            ? 'Yes'
            : record.MedNegStatus === 1 && record.FreePreliminaryOpinionRequest === 2
            ? 'No'
            : 'Unsure'}
        </Table.Cell>
        <Table.Cell>{record.AccountManager}</Table.Cell>
        {/* <Table.Cell>{record.UserName}</Table.Cell> */}
        <Table.Cell>{record.Specialty}</Table.Cell>

        <Table.Cell>
          <div>{record.ProviderNumber}</div>
          <div>{record.ProviderNumber2}</div>
          <div>{record.ProviderNumber3}</div>
          <div>{record.ProviderNumber4}</div>
        </Table.Cell>
        {/* <Table.Cell>{record.Qualifications}</Table.Cell> */}
        <Table.Cell>{record.Accreditations}</Table.Cell>
        <Table.Cell>
          <FormatPhoneNumber officePhoneNumber={record.Telephone} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          <FormatPhoneNumber officePhoneNumber={record.OfficePhone} />
        </Table.Cell>
        <Table.Cell>{record.Email2}</Table.Cell>
        <Table.Cell>{record.Postcode}</Table.Cell>
        <Table.Cell>
          <div>{record.IsActive ? 'Active' : 'Inactive'}</div>
          <div>
            {!record.IsActive && (
              <span>{record.InActiveDate ? moment(formatDate(record.InActiveDate)).format('DD/MM/YYYY') : ''}</span>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{this.convertEmailCreatedAt(record.CreatedByUsername)}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {/* <Menu.Item onClick={this.openCaseDetail(id, record, 'details')}>Manage Detail</Menu.Item> */}
          <Menu.Item onClick={this.openCaseManageCalendar(id, record.FirstName)}>View Clinic Calendar</Menu.Item>
          {record.IsActive ? (
            <Menu.Item onClick={this.openCaseDetail(id, record, 'assisgnMed')}>Update Assigned Clinics</Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'statusLog',
              id: id,
              record,
            })}
          >
            View Status History
          </Menu.Item>
          {/*{record.IsActive ? (
            <Menu.Item onClick={this.openCaseDetail(id, record, 'correspondence')}>Manage Correspondence</Menu.Item>
          ) : (
            <div />
          )}
           <hr />
          <Menu.Item onClick={this.openCaseDetail(id, record, 'loginInfo')}>Edit Username</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record, 'loginInfo')}>Unlock User</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record, 'loginInfo')}>Reset Password</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record, 'loginInfo')}>Resend Login Information</Menu.Item>
          <Menu.Item
            onClick={
              !record.UserId
                ? this.openModal({
                    modalType: 'alert',
                    message: 'This user is not yet enabled for login. Open the user details and enable login first.',
                  })
                : this.openModal({
                    modalType: 'history',
                    id: id,
                    record,
                  })
            }
          >
            Login History
          </Menu.Item>*/}
          {record.IsActive ? (
            <Menu.Item
              // onClick={this.openModal({
              //   modalType: 'confirm',
              //   message: 'Do you want to disable this specialist?',
              //   onOk: this.handleEditItemStatus(id, record),
              // })}
              onClick={this.openModal({ modalType: 'disable', modalParams: { Id: id, Record: record } })}
            >
              Disable
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {/* <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item> */}
          {record.UserId && (api.isAdmin() || api.isMagStaff()) && (
            <Menu.Item onClick={this.handleImpersonateUser(record.UserId)}>Impersonate</Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    DocAndConsStore.refetch({ curPage: page });
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalResetPassword
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = DocAndConsStore;
    const colSpan = columns.length;
    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: '0' }}>
        {this.renderModal()}
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={DocAndConsStore.handleSort}
        />
        <TableBody>{this.renderTableBody(data, colSpan)}</TableBody>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={DocAndConsStore.curPage}
          totalPage={DocAndConsStore.totalPage}
          totalItems={DocAndConsStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default DocAndConsTable;
