import { observer } from 'mobx-react';
import React from 'react';
import * as api from 'stores/api';
import router from 'stores/router';
import apiResetStore from 'utils/apiResetStore';
import isIE from 'utils/isIE';
import Form from 'components/shared/form/Form';
import '../anonymous-routes/anonymous-routes.scss';

@observer
class Login extends React.Component {
  onValidSubmit = data => {
    api.login.post(data, () => {
      if (router.location.pathname === '/view/login') {
        router.history.push('/');
      }
      if (isIE()) {
        window.location.reload();
      }
      apiResetStore();
    });
  };

  render() {
    return (
      <React.Fragment>
        <h1>LOGIN TO MAG</h1>
        <Form
          id="login-form"
          onValidSubmit={this.onValidSubmit}
          fields={[
            {
              name: 'Username',
              rule: 'required',
              label: 'Username',
            },
            {
              name: 'Password',
              rule: 'required',
              type: 'PasswordInput',
              label: 'Password',
              autoComplete: 'current-password',
            },
            {
              name: 'Remember',
              type: 'Checkbox',
              label: 'Remember me',
              defaultChecked: true,
            },
          ]}
          submit={{ loading: api.login.loading }}
          button={{ loading: api.login.loading }}
        />
      </React.Fragment>
    );
  }
}

export default Login;
