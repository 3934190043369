import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStore from './store';
// import ModalDuplicate from './ModalDuplicate';

function AddEditModal() {
  const { modalType } = AddEditStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
}

export default AddEditModal;
