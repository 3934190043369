import { observer } from 'mobx-react';
import React from 'react';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';

const TextArea = ({ name, maxLength, functionOnChange, value, ...props }) => {
  const { setFieldValue } = DoctorDetailsStore;
  const inputValue = value || DoctorDetailsStore.dataDoctor[name];

  return (
    <div>
      <SemanticTextArea
        style={{ minHeight: '109px' }}
        name={name}
        maxLength={maxLength}
        value={inputValue || ''}
        onChange={functionOnChange || setFieldValue}
        {...props}
      />
      {maxLength && (
        <span className="float-right">
          {(inputValue || '').length}/{maxLength}
        </span>
      )}
    </div>
  );
};

export default observer(TextArea);
