import React from 'react';
import { Button, Form, Input, Dropdown, Table, TableBody, Icon, Image, Divider } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';

import Modal from '../../../shared/Modal/';
import { TableEmptyMessage, TableFooterPagination } from '../../../shared/table';
import DoctorDetailsStore from './DoctorDetailsStore';
import customFetch from '../../../../utils/customFetch';
import ModalConfirm from './ModalConfirm';
import { notification } from 'antd';
import placeholderImg from './placeholder.png';

const options = [
  { key: 1, text: 'All Clinics', value: 0 },
  { key: 2, text: 'Selected Clinics', value: 1 },
];

@observer
class ModalAddClinic extends React.Component {
  state = {
    dropDownValue: 1,
    selectedClinics: false,
    allClinics: false,
    clinistListIdState: DoctorDetailsStore.clinicListId.itemList.map(i => i) || [],
    openModal: false,
    message: '',
    onOk: false,
  };

  componentDidMount = () => {
    const { dataDoctor, selectedClinicsId } = DoctorDetailsStore;
    if (!dataDoctor.Id) {
      this.setState({ clinistListIdState: toJS(selectedClinicsId) });
    }
  };

  handleClose = () => {
    if (!DoctorDetailsStore.dataDoctor.Id) {
      DoctorDetailsStore.fetchSelectedClinic(DoctorDetailsStore.selectedClinicsId.map(i => i));
    } else {
      DoctorDetailsStore.fetchSelectedClinic(DoctorDetailsStore.clinicListId.itemList.map(i => i));
    }
    DoctorDetailsStore.toggleModal(false)();
    return;
  };

  onOpenConfirm = () => {
    const { clinistListIdState } = this.state;
    const { dataDoctor, clinicListId, selectedClinicsId } = DoctorDetailsStore;

    if (!dataDoctor.Id) {
      if (clinistListIdState.length !== selectedClinicsId.length) {
        return this.setState({
          onOk: () => this.handleClose(),
          message: 'All unsaved changes will be discarded. Do you want to continue?',
          openModal: true,
        });
      } else {
        const matchItem = clinistListIdState.map(id => {
          return selectedClinicsId.find(i => i === id);
        });
        if (matchItem.indexOf(undefined) === -1) {
          return DoctorDetailsStore.toggleModal(false)();
        } else {
          return this.setState({
            onOk: () => this.handleClose(),
            message: 'All unsaved changes will be discarded. Do you want to continue?',
            openModal: true,
          });
        }
      }
    }

    if (clinistListIdState.length !== clinicListId.itemList.length) {
      return this.setState({
        onOk: () => this.handleClose(),
        message: 'All unsaved changes will be discarded. Do you want to continue?',
        openModal: true,
      });
    } else {
      const matchItem = clinistListIdState.map(id => {
        return clinicListId.itemList.find(i => i === id);
      });
      if (matchItem.indexOf(undefined) === -1) {
        return DoctorDetailsStore.toggleModal(false)();
      } else {
        return this.setState({
          onOk: () => this.handleClose(),
          message: 'All unsaved changes will be discarded. Do you want to continue?',
          openModal: true,
        });
      }
    }
  };
  renderTableBodyMed = (data, type) => {
    if (!data || data.length === 0) {
      return <TableEmptyMessage colSpan={5} />;
    } else {
      if (type === 'selectedClinics') {
        return data.map((i, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>
              <Image src={placeholderImg} className="Form-table-DRDetail-cell-img" />
            </Table.Cell>
            <Table.Cell>
              <p className="Form-table-DRDetail-cell-id">{i.ID}</p>
            </Table.Cell>
            <Table.Cell>
              <p className="Form-table-DRDetail-cell-name">{i.Name}</p>
            </Table.Cell>
            <Table.Cell>
              <p className="Form-table-DRDetail-cell-address">{i.FullAddress}</p>
            </Table.Cell>
            <Table.Cell>
              <div className="Form-table-DRDetail-cell-icon">
                <Icon name="delete" onClick={() => this.removeClinic(i.ID)} />
              </div>
            </Table.Cell>
          </Table.Row>
        ));
      }
      return data.map((i, idx) => (
        <Table.Row key={idx}>
          <Table.Cell>
            <Image src={placeholderImg} className="Form-table-DRDetail-cell-img" />
          </Table.Cell>
          <Table.Cell>
            <p className="Form-table-DRDetail-cell-id">{i.ID}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="Form-table-DRDetail-cell-name">{i.Name}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="Form-table-DRDetail-cell-address">{i.FullAddress}</p>
          </Table.Cell>
          <Table.Cell>
            <div className="Form-table-DRDetail-cell-add" onClick={() => this.addClinic(i.ID, i.Name)}>
              Add
            </div>
          </Table.Cell>
        </Table.Row>
      ));
    }
  };
  handlePageClick = page => {
    DoctorDetailsStore.refetchClinic({
      curPageClinic: page,
    });
  };
  handlePageClickSelect = page => {
    DoctorDetailsStore.setFieldsValue({
      curPageClinicSelected: page,
    });
  };
  removeClinic = id => {
    const { clinistListIdState } = this.state;
    this.setState({
      clinistListIdState: clinistListIdState.filter(i => i !== id),
    });
    DoctorDetailsStore.fetchSelectedClinic(clinistListIdState.filter(i => i !== id));
  };
  @action onChangeClinic = (_, { value }) => {
    DoctorDetailsStore.keyword = value;
  };
  @action onEnterInput = e => {
    if (e.key === 'Enter') {
      DoctorDetailsStore.fetchClinic({ curPageClinic: 1 }, true);
    }
  };
  @action onSearchClinic = () => {
    DoctorDetailsStore.fetchClinic({ curPageClinic: 1 }, true);
  };

  addClinic = (id, name) => {
    const array = this.state.clinistListIdState;
    if (array.indexOf(id) !== -1) {
      this.setState({
        openModal: true,
        message: `${name} has already been selected`,
      });
    } else {
      array.unshift(id);
      this.setState({
        clinistListIdState: array,
      });
      DoctorDetailsStore.fetchSelectedClinic(array);
    }
  };

  @action updateClinic = async () => {
    const { clinistListIdState } = this.state;
    const { dataDoctor, clinicList } = DoctorDetailsStore;

    if (!dataDoctor.Id) {
      DoctorDetailsStore.selectedClinicsId = clinicList.itemList.map(i => i.ID);
      DoctorDetailsStore.toggleModal(false)();
      return;
    }

    const clinics = clinistListIdState.length ? clinistListIdState : null;
    const res = await customFetch('/MedicalDoctor/UpdateDoctorClinic', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        clinics,
        doctorId: dataDoctor.Id,
      }),
    });
    if (res.status === 'success') {
      notification.destroy();
      notification.success({
        message: 'Data has been saved successfully.',
      });
      DoctorDetailsStore.fetchListClinicDoctor(dataDoctor.Id);
    }
  };
  renderTable = (data, type) => {
    const {
      curPageClinic,
      totalPageClinic,
      totalItemsClinic,
      totalItemsClinicSelected,
      totalPageClinicSelected,
      curPageClinicSelected,
    } = DoctorDetailsStore;
    if (type === 'allClinics')
      return (
        <Table sortable striped>
          <TableBody>{this.renderTableBodyMed(data, type)}</TableBody>
          <TableFooterPagination
            colSpan={5}
            currentPage={curPageClinic}
            totalPage={totalPageClinic}
            onPageClick={this.handlePageClick}
            totalItems={totalItemsClinic || 0}
          />
        </Table>
      );
    return (
      <Table sortable striped>
        <TableBody>{this.renderTableBodyMed(data, type)}</TableBody>
        <TableFooterPagination
          colSpan={5}
          currentPage={curPageClinicSelected}
          totalPage={totalPageClinicSelected}
          onPageClick={this.handlePageClick}
          totalItems={totalItemsClinicSelected || 0}
        />
      </Table>
    );
  };
  renderDropdownText = value => {
    return `Display: ${options.filter(i => i.value === value).map(i => i.text)[0]}`;
  };
  handleChangeDropdown = (_, { value }) => {
    this.setState({
      dropDownValue: value,
      selectedClinics: true,
      allClinics: true,
    });
    if (value === 0) {
      DoctorDetailsStore.refetchClinic({ curPageClinic: 1 }, true);
    }
  };
  handleShowHideTable = type => () => {
    const value = this.state[type];
    this.setState({ [type]: !value });
  };
  render() {
    const {
      open,
      loadingClinic,
      dataDoctor,
      dataClinicList,
      clinicList,
      totalItemsClinicSelected,
    } = DoctorDetailsStore;
    const { dropDownValue, allClinics, selectedClinics, openModal, message, onOk } = this.state;
    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.onOpenConfirm}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.updateClinic}>
              Save
            </Button>
            <Button className="negative" onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="popup-clinic-header">
          {openModal && (
            <ModalConfirm
              open={openModal}
              message={message}
              onCancel={() => this.setState({ openModal: false })}
              onOk={onOk}
            />
          )}
          <div className="popup-clinic-header-text">
            {dataDoctor.FirstName && dataDoctor.LastName
              ? `${dataDoctor.FirstName} ${dataDoctor.LastName}'s Medical Centres`
              : `New doctor's Medical Centres`}
            <Icon name="delete" className="popup-clinic-header-icon" onClick={this.onOpenConfirm} />
          </div>
          <Divider />
        </div>
        <div className="popup-clinic">
          <Input
            className="popup-clinic-input"
            icon="search"
            placeholder="Search Clinic"
            value={DoctorDetailsStore.keyword}
            onChange={this.onChangeClinic}
            onKeyDown={this.onEnterInput}
          />
          <Dropdown
            options={options}
            selection
            className="popup-clinic-dropdown"
            text={this.renderDropdownText(this.state.dropDownValue)}
            value={this.state.dropDownValue}
            onChange={this.handleChangeDropdown}
          />
          <Button style={{ marginLeft: '15px', marginBottom: '10px' }} color="blue" onClick={this.onSearchClinic}>
            Search
          </Button>
        </div>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <div>
                <label className="Form-table-DRDetail-cell.label">
                  {`Current registered clinics (${totalItemsClinicSelected}):`}
                </label>
                <label className="btn-text-show" onClick={this.handleShowHideTable('selectedClinics')}>
                  {!selectedClinics ? 'Show' : 'Hide'}
                </label>
              </div>
              {selectedClinics && (
                <div className="Modal-Form-Field-table">{this.renderTable(clinicList.itemList, 'selectedClinics')}</div>
              )}
            </Form.Field>
          </Form.Group>
          {dropDownValue === 0 && (
            <Form.Group widths="equal">
              {!loadingClinic && dataClinicList && (
                <Form.Field>
                  <div style={{ marginTop: '1.25rem' }}>
                    <label className="Form-table-DRDetail-cell.label">
                      {`All Medical Centres (${dataClinicList.sumItem}):`}
                    </label>
                    <label className="btn-text-show" onClick={this.handleShowHideTable('allClinics')}>
                      {!allClinics ? 'Show' : 'Hide'}
                    </label>
                  </div>
                  {allClinics && (
                    <div className="Modal-Form-Field-table">
                      {this.renderTable(dataClinicList.itemList, 'allClinics')}
                    </div>
                  )}
                </Form.Field>
              )}
            </Form.Group>
          )}
        </Form>
      </Modal>
    );
  }
}

export default ModalAddClinic;
