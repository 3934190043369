import './Modal.scss';

import { Modal as AntdModal } from 'antd';
import cx from 'classnames';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

function Modal(props) {
  const renderModalFooter = () => {
    const { footer, onCancel } = props;

    if (!footer) {
      return (
        <Button color="red" onClick={onCancel}>
          <Icon name="close" /> Close
        </Button>
      );
    }

    return footer;
  };

  return (
    <AntdModal
      {...props}
      closable={false}
      centered
      className={cx('custom-modal', props.className)}
      destroyOnClose
      footer={renderModalFooter()}
      maskClosable={false}
    >
      {props.children}
    </AntdModal>
  );
}

export default Modal;
