import { Divider, Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import dashboardStore from '@stores/dashboard';
import customFetch from '../../../utils/customFetch';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import fileReviewStore from './fileReviewStore';
import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'FileReviewNumber' },
  { title: 'Case Progress', sortKey: 'CaseProgress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Action Required' },
  { title: 'Claim No', sortKey: 'ClaimantNo' },
  { title: 'Type of Claim/Report', sortKey: 'TypeOfClaimnt' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Assessing Specialist', sortKey: 'DoctorName' },
  { title: 'Next Task' },
  { title: 'Next Task PIC' },
  { title: 'Created By', sortKey: 'CreatedByName' },
];

const columnsCM = [
  { title: '' },
  { title: 'Case No', sortKey: 'FileReviewNumber' },
  { title: 'Case Progress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Claim No', sortKey: 'ClaimantNo' },
  { title: 'Type Of Claim/Report', sortKey: 'TypeOfClaimnt' },
  { title: 'Claimant', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'ClientId' },
  { title: 'Case Manager', sortKey: 'CaseManagerId' },
  { title: 'Specialist', sortKey: 'DoctorFirstname' },
];

@observer
class FileReviewTable extends React.Component {
  getPICName = ({ CaseProgress, ...record }) => {
    switch (CaseProgress) {
      case 1:
        return record.PICsentToProviderName;
      case 2:
        return record.PICcompletedName;
      case 3:
        return record.PICDictationReceivedName;
      case 4:
        return record.PICReportDraftReceivedName;
      case 5:
        return record.PICcorrectedReportName;
      case 6:
      case 8:
        return record.PICServiceBilledName;
      default:
        return '';
    }
  };
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Document':
        return 1;
      case 'Cancellation':
        return 2;
      case 'ServicePrepaymentSection':
        return 3;
      case 'Corespondence':
        return 4;
      case 'ActionRequired':
        return 5;
      default:
        return 0;
    }
  };

  openCaseDetail = (id, type, record) => () => {
    localStorage.setItem('FileReviewCaseId', `Case ${record.FileReviewNumber}`);
    dashboardStore.close('/view/add-edit-file-review-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-file-review-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  isShowDetailTypist = record => {
    if (api.isTypist()) {
      switch (record.CaseProgress) {
        case api.frStatusEnum.CANCELLED_WITH_FEE:
        case api.frStatusEnum.CANCELLED_NO_FEE:
        case api.frStatusEnum.SERVICE_BILLED:
        case api.frStatusEnum.SERVICE_PAID:
          return false;
        default:
          break;
      }
    }
    return true;
  };

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/FileReview/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 3, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } finally {
    }
  };

  openCreateNewSupp = (id, name) => async () => {
    await customFetch('/Supplementary/CheckOriginalCaseNo', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ caseNo: name }),
    }).then(res => {
      if (res.status === 'success') {
        if (res.exist) {
          var existItems = '';
          var message = '';

          res.listExist.forEach(i => {
            existItems += i.CaseNo + ' - ' + i.StatusStaffText + ', ';
          });

          if (res.listExist.length > 1) {
            message =
              'Supplementary requests (' +
              existItems.substring(0, existItems.length - 2) +
              ') have already been created for this case. Do you want to proceed?';
          } else {
            message =
              'A supplementary request (' +
              existItems.substring(0, existItems.length - 2) +
              ') has already been created for this case. Do you want to proceed?';
          }

          fileReviewStore.toggleModal(true, {
            modalType: 'confirm',
            message: message,
            onOk: this.doOpenCreateNewSupp(id, name),
          })();
        } else {
          this.doOpenCreateNewSupp(id, name)();
        }
      }
    });
  };

  doOpenCreateNewSupp = (id, name) => () => {
    localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=F&mId=${id}`);
    });
  };

  openViewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  openModal = options => {
    return fileReviewStore.toggleModal(true, options);
  };

  handleDuplicateAndRemoe = (id, record, fn, act) => async () => {
    fileReviewStore.setFieldsValue({ loading: true });

    try {
      await fn(id, record);
      await fileReviewStore.refetch({}, true);

      // notification.destroy();
      // notification.success({
      //   message: 'Success',
      //   description: `File Review is ${act}d`,
      //   duration: 2,
      // });
    } finally {
      fileReviewStore.setFieldsValue({ loading: false });
    }
  };

  duplicate = (id, record) => {
    return customFetch('/FileReview/FileReview_CopyDetail', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ fileReviewId: id }),
    });
  };

  delete = (id, record) => {
    if (record.CaseProgress === api.frStatusEnum.CANCELLED_NO_FEE) {
      fileReviewStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to delete this file review?',
        onOk: async () => {
          try {
            fileReviewStore.setFieldsValue({ loading: true });
            const response = await customFetch('/FileReview/Delete', {
              method: 'POST',
              headers: { 'Content-type': 'application/json' },
              body: JSON.stringify({ id }),
            });
            if (response.status === 'success') {
              fileReviewStore.fetchFiles(true);
            }
          } catch (e) {
          } finally {
            fileReviewStore.setFieldsValue({ loading: false });
          }
        },
      })();
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=F&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'F',
      view: 'ViewInvoice',
    });
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  convertDoctorName = name => {
    const n = '(P)';
    const m = '(P&D)';
    if (name) {
      if (name.search(m)) {
        return name.replace(m, '');
      } else if (name.search(n)) {
        return name.replace(n, '');
      }
    }
    return name;
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id, ``, record)}>
            {record.FileReviewNumber}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.FileReviewNumber,
              status: record.CaseProgressText,
            })}
          >
            {record.StatusStaffText}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate ? moment(formatDate(record.RequestDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Table.Cell
            className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
          >
            <Link
              to="#"
              onClick={this.openModal({
                modalType: 'notes',
                id: Id,
                caseNo: record.FileReviewNumber,
              })}
            >
              {record.ActionRequired === 0 ? (
                <span className="link-add-action">(Add Action)</span>
              ) : (
                <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                  {record.ActionRequiredType === 0
                    ? '(Add Action)'
                    : record.ActionRequiredType === 1
                    ? '(Action Required)'
                    : '(View Actions)'}
                </span>
              )}
            </Link>
            {record.ActionOverview && (
              <div>
                {record.ActionOverview}
                <br />
                <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
              </div>
            )}
          </Table.Cell>
        )}
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        <Table.Cell>
          {record.TypeOfClaimnt}/{record.TypeOfRerpot}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'claimant',
                id: record.ClaimantId,
                localStorageName: 'ClaimantName',
                localStorageValue: record.Claimant,
              })
            }
          >
            ({record.ClaimantId}) {record.Claimant}
          </Link>
          <br /> DOB:{' '}
          {record.ClaimantDOB ? moment(formatDate(record.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
          <br />
          <FormatPhoneNumber
            officePhoneNumber={record.ClaimantHomePhone}
            mobilePhoneNumber={record.ClaimantTelePhone}
          />
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: record.CaseManagerId,
                localStorageName: 'CmName',
                localStorageValue: record.CaseManager,
              })
            }
          >
            ({record.CaseManagerId}) {record.CaseManager}
          </Link>
          <div>{record.CMEmail}</div>
          <div>
            <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'specialist',
                id: record.DoctorId,
                localStorageName: 'DoctorName',
                localStorageValue: record.DoctorName,
              })
            }
          >
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorName}{' '}
            {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
          </Link>

          {record.DoctorName && (
            <React.Fragment>
              <div style={{ whiteSpace: 'nowrap' }}>Specialty: {record.DoctorSpecialty}</div>
              {api.isAdminOrMagStaffOrAccounting() && (
                <div>
                  <FormatPhoneNumber
                    officePhoneNumber={record.DoctorOfficePhone}
                    mobilePhoneNumber={record.DoctorPhone}
                  />
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
                  Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
                </div>
              )}
            </React.Fragment>
          )}
        </Table.Cell>
        <Table.Cell className={cx({ 'text-danger': record.IsOverdue })}>{record.NextStatusStaffText}</Table.Cell>
        <Table.Cell>{this.getPICName(record)}</Table.Cell>
        <Table.Cell>{record.CreatedByName}</Table.Cell>
      </Table.Row>
    ));
  };

  openViewSuppRequest = (id, record) => {
    return fileReviewStore.toggleModal(true, {
      modalType: 'viewSuppRequest',
      id,
      record,
    })();
  };

  renderTableBodyCM = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">
          {this.isShowDetailTypist(record) ? this.renderTableActions(Id, record) : ``}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          {this.isShowDetailTypist(record) ? (
            <Link to="#" onClick={this.openCaseDetail(Id, 'Document', record)}>
              {record.FileReviewNumber}
            </Link>
          ) : (
            record.FileReviewNumber
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.FileReviewNumber,
            })}
          >
            {record.StatusStaffText}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate ? moment(formatDate(record.RequestDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          {record.TypeOfClaimnt}/{record.TypeOfRerpot}
        </Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          {' '}
          ({record.ClaimantId}) {record.Claimant}
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell>
          <h5 className="ma-0">{record.CaseManager}</h5>
          {record.IsDirect && <div>{record.CMEmail}</div>}
          {record.IsDirect && (
            <div>
              <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
            {record.DoctorName}{' '}
          </h5>
          {record.DoctorName && (
            <React.Fragment>
              <div style={{ whiteSpace: 'nowrap' }}>Specialty: {record.DoctorSpecialty}</div>
              {api.isAdminOrMagStaffOrAccounting() && (
                <div>
                  <FormatPhoneNumber
                    officePhoneNumber={record.DoctorOfficePhone}
                    mobilePhoneNumber={record.DoctorPhone}
                  />
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
                  Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
                </div>
              )}
            </React.Fragment>
          )}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        !api.isTypist() ? (
          <Menu>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openCaseDetail(id, `CaseDetails`, record)}>Update Claim Details</Menu.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Update Case Documents</Menu.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Update Dictations & Reports</Menu.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openCaseDetail(id, `ServicePrepaymentSection`, record)}>
                Update Provider Payment
              </Menu.Item>
            )}
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `CaseDetails`, record)}>Update Claim Details</Menu.Item>
            )}
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Upload Case Documents</Menu.Item>
            )}
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>View Completed Reports</Menu.Item>
            )}
            {api.isCaseManager() && (
              <Menu.Item onClick={this.openCaseDetail(id, `Cancellation`, record)}>Cancel Case Request</Menu.Item>
            )}
            <Menu.Item onClick={this.openCaseDetail(id, 'Corespondence', record)}>
              {api.isAdminOrMagStaffOrAccounting() ? `Update Correspondence` : `View Correspondence`}
            </Menu.Item>
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openPrintCase(`/FileReview/FileReviewPrint?id=${id}`)}>
                Print Service Request
              </Menu.Item>
            )}
            {!api.isCaseManager() && (
              <Menu.Item
                onClick={this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to send report status update to booking CM?',
                  onOk: this.handleSendStatusUpdate(id, 0),
                })}
              >
                Send Status Update (CMs) (MAIL-105)
              </Menu.Item>
            )}
            {!api.isCaseManager() && record.DoctorName && (
              <Menu.Item
                onClick={this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to send report status update to assessing specialist?',
                  onOk: this.handleSendStatusUpdate(id, 1),
                })}
              >
                Send Status Update (Specialist) (MAIL-106)
              </Menu.Item>
            )}
            {(record.IsBilled || record.Status === 'Report Completed' || record.Status === 'Cancelled With Fee') &&
              api.isAdminOrMagStaffOrAccounting() && (
                <Menu.Item style={{ pointerEvents: 'none' }}>
                  <Divider style={{ margin: 0 }} />
                </Menu.Item>
              )}
            {/* {(record.Status === 'Report Completed' ||
          record.Status === 'Cancelled With Fee' ||
          record.IsBilled ||
          record.InvoiceId > 0) && (
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
          )} */}
            {/* <Menu.Item onClick={this.openCaseDetailCM(id, `Invoices`)}>
          View Invoice
        </Menu.Item> */}
            {/* {(record.CaseProgress === api.frStatusEnum.REPORT_COMPLETED ||
              record.CaseProgress === api.frStatusEnum.CANCELLED_WITH_FEE ||
              record.ClientPrepaymentRequired) && */}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.handleAddCopyInvoice(id, 0)}>
                <Icon name="plus" size="small" /> Generate Invoice
              </Menu.Item>
            )}
            {record.IsBilled && api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
            )}
            {record.InvoiceId > 0 && api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openViewInvoice(record.InvoiceId)}>View Invoice</Menu.Item>
            )}
            {record.InvoiceId > 0 && api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
                Print invoice
              </Menu.Item>
            )}
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item
                onClick={this.openModal({
                  modalType: 'confirm',
                  message:
                    'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
                  onOk: this.handleDuplicateAndRemoe(id, record, this.duplicate, `duplicate`),
                })}
              >
                Rebook this Case
              </Menu.Item>
            )}
            {/* {api.isAdmin() && <Menu.Item onClick={() => this.delete(id, record)}>Delete this Case</Menu.Item>} */}
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>

            {record.IsExistSuppRequest && (
              <Menu.Item onClick={() => this.openViewSuppRequest(id, record)}>View Supp Requests</Menu.Item>
            )}
            <Menu.Item onClick={this.openCreateNewSupp(id, record.FileReviewNumber)}>Request Supp Report</Menu.Item>
          </Menu>
        ) : (
          <Menu>
            <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Download Dictations</Menu.Item>
            <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Upload Typed Reports</Menu.Item>
          </Menu>
        )
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    fileReviewStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = fileReviewStore;
    const colSpan = api.isAdminOrMagStaffOrAccounting() ? columns.length : columnsCM.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: 0 }}>
        <TableHeader
          columns={api.isAdminOrMagStaffOrAccounting() ? columns : columnsCM}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={fileReviewStore.handleSort}
        />
        <Table.Body>
          {api.isAdminOrMagStaffOrAccounting()
            ? this.renderTableBody(data, colSpan)
            : this.renderTableBodyCM(data, colSpan)}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={fileReviewStore.curPage}
          totalPage={fileReviewStore.totalPage}
          totalItems={fileReviewStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default FileReviewTable;
