import React from 'react';
import { Form, Input, notification, Spin } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './ModalCreate.scss';
import Modal from '../../shared/Modal';
import SalesTargetStore from './Store';
import { getSaleTargetInfo, saveTarget } from './Service';
import * as api from '@stores/api';

@observer
class ModalCreate extends React.Component {
  state = {
    loading: true,
    data: null,
  };

  handleSave = () => {
    const { data } = this.state;
    if (!data.Key) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 3,
      });
    } else {
      Promise.all([saveTarget(data)])
        .then(([response]) => {
          if (response.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
              duration: 3,
            });
            SalesTargetStore.refetch({}, true);
            this.props.onCancel();
          }
        })
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
            duration: 3,
          });
        });
    }
  };
  componentDidMount() {
    const { id } = SalesTargetStore.modalParams;
    if (id) {
      Promise.all([getSaleTargetInfo(id)]).then(([targetInfo]) => {
        this.setState({
          loading: false,
          data: targetInfo.objItem,
        });
      });
    } else {
      this.setState({
        loading: false,
        data: {
          Id: 0,
          Key: '',
          Label: '',
          Target: 0,
        },
      });
    }
  }

  handleFieldChange = fieldName => event => {
    const { data } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      data: {
        ...data,
        [fieldName]: value,
      },
    });
  };

  render() {
    const { record, id } = SalesTargetStore.modalParams;
    const { open } = SalesTargetStore;

    const { data, loading } = this.state;

    return (
      <Modal
        width={800}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>}
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New Campaign' : record.Key}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item
              label="Key"
              required
              validateStatus={!data.Key ? 'error' : null}
              help={!data.Key ? 'This field is required' : null}
            >
              <Input disabled={data.Id !== 0} value={data.Key} onChange={this.handleFieldChange('Key')} />
            </Form.Item>
            <Form.Item label="Label" required>
              <Input value={data.Label} onChange={this.handleFieldChange('Label')} />
            </Form.Item>
            <Form.Item label="Target" required>
              <Input value={data.Target} type="number" onChange={this.handleFieldChange('Target')} />
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ModalCreate;
