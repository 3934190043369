import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import * as api from '@stores/api';
import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import ModuleStore from './ModuleStore';

const options = [
  {
    text: 'Mail Module',
    value: 'mailmoduletype',
  },
  {
    text: 'Mail Receiver',
    value: 'mailreceivertype',
  },
  {
    text: 'Case Stage',
    value: 'casestages',
  },
];

const statusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
];

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: this.props.modalParams.record
        ? {
            ...this.props.modalParams.record,
            description: this.props.modalParams.record.Description || '',
            label: this.props.modalParams.record.Label || '',
            type: this.props.modalParams.record.Type || 'mailmoduletype',
            isActive: this.props.modalParams.record.IsActive || false,
            orderNo: this.props.modalParams.record.OrderNo || 0,
          }
        : {
            description: '',
            label: '',
            type: ModuleStore.filter,
            isActive: true,
            orderNo: 0,
          },
    };
  }
  renderTypeLabel = () => {
    return options.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderTypeLabelText = value => {
    return options.filter(i => i.value === value).map(i => i.text)[0];
  };
  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const param = {
      Label: body.label,
      OrderNo: body.orderNo,
      Description: body.description,
      IsActive: body.isActive,
      Type: body.type,
    };
    if (id === 0) {
      ModuleStore.setFieldsValue({ loading: true });
      try {
        await customFetch('/MailTemplate/AddLockupConfig', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            Id: id,
            ...param,
          }),
        });
        await ModuleStore.refetch({}, true);
      } catch (e) {
      } finally {
        ModuleStore.setFieldsValue({ loading: false });
        this.props.onCancel();
      }
    } else {
      ModuleStore.setFieldsValue({ loading: true });
      try {
        await customFetch('/MailTemplate/UpdateLockupConfig', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            Id: id,
            ...param,
          }),
        });
        await ModuleStore.refetch({}, true);
      } catch (e) {
      } finally {
        ModuleStore.setFieldsValue({ loading: false });
        this.props.onCancel();
      }
    }
  };
  handleCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const { loading } = ModuleStore;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`${
          id === 0
            ? `${
                body.type === 'mailmoduletype'
                  ? 'New Module'
                  : body.type === 'mailreceivertype'
                  ? 'New Receiver'
                  : 'New Case Stage'
              }`
            : `${body.Label}`
        }`}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Input
                  label={`${
                    body.type === 'mailmoduletype'
                      ? 'Module'
                      : body.type === 'mailreceivertype'
                      ? 'Receiver'
                      : 'Case Stage'
                  }`}
                  value={body.label}
                  placeholder="Label"
                  name="label"
                  onChange={this.handleChangeInput}
                />
                <Form.Select
                  label="Type"
                  options={this.renderTypeLabel()}
                  name="type"
                  disabled={id !== 0}
                  onChange={this.handleChangeDropdown}
                  text={this.renderTypeLabelText(body.type)}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Order No"
                  value={body.orderNo}
                  type="number"
                  name="orderNo"
                  onChange={this.handleChangeInput}
                />
                <Form.Select
                  label="Status"
                  options={this.renderStatusLabel()}
                  name="isActive"
                  onChange={this.handleChangeDropdown}
                  text={this.renderStatusLabelText(body.isActive)}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  label="Description"
                  value={body.description}
                  placeholder="Description"
                  name="description"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
