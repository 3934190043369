import {
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Radio,
  Select,
  TimePicker,
  Tooltip,
  notification,
} from 'antd';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Button, Icon as IconSemantic, Popup } from 'semantic-ui-react';

import FieldUpload from './FieldUpload';
import {
  checkExcludeTypeOfService,
  getAllBlockedClients,
  getConfirmDoctorRate,
  getDoctorRate,
  getStaffRecentChangeHistory,
  sendCMReminder,
  viewFieldChangeHistory,
  viewHistoryReminder,
} from './service';
import { CONSTANTS, DEFAULT_COLUMNS_RECENT_CHANGE, DEFAULT_FIELD_NAME_RECENT_CHANGE, LOCAL_CONSTANT } from './type';

import appStore from 'stores/app';
import * as api from '../../../stores/api';
import store from './Store';

import { timezoneList } from '@components/doctor-routes/AddEditMySessions/type';
import dashboardStore from '@stores/dashboard';
import customFetch from '@utils/customFetch';
import { Header } from '../../shared/Collapse/Panel';
import { commonInlineStyles, renderDate } from '../Shared';
import { formatDate } from '../../../utils/functions';

const formatTimeHeader = (dateFrom, dateTo, timezone) => {
  let timeHeader = '';
  if (dateFrom) {
    timeHeader = renderDate(dateFrom, false, true);

    if (dateTo) {
      timeHeader = `${timeHeader} ~ ${moment(dateTo).format('HH:mm')}`;
    }
  }

  //console.log('dateFrom', dateFrom);
  //console.log('dateTo', dateTo);
  return dateFrom && dateTo && timezone
    ? `${moment(dateFrom)
        .tz(timezone)
        .format('DD/MM/YYYY HH:mm')} ~ ${moment(dateTo)
        .tz(timezone)
        .format('HH:mm')} ${timezone ?? ''}`
    : '';
};

const styles = {
  ...commonInlineStyles,
  highlightSpan5: { ...commonInlineStyles.highlightColor, marginLeft: 5 },
  highlightSpan25: { ...commonInlineStyles.highlightColor, marginLeft: 25 },
  marginTop22: { marginTop: 22 },
  appointmentCommentWarning: {
    ...commonInlineStyles.marginTop10,
    color: 'red',
  },
};

@observer
class AppointmentDetailsAndCaseDocuments extends React.Component {
  state = {
    specialistClinic: null,
  };
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  handleOpenCalendarModuleClick = action => {
    const itemModel = toJS(store.assessmentInfo);

    let IsSelectingAppointmentForAssessment = true;
    const { specialist = {}, clinic = {} } = store;
    if (itemModel.IsBilled) {
      return store.showNotificationError("You can't add appointment as this assessment has an issued invoice.");
    }
    localStorage.setItem('ChangeCalSessionMode', action);
    if (action === 'addAppt') {
      dashboardStore.close('/view/calendar-2');

      setTimeout(() => {
        dashboardStore.open(`/view/calendar-2?pageGetter=addEditIME&fieldName=SessionId&onlySessions=true`);
      });
      return;
    } else {
      let sessionDate = moment();
      if (itemModel.DateFrom) {
        itemModel.DateFrom.hour(0);
        itemModel.DateFrom.minutes(0);
        sessionDate = itemModel.DateFrom;
      }

      IsSelectingAppointmentForAssessment = false;

      const doctorSession = {
        clinicId: clinic?.Id || null,
        doctorId: specialist?.Id || null,
        selectedDate: sessionDate,
        doctor: specialist,
      };
      localStorage.setItem('Data:DoctorSessionDateSelected', JSON.stringify(doctorSession));
    }

    if (appStore.iframeMode) {
      window.parent.postMessage({ action: 'OPEN_CALENDAR' }, '*');
      return;
    }
    dashboardStore.close('/view/calendar-2');

    setTimeout(() => {
      dashboardStore.open(`/view/calendar-2?pageGetter=addEditIME&fieldName=SessionId&onlySessions=true`);
    });

    return IsSelectingAppointmentForAssessment;
  };
  handleOpenCaseProgress = () => {
    return store.toggleModal(true, {
      modalType: 'caseProgress',
    })();
  };

  @action handleChangeModeMultipleObj = (_, event, fieldName, lengthLimit) => {
    const data = event.map(i => i.props.data);
    var bdata = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null));
    console.log('handleChangeModeMultipleObj', bdata);

    const data_ = bdata.filter(i => i.Id);

    if (lengthLimit) {
      if (data_.length <= lengthLimit) {
        return (store[fieldName] = data_);
      } else return;
    }
    return (store[fieldName] = data_);
  };

  handleAppointmentConfirmedChange = action(event => {
    const value = event.target.checked;
    const { claimant = {}, submitData } = store;
    const itemModel = toJS(store.assessmentInfo);
    if (value) {
      store.handleChangeValue('AppointmentConfirmedDate', moment());
      store.submitData = {
        ...submitData,
        sendDaysheetToDoctor: false,
      };
      // if (claimant?.Email || claimant?.Telephone) {
      //   store.handleChangeValue('NotifyClaimantConfirmedAppointment', true);
      // }
      if (!store.assessmentInfo.SentDateNotifyCMAppointmentInquiry) {
        store.handleChangeValue('NotifyCmAppointmentInquiry', false);
      }
      if (!store.assessmentInfo.SentDateNotifyDoctorAppointmentInquiry) {
        store.handleChangeValue('NotifyDoctorAppointmentInquiry', false);
      }
      if (itemModel.AssessmentMethod) {
        store.handleChangeValue('SendEassessUrlToClaimantLocation', true);
        store.handleChangeValue('SendEassessUrlToDoctorLocation', true);
      }
    } else {
      store.submitData = {
        ...submitData,
        sendDaysheetToDoctor: false,
      };
      store.handleChangeValue('AppointmentConfirmedDate', null);
      if (!store.assessmentInfo.SentDateNotifyCMAppointmentInquiry) {
        store.handleChangeValue('NotifyCmAppointmentInquiry', true);
      }
      if (!store.assessmentInfo.SentDateNotifyDoctorAppointmentInquiry && !!store.specialist?.Email2) {
        store.handleChangeValue('NotifyDoctorAppointmentInquiry', true);
      }
      if (!store.assessmentInfo.SentDateNotifyStaffPICAppointmentInquiry) {
        store.handleChangeValue('NotifyStaffPICAppointmentInquiry', true);
      }
      if (itemModel.AssessmentMethod) {
        if (!itemModel.SendDateEassessToClaimant) {
          store.handleChangeValue('SendEassessUrlToClaimantLocation', false);
        }
        if (!itemModel.SendDateEassessToDoctor) {
          store.handleChangeValue('SendEassessUrlToDoctorLocation', false);
        }
      }
    }
    store.handleChangeValue('IsAppointmentConfirmed', value);
    if (value) {
      if (!store.assessmentInfo.NotifyCmAppointmentConfirmed) {
        store.handleChangeValue('NotifyCmAppointmentConfirmed', true);
      }
      if (!store.assessmentInfo.SentDateNotifyStaffPICAppointmentConfirmed) {
        store.handleChangeValue('NotifyStaffPICAppointmentConfirmed', true);
      }
      if (!store.assessmentInfo.NotifyDoctorAppointmentConfirmed) {
        if (!store.specialist?.SubscribeToAppointmentConfirmation) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'This specialist does not want to receive appointment confirmations, do you still want to send?',
            onOk: () => {
              store.handleChangeValue('NotifyDoctorAppointmentConfirmed', true);
            },
            onCancel: () => {
              store.handleChangeValue('NotifyDoctorAppointmentConfirmed', false);
            },
          })();
        } else {
          store.handleChangeValue('NotifyDoctorAppointmentConfirmed', true);
        }
      }
      if (!store.assessmentInfo.SentDateNotifyStaffPICAppointmentInquiry) {
        store.handleChangeValue('NotifyStaffPICAppointmentInquiry', false);
      }
    } else {
      if (!store.assessmentInfo.SentDateEmailToCMConfirmAppointment) {
        store.handleChangeValue('NotifyCmAppointmentConfirmed', false);
      }
      if (!store.assessmentInfo.SentDateEmailToDoctorConfirmAppointment) {
        store.handleChangeValue('NotifyDoctorAppointmentConfirmed', false);
      }
      if (!store.assessmentInfo.SentDateNotifyStaffPICAppointmentConfirmed) {
        store.handleChangeValue('NotifyStaffPICAppointmentConfirmed', false);
      }
      // if (!store.assessmentInfo.SendDateNotifyClaimantConfirmedAppointment) {
      //   store.handleChangeValue('NotifyClaimantConfirmedAppointment', false);
      // }
    }
    return this.ChangeAssDate();
  });

  handleChangeSessionAcceptsType = action(event => {
    const value = event.target.value;
    const itemModel = toJS(store.assessmentInfo);
    switch (value) {
      case 0:
        store.assessmentInfo = {
          ...itemModel,
          SessionAcceptsType: 0,
          AssessmentMethod: false,
        };
        store.assessmentMethodDisable = true;
        break;
      case 1:
        store.assessmentInfo = {
          ...itemModel,
          SessionAcceptsType: 1,
          AssessmentMethod: true,
          SendEassessUrlToClaimantLocation: itemModel.IsAppointmentConfirmed ? true : false,
          SendEassessUrlToDoctorLocation: itemModel.IsAppointmentConfirmed ? true : false,
        };
        store.assessmentMethodDisable = false;
        store.handleAssessmentMethodChanged();
        break;
      case 2:
        store.assessmentInfo = {
          ...itemModel,
          SessionAcceptsType: 2,
          AssessmentMethod: false,
        };
        store.assessmentMethodDisable = false;
        break;
      default:
        return store.showNotificationError('Unknown Session Accept Type!');
    }
  });

  @action onAllowDoctorToViewPaperworkChange = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      itemModel.AllowDoctorToViewPaperwork = false;
      notification.error({
        message: 'Error',
        description:
          "Paperwork can't be sent to specialist before appointment is confirmed. Please check appointment status.",
      });
      return (store.assessmentInfo = {
        ...itemModel,
        AllowDoctorToViewPaperwork: false,
      });
    }
    if (itemModel.AllowDoctorToViewPaperwork) {
      if (
        !store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork).length &&
        !itemModel.PaperworkExLink
      ) {
        notification.error({
          message: 'Error',
          description: 'You need to attach LOI, paperwork or share download links before sending to Doctor.',
        });
        store.assessmentInfo = {
          ...itemModel,
          PaperWorkReceived: false,
        };
      } else {
        store.assessmentInfo = { ...itemModel, PaperWorkReceived: true };
        // if (
        //   itemModel.PaperWorkReceived &&
        //   (!itemModel.AssessmentServiceItems ||
        //     itemModel.AssessmentServiceItems.length === 0)
        // ) {
        //   notification.error({
        //     message: 'Error',
        //     description:
        //       'Billing item codes are still missing in the Services Notes and Fees. Click close to continue.',
        //   });
        // }
      }
    }
  };

  handleOpenAddEdit = (actionType = 'add', storageName, storageValue, pathName, id = 0, fieldName) => {
    if (id === 0) {
      localStorage.setItem(storageName, storageValue);
      dashboardStore.close(pathName, true);
      setTimeout(() => {
        dashboardStore.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
      });
    } else {
      if (actionType === 'add') {
        localStorage.removeItem(storageName);
        dashboardStore.close(pathName, true);
        setTimeout(() => {
          dashboardStore.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      } else {
        localStorage.setItem(storageName, storageValue);
        dashboardStore.close(pathName, true);
        setTimeout(() => {
          dashboardStore.open(`${pathName}&pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      }
    }
  };

  renderStaffName = id => {
    const { listStaff } = store;
    const rs = listStaff.itemList.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'EassessDoctorLocationChecklistReceived') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessDoctorLocationChecklistReceivedDate: moment(),
        };
        return store.handleActiveUploadType(toJS(store.currentStep));
      }
      if (fieldName === 'AllowDoctorToViewPaperwork') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        return this.onAllowDoctorToViewPaperworkChange();
      }
      if (fieldName === 'SendInterpreterRqtoProvider') {
        if (value && !itemModel.InterpretationServiceClientEmail) {
          store.showNotificationError('Please update email for interpreter.');
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          });
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          });
        }
      }
      if (fieldName === 'SelectedSendInterpretation') {
        if (value && (!itemModel.InterpretationServiceClientId || itemModel.InterpretationServiceClientId <= 0)) {
          store.showNotificationError('Select an Interpreter Provider first.');
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          });
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          });
        }
      }
      //Send video link check to confirm
      if (fieldName === 'SendEassessUrlToInterpreter') {
        if (value) {
          const InterpreterEmail = store.interpreterProviderCMs.find(
            i => i.Id === store.assessmentInfo.CMsInterpreterProviderId,
          );

          if (!itemModel.InterpreterEassessUrl) {
            store.showNotificationError('Video Link to Interpreter must be created first.'); //
            return (store.assessmentInfo = {
              ...itemModel,
              SendEassessUrlToInterpreter: false,
            });
          } else if (!InterpreterEmail || !InterpreterEmail?.Email) {
            store.showNotificationError('Interpreter Email must be filled in.');
            return (store.assessmentInfo = {
              ...itemModel,
              SendEassessUrlToInterpreter: false,
            });
          } else {
            return (store.assessmentInfo = {
              ...itemModel,
              SendEassessUrlToInterpreter: value,
            });
          }
        }
        return store.handleChangeValue('SendEassessUrlToInterpreter', value);
      }

      if (fieldName === 'EassessClaimantLocationConfirmed' && value) {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessClaimantLocationConfirmedDate: moment(),
        });
      }
      if (fieldName === 'EassessClaimantLocationChecklistReceived' && value) {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessClaimantLocationChecklistReceivedDate: moment(),
        };
        return store.handleActiveUploadType(toJS(store.currentStep));
      }
      if (fieldName === 'ConsentFormReceived' && value) {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          ConsentFormReceivedDate: moment(),
        };
        return store.handleActiveUploadType(toJS(store.currentStep));
      }
      if (fieldName === 'SendEassessUrlToClaimantLocation' && value && !itemModel.EassessUrl) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'Video Assessment Link to Claimant must be created first.',
        });
      }

      if (fieldName === 'SendEassessUrlToClaimantLocation' && value && !itemModel.EassessClaimantEmail) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'Claimant Email must be filled in.',
        });
      }

      if (fieldName === 'EassessDoctorLocationConfirmed' && value) {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessDoctorLocationConfirmedDate: moment(),
        });
      }
      if (fieldName === 'NotifyClaimantConfirmedAppointment') {
        if (value) {
          if (itemModel.AssessmentMethod) {
            if (!itemModel.EassessClaimantEmail && !itemModel.EassessClaimantPhone) {
              if (!store.claimant?.Email && !store.claimant?.Telephone) {
                notification.destroy();
                notification.error({
                  message: 'Error',
                  description: 'Claimant has no email or mobile phone. Please check again.',
                });
                return (store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: false,
                });
              }
              notification.destroy();
              notification.error({
                message: 'Error',
                description: 'Claimant has no email or mobile phone. Please check again.',
              });
              itemModel.NotifyClaimantConfirmedAppointment = false;
              return (store.assessmentInfo = {
                ...itemModel,
                [fieldName]: value,
              });
            }
          }
        }
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        });
      }
      if (fieldName === 'NotifyDoctorAppointmentConfirmed') {
        if (!store.specialist?.Id) {
          notification.destroy(); // check doctor email
          return notification.error({
            message: 'Error',
            description: 'Please select a specialist first!',
          });
        } else {
          if (!store.specialist?.SubscribeToAppointmentConfirmation && value) {
            store.toggleModal(true, {
              modalType: 'confirm',
              message: 'This specialist does not want to receive appointment confirmations, do you still want to send?',
              onOk: () => {},
              onCancel: () => {
                this.handleChangeValue(fieldName, false);
              },
            })();
          }
        }
      }

      if (fieldName === 'PaperWorkReceived') {
        return this.onPaperworkReceivedChange();
      }

      if (fieldName === 'NotifyCmAppointmentConfirmed') {
        store.submitData = {
          ...store.submitData,
          notifyCmAboutAppointmentConfirmed: value,
        };
      }

      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  @action handleChangeValue = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };
  @action ChangeAssDate = () => {
    const itemModel = toJS(store.assessmentInfo);
    const hourList = store.generateTimeRange(24);
    const minuteList = store.generateTimeRange(60);
    //chay lai moi lan doi appoint date
    if (itemModel.DateFrom) {
      if (!(itemModel.DateFromHour && itemModel.DateFromHour.value > 0)) {
        this.handleChangeValue('DateFromHour', hourList[9]);
        this.handleChangeValue('DateFromMinute', minuteList[0]);
        this.handleChangeValue('DateToHour', hourList[9]);
        this.handleChangeValue('DateToMinute', minuteList[0]);
      }
      if (
        // !itemModel.RequestExpectedCompletionDate &&
        itemModel.Attendance !== CONSTANTS.Attendance_LCF &&
        itemModel.Attendance !== CONSTANTS.Attendance_Cancelled
      ) {
        this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(3, 'days'));
        const day = store.assessmentInfo.RequestExpectedCompletionDate.day();
        if (day === 6) {
          this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(5, 'days'));
        } else if (day === 0) {
          this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(4, 'days'));
        }
      }
    } else {
      this.handleChangeValue('RequestExpectedCompletionDate', null);
    }
  };

  checkSelectOptionSend = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (itemModel.PaperWorkReceived) {
      let showMessage = true;
      if (itemModel.AllowDoctorToViewPaperwork) {
        showMessage = false;
      }
      const selectedItems = itemModel.SelectedPaperworkToDoctorBies.filter(function(item) {
        return item;
      });
      if (showMessage && selectedItems.length > 0) {
        showMessage = false;
      }
      return showMessage;
    }
  };

  @action onPaperworkReceivedChange = () => {
    let itemModel = toJS(store.assessmentInfo);
    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      notification.error({
        message: 'Error',
        description:
          "Paperwork can't be sent to specialist before appointment is confirmed. Please check appointment status.",
      });
      return (store.assessmentInfo = {
        ...itemModel,
        PaperWorkReceived: false,
        AllowDoctorToViewPaperwork: false,
      });
    }
    let showMessage = this.checkSelectOptionSend();
    if (!api.isAdminOrMagStaffOrAccounting()) {
      itemModel.AllowDoctorToViewPaperwork = true;
      showMessage = false;
    }
    if (showMessage) {
      notification.error({
        message: 'Error',
        description: 'Please select a request sending option first',
      });
      return (store.assessmentInfo = {
        ...itemModel,
        PaperWorkReceived: false,
      });
    } else {
      if (!itemModel.IsAppointmentConfirmed && itemModel.PaperWorkReceived) {
        return store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Appointment is not yet confirmed. Do you want to continue?',
          onOk: () =>
            action(() => {
              store.assessmentInfo({
                ...itemModel,
                PaperWorkReceived: false,
                AllowDoctorToViewPaperwork: false,
                PaperworkReceivedDate: itemModel.PaperWorkReceived ? moment() : null,
              });
            }),
        })();
      } else {
        itemModel.PaperworkReceivedDate = itemModel.PaperWorkReceived ? moment() : null;
        if (!itemModel.PaperWorkReceived && !itemModel.AllowDoctorToViewPaperwork) {
          store.assessmentInfo = {
            ...itemModel,
            AllowDoctorToViewPaperwork: false,
          };
        }
      }
    }
    // if (
    //   itemModel.PaperWorkReceived &&
    //   (!itemModel.AssessmentServiceItems ||
    //     itemModel.AssessmentServiceItems.length === 0)
    // ) {
    //   notification.error({
    //     message: 'Error',
    //     description:
    //       'Billing item codes are still missing in the Services Notes and Fees. Click close to continue.',
    //   });
    // }
  };
  @action handleChangeDatePicker = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    if (fieldName === 'DateFrom') {
      const hourList = store.generateTimeRange(24);
      const minuteList = store.generateTimeRange(60);
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
        DateFromHour: hourList[value.hour()],
        DateFromMinute: minuteList[value.minutes()],
      };
      return this.ChangeAssDate();
    }
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  handleChangeRangePicker = fieldName =>
    action(event => {
      const itemModel = toJS(store.assessmentInfo);
      const hourList = store.generateTimeRange(24);
      const minuteList = store.generateTimeRange(60);
      if (fieldName === 'DateFrom') {
        notification.destroy();
        notification.info({
          message: 'Information',
          description: 'Remember to send appointment updates to participants',
          duration: 5,
        });
        const dateTo = event ? moment(event).add(30, 'minutes') : null;
        store.assessmentInfo = {
          ...itemModel,
          DateFrom: event,
          DateTo: dateTo,
        };
        this.checkNotifyCMAppointment();
        return this.ChangeAssDate();
      } else if (fieldName === 'DateFromHour') {
        notification.destroy();
        notification.info({
          message: 'Information',
          description: 'Remember to send appointment updates to participants',
          duration: 5,
        });
        const dateFrom = event;
        store.assessmentInfo = {
          ...itemModel,
          DateFrom: event,
          DateFromHour: hourList[dateFrom.hour()],
          DateFromMinute: minuteList[dateFrom.minutes()],
        };
        this.checkNotifyCMAppointment();
        return this.ChangeAssDate();
      } else if (fieldName === 'DateToHour') {
        notification.destroy();
        notification.info({
          message: 'Information',
          description: 'Remember to send appointment updates to participants',
          duration: 5,
        });
        const dateTo = event;
        store.assessmentInfo = {
          ...itemModel,
          DateTo: event,
          DateToHour: hourList[dateTo.hour()],
          DateToMinute: minuteList[dateTo.minutes()],
        };
        this.checkNotifyCMAppointment();
        return this.ChangeAssDate();
      } else {
        return this.ChangeAssDate();
      }
    });

  @action checkNotifyCMAppointment = () => {
    if (store.specialist?.Id > 0 && !!store.assessmentInfo.DateFrom && store.assessmentInfo.IsAppointmentConfirmed) {
      store.assessmentInfo.NotifyCmAppointmentConfirmed = true;
    }
  };

  handleChangeFieldRadioBox = fieldName =>
    action(event => {
      const value = event.target.value;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'DictationOption') {
        store.bindCommisionRate();
      }
      if (fieldName === 'InterpretationStatus') {
        if (value !== -1 && !!!itemModel.InterpretationServiceClientId) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Select an Interpreter Provider first.',
          })();
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: -1,
          });
        }
        if (value === 0 && !itemModel.InterpretationServiceConfirmedDateTime) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            InterpretationServiceConfirmedDateTime: moment(),
          });
        }
        if ((value === 1 || value === 2) && !itemModel.InterpreterStatusCancelledDate) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            InterpreterStatusCancelledDate: moment(),
            InterpreterStatusReasonCancellation: 0,
          });
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          });
        }
      }
      if (fieldName === 'AssessmentMethod') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        return store.handleAssessmentMethodChanged();
      }
      if (fieldName === 'ClinicOption') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        return store.onClinicOptionChange();
      }
      if (fieldName === 'HasInterpretationService') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          SendInterpreterRqtoOperator: value,
        };
        return;
      }
      if (fieldName === 'Interpreter') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          InterpretationStatus: -1,
        };
        return;
      }
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });
  handleChangeFieldSelect = fieldName =>
    action(event => {
      const value = event;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'EassessClaimantLocationType' && value === 'ClaimantHome') {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessClaimantAddress: store.claimant?.Address || null,
        });
      }

      if (fieldName === 'InterpretationServiceClientId') {
        store.assessmentInfo = {
          ...itemModel,
          SelectedSendInterpretation: true,
        };
        store.refetchInterproviderCMs(value);
        const clinicInterpretationServiceList = toJS(store.clinicInterpretationServiceList);
        const matchResult = clinicInterpretationServiceList.find(i => i.Id === value);
        if (matchResult) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            InterpretationServiceClient: matchResult.Name,
            CMsInterpreterProviderId: null,
            InterpretationServiceClientEmail: matchResult.Email,
            SendInterpreterRqtoProvider: matchResult.Email ? true : false,
          });
        } else
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            CMsInterpreterProviderId: null,
            InterpretationServiceClientEmail: null,
            SendInterpreterRqtoProvider: matchResult?.Email ? true : false,
          });
      }
      if (fieldName === 'EassessDoctorLocationType' && value === 'AClinic') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessDoctorAddress: '',
        };
        this.setState({ specialistClinic: null });
        store.setFieldsValue({ listClinicForAddress: null });
        return store.handleRefetchClinicAddress();
      }
      if (fieldName === 'EassessDoctorLocationType' && value === 'DoctorOffice' && store.specialist?.Id) {
        this.setState({ specialistClinic: null });
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessDoctorAddress: store.specialist?.FullAddress,
        };
        store.setFieldsValue({ listClinicForAddress: null });
        return store.handleRefetchClinicAddress();
      }
      if (fieldName === 'EassessDoctorLocationType' && value === 'Other' && store.specialist?.Id) {
        this.setState({ specialistClinic: null });
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          EassessDoctorAddress: '',
        };
        store.setFieldsValue({ listClinicForAddress: null });
        return;
      }

      if (fieldName === 'CMsInterpreterProviderId') {
        if (store.assessmentInfo.InterpretationStatus === 0) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            SelectedSendInterpretation: !!value ? true : false,
          });
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            //SelectedSendInterpretation: !!value ? true : false,
          });
        }
      }
      if (fieldName == 'AssessmentMethodType' && value === 'MAGVideo') {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          SendEassessUrlToClaimantLocation: itemModel.IsAppointmentConfirmed ? true : false,
          SendEassessUrlToDoctorLocation: itemModel.IsAppointmentConfirmed ? true : false,
        });
      }

      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });
  @action handleChangePIC = (_, event, fieldName) => {
    const { data } = event.props;
    const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
    return (store[fieldName] = {
      picId: data_.Id,
      picName: data_.FullName,
      inChargeOf: 0,
    });
  };

  handleUserInfo = (type, user) => {
    if (store.claimant && user === 'claimant') {
      if (type === 'mobile') {
        store.handleChangeValue('EassessClaimantPhone', store.claimant.Telephone);
      } else {
        store.handleChangeValue('EassessClaimantEmail', store.claimant.Email);
      }
    } else if (store.bookingCaseManager) {
      if (type === 'mobile') {
        store.handleChangeValue('EassessClaimantPhone', store.bookingCaseManager.OfficePhone);
      } else {
        store.handleChangeValue('EassessClaimantEmail', store.bookingCaseManager.Email);
      }
    }
  };
  handleRecentChange = async (record, actionName, columns, fieldNameStaffRecentChange) => {
    try {
      const response = await getStaffRecentChangeHistory(record.Id, actionName);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };
  handleViewClaimantHistory = async () => {
    const columns = [{ title: 'Assessment No' }, { title: 'Appointment Date' }, { title: 'Created Date' }];
    const fieldName = ['AssessmentNumber', 'DateFrom', 'CreateDate'];
    try {
      const itemModel = toJS(store.assessmentInfo);
      const response = await customFetch('/MedicalService/GetDoctorClaimantAppointmentHistory', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          assId: itemModel.Id,
          claimantId: store.claimant?.Id || 0,
          doctorId: store.specialist?.Id || 0,
        }),
      });

      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange: fieldName,
          showDateTime: true,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };
  handleViewHistoryReminder = async () => {
    try {
      const columns = [
        { title: 'Receiver' },
        { title: 'Email/SMS' },
        { title: 'Sent Date/Time' },
        { title: 'Sent By' },
      ];
      const fieldNameStaffRecentChange = ['Receivers', 'ServiceType', 'SentDate', 'StaffSent'];
      const itemModel = toJS(store.assessmentInfo);
      const response = await viewHistoryReminder(itemModel.Id);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      const arr_ = arr.filter(i => i[key] !== item[key]);
      return [...arr_, item];
    } else if (key && item && item[key] && arr.filter(i => i[key]).length === 0) {
      return [toJS(item)];
    }
    return arr || [];
  };
  handleCheckNCIPeriod = (specialist, oldValue) => {
    this.handleCheckGazettedRateOfDoctorRate();
    const { IsNotCurrentlyInterested, NotCurrentlyInterestedDate, NotCurrentlyInterestedEndDate } = specialist;
    //console.log(specialist);
    if (!!IsNotCurrentlyInterested && !!store.assessmentInfo.DateFrom) {
      if (
        moment(store.assessmentInfo.DateFrom).isSameOrAfter(moment(NotCurrentlyInterestedDate), 'day') &&
        moment(store.assessmentInfo.DateFrom).isSameOrBefore(moment(NotCurrentlyInterestedEndDate), 'day')
      ) {
        setTimeout(() => {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'This specialist indicates NCI during this period, do you want to continue with this booking?',
            onOk: () => {},
            onCancel: () => {
              store.setFieldsValue({ specialist: oldValue });
            },
          })();
        }, 500);
      }
    }
  };
  handleCheckGazettedRateOfDoctorRate = () => {
    if (store.specialist && store.reportType) {
      getConfirmDoctorRate(store.specialist?.Id, store.reportType?.Id).then(
        action(resp => {
          if (resp.status === 'success' && resp.showConfirm) {
            store.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate = true;
            notification.info({
              message: 'Gazetted Rate',
              description: `This specialist may not apply Gazetted rates for this service.`,
              duration: 15,
            });
          } else {
            store.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate = false;
          }
        }),
      );
    } else {
      store.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate = false;
    }
  };
  handleSpecialistChange = (oldDoctor, doctor) => {
    const itemModel = toJS(store.assessmentInfo);
    if (doctor.Id !== itemModel.MedNegDoctorId && itemModel.SpecialtyMedNegId > 0) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'This specialist is not the same as in Med Neg case. Do you want to proceed?',
        onOk: () => {
          // scope.doctorModel.selected = MedNegDoctor;
        },
      })();
    }
    if (!!doctor?.Id && api.isAdminOrMagStaffOrAccounting() && !!itemModel && itemModel.Id < 1) {
      customFetch('/MedicalService/GetAttachContractDoctor', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ doctorId: doctor?.Id }),
      }).then(response => {
        const contract = response.contract;

        let isOnboarded =
          contract.HasContractWithMag &&
          moment(contract.ContractWithMagDate).isBefore(moment()) &&
          (!contract.ContractWithMagEndDate || moment(contract.ContractWithMagEndDate).isAfter(moment()));

        if (!isOnboarded) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'This specialist does not have a contract with us. Do you want to continue?',
            onOk: () => {
              store.setFieldsValue({ specialist: doctor });
              this.handleCheckNCIPeriod(doctor, oldDoctor);
            },
            onCancel: () => {
              store.setFieldsValue({ specialist: oldDoctor });
            },
          })();
        } else {
          this.handleCheckNCIPeriod(doctor, oldDoctor);
        }
      });
    }
    if ((itemModel === null || itemModel.Id < 1) && doctor.DictationOptionStartDate) {
      const sDate = moment(doctor.DictationOptionStartDate).toDate();
      if (
        doctor.DictationOptionEndDate &&
        store.inRangeDate(new Date(), sDate, moment(doctor.DictationOptionEndDate).toDate())
      )
        this.handleChangeValue('DictationOption', doctor.DictationOption);
      else if (store.inRangeDate2(new Date(), sDate)) this.handleChangeValue('DictationOption', doctor.DictationOption);
      else this.handleChangeValue('DictationOption', 1);
    } else {
      this.handleChangeValue('DictationOption', 1);
    }
  };
  @action handleChangeObj = (_, event, fieldName) => {
    if (event) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      if (fieldName === 'specialist') {
        store.assessmentInfo.SpecialistPaperworkNotes = data_.PaperworkNotes;
        if (data_.Id && (store.claimType?.Id || store.reportType?.Id)) {
          notification.destroy();
          checkExcludeTypeOfService(data_.Id, store.claimType?.Id, store.reportType?.Id).then(resp => {
            if (resp.status === 'success' && resp.isExcludeService) {
              notification.warning({
                message: 'Error',
                description: `This specialist does not accept performing the required service. Please check with our staff`,
                duration: 15,
              });
            }
          });
        }
        if (api.isAdminOrMagStaffOrAccounting()) {
          if (store.bookingClient && store.bookingClient.Id > 0) {
            getAllBlockedClients(data_.Id).then(
              action(resp => {
                const { itemList } = resp;
                if (!!itemList.length && itemList[0] && itemList[0].ClientList && itemList[0].ClientList.length > 0) {
                  store.blockedClients = itemList[0].ClientList;
                  notification.destroy();
                  var findClient = store.blockedClients.find(i => i.Id === store.bookingClient.Id);
                  if (findClient) {
                    notification.error({
                      message: 'Blocked Client',
                      description: 'This client has been blocked from booking the specialist.',
                      duration: 15,
                    });
                  }
                } else {
                  store.blockedClients = [];
                }
              }),
            );
          }
        }
        this.handleSpecialistChange(store.specialist, data_);
        store[fieldName] = data_;
        return this.handleSpecialistChanged();
      }
      if (fieldName === 'specialist' || fieldName === 'clinic') {
        store[fieldName] = data_;
        return this.handleSpecialistChanged();
      }
      return (store[fieldName] = data_);
    } else {
      if (fieldName === 'specialist') {
        //console.log('Check DoctorRate', store.specialist, store.reportType);
        if (store.specialist && store.specialist?.Id > 0 && store.reportType && store.reportType?.Id > 0) {
          getDoctorRate(store.specialist?.Id, store.reportType?.Id).then(
            action(resp => {
              if (resp.status === 'success') {
                store.assessmentInfo.DoctorRate = resp.objRet;
              } else {
                store.assessmentInfo.DoctorRate = null;
              }
            }),
          );
          //console.log(1, store.assessmentInfo);
        }
        this.handleSpecialistChange(store.specialist, {});
        store[fieldName] = {};
        return this.handleSpecialistChanged();
      }
      return (store[fieldName] = {});
    }
  };
  @action handleSpecialistChanged = () => {
    const itemModel = toJS(store.assessmentInfo);
    this.checkNotifyCMAppointment();
    if (itemModel.AssessmentMethod) {
      if (!!store.specialist) {
        store.assessmentInfo = {
          ...toJS(store.assessmentInfo),
          EassessDoctorEmail: store.specialist.Email2,
          EassessDoctorPhone: store.specialist.Telephone,
        };
        return store.handleRefetchClinicAddress('');
      }

      if (store.clinic) {
        store.assessmentInfo = {
          ...toJS(store.assessmentInfo),
          EassessDoctorAddress: store.clinic.FullAddress,
        };
      }
    }
    store.handleCheckICare();
  };
  handleSearch = fieldName => value => {
    if (fieldName === 'specialist') {
      return value
        ? store.handleRefetchSpecialist({ keywordAllWithoutNCI: value })
        : store.handleRefetchSpecialist({
            keywordAllWithoutNCI: store.specialist?.Id || '',
            numItemPerPageAllWithoutNCI: 30,
          });
    }
    if (fieldName === 'clinic') {
      return value
        ? store.handleRefetchClinic({ keywordClinicList: value })
        : store.handleRefetchClinic({
            keywordClinicList: store.clinic?.Id || '',
            numItemPerPageClinicList: 30,
          });
    }
    if (fieldName === 'homeClinic') {
      return value
        ? store.handleRefetchClinicByType({ typeClinicList: 'home', keywordClinicList: value })
        : store.handleRefetchClinicByType({
            typeClinicList: 'home',
            keywordClinicList: store.clinic?.Id || '',
            numItemPerPageClinicList: 30,
          });
    }
    if (fieldName === 'clinicAddress') {
      return store.handleRefetchClinicAddress(value);
    }
    if (fieldName === 'clientAddress') {
      return store.handleRefetchClientAddress(value);
    }

    if (fieldName === 'waitingList') {
      return value
        ? store.handleRefetchWaitingList({
            waitingListKeyword: value,
          })
        : store.handleRefetchWaitingList({
            waitingListKeyword: '',
            waitingListNumItemPerPage: 30,
          });
    }
  };

  handleScroll = fieldName => event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      if (fieldName === 'specialist') {
        const number = store.numItemPerPageAllWithoutNCI;
        return store.handleRefetchSpecialist({
          numItemPerPageAllWithoutNCI: number + 30,
        });
      }
      if (fieldName === 'clinic') {
        const number = store.numItemPerPageClinicList;
        return store.handleRefetchClinic({
          numItemPerPageClinicList: number + 30,
        });
      }
    }
  };
  sendClickSendInterpretationEmail = id => {
    customFetch('/MedicalService/ResendInterpretationEmail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(
      action(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend interpreter request',
          });
          store.handleChangeValue('SendInterpretationDateTime', moment(response.result.SendInterpretationDateTime));
          store.handleChangeValue('SendInterpretationBy', response.result.SendInterpretationBy);
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Resend interpreter request',
          });
        }
      }),
    );
  };
  reSendEmailInterpretationToOperator = id => {
    customFetch('/MedicalService/ReSendInterpreterRqtoOperator', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(
      action(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend interpreter request',
          });
          store.handleChangeValue(
            'SendInterpreterRqtoOperatorDateTime',
            moment(response.result.SendInterpreterRqtoOperatorDateTime),
          );
          store.handleChangeValue('SendInterpreterRqtoOperatorBy', response.result.SendInterpreterRqtoOperatorBy);
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Resend interpreter request',
          });
        }
      }),
    );
  };
  handleClickSendInterpretationEmail = () => {
    const itemModel = toJS(store.assessmentInfo);
    let msg = 'Do you want to send interpreter request to Interpreter?';
    if (itemModel.SendInterpretationDateTime) {
      msg = 'Do you want to resend interpreter request to Interpreter?';
    }
    if (
      !itemModel.CMsInterpreterProviderId ||
      !toJS(store.interpreterProviderCMs).find(i => i.Id === itemModel.CMsInterpreterProviderId).Email
    ) {
      store.showNotificationError('Please select an interpreter with email');
    } else {
      return store.toggleModal(true, {
        modalType: 'confirm',
        message: msg,
        onOk: () => this.sendClickSendInterpretationEmail(itemModel.Id),
      })();
    }
  };

  handleClickReSendInterpreterRqtoProvider = () => {
    const itemModel = toJS(store.assessmentInfo);
    let msg = 'Do you want to resend interpreter request to provider?';
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => {
        var id = itemModel.Id;
        customFetch('/MedicalService/ReSendInterpreterRqtoProvider', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id }),
        }).then(
          action(response => {
            if (response.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend interpreter booking request to provider',
              });
              store.handleChangeValue(
                'SentDateInterpreterRqtoProvider',
                moment(response.result.SentDateInterpreterRqtoProvider),
              );
              store.handleChangeValue('SentByInterpreterRqtoProvider', response.result.SentByInterpreterRqtoProvider);
            } else {
              notification.destroy();
              notification.error({
                message: 'Error',
                description: 'Resend interpreter booking request to provider',
              });
            }
          }),
        );
      },
    })();
  };

  handleClickReSendInterpreterRqtoOperator = () => {
    const itemModel = toJS(store.assessmentInfo);
    let msg = 'Do you want to send interpreter request to Medicolegal Provider?';
    if (itemModel.SendInterpretationDateTime) {
      msg = 'Do you want to resend interpreter request to Medicolegal Provider?';
    }
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => this.reSendEmailInterpretationToOperator(itemModel.Id),
    })();
  };

  handleChangeTextInput = fieldName =>
    action(event => {
      const value = event.target.value;
      const itemModel = toJS(store.assessmentInfo);
      if (
        fieldName === 'EassessUrl' ||
        fieldName === 'InterpreterEassessUrl' ||
        fieldName === 'DoctorEassessUrl' ||
        fieldName === 'BackupEassessUrl'
      ) {
        store.validateInput(value, 'url', fieldName);
      }
      if (
        fieldName === 'EassessClaimantEmail' ||
        fieldName === 'EassessDoctorEmail' ||
        fieldName === 'InterpreterEmail'
      ) {
        store.validateInput(value, 'email', fieldName);
      }
      if (fieldName === 'EassessClaimantPhone' || fieldName === 'EassessDoctorPhone') {
        const value_ = value.replace(/\D/g, '').replace(/[^\d]/g, '');
        if (value_.length <= 10) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value_,
          });
        } else return;
      }
      if (fieldName === 'InterpreterMobile' || fieldName === 'InterpreterPhone') {
        const value_ = value.replace(/\D/g, '').replace(/[^\d]/g, '');
        if (value_.length <= 12) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value_,
          });
        } else return;
      }

      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });
  @action handleChangeCheckBoxGroup = checkedValues => {
    const itemModel = toJS(store.assessmentInfo);
    store.assessmentInfo = {
      ...itemModel,
      SelectedPaperworkToDoctorBies: checkedValues,
    };
    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      notification.info({
        message: 'Notification',
        description:
          "Paperwork can't be sent to specialist before appointment is confirmed. Please check appointment status.",
      });
      return;
    }
    if (checkedValues.length !== 0) {
      let itemModel = toJS(store.assessmentInfo);

      store.handleChangeValue('PaperWorkReceived', true);

      if (!itemModel.OtherMethodSendingDate) {
        store.handleChangeValue('OtherMethodSendingDate', moment());
      }
      this.onPaperworkReceivedChange();
    }
  };
  @action handleChangeEssessClaimantLocation = (_, event) => {
    const itemModel = toJS(store.assessmentInfo);
    if (event) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      store.assessmentInfo = {
        ...itemModel,
        EassessClaimantAddress: data_?.FullAddress || '',
      };
    } else {
      store.assessmentInfo = {
        ...itemModel,
        EassessClaimantAddress: '',
      };
    }
  };
  @action handleChangeEassessDoctorLocationType = (_, event) => {
    const itemModel = toJS(store.assessmentInfo);
    if (event) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      store.assessmentInfo = {
        ...itemModel,
        EassessDoctorAddress: data_?.FullAddress || '',
      };
      this.setState({ specialistClinic: data_?.ID });
    } else {
      this.setState({ specialistClinic: null });
      store.assessmentInfo = {
        ...itemModel,
        EassessDoctorAddress: '',
      };
    }
  };
  resendVideoToDoctor = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.DoctorEassessUrl && !itemModel.EassessDoctorEmail) {
      store.showNotificationError('Please input Doctor Email and Scope Video Assessment Link.');
      return;
    }
    return store.toggleModal(true, {
      message: 'Do you want to resend Scope Video Link to specialist?',
      modalType: 'confirm',
      onOk: () => {
        customFetch('/MedicalService/ResendEassessUrlToDoctorLocation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeValue('EmailToCMsViewReportRecent', moment(response.ResendDatetime));
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend Scope Video Link to specialist',
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Scope Video Link to specialist',
            });
          }
        });
      },
    })();
  };
  resendVideoToClaimant = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.EassessUrl && !itemModel.EassessClaimantEmail) {
      store.showNotificationError('Please input Claimant Email and Scope Video Assessment Link.');
      return;
    }
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend Scope Video Link to claimant?',
      onOk: () => {
        customFetch('/MedicalService/ResendEassessUrlToClaimantLocation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeValue('EmailToCMsViewReportRecent', moment(response.ResendDatetime));
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend Scope Video Link to claimant',
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Scope Video Link to claimant',
            });
          }
        });
      },
    })();
  };

  resendBackupVideoLink = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.BackupEassessUrl || !itemModel.EassessClaimantEmail || !itemModel.EassessDoctorEmail) {
      store.showNotificationError('Please check emails of claimant and specialist to send video link.');
      return;
    }
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to send alternative video link to specialist and claimant?',
      onOk: () => {
        customFetch('/MedicalService/ResendBackupEassessUrl', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            url: itemModel.BackupEassessUrl,
            backupRoomId: itemModel.BackupIdRoom,
            passcode: itemModel.BackupPasscode,
            id: itemModel.Id,
          }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeValue('SendEassessUrlToClaimantLocation', true);
            store.handleChangeValue('SendDateEassessToClaimant', moment(response.ResendDatetime));
            store.handleChangeValue('SendDateEassessToDoctor', moment(response.ResendDatetime));
            store.handleChangeValue('SentDateBackupEassessUrl', moment(response.SentDateBackupEassessUrl));
            store.handleChangeValue('SendEassessUrlToDoctorLocation', true);
            store.handleChangeValue('SendEassessToClaimantByUser', response.resendBy);
            store.handleChangeValue('SendEassessToDoctorByUser', response.resendBy);
            store.handleChangeValue('SentByBackupEassessUrl', response.resendBy);

            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend Alternative Video Link',
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Scope Video Link to claimant',
            });
          }
        });
      },
    })();
  };
  renderEassessClaimantLocation = () => {
    const itemModel = toJS(store.assessmentInfo);
    const clinics = toJS(store.listClinicForAddress);
    const companys = toJS(store.listClientForAddress);
    if (itemModel.EassessClaimantLocationType === 'AClinic') {
      return (
        <Select
          size="large"
          showSearch
          optionFilterProp="children"
          onChange={(value, event) => this.handleChangeEssessClaimantLocation(value, event)}
          onSearch={this.handleSearch('clinicAddress')}
          disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
          allowClear
        >
          {(clinics?.itemList || []).map(i => (
            <Select.Option key={i.ID} value={i.ID} data={JSON.stringify(i)}>
              {i.Name} - {i.FullAddress}
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Select
          size="large"
          showSearch
          optionFilterProp="children"
          onChange={(value, event) => this.handleChangeEssessClaimantLocation(value, event)}
          onSearch={this.handleSearch('clientAddress')}
          disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
        >
          {companys?.itemList &&
            companys.itemList.length &&
            companys.itemList.map(i => (
              <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                {i.Name}
              </Select.Option>
            ))}
        </Select>
      );
    }
  };

  reSendEassessUrlToInterpreter = id => {
    const itemModel = toJS(store.assessmentInfo);
    customFetch('/MedicalService/ResendEassessUrlToInterpreter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    })
      .then(
        action(response => {
          if (response.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend Scope Video Link to interpreter',
            });
            store.assessmentInfo = {
              ...itemModel,
              SendDateEassessUrlToInterpreter: moment(response.SendDateEassessUrlToInterpreter),
            };
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Scope Video Link to interpreter',
            });
          }
        }),
      )
      .catch(() => {});
  };

  handleReSendEassessUrlToInterpreter = () => {
    const itemModel = toJS(store.assessmentInfo);
    const InterpreterEmail = store.interpreterProviderCMs.find(
      i => i.Id === store.assessmentInfo.CMsInterpreterProviderId,
    );

    if (!itemModel.InterpreterEassessUrl && (!InterpreterEmail || !InterpreterEmail?.Email)) {
      return store.showNotificationError('Please input Interpreter Email and Scope Video Link.');
    } else if (!itemModel.InterpreterEassessUrl) {
      store.showNotificationError('Video Link to Interpreter must be created first.'); //
    } else if (!InterpreterEmail || !InterpreterEmail?.Email) {
      store.showNotificationError('Interpreter Email must be filled in.');
    } else {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to resend Video Link to interpreter?',
        onOk: () => this.reSendEassessUrlToInterpreter(itemModel.Id),
      })();
    }
  };

  generateVideLink = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.EassessClaimantEmail) {
      store.showNotificationError('Claim Video - Please enter Contact Email.');
      return;
    }

    if (!itemModel.EassessDoctorEmail) {
      store.showNotificationError('Specialist Video - Please enter Specialist Email.');
      return;
    }

    if (itemModel.EassessUrl || itemModel.DoctorEassessUrl) {
      store.showNotificationError('Video session has been created. Please check again');
      return;
    }
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Are you sure to create video assessment links?',
      onOk: () => {
        customFetch('/MedicalService/CreateVideoSession', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        })
          .then(response => {
            if (response.status === 'success') {
              store.handleChangeValue('DoctorEassessUrl', response.DoctorEassessUrl);
              store.handleChangeValue('EassessUrl', response.EassessUrl);
              notification.destroy();
              notification.success({
                message: 'Video Scope Connect',
                description: 'Create Session Video Scope Connect successfully!',
              });
            } else {
              notification.destroy();
              notification.error({
                message: 'Video Scope Connect',
                description: 'Create Session Video Scope Connect error!',
              });
            }
          })
          .catch(() => {});
      },
    })();
  };

  handleSendReminder = () => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to send a paperwork reminder to the CM?',
      onOk: () => {
        store.toggleModal(false, {})();
        sendCMReminder(itemModel.Id)
          .then(res => {
            if (res.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Reminder sent.',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Fail to send reminder',
            });
          });
      },
    })();
  };
  handleResendNotify = (sentype = 0) => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend confirmed appointment?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendAppointmentConfirm', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, sentype }),
        })
          .then(res => {
            if (res.status === 'success') {
              store.handleChangeValue('EmailToPaperworkReceivedRecent', moment());
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Appointment Confirm',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Appointment Confirm',
            });
          });
      },
    })();
  };

  handleResendNotifyInquiry = (sendtype = 1) => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend appointment inquiry?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendAppointmentInquiry', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id, sendtype }),
        })
          .then(res => {
            if (res.status === 'success') {
              if (sendtype === 2) {
                store.handleChangeValue('SentDateNotifyDoctorAppointmentInquiry', moment());
                store.handleChangeValue('SentByNotifyDoctorAppointmentInquiry', res.sentBy);
              } else {
                store.handleChangeValue('SentDateNotifyCMAppointmentInquiry', moment());
                store.handleChangeValue('SentByNotifyCMAppointmentInquiry', res.sentBy);
              }

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Appointment Inquiry',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Appointment Inquiry',
            });
          });
      },
    })();
  };

  handleResendNotifyInquiryToStaffPIC = () => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend appointment inquiry?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendNotifyInquiryToStaffPIC', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        })
          .then(res => {
            if (res.status === 'success') {
              store.handleChangeValue('SentDateNotifyStaffPICAppointmentInquiry', moment());
              store.handleChangeValue('SentByNotifyStaffPICAppointmentInquiry', res.sentBy);

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Appointment Inquiry',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Appointment Inquiry',
            });
          });
      },
    })();
  };

  handleResendNotifyAptConfrimToStaffPIC = () => {
    const itemModel = toJS(store.assessmentInfo);
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend appointment confirmed?',
      onOk: () => {
        store.toggleModal(false, {})();
        customFetch('/MedicalService/ResendNotifyConfirmedToStaffPIC', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        })
          .then(res => {
            if (res.status === 'success') {
              store.handleChangeValue('SentDateNotifyStaffPICAppointmentConfirmed', moment());
              store.handleChangeValue('SentByNotifyStaffPICAppointmentConfirmed', res.sentBy);

              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Appointment Confirmed',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Resend Appointment Confirmed',
            });
          });
      },
    })();
  };

  formatNumber = value => {
    return !!value ? '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ 0';
  };

  @action handleChangeDateRangePicker = (value, fieldNameFrom, fieldNameTo) => {
    const [from, to] = value;
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldNameFrom]: from,
      [fieldNameTo]: to,
    });
  };

  handleFieldRecentChange = async (record, fieldName) => {
    try {
      let title = '';
      if (fieldName === 'SessionAcceptsType') title = 'Session Type Change History';
      if (fieldName === 'AssessmentMethod') title = 'Video Assessment Change History';
      const response = await viewFieldChangeHistory(record.Id, fieldName, 1, 1000);
      const dataFieldChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, { modalType: 'fieldChange', title: title, dataFieldChange })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  renderSpecialistNCI = (isNCI = false, from, to) => {
    if (isNCI) {
      let fromDate = null;
      if (!!from) {
        if (typeof from === 'string' && from.search('/Date') !== -1) {
          fromDate = this.convertDate(from);
        } else {
          fromDate = moment(from);
        }
      }
      let toDate = null;
      if (!!to) {
        if (typeof to === 'string' && to.search('/Date') !== -1) {
          toDate = this.convertDate(to);
        } else {
          toDate = moment(to);
        }
      }
      if (moment() >= fromDate && moment() <= toDate) {
        return '(NCI) ';
      } else if (moment() >= fromDate && !to) {
        return '(NCI) ';
      }
      return '';
    }
    return '';
  };

  render() {
    const itemModel = toJS(store.assessmentInfo);
    const pic_2 = toJS(store.PIC_2);
    const staffs = toJS(store.listStaff);
    const listSpecialist = toJS(store.listSpecialist);
    const clinics = toJS(store.listClinic);
    const homeClinics = toJS(store.listHomeClinic);
    const languages = toJS(store.languageList);
    const listWaitingList = toJS(store.listWaitingList);
    const {
      LockCMDoctor,
      lockTypist,
      oldPaperworkReceived,
      lockDoctorDirect,
      lockDoctor,
    } = store.handleLockReportCompleteCM(itemModel);

    const clinicInterpretationServiceList = toJS(store.clinicInterpretationServiceList);
    //-- // Field day 2 cot
    return (
      <Form name="appointment-details-and-case-documents" className="grid-style">
        {!!itemModel.Id && api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 2
                <button
                  className="field-actions"
                  onClick={() =>
                    this.handleRecentChange(
                      itemModel,
                      'Status_AppointmentConfirmed',
                      DEFAULT_COLUMNS_RECENT_CHANGE,
                      DEFAULT_FIELD_NAME_RECENT_CHANGE,
                    )
                  }
                >
                  Recent changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              size="large"
              value={pic_2?.picId || undefined}
              showSearch
              optionFilterProp="children"
              onChange={(value, event) => this.handleChangePIC(value, event, 'PIC_2')}
            >
              {staffs.itemList &&
                staffs.itemList.length &&
                staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            required={itemModel.IsAppointmentConfirmed}
            label="Appointment Confirmation Date"
            validateStatus={itemModel.IsAppointmentConfirmed && !itemModel.AppointmentConfirmedDate ? 'error' : null}
            help={
              itemModel.IsAppointmentConfirmed && !itemModel.AppointmentConfirmedDate ? 'This field is required' : ''
            }
          >
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              value={renderDate(itemModel.AppointmentConfirmedDate)}
              disabled={itemModel.isLocked || !itemModel.IsAppointmentConfirmed}
              onChange={value => this.handleChangeDatePicker('AppointmentConfirmedDate', value)}
            />
          </Form.Item>
        )}
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <Header
                title="Specialist - Time - Location"
                isChecked={!!itemModel.IsAppointmentConfirmed}
                showGreenTick={!!itemModel.IsAppointmentConfirmed}
                text={`${store.specialist?.Title ?? ''} ${store.specialist?.FullName ?? ''} ${formatTimeHeader(
                  itemModel.DateFrom,
                  itemModel.DateTo,
                  itemModel.AppointmentTimeZone,
                )}`}
                date={!!itemModel.AssessmentMethod ? 'Video Assessment' : store.clinic ? `${store.clinic.Name}` : ''}
                shouldFormatDate={false}
                isRequired={false}
              />
            }
          >
            <div className="fluid-field field-action action-group" style={styles.flexCenter}>
              {(!itemModel.Id || !itemModel.SessionId) &&
                !itemModel.IsAppointmentConfirmed &&
                (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                  <button
                    className="btn-action"
                    onClick={() => this.handleOpenCalendarModuleClick('addAppt')}
                    disabled={
                      itemModel.Attendance === CONSTANTS.Attendance_Attended ||
                      itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.Attendance === CONSTANTS.Attendance_CancelledSpecialistTBP
                    }
                  >
                    {!!itemModel.Appointments.length ? <b>Add Another Appointment</b> : <b>Add Appointment</b>}
                  </button>
                )}

              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <React.Fragment>
                    {(!!itemModel.SessionId ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.IsAppointmentConfirmed) &&
                      !!itemModel.Id && (
                        <button
                          className="btn-action"
                          onClick={() => this.handleOpenCalendarModuleClick('updateAppt')}
                          disabled={
                            itemModel.Attendance === CONSTANTS.Attendance_Attended ||
                            itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                            itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                            itemModel.Attendance === CONSTANTS.Attendance_CancelledSpecialistTBP ||
                            !itemModel.IsAllowReschedule
                          }
                        >
                          <b>Reschedule Appointment</b>
                        </button>
                      )}
                    {/* <button className="btn-action" onClick={() => this.handleOpenCalendarModuleClick('openCal')}>
                      <b>Open Appointment Date Calendar</b>
                    </button> */}
                    {!!itemModel.Id && (
                      <button className="btn-action" onClick={this.handleOpenCaseProgress}>
                        <b>View Appointment History</b>
                      </button>
                    )}
                  </React.Fragment>
                </>
              )}
            </div>

            <div className="checkbox-field fluid-field" style={styles.flexCenter}>
              <Checkbox
                disabled={
                  itemModel.isLocked ||
                  !api.isAdminOrMagStaffOrAccounting() ||
                  !!itemModel.Appointments.length ||
                  itemModel.IsCancelled
                }
                className="checkbox-session"
                checked={itemModel.IsAppointmentConfirmed}
                onChange={this.handleAppointmentConfirmedChange}
              >
                Appointment Confirmed
              </Checkbox>
            </div>
            {!!itemModel.Appointments.length && (
              <>
                <Divider className="fluid-field" />
                {itemModel.Appointments.map((i, idx) => (
                  <React.Fragment key={idx}>
                    <Form.Item label="Specialist">
                      <Input size="large" disabled value={i.Doctor.FullName} />
                    </Form.Item>
                    <Form.Item label="Clinic">
                      <Input size="large" disabled value={i.Clinic.Name} />
                    </Form.Item>
                    <Form.Item label="Appointment Date">
                      <Input size="large" value={i.AppointmentDate} disabled />
                    </Form.Item>
                    <Form.Item label="Clinic Type">
                      <Radio.Group className="clinic-type-radio-group" value={i.ClinicType} disabled>
                        <Radio value="mag" className="clinic-type-radio">
                          MLP's Owned Clinic
                        </Radio>
                        <Radio value="non-mag" className="clinic-type-radio">
                          Specialist's Owned Clinic
                        </Radio>
                        <Radio value="obtain" className="clinic-type-radio">
                          <span style={{ position: 'absolute' }}>Obtained Appt</span>
                          <span style={{ visibility: 'hidden' }}>MLP's Owned Clinic</span>
                        </Radio>
                        <Radio value="home" className="clinic-type-radio">
                          Home Visit
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="This session accepts">
                      <Radio.Group value={i.SessionAcceptsType} disabled>
                        <Radio value={0}>
                          In-person Assessment Only{' '}
                          <Popup
                            trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                            content="This session accepts in-person appointment only. Video appointment is not accepted."
                            position="top center"
                            wide="very"
                          />
                        </Radio>
                        <Radio value={1}>
                          Video Assessment Only{' '}
                          <Popup
                            trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                            content="This session accepts video appointment only. Make sure to update video session details in “Require Video Assessment” section."
                            position="top center"
                            wide="very"
                          />
                        </Radio>
                        <Radio value={2}>
                          Either In-person or Video Assessment{' '}
                          <Popup
                            trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                            content="This session accepts either in-person or video appointment. If video appointment is requested, make sure to update video session details in “Require Video Assessment” section."
                            position="top center"
                            wide="very"
                          />
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="blue"
                        style={styles.marginTop22}
                        onClick={() => store.handleConfirmAppointment(i)}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="negative"
                        style={styles.marginTop22}
                        onClick={() => store.handleRemoveAppointment(i)}
                      >
                        Remove
                      </Button>
                    </Form.Item>
                    <Divider className="fluid-field" />
                  </React.Fragment>
                ))}
                {itemModel.Id > 0 &&
                  !!itemModel.Appointments &&
                  !!itemModel.Appointments.length &&
                  !itemModel.IsAppointmentConfirmed && (
                    <Button
                      className="blue"
                      style={{ marginTop: '20px', marginBottom: '20px', width: '330px' }}
                      onClick={() => store.handleResendMultiAppointmentRequest()}
                    >
                      Resend Multi-Appointment Request
                    </Button>
                  )}
              </>
            )}
            {!itemModel.Appointments.length && (
              <>
                <Form.Item
                  label={
                    <React.Fragment>
                      Specialist
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <>
                          <button
                            className="btn-action"
                            onClick={() => {
                              store.toggleModal(true, {
                                modalType: 'confidential',
                              })();
                            }}
                            style={styles.marginLeft5}
                            disabled={!store.specialist || !store.specialist?.Id}
                          >
                            View Confidential Insights
                          </button>
                          <button
                            className="btn-action"
                            onClick={() =>
                              this.handleOpenAddEdit(
                                'edit',
                                'DoctorName',
                                store.specialist?.FullName || '',
                                `/view/add-edit-doctor-2?id=${store.specialist?.Id}&action=edit&step=0`,
                                store.specialist?.Id,
                                'specialist',
                              )
                            }
                            style={styles.marginLeft5}
                            disabled={!store.specialist || !store.specialist?.Id}
                          >
                            View Details
                          </button>
                        </>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    value={store.specialist?.Id}
                    onChange={(value, event) => {
                      this.handleChangeObj(value, event, 'specialist');
                    }}
                    // disabled={itemModel.isLocked || api.isCaseManager() || itemModel.SessionId > 0}
                    disabled={api.isTypist() || !!itemModel.SessionId || api.isDoctor() || itemModel.IsCancelled}
                    showSearch
                    optionFilterProp="children"
                    onSearch={this.handleSearch('specialist')}
                    onPopupScroll={this.handleScroll('specialist')}
                  >
                    {this.itemToArray(store.specialist, listSpecialist.itemList, 'Id').map(i => (
                      <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                        {i.Id} - {i.Title} {i.FullName}{' '}
                        {this.renderSpecialistNCI(
                          i.IsNotCurrentlyInterested,
                          i.NotCurrentlyInterestedDate,
                          i.NotCurrentlyInterestedEndDate,
                        )}
                        {api.isAdminOrMagStaffOrAccounting() ? ' -' + i.BusinessEmail : ''}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Provider No">
                  <Select
                    size="large"
                    value={
                      !![
                        store.specialist?.ProviderNumber,
                        store.specialist?.ProviderNumber2,
                        store.specialist?.ProviderNumber3,
                        store.specialist?.ProviderNumber4,
                      ].filter(i => i).length
                        ? itemModel.SelectedDoctorProviderNoIdx
                        : null
                    }
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={this.handleChangeFieldSelect('SelectedDoctorProviderNoIdx')}
                    disabled={
                      itemModel.isLocked ||
                      itemModel.IsCancelled ||
                      api.isCaseManager() ||
                      ![
                        store.specialist?.ProviderNumber,
                        store.specialist?.ProviderNumber2,
                        store.specialist?.ProviderNumber3,
                        store.specialist?.ProviderNumber4,
                      ].filter(i => i).length
                    }
                  >
                    {[
                      store.specialist?.ProviderNumber,
                      store.specialist?.ProviderNumber2,
                      store.specialist?.ProviderNumber3,
                      store.specialist?.ProviderNumber4,
                    ].map((i, idx) =>
                      i ? (
                        <Select.Option key={idx} value={idx + 1}>
                          {i} {store.specialist?.[`ProviderNumber${idx === 0 ? '' : idx + 1}Note`]}
                        </Select.Option>
                      ) : null,
                    )}
                  </Select>
                </Form.Item>
                {store.specialist?.Id && (
                  <Form.Item
                    label={
                      <>
                        Booking and Cancellation Terms
                        <Popup
                          trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                          content="Staff, CMs and Specialist can view in bookings and calendar."
                          position="top center"
                          wide="very"
                        />
                      </>
                    }
                    className="fluid-field"
                  >
                    <Input.TextArea
                      row={3}
                      size="large"
                      disabled
                      value={store.specialist?.BookingsAndCancellationTerms}
                    />
                  </Form.Item>
                )}
                {store.specialist?.Id &&
                  store.assessmentInfo.DoctorRate &&
                  store.assessmentInfo.DoctorRate.Id > 0 &&
                  api.isAdminOrMagStaffOrAccounting() && (
                    <React.Fragment>
                      <Form.Item label="Applicable Specialist Rates (excl GST)">
                        <div className="ant-form-item-control">
                          <span className="ant-form-item-children">
                            <span className="ant-input ant-input-lg ant-input-disabled">
                              From {this.formatNumber(store.assessmentInfo.DoctorRate.RateFrom || 0)} to{' '}
                              {this.formatNumber(store.assessmentInfo.DoctorRate.RateTo || 0)}
                            </span>
                          </span>
                        </div>
                      </Form.Item>
                      <Form.Item label="Accept Gazetted Rates">
                        <div className="ant-form-item-control">
                          <span className="ant-form-item-children">
                            <span className="ant-input ant-input-lg ant-input-disabled">
                              {store.assessmentInfo.DoctorRate.ApplyGazettedRate ? 'Yes' : 'No'}
                            </span>
                          </span>
                        </div>
                      </Form.Item>
                      {/* <Form.Item label="Accept Gazetted Rates" className="fluid-field">
                      <div className="ant-form-item-control">
                        <span className="ant-form-item-children">
                          <span className="ant-input ant-input-lg ant-input-disabled">{store.assessmentInfo.DoctorRate.ApplyGazettedRate ? 'Yes' : 'No'}</span>
                        </span>
                      </div>
                    </Form.Item> */}
                    </React.Fragment>
                  )}
                {!(
                  itemModel.ClinicOption === 'home' ||
                  (api.isCaseManager() && !itemModel.Id && itemModel.InquiryReceived && !store.clinic?.Id) ||
                  !!itemModel.Appointments.length
                ) && (
                  <Form.Item
                    required={
                      (itemModel.ClinicOption !== 'home' &&
                        !itemModel.AssessmentMethod &&
                        itemModel.IsAppointmentConfirmed) ||
                      (itemModel.ClinicOption !== 'home' &&
                        itemModel.SessionAcceptsType === 0 &&
                        store.specialist &&
                        itemModel.DateFrom &&
                        !store.clinic?.ID)
                    }
                    validateStatus={
                      (itemModel.ClinicOption !== 'home' &&
                        !itemModel.AssessmentMethod &&
                        itemModel.IsAppointmentConfirmed &&
                        !store.clinic?.ID) ||
                      (itemModel.ClinicOption !== 'home' &&
                        itemModel.SessionAcceptsType === 0 &&
                        store.specialist &&
                        itemModel.DateFrom &&
                        !store.clinic?.ID)
                        ? 'error'
                        : null
                    }
                    help={
                      (itemModel.ClinicOption !== 'home' &&
                        !itemModel.AssessmentMethod &&
                        itemModel.IsAppointmentConfirmed &&
                        !store.clinic?.ID) ||
                      (itemModel.ClinicOption !== 'home' &&
                        itemModel.SessionAcceptsType === 0 &&
                        store.specialist &&
                        itemModel.DateFrom &&
                        !store.clinic?.ID)
                        ? 'This field is required'
                        : ''
                    }
                    label={
                      <React.Fragment>
                        Clinic
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <>
                            {itemModel.SessionId ? (
                              <button
                                className="btn-action"
                                onClick={() =>
                                  this.handleOpenAddEdit(
                                    'edit',
                                    'ClinicName',
                                    store.clinic?.Name || '',
                                    `/view/add-edit-clinic-2?id=${store.clinic?.ID || 0}&action=edit&step=0`,
                                    store.clinic?.ID || 0,
                                    'clinic',
                                  )
                                }
                                style={styles.marginLeft5}
                              >
                                Edit
                              </button>
                            ) : (
                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      onClick={() =>
                                        this.handleOpenAddEdit(
                                          'edit',
                                          'ClinicName',
                                          'New Clinic',
                                          `/view/add-edit-clinic-2`,
                                          0,
                                          'clinic',
                                        )
                                      }
                                    >
                                      Add Clinic
                                    </Menu.Item>
                                    <Menu.Item
                                      onClick={() =>
                                        this.handleOpenAddEdit(
                                          'edit',
                                          'ClinicName',
                                          store.clinic?.Name || '',
                                          `/view/add-edit-clinic-2?id=${store.clinic?.ID || 0}&action=edit&step=0`,
                                          store.clinic?.ID || 0,
                                          'clinic',
                                        )
                                      }
                                    >
                                      Edit Clinic
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <button className="field-actions">
                                  Add/Edit <Icon type="down" />
                                </button>
                              </Dropdown>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    }
                  >
                    <Select
                      size="large"
                      value={store.clinic?.ID || undefined}
                      showSearch
                      disabled={
                        itemModel.isLocked || api.isCaseManager() || !!itemModel.SessionId || itemModel.IsCancelled
                      }
                      optionFilterProp="children"
                      onChange={(value, event) => this.handleChangeObj(value, event, 'clinic')}
                      onSearch={this.handleSearch('clinic')}
                      onPopupScroll={this.handleScroll('clinic')}
                    >
                      {this.itemToArray(itemModel.Clinic, [...(clinics?.itemList || []), store.clinic], 'ID')
                        .filter(i => i?.ID)
                        .map(i => (
                          <Select.Option key={i.ID} value={i.ID} data={JSON.stringify(i)}>
                            {i?.ID || ''} - {i?.Name || ''} - {i?.City?.CityName || ''}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            )}
            {itemModel.ClinicOption === 'home' && (
              <Form.Item
                label="Clinic"
                required={itemModel.IsAppointmentConfirmed && itemModel.ClinicOption !== 'home'}
              >
                <Select
                  size="large"
                  value={store.clinic?.ID || undefined}
                  showSearch
                  disabled={itemModel.isLocked || api.isCaseManager() || !!itemModel.SessionId || itemModel.IsCancelled}
                  optionFilterProp="children"
                  onChange={(value, event) => this.handleChangeObj(value, event, 'clinic')}
                  onSearch={this.handleSearch('homeClinic')}
                  onPopupScroll={this.handleScroll('homeClinic')}
                >
                  {this.itemToArray(itemModel.Clinic, [...(homeClinics?.itemList || []), store.clinic], 'ID')
                    .filter(i => i?.ID)
                    .map(i => (
                      <Select.Option key={i.ID} value={i.ID} data={JSON.stringify(i)}>
                        {i?.ID || ''} - {i?.Name || ''}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {!itemModel.Appointments.length && (
              <>
                <React.Fragment>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item label="Clinic Type">
                      <Radio.Group
                        style={{ display: 'flex' }}
                        value={itemModel.ClinicOption}
                        onChange={this.handleChangeFieldRadioBox('ClinicOption')}
                        disabled={
                          itemModel.isLocked || !!itemModel.SessionId || api.isCaseManager() || itemModel.IsCancelled
                        }
                      >
                        <Radio value="mag" className="appointment-clinic-type">
                          <span>MLP's Owned Clinic</span>
                        </Radio>
                        <Radio value="doctor" className="appointment-clinic-type">
                          Specialist's Owned Clinic
                        </Radio>
                      </Radio.Group>
                      <Radio.Group
                        style={{ display: 'flex' }}
                        value={itemModel.ClinicOption}
                        onChange={this.handleChangeFieldRadioBox('ClinicOption')}
                        disabled={
                          itemModel.isLocked || !!itemModel.SessionId || api.isCaseManager() || itemModel.IsCancelled
                        }
                      >
                        <Radio value="obtain" className="appointment-clinic-type">
                          <span style={{ position: 'absolute' }}>Obtained Appt</span>
                          <span style={{ visibility: 'hidden' }}>MLP's Owned Clinic</span>
                        </Radio>
                        <Radio value="home" className="appointment-clinic-type">
                          Home Visit
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {itemModel.ClinicOption === 'home' && (
                    <>
                      <Form.Item label="Home Address">
                        <Input
                          size="large"
                          value={itemModel.HomeVisitAddress}
                          onChange={this.handleChangeTextInput('HomeVisitAddress')}
                          disabled={
                            itemModel.isLocked || !!itemModel.SessionId || api.isCaseManager() || itemModel.IsCancelled
                          }
                        />
                      </Form.Item>
                      <div />
                    </>
                  )}

                  {!(api.isCaseManager() && !itemModel.Id && !itemModel.DateFrom) && (
                    <>
                      <Form.Item
                        label="Appointment Date/Time"
                        required={itemModel.IsAppointmentConfirmed}
                        validateStatus={itemModel.IsAppointmentConfirmed && !itemModel.DateFrom ? 'error' : null}
                        help={itemModel.IsAppointmentConfirmed && !itemModel.DateFrom ? 'This field is required' : ''}
                        //className="fluid-field"
                      >
                        <DatePicker
                          size="large"
                          format="DD/MM/YYYY"
                          value={renderDate(itemModel.DateFrom, true, false)}
                          disabled={itemModel.isLocked || (!!itemModel.SessionId && !api.isAdminOrMagStaffOrAccounting()) || itemModel.IsCancelled}
                          onChange={this.handleChangeRangePicker('DateFrom')}
                          style={{ width: '175px' }}
                        />
                        <TimePicker
                          format="HH:mm"
                          showTime
                          placeholder="Start Time"
                          size="large"
                          disabled={itemModel.isLocked || (!!itemModel.SessionId && !api.isAdminOrMagStaffOrAccounting()) || itemModel.IsCancelled}
                          allowClear={false}
                          style={{ marginLeft: '10px', width: '125px' }}
                          value={store.getMomentDateTime(itemModel.DateFrom, itemModel.AppointmentTimeZone)}
                          onChange={this.handleChangeRangePicker('DateFromHour')}
                        />
                        <TimePicker
                          value={store.getMomentDateTime(itemModel.DateTo, itemModel.AppointmentTimeZone)}
                          disabled={itemModel.isLocked || (!!itemModel.SessionId && !api.isAdminOrMagStaffOrAccounting()) || itemModel.IsCancelled}
                          onChange={this.handleChangeRangePicker('DateToHour')}
                          size="large"
                          placeholder="End Time"
                          showTime
                          format="HH:mm"
                          style={{ marginLeft: '10px', width: '125px' }}
                          allowClear={false}
                        />
                        <Select
                          showSearch
                          size="large"
                          disabled={itemModel.isLocked || (!!itemModel.SessionId && !api.isAdminOrMagStaffOrAccounting()) || itemModel.IsCancelled}
                          optionFilterProp="children"
                          value={itemModel.AppointmentTimeZone}
                          style={{ width: '200px', marginLeft: '10px' }}
                          onChange={event => {
                            store.handleChangeValue('AppointmentTimeZone', event);
                            // store.setFieldsValue({ timeZone: event });
                          }}
                        >
                          {timezoneList.map(i => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      style={{ marginTop: '20px' }}
                      label={
                        <>
                          Claimant Status
                          <button className="field-actions" onClick={() => this.handleViewClaimantHistory()}>
                            View History
                          </button>
                        </>
                      }
                    >
                      <Radio.Group
                        onChange={this.handleChangeFieldRadioBox('ClaimantStatus')}
                        value={itemModel.ClaimantStatus}
                        disabled
                        style={{ display: 'flex' }}
                      >
                        <Radio value={false} className="appointment-clinic-type">
                          <span style={{ position: 'absolute' }}>Previous Claimant</span>
                          <span style={{ visibility: 'hidden' }}>Medicolegal Provider's Owned Clinic</span>
                        </Radio>
                        <Radio value={true} className="appointment-clinic-type">
                          New Claimant
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && itemModel.SessionId && (
                    <Form.Item label="Report Types excluded for this session" className="fluid-field">
                      <div className="ant-form-item-control">
                        <span className="ant-form-item-children">
                          <textarea className="ant-input ant-input-disabled">
                            {store.specialistAcceptedTypesText}
                          </textarea>
                        </span>
                      </div>
                    </Form.Item>
                  )}
                  {!itemModel.DateFrom &&
                    !(!itemModel.Id && itemModel.SessionId > 0 && itemModel.IsAppointmentConfirmed) && (
                      <>
                        <Form.Item label="Or Appointment Date Preference">
                          <DatePicker.RangePicker
                            size="large"
                            onChange={value =>
                              this.handleChangeDateRangePicker(value, 'ClaimReqDateFrom', 'ClaimReqDateTo')
                            }
                            style={{ width: '445px' }}
                            value={[renderDate(itemModel.ClaimReqDateFrom), renderDate(itemModel.ClaimReqDateTo)]}
                            format="DD/MM/YYYY"
                            disabled={itemModel.isLocked || itemModel.IsCancelled}
                          />
                        </Form.Item>
                        <div />
                      </>
                    )}
                  <div className="checkbox-field fluid-field">
                    <Checkbox
                      style={styles.width300}
                      checked={itemModel.ConsentFormReceived}
                      onChange={this.handleChangeCheckBox('ConsentFormReceived')}
                      disabled={
                        (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                        lockTypist ||
                        api.isDoctor() ||
                        itemModel.IsCancelled
                      }
                    >
                      Claimant Consent Signed{' '}
                    </Checkbox>
                    {!!itemModel.ConsentFormReceivedCheckedDate && (
                      <>
                        <span style={{ marginLeft: '5px' }}>
                          {itemModel.ConsentFormReceivedCheckedDate
                            ? renderDate(itemModel.ConsentFormReceivedCheckedDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {itemModel.ConsentFormReceivedBy
                            ? ` - By ${this.renderStaffName(itemModel.ConsentFormReceivedBy)}`
                            : ''}
                        </span>
                      </>
                    )}
                    <Button
                      style={{ marginLeft: '10px' }}
                      className="blue"
                      onClick={() => {
                        window.open(`/MedicalService/MedicolegalAssessmentPdf?Id=${itemModel.Id}`, '_blank');
                      }}
                    >
                      View Consent Template
                    </Button>
                  </div>
                  {itemModel.ConsentFormReceived && (
                    <React.Fragment>
                      <Form.Item
                        label="Received date"
                        required={api.isAdminOrMagStaffOrAccounting()}
                        validateStatus={
                          api.isAdminOrMagStaffOrAccounting() && !itemModel.ConsentFormReceivedDate ? 'error' : null
                        }
                        help={
                          api.isAdminOrMagStaffOrAccounting() && !itemModel.ConsentFormReceivedDate
                            ? 'This field is required'
                            : ''
                        }
                      >
                        <DatePicker
                          size="large"
                          format="DD/MM/YYYY"
                          value={renderDate(itemModel.ConsentFormReceivedDate)}
                          disabled={
                            (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                            lockTypist ||
                            api.isDoctor()
                          }
                          onChange={value => this.handleChangeDatePicker('ConsentFormReceivedDate', value)}
                        />
                      </Form.Item>
                      <Form.Item />
                      <FieldUpload
                        uploadLoading={store.loadingUpload}
                        attachments={store.renderAttachment(
                          itemModel.AssessmentAttachment,
                          LOCAL_CONSTANT.ReportType_ConsentForm,
                        )}
                        reportType={LOCAL_CONSTANT.ReportType_ConsentForm}
                        multiUpload={true}
                        label="Attached Signed Consent"
                        disabled={(!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) || lockTypist}
                      />
                    </React.Fragment>
                  )}
                  <Form.Item
                    className="fluid-field"
                    label={
                      <React.Fragment>
                        Appointment Type
                        <button
                          className="field-actions"
                          onClick={() => this.handleFieldRecentChange(itemModel, 'SessionAcceptsType')}
                        >
                          Recent changes
                        </button>
                      </React.Fragment>
                    }
                    required
                  >
                    <Radio.Group
                      value={itemModel.SessionAcceptsType}
                      onChange={this.handleChangeSessionAcceptsType}
                      disabled={itemModel.isLocked || !api.isAdminOrMagStaffOrAccounting() || itemModel.IsCancelled}
                    >
                      <Radio value={0}>
                        In-person Assessment Only{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                          content="This session accepts in-person appointment only. Video appointment is not accepted."
                          position="top center"
                          wide="very"
                        />
                      </Radio>
                      <Radio value={1}>
                        Video Assessment Only{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                          content="This session accepts video appointment only. Make sure to update video session details in “Require Video Assessment” section."
                          position="top center"
                          wide="very"
                        />
                      </Radio>
                      <Radio value={2}>
                        Either In-person or Video Assessment{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                          content="This session accepts either in-person or video appointment. If video appointment is requested, make sure to update video session details in “Require Video Assessment” section."
                          position="top center"
                          wide="very"
                        />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {itemModel.SessionId > 0 && (
                    <Form.Item label="Appointment Availability Type">
                      <Tooltip
                        zIndex={9000}
                        color="white"
                        placement="top"
                        overlayInnerStyle={{
                          borderRadius: '10px',
                          color: 'black',
                          padding: '1rem',
                          maxHeight: '40vh',
                          overflow: 'auto',
                        }}
                        overlayStyle={{ minWidth: '450px' }}
                        title={
                          store.clinicConfirmed
                            ? 'Both date and time are confirmed available'
                            : 'Date is confirmed, time is not yet confirmed available'
                        }
                      >
                        <span>
                          <Input
                            size="large"
                            disabled
                            value={toJS(store.clinicConfirmed) ? 'Confirmed Available' : 'Unconfirmed Available'}
                          />
                        </span>
                      </Tooltip>
                    </Form.Item>
                  )}
                  <Form.Item label="Session Schedule Type">
                    <Input
                      size="large"
                      value={itemModel.SessionId > 0 && !itemModel.IsAdHoc ? 'Planned' : 'Ad-Hoc'}
                      disabled
                    />
                  </Form.Item>
                  {api.isAdminOrMagStaffOrAccounting() ? (
                    <Form.Item
                      label={
                        <React.Fragment>
                          Waiting List{' '}
                          <Popup
                            trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                            content="Mail-147 will be sent to waiting-list CMs if appointment is cancelled"
                            position="top center"
                            wide="very"
                          />
                        </React.Fragment>
                      }
                      className="fluid-field"
                    >
                      <Select
                        size="large"
                        value={store.waitingList.map(i => i.Id) || []}
                        mode="multiple"
                        onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'waitingList', 5)}
                        showSearch
                        showArrow
                        allowClear
                        disabled={itemModel.isLocked}
                        optionFilterProp="children"
                        onSearch={this.handleSearch('waitingList')}
                        onPopupScroll={this.handleScroll('waitingList')}
                      >
                        {listWaitingList.itemList &&
                          listWaitingList.itemList.length &&
                          store.arrayToArray(store.waitingList, listWaitingList?.itemList, 'Id').map(i => (
                            <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                              {i.Id} - {i.FullName} - {i.Email}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              </>
            )}
          </Collapse.Panel>

          <Collapse.Panel
            key="3"
            header={
              <Header
                title="Video Assessment Information"
                statuses={[
                  {
                    key: 'ime-video-assess-info-method',
                    isChecked: !!itemModel.AssessmentMethod,
                    text: !!itemModel.AssessmentMethod ? 'Required' : '',
                  },
                  {
                    key: 'ime-send-video-link-interpreter',
                    isChecked: !!itemModel.SendEassessUrlToInterpreter && !!itemModel.AssessmentMethod,
                    text: !!itemModel.AssessmentMethod ? 'Interpreter Sent' : '',
                  },
                  {
                    key: 'ime-send-video-link-claimant',
                    isChecked: !!itemModel.SendEassessUrlToClaimantLocation && !!itemModel.AssessmentMethod,
                    text: !!itemModel.AssessmentMethod ? 'Claimant Sent' : '',
                  },
                  {
                    key: 'ime-send-video-link-specialist',
                    isChecked: !!itemModel.SendEassessUrlToDoctorLocation && !!itemModel.AssessmentMethod,
                    text: !!itemModel.AssessmentMethod ? 'Specialist Sent' : '',
                  },
                ]}
                isRequired={false}
              />
            }
          >
            <Form.Item
              label={
                <React.Fragment>
                  Require Video Assessment
                  <button
                    className="field-actions"
                    onClick={() => this.handleFieldRecentChange(itemModel, 'AssessmentMethod')}
                  >
                    Recent changes
                  </button>
                </React.Fragment>
              }
            >
              <Radio.Group
                value={itemModel.AssessmentMethod}
                onChange={this.handleChangeFieldRadioBox('AssessmentMethod')}
                // className="dictation-type-radio-group"
                disabled={itemModel.isLocked || store.assessmentMethodDisable || itemModel.SessionAcceptsType === 0}
              >
                <Radio value={true} className="dictation-type-radio">
                  Yes
                </Radio>
                <Radio value={false} className="dictation-type-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            {itemModel.AssessmentMethod && (
              <Form.Item
                label={
                  <>
                    Primary Video Apps{' '}
                    <button
                      className="field-actions"
                      disabled={
                        itemModel.isLocked ||
                        !itemModel.IsAppointmentConfirmed ||
                        itemModel.AssessmentMethodType !== 'MAGVideo'
                      }
                      onClick={() => {
                        return this.generateVideLink();
                      }}
                    >
                      Generate Video Link
                    </button>
                  </>
                }
              >
                <Select
                  size="large"
                  value={itemModel.AssessmentMethodType}
                  onChange={this.handleChangeFieldSelect('AssessmentMethodType')}
                  showSearch
                  disabled={itemModel.isLocked}
                  optionFilterProp="children"
                >
                  <Select.Option value="MAGVideo">Scope Video</Select.Option>
                  <Select.Option value="Others">Zoom, Google Meet, MS Teams...</Select.Option>
                </Select>
              </Form.Item>
            )}

            {itemModel.AssessmentMethod && (
              <Form.Item
                label={
                  <span>
                    <span style={{ marginRight: '10px' }}>Alternative Video Link:</span>
                    <Checkbox
                      checked={itemModel.UseBackupLink}
                      className="checkbox-IsTypist"
                      onChange={this.handleChangeCheckBox('UseBackupLink')}
                      disabled={!itemModel.BackupEassessUrl}
                    >
                      Use this link for appointment updates
                    </Checkbox>
                  </span>
                }
                className="fluid-field"
                validateStatus={!store.validateField(itemModel.BackupEassessUrl, 'url') ? 'error' : null}
                help={!store.validateField(itemModel.BackupEassessUrl, 'url') ? 'Video link is not valid' : ''}
              >
                <Input
                  size="large"
                  disabled={itemModel.isLocked || !api.isAdminOrMagStaffOrAccounting()}
                  value={itemModel.BackupEassessUrl}
                  onChange={this.handleChangeTextInput('BackupEassessUrl')}
                />
              </Form.Item>
            )}
            {itemModel.AssessmentMethod && (
              <Form.Item label="Meeting Id">
                <Input
                  size="large"
                  disabled={api.isTypist() || api.isCaseManager() || api.isDoctor()}
                  value={itemModel.BackupIdRoom}
                  onChange={this.handleChangeTextInput('BackupIdRoom')}
                />
              </Form.Item>
            )}
            {itemModel.AssessmentMethod && (
              <Form.Item label="Passcode">
                <Input
                  size="large"
                  disabled={api.isTypist() || api.isCaseManager() || api.isDoctor()}
                  value={itemModel.BackupPasscode}
                  onChange={this.handleChangeTextInput('BackupPasscode')}
                />
              </Form.Item>
            )}
            {!!itemModel.BackupEassessUrl && (
              <div className="fluid-field checkbox-field">
                <Button
                  className="blue"
                  onClick={this.resendBackupVideoLink}
                  disabled={!itemModel.BackupEassessUrl || !store.validateField(itemModel.BackupEassessUrl, 'url')}
                >
                  Send Alternative Video Link
                </Button>
                {this.renderPopup('Send this video link to claimant/doctor (MAIL-38).')}
                {itemModel.SentDateBackupEassessUrl && (
                  <>
                    <span>
                      {' '}
                      {renderDate(itemModel.SentDateBackupEassessUrl, false, true)}{' '}
                      {itemModel.SentByBackupEassessUrl &&
                        ` - By ${store.renderStaffName(itemModel.SentByBackupEassessUrl)}`}
                    </span>{' '}
                  </>
                )}
              </div>
            )}
            <Collapse bordered={false}>
              {itemModel.AssessmentMethod &&
                !!itemModel.CMsInterpreterProviderId &&
                !!itemModel.Language &&
                api.isAdminOrMagStaffOrAccounting() && (
                  <Collapse.Panel key="4" header="Interpreter Arrangement">
                    <Form.Item
                      className="fluid-field"
                      label="Primary Video Link For Interpreter"
                      validateStatus={!store.validateField(itemModel.InterpreterEassessUrl, 'url') ? 'error' : null}
                      help={
                        !store.validateField(itemModel.InterpreterEassessUrl, 'url')
                          ? 'Please enter a valid url starts with http:// | https://'
                          : null
                      }
                    >
                      <Input
                        value={itemModel.InterpreterEassessUrl}
                        onChange={this.handleChangeTextInput('InterpreterEassessUrl')}
                        disabled={itemModel.isLocked}
                      />
                    </Form.Item>
                    <div className="fluid-field checkbox-field">
                      <Checkbox
                        disabled={itemModel.isLocked || itemModel.SendDateEassessUrlToInterpreter}
                        checked={itemModel.SendEassessUrlToInterpreter}
                        onChange={this.handleChangeCheckBox('SendEassessUrlToInterpreter')}
                      >
                        Send Video Link
                        <Popup
                          trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                          content="Video link will be emailed to Interpreter(MAIL-139)"
                          position="top center"
                          wide="very"
                        />
                      </Checkbox>
                      <span className="field-actions">
                        {itemModel.SendDateEassessUrlToInterpreter &&
                          `${renderDate(itemModel.SendDateEassessUrlToInterpreter, false, true)}`}
                        {itemModel.SendDateEassessUrlToInterpreter &&
                          ` - By ${store.renderStaffName(itemModel.SendDateEassessUrlToInterpreter)}`}
                      </span>

                      {itemModel.SendDateEassessUrlToInterpreter && (
                        <Button
                          className="blue ml-1"
                          disabled={
                            !itemModel.SendEassessUrlToInterpreter && !itemModel.SendDateEassessUrlToInterpreter
                          }
                          onClick={() => {
                            this.handleReSendEassessUrlToInterpreter();
                          }}
                        >
                          Resend Video Link
                        </Button>
                      )}
                    </div>
                  </Collapse.Panel>
                )}
              {itemModel.AssessmentMethod && (
                <Collapse.Panel key="5" header="Claimant Arrangement">
                  <Form.Item
                    validateStatus={!store.validateField(itemModel.EassessUrl, 'url') ? 'error' : null}
                    help={!store.validateField(itemModel.EassessUrl, 'url') ? 'Video link is not valid' : ''}
                    label="Primary Video Link for Claimant"
                    className="fluid-field"
                  >
                    <Input
                      size="large"
                      disabled={itemModel.isLocked || !api.isAdminOrMagStaffOrAccounting()}
                      value={itemModel.EassessUrl}
                      onChange={this.handleChangeTextInput('EassessUrl')}
                    />
                  </Form.Item>
                  {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
                    <div className="fluid-field checkbox-field">
                      <Checkbox
                        disabled={api.isCaseManager() || api.isDoctor() || itemModel.SendDateEassessToClaimant}
                        checked={itemModel.SendEassessUrlToClaimantLocation || !!itemModel.SendDateEassessToClaimant}
                        onChange={this.handleChangeCheckBox('SendEassessUrlToClaimantLocation')}
                      >
                        Send Video Link{' '}
                        {this.renderPopup(
                          !api.isAdminOrMagStaffOrAccounting()
                            ? 'Tick on the checkbox to send video link immediately upon Save.'
                            : 'Tick on the checkbox to send video link (MAIL-38) immediately upon Save.',
                        )}
                      </Checkbox>
                      {itemModel.SendDateEassessToClaimant && (
                        <>
                          <span className="field-actions">
                            {renderDate(itemModel.SendDateEassessToClaimant, false, true)}{' '}
                            {itemModel.SendEassessToClaimantByUser &&
                              ` - By ${store.renderStaffName(itemModel.SendEassessToClaimantByUser)}`}
                          </span>{' '}
                          <Button
                            className="blue"
                            onClick={this.resendVideoToClaimant}
                            disabled={
                              (itemModel.SendEassessUrlToClaimantLocation && !itemModel.SendDateEassessToClaimant) ||
                              api.isDoctor()
                            }
                          >
                            Resend Video Link
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                  <Form.Item
                    label="Select a location"
                    required
                    validateStatus={!itemModel.EassessClaimantLocationType ? 'error' : null}
                    help={!itemModel.EassessClaimantLocationType ? 'This field is required' : ''}
                  >
                    <Select
                      size="large"
                      value={itemModel.EassessClaimantLocationType}
                      onChange={this.handleChangeFieldSelect('EassessClaimantLocationType')}
                      disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
                    >
                      <Select.Option value="ClaimantHome">Claimant's home</Select.Option>
                      <Select.Option value="ClaimantSolicitorOffice">Claimant's solicitor office</Select.Option>
                      <Select.Option value="InsurerOffice">Insurer's office</Select.Option>
                      <Select.Option value="EmployerOffice">Employer's office</Select.Option>
                      <Select.Option value="AClinic">A clinic</Select.Option>
                      <Select.Option value="Other">Other location</Select.Option>
                    </Select>
                  </Form.Item>
                  {api.isAdminOrMagStaffOrAccounting() &&
                    !itemModel.isLocked &&
                    (itemModel.EassessClaimantLocationType === 'ClaimantSolicitorOffice' ||
                      itemModel.EassessClaimantLocationType === 'InsurerOffice' ||
                      itemModel.EassessClaimantLocationType === 'EmployerOffice' ||
                      itemModel.EassessClaimantLocationType === 'AClinic') && (
                      <Form.Item label={itemModel.EassessClaimantLocationType === 'AClinic' ? 'Clinic' : 'Client'}>
                        {this.renderEassessClaimantLocation()}
                      </Form.Item>
                    )}
                  <Form.Item label="Address">
                    <Input
                      size="large"
                      value={itemModel.EassessClaimantAddress}
                      onChange={this.handleChangeTextInput('EassessClaimantAddress')}
                      disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        Mobile Phone{' '}
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <Dropdown
                            overlay={
                              <Menu>
                                {store.claimant && (
                                  <Menu.Item onClick={() => this.handleUserInfo('mobile', 'claimant')}>
                                    Claimant's mobile
                                  </Menu.Item>
                                )}
                                {api.isAdminOrMagStaffOrAccounting() && (
                                  <Menu.Item onClick={() => this.handleUserInfo('mobile', 'casemanager')}>
                                    CM's mobile
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <button className="field-actions">
                              Use Claimant/CM's mobile <Icon type="down" />
                            </button>
                          </Dropdown>
                        )}
                      </>
                    }
                  >
                    <Input
                      size="large"
                      value={itemModel.EassessClaimantPhone}
                      onChange={this.handleChangeTextInput('EassessClaimantPhone')}
                      disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        Contact Email{' '}
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <Dropdown
                            overlay={
                              <Menu>
                                {store.claimant && (
                                  <Menu.Item onClick={() => this.handleUserInfo('email', 'claimant')}>
                                    Claimant's email
                                  </Menu.Item>
                                )}
                                {api.isAdminOrMagStaffOrAccounting() && (
                                  <Menu.Item onClick={() => this.handleUserInfo('email', 'casemanager')}>
                                    CM's email
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <button className="field-actions">
                              Use Claimant/CM's email <Icon type="down" />
                            </button>
                          </Dropdown>
                        )}
                      </>
                    }
                    required
                    validateStatus={
                      !store.validateField(itemModel.EassessClaimantEmail, 'email') || !itemModel.EassessClaimantEmail
                        ? 'error'
                        : null
                    }
                    help={
                      !store.validateField(itemModel.EassessClaimantEmail, 'email')
                        ? 'Please enter a valid email address'
                        : !itemModel.EassessClaimantEmail
                        ? 'This field is required'
                        : null
                    }
                  >
                    <Input
                      size="large"
                      value={itemModel.EassessClaimantEmail}
                      onChange={this.handleChangeTextInput('EassessClaimantEmail')}
                      disabled={itemModel.Attendance === CONSTANTS.Attendance_Attended || api.isDoctor()}
                    />
                  </Form.Item>
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={itemModel.EassessClaimantLocationConfirmed}
                      onChange={this.handleChangeCheckBox('EassessClaimantLocationConfirmed')}
                      disabled={
                        (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                        lockTypist ||
                        api.isDoctor()
                      }
                      style={styles.width300}
                    >
                      Video Assessment Location Confirmed{' '}
                      {this.renderPopup('Claimant confirms the selected location to conduct the video assessment.')}
                    </Checkbox>
                    {!!itemModel.EassessClaimantLocationConfirmedCheckedDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {itemModel.EassessClaimantLocationConfirmedCheckedDate
                            ? renderDate(itemModel.EassessClaimantLocationConfirmedCheckedDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {itemModel.EassessClaimantLocationConfirmedBy
                            ? ` - By ${this.renderStaffName(itemModel.EassessClaimantLocationConfirmedBy)}`
                            : ''}
                        </span>
                      </>
                    )}
                  </div>
                  {itemModel.EassessClaimantLocationConfirmed && (
                    <Form.Item
                      label={<React.Fragment>Confirmed date </React.Fragment>}
                      required
                      className="fluid-field"
                      validateStatus={!itemModel.EassessClaimantLocationConfirmedDate ? 'error' : null}
                      help={!itemModel.EassessClaimantLocationConfirmedDate ? 'This field is required' : ''}
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(itemModel.EassessClaimantLocationConfirmedDate)}
                        disabled={
                          (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                          lockTypist ||
                          api.isDoctor()
                        }
                        onChange={value => this.handleChangeDatePicker('EassessClaimantLocationConfirmedDate', value)}
                      />
                    </Form.Item>
                  )}
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={itemModel.EassessClaimantLocationChecklistReceived}
                      onChange={this.handleChangeCheckBox('EassessClaimantLocationChecklistReceived')}
                      disabled={
                        (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                        lockTypist ||
                        api.isDoctor()
                      }
                      style={styles.width300}
                    >
                      Video Equipment Checked{' '}
                      {this.renderPopup('Staff has checked claimant video equipment ready for the appointment.')}
                    </Checkbox>
                    {!!itemModel.EassessClaimantLocationChecklistReceivedCheckedDate && (
                      <>
                        <span style={{ marginLeft: '5px' }}>
                          {itemModel.EassessClaimantLocationChecklistReceivedCheckedDate
                            ? renderDate(itemModel.EassessClaimantLocationChecklistReceivedCheckedDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {itemModel.EassessClaimantLocationChecklistReceivedBy
                            ? ` - By ${this.renderStaffName(itemModel.EassessClaimantLocationChecklistReceivedBy)}`
                            : ''}
                        </span>
                      </>
                    )}
                    <Button
                      style={{ marginLeft: '10px' }}
                      className="blue"
                      onClick={() => {
                        window.open(`/MedicalService/AssessmentCheckList?Id=${itemModel.Id}`, '_blank');
                      }}
                    >
                      View Checklist Template
                    </Button>
                  </div>
                  {itemModel.EassessClaimantLocationChecklistReceived && (
                    <React.Fragment>
                      <Form.Item
                        label={<React.Fragment>Checked date </React.Fragment>}
                        required
                        validateStatus={!itemModel.EassessClaimantLocationChecklistReceivedDate ? 'error' : null}
                        help={!itemModel.EassessClaimantLocationChecklistReceivedDate ? 'This field is required' : ''}
                      >
                        <DatePicker
                          size="large"
                          format="DD/MM/YYYY"
                          value={renderDate(itemModel.EassessClaimantLocationChecklistReceivedDate)}
                          disabled={
                            (!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                            lockTypist ||
                            api.isDoctor()
                          }
                          onChange={value =>
                            this.handleChangeDatePicker('EassessClaimantLocationChecklistReceivedDate', value)
                          }
                        />
                      </Form.Item>
                      <Form.Item />
                      {((!api.isAdminOrMagStaffOrAccounting() && itemModel.PaperWorkReceived) ||
                        api.isDoctor() ||
                        lockTypist) && (
                        <FieldUpload
                          uploadLoading={store.loadingUpload}
                          attachments={store.renderAttachment(
                            itemModel.AssessmentAttachment,
                            LOCAL_CONSTANT.ReportType_EassessClaimantLocationChecklist,
                          )}
                          reportType={LOCAL_CONSTANT.ReportType_EassessClaimantLocationChecklist}
                          multiUpload={true}
                          label="Attached Equipment Checklist"
                        />
                      )}
                    </React.Fragment>
                  )}
                </Collapse.Panel>
              )}
              {itemModel.AssessmentMethod && !api.isCaseManager() && (
                <Collapse.Panel key="6" header="Specialist Arrangement">
                  <Form.Item
                    label="Primary Video Link for Specialist"
                    validateStatus={
                      !store.validateField(itemModel.DoctorEassessUrl, 'url', 'DoctorEassessUrl') ? 'error' : null
                    }
                    className="fluid-field"
                    help={!store.validateField(itemModel.DoctorEassessUrl, 'url') ? 'Video link is not valid' : ''}
                  >
                    <Input
                      size="large"
                      value={itemModel.DoctorEassessUrl}
                      onChange={this.handleChangeTextInput('DoctorEassessUrl')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || api.isTypist()
                      }
                    />
                  </Form.Item>
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      disabled={itemModel.isLocked || itemModel.SendDateEassessToDoctor}
                      checked={itemModel.SendEassessUrlToDoctorLocation}
                      onChange={this.handleChangeCheckBox('SendEassessUrlToDoctorLocation')}
                    >
                      Send Video Link{' '}
                      {this.renderPopup(
                        !api.isAdminOrMagStaffOrAccounting()
                          ? 'Tick on the checkbox to send video link immediately upon Save.'
                          : 'Tick on the checkbox to send video link (MAIL-38) immediately upon Save.',
                      )}
                    </Checkbox>
                    {itemModel.SendDateEassessToDoctor && (
                      <>
                        <span className="field-actions">
                          {renderDate(itemModel.SendDateEassessToDoctor, false, true)}{' '}
                          {itemModel.SendEassessToDoctorByUser &&
                            ` - By ${store.renderStaffName(itemModel.SendEassessToDoctorByUser)}`}
                        </span>{' '}
                        <Button
                          className="blue"
                          onClick={this.resendVideoToDoctor}
                          disabled={
                            (!itemModel.SendEassessUrlToDoctorLocation && !itemModel.SendDateEassessToDoctor) ||
                            api.isTypist()
                          }
                        >
                          Resend Video Link
                        </Button>
                      </>
                    )}
                  </div>
                  <Form.Item
                    label="Select a location"
                    required
                    validateStatus={!itemModel.EassessDoctorLocationType ? 'error' : null}
                    help={!itemModel.EassessDoctorLocationType ? 'This field is required' : ''}
                  >
                    <Select
                      size="large"
                      value={itemModel.EassessDoctorLocationType}
                      onChange={this.handleChangeFieldSelect('EassessDoctorLocationType')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                    >
                      <Select.Option value="DoctorOffice">Specialist Owned Clinic</Select.Option>
                      <Select.Option value="AClinic">Obtained Clinic</Select.Option>
                      <Select.Option value="Other">Other location</Select.Option>
                    </Select>
                  </Form.Item>
                  {itemModel.EassessDoctorLocationType !== 'Other' && (
                    <Form.Item label="Location">
                      <Select
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        onChange={(value, event) => this.handleChangeEassessDoctorLocationType(value, event)}
                        onSearch={this.handleSearch('clinicAddress')}
                        allowClear
                        value={this.state.specialistClinic}
                        disabled={api.isTypist()}
                      >
                        {(store.listClinicForAddress?.itemList || []).map(i => (
                          <Select.Option key={i.ID} value={i.ID} data={JSON.stringify(i)}>
                            {i.Name} - {i.FullAddress}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item label="Address">
                    <Input
                      size="large"
                      value={itemModel.EassessDoctorAddress}
                      onChange={this.handleChangeTextInput('EassessDoctorAddress')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Mobile Phone">
                    <Input
                      size="large"
                      value={itemModel.EassessDoctorPhone}
                      onChange={this.handleChangeTextInput('EassessDoctorPhone')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Specialist Email"
                    required={itemModel.AssessmentMethod && itemModel.Attendance === CONSTANTS.Attendance_None}
                    validateStatus={
                      !store.validateField(itemModel.EassessDoctorEmail, 'email') ||
                      (itemModel.AssessmentMethod &&
                        itemModel.Attendance === CONSTANTS.Attendance_None &&
                        !itemModel.EassessDoctorEmail)
                        ? 'error'
                        : null
                    }
                    help={
                      !store.validateField(itemModel.EassessDoctorEmail, 'email')
                        ? 'Please enter a valid email address'
                        : itemModel.AssessmentMethod &&
                          itemModel.Attendance === CONSTANTS.Attendance_None &&
                          !itemModel.EassessDoctorEmail
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <Input
                      size="large"
                      value={itemModel.EassessDoctorEmail}
                      onChange={this.handleChangeTextInput('EassessDoctorEmail')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                    />
                  </Form.Item>
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={itemModel.EassessDoctorLocationConfirmed}
                      onChange={this.handleChangeCheckBox('EassessDoctorLocationConfirmed')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                    >
                      Video Assessment Location Confirmed{' '}
                      {this.renderPopup('Staff has confirmed with specialist regarding assessment location.')}
                    </Checkbox>
                    {!!itemModel.EassessDoctorLocationConfirmedCheckedDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {itemModel.EassessDoctorLocationConfirmedCheckedDate
                            ? renderDate(itemModel.EassessDoctorLocationConfirmedCheckedDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {itemModel.EassessDoctorLocationConfirmedBy
                            ? ` - By ${this.renderStaffName(itemModel.EassessDoctorLocationConfirmedBy)}`
                            : ''}
                        </span>
                      </>
                    )}
                  </div>
                  {itemModel.EassessDoctorLocationConfirmed && (
                    <Form.Item label="Confirmed date" required>
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(itemModel.EassessDoctorLocationConfirmedDate)}
                        disabled={
                          (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                        }
                        onChange={value => this.handleChangeDatePicker('EassessDoctorLocationConfirmedDate', value)}
                      />
                    </Form.Item>
                  )}
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={itemModel.EassessDoctorLocationChecklistReceived}
                      onChange={this.handleChangeCheckBox('EassessDoctorLocationChecklistReceived')}
                      disabled={
                        (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                      }
                      style={styles.width300}
                    >
                      Video Equipment Checked{' '}
                      {this.renderPopup('Staff has checked specialist video equipment ready for appointment.')}
                    </Checkbox>
                    {!!itemModel.EassessDoctorLocationChecklistReceivedCheckedDate && (
                      <>
                        <span style={{ marginLeft: '5px' }}>
                          {itemModel.EassessDoctorLocationChecklistReceivedCheckedDate
                            ? renderDate(itemModel.EassessDoctorLocationChecklistReceivedCheckedDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {itemModel.EassessDoctorLocationChecklistReceivedBy
                            ? ` - By ${this.renderStaffName(itemModel.EassessDoctorLocationChecklistReceivedBy)}`
                            : ''}
                        </span>
                      </>
                    )}
                    <Button
                      style={{ marginLeft: '10px' }}
                      className="blue"
                      disabled={itemModel.isLocked || lockTypist}
                      onClick={() => {
                        window.open(`/MedicalService/AssessmentCheckList?Id=${itemModel.Id}`);
                      }}
                    >
                      View Checklist Template
                    </Button>
                  </div>
                  {itemModel.EassessDoctorLocationChecklistReceived && (
                    <React.Fragment>
                      <Form.Item
                        required={api.isAdminOrMagStaffOrAccounting()}
                        label={<React.Fragment>Received date</React.Fragment>}
                        validateStatus={
                          api.isAdminOrMagStaffOrAccounting() && !itemModel.EassessDoctorLocationChecklistReceivedDate
                            ? 'error'
                            : null
                        }
                        help={
                          api.isAdminOrMagStaffOrAccounting() && !itemModel.EassessDoctorLocationChecklistReceivedDate
                            ? 'This field is required'
                            : ''
                        }
                      >
                        <DatePicker
                          size="large"
                          format="DD/MM/YYYY"
                          value={renderDate(itemModel.EassessDoctorLocationChecklistReceivedDate)}
                          disabled={
                            (api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist
                          }
                          onChange={value =>
                            this.handleChangeDatePicker('EassessDoctorLocationChecklistReceivedDate', value)
                          }
                        />
                      </Form.Item>
                      <Form.Item />
                      {((api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended) || lockTypist) && (
                        <FieldUpload
                          disabled={lockTypist}
                          uploadLoading={store.loadingUpload}
                          attachments={store.renderAttachment(
                            itemModel.AssessmentAttachment,
                            LOCAL_CONSTANT.ReportType_EassessDoctorLocationChecklist,
                          )}
                          reportType={LOCAL_CONSTANT.ReportType_EassessDoctorLocationChecklist}
                          multiUpload={true}
                          label="Attached Equipment Checklist"
                        />
                      )}
                    </React.Fragment>
                  )}
                </Collapse.Panel>
              )}
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <Header
                title="Interpreter Service"
                statuses={[
                  // {
                  //   key: 'ime-dictation-opt',
                  //   isChecked: itemModel.DictationOption === 0,
                  //   text: itemModel.DictationOption === 0 ? 'Dictation by Medicolegal Provider' : '',
                  // },
                  {
                    key: 'ime-interperter',
                    isChecked: !!itemModel.Interpreter,
                    text: itemModel.Interpreter
                      ? itemModel.InterpretationStatus === -1
                        ? 'Interpreter Required'
                        : itemModel.InterpretationStatus === 0
                        ? 'Interpreter Confirmed'
                        : itemModel.InterpretationStatus === 1
                        ? 'Interpreter Cancelled - No Fee'
                        : itemModel.InterpretationStatus === 2
                        ? 'Interpreter Cancelled with Fee'
                        : ''
                      : '',
                  },
                ]}
                isRequired={false}
              />
            }
          >
            <Form.Item label="Interpreter Required">
              <Radio.Group
                disabled={
                  LockCMDoctor ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF
                }
                // className="dictation-type-radio-group"
                value={itemModel.Interpreter}
                onChange={this.handleChangeFieldRadioBox('Interpreter')}
              >
                <Radio value={true} className="dictation-type-radio">
                  Yes
                </Radio>
                <Radio value={false} className="dictation-type-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            {itemModel.Interpreter && (
              <React.Fragment>
                <Form.Item label="Language" required={itemModel.Interpreter} className="fluid-field">
                  <Select
                    size="large"
                    value={itemModel.Language}
                    onChange={this.handleChangeFieldSelect('Language')}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={
                      LockCMDoctor ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.Attendance === CONSTANTS.Attendance_LCF
                    }
                  >
                    {languages.itemList &&
                      languages.itemList.length &&
                      languages.itemList.map((i, idx) => (
                        <Select.Option value={i.LanguageName} key={idx}>
                          {i.LanguageName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {itemModel.Language && (
                  <Form.Item
                    className="fluid-field"
                    label={
                      <React.Fragment>
                        Organised by Medicolegal Provider{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="Tick on 'Organised by Medicolegal Provider = Yes' if booking client does not organise this service"
                          position="top center"
                          wide="very"
                        />
                      </React.Fragment>
                    }
                  >
                    <Radio.Group
                      value={itemModel.HasInterpretationService}
                      onChange={this.handleChangeFieldRadioBox('HasInterpretationService')}
                      // className="dictation-type-radio-group"
                      disabled={
                        LockCMDoctor ||
                        itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                        itemModel.Attendance === CONSTANTS.Attendance_LCF
                      }
                    >
                      <Radio value={true} className="dictation-type-radio">
                        Yes
                      </Radio>
                      <Radio value={false} className="dictation-type-radio">
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item className="fluid-field" label="Additional Service Notes">
                    <Input.TextArea
                      size="large"
                      rows={3}
                      value={itemModel.InterpreterAdditionalInformation}
                      onChange={this.handleChangeTextInput('InterpreterAdditionalInformation')}
                    />
                  </Form.Item>
                )}
                <Form.Item className="fluid-field">
                  <Checkbox
                    disabled={
                      !!itemModel.SendInterpreterRqtoOperatorDateTime ||
                      itemModel.isLocked ||
                      LockCMDoctor ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.Attendance === CONSTANTS.Attendance_LCF
                    }
                    checked={itemModel.SendInterpreterRqtoOperator}
                    onChange={this.handleChangeCheckBox('SendInterpreterRqtoOperator')}
                  >
                    Send Interpreter Request to Medicolegal Provider
                    <Popup
                      trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                      content="Interpreter request will be sent to operator (MAIL-96)"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>{' '}
                  {!!itemModel.SendInterpreterRqtoOperatorDateTime && (
                    <span className="field-actions">
                      {renderDate(itemModel.SendInterpreterRqtoOperatorDateTime, false, true)}
                      {!!itemModel.SendInterpreterRqtoOperatorBy
                        ? ` - By ${store.renderStaffName(itemModel.SendInterpreterRqtoOperatorBy)}`
                        : ''}
                    </span>
                  )}
                  {itemModel.Id > 0 && !!itemModel.SendInterpreterRqtoOperatorDateTime && (
                    <Button
                      className="blue"
                      disabled={
                        LockCMDoctor ||
                        itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                        itemModel.Attendance === CONSTANTS.Attendance_LCF
                      }
                      onClick={() => {
                        this.handleClickReSendInterpreterRqtoOperator();
                      }}
                    >
                      Resend Request
                    </Button>
                  )}
                </Form.Item>
              </React.Fragment>
            )}
            {api.isAdminOrMagStaffOrAccounting() && itemModel.Interpreter && itemModel.Language && (
              <React.Fragment>
                <Divider className="fluid-field" />
                <Form.Item label="Interpreter Provider">
                  <Select
                    size="large"
                    value={itemModel.InterpretationServiceClientId}
                    onChange={this.handleChangeFieldSelect('InterpretationServiceClientId')}
                    showSearch
                    disabled={
                      LockCMDoctor ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.Attendance === CONSTANTS.Attendance_LCF
                    }
                    allowClear
                    optionFilterProp="children"
                  >
                    {clinicInterpretationServiceList &&
                      !!clinicInterpretationServiceList.length &&
                      clinicInterpretationServiceList.map(i => (
                        <Select.Option value={i.Id} key={i.Id}>
                          {i.Id} - {i.Name} - {i.Email}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Interpreter">
                  <Select
                    size="large"
                    value={itemModel.CMsInterpreterProviderId}
                    onChange={this.handleChangeFieldSelect('CMsInterpreterProviderId')}
                    showSearch
                    allowClear
                    disabled={
                      !itemModel.InterpretationServiceClientId ||
                      LockCMDoctor ||
                      itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                      itemModel.Attendance === CONSTANTS.Attendance_LCF
                    }
                    optionFilterProp="children"
                  >
                    {(Array.isArray(toJS(store.interpreterProviderCMs)) ? store.interpreterProviderCMs : []).map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.FullName} - {i.Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <React.Fragment>
                  <Form.Item>
                    {/* {itemModel.InterpretationServiceClientEmail} */}
                    <Checkbox
                      disabled={
                        !itemModel.InterpretationServiceClientEmail ||
                        !!itemModel.SentDateInterpreterRqtoProvider ||
                        itemModel.isLocked ||
                        LockCMDoctor ||
                        itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                        itemModel.Attendance === CONSTANTS.Attendance_LCF
                      }
                      checked={itemModel.SendInterpreterRqtoProvider}
                      onChange={this.handleChangeCheckBox('SendInterpreterRqtoProvider')}
                    >
                      Send Interpreter Booking to Provider
                      <Popup
                        trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                        content="Interpreter request will be sent to provider (MAIL-95)"
                        position="top center"
                        wide="very"
                      />
                    </Checkbox>{' '}
                    {!!itemModel.SentDateInterpreterRqtoProvider && (
                      <span className="field-actions">
                        {renderDate(itemModel.SentDateInterpreterRqtoProvider, false, true)}
                        {!!itemModel.SentByInterpreterRqtoProvider
                          ? ` - By ${store.renderStaffName(itemModel.SentByInterpreterRqtoProvider)}`
                          : ''}
                      </span>
                    )}
                    {/* {itemModel.Id > 0 && !!itemModel.SentDateInterpreterRqtoProvider && (
                      <div style={{ marginTop: '8px' }}>
                        <Button
                          className="blue"
                          disabled={
                            itemModel.InterpretationStatus !== 0 ||
                            LockCMDoctor ||
                            itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                            itemModel.Attendance === CONSTANTS.Attendance_LCF
                          }
                          onClick={() => {
                            this.handleClickReSendInterpreterRqtoProvider();
                          }}
                        >
                          Resend Request
                        </Button>
                      </div>
                    )} */}
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      disabled={
                        itemModel.InterpretationStatus !== 0 ||
                        !itemModel.InterpretationServiceClientId ||
                        !!itemModel.SendInterpretationDateTime ||
                        itemModel.isLocked ||
                        LockCMDoctor ||
                        itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                        itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                        !itemModel.SendInterpreterRqtoProvider
                      }
                      checked={itemModel.SelectedSendInterpretation}
                      onChange={this.handleChangeCheckBox('SelectedSendInterpretation')}
                    >
                      Send Interpreter Booking To Interpreter
                      <Popup
                        trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                        content="Interpreter request will be sent to interpreter (MAIL-95)"
                        position="top center"
                        wide="very"
                      />
                    </Checkbox>{' '}
                    {!!itemModel.SendInterpretationDateTime && (
                      <span className="field-actions">
                        {renderDate(itemModel.SendInterpretationDateTime, false, true)}
                        {!!itemModel.SendInterpretationBy
                          ? ` - By ${store.renderStaffName(itemModel.SendInterpretationBy)}`
                          : ''}
                      </span>
                    )}
                    {/* {itemModel.Id > 0 && !!itemModel.SendInterpretationDateTime && (
                      <div style={{ marginTop: '8px' }}>
                        <Button
                          className="blue"
                          disabled={
                            LockCMDoctor ||
                            itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                            itemModel.Attendance === CONSTANTS.Attendance_LCF
                          }
                          onClick={() => {
                            this.handleClickSendInterpretationEmail();
                          }}
                        >
                          Resend Request
                        </Button>
                      </div>
                    )} */}
                  </Form.Item>
                </React.Fragment>
                <Form.Item className="fluid-field" label="Interpreter Booking Status">
                  <Radio.Group
                    value={itemModel.InterpretationStatus}
                    onChange={this.handleChangeFieldRadioBox('InterpretationStatus')}
                  >
                    <Radio value={-1}>None</Radio>
                    <Radio value={0}>Provider Confirmed</Radio>
                    <Radio value={1}>
                      Cancelled - No Fee{' '}
                      {this.renderPopup('Interpreter booking cancellation will be sent to Provider (MAIL-103)')}
                    </Radio>
                    <Radio value={2}>
                      Cancelled with Fee{' '}
                      {this.renderPopup('Interpreter booking cancellation will be sent to Provider (MAIL-103)')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {parseInt(itemModel.InterpretationStatus) === 0 && (
                  <Form.Item
                    className="fluid-field"
                    label="Confirmation Date"
                    required
                    validateStatus={!itemModel.InterpretationServiceConfirmedDateTime ? 'error' : null}
                    help={!itemModel.InterpretationServiceConfirmedDateTime ? 'This field is required' : ''}
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      value={renderDate(itemModel.InterpretationServiceConfirmedDateTime)}
                      disabled={itemModel.isLocked}
                      onChange={value => this.handleChangeDatePicker('InterpretationServiceConfirmedDateTime', value)}
                    />
                  </Form.Item>
                )}
                {parseInt(itemModel.InterpretationStatus) !== 0 && parseInt(itemModel.InterpretationStatus) !== -1 && (
                  <>
                    <Form.Item
                      className="fluid-field"
                      label="Date of Cancellation"
                      required
                      validateStatus={!itemModel.InterpreterStatusCancelledDate ? 'error' : null}
                      help={!itemModel.InterpreterStatusCancelledDate ? 'This field is required' : ''}
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(itemModel.InterpreterStatusCancelledDate)}
                        disabled={
                          itemModel.isLocked ||
                          LockCMDoctor ||
                          itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                          itemModel.Attendance === CONSTANTS.Attendance_LCF
                        }
                        onChange={value => this.handleChangeDatePicker('InterpretationServiceConfirmedDateTime', value)}
                      />
                    </Form.Item>
                    <Form.Item
                      className="fluid-field"
                      label="Reasons"
                      // required
                      // validateStatus={!itemModel.InterpreterStatusReasonCancellation ? 'error' : null}
                      // help={!itemModel.InterpreterStatusReasonCancellation ? 'This field is required' : ''}
                    >
                      <Radio.Group
                        value={itemModel.InterpreterStatusReasonCancellation}
                        onChange={this.handleChangeFieldRadioBox('InterpreterStatusReasonCancellation')}
                        disabled={
                          itemModel.isLocked ||
                          LockCMDoctor ||
                          itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                          itemModel.Attendance === CONSTANTS.Attendance_LCF
                        }
                      >
                        <Radio value={0}>At the request of Case Manager</Radio>
                        <Radio value={1}>At the request of Specialist</Radio>
                        <Radio value={2}>At the request of Interpreter Provider</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
              </React.Fragment>
            )}
          </Collapse.Panel>
        </Collapse>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            className="fluid-field"
            label={
              <>
                Appointment Comment
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Only staff can view"
                  position="top center"
                  wide="very"
                />
              </>
            }
          >
            <Input.TextArea
              rows={3}
              value={itemModel.AppointmentComment}
              onChange={this.handleChangeTextInput('AppointmentComment')}
              disabled={api.isDoctor() && itemModel.Attendance === CONSTANTS.Attendance_Attended}
            />
          </Form.Item>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <>
            <Form.Item
              className="fluid-field cancellation-label"
              label="Send Appointment Inquiry to"
              style={styles.marginTop10}
            >
              <br />
              <Checkbox
                checked={itemModel.NotifyStaffPICAppointmentInquiry}
                onChange={this.handleChangeCheckBox('NotifyStaffPICAppointmentInquiry')}
                disabled={
                  itemModel.IsAppointmentConfirmed ||
                  !api.isAdminOrMagStaffOrAccounting() ||
                  !!itemModel.SentDateNotifyStaffPICAppointmentInquiry
                }
                style={styles.checkboxBottom}
              >
                Staff PIC
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Inquiry confirmation will be sent to PIC 2 (MAIL-124)"
                  position="top center"
                  wide="very"
                />
                <span style={styles.highlightSpan5}>
                  {`${
                    itemModel.SentDateNotifyStaffPICAppointmentInquiry
                      ? `${renderDate(itemModel.SentDateNotifyStaffPICAppointmentInquiry, false, true)}`
                      : ''
                  } ${
                    itemModel.SentByNotifyStaffPICAppointmentInquiry
                      ? ` - By ${this.renderStaffName(itemModel.SentByNotifyStaffPICAppointmentInquiry)}`
                      : ''
                  }`}
                </span>
              </Checkbox>
              <br />
              <Checkbox
                checked={itemModel.NotifyCmAppointmentInquiry}
                onChange={this.handleChangeCheckBox('NotifyCmAppointmentInquiry')}
                disabled={
                  itemModel.IsAppointmentConfirmed ||
                  !!itemModel.SentDateNotifyCMAppointmentInquiry ||
                  !store.bookingCaseManager.Email ||
                  (itemModel.Id === 0 && !store.cmSelected?.Email)
                }
                style={styles.checkboxBottom}
              >
                Booking CM
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Inquiry confirmation will be sent to Booking CM (MAIL-118, DIRECT-118)"
                  position="top center"
                  wide="very"
                />
                <span style={styles.highlightSpan5}>
                  {`${
                    itemModel.SentDateNotifyCMAppointmentInquiry
                      ? `${renderDate(itemModel.SentDateNotifyCMAppointmentInquiry, false, true)}`
                      : ''
                  } ${
                    itemModel.SentByNotifyCMAppointmentInquiry
                      ? ` - By ${this.renderStaffName(itemModel.SentByNotifyCMAppointmentInquiry)}`
                      : ''
                  }`}
                </span>
              </Checkbox>

              <br />
              <Checkbox
                checked={itemModel.NotifyDoctorAppointmentInquiry}
                onChange={this.handleChangeCheckBox('NotifyDoctorAppointmentInquiry')}
                disabled={
                  itemModel.IsAppointmentConfirmed ||
                  !!itemModel.SentDateNotifyDoctorAppointmentInquiry ||
                  !store.specialist?.Email2
                }
              >
                Specialist
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Inquiry confirmation will be sent to Specialist (MAIL-123, DIRECT-123)"
                  position="top center"
                  wide="very"
                />
                <span style={styles.highlightSpan25}>
                  {`${
                    itemModel.SentDateNotifyDoctorAppointmentInquiry
                      ? `${renderDate(itemModel.SentDateNotifyDoctorAppointmentInquiry, false, true)}`
                      : ''
                  } ${
                    itemModel.SentByNotifyDoctorAppointmentInquiry
                      ? ` - By ${this.renderStaffName(itemModel.SentByNotifyDoctorAppointmentInquiry)}`
                      : ''
                  }`}
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item className="fluid-field">
              <Dropdown
                disabled={
                  (!itemModel.SentDateNotifyCMAppointmentInquiry &&
                    !itemModel.SentDateNotifyDoctorAppointmentInquiry &&
                    !itemModel.SentDateNotifyStaffPICAppointmentInquiry) ||
                  lockTypist
                }
                overlay={
                  <Menu>
                    <Menu.Item
                      disabled={
                        !itemModel.SentDateNotifyStaffPICAppointmentInquiry || !api.isAdminOrMagStaffOrAccounting()
                      }
                      onClick={() => this.handleResendNotifyInquiryToStaffPIC()}
                    >
                      To Staff PIC{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SentDateNotifyCMAppointmentInquiry || !api.isAdminOrMagStaffOrAccounting()}
                      onClick={() => this.handleResendNotifyInquiry(1)}
                    >
                      To Booking CM{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !itemModel.SentDateNotifyDoctorAppointmentInquiry || !api.isAdminOrMagStaffOrAccounting()
                      }
                      onClick={() => this.handleResendNotifyInquiry(2)}
                    >
                      To Specialist{' '}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className="blue">
                  Resend Appointment Inquiry <Icon type="down" />
                </Button>
              </Dropdown>
            </Form.Item>
          </>
        )}
        {!api.isDoctor() && !api.isTypist() && (
          <>
            <Form.Item
              className="fluid-field cancellation-label"
              label="Send Appointment Confirmation to"
              style={styles.marginTop10}
            >
              <br />
              <Checkbox
                checked={itemModel.NotifyStaffPICAppointmentConfirmed}
                onChange={this.handleChangeCheckBox('NotifyStaffPICAppointmentConfirmed')}
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (oldPaperworkReceived && !api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()) ||
                  !!itemModel.SentDateNotifyStaffPICAppointmentConfirmed
                }
                style={styles.checkboxMargin}
              >
                Staff PIC
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Appointment confirmation will be sent to PIC 2 (MAIL-176)"
                  position="top center"
                  wide="very"
                />
                <span style={styles.highlightSpan25}>
                  {`${
                    itemModel.SentDateNotifyStaffPICAppointmentConfirmed
                      ? `${renderDate(itemModel.SentDateNotifyStaffPICAppointmentConfirmed, false, true)}`
                      : ''
                  } ${
                    itemModel.SentByNotifyStaffPICAppointmentConfirmed
                      ? ` - By ${this.renderStaffName(itemModel.SentByNotifyStaffPICAppointmentConfirmed)}`
                      : ''
                  }`}
                </span>
              </Checkbox>
              <br />
              <Checkbox
                checked={itemModel.NotifyClaimantConfirmedAppointment}
                onChange={this.handleChangeCheckBox('NotifyClaimantConfirmedAppointment')}
                disabled={
                  !itemModel.IsAppointmentConfirmed ||
                  (oldPaperworkReceived && !api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()) ||
                  !!itemModel.SendDateNotifyClaimantConfirmedAppointment
                }
                style={styles.checkboxMargin}
              >
                Claimant
                <Popup
                  trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                  content="Appointment confirmation will be sent to Claimant (MAIL-08 for in-person, MAIL-08B for video assessment)"
                  position="top center"
                  wide="very"
                />
                <span style={styles.highlightSpan25}>
                  {`${
                    itemModel.SendDateNotifyClaimantConfirmedAppointment
                      ? `${renderDate(itemModel.SendDateNotifyClaimantConfirmedAppointment, false, true)}`
                      : ''
                  } ${
                    itemModel.NotifyClaimantConfirmedAppointmentByUser
                      ? ` - By ${this.renderStaffName(itemModel.NotifyClaimantConfirmedAppointmentByUser)}`
                      : ''
                  }`}
                </span>
              </Checkbox>
              {api.isAdminOrMagStaffOrAccounting() && (
                <FieldUpload
                  uploadLoading={store.loadingUpload}
                  className="add-file-container posstion-IsTypist-label"
                  label={<span>Attached Files</span>}
                  attachments={store.renderAttachment(
                    itemModel.AssessmentAttachment,
                    LOCAL_CONSTANT.ReportType_AppointmentConfirmationClaimant,
                  )}
                  reportType={LOCAL_CONSTANT.ReportType_AppointmentConfirmationClaimant}
                  multiUpload={true}
                  disableDragDrop={true}
                />
              )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                <>
                  <br />
                  <Checkbox
                    checked={itemModel.NotifyCmAppointmentConfirmed}
                    onChange={this.handleChangeCheckBox('NotifyCmAppointmentConfirmed')}
                    disabled={
                      !itemModel.IsAppointmentConfirmed ||
                      !!itemModel.SentDateEmailToCMConfirmAppointment ||
                      (itemModel.Id === 0 && !store.cmSelected?.Email)
                    }
                    style={styles.checkboxBottom}
                  >
                    Booking CM
                    <Popup
                      trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                      content="Appointment confirmation will be sent to CMs (MAIL-04 for in-person, MAIL-39 for video assessment)"
                      position="top center"
                      wide="very"
                    />
                    <span style={styles.highlightSpan5}>
                      {`${
                        itemModel.SentDateEmailToCMConfirmAppointment
                          ? `${renderDate(itemModel.SentDateEmailToCMConfirmAppointment, false, true)}`
                          : ''
                      } ${
                        itemModel.EmailToCMConfirmAppointmentByUser
                          ? ` - By ${this.renderStaffName(itemModel.EmailToCMConfirmAppointmentByUser)}`
                          : ''
                      }`}
                    </span>
                  </Checkbox>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <FieldUpload
                      uploadLoading={store.loadingUpload}
                      className="add-file-container posstion-IsTypist-label"
                      label={<span>Attached Files</span>}
                      attachments={store.renderAttachment(
                        itemModel.AssessmentAttachment,
                        LOCAL_CONSTANT.ReportType_AppointmentConfirmationCM,
                      )}
                      reportType={LOCAL_CONSTANT.ReportType_AppointmentConfirmationCM}
                      multiUpload={true}
                      disableDragDrop={true}
                    />
                  )}
                </>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <br />
                  <Checkbox
                    checked={itemModel.NotifyDoctorAppointmentConfirmed}
                    onChange={this.handleChangeCheckBox('NotifyDoctorAppointmentConfirmed')}
                    disabled={
                      !itemModel.IsAppointmentConfirmed ||
                      !!itemModel.SentDateEmailToDoctorConfirmAppointment ||
                      !store.specialist?.Email2
                    }
                  >
                    Specialist
                    <Popup
                      trigger={<IconSemantic name="info circle" style={styles.marginLeft5} />}
                      content="Appointment confirmation will be sent to Specialist (MAIL-78 for in-person, MAIL-40 for video assessment)"
                      position="top center"
                      wide="very"
                    />
                    <span style={styles.highlightSpan25}>
                      {`${
                        itemModel.SentDateEmailToDoctorConfirmAppointment
                          ? `${renderDate(itemModel.SentDateEmailToDoctorConfirmAppointment, false, true)}`
                          : ''
                      } ${
                        itemModel.EmailToDoctorConfirmAppointmentByUser
                          ? ` - By ${this.renderStaffName(itemModel.EmailToDoctorConfirmAppointmentByUser)}`
                          : ''
                      }`}
                    </span>
                  </Checkbox>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <FieldUpload
                      uploadLoading={store.loadingUpload}
                      className="add-file-container posstion-IsTypist-label"
                      label={<span>Attached Files</span>}
                      attachments={store.renderAttachment(
                        itemModel.AssessmentAttachment,
                        LOCAL_CONSTANT.ReportType_AppointmentConfirmationSpecialist,
                      )}
                      reportType={LOCAL_CONSTANT.ReportType_AppointmentConfirmationSpecialist}
                      multiUpload={true}
                      disableDragDrop={true}
                    />
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item className="fluid-field">
              <Dropdown
                disabled={
                  (!itemModel.SendDateNotifyClaimantConfirmedAppointment &&
                    !itemModel.SentDateEmailToCMConfirmAppointment &&
                    !itemModel.SentDateEmailToDoctorConfirmAppointment &&
                    !itemModel.SentDateInterpreterRqtoProvider &&
                    !itemModel.SentDateNotifyStaffPICAppointmentConfirmed &&
                    !itemModel.SendInterpretationDateTime) ||
                  lockTypist
                }
                overlay={
                  <Menu>
                    <Menu.Item
                      disabled={
                        !api.isAdminOrMagStaffOrAccounting() ||
                        !itemModel.SentDateNotifyStaffPICAppointmentConfirmed ||
                        itemModel.isLocked
                      }
                      onClick={() => this.handleResendNotifyAptConfrimToStaffPIC()}
                    >
                      To Staff PIC{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SendDateNotifyClaimantConfirmedAppointment || itemModel.isLocked}
                      onClick={() => this.handleResendNotify(0)}
                    >
                      To Claimant{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!itemModel.SentDateEmailToCMConfirmAppointment || !api.isAdminOrMagStaffOrAccounting()}
                      onClick={() => this.handleResendNotify(1)}
                    >
                      To Booking CM{' '}
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !itemModel.SentDateEmailToDoctorConfirmAppointment || !api.isAdminOrMagStaffOrAccounting()
                      }
                      onClick={() => this.handleResendNotify(2)}
                    >
                      To Specialist{' '}
                    </Menu.Item>
                    {itemModel.Id > 0 && !!itemModel.SentDateInterpreterRqtoProvider && (
                      <Menu.Item
                        disabled={
                          itemModel.InterpretationStatus !== 0 ||
                          LockCMDoctor ||
                          itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                          itemModel.Attendance === CONSTANTS.Attendance_LCF
                        }
                        onClick={() => this.handleClickReSendInterpreterRqtoProvider()}
                      >
                        To Interpreter Provider{' '}
                      </Menu.Item>
                    )}
                    {itemModel.Id > 0 && !!itemModel.SendInterpretationDateTime && (
                      <Menu.Item
                        disabled={
                          LockCMDoctor ||
                          itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                          itemModel.Attendance === CONSTANTS.Attendance_LCF
                        }
                        onClick={() => this.handleClickSendInterpretationEmail()}
                      >
                        To Interpreter{' '}
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button className="blue">
                  Resend Appointment Confirmation <Icon type="down" />
                </Button>
              </Dropdown>
            </Form.Item>
          </>
        )}
      </Form>
    );
  }
}

export default AppointmentDetailsAndCaseDocuments;
