import React from 'react';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import Modal from '../../shared/Modal/';
import ClientsStore from './ClientsStore';
import customFetch from '../../../utils/customFetch';

const datePickerFormat = 'DD/MM/YYYY';

class ModalExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      states: [],
      clientsuburb: [],
      clientpostcode: [],
      staffsuburb: [],
      staffpostcode: [],
      clientTypes1: '',
      clientTypes2: '',
      clientTypes3: '',
      clientTypes4: '',
      clientTypes5: '',
      clientTypes6: '',
      clientCityId: '',
      clientSuburb: '',
      clientPostcode: '',
      clientIncludePromotionsUnsubscribedClients: false,
      MAGDirect: false,
      caseManagerCityId: '',
      caseManagerCreatedDate: null,
      caseManagerPostcode: '',
      caseManagerState: '',
      caseManagerSuburb: '',
      caseManagerUnsubscribed: false,
    };
  }

  onCancel = () => {
    this.props.onCancel();
  };

  renderStateOptionsClient = () => {
    return toJS(this.state.states).map(opt => ({
      text: opt.name,
      value: opt.id,
    }));
  };

  renderStateOptionsCMs = () => {
    return toJS(this.state.states).map(opt => ({
      text: opt.name,
      value: opt.id,
    }));
  };

  renderClientSuburbOptions = () => {
    return toJS(this.state.clientsuburb).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderClientPostcodeOptions = () => {
    return toJS(this.state.clientpostcode).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderStaffSuburbOptions = () => {
    return toJS(this.state.staffsuburb).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderStaffPostcodeOptions = () => {
    return toJS(this.state.staffpostcode).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  handleChangeType = value => {
    this.setState({
      clientTypes: value,
    });
  };

  handleChangeCityId = (e, data) => {
    this.setState({
      clientCityId: data.value,
    });
  };

  handleClientSuburb = (e, data) => {
    this.setState({
      clientSuburb: data.value,
    });
  };

  handleClientPostcode = (e, data) => {
    this.setState({
      clientPostcode: data.value,
    });
  };

  handleCMsSuburb = (e, data) => {
    this.setState({
      caseManagerSuburb: data.value,
    });
  };

  handleCMsPostcode = (e, data) => {
    this.setState({
      caseManagerPostcode: data.value,
    });
  };

  onChangeCheckBoxFalse = name => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  onChangeCheckBox = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handlePickerChange = momentValue => {
    this.setState({
      ...this.state,
      caseManagerCreatedDate: momentValue ? momentValue.format('DDMMMMYYYY') : null,
    });
  };

  handleCMsState = (e, data) => {
    this.setState({
      caseManagerState: data.value,
    });
  };

  handleExport = () => {
    const clientTypes = [];
    if (this.state.clientTypes1) {
      clientTypes.push(this.state.clientTypes1);
    }
    if (this.state.clientTypes2) {
      clientTypes.push(this.state.clientTypes2);
    }
    if (this.state.clientTypes3) {
      clientTypes.push(this.state.clientTypes3);
    }
    if (this.state.clientTypes4) {
      clientTypes.push(this.state.clientTypes4);
    }
    if (this.state.clientTypes5) {
      clientTypes.push(this.state.clientTypes5);
    }
    if (this.state.clientTypes6) {
      clientTypes.push(this.state.clientTypes6);
    }
    const searchParams = Object.assign(
      {},
      {
        clientCityId: this.state.clientCityId,
        clientTypes: clientTypes,
        clientSuburb: this.state.clientSuburb,
        clientPostcode: this.state.clientPostcode,
        clientIncludePromotionsUnsubscribedClients: this.state.clientIncludePromotionsUnsubscribedClients,
        MAGDirect: this.state.MAGDirect,
        caseManagerCreatedDate: this.state.caseManagerCreatedDate,
        caseManagerPostcode: this.state.caseManagerPostcode,
        caseManagerState: this.state.caseManagerState,
        caseManagerSuburb: this.state.caseManagerSuburb,
        caseManagerCityId: this.state.caseManagerCityId,
        caseManagerUnsubscribed: this.state.caseManagerUnsubscribed,
      },
    );
    var qs = Object.entries(searchParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    var qs1 = clientTypes.map(val => `clientTypes=${val}`).join('&');
    window.open('/Report/ExportClientAndCm?' + qs + '&' + qs1 + '&forDownloading=true', '_blank');
  };

  async componentDidMount() {
    try {
      const statesPromise = customFetch('/api/city', {
        method: 'GET',
      });

      const clientsuburbPromise = customFetch('/api/clientsuburb', {
        method: 'GET',
      });

      const clientpostcodePromise = customFetch('/api/clientpostcode', {
        method: 'GET',
      });

      const staffsuburbPromise = customFetch('/api/staffsuburb', {
        method: 'GET',
      });

      const staffpostcodePromise = customFetch('/api/staffpostcode', {
        method: 'GET',
      });

      const [states, clientsuburb, clientpostcode, staffsuburb, staffpostcode] = await Promise.all([
        statesPromise,
        clientsuburbPromise,
        clientpostcodePromise,
        staffsuburbPromise,
        staffpostcodePromise,
      ]);
      this.setState({
        states: states,
        clientsuburb: clientsuburb,
        clientpostcode: clientpostcode,
        staffsuburb: staffsuburb,
        staffpostcode: staffpostcode,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { toggleModal, open } = ClientsStore;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={toggleModal(false)}
          title={`Export Clients and Case Managers`}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleExport}>
                Export
              </Button>
              <Button className="negative" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
        >
          <div className="Wrap-export-modal">
            <h2 className="Title-export">Fliter Clients by:</h2>
            <div className="Type-clients">
              <div className="Title">Type</div>
              <div className="Checkbox-clients ui checkbox">
                <Checkbox
                  label="Insurer"
                  onChange={
                    this.state.clientTypes1
                      ? () => this.onChangeCheckBoxFalse('clientTypes1')
                      : () => this.onChangeCheckBox('clientTypes1', '1')
                  }
                />
                <Checkbox
                  label="Self Insurer"
                  onChange={
                    this.state.clientTypes2
                      ? () => this.onChangeCheckBoxFalse('clientTypes2')
                      : () => this.onChangeCheckBox('clientTypes2', '6')
                  }
                />
                <Checkbox
                  label="Law Defendant"
                  onChange={
                    this.state.clientTypes3
                      ? () => this.onChangeCheckBoxFalse('clientTypes3')
                      : () => this.onChangeCheckBox('clientTypes3', '2')
                  }
                />
                <Checkbox
                  label="Law Plaintiff"
                  onChange={
                    this.state.clientTypes4
                      ? () => this.onChangeCheckBoxFalse('clientTypes4')
                      : () => this.onChangeCheckBox('clientTypes4', '3')
                  }
                />
                <Checkbox
                  label="Law Practice"
                  onChange={
                    this.state.clientTypes5
                      ? () => this.onChangeCheckBoxFalse('clientTypes5')
                      : () => this.onChangeCheckBox('clientTypes5', '4')
                  }
                />
                <Checkbox
                  label="Other"
                  onChange={
                    this.state.clientTypes6
                      ? () => this.onChangeCheckBoxFalse('clientTypes6')
                      : () => this.onChangeCheckBox('clientTypes5', '5')
                  }
                />
              </div>
            </div>

            <div className="Direct-clients">
              <div className="Title">MAG Direct</div>
              <div className="Checkbox-clients ui checkbox">
                <Checkbox
                  onChange={
                    this.state.MAGDirect
                      ? () => this.onChangeCheckBoxFalse('MAGDirect')
                      : () => this.onChangeCheckBox('MAGDirect', true)
                  }
                />
              </div>
            </div>

            <div className="Suburb-clients">
              <div className="Title">Suburb</div>
              <Dropdown
                clearable
                placeholder="Suburb"
                fluid
                search
                selection
                value={this.state.clientSuburb}
                className="Dropdown-suburb"
                onChange={this.handleClientSuburb}
                options={this.renderClientSuburbOptions()}
              />
            </div>

            <div className="Postcode-clients">
              <div className="Title">Postcode</div>
              <Dropdown
                clearable
                placeholder="Postcode"
                fluid
                search
                selection
                value={this.state.clientPostcode}
                onChange={this.handleClientPostcode}
                options={this.renderClientPostcodeOptions()}
                className="Dropdown-postcode"
              />
            </div>

            <div className="State-clients">
              <div className="Title">State</div>
              <Dropdown
                clearable
                placeholder="State"
                fluid
                search
                selection
                onChange={this.handleChangeCityId}
                className="Dropdown-state"
                options={this.renderStateOptionsClient()}
              />
            </div>

            <div className="Unsub-clients">
              <div className="Title">Include Unsubscribed Clients</div>
              <div className="Checkbox-clients ui checkbox">
                <Checkbox
                  onChange={
                    this.state.clientIncludePromotionsUnsubscribedClients
                      ? () => this.onChangeCheckBoxFalse('clientIncludePromotionsUnsubscribedClients')
                      : () => this.onChangeCheckBox('clientIncludePromotionsUnsubscribedClients', true)
                  }
                />
              </div>
            </div>

            <h2 className="Title-export">Filter Case Managers by:</h2>
            <div className="Data-cms">
              <div className="Title">Created Date From</div>
              <DatePicker
                className="Date-picker-cms"
                allowClear={true}
                format={datePickerFormat}
                onChange={this.handlePickerChange}
              />
            </div>

            <div className="Suburb-clients">
              <div className="Title">Suburb</div>
              <Dropdown
                clearable
                placeholder="Suburb"
                fluid
                search
                selection
                value={this.state.caseManagerSuburb}
                onChange={this.handleCMsSuburb}
                options={this.renderStaffSuburbOptions()}
                className="Dropdown-suburb"
              />
            </div>

            <div className="Postcode-clients">
              <div className="Title">Postcode</div>
              <Dropdown
                clearable
                placeholder="Postcode"
                fluid
                search
                selection
                value={this.state.caseManagerPostcode}
                onChange={this.handleCMsPostcode}
                options={this.renderStaffPostcodeOptions()}
                className="Dropdown-postcode"
              />
            </div>

            <div className="State-clients">
              <div className="Title">State</div>
              <Dropdown
                clearable
                placeholder="State"
                fluid
                search
                selection
                value={this.state.caseManagerState}
                onChange={this.handleCMsState}
                options={this.renderStateOptionsCMs()}
                className="Dropdown-state"
              />
            </div>

            <div className="Unsub-clients">
              <div className="Title">Include Unsubscribed CMs</div>
              <div className="Checkbox-clients ui checkbox">
                <Checkbox
                  onChange={
                    this.state.caseManagerUnsubscribed
                      ? () => this.onChangeCheckBoxFalse('caseManagerUnsubscribed')
                      : () => this.onChangeCheckBox('caseManagerUnsubscribed', true)
                  }
                />
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(ModalExport);
