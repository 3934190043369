import moment from 'moment';
import { formatDate } from '../../../utils/functions';

export const renderDate = (dateStr, forDatePicker = true, isShowTime = false) => {
  const format = isShowTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY';
  const formatForFormatDate = isShowTime ? 'DD MMM YYYY, HH:mm' : 'DD MMM, YYYY';
  if (dateStr) {
    if (typeof dateStr === 'string') {
      if (dateStr.startsWith('/Date')) {
        const date_ = formatDate(dateStr, isShowTime);
        return date_
          ? forDatePicker
            ? moment(date_, formatForFormatDate)
            : moment(date_, formatForFormatDate).format(format)
          : null;
      } else {
        return forDatePicker ? moment(dateStr) : moment(dateStr).format(format);
      }
    }
    return forDatePicker ? dateStr : dateStr.format(format);
  }
  return forDatePicker ? null : 'None';
};

export const renderDate2 = (dateStr, forDatePicker = true, isShowTime = false) => {
  const format = isShowTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY';
  const formatForFormatDate = isShowTime ? 'DD MMM YYYY, HH:mm' : 'DD MMM, YYYY';
  if (dateStr) {
    if (typeof dateStr === 'string') {
      if (dateStr.startsWith('/Date')) {
        const date_ = formatDate(dateStr, isShowTime);
        return date_
          ? forDatePicker
            ? moment(date_, formatForFormatDate)
            : moment(date_, formatForFormatDate).format(format)
          : null;
      } else {
        return forDatePicker ? moment(dateStr) : moment(dateStr).format(format);
      }
    }
    return forDatePicker ? dateStr : dateStr.format(format);
  }
  return forDatePicker ? null : '';
};

export const renderDateTimeForCC = (utcServerTime, datestr, showTime) => {
  const utcLocalMinutes = new Date().getTimezoneOffset();
  const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
  if (datestr) {
    if (datestr.search('/Date') !== -1) {
      const date = formatDate(datestr, true);
      return showTime
        ? moment(date, 'DD MMM YYYY, HH:mm')
            .add(addminutes, 'minutes')
            .format('DD/MM/YYYY - HH:mm')
        : moment(date, 'DD MMM YYYY, HH:mm')
            .add(addminutes, 'minutes')
            .format('DD/MM/YYYY');
    }
    return datestr;
  }
  return datestr;
};
