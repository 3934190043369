import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import IndustrySegmentsModal from './IndustrySegmentsModal';
import IndustrySegmentsStore from './IndustrySegmentsStore';
import IndustrySegmentsTable from './IndustrySegmentsTable';
import IndustrySegmentsToolbar from './IndustrySegmentsToolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class IndustrySegments extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/industry-segments-2', true);
      return;
    }

    IndustrySegmentsStore.fetchIndustrySegments(1);
  }

  render() {
    const { loading, data, open } = IndustrySegmentsStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <IndustrySegmentsToolbar />
            <IndustrySegmentsTable />
            {open && <IndustrySegmentsModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(IndustrySegments);
