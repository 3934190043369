import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ClinicalRecordStore from './ClinicalRecordStore';
import ModalCaseProgress from './ModalCaseProgress';
import ModalActionNotes from './ModalActionNotes';
import ModalConfirmNewUI from './ModalConfirmNewUI';

function ClinicalRecordModal() {
  const { modalType } = ClinicalRecordStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ClinicalRecordStore} onCancel={ClinicalRecordStore.toggleModal(false)} />;
  }
  if (modalType === `caseProgress`) {
    return <ModalCaseProgress />;
  }
  if (modalType === `notes`) {
    return <ModalActionNotes />;
  }
  if (modalType === 'newUI') {
    return <ModalConfirmNewUI onCancel={ClinicalRecordStore.toggleModal(false)} />;
  }
}

export default ClinicalRecordModal;
