import './index.scss';

import { observer } from 'mobx-react';
import React from 'react';

import Form from './Form';
import Modal from './Modal';
import router from '../../../stores/router';
import { debounce } from 'lodash';
import ui from '../../../stores/dashboard';
import TypeOfReportStore from './TypeOfReportStore';
import { Spin } from 'antd';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class TypeOfReport extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/add-edit-type-of-report-2', true);
      return;
    }
    this._searchDebounced();
    TypeOfReportStore.fetchDetail();
    ui.on('open(/view/add-edit-type-of-report-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search, state } = router.location;
    const Id = parseInt(search.slice(4));

    if (Id !== 0) {
      if (state) {
        const { record } = state;
        if (record) {
          TypeOfReportStore.setFieldsValue({
            dataReport: {
              id: Id,
              name: record.Name || '',
              descriptions: record.Description || '',
              isActive: record.IsActive,
              periodDays: record.ReportDueDay || 0,
              qualifiedAccreditations: record.Accreditations || [],
            },
          });
        }
      }
    } else {
      TypeOfReportStore.onReset();
    }
  }, 100);

  render() {
    const { open, loadingAcc, dataAcc } = TypeOfReportStore;

    return (
      <div className={`form-add-edit-${loadingAcc && !dataAcc ? 'loading' : 'container'}`}>
        {loadingAcc && !dataAcc ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Form />
            {open && <Modal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(TypeOfReport);
