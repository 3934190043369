import { observer } from 'mobx-react';
import { action } from 'mobx';
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import moment from 'moment-timezone';

import TableToolbar from '../../shared/tableToolbar';
import store from './store';
import { notification, Radio } from 'antd';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';
import { splitSortJoin } from '@components/dashboard-routes/SmartSearch/utils';
import { uniqBy } from 'lodash';

@observer
class Searchbar extends React.Component {
  renderClinicOptions = () => {
    return uniqBy([...store.clinics, store.selectedClinic], 'Id')
      .filter(i => !!i?.Id || !!i?.ID)
      .map(({ Id, ID, Name, FullAddress }) => ({
        key: Id ?? ID,
        text: Name,
        value: Id,
        content: (
          <div className="clinic-option">
            <div>{Name}</div>
            <div>{FullAddress}</div>
          </div>
        ),
      }));
  };

  searchDelay = () => {
    setTimeout(() => {
      store.handleSearch();
    }, 500);
  };

  renderDoctorOptions = () => {
    return uniqBy([...store.doctors, ...store.selectedDoctors, store.selectedDoctor], 'Id')
      .filter(i => !!i?.Id)
      .map(({ Title, Id, FullName, Specialty, RegisterOption, Qualifications }) => ({
        key: Id,
        text: FullName,
        value: Id,
        content: (
          <div>
            <div style={{ fontWeight: 'bold' }} className="text-bold">
              {Title || ''} {FullName}
            </div>
            <div>
              - Specialty: {splitSortJoin(Specialty)}
              {RegisterOption && ' ' + RegisterOption}
            </div>
            <div>- Qualifications: {splitSortJoin(Qualifications)}</div>
          </div>
        ),
      }));
  };

  @action handleChange = (_, { value, name }) => {
    if (name === 'start') {
      const { end } = store;
      if (!end) {
        return store.setFieldsValue({
          [name]: value,
          isReloadCalendar: true,
        });
      } else {
        if (value && moment(value, 'DD/MM/YYYY').isAfter(moment(end, 'DD/MM/YYYY'))) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'From-date must be before To-date',
          })();
        } else {
          return store.setFieldsValue({
            [name]: value,
            isReloadCalendar: true,
          });
        }
      }
    }
    if (name === 'end') {
      const { start } = store;
      if (!start) {
        return (store[name] = value || '');
      } else {
        if (value && moment(value, 'DD/MM/YYYY').isBefore(moment(start, 'DD/MM/YYYY'))) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'To-date must be after From-date',
          })();
        } else {
          return (store[name] = value || '');
        }
      }
    }
    if (name === 'doctorIds') {
      if (value.length > 5) {
        return;
      } else {
        store.specialistKeyword = '';
        const doctors = value
          .map(i => {
            const existDoctor = store.doctors.find(d => d.Id === i);
            return existDoctor;
          })
          .filter(doctor => !!doctor);
        store.setFieldsValue({ selectedDoctors: doctors });
        if (!value.length) {
          store.setFieldsValue({ doctorId: null });
        }
        this.searchDelay();
        return;
      }
    }
    if (name === 'doctorId') {
      const doctor = store.doctors.find(i => i.Id === value);
      store.setFieldsValue({ selectedDoctor: doctor, doctorId: value });
      store.specialistKeyword = '';
      this.searchDelay();
      return;
    }
    if (name === 'clinicId') {
      store.clinicKeyword = '';
      const clinic = store.clinics.find(i => i.Id === value);
      store.setFieldsValue({
        isReloadCalendar: store.calendarViewMode !== 'dayGridMonth',
        [name]: value || null,
        selectedClinic: clinic,
      });
      this.searchDelay();
      return store.calendarViewMode !== 'dayGridMonth' && this.searchDelay();
    }
    return (store[name] = value || 0);
  };

  SendUpdateClinicAvailabilityRequestToDoctor = () => {
    const { selectedClinic, selectedDoctor } = store;
    if (store.calendarViewMode !== 'dayGridMonth' && store.dayWeekViewType !== 'all') {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please switch to Month view to send the availability update request.',
      })();
      return;
    }

    if (!(selectedClinic && selectedClinic.Id > 0 && selectedDoctor && selectedDoctor.Id > 0)) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select a specialist and a clinic you want to send the availability update request.',
      })();
      return;
    } else {
      return store.toggleModal(true, {
        modalType: 'confirm',
        message: `Do you want to request a clinic availability update from Doctor ${selectedDoctor.FullName} for Clinic ${selectedClinic.Name}?`,
        onOk: () => {
          store.toggleModal(false, {})();
          customFetch('/MedicalDoctor/SendClinicAvailabilityRequest', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              doctorId: selectedDoctor.Id,
              clinicId: selectedClinic.Id,
            }),
          }).then(res => {
            if (res.status === 'success') {
              store.toggleModal(true, {
                modalType: 'confirm',
                message: `An email asking for clinic availability updates has been sent to Doctor ${selectedDoctor.FullName}`,
                hideWarningIcon: true,
                footerCloseButton: true,
              })();
            } else {
              if (res.error === 'no-session') {
                store.toggleModal(true, {
                  modalType: 'confirm',
                  message: `Doctor ${selectedDoctor.FullName} has never had a session in clinic ${selectedClinic.Name}`,
                })();
              } else {
                notification.destroy();
                notification.error({
                  message: 'Error',
                  description: 'Some error occurred. Please report this error to system administrator.',
                });
              }
            }
          });
        },
      })();
    }
  };

  EmailDoctorBookingDaysheets = () => {
    const { isHomeVisit, selectedClinic, selectedDoctor, start, end } = store;
    if (store.calendarViewMode !== 'dayGridMonth' && store.dayWeekViewType !== 'all') {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please switch to Month view to email booking daysheets to specialist.',
      })();
      return;
    }

    if ((!isHomeVisit && (!selectedClinic || selectedClinic.Id === 0)) || !selectedDoctor || selectedDoctor.Id === 0) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select a specialist and clinic to email booking daysheets to specialist.',
      })();
      return;
    }
    const yearFrom = moment(start, 'DD/MM/YYYY').year();
    const yearTo = moment(end, 'DD/MM/YYYY').year();

    if (yearFrom === yearTo) {
      if (moment(start, 'DD/MM/YYYY').isSameOrBefore(moment(end, 'DD/MM/YYYY'))) {
        if (Math.abs(moment(start, 'DD/MM/YYYY').diff(moment(end, 'DD/MM/YYYY'), 'days', true))) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Do you want to email booking daysheets of the selected dates to the specialist?',
            onOk: () => {
              store.toggleModal(false, {})();
              return customFetch('/MedicalService/EmailDoctorBookingDaysheets', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  doctorId: selectedDoctor.Id,
                  clinicId: selectedClinic.Id,
                  isHomeVisit: isHomeVisit,
                  fromDate: moment(start, 'DD/MM/YYYY').toISOString(),
                  toDate: moment(end, 'DD/MM/YYYY').toISOString(),
                }),
              })
                .then(res => {
                  if (res.status === 'success') {
                    notification.destroy();
                    notification.success({
                      message: 'Success',
                      description: `Sent to ${res.doctorEmail}.`,
                    });
                  } else {
                    notification.destroy();
                    notification.error({
                      message: 'Error',
                      description: res.message,
                    });
                  }
                })
                .catch(() => {});
            },
          })();
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please select from/to dates within 5 days',
          })();
        }
      } else {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'To date must be after From date',
        })();
      }
    } else {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Select from/to dates first',
      })();
    }
  };

  printDoctorDaySheet = doctor => {
    const { isHomeVisit, selectedClinic, start, end } = store;
    const yearFrom = moment(start, 'DD/MM/YYYY').year();
    const yearTo = moment(end, 'DD/MM/YYYY').year();

    if (moment(start, 'DD/MM/YYYY').isSame(moment(end, 'DD/MM/YYYY'))) {
      let openPrintDate = moment(start, 'DD/MM/YYYY');
      let strOpenPrintDate = openPrintDate.format('DDMMMMYYYY');

      window.open(
        '/MedicalService/DoctorDaySheetPrint?dId=' +
          doctor.Id +
          '&cId=' +
          selectedClinic.Id +
          '&printDate=' +
          strOpenPrintDate +
          '&isHomeVisit=' +
          isHomeVisit,
        '_blank',
      );
    } else {
      if (yearFrom === yearTo) {
        if (moment(start, 'DD/MM/YYYY').isSameOrBefore(moment(end, 'DD/MM/YYYY'))) {
          if (Math.abs(moment(start, 'DD/MM/YYYY').diff(moment(end, 'DD/MM/YYYY'), 'days', true)) <= 5) {
            for (
              var i = 1;
              i <= Math.abs(moment(start, 'DD/MM/YYYY').diff(moment(end, 'DD/MM/YYYY'), 'days', true));
              i++
            ) {
              let openPrintDate = i > 1 ? moment(start, 'DD/MM/YYYY').add(i - 1, 'days') : moment(start, 'DD/MM/YYYY');
              let strOpenPrintDate = openPrintDate.format('DDMMMMYYYY');
              window.open(
                '/MedicalService/DoctorDaySheetPrint?dId=' +
                  doctor.Id +
                  '&cId=' +
                  selectedClinic.Id +
                  '&printDate=' +
                  strOpenPrintDate +
                  '&isHomeVisit=' +
                  isHomeVisit,
                '_blank',
              );
            }
          } else {
            store.toggleModal(true, {
              modalType: 'confirm',
              message: 'Please select from/to dates within 5 days',
            })();
          }
        } else {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'To date must be after From date',
          })();
        }
      } else {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Select from/to dates first',
        })();
      }
    }
  };

  PrintDoctorBookingDaysheet = () => {
    const { isHomeVisit, selectedClinic, selectedDoctor, selectedDoctors } = store;
    if (store.calendarViewMode !== 'dayGridMonth') {
      if (
        !isHomeVisit &&
        (!selectedClinic || selectedClinic?.Id === 0) &&
        selectedDoctors.length !== 1 &&
        !selectedDoctor.Id
      ) {
        return store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select a specialist and clinic to view booking daysheets',
        })();
      }
      return this.printDoctorDaySheet(selectedDoctors[0] || selectedDoctor);
    } else {
      if (
        (!isHomeVisit && (!selectedClinic || selectedClinic?.Id === 0)) ||
        !selectedDoctor ||
        selectedDoctor.Id === 0
      ) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select a specialist and clinic to view booking daysheets',
        })();
        return;
      }
      return this.printDoctorDaySheet(selectedDoctor);
    }
  };

  handleSearchChange = (_, { searchQuery }) => {
    store.setFieldsValue({ specialistKeyword: searchQuery });
  };

  handleClinicSearchChange = (_, { searchQuery }) => {
    store.setFieldsValue({ clinicKeyword: searchQuery });
  };

  handleScroll = event => {
    const target = event.target;
    const number = store.specialistNumber;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      store.setFieldsValue({ specialistNumber: number + 30 });
    }
  };

  handleClinicScroll = event => {
    const target = event.target;
    const number = store.clinicNumber;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      store.setFieldsValue({ clinicNumber: number + 30 });
    }
  };

  render() {
    const {
      loading,
      start,
      end,
      selectedClinic,
      selectedDoctor,
      loadingClinics,
      loadingSpecialists,
      specialistKeyword,
      clinicKeyword,
      selectedDoctors,
    } = store;

    return (
      <React.Fragment>
        <TableToolbar.Container className="searchbar">
          <TableToolbar.Left className="filter-input">
            {!api.isDoctor() && (
              <div className="searchbar__group-items">
                <label
                  style={{
                    marginRight: '5px',
                    fontWeight: 'bold',
                    width: '75px',
                  }}
                >
                  Specialist:
                </label>
                {store.calendarViewMode === 'dayGridMonth' || store.dayWeekViewType === 'all' ? (
                  <TableToolbar.SearchDropdown
                    search={opt => {
                      return opt;
                    }}
                    fluid
                    selectOnNavigation={false}
                    selectOnBlur={false}
                    name="doctorId"
                    selection
                    onChange={this.handleChange}
                    value={
                      !!selectedDoctor ? `${selectedDoctor?.FullName || ''} - ${selectedDoctor?.Email2 || ''}` : ''
                    }
                    className="Toolbar-input input--overridden-style"
                    style={selectedDoctor ? { height: '50px' } : {}}
                    options={this.renderDoctorOptions()}
                    loading={loading || loadingSpecialists}
                    disabled={loading || loadingSpecialists}
                    clearable
                    onScroll={this.handleScroll}
                    onBlur={() => store.setFieldsValue({ specialistKeyword: '' })}
                    searchQuery={specialistKeyword}
                    onSearchChange={this.handleSearchChange}
                    icon={!selectedDoctor ? <Icon name="dropdown" /> : <Icon name="delete" />}
                  />
                ) : (
                  <TableToolbar.SearchDropdown
                    search
                    fluid
                    selectOnNavigation={false}
                    selectOnBlur={false}
                    name="doctorIds"
                    selection
                    value={selectedDoctors.map(i => i.Id)}
                    multiple
                    style={{ height: '100%' }}
                    onChange={this.handleChange}
                    className="Toolbar-input input--overridden-style"
                    options={this.renderDoctorOptions()}
                    loading={loading || loadingSpecialists}
                    disabled={loading || loadingSpecialists}
                    clearable
                    onScroll={this.handleScroll}
                    searchQuery={specialistKeyword}
                    onSearchChange={this.handleSearchChange}
                    searchPlaceHolder="Select specialists to show"
                    icon={!selectedDoctors.length ? <Icon name="dropdown" /> : <Icon name="delete" />}
                  />
                )}
              </div>
            )}
            <div className="searchbar__group-items">
              <label
                style={
                  api.isDoctor()
                    ? {
                        marginRight: '5px',
                        fontWeight: 'bold',
                        width: '50px',
                      }
                    : {
                        marginRight: '5px',
                        marginLeft: '15px',
                        fontWeight: 'bold',
                        width: '50px',
                      }
                }
              >
                Clinic:
              </label>
              <TableToolbar.SearchDropdown
                fluid
                selection
                name="clinicId"
                value={!selectedClinic ? '' : `${selectedClinic?.Name || ''} - ${selectedClinic?.FullAddress || ''}`}
                className="Toolbar-input"
                options={this.renderClinicOptions()}
                loading={loading || loadingClinics}
                selectOnNavigation={false}
                selectOnBlur={false}
                style={selectedClinic ? { height: '50px' } : {}}
                clearable
                onBlur={() => store.setFieldsValue({ clinicKeyword: '' })}
                icon={!selectedClinic ? <Icon name="dropdown" /> : <Icon name="delete" />}
                disabled={loading || loadingClinics}
                onChange={this.handleChange}
                searchQuery={clinicKeyword}
                onSearchChange={this.handleClinicSearchChange}
                onScroll={this.handleClinicScroll}
                search={options => {
                  return options;
                }}
              />
            </div>
            <div className="searchbar__group-items">
              <TableToolbar.HighLightButton
                className="blue px-2"
                onClick={() => {
                  return store.handleSearch();
                }}
                disabled={loading}
              >
                Search
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                style={{
                  marginLeft: '5px',
                }}
                className="negative px-2"
                onClick={() => {
                  store.clearStore(false);
                }}
                disabled={loading}
              >
                Clear
              </TableToolbar.HighLightButton>
              {!api.isCaseManager() && !api.isOnlySearch() && (
                <TableToolbar.HighLightButton
                  className="positive button-toolbar"
                  onClick={() => store.toggleModal(true, { modalType: 'editSession', id: null })()}
                  disabled={loading}
                  style={{
                    marginLeft: '5px',
                  }}
                >
                  <Icon name="plus" /> New Session
                </TableToolbar.HighLightButton>
              )}
              {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && !api.isOnlySearch() && (
                <TableToolbar.HighLightButton
                  className="positive button-toolbar"
                  onClick={() => store.toggleModal(true, { modalType: 'export' })()}
                  disabled={loading}
                  style={{
                    marginLeft: '5px',
                  }}
                >
                  Specialist Availability & Daysheets
                </TableToolbar.HighLightButton>
              )}
            </div>
          </TableToolbar.Left>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default Searchbar;
