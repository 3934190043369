import React from 'react';
import { observer } from 'mobx-react';
import { notification, Select, DatePicker, InputNumber } from 'antd';
import { Button, Icon, Dimmer, Form, Input, Loader, Radio, Segment, Dropdown } from 'semantic-ui-react';

import { renderDate } from '../Shared';
import * as api from '../../../stores/api';
import mainStore from '../../admin-routes/FundingRequest/Store';
import store from './Store';
import DragDropFile from '../Shared/DragDropComponent';
import {
  saveData,
  getStatusHistory,
  getFundingBorrower,
  getApplicantByBorrower,
} from '../../admin-routes/FundingRequest/Service';
import dashboardStore from '@stores/dashboard';
import { action, toJS, observable } from 'mobx';
import { Link } from 'react-router-dom';
import Validator from 'validatorjs';
import StatusHistory from './StatusHistory';
import { convertPhoneFaxNumber, convertMobileNumber } from '@utils/functions';

@observer
class FormAddEdit extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};

  state = {
    canEdit: api.isAdmin(),
  };

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };

  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  handleChange = (_, { name, value }) => {
    const { fundingReqInfo } = store;
    store.setFieldsValue({
      fundingReqInfo: { ...fundingReqInfo, [name]: value },
    });
  };

  handleChangeActive = () => {
    const { fundingReqInfo } = store;
    const { isActive } = fundingReqInfo;
    store.setFieldsValue({
      fundingReqInfo: { ...fundingReqInfo, isActive: !isActive },
    });
  };

  dragdropUploadFile = (fileList, reportType) => {
    const { fundingReqInfo, lstFileAttachments } = store;
    const attachment = {
      FileName: fileList[0].name,
      Title: fileList[0].title,
      NumberOfPages: fileList[0].numberOfPages,
      Type: 2,
      CaseId: fundingReqInfo.Id,
      AttachmentType: reportType,
      Id: 0,
    };
    const newAttachments = [...lstFileAttachments, attachment].map(i => ({
      ...i,
      key: i.FileName,
    }));
    store.setFileAttachment(newAttachments);
  };

  dragdropDeleteFile = key => {
    const { lstFileAttachments } = store;
    const newAttachments = lstFileAttachments.filter(i => i.key !== key);
    store.setFileAttachment(newAttachments);
  };

  renderAttachment = (Attachments = [], attachmentType) => {
    return Attachments.filter(i => i.AttachmentType === attachmentType);
  };

  handSave = async sentRq => {
    const { fundingReqInfo, lstManagementTeams, lstFileAttachments } = store;
    var saveDataObj = { ...fundingReqInfo };
    //console.log(saveDataObj);
    saveDataObj.SentRqToProvider = sentRq;
    if (lstManagementTeams && lstManagementTeams.length > 0) {
      saveDataObj.ManagementTeams = lstManagementTeams;
    } else {
      saveDataObj.ManagementTeams = [];
    }
    if (lstFileAttachments && lstFileAttachments.length > 0) {
      saveDataObj.Attachments = lstFileAttachments;
    } else {
      saveDataObj.Attachments = [];
    }
    // if(saveDataObj.FundingRequestStatus === 4){
    //   return store.toggleModal(true, {
    //     modalType: 'confirm',
    //     message:
    //       'Do you want to generate a Facility agreement now?',
    //     onOk: () => {
    //       saveDataObj.CreateRequestAgreement = true;
    //       this.execSaveData(saveDataObj);
    //     },
    //     onCancel: () => {
    //       saveDataObj.CreateRequestAgreement = false;
    //       this.execSaveData(saveDataObj);
    //     }
    //   })();
    // }else{
    //   saveDataObj.CreateRequestAgreement = false;
    //   this.execSaveData(saveDataObj);
    // }
    this.execSaveData(saveDataObj);
  };

  execSaveData = saveDataObj => {
    saveData(saveDataObj).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
        });
        var record = res.model;
        mainStore.refetch({});
        localStorage.setItem('FundingRequest', record.RequestNo + ' - Request');
        dashboardStore.close('/view/add-edit-funding-request');
        return setTimeout(() => {
          dashboardStore.open(`/view/add-edit-funding-request?id=${record.Id}`);
        });
        // const { fundingReqInfo } = store;
        // store.setFieldsValue({
        //   fundingReqInfo: { ...fundingReqInfo, FundingRequestStatus: res.model.FundingRequestStatus },
        // });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again.',
        });
      }
    });
  };

  handleClose = () => {
    window.closeCurrentTab();
    store.onReset();
  };
  onClose = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'All unsaved changes will be discarded. Do you want to continue?',
      onOk: () => this.handleClose(),
    })();
  };

  onAddManagementTeam = () => {
    const { fundingReqInfo, lstManagementTeams } = store;
    var listItems = toJS(lstManagementTeams);
    listItems.push({
      Id: 0,
      Idx: listItems.length + 1,
      FundingRequestId: fundingReqInfo.Id,
      Title: null,
      FullName: null,
      Phone: null,
      Email: null,
      LinkIn: null,
    });
    store.setManagementTeams(listItems);
  };

  deleteManagementTeam = idx => {
    const { fundingReqInfo, lstManagementTeams } = store;
    var oldArr = lstManagementTeams;
    var listItems = [];
    var countIdx = 1;
    oldArr.forEach(el => {
      if (el.Idx !== idx) {
        listItems.push({
          Id: 0,
          Idx: listItems.length + 1,
          FundingRequestId: fundingReqInfo.Id,
          Title: el.Title,
          FullName: el.FullName,
          Phone: el.Phone,
          Email: el.Email,
          LinkIn: el.LinkIn,
        });
        countIdx++;
      }
    });
    store.setManagementTeams(listItems);
  };

  handleChangeFieldTeam = (idx, field) =>
    action(event => {
      var value = event ? (event.target ? event.target.value : event) : null;
      if (field === 'Phone') value = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      const { lstManagementTeams } = store;
      var currentItem = lstManagementTeams.find(i => i.Idx === idx);
      if (currentItem) {
        currentItem[field] = value;
      }
    });

  handleShowHistory = id => {
    if (id) {
      getStatusHistory(id).then(resp => {
        if (resp.status === 'success') {
          var data = resp.itemList;
          this.handleOpenConfirm(true, {
            modalType: 'confirm',
            data: data,
            onCancel: () => {
              this.handleCancelConfirm();
            },
          });
        }
      });
    }
  };

  validateEmail = emailStr => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    }
    return null;
  };

  validatePhone = phoneStr => {
    if (phoneStr) {
      const validation = new Validator(
        { phone: phoneStr },
        { phone: 'max:10' },
        'Must be less than or equal to 10 digits',
      );
      if (validation.passes()) {
        return null;
      }
      return 'Must be less than or equal to 10 digits';
    }
    return null;
  };

  @action handleSearchBorrower = () =>
    action(event => {
      const value = event;
      if (value.length > 2) {
        getFundingBorrower(value).then(
          action(resp => {
            store.setListData('arrBorrower', resp.itemList || []);
          }),
        );
      }
    });

  @action handleSearchApplicant = () =>
    action(event => {
      const { fundingReqInfo } = store;
      const value = event;
      if (value.length > 2) {
        getApplicantByBorrower(value, fundingReqInfo.Borrower).then(
          action(resp => {
            store.setListData('arrApplicant', resp.itemList || []);
          }),
        );
      }
    });

  render() {
    const { fundingReqInfo, arrBorrower, arrApplicant, lstManagementTeams, lstFileAttachments } = store;
    const data = fundingReqInfo;
    const { canEdit } = this.state;
    const dateFormat = 'DD/MM/YYYY';

    return (
      <Segment size="massive">
        <Dimmer>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Facility Application No</label>
              <Input
                size="large"
                disabled={true}
                value={data.RequestNo ?? ''}
                onChange={store.handleFieldChange('RequestNo')}
              />
            </Form.Field>
            <Form.Field required>
              <label>Application Date</label>
              <React.Fragment>
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  value={renderDate(data.RequestDate)}
                  format={dateFormat}
                  name="RequestDate"
                  onChange={store.handleFieldChange('RequestDate')}
                />
                {!data.RequestDate && <div style={{ width: '100%', color: 'red' }}>This field is required</div>}
              </React.Fragment>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Borrower</label>
              <React.Fragment>
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={data.Borrower}
                  onChange={store.handleFieldChange('Borrower')}
                  onSearch={this.handleSearchBorrower()}
                  showSearch
                  filterOption="children"
                >
                  {arrBorrower && data.Borrower && !arrBorrower.find(i => i.Id === data.Borrower) && (
                    <Select.Option style={{ display: 'none' }} value={data.Borrower}>
                      {data.BorrowerObj.Id} - {data.BorrowerObj.Name}
                    </Select.Option>
                  )}
                  {arrBorrower &&
                    arrBorrower.map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.Id} - {i.Name}
                      </Select.Option>
                    ))}
                </Select>
                {!data.Borrower && <div style={{ width: '100%', color: 'red' }}>This field is required</div>}
              </React.Fragment>
            </Form.Field>
            <Form.Field required>
              <label>Applicant</label>
              <React.Fragment>
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={data.Applicant}
                  onChange={store.handleFieldChange('Applicant')}
                  onSearch={this.handleSearchApplicant()}
                  showSearch
                  filterOption="children"
                >
                  {arrApplicant && data.Applicant && !arrApplicant.find(i => i.Id === data.Applicant) && (
                    <Select.Option style={{ display: 'none' }} value={data.Applicant}>
                      {data.ApplicantObj.Id} - {data.ApplicantObj.FullName}
                    </Select.Option>
                  )}
                  {arrApplicant &&
                    arrApplicant.map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.Id} - {i.FullName}
                      </Select.Option>
                    ))}
                </Select>
                {!data.Applicant && <div style={{ width: '100%', color: 'red' }}>This field is required</div>}
              </React.Fragment>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Management Team</label>
            <React.Fragment>
              <table className="ui celled sortable striped table">
                <thead className="">
                  <tr>
                    <th className="cursor-default">Job Title</th>
                    <th className="cursor-default">Full Name</th>
                    <th className="cursor-default">Phone</th>
                    <th className="cursor-default">Email</th>
                    <th className="cursor-default">LinkedIn</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!!toJS(lstManagementTeams) &&
                    toJS(lstManagementTeams).map(team => {
                      return (
                        <tr key={'team' + team.Idx}>
                          <td>
                            <Input
                              style={{ width: '100%' }}
                              type="text"
                              value={team.Title}
                              onChange={this.handleChangeFieldTeam(team.Idx, 'Title')}
                            />
                          </td>
                          <td>
                            <Input
                              style={{ width: '100%' }}
                              type="text"
                              value={team.FullName}
                              onChange={this.handleChangeFieldTeam(team.Idx, 'FullName')}
                            />
                          </td>
                          <td>
                            <Input
                              style={{ width: '100%' }}
                              type="text"
                              value={convertPhoneFaxNumber(team.Phone)}
                              onChange={this.handleChangeFieldTeam(team.Idx, 'Phone')}
                            />
                            {this.validatePhone(team.Phone) && (
                              <span className="invalid-label-eror-doctor-details">
                                {this.validatePhone(team.Phone)}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              style={{ width: '100%' }}
                              type="text"
                              value={team.Email}
                              onChange={this.handleChangeFieldTeam(team.Idx, 'Email')}
                            />
                            {this.validateEmail(team.Email) && (
                              <span className="invalid-label-eror-doctor-details">
                                {this.validateEmail(team.Email)}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              style={{ width: '100%' }}
                              type="text"
                              value={team.LinkIn}
                              onChange={this.handleChangeFieldTeam(team.Idx, 'LinkIn')}
                            />
                          </td>
                          <td>
                            <Link to="#" onClick={() => this.deleteManagementTeam(team.Idx)}>
                              <span style={{ color: 'red' }}>X</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Button color="green" onClick={this.onAddManagementTeam}>
                <Icon name="plus" /> Add Members
              </Button>{' '}
            </React.Fragment>
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Number of Personal Injury Lawyers</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfPersonInjuryLawyer"
                value={data.NumberOfPersonInjuryLawyer}
                onChange={store.handleFieldChange('NumberOfPersonInjuryLawyer')}
              />
            </Form.Field>
            <Form.Field>
              <label>Number of Accredited Specialist Personal Injury Lawyers</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfAccreditedSpecialistPersonIL"
                value={data.NumberOfAccreditedSpecialistPersonIL}
                onChange={store.handleFieldChange('NumberOfAccreditedSpecialistPersonIL')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Number of Other Lawyers</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfOtherLawyer"
                value={data.NumberOfOtherLawyer}
                onChange={store.handleFieldChange('NumberOfOtherLawyer')}
              />
            </Form.Field>
            <Form.Field>
              <label>Number of PAs</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfPAs"
                value={data.NumberOfPAs}
                onChange={store.handleFieldChange('NumberOfPAs')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Number of Support Staff</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfSupportStaff"
                value={data.NumberOfSupportStaff}
                onChange={store.handleFieldChange('NumberOfSupportStaff')}
              />
            </Form.Field>
            <Form.Field>
              <label>Number of Current Open Matters</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="NumberOfCurrentOpenMatters"
                value={data.NumberOfCurrentOpenMatters}
                onChange={store.handleFieldChange('NumberOfCurrentOpenMatters')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Average Number of Matters per Lawyer</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="AvgNumberOfMattersPerLawyer"
                value={data.AvgNumberOfMattersPerLawyer}
                onChange={store.handleFieldChange('AvgNumberOfMattersPerLawyer')}
              />
            </Form.Field>
            <Form.Field>
              <label>Average Practicing Experience per Lawyer</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="AvgPracticingExperiencePerLawyer"
                value={data.AvgPracticingExperiencePerLawyer}
                onChange={store.handleFieldChange('AvgPracticingExperiencePerLawyer')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label className="Form-Field-label">Growth Status Last 3 Years</label>
              <Radio
                label="Stable "
                className="Form-field-radio"
                name="GrowthStatus"
                value={0}
                checked={data.GrowthStatus === 0}
                onChange={store.handleChangeFieldValue}
              />
              <React.Fragment>
                <Radio
                  label="Growing"
                  className="Form-field-radio"
                  name="GrowthStatus"
                  value={1}
                  checked={data.GrowthStatus === 1}
                  onChange={store.handleChangeFieldValue}
                />
                <InputNumber
                  style={{ width: '350px' }}
                  size="large"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  name="GrowthPerAnmun"
                  value={data.GrowthPerAnmun}
                  onChange={store.handleFieldChange('GrowthPerAnmun')}
                />{' '}
                (% per annum)
              </React.Fragment>
              <React.Fragment>
                <Radio
                  style={{ marginLeft: '50px' }}
                  label="Contracting"
                  className="Form-field-radio"
                  name="GrowthStatus"
                  value={2}
                  checked={data.GrowthStatus === 2}
                  onChange={store.handleChangeFieldValue}
                />
                <InputNumber
                  style={{ width: '350px' }}
                  size="large"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  name="ContractingPerAnmun"
                  value={data.ContractingPerAnmun}
                  onChange={store.handleFieldChange('ContractingPerAnmun')}
                />{' '}
                (% per annum)
              </React.Fragment>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Success Rate last 3 Years</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="SuccessRateLast3Year"
                value={data.SuccessRateLast3Year}
                onChange={store.handleFieldChange('SuccessRateLast3Year')}
              />
            </Form.Field>
            <Form.Field>
              <label>Average WIP per Matter</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="AvgWIPPerMatter"
                value={data.AvgWIPPerMatter}
                onChange={store.handleFieldChange('AvgWIPPerMatter')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Annual Write-Off Amount Last 3 Years</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="AnnualWriteOfAmountLast3Year"
                value={data.AnnualWriteOfAmountLast3Year}
                onChange={store.handleFieldChange('AnnualWriteOfAmountLast3Year')}
              />
            </Form.Field>
            <Form.Field>
              <span></span>
            </Form.Field>
          </Form.Group>
          <div>
            <label style={{ fontWeight: 'bold' }} className="Form-Field-label">
              Practice Areas in Personal Injury(%)
            </label>
          </div>
          <Form.Group widths="equal">
            <Form.Field>
              <label>In Motor Vehicle Accidents</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="InMotorVehicleAccidents"
                value={data.InMotorVehicleAccidents}
                onChange={store.handleFieldChange('InMotorVehicleAccidents')}
              />
            </Form.Field>
            <Form.Field>
              <label>In Worker Compensation</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="InWorkerCompensation"
                value={data.InWorkerCompensation}
                onChange={store.handleFieldChange('InWorkerCompensation')}
              />
            </Form.Field>
            <Form.Field>
              <label>In Medical Negligence</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="InMedicalNegligence"
                value={data.InMedicalNegligence}
                onChange={store.handleFieldChange('InMedicalNegligence')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>In Liability</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="InLiability"
                value={data.InLiability}
                onChange={store.handleFieldChange('InLiability')}
              />
            </Form.Field>
            <Form.Field>
              <label>In Class Action</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="ClassAction"
                value={data.ClassAction}
                onChange={store.handleFieldChange('ClassAction')}
              />
            </Form.Field>
            <Form.Field>
              <label>Others</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="PracticeAreaOther"
                value={data.PracticeAreaOther}
                onChange={store.handleFieldChange('PracticeAreaOther')}
              />
            </Form.Field>
          </Form.Group>
          <div>
            <label style={{ fontWeight: 'bold' }} className="Form-Field-label">
              Select a Lender
            </label>
          </div>
          <Form.Field>
            <Dropdown
              placeholder="Select a Lender"
              selection
              name="FundingProvider"
              options={store.dataFundingProvider}
              value={data.FundingProvider}
              onChange={store.handleChangeFieldValue}
            />
          </Form.Field>
          {/* <div>
            <label style={{ fontWeight: 'bold' }} className="Form-Field-label">
              Application Monthly Facility Amount for Next 24 Months
            </label>
          </div>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 1</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth1"
                value={data.FundingAmountMonth1}
                onChange={store.handleFieldChange('FundingAmountMonth1')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 7</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth7"
                value={data.FundingAmountMonth7}
                onChange={store.handleFieldChange('FundingAmountMonth7')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 13</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth13"
                value={data.FundingAmountMonth13}
                onChange={store.handleFieldChange('FundingAmountMonth13')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 19</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth19"
                value={data.FundingAmountMonth19}
                onChange={store.handleFieldChange('FundingAmountMonth19')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 2</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth2"
                value={data.FundingAmountMonth2}
                onChange={store.handleFieldChange('FundingAmountMonth2')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 8</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth8"
                value={data.FundingAmountMonth8}
                onChange={store.handleFieldChange('FundingAmountMonth8')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 14</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth14"
                value={data.FundingAmountMonth14}
                onChange={store.handleFieldChange('FundingAmountMonth14')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 20</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth20"
                value={data.FundingAmountMonth20}
                onChange={store.handleFieldChange('FundingAmountMonth20')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 3</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth3"
                value={data.FundingAmountMonth3}
                onChange={store.handleFieldChange('FundingAmountMonth3')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 9</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth9"
                value={data.FundingAmountMonth9}
                onChange={store.handleFieldChange('FundingAmountMonth9')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 15</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth15"
                value={data.FundingAmountMonth15}
                onChange={store.handleFieldChange('FundingAmountMonth15')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 21</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth21"
                value={data.FundingAmountMonth21}
                onChange={store.handleFieldChange('FundingAmountMonth21')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 4</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth4"
                value={data.FundingAmountMonth4}
                onChange={store.handleFieldChange('FundingAmountMonth4')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 10</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth10"
                value={data.FundingAmountMonth10}
                onChange={store.handleFieldChange('FundingAmountMonth10')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 16</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth16"
                value={data.FundingAmountMonth16}
                onChange={store.handleFieldChange('FundingAmountMonth16')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 22</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth22"
                value={data.FundingAmountMonth22}
                onChange={store.handleFieldChange('FundingAmountMonth22')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 5</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth5"
                value={data.FundingAmountMonth5}
                onChange={store.handleFieldChange('FundingAmountMonth5')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 11</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth11"
                value={data.FundingAmountMonth11}
                onChange={store.handleFieldChange('FundingAmountMonth11')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 17</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth17"
                value={data.FundingAmountMonth17}
                onChange={store.handleFieldChange('FundingAmountMonth17')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 23</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth23"
                value={data.FundingAmountMonth23}
                onChange={store.handleFieldChange('FundingAmountMonth23')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Month 6</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth6"
                value={data.FundingAmountMonth6}
                onChange={store.handleFieldChange('FundingAmountMonth6')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 12</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth12"
                value={data.FundingAmountMonth12}
                onChange={store.handleFieldChange('FundingAmountMonth12')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 18</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth18"
                value={data.FundingAmountMonth18}
                onChange={store.handleFieldChange('FundingAmountMonth18')}
              />
            </Form.Field>
            <Form.Field>
              <label>Month 24</label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="FundingAmountMonth24"
                value={data.FundingAmountMonth24}
                onChange={store.handleFieldChange('FundingAmountMonth24')}
              />
            </Form.Field>
          </Form.Group> */}

          <Form.Group widths="equal">
            <Form.Field>
              <label>
                <React.Fragment>
                  {/* <div style={{ width: '100%' }}>
                    <label style={{}} className="Form-Field-label">
                      &nbsp;
                    </label>
                  </div> */}
                  Borrowing Facility Amount
                </React.Fragment>
              </label>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                name="BorrowingFacilityAmount"
                value={data.BorrowingFacilityAmount}
                onChange={store.handleFieldChange('BorrowingFacilityAmount')}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <React.Fragment>Facility Duration From</React.Fragment>
              </label>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                placeholder="From"
                value={renderDate(data.FundingPeriodFrom)}
                format={dateFormat}
                name="FundingPeriodFrom"
                onChange={store.handleFieldChange('FundingPeriodFrom')}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <React.Fragment>Facility Duration To</React.Fragment>
              </label>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                placeholder="To"
                value={renderDate(data.FundingPeriodTo)}
                format={dateFormat}
                name="FundingPeriodTo"
                onChange={store.handleFieldChange('FundingPeriodTo')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label className="Form-Field-label">Facility Application Status</label>
              <Radio
                label="Pending"
                className="Form-field-radio"
                name="FundingRequestStatus"
                value={1}
                checked={data.FundingRequestStatus === 1}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Sent"
                className="Form-field-radio"
                name="FundingRequestStatus"
                value={2}
                checked={data.FundingRequestStatus === 2}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Under Review"
                className="Form-field-radio"
                name="FundingRequestStatus"
                value={3}
                checked={data.FundingRequestStatus === 3}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Approved"
                className="Form-field-radio"
                name="FundingRequestStatus"
                value={4}
                checked={data.FundingRequestStatus === 4}
                onChange={store.handleChangeFieldValue}
              />
              <Radio
                label="Rejected"
                className="Form-field-radio"
                name="FundingRequestStatus"
                value={5}
                checked={data.FundingRequestStatus === 5}
                onChange={store.handleChangeFieldValue}
              />
            </Form.Field>
          </Form.Group>
          {data.FundingRequestStatus === 1 && (
            <Form.Field>
              <label>
                Facility Status Date{' '}
                <Link to="#" onClick={() => this.handleShowHistory(data.Id)}>
                  View histories
                </Link>
              </label>
              <DatePicker
                style={{ float: 'left' }}
                size="large"
                placeholder="Status Date"
                value={renderDate(data.FundingStatusDatePending)}
                format={dateFormat}
                name="FundingStatusDatePending"
                onChange={store.handleFieldChange('FundingStatusDatePending')}
              />
              <span style={{ display: 'block', lineHeight: '43px', float: 'left', marginLeft: '10px' }}>
                {data.LatestFundingStatusDate ? data.LatestFundingStatusDate.format('DD/MM/YYYY @ HH:mm') : ''}
                {data.FundingStatusUpdatedBy ? ' by ' + data.FundingStatusUpdatedBy : ''}
              </span>
            </Form.Field>
          )}
          {data.FundingRequestStatus === 2 && (
            <Form.Field>
              <label>
                Facility Status Date{' '}
                <Link to="#" onClick={() => this.handleShowHistory(data.Id)}>
                  View histories
                </Link>
              </label>
              <DatePicker
                style={{ float: 'left' }}
                size="large"
                placeholder="Status Date"
                value={renderDate(data.FundingStatusDateSent)}
                format={dateFormat}
                name="FundingStatusDateSent"
                onChange={store.handleFieldChange('FundingStatusDateSent')}
              />
              <span style={{ display: 'block', lineHeight: '43px', float: 'left', marginLeft: '10px' }}>
                {data.LatestFundingStatusDate ? data.LatestFundingStatusDate.format('DD/MM/YYYY @ HH:mm') : ''}
                {data.FundingStatusUpdatedBy ? ' by ' + data.FundingStatusUpdatedBy : ''}
              </span>
            </Form.Field>
          )}
          {data.FundingRequestStatus === 3 && (
            <Form.Field>
              <label>
                Facility Status Date{' '}
                <Link to="#" onClick={() => this.handleShowHistory(data.Id)}>
                  View histories
                </Link>
              </label>
              <DatePicker
                style={{ float: 'left' }}
                size="large"
                placeholder="Status Date"
                value={renderDate(data.FundingStatusDateReviewed)}
                format={dateFormat}
                name="FundingStatusDateReviewed"
                onChange={store.handleFieldChange('FundingStatusDateReviewed')}
              />
              <span style={{ display: 'block', lineHeight: '43px', float: 'left', marginLeft: '10px' }}>
                {data.LatestFundingStatusDate ? data.LatestFundingStatusDate.format('DD/MM/YYYY @ HH:mm') : ''}
                {data.FundingStatusUpdatedBy ? ' by ' + data.FundingStatusUpdatedBy : ''}
              </span>
            </Form.Field>
          )}
          {data.FundingRequestStatus === 4 && (
            <Form.Field>
              <label>
                Facility Status Date{' '}
                <Link to="#" onClick={() => this.handleShowHistory(data.Id)}>
                  View histories
                </Link>
              </label>
              <DatePicker
                style={{ float: 'left' }}
                size="large"
                placeholder="Status Date"
                value={renderDate(data.FundingStatusDateApproved)}
                format={dateFormat}
                name="FundingStatusDateApproved"
                onChange={store.handleFieldChange('FundingStatusDateApproved')}
              />
              <span style={{ display: 'block', lineHeight: '43px', float: 'left', marginLeft: '10px' }}>
                {data.LatestFundingStatusDate ? data.LatestFundingStatusDate.format('DD/MM/YYYY @ HH:mm') : ''}
                {data.FundingStatusUpdatedBy ? ' by ' + data.FundingStatusUpdatedBy : ''}
              </span>
            </Form.Field>
          )}
          {data.FundingRequestStatus === 5 && (
            <Form.Field>
              <label>
                Facility Status Date{' '}
                <Link to="#" onClick={() => this.handleShowHistory(data.Id)}>
                  View histories
                </Link>
              </label>
              <DatePicker
                style={{ float: 'left' }}
                size="large"
                placeholder="Status Date"
                value={renderDate(data.FundingStatusDateRejected)}
                format={dateFormat}
                name="FundingStatusDateRejected"
                onChange={store.handleFieldChange('FundingStatusDateRejected')}
              />
              <span style={{ display: 'block', lineHeight: '43px', float: 'left', marginLeft: '10px' }}>
                {data.LatestFundingStatusDate ? data.LatestFundingStatusDate.format('DD/MM/YYYY @ HH:mm') : ''}
                {data.FundingStatusUpdatedBy ? ' by ' + data.FundingStatusUpdatedBy : ''}
              </span>
            </Form.Field>
          )}
          {/* <React.Fragment>{data.FundingStatusUpdatedBy && <span> by {data.FundingStatusUpdatedBy}</span>}</React.Fragment> */}
          <Form.Field>
            <label>Attached Files</label>
            <DragDropFile
              onUploadFile={this.dragdropUploadFile}
              onDeleteFile={this.dragdropDeleteFile}
              type={1}
              maxSize={50}
              multiple={true}
              uploadTitle={'Attachment'}
              renderAttachment={this.renderAttachment}
              Attachments={lstFileAttachments}
              baseUrl="/UserFile/FundingRequestAttachment"
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field>
              <Button floated="right" className="negative" onClick={this.onClose}>
                Close
              </Button>
              {!api.isOnlySearch() && <Button
                floated="right"
                onClick={() => this.handSave(0)}
                className="btn-primary-submit"
                disabled={!canEdit}
              >
                Save
              </Button>}
              {!api.isOnlySearch() && <Button
                floated="right"
                onClick={() => this.handSave(1)}
                className="btn-primary-submit"
                disabled={!canEdit}
              >
                Save & Send Request
              </Button>}
            </Form.Field>
          </Form.Group>
          {this.openConfirm && <StatusHistory {...this.confirmParams} open={this.openConfirm} />}
        </Form>
      </Segment>
    );
  }
}

export default FormAddEdit;
