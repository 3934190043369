import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import AddEditStore from './store';
import moment from 'moment';

const columns = [
  { title: 'Case No' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Specialist', sortKey: 'Specialist' },
  { title: 'Claim/Report Type' },
  { title: 'Case Status' },
  { title: 'Status Date' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Client' },
];

@observer
class BookingHistory extends React.Component {
  updateDimensions = () => {
    const tbl = document.getElementById('Table-booking-history');
    const step = window.functionGetHeightAddEditClientStep();
    const screenWidth = window.innerWidth;
    if (tbl) {
      if (step) {
        tbl.style.height = `${step.clientHeight * 0.7}px`;
      } else {
        tbl.style.height = '30em';
      }
      return (tbl.style.width = `${screenWidth * 0.575}px`);
    }
    return null;
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  renderTableHistory = () =>
    AddEditStore.dataBookingHistory.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell style={{ maxWidth: '200px' }}>{record.CaseNo}</Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell style={{ maxWidth: '300px' }}>
          <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorFullName}{' '}
            {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
          </h5>
          <div>Specialty: {record.DoctorSepcialty}</div>
          <div>
            <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
          </div>
          <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
            Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
          </div>
        </Table.Cell>
        <Table.Cell>{record.ClaimReportType}</Table.Cell>
        <Table.Cell>{record.CaseStatus}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.CMFullName}
          <div>{record.CMEmail ? `${record.CMEmail}` : ''}</div>
          <div>{record.CMPhone ? `${record.CMPhone}` : ''}</div>
        </Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { dataBookingHistory, loadingBookingHistory } = AddEditStore;
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <div className="fluid-field table-booking-history" id="Table-booking-history">
          {loadingBookingHistory ? (
            <Spin size="large" />
          ) : (
            <Table sortable striped celled className={cx({ disabled: loadingBookingHistory })}>
              <TableHeader
                columns={columns}
                sortColumn={AddEditStore.sortColumnBookingHistory}
                sortDirection={AddEditStore.sortDirectionBookingHistory}
                onSort={AddEditStore.handleSortBooking}
              />
              <Table.Body>
                {!dataBookingHistory ? (
                  <TableEmptyMessage colSpan={colSpan} />
                ) : dataBookingHistory.itemList.length ? (
                  this.renderTableHistory()
                ) : (
                  <TableEmptyMessage colSpan={colSpan} />
                )}
              </Table.Body>
            </Table>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default BookingHistory;
