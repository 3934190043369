import './style.scss';

import cx from 'classnames';
import React from 'react';
import { Icon, Loader, Message, Table } from 'semantic-ui-react';

import data from './data';

class Appointments extends React.Component {
  state = {
    loading: true,
    data: [],
    expandedRow: '',
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
        data,
        expandedRow: data[0].Month,
      });
    }, 2000);
  }

  handleRowExpand = month => () => {
    this.setState(({ expandedRow }) => ({
      expandedRow: expandedRow === month ? '' : month,
    }));
  };

  renderAccordionRows = (row, isRowExpanded) => (
    <Table.Row onClick={this.handleRowExpand(row.Month)}>
      <Table.Cell className="Table__AccordionTile">
        <div className="DoctorTable__monthlySession">
          <span className="DoctorTable__monthlySession__month">
            <strong>{row.MonthText}</strong>
          </span>
          <span>
            Sessions:{' '}
            <strong>
              {row.AvailableSession}/{row.TotalSession}
            </strong>
          </span>
        </div>
        <Icon name={`angle ${isRowExpanded ? 'up' : 'down'}`} className="Table__AccordionIcon" />
      </Table.Cell>
    </Table.Row>
  );

  renderExpandedRow = doctors => {
    return doctors.map(doc => (
      <React.Fragment key={doc.Id}>
        <Table.Row>
          <Table.Cell>
            <div>{doc.ClinicName}</div>
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps?q=${doc.ClinicAddress}`}
            >
              {doc.ClinicAddress}
            </a>
          </Table.Cell>
        </Table.Row>
        {doc.Sessions && doc.Sessions.length && this.renderSessionsRow(doc.Sessions)}
      </React.Fragment>
    ));
  };

  renderSessionsRow = sessions => {
    return (
      <Table.Row>
        <Table.Cell>
          {sessions.map(s => (
            <div key={s.Date} className="Session-container">
              <div className="Session-label">
                {s.DateText} ({s.AvailableSession}/{s.TotalSession})
              </div>
              <div className="Appointments">
                {s.Appointments.map((a, idx) => (
                  <div
                    key={a.Id}
                    className={cx('Appointments-detail', {
                      booked: a.Booked,
                      confirmed: a.Confirmed && !a.Booked,
                      default: !a.Booked && !a.Confirmed,
                      'new-line': idx + 1 > 10,
                      'ml-0': idx % 10 === 0,
                    })}
                  >
                    <span>{a.From}</span>
                    <span>{a.To}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderTableBody = () => {
    return this.state.data.map(row => {
      const isRowExpanded = this.state.expandedRow === row.Month;
      return (
        <React.Fragment key={row.Month}>
          {this.renderAccordionRows(row, isRowExpanded)}
          {isRowExpanded && this.renderExpandedRow(row.Doctors)}
        </React.Fragment>
      );
    });
  };

  renderLoadingTableBody = () => (
    <Table.Row>
      <Table.Cell colSpan={1}>
        <div className="Table-loading pa-1 ma-2">
          <Loader />
        </div>
      </Table.Cell>
    </Table.Row>
  );

  render() {
    return (
      <React.Fragment>
        <Message color="blue">Call MAG on (02) 8090 7611 for information and assistance.</Message>
        <Table sortable celled striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Clinic</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.state.loading ? this.renderLoadingTableBody() : this.renderTableBody()}</Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default Appointments;
