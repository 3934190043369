import { action, observable } from 'mobx';
import { notification } from 'antd';
import { getBusinessUnit, getBUInfo, getStaffByCompanyId, validateCMBUManager, getCaseManagerList } from './service';

const INIT_BUINFO = {
  CompanyId: 0,
  Deleted: false,
  Description: '',
  Id: 0,
  IsActive: true,
  Title: '',
  cmBU: [],
  cmBUManager: [],
  cmBUExternal: [],
};

class BusinessUnitStore {
  @observable loading = true;
  @observable data = null;

  @observable loadingBUInfo = true;
  @observable dataBUInfo = INIT_BUINFO;
  @observable dataExternalCM = null;
  @observable dataStaff = null;
  @observable dataSubBU = null;
  @observable dataBUManager = [];

  @observable filter = 'active';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable clientSearch = '';
  @observable sortColumn = 'ID';
  @observable sortKey = 'Id';
  @observable companyId = 0;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItem = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action handleCheckedBUInfo = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.dataBUInfo[fieldName] = value;
    });

  handleChangeSearchDropdown = value => {
    getCaseManagerList(value, this.dataBUInfo.CompanyId).then(
      action(result => {
        if (result.status === 'success') {
          this.dataExternalCM = result;
        }
      }),
    );
  };
  handleFieldChangeBUINfo = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'cmBU' || fieldName === 'cmBUManager' || fieldName === 'cmBUExternal') {
        //if add new CM into Manager ->  check this CM belong any another BU
        if (
          (fieldName === 'cmBUManager' && value.length > 0 && value.length > this.dataBUInfo.cmBUManager.length) ||
          (fieldName === 'cmBUExternal' && value.length > 0 && value.length > this.dataBUInfo.cmBUExternal.length)
        ) {
          let companyId = fieldName === 'cmBUExternal' ? this.dataBUInfo.CompanyId : 0;
          return validateCMBUManager(value[value.length - 1].key, companyId).then(
            action(result => {
              if (result.status === 'success') {
                return (this.dataBUInfo[fieldName] = value.map(i => ({
                  id: i.key,
                  fullName: i.label,
                })));
              } else {
                notification.destroy();
                return notification.error({
                  message: 'Error',
                  description: result.message,
                });
              }
            }),
          );
        }

        // if (fieldName === 'cmBU') {
        //   this.dataBUManager = value.map(i => ({
        //     id: i.key,
        //     fullName: i.label,
        //   }));
        // }

        return (this.dataBUInfo[fieldName] = value.map(i => ({
          id: i.key,
          fullName: i.label,
        })));
      }
      return (this.dataBUInfo[fieldName] = value);
    });

  @action fetchBUInfo = (id, cId) => {
    this.loadingBUInfo = true;
    Promise.all([getBUInfo(id, cId), getStaffByCompanyId(cId), getCaseManagerList('', cId)]).then(
      action(([buInfo, staffList, cmList]) => {
        this.dataBUInfo = buInfo;
        this.dataStaff = staffList;
        this.dataBUManager = staffList;
        this.dataExternalCM = cmList;
        if (buInfo.cmBUExternal && this.dataExternalCM) {
          buInfo.cmBUExternal.forEach(item => {
            if (this.dataExternalCM.itemList.findIndex(x => x.Id === item.id) < 0) {
              this.dataExternalCM.itemList.push({
                Id: item.id,
                FullName: item.fullName,
              });
            }
          });
        }
        this.loadingBUInfo = false;
      }),
    );
  };

  @action fetchBU = () => {
    this.loading = true;
    const bodyParam = {
      CurPage: this.curPage,
      Keyword: this.keyword,
      NumItemPerPage: this.pageSize,
      Showing: this.filter,
      SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      companyId: this.companyId,
      SortBy: this.sortKey,
    };
    Promise.all([getBusinessUnit(bodyParam)])
      .then(
        action(([businessUnitList]) => {
          this.data = businessUnitList;
          this.loading = false;
          this.totalItem = businessUnitList.sumItem;
          this.totalPage = businessUnitList.sumPage;
        }),
      )
      .catch(() => {});
  };

  @action initBUInfo = cId => {
    this.loadingBUInfo = true;
    Promise.all([getStaffByCompanyId(cId)]).then(
      action(([staffList]) => {
        this.dataBUInfo = INIT_BUINFO;
        this.dataStaff = staffList;
        this.dataBUManager = staffList;
        this.loadingBUInfo = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchBU();
  };

  @action refetch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchBU();
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'active';
    this.sortColumn = 'ID';
    this.sortKey = 'Id';
    this.sortDirection = 'ascending';

    this.curPage = 1;
  };
}

export default new BusinessUnitStore();
