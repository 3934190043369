import { action, observable } from 'mobx';
import * as api from './api';
import { PRODUCTION_HOST } from '@utils/constants';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const allowHosts = ['staging.icsconnect.com.au', 'localhost', 'mlpdemo.kawaconn.com.au'];
class NewUI {
  @observable enable =
    !IS_PRODUCTION ||
    allowHosts.indexOf(window.location.hostname) > -1 ||
    (window.location.hostname === PRODUCTION_HOST && api.isAdminOrMagStaffOrAccounting());

  @action toggleNewUi = () => {
    this.enable = !this.enable;
  };

  enableModules = {
    DoctorTable: window.location.hostname === PRODUCTION_HOST ? false : this.enable,
    SmartSearch: window.location.hostname === PRODUCTION_HOST ? false : this.enable,
    ModalAddEdit: window.location.hostname === PRODUCTION_HOST ? false : this.enable,
    CreateIMEAssessment: window.location.hostname === PRODUCTION_HOST ? false : this.enable,
    CreateFileReview: window.location.hostname === PRODUCTION_HOST ? true : this.enable,
    CreateClinicalRecord: window.location.hostname === PRODUCTION_HOST ? true : this.enable,
    CreateSupplementaryReport: window.location.hostname === PRODUCTION_HOST ? true : this.enable,
  };

  @action isEnable = componentName => {
    return this.enableModules[componentName];
  };
}

window.__newui = new NewUI();
