import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import moment from 'moment';

const columns = [
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Change Date/Time' },
  { title: 'Reason' },
  { title: 'Updated by' },
];

@observer
class ModalCaseManagerHistory extends React.Component {
  componentDidMount() {
    AddEditStore.fetchHistory(true);
  }

  renderTableBody = () => {
    return AddEditStore.dataHistory.itemList.map(({ ...record }, id) => (
      <Table.Row key={id}>
        <Table.Cell>{record.ReferrerName}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusChangeDate
            ? moment(formatDate(record.StatusChangeDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.Reason}</Table.Cell>
        <Table.Cell>{record.UpdatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loadingHistory, dataHistory, toggleModal, open } = AddEditStore;
    const colSpan = columns.length;
    const { record } = this.props.modalParams;

    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1000}
          onCancel={toggleModal(false)}
          title={`Status history - ${record.FirstName} ${record.LastName}`}
        >
          {loadingHistory && !dataHistory ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columns} />
              <Table.Body>
                {dataHistory && dataHistory.itemList && dataHistory.itemList.length ? (
                  this.renderTableBody()
                ) : (
                  <TableEmptyMessage colSpan={colSpan} />
                )}
              </Table.Body>
            </Table>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalCaseManagerHistory;
