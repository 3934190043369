export const ERROR_FIELDNAME = {
  FirstName: 'First name',
  LastName: 'Last name',
  Email: 'Email address',
  HomePhone: 'Home phone',
  Telephone: 'Telephone',
};

export const ERROR_MESSAGES = {
  required: 'is required',
  invalid: 'is invalid',
  digits: 'must be less than or equal to 10 digits',
};
