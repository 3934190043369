import { action, observable, toJS } from 'mobx';
import customFetch from '../../../../utils/customFetch';
import swal from 'sweetalert2';
import router from '../../../../stores/router';
import moment from 'moment';

class UploadStore {
  @observable loading = false;

  @observable open = false;
  @observable modalParams = {};
  @observable timeZone = moment.tz.guess();
  @observable emailInfo = null;
  @observable icareLookupList = null;

  @observable attachmentType_Paperwork = {
    Assessment: 1,
    Supplementary: 2,
    FileReview: 1,
    Assessment_Other: 9,
    FileReview_Other: 6,
    Supplementary_Other: 3,
  };
  @observable attachmentType_Dictations = {
    Assessment: 2,
    Supplementary: 5,
    FileReview: 2,
  };
  @observable attachmentType_Draft = {
    Assessment: 3,
    Supplementary: 6,
    FileReview: 3,
  };
  @observable attachmentType_ReportCompleted = {
    Assessment: 4,
    Supplementary: 0,
    FileReview: 4,
  };
  @observable attachmentType_CorrectedReport = {
    Assessment: 18,
    Supplementary: 13,
    FileReview: 14,
  };
  @observable submitDocumentType = {
    Assessment: '/MedicalService/SubmitAssessmentDocument',
    Supplementary: '/Supplementary/SubmitSUPPDocument',
    FileReview: '/FileReview/SubmitFRDocument',
    IME_Paperwork_Onetimeacess: '/OneTimeAccess/UploadPaperworkSubmit',
  };
  @observable infoEmailCase = {
    Assessment: '/MedicalService/InfoEmailCase',
    Supplementary: '/Supplementary/InfoEmailCase',
    FileReview: '/FileReview/InfoEmailCase',
  };
  @observable submitUpdateAttendance = {
    Assessment: '/MedicalService/SaveCaseAttendance',
    // Supplementary: '/Supplementary/SubmitSUPPDocument',
    // FileReview: '/FileReview/SubmitFRDocument',
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  generateTimeRange = total => {
    let result = [];
    for (let i = 0; i < total; i++) {
      if (i < 10) {
        result.push({ label: '0' + i, value: i });
      } else result.push({ label: i.toString(), value: i });
    }
    return result;
  };

  getMomentDateTime(date, timezoneParam = null, { ignoreDST = false } = {}) {
    if (!date) {
      return null;
    }

    const timezoneValue = timezoneParam ?? this.timeZone;
    if (typeof date === 'string') {
      if (ignoreDST) {
        const defaultOffset = moment.tz(timezoneValue).utcOffset();
        return moment(date).utcOffset(defaultOffset);
      } else {
        return moment(date).tz(timezoneValue);
      }
    }
    return moment(date).tz(timezoneValue);
  }

  @action getLookupIcareReport = () => {
    customFetch('/Default/GetLookupConfigByTypes2', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        types: [
          'IMEReportCapacity',
          'IMEReportDiagnosis',
          'IMEReportLiability',
          'IMEReportTreatment',
          'IMEReportWPI',
          'IMEReportPsyWPI',
          'IMEReportIMC',
        ],
      }),
    }).then(
      action(res => {
        this.icareLookupList = toJS(res);
      }),
    );
  };

  @action fetchInfoEmail = caseId => {
    this.emailInfo = null;
    const { serviceType } = this.modalParams;
    customFetch(this.infoEmailCase[serviceType], {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        id: caseId,
      }),
    }).then(
      action(res => {
        this.emailInfo = res.data;
      }),
    );
  };

  @action submitFileHandler = (url, data, refetchTable) => {
    action(
      customFetch(url, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      }).then(
        action(res => {
          this.loading = false;
          if (res.status === 'success') {
            swal.fire('', 'Upload files success', 'success').then(result => {
              if (result.isConfirmed && data.Token) {
                router.history.push('/view/login');
              }
            });
          } else {
            swal.fire('', 'Upload files fail', 'error');
          }
          if (refetchTable) {
            refetchTable();
          }
          this.open = false;
        }),
      ),
    );
  };

  @action updateAttendanceHandler = (url, data, refetchTable) => {
    action(
      customFetch(url, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      }).then(
        action(res => {
          this.loading = false;
          if (res.status === 'success') {
            swal.fire('', 'Update attendance success', 'success');
          } else {
            swal.fire('', 'Update attendance fail', 'error');
          }
          if (refetchTable) {
            refetchTable();
          }
          this.open = false;
        }),
      ),
    );
  };

  ReplaceFileNameUpload = (
    serviceType,
    caseNumber,
    claimantFullname,
    doctorTitle,
    doctorFullName,
    inputDate,
    isRequireVideo,
    clinicName,
    number,
  ) => {
    var retStr = claimantFullname + ' - ' + doctorTitle + '.' + doctorFullName + ' - ' + inputDate;
    switch (serviceType) {
      case 'Assessment':
        retStr += ' - ';
        if (isRequireVideo) {
          retStr += 'telehealth';
        } else {
          retStr += clinicName;
        }
        retStr += ' - Case No:' + caseNumber;
        if (number > 0) {
          retStr += ' - ' + number;
        }
        break;
      case 'Supplementary':
      case 'FileReview':
        retStr = retStr + ' - Case No: ' + caseNumber;
        if (number > 0) {
          retStr += ' - ' + number;
        }
        break;
      default:
        break;
    }
    return retStr;
  };
}

export default new UploadStore();
