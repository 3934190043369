import customFetch from '../../../utils/customFetch';

export const getActionRequired = () => {
  return customFetch('/Account/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getMyDetail = () => {
  return customFetch('/Account/MyDetail', {
    method: 'POST',
  });
};

export const getUserSetting = () => {
  return customFetch('/Account/GetUserSetting', {
    method: 'POST',
  });
};
