import './index.scss';
import { Dropdown, Menu, Form, Input, notification } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody, Popup, Icon as IconSemantic } from 'semantic-ui-react';
import TableToolbar from '../../shared/tableToolbar';
import { TableHeader } from '../../shared/table';
import VideoSessionStore from './VideoSessionStore';
import uploadStore from '../../shared/UniversalSearch/UploadFile/UploadStore';

import copy from 'copy-to-clipboard';
import customFetch from '@utils/customFetch';
import * as api from '../../../stores/api';
import { PRODUCTION_HOST } from '@utils/constants';

const columns = [
  { title: 'Name' },
  { title: 'Type' },
  { title: 'Email' },
  { title: 'Phone Number' },
  { title: 'Joined Time' },
  { title: 'Joined Duration' },
  { title: 'Speaker Time' },
  { title: 'Video links' },
];

@observer
class VideoSessionTable extends React.Component {
  state = {
    backupLink: '',
    backupRoomId: '',
    passcode: '',
  };

  componentDidMount() {
    const { Id, ...record } = this.props;
    console.log(record.data);
    this.setState({ backupLink: record.data.BackupLink });
    this.setState({ backupRoomId: record.data.BackupIdRoom });
    this.setState({ passcode: record.data.BackupPasscode });
  }

  openModal = options => {
    return VideoSessionStore.toggleModal(true, options);
  };

  copyLink = token => {
    copy(`${window.location.origin}/view/video?token=${token}`);
    notification.success({
      message: 'Video link copied successfully',
      duration: 1,
    });
  };

  //type = Doctor/CM/Staff/Patient/Interpreter
  //sendBy = 0 : Email/ 1 : SMS
  ResendLink = (assessmentId, type, sendBy, sendToEmail, backupLink = '', backupRoomId = '', passcode = '') => {
    if (backupLink && !VideoSessionStore.validateField(this.state.backupLink, 'url')) {
      notification.error({
        message: 'Video link is not valid',
        duration: 1,
      });
      return;
    }
    VideoSessionStore.setFieldsValue({ loading: true });

    VideoSessionStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Do you want to resend alternative video link`,
      onOk: () => {
        customFetch('/MedicalService/ResendVideoLink', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: assessmentId,
            videoType: type,
            sendType: sendBy,
            email: sendToEmail,
            backupLink,
            backupRoomId,
            passcode,
          }),
        }).then(res => {
          if (res.status === 'success') {
            notification.success({
              message: sendBy === 0 ? 'Video link emailed successfully' : 'Video link SMS successfully',
              duration: 1,
            });
          }
          VideoSessionStore.setFieldsValue({ loading: false });
        });
      },
    })();
  };

  staffJoin = staffLink => {
    return VideoSessionStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Make sure to acknowledge your presence to other participants upon joining. To leave the session properly, click "Leave" button inside Scope apps`,
      onOk: () => window.open(`/view/video?token=${staffLink}`, '_blank'),
    })();
  };

  updateAlternativeLink = assessmentId => {
    customFetch('/MedicalService/UpdateBackupEassessUrl', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: assessmentId,
        url: this.state.backupLink,
        backupRoomId: this.state.backupRoomId,
        passcode: this.state.passcode,
      }),
    }).then(res => {
      if (res.status === 'success') {
        notification.success({
          message: 'Update alternative link successfully',
          duration: 1,
        });
      }
      VideoSessionStore.setFieldsValue({ loading: false });
    });
  };

  staffJoin = staffLink => {
    return VideoSessionStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Make sure to acknowledge your presence to other participants upon joining. To leave the session properly, click "Leave" button inside Scope apps`,
      onOk: () => window.open(`/view/video?token=${staffLink}`, '_blank'),
    })();
  };

  handleTestLink = token => {
    return VideoSessionStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Make sure to acknowledge your presence to other participants upon joining. To leave the session properly, click "Leave" button inside Scope apps`,
      onOk: () => {
        window.open(`${window.location.origin}/view/video?token=${token}`);
      },
    })();
  };

  handleJaasTestLink = token => {
    return VideoSessionStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Make sure to acknowledge your presence to other participants upon joining. To leave the session properly, click "Leave" button inside Scope apps`,
      onOk: () => {
        window.open(`${window.location.origin}/view/video-meeting?token=${token}`);
      },
    })();
  };

  renderSession = data => {
    const {
      // loading,
      sortColumn,
      sortDirection,
    } = VideoSessionStore;
    const colSpan = columns.length;
    if (!data) {
      return null;
    }
    const { Id, ...record } = data;
    const disableUpdate =
      record.AssessmentStatus !== api.imeStatus.IME_REQUESTED &&
      record.AssessmentStatus !== api.imeStatus.APPOINTMENT_CONFIRMED &&
      record.AssessmentStatus !== api.imeStatus.PAPERWORK_RECEIVED;

    return (
      <React.Fragment>
        <div className="search-title">
          <h2> {`Case No: ${record.CaseNo}`}</h2>
          <h2>
            {' '}
            {`Appointment Date: ${moment(record.AppointmentDate).format('DD/MM/YYYY HH:mm')} - ${moment(
              record.AppointmentDateTo,
            ).format('HH:mm')}`}
          </h2>
        </div>
        <TableToolbar.Container className="toolbar">
          <TableToolbar.Left>
            <TableToolbar.HighLightButton
              color="green"
              onClick={e => {
                e.preventDefault();
                this.staffJoin(record.StaffLink);
              }}
            >
              Join Video Session
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              color="green"
              style={{ marginLeft: '5px' }}
              disabled={disableUpdate}
              onClick={e => {
                e.preventDefault();
                this.openUpdateModel(
                  record.AssessmentId,
                  record.CaseNo,
                  record.Status || record.status || record.currentStatus,
                  record.PatientName,
                  record,
                );
              }}
            >
              Update Attendance
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              color="green"
              style={{ marginLeft: '5px' }}
              disabled={disableUpdate}
              onClick={e => {
                e.preventDefault();
                this.updateAlternativeLink(record.AssessmentId);
              }}
            >
              Update Alternative Link
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Container>
            <TableToolbar.Left as="div">
              <h3 style={{ marginTop: '30px' }}>Add Alternative Video Link:</h3>
              <Form.Item
                style={{ marginLeft: '5px', marginTop: '2.5%' }}
                validateStatus={!VideoSessionStore.validateField(this.state.backupLink, 'url') ? 'error' : null}
                help={!VideoSessionStore.validateField(this.state.backupLink, 'url') ? 'Video link is not valid' : ''}
              >
                <Input
                  size="large"
                  style={{ height: '42px', width: '500px' }}
                  value={this.state.backupLink}
                  onChange={e => this.setState({ backupLink: e.target.value })}
                  placeholder="Backup Link"
                />
              </Form.Item>
              <h3 style={{ marginLeft: '10px' }}>Meeting ID:</h3>
              <Form.Item style={{ marginLeft: '5px', marginTop: '2.5%' }}>
                <Input
                  size="large"
                  style={{ height: '42px' }}
                  value={this.state.backupRoomId}
                  onChange={e => this.setState({ backupRoomId: e.target.value })}
                  placeholder="Meeting Id"
                />
              </Form.Item>
              <h3 style={{ marginLeft: '10px' }}>Passcode:</h3>
              <Form.Item style={{ marginLeft: '5px', marginTop: '2.5%' }}>
                <Input
                  size="large"
                  style={{ height: '42px' }}
                  value={this.state.passcode}
                  onChange={e => this.setState({ passcode: e.target.value })}
                  placeholder="Passcode"
                />
              </Form.Item>
            </TableToolbar.Left>
          </TableToolbar.Container>
        </TableToolbar.Container>
        <Table sortable celled striped>
          <TableHeader
            columns={columns}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={VideoSessionStore.handleSort}
          />
          <TableBody>{this.renderTableBody(Id, record, colSpan)}</TableBody>
          {/* <TableFooterPagination
            colSpan={colSpan}
            currentPage={VideoSessionStore.curPage}
            totalPage={VideoSessionStore.totalPage}
            totalItems={VideoSessionStore.totalItems}
            onPageClick={this.handlePageClick}
          /> */}
        </Table>
      </React.Fragment>
    );
  };

  openUpdateModel = (id, caseNo, status, claimant, record) => {
    let recordParam = {
      "DateFrom" : record.AppointmentDate,
      "DateTo": record.AppointmentDateTo,
      "AppointmentTimeZone": record.AppointmentTimeZone
    }
    return uploadStore.toggleModal(true, {
      modalType: 'openUpdateModel',
      id,
      caseNo,
      status,
      claimant,
      serviceType: 'Assessment',
      label: 'IME',
      service: 'UpdateAttendance',
      record: recordParam
    })();
  };

  renderJaasVideo = link => {
    if (window.location.hostname === PRODUCTION_HOST) {
      return null;
    }
    return (
      <React.Fragment>
        <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
        <Link to="#" onClick={() => this.handleJaasTestLink(link)}>
          <span style={{ padding: '0 10px' }}>Jaas Video</span>
        </Link>
      </React.Fragment>
    );
  };

  renderTableBody = (Id, record, colSpan) => {
    return (
      <React.Fragment>
        {record.DoctorId > 0 && (
          <Table.Row key={Id + 'doctor'}>
            <Table.Cell>{record.DoctorName}</Table.Cell>
            <Table.Cell>Specialist</Table.Cell>
            <Table.Cell>{record.DoctorEmail}</Table.Cell>
            <Table.Cell>{record.DoctorPhone}</Table.Cell>
            <Table.Cell>
              {record.DoctorJoinedTime ? moment(record.DoctorJoinedTime).format('DD/MM/YYYY - HH:mm') : 'N/A'}
            </Table.Cell>
            <Table.Cell>{record.DoctorDuration}m</Table.Cell>
            <Table.Cell>{record.DoctorSpeadkerDuration}m</Table.Cell>
            <Table.Cell selectable className="Cell-link Cell-nowrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="#" onClick={() => this.handleTestLink(record.DoctorLink)}>
                  <span style={{ padding: '0 10px' }}>Test Scope Link</span>
                </Link>
                {/* {this.renderJaasVideo(record.DoctorLink)} */}
                <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                <Link to="#" onClick={() => this.copyLink(record.DoctorLink)}>
                  <span style={{ padding: '0 10px' }}>Copy</span>
                </Link>
                {record.DoctorEmail && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'D', 0, record.DoctorEmail)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend Email </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.DoctorPhone && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'D', 1)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend SMS </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to specialist (MAIL-38-SMS)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.DoctorEmail && this.state.backupLink && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      disabled={!this.state.backupLink}
                      onClick={() =>
                        this.ResendLink(
                          record.AssessmentId,
                          'D',
                          0,
                          record.DoctorEmail,
                          this.state.backupLink,
                          this.state.backupRoomId,
                          this.state.passcode,
                        )
                      }
                    >
                      <span style={{ paddingLeft: '10px' }}>Send Alternative Link </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Alternative video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {record.PatientId > 0 && (
          <Table.Row key={Id + 'patient'}>
            <Table.Cell>{record.PatientName}</Table.Cell>
            <Table.Cell>Claimant</Table.Cell>
            <Table.Cell>{record.PatientEmail}</Table.Cell>
            <Table.Cell>{record.PatientPhone}</Table.Cell>
            <Table.Cell>
              {record.PatientJoinedTime ? moment(record.PatientJoinedTime).format('DD/MM/YYYY - HH:mm') : 'N/A'}
            </Table.Cell>
            <Table.Cell>{record.PatientDuration}m</Table.Cell>
            <Table.Cell>{record.PatientSpeadkerDuration}m</Table.Cell>
            <Table.Cell selectable className="Cell-link Cell-nowrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="#" onClick={() => this.handleTestLink(record.PatientLink)}>
                  <span style={{ padding: '0 10px' }}>Test Scope Link</span>
                </Link>
                {/* {this.renderJaasVideo(record.PatientLink)} */}
                <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                <Link to="#" onClick={() => this.copyLink(record.PatientLink)}>
                  <span style={{ padding: '0 10px' }}>Copy</span>
                </Link>

                {record.PatientEmail && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'P', 0, record.PatientEmail)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend Email </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to claimant (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.PatientPhone && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'P', 1, '')}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend SMS </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to claimant (MAIL-38-SMS)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.PatientEmail && this.state.backupLink && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      disabled={!this.state.backupLink}
                      onClick={() =>
                        this.ResendLink(
                          record.AssessmentId,
                          'P',
                          0,
                          record.PatientEmail,
                          this.state.backupLink,
                          this.state.backupRoomId,
                          this.state.passcode,
                        )
                      }
                    >
                      <span style={{ paddingLeft: '10px' }}>Send Alternative Link </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Alternative video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {record.InterpreterId > 0 && (
          <Table.Row key={Id + 'interpreter'}>
            <Table.Cell>{record.InterpreterName}</Table.Cell>
            <Table.Cell>Interpreter</Table.Cell>
            <Table.Cell>{record.InterpreterEmail}</Table.Cell>
            <Table.Cell>{record.InterpreterPhone}</Table.Cell>
            <Table.Cell>
              {record.InterpreterJoinedTime ? moment(record.InterpreterJoinedTime).format('DD/MM/YYYY - HH:mm') : 'N/A'}
            </Table.Cell>
            <Table.Cell>{record.InterpreterDuration}m</Table.Cell>
            <Table.Cell>{record.InterpreterSpeadkerDuration}m</Table.Cell>
            <Table.Cell selectable className="Cell-link Cell-nowrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="#" onClick={() => this.handleTestLink(record.InterpreterLink)}>
                  <span style={{ padding: '0 10px' }}>Test Scope Link</span>
                </Link>
                {/* {this.renderJaasVideo(record.InterpreterLink)} */}
                <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                <Link to="#" onClick={() => this.copyLink(record.InterpreterLink)}>
                  <span style={{ padding: '0 10px' }}>Copy</span>
                </Link>

                {record.InterpreterLink && record.InterpreterEmail && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'I', 0, record.InterpreterEmail)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend Email </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to interpreter (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.InterpreterPhone && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'I', 1, '')}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend SMS </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to interpreter (MAIL-38-SMS)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.InterpreterEmail && this.state.backupLink && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      disabled={!this.state.backupLink}
                      onClick={() =>
                        this.ResendLink(
                          record.AssessmentId,
                          'I',
                          0,
                          record.InterpreterEmail,
                          this.state.backupLink,
                          this.state.backupRoomId,
                          this.state.passcode,
                        )
                      }
                    >
                      <span style={{ paddingLeft: '10px' }}>Send Alternative Link </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Alternative video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {record.CaseManagerId > 0 && (
          <Table.Row key={Id + 'cm'}>
            <Table.Cell>{record.CaseManagerName}</Table.Cell>
            <Table.Cell>Case Manager</Table.Cell>
            <Table.Cell>{record.CaseManagerEmail}</Table.Cell>
            <Table.Cell>{record.CaseManagerPhone}</Table.Cell>
            <Table.Cell>
              {record.CaseManagerJoinedTime ? moment(record.CaseManagerJoinedTime).format('DD/MM/YYYY - HH:mm') : 'N/A'}
            </Table.Cell>
            <Table.Cell>{record.CaseManagerDuration}m</Table.Cell>
            <Table.Cell>{record.CaseManagerSpeadkerDuration}m</Table.Cell>
            <Table.Cell selectable className="Cell-link Cell-nowrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="#" onClick={() => this.handleTestLink(record.CaseManagerLink)}>
                  <span style={{ padding: '0 10px' }}>Test Scope Link</span>
                </Link>
                {/* {this.renderJaasVideo(record.CaseManagerLink)} */}
                <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                <Link to="#" onClick={() => this.copyLink(record.CaseManagerLink)}>
                  <span style={{ padding: '0 10px' }}>Copy</span>
                </Link>

                {record.CaseManagerEmail && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      style={{ width: 130, marginLeft: 10 }}
                      onClick={() => this.ResendLink(record.AssessmentId, 'C', 0, record.CaseManagerEmail)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend Email </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to case manager (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.CaseManagerPhone && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      style={{ width: 130, marginLeft: 10 }}
                      onClick={() => this.ResendLink(record.AssessmentId, 'C', 1, '')}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend SMS </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to case manager (MAIL-38-SMS)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.CaseManagerEmail && this.state.backupLink && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      disabled={!this.state.backupLink}
                      onClick={() =>
                        this.ResendLink(
                          record.AssessmentId,
                          'C',
                          0,
                          record.CaseManagerEmail,
                          this.state.backupLink,
                          this.state.backupRoomId,
                          this.state.passcode,
                        )
                      }
                    >
                      <span style={{ paddingLeft: '10px' }}>Send Alternative Link </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Alternative video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {record.StaffId && (
          <Table.Row key={Id + 'staff'}>
            <Table.Cell>{record.StaffName}</Table.Cell>
            <Table.Cell>Staff PIC</Table.Cell>
            <Table.Cell>{record.StaffEmail}</Table.Cell>
            <Table.Cell>{record.StaffPhone}</Table.Cell>
            <Table.Cell>
              {record.StaffJoinedTime ? moment(record.StaffJoinedTime).format('DD/MM/YYYY - HH:mm') : 'N/A'}
            </Table.Cell>
            <Table.Cell>{record.StaffDuration}m</Table.Cell>
            <Table.Cell>{record.StaffSpeadkerDuration}m</Table.Cell>
            <Table.Cell selectable className="Cell-link Cell-nowrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="#" onClick={() => this.handleTestLink(record.StaffLink)}>
                  <span style={{ padding: '0 10px' }}>Test Scope Link</span>
                </Link>
                {/* {this.renderJaasVideo(record.StaffLink)} */}
                <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                <Link to="#" onClick={() => this.copyLink(record.StaffLink)}>
                  <span style={{ padding: '0 10px' }}>Copy</span>
                </Link>

                {record.StaffEmail && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      style={{ width: 130, marginLeft: 10 }}
                      onClick={() => this.ResendLink(record.AssessmentId, 'S', 0, record.StaffEmail)}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend Email </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to staff (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.StaffPhone && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      style={{ width: 130, marginLeft: 10 }}
                      to="#"
                      onClick={() => this.ResendLink(record.AssessmentId, 'S', 1, '')}
                    >
                      <span style={{ width: 95, float: 'left' }}>Resend SMS</span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Video link will be sent to staff (MAIL-38-SMS)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
                {record.StaffEmail && this.state.backupLink && (
                  <React.Fragment>
                    <div style={{ width: '2px', height: '18px', backgroundColor: '#c1c2c2' }}></div>
                    <Link
                      to="#"
                      disabled={!this.state.backupLink}
                      onClick={() =>
                        this.ResendLink(
                          record.AssessmentId,
                          'S',
                          0,
                          record.StaffEmail,
                          this.state.backupLink,
                          this.state.backupRoomId,
                          this.state.passcode,
                        )
                      }
                    >
                      <span style={{ paddingLeft: '10px' }}>Send Alternative Link </span>
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Alternative video link will be sent to specialist (MAIL-38)"
                        position="top center"
                        wide="very"
                      />
                    </Link>
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </React.Fragment>
    );
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {/* <Menu.Item onClick={this.openCaseDetail(id, record.AssessmentNumber)}>
            Update Claim Details
          </Menu.Item>
          <Menu.Item
            onClick={this.openCaseUploadPaper(id, record.AssessmentNumber)}
          >
            Update Appointment & Case Documents
          </Menu.Item> */}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    VideoSessionStore.refetch({ curPage: page });
  };

  render() {
    const { data } = this.props;
    return <React.Fragment>{this.renderSession(data)}</React.Fragment>;
  }
}

export default VideoSessionTable;
