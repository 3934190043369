import customFetch from '../../../utils/customFetch';

export const getCMInfo = () => {
  return customFetch('/Account/CMAccountInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getBusinessUnits = () => {
  return customFetch('/BusinessUnit/GetBusinessUnits', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const getStaffs = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getClient = (keyword = '', numberItem = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getSpecificLookupConfigByType = () => {
  return customFetch('/Staff/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'job' }),
  });
};

export const getStaffAll = (keyword = '', numberItem = 30) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const saveCaseManager = body => {
  return customFetch('/Account/SaveCmAccount', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const fetchChartDate = id => {
  return customFetch(`/Staff/GetCMBookingLast6Month?caseManagerId=${id}`, {});
};
