import React from 'react';
import { Form, Input, notification, Spin, DatePicker } from 'antd';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import './ModalCreate.scss';
import Modal from '../../shared/Modal';
import AMACalculationRuleStore from './Store';
import { getAMACalculationRuleInfo, saveAMACalculationRule } from './Service';
import { formatDate } from '../../../utils/functions';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';

@observer
class ModalCreate extends React.Component {
  state = {
    loading: true,
    data: null,
  };

  handleSave = () => {
    const { listPercentages } = AMACalculationRuleStore;
    const { data } = this.state;
    if (!data.CalculationName) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 3,
      });
    } else {
      var submitData = data;
      var percentages = '';
      var saveRateArr = [];
      if (toJS(listPercentages)) {
        listPercentages.forEach(item => {
          if (item.Rate > 0) {
            percentages += item.Rate + ';';
            saveRateArr.push({
              Id: item.Id,
              CalculationId: item.CalculateId,
              Rate: item.Rate,
              RateGuide: item.RateGuide,
            });
          }
        });
      }
      submitData.StartEffectiveDate = !!data.StartEffectiveDate ? moment(data.StartEffectiveDate) : null;
      submitData.EndEffectiveDate = !!data.EndEffectiveDate ? moment(data.EndEffectiveDate) : null;
      submitData.Percentages =
        percentages.slice(-1) === ';' ? percentages.substring(0, percentages.length - 1) : percentages;
      submitData.AMACalculationRuleRates = saveRateArr;
      Promise.all([saveAMACalculationRule(submitData)])
        .then(([response]) => {
          if (response.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
              duration: 3,
            });
            AMACalculationRuleStore.refetch({}, true);
            this.props.onCancel();
          }
        })
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
            duration: 3,
          });
        });
    }
  };
  componentDidMount() {
    const { id } = AMACalculationRuleStore.modalParams;
    if (id) {
      Promise.all([getAMACalculationRuleInfo(id)]).then(([itemInfo]) => {
        var newArr = [];
        if (
          itemInfo &&
          itemInfo.objItem.AMACalculationRuleRates &&
          itemInfo.objItem.AMACalculationRuleRates.length > 0
        ) {
          var countIdx = 1;
          itemInfo.objItem.AMACalculationRuleRates.forEach(i => {
            newArr.push({
              idx: countIdx,
              Id: i.Id,
              CalculateId: i.CalculationId,
              Rate: i.Rate,
              RateGuide: i.RateGuide,
            });
            countIdx++;
          });
        }
        AMACalculationRuleStore.setPercentages(newArr);
        this.setState({
          loading: false,
          data: itemInfo.objItem,
        });
      });
    } else {
      this.setState({
        loading: false,
        data: {
          Id: 0,
          Key: '',
          Label: '',
          Target: 0,
        },
      });
    }
  }

  handleFieldChange = fieldName => event => {
    const { data } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      data: {
        ...data,
        [fieldName]: value,
      },
    });
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  handlePercentageChange = (idx, fieldName) => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var newArray = toJS(AMACalculationRuleStore.listPercentages);
    if (fieldName === 'Rate') {
      newArray.forEach(i => {
        if (i.idx === idx) {
          i.Rate = value;
        }
      });
    }
    if (fieldName === 'RateGuide') {
      newArray.forEach(i => {
        if (i.idx === idx) {
          i.RateGuide = value;
        }
      });
    }
    // var newArray = toJS(listPercentages) ? toJS(listPercentages) : [];
    // newArray = newArray.map(i => {
    //   if (i.idx === idx) {
    //     return { idx: i.idx, Percentage: parseInt(value) };
    //   } else {
    //     return { ...i };
    //   }
    // });
    AMACalculationRuleStore.setPercentages(newArray);
  };

  onAddPercentage = () => {
    const { data } = this.state;
    var newArr = toJS(AMACalculationRuleStore.listPercentages);
    newArr.push({ idx: newArr.length + 1, Id: 0, CalculateId: data.Id, Rate: null, RateGuide: null });
    //listPers.push({ idx: listPers.length + 1, Percentage: 0 });
    AMACalculationRuleStore.setPercentages(newArr);
  };

  deletePercentage = idx => {
    var oldArr = toJS(AMACalculationRuleStore.listPercentages);
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(i => {
      if (i.idx !== idx) {
        newArr.push({ idx: countIdx, Id: i.Id, CalculateId: i.CalculateId, Rate: i.Rate, RateGuide: i.RateGuide });
        countIdx++;
      }
    });
    AMACalculationRuleStore.setPercentages(newArr);
  };

  updatePercentages = record => {};

  render() {
    const { record, id } = AMACalculationRuleStore.modalParams;
    const { open, listPercentages } = AMACalculationRuleStore;
    const { data, loading } = this.state;

    return (
      <Modal
        width={800}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New AMA Calculation Rule' : record.CalculationName}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item
              label="Calculation Name"
              required
              validateStatus={!data.CalculationName ? 'error' : null}
              help={!data.CalculationName ? 'This field is required' : null}
            >
              <Input value={data.CalculationName} onChange={this.handleFieldChange('CalculationName')} />
            </Form.Item>
            <Form.Item label="Calculation Rules">
              <Input.TextArea
                rows={4}
                value={data.CalculationRules}
                onChange={this.handleFieldChange('CalculationRules')}
              />
            </Form.Item>
            <Form.Item
              label={
                <React.Fragment>
                  Applicable Rates (%){' '}
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="Set rates from higher to lower"
                    position="top center"
                  />
                </React.Fragment>
              }
            >
              {!!toJS(listPercentages) &&
                toJS(listPercentages).map(item => {
                  return (
                    <Form.Item
                      key={'form-' + item.idx}
                      label={
                        <React.Fragment>
                          Rate {item.idx}
                          <Link key={'link-' + item.idx} to="#" onClick={() => this.deletePercentage(item.idx)}>
                            {' '}
                            Delete
                          </Link>
                        </React.Fragment>
                      }
                    >
                      <Input
                        type="number"
                        key={item.idx}
                        name={item.idx}
                        value={item.Rate || 0}
                        onChange={this.handlePercentageChange(item.idx, 'Rate')}
                      />
                      <br />
                      <div className="ant-col ant-form-item-label">
                        <label>Rate Guide {item.idx}</label>
                      </div>
                      <Input
                        type="text"
                        key={'guide-' + item.idx}
                        name={item.idx}
                        value={item.RateGuide || ''}
                        onChange={this.handlePercentageChange(item.idx, 'RateGuide')}
                      />
                    </Form.Item>
                  );
                })}
            </Form.Item>
            <Form.Item>
              <Button color="green" onClick={this.onAddPercentage}>
                <Icon name="plus" /> Add Rate
              </Button>
            </Form.Item>
            <Form.Item label="Effective start date">
              <DatePicker
                size="large"
                value={this.convertDate(data.StartEffectiveDate)}
                onChange={this.handleFieldChange('StartEffectiveDate')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Effective end date">
              <DatePicker
                size="large"
                value={this.convertDate(data.EndEffectiveDate)}
                onChange={this.handleFieldChange('EndEffectiveDate')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ModalCreate;
