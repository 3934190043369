import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import MedicalCentresStore from './MedicalCentresStore';
import MedicalCentresTable from './MedicalCentresTable';
import MedicalCentresToolbar from './MedicalCentresToolbar';
import MedicalCentresModal from './MedicalCentresModal';
import ui from '../../../stores/dashboard';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class MedicalCentres extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/medical-centres-2', true);
      return;
    }
    this._searchDebounced();
    ui.on('open(/view/medical-centres-2)', this._searchDebounced);
  }

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  _searchDebounced = debounce(() => {
    const { sortBy, sortDirection } = this.getRouteParams();
    if (sortBy && sortDirection) {
      MedicalCentresStore.refetch(
        {
          sortKey: sortBy,
          sortDirection: sortDirection,
        },
        true,
      );
      return router.history.replace({ ...router.location.search, search: '' });
    }
    return MedicalCentresStore.fetchClinic(true);
  }, 500);
  render() {
    const { loading, data, open, loadingSearch, dataSearch } = MedicalCentresStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingSearch && !dataSearch) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingSearch && !dataSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <MedicalCentresToolbar />
            <MedicalCentresTable />
            {open && <MedicalCentresModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default MedicalCentres;
