import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isNil } from 'lodash';
import { Dropdown, Menu, notification } from 'antd';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '@components/shared/table';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';
import { getItemTextByKey } from '@utils/functions';
import NotificationStore from './NotificationStore';
import { NotificationTypes } from './constants';

const columns = [
  { title: '' },
  { title: 'Subject', sortKey: 'Summary' },
  { title: 'Notification Type', sortKey: 'NotificationType' },
  { title: 'Sent/Received Date', sortKey: 'CreatedAt' },
  { title: 'Sent By', sortKey: 'CreatedBy' },
  { title: '' },
];

@observer
class NotificationTable extends React.Component {
  handleViewItem = record => {
    NotificationStore.setFieldsValue({
      modalParams: {
        record,
        isOpen: true,
        type: 'view',
      },
    });
  };

  handleDelete = async record => {
    const res = await customFetch('/Notification/Delete', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id: record.Id }),
    });

    notification.destroy();

    if (res.status === 'success') {
      NotificationStore.fetchNotifications(true);
      notification.success({
        message: 'Notification was deleted successfully',
      });
    } else {
      notification.error({
        message: 'Notification failed to delete',
        description: 'Please check logs for details.',
      });
    }
  };

  handleDuplicate = record => {
    NotificationStore.setFieldsValue({
      modalParams: {
        record,
        isOpen: true,
        type: 'duplicate',
      },
    });
  };

  handlePageClick = page => {
    NotificationStore.refetch(page);
  };

  renderTableBody = () => {
    return NotificationStore.itemList.map(record => (
      <Table.Row key={record.Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(record)}</Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={() => this.handleViewItem(record)}>
            {record.Summary} {record.Status === 0 && <sup> new</sup>}
          </Link>
        </Table.Cell>
        <Table.Cell>{getItemTextByKey(NotificationTypes, record.NotificationType)}</Table.Cell>
        <Table.Cell>{moment(record.CreatedAt).format('DD/MM/YYYY @ HH:mm')}</Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
        <Table.Cell>{record.IsOriginal ? 'Sent' : 'Received'}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {api.isAdmin() && <Menu.Item onClick={() => this.handleDuplicate(record)}>Duplicate</Menu.Item>}
          <Menu.Item onClick={() => this.handleDelete(record)}>Delete</Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, itemList } = NotificationStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={NotificationStore.sortColumn}
          sortDirection={NotificationStore.sortDirection}
          onSort={NotificationStore.handleSort}
        />
        <Table.Body>{!isNil(itemList) ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={NotificationStore.totalItems}
          currentPage={NotificationStore.curPage}
          totalPage={NotificationStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default NotificationTable;
