import './DoctorDetails.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Checkbox, Form, Grid, Segment } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';

@observer
class ActionRequiredTab extends React.Component {
  render() {
    const { dataDoctor, toggleCheckbox } = DoctorDetailsStore;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Action Required</label>
                  </Form.Field>
                </Form.Group>
                <Checkbox
                  label="Action Required"
                  disabled
                  name="IsActionRequired"
                  checked={dataDoctor.IsActionRequired}
                  onChange={toggleCheckbox}
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">PIC</label>
                    <div>
                      <p className="Form-Field-Text">
                        {dataDoctor.IsActionRequired && dataDoctor.ActionRequiredStaffName
                          ? dataDoctor.ActionRequiredStaffName
                          : 'None'}
                      </p>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Action Completed</label>
                    <div>
                      <Checkbox
                        disabled={true}
                        checked={dataDoctor.ActionRequiredCompleted}
                        label={
                          dataDoctor.IsActionRequired && dataDoctor.ActionRequiredCompleted
                            ? `Completed - ${dataDoctor.ActionRequiredCompletedDate}`
                            : 'Completed'
                        }
                        style={{ marginRight: '15px' }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Request Date</label>
                    <div>
                      <p className="Form-Field-Text">
                        {dataDoctor.IsActionRequired && dataDoctor.ActionRequiredDate
                          ? dataDoctor.ActionRequiredDate
                          : 'None'}
                      </p>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Due Date</label>
                    <div>
                      <p className="Form-Field-Text">
                        {dataDoctor.IsActionRequired && dataDoctor.ActionRequiredDueDate
                          ? dataDoctor.ActionRequiredDueDate
                          : 'None'}
                      </p>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Action Note</label>
                    <div>
                      <p className="Form-Field-Text">
                        {dataDoctor.IsActionRequired && dataDoctor.ActionRequiredNote
                          ? dataDoctor.ActionRequiredNote
                          : 'None'}
                      </p>
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default ActionRequiredTab;
