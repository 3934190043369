import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Dropdown, Menu, Icon } from 'antd';

const EditableDropdown = ({
  primaryField,
  searchField,
  isRequired,
  isEditable,
  label = 'Specialist',
  value,
  isDisabled,
  isMenuDisabled = false,
  openAddEditDropdown,
  handleChangeFieldSelect,
  handleSearch,
  handleScroll,
  children,
}) => {
  return (
    <Form.Item
      required={isRequired}
      label={
        <React.Fragment>
          {label}
          {isEditable && (
            <Dropdown
              disabled={isMenuDisabled}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => openAddEditDropdown('add', primaryField)}>Add {label}</Menu.Item>
                  <Menu.Item onClick={() => openAddEditDropdown('edit', primaryField)}>Edit {label}</Menu.Item>
                </Menu>
              }
            >
              <button className="field-actions">
                Add/Edit <Icon type="down" />
              </button>
            </Dropdown>
          )}
        </React.Fragment>
      }
    >
      <Select
        size="large"
        value={value}
        disabled={isDisabled}
        onChange={handleChangeFieldSelect(primaryField)}
        onSearch={handleSearch(searchField)}
        onPopupScroll={handleScroll(searchField)}
        showSearch
        allowClear
        filterOption="children"
      >
        {children}
      </Select>
    </Form.Item>
  );
};

EditableDropdown.propTypes = {
  isRequired: PropTypes.bool,
  isEditable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMenuDisabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  primaryField: PropTypes.string.isRequired,
  openAddEditDropdown: PropTypes.func,
  handleChangeFieldSelect: PropTypes.func,
  handleSearch: PropTypes.func,
  handleScroll: PropTypes.func,
};

export default React.memo(EditableDropdown);
