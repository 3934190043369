import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'semantic-ui-react';
import { toJS } from 'mobx';

import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import medNegStore from './MedNegStore';
import moment from 'moment';
import ModalAlert from './ModalAlert';

@observer
class MedNegToolbar extends React.Component {
  state = {
    openModalAlert: false,
    message: `You need to search or filter first before exporting results.`,
    checkedClaimtOnly: true,
  };

  openCreateNewCase = () => {
    localStorage.removeItem(`MedNegCaseId`);
    var id = 0;
    iframe.forceOpenTab('PostMedNeg', `?id=${id}&view=PostMedNeg`, {
      id,
      view: 'PostMedNeg',
    });
  };

  handleExport = () => {
    const { fromDate, toDate } = medNegStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant, keyword, actionRequiredById, filterNextTask } = medNegStore;
    if (
      claimant.Id ||
      fromDate ||
      toDate ||
      NextTaskPIC ||
      filter !== `All` ||
      filterOverdue !== `All` ||
      keyword ||
      actionRequiredById ||
      filterNextTask
    ) {
      this.openDownloadSearchResultsUrl();
    } else {
      this.setState({
        openModalAlert: true,
      });
    }
  };

  openDownloadSearchResultsUrl = () => {
    const { fromDate, toDate } = medNegStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant } = medNegStore;
    const searchOption = {
      ActionRequiredById: medNegStore.actionRequiredById,
      Claimant: Object.entries(medNegStore.claimant)
        .map(([key, val]) => `${key}=${val}`)
        .join('&'),
      CurPage: medNegStore.curPage,
      FilterOverdue: filterOverdue,
      FilterType: filter.search(/\s/) ? filter.replace(/\s+/g, '+') : filter,
      From: moment(fromDate)
        .startOf('day')
        .toISOString(),
      GetAllRecords: true,
      ClaimantId: medNegStore.claimant.Id,
      Keyword: medNegStore.keyword,
      NextPicId: NextTaskPIC,
      NumItemPerPage: 10000,
      SearchClaimantOnly: medNegStore.searchClaimantOnly,
      SortBy: medNegStore.sortKey,
      SortDirection: `DESC`,
      To: moment(toDate)
        .endOf('day')
        .toISOString(),
    };
    if (!claimant.Id) {
      delete searchOption.Claimant;
      delete searchOption.ClaimantId;
    }
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    if (!NextTaskPIC) {
      delete searchOption.NextPicId;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open('/MedNeg/ExportSearchResult?' + qs, '_blank');
  };

  handleFilterChange = (_, { name, value }) => {
    medNegStore.refetch({ [name]: value, curPage: 1 }, true);
  };

  renderOverDueLabel = item => {
    switch (item) {
      case `Request Received`:
        return `Send Requests to Providers`;
      default:
        return `All`;
    }
  };

  renderFilterOption = name => {
    const array = [];
    const data = toJS(medNegStore[name].itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: `All`, Count: `` });
    return array.map(opt => ({
      text: `${opt.FullName} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Id,
    }));
  };

  renderFilterLabel = (name, value) => {
    if (value === null) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(medNegStore[name].itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: `All`, Count: null });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => `${opt.FullName} ${opt.Count ? `(${opt.Count})` : ''}`);
      if (labels[0]) return labels[0];
      return `All`;
    }
  };

  renderStaffActionRequired = () => {
    return medNegStore.dataStaffAssignAction.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderCaseStatus = () => {
    const array = [];
    const data = toJS(medNegStore.dataDynamicMedNegStatus);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Status: `All`, Count: `` });
    return array.map(opt => ({
      text: `${opt.Status} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Status,
    }));
  };

  renderCaseStatusLabel = (name, value) => {
    if (value === `All`) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(medNegStore[name]);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Status: `All`, Count: `` });
      let labels = array
        .filter(opt => opt.Status === value)
        .map(
          opt =>
            `${name === `dataDynamicOverDueMedNegStatus` ? this.renderOverDueLabel(opt.Status) : opt.Status} ${
              opt.Count ? `(${opt.Count})` : ''
            }`,
        );
      if (labels[0]) return labels[0];
      return `All`;
    }
  };

  notInNextTask = [`Case Created`, `Cancelled No Fee`, `Service Billed`];

  renderTaskForCM = item => {
    switch (item) {
      case `Service Billed`:
        return `Pay Invoice`;
      default:
        return `All`;
    }
  };
  renderNextTaskLabel = (name, value) => {
    if (value === `All`) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(medNegStore[name].itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ NextTask: `All`, Count: ``, NextTaskText: `All` });
      let labels = array
        .filter(opt => opt.NextTask === value)
        .map(opt => `${opt.NextTaskText} ${opt.Count ? `(${opt.Count})` : ''}`);
      if (labels[0]) return labels[0];
      return `All`;
    }
  };
  renderNextTask = () => {
    let array = [];
    const data = toJS(medNegStore.dataDynamicMedNegNextTask);
    if (data) {
      array = data.itemList.filter(x => x.Count > 0);
      array.unshift({ NextTaskText: `All`, Count: ``, NextTask: `All` });
      return array.map(opt => ({
        text: `${opt.NextTaskText} ${opt.Count ? `(${opt.Count})` : ''}`,
        value: opt.NextTask,
      }));
    }
  };

  renderNextTaskOverDue = () => {
    const array = [];
    const data = toJS(medNegStore.dataDynamicOverDueMedNegStatus);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Status: `All`, Count: `` });
    return array.map(opt => ({
      text: `${this.renderOverDueLabel(opt.Status)} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Status,
    }));
  };

  onChecked = () => {
    const { searchClaimantOnly } = medNegStore;
    medNegStore.setFieldsValue({ searchClaimantOnly: !searchClaimantOnly });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = medNegStore;
    medNegStore.refetch(
      {
        claimant: {
          isFreeText: true,
          FullName: keyword,
        },
        curPage: 1,
      },
      true,
    );
  };

  handleChange = (_, { value }) => {
    medNegStore.setFieldsValue({ keyword: value });
  };

  handleReset = () => {
    medNegStore.resetStore();
  };
  handleRefetch = () => {
    medNegStore.refetch({}, true);
  };
  handleChangeSearchDropdown = e => {
    medNegStore.refetchClaimant({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };
  renderActionOptions = () => {
    const array = [];
    const data = toJS(medNegStore.dataClaimant.itemList);
    for (var i = 0; i < data.length; i++) {
      if (data[i].FullName !== null) {
        array.push(data[i]);
      }
    }
    array.unshift({ Id: '', FullName: `All` });
    return array.map(opt => ({
      text: `${opt.Id} - ${opt.FullName || ''}`,
      value: JSON.stringify({ Id: opt.Id, FullName: opt.FullName }),
    }));
  };
  renderActionText = value => {
    if (value.Id === '') {
      return 'Search a Claimant';
    } else {
      const array = [];
      const data = toJS(medNegStore.dataClaimant.itemList);
      for (var i = 0; i < data.length; i++) {
        if (data[i].FullName !== null) {
          array.push(data[i]);
        }
      }
      array.unshift({ Id: '', FullName: `All` });
      let labels = array.filter(opt => opt.Id === value.Id).map(opt => `${opt.FullName || ''}`);
      return labels[0];
    }
  };

  checkIsJSON = json => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const claimant = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (claimant.Id === `` || value === `All`) {
      medNegStore.refetch(
        {
          claimant: { Id: ``, FullName: `` },
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      medNegStore.resetSearch();
    } else {
      medNegStore.refetch(
        {
          claimant,
          keyword: claimant.FullName,
          curPage: 1,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  handleChangeFromTo = (_, { value }) => {
    medNegStore.setFieldsValue({ fromTo: value, showBookingToday: false });
  };

  handleChangeAppointmentFromTo = (_, { value }) => {
    medNegStore.setFieldsValue({ appointmentFromTo: value });
  };

  handleShowBookingForToday = () => {
    medNegStore.refetch(
      {
        fromTo: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
        curPage: 1,
      },
      true,
    );
  };

  handleScroll = event => {
    const target = event.target;
    const number = medNegStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      medNegStore.refetchClaimant({ numberSearch: number + 20 });
    }
  };

  renderModal = () => {
    const { message, openModalAlert } = this.state;
    return (
      <ModalAlert
        open={openModalAlert}
        modalParams={{ message }}
        onCancel={() => this.setState({ openModalAlert: false })}
      />
    );
  };

  render() {
    const {
      keyword,
      searchClaimantOnly,
      loadingClaimant,
      loading,
      fromTo,
      appointmentFromTo,
      totalItems,
    } = medNegStore;

    return (
      <form className="med-meg-toolbar">
        {this.renderModal()}

        <TableToolbar.Container style={{ justifyContent: 'flex-start' }}>
          <TableToolbar.Left as="div">
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <Checkbox
                label="Search claimant only"
                onChange={this.onChecked}
                checked={searchClaimantOnly}
                style={{ marginRight: `10px` }}
                disabled={loading}
              />
            )} */}
            {searchClaimantOnly && api.isAdminOrMagStaffOrAccounting() ? (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                onScroll={this.handleScroll}
                allowAdditions
                additionLabel=""
                selectOnNavigation={false}
                closeOnChange
                value={keyword}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                className="Toolbar-input"
                disabled={loading}
                style={{ width: '20rem' }}
              />
            ) : (
              <TableToolbar.Input
                value={keyword}
                onChange={this.handleChange}
                disabled={loading}
                style={{ width: '20rem' }}
              />
            )}
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: '5px' }}
              onClick={this.handleReset}
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefetch}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isAdminOrMagStaffOrAccounting() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                className={`positive`}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>

          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Left as="div">
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                style={{ marginLeft: `5px` }}
                onClick={this.openCreateNewCase}
                className={`positive`}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>}

              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExport}
                disabled={loading}
              >
                Export Searches
              </TableToolbar.HighLightButton>

              <TableToolbar.HighLightButton
                color={`blue`}
                onClick={this.handleShowBookingForToday}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                Show Today's Bookings
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          )}
          <TableToolbar.Left>
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.RangePicker
            value={fromTo}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          <TableToolbar.RangePicker
            value={appointmentFromTo}
            onChange={this.handleChangeAppointmentFromTo}
            className="Toolbar-range-picker"
            placeholder="EO Confirmed From - To"
            disabled={loading}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Next Task PIC`}
              value={`${this.renderFilterLabel(`dataStaffAssign`, medNegStore.NextTaskPIC)}`}
              name={'NextTaskPIC'}
              options={this.renderFilterOption(`dataStaffAssign`)}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}

          <TableToolbar.Filter
            selection
            label="Next Task"
            name="filterNextTask"
            value={`${this.renderNextTaskLabel(`dataDynamicMedNegNextTask`, medNegStore.filterNextTask)}`}
            options={this.renderNextTask()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {/* {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Next Task Overdue`}
                value={`${this.renderCaseStatusLabel(
                  `dataDynamicOverDueMedNegStatus`,
                  medNegStore.filterOverdue,
                )}`}
                name={'filterOverdue'}
                options={this.renderNextTaskOverDue()}
                onChange={this.handleFilterChange}
                disabled={loading}
              />
            )} */}

          <TableToolbar.Filter
            selection
            label={`Case Status`}
            value={`${this.renderCaseStatusLabel(`dataDynamicMedNegStatus`, medNegStore.filter)}`}
            name={'filter'}
            options={this.renderCaseStatus()}
            onChange={this.handleFilterChange}
            disabled={loading}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Action Required`}
              value={`${this.renderFilterLabel(`dataStaffAssignAction`, medNegStore.actionRequiredById)}`}
              name={'actionRequiredById'}
              options={this.renderFilterOption(`dataStaffAssignAction`)}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
        </TableToolbar.Container>
      </form>
    );
  }
}

export default MedNegToolbar;
