import * as api from '../../../stores/api';
import { showUnauthorizedError } from '../../../utils/errors';
import tabConfig from './tabConfig';

const checkTouristAndShowError = () => {
  if (api.isTourist()) {
    showUnauthorizedError();
    return false;
  }
  return true;
};

tabConfig.PostAssessment.authorize = checkTouristAndShowError;
tabConfig.ManageCalendar.authorize = checkTouristAndShowError;

const buildFullUrl = (tab, q) => {
  const c = tabConfig[tab];
  return `/${c.controller}/${tab}${q || ''}`;
};

const pathToTab = Object.entries(tabConfig).reduce((m, [k, v]) => {
  m[v.path] = k;
  return m;
}, {});

// Manually add view because it's duplicated with add
const tabToPathDetail = {
  PostAssessment: '/view/view-assessment',
  AddEditClinicalNote: '/view/view-clinical-note',
  PostMRI: '/view/view-mri',
  PostSupplementary: '/view/view-supplementary-report',
  PostFileReview: '/view/view-file-review',
  PostMedNeg: '/view/view-med-neg',
};
const pathToPathDetail = {};
const pathDetailToPath = {};
Object.entries(tabToPathDetail).forEach(([t, p]) => {
  pathToTab[p] = t;
  pathToPathDetail[tabConfig[t].path] = p;
  pathDetailToPath[p] = tabConfig[t].path;
});

const isIframePath = path => {
  return !!pathToTab[path];
};

export { tabConfig, buildFullUrl, pathToTab, isIframePath, tabToPathDetail, pathToPathDetail, pathDetailToPath };
