import React from 'react';
import { Form, Input, Select, DatePicker, notification, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { formatDate } from '../../../utils/functions';
import AddEditStore from './Store';
import moment from 'moment';
import { checkDuplicateEmail, checkDuplicateLastName } from './service';
import { convertPhoneFaxNumber, convertMobileNumber } from '@utils/functions';

@observer
class GeneralInformation extends React.Component {
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };
  openModalDuplicate = (arrayDuplicate, type) => {
    return AddEditStore.toggleModal(true, {
      modalType: 'checkDuplicate',
      dataDuplicate: arrayDuplicate || [],
      type,
    })();
  };
  handleCheckDuplicate = (id, value, fieldName) => {
    AddEditStore.setFieldsValue({ loadingCheckDuplicate: true });
    if (fieldName === 'Email') {
      Promise.all([checkDuplicateEmail(id, value)])
        .then(([duplicateValue]) => {
          this.openModalDuplicate(duplicateValue.lstObjs, 'Email');
          AddEditStore.setFieldsValue({ loadingCheckDuplicate: false });
        })
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred on the server',
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingCheckDuplicate: false });
        });
    }
    if (fieldName === 'LastName') {
      Promise.all([checkDuplicateLastName(id, value)])
        .then(([duplicateValue]) => {
          this.openModalDuplicate(duplicateValue.lstObjs, 'LastName');
          AddEditStore.setFieldsValue({ loadingCheckDuplicate: false });
        })
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred on the server',
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingCheckDuplicate: false });
        });
    }
  };

  openModal = options => {
    return AddEditStore.toggleModal(true, options);
  };

  handleChangeStatus = option => {
    if (option === 0) {
      AddEditStore.handleEnableCase(AddEditStore.claimantInfo.Id);
    } else {
      AddEditStore.handleDisableCase(AddEditStore.claimantInfo.Id);
    }
  };

  render() {
    const { claimantInfo, greetingTitle, cityList } = AddEditStore;
    return (
      <Form name="general-information">
        {claimantInfo.Id > 0 && (
          <Form.Item className="fluid-field">
            <Checkbox disabled={true} checked={claimantInfo.IsActive}>
              Active Status
              <Link
                to="#"
                onClick={this.openModal({ modalType: 'viewStatusHistory', id: claimantInfo.Id, record: claimantInfo })}
              >
                {' '}
                Recent changes{'  '}
              </Link>
              {!claimantInfo.IsActive && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="green"
                  onClick={AddEditStore.toggleModal(true, {
                    modalType: 'confirm',
                    message: `Do you want to enable this claimant?`,
                    onOk: () => this.handleChangeStatus(0),
                  })}
                >
                  Enable
                </Button>
              )}
              {claimantInfo.IsActive && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="red"
                  onClick={this.openModal({
                    modalType: 'disable',
                    modalParams: { Id: claimantInfo.Id, Record: claimantInfo },
                  })}
                >
                  Disable
                </Button>
              )}
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item label="Claimant ID" required>
          <Input size="large" value={claimantInfo.Id === 0 ? '' : claimantInfo.Id} disabled={true} />
        </Form.Item>
        <Form.Item label="Title">
          <Select allowClear size="large" value={claimantInfo.Title} onChange={AddEditStore.handleFieldChange('Title')}>
            {greetingTitle.map((i, index) => (
              <Select.Option key={index} value={i.Title}>
                {i.Title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="First Name"
          required
          validateStatus={AddEditStore.errors['FirstName'] ? 'error' : null}
          help={AddEditStore.errors['FirstName']}
        >
          <Input size="large" value={claimantInfo.FirstName} onChange={AddEditStore.handleFieldChange('FirstName')} />
        </Form.Item>
        <Form.Item
          required
          validateStatus={AddEditStore.errors['LastName'] ? 'error' : null}
          help={AddEditStore.errors['LastName']}
          label={
            <React.Fragment>
              Last Name
              <button
                className="check-duplicate"
                onClick={() => this.handleCheckDuplicate(claimantInfo.Id, claimantInfo.LastName, 'LastName')}
              >
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={claimantInfo.LastName} onChange={AddEditStore.handleFieldChange('LastName')} />
        </Form.Item>
        <Form.Item
          label="Phone"
          validateStatus={AddEditStore.errors['HomePhone'] ? 'error' : null}
          help={AddEditStore.errors['HomePhone']}
        >
          <Input
            size="large"
            value={convertPhoneFaxNumber(claimantInfo.HomePhone)}
            onChange={AddEditStore.handleFieldChange('HomePhone')}
          />
        </Form.Item>
        <Form.Item
          label="Mobile"
          validateStatus={AddEditStore.errors['Telephone'] ? 'error' : null}
          help={AddEditStore.errors['Telephone']}
        >
          <Input
            size="large"
            value={convertMobileNumber(claimantInfo.Telephone)}
            onChange={AddEditStore.handleFieldChange('Telephone')}
          />
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['Email'] ? 'error' : null}
          help={AddEditStore.errors['Email']}
          label={
            <React.Fragment>
              Email
              <button
                className="check-duplicate"
                onClick={() => this.handleCheckDuplicate(claimantInfo.Id, claimantInfo.Email, 'Email')}
              >
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={claimantInfo.Email} onChange={AddEditStore.handleFieldChange('Email')} />
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          validateStatus={AddEditStore.errors['DOB'] ? 'error' : null}
          help={AddEditStore.errors['DOB']}
        >
          <DatePicker
            size="large"
            value={this.convertDate(claimantInfo.DOB)}
            onChange={AddEditStore.handleFieldChange('DOB')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item label="Address">
          <Input size="large" value={claimantInfo.Address} onChange={AddEditStore.handleFieldChange('Address')} />
        </Form.Item>
        <Form.Item label="Suburb">
          <Input size="large" value={claimantInfo.Suburb} onChange={AddEditStore.handleFieldChange('Suburb')} />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input size="large" value={claimantInfo.Postcode} onChange={AddEditStore.handleFieldChange('Postcode')} />
        </Form.Item>
        <Form.Item label="State">
          <Select
            size="large"
            allowClear
            value={claimantInfo.CityId}
            onChange={AddEditStore.handleFieldChange('CityId')}
          >
            {cityList &&
              cityList.itemList.map(i => (
                <Select.Option key={i.CityId} value={i.CityId}>
                  {i.CityName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Requires Guardian" className="fluid-field">
          <Checkbox
            checked={claimantInfo.RequiresGuardian}
            onChange={AddEditStore.handleChangeRequiresGuardian('RequiresGuardian')}
          ></Checkbox>
        </Form.Item>
        {claimantInfo.RequiresGuardian && (
          <Form.Item label="Guardian's Name">
            <Input
              size="large"
              value={claimantInfo.GuardianName}
              onChange={AddEditStore.handleFieldChange('GuardianName')}
            />
          </Form.Item>
        )}
        {claimantInfo.RequiresGuardian && (
          <Form.Item label="Relationship to Claimant">
            <Input
              size="large"
              value={claimantInfo.RelationshipToClaimant}
              onChange={AddEditStore.handleFieldChange('RelationshipToClaimant')}
            />
          </Form.Item>
        )}
        {/* <Form.Item label="Suburb">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="State">
          <Select size="large" value="1">
            <Select.Option value="1">ACT</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Country" style={{ marginBottom: 0 }}>
          <Select size="large" value="1">
            <Select.Option value="1">Australia</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    );
  }
}

export default GeneralInformation;
