import customFetch from '../../utils/customFetch';

export const getNewSpecialist = (curPage = 1, numberItemPerPage = 20) => {
  return customFetch(`/Report/GetStatisticsSpecialistStatus?curPage=${curPage}&numItemOfPage=${numberItemPerPage}`, {
    method: 'GET',
  });
};

export const getNewClient = (curPage = 1, numberItemPerPage = 20) => {
  return customFetch(`/Report/GetStatisticsNewClient?curPage=${curPage}&numItemOfPage=${numberItemPerPage}`, {
    method: 'GET',
  });
};

export const getNewClinic = (curPage = 1, numberItemPerPage = 20) => {
  return customFetch(`/Report/GetStatisticsNewClinic?curPage=${curPage}&numItemOfPage=${numberItemPerPage}`, {
    method: 'GET',
  });
};

export const getStatisticsCMBooking = type => {
  return customFetch(`/Report/GetStatisticCmBookings?type=${type}`, {
    method: 'GET',
  });
};

export const getStatisticsStaffBooking = type => {
  return customFetch(`/Report/GetStatisticStaffBookings?type=${type}`, {
    method: 'GET',
  });
};

export const getStatisticsDoctorBooking = type => {
  return customFetch(`/Report/GetStatisticDoctorBookings?type=${type}`, {
    method: 'GET',
  });
};
