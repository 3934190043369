import { Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';

import M4ModifierExclusionStore from './Store';
import M4ModifierExclusionTable from './Table';
import M4ModifierExclusionToolbar from './Toolbar';
import M4ModifierExclusionModal from './Modal';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class M4ModifierExclusion extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/m4-modifier', true);
      return;
    }
    M4ModifierExclusionStore.fetchData(true);
  }
  render() {
    const { data, loading, open } = M4ModifierExclusionStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <M4ModifierExclusionToolbar />
            <M4ModifierExclusionTable />
            {open && <M4ModifierExclusionModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default M4ModifierExclusion;
