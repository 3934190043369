import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import moment from 'moment';

const columns = [
  { title: 'Old Staff' },
  { title: 'New Staff' },
  { title: 'Change Date/Time' },
  { title: 'Updated by' },
];

@observer
class ModalCreatedByHistory extends React.Component {
  renderTableBody = data => {
    return data.map(({ ...record }, id) => (
      <Table.Row key={id}>
        <Table.Cell>{record.OldValue}</Table.Cell>
        <Table.Cell>{record.NewValue}</Table.Cell>
        <Table.Cell>
          {record.Updated
            ? moment(formatDate(record.Updated, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.UpdateBy}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { dataCreatedHistories, record } = AddEditStore.modalParams;
    const colSpan = columns.length;
    const { toggleModal, open } = AddEditStore;

    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1000}
          onCancel={toggleModal(false)}
          title={`Created By history - ${record.FirstName} ${record.LastName}`}
        >
          {!dataCreatedHistories ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled>
              <TableHeader columns={columns} />
              <Table.Body>
                {dataCreatedHistories && dataCreatedHistories.length ? (
                  this.renderTableBody(dataCreatedHistories)
                ) : (
                  <TableEmptyMessage colSpan={colSpan} />
                )}
              </Table.Body>
            </Table>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalCreatedByHistory;
