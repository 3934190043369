import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEdit from './AddEdit';
import Store from './Store';

function ModuleModal() {
  const { modalType } = Store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...Store} onCancel={Store.toggleModal(false)} />;
  }
  if (modalType === 'addOrEdit') {
    return <AddEdit {...Store} onCancel={Store.toggleModal(false)} />;
  }
}

export default ModuleModal;
