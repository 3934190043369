import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';
import moment from 'moment';

class DoctorReportsStore {
  @observable loadingClientSearch = true;
  @observable dataClientSearch = null;
  @observable loadingCM = true;
  @observable dataCM = null;
  @observable loading = false;
  @observable type = 1;

  @observable dataArrayIME = null;
  @observable dataArrayFR = null;
  @observable dataArraySupp = null;
  @observable dataIME = null;
  @observable dataFR = null;
  @observable dataSupp = null;
  @observable dataCR = null;
  @observable dataArrayCR = null;
  @observable dataMN = null;
  @observable dataArrayMN = null;

  @observable tabIsActive = 'ime';

  @observable AssessmentAppointmentAttended = true;
  @observable AssessmentAppointmentConfirmed = true;
  @observable AssessmentClientInvoice = true;
  @observable AssessmentCancelledNoFee = true;
  @observable AssessmentCancelledWithFee = true;
  @observable AssessmentDictationReceived = true;
  @observable AssessmentIMERequested = true;
  @observable AssessmentPaperWorkReceived = true;
  @observable AssessmentReportCompleted = true;
  @observable AssessmentCorrectedReportReturned = true;
  @observable AssessmentReportDraft = true;
  @observable AssessmentServicePaid = true;
  @observable AssessmentSpecialistInvoice = true;
  // @observable AssessmentServiceInvoice = true;

  @observable AssessmentSelectAll = true;

  @observable searchOpt = {};

  @observable FileRevBilled = true;
  @observable FileRevCancelledWithFee = true;
  @observable FileRevCancelledNoFee = true;
  @observable FileRevReportCompleted = true;
  @observable FileRevReportDraftSentToDoctor = true;
  @observable FileRevRequestSentToProvider = true;
  @observable FileRevRequested = true;
  @observable FileRevSelectAll = true;
  @observable FileRevServicePaid = true;
  @observable FileRevReturnedFromDoctors = true;
  @observable FileRevDictationReceived = true;

  @observable SuppBilled = true;
  @observable SuppCancelledNoFee = true;
  @observable SuppCancelledWithFee = true;
  @observable SuppReportCompleted = true;
  @observable SuppReportDraftSentToDoctor = true;
  @observable SuppRequestSentToProvider = true;
  @observable SuppRequested = true;
  @observable SuppSelectAll = true;
  @observable SuppServicePaid = true;
  @observable SuppReportReturnedFromDoctor = true;
  @observable SuppDictationReceived = true;

  //CR
  @observable CRRequestReceived = true;
  @observable CRRequestSentToSpecialist = true;
  @observable CRPrepaymentRequired = true;
  @observable CRPrepaymentPaid = true;
  @observable CRCRFilesSent = true;
  @observable CRCancelledNoFee = true;
  @observable CRCancelledWithFee = true;
  @observable CROnHold = true;
  @observable CRServiceBilled = true;
  @observable CRServicePaid = true;
  @observable CRSelectAll = true;
  //MN
  @observable MNSelectAll = true;
  @observable MNRequestReceived = true;
  @observable MNRequestSentToSpecialist = true;
  @observable MNCloseWithoutBookings = true;
  @observable MNCloseWithBookings = true;

  @observable CustomerId = 0;
  @observable CaseManagerId = 0;

  @observable numberClientSearch = 30;
  @observable numberCMSearch = 30;

  @observable keywordClient = ``;
  @observable keywordCM = ``;
  @observable fromTo = `${moment()
    .subtract(90, 'day')
    .format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
  @observable checkFrom = ``;
  @observable checkTo = ``;

  @observable open = false;
  @observable modalParams = {};

  @observable openModelAccessCode = false;
  @observable exportUrl = '';
  @observable exportQueryParams = '';

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate,
      toDate,
    };
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action fetchData = () => {
    this.loading = true;
    this.dataArrayIME = null;
    this.dataArraySupp = null;
    this.dataArrayFR = null;
    this.dataArrayCR = null;
    this.dataArrayMN = null;
    const { fromDate, toDate } = this.getDateRange();
    const body = {
      AssessmentAppointmentAttended: this.AssessmentAppointmentAttended,
      AssessmentAppointmentConfirmed: this.AssessmentAppointmentConfirmed,
      AssessmentClientInvoice: this.AssessmentClientInvoice,
      AssessmentSpecialistInvoice: this.AssessmentSpecialistInvoice,
      //AssessmentServiceInvoice: this.AssessmentServiceInvoice,
      AssessmentCancelledNoFee: this.AssessmentCancelledNoFee,
      AssessmentCancelledWithFee: this.AssessmentCancelledWithFee,
      AssessmentDictationReceived: this.AssessmentDictationReceived,
      AssessmentIMERequested: this.AssessmentIMERequested,
      AssessmentPaperWorkReceived: this.AssessmentPaperWorkReceived,
      AssessmentReportCompleted: this.AssessmentReportCompleted,
      AssessmentCorrectedReportReturned: this.AssessmentCorrectedReportReturned,
      AssessmentReportDraft: this.AssessmentReportDraft,
      AssessmentSelectAll: this.AssessmentSelectAll,
      AssessmentServicePaid: this.AssessmentServicePaid,
      CaseManagerId: this.CaseManagerId,
      CustomerId: this.CustomerId,
      FileRevBilled: this.FileRevBilled,
      FileRevCancelledNoFee: this.FileRevCancelledNoFee,
      FileRevCancelledWithFee: this.FileRevCancelledWithFee,
      FileRevReportCompleted: this.FileRevReportCompleted,
      FileRevReportDraftSentToDoctor: this.FileRevReportDraftSentToDoctor,
      FileRevRequestSentToProvider: this.FileRevRequestSentToProvider,
      FileRevRequested: this.FileRevRequested,
      FileRevSelectAll: this.FileRevSelectAll,
      FileRevServicePaid: this.FileRevServicePaid,
      FileRevReturnedFromDoctors: this.FileRevReturnedFromDoctors,
      FileRevDictationReceived: this.FileRevDictationReceived,
      From: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      SelectedCaseType: this.type,
      SuppBilled: this.SuppBilled,
      SuppCancelledNoFee: this.SuppCancelledNoFee,
      SuppCancelledWithFee: this.SuppCancelledWithFee,
      SuppReportCompleted: this.SuppReportCompleted,
      SuppReportDraftSentToDoctor: this.SuppReportDraftSentToDoctor,
      SuppRequestSentToProvider: this.SuppRequestSentToProvider,
      SuppRequested: this.SuppRequested,
      SuppSelectAll: this.SuppSelectAll,
      SuppServicePaid: this.SuppServicePaid,
      SuppReportReturnedFromDoctor: this.SuppReportReturnedFromDoctor,
      SuppDictationReceived: this.SuppDictationReceived,
      To: toDate ? moment(toDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      CRRequestReceived: this.CRRequestReceived,
      CRRequestSentToSpecialist: this.CRRequestSentToSpecialist,
      CRPrepaymentRequired: this.CRPrepaymentRequired,
      CRPrepaymentPaid: this.CRPrepaymentPaid,
      CRCRFilesSent: this.CRCRFilesSent,
      CRCancelledNoFee: this.CRCancelledNoFee,
      CRCancelledWithFee: this.CRCancelledWithFee,
      CROnHold: this.CROnHold,
      CRServiceBilled: this.CRServiceBilled,
      CRServicePaid: this.CRServicePaid,
      MNRequestReceived: this.MNRequestReceived,
      MNRequestSentToSpecialist: this.MNRequestSentToSpecialist,
      MNCloseWithoutBookings: this.MNCloseWithoutBookings,
      MNCloseWithBookings: this.MNCloseWithBookings,
    };
    customFetch('/Report/GetClientReport', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then(
        action(data => {
          this.searchOpt = body;
          this.loading = false;
          if (this.type === 1) {
            this.dataArrayIME = Object.entries(data.reports);
            this.dataIME = data;
          } else if (this.type === 2) {
            this.dataArrayFR = Object.entries(data.reports);
            this.dataFR = data;
          } else if (this.type === 3) {
            this.dataSupp = data;
            this.dataArraySupp = Object.entries(data.reports);
          } else if (this.type === 4) {
            this.dataCR = data;
            this.dataArrayCR = Object.entries(data.reports);
          } else if (this.type === 5) {
            this.dataMN = data;
            this.dataArrayMN = Object.entries(data.reports);
          }
        }),
      )
      .catch(
        action(
          this.toggleModal(true, {
            modalType: `confirm`,
            message: `Error occured while contacting server. Please report this error to system administrator and try again later`,
          }),
        ),
      )
      .then(
        action(() => {
          this.loading = false;
        }),
      );
  };

  //

  @action fetchClientSearch = () => {
    this.loadingClientSearch = true;
    customFetch('/Company/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        NumItemPerPage: this.numberClientSearch,
        Showing: `active`,
        curPage: 1,
        keyword: this.keywordClient,
      }),
    }).then(
      action(data => {
        this.dataClientSearch = data;
        this.loadingClientSearch = false;
      }),
    );
  };

  @action fetchCMSearch = () => {
    this.loadingCM = true;
    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        NumItemPerPage: this.numberCMSearch,
        Showing: `active`,
        curPage: 1,
        keyword: this.keywordCM,
      }),
    }).then(
      action(data => {
        this.dataCM = data;
        this.loadingCM = false;
      }),
    );
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action refetch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData();
  };
  @action refetchClientSearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClientSearch();
  };
  @action refetchCMSearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCMSearch();
  };
}

export default new DoctorReportsStore();
