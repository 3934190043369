import './MailDetail.scss';

import iframe from '@components/dashboard-routes/Iframe/iframeData';
import router from '@stores/router';
import { PubSubTopics } from '@utils/constants';
import { DatePicker, notification } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import PubSub from 'pubsub-js';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Form, Grid, Header } from 'semantic-ui-react';
import ForwardMailModal from './ForwardMailModal';

import customFetch from '@utils/customFetch';

import * as api from '../../../stores/api';
import { Folders, InquiryStatuses } from './constants';
import Store from './Store';
import dashboardStore from '@stores/dashboard';

@observer
class MailDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      inquiryStatus: 0,
      caseNo: '',
      inquiryType: '',
      folder: 0,
      caseOwnerId: null,
      owners: [],
      isForwardModelOpen: false,
    };
  }

  handleOnChangeInquiryStatus = (_, { value }) => {
    this.setState({ inquiryStatus: value });
    if (value !== 0) {
      this.setState({ closedDate: moment() });
    }
  };

  handleOnChangeInquiryType = (_, { value }) => {
    this.setState({ inquiryType: value });
  };

  handleOnChangeCaseType = (_, { value }) => {
    this.setState({ folder: value });
  };

  handleOnChangeCaseOwner = (_, { value }) => {
    this.setState({ caseOwnerId: value });
  };

  handleOnSaveEmail = () => {
    Store.saveEmail({ ...this.state });
    notification.destroy();
    notification.success({
      message: 'Edit Email',
      description: 'Data has been saved successfully.',
      placement: 'topRight',
    });
  };

  handleOnReplyEmail = () => {
    Store.setFieldsValue({ isReplyModalOpen: true });
  };

  handleOnForwardEmail = () => {
    this.setState({ isForwardModelOpen: true });
  };

  handleOnOpenActionNotesModal = () => {
    Store.setFieldsValue({ isActionNotesModalOpen: true });
  };

  handleOnRequestChangeCaseNo = () => {
    router.history.push('/view/universal-search');
  };

  handleOnChangeClosedDate = momentValue => {
    this.setState({ closedDate: momentValue });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.selectedMail.id !== state.id) {
      return {
        id: props.selectedMail.id,
        inquiryStatus: props.selectedMail.inquiryStatus,
        caseNo: props.selectedMail.caseNo,
        closedDate: props.selectedMail.closedDate,
        inquiryType: props.selectedMail.inquiryType,
        folder: props.selectedMail.folder,
        caseOwnerId: props.selectedMail.caseOwnerId ? props.selectedMail.caseOwnerId : api.currentUser.data.id, // Auto set default caseOwnerId to currentUser int first opening
      };
    }
    return null;
  }

  componentDidMount() {
    PubSub.subscribe(PubSubTopics.SelectCaseNo, this.handleCaseNoSelected);
  }

  componentWillUnmount() {
    PubSub.unsubscribe(PubSubTopics.SelectCaseNo);
  }

  handleCaseNoSelected = (msg, data) => {
    // Set folder = 2 (existing cases)
    this.setState({ caseNo: data, folder: 2 });
  };

  openCaseDetails = async () => {
    const { caseNo } = this.state;
    if (isEmpty(caseNo)) return;
    const parts = caseNo.split('-');
    if (parts.length < 2) return;

    Store.setFieldsValue({ loading: true });
    const res = await customFetch('/IMSData/GetCaseIdByCaseNo', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ caseNo }),
    });
    Store.setFieldsValue({ loading: false });

    if (res.id === 0) {
      notification.destroy();
      notification.warning({
        message: 'Open Case Details',
        description: 'Failed to get Case ID. Case does not exist.',
        placement: 'topRight',
      });
      return;
    }

    switch (parts[0]) {
      case 'A':
        localStorage.setItem('AssessmentCaseId', `Case ${caseNo}`);
        dashboardStore.close('/view/add-edit-ime-assessment-2');
        setTimeout(() => {
          dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${res.id}`);
        });
        break;
      case 'S':
        localStorage.setItem('SupplementaryCaseId', `Case ${caseNo}`);
        dashboardStore.close('/view/add-edit-supplementary-report-2');
        setTimeout(() => {
          dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${res.id}`);
        });
        break;
      case 'F':
        localStorage.setItem('FileReviewCaseId', caseNo);
        dashboardStore.close('/view/add-edit-file-review-2');
        setTimeout(() => {
          dashboardStore.open(`/view/add-edit-file-review-2?id=${res.id}`);
        });
        break;
      case 'C':
        localStorage.setItem('ClinicalRecordCaseId', caseNo);
        dashboardStore.close('/view/add-edit-clinical-notes-2');
        setTimeout(() => {
          dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${res.id}`);
        });
        break;
      case 'MN':
        localStorage.setItem('MedNegCaseId', `Case ${caseNo}`);
        iframe.forceOpenTab('PostMedNeg', `?id=${res.id}&view=PostMedNeg`, {
          id: res.id,
          view: 'PostMedNeg',
        });
        break;
      case 'I':
      case 'FI':
      default:
        notification.destroy();
        notification.error({
          message: 'Open Case Details',
          description: 'Not supported.',
          placement: 'topRight',
        });
        break;
    }
  };

  render() {
    const { selectedMail } = this.props;

    return (
      <div className="mail-detail infinite-scroll">
        <Grid padded>
          <Grid.Row className="no-padding">
            {selectedMail.isRoot && (
              <Form className="action-note-form">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Case No</label>
                    {this.state.caseNo && (
                      <Link to="#" className="case-no" onClick={this.openCaseDetails}>
                        {this.state.caseNo}
                      </Link>
                    )}
                    <Button
                      icon="linkify"
                      labelPosition="left"
                      content="Link to Case"
                      onClick={this.handleOnRequestChangeCaseNo}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Dropdown
                    search
                    selection
                    label="Case Owner"
                    value={this.state.caseOwnerId}
                    onChange={this.handleOnChangeCaseOwner}
                    options={Store.picOptions}
                  />
                  <Form.Dropdown
                    selection
                    label="Case Type"
                    value={this.state.folder}
                    onChange={this.handleOnChangeCaseType}
                    options={Folders}
                  />
                  <Form.Dropdown
                    search
                    label="Inquiry Type"
                    selection
                    value={this.state.inquiryType}
                    onChange={this.handleOnChangeInquiryType}
                    options={Store.inquiryTypeOptions}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Dropdown
                    label="Inquiry Status"
                    selection
                    upward
                    value={this.state.inquiryStatus}
                    onChange={this.handleOnChangeInquiryStatus}
                    options={InquiryStatuses}
                  />
                  <Form.Field disabled={this.state.inquiryStatus === 0}>
                    <label>Closed Date</label>
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      value={this.state.closedDate ? moment(this.state.closedDate) : null}
                      onChange={this.handleOnChangeClosedDate}
                    />
                  </Form.Field>
                  <Form.Field className="save-button">
                    <Button
                      color="blue"
                      icon="edit"
                      labelPosition="left"
                      content="Save"
                      onClick={this.handleOnSaveEmail}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
            <Divider />
          </Grid.Row>
          <Grid.Row className="no-padding-top">
            <Header as="h3">
              {selectedMail.isRoot && (
                <span>
                  [{selectedMail.inquiryStatusText}] [{selectedMail.caseTypeText}]{' '}
                </span>
              )}
              {selectedMail.subject}
              <Header.Subheader>
                Received Date: {moment(selectedMail.createdDate).format('DD/MM/YYYY @ HH:mm')}
              </Header.Subheader>
              <Header.Subheader>Sender: {selectedMail.sender}</Header.Subheader>
              <Header.Subheader>Receiver: {selectedMail.receiver}</Header.Subheader>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Button icon="reply" labelPosition="left" content="Reply" onClick={this.handleOnReplyEmail} />
            <Button icon="forward" labelPosition="left" content="Forward" onClick={this.handleOnForwardEmail} />
            <Button
              color={
                selectedMail.actionCount > 0
                  ? selectedMail.actionCount === selectedMail.closedActionCount
                    ? 'blue'
                    : 'red'
                  : undefined
              }
              icon="sticky note outline"
              labelPosition="left"
              content="Action Notes"
              onClick={this.handleOnOpenActionNotesModal}
            />
          </Grid.Row>
          <Grid.Row>
            <div dangerouslySetInnerHTML={{ __html: selectedMail.body }} />
          </Grid.Row>
        </Grid>
        {this.state.isForwardModelOpen && (
          <ForwardMailModal selectedMail={selectedMail} onClose={() => this.setState({ isForwardModelOpen: false })} />
        )}
      </div>
    );
  }
}
export default MailDetail;
