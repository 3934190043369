import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteRecord } from './Service';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';
import iframe from '../../dashboard-routes/Iframe/iframeData';

const columns = [
  { title: '' },
  { title: 'Transaction No', sortKey: 'Id' },
  { title: 'Transaction Date', sortKey: 'Created' },
  { title: 'Case No' },
  { title: 'Facility Agreement No' },
  { title: 'Facility Provider' },
  { title: 'Service Type' },
  { title: 'Claim No' },
  { title: 'Claimant' },
  { title: 'Matter Type' },
  { title: 'Report Status' },
  { title: 'Report Status Date' },
  { title: 'Facility Transaction Amount' },
  { title: 'Invoice No' },
  { title: 'Invoice Date' },
  { title: 'Estimate Settlement Date' },
  { title: 'Actual Settlement Date' },
];

@observer
class AMAAftercareRuleTable extends React.Component {
  openModal = options => {
    return store.toggleModal(true, options);
  };
  handleDelete = id => {
    store.setFieldsValue({ loading: true });
    Promise.all([deleteRecord(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          store.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    localStorage.setItem('FacilityReport', `${record.ReportNo} - Funded Transaction`);
    dashboardStore.close('/view/add-edit-funding-report');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-report?id=${id}`);
    });
  };

  handleOpenFundingRequest = (rqId, rqNo) => {
    // localStorage.setItem('FundingRequest', rqNo);
    // dashboardStore.close('/view/add-edit-funding-request');
    // return setTimeout(() => {
    //   dashboardStore.open(`/view/add-edit-funding-request?id=${rqId}`);
    // });
  };

  openCaseDetail = record => {
    console.log(1);
    if (record.ServiceType === 1) {
      localStorage.setItem('AssessmentCaseId', `Case ${record.AdditionalInfo.CaseNo}`);
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${record.CaseId}`);
      });
    } else if (record.ServiceType === 2) {
      localStorage.setItem('FileReviewCaseId', `Case ${record.AdditionalInfo.CaseNo}`);
      dashboardStore.close('/view/add-edit-file-review-2');
      return setTimeout(() => {
        dashboardStore.open(`/view/add-edit-file-review-2?id=${record.CaseId}`);
      });
    } else if (record.ServiceType === 3) {
      localStorage.setItem('SupplementaryCaseId', `Case ${record.AdditionalInfo.CaseNo}`);
      dashboardStore.close('/view/add-edit-supplementary-report-2');
      return setTimeout(() => {
        dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${record.CaseId}`);
      });
    } else if (record.ServiceType === 4) {
      localStorage.setItem('ClinicalRecordCaseId', record.AdditionalInfo.CaseNo);
      dashboardStore.close('/view/add-edit-clinical-notes-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${record.CaseId}`);
      });
    } else if (record.ServiceType === 5) {
      localStorage.setItem('MedNegCaseId', `Case ${record.AdditionalInfo.CaseNo}`);
      iframe.forceOpenTab('PostMedNeg', `?id=${record.CaseId}&view=PostMedNeg`, {
        id: record.CaseId,
        view: 'PostMedNeg',
      });
    }
  };

  openInvoiceDetail = record => {
    localStorage.setItem('InvoiceCaseId', `INV-${record.InvoiceNo}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${record.Id}&view=ViewInvoice`, {
      id: record.Id,
      view: 'ViewInvoice',
    });
  };

  generateInvoice = record => {
    window.open('/Invoice/ViewBilling?invoiceId=' + record.Id, '_blank');
  };

  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            {/* <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Deleting this rule can affect current invoices. Are you sure to continue??',
                onOk: () => this.handleDelete(id),
              })}
              //onClick={() => this.handleDelete(id)}
            >Delete</Menu.Item> */}
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  openFundingAgreement = record => {
    console.log(1);
    localStorage.setItem('FundingAgreement', record.AgreementNo + '- Agreement');
    dashboardStore.close('/view/add-edit-funding-agreement');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-agreement?id=${record.Id}`);
    });
  };

  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      let arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      let i = +arr[0] + 'e' + sig + (+arr[1] + scale);
      let j = Math.round(i);
      let k = +(j + 'e-' + scale);
      return k;
    }
  }

  numberDecimalFormatter = value => {
    return !!value ? '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ 0';
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>}
        {api.isAdmin() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record)}>
              {record.ReportNo}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.ReportNo}</Table.Cell>
        )}
        <Table.Cell>{record.Created ? moment(record.Created).format('DD/MM/YYYY') : 'N/A'}</Table.Cell>
        {/* <Table.Cell className="Cell-link Cell-nowrap">
          {record.ServiceType === 1 && <Link to="#" onClick={() => this.openIMECaseDetail(record.CaseId, record.AdditionalInfo.CaseNo)}>
              {record.AdditionalInfo && record.AdditionalInfo.CaseNo}
          </Link>}
        </Table.Cell> */}
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.AdditionalInfo && record.AdditionalInfo.CaseNo ? (
            <Link to="#" onClick={() => this.openCaseDetail(record)}>
              {record.AdditionalInfo && record.AdditionalInfo.CaseNo}
            </Link>
          ) : (
            'N/A'
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.FundingAgreementObj && record.FundingAgreementObj.Id ? (
            <Link to="#" onClick={() => this.openFundingAgreement(record.FundingAgreementObj)}>
              {record.FundingAgreementObj.AgreementNo}
            </Link>
          ) : (
            'N/A'
          )}
        </Table.Cell>
        <Table.Cell>
          {record.FundingProviderObj && record.FundingProviderObj.Id
            ? record.FundingProviderObj.Id + ' - ' + record.FundingProviderObj.Name
            : 'N/A'}
        </Table.Cell>

        <Table.Cell>
          {record.ServiceType === 1
            ? 'IME'
            : record.ServiceType === 2
            ? 'FR'
            : record.ServiceType === 3
            ? 'Supp'
            : record.ServiceType === 4
            ? 'CR'
            : record.ServiceType === 5
            ? 'NM'
            : 'Other'}
        </Table.Cell>
        <Table.Cell>{record.AdditionalInfo && record.AdditionalInfo.ClaimNo}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo && record.AdditionalInfo.Claimant}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo && record.AdditionalInfo.MatterType}</Table.Cell>
        <Table.Cell>{record.AdditionalInfo && record.AdditionalInfo.ReportStatus}</Table.Cell>
        <Table.Cell>
          {record.AdditionalInfo && record.AdditionalInfo.ReportStatusDate
            ? moment(record.AdditionalInfo.ReportStatusDate).format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {this.numberDecimalFormatter(this.roundNumber(record.ReportFee / ((100 - record.GrossMargin) / 100), 2), 2)}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {/* <Link to="#" onClick={() => this.generateInvoice(record.InvoiceObj)}>
              {record.InvoiceObj.InvoiceNo}
            </Link> */}
          {record.InvoiceObj.InvoiceNo}
        </Table.Cell>
        <Table.Cell>
          {record.InvoiceObj && record.InvoiceObj.DateOfInvoice
            ? moment(record.InvoiceObj.DateOfInvoice).format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.EstimateSettlementDate ? moment(record.EstimateSettlementDate).format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.ActualSettlementDate ? moment(record.ActualSettlementDate).format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    store.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = store;
    const colummn_ = api.isAdmin() ? columns : columns.filter(i => i.title);
    const colSpan = colummn_.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={colummn_}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={store.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage}
          totalPage={store.totalPage}
          totalItems={store.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default AMAAftercareRuleTable;
