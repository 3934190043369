import React from 'react';
import { observer } from 'mobx-react';
import { Tag, Input } from 'antd';

import ClinicStore from './store';

@observer
class RoomForm extends React.Component {
  state = {
    inputVisible: false,
    inputValue: '',
  };

  handleShowInput = () => {
    this.setState({
      inputVisible: true,
    });
  };

  handleEditInputChange = event => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  handleSaveAndHideInput = () => {
    if (!this.state.inputValue) {
      return this.setState({
        inputVisible: false,
      });
    }

    ClinicStore.handleAddRoom(this.state.inputValue);
    this.setState({
      inputVisible: false,
      inputValue: '',
    });
  };

  render() {
    return (
      <div className="room-list">
        {ClinicStore.rooms.map(({ Id, Name }) => (
          <Tag className="tag-item" closable key={Id} onClose={() => ClinicStore.handleRemoveRoom(Id)}>
            {Name}
          </Tag>
        ))}
        {this.state.inputVisible && (
          <Input
            value={this.state.inputValue}
            size="small"
            className="tag-input"
            onChange={this.handleEditInputChange}
            onBlur={this.handleSaveAndHideInput}
            onPressEnter={this.handleSaveAndHideInput}
          />
        )}
        {!this.state.inputVisible && (
          <Tag className="add-new-room" onClick={this.handleShowInput}>
            + Add new room
          </Tag>
        )}
      </div>
    );
  }
}

export default RoomForm;
