import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import AddEditStore from './Store';
import Modal from '../../shared/Modal';
import { TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';

const columns = [{ title: 'Full Name' }, { title: 'Email' }, { title: 'Mobile' }];

@observer
class ModalDuplicate extends React.Component {
  renderModalTitle = (dataDuplicate, type) => {
    if (!dataDuplicate.length) {
      return 'Duplicate message';
    }
    return type === 'Email' ? 'List of similar emails' : 'List of similar claimants';
  };

  renderModalBody = (dataDuplicate, type) => {
    if (!dataDuplicate.length) {
      return type === 'Email' ? 'No similar emails are found' : 'No similar claimants are found';
    }
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {dataDuplicate.map((record, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{record.FullName}</Table.Cell>
              <Table.Cell>{record.Email}</Table.Cell>
              <Table.Cell>{record.Mobile ? <FormatPhoneNumber officePhoneNumber={record.Mobile} /> : ''}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { open } = AddEditStore;
    const { dataDuplicate, type } = AddEditStore.modalParams;
    return (
      <Modal
        visible={open}
        width={700}
        onCancel={AddEditStore.toggleModal(false)}
        title={this.renderModalTitle(dataDuplicate, type)}
      >
        {dataDuplicate && this.renderModalBody(dataDuplicate, type)}
      </Modal>
    );
  }
}

export default ModalDuplicate;
