import { action, observable, toJS } from 'mobx';

import customFetch from '../../../utils/customFetch';
import { getCategories, getGroups, getAllItems, getStateList, searchAMACaculation } from './Service';

class Store {
  @observable loading = true;
  @observable data = null;
  @observable mbsSelectionList = [];

  @observable keyword = '';
  @observable filter = 1;
  @observable sortKey = 'MBSCode';
  @observable sortColumn = 'MBSCode';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable numberPageSearch = 20;
  @observable isSelectionList = false;

  @observable open = false;
  @observable modalParams = {};

  @observable searchExclutionText = '';
  @observable arrExclutionId = [];
  @observable selectedExclutions = [];

  @observable lstCategories = [];
  @observable lstGroups = [];
  @observable filterCategory = '';
  @observable filterGroup = '';
  @observable filterStatus = 'all';

  @observable listTreatment = [];
  @observable loadingCheckAmaTreatment = false;
  @observable lstExclusionRange = [];
  @observable loadingUpload = false;
  @observable lstMBSCodeFee = [];
  @observable lstCalculationRule = [];
  @observable stateList = [];

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action exportSearchResults = () => {
    var query = 'keyword=' + this.keyword;
    query += '&filterCategory=' + this.filterCategory;
    query += '&filterGroup=' + this.filterGroup;
    query += '&filterType=' + this.filterStatus;

    window.open('/MBSCode/ExportMBSCodes?' + query, '_blank');
  };

  @action fetchData = (setTotalPage = false) => {
    this.loading = this.isSelectionList ? false : true;
    this.loadingSearch = this.isSelectionList ? true : false;
    getAllItems({
      ActionType: 'NONE',
      CurPage: this.curPage,
      NumItemPerPage: this.numberPageSearch,
      IsSelectionList: this.isSelectionList,
      Keyword: this.keyword,
      FilterCategory: this.filterCategory,
      FilterGroup: this.filterGroup,
      FilterType: this.filterStatus,
      sortBy: this.sortKey,
      sortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
    }).then(
      action(data => {
        if (this.isSelectionList) {
          this.loadingSearch = false;
          this.mbsSelectionList =
            toJS(
              data.itemList.map(t => ({
                value: t.Id,
                text: t.MBSCode,
                key: t.Id,
              })),
            ) || [];
        } else {
          this.data = data;
        }
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action setListAMACodeFrom = arr => {
    this.lstAMACodeFrom = arr;
  };

  @action setListAMACodeTo = arr => {
    this.lstAMACodeTo = arr;
  };

  @action setListCalculationRule = arr => {
    this.lstCalculationRule = arr;
  };

  @action setArrExclutionId = arr => {
    this.arrExclutionId = arr;
  };

  @action setSelectedExclutions = arr => {
    this.selectedExclutions = arr;
  };

  @action setListFee = arr => {
    this.lstMBSCodeFee = arr;
  };

  @action setLoadingUpload = status => {
    this.loadingUpload = status;
  };

  @action fetchCaluationRule = () => {
    searchAMACaculation({}).then(resp => {
      if (resp.status === 'success') {
        resp.itemList.unshift({ Id: -1, CalculationName: 'None' });
        this.setListCalculationRule(resp.itemList);
      } else {
        this.setListCalculationRule([]);
      }
    });
  };

  @action fetchCategories = () => {
    getCategories().then(
      action(data => {
        this.lstCategories = data.itemList.map(t => ({
          value: t,
          text: t,
        }));
      }),
    );
  };

  @action fetchGroups = () => {
    getGroups().then(
      action(data => {
        this.lstGroups = data.itemList.map(t => ({
          value: t,
          text: t,
        }));
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  @action refetchGroup = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchService();
  };

  @action fetchStateList = () => {
    getStateList().then(
      action(data => {
        this.stateList = data.itemList.map(s => ({
          value: s,
          text: s,
          key: s,
        }));
      }),
    );
  };

  @action resetRefresh = () => {
    this.keyword = '';
    this.FilterType = 'all';
    this.sortColumn = 'MBSCode';
    this.sortKey = 'MBSCode';
    this.sortDirection = 'ascending';
    this.filterCategory = '';
    this.filterGroup = '';
    this.refetch({}, true);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.FilterType = 'all';
    this.sortColumn = 'MBSCode';
    this.sortKey = 'MBSCode';
    this.sortDirection = 'ascending';
    this.filterCategory = '';
    this.filterGroup = '';
    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new Store();
