import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import ServiceEstimateStore from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteServiceEstimate } from './Service';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import * as api from '../../../stores/api';

const columns = [
  { title: '' },
  { title: 'No', sortKey: 'Id' },
  { title: 'Checking Date' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Service Type', sortKey: 'ServiceType' },
  { title: 'Case No' },
  { title: 'Date of Injury' },
  { title: 'Type Of Work' },
  { title: 'Injured Body Parts' },
  { title: 'Claim Number' },
  { title: 'Claimant DOB' },
  { title: 'Claimant Gender' },
  { title: 'Requesting Surgeon' },
  { title: 'Specialty' },
];

@observer
class SaleTargetTable extends React.Component {
  openModal = options => {
    return ServiceEstimateStore.toggleModal(true, options);
  };
  handleDelete = id => {
    ServiceEstimateStore.setFieldsValue({ loading: true });
    Promise.all([deleteServiceEstimate(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          ServiceEstimateStore.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    ServiceEstimateStore.setServiceItemOptions([]);
    return ServiceEstimateStore.toggleModal(true, {
      modalType: 'create',
      id,
      record,
    })();
  };
  handlePrintEstimate = id => {
    window.open('/ServiceEstimate/PrintEstimate?id=' + id, '_blank');
  };
  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item
                onClick={this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to delete this item?',
                  onOk: () => this.handleDelete(id),
                })}
              >
                Delete
              </Menu.Item>
            )}
            <Menu.Item onClick={() => this.handlePrintEstimate(id)}>Print</Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  renderTypeOfWordText = value => {
    if (value === 1) return 'Heavy Labour';
    if (value === 2) return 'Light Labour';
    if (value === 3) return 'Sedentary';
    return '';
  };

  renderCheckingDate = (startDate, endDate) => {
    if (startDate && endDate) {
      var sDate = moment(startDate).format('DD/MM/YYYY');
      ///var eDate = moment(endDate).format('HH:mm');
      return sDate;
    }
    return '';
  };

  isArray = arr => {
    return Array.isArray(arr) ? arr : [];
  };

  renderBodyPartyText = value => {
    const { lstBodyPartsOptions } = ServiceEstimateStore;
    if (lstBodyPartsOptions && lstBodyPartsOptions.length > 0 && value) {
      var speLable = '';
      var arrBodyParts = ServiceEstimateStore.checkIsJSON(value) ? JSON.parse(value) : value;
      this.isArray(arrBodyParts).forEach(e => {
        lstBodyPartsOptions.forEach(i => {
          if (i.Id === e) {
            speLable += i.Label + ', ';
          }
        });
      });
      return speLable.length > 2 ? speLable.substring(0, speLable.length - 2) : speLable;
    }
    return '';
  };

  renderSpecialtyText = value => {
    const { lstSpecialtiesOptions } = ServiceEstimateStore;
    if (lstSpecialtiesOptions && lstSpecialtiesOptions.length > 0 && value) {
      var speLable = '';
      var arrSpecialties = JSON.parse(value);
      arrSpecialties.forEach(e => {
        lstSpecialtiesOptions.forEach(i => {
          if (i.Id === e) {
            speLable += i.Label + ', ';
          }
        });
      });
      return speLable.length > 2 ? speLable.substring(0, speLable.length - 2) : speLable;
    }
    return '';
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>
        {api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record)}>
              {record.EstimateNo}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.EstimateNo}</Table.Cell>
        )}
        <Table.Cell>{record.StartDate ? moment(record.StartDate).format('DD/MM/YYYY') : 'N/A'}</Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.Client && (
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client?.Name,
                })
              }
            >
              ({record.ClientId}) {record.Client?.Name}
            </Link>
          )}
          {record.Client && (
            <div>
              {record.Client.ClientType === 1
                ? `Insurer`
                : record.Client.ClientType === 2
                ? `Law Defendant`
                : record.Client.ClientType === 3
                ? `Law Plaintiff`
                : record.Client.ClientType === 4
                ? `Law Practice`
                : record.Client.ClientType === 5
                ? `Other`
                : null}
            </div>
          )}
          {record.Client && <div>{record.Client.CityName}</div>}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.CaseManager && (
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'cm',
                  id: record.CaseManagerId,
                  localStorageName: 'CmName',
                  localStorageValue: record.CaseManager?.FullName,
                })
              }
            >
              ({record.CaseManagerId}) {record.CaseManager?.FullName}
            </Link>
          )}
          <div>{record.CaseManager?.Email}</div>
          <div>
            <FormatPhoneNumber phoneNumber={record.CaseManager?.OfficePhone} />
          </div>
        </Table.Cell>
        <Table.Cell>{record.ServiceType}</Table.Cell>
        <Table.Cell>{!record.IsManual ? record.ManualCaseNo : record.CaseNo}</Table.Cell>
        <Table.Cell>
          {!record.IsManual &&
            (record.ManualDateOfInjury
              ? moment(formatDate(record.ManualDateOfInjury), 'DD MMM YYYY').format('DD/MM/YYYY')
              : 'N/A')}
          {record.IsManual &&
            (record.DateOfInjury ? moment(formatDate(record.DateOfInjury), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A')}
        </Table.Cell>
        <Table.Cell>{record.TypeOfWorkText}</Table.Cell>
        <Table.Cell>{this.renderBodyPartyText(record.InjuryBodyParts)}</Table.Cell>
        <Table.Cell>{!record.IsManual ? record.ManualClaimNumber : record.ClaimNumber}</Table.Cell>
        <Table.Cell>
          {!record.IsManual &&
            (record.ManualClaimantDOB
              ? moment(formatDate(record.ManualClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY')
              : 'N/A')}
          {record.IsManual &&
            (record.ClaimantDOB ? moment(formatDate(record.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A')}
        </Table.Cell>
        <Table.Cell>{!record.IsManual ? record.ManualClaimantGender : record.ClaimantGender}</Table.Cell>
        <Table.Cell>{!record.IsManual ? record.ManualAssessingSpecialist : record.AssessingSpecialist}</Table.Cell>
        <Table.Cell>
          {!record.IsManual ? this.renderSpecialtyText(record.ManualSpecialistSpecialty) : record.SpecialistSpecialty}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    ServiceEstimateStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = ServiceEstimateStore;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={ServiceEstimateStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(columns.length, data)}</Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={ServiceEstimateStore.curPage}
          totalPage={ServiceEstimateStore.totalPage}
          totalItems={ServiceEstimateStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default SaleTargetTable;
