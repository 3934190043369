import { Checkbox, DatePicker, Form, Input, notification, Radio, Select } from 'antd';
import { observer } from 'mobx-react';
import { action, computed, observable, toJS } from 'mobx';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import React from 'react';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal';
import CKEditor from '../AddEditImeAssessments/CKEditor';
import { submitConversation } from './service';
import store from './Store';
import { conversationTypes, conversationContentCategories, CREATED_BY_ROLE } from './type';
import './ConversationModalForm.scss';
import * as api from '@stores/api';
import { formatDate } from '../../../utils/functions';

@observer
class NewConversationModalForm extends React.Component {
  @observable conversation = {
    Id: 0,
    AssessmentId: store.assessmentInfo.Id,
    ContentCategory: 0,
    SentDate: moment(),
    Subject: '',
    Content: store.conversationParam.Content,
    ReceiverType: api.isCaseManager() || api.isDoctor() ? 3 : 1,
    ReceiverId: '',
    Receiver: [],
    CallerSenderType: api.isDoctor() ? 2 : 1,
    CallerSenderId: '',
    CallerSender: [],
    ConversationType: api.isCaseManager() || api.isDoctor() ? 1 : 0,
    IsAutoEmail: true,
    IsSentNotice: true,
    AllowCMToView: api.isCaseManager() ? true : false,
    AllowDoctorToView: api.isDoctor() ? true : false,
    AllowStaffToView: api.isAdminOrMagStaffOrAccounting() ? true : false,
    CreatedByRole: api.isCaseManager()
      ? CREATED_BY_ROLE.CaseManager
      : api.isDoctor()
      ? CREATED_BY_ROLE.Specialist
      : CREATED_BY_ROLE.Staff,
  };

  state = {
    CorrespondenceObj: this.conversation,
    OldCorrespondence: null,
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  @action componentDidMount() {
    const { conversationParam } = store;
    var data = conversationParam;
    if (!data?.Id) {
      this.conversation.Id = 0;
      this.conversation.IsAutoEmail = false;
      this.conversation.IsSentNotice = false;
      this.conversation.AllowCMToView = api.isCaseManager();
      this.conversation.AllowDoctorToView = api.isDoctor();
      this.conversation.AllowStaffToView = api.isAdminOrMagStaffOrAccounting();
      this.conversation.CreatedByRole = api.isAdminOrMagStaffOrAccounting()
        ? CREATED_BY_ROLE.Staff
        : api.isCaseManager()
        ? CREATED_BY_ROLE.CaseManager
        : CREATED_BY_ROLE.Specialist;
      if (data.Subject) {
        this.conversation.Subject = data.Subject;
      }
      switch (this.conversation.CallerSenderType) {
        case 1:
          const cm = store.bookingCaseManager;
          if (!!cm) {
            this.conversation.CallerSender = [{ value: cm.Id, label: cm.FullName }];
          }
          break;
        case 2:
          const doctor = store.specialist;
          if (!!doctor) {
            this.conversation.CallerSender = [{ value: doctor.Id, label: doctor.FullName }];
          }
          break;
        case 3:
          if (api.isAdminOrMagStaffOrAccounting()) {
            this.conversation.CallerSender = [{ value: api.currentUser.data.id, label: api.currentUser.data.FullName }];
          } else {
            var staff = (store.listStaff?.itemList || []).find(i => i.Id === store.assessmentInfo.StaffId);
            if (!!staff) {
              this.conversation.CallerSender = [{ value: staff.Id, label: staff.FullName }];
            } else {
              this.conversation.CallerSender = [];
            }
          }
          break;
      }
      switch (this.conversation.ReceiverType) {
        case 1:
          const cm = store.bookingCaseManager;
          if (!!cm) {
            this.conversation.Receiver = [{ value: cm.Id, label: cm.FullName }];
          }
          break;
        case 2:
          const doctor = store.specialist;
          if (!!doctor) {
            this.conversation.Receiver = [{ value: doctor.Id, label: doctor.FullName }];
          }
          break;
        case 3:
          if (api.isAdminOrMagStaffOrAccounting()) {
            this.conversation.Receiver = [{ value: api.currentUser.data.id, label: api.currentUser.data.FullName }];
          } else {
            var staff = (store.listStaff?.itemList || []).find(i => i.Id === store.assessmentInfo.StaffId);
            if (!!staff) {
              this.conversation.Receiver = [{ value: staff.Id, label: staff.FullName }];
            } else {
              this.conversation.Receiver = [];
            }
          }
          break;
      }
      this.setState({
        CorrespondenceObj: this.conversation,
      });
    } else {
      customFetch('/MedicalService/GetCorrespondenceDetail?type=IME&id=' + data.Id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(resp => {
        if (resp.status === 'success') {
          let data = {
            ...resp.data,
            SentDate: this.convertDate(resp.data.SentDate),
            CreatedDate: this.convertDate(resp.data.CreatedDate),
          };
          this.setState({
            CorrespondenceObj: data,
            OldCorrespondence: data,
          });
        }
      });
    }
  }

  getCallerReceivers = type => {
    switch (type) {
      case 1:
        return store.itemToArray(store.bookingCaseManager, store.listBookingCMCC?.itemList || [], 'Id').map(cm => ({
          label: cm.FullName,
          value: cm.Id,
        }));
      case 2:
        const doctor = store.specialist;
        return doctor
          ? [
              {
                label: doctor.FullName,
                value: doctor.Id,
              },
            ]
          : [];
      case 3:
        return (store.listStaff?.itemList || []).map(staff => ({
          label: staff.FullName,
          value: staff.Id,
        }));
      default:
        return [];
    }
  };

  setCaller = type => {
    const { OldCorrespondence, CorrespondenceObj } = this.state;
    let newObj = { ...CorrespondenceObj, CallerSenderType: type };
    if (!!OldCorrespondence && type === OldCorrespondence.CallerSenderType) {
      newObj = { ...newObj, CallerSender: OldCorrespondence.CallerSender };
    } else {
      switch (type) {
        case 1:
          const cm = store.bookingCaseManager;
          if (!!cm) {
            newObj = {
              ...newObj,
              CallerSender: [{ value: cm.Id, label: cm.FullName }],
            };
          } else {
            newObj = {
              ...newObj,
              CallerSender: [],
            };
          }
          break;
        case 2:
          const doctor = store.specialist;
          if (!!doctor) {
            newObj = {
              ...newObj,
              CallerSender: [{ value: doctor.Id, label: doctor.FullName }],
            };
          } else {
            newObj = {
              ...newObj,
              CallerSender: [],
            };
          }
          break;
        case 3:
          newObj = {
            ...newObj,
            CallerSender: [{ value: api.currentUser.data.id, label: api.currentUser.data.FullName }],
          };
          break;
      }
    }
    this.setState({ CorrespondenceObj: newObj });
  };

  setReceiver = type => {
    const { OldCorrespondence, CorrespondenceObj } = this.state;
    let newObj = { ...CorrespondenceObj, ReceiverType: type };
    if (!!OldCorrespondence && type === OldCorrespondence.ReceiverType) {
      newObj = { ...newObj, Receiver: OldCorrespondence.Receiver };
    } else {
      switch (type) {
        case 1:
          const cm = store.bookingCaseManager;
          if (!!cm) {
            newObj = {
              ...newObj,
              Receiver: [{ value: cm.Id, label: cm.FullName }],
            };
          } else {
            newObj = {
              ...newObj,
              Receiver: [],
            };
          }
          break;
        case 2:
          const doctor = store.specialist;
          if (!!doctor) {
            newObj = {
              ...newObj,
              Receiver: [{ value: doctor.Id, label: doctor.FullName }],
            };
          } else {
            newObj = {
              ...newObj,
              Receiver: [],
            };
          }
          break;
        case 3:
          newObj = {
            ...newObj,
            Receiver: [{ value: api.currentUser.data.id, label: api.currentUser.data.FullName }],
          };
          break;
      }
    }
    this.setState({ CorrespondenceObj: newObj });
  };

  setFieldValue = key =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event ?? null;
      if (key === 'CallerSenderType') {
        this.setCaller(value);
      } else if (key === 'ReceiverType') {
        this.setReceiver(value);
      } else {
        const { OldCorrespondence, CorrespondenceObj } = this.state;
        var newObj = {
          ...CorrespondenceObj,
          [key]: value,
        };
        this.setState({ CorrespondenceObj: newObj });
      }
    });

  toggleCheckbox = key =>
    action(event => {
      const { checked } = event.target;
      const { CorrespondenceObj } = this.state;
      var newObj = {
        ...CorrespondenceObj,
        [key]: checked,
      };
      this.setState({ CorrespondenceObj: newObj });
    });

  @computed get getCallerSenders() {
    const { CorrespondenceObj } = this.state;
    var arrayRet = this.getCallerReceivers(CorrespondenceObj.CallerSenderType);
    if (CorrespondenceObj.CallerSender && CorrespondenceObj.CallerSender.length > 0) {
      CorrespondenceObj.CallerSender.forEach(el => {
        var fObj = arrayRet.find(i => i.value === el.value);
        if (!fObj) arrayRet.push(el);
      });
    }
    return arrayRet;
  }

  @computed get getReceivers() {
    const { CorrespondenceObj } = this.state;
    var arrayRet = this.getCallerReceivers(CorrespondenceObj.ReceiverType);
    if (CorrespondenceObj.Receiver && CorrespondenceObj.Receiver.length > 0) {
      CorrespondenceObj.Receiver.forEach(el => {
        var fObj = arrayRet.find(i => i.value === el.value);
        if (!fObj) arrayRet.push(el);
      });
    }
    return arrayRet;
  }

  @action handChangeCaller = (_, event) => {
    const { CorrespondenceObj } = this.state;
    const data = event.props.value;
    var lstObjs = this.getCallerReceivers(CorrespondenceObj.CallerSenderType);
    let data_ = [];
    var fObj = lstObjs.find(i => i.value == data);
    if (!!fObj) data_.push(fObj);
    var newObj = {
      ...CorrespondenceObj,
      CallerSender: data_,
    };
    this.setState({ CorrespondenceObj: newObj });
  };

  @action handChangeReceiver = (_, event) => {
    const { CorrespondenceObj } = this.state;
    const data = event.map(i => i.props.value);
    var lstObjs = this.getCallerReceivers(CorrespondenceObj.ReceiverType);
    let data_ = [];
    data.forEach(el => {
      var fObj = lstObjs.find(i => i.value == el);
      if (!!fObj) data_.push(fObj);
    });
    var newObj = {
      ...CorrespondenceObj,
      Receiver: data_,
    };
    this.setState({ CorrespondenceObj: newObj });
  };
  //Save
  handleSave = type => {
    store.setFieldsValue({ loading: true });
    const { CorrespondenceObj } = this.state;
    if (!CorrespondenceObj.Subject) {
      return store.showNotification('Error', 'Please enter subject');
    }
    if (!CorrespondenceObj.Receiver || CorrespondenceObj.Receiver.length <= 0) {
      return store.showNotification('Error', 'Please select a Receiver');
    }
    if (!CorrespondenceObj.CallerSender || CorrespondenceObj.CallerSender.length <= 0) {
      return store.showNotification('Error', 'Please select a Caller/Sender');
    }
    var callerNameOrEmail = CorrespondenceObj.CallerSender.map(i => i.label).join(', ');
    var receiverNameOrEmail = CorrespondenceObj.Receiver.map(i => i.label).join(', ');
    submitConversation({
      id: CorrespondenceObj.Id,
      assessmentId: CorrespondenceObj.AssessmentId,
      allowCMToView: CorrespondenceObj.AllowCMToView,
      allowDoctorToView: CorrespondenceObj.AllowDoctorToView,
      allowStaffToView: CorrespondenceObj.AllowStaffToView,
      sentDate: store.formatReqDate(CorrespondenceObj.SentDate),
      conversationType: CorrespondenceObj.ConversationType,
      subject: CorrespondenceObj.Subject,
      content: CorrespondenceObj.Content,
      callerSenderType: CorrespondenceObj.CallerSenderType,
      callerSender: callerNameOrEmail,
      receiverType: CorrespondenceObj.ReceiverType,
      receiver: receiverNameOrEmail,
      hour: CorrespondenceObj.SentDate.hour(),
      minute: CorrespondenceObj.SentDate.minute(),
      callerSenderId: CorrespondenceObj.CallerSender.map(i => i.value).join(','),
      receiverId: CorrespondenceObj.Receiver.map(i => i.value).join(','),
      contentCategory: CorrespondenceObj.ContentCategory,
      sendEmailNotice: type === 0 ? false : true,
    })
      .then(res => {
        store.setFieldsValue({ loading: false });
        if (res.status === 'success') {
          store.handleRefetchConversation();
          notification.destroy();
          notification.success({
            description: 'Add Conversation Log successfully!',
            message: 'Success',
            duration: 5,
          });
        } else if (res.status === 'fail') {
          notification.destroy();
          notification.error({
            description:
              'Error occured while saving data. Please report this to system administrator and try again later.',
            message: 'Fail',
            duration: 5,
          });
        }
        this.props.onCancel();
      })
      .catch(() => {
        store.setFieldsValue({ loading: false });
      });
  };

  render() {
    const { visible, onCancel } = this.props;
    const { CorrespondenceObj } = this.state;
    var disableSaveAndSend = true;
    var disableSaveButton = true;
    var disableEditAllow = true;
    var disableEdit = true;
    var disableEditTypeAndCate = true;
    var disableContent = false;
    if (
      CorrespondenceObj.Id !== 0 &&
      (api.isCaseManager() || api.isDoctor()) &&
      (CorrespondenceObj.IsSentNotice || CorrespondenceObj.IsAutoEmail)
    ) {
      disableContent = true;
    }
    if (!!store.assessmentInfo.Id && !CorrespondenceObj.IsSentNotice) {
      if (
        !CorrespondenceObj.IsAutoEmail &&
        api.isAdminOrMagStaffOrAccounting() &&
        (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.Staff || CorrespondenceObj.Id === 0) &&
        (CorrespondenceObj.AllowCMToView || CorrespondenceObj.AllowDoctorToView)
      )
        disableSaveAndSend = false;
      if (
        !CorrespondenceObj.IsAutoEmail &&
        api.isCaseManager() &&
        (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.CaseManager || CorrespondenceObj.Id === 0) &&
        (CorrespondenceObj.AllowStaffToView || CorrespondenceObj.AllowDoctorToView)
      )
        disableSaveAndSend = false;
      if (
        !CorrespondenceObj.IsAutoEmail &&
        api.isDoctor() &&
        (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.Specialist || CorrespondenceObj.Id === 0) &&
        (CorrespondenceObj.AllowCMToView || CorrespondenceObj.AllowStaffToView)
      )
        disableSaveAndSend = false;
      //Edit
      if (api.isAdminOrMagStaffOrAccounting()) {
        if (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.Staff || CorrespondenceObj.Id === 0) {
          disableEditAllow = false;
          disableEditTypeAndCate = false;
          disableEdit = false;
          disableSaveButton = false;
        }
        if (CorrespondenceObj.AllowStaffToView) {
          disableEditTypeAndCate = false;
          disableSaveButton = false;
        }
      }
      if (api.isCaseManager()) {
        if (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.CaseManager || CorrespondenceObj.Id === 0) {
          disableEditAllow = false;
          disableEditTypeAndCate = false;
          disableEdit = false;
          disableSaveButton = false;
        }
        if (CorrespondenceObj.AllowCMToView) {
          disableEditTypeAndCate = false;
          disableSaveButton = false;
        }
      }
      if (api.isDoctor()) {
        if (CorrespondenceObj.CreatedByRole === CREATED_BY_ROLE.Specialist || CorrespondenceObj.Id === 0) {
          disableEditAllow = false;
          disableEditTypeAndCate = false;
          disableEdit = false;
          disableSaveButton = false;
        }
        if (CorrespondenceObj.AllowDoctorToView) {
          disableEditTypeAndCate = false;
          disableSaveButton = false;
        }
      }
    }
    return (
      <Modal
        width={1000}
        onCancel={onCancel}
        visible={visible}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={() => this.handleSave(1)} disabled={disableSaveAndSend}>
              Save & Send
            </Button>}
            {!api.isOnlySearch() && <Button color="blue" onClick={() => this.handleSave(0)} disabled={disableSaveButton}>
              Save
            </Button>}
            <Button color="red" onClick={onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!!CorrespondenceObj && !!CorrespondenceObj.Id ? 'Correspondence Details' : 'Add New'}
      >
        <Form name="conversation" className="conversation-form">
          {api.isAdminOrMagStaffOrAccounting() && (
            <Form.Item>
              <Checkbox
                checked={CorrespondenceObj.AllowCMToView}
                onChange={this.toggleCheckbox('AllowCMToView')}
                disabled={disableEditAllow}
              >
                Allow CM to view
              </Checkbox>
            </Form.Item>
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Form.Item>
              <Checkbox
                checked={CorrespondenceObj.AllowDoctorToView}
                onChange={this.toggleCheckbox('AllowDoctorToView')}
                disabled={disableEditAllow}
              >
                Allow Specialist to view
              </Checkbox>
            </Form.Item>
          )}
          {(api.isCaseManager() || api.isDoctor()) && (
            <Form.Item>
              <Checkbox
                checked={CorrespondenceObj.AllowStaffToView}
                onChange={this.toggleCheckbox('AllowStaffToView')}
                disabled={disableEditAllow}
              >
                Allow MLP Staff to view
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item label="Correspondence Type">
            <Select
              size="large"
              value={CorrespondenceObj.ConversationType}
              onChange={this.setFieldValue('ConversationType')}
              disabled={disableEditTypeAndCate}
            >
              {conversationTypes
                .filter(i => !i.visible)
                .map(({ label, value }) => (
                  <Select.Option style={{ display: 'none' }} key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              {conversationTypes
                .filter(i => i.visible)
                .map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label=" Content Category">
            <Select
              size="large"
              value={CorrespondenceObj.ContentCategory}
              onChange={this.setFieldValue('ContentCategory')}
              disabled={disableEditTypeAndCate}
            >
              {conversationContentCategories.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={`Date/Time - UTC${moment.tz(moment.tz.guess()).format('Z')}`}>
            <DatePicker
              disabled={disableEdit}
              size="large"
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY - HH:mm"
              value={CorrespondenceObj.SentDate}
              //onChange={this.setFieldValue('SentDate')}
            />
          </Form.Item>
          <Form.Item
            label="Subject"
            required
            validateStatus={!CorrespondenceObj.Subject ? 'error' : null}
            help={!CorrespondenceObj.Subject ? 'This field is required' : ''}
          >
            <Input
              size="large"
              value={CorrespondenceObj.Subject}
              onChange={this.setFieldValue('Subject')}
              disabled={disableEdit}
            />
          </Form.Item>
          <Form.Item label="Content">
            <CKEditor
              html={CorrespondenceObj.Content}
              handleChangeContent={this.setFieldValue('Content')}
              disabled={disableContent}
            />
          </Form.Item>
          <Form.Item label="Caller/Sender" required>
            <Radio.Group
              className="mb-1"
              value={CorrespondenceObj.CallerSenderType}
              onChange={this.setFieldValue('CallerSenderType')}
              disabled={disableEdit}
            >
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()} value={1}>
                Case Manager
              </Radio>
              <Radio
                disabled={!store.specialist || (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor())}
                value={2}
              >
                Specialist
              </Radio>
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting()} value={3}>
                Staff
              </Radio>
            </Radio.Group>
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              value={CorrespondenceObj.CallerSender ? CorrespondenceObj.CallerSender.map(i => i.value) : []}
              onChange={(value, event) => this.handChangeCaller(value, event)}
              disabled={CorrespondenceObj.CallerSenderType === 2 || disableEdit}
            >
              {this.getCallerSenders &&
                this.getCallerSenders.length > 0 &&
                this.getCallerSenders.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Receiver" required>
            <Radio.Group
              className="mb-1"
              value={CorrespondenceObj.ReceiverType}
              onChange={this.setFieldValue('ReceiverType')}
              disabled={disableEdit}
            >
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting()} value={1}>
                Case Manager
              </Radio>
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting() || !store.specialist} value={2}>
                Specialist
              </Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            <Select
              size="large"
              showSearch
              mode="multiple"
              optionFilterProp="children"
              value={CorrespondenceObj.Receiver ? CorrespondenceObj.Receiver.map(i => i.value) : []}
              onChange={(value, event) => this.handChangeReceiver(value, event)}
              disabled={CorrespondenceObj.ReceiverType === 2 || disableEdit}
            >
              {this.getReceivers.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NewConversationModalForm;
