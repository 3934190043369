import React from 'react';
import { observer } from 'mobx-react';
import {
  Form,
  Checkbox,
  Divider,
  Radio,
  Select,
  Menu,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  DatePicker,
  notification,
  Collapse,
} from 'antd';
import { toJS } from 'mobx';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';

import store from './store';
import FieldUpload from './FieldUpload';
import { getCurrentRate } from './service';
import * as api from '@stores/api';
import customFetch from '@utils/customFetch';
import moment from 'moment';
import ListInvoice from './ListInvoice';
import { Header } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';

@observer
class ClientInvoicingForm extends React.Component {
  handleCheckCurrentRate = () => {
    return getCurrentRate()
      .then(({ data }) => {
        const { AssessedDoctor } = toJS(store.supplementaryInfo);
        const d = data;
        store.handleChangeSuppValue({
          AssessedDoctor: {
            ...AssessedDoctor,
            MAGChargeRate: d.MAGChargeRate,
            MAGChargeRateFrom: d.MAGChargeRateFrom,
            MAGChargeRateTo: d.MAGChargeRateTo,
            MAGChargeRate2: d.MAGChargeRate2,
            MAGChargeRate2From: d.MAGChargeRate2From,
            MAGChargeRate2To: d.MAGChargeRate2To,
            ChargeRate: d.ChargeRate,
            ChargeRateFrom: d.ChargeRateFrom,
            ChargeRateTo: d.ChargeRateTo,
            ChargeRate2: d.ChargeRate2,
            ChargeRate2From: d.ChargeRate2From,
            ChargeRate2To: d.ChargeRate2To,
            CommissionOnTop: d.CommissionOnTop,
            CommissionOnTopFrom: d.CommissionOnTopFrom,
            CommissionOnTopTo: d.CommissionOnTopTo,
            CommissionOnTop2: d.CommissionOnTop2,
            CommissionOnTop2From: d.CommissionOnTop2From,
            CommissionOnTop2To: d.CommissionOnTop2To,
          },
        });
      })
      .catch(() => {});
  };
  onclickResendEmailPaymentReceipt = () => {
    const itemModel = toJS(store.supplementaryInfo);
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend payment receipt to Specialist?',
      onOk: () => {
        customFetch('/Supplementary/ResendEmailPaymentReceipt', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        })
          .then(response => {
            if (response.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend email payment receipt to Specialist',
              });
              store.handleChangeSuppValue({
                EmailPaymentReceiptToDoctorDateSent: moment(),
              });
            } else {
              notification.destroy();
              notification.Error({
                message: 'Error',
                description: 'Resend email payment receipt to Specialist',
              });
            }
          })
          .catch(() => {});
      },
    });
  };

  onclickResendInvoice = () => {
    const itemModel = toJS(store.supplementaryInfo);
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend invoice?',
      onOk: () => {
        return customFetch('/Supplementary/ResendInvoice', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        })
          .then(response => {
            if (response.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Resend Invoice',
              });
              store.handleChangeSuppValue({
                EmailInvoiceToMAGDateSent: moment(),
              });
            } else {
              notification.destroy();
              notification.Error({
                message: 'Error',
                description: 'Resend Invoice',
              });
            }
          })
          .catch(() => {});
      },
    });
  };

  handleResendEmailClientPaymentReceipt = () => {
    const itemModel = toJS(store.supplementaryInfo);
    return customFetch('/Supplementary/ResendEmailClientPaymentReceipt', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend email payment receipt',
          });
          store.handleChangeSuppValue({
            EmailPaymentReceiptToClientDateSent: moment(),
          });
          response.sentBy &&
            store.handleChangeSuppValue({
              EmailPaymentReceiptToClientBy: response.sentBy,
            });
        } else {
          notification.destroy();
          notification.Error({
            message: 'Error',
            description: 'Resend email payment receipt',
          });
        }
      })
      .catch(() => {});
  };
  ResendEmailClientPaymentReceipt = () => {
    return store.toggleModalConfirm(true, {
      modalType: 'confirm',
      message: 'Do you want to resend payment receipt?',
      onOk: () => this.handleResendEmailClientPaymentReceipt(),
    });
  };
  EmailInvoiceClientResend = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend invoice to client?',
      onOk: () => {
        customFetch('/Supplementary/ResendEmailInvoiceClient', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: toJS(store.supplementaryInfo).Id }),
        }).then(res => {
          if (res.status === 'success') {
            store.showNotification('Success', 'Resend Invoice', 'success');
          } else {
            store.showNotification('Error', 'Resend Invoice', 'error');
          }
        });
      },
    });
  };
  PrepaymentPaidUploadComplete = () => {
    const itemModel = toJS(store.supplementaryInfo);
    if (!itemModel.EmailPaymentReceiptToDoctor) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to email receipt to the provider?',
        onOk: () => store.handleChangeSuppValue({ EmailPaymentReceiptToDoctor: true }),
      });
    }
  };
  PaymentRequestedUploadComplete = () => {
    const itemModel = toJS(store.supplementaryInfo);
    if (!itemModel.EmailInvoiceToMAG) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to email invoice to Medicolegal Provider?',
        onOk: () => store.handleChangeSuppValue({ EmailInvoiceToMAG: true }),
      });
    }
  };
  handleSendPaymentReceipt = () => {
    const itemModel = toJS(store.supplementaryInfo);
    if (!itemModel.EmailPaymentReceiptToDoctor) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to send receipt?',
        onOk: () => store.handleChangeSuppValue({ EmailPaymentReceiptToClient: true }),
      });
    }
  };
  render() {
    const {
      handleChangeFieldSelect,
      handleScroll,
      handleSearch,
      itemToArray,
      handleChangeFieldInput,
      handleChangeFieldCheckbox,
      handleChangeFieldInputNumber,
      handleChangeFieldRadio,
      getMessageError,
      handleChangeDatePicker,
      handleOpenAddEdit,
      supplementaryInfo,
      payingClients,
      payingClientSelected,
      payingCMs,
      payingCMSelected,
      CReferences,
      CReferenceSelected,
      bookingCMSelected,
      serviceItemCodes,
      serviceItemId,
      serviceItemTotalFee,
      serviceItemTotalTax,
    } = store;
    const itemModel = toJS(supplementaryInfo);
    const { ServiceNotesandFeeObj } = itemModel;
    if (!api.isCaseManager())
      return (
        <Form name="client-invoicing-information" className="grid-style">
          <Collapse bordered={false} defaultActiveKey={api.isDoctor() ? 3 : null}>
            {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && [
              <Collapse.Panel
                key="1"
                header={
                  <Header
                    title="Client Invoicing Information"
                    isChecked={serviceItemId?.length > 0 ?? false}
                    text="Billing Items Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Paying Client">
                  <Select
                    size="large"
                    value={ServiceNotesandFeeObj ? ServiceNotesandFeeObj.PayingClientId : undefined}
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    placeholder="Select Paying Client"
                    onChange={handleChangeFieldSelect('PayingClientId')}
                    onSearch={handleSearch('searchOptPayingClient')}
                    onPopupScroll={handleScroll('searchOptPayingClient')}
                  >
                    {itemToArray(payingClientSelected, payingClients, 'Id').map(({ Id, Name, FullAddress }) => (
                      <Select.Option value={Id} key={Id}>
                        Id: {Id} - Client: {Name} - Address: {FullAddress}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Case Manager / Solicitor
                      <Dropdown
                        disabled={ServiceNotesandFeeObj ? !!!ServiceNotesandFeeObj.PayingClientId : true}
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => handleOpenAddEdit('add', 'PayingCaseManagerId')}>
                              Add CM
                            </Menu.Item>
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'PayingCaseManagerId')}>
                              Edit CM
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <button className="field-actions">
                          Add/Edit <Icon type="down" />
                        </button>
                      </Dropdown>
                    </React.Fragment>
                  }
                >
                  <Select
                    disabled={
                      itemModel.IsLocked || (ServiceNotesandFeeObj ? !!!ServiceNotesandFeeObj.PayingClientId : true)
                    }
                    size="large"
                    value={ServiceNotesandFeeObj ? ServiceNotesandFeeObj.PayingCaseManagerId : undefined}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Paying Case Manager"
                    onChange={handleChangeFieldSelect('PayingCaseManagerId')}
                    onSearch={handleSearch('searchOptPayingCM')}
                    onPopupScroll={handleScroll('searchOptPayingCM')}
                    allowClear
                  >
                    {itemToArray(payingCMSelected, payingCMs, 'Id').map(({ Id, FullName, Email }) => (
                      <Select.Option value={Id} key={Id}>
                        Id: {Id} - Name: {FullName} - Email: {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="C/-Reference">
                  <Select
                    size="large"
                    value={ServiceNotesandFeeObj ? ServiceNotesandFeeObj.CReferenceId : undefined}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select C/-Reference"
                    onChange={handleChangeFieldSelect('CReferenceId')}
                    onSearch={handleSearch('searchOptCReference')}
                    onPopupScroll={handleScroll('searchOptCReference')}
                    allowClear
                  >
                    {itemToArray(CReferenceSelected, CReferences, 'Id').map(({ Id, Name, FullAddress }) => (
                      <Select.Option value={Id} key={Id}>
                        Id: {Id} - Client: {Name} - Address: {FullAddress}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <div />
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 1:</span>
                      <Checkbox
                        disabled={itemModel.isLocked && !!bookingCMSelected?.Id}
                        checked={ServiceNotesandFeeObj.SameBookingCM}
                        onChange={handleChangeFieldCheckbox('SameBookingCM')}
                        className="checkbox-IsTypist"
                      >
                        Same as Booking CM
                      </Checkbox>
                    </span>
                  }
                  validateStatus={getMessageError('SendInvoice', 'field') ? 'error' : null}
                  help={getMessageError('SendInvoice', 'field') ? getMessageError('SendInvoice', 'field') : ''}
                >
                  <Input
                    size="large"
                    disabled={itemModel.isLocked || ServiceNotesandFeeObj.SameBookingCM}
                    value={ServiceNotesandFeeObj.SendInvoice}
                    onChange={handleChangeFieldInput('SendInvoice')}
                  />
                </Form.Item>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 2:</span>
                      <Checkbox
                        disabled={itemModel.isLocked && !!payingClientSelected?.Id}
                        checked={ServiceNotesandFeeObj.SameClientAccEmail}
                        onChange={handleChangeFieldCheckbox('SameClientAccEmail')}
                        style={{ marginTop: 10 }}
                        className="checkbox-IsTypist"
                      >
                        Same as Client Accounting Email
                      </Checkbox>
                    </span>
                  }
                  validateStatus={getMessageError('SendInvoice2', 'field') ? 'error' : null}
                  help={getMessageError('SendInvoice2', 'field') ? getMessageError('SendInvoice2', 'field') : ''}
                >
                  <Input
                    size="large"
                    disabled={itemModel.isLocked || ServiceNotesandFeeObj.SameClientAccEmail}
                    value={ServiceNotesandFeeObj.SendInvoice2}
                    onChange={handleChangeFieldInput('SendInvoice2')}
                  />
                </Form.Item>
                <Form.Item label="Billing Item Codes">
                  <Select
                    size="large"
                    value={[...serviceItemId]}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Billing Item Codes"
                    onChange={handleChangeFieldSelect('serviceItemId')}
                    mode="multiple"
                    showArrow
                  >
                    {serviceItemCodes?.map(({ Id, Name, ApplicablePrice, Tax }) => (
                      <Select.Option value={Id} key={Id}>
                        Id: {Id} - Name: {Name} - Fee: {ApplicablePrice} - Tax: {Tax}
                      </Select.Option>
                    ))}
                  </Select>
                  <Checkbox
                    checked={ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission}
                    onChange={handleChangeFieldCheckbox('IncludeServiceItemFeeToDoctorCommission')}
                    style={{ marginTop: 10 }}
                  >
                    Specialist Service
                  </Checkbox>
                </Form.Item>
                <div />
                <Form.Item label="Billing Item Fees (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={serviceItemTotalFee ? serviceItemTotalFee.toFixed(2) : 0}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={serviceItemTotalTax ? serviceItemTotalTax.toFixed(2) : 0}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Admin Extra (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.AdminExtraFeeAmount || 0}
                    onChange={handleChangeFieldInputNumber('AdminExtraFeeAmount')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                  <Checkbox
                    checked={ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission}
                    onChange={handleChangeFieldCheckbox('IncludeAdminExtraFeeToDoctorCommission')}
                    style={{ marginTop: 10 }}
                  >
                    Specialist Service
                  </Checkbox>
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage || 0}
                    onChange={handleChangeFieldInputNumber('AdminExtraFeeTaxPercentage')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <div className="fluid-field checkbox-field">
                  <Checkbox
                    disabled={itemModel.IsLocked}
                    checked={ServiceNotesandFeeObj.HasOtherFee}
                    onChange={handleChangeFieldCheckbox('HasOtherFee')}
                  >
                    Other Fees (excl GST)
                  </Checkbox>
                </div>
                {ServiceNotesandFeeObj.HasOtherFee && (
                  <>
                    <Form.Item label="Fee Name">
                      <Input
                        size="large"
                        disabled={itemModel.IsLocked}
                        model={ServiceNotesandFeeObj.OtherFee1Label}
                        onChange={handleChangeFieldInput('OtherFee1Label')}
                      />
                    </Form.Item>
                    <Form.Item label="Fee">
                      <InputNumber
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        disabled={itemModel.IsLocked}
                        value={ServiceNotesandFeeObj.OtherFee1Value}
                        onChange={handleChangeFieldInputNumber('OtherFee1Value')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    <Form.Item label="GST">
                      <InputNumber
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        disabled={itemModel.IsLocked}
                        value={ServiceNotesandFeeObj.OtherFee1TaxPercentage}
                        onChange={handleChangeFieldInputNumber('OtherFee1TaxPercentage')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                      <Checkbox
                        style={{ marginTop: 10 }}
                        disabled={itemModel.IsLocked}
                        checked={ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission}
                        onChange={handleChangeFieldCheckbox('IncludeManualFee1ToDoctorCommission')}
                      >
                        Specialist Service
                      </Checkbox>
                    </Form.Item>
                    <div />
                    <Form.Item
                      className="fluid-field"
                      label={
                        <>
                          {ServiceNotesandFeeObj.OtherFee1Label ? ServiceNotesandFeeObj.OtherFee1Label : 'Fee 1'}{' '}
                          Description
                        </>
                      }
                    >
                      <Input.TextArea
                        rows={3}
                        value={ServiceNotesandFeeObj.OtherFee1Description}
                        onChange={handleChangeFieldInput('OtherFee1Description')}
                      />
                    </Form.Item>
                    <Form.Item label="Service Fees (excl GST)">
                      <InputNumber
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        value={ServiceNotesandFeeObj.OtherFee2Value}
                        onChange={handleChangeFieldInputNumber('OtherFee2Value')}
                        disabled={itemModel.IsLocked}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    <Form.Item label="GST">
                      <InputNumber
                        size="large"
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        value={ServiceNotesandFeeObj.OtherFee2TaxPercentage}
                        onChange={handleChangeFieldInputNumber('OtherFee2TaxPercentage')}
                        disabled={itemModel.IsLocked}
                      />
                    </Form.Item>
                    <Form.Item
                      className="fluid-field"
                      label={
                        <>
                          {ServiceNotesandFeeObj.OtherFee1Label ? ServiceNotesandFeeObj.OtherFee1Label : 'Fee 2'}{' '}
                          Description
                        </>
                      }
                    >
                      <Input.TextArea
                        rows={3}
                        value={ServiceNotesandFeeObj.OtherFee2Description}
                        onChange={handleChangeFieldInput('OtherFee2Description')}
                      />
                    </Form.Item>
                  </>
                )}

                <Divider className="fluid-field" />
                <Form.Item label="Total Billing Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.TotalWithoutTax}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.TaxTotal}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total Billing Amount (incl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.Total ? parseFloat(ServiceNotesandFeeObj.Total).toFixed(2) : 0}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item className="fluid-field" label="Description">
                  <Input.TextArea
                    rows={3}
                    value={ServiceNotesandFeeObj.Description}
                    onChange={handleChangeFieldInput('Description')}
                  />
                </Form.Item>
                <Form.Item label="Attach Client Files" className="add-file-container">
                  <FieldUpload type={9} disabled={itemModel.IsLocked} multiple />
                </Form.Item>
              </Collapse.Panel>,
              <Collapse.Panel
                key="2"
                header={
                  <Header
                    title="Client Prepayment"
                    isChecked={itemModel.ClientPrepaymentReceived || itemModel.ClientPrepaymentRequired}
                    text={
                      itemModel.ClientPrepaymentReceived
                        ? 'Prepayment Received'
                        : itemModel.ClientPrepaymentRequired
                        ? 'Prepayment Required'
                        : ''
                    }
                    date={itemModel.ClientPrepaymentReceived ? itemModel.ClientPrepaymentPaidDate : null}
                    isRequired={false}
                  />
                }
              >
                {api.isAdminOrMagStaffOrAccounting() && (
                  <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                      disabled={itemModel.isLocked}
                      checked={itemModel.ClientPrepaymentRequired}
                      className="checkbox-session"
                      onChange={handleChangeFieldCheckbox('ClientPrepaymentRequired')}
                    >
                      Client Prepayment Required
                    </Checkbox>
                  </div>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <div className="checkbox-field" style={{ marginTop: 20 }}>
                    <Checkbox disabled checked={itemModel.ClientInvoiced}>
                      Client Invoice Created
                      {itemModel.InvoiceNo ? ` - Invoice No: ${itemModel.InvoiceNo}` : ''}
                    </Checkbox>
                  </div>
                )}
                <div className="checkbox-field" style={{ marginTop: 20 }}>
                  <Checkbox
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    checked={itemModel.DelayPrepaymentRequired}
                    onChange={handleChangeFieldCheckbox('DelayPrepaymentRequired')}
                  >
                    Delay Sending Reports{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Do not allow sending completed report to client until payment is made"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </div>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item label="Invoice Date">
                    <DatePicker
                      size="large"
                      value={renderDate(itemModel.ClientInvoicedDate)}
                      disabled
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
                {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
                  <Form.Item
                    validateStatus={
                      itemModel.ClientPrepaymentRequired && getMessageError('ClientPrepaymentRequiredDate', 'field')
                        ? 'error'
                        : null
                    }
                    help={
                      itemModel.ClientPrepaymentRequired && getMessageError('ClientPrepaymentRequiredDate', 'field')
                        ? getMessageError('ClientPrepaymentRequiredDate', 'field')
                        : ''
                    }
                    label="To be paid by"
                    required={itemModel.ClientPrepaymentRequired}
                  >
                    <DatePicker
                      disabled={!itemModel.ClientPrepaymentRequired}
                      size="large"
                      value={renderDate(itemModel.ClientPrepaymentRequiredDate)}
                      onChange={handleChangeDatePicker('ClientPrepaymentRequiredDate')}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
                <div className="checkbox-field" style={{ marginTop: 20 }}>
                  <Checkbox
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    checked={itemModel.ClientPrepaymentReceived}
                    onChange={handleChangeFieldCheckbox('ClientPrepaymentReceived')}
                  >
                    Client Prepayment Received
                  </Checkbox>
                </div>
                <Form.Item
                  required={itemModel.ClientPrepaymentReceived}
                  label="Payment Date"
                  validateStatus={
                    itemModel.ClientPrepaymentReceived && getMessageError('ClientPrepaymentPaidDate', 'field')
                      ? 'error'
                      : null
                  }
                  help={
                    itemModel.ClientPrepaymentReceived && getMessageError('ClientPrepaymentPaidDate', 'field')
                      ? getMessageError('ClientPrepaymentPaidDate', 'field')
                      : ''
                  }
                >
                  <DatePicker
                    size="large"
                    value={renderDate(itemModel.ClientPrepaymentPaidDate)}
                    format="DD/MM/YYYY"
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentReceived}
                    onChange={handleChangeDatePicker('ClientPrepaymentPaidDate')}
                  />
                </Form.Item>
                <Form.Item label="Attached Invoice" className="add-file-container">
                  <FieldUpload type={20} disabled={itemModel.IsLocked} multiple />
                </Form.Item>
                <div className="checkbox-field fluid-field">
                  <Checkbox
                    disabled={!!itemModel.SentByEmailInvoiceClient}
                    checked={itemModel.EmailInvoiceClient}
                    onChange={handleChangeFieldCheckbox('EmailInvoiceClient')}
                  >
                    Email Invoice to Client
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Sending quotation and invoice to Client (Mail-108A)"
                        position="top center"
                        wide="very"
                      />
                    )}
                  </Checkbox>
                  {itemModel.SentDateEmailInvoiceClient && (
                    <>
                      <span
                        style={{
                          color: '#107fc9',
                          display: 'inline-block',
                          width: '300px',
                        }}
                      >
                        {itemModel.SentDateEmailInvoiceClient
                          ? renderDate(itemModel.SentDateEmailInvoiceClient, false, true)
                          : ''}
                        {itemModel.EmailPaymentReceiptToClientBy
                          ? ` - By ${store.renderStaffName(itemModel.SentByEmailInvoiceClient)}`
                          : ''}
                      </span>
                      {!!itemModel.SentDateEmailInvoiceClient && (
                        <Button className="blue" style={{ marginLeft: 10 }} onClick={this.EmailInvoiceClientResend}>
                          Resend
                        </Button>
                      )}
                    </>
                  )}
                </div>

                {/* {!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && <div />} */}

                <Form.Item label="Attached Payment Receipt" className="add-file-container">
                  <FieldUpload
                    multiple
                    type={18}
                    disabled={itemModel.IsLocked}
                    functionCallback={this.handleSendPaymentReceipt}
                  />
                </Form.Item>
                <div className="checkbox-field fluid-field">
                  <Checkbox
                    disabled={itemModel.isLocked || !!itemModel.EmailPaymentReceiptToClientDateSent}
                    checked={itemModel.EmailPaymentReceiptToClient}
                    onChange={handleChangeFieldCheckbox('EmailPaymentReceiptToClient')}
                  >
                    Email Payment Receipt to Client
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Payment receipt will be sent to Client(MAIL-141)"
                        position="top center"
                        wide="very"
                      />
                    )}
                  </Checkbox>
                  {itemModel.EmailPaymentReceiptToClientDateSent && (
                    <>
                      <span
                        style={{
                          color: '#107fc9',
                          display: 'inline-block',
                          width: '300px',
                        }}
                      >
                        {itemModel.EmailPaymentReceiptToClientDateSent
                          ? `${renderDate(itemModel.EmailPaymentReceiptToClientDateSent, false, true)}`
                          : ''}
                        {itemModel.EmailPaymentReceiptToClientBy
                          ? ` - By ${store.renderStaffName(itemModel.EmailPaymentReceiptToClientBy)}`
                          : ''}
                      </span>
                      {!!itemModel.EmailPaymentReceiptToClientDateSent && (
                        <Button
                          className="blue"
                          style={{ marginLeft: 10 }}
                          onClick={this.ResendEmailClientPaymentReceipt}
                        >
                          Resend Payment Receipt
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <Form.Item className="fluid-field" label="Note">
                  <Input.TextArea
                    rows={3}
                    onChange={handleChangeFieldInput('ClientPrepaymentNote')}
                    value={itemModel.ClientPrepaymentNote}
                  />
                </Form.Item>
              </Collapse.Panel>,
            ]}

            {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && [
              <Collapse.Panel
                key="3"
                header={
                  <Header
                    title="Specialist Billing Information"
                    isChecked={(ServiceNotesandFeeObj.CommissionRateType ?? -1) > -1}
                    text="Specialist Rate Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Booking Fee Type">
                  <Radio.Group
                    value={ServiceNotesandFeeObj.CommissionRateType}
                    onChange={handleChangeFieldRadio('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={0} className="radio">
                      Medicolegal Provider Add-On Rate
                    </Radio>
                    <Radio value={1} className="radio">
                      Medicolegal Provider Standard Rate
                    </Radio>
                  </Radio.Group>
                  <Radio.Group
                    value={ServiceNotesandFeeObj.CommissionRateType}
                    onChange={handleChangeFieldRadio('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={3} className="radio">
                      Gross Margin COT
                    </Radio>
                    <Radio value={2} className="radio">
                      Fixed Amount
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {ServiceNotesandFeeObj.CommissionRateType !== 2 ? (
                  <>
                    <Form.Item
                      label={
                        <React.Fragment>
                          Commission Rate (%)
                          <button className="field-actions" onClick={this.handleCheckCurrentRate}>
                            Check Current Rate
                          </button>
                        </React.Fragment>
                      }
                    >
                      <InputNumber
                        size="large"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        value={ServiceNotesandFeeObj.CommissionRate}
                        onChange={handleChangeFieldInputNumber('CommissionRate')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <div />
                )}
                <Form.Item label="Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={
                      ServiceNotesandFeeObj.TotalAmountForCommissionCalculation
                        ? parseFloat(ServiceNotesandFeeObj.TotalAmountForCommissionCalculation).toFixed(2)
                        : 0
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={
                      ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax
                        ? parseFloat(ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax).toFixed(2)
                        : 0
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={(
                      parseFloat(ServiceNotesandFeeObj.TotalAmountForCommissionCalculation) +
                      parseFloat(ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax)
                    ).toFixed(2)}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {ServiceNotesandFeeObj.CommissionRateType === 3 ? (
                  <>
                    <Form.Item label="Gross Margin COT Rate (%)">
                      <InputNumber
                        size="large"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        value={ServiceNotesandFeeObj.CommissionOnTopRate}
                        onChange={handleChangeFieldInputNumber('CommissionOnTopRate')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <div />
                )}
                <Form.Item label="Eligible Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={ServiceNotesandFeeObj.CommissionAmount}
                    onChange={handleChangeFieldInputNumber('CommissionAmount')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={(parseFloat(ServiceNotesandFeeObj.CommissionAmount) * 0.1).toFixed(2)}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Eligible Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={
                      ServiceNotesandFeeObj.CommissionAmountGstNilEnabled
                        ? parseFloat(ServiceNotesandFeeObj.CommissionAmount).toFixed(2)
                        : (parseFloat(ServiceNotesandFeeObj.CommissionAmount) * 0.1).toFixed(2)
                    }
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {/* ---specialists invoice */}
              </Collapse.Panel>,
              !store.isServicePrepaymentLocked && (
                <Collapse.Panel
                  key="4"
                  header={
                    <Header
                      title="Specialist Prepayment"
                      isChecked={itemModel.SpecialistPrepaymentPaid || itemModel.DoctorPrepaymentRequired}
                      text={
                        itemModel.SpecialistPrepaymentPaid
                          ? 'Prepayment Paid'
                          : itemModel.DoctorPrepaymentRequired
                          ? 'Prepayment Required'
                          : ''
                      }
                      date={itemModel.SpecialistPrepaymentPaid ? itemModel.PrepaymentPaidDate : null}
                      isRequired={false}
                    />
                  }
                >
                  {!store.isServicePrepaymentLocked && (
                    <React.Fragment>
                      <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox
                          disabled={store.isServicePrepaymentLocked}
                          checked={itemModel.DoctorPrepaymentRequired}
                          onChange={handleChangeFieldCheckbox('DoctorPrepaymentRequired')}
                          className="checkbox-session"
                        >
                          Specialist Prepayment Required
                          <Popup
                            trigger={<IconSemantic name="info circle" style={{ marginLeft: '5px' }} />}
                            content="Prepayment notice will be sent to staff (MAIL-108)"
                            position="top center"
                            wide="very"
                          />
                        </Checkbox>
                      </div>
                      <div className="checkbox-field">
                        <Checkbox
                          disabled
                          checked={store.specialistSelected?.ApprovedPrepayment}
                          style={{ marginTop: 20 }}
                        >
                          Approved Prepayment
                          <Popup
                            trigger={<IconSemantic name="info circle" />}
                            content="This doctor has been approved for payment before report is completed"
                            position="top center"
                            wide="very"
                          />
                        </Checkbox>
                      </div>
                      <Form.Item label="Valid Until">
                        <DatePicker
                          size="large"
                          value={renderDate(store.specialistSelected?.ValidUntil)}
                          disabled
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <React.Fragment>
                      <Form.Item className="checkbox-field">
                        <Checkbox disabled checked={itemModel.DoctorInvoiced}>
                          Specialist Bill Created {itemModel.InvoiceNo ? ` - Invoice No: ${itemModel.InvoiceNo}` : ''}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item label="Bill Date">
                        <DatePicker
                          size="large"
                          value={renderDate(itemModel.DoctorInvoicedDate)}
                          disabled
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}
                  <Form.Item
                    validateStatus={itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'error' : null}
                    help={
                      itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'Invoice Date is required' : ''
                    }
                    label="Invoice Date"
                    required={itemModel.PrepaymentRequired}
                  >
                    <DatePicker
                      size="large"
                      disabled={store.isServicePrepaymentLocked}
                      value={renderDate(itemModel.PaymentRequestedDate)}
                      onChange={handleChangeDatePicker('PaymentRequestedDate')}
                    />
                  </Form.Item>
                  {!store.isServicePrepaymentLocked && (
                    <Form.Item
                      validateStatus={
                        itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate ? 'error' : null
                      }
                      help={
                        itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate
                          ? 'Prepayment Required Date is required'
                          : ''
                      }
                      label="To be paid by"
                      required={itemModel.DoctorPrepaymentRequired}
                    >
                      <DatePicker
                        size="large"
                        disabled={store.isServicePrepaymentLocked}
                        value={renderDate(itemModel.PrepaymentRequiredDate)}
                        onChange={handleChangeDatePicker('PrepaymentRequiredDate')}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  )}
                  <Form.Item label="Attached Specialist Invoice" className="add-file-container">
                    <FieldUpload multiple type={14} functionCallback={this.PaymentRequestedUploadComplete} />
                  </Form.Item>
                  <div className="checkbox-field fluid-field">
                    <Checkbox
                      disabled={store.isServicePrepaymentLocked || !!itemModel.EmailInvoiceToMAGDateSent}
                      checked={itemModel.EmailInvoiceToMAG}
                      onChange={handleChangeFieldCheckbox('EmailInvoiceToMAG')}
                    >
                      Email Invoice to Medicolegal Provider
                      {!store.servicePrepaymentLocked && (
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="Specialist invoice will be sent to staff (MAIL-135)"
                          position="top center"
                          wide="very"
                        />
                      )}
                      {!!itemModel.EmailInvoiceToMAGDateSent && (
                        <span
                          style={{
                            display: 'inline-block',
                            width: '300px',
                            // marginLeft: '10px',
                            color: '#107fc9',
                          }}
                        >
                          {itemModel.EmailInvoiceToMAGDateSent
                            ? renderDate(itemModel.EmailInvoiceToMAGDateSent, false, true)
                            : ''}{' '}
                          - By {store.renderStaffName(itemModel.EmailInvoiceToMAGStaffSent) || ''}
                        </span>
                      )}
                      {!!itemModel.EmailInvoiceToMAGDateSent && (
                        <Button className="blue" style={{ marginLeft: '5px' }} onClick={this.onclickResendInvoice}>
                          Resend Invoice
                        </Button>
                      )}
                    </Checkbox>
                  </div>
                  <Form.Item className="checkbox-field">
                    <Checkbox
                      disabled={itemModel.isLocked}
                      checked={itemModel.SpecialistPrepaymentPaid}
                      onChange={handleChangeFieldCheckbox('SpecialistPrepaymentPaid')}
                    >
                      Specialist Prepayment Paid
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="Prepayment Date"
                    validateStatus={
                      itemModel.SpecialistPrepaymentPaid && !itemModel.PrepaymentPaidDate ? 'error' : null
                    }
                    help={
                      itemModel.SpecialistPrepaymentPaid && !itemModel.PrepaymentPaidDate
                        ? 'Prepayment Paid Date is required'
                        : ''
                    }
                    required={itemModel.SpecialistPrepaymentPaid}
                  >
                    <DatePicker
                      size="large"
                      value={renderDate(itemModel.PrepaymentPaidDate)}
                      onChange={handleChangeDatePicker('PrepaymentPaidDate')}
                      disabled={itemModel.isLocked || !itemModel.SpecialistPrepaymentPaid}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item label="Attached Payment Receipt" className="add-file-container">
                    <FieldUpload multiple type={15} functionCallback={this.PrepaymentPaidUploadComplete} />
                  </Form.Item>
                  <div className="checkbox-field  fluid-field">
                    <Checkbox
                      disabled={itemModel.isLocked || !!itemModel.EmailPaymentReceiptToDoctorDateSent}
                      checked={itemModel.EmailPaymentReceiptToDoctor}
                      onChange={handleChangeFieldCheckbox('EmailPaymentReceiptToDoctor')}
                    >
                      Email Payment Receipt to Specialist
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="Payment receipt will be sent to Specialist (MAIL-107, DIRECT-107)"
                          position="top center"
                          wide="very"
                        />
                      )}
                      {!!itemModel.EmailPaymentReceiptToDoctorDateSent && (
                        <span
                          style={{
                            display: 'inline-block',
                            width: '300px',
                            // marginLeft: '10px',
                            color: '#107fc9',
                          }}
                        >
                          {itemModel.EmailPaymentReceiptToDoctorDateSent
                            ? renderDate(itemModel.EmailPaymentReceiptToDoctorDateSent, false, true)
                            : ''}{' '}
                          {/* - By:{' '}
                    {store.renderStaffName(
                      itemModel.EmailInvoiceToMAGStaffSent,
                    ) || ''} */}
                        </span>
                      )}
                      {!!itemModel.EmailPaymentReceiptToDoctorDateSent && (
                        <Button
                          className="blue"
                          style={{ marginLeft: '5px' }}
                          onClick={this.onclickResendEmailPaymentReceipt}
                        >
                          Resend Payment Receipt
                        </Button>
                      )}
                    </Checkbox>
                  </div>
                  <Form.Item className="fluid-field" label="Note">
                    <Input.TextArea
                      rows={3}
                      onChange={handleChangeFieldInput('SpecialistPrepaymentNote')}
                      value={itemModel.SpecialistPrepaymentNote}
                    />
                  </Form.Item>
                </Collapse.Panel>
              ),
            ]}
          </Collapse>
        </Form>
      );
    return <ListInvoice />;
  }
}

export default ClientInvoicingForm;
