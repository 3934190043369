import { Button, Upload } from 'antd';
import { Icon } from 'semantic-ui-react';
import React from 'react';
import { uploadFileBase } from '@utils/uploadFileBase';
import './FileUpload.scss';

function FileUpload(props) {
  const handleChange = info => {
    // const res = await uploadFileBase(acceptedFiles, props.maxSize);
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    props.onFileChange(fileList);
  };

  const fileProps = {
    onChange: handleChange,
    multiple: props.multiple || false,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        var res = await uploadFileBase([file]);
        onSuccess(res.fileList[0]);
      } catch (error) {
        onError('Upload failed');
      }
    },
  };

  return (
    <div className="file-upload-container">
      <Upload {...fileProps}>
        <Button icon={<Icon name="Upload" />}>{props.label}</Button>
      </Upload>
    </div>
  );
}

export default FileUpload;
