import './Menu.scss';

import cx from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';

import router from '../../../stores/router';
import routes from '../../dashboard-routes';
import Icon8 from '../../shared/Icon8';

@observer
class MenuItem extends React.Component {
  render() {
    const {
      labelMenu,
      path,
      title,
      target,
      materialIcon,
      noImage,
      showBubble,
      bubbleText = '',
      bubbleClick,
      ...rest
    } = this.props;
    const { title: routeTitle, icon, disabled } = routes[path];
    const finalTitle = title || routeTitle;

    if (target && target === '_blank') {
      return (
        <a
          href={path}
          target={target}
          className={cx(materialIcon ? 'MenuItemParent' : 'MenuItem', { disabled, labelMenu })}
        >
          <Icon8 name={noImage ? '' : icon} title={finalTitle} materialIcon={materialIcon ? materialIcon : null} />
          {finalTitle}
        </a>
      );
    }

    const active = path === router.location.pathname;
    return (
      <Link
        {...rest}
        to={path}
        className={cx(materialIcon ? 'MenuItemParent' : 'MenuItem', { active, disabled, labelMenu })}
      >
        <Icon8
          name={noImage ? '' : rest.icon || icon}
          title={finalTitle}
          materialIcon={materialIcon ? materialIcon : null}
        />
        {showBubble ? (
          <>
            <span>{finalTitle}</span>
            <Label
              color="red"
              horizontal
              style={{ position: 'absolute', right: 0 }}
              onClick={() => {
                bubbleClick && bubbleClick();
              }}
            >
              {bubbleText}
            </Label>
          </>
        ) : (
          finalTitle
        )}
      </Link>
    );
  }
}

@observer
class MenuGroup extends React.Component {
  @observable hidden = this.props.hidden;
  @action toggle = () => {
    this.hidden = !this.hidden;
  };

  render() {
    const { hidden } = this;
    const { title, children, materialIcon, name } = this.props;

    return (
      <React.Fragment>
        <div className="MenuGroup" onClick={this.toggle}>
          {materialIcon ? <Icon8 name={name} title={title} materialIcon={materialIcon ? materialIcon : null} /> : null}
          {title}
          <Icon name={`caret ${hidden ? 'right' : 'down'}`} />
        </div>
        <div className={cx('MenuGroup-Children', { hidden })}>{children}</div>
      </React.Fragment>
    );
  }
}

export { MenuItem, MenuGroup };
