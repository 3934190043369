import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import ClinicalRecordModal from './ClinicalRecordModal';
import ClinicalRecordStore from './ClinicalRecordStore';
import ClinicalRecordToolbar from './ClinicalRecordToolbar';
import ClinicalRecordTable from './ClinicalRecordTable';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';
import * as api from '../../../stores/api';
import moment from 'moment';

window.reloadClinicalRecord = () => {
  ClinicalRecordStore.refetch({}, true);
};
@observer
class FileReview extends React.Component {
  renderCaseForCM = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case 'Corrected Report Sent to MAG':
        return 'Corrected Report Returned from Provider';
      default:
        return caseStatus;
    }
  };
  componentDidMount() {
    this._searchDebounced();
    // ui.on('open(/view/clinical-notes-2)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?actionRequired=') !== -1) {
        const id = search.replace('?actionRequired=', '');
        ClinicalRecordStore.refetch({ actionRequiredById: id }, true);
      } else if (search.indexOf('?caseStatus=') !== -1 && api.isCaseManager()) {
        const id = search.replace('?caseStatus=', '');
        ClinicalRecordStore.refetch({ filter: this.renderCaseForCM(id) }, true);
      } else if (search.indexOf('?fromToRequest') !== -1) {
        ClinicalRecordStore.refetch({ fromTo: `${moment().format('DD/MM/YYYY')} - ` }, true);
      } else {
        const id = search.replace('?nextTaskDue=', '');
        if (api.isCaseManager()) {
          ClinicalRecordStore.refetch({ filter: this.renderCaseForCM(id) }, true);
        } else {
          ClinicalRecordStore.refetch({ filterOverdue: id }, true);
        }
      }
    } else {
      ClinicalRecordStore.refetch({}, true);
    }
  }, 1000);

  render() {
    const {
      open,
      loading,
      loadingClaimant,
      loadingCRStatus,
      loadingOverDueCRStatus,
      loadingStaffAssign,
      loadingStaffAssignAction,
      data,
      dataClaimant,
      dataCRStatus,
      dataOverDueCRStatus,
      dataStaffAssign,
      dataStaffAssignAction,
    } = ClinicalRecordStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {(loading && !data) ||
        (!dataClaimant && loadingClaimant) ||
        (loadingOverDueCRStatus && !dataOverDueCRStatus) ||
        (loadingCRStatus && !dataCRStatus) ||
        (loadingStaffAssignAction && !dataStaffAssignAction) ||
        (loadingStaffAssign && !dataStaffAssign) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ClinicalRecordToolbar />
            <ClinicalRecordTable />
            {open && <ClinicalRecordModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FileReview;
