import cx from 'classnames';
import { action, computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Dropdown, Icon, Form as FormSemantic, Radio } from 'semantic-ui-react';
import * as api from '../../stores/api';
import ButtonSubmit from '../shared/form/ButtonSubmit';
import FormGroupTitle from '../shared/FormGroupTitle';
import customFetch from '../../utils/customFetch';

const fields = [
  // { name: 'AccType', value: 'doc', label: 'Doctor Account', style: { marginLeft: '0em' } },
  {
    name: 'AccType',
    value: 'cm',
    label: 'Case Manager Account',
    checked: true,
    style: { marginLeft: '0em' },
  },
  { name: 'CompanyName', rule: 'required', label: 'Company Name', value: '' },
  {
    name: 'StreetAddress',
    rule: 'required',
    label: 'Street Address',
    // value: '',
  },
  { name: 'Suburb', rule: 'required', label: 'Suburb', multi: 1, value: '' },
  {
    name: 'Postcode',
    rule: 'required',
    label: 'Postcode',
    multi: 1,
    maxLength: 4,
    value: '',
  },
  // { name: 'State', rule: 'required', label: 'State' },
  {
    name: 'BusinessType',
    value: 1,
    label: 'Insurer',
    checked: true,
    style: { marginLeft: '0em' },
  },
  {
    name: 'BusinessType',
    value: 6,
    label: 'Self Insurer',
    style: { marginLeft: '3.2em' },
  },
  {
    name: 'BusinessType',
    value: 2,
    label: 'Law Defendant',
    style: { marginLeft: '1.6em' },
  },
  {
    name: 'BusinessType',
    value: 3,
    label: 'Law Plaintiff',
    style: { marginLeft: '0em' },
  },
  { name: 'BusinessType', value: 4, label: 'Law Practice' },
  { name: 'BusinessType', value: 5, label: 'Other' },
  { name: 'FirstName', rule: 'required', label: 'First Name', multi: 1 },
  { name: 'LastName', rule: 'required', label: 'Last Name', multi: 1 },
  { name: 'Email', rule: 'required|email', label: 'Email' },
  {
    name: 'PhoneNumber',
    rule: 'required',
    label: 'Phone Number',
    // type: 'number',
    maxLength: 10,
  },
  {
    name: 'Mobile',
    rule: 'required',
    label: 'Mobile',
    // type: 'number',
    maxLength: 10,
  },
];

const State = [
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

const Title = ['Mr', 'Ms', 'Mrs'];

const aboutServiceOptions = [
  { value: 1, text: `My colleague's referral` },
  { value: 2, text: `Your company website` },
  { value: 3, text: `Your Linkedin` },
  { value: 6, text: `Your seminars/conference` },
  { value: 7, text: `Your staff advice` },
  { value: 99, text: 'Other' },
];

@observer
class Register extends React.Component {
  //For client
  @observable loadingSearchClient = true;
  @observable searchClient = '';
  @observable clients = [];

  @observable modelCM = {
    CompanyName: '',
    StreetAddress: '',
    Suburb: '',
    Postcode: '',
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    Mobile: '',
    AboutOurServie: 0,
    CreatedBy: '',
    OtherAboutServiceDetail: '',
  };

  @observable errorModelCM = {
    CompanyName: '',
    StreetAddress: '',
    Suburb: '',
    Postcode: '',
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    AboutOurServie: '',
    CreatedBy: '',
    OtherAboutServiceDetail: '',
  };

  @observable registerSuccessfully = false;
  @observable step = 1;

  @observable ddlClients = [];
  @observable ddlPosition = [];

  @observable numberSearchClient = 30;

  @observable stateSelected = null;
  @observable titleSelected = null;
  @observable positionSelected = null;
  @observable clientSelected = null;
  @observable businessType = 1;
  @observable accType = 'cm';

  @observable ddlRegOlSource = null;
  @observable regOlSourceSelected = null;
  @observable ddlStaffs = null;
  @observable staffSelected = null;

  @computed get hidden() {
    return this.step > 1;
  }
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
  }

  @action renderStateOptions = () => {
    // this.titleSelected = 'Mr'; //set default
    return State.map(opt => ({
      key: opt.id,
      text: opt.label,
      value: opt.id,
    }));
  };

  @action renderTitleOptions = () => {
    // this.stateSelected = '109'; //set default
    return Title.map(opt => ({
      key: opt,
      text: opt,
      value: opt,
    }));
  };

  componentDidMount() {
    this.getClientInfo();
    this.getPositionInfo();
    this.getStaff();
  }

  @action Clear = () => {
    this.stateSelected = null;
    this.titleSelected = null;
    this.positionSelected = null;
    this.clientSelected = null;
    this.businessType = 1;
    this.accType = 'cm';
    this.modelCM = {
      CompanyName: '',
      StreetAddress: '',
      Suburb: '',
      Postcode: '',
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      Mobile: '',
      AboutOurServie: 0,
      CreatedBy: '',
      OtherAboutServiceDetail: '',
    };
    this.errorModelCM = {
      CompanyName: '',
      StreetAddress: '',
      Suburb: '',
      Postcode: '',
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      AboutOurServie: '',
      CreatedBy: '',
      OtherAboutServiceDetail: '',
      // Mobile: '',
    };
  };

  @action resetRegister = () => {
    this.registerSuccessfully = false;
    this.Clear();
  };

  onStepChange = step => {
    return action(() => (this.step += step));
  };

  getDataField = (name, value, disable) => {
    var obj = fields.find(x => x.name === name);
    obj.value = value;
    obj.disabled = disable;
    if (this.formikRef.current) {
      this.formikRef.current.setFieldValue(name, value);
    }
  };

  @action onBusinessTypeChange = (_, { value }) => {
    this.businessType = value;
  };

  @action onChangePosition = (_, { value }) => {
    this.positionSelected = value;
  };

  @action setRegisterSuccess = value => {
    this.registerSuccessfully = value;
  };

  @action onChangeRegOlSource = (_, { value }) => {
    if (value) {
      this.errorModelCM.AboutOurServie = '';
    }
    if (value != 99) {
      this.errorModelCM.OtherAboutServiceDetail = '';
    }
    this.modelCM.AboutOurServie = value;
    this.regOlSourceSelected = value;
  };

  @action onChangeStaff = (_, { value }) => {
    this.staffSelected = value;
  };

  @action onChange = (e, data) => {
    this.clientSelected = data.value;
    var client = this.clients.find(x => x.Id === this.clientSelected);
    //------------
    if (client) {
      //------ Add clearable
      this.modelCM.CompanyName = client.Name || 'N/A';
      this.modelCM.StreetAddress = client.Address || 'N/A';
      this.modelCM.Suburb = client.District || 'N/A';
      this.modelCM.Postcode = client.Postcode || 'N/A';
      this.businessType = client.ClientType;
      this.stateSelected = client.State;
      this.errorModelCM = {
        CompanyName: '',
        StreetAddress: '',
        Suburb: '',
        Postcode: '',
        CreatedBy: '',
        OtherAboutServiceDetail: '',
      };
    } else {
      this.modelCM.CompanyName = '';
      this.modelCM.StreetAddress = '';
      this.modelCM.Suburb = '';
      this.modelCM.Postcode = '';
      this.modelCM.CreatedBy = '';
      this.modelCM.OtherAboutServiceDetail = '';
      this.businessType = 1;
      this.stateSelected = null;
      this.errorModelCM = {
        CompanyName: 'This field is required',
        StreetAddress: 'This field is required',
        Suburb: 'This field is required',
        Postcode: 'This field is required',
      };
    }
  };

  @action onSearchChange = (_, { searchQuery }) => {
    this.searchClient = searchQuery;
    this.getClientInfo();
  };

  @action onChangeState = (_, { value }) => {
    this.stateSelected = value;
  };

  @action onChangeTitle = (e, data) => {
    this.titleSelected = data.value;
  };

  @action getClientInfo = () => {
    // if (this.accType === 'cm') {
    this.loadingSearchClient = true;
    return customFetch('/Company/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.searchClient,
        IsSelectionList: true,
        NumItemPerPage: this.numberSearchClient,
      }),
    }).then(
      action(data => {
        if (data && data.itemList) {
          this.clients = data.itemList;
          this.ddlClients = this.clients.map(opt => ({
            key: opt.Id,
            text: opt.Name,
            value: opt.Id,
          }));
        }
        this.loadingSearchClient = false;
      }),
    );
    // }
  };

  @action getPositionInfo = () => {
    return customFetch('/Staff/GetSpecificLookupConfigByType', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        type: 'job',
      }),
    }).then(
      action(data => {
        if (data && data.itemList) {
          this.ddlPosition = data.itemList.map(opt => ({
            key: opt.Id,
            text: opt.Label,
            value: opt.Id,
          }));
        }
      }),
    );
  };

  @action getStaff = () => {
    return customFetch('/Account2/GetStaffRegister', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
    }).then(
      action(data => {
        if (data && data.itemList) {
          this.ddlStaffs = data.itemList.map(opt => ({
            key: opt.Id,
            text: opt.FullName,
            value: opt.Id,
          }));
        }
      }),
    );
  };

  @action handleScrollClient = event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    this.numberSearchClient += 30;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      this.getClientInfo();
    }
  };

  @action onValidChangeInput = (name, input) => {
    if (name === 'Email') {
      if (!input) {
        this.errorModelCM[name] = 'This field is required';
      } else {
        if (!this.validateEmail(input)) {
          this.errorModelCM[name] = 'Invalid email address';
        } else {
          this.errorModelCM[name] = '';
        }
      }
    } else if (name !== 'Mobile' && name !== 'CreatedBy' && name !== 'OtherAboutServiceDetail') {
      if (!input) {
        this.errorModelCM[name] = 'This field is required';
      } else {
        this.errorModelCM[name] = '';
      }
    }
  };

  @action checkValidSubmit = () => {
    const data = this.modelCM;
    const inValid = this.errorModelCM;
    for (let i in data) {
      if (i !== 'Mobile') {
        if (!data[i]) {
          this.onValidChangeInput(i, '');
        }
        if (i === 'Email') {
          if (!data[i]) {
            this.errorModelCM[i] = 'This field is required';
          } else {
            if (!this.validateEmail(data[i])) {
              this.errorModelCM[i] = 'Invalid email address';
            } else {
              this.errorModelCM[i] = '';
            }
          }
        }
        if (i === 'OtherAboutServiceDetail' && !data[i] && this.regOlSourceSelected == 99) {
          this.errorModelCM[i] = 'This field is required';
        }
      }
    }
    for (let e in inValid) {
      if (inValid[e]) {
        return false;
      }
    }
    return true;
  };

  onSubmit = () => {
    const isValid = this.checkValidSubmit();
    if (!isValid) {
      notification.destroy();
      notification.error({
        message: 'Invalid',
        description: 'Your provided data is invalid',
        placement: 'topRight',
      });
    } else {
      const bodyModal = {
        ...this.modelCM,
        Mobile: this.modelCM.Mobile.replace(/[^\d]/g, ''),
        PhoneNumber: this.modelCM.PhoneNumber.replace(/[^\d]/g, ''),
        State: this.stateSelected,
        Tilte: this.titleSelected,
        CompanyId: this.clientSelected || 0,
        AccType: this.accType,
        BusinessType: this.businessType,
        Position: this.positionSelected,
        AboutOurServie: this.regOlSourceSelected,
        CreatedBy: this.staffSelected,
      };
      //api.register.post(bodyModal, () => (this.registerSuccessfully = true), () => this.saveError);
      customFetch('/Account2/Register', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(bodyModal),
      }).then(respone => {
        if (respone.status === 'success') {
          this.setRegisterSuccess(true);
        } else {
          if (respone.error === 'ExistEmail') {
            notification.destroy();
            notification.error({
              message: 'Invalid',
              description: (
                <>
                  Your email has been already registered in our system. Please contact{' '}
                  <a
                    href={`mailto:admin@medicolegalassessmentsgroup.com.au`}
                    onClick={() => {
                      window.location.href = 'admin@medicolegalassessmentsgroup.com.au';
                    }}
                  >
                    admin@medicolegalassessmentsgroup.com.au
                  </a>{' '}
                  for further support
                </>
              ),
              placement: 'topRight',
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Invalid',
              description: 'Your provided data is invalid',
              placement: 'topRight',
            });
          }
        }
      });
    }
  };

  saveError = () => {
    this.registerSuccessfully = false;
  };

  validateEmail = email => {
    return /\S+@\S+\.\S+/.test(email);
  };

  convertPhoneNumber = (number, type) => {
    const value = number;
    if (!value) return value;
    else if (value) {
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;
      if (type === 'Mobile') {
        if (cvLength < 4) {
          return currentValue;
        }
        if (cvLength < 8) {
          const sliceTwo = currentValue.slice(4, 7);
          return `${currentValue.slice(0, 4)}${sliceTwo ? ` ${currentValue.slice(4, 7)}` : ``}`;
        } else if (cvLength <= 10) {
          return `${currentValue.slice(0, 4)} ${currentValue.slice(4, 7)}${currentValue.slice(7, 10)}`;
        }
      } else {
        if (cvLength < 2) {
          return currentValue;
        }
        if (cvLength < 8) {
          const sliceTwo = currentValue.slice(2, 7);
          return `${currentValue.slice(0, 2)}${sliceTwo ? ` ${currentValue.slice(2, 7)}` : ``}`;
        } else if (cvLength <= 10) {
          return `${currentValue.slice(0, 2)} ${currentValue.slice(2, 7)}${currentValue.slice(7, 10)}`;
        }
      }
    }
  };

  @action onChangeTextInput = (_, { value, name }) => {
    this.onValidChangeInput(name, value);
    if (name === 'PhoneNumber') {
      this.modelCM[name] = this.convertPhoneNumber(value, 'PhoneNumber');
    } else if (name === 'Mobile') {
      this.modelCM[name] = this.convertPhoneNumber(value, 'Mobile');
    } else {
      this.modelCM[name] = value;
    }
  };

  renderRadioBox = fields => {
    return fields.map(i => (
      <div key={i.value} className="Radio-box">
        <Radio
          value={i.value}
          name={i.name}
          disabled={this.clientSelected ? true : false}
          checked={i.value === this.businessType}
          label={i.label}
          onChange={this.onBusinessTypeChange}
        />
      </div>
    ));
  };

  renderForm = () => {
    return (
      <FormSemantic size={'small'}>
        <div className={cx('formgroup')}>
          <FormGroupTitle>Company information</FormGroupTitle>
          <FormSemantic as="div" widths="equal">
            <FormSemantic.Group>
              <FormSemantic.Field>
                <div className="Input-Label required">Company Name</div>
                <FormSemantic.Input
                  name="CompanyName"
                  onChange={this.onChangeTextInput}
                  value={this.modelCM.CompanyName}
                  disabled={this.clientSelected ? true : false}
                  error={
                    this.errorModelCM.CompanyName
                      ? {
                          content: this.errorModelCM.CompanyName,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
            <FormSemantic.Group>
              <FormSemantic.Field>
                <div className="Input-Label required">Street Address</div>
                <FormSemantic.Input
                  name="StreetAddress"
                  onChange={this.onChangeTextInput}
                  disabled={this.clientSelected ? true : false}
                  value={this.modelCM.StreetAddress}
                  error={
                    this.errorModelCM.StreetAddress
                      ? {
                          content: this.errorModelCM.StreetAddress,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
            <FormSemantic.Group>
              <FormSemantic.Field>
                <div className="Input-Label required">Suburb</div>
                <FormSemantic.Input
                  name="Suburb"
                  onChange={this.onChangeTextInput}
                  value={this.modelCM.Suburb}
                  disabled={this.clientSelected ? true : false}
                  error={
                    this.errorModelCM.Suburb
                      ? {
                          content: this.errorModelCM.Suburb,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
              <FormSemantic.Field>
                <div className="Input-Label required">Postcode</div>
                <FormSemantic.Input
                  name="Postcode"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  disabled={this.clientSelected ? true : false}
                  maxLength={4}
                  value={this.modelCM.Postcode}
                  error={
                    this.errorModelCM.Postcode
                      ? {
                          content: this.errorModelCM.Postcode,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
          </FormSemantic>
          <div className={cx('formgroup')}>
            <div className="Input-Label required">State</div>
            <Dropdown
              fluid
              selection
              required
              clearable
              disabled={this.clientSelected ? true : false}
              name="stateSelected"
              placeholder="Select a state"
              className="input--overridden-style"
              options={toJS(this.renderStateOptions())}
              onChange={this.onChangeState}
              value={this.stateSelected}
              icon={!this.stateSelected ? <Icon name="dropdown" /> : <Icon name="delete" />}
            />
          </div>
          <div className="Container">{this.renderRadioBox(fields.slice(5, 9))}</div>
          <div className="Container">{this.renderRadioBox(fields.slice(9, 11))}</div>
        </div>
        <div className={cx('formgroup')}>
          <FormGroupTitle>Your information</FormGroupTitle>
          <div className={cx('formgroup')}>
            <div className="Input-Label required">Title</div>
            <Dropdown
              fluid
              selection
              name="Title"
              placeholder="Select a title"
              className="input--overridden-style"
              options={toJS(this.renderTitleOptions())}
              onChange={this.onChangeTitle}
              value={this.titleSelected}
              clearable
              icon={!this.titleSelected ? <Icon name="dropdown" /> : <Icon name="delete" />}
            />
          </div>
          {/* {this.renderFields(fields.slice(11, 13))} */}
          <FormSemantic as="div">
            <FormSemantic.Group widths="equal">
              <FormSemantic.Field>
                <div className="Input-Label required">First Name</div>
                <FormSemantic.Input
                  name="FirstName"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  value={this.modelCM.FirstName}
                  error={
                    this.errorModelCM.FirstName
                      ? {
                          content: this.errorModelCM.FirstName,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
              <FormSemantic.Field>
                <div className="Input-Label required">Last Name</div>
                <FormSemantic.Input
                  name="LastName"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  value={this.modelCM.LastName}
                  error={
                    this.errorModelCM.LastName
                      ? {
                          content: this.errorModelCM.LastName,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
          </FormSemantic>
          <div className={cx('formgroup')}>
            <div className="Input-Label required">Position</div>
            <Dropdown
              search
              fluid
              selection
              required
              clearable
              icon={!this.positionSelected ? <Icon name="dropdown" /> : <Icon name="delete" />}
              name="Position"
              placeholder="Select a position"
              className="input--overridden-style"
              options={toJS(this.ddlPosition)}
              value={this.positionSelected}
              onChange={this.onChangePosition}
            />
          </div>
          {/* {this.renderFields(fields.slice(13))} */}
          <FormSemantic as="div">
            <FormSemantic.Group widths="equal">
              <FormSemantic.Field>
                <div className="Input-Label required">Email</div>
                <FormSemantic.Input
                  name="Email"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  value={this.modelCM.Email}
                  error={
                    this.errorModelCM.Email
                      ? {
                          content: this.errorModelCM.Email,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
            <FormSemantic.Group widths="equal">
              <FormSemantic.Field>
                <div className="Input-Label required">Phone Number</div>
                <FormSemantic.Input
                  name="PhoneNumber"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  maxLength={11}
                  value={this.modelCM.PhoneNumber}
                  error={
                    this.errorModelCM.PhoneNumber
                      ? {
                          content: this.errorModelCM.PhoneNumber,
                        }
                      : null
                  }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
            <FormSemantic.Group widths="equal">
              <FormSemantic.Field>
                <div className="Input-Label">Mobile</div>
                <FormSemantic.Input
                  name="Mobile"
                  style={{ fontWeight: 'normal' }}
                  onChange={this.onChangeTextInput}
                  maxLength={11}
                  value={this.modelCM.Mobile}
                  // error={
                  //   this.errorModelCM.Mobile
                  //     ? {
                  //         content: this.errorModelCM.Mobile,
                  //       }
                  //     : null
                  // }
                />
              </FormSemantic.Field>
            </FormSemantic.Group>
            <div className={cx('formgroup')}>
              <div className="Input-Label required">How do you know about our service</div>
              <Dropdown
                search
                fluid
                selection
                required
                clearable
                icon={!this.regOlSourceSelected ? <Icon name="dropdown" /> : <Icon name="delete" />}
                name="AboutOurServie"
                placeholder="Select a option"
                className="input--overridden-style"
                options={toJS(aboutServiceOptions)}
                value={this.regOlSourceSelected}
                onChange={this.onChangeRegOlSource}
              />
            </div>

            {this.regOlSourceSelected && this.regOlSourceSelected === 7 && (
              <FormSemantic.Group widths="equal">
                <FormSemantic.Field>
                  <div className="Input-Label">Please select the staff providing the service to you</div>
                  <Dropdown
                    search
                    fluid
                    selection
                    clearable
                    icon={!this.regOlSourceSelected ? <Icon name="dropdown" /> : <Icon name="delete" />}
                    name="CreatedBy"
                    placeholder="Please select a staff name"
                    className="input--overridden-style"
                    options={toJS(this.ddlStaffs)}
                    value={this.staffSelected}
                    onChange={this.onChangeStaff}
                  />
                </FormSemantic.Field>
              </FormSemantic.Group>
            )}
            {this.regOlSourceSelected && this.regOlSourceSelected === 99 && (
              <FormSemantic.Group widths="equal">
                <FormSemantic.Field>
                  <div className="Input-Label" required={this.regOlSourceSelected === 99}>
                    Other Detail
                  </div>
                  <FormSemantic.Input
                    style={{ with: '100%' }}
                    name="OtherAboutServiceDetail"
                    placeholder="Please enter details"
                    onChange={this.onChangeTextInput}
                    value={this.modelCM.OtherAboutServiceDetail}
                    error={
                      this.errorModelCM.OtherAboutServiceDetail
                        ? {
                            content: this.errorModelCM.OtherAboutServiceDetail,
                          }
                        : null
                    }
                  />
                </FormSemantic.Field>
              </FormSemantic.Group>
            )}
          </FormSemantic>
        </div>
        <div className={cx('d-flex', 'flex-between')} style={{ marginTop: '1em' }}>
          <React.Fragment>
            <Button type="button" color="red" content="Clear" onClick={this.Clear} />
            <ButtonSubmit
              icon="angle double right"
              loading={api.register.loading}
              content="Register"
              onClick={this.onSubmit}
            />
          </React.Fragment>
        </div>
      </FormSemantic>
    );
  };

  renderSuccessMessage = () => {
    return (
      <React.Fragment>
        <p>
          You have registered an account successfully. We will contact you shortly to confirm and open your account.{' '}
          <span className="link" onClick={this.resetRegister}>
            Register again
          </span>
        </p>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="form-register">
        {this.registerSuccessfully ? this.renderSuccessMessage() : this.renderForm()}
        <Divider />
        <div>
          <Link to="/view/login" className="link">
            Back to login
          </Link>
        </div>
      </div>
    );
  }
}

export default Register;
