import React from 'react';
import { Form, DatePicker, Select, Input, Radio } from 'antd';

class CaseStatus extends React.Component {
  render() {
    return (
      <Form name="case-status">
        <Form.Item
          label={
            <React.Fragment>
              PIC 4<button className="field-actions">Recent Changes</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Status Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Case Status" className="fluid-field">
          <Radio.Group>
            <Radio>Request Received</Radio>
            <Radio>Request Sent to Specialist</Radio>
            <Radio>Close without Bookings</Radio>
            <Radio>Close with Bookings</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Comments" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default CaseStatus;
