import React from 'react';
import { Form, DatePicker, Select, Divider, Input, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react';

class ServiceDetails extends React.Component {
  render() {
    return (
      <Form name="service-details">
        <h3 className="form-subtitle fluid-field">Imaging Request</h3>
        <Form.Item label="Referring Specialist">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Examination Required" className="fluid-field">
          <Checkbox.Group>
            <Checkbox>X-Ray</Checkbox>
            <Checkbox>MRI</Checkbox>
            <Checkbox>CT</Checkbox>
            <Checkbox>Ultrasound</Checkbox>
            <Checkbox>Cardiac CT</Checkbox>
            <Checkbox>Bone Mineral Density</Checkbox>
            <Checkbox>Mammography</Checkbox>
            <Checkbox>Fluoroscopy</Checkbox>
            <Checkbox>Nuclear Medicine</Checkbox>
            <Checkbox>Interventional Procedures</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="Attach Referral Documents" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Comment" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Imaging Service Booked</Checkbox>
        </div>
        <Form.Item
          required
          label={
            <React.Fragment>
              Imaging Centre
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          label={
            <React.Fragment>
              Radiologist
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Confirmation Date" required>
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Cost Approval" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Comment" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Imaging Service Performed</Checkbox>
        </div>
        <Form.Item label="Date of Performed Service" required>
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Imaging Result Completed</Checkbox>
        </div>
        <Form.Item label="Report Complete Date" required>
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Attach Reports" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Comment" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default ServiceDetails;
