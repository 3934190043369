import React from 'react';
import { action } from 'mobx';
import { Form, Input, DatePicker, notification, Spin, Radio, Select, Checkbox } from 'antd';
import { Button, Icon, Message, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import AddEditStore from './store';
import CKEditor from './CKEditor';
import {
  getCampaignInfo,
  saveCaseManagerCampaign,
  saveAndSendPreivewCaseManagerCampaign,
  uploadFileTemplate,
} from './service';
import DragDropFile from './../../shared/DragDropFile';
import * as api from '../../../stores/api';
import { renderDate } from '../../case-details-routes/Shared';
import { FILE_EXTENSION } from '@utils/constants';

const State = [
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

@observer
class ConversationModalForm extends React.Component {
  state = {
    loading: true,
    dataCampaign: null,
    loadingUpload: false,
  };
  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        return moment(formatDate(dateStr), 'DD MMM YYYY').format('MM-DD-YYYY');
      }
      return dateStr.format('MM-DD-YYYY');
    }
    return null;
  };

  handleSave = sendPreview => {
    const { dataCampaign } = this.state;
    const parram = {
      ...dataCampaign,
      //CMResponsesDate: dataCampaign.CMResponsesDate !== null ? this.convertDate(dataCampaign.CMResponsesDate): null,
      // Location:
      //   dataCampaign.Location.length === 0
      //     ? null
      //     : dataCampaign.Location.join(';'),
    };

    var contactListAtt = parram.Attachments ? parram.Attachments?.filter(i => i.AttachmentType === 3) : [];
    var checkRequiredTo = true;
    if (parram.Channel === 2713 && !parram.To && contactListAtt.length <= 0) {
      checkRequiredTo = false;
    }

    if (
      parram.CampaignName &&
      parram.Channel &&
      (parram.Channel !== 2713 || (!this.checkRequired('Subject') && !this.checkRequired('From') && checkRequiredTo))
    ) {
      if (sendPreview === 0) {
        Promise.all([saveCaseManagerCampaign(parram)])
          .then(([response]) => {
            if (response.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Data has been saved successfully.',
                duration: 3,
              });
              AddEditStore.refetch({});
              this.props.onCancel();
            } else if (response.status === 'fail') {
              notification.destroy();
              notification.error({
                message: 'Error',
                description: 'An error occurred, please try again',
                duration: 3,
              });
            }
          })
          .catch(() => {});
      } else {
        Promise.all([saveAndSendPreivewCaseManagerCampaign(parram)])
          .then(([response]) => {
            if (response.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Data has been saved successfully.',
                duration: 3,
              });
              AddEditStore.refetch({});
              this.props.onCancel();
            } else if (response.status === 'fail') {
              notification.destroy();
              notification.error({
                message: 'Error',
                description: 'An error occurred, please try again',
                duration: 3,
              });
            }
          })
          .catch(() => {});
      }
    } else {
      notification.destroy();
      var msg = 'Please enter';
      if (
        !parram.CampaignName ||
        !parram.Channel ||
        (parram.Channel === 2713 && (!parram.Subject || !parram.From || !parram.To || contactListAtt.length <= 0))
      ) {
        msg += ': ';
      }
      if (!parram.CampaignName) {
        msg += 'Campaign Name, ';
      }
      if (!parram.Channel) {
        msg += 'Channel, ';
      }
      if (parram.Channel === 2713) {
        if (!parram.Subject) {
          msg += 'Subject, ';
        }
        if (!parram.From) {
          msg += 'From, ';
        }
        if (!parram.To && contactListAtt.length <= 0) {
          msg += 'To or Attached Contact List, ';
        }
      }
      msg = msg.substr(0, msg.length - 2);
      notification.error({
        message: 'Error',
        description: msg,
        duration: 3,
      });
    }
  };

  componentDidMount() {
    const { id } = AddEditStore.modalParams;
    if (id) {
      Promise.all([getCampaignInfo(id)]).then(([campaignInfo]) => {
        this.setState({
          loading: false,
          dataCampaign: {
            ...campaignInfo.objItem,
            CampaignDate: campaignInfo.objItem.CampaignDate
              ? moment(formatDate(campaignInfo.objItem.CampaignDate), 'DD MMM YYYY')
              : null,
            CampaignEndDate: campaignInfo.objItem.CampaignEndDate
              ? moment(formatDate(campaignInfo.objItem.CampaignEndDate), 'DD MMM YYYY')
              : null,
            CMResponsesDate: campaignInfo.objItem.CMResponsesDate
              ? moment(formatDate(campaignInfo.objItem.CMResponsesDate), 'DD MMM YYYY')
              : null,
            Location: campaignInfo.objItem.Location ? campaignInfo.objItem.Location.split(';') : [],
            TargetAudience: campaignInfo.objItem.TargetAudience > 0 ? campaignInfo.objItem.TargetAudience : null,
            Channel: campaignInfo.objItem.Channel > 0 ? campaignInfo.objItem.Channel : null,
            TargetStatus: campaignInfo.objItem.TargetStatus > 0 ? campaignInfo.objItem.TargetStatus : null,
            SuccessRate: campaignInfo.objItem.SuccessRate > 0 ? campaignInfo.objItem.SuccessRate : null,
            ResponsesRate: campaignInfo.objItem.ResponsesRate > 0 ? campaignInfo.objItem.ResponsesRate : null,
          },
        });
        console.log(campaignInfo);
      });
    } else {
      this.setState({
        loading: false,
        dataCampaign: {
          Id: 0,
          CampaignName: '',
          Channel: null,
          CampaignDate: moment(),
          CMResponses: false,
          CMResponsesDate: moment(),
          CMResponsesBy: '',
          Comment: '',
          TargetAudience: null,
          TargetStatus: null,
          Location: [],
          ResponsesRate: 0,
          SuccessRate: 0,
          CampaignContent: null,
          Bcc: null,
          From: api.currentUser.data.id,
          To: null,
          Volume: null,
          Subject: null,
          ReplyTo: null,
          Attachments: null,
          Status: 0, // Status Planning
          Sender: '',
          CampaignSubject: '',
        },
      });
    }
  }

  handleFieldChecked = fieldName => event => {
    const value = event ? (event.target ? event.target.checked : event) : false;
    if (fieldName === 'CMResponses') {
      if (value) {
        this.setState({ CMResponsesDate: moment() });
      } else {
        this.setState({ CMResponsesDate: null });
      }
    }
    this.setState(state => (state.dataCampaign[fieldName] = value));
  };

  handleFieldChange = fieldName => event => {
    const { dataCampaign } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    if (fieldName === 'ResponsesRate' || fieldName === 'SuccessRate') {
      this.setState({
        dataCampaign: {
          ...dataCampaign,
          [fieldName]: value.replace(/\D/g, '').replace(/[^\d]/g, ''),
        },
      });
    } else if (fieldName === 'CampaignEndDate') {
      if (dataCampaign.CampaignDate && value < dataCampaign.CampaignDate) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'Campaign Date must be less than Campaign End Date.',
        });
      }
      this.setState({
        dataCampaign: {
          ...dataCampaign,
          [fieldName]: value,
        },
      });
    } else {
      this.setState({
        dataCampaign: {
          ...dataCampaign,
          [fieldName]: value,
        },
      });
    }
  };

  @action handleChecked = fieldName =>
    action(event => {
      const { dataCampaign } = this.state;
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.setState({
        dataCampaign: {
          ...dataCampaign,
          [fieldName]: value,
        },
      });
    });

  checkRequired = fieldName => {
    const { dataCampaign } = this.state;
    if (dataCampaign.Channel === 2713) {
      if (dataCampaign[fieldName]) {
        return false;
      }
      return true;
    } else {
      if (dataCampaign[fieldName]) {
        return false;
      }
      return true;
    }
  };

  handleViewFile = fileName => {
    window.open(`/UserFile/CampaignAttachment/${fileName}`, '_blank');
  };

  handleUploadFile = (file, type) => {
    this.loadingUpload = true;
    const formData = new FormData();
    formData.append(file.name, file);

    uploadFileTemplate(formData).then(({ fileList }) => {
      const attachment = {
        FileName: fileList[0].name,
        Title: fileList[0].title,
        NumberOfPages: fileList[0].numberOfPages,
        CampaignId: this.state.dataCampaign.Id,
        AttachmentType: type,
      };
      if (this.state.dataCampaign.Attachments && this.state.dataCampaign.Attachments.length) {
        const { dataCampaign } = this.state;
        // const itemId = dataCampaign.Attachments.map(i => i.Id).reduce(function(
        //   idA,
        //   idB,
        // ) {
        //   return idA > idB ? idA : idB;
        // });
        const newAttachments = [...this.state.dataCampaign.Attachments, { ...attachment, Id: 0 }];
        const newDataCampaign = {
          ...dataCampaign,
          Attachments: newAttachments,
        };
        this.setState({ loadingUpload: false, dataCampaign: newDataCampaign });
      } else {
        const { dataCampaign } = this.state;
        const newAttachments = [{ ...attachment, Id: 0 }];
        const newDataCampaign = {
          ...dataCampaign,
          Attachments: newAttachments,
        };
        this.setState({ loadingUpload: false, dataCampaign: newDataCampaign });
      }
    });
  };

  handleDeleteFile = id => {
    const { dataCampaign } = this.state;
    const newAttachments = dataCampaign.Attachments.filter(i => i.Id !== id);
    const newDataCampaign = { ...dataCampaign, Attachments: newAttachments };
    return this.setState({ dataCampaign: newDataCampaign });
    // return this.forceUpdate();
  };

  handleUpload = (event, attachmentType) => {
    const files = event.target.files;
    if (!files || !files.length) {
      return;
    } else {
      const file = files[0];
      const type = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
      const size = file.size / 1024 / 1024;
      if (size > 50) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'The file you have selected is too large. The maximum size is 50MB.',
        });
      } else if (FILE_EXTENSION.indexOf(type) === -1) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'File type not permitted',
        });
      } else {
        this.handleUploadFile(files[0], attachmentType);
      }
    }
  };

  renderAttachment = attachmentType => {
    const { Attachments } = this.state.dataCampaign;
    if (Attachments && Attachments.length) {
      if (Attachments.length > 0) {
        const attachments = Attachments.filter(i => i.AttachmentType === attachmentType);
        if (attachments.length === 0) {
          return null;
        }
        return attachments;
      }
      return null;
    }
    return null;
  };

  handleChangeContent = value => {
    const { dataCampaign } = this.state;
    this.setState({
      dataCampaign: { ...dataCampaign, CampaignContent: value },
    });
  };

  handleChangeSignature = value => {
    const { dataCampaign } = this.state;
    this.setState({
      dataCampaign: { ...dataCampaign, MailSignature: value },
    });
  };

  dragdropUploadFile = (acceptedFiles, reportType) => {
    var uploadFile = [];
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
        element.filename = element.name;
      });
      uploadFile = acceptedFiles
        .filter(i => i.Id === undefined)
        .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
    }
    if (this.state.dataCampaign.Attachments && this.state.dataCampaign.Attachments.length) {
      const { dataCampaign } = this.state;
      const newAttachments = this.state.dataCampaign.Attachments;
      uploadFile.forEach(key => {
        newAttachments.push({
          Id: 0,
          CampaignId: 68,
          FileName: key.name,
          NumberOfPages: key.numberOfPages,
          Title: key.title,
          AttachmentType: 2,
        });
      });
      const newDataCampaign = {
        ...dataCampaign,
        Attachments: newAttachments,
      };
      this.setState({ loadingUpload: false, dataCampaign: newDataCampaign });
    } else {
      const { dataCampaign } = this.state;
      const newAttachments = [];
      uploadFile.forEach(key => {
        newAttachments.push({
          Id: 0,
          CampaignId: 68,
          FileName: key.name,
          NumberOfPages: key.numberOfPages,
          Title: key.title,
          AttachmentType: 2,
        });
      });
      const newDataCampaign = {
        ...dataCampaign,
        Attachments: newAttachments,
      };
      this.setState({ loadingUpload: false, dataCampaign: newDataCampaign });
    }
  };

  dragdropDeleteFile = deletedFiles => {
    const { dataCampaign } = this.state;
    if (deletedFiles != null) {
      var newAttachments = [];
      if (this.state.dataCampaign.Attachments && this.state.dataCampaign.Attachments.length) {
        newAttachments = this.state.dataCampaign.Attachments;
      }
      newAttachments = newAttachments.filter(
        x => (x.name || x.FileName) !== (deletedFiles.name || deletedFiles.FileName),
      );
      const newDataCampaign = {
        ...dataCampaign,
        Attachments: newAttachments,
      };
      this.setState({ loadingUpload: false, dataCampaign: newDataCampaign });
    }
  };

  handleDownloadTemplate = () => {
    window.open('/ExportImport/DownloadMailMergeCampaignTemplate', '_blank');
  };

  render() {
    const { record, id } = AddEditStore.modalParams;
    const { open } = AddEditStore;

    const { dataCampaign, loading } = this.state;
    const { channels, targetAudience, targetStatus, staffs } = AddEditStore;
    return (
      <Modal
        width={1200}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              onClick={() => this.handleSave(1)}
              disabled={!dataCampaign || (dataCampaign.Channel !== 2713 && dataCampaign.Channel !== 2738)}
            >
              Save & Send Preview
            </Button>}
            {!api.isOnlySearch() && <Button color="blue" onClick={() => this.handleSave(0)}>
              Save
            </Button>}
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New Campaign' : record.CampaignName}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item
              label="Campaign Name"
              required
              validateStatus={!dataCampaign.CampaignName ? 'error' : null}
              help={!dataCampaign.CampaignName ? 'This field is required' : null}
            >
              <Input value={dataCampaign.CampaignName} onChange={this.handleFieldChange('CampaignName')} />
            </Form.Item>
            <Form.Item
              label="Channel"
              validateStatus={this.checkRequired('Channel') ? 'error' : null}
              help={this.checkRequired('Channel') ? 'This field is required' : null}
              required
            >
              <Select
                size="large"
                value={dataCampaign.Channel}
                onChange={this.handleFieldChange('Channel')}
                style={{ marginTop: '10px' }}
              >
                {channels.itemList.map(i => (
                  <Select.Option value={i.Id} key={i.Id}>
                    {i.Label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
              <Form.Item label="">
                <Checkbox
                  disabled={dataCampaign.NeedSendEmail && dataCampaign.SaveAndSendDate}
                  checked={dataCampaign.NeedSendEmail}
                  onChange={this.handleChecked('NeedSendEmail')}
                >
                  Scheduled to send{' '}
                  {dataCampaign.Channel === 2738 && (
                    <Popup
                      className="icon-msg-to"
                      trigger={<Icon name="info circle" />}
                      content="Sending previously booked specialist availability (Next 8 weeks) to all CMs of booking clients. Campaign will be sent at 7:30AM the next day"
                      position="top center"
                    />
                  )}
                </Checkbox>
                {dataCampaign.SaveAndSendDate && (
                  <span>
                    Sent: {dataCampaign.SaveAndSendDate ? renderDate(dataCampaign.SaveAndSendDate, false, true) : ''}
                    {dataCampaign.SaveAndSendBy ? ` - By ${dataCampaign.SaveAndSendBy}` : ''}
                  </span>
                )}
                {dataCampaign.SentErrorDate && (
                  <span className="campaign-erorr-text">
                    Sending Error: {dataCampaign.SentErrorName} - Date/time stamp:{' '}
                    {renderDate(dataCampaign.SentErrorDate, false, true)}
                  </span>
                )}
              </Form.Item>
            )}
            {dataCampaign.Channel !== 2738 && (
              <Form.Item label="Start - End Date" className="flex-children">
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={dataCampaign.CampaignDate}
                  onChange={this.handleFieldChange('CampaignDate')}
                  style={{ marginRight: '10px' }}
                />
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={dataCampaign.CampaignEndDate}
                  onChange={this.handleFieldChange('CampaignEndDate')}
                />
              </Form.Item>
            )}
            {dataCampaign.Channel !== 2738 && (
              <Form.Item label="Status">
                <Radio.Group value={dataCampaign.Status} onChange={this.handleFieldChange('Status')}>
                  <Radio value={0}>Planning</Radio>
                  <Radio value={1}>In progress</Radio>
                  <Radio value={2}>Completed</Radio>
                  <Radio value={3}>Cancelled</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {dataCampaign.Channel === 2738 && (
              <Form.Item
                label="Subject"
                validateStatus={this.checkRequired('Subject') ? 'error' : null}
                help={this.checkRequired('Subject') ? 'This field is required' : null}
                required
              >
                <Input value={dataCampaign.Subject} onChange={this.handleFieldChange('Subject')} />
              </Form.Item>
            )}
            {dataCampaign.Channel === 2713 && (
              <React.Fragment>
                <Form.Item
                  label="Subject"
                  validateStatus={this.checkRequired('Subject') ? 'error' : null}
                  help={this.checkRequired('Subject') ? 'This field is required' : null}
                  required
                >
                  <Input value={dataCampaign.Subject} onChange={this.handleFieldChange('Subject')} />
                </Form.Item>
                <Form.Item
                  label="From"
                  validateStatus={this.checkRequired('From') ? 'error' : null}
                  help={this.checkRequired('From') ? 'This field is required' : null}
                  required
                >
                  <Select
                    size="large"
                    value={dataCampaign.From}
                    onChange={this.handleFieldChange('From')}
                    style={{ marginTop: '10px' }}
                    showArrow
                    optionFilterProp="children"
                  >
                    {staffs.map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.FullName}
                      </Select.Option>
                    ))}
                  </Select>
                  {/* <Input
                    value={dataCampaign.From}
                    onChange={this.handleFieldChange('From')}
                  /> */}
                </Form.Item>
                <Form.Item
                // validateStatus={this.checkRequired('To') ? 'error' : null}
                // help={
                //   this.checkRequired('To') ? 'This field is required' : null
                // }
                // label="To"
                // required
                >
                  <label style={{ display: 'inline-block', marginBottom: '6px' }}>
                    {' '}
                    <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                    To{' '}
                    <Popup
                      className="icon-msg-to"
                      trigger={<Icon name="info circle" />}
                      content="Multiple emails separated by ';'"
                      position="top center"
                    />
                    :
                  </label>

                  <Input
                    value={dataCampaign.To}
                    onChange={this.handleFieldChange('To')}
                    disabled={
                      this.state.dataCampaign.Attachments &&
                      this.state.dataCampaign.Attachments?.filter(i => i.AttachmentType === 3).length > 0
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="attach-contact-list"
                  label={
                    <React.Fragment>
                      Attached Contact List
                      <Popup
                        className="icon-msg-to"
                        trigger={<Icon name="info circle" />}
                        content="This list should contain Title, FirstName, LastName, Email in Excel format"
                        position="top center"
                      />
                      <Link to="#" onClick={() => this.handleDownloadTemplate()}>
                        View Contact List Template
                      </Link>
                    </React.Fragment>
                  }
                >
                  <div className="add-file-container-marketing-campaign">
                    {this.renderAttachment(3) ? (
                      this.renderAttachment(3).map(i => (
                        <Message className="upload-box" key={i.Id}>
                          <div className="file-info">
                            <div className="file-info-inner">
                              <Icon name="file alternate" />
                              <div className="file-name">{i.Title}</div>
                            </div>
                            <div className="file-actions">
                              <Icon name="download" onClick={() => this.handleViewFile(i.FileName)} />
                              <Icon name="delete" color="red" onClick={() => this.handleDeleteFile(i.Id)} />
                            </div>
                          </div>
                        </Message>
                      ))
                    ) : (
                      <Message className="upload-box">
                        <div className="upload-file">
                          {this.state.uploadLoading && <Spin />}
                          <input
                            type="file"
                            onChange={event => this.handleUpload(event, 3)}
                            disabled={dataCampaign.To}
                          />
                          Click here to upload
                        </div>
                      </Message>
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <label style={{ display: 'inline-block', marginBottom: '6px' }}>
                    Bcc{' '}
                    <Popup
                      className="icon-msg-to"
                      trigger={<Icon name="info circle" />}
                      content="Multiple emails separated by ';'"
                      position="top center"
                    />
                    :
                  </label>
                  <Input value={dataCampaign.Bcc} onChange={this.handleFieldChange('Bcc')} />
                </Form.Item>
                {/* <Form.Item label="Reply - to">
                  <Input
                    value={dataCampaign.ReplyTo}
                    onChange={this.handleFieldChange('ReplyTo')}
                  />
                </Form.Item> */}
              </React.Fragment>
            )}
            <Form.Item>
              <label style={{ display: 'inline-block', marginBottom: '6px' }}>
                Campaign Content{' '}
                {dataCampaign.Channel === 2713 && (
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="Parameters can be used in content: {Title}, {FirstName}, {LastName}, {Email}, {UnsubscribeLink} retrieved from the Contact list"
                    position="top center"
                  />
                )}
                {dataCampaign.Channel === 2738 && (
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="Parameters can be use in content: {CMFirstName}, {CMLastName}, {ClientName}, {UnsubscribeLink}"
                    position="top center"
                  />
                )}
                :
              </label>
              <CKEditor html={dataCampaign.CampaignContent} handleChangeContent={this.handleChangeContent} />
            </Form.Item>
            {(dataCampaign.Channel === 2713 || dataCampaign.Channel === 2738) && (
              <Form.Item>
                <label style={{ display: 'inline-block', marginBottom: '6px' }}>
                  Signature{' '}
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="If signature is blank, default system signature will be used in emails"
                    position="top center"
                  />
                </label>
                <CKEditor html={dataCampaign.MailSignature} handleChangeContent={this.handleChangeSignature} />
              </Form.Item>
            )}

            <Form.Item>
              <label style={{ display: 'inline-block', marginBottom: '8px' }}>
                Mail Merge Attachments{' '}
                <Popup
                  trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                  content="Attached files here will be sent out in relationship emails for the same campaign"
                  position="top center"
                />
                :
              </label>
              <div className="add-file-container-marketing-campaign">
                <div style={{ marginTop: '10px' }}>
                  <DragDropFile
                    onUploadFile={this.dragdropUploadFile}
                    onDeleteFile={this.dragdropDeleteFile}
                    fileList={this.state.dataCampaign.Attachments?.filter(i => i.AttachmentType === 2)}
                    type="2"
                    title="Attachment"
                    maxSize="50"
                  />
                  <hr></hr>
                </div>
              </div>
            </Form.Item>
            <Form.Item label="Target Audience">
              <Select
                size="large"
                value={dataCampaign.TargetAudience}
                onChange={this.handleFieldChange('TargetAudience')}
                style={{ marginTop: '10px' }}
              >
                {targetAudience.itemList.map(i => (
                  <Select.Option value={i.Id} key={i.Id}>
                    {i.Label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Target Status">
              <Select
                size="large"
                value={dataCampaign.TargetStatus}
                onChange={this.handleFieldChange('TargetStatus')}
                style={{ marginTop: '10px' }}
              >
                {targetStatus.itemList.map(i => (
                  <Select.Option value={i.Id} key={i.Id}>
                    {i.Label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <label style={{ display: 'inline-block', marginBottom: '8px' }}>
                Attached Content{' '}
                <Popup
                  className="icon-msg-to"
                  trigger={<Icon name="info circle" />}
                  content="Attaching contents sent as part of the campaign for reference only. The attachments here will not be sent."
                  position="top center"
                />
                :
              </label>
              <div className="add-file-container-marketing-campaign">
                {this.renderAttachment(1) ? (
                  this.renderAttachment(1).map(i => (
                    <Message className="upload-box" key={i.Id}>
                      <div className="file-info">
                        <div className="file-info-inner">
                          <Icon name="file alternate" />
                          <div className="file-name">{i.Title}</div>
                        </div>
                        <div className="file-actions">
                          <Icon name="download" onClick={() => this.handleViewFile(i.FileName)} />
                          <Icon name="delete" color="red" onClick={() => this.handleDeleteFile(i.Id)} />
                        </div>
                      </div>
                    </Message>
                  ))
                ) : (
                  <Message className="upload-box">
                    <div className="upload-file">
                      {this.state.uploadLoading && <Spin />}
                      <input type="file" onChange={event => this.handleUpload(event, 1)} />
                      Click here to upload
                    </div>
                  </Message>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Location">
              <Select
                size="large"
                value={dataCampaign.Location}
                onChange={this.handleFieldChange('Location')}
                style={{ marginTop: '10px' }}
                mode="multiple"
                showArrow
                optionFilterProp="children"
              >
                {State.map(i => (
                  <Select.Option value={i.id} key={i.id}>
                    {i.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Volume (Number of Contacts Sent Out)">
              <Input type="number" value={dataCampaign.Volume} onChange={this.handleFieldChange('Volume')} />
            </Form.Item>
            <Form.Item label="Success Rate (%)">
              <Input value={dataCampaign.SuccessRate} onChange={this.handleFieldChange('SuccessRate')} />
            </Form.Item>
            <Form.Item label="Response Rate (%)">
              <Input value={dataCampaign.ResponsesRate} onChange={this.handleFieldChange('ResponsesRate')} />
            </Form.Item>
            <Form.Item label="Comment">
              <Input.TextArea rows={4} value={dataCampaign.Comment} onChange={this.handleFieldChange('Comment')} />
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ConversationModalForm;
