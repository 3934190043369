import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { uniqBy } from 'lodash';
import { Button, Form, Checkbox, Select } from 'semantic-ui-react';

import Modal from '../../shared/Modal';
import customFetch from '../../../utils/customFetch';
import DoctorReportsStore from './DoctorReportsStore';
import { DatePicker, Spin } from 'antd';
import { action, observable, toJS } from 'mobx';
import ModalVerifyAccessCode from './ModalVerifyAccessCode';

const datePickerFormat = 'DD/MM/YYYY';

@observer
class ModalExportSpecialistStatus extends React.Component {
  @observable specialistKeyword = '';
  @observable specialistNumItemPerPage = 500;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpenAccessCodeModal: false,
      allDoctos: true,
      lstSpecialists: [],
      selectedSpecialist: [],
    };
  }

  @action setListSpecItem = data => {
    this.lstSpecialists = data;
  };

  async componentDidMount() {
    try {
      DoctorReportsStore.setFieldsValue({ loadingDoctorStatus: true });

      const exportPromise = customFetch('/MedicalDoctor/GetAllWithoutNCI', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          IsSelectionList: true,
          RecruitedOrContractedOnly: true,
          Showing: 'active',
          curPage: 1,
          keyword: '',
          numItemPerPage: 300,
        }),
      });

      const [exportDoctor] = await Promise.all([exportPromise]);
      //this.setState({ lstSpecialists: exportDoctor.itemList });
      DoctorReportsStore.setFieldsValue({ lstSpecialistStatus: exportDoctor.itemList });
    } finally {
      DoctorReportsStore.setFieldsValue({ loadingDoctorStatus: false });
      //this.setState({ loading: false });
    }
  }

  renderSpeFilterOptions = () => {
    return DoctorReportsStore.lstSpecialistStatus.map(opt => ({
      text: opt.FullName,
      value: opt.Id,
    }));
  };

  renderSpeFilterText = () => {
    let labels = this.state.selectedSpecialist.map(opt => {
      const matchItem = DoctorReportsStore.lstSpecialistStatus.find(i => i.Id === opt);
      if (matchItem) {
        return matchItem.Id;
      }
      return opt;
    });
    return labels;
  };

  onChangeCheckBoxFalse = name => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  onChangeCheckBox = (name, value) => {
    this.setState({
      ...this.state,
      [name]: true,
    });
  };

  openModal = options => {
    this.setState({ isActive: false });
    return DoctorReportsStore.toggleModal(true, options)();
  };

  handleExport = () => {
    const { allDoctos, selectedSpecialist } = this.state;
    var qs1 = selectedSpecialist.map(val => `${val}`).join(',');
    // window.open('/Report/ExportClinicSessionHistory?allDoctos=' + allDoctos + '&speList=' + qs1, '_blank');

    DoctorReportsStore.setFieldsValue({
      open: true,
      exportUrl: '/Report/ExportClinicSessionHistory',
      exportQueryParams: allDoctos + '&speList=' + qs1,
    });

    this.setState({ ...this.state, isOpenAccessCodeModal: true });
  };

  handleOnChangeDropDown = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  @action fetchSearchSepecialistStatus = (_, { searchQuery }) => {
    if (searchQuery.length < 3) {
      return;
    }
    this.setState({ selectedSpecialist: [] });

    customFetch('/MedicalDoctor/GetAllWithoutNCI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        RecruitedOrContractedOnly: true,
        Showing: 'active',
        curPage: 1,
        keyword: searchQuery,
        numItemPerPage: 300,
      }),
    }).then(
      action(data => {
        DoctorReportsStore.setFieldsValue({ lstSpecialistStatus: data.itemList });
        //this.lstSpecialistStatus = data.itemList;
      }),
    );
  };

  onCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open, loadingDoctorStatus } = DoctorReportsStore;
    const { allDoctos } = this.state;
    return (
      <React.Fragment>
        {loadingDoctorStatus ? (
          <Spin size="large" className="modal-spin" />
        ) : (
          <Modal
            visible={open}
            width={900}
            onCancel={this.props.onCancel}
            footer={
              <React.Fragment>
                <Button color="blue" onClick={this.handleExport}>
                  Export
                </Button>
                <Button color="red" onClick={this.onCancel}>
                  Close
                </Button>
              </React.Fragment>
            }
            title="Export Specialist & Session Status"
          >
            <div style={{ minHeight: '500px' }}>
              <div className="field-checkbox" style={{ marginBottom: 10 }}>
                <Checkbox
                  defaultChecked={allDoctos ? true : false}
                  label="All Specialist"
                  onChange={
                    allDoctos ? () => this.onChangeCheckBoxFalse('allDoctos') : () => this.onChangeCheckBox('allDoctos')
                  }
                />
              </div>
              <Form className="export-specialist">
                <Form.Dropdown
                  disabled={allDoctos}
                  label="Specialists"
                  placeholder="Enter at least 3 letters to search specialist"
                  multiple
                  search
                  selection
                  clearable
                  name="selectedSpecialist"
                  onSearchChange={this.fetchSearchSepecialistStatus}
                  options={this.renderSpeFilterOptions()}
                  value={this.renderSpeFilterText()}
                  onChange={this.handleOnChangeDropDown}
                />
              </Form>
            </div>
            {this.state.isOpenAccessCodeModal && (
              <ModalVerifyAccessCode {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default ModalExportSpecialistStatus;
