import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class TypistStore {
  @observable loading = true;
  @observable loadingHistory = true;
  @observable loadingSearch = true;
  @observable loadingClient = true;
  @observable dataClient = null;
  @observable dataSearch = null;
  @observable data = null;
  @observable dataHistory = null;
  @observable dataUser = null;

  @observable cmId = 0;

  @observable client = 'All';
  @observable caseManager = 'All';

  @observable filter = 'All';
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable keywordSearch = '';
  @observable sortColumn = 'First Name';
  @observable sortKey = 'FirstName';

  @observable sortDirectionBooking = 'ascending';
  @observable sortColumnBooking = 'Request Date';
  @observable sortKeyBooking = 'CaseManager';

  @observable loadingBookingHistory = true;
  @observable dataBookingHistory = null;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable listSize = 30;
  @observable companyId = 0;
  @observable numberSearch = 30;
  @observable numberClientPage = 30;
  @observable id = '';
  @observable IsSelectionList = true;
  @observable keywordClient = '';
  @observable cmState = '';
  @observable filterType = 'All';
  @observable clientOfficerId = '';

  @observable totalBooking = 0;

  @observable uId = '';
  @observable data1 = null;
  @observable loading1 = true;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchSearch = () => {
    this.loadingSearch = true;
    customFetch('/Typist/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Showing: `active`,
        Keyword: this.keywordSearch,
        CurPage: this.curPage,
        NumItemPerPage: this.numberSearch,
      }),
    }).then(
      action(data => {
        this.dataSearch = data;
        this.loadingSearch = false;
      }),
    );
  };

  @action fetchBookingHistory = tpId => {
    this.loadingBookingHistory = true;
    customFetch(
      `/Typist/GetBookingHistory?typistId=${tpId}&sortBy=${
        this.sortKeyBooking
      }&sortDirection=${this.sortDirectionBooking.replace('ending', '').toUpperCase()}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataBookingHistory = data;
        this.loadingBookingHistory = false;
        this.totalBooking = data.sumItem;
      }),
    );
  };

  @action fetchClient = () => {
    this.loadingClient = true;
    customFetch('/Staff/GetStaffs', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        NumItemPerPage: this.numberClientPage,
        curPage: 1,
        keyword: this.keywordClient,
      }),
    }).then(
      action(data => {
        this.loadingClient = false;
        this.dataClient = data;
      }),
    );
  };

  @action fetchCase = (setTotalPage = false) => {
    this.loading = true;
    customFetch('/Typist/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        // FilterType: this.filter,
        Showing: this.filter,
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        companyId: this.companyId,
        SortBy: this.sortKey,
        State: this.cmState,
        FilterType: this.filterType,
        AssignedStaffId: this.clientOfficerId,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      }),
    }).then(
      action(data => {
        this.loading = false;
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.fetchSearch();
      }),
    );
  };

  @action fetchLoginHistory = () => {
    this.loading1 = true;
    customFetch('/Manager/GetLoginHistory', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        uId: this.modalParams.record.UserId,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
      }),
    }).then(
      action(data => {
        this.data1 = data;
        this.loading1 = false;
      }),
    );
  };

  @action fetchTypistGroup = () => {
    this.loadingCMGroup = true;

    customFetch('/Typist/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: this.IsSelectionList,
        numItemPerPage: this.listSize,
      }),
    }).then(
      action(data => {
        this.dataCMGroup = data;
        this.loadingCMGroup = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchCase();
  };

  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBooking = column;
    this.sortDirectionBooking = direction;
    this.sortKeyBooking = sortKey;
    this.fetchBookingHistory(this.cmId);
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCase(setTotalPage);
  };

  @action refetchClient = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClient();
  };

  @action refetchSearch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearch(setTotalPage);
  };

  @action refetchGroup = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCase(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'First Name';
    this.sortKey = 'FirstName';
    this.sortDirection = 'ascending';
    this.companyId = 0;
    this.clientOfficerId = '';
    this.cmState = '';

    this.curPage = 1;
    this.totalPage = 0;
    this.refetch({}, true);
  };
  @action resetSearch = () => {
    this.keywordSearch = '';
    this.numberSearch = 30;

    this.refetchSearch({});
  };
}

export default new TypistStore();
