import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { toJS } from 'mobx';
import dashboardStore from '@stores/dashboard';
import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import ClinicalRecordStore from './ClinicalRecordStore';
import moment from 'moment';
import ModalAlert from './ModalAlert';

@observer
class ClinicalRecordToolbar extends React.Component {
  state = {
    openModalAlert: false,
    message: `You need to search or filter first before exporting results.`,
    checkedClaimtOnly: true,
    type: null,
  };

  openCreateNewCase = () => {
    localStorage.setItem('ClinicalRecordCaseId', 'New CR Retrieval');
    var id = 0;
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}`);
    });
  };

  openDownloadResultsUrl = name => {
    const { fromDate, toDate } = ClinicalRecordStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant } = ClinicalRecordStore;
    const searchOption = {
      ActionRequired: false,
      ActionRequiredById: ClinicalRecordStore.actionRequiredById,
      ActionType: 'NONE',
      Claimant: Object.entries(ClinicalRecordStore.claimant)
        .map(([key, val]) => `${key}=${val}`)
        .join('&'),
      CurPage: ClinicalRecordStore.curPage,
      FilterOverdue: filterOverdue,
      FilterType: filter.search(/\s/) ? filter.replace(/\s+/g, '+') : filter,
      From: moment(fromDate)
        .startOf('day')
        .toISOString(),
      GetAllRecords: true,
      ClaimantId: ClinicalRecordStore.claimant.Id,
      Keyword: ClinicalRecordStore.keyword,
      NextPicId: NextTaskPIC,
      NumItemPerPage: 20,
      SearchClaimantOnly: ClinicalRecordStore.searchClaimantOnly,
      SortBy: ClinicalRecordStore.sortKey,
      SortDirection: `DESC`,
      To: moment(toDate)
        .endOf('day')
        .toISOString(),
      UrgentReport: false,
    };
    if (!claimant.Id) {
      delete searchOption.Claimant;
      delete searchOption.ClaimantId;
    }
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    if (!NextTaskPIC) {
      delete searchOption.NextPicId;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    if (name === `search`) {
      window.open('/ClinicalNote/ExportSearchResult?' + qs, '_blank');
    } else if (name === `correspondence`) {
      window.open('/ClinicalNote/ExportCorrespondenceSearchResult?' + qs, '_blank');
    }
  };

  handleExport = (_, { name }) => {
    const { fromDate, toDate } = ClinicalRecordStore.getDateRange();
    const { filter, filterOverdue, NextTaskPIC, claimant, keyword, actionRequiredById } = ClinicalRecordStore;
    if (
      claimant.Id ||
      fromDate ||
      toDate ||
      NextTaskPIC ||
      filter !== `All` ||
      filterOverdue !== `All` ||
      keyword ||
      actionRequiredById
    ) {
      this.openDownloadResultsUrl(name);
    } else {
      if (name === `search`) {
        this.setState({
          openModalAlert: true,
          type: name,
        });
      } else {
        this.setState({
          openModalAlert: true,
          type: name,
          message: `Please insert search criteria first.`,
        });
      }
    }
  };

  handleFilterChange = (_, { name, value }) => {
    ClinicalRecordStore.refetch({ [name]: value }, true);
  };

  renderFilterOption = name => {
    const array = [];
    const data = toJS(ClinicalRecordStore[name].itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: `All`, Count: `` });
    return array.map(opt => ({
      text: `${opt.FullName} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Id,
    }));
  };

  renderFilterLabel = (name, value) => {
    if (value === null || value === ``) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(ClinicalRecordStore[name].itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: `All`, Count: null });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => `${opt.FullName} ${opt.Count ? `(${opt.Count})` : ''}`);
      if (labels[0]) {
        return labels[0];
      } else {
        return `All`;
      }
    }
  };

  renderCaseStatus = () => {
    const array = [];
    const data = toJS(ClinicalRecordStore.dataCRStatus);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ status: `All`, count: `` });
    return array.map(opt => ({
      text: `${opt.status} ${opt.count ? `(${opt.count})` : ''}`,
      value: opt.status,
    }));
  };

  arrayNotNextTask = ['On-Hold', 'Cancelled No Fee', 'Service Paid'];

  renderCaseStatusLabel = (name, value) => {
    if (value === `All`) {
      return `All`;
    } else {
      const array = [];
      const data = toJS(ClinicalRecordStore[name]);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ status: `All`, Count: `` });
      let labels = array
        .filter(opt => opt.status === value)
        .map(opt => `${name === `dataOverDueCRStatus` ? opt.status : opt.status} ${opt.count ? `(${opt.count})` : ''}`);
      if (labels[0]) return labels[0];
      return `All`;
    }
  };

  renderNextTaskLabel = value => {
    if (value === `All`) {
      return `All`;
    } else {
      let array = [];
      const data = toJS(ClinicalRecordStore.dataCRStatus.filter(i => this.arrayNotNextTask.indexOf(i.status) === -1));
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ nextStatus: `All`, count: `` });
      let labels = array.filter(opt => opt.status === value).map(opt => opt);
      if (labels[0]) return `${labels[0].nextStatus} (${labels[0].count})`;
      return `All`;
    }
  };

  renderNextTask = () => {
    let array = [];
    if (api.isAdminOrMagStaffOrAccounting()) {
      const data = toJS(ClinicalRecordStore.dataCRStatus.filter(i => this.arrayNotNextTask.indexOf(i.status) === -1));
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ nextStatus: `All`, count: `` });
      return array.map(opt => ({
        text: `${opt.nextStatus} ${opt.count ? `(${opt.count})` : ''}`,
        value: opt.status,
      }));
    }
    return [];
  };

  renderNextTaskOverDue = () => {
    const array = [];
    if (api.isAdminOrMagStaffOrAccounting()) {
      const data = toJS(ClinicalRecordStore.dataOverDueCRStatus);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ status: `All`, count: `` });
      return array.map(opt => ({
        text: `${opt.status} ${opt.count ? `(${opt.count})` : ''}`,
        value: opt.status,
      }));
    }
    return [];
  };

  onChecked = () => {
    const { searchClaimantOnly } = ClinicalRecordStore;
    ClinicalRecordStore.setFieldsValue({
      searchClaimantOnly: !searchClaimantOnly,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = ClinicalRecordStore;

    if (ClinicalRecordStore.claimant === null || !ClinicalRecordStore.searchClaimantOnly) {
      ClinicalRecordStore.refetch({ claimant: { isFreeText: true, FullName: keyword }, curPage: 1 }, true);
    } else {
      ClinicalRecordStore.refetch(
        {
          keyword: ClinicalRecordStore.claimant.FullName,
          curPage: 1,
          keywordClaimant: ClinicalRecordStore.claimant.FullName,
          numberSearch: 30,
        },
        true,
      );
    }
    ClinicalRecordStore.resetSearch();
  };

  handleChange = (_, { value }) => {
    ClinicalRecordStore.setFieldsValue({ keyword: value });
  };

  handleReset = () => {
    ClinicalRecordStore.reset();
  };

  handleRefresh = () => {
    ClinicalRecordStore.refetch({}, true);
  };

  handleChangeSearchDropdown = e => {
    ClinicalRecordStore.refetchClaimant({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };
  renderClaimantOptions = () => {
    const array = [];
    const data = toJS(ClinicalRecordStore.dataClaimant.itemList);
    if (api.isAdminOrMagStaffOrAccounting()) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].FullName !== null) {
          array.push(data[i]);
        }
      }
      array.unshift({ Id: '', FullName: `All` });
      return array.map(opt => ({
        text: `${opt.Id ? `${opt.Id} -` : ``}  ${opt.FullName || ''}`,
        value: JSON.stringify({ Id: opt.Id, FullName: opt.FullName }),
      }));
    }
    return [];
  };
  renderClaimantText = value => {
    if (value.Id === '') {
      return 'Search a Claimant';
    } else {
      const array = [];
      const data = toJS(ClinicalRecordStore.dataClaimant.itemList);
      for (var i = 0; i < data.length; i++) {
        if (data[i].FullName !== null) {
          array.push(data[i]);
        }
      }
      array.unshift({ Id: '', FullName: `All` });
      let labels = array.filter(opt => opt.Id === value.Id).map(opt => `${opt.FullName || ''}`);
      return labels[0];
    }
  };

  checkIsJSON = json => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterClaimant = (_, { value }) => {
    const claimant = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (claimant.Id === `` || value === `All` || !claimant) {
      ClinicalRecordStore.refetch(
        {
          claimant: { Id: ``, FullName: `` },
          curPage: 1,
          keyword: ``,
        },
        true,
      );
      ClinicalRecordStore.resetSearch();
    } else {
      ClinicalRecordStore.refetch(
        {
          claimant,
          keyword: claimant.FullName,
          curPage: 1,
          keywordClaimant: claimant.FullName,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  handleChangeFromTo = (_, { value }) => {
    ClinicalRecordStore.setFieldsValue({ fromTo: value });
  };

  handleShowBookingForToday = () => {
    ClinicalRecordStore.refetch(
      {
        fromTo: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
      },
      true,
    );
  };

  renderModal = () => {
    const { message, openModalAlert } = this.state;
    return (
      <ModalAlert
        open={openModalAlert}
        modalParams={{ message }}
        onCancel={() => this.setState({ openModalAlert: false })}
      />
    );
  };

  handleScroll = event => {
    const target = event.target;
    const number = ClinicalRecordStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ClinicalRecordStore.refetchClaimant({ numberSearch: number + 20 });
    }
  };

  render() {
    const { keyword, searchClaimantOnly, loading, fromTo, filter, loadingClaimant, totalItems } = ClinicalRecordStore;

    return (
      <form className="file-review-toolbar">
        {this.renderModal()}
        <TableToolbar.Container style={{ justifyContent: 'flex-start' }}>
          <TableToolbar.Left as="div">
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <Checkbox
                label="Search claimant only"
                onChange={this.onChecked}
                checked={searchClaimantOnly}
                style={{ marginRight: `10px` }}
                disabled={loading}
              />
            )} */}
            {searchClaimantOnly && api.isAdminOrMagStaffOrAccounting() ? (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                onScroll={this.handleScroll}
                allowAdditions
                additionLabel=""
                closeOnChange
                selectOnNavigation={false}
                value={keyword}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderClaimantOptions()}
                onChange={this.handleFilterClaimant}
                className="Toolbar-input"
                disabled={loading}
                clearable
                style={{ width: '20rem' }}
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
            ) : (
              <TableToolbar.Input
                value={keyword}
                onChange={this.handleChange}
                disabled={loading}
                style={{ width: '20rem' }}
              />
            )}
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: '5px' }}
              onClick={this.handleReset}
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              onClick={this.handleRefresh}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {api.isCaseManager() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                className={`positive`}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>
            )}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Left as="div" onSubmit={this.handleSubmit}>
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                onClick={this.openCreateNewCase}
                style={{ marginLeft: `5px` }}
                className={`positive`}
                disabled={loading}
              >
                New Case
              </TableToolbar.HighLightButton>}

              <TableToolbar.HighLightButton
                className="positive"
                name="search"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExport}
                disabled={loading}
              >
                Export Searches
              </TableToolbar.HighLightButton>

              <TableToolbar.HighLightButton
                className="positive"
                name="correspondence"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExport}
                disabled={loading}
              >
                Export Correspondence History
              </TableToolbar.HighLightButton>

              <TableToolbar.HighLightButton
                color={`blue`}
                onClick={this.handleShowBookingForToday}
                style={{ marginLeft: `5px` }}
                disabled={loading}
              >
                Show Today's Bookings
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          )}
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          {/* {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Next Task PIC`}
                value={`${this.renderFilterLabel(`dataStaffAssign`, ClinicalRecordStore.NextTaskPIC)}`}
                name={'NextTaskPIC'}
                options={this.renderFilterOption(`dataStaffAssign`)}
                onChange={this.handleFilterChange}
                disabled={loading}
              />
            )} */}
          {/* {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Next Task`}
                value={`${this.renderNextTaskLabel(filter)}`}
                name={'filter'}
                options={this.renderNextTask()}
                onChange={this.handleFilterChange}
                disabled={loading}
              />
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                selection
                label={`Next Task Overdue`}
                value={`${this.renderCaseStatusLabel(`dataOverDueCRStatus`, ClinicalRecordStore.filterOverdue)}`}
                className="Toolbar-filter"
                name={'filterOverdue'}
                options={this.renderNextTaskOverDue()}
                onChange={this.handleFilterChange}
                disabled={loading}
              />
            )} */}

          <TableToolbar.RangePicker
            value={fromTo}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          <TableToolbar.Filter
            selection
            label={`Case Status`}
            value={`${this.renderCaseStatusLabel(`dataCRStatus`, filter)}`}
            name={'filter'}
            options={this.renderCaseStatus()}
            onChange={this.handleFilterChange}
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              selection
              label={`Action Required`}
              value={`${this.renderFilterLabel(`dataStaffAssignAction`, ClinicalRecordStore.actionRequiredById)}`}
              name={'actionRequiredById'}
              options={this.renderFilterOption(`dataStaffAssignAction`)}
              onChange={this.handleFilterChange}
              disabled={loading}
              style={{ marginBottom: '1rem' }}
            />
          )}
        </TableToolbar.Container>
      </form>
    );
  }
}

export default ClinicalRecordToolbar;
