import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ServiceFeeStore from './AMAServiceFeeStore';
import ModalCheckTreatment from './ModalCheckTreatment';
import ModalCreateEditServiceFee from './ModalCreatEditServiceFee';
import CreateCalculationRule from './CreateCalculationRule';
import ModalImport from './ModalImport';

function ServiceFeeModal() {
  const { modalType } = ServiceFeeStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'checkTreatment') {
    return <ModalCheckTreatment {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'addCalculationRule') {
    return <CreateCalculationRule {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'createServiceFee') {
    return <ModalCreateEditServiceFee {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'import') {
    return <ModalImport {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
}

export default ServiceFeeModal;
