import { notification } from 'antd';
import { action, observable, computed, reaction } from 'mobx';
import moment from 'moment-timezone';

import {
  fetchCalendarList,
  fetchClinic,
  fetchSpecialists,
  fetchAllClinic,
  getClientList,
  getCaseManagerList,
  getDailyBookingsByRoom,
  fetchReportTypesList,
} from './service';
import * as api from '../../../stores/api';
import { debounce } from 'lodash-es';

class Store {
  @observable loading = true;
  @observable loadingSpecialists = true;
  @observable loadingClients = true;
  @observable loadingCaseManagers = true;
  @observable loadingClinics = true;
  @observable dataDailyBooking = null;
  @observable clinics = [];
  @observable sessions = [];
  @observable doctors = [];
  @observable reportTypes = [];
  @observable initialView = 'dayGridMonth';
  @observable clients = [];
  @observable keywordClient = '';
  @observable numberClient = 30;
  @observable casemanagers = [];
  @observable keywordCaseManager = '';
  @observable numberCaseManager = 30;
  @observable isReloadCalendar = false;
  @observable isResetStore = false;
  @observable currentDate = null;
  @observable showWeekend = false;
  @observable dayWeekViewType = 'all';
  @observable calendarDateString = '';
  @observable timeZone = moment.tz.guess();
  //----------------------------------------------------------------
  @observable GoToDate = '';
  @observable dateForDailyBooking = '';
  @observable clinicId = 0;
  @observable doctorId = 0;
  @observable end = null;
  @observable start = null;
  @observable isHomeVisit = false;
  @observable onlySessions = false;
  @observable onlyTeleAssessment = false;

  //----------------------------------------------------------------
  @observable specialistKeyword = '';
  @observable specialistNumber = 30;
  //----------------------------------------------------------------
  //----------------------------------------------------------------
  @observable clinicKeyword = '';
  @observable clinicNumber = 30;
  //----------------------------------------------------------------
  @observable clinicId = null;
  @observable isHomeVisit = false;
  @observable calendarViewMode = 'dayGridMonth';

  @observable open = false;
  @observable pageGetter = null;
  @observable fieldName = null;
  @observable modalParams = {};
  @observable selectedDoctors = [];
  @observable selectedClinic = null;
  @observable selectedDoctor = null;
  @observable addByClickTimeSlot = false;
  @observable doctorAppointmentSelected = null;

  @observable tableFileChangeTitle = "";
  @observable tableDataFieldChange = [];

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action updateListCasemanagers = data => {
    this.casemanagers.push(data);
  };

  getTimezone = () => {
    const leadZero = num => (num < 10 ? '0' + num : num);

    const tzOffset = -1 * new Date().getTimezoneOffset();
    const hour = ~~Math.abs(tzOffset / 60);
    const minute = Math.abs(tzOffset % 60);

    return `${tzOffset < 0 ? '-' : '+'}${leadZero(hour)}:${leadZero(minute)}`;
  };
  returnStartAndEndDate = () => {
    const { start, end } = this;
    const start_ = start
      ? moment(start)
          .startOf('day')
          .tz(this.timeZone)
          .toISOString()
      : end
      ? moment(end)
          .subtract(1, 'week')
          .startOf('day')
          .tz(this.timeZone)
          .toISOString()
      : moment()
          .startOf('day')
          .tz(this.timeZone)
          .toISOString();
    const end_ = end
      ? moment(end)
          .endOf('day')
          .tz(this.timeZone)
          .toISOString()
      : start
      ? moment(start)
          .add(1, 'week')
          .endOf('day')
          .tz(this.timeZone)
          .toISOString()
      : moment()
          .add(1, 'week')
          .startOf('day')
          .tz(this.timeZone)
          .toISOString();
    return { start_, end_ };
  };

  handleSearch = () => {
    if (this.calendarViewMode !== 'dailyBookingByRooms') {
      this.setFieldsValue({ loading: true });
      const { start_, end_ } = this.returnStartAndEndDate();

      const goToDate = this.GoToDate ? moment(this.GoToDate, 'DD/MM/YYYY').toISOString() : null;
      fetchCalendarList(
        goToDate,
        this.clinicId,
        this.doctorId ? this.doctorId || this.selectedDoctors[0]?.Id : null,
        end_,
        this.isHomeVisit,
        this.onlySessions,
        this.onlyTeleAssessment,
        start_,
      )
        .then(
          action(res => {
            this.sessions = res.itemList;
            this.setFieldsValue({ loading: false });
          }),
        )
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error occred while fetching calendar events.',
            description: 'Please report this to system administrator and try again later.',
          });
        });
    }
    if (this.calendarViewMode === 'dailyBookingByRooms') {
      if (!!this.clinicId && this.GoToDate) {
        this.setFieldsValue({ loading: true });
        const bookingsDate = moment(this.GoToDate, 'DD/MM/YYYY').toISOString();
        getDailyBookingsByRoom(bookingsDate, this.clinicId).then(
          action(res => {
            this.dataDailyBooking = res;
            this.setFieldsValue({ loading: false });
          }),
        );
      } else if (!this.GoToDate) {
        this.toggleModal(true, { modalType: 'confirm', message: 'Please select a date' })();
      } else if (!this.clinicId) {
        this.toggleModal(true, { modalType: 'confirm', message: 'Please select a clinic' })();
      }
    }
  };

  @action clearStore = (isClearSelectSession = false) => {
    this.selectedDoctor = null;
    this.selectedClinic = null;
    this.dataDailyBooking = null;
    this.sessions = [];
    this.initialView = 'dayGridMonth';
    this.clients = [];
    this.casemanagers = [];
    this.keywordClient = '';
    this.numberClient = 30;
    this.isReloadCalendar = false;
    this.isResetStore = true;
    this.currentDate = null;
    this.showWeekend = false;
    this.timeZone = moment.tz.guess();
    //----------------------------------------------------------------
    this.GoToDate = '';
    this.dateForDailyBooking = '';
    this.clinicId = 0;
    this.doctorId = 0;
    this.isHomeVisit = false;
    this.onlySessions = false;
    this.onlyTeleAssessment = false;

    //----------------------------------------------------------------
    this.specialistKeyword = '';
    this.specialistNumber = 500;
    //----------------------------------------------------------------
    //----------------------------------------------------------------
    this.clinicKeyword = '';
    this.clinicNumber = 30;
    //----------------------------------------------------------------
    this.clinicId = 0;
    this.isHomeVisit = false;
    this.calendarViewMode = 'dayGridMonth';

    this.open = false;
    if (isClearSelectSession) {
      this.pageGetter = null;
      this.fieldName = null;
      this.doctorAppointmentSelected = null;
    } else {
      this.handleSearch();
    }
    this.modalParams = {};
    this.dayWeekViewType = 'all';
    this.addByClickTimeSlot = false;
    //----------------------------------------------------------------
    this.updateTimeRange(this.getMomentDate(moment()), this.calendarViewMode);
  };
  getMomentDate(date, skipTimezone = true) {
    if (!date) return date;
    if (skipTimezone) {
      return moment(date).startOf('day');
    }
    return moment(date)
      .tz(this.timeZone)
      .startOf('day');
  }

  @action fetchCalendarList = () => {
    if (this.calendarViewMode !== 'dailyBookingByRooms') {
      this.setFieldsValue({ loading: true });
      const { start_, end_ } = this.returnStartAndEndDate();
      const goToDate = this.GoToDate ? moment(this.GoToDate, 'DD/MM/YYYY').toISOString() : null;
      fetchCalendarList(
        goToDate,
        this.clinicId,
        this.doctorId,
        end_,
        this.isHomeVisit,
        this.onlySessions,
        this.onlyTeleAssessment,
        start_,
      )
        .then(
          action(res => {
            this.sessions = res.itemList;
            this.setFieldsValue({ loading: false });
          }),
        )
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error occred while fetching calendar events.',
            description: 'Please report this to system administrator and try again later.',
          });
        })
        .finally(() => {
          this.setFieldsValue({ loading: false });
        });
    }

    this.setFieldsValue({ loadingSpecialists: true, loadingClients: true, loadingClinics: true });
    fetchReportTypesList()
      .then(
        action(res => {
          this.reportTypes = res.itemList;
        }),
      )
      .catch(() => {});
    fetchSpecialists(this.specialistKeyword, this.specialistNumber)
      .then(
        action(res => {
          this.doctors = res.itemList;
          this.setFieldsValue({ loadingSpecialists: false });
        }),
      )
      .catch(() => {});
    getClientList(this.keywordClient, this.numberClient)
      .then(
        action(res => {
          this.clients = res.itemList;
          this.setFieldsValue({ loadingClients: false });
        }),
      )
      .catch(() => {});
    getCaseManagerList(this.keywordCaseManager, this.numberCaseManager)
      .then(
        action(res => {
          this.casemanagers = res.itemList;
          this.setFieldsValue({ loadingCaseManagers: false });
        }),
      )
      .catch(() => {});
    if (!this.doctorId) {
      fetchAllClinic(this.clinicKeyword, null, this.clinicNumber)
        .then(
          action(res => {
            this.clinics = res.itemList;
            this.setFieldsValue({ loadingClinics: false });
          }),
        )
        .catch(() => {});
    } else {
      fetchClinic(this.doctorId, this.clinicKeyword, this.clinicNumber)
        .then(
          action(res => {
            this.clinics = res.itemList;
            this.setFieldsValue({ loadingClinics: false });
          }),
        )
        .catch(() => {});
    }

    reaction(
      () => this.keywordClient,
      debounce(() => {
        this.setFieldsValue({ loadingClients: true });
        getClientList(this.keywordClient, this.numberClient)
          .then(
            action(res => {
              this.clients = res.itemList;
              this.setFieldsValue({ loadingClients: false });
            }),
          )
          .catch(() => {});
      }, 1500),
    );

    reaction(
      () => this.keywordCaseManager,
      debounce(() => {
        this.setFieldsValue({ loadingCaseManagers: true });
        getCaseManagerList(this.keywordCaseManager, this.numberCaseManager)
          .then(
            action(res => {
              this.casemanagers = res.itemList;
              this.setFieldsValue({ loadingCaseManagers: false });
            }),
          )
          .catch(() => {});
      }, 1500),
    );

    if (this.calendarViewMode === 'dailyBookingByRooms') {
      if (!!this.clinicId && this.GoToDate) {
        this.setFieldsValue({ loading: true });
        const bookingsDate = moment(this.GoToDate, 'DD/MM/YYYY').toISOString();
        getDailyBookingsByRoom(bookingsDate, this.clinicId)
          .then(
            action(res => {
              this.dataDailyBooking = res;
              this.setFieldsValue({ loading: false });
            }),
          )
          .catch(() => {});
      }
    }
    if (!api.isDoctor()) {
      reaction(
        () => this.specialistKeyword || this.specialistNumber,
        debounce(() => {
          this.setFieldsValue({ loadingSpecialists: true });
          fetchSpecialists(this.specialistKeyword, this.specialistNumber)
            .then(
              action(res => {
                this.doctors = res.itemList;
                this.setFieldsValue({ loadingSpecialists: false });
              }),
            )
            .catch(() => {});
        }, 1500),
      );
    }

    if (!api.isDoctor()) {
      if (!!!this.doctorId) {
        fetchAllClinic(this.clinicKeyword, null, this.clinicNumber)
          .then(
            action(res => {
              this.clinics = res.itemList;
              this.setFieldsValue({ loadingClinics: false });
            }),
          )
          .catch(() => {});
      } else {
        fetchClinic(this.doctorId, this.clinicKeyword, this.clinicNumber)
          .then(
            action(res => {
              this.clinics = res.itemList;
              this.setFieldsValue({ loadingClinics: false });
            }),
          )
          .catch(() => {});
      }
      reaction(
        () => this.doctorId,
        () => {
          this.setFieldsValue({ loadingClinics: true });
          if (!!!this.doctorId) {
            fetchAllClinic(this.clinicKeyword, null, this.clinicNumber)
              .then(
                action(res => {
                  this.clinics = res.itemList;
                  this.setFieldsValue({ loadingClinics: false });
                }),
              )
              .catch(() => {});
          } else {
            fetchClinic(this.doctorId, this.clinicKeyword, this.clinicNumber)
              .then(
                action(res => {
                  this.clinics = res.itemList;
                  this.setFieldsValue({ loadingClinics: false });
                }),
              )
              .catch(() => {});
          }
        },
      );
      reaction(
        () => this.clinicKeyword,
        debounce(() => {
          this.setFieldsValue({ loadingClinics: true });
          if (!!!this.doctorId) {
            fetchAllClinic(this.clinicKeyword, null, this.clinicNumber)
              .then(
                action(res => {
                  this.clinics = res.itemList?.map(({ Id, ID, Name, FullAddress }) => ({
                    Id: Id ?? ID,
                    Name: Name,
                    FullAddress: FullAddress,
                  }));
                  this.setFieldsValue({ loadingClinics: false });
                }),
              )
              .catch(() => {});
          } else {
            fetchClinic(this.doctorId, this.clinicKeyword, this.clinicNumber)
              .then(
                action(res => {
                  this.clinics = res.itemList;
                  this.setFieldsValue({ loadingClinics: false });
                }),
              )
              .catch(() => {});
          }
        }, 1500),
      );
    } else {
      fetchClinic(api.currentUser.data.DoctorId)
        .then(
          action(res => {
            this.clinics = res.itemList;
            this.setFieldsValue({ loadingClinics: false });
          }),
        )
        .catch(() => {});
      reaction(
        () => this.clinicKeyword,
        debounce(() => {
          this.setFieldsValue({ loadingClinics: true });
          fetchClinic(api.currentUser.data.DoctorId)
            .then(
              action(res => {
                this.clinics = res.itemList;
                this.setFieldsValue({ loadingClinics: false });
              }),
            )
            .catch(() => {});
        }, 1500),
      );
    }
  };

  handleViewModeChange = mode => {
    return action(() => {
      this.calendarViewMode = mode;
    });
  };

  getMomentDateTime(date, timezoneParam = null, { ignoreDST = false } = {}) {
    const timezoneValue = timezoneParam ?? this.timeZone;
    if (typeof date === 'string') {
      if (ignoreDST) {
        const defaultOffset = moment.tz(timezoneValue).utcOffset();
        return moment(date).utcOffset(defaultOffset);
      } else {
        return moment(date).tz(timezoneValue);
      }
    }

    if (date) {
      return moment(date).tz(timezoneValue);
    }

    return date;
  }

  updateTimeRange = (date, viewMode) => {
    let startDate;
    let endDate;

    if (!viewMode) {
      viewMode = this.calendarViewMode;
    }
    if (viewMode === 'timeGridDay' || viewMode === 'resourceTimeGridDay') {
      startDate = moment(date);
      endDate = moment(date);
    }

    if (viewMode === 'timeGridWeek' || viewMode === 'resourceTimeGridWeek') {
      startDate = moment(date).startOf('isoWeek');
      endDate = moment(date).endOf('isoWeek');
    }

    if (viewMode === 'dayGridMonth') {
      startDate = moment(date).startOf('month');
      endDate = moment(date).endOf('month');
    }

    this.setFieldsValue({
      start: startDate,
      end: endDate,
      calendarViewMode: viewMode,
    });

    this.updateCalendarDateString();
  };

  formatDate(datetime, timeZone, format, skipTimezone = false) {
    const defaultFormat = 'DD/MM/YYYY';
    const configTimeZone = timeZone || moment.tz.guess();
    if (!format) {
      format = defaultFormat;
    }
    if (datetime) {
      if (skipTimezone) {
        return moment(datetime).format(format);
      }
      return moment(datetime)
        .tz(configTimeZone)
        .format(format);
    }
    return '';
  }

  @action updateCalendarDateString = () => {
    let calendarDateString;

    if (this.calendarViewMode === 'timeGridDay' || this.calendarViewMode === 'resourceTimeGridDay') {
      calendarDateString = this.formatDate(this.start);
    }

    if (this.calendarViewMode === 'timeGridWeek' || this.calendarViewMode === 'resourceTimeGridWeek') {
      calendarDateString = this.formatDate(this.start) + ' - ' + this.formatDate(this.end);
    }

    if (this.calendarViewMode === 'dayGridMonth') {
      calendarDateString = this.formatDate(this.start) + ' - ' + this.formatDate(this.end);
    }

    this.setFieldsValue({ calendarDateString });
  };
}

export default new Store();
