import './index.scss';
import cx from 'classnames';
import React from 'react';
import {
  Accordion,
  Checkbox,
  Divider,
  Icon,
  Input,
  Label,
  Button,
  // Form,
} from 'semantic-ui-react';
import {
  action,
  observable,
  // toJS
} from 'mobx';
// import customFetch from '../../../utils/customFetch';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import TablesDoc from './TablesDoc';

import imeStore from './StoreIME';
import frStore from './StoreFR';
import suppStore from './StoreSUPP';
import crStore from './StoreCR';
import mnStore from './StoreMN';
import invStore from './StoreINV';

import { observer } from 'mobx-react';
import router from '../../../stores/router';
import { getRouteParams } from '@utils/functions';

@observer
class DocSearch extends React.Component {
  @observable accordionItems = [
    {
      title: 'IME Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageIMEClick,
    },
    {
      title: 'File Reviews',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageFRClick,
    },
    {
      title: 'Supplementary Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageSUPPClick,
    },
    {
      title: 'Clinical Records',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageCRClick,
    },
    {
      title: 'Med Negs',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageMNClick,
    },
    {
      title: 'Invoices',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      eSumItem: 0,
      handlePageClick: this.handlePageINVClick,
    },
  ];
  @observable searchIME = true;
  @observable searchFr = true;
  @observable searchSupp = true;
  @observable searchCr = true;
  @observable searchMN = true;
  @observable searchInv = true;
  @observable loading = false;
  @observable searchActive = false;
  @observable searchAdvanced = false;
  @observable noCaseFound = false;

  state = {
    search: '',
    active: [],
    recentSearch: JSON.parse(localStorage.getItem('MAG/DOC_RECENT_SEARCH')) || [],
  };

  @action componentDidMount() {
    const { search } = getRouteParams(router.location.search, router.location.state);
    if (search && search.length) {
      this.searchIME = true;
      this.searchFr = true;
      this.searchSupp = true;
      this.searchCr = true;
      this.searchMN = true;
      this.searchInv = true;
      this.setState({ search }, () => this.handleSubmit());
    }
  }

  @action imeData = curPage => {
    if (curPage) {
      imeStore.curPage = curPage;
    }
    imeStore.keyword = this.state.search;
    imeStore.refetch({ curPage: imeStore.curPage }).then(
      action(() => {
        if (imeStore && imeStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'IME Reports');
          this.accordionItems[idx].data = imeStore.data;
          this.accordionItems[idx].curPage = imeStore.curPage;
          this.accordionItems[idx].totalPage = imeStore.totalPage;
          this.accordionItems[idx].sumItem = imeStore.totalItems;
          this.accordionItems[idx].eSumItem = imeStore.etotalItems;
          this.loading = imeStore.loading;
          if (imeStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        } else {
          this.clearData('IME Reports');
        }
      }),
    );
  };

  @action crData = curPage => {
    if (curPage) {
      crStore.currentPage = curPage;
    }
    crStore.keyword = this.state.search;
    crStore.refetch({ currentPage: crStore.currentPage }).then(
      action(() => {
        if (crStore && crStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Clinical Records');
          this.accordionItems[idx].data = crStore.data;
          this.accordionItems[idx].curPage = crStore.curPage;
          this.accordionItems[idx].totalPage = crStore.totalPage;
          this.accordionItems[idx].sumItem = crStore.totalItems;
          this.accordionItems[idx].eSumItem = crStore.etotalItems;
          this.loading = crStore.loading;
          if (crStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        } else {
          this.clearData('Clinical Records');
        }
      }),
    );
  };

  @action suppData = curPage => {
    if (curPage) {
      suppStore.curPage = curPage;
    }
    suppStore.onSearchChange(this.state.search).then(
      action(() => {
        if (suppStore && suppStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Supplementary Reports');
          this.accordionItems[idx].data = suppStore.data;
          this.accordionItems[idx].curPage = suppStore.curPage;
          this.accordionItems[idx].totalPage = suppStore.totalPage;
          this.accordionItems[idx].sumItem = suppStore.totalItems;
          this.accordionItems[idx].eSumItem = suppStore.etotalItems;
          this.loading = suppStore.loading;
          if (suppStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        } else {
          this.clearData('Supplementary Reports');
        }
      }),
    );
  };

  @action frData = curPage => {
    if (curPage) {
      frStore.curPage = curPage;
    }
    frStore.onSearchChange(this.state.search).then(
      action(() => {
        if (frStore && frStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
          this.accordionItems[idx].data = frStore.data;
          this.accordionItems[idx].curPage = frStore.curPage;
          this.accordionItems[idx].totalPage = frStore.totalPage;
          this.accordionItems[idx].sumItem = frStore.totalItems;
          this.accordionItems[idx].eSumItem = frStore.etotalItems;
          this.loading = frStore.loading;
          if (frStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        } else {
          this.clearData('File Reviews');
        }
      }),
    );
  };

  @action mnData = curPage => {
    if (curPage) {
      mnStore.curPage = curPage;
    }
    mnStore.keyword = this.state.search;
    mnStore.refetch({ curPage: mnStore.curPage }).then(
      action(() => {
        if (mnStore && mnStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Med Negs');
          this.accordionItems[idx].data = mnStore.data;
          this.accordionItems[idx].curPage = mnStore.curPage;
          this.accordionItems[idx].totalPage = mnStore.totalPage;
          this.accordionItems[idx].sumItem = mnStore.totalItems;
          this.accordionItems[idx].eSumItem = mnStore.etotalItems;
          this.loading = mnStore.loading;
          if (mnStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        } else {
          this.clearData('Med Negs');
        }
      }),
    );
  };

  @action invData = curPage => {
    if (curPage) {
      invStore.curPage = curPage;
    }
    if (this.searchStaffOnly) {
      invStore.StaffId = this.staff;
    }
    invStore.searchStaffOnly = this.searchStaffOnly;
    invStore.pendingAC = this.pendingAC;
    invStore.keyword = this.state.search;
    invStore.refetch({ curPage: crStore.curPage }).then(
      action(() => {
        if (invStore && invStore.data && invStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Invoices');
          this.accordionItems[idx].data = invStore.data.itemList;
          this.accordionItems[idx].curPage = invStore.data.curPage;
          this.accordionItems[idx].totalPage = invStore.data.sumPage;
          this.accordionItems[idx].sumItem = invStore.data.sumItem;
          this.loading = invStore.loading;
          if (invStore.totalItems > 0) {
            this.noCaseFound = false;
          }
        }
      }),
    );
  };

  @action handlePageMNClick = page => {
    this.loading = true;
    this.mnData(page);
  };
  @action handlePageINVClick = page => {
    this.loading = true;
    this.invData(page);
  };

  @action handlePageFRClick = page => {
    this.loading = true;
    this.frData(page);
  };
  @action handlePageSUPPClick = page => {
    this.loading = true;
    this.suppData(page);
  };
  @action handlePageIMEClick = page => {
    this.loading = true;
    this.imeData(page);
  };

  @action handlePageCRClick = page => {
    this.loading = true;
    this.crData(page);
  };
  @action onSearch = () => {
    this.loading = true;
    this.noCaseFound = true;
    if (this.searchIME) {
      this.imeData();
    } else {
      this.clearData('IME Reports');
    }
    if (this.searchSupp) {
      this.suppData();
    } else {
      this.clearData('Supplementary Reports');
    }
    if (this.searchFr) {
      this.frData();
    } else {
      this.clearData('File Reviews');
    }
    if (this.searchCr && !api.isDoctor()) {
      this.crData();
    } else {
      this.clearData('Clinical Records');
    }
    if (this.searchMN) {
      this.mnData();
    } else {
      this.clearData('Med Negs');
    }
    if (this.searchINV) {
      this.invData();
    } else {
      this.clearData('Invoices');
    }
  };

  @action clearData = type => {
    this.accordionItems[this.accordionItems.findIndex(i => i.title === type)].data = [];
  };

  @action onClear = () => {
    this.setState({
      search: '',
    });
    this.noCaseFound = false;
    this.searchIME = true;
    this.searchFr = true;
    this.searchSupp = true;
    this.searchCr = true;
    this.searchMN = true;
    this.searchInv = true;
    this.searchAdvanced = false;
    this.clearData('IME Reports');
    this.clearData('Supplementary Reports');
    this.clearData('File Reviews');
    this.clearData('Clinical Records');
    this.clearData('Med Negs');
    this.clearData('Invoices');
  };

  handleSearchChange = ({ target }) => {
    this.setState({ search: target.value });
  };

  handleSubmit = event => {
    event && event.preventDefault();
    if (this.state.search) {
      this.onSearch();
      this.setState(() => {
        this.handleRecentSearchChange({ newSearch: this.state.search });
      });
    }
  };

  recentSearchHandle = search => () => {
    this.setState({ search }, () => this.onSearch());
  };

  renderRecentSearchList = recentSearch => {
    return recentSearch.map((search, idx) => (
      <Label key={idx}>
        <span onClick={this.recentSearchHandle(search)} className="clickable">
          {search}
        </span>
        <Icon name="delete" onClick={() => this.handleRecentSearchChange({ idx })} />
      </Label>
    ));
  };

  handleRecentSearchChange = ({ idx, newSearch }) => {
    const { recentSearch } = this.state;
    let newRecentSearch;

    if (!!newSearch) {
      newRecentSearch = [...(recentSearch.length < 5 ? recentSearch : recentSearch.slice(1)), newSearch];
    } else {
      newRecentSearch = recentSearch.filter((_, index) => index !== idx);
    }

    this.setState({ recentSearch: newRecentSearch }, () => {
      localStorage.setItem('MAG/DOC_RECENT_SEARCH', JSON.stringify(newRecentSearch));
    });
  };

  isActive = index => {
    return this.state.active.includes(index);
  };

  handleToggleActive = index => () => {
    this.setState(({ active, ...prev }) => ({
      ...prev,
      active: this.isActive(index) ? active.filter(idx => idx !== index) : [...active, index],
    }));
  };

  @action searchToggle = () => {
    this.searchAdvanced = !this.searchAdvanced;
  };

  render() {
    const { search, recentSearch } = this.state;
    const loading = this.loading;

    return (
      <div className={cx('Documents-search-page mb-1', { loading })}>
        {/* <div className={'search-wrapper ' + (this.searchActive ? ' active' : '')}>
          <div className="input-holder">
            <input type="text" className="search-input" placeholder="Type to search" />
            <button className="search-icon"><span></span></button>
          </div>
          <span className={'plus ' + (this.searchAdvanced ? ' active' : '')} onClick={this.searchToggle}></span>


          <div className={'result-container ' + (this.searchAdvanced ? ' advanced' : '')} >
            <div className="form-checkboxes">
              <Checkbox
                label="IME Reports"
                name="searchIME"
                checked={this.searchIME}
                onChange={action(
                  () => (this.searchIME = !this.searchIME),
                )} />
              <Checkbox
                label="File Reviews"
                name="searchFr"
                checked={this.searchFr}
                onChange={action(
                  () => (this.searchFr = !this.searchFr),
                )}
              />
              <Checkbox
                label="Supplementart Reports"
                name="searchSupp"
                checked={this.searchSupp}
                onChange={action(
                  () => (this.searchSupp = !this.searchSupp),
                )}
              />
              <Checkbox
                label="Clinical Records"
                name="searchCr"
                checked={this.searchCr}
                onChange={action(
                  () => (this.searchCr = !this.searchCr),
                )}
              />
              <Checkbox
                label="Med Negs"
                name="searchMN"
                checked={this.searchMN}
                onChange={action(
                  () => (this.searchMN = !this.searchMN),
                )}
              />
            </div>
          </div>
          {this.searchActive &&
            <div className={'btn-container ' + (this.searchAdvanced ? ' advanced' : '')} >
              <TableToolbar.SubmitButton
                className="Toolbar-highlight-button px-2"
                style={{ marginLeft: '5px' }}
                onClick={this.handleSubmit}
                disabled={loading}
              >
                Search
            </TableToolbar.SubmitButton>
              <TableToolbar.HighLightButton
                onClick={this.onClear}
                style={{ marginLeft: '5px' }}
                className="negative"
                disabled={loading}
              >
                Clear
            </TableToolbar.HighLightButton>
              <div className="recent-search">
                <h4>Recent searches:</h4>
                <div className="recent-search-list">
                  {!recentSearch.length ? (
                    <div className="recent-search-emtpy-msg">No recent search</div>
                  ) : (
                      this.renderRecentSearchList(recentSearch)
                    )}
                </div>
              </div>
            </div>
          }
        </div> */}

        <h1 className="search-title">Search Case Documents</h1>
        <form className="search-form" onSubmit={this.handleSubmit}>
          <div className="inline">
            <span title='Put in "" for exact words'>
              <Input
                fluid
                size="large"
                icon="search"
                value={search}
                onChange={this.handleSearchChange}
                placeholder="Search any keyword"
                loading={loading}
                disabled={loading}
                className="search-input"
                iconPosition="left"
                label={
                  search.length ? <Button className="clear" type="button" icon="times" onClick={this.onClear} /> : null
                }
                labelPosition={search.length ? 'right' : null}
              />
            </span>
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            {/* <TableToolbar.HighLightButton
              onClick={this.onClear}
              style={{ marginLeft: '5px' }}
              className="negative"
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton> */}
          </div>
          <div className="form-checkboxes">
            <Checkbox
              label="IME Reports"
              name="searchIME"
              checked={this.searchIME}
              onChange={action(() => (this.searchIME = !this.searchIME))}
            />
            <Checkbox
              label="File Reviews"
              name="searchFr"
              checked={this.searchFr}
              onChange={action(() => (this.searchFr = !this.searchFr))}
            />
            <Checkbox
              label="Supplementary Reports"
              name="searchSupp"
              checked={this.searchSupp}
              onChange={action(() => (this.searchSupp = !this.searchSupp))}
            />
            <Checkbox
              label="Clinical Records"
              name="searchCr"
              checked={this.searchCr}
              onChange={action(() => (this.searchCr = !this.searchCr))}
            />
            <Checkbox
              label="Med Negs"
              name="searchMN"
              checked={this.searchMN}
              onChange={action(() => (this.searchMN = !this.searchMN))}
            />
          </div>
          <div className="recent-search">
            <h4>Recent searches:</h4>
            <div className="recent-search-list">
              {!recentSearch.length ? (
                <div className="recent-search-emtpy-msg">No recent search</div>
              ) : (
                this.renderRecentSearchList(recentSearch)
              )}
            </div>
          </div>
          {this.noCaseFound && !this.loading && (
            <div className="recent-search">
              <div className="recent-search-list">
                <div className="recent-search-emtpy-msg">
                  <h2>NO RESULTS</h2>
                </div>
              </div>
            </div>
          )}
        </form>
        <Accordion fluid className="mt-3">
          {this.accordionItems.map(({ title, data, curPage, totalPage, sumItem, eSumItem, handlePageClick }, idx) => (
            <React.Fragment key={idx}>
              {!api.isDoctor() && data.length > 0 && (
                <React.Fragment>
                  <Accordion.Title
                    index={idx}
                    className={cx('py-0', { 'mt-3': idx > 0 })}
                    active={this.isActive(idx)}
                    onClick={this.handleToggleActive(idx)}
                  >
                    <h3 className="mb-0">
                      <Icon name="dropdown" />
                      {title} ({eSumItem})
                    </h3>
                  </Accordion.Title>
                  <Divider />
                  <Accordion.Content active={this.isActive(idx)}>
                    <TablesDoc
                      data={data}
                      curPage={curPage}
                      totalPage={totalPage}
                      sumItem={sumItem}
                      handlePageClick={handlePageClick}
                      title={title}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default observer(DocSearch);
