import React from 'react';
import { Form, Checkbox, Radio, Input, DatePicker, Select, notification } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import AddEditStore from './store';
import { toJS } from 'mobx';
import * as api from '@stores/api';

const conversationType = [
  { Name: 'Phone', Id: 0 },
  { Name: 'Email', Id: 1 },
  { Name: 'Email (Send Now)', Id: 4 },
  { Name: 'Private Notes', Id: 5 },
  { Name: 'Relationship Meeting', Id: 2 },
  { Name: 'Relationship Call', Id: 8 },
  { Name: 'Relationship Email', Id: 9 },
  { Name: 'Others', Id: 3 },
];

@observer
class ConversationModalForm extends React.Component {
  converDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString, true);
          const newDate = moment(dateFormat, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
          return moment(newDate, 'DD/MM/YYYY - HH:mm');
        }
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      } else {
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      }
    }
    return null;
  };

  handleSearch = val => {
    AddEditStore.refetchSearchClient({ keywordCM: val });
  };
  handleScroll = event => {
    const target = event.target;
    const number = AddEditStore.numberItemClient;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      AddEditStore.refetchSearchClient({ numberItemClient: number + 20 });
    }
  };
  converMinuteOrHour = (datestr, type) => {
    if (datestr) {
      if (typeof datestr === 'string') {
        if (type === 'hour') {
          return moment(datestr, 'DD/MM/YYYY - HH:mm').format('HH');
        }
        return moment(datestr, 'DD/MM/YYYY - HH:mm').format('mm');
      } else {
        if (type === 'hour') {
          return datestr.format('HH');
        } else {
          return datestr.format('mm');
        }
      }
    }
    return 0;
  };

  checkIsNumber = str => {
    if (typeof str !== 'string') {
      if (typeof str === 'number') return true;
      return false;
    } else {
      return !isNaN(str) && !isNaN(parseFloat(str));
    }
  };

  detectStringOrNumber = value => {
    return this.checkIsNumber(value) ? parseInt(value) : value;
  };

  handleSave = () => {
    const { conversationParam, clinicInfo } = AddEditStore;
    const date = conversationParam.SentDate ? this.converDate(conversationParam.SentDate).format('YYYY-MM-DD') : null;
    const date_ = date ? date : null;
    const body = {
      hour: parseInt(this.converMinuteOrHour(conversationParam.SentDate, 'hour')),
      minute: parseInt(this.converMinuteOrHour(conversationParam.SentDate, 'minute')),
      ClinicId: clinicInfo.ID,
      CallerSender: this.detectStringOrNumber(conversationParam.CallerSender),
      CallerSenderName: conversationParam.CallerSenderName,
      CallerSenderType: conversationParam.CallerSenderType,
      Content: conversationParam.Content,
      ConversationType: conversationParam.ConversationType,
      Id: conversationParam.Id,
      Receiver: this.detectStringOrNumber(conversationParam.Receiver),
      ReceiverName: conversationParam.ReceiverName,
      ReceiverType: conversationParam.ReceiverType,
      SentDate: date_,
      Subject: conversationParam.Subject,
      allowCMToView: conversationParam.allowCMToView,
      allowDoctorToView: conversationParam.allowDoctorToView,
      callerSenderId: this.detectStringOrNumber(conversationParam.CallerSender),
      receiverId: this.detectStringOrNumber(conversationParam.Receiver),
    };
    if (!body.Subject || !body.Receiver || !body.CallerSender || !body.SentDate) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 5,
      });
    } else {
      AddEditStore.handleAddConversation(body);
      this.props.onCancel();
    }
  };

  render() {
    const { visible, onCancel } = this.props;
    const { conversationParam, staffAllList, staffList } = AddEditStore;
    const titleModal = conversationParam.Id === 0 ? 'Add New' : conversationParam.Subject;
    var arrstaffList = JSON.parse(staffList);
    return (
      <Modal
        width={600}
        onCancel={onCancel}
        visible={visible}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>}
            <Button color="red" onClick={onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={titleModal}
      >
        <Form name="conversation" className="conversation-form">
          <div className="checkbox-field">
            <Checkbox
              checked={conversationParam.allowCMToView}
              onChange={AddEditStore.handleCheckedConversation('allowCMToView')}
            >
              Allow CMs to view
            </Checkbox>
          </div>
          <Form.Item label="Correspondence Type">
            <Select
              size="large"
              value={conversationParam.ConversationType}
              onChange={AddEditStore.handleFieldChangeConversation('ConversationType')}
            >
              {conversationType.map(i => (
                <Select.Option key={i.Id} value={i.Id}>
                  {i.Name}
                </Select.Option>
              ))}
            </Select>
            {conversationParam.ConversationType === 4 && (
              <span style={{ color: 'red' }}>Emails will be sent after conversation is saved</span>
            )}
          </Form.Item>
          <Form.Item
            label="Date/Time"
            required
            validateStatus={!conversationParam.SentDate ? 'error' : null}
            help={!conversationParam.SentDate ? 'This field is required' : null}
          >
            <DatePicker
              size="large"
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY - HH:mm"
              value={this.converDate(conversationParam.SentDate)}
              onChange={AddEditStore.handleFieldChangeConversation('SentDate')}
            />
          </Form.Item>
          <Form.Item
            label="Subject"
            required
            validateStatus={!conversationParam.Subject ? 'error' : null}
            help={!conversationParam.Subject ? 'This field is required' : null}
          >
            <Input
              size="large"
              value={conversationParam.Subject}
              onChange={AddEditStore.handleFieldChangeConversation('Subject')}
            />
          </Form.Item>
          <Form.Item label="Content">
            <Input.TextArea
              rows={4}
              value={conversationParam.Content}
              onChange={AddEditStore.handleFieldChangeConversation('Content')}
            />
          </Form.Item>
          <Form.Item
            label="Caller/Sender"
            required
            validateStatus={!conversationParam.CallerSender ? 'error' : null}
            help={!conversationParam.CallerSender ? 'This field is required' : null}
          >
            <Radio.Group
              value={conversationParam.CallerSenderType}
              onChange={AddEditStore.handleFieldChangeConversation('CallerSenderType')}
            >
              <Radio value={1}>Case Manager</Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            {conversationParam.CallerSenderType === 1 ? (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.CallerSender),
                  label: toJS(conversationParam.CallerSenderName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('CallerSender')}
                onSearch={this.handleSearch}
                onPopupScroll={this.handleScroll}
                optionFilterProp="children"
                showSearch
                style={{ marginTop: '10px' }}
                placeholder="Select Case Manager"
              >
                {staffAllList &&
                  staffAllList.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.CallerSender),
                  label: toJS(conversationParam.CallerSenderName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('CallerSender')}
                placeholder="Select Client Officer"
                style={{ marginTop: '10px' }}
              >
                {arrstaffList &&
                  arrstaffList.itemList.map(i => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.fullName}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Receiver"
            required
            validateStatus={!conversationParam.Receiver ? 'error' : null}
            help={!conversationParam.Receiver ? 'This field is required' : null}
          >
            <Radio.Group
              value={conversationParam.ReceiverType}
              onChange={AddEditStore.handleFieldChangeConversation('ReceiverType')}
            >
              <Radio value={1}>Case Manager</Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            {conversationParam.ReceiverType === 1 ? (
              <Select
                size="large"
                onChange={AddEditStore.handleFieldChangeConversation('Receiver')}
                onSearch={this.handleSearch}
                onPopupScroll={this.handleScroll}
                optionFilterProp="children"
                showSearch
                labelInValue
                value={{
                  key: toJS(conversationParam).Receiver,
                  label: toJS(conversationParam).ReceiverName,
                }}
                style={{ marginTop: '10px' }}
                placeholder="Select Case Manager"
              >
                {staffAllList &&
                  staffAllList.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.Receiver),
                  label: toJS(conversationParam.ReceiverName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('Receiver')}
                style={{ marginTop: '10px' }}
                placeholder="Select Client Officer"
              >
                {arrstaffList &&
                  arrstaffList.itemList.map(i => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.fullName}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ConversationModalForm;
