import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import TableToolbar from '../../shared/tableToolbar';
import IMESearchBody from './IMESearchTable';
import CMReportStore from './CMReportStore';
import { toJS } from 'mobx';

const { $ } = window;
const optionsFilter = [
  {
    text: `None`,
    value: `none`,
  },
  {
    text: `Today`,
    value: `today`,
  },
  {
    text: `This week`,
    value: `tWeek`,
  },
  {
    text: `Last week`,
    value: `lWeek`,
  },
  {
    text: `Last 7 days`,
    value: `l7days`,
  },
  {
    text: `This month`,
    value: `tMonth`,
  },
  {
    text: `Last month`,
    value: `lMonth`,
  },
  {
    text: `Last 30 days`,
    value: `l30days`,
  },
];

@observer
class IMESearch extends React.Component {
  state = {
    opt: `today`,
  };
  renderOptions = () => {
    return optionsFilter.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  convertValueToLabel = value => {
    const label = optionsFilter
      .filter(i => i.value === value)
      .map(opt => ({
        text: opt.text,
        value: opt.value,
      }));
    return label[0].text;
  };

  onFilterChange = value => {
    switch (value) {
      case `today`:
        return {
          timeFrom: moment()
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };
      case `tWeek`:
        return {
          timeFrom: moment()
            .startOf('isoWeek')
            .toDate(),
          timeTo: moment()
            .endOf('isoWeek')
            .toDate(),
        };
      case `lWeek`:
        return {
          timeFrom: moment()
            .subtract(7, 'day')
            .startOf('isoWeek')
            .toDate(),
          timeTo: moment()
            .subtract(7, 'day')
            .endOf('isoWeek')
            .toDate(),
        };

      case `l7days`:
        return {
          timeFrom: moment()
            .subtract(7, 'day')
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };

      case `tMonth`:
        return {
          timeFrom: moment()
            .startOf('month')
            .toDate(),
          timeTo: moment()
            .endOf('month')
            .toDate(),
        };

      case `lMonth`:
        return {
          timeFrom: moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          timeTo: moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate(),
        };

      case `l30days`:
        return {
          timeFrom: moment()
            .subtract(30, 'day')
            .startOf('day')
            .toDate(),
          timeTo: moment()
            .endOf('day')
            .toDate(),
        };

      default:
        // QuickFilterValues.NONE
        return {
          timeFrom: ``,
          timeTo: ``,
        };
    }
  };

  handleChangeDropdown = (_, { value }) => {
    const { timeFrom, timeTo } = this.onFilterChange(value);
    const fromDate = timeFrom ? moment(timeFrom).format('DD/MM/YYYY') : timeFrom;
    const toDate = timeTo ? moment(timeTo).format(`DD/MM/YYYY`) : timeTo;
    this.setState({ opt: value }, () => {
      CMReportStore.refetchIMESearch({
        fromToIMESearch: value === `none` ? `` : fromDate + ' - ' + toDate,
      });
    });
  };

  handleChangeFromToCaseManager = (_, { value }) => {
    CMReportStore.setFieldsValue({ fromToIMESearch: value });
  };

  handleExportSearchResult = () => {
    const { keywordIMESearch } = CMReportStore;
    const { fromDate, toDate } = CMReportStore.getDateRangeIMESearch();
    const fromDate_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const toDate_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    window.open(
      `/api/smartsearchhistory/export-search-result?${$.param({
        searchedFrom: fromDate_,
        searchedTo: toDate_,
        searcherName: keywordIMESearch,
        orderBy: `SearchedAtUtc`,
        orderAsc: false,
      })}`,
      '_blank',
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    CMReportStore.fetchIMESearch();
  };
  handleChangeInput = (_, { value }) => {
    CMReportStore.setFieldsValue({ keywordIMESearch: value });
  };

  handleClear = () => {
    const { timeFrom, timeTo } = this.onFilterChange('today');
    const fromDate = timeFrom ? moment(timeFrom).format('DD/MM/YYYY') : timeFrom;
    const toDate = timeTo ? moment(timeTo).format(`DD/MM/YYYY`) : timeTo;
    this.setState({ opt: 'today' }, () => {
      CMReportStore.refetchIMESearch({
        fromToIMESearch: fromDate + ' - ' + toDate,
      });
    });
    CMReportStore.refetchIMESearch({});
  };
  handleRefresh = () => {
    CMReportStore.refetchIMESearch({ dataIMESearch: null });
  };

  renderTotalItem = () => {
    const { dataIMESearch, loadingIMESearch } = CMReportStore;
    const totalItem = Array.isArray(toJS(dataIMESearch)) ? toJS(dataIMESearch).length : 0;
    return `${loadingIMESearch ? '(0)' : `(${totalItem})`}`;
  };

  render() {
    const { fromToIMESearch } = CMReportStore;
    const { opt } = this.state;
    return (
      <React.Fragment>
        <form>
          <TableToolbar.Container style={{ marginTop: `5px` }}>
            <TableToolbar.Left as="div">
              <TableToolbar.Input
                className="Toolbar-input"
                placeholder={'Search User'}
                onChange={this.handleChangeInput}
              />
              <TableToolbar.RangePicker
                placeholder="From Date - To Date"
                className="Toolbar-range-picker"
                onChange={this.handleChangeFromToCaseManager}
                value={fromToIMESearch}
              />
              <TableToolbar.Filter
                value={this.convertValueToLabel(opt)}
                label={`Status`}
                options={this.renderOptions()}
                name="search"
                onChange={this.handleChangeDropdown}
              />
              <TableToolbar.SubmitButton onClick={this.handleSubmit} className="px-2">
                Search
              </TableToolbar.SubmitButton>
              <TableToolbar.HighLightButton
                className="negative"
                style={{ marginLeft: `5px` }}
                onClick={this.handleClear}
              >
                Clear
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton className="blue" style={{ marginLeft: `5px` }} onClick={this.handleRefresh}>
                Refresh
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                className="positive"
                style={{ marginLeft: `5px` }}
                onClick={this.handleExportSearchResult}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
          <TableToolbar.Container style={{ marginTop: `10px` }}>
            <TableToolbar.Left as="div">
              <span style={{ marginLeft: `5px` }}>
                <b className="title-table">Specialist Search History {this.renderTotalItem()}</b>
              </span>
            </TableToolbar.Left>
          </TableToolbar.Container>
        </form>
        <IMESearchBody />
      </React.Fragment>
    );
  }
}
export default IMESearch;
