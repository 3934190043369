import createQuery from '../utils/createQuery';

export const imeStatus = {
  CASE_CREATED: 'CaseCreated',
  IME_REQUESTED: 'Booking Inquiry Received',
  APPOINTMENT_CONFIRMED: 'Appointment Confirmed',
  PAPERWORK_RECEIVED: 'Paperwork Received',
  ATTENDED: 'Attended',
  NOT_ATTEND: 'Did Not Attend',
  DICTATION_RECEIVED: 'Dictation Received',
  REPORT_DRAFT_RECEIVED: 'Report Draft Received',
  REPORT_RETURNED_FROM_DOCTOR: 'Report Returned From Doctor',
  REPORT_COMPLETED: 'Report Completed',
  CANCELLED: 'Cancelled-No Fee',
  IME_CANCELLED_WITH_FEE: 'Late Cancellation Fee',
  CANCELLED_SPECIALIST_TBP: 'Cancelled - Specialist TBP',
  CLIENT_INVOICE: 'ClientInvoiced',
  DOCTOR_INVOICE: 'DoctorInvoiced',
  BILLED: 'Billed',
  FULLY_PAID: 'Fully Paid',
  OLD_BILLED: 'Old-Billed',
};

export const frStatusEnum = {
  CASE_CREATED: 0,
  REPORT_REVIEW_REQUESTED: 1,
  REQUEST_SENT_PROVIDER: 2,
  DICTATION_RECEIVED: 3,
  REPORT_DRAFT_RECEIVED: 4,
  REPORT_RETURNED_FROM_DOCTOR: 5,
  REPORT_COMPLETED: 6,
  CANCELLED_NO_FEE: 7,
  CANCELLED_WITH_FEE: 8,
  CLIENT_INVOICE: 9,
  DOCTOR_INVOICE: 10,
  SERVICE_BILLED: 11,
  SERVICE_PAID: 12,
};

export const frStatus = {
  CASE_CREATED: 'CaseCreated',
  REPORT_REVIEW_REQUESTED: 'ReportReviewRequested',
  REQUEST_SENT_PROVIDER: 'RequestSentProvider',
  DICTATION_RECEIVED: 'DictationReceived',
  REPORT_DRAFT_RECEIVED: 'ReportDraftReceived',
  REPORT_RETURNED_FROM_DOCTOR: 'ReportReturnedFromDoctor',
  REPORT_COMPLETED: 'ReportCompleted',
  CANCELLED_NO_FEE: 'CancelledNoFee',
  CANCELLED_WITH_FEE: 'CancelledWithFee',
  CLIENT_INVOICE: 'ClientInvoiced',
  DOCTOR_INVOICE: 'DoctorInvoiced',
  SERVICE_BILLED: 'ServiceBilled',
  SERVICE_PAID: 'ServicePaid',
};

export const suppStatusEnum = {
  CASE_CREATED: 0,
  REPORT_REVIEW_REQUESTED: 1,
  REQUEST_SENT_TO_PROVIDER: 2,
  DICTATION_RECEIVED: 3,
  REPORT_DRAFT_RECEIVED: 4,
  REPORT_RETURNED_FROMDOCTOR: 5,
  CLARIFICATION_NO_FEE: 6,
  REPORT_COMPLETED: 7,
  CANCELLED_NO_FEE: 8,
  CANCELLED_WITH_FEE: 9,
  CLIENT_INVOICE: 10,
  DOCTOR_INVOICE: 11,
  SERVICE_BILLED: 12,
  SERVICE_PAID: 13,
};

export const suppStatus = {
  CASE_CREATED: 'CaseCreated',
  REPORT_REVIEW_REQUESTED: 'ReportReviewRequested',
  REQUEST_SENT_TO_PROVIDER: 'RequestSenttoProvider',
  DICTATION_RECEIVED: 'DictationReceived',
  REPORT_DRAFT_RECEIVED: 'ReportDraftReceived',
  REPORT_RETURNED_FROMDOCTOR: 'ReportReturnedFromDoctor',
  CLARIFICATION_NO_FEE: 'ClarificationNoFee',
  REPORT_COMPLETED: 'ReportCompleted',
  CANCELLED_NO_FEE: 'CancelledNoFee',
  CANCELLED_WITH_FEE: 'CancelledWithFee',
  CLIENT_INVOICE: 'ClientInvoiced',
  DOCTOR_INVOICE: 'DoctorInvoiced',
  SERVICE_BILLED: 'ServiceBilled',
  SERVICE_PAID: 'ServicePaid',
};

export const crStatusEnum = {
  CN_Case_Created: 9,
  CN_Request_Received: 0,
  CN_Request_Docs_Sent_To_Provider: 1,
  Prepayment_Required: 2,
  Prepayment_Paid: 3,
  CN_Files_Received: 4,
  Service_Billed: 13,
  Service_Paid: 14,
  Cancelled_No_Charge: 7,
  Cancelled_With_Fee: 8,
  On_Hold: 10,
  Client_Invoiced: 11,
};

export const crStatus = {
  CN_CASE_CREATED: 'CNCaseCreated',
  CN_REQUEST_RECEIVED: 'CNRequestReceived',
  CN_REQUEST_DOCS_SENT_TO_PROVIDER: 'CNRequestDocsSentToProvider',
  PREPAYMENT_REQUIRED: 'PrepaymentRequired',
  PREPAYMENT_PAID: 'PrepaymentPaid',
  CN_FILES_RECEIVED: 'CNFilesReceived',
  SERVICE_BILLED: 'ServiceBilled',
  SERVICE_PAID: 'ServicePaid',
  CANCELLED_NO_CHARGE: 'CancelledNoCharge',
  CANCELLED_WITH_FEE: 'CancelledWithFee',
  ON_HOLD: 'OnHold',
  CLIENT_INVOICE: 'ClientInvoiced',
};

export const mnStatusEnum = {
  CASE_CREATED: 0,
  REQUEST_RECEIVED: 1,
  REQUEST_SENT_PROVIDER: 2,
  CLOSE_WITH_BOOKING: 3,
  CLOSE_NO_BOOKING: 4,
  ON_HOLD: 5,
};

export const mnStatus = {
  CASE_CREATED: 'CaseCreated',
  REQUEST_RECEIVED: 'RequestReceived',
  REQUEST_SENT_PROVIDER: 'RequestSentProvider',
  CLOSE_WITH_BOOKING: 'CloseWithBooking',
  CLOSE_NO_BOOKING: 'CloseNoBooking',
};

export const IME_CaseProgress = createQuery('/Default/GetCaseProgressConfig?type=ime');
export const FR_CaseProgress = createQuery('/Default/GetCaseProgressConfig?type=fr');
export const SUPP_CaseProgress = createQuery('/Default/GetCaseProgressConfig?type=supp');
export const CR_CaseProgress = createQuery('/Default/GetCaseProgressConfig?type=cr');
export const MN_CaseProgress = createQuery('/Default/GetCaseProgressConfig?type=mn');

export const GetIMEStatusText = status => IME_CaseProgress?.data?.itemList?.filter(i => i.Key === status)[0]?.Label;
export const GetFRStatusText = status => FR_CaseProgress?.data?.itemList?.filter(i => i.Key === status)[0]?.Label;
export const GetSUPPStatusText = status => SUPP_CaseProgress?.data?.itemList?.filter(i => i.Key === status)[0]?.Label;
export const GetCRStatusText = status => CR_CaseProgress?.data?.itemList?.filter(i => i.Key === status)[0]?.Label;
export const GetMNStatusText = status => MN_CaseProgress?.data?.itemList?.filter(i => i.Key === status)[0]?.Label;
