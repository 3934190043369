import { Spin } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import React from 'react';
import { Button, Divider, Header, Table, Checkbox } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import ModalDoctorToolbar from './ModalDoctorToolbar';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import customFetch from '../../../utils/customFetch';
import ModalConfirm from './ModalConfirm';
import ModalAlert from './ModalAlert';
import DoctorClinicStore from './DoctorClinicStore';

@observer
class ModalDoctorClinic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clinics: [],
      doctorId: props.modalParams.clinicId,
      openModalConfirm: false,
      message: `All unsaved changes will be discarded. Do you want to continue?`,
      alert: false,
      openModalAlert: false,
      selectAll: false,
    };
  }

  componentDidMount() {
    this.setState({
      clinics: DoctorClinicStore.clinics,
    });
  }

  handlePageClick = page => {
    DoctorClinicStore.refetch({ curPage: page });
  };

  onChecked = id => () => {
    if (this.state.clinics.indexOf(id) !== -1) {
      this.setState({
        clinics: this.state.clinics.filter(i => i !== id),
      });
    } else {
      this.setState(state => {
        const clinics = [...state.clinics, id];
        return {
          clinics,
        };
      });
    }
  };

  renderCheckBoxValue = id => {
    if (this.state.clinics.indexOf(id) !== -1) return true;
    return false;
  };

  renderCheckBoxSelectedValue = () => {
    let clinics = this.state.clinics.map(i => i);
    let clinics_ = DoctorClinicStore.data ? DoctorClinicStore.data.itemList.map(i => i.ID) : [];
    let selectAll = false;
    clinics_.forEach(i => {
      if (clinics.indexOf(i) >= 0) {
        selectAll = true;
      }
      if (clinics.indexOf(i) === -1) {
        selectAll = false;
      }
    });
    return selectAll;
  };

  handleSelectedAll = () => {
    let clinics = this.state.clinics.map(i => i);
    let clinics_ = DoctorClinicStore.data.itemList.map(i => i.ID);
    let selectedAll = this.renderCheckBoxSelectedValue();
    if (!selectedAll) {
      clinics_.forEach(i => {
        if (this.state.clinics.indexOf(i) === -1) {
          this.setState(state => {
            const clinics = [...state.clinics, i];
            return {
              clinics,
            };
          });
        }
      });
    } else {
      clinics_.forEach(i => {
        if (this.state.clinics.indexOf(i) >= 0) {
          clinics = clinics.filter(clinic => clinic !== i);
        }
      });
      this.setState(state => (state.clinics = clinics));
    }
  };

  renderCheckBoxSelectAll = () => {
    return <Checkbox onChange={this.handleSelectedAll} checked={this.renderCheckBoxSelectedValue()} />;
  };

  renderTable = () =>
    DoctorClinicStore.data.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <Checkbox checked={this.renderCheckBoxValue(record.ID)} onChange={this.onChecked(record.ID)} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.Name}</Table.Cell>
        <Table.Cell>{record.FullAddress}</Table.Cell>
      </Table.Row>
    ));

  handleCancel = () => {
    this.setState(state => {
      state.openModalConfirm = false;
      this.props.onCancel();
      DoctorClinicStore.setFieldsValue({ clinics: null });
      return state;
    });
  };

  fetchDoctorByList = doctorId => {
    customFetch('/Clinic/GetClinicByIdList', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(this.state.clinics.map(i => i)),
    }).then(
      action(data =>
        DoctorClinicStore.setFieldsValue({
          data,
          loading: false,
          totalPage: data.sumPage || DoctorClinicStore.totalPage,
          curPage: 1,
          filter: `selected`,
        }),
      ),
    );
  };

  handleUpdateClinicDoctor = () => async () => {
    const { doctorId, clinics } = this.state;
    if (!clinics.length) {
      this.setState({ openModalAlert: true });
    } else {
      DoctorClinicStore.setFieldsValue({ loading: true });
      try {
        await customFetch('/MedicalDoctor/UpdateDoctorClinic', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            clinics: clinics.map(i => i),
            doctorId: doctorId,
          }),
        });
        this.fetchDoctorByList(doctorId);
      } finally {
        DoctorClinicStore.setFieldsValue({ loading: false });
      }
    }
  };

  onOpenConfirm = () => {
    this.setState({ openModalConfirm: true });
  };

  onCancelConfirm = () => {
    this.setState({ openModalConfirm: false });
  };

  onCancelAlert = () => {
    this.setState({ openModalAlert: false });
  };

  render() {
    const { loading, data, curPage, totalPage } = DoctorClinicStore;
    const columns = [{ title: this.renderCheckBoxSelectAll() }, { title: 'Clinic Name' }, { title: 'Address' }];
    const colSpan = columns.length;
    const { open } = this.props;
    const { record } = this.props.modalParams;
    const { openModalConfirm, message } = this.state;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={this.onOpenConfirm}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleUpdateClinicDoctor()}>
              Save
            </Button>
            <Button className="negative" onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-LoginHistory">
          <Header size="small">{`${record.FirstName} ${record.LastName} Clinics`}</Header>
          <Divider />
          <ModalDoctorToolbar />
          <ModalConfirm
            open={openModalConfirm}
            message={message}
            onCancel={this.onCancelConfirm}
            onOk={this.handleCancel}
          />
          <ModalAlert
            open={this.state.openModalAlert}
            message={'Error occured while saving data.Please report this to system administrator and try again later.'}
            onCancel={this.onCancelAlert}
          />
          {loading && !data ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled className={cx({ disabled: loading })}>
              <TableHeader columns={columns} />
              <Table.Body>
                {data.itemList.length ? this.renderTable() : <TableEmptyMessage colSpan={colSpan} />}
              </Table.Body>
              <TableFooterPagination
                colSpan={colSpan}
                currentPage={curPage}
                totalPage={totalPage}
                onPageClick={this.handlePageClick}
              />
            </Table>
          )}
        </div>
      </Modal>
    );
  }
}

export default ModalDoctorClinic;
