import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import IMEStore from './IMEStore';
import ModalActionNotes from './ModalActionNotes';
import ModalCaseProgress from './ModalCaseProgress';
import ModalViewSuppRequest from './ModalViewSuppRequest';
import ModalConfirmNewUI from './ModalConfirmNewUI';

function IMEModal() {
  const { modalType } = IMEStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...IMEStore} onCancel={IMEStore.toggleModal(false)} />;
  } else if (modalType === 'viewSuppRequest') {
    return <ModalViewSuppRequest {...IMEStore} onCancel={IMEStore.toggleModal(false)} />;
  } else if (modalType === 'notes') {
    return <ModalActionNotes />;
  } else if (modalType === 'confirmNewUI') {
    return <ModalConfirmNewUI {...IMEStore} onCancel={IMEStore.toggleModal(false)} />;
  } else {
    return <ModalCaseProgress />;
  }
}

export default IMEModal;
