import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStore from './Store';
import ModalDuplicate from './ModalDuplicate';
import ModalDisableUser from './ModalDisableUser';
import ModalStatusHistory from './ModalStatusHistory';

function AddEditModal() {
  const { modalType } = AddEditStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'checkDuplicate') {
    return <ModalDuplicate {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'disable') {
    return <ModalDisableUser {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'viewStatusHistory') {
    return <ModalStatusHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
}

export default AddEditModal;
