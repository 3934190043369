import React from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react';
import { Spin, Form, Select, Tooltip, notification } from 'antd';
import { action, autorun } from 'mobx';
import Modal from '../../shared/Modal';
import ServiceFeeStore from './Store';
import { searchAMAFeeCode, searchAMACalculationRule, addAMACalculationRule } from './Service';

@observer
class CreateCalculationRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ApplyForRange: true,
      AMAFeeId: undefined,
      FromAMAFeeId: undefined,
      ToAMAFeeId: undefined,
      CalculationId: undefined,
      IsClear: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Promise.all([searchAMAFeeCode(''), searchAMACalculationRule({})]).then(([respAMA, respCalculationRule]) => {
      if (respAMA.status === 'success') {
        ServiceFeeStore.setListAMACodeFrom(respAMA.itemList);
        ServiceFeeStore.setListAMACodeTo(respAMA.itemList);
      } else {
        ServiceFeeStore.setListAMACodeFrom([]);
        ServiceFeeStore.setListAMACodeTo([]);
      }
      if (respCalculationRule.status === 'success') {
        respCalculationRule.itemList.unshift({ Id: -1, CalculationName: 'None' });
        ServiceFeeStore.setListCalculationRule(respCalculationRule.itemList);
      } else {
        ServiceFeeStore.setListCalculationRule([]);
      }
      this.startReaction();
      this.setState({ loading: false });
    });
  }

  startReaction = () => {
    this.reactionValidate = autorun(() => {
      const data = this.state;
      var checkFromAMAFeeRequired = false;
      const { FromAMAFeeId, CalculationId } = data;
      if (!FromAMAFeeId) {
        checkFromAMAFeeRequired = true;
        ServiceFeeStore.addErrors('FromAMAFeeId', 'This field is required');
      } else {
        checkFromAMAFeeRequired = false;
        ServiceFeeStore.removeError('FromAMAFeeId');
      }
      if (!CalculationId) {
        ServiceFeeStore.addErrors('CalculationId', 'This field is required');
      } else {
        ServiceFeeStore.removeError('CalculationId');
      }
      const { lstAMACodeFrom, lstAMACodeTo } = ServiceFeeStore;
      var fromCode = lstAMACodeFrom.filter(i => i.Id === data.FromAMAFeeId);
      var toCode = lstAMACodeTo.filter(i => i.Id === data.ToAMAFeeId);
      if (fromCode[0].AMACode !== toCode[0].AMACode) {
        if (fromCode && fromCode.length > 0 && toCode && toCode.length > 0) {
          var prefixFromCode = fromCode[0].AMACode.substring(0, 2);
          var prefixToCode = toCode[0].AMACode.substring(0, 2);
          var subfixFromCode = fromCode[0].AMACode.substring(2, fromCode[0].AMACode.length);
          var subfixToCode = toCode[0].AMACode.substring(2, toCode[0].AMACode.length);
          if (prefixFromCode !== prefixToCode) {
            //if(!checkFromAMAFeeRequired) ServiceFeeStore.addErrors('FromAMAFeeId', 'From AMA code and o AMA code must be same prefix');
            ServiceFeeStore.addErrors('ToAMAFeeId', 'To AMA code must be same prefix with from AMA code');
          } else if (parseInt(subfixFromCode) >= parseInt(subfixToCode)) {
            //if(!checkFromAMAFeeRequired) ServiceFeeStore.addErrors('FromAMAFeeId', 'To AMA code must be greater than from AMA code');
            ServiceFeeStore.addErrors('ToAMAFeeId', 'To AMA code must be greater than from AMA code');
          } else {
            if (!checkFromAMAFeeRequired) ServiceFeeStore.removeError('FromAMAFeeId');
            ServiceFeeStore.removeError('ToAMAFeeId');
          }
        }
      }
    });
  };

  // componentWillUnmount() {
  //   this.handleResetDataModal();
  // }

  onCancel = () => {
    this.props.onCancel();
  };

  handleChangeFieldCheckbox = fieldName =>
    action(event => {
      const value = event.target.checked;
      this.setState({
        ...this.state,
        [fieldName]: value,
      });
    });

  @action handleSearchAMAFeeCode = type =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchAMAFeeCode(value).then(
          action(resp => {
            if (type === 1) {
              ServiceFeeStore.setListAMACodeFrom(resp.itemList);
            } else {
              ServiceFeeStore.setListAMACodeTo(resp.itemList);
            }
          }),
        );
      }
    });

  @action handleSearchCalculationRule = () =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        var params = { keyword: value };
        searchAMACalculationRule(params).then(
          action(resp => {
            ServiceFeeStore.setListCalculationRule(resp.itemList);
          }),
        );
      }
    });

  @action handleDropdowlistValue = fieldName =>
    action(event => {
      const value = event;
      this.setState({
        ...this.state,
        [fieldName]: value,
      });
    });

  handleSaveCalculationRule = type => {
    console.log(1111);
    var saveData = this.state;
    if (type === 2) saveData.IsClear = true;
    const { lstAMACodeFrom, lstAMACodeTo } = ServiceFeeStore;
    var fromCode = lstAMACodeFrom.filter(i => i.Id === saveData.FromAMAFeeId);
    var toCode = lstAMACodeTo.filter(i => i.Id === saveData.ToAMAFeeId);
    if (fromCode && fromCode.length > 0 && toCode && toCode.length > 0) {
      if (fromCode[0].AMACode === toCode[0].AMACode) {
        saveData.ApplyForRange = false;
        saveData.AMAFeeId = fromCode[0].Id;
      } else {
        saveData.ApplyForRange = true;
        var prefixFromCode = fromCode[0].AMACode.substring(0, 2);
        var prefixToCode = toCode[0].AMACode.substring(0, 2);
        var subfixFromCode = fromCode[0].AMACode.substring(2, fromCode[0].AMACode.length);
        var subfixToCode = toCode[0].AMACode.substring(2, toCode[0].AMACode.length);
        if (prefixFromCode !== prefixToCode) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Data invalid, please check again.',
            duration: 3,
          });
          return;
        } else if (parseInt(subfixFromCode) >= parseInt(subfixToCode)) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Data invalid, please check again.',
            duration: 3,
          });
          return;
        }
        saveData.FromAMAFeeCode = fromCode[0].AMACode;
        saveData.ToAMAFee = toCode[0].AMACode;
      }
    } else if (fromCode && fromCode.length > 0) {
      saveData.ApplyForRange = false;
      saveData.AMAFeeId = fromCode[0].Id;
    } else {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Data invalid, please check again.',
        duration: 3,
      });
      return;
    }
    if (!saveData.CalculationId) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Data invalid, please check again.',
        duration: 3,
      });
      return;
    }
    addAMACalculationRule(saveData).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Save successfully',
          duration: 3,
        });
        this.props.onCancel();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Data invalid, please check again.',
          duration: 3,
        });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { open, lstAMACodeFrom, lstAMACodeTo, lstCalculationRule } = ServiceFeeStore;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={() => this.handleSaveCalculationRule(1)}>
              <Icon name="save" /> Save
            </Button>
            <Button color="blue" onClick={() => this.handleSaveCalculationRule(2)}>
              <Icon name="save" /> Clear Rule
            </Button>
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title="Applicable Item Code Ranges"
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Form name="conversation" className="conversation-form">
              <Form.Item
                required
                validateStatus={ServiceFeeStore.errors['FromAMAFeeId'] ? 'error' : null}
                help={ServiceFeeStore.errors['FromAMAFeeId'] ? ServiceFeeStore.errors['FromAMAFeeId'] : null}
                label="AMA Item Code From"
              >
                <Select
                  value={this.state.FromAMAFeeId || undefined}
                  onChange={this.handleDropdowlistValue('FromAMAFeeId')}
                  onSearch={this.handleSearchAMAFeeCode(1)}
                  showSearch
                  filterOption="children"
                >
                  {lstAMACodeFrom &&
                    lstAMACodeFrom.map(({ Id, AMACode, Description }) => (
                      <Select.Option value={Id} key={Id}>
                        <Tooltip title={Description} placement="right">
                          <span
                            style={{
                              width: '175',
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {AMACode}
                          </span>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="AMA Item Code To"
                validateStatus={ServiceFeeStore.errors['ToAMAFeeId'] ? 'error' : null}
                help={ServiceFeeStore.errors['ToAMAFeeId'] ? ServiceFeeStore.errors['ToAMAFeeId'] : null}
              >
                <Select
                  value={this.state.ToAMAFeeId || undefined}
                  onChange={this.handleDropdowlistValue('ToAMAFeeId')}
                  onSearch={this.handleSearchAMAFeeCode(2)}
                  showSearch
                  filterOption="children"
                >
                  {lstAMACodeTo &&
                    lstAMACodeTo.map(({ Id, AMACode, Description }) => (
                      <Select.Option value={Id} key={Id}>
                        <Tooltip title={Description} placement="right">
                          <span
                            style={{
                              width: '175',
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {AMACode}
                          </span>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                required
                validateStatus={ServiceFeeStore.errors['CalculationId'] ? 'error' : null}
                help={ServiceFeeStore.errors['CalculationId'] ? ServiceFeeStore.errors['CalculationId'] : null}
                label="Calculation Rule"
              >
                <Select
                  value={this.state.CalculationId || undefined}
                  onChange={this.handleDropdowlistValue('CalculationId')}
                  onSearch={this.handleSearchCalculationRule()}
                  showSearch
                  filterOption="children"
                >
                  {lstCalculationRule &&
                    lstCalculationRule.map(({ Id, CalculationName, CalculationRules }) => (
                      <Select.Option value={Id} key={Id}>
                        <Tooltip title={CalculationRules} placement="right">
                          <span
                            style={{
                              width: '175',
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {CalculationName}
                          </span>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    );
  }
}

export default CreateCalculationRule;
