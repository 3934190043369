import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { observer } from 'mobx-react';

import Modal from '../../../shared/Modal';
import { TableHeader, TableEmptyMessage } from '../../../shared/table';
import { formatDate } from '../../../../utils/functions';

import AddEditStore from './DoctorDetailsStore';
import { toJS } from 'mobx';

const columns = [
  { title: '#' },
  { title: 'Old Value' },
  { title: 'New Value' },
  { title: 'Updated Time' },
  { title: 'Updated By' },
];

@observer
class FieldChangeHistory extends React.Component {
  renderDate = (datestr) => {
    if (datestr) {
      const date = formatDate(datestr, true);
      return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
    }
    return '';
  };

  renderTableEmpty = columns => {
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          <TableEmptyMessage colSpan={columns.length} />
        </Table.Body>
      </Table>
    );
  };

  renderTableCell = (record, idx) => {
    if (this.checkIsDateString(record) !== 'Invalid date') {
      return <Table.Cell key={idx}>{this.checkIsDateString(record)}</Table.Cell>;
    } else {
      return <Table.Cell key={idx}>{record}</Table.Cell>;
    }
  };

  renderTableData = dataFieldChange => {
    if (Array.isArray(dataFieldChange)) {
      if (dataFieldChange.length === 0) {
        return this.renderTableEmpty(columns);
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataFieldChange.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{idx + 1}</Table.Cell>
                <Table.Cell>{record.OldValue}</Table.Cell>
                <Table.Cell>{record.NewValue}</Table.Cell>
                <Table.Cell>{this.renderDate(record.Updated)}</Table.Cell>
                <Table.Cell>{record.UpdateBy}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return this.renderTableEmpty(columns);
    }
  };
  render() {
    const { title, dataFieldChange } = AddEditStore.modalParams;
    const { open } = AddEditStore;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={AddEditStore.toggleModal(false)}
        title={title}
      >
        {this.renderTableData(toJS(dataFieldChange))}
      </Modal>
    );
  }
}
export default FieldChangeHistory;
