import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, Divider, notification } from 'antd';
import { Table, Icon } from 'semantic-ui-react';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import * as api from '../../../stores/api';
import ShortcutTable from './ShortcutTable';
import Store from './clinicalNoteStore';
import uploadStore from './UploadFile/UploadStore';
import customFetch from '../../../utils/customFetch';
import { toJS } from 'mobx';
import moment from 'moment';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '@utils/constants';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';
import cx from 'classnames';

const shortcutOptionsTypist = [
  {
    key: 0,
    title: 'Download Dictations',
    type: 'Completion',
  },
  {
    key: 1,
    title: 'Upload Typed Reports',
    type: 'Completion',
  },
];

const shortcutOptions = [
  {
    key: 0,
    title: 'Update Claim Details',
    type: 'CaseDetails',
  },
  {
    key: 1,
    title: 'View Appointment Details',
    type: 'Appointment',
  },
  {
    key: 2,
    title: 'Upload Case Documents',
    type: 'Appointment',
  },
  {
    key: 3,
    title: 'Cancel Appointment',
    type: 'Attendance',
  },
  {
    key: 4,
    title: 'View Completed Reports',
    type: 'Completion',
  },
  {
    key: 5,
    title: 'View Correspondence',
    type: 'Corespondence',
  },
  {
    key: 6,
    title: 'View Invoice',
    type: 'Invoices',
  },
  {
    key: 7,
    title: 'View Supp Requests',
    type: '',
  },
  {
    key: 8,
    title: 'Request Supp Report',
    type: '',
  },
];

const shortcutOptionsDR = [
  {
    key: 0,
    title: 'View Claim Details',
    type: 'CaseDetails',
  },
  {
    key: 1,
    title: 'View Case Documents',
    type: 'Appointment',
  },
  {
    key: 2,
    title: 'Update Patient Attendance',
    type: 'Attendance',
  },
  {
    key: 3,
    title: 'Upload Invoices',
    type: 'UploadInvoices',
  },
  {
    key: 4,
    title: 'Upload Dictations & Reports',
    type: 'Completion',
  },
  {
    key: 5,
    title: 'View Correspondence',
    type: 'Corespondence',
  },
];
class TablesIME extends React.Component {
  openCaseDetail = (record, type) => () => {
    const id = record.Id || record.id;
    const caseNo = record.magRefNo || record.AssessmentNumber || record.MagRefNo;

    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      if (api.isTypist() && type === 'Completion') {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=4`);
      } else {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=0`);
      }
    });
  };

  openModalNote = options => {
    return Store.toggleModal(true, options);
  };

  // openModalNote = (record) => {
  //   return Store.toggleModal(true, {
  //     modalType: 'notes',
  //     id: record.Id,
  //     caseNo: record.AssessmentNumber,
  //     client: record.Client,
  //   })();
  // }

  convertEmailCreatedAt = email => {
    const email_ = email ? email.replace(/^(.+)@(.+)$/g, '$1') : email;
    return email_;
  };

  renderVideoSessionDetailForCM = (Id, record) => {
    return (
      <React.Fragment>
        <Table.Cell
          selectable
          className="Cell-link"
          style={{ textAlign: 'center' }}
          onClick={() => this.openSessionDetail(Id, record)}
        >
          <Link to="#" onClick={() => this.openSessionDetail(Id, record)}>
            {record.appointmentDate || record.AppointmentDate || record.BookingDate
              ? moment(
                  formatDate(record.appointmentDate || record.AppointmentDate || record.BookingDate, true),
                  'DD MMM YYYY, HH:mm',
                ).format('DD/MM/YYYY - HH:mm')
              : 'N/A'}
          </Link>
        </Table.Cell>
      </React.Fragment>
    );
  };
  renderMultiAppointments = appointments => {
    return (
      <React.Fragment>
        <div style={{ minWidth: '220px' }}>
          <span style={{ display: 'inline-block' }}>Multi-Option:</span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {appointments.map((m, idx) =>
              m.StartTime ? (
                <span key={idx} style={{ display: 'inline-block' }}>
                  {moment(m.StartTime).format('DD/MM/YYYY - HH:mm')}
                  {m.EndTime ? ' - ' + moment(m.EndTime).format('HH:mm') : ''}
                </span>
              ) : (
                ''
              ),
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
  renderVideoSessionDetailForAdmin = (Id, record) => {
    var color = '#1fc0da';
    if (record.Status === api.imeStatus.CANCELLED || record.Status === api.imeStatus.IME_CANCELLED_WITH_FEE) {
      color = 'red';
    } else if (record.Status === api.imeStatus.CASE_CREATED || record.Status === api.imeStatus.IME_REQUESTED) {
      return (
        <React.Fragment>
          <Table.Cell selectable style={{ textAlign: 'center', color: 'black' }}>
            {record.HasMultiAppointment
              ? this.renderMultiAppointments(record.Appointments)
              : record.DateFrom
              ? moment(record.DateFrom).format('DD/MM/YYYY - HH:mm')
              : ''}
            <br />
            {record.AssessmentMethod ? <Icon name="video camera" /> : ''}{' '}
            {record.EassessClaimantLocationChecklistReceived ? <Icon name="check" /> : ''}
            {record.Interpreter && (
              <React.Fragment>
                {record.InterpretationStatus === 1 ? (
                  <span style={{ color: '#1fc0da' }}>
                    Interpreter: <Icon name="check" />
                  </span>
                ) : (
                  ''
                )}
                {record.InterpretationStatus === -1 ? <span style={{ color: 'red' }}>Interpreter: X</span> : ''}
              </React.Fragment>
            )}
          </Table.Cell>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Table.Cell
          selectable
          style={{ textAlign: 'center', color: color }}
          onClick={() => this.openSessionDetail(Id, record)}
        >
          <Link to="#" onClick={() => this.openSessionDetail(Id, record)}>
            {record.HasMultiAppointment
              ? `Multi-Option: ${record.Appointments.map(m =>
                  m.StartTime ? moment(m.StartTime).format('DD/MM/YYYY - HH:mm') : '',
                )} 
                                ${record.Appointments.length === 1 ? '' : ','}`
              : record.DateFrom
              ? moment(record.DateFrom).format('DD/MM/YYYY - HH:mm')
              : ''}
            <br />
            {record.AssessmentMethod ? <Icon name="video camera" /> : ''}{' '}
            {record.EassessClaimantLocationChecklistReceived ? <Icon name="check" /> : ''}
          </Link>
          {record.Interpreter && (
            <React.Fragment>
              {record.InterpretationStatus === 0 ? (
                <span style={{ color: '#1fc0da' }}>
                  Interpreter: <Icon name="check" />
                </span>
              ) : (
                ''
              )}
              {record.InterpretationStatus === -1 ? <span style={{ color: 'red' }}>Interpreter: X</span> : ''}
            </React.Fragment>
          )}
        </Table.Cell>
      </React.Fragment>
    );
  };

  openSessionDetail = (id, record) => {
    if (api.isTypist()) {
      return this.openUpdateModel(
        record.id || record.Id,
        record.magRefNo || record.AssessmentNumber || record.MagRefNo,
        record.Status || record.status || record.currentStatus,
        record.claimant || record.Claimant,
        record,
      );
    }
    if (!record.AssessmentMethod) {
      return this.openUpdateModel(
        record.id || record.Id,
        record.magRefNo || record.AssessmentNumber || record.MagRefNo,
        record.Status || record.status || record.currentStatus,
        record.claimant || record.Claimant,
        record,
      );
    } else {
      if (record.AssessmentMethod && record.AssessmentMethodType === 1) {
        return this.openUpdateModel(
          record.id || record.Id,
          record.magRefNo || record.AssessmentNumber || record.MagRefNo,
          record.Status || record.status || record.currentStatus,
          record.claimant || record.Claimant,
          record,
        );
      } else {
        localStorage.setItem('VideoSessionCaseId', `Video Session (${record.AssessmentNumber})`);
        dashboardStore.close('/view/video-session');
        setTimeout(() => {
          dashboardStore.open(`/view/video-session?assessmentId=${id}`);
        });
      }
    }
  };

  renderShortCutArray = record => {
    if (api.isTypist()) {
      return shortcutOptionsTypist
        .map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }))
        .filter(i => i.key !== 7);
    } else if (api.isCaseManager()) {
      if (record.IsExistSuppRequest) {
        return shortcutOptions.map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }));
      } else {
        return shortcutOptions
          .map(i => ({
            key: i.key,
            title: i.title,
            onClickShortCut: this.openCaseDetail(record, i.type),
          }))
          .filter(i => i.key !== 2);
      }
    } else if (api.isDoctor()) {
      if (record.currentStatus !== 'Cancelled-No Fee' && record.currentStatus !== 'Late Cancellation Fee') {
        return shortcutOptionsDR.map(i => ({
          key: i.key,
          title: i.title,
          onClickShortCut: this.openCaseDetail(record, i.type),
        }));
      } else {
        return shortcutOptionsDR
          .map(i => ({
            key: i.key,
            title: i.title,
            onClickShortCut: this.openCaseDetail(record, i.type),
          }))
          .filter(i => i.key !== 7);
      }
    }
    return [];
  };

  openCaseProgress = (id, caseNo) => {
    return Store.toggleModal(true, {
      modalType: 'imeCaseProgress',
      id,
      caseNo,
    })();
  };

  openUploadModel = (
    id,
    caseNo,
    status,
    claimant,
    dateFrom,
    isInvoiceUrgentReport,
    urgentReportType,
    urgentReportReportCompletionDate,
  ) => {
    //console.log(caseNo);
    return uploadStore.toggleModal(true, {
      modalType: 'openUploadModel',
      id,
      caseNo,
      status,
      claimant,
      serviceType: 'Assessment',
      label: 'IME',
      dateFrom: dateFrom,
      isInvoiceUrgentReport: isInvoiceUrgentReport,
      urgentReportType: urgentReportType,
      urgentReportReportCompletionDate: urgentReportReportCompletionDate,
      service: this.props.service,
    })();
  };

  openUpdateModel = (id, caseNo, status, claimant, record) => {
    return uploadStore.toggleModal(true, {
      modalType: 'openUpdateModel',
      id,
      caseNo,
      status,
      claimant,
      serviceType: 'Assessment',
      label: 'IME',
      service: this.props.service,
      record,
    })();
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  openCaseUploadAppointment = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);

    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=1`);
    });
    // iframe.forceOpenTab('PostAssessment', `?id=${id}&view=PostAssessment#Appointment`, {
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  openCaseUploadPaperwork = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);

    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=2`);
    });
    // iframe.forceOpenTab('PostAssessment', `?id=${id}&view=PostAssessment#Appointment`, {
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  openCaseUpdateAttAndCancel = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=3`);
    });
    // iframe.forceOpenTab('PostAssessment', `?id=${id}&view=PostAssessment#Attendance`, {
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  openCaseDictation = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=4`);
    });
    // iframe.forceOpenTab('PostAssessment', `?inquiryReq=7&id=${id}&view=PostAssessment#Completion`, {
    //   inquiryReq: 7,
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  openCaseCorrespondence = (id, name, type) => () => {
    localStorage.setItem('AssessmentCaseId', `Case ${name}`);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=6`);
    });
    // iframe.forceOpenTab('PostAssessment', `?inquiryReq=9&id=${id}&view=PostAssessment#${type}`, {
    //   inquiryReq: 9,
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  addNewInvoice = (id, name) => () => {
    // localStorage.setItem('InvoiceCaseId', id);
    localStorage.removeItem(`InvoiceCaseId`);
    iframe.forceOpenTab('ViewInvoice', `?id=0&mId=${id}&type=A&view=ViewInvoice`, {
      id: 0,
      type: 'A',
      mId: id,
      view: 'ViewInvoice',
    });
  };

  openCaseViewInvoice = (id, name) => () => {
    localStorage.setItem('InvoiceCaseId', `INV-${id}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  doOpenCaseRequestSuppReport = (id, name) => () => {
    localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
    });
  };

  openModal = options => {
    return Store.toggleModal(true, options)();
  };

  handlePrintAppCon = id => {
    window.open('/MedicalService/AppointmentConfirmationLetterPrint?aId=' + id, '_blank');
  };

  handPrintDoctor = id => {
    window.open('/MedicalService/DoctorConfPrint?aId=' + id, '_blank');
  };

  handPrintInvoice = id => {
    window.open('/Invoice/ViewBilling?invoiceId=' + id, '_blank');
  };

  handleUnClockForEdit = id => async () => {
    try {
      await customFetch('/MedicalService/UnlockEdit', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id: id, module: 'Assessment' }),
      });
      this.props.refetchTable();
    } catch (e) {}
  };

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/MedicalService/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 1, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          this.props.refetchTable();
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } catch (e) {}
  };

  handleAttendanceRemider = id => async () => {
    try {
      await customFetch('/MedicalService/SendAttendanceRemider', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      }).then(res => {
        if (res.status === 'success') {
          this.props.refetchTable();
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Attendance reminder was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Attendance reminder can't be sent`,
            duration: 2,
          });
        }
      });
    } catch (e) {}
  };

  renderUploadActions = record => {
    var arrUploadStatus = [
      api.imeStatus.ATTENDED,
      api.imeStatus.DICTATION_RECEIVED,
      api.imeStatus.APPOINTMENT_CONFIRMED,
      api.imeStatus.REPORT_RETURNED_FROM_DOCTOR,
    ];

    if (api.isDoctor()) {
      arrUploadStatus = [api.imeStatus.ATTENDED, api.imeStatus.DICTATION_RECEIVED, api.imeStatus.REPORT_DRAFT_RECEIVED];
    } else if (api.isTypist()) {
      arrUploadStatus = [];
    } else if (api.isCaseManager()) {
      arrUploadStatus = [api.imeStatus.APPOINTMENT_CONFIRMED];
    }

    if (arrUploadStatus.includes(record.Status || record.currentStatus)) {
      return (
        <Table.Cell
          selectable
          className="Cell-link"
          style={{ textAlign: 'center' }}
          onClick={() =>
            this.openUploadModel(
              record.id || record.Id,
              record.magRefNo || record.AssessmentNumber || record.MagRefNo,
              record.Status || record.status || record.currentStatus,
              record.claimant || record.Claimant,
              record.DateFrom || null,
              record.IsInvoiceUrgentReport,
              record.UrgentReportType,
              record.UrgentReportReportCompletionDate,
            )
          }
        >
          <Link
            to="#"
            onClick={() =>
              this.openUploadModel(
                record.id || record.Id,
                record.magRefNo || record.AssessmentNumber || record.MagRefNo,
                record.Status || record.status || record.currentStatus,
                record.claimant || record.Claimant,
                record.DateFrom || null,
                record.IsInvoiceUrgentReport,
                record.UrgentReportType,
                record.UrgentReportReportCompletionDate,
              )
            }
          >
            <Icon name="upload" size="small"></Icon>UPLOAD
          </Link>
        </Table.Cell>
      );
    } else {
      return <Table.Cell></Table.Cell>;
    }
  };

  renderUpdateAttendanceAction = record => {
    return (
      <Table.Cell
        selectable
        className="Cell-link"
        style={{ textAlign: 'center' }}
        onClick={() =>
          this.openUpdateModel(
            record.id || record.Id,
            record.magRefNo || record.AssessmentNumber || record.MagRefNo,
            record.Status || record.status || record.currentStatus,
            record.claimant || record.Claimant,
            record,
          )
        }
      >
        <Link
          to="#"
          onClick={() =>
            this.openUpdateModel(
              record.id || record.Id,
              record.magRefNo || record.AssessmentNumber || record.MagRefNo,
              record.Status || record.status || record.currentStatus,
              record.claimant || record.Claimant,
              record,
            )
          }
        >
          UPDATE
        </Link>
      </Table.Cell>
    );
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(record, 'CaseDetails')}>Update Claim Details</Menu.Item>
          <Menu.Item onClick={this.openCaseUploadAppointment(id, record.AssessmentNumber)}>
            Update Appointment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUploadPaperwork(id, record.AssessmentNumber)}>Update Paperwork</Menu.Item>
          <Menu.Item onClick={this.openCaseUpdateAttAndCancel(id, record.AssessmentNumber)}>
            Update Attendance & Cancellation
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDictation(id, record.AssessmentNumber)}>
            Update Dictations & Reports
          </Menu.Item>
          <Menu.Item onClick={this.openCaseCorrespondence(id, record.AssessmentNumber, `ServicePrepaymentSection`)}>
            Update Provider Payment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseCorrespondence(id, record.AssessmentNumber, `Corespondence`)}>
            Update Correspondence
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item onClick={() => this.handlePrintAppCon(id)}>Print Appointment Confirmation (CMs)</Menu.Item>
          <Menu.Item onClick={() => this.handPrintDoctor(id)}>Print Appointment Confirmation (Specialists)</Menu.Item>

          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to booking CM?',
                onOk: this.handleSendStatusUpdate(id, 0),
              })
            }
          >
            Send Status Update (CMs) (MAIL-105)
          </Menu.Item>

          {!api.isCaseManager() && record.DoctorName && (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to send report status update to assessing specialist?',
                  onOk: this.handleSendStatusUpdate(id, 1),
                })
              }
            >
              Send Status Update (Specialists) (MAIL-106)
            </Menu.Item>
          )}
          {/* <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send attendance reminder to specialist?',
                onOk: this.handleAttendanceRemider(id),
              })
            }
          >
            Send Attendance Remider (MAIL-09)
          </Menu.Item> */}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {!record.InvoiceId && record.DoctorId > 0 ? (
            <Menu.Item onClick={this.addNewInvoice(id, record.AssessmentNumber)}>
              <Icon name="plus" size="small" />
              Generate Invoice
            </Menu.Item>
          ) : (
            <div />
          )}
          {record.InvoiceId > 0 ? (
            <Menu.Item onClick={this.openCaseViewInvoice(record.InvoiceId, record.AssessmentNumber)}>
              View Invoice
            </Menu.Item>
          ) : (
            <div />
          )}
          {record.IsBilled ? <Menu.Item onClick={this.handleCopyVoice(id)}>Copy Invoice</Menu.Item> : <div />}
          {record.InvoiceId > 0 ? (
            <Menu.Item onClick={() => this.handPrintInvoice(record.InvoiceId)}>Print Invoice</Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {record.IsBilled ? (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to unlock this assessment?',
                  onOk: this.handleUnClockForEdit(id),
                })
              }
            >
              Unlock for Edit
            </Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message:
                  'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
                onOk: this.handleDuplicate(id),
              })
            }
          >
            Rebook this Case
          </Menu.Item>
          {/* {api.isAdmin() && <Menu.Item onClick={() => this.handleDelete(id, record)}>Delete this Case</Menu.Item>} */}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {record.IsExistSuppRequest && (
            <Menu.Item onClick={() => this.openViewSuppRequest(id, record, 'IME')}>View Supp Requests</Menu.Item>
          )}
          <Menu.Item onClick={this.openCaseRequestSuppReport(id, record.AssessmentNumber)}>
            Request Supp Report
          </Menu.Item>
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.onInsertCaseNoToImsHandler(record.AssessmentNumber)}>
              Link Inquiry to this Case
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  openViewSuppRequest = (id, record, type) => {
    return Store.toggleModal(true, {
      modalType: 'viewSuppRequest',
      id,
      record,
      type,
    })();
  };

  handleDelete = (id, record) => {
    if (record.Status === api.imeStatus.IME_REQUESTED || record.Status === api.imeStatus.CANCELLED) {
      this.openModal({
        modalType: 'confirm',
        message: 'Do you want to delete this assessment?',
        onOk: async () => {
          try {
            await customFetch('/MedicalService/DeleteAssessment', {
              headers: { 'Content-Type': 'application/json' },
              method: 'POST',
              body: JSON.stringify({ id: id }),
            });
            this.props.refetchTable();
          } catch (e) {}
        },
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  handleDuplicate = id => async () => {
    customFetch('/MedicalService/Assessment_CopyDetail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ assessmentId: id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.success({
            message: 'Success',
            description: 'Assessment is duplicated',
          });
          this.props.refetchTable();
          localStorage.setItem('AssessmentCaseId', response.AssessmentNumber);
          dashboardStore.close('/view/add-edit-ime-assessment-2');
          setTimeout(() => {
            dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${response.Id}&step=1`);
          });
        }
      })
      .catch(() => {});
    // iframe.forceOpenTab('PostAssessment', `?id=${id}&copy=true&view=PostAssessment`, {
    //   copy: true,
    //   id,
    //   view: 'PostAssessment',
    // });
  };

  openCaseRequestSuppReport = (id, name) => async () => {
    await customFetch('/Supplementary/CheckOriginalCaseNo', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ caseNo: name }),
    }).then(res => {
      if (res.status === 'success') {
        if (res.exist) {
          var existItems = '';
          var message = '';

          res.listExist.forEach(i => {
            existItems += i.CaseNo + ' - ' + i.StatusStaffText + ', ';
          });

          if (res.listExist.length > 1) {
            message =
              'Supplementary requests (' +
              existItems.substring(0, existItems.length - 2) +
              ') have already been created for this case. Do you want to proceed?';
          } else {
            message =
              'A supplementary request (' +
              existItems.substring(0, existItems.length - 2) +
              ') has already been created for this case. Do you want to proceed?';
          }

          Store.toggleModal(true, {
            modalType: 'confirm',
            message: message,
            onOk: this.doOpenCaseRequestSuppReport(id, name),
          })();
        } else {
          localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
          dashboardStore.close('/view/add-edit-supplementary-report-2');
          setTimeout(() => {
            dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
          });
        }
      }
    });
  };

  handleCopyVoice = id => async () => {
    try {
      await customFetch('/Invoice/Checkexist', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id: id, module: 'Assessment' }),
      });
      this.props.refetchTable();
    } catch (e) {}
  };

  renderStatus = record => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return record.StatusStaffText;
    } else if (api.isCaseManager()) {
      return record.StatusCMText;
    } else if (api.isDoctor()) {
      return record.statusDoctorText;
    }
    return record.Status;
  };

  uploadTilte = () => {
    if (this.props.service) {
      switch (this.props.service) {
        case 'UploadPaperwork':
          return 'Upload Paperwork';
        case 'UploadDictations':
          return 'Upload Dictations';
        case 'UploadReportDrafts':
          return 'Upload Report Drafts';
        case 'UploadReports':
          return 'Upload Reports';
        case 'UploadCorrectedReport':
          return 'Upload Corrected Report';
        default:
          return '';
      }
    }
  };

  onInsertCaseNoToImsHandler = caseNo => () => {
    PubSub.publish(PubSubTopics.SelectCaseNo, caseNo);
    router.history.push('/view/ims');
  };

  render() {
    const { data, curPage, totalPage, sumItem, searchAttachment, service } = this.props;
    let columns = [];
    if (!api.isAdminOrMagStaffOrAccounting()) {
      columns = [
        { title: '' },
        { title: 'Upload' },
        { title: 'Case No' },
        { title: 'Request Date' },
        { title: 'Appointment Date' },
        { title: 'Claim No' },
        { title: 'Type Of Claim/Report' },
        { title: 'Claimant' },
        { title: 'Booking Client ' },
        { title: 'Case Manager' },
        { title: 'Specialist ' },
        { title: 'Clinic Centre' },
        { title: 'Case Progress' },
      ];
      if (service === 'UpdateAttendance') {
        columns = [
          { title: '' },
          { title: 'Attendance' },
          { title: 'Case No' },
          { title: 'Appointment Date' },
          { title: 'Type Of Claim/Report' },
          { title: 'Claimant' },
          { title: 'Booking Client ' },
          { title: 'Clinic Centre' },
          { title: 'Case Progress' },
        ];
      } else if (service === 'UploadDictations') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Case No' },
          { title: 'Appointment Date' },
          { title: 'Type Of Claim/Report' },
          { title: 'Claimant' },
          { title: 'Booking Client ' },
          { title: 'Case Progress' },
        ];
      } else if (service === 'UploadCorrectedReport') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Case No' },
          { title: 'Appointment Date' },
          { title: 'Type Of Claim/Report' },
          { title: 'Claimant' },
          { title: 'Booking Client ' },
          { title: 'Case Progress' },
        ];
      }
    } else {
      columns = [
        { title: '' },
        { title: 'Upload' },
        { title: 'Case No' },
        { title: 'Request Date' },
        { title: 'Appointment Date' },
        { title: 'Action Required' },
        { title: 'Claim No' },
        { title: 'Type Of Claim/Report' },
        { title: 'Claimant' },
        { title: 'Booking Client ' },
        { title: 'Case Manager' },
        { title: 'Specialist ' },
        { title: 'Clinic Centre' },
        { title: 'Case Progress' },
        { title: 'Status Date' },
        { title: 'Created By' },
      ];

      if (service === 'UpdateAttendance') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Attendance' },
          { title: 'Case No' },
          { title: 'Request Date' },
          { title: 'Appointment Date' },
          { title: 'Claim No' },
          { title: 'Type Of Claim/Report' },
          { title: 'Claimant' },
          { title: 'Booking Client ' },
          { title: 'Case Manager' },
          { title: 'Specialist ' },
          { title: 'Clinic Centre' },
          { title: 'Case Progress' },
          { title: 'Status Date' },
          { title: 'Created By' },
        ];
      }

      if (service === 'UploadDictations') {
        columns = [
          { title: '' },
          { title: 'Upload' },
          { title: 'Case No' },
          { title: 'Request Date' },
          { title: 'Appointment Date' },
          { title: 'Action Required' },
          { title: 'Claim No' },
          { title: 'Type Of Claim/Report' },
          { title: 'Claimant' },
          { title: 'Booking Client ' },
          { title: 'Case Manager' },
          { title: 'Specialist ' },
          { title: 'Clinic Centre' },
          { title: 'Case Progress' },
          { title: 'Status Date' },
          { title: 'Created By' },
        ];
      }
    }

    if (searchAttachment) {
      columns = [
        ...columns,
        {
          title: 'Case Documents',
          style: { minWidth: '500px', maxWidth: '500px' },
        },
        {
          title: 'Attachment Type',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded By',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded Date',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
      ];
    }

    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {data.length ? (
            data.map(record =>
              !api.isDoctor() ? (
                <Table.Row key={record.id || record.Id}>
                  <Table.Cell className="Cell-action">
                    {(api.isCaseManager() || api.isDoctor() || api.isTypist()) && (
                      <ShortcutTable shortcutOptions={this.renderShortCutArray(record)} />
                    )}
                    {api.isAdminOrMagStaffOrAccounting() && this.renderTableActions(record.id || record.Id, record)}
                  </Table.Cell>
                  {this.renderUploadActions(record)}
                  {service === 'UpdateAttendance' && this.renderUpdateAttendanceAction(record)}
                  <Table.Cell className="Cell-nowrap Cell-link">
                    <Link to="#" onClick={this.openCaseDetail(record, 'CaseDetails')}>
                      {record.magRefNo || record.AssessmentNumber || record.MagRefNo}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {record.createDate || record.CreateDate || record.BookingDate
                      ? moment(
                          formatDate(record.createDate || record.CreateDate || record.BookingDate, true),
                          'DD MMM YYYY, HH:mm',
                        ).format('DD/MM/YYYY')
                      : 'N/A'}
                  </Table.Cell>
                  {api.isCaseManager() && this.renderVideoSessionDetailForCM(record.id || record.Id, record)}
                  {!api.isDoctor() &&
                    !api.isCaseManager() &&
                    this.renderVideoSessionDetailForAdmin(record.id || record.Id, record)}
                  {api.isDoctor() && (
                    <Table.Cell>
                      {record.appointmentDate
                        ? moment(formatDate(record.appointmentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                        : 'N/A'}
                    </Table.Cell>
                  )}
                  {!api.isDoctor() && !api.isCaseManager() && service !== 'UpdateAttendance' && (
                    <Table.Cell
                      className={cx(
                        `${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`,
                      )}
                    >
                      <Link
                        to="#"
                        //onClick={this.openModalNote(record)}
                        onClick={this.openModalNote({
                          modalType: 'notes',
                          id: record.Id,
                          caseNo: record.AssessmentNumber,
                          client: record.Client,
                        })}
                      >
                        {record.ActionRequired === 0 ? (
                          <span className="link-add-action">(Add Action)</span>
                        ) : (
                          <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                            {record.ActionRequiredType === 0
                              ? '(Add Action)'
                              : record.ActionRequiredType === 1
                              ? '(Action Required)'
                              : '(View Actions)'}
                          </span>
                        )}
                      </Link>
                      <br />
                      <div>
                        {record.ActionRequiredType > 0 && record.ActionOverview
                          ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
                          : ''}
                      </div>
                    </Table.Cell>
                  )}
                  <Table.Cell>{record.claimNo || record.ClaimNo}</Table.Cell>
                  <Table.Cell>
                    {record.typeOfClaim || record.TypeOfClaimLabel || record.TypeOfClaim}
                    {record.typeOfReport || record.TypeOfReportLabel}
                  </Table.Cell>
                  <Table.Cell>{record.claimant || record.Claimant}</Table.Cell>
                  <Table.Cell>{record.client || record.Client}</Table.Cell>
                  <Table.Cell>
                    <h5 className="ma-0">
                      {record.CaseManagerId > 0 ? `(${record.CaseManagerId}) ` : ''}
                      {record.caseManager || record.CaseManager || record.CaseManagerName}
                    </h5>
                    {!api.isDoctor() && <div>{record.cmEmail || record.CMEmail}</div>}
                    {!api.isDoctor() && <div>{record.cmMobilePhone || record.CMMobilePhone}</div>}
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className="ma-0">
                      {record.DoctorId > 0 ? `(${record.DoctorId}) ` : ''}
                      {record.doctorName || record.DoctorName || record.doctor || record.Doctor}
                    </h5>
                    <div>{record.doctorBusinessEmail || record.DoctorBusinessEmail}</div>
                    <div>{record.doctorPhone || record.DoctorPhone}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className="ma-0">{record.clinicName || record.ClinicName}</h5>
                    <div>{record.clinicEmail || record.ClinicEmail}</div>
                    <div>{record.clinicPhone || record.ClinicPhone}</div>
                  </Table.Cell>
                  {/* {service !== 'UpdateAttendance' && (
                  <Table.Cell>
                    {record.appointmentDate || record.DateFrom || record.AppointmentDate
                      ? moment(
                          formatDate(record.appointmentDate || record.DateFrom || record.AppointmentDate, true),
                          'DD MMM YYYY, HH:mm',
                        ).format('DD/MM/YYYY')
                      : 'N/A'}
                  </Table.Cell>
                )} */}
                  <Table.Cell
                    selectable
                    onClick={() =>
                      this.openCaseProgress(
                        record.id || record.Id,
                        record.magRefNo || record.AssessmentNumber || record.MagRefNo,
                      )
                    }
                    className="Cell-link"
                  >
                    <Link
                      to="#"
                      onClick={() =>
                        this.openCaseProgress(
                          record.id || record.Id,
                          record.magRefNo || record.AssessmentNumber || record.MagRefNo,
                        )
                      }
                    >
                      {this.renderStatus(record)}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {record.statusDate || record.StatusDate
                      ? moment(formatDate(record.statusDate || record.StatusDate, true), 'DD MMM YYYY, HH:mm').format(
                          'DD/MM/YYYY',
                        )
                      : 'N/A'}
                  </Table.Cell>
                  <Table.Cell>{record.createdByName || record.CreatedByName}</Table.Cell>

                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell className="Cell-link">
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx} style={{ overflow: 'hidden' }}>
                          {!item.IsHyperlink && (
                            <a
                              href={item.PhysicalPath || item.physicalPath}
                              target="_blank"
                              download={item.FileTitle || item.fileTitle}
                              rel="noopener noreferrer"
                            >
                              {item.FileTitle || item.fileTitle}
                            </a>
                          )}
                          {item.IsHyperlink && (
                            <a href={item.FileName || item.fileName} target="_blank" rel="noopener noreferrer">
                              {item.FileName || item.fileName}
                            </a>
                          )}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx} style={{ minWidth: '500px', maxWidth: '500px' }}>
                          {item.ReportTypeName || item.reportTypeName}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx}>
                          {item.UplodatedBy || item.uplodatedBy}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx}>
                          {item.UplodatedDate || item.uplodatedDate
                            ? moment(
                                formatDate(item.UplodatedDate || item.uplodatedDate, true),
                                'DD MMM YYYY, HH:mm',
                              ).format('DD/MM/YYYY - HH:mm')
                            : 'N/A'}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ) : (
                //FOR ROLE DOCTOR
                <Table.Row key={record.id || record.Id}>
                  <Table.Cell className="Cell-action">
                    {(api.isCaseManager() || api.isDoctor() || api.isTypist()) && (
                      <ShortcutTable shortcutOptions={this.renderShortCutArray(record)} />
                    )}
                    {api.isAdminOrMagStaffOrAccounting() && this.renderTableActions(record.id || record.Id, record)}
                  </Table.Cell>
                  {service !== 'UpdateAttendance' && this.renderUploadActions(record)}
                  {service === 'UpdateAttendance' && this.renderUpdateAttendanceAction(record)}
                  <Table.Cell className="Cell-nowrap Cell-link">
                    <Link to="#" onClick={this.openCaseDetail(record, 'CaseDetails')}>
                      {record.magRefNo || record.AssessmentNumber || record.MagRefNo}
                    </Link>
                  </Table.Cell>
                  {service !== 'UpdateAttendance' &&
                    service !== 'UploadCorrectedReport' &&
                    service !== 'UploadDictations' && (
                      <Table.Cell>
                        {record.createDate || record.CreateDate || record.BookingDate
                          ? moment(
                              formatDate(record.createDate || record.CreateDate || record.BookingDate, true),
                              'DD MMM YYYY, HH:mm',
                            ).format('DD/MM/YYYY')
                          : 'N/A'}
                      </Table.Cell>
                    )}
                  {!api.isDoctor() &&
                    !api.isCaseManager() &&
                    this.renderVideoSessionDetailForAdmin(record.id || record.Id, record)}
                  {api.isDoctor() && (
                    <Table.Cell>
                      {record.appointmentDate
                        ? moment(formatDate(record.appointmentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                        : 'N/A'}
                    </Table.Cell>
                  )}
                  {!api.isDoctor() && !api.isCaseManager() && service !== 'UpdateAttendance' && (
                    <Table.Cell
                      className={cx(
                        `${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`,
                      )}
                    >
                      <Link
                        to="#"
                        //onClick={this.openModalNote(record)}
                        onClick={this.openModalNote({
                          modalType: 'notes',
                          id: record.Id,
                          caseNo: record.AssessmentNumber,
                          client: record.Client,
                        })}
                      >
                        {record.ActionRequired === 0 ? (
                          <span className="link-add-action">(Add Action)</span>
                        ) : (
                          <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                            {record.ActionRequiredType === 0
                              ? '(Add Action)'
                              : record.ActionRequiredType === 1
                              ? '(Action Required)'
                              : '(View Actions)'}
                          </span>
                        )}
                      </Link>
                      <br />
                      <div>
                        {record.ActionRequiredType > 0 && record.ActionOverview
                          ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
                          : ''}
                      </div>
                    </Table.Cell>
                  )}
                  {service !== 'UpdateAttendance' &&
                    service !== 'UploadCorrectedReport' &&
                    service !== 'UploadDictations' && <Table.Cell>{record.claimNo || record.ClaimNo}</Table.Cell>}
                  <Table.Cell>
                    {record.typeOfClaim || record.TypeOfClaimLabel || record.TypeOfClaim}
                    {record.typeOfReport || record.TypeOfReportLabel}
                  </Table.Cell>
                  <Table.Cell>{record.claimant || record.Claimant}</Table.Cell>
                  <Table.Cell>{record.client || record.Client}</Table.Cell>
                  {service !== 'UpdateAttendance' &&
                    service !== 'UploadCorrectedReport' &&
                    service !== 'UploadDictations' && (
                      <Table.Cell>
                        <h5 className="ma-0">
                          {record.CaseManagerId > 0 ? `(${record.CaseManagerId}) ` : ''}
                          {record.caseManager || record.CaseManager || record.CaseManagerName}
                        </h5>
                        {!api.isDoctor() && <div>{record.cmEmail || record.CMEmail}</div>}
                        {!api.isDoctor() && <div>{record.cmMobilePhone || record.CMMobilePhone}</div>}
                      </Table.Cell>
                    )}
                  {service !== 'UpdateAttendance' &&
                    service !== 'UploadCorrectedReport' &&
                    service !== 'UploadDictations' && (
                      <Table.Cell>
                        <h5 className="ma-0">
                          {record.DoctorId > 0 ? `(${record.DoctorId}) ` : ''}
                          {record.doctorName || record.DoctorName || record.doctor || record.Doctor}
                        </h5>
                        <div>{record.doctorBusinessEmail || record.DoctorBusinessEmail}</div>
                        <div>{record.doctorPhone || record.DoctorPhone}</div>
                      </Table.Cell>
                    )}
                  {service !== 'UploadCorrectedReport' && service !== 'UploadDictations' && (
                    <Table.Cell>
                      <h5 className="ma-0">{record.clinicName || record.ClinicName}</h5>
                      <div>{record.clinicEmail || record.ClinicEmail}</div>
                      <div>{record.clinicPhone || record.ClinicPhone}</div>
                    </Table.Cell>
                  )}
                  {/* {service !== 'UpdateAttendance' && (
                  <Table.Cell>
                    {record.appointmentDate || record.DateFrom || record.AppointmentDate
                      ? moment(
                          formatDate(record.appointmentDate || record.DateFrom || record.AppointmentDate, true),
                          'DD MMM YYYY, HH:mm',
                        ).format('DD/MM/YYYY')
                      : 'N/A'}
                  </Table.Cell>
                )} */}
                  <Table.Cell
                    selectable
                    onClick={() =>
                      this.openCaseProgress(
                        record.id || record.Id,
                        record.magRefNo || record.AssessmentNumber || record.MagRefNo,
                      )
                    }
                    className="Cell-link"
                  >
                    <Link
                      to="#"
                      onClick={() =>
                        this.openCaseProgress(
                          record.id || record.Id,
                          record.magRefNo || record.AssessmentNumber || record.MagRefNo,
                        )
                      }
                    >
                      {this.renderStatus(record)}
                    </Link>
                  </Table.Cell>
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell className="Cell-link">
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx} style={{ overflow: 'hidden' }}>
                          {!item.IsHyperlink && (
                            <a
                              href={item.PhysicalPath || item.physicalPath}
                              target="_blank"
                              download={item.FileTitle || item.fileTitle}
                              rel="noopener noreferrer"
                            >
                              {item.FileTitle || item.fileTitle}
                            </a>
                          )}
                          {item.IsHyperlink && (
                            <a href={item.FileName || item.fileName} target="_blank" rel="noopener noreferrer">
                              {item.FileName || item.fileName}
                            </a>
                          )}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx} style={{ minWidth: '500px', maxWidth: '500px' }}>
                          {item.ReportTypeName || item.reportTypeName}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx}>
                          {item.UplodatedBy || item.uplodatedBy}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                  {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment || record.listCaseAttachment)) ? (
                    <Table.Cell>
                      {(record.ListCaseAttachment || record.listCaseAttachment).map((item, idx) => (
                        <div key={idx}>
                          {item.UplodatedDate || item.uplodatedDate
                            ? moment(
                                formatDate(item.UplodatedDate || item.uplodatedDate, true),
                                'DD MMM YYYY, HH:mm',
                              ).format('DD/MM/YYYY - HH:mm')
                            : 'N/A'}
                          {idx < (record.ListCaseAttachment || record.listCaseAttachment).length - 1 && <Divider />}
                        </div>
                      ))}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ),
            )
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage || 1}
          totalPage={totalPage || 1}
          onPageClick={this.handlePageClick}
          totalItems={sumItem || 0}
        />
      </Table>
    );
  }
}

export default TablesIME;
