import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader } from '../../shared/table';
import TableToolbar from '../../shared/tableToolbar';

const { $ } = window;
window.AdminStuffReact = {};

// function onSignalrStarted() {
//   $.connection.onlineUserHub.server.refreshOnlineUserList();
//   window.AdminStuffReact.SignalrStarted = true;
// }

const compareAsc = (a, b) => {
  if (typeof a === typeof true) {
    if (a === b) {
      return 0;
    }

    return !a && b ? -1 : 1;
  }

  if (typeof a === typeof '') {
    return a.toUpperCase().localeCompare(b.toUpperCase());
  }

  if (moment.isMoment(a) && moment.isMoment(b)) {
    return a.isBefore(b) ? -1 : 1;
  }

  return a - b; // compare numbers
};
const formatUsers = users =>
  users.map(user => ({
    ...user,
    LastActivityAt: moment(user.LastActivityAt),
  }));

/**
 * case insensitivie
 * @param {Array} users
 * @param {string} searchTerm
 */
const filterUsers = (users, searchTerm) =>
  users.filter(user => user[`Username`].toUpperCase().indexOf(searchTerm.toUpperCase()) > -1);

class OnlineUsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineUsers: [],
      filteredUsers: [], // for local filtering
      orderBy: `Username`,
      orderAsc: true,
      searchTerm: '',
    };
    this.refreshOnlineUserList = this.refreshOnlineUserList.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    $.connection.hub.createHubProxy('onlineUserHub').on('refreshOnlineUserList', this.refreshOnlineUserList);
    if (window.AdminStuffReact.SignalrStarted) {
      $.connection.hub.createHubProxy('onlineUserHub').invoke('refreshOnlineUserList');
    }
  }

  componentWillUnmount() {
    $.connection.hub.createHubProxy('onlineUserHub').off('refreshOnlineUserList', this.refreshOnlineUserList);
  }

  onSort = event => {
    const { filteredUsers, orderBy, orderAsc } = this.state;
    const newOrderBy = event.sortKey;
    const newOrderAsc = orderBy === newOrderBy ? !orderAsc : true; // always order asc by default
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      const leftValue = a[newOrderBy];
      const rightValue = b[newOrderBy];
      return newOrderAsc ? compareAsc(leftValue, rightValue) : compareAsc(leftValue, rightValue) * -1;
    });
    this.setState({
      orderBy: newOrderBy,
      orderAsc: newOrderAsc,
      filteredUsers: sortedUsers,
    });
  };

  onSearchChange = event => {
    const { value: searchTerm } = event.currentTarget;
    const { onlineUsers } = this.state;
    this.setState({
      filteredUsers: filterUsers(onlineUsers, searchTerm),
      searchTerm,
    });
  };

  refreshOnlineUserList = onlineUsers => {
    const { searchTerm } = this.state;
    const formattedUsers = formatUsers(onlineUsers);
    window.AdminStuffReact.onlineUsers = formattedUsers;
    this.setState({
      onlineUsers: formattedUsers,
      filteredUsers: filterUsers(formattedUsers, searchTerm),
      orderBy: `Username`,
      orderAsc: true,
    });
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }
    return data.map(({ Id, ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{record.Username}</Table.Cell>
        <Table.Cell>{record.Role}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.LastActivityAt ? moment(record.LastActivityAt).format('DD/MM/YYYY - HH:mm:ss') : ''}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handleRefresh = e => {
    e.preventDefault();
    if ($ && $.connection && $.connection.onlineUserHub) {
      $.connection.hub.createHubProxy('onlineUserHub').client.dummyMethod = function() {};
      const startHub = () => {
        // $.connection.hub.start({ pingInterval: null }).done(onSignalrStarted);
      };
      startHub();
    }
  };

  render() {
    const columns = [
      {
        title: `Username`,
        sortKey: 'Username',
      },
      { title: 'Role', sortKey: `Role` },
      { title: 'Last login time (UTC+07:00)', sortKey: `LastActivityAt` },
    ];
    const colSpan = columns.length;
    const { orderAsc, orderBy } = this.state;
    return (
      <React.Fragment>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={this.onSearchChange} />
            <TableToolbar.SubmitButton onClick={this.handleRefresh}>Refresh</TableToolbar.SubmitButton>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container>
          <span style={{ marginLeft: `5px` }}>
            <b className="title-table">
              {`Current showing: ${this.state.filteredUsers.length}/${this.state.onlineUsers.length} online users`}
            </b>
          </span>
        </TableToolbar.Container>
        <Table sortable striped celled>
          <TableHeader
            onSort={this.onSort}
            sortColumn={orderBy}
            sortDirection={!orderAsc ? `ascending` : `descending`}
            columns={columns}
          />
          <Table.Body>{this.renderTableBody(this.state.filteredUsers, colSpan)}</Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default OnlineUsersPage;
