import dashboardStore from '@stores/dashboard';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import Content from './Content';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import SalesCallReportStore from './SalesCallReportStore';
import { getActivityType, getConversationLogContent } from '../../../utils/functions';

const columnsCM = [
  { title: 'ID' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Activity Type' },
  //{ title: 'Marketing Campaign' },
  { title: 'Service Type' },
  { title: 'Content' },
  { title: 'Activity PIC', sortKey: 'CallBy' },
  { title: 'Activity Date', sortKey: `CallDate` },
  { title: 'Account Manager' },
];

@observer
class SalesCallReportTable extends React.Component {
  openCaseCorrespondence = record => e => {
    e.preventDefault();
    localStorage.setItem('CmName', record.CMFullName);
    dashboardStore.close('/view/add-edit-case-manager-2', true);
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-case-manager-2?id=${record.CMId}&step=4`);
    });
  };

  renderTableBody = () => {
    return (SalesCallReportStore.data?.itemList || []).map((record, idx) => (
      <Table.Row key={idx}>
        <Table.Cell className="Cell-link" onClick={this.openCaseCorrespondence(record)} selectable>
          <Link to="#" onClick={this.openCaseCorrespondence(record)}>
            {record.CMId}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <div>
            <span style={{ fontWeight: 'bold' }}>{record.CMFullName}</span>
          </div>
          <div>
            <span>{record.CMNumber}</span>
          </div>
          <div>
            <span>{record.CMPosition}</span>
          </div>
          <div>
            <span>{record.CMType}</span>
          </div>
        </Table.Cell>
        <Table.Cell>{record.CMClientName}</Table.Cell>
        <Table.Cell>{getActivityType(record.TypeLog)}</Table.Cell>
        {/* <Table.Cell>{record.CampaignName}</Table.Cell> */}
        <Table.Cell>{record.CCServiceType}</Table.Cell>
        <Table.Cell className="Cell-Body">
          <Content
            Notes={record.Notes}
            onViewMore={async () => {
              const content = await getConversationLogContent(record.Id, 'Staff').then(res => res?.content);
              SalesCallReportStore.setDataFieldsValue(idx, content);
            }}
          />
        </Table.Cell>
        <Table.Cell>{record.CallBy}</Table.Cell>
        <Table.Cell>{record.CallDate}</Table.Cell>
        <Table.Cell>{record.ClientServiceOfficer}</Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    SalesCallReportStore.refetch({ curPage: page - 1 });
  };

  render() {
    const { loading, data } = SalesCallReportStore;
    const columns = columnsCM;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={SalesCallReportStore.sortColumn}
          sortDirection={SalesCallReportStore.sortDirection}
          onSort={SalesCallReportStore.handleSort}
        />
        <Table.Body>
          {(data?.itemList || []).length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={SalesCallReportStore.totalItem}
          currentPage={SalesCallReportStore.curPage + 1}
          totalPage={SalesCallReportStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default SalesCallReportTable;
