export const InquiryStatuses = [
  { text: 'Open', value: 0 },
  { text: 'Closed - Success', value: 1 },
  { text: 'Closed - No Sales', value: 2 },
];

export const ActionNoteStatuses = [
  { text: 'Open', value: 0 },
  { text: 'Closed', value: 1 },
];

export const DeletedFolderId = 4;
export const Folders = [
  { text: 'Unclassified', value: 0 },
  { text: 'Sales Inquiry', value: 1 },
  { text: 'Existing Cases', value: 2 },
  { text: 'Others', value: 3 },
  { text: 'Deleted', value: DeletedFolderId },
];
