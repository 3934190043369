import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import ui from '../../../stores/dashboard';
import Tooltip from '../../shared/Tooltip';

@observer
class ToggleAutohideIcon extends React.Component {
  render() {
    const {
      sidebar: { autohide, visible },
      toggleSidebarAutohide,
    } = ui;
    const appear = !visible && autohide ? null : autohide ? 'Pin sidebar to fixed' : 'Make sidebar auto-hide';

    return (
      <Tooltip appear={appear}>
        <div onClick={toggleSidebarAutohide} className="DashboardHeaderIcon ToggleAutohideIcon r-0">
          <Icon name={autohide ? 'long arrow alternate right' : 'exchange'} fitted />
        </div>
      </Tooltip>
    );
  }
}

export default ToggleAutohideIcon;
