import React from 'react';
import { observer } from 'mobx-react';

import AddEditStore from './DoctorDetailsStore';
import Modal from '../../../shared/Modal';
import PasswordResetHistory from '../../../admin-routes/PasswordResetHistory';

@observer
class ModalDuplicate extends React.Component {
  render() {
    const { open } = AddEditStore;
    return (
      <Modal visible={open} width={1200} onCancel={AddEditStore.toggleModal(false)} title="Password Reset History">
        <PasswordResetHistory userId={AddEditStore.userId} username={AddEditStore.dataDoctor.Username} type={3} />
      </Modal>
    );
  }
}

export default ModalDuplicate;
