import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
// import { Link } from 'react-router-dom';
import { Icon, Popup, Table } from 'semantic-ui-react';

import customFetch from '../../../../utils/customFetch';
import * as api from '../../../../stores/api';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../../shared/table';
import SessionAvailabilityStore from './SessionAvailabilityStore';

const columns = [
  // { title: '' },
  { title: 'Specialist', sortKey: 'Doctor' },
  { title: 'Speciality', sortKey: 'Speciality' },
  { title: 'State', sortKey: 'State' },
  { title: 'Location', sortKey: 'Location' },
  { title: 'Month', sortKey: 'Month' },
  { title: 'Dates' },
];

@observer
class SessionAvailabilityTable extends React.Component {
  openModal = options => {
    return SessionAvailabilityStore.toggleModal(true, options);
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ ...record }, idx) => (
      <Table.Row key={idx}>
        {/* <Table.Cell className="Cell-actions">
            {this.renderTableActions(Id, record)}
          </Table.Cell> */}

        <Table.Cell>
          <Popup trigger={<span className="doctor-name-text">{record.Doctor}</span>}>
            <span className="">{record.Doctor}</span>
          </Popup>
        </Table.Cell>
        <Table.Cell>
          <Popup trigger={<span className="doctor-name-text">{record.Speciality}</span>}>
            <span className="">{record.Speciality}</span>
          </Popup>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">{record.State}</Table.Cell>
        <Table.Cell>{record.Location}</Table.Cell>
        <Table.Cell>{record.Month}</Table.Cell>
        <Table.Cell>{record.sDate}</Table.Cell>
      </Table.Row>
    ));
  };

  handleDelete = id => async () => {
    SessionAvailabilityStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/SessionAvailability/DeleteRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await SessionAvailabilityStore.refetch({}, true);
    } finally {
      SessionAvailabilityStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = (id, record) => async () => {
    SessionAvailabilityStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/SessionAvailability/EditItemStatus', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });
      await SessionAvailabilityStore.refetch({}, true);
    } finally {
      SessionAvailabilityStore.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.onOpenCaseDetail(record, id)}>Edit</Menu.Item>}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    SessionAvailabilityStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection, totalItems } = SessionAvailabilityStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={SessionAvailabilityStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(data, colSpan)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={SessionAvailabilityStore.curPage || 1}
          totalPage={SessionAvailabilityStore.totalPage || 0}
          onPageClick={this.handlePageClick}
          totalItems={totalItems || 0}
        />
      </Table>
    );
  }
}

export default SessionAvailabilityTable;
