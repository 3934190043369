import customFetch from '../../../utils/customFetch';

export const getAllData = (keyword = '', curPage = 1, numItemPerPage = 20, sortBy = '', sortDirection = '') => {
  return customFetch('/TalkingPointCategory/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
