import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import MessageTemplateStore from './MessageTemplateStore';

const moduleTypeLabel = [
  { text: `All`, value: -1 },
  { text: `All modules`, value: 0 },
  { text: `IME`, value: 1 },
  { text: `FileReview`, value: 2 },
  { text: `Supp`, value: 3 },
  { text: `CR`, value: 4 },
  { text: `Cirrus Account Registration`, value: 5 },
  { text: `Online Doctor Module`, value: 6 },
];

const statusLabels = [
  { text: `All`, value: -1 },
  { text: `Active (in DB)`, value: 0 },
  { text: `Inactive (in DB)`, value: 1 },
  { text: `Active (not in DB)`, value: 2 },
  { text: `Inactive (not in DB)`, value: 3 },
];

const receiverAliasLabels = [
  { text: `All`, value: -1 },
  { text: `Other`, value: 0 },
  { text: `Case Managers (Booking)`, value: 1 },
  { text: `Case Managers (Paying)`, value: 2 },
  { text: `Doctors`, value: 3 },
  { text: `Claimants (Email)`, value: 4 },
  { text: `Claimants (Mobile)`, value: 5 },
  { text: `Client Accounting`, value: 6 },
  { text: `Staff PIC`, value: 7 },
];

@observer
class MessageTemplateToolbar extends React.Component {
  renderModule = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataModuleType.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };
  renderCaseStage = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataCaseStage.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderStatus = () => {
    return statusLabels.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };
  renderReceiverAlias = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataReceiver.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };
  handleOpenCaseCreate = () => {
    MessageTemplateStore.setFieldsValue({ template: '' });
    MessageTemplateStore.toggleModal(true, {
      modalType: 'edit',
      id: 0,
      record: {
        Subject: '',
        AppendSignature: true,
        Bcc: '',
        Destination: null,
        Logic: '',
        ModuleType: -1,
        Sender: '',
        CaseStage: -1,
        Status: 0,
        TemplateKey: '',
        Modified: '',
        ModifiedBy: '',
        Created: null,
        CreatedBy: null,
        ReceiverAlias: -1,
      },
    })();
  };
  handSubmit = e => {
    e.preventDefault();
    MessageTemplateStore.refetch({ curPage: 1 }, true);
  };
  handleExportSearchResult = e => {
    e.preventDefault();
    const { keyword, sortKey, sortDirection, moduleType, status, receiverAlias } = MessageTemplateStore;
    const searchParams = Object.assign({
      Keyword: keyword,
      // ServiceType: filter,
      ServiceType: moduleType,
      Status: status,
      ReceiverAlias: receiverAlias,
      SortBy: sortKey,
      SortDirection: sortDirection.replace('ending', '').toUpperCase(),
    });
    var qs = Object.entries(searchParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open(`/MailTemplate/ExportSearchResult?` + qs, `_blank`);
  };

  handleRefresh = () => {
    MessageTemplateStore.refetch({ data: null }, true);
  };
  handleReset = () => {
    MessageTemplateStore.resetStore();
  };
  handleChangeSearch = (_, { value }) => {
    MessageTemplateStore.setFieldsValue({ keyword: value });
  };
  handleChangeDropdown = (_, { value, name }) => {
    MessageTemplateStore.refetch(
      {
        [name]: value,
      },
      true,
    );
  };
  renderTextFilter = (value, name) => {
    if (name === 'moduleType') {
      return moduleTypeLabel.filter(i => i.value === value).map(i => i.text)[0];
    } else if (name === 'status') {
      return statusLabels.filter(i => i.value === value).map(i => i.text)[0];
    } else if (name === 'receiverAlias') {
      return receiverAliasLabels.filter(i => i.value === value).map(i => i.text)[0];
    }
  };
  renderFilterText = (value, name) => {
    if (name === 'moduleType') {
      const array = [];
      const data = toJS(MessageTemplateStore.dataModuleType.itemList);
      for (let i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Label: `All`, Id: -1 });
      return array.filter(i => i.Id === value).map(i => i.Label)[0];
    } else if (name === 'receiverAlias') {
      const array = [];
      const data = toJS(MessageTemplateStore.dataReceiver.itemList);
      for (let i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Label: `All`, Id: -1 });
      return array.filter(i => i.Id === value).map(i => i.Label)[0];
    } else if (name === 'caseStages') {
      const array = [];
      const data = toJS(MessageTemplateStore.dataCaseStage.itemList);
      for (let i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Label: `All`, Id: -1 });
      return array.filter(i => i.Id === value).map(i => i.Label)[0];
    }
  };
  render() {
    const { loading, keyword, moduleType, status, receiverAlias, caseStages } = MessageTemplateStore;
    return (
      <React.Fragment>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input value={keyword} disabled={loading} onChange={this.handleChangeSearch} />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              className="negative"
              style={{ marginLeft: '5px' }}
              onClick={this.handleReset}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              style={{ marginLeft: '5px' }}
              className="blue"
              onClick={this.handleRefresh}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                disabled={loading}
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleExportSearchResult}
              >
                Export Search Result
              </TableToolbar.HighLightButton>
            )}
            {api.isAdmin() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton
                disabled={loading}
                className="positive"
                style={{ marginLeft: '5px' }}
                onClick={this.handleOpenCaseCreate}
              >
                Add Message
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>
          <TableToolbar.Right></TableToolbar.Right>
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Filter
              label="Module type"
              options={this.renderModule()}
              value={this.renderFilterText(moduleType, 'moduleType')}
              name="moduleType"
              disabled={loading}
              onChange={this.handleChangeDropdown}
            />
            <TableToolbar.Filter
              label="Case Stage"
              options={this.renderCaseStage()}
              value={this.renderFilterText(caseStages, 'caseStages')}
              name="caseStages"
              disabled={loading}
              onChange={this.handleChangeDropdown}
            />
            <TableToolbar.Filter
              label="Status"
              name="status"
              options={this.renderStatus()}
              disabled={loading}
              value={this.renderTextFilter(status, 'status')}
              onChange={this.handleChangeDropdown}
            />
            <TableToolbar.Filter
              label="ReceiverAlias"
              name="receiverAlias"
              options={this.renderReceiverAlias()}
              value={this.renderFilterText(receiverAlias, 'receiverAlias')}
              onChange={this.handleChangeDropdown}
              disabled={loading}
            />
          </TableToolbar.Left>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default MessageTemplateToolbar;
