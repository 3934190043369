import React from 'react';
import { Form, Checkbox, DatePicker, Select, Input, Spin, InputNumber } from 'antd';
import { Message, Icon, Popup, Icon as IconSemantic } from 'semantic-ui-react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';

@observer
class DisbursementFunding extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  handleUpload = event => {
    const files = event.target.files;
    if (!files || !files.length) return;

    AddEditStore.handleUploadFile(files[0]);
  };
  handleDownloadFile = pathName => {
    window.open(`${window.location.origin}/UserFile/ClientAttachment/${pathName}`, '_blank');
  };
  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };
  render() {
    const data = toJS(AddEditStore.clientInfo);
    const attachments = this.checkIsJSON(data.FundingAttachments) ? JSON.parse(data.FundingAttachments) : null;
    const { staffList } = AddEditStore;
    return (
      <Form name="disbursement-funding-form">
        <div>
          <Checkbox
            checked={data.PreApprovedDisbursementFunding}
            onChange={AddEditStore.handleChecked('PreApprovedDisbursementFunding')}
          >
            Offer Pre-Approved Disbursement Funding
            {this.renderPopup(
              'Disbursement funding offer will be shown to Law Plaintiff when booking non-IRO funding reports (MAIL-150)',
            )}
          </Checkbox>
        </div>
        <Form.Item
          label="Pre-Approved Amount"
          required={data.PreApprovedDisbursementFunding}
          validateStatus={AddEditStore.errors['PreApprovedDisbursementFundingAmount'] ? 'error' : null}
          help={AddEditStore.errors['PreApprovedDisbursementFundingAmount']}
        >
          <InputNumber
            style={{ width: '250px' }}
            size="large"
            onChange={AddEditStore.handleFieldChange('PreApprovedDisbursementFundingAmount')}
            value={data.PreApprovedDisbursementFundingAmount}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <div className="subscribe-box">
          <Checkbox
            checked={toJS(data.IsDisbursementFunding)}
            onChange={AddEditStore.handleChecked('IsDisbursementFunding')}
          >
            Approved for Disbursement Funding
            {this.renderPopup(
              'Disbursement funding offer will be shown to Law Plaintiff when booking non-IRO funding reports (MAIL-151)',
            )}
          </Checkbox>
        </div>
        <Form.Item
          label="Approved Amount"
          required={data.IsDisbursementFunding}
          validateStatus={AddEditStore.errors['DisbursmentFundingAmount'] ? 'error' : null}
          help={AddEditStore.errors['DisbursmentFundingAmount']}
        >
          <InputNumber
            style={{ width: '250px' }}
            size="large"
            onChange={AddEditStore.handleFieldChange('DisbursmentFundingAmount')}
            value={data.DisbursmentFundingAmount}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item label="Approved By">
          <Select
            size="large"
            value={data.FundingApprovedById}
            onChange={AddEditStore.handleFieldChange('FundingApprovedById')}
          >
            {staffList &&
              this.checkIsJSON(staffList) &&
              JSON.parse(staffList).itemList.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.fullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Approved Date">
          <DatePicker
            style={{ width: '250px' }}
            size="large"
            value={this.convertDate(data.ApprovedFundingDate)}
            format="DD/MM/YYYY"
            onChange={AddEditStore.handleFieldChange('ApprovedFundingDate')}
          />
        </Form.Item>
        <Form.Item label="Funding Term start date">
          <DatePicker
            style={{ width: '250px' }}
            size="large"
            value={this.convertDate(data.StartFundingTerm)}
            format="DD/MM/YYYY"
            onChange={AddEditStore.handleFieldChange('StartFundingTerm')}
          />
        </Form.Item>
        <Form.Item label="Funding Term end date">
          <DatePicker
            style={{ width: '250px' }}
            size="large"
            value={this.convertDate(data.EndFundingTerm)}
            format="DD/MM/YYYY"
            onChange={AddEditStore.handleFieldChange('EndFundingTerm')}
          />
        </Form.Item>
        <Form.Item label="Attached Documents" className="fluid-field">
          {!attachments ? (
            <Message className="upload-box">
              <div className="upload-file">
                {AddEditStore.uploadLoading && <Spin />}
                <input type="file" onChange={this.handleUpload} />
                Click here to upload
              </div>
            </Message>
          ) : attachments.length === 0 ? (
            <Message className="upload-box">
              <div className="upload-file">
                {AddEditStore.uploadLoading && <Spin />}
                <input type="file" onChange={this.handleUpload} />
                Click here to upload
              </div>
            </Message>
          ) : (
            attachments.map((attachment, idx) => (
              <Message className="upload-box" key={idx}>
                <div className="file-info">
                  <div className="file-info-inner">
                    <Icon name="file alternate" />
                    <div className="file-name">{attachment.Title}</div>
                  </div>
                  <div className="file-actions">
                    <Icon name="download" onClick={() => this.handleDownloadFile(attachment.FileName)} />
                    <Icon name="delete" color="red" onClick={() => AddEditStore.handleDeleteFile(idx)} />
                  </div>
                </div>
              </Message>
            ))
          )}
        </Form.Item>
        <Form.Item label="Comments" className="fluid-field">
          <Input.TextArea
            rows={3}
            value={data.CommentsFunding}
            onChange={AddEditStore.handleFieldChange('CommentsFunding')}
            style={{ marginBottom: 0 }}
          />
        </Form.Item>
      </Form>
    );
  }
}

export default DisbursementFunding;
