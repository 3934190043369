import { Checkbox, DatePicker, Form, Input, notification, Radio, Select } from 'antd';
import { observer } from 'mobx-react';
import { action, computed, observable, toJS } from 'mobx';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import React from 'react';

import Modal from '../../shared/Modal';
import CKEditor from '../AddEditImeAssessments/CKEditor';
import { submitConversation, getFullNameInCC } from './service';
import store from './store';
import { conversationTypes } from './type';
import { conversationContentCategories, CREATED_BY_ROLE } from '../AddEditImeAssessments/type';
import './ConversationModalForm.scss';
import * as api from '@stores/api';
import { renderDate } from '../Shared';

@observer
class ConversationModalForm extends React.Component {
  state = {
    CallerSenderName: '',
    ReceiverName: '',
    CallerType: 0,
    ReceiverType: 0,
  };

  @observable conversation = {
    Id: 0,
    SupplementaryId: store.supplementaryInfo.Id,
    SentDate: moment(),
    Subject: `Case No: ${store.supplementaryInfo.SuppNo} - Claimant: ${store.supplementaryInfo.Claimant?.FullName}`,
    Content: '',
    ReceiverType: api.isCaseManager() || api.isDoctor() ? 3 : 1,
    Receiver: '',
    receiverId: null,
    CallerSenderType: api.isDoctor() ? 2 : 1,
    CallerSender: '',
    callerSenderId: null,
    ConversationType: api.isCaseManager() || api.isDoctor() ? 1 : 0,
    AllowCMToView: api.isCaseManager() ? true : false,
    AllowDoctorToView: api.isDoctor() ? true : false,
    AllowStaffToView: api.isAdminOrMagStaffOrAccounting() ? true : false,
    ContentCategory: 0,
    CreatedByRole: api.isCaseManager()
      ? CREATED_BY_ROLE.CaseManager
      : api.isDoctor()
      ? CREATED_BY_ROLE.Specialist
      : CREATED_BY_ROLE.Staff,
  };

  convertTypeLog = typeLog => {
    if (typeof typeLog === 'string') {
      switch (typeLog) {
        case 'Phone Call':
          return 0;
        case 'Email':
          return 1;
        case 'Relationship Meeting':
          return 2;
        case 'Others':
          return 3;
        case 'Email (Send Now)':
          return 4;
        case 'Private Notes':
          return 5;
        case 'Relationship Call':
          return 8;
        case 'Relationship Email':
          return 9;
        default:
          return 3;
      }
    } else {
      return typeLog;
    }
  };

  convertCallerReceiver = type => {
    switch (type) {
      case 'Case Manager':
        return 1;
      case 'Provider':
        return 2;
      case 'Staff':
        return 3;
      default:
        return undefined;
    }
  };

  @action componentDidMount() {
    const { data } = this.props;
    var dataObj = { ...data };
    if (!data?.Id) {
      if (this.conversation.CallerSenderType === 1 && store.bookingCMSelected?.IsActive) {
        this.conversation.callerSenderId = store.bookingCMSelected?.Id;
      }
      if (this.conversation.CallerSenderType === 2 && store.specialistSelected?.IsActive) {
        this.conversation.callerSenderId = store.specialistSelected?.Id;
      }
      if (this.conversation.CallerSenderType === 3) {
        this.conversation.callerSenderId = api.currentUser.data.id; //store.supplementaryInfo.AssignedStaffId;
      }
      if (this.conversation.ReceiverType === 1 && store.bookingCMSelected?.IsActive) {
        this.conversation.receiverId = store.bookingCMSelected?.Id;
      }
      if (this.conversation.ReceiverType === 2 && store.specialistSelected?.IsActive) {
        this.conversation.receiverId = store.specialistSelected?.Id;
      }
      if (this.conversation.ReceiverType === 3) {
        this.conversation.receiverId = api.currentUser.data.id; //store.supplementaryInfo.AssignedStaffId;
      }
      this.conversation.AllowCMToView = api.isCaseManager();
      this.conversation.AllowDoctorToView = api.isDoctor();
      this.conversation.AllowStaffToView = api.isAdminOrMagStaffOrAccounting();

      return;
    } else {
      if (dataObj.CallerSenderId) {
        if (dataObj.CallerSenderType === 'Case Manager' || dataObj.CallerSenderType === 'Provider') {
          dataObj.callerSenderId = parseInt(dataObj.CallerSenderId);
        } else {
          dataObj.callerSenderId = dataObj.CallerSenderId;
        }
      }
      if (dataObj.ReceiverId) {
        if (dataObj.ReceiverType === 'Case Manager' || dataObj.ReceiverType === 'Provider') {
          dataObj.receiverId = parseInt(dataObj.ReceiverId);
        } else {
          dataObj.receiverId = dataObj.ReceiverId;
        }
      }
      if (dataObj.CallerSenderType && !dataObj.CallerSenderId) {
        getFullNameInCC(dataObj.CallerSenderType, dataObj.CallerSender).then(resp => {
          if (resp.result) {
            this.setState({ CallerSenderName: resp.result.FullName, CallerType: resp.resultType });
          }
        });
      }
      if (dataObj.ReceiverType && !dataObj.ReceiverId) {
        getFullNameInCC(dataObj.ReceiverType, dataObj.Receiver).then(resp => {
          if (resp.result) {
            this.setState({ ReceiverName: resp.result.FullName, ReceiverType: resp.resultType });
          }
        });
      }
    }
    this.conversation = {
      ...dataObj,
      SupplementaryId: store.supplementaryInfo.Id,
      ConversationType: this.convertTypeLog(data.TypeLog),
      ReceiverType: this.convertCallerReceiver(data.ReceiverType),
      CallerSenderType: this.convertCallerReceiver(data.CallerSenderType),
    };
    // if (this.conversation.ReceiverType === 2) {
    //   this.conversation.receiverId = store.specialistSelected?.Id;
    // }
    // if (this.conversation.CallerSenderType === 2) {
    //   this.conversation.callerSenderId = store.specialistSelected?.Id;
    // }
  }

  handleSave = type => {
    store.setFieldsValue({ loading: true });
    const data = toJS(this.conversation);
    const model = {
      allowCMToView: data.AllowCMToView,
      allowDoctorToView: data.AllowDoctorToView,
      CallerSender: this.getCallerSenders.find(i => {
        return i.value === data.callerSenderId;
      })?.label,
      callerSenderId: data.callerSenderId,
      CallerSenderType: data.CallerSenderType,
      Content: data.Content,
      ConversationType: data.ConversationType,
      SupplementaryId: data.SupplementaryId,
      hour: renderDate(data.SentDate).hour(),
      Id: data.Id,
      minute: renderDate(data.SentDate).minute(),
      Receiver: this.getReceivers.find(i => {
        return i.value === data.receiverId;
      })?.label,
      receiverId: data.receiverId,
      ReceiverType: data.ReceiverType,
      SentDate: store.formatReqDate(data.SentDate),
      Subject: data.Subject,
      ContentCategory: data.ContentCategory,
      allowStaffToView: data.AllowStaffToView,
      sendEmailNotice: type === 0 ? false : true,
    };

    if (!model.Subject) {
      return store.showNotification('Error', 'Please enter subject');
    }
    if (!model.receiverId) {
      return store.showNotification('Error', 'Please select a Receiver');
    }
    if (!model.callerSenderId) {
      return store.showNotification('Error', 'Please select a Caller/Sender');
    }

    submitConversation(model).then(res => {
      store.setFieldsValue({ loading: false });
      if (res.status === 'success') {
        store.fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Add Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while saving data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
      this.props.onCancel();
    });
  };

  setFieldValue = key =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event ?? null;

      this.conversation[key] = value;

      if (key === 'receiverId') {
        this.conversation = {
          ...this.conversation,
          receiverId: value,
        };
      }
      if (key === 'callerSenderId') {
        this.conversation = {
          ...this.conversation,
          callerSenderId: value,
        };
      }
      if (key === 'ContentCategory') {
        this.conversation = {
          ...this.conversation,
          ContentCategory: value,
        };
      }
      if (key === 'ReceiverType' && value) {
        if (value === 1) {
          this.conversation.receiverId = store.bookingCMSelected?.Id;
        }
        if (value === 2) {
          this.conversation.receiverId = store.specialistSelected?.Id;
        }
        if (value === 3) {
          this.conversation.receiverId = api.currentUser.data.id; //store.supplementaryInfo.AssignedStaffId;
        }
      }
      if (key === 'CallerSenderType' && value) {
        if (value === 1) {
          this.conversation.callerSenderId = store.bookingCMSelected?.Id;
        }
        if (value === 2) {
          this.conversation.callerSenderId = store.specialistSelected?.Id;
        }
        if (value === 3) {
          this.conversation.callerSenderId = api.currentUser.data.id; //store.supplementaryInfo.AssignedStaffId;
        }
      }
    });

  toggleCheckbox = key =>
    action(event => {
      const { checked } = event.target;
      this.conversation[key] = checked;
    });

  getCallerReceivers = type => {
    switch (type) {
      case 1:
        return store.bookingCMs.map(cm => ({
          label: cm.FullName,
          value: cm.Id,
        }));
      case 2:
        const doctor = store.specialistSelected;
        return doctor
          ? [
              {
                label: doctor.FullName,
                value: doctor.Id,
              },
            ]
          : [];
      case 3:
        return store.staffs.map(staff => ({
          label: staff.FullName,
          value: staff.Id,
        }));
      default:
        return [];
    }
  };

  @computed get getReceivers() {
    const { ReceiverType } = this.conversation;
    return this.getCallerReceivers(ReceiverType);
  }

  @computed get getCallerSenders() {
    const { CallerSenderType } = this.conversation;
    return this.getCallerReceivers(CallerSenderType);
  }

  render() {
    const { visible, onCancel } = this.props;
    const { CallerSenderName, ReceiverName } = this.state;
    const notExistCallerSender =
      !this.getCallerSenders ||
      this.getCallerSenders.length <= 0 ||
      !this.getCallerSenders.find(i => i.value === this.conversation.callerSenderId);
    const notExistReceiver =
      !this.getReceivers ||
      this.getReceivers.length <= 0 ||
      !this.getReceivers.find(i => i.value === this.conversation.receiverId);
    var callType = this.state.CallerType;
    var receiveType = this.state.ReceiverType;
    var disableSaveAndSend = false;
    if (api.isAdminOrMagStaffOrAccounting() && !this.conversation.AllowCMToView && !this.conversation.AllowDoctorToView)
      disableSaveAndSend = true;
    if (api.isCaseManager() && !this.conversation.AllowStaffToView && !this.conversation.AllowDoctorToView)
      disableSaveAndSend = true;
    if (api.isDoctor() && !this.conversation.AllowCMToView && !this.conversation.AllowStaffToView)
      disableSaveAndSend = true;
    var disableEdit = false;
    var disableEditTypeAndCate = false;
    var disableEditAllow = false;
    var disableSaveButton = false;
    if ((api.isCaseManager() || api.isDoctor()) && this.conversation.IsSentNotice) {
      disableEdit = true;
      disableEditAllow = true;
      disableSaveButton = true;
    }
    if (api.isAdminOrMagStaffOrAccounting()) {
      if (this.conversation.CreatedByRole !== CREATED_BY_ROLE.Staff) {
        disableEdit = true;
        disableEditAllow = true;
        disableSaveButton = true;
        disableEditTypeAndCate = this.conversation.AllowStaffToView ? false : true;
      }
      if (this.conversation.IsSentNotice) {
        disableEdit = true;
        disableSaveAndSend = true;
      }
    }
    if (api.isCaseManager() && this.conversation.CreatedByRole !== CREATED_BY_ROLE.CaseManager) {
      disableEdit = true;
      disableEditAllow = true;
      disableSaveButton = true;
      disableEditTypeAndCate = this.conversation.AllowCMToView ? false : true;
    }
    if (api.isDoctor() && this.conversation.CreatedByRole !== CREATED_BY_ROLE.Specialist) {
      disableEdit = true;
      disableEditAllow = true;
      disableSaveButton = true;
      disableEditTypeAndCate = this.conversation.AllowDoctorToView ? false : true;
    }

    return (
      <Modal
        width={1000}
        onCancel={onCancel}
        visible={visible}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              onClick={() => this.handleSave(1)}
              disabled={this.conversation.IsAutoEmail || disableSaveAndSend || disableSaveButton}
            >
              Save & Send
            </Button>}
            {!api.isOnlySearch() && <Button color="blue" onClick={() => this.handleSave(0)} disabled={this.conversation.IsAutoEmail}>
              Save
            </Button>}
            <Button color="red" onClick={onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!!this.conversation.Id ? 'Correspondence Details' : 'Add New'}
      >
        <Form name="conversation" className="conversation-form">
          {api.isAdminOrMagStaffOrAccounting() && (
            <Form.Item>
              <Checkbox
                checked={this.conversation.AllowCMToView}
                onChange={this.toggleCheckbox('AllowCMToView')}
                disabled={disableEditAllow}
              >
                Allow CMs to view
              </Checkbox>
            </Form.Item>
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Form.Item>
              <Checkbox
                checked={this.conversation.AllowDoctorToView}
                onChange={this.toggleCheckbox('AllowDoctorToView')}
                disabled={disableEditAllow}
              >
                Allow Specialists/Clinics to view
              </Checkbox>
            </Form.Item>
          )}
          {(api.isCaseManager() || api.isDoctor()) && (
            <Form.Item>
              <Checkbox
                checked={this.conversation.AllowStaffToView}
                onChange={this.toggleCheckbox('AllowStaffToView')}
                disabled={disableEditAllow}
              >
                Allow MLP Staff to view
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item label="Correspondence Type">
            <Select
              size="large"
              value={this.conversation.ConversationType}
              onChange={this.setFieldValue('ConversationType')}
              disabled={disableEditTypeAndCate}
            >
              {conversationTypes
                .filter(i => !i.visible)
                .map(({ label, value }) => (
                  <Select.Option style={{ display: 'none' }} key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              {conversationTypes
                .filter(i => i.visible)
                .map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Content Category">
            <Select
              size="large"
              value={this.conversation.ContentCategory}
              onChange={this.setFieldValue('ContentCategory')}
              disabled={disableEditTypeAndCate}
            >
              {conversationContentCategories.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={`Date/Time - UTC${moment.tz(moment.tz.guess()).format('Z')}`}>
            <DatePicker
              disabled={disableEdit}
              size="large"
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY - HH:mm"
              value={renderDate(this.conversation.SentDate)}
              onChange={this.setFieldValue('SentDate')}
            />
          </Form.Item>

          <Form.Item
            label="Subject"
            required
            validateStatus={!this.conversation.Subject ? 'error' : null}
            help={!this.conversation.Subject ? 'This field is required' : ''}
          >
            <Input
              size="large"
              value={this.conversation.Subject}
              onChange={this.setFieldValue('Subject')}
              disabled={disableEdit}
            />
          </Form.Item>
          <Form.Item label="Content">
            <CKEditor
              html={this.conversation.Content}
              handleChangeContent={this.setFieldValue('Content')}
              disabled={disableEdit}
            />
          </Form.Item>
          <Form.Item label="Caller/Sender" required>
            <Radio.Group
              className="mb-1"
              value={this.conversation.CallerSenderType || callType}
              onChange={this.setFieldValue('CallerSenderType')}
              disabled={disableEdit}
            >
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()} value={1}>
                Case Manager
              </Radio>
              <Radio
                disabled={!store.specialistSelected || (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor())}
                value={2}
              >
                Specialist
              </Radio>
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting()} value={3}>
                Staff
              </Radio>
            </Radio.Group>
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              value={this.conversation.callerSenderId || -1}
              onChange={this.setFieldValue('callerSenderId')}
              disabled={this.conversation.CallerSenderType === 2 || disableEdit}
            >
              {notExistCallerSender && (
                <Select.Option key={'none'} value={-1}>
                  {CallerSenderName}
                </Select.Option>
              )}
              {this.getCallerSenders.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Receiver" required>
            <Radio.Group
              className="mb-1"
              value={this.conversation.ReceiverType || receiveType}
              onChange={this.setFieldValue('ReceiverType')}
              disabled={disableEdit}
            >
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting()} value={1}>
                Case Manager
              </Radio>
              <Radio disabled={!api.isAdminOrMagStaffOrAccounting() || !store.specialistSelected} value={2}>
                Specialist
              </Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              value={this.conversation.receiverId || -1}
              onChange={this.setFieldValue('receiverId')}
              disabled={this.conversation.ReceiverType === 2 || disableEdit}
            >
              {notExistReceiver && (
                <Select.Option key={'none'} value={-1}>
                  {ReceiverName}
                </Select.Option>
              )}
              {this.getReceivers.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ConversationModalForm;
