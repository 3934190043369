import { observer } from 'mobx-react';
import React from 'react';

const apiConnectQuery = query => Component => {
  @observer
  class Outer extends React.Component {
    componentDidMount() {
      query.fetch();
    }
    componentDidUpdate() {
      if (!query.fetched) {
        query.refetch();
      }
    }
    render() {
      // Access to this property so the componentDidUpdate method
      //    will be called when this property get changed
      void query.fetched;
      return <Component {...this.props} />;
    }
  }
  return Outer;
};

export default apiConnectQuery;
