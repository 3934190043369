import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import CMReportStore from './CMReportStore';
import CMReportToolbar from './CMReportToolbar';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import moment from 'moment';

const columns = [
  { title: 'Case No' },
  { title: 'Case Status' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Specialist', sortKey: 'Specialist' },
  { title: 'Claim/Report Type' },
  { title: 'Status Date' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Client' },
];

@observer
class ModalBookingHistory extends React.Component {
  renderTableHistory = () =>
    CMReportStore.dataCMReports.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{record.CaseNo}</Table.Cell>
        <Table.Cell>{record.CaseStatus}</Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'specialist',
                id: record.DoctorId,
                localStorageName: 'DoctorName',
                localStorageValue: record.DoctorFullName,
              })
            }
          >
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorFullName}{' '}
            {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
          </Link>
          <div style={{ whiteSpace: 'nowrap' }}>Specialty: {record.DoctorSepcialty}</div>
          <div>
            <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
          </div>
          <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
            Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
          </div>
        </Table.Cell>
        <Table.Cell>{record.ClaimReportType}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: CMReportStore.CMId,
                localStorageName: 'CmName',
                localStorageValue: record.CMFullName,
              })
            }
          >
            {record.CMFullName}
          </Link>
          <div>{record.CMEmail ? `${record.CMEmail}` : ''}</div>
          <div>{record.CMPhone ? `${record.CMPhone}` : ''}</div>
        </Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
      </Table.Row>
    ));

  checkDisabled = () => {
    const { loadingCMReports, dataCMReports } = CMReportStore;
    if (loadingCMReports) {
      return loadingCMReports;
    } else {
      if (dataCMReports === null) {
        return true;
      } else {
        if (!dataCMReports.itemList || dataCMReports.itemList.length === 0) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { dataCMReports } = CMReportStore;
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <CMReportToolbar />
        <Table sortable striped celled className={cx({ disabled: this.checkDisabled() })}>
          <TableHeader
            columns={columns}
            sortColumn={CMReportStore.sortColumnBooking}
            sortDirection={CMReportStore.sortDirectionBooking}
            onSort={CMReportStore.handleSortBooking}
          />
          <Table.Body>
            {dataCMReports === null ? (
              <TableEmptyMessage colSpan={colSpan} />
            ) : !!dataCMReports.itemList && dataCMReports.itemList.length ? (
              this.renderTableHistory()
            ) : (
              <TableEmptyMessage colSpan={colSpan} />
            )}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default ModalBookingHistory;
