import React from 'react';
import {
  Form,
  DatePicker,
  Select,
  Divider,
  Input,
  Radio,
  InputNumber,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
  Collapse,
} from 'antd';
import { Icon as IconSemantic, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { toJS, action } from 'mobx';
import moment from 'moment';

import store from './store';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';
import FieldUpload from './FieldUpload';
import { Header, HeaderWithoutCheck } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';
import dashboardStore from '@stores/dashboard';

@observer
class ClaimDetailsForm extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  loadCaseFromOriginal = viewModel => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      store.setFieldsValue({
        isServicePrepaymentLocked: false,
        isLockReport_CM: false,
      });
    } else if (api.isCaseManager()) {
      // CM is not allow to modify Report
      store.setFieldsValue({ isLockReport: true });
      store.setFieldsValue({ isServicePrepaymentLocked: true });
    } else if (api.isDoctor()) {
      // Doctor is not allow to modify paperwork
      store.setFieldsValue({ isLockPaperword: true });
      store.setFieldsValue({ isLockReport_CM: true });
      store.setFieldsValue({ isServicePrepaymentLocked: false });
    } else if (api.isTypist()) {
      store.setFieldsValue({ isLockPaperword: true });
      store.setFieldsValue({ isLockReport_CM: true });
      store.setFieldsValue({ isServicePrepaymentLocked: false });
      store.setFieldsValue({ isLockReport: true });
    }
    if (!!viewModel.CancelledChargeStatus && viewModel.CancelledChargeStatus === 4) {
      store.setFieldsValue({ IsExpectedCompletionDate: true });
    } else {
      store.setFieldsValue({ IsExpectedCompletionDate: false });
    }
    if (api.isCaseManager()) {
      store.setFieldsValue({ isLockPaperword: viewModel.IsPaperworkReceived });
      if (viewModel.ChargeStatus !== 4) {
        store.setFieldsValue({ isLockPaperword: true });
      }
    }

    if (api.isDoctor()) {
      if (viewModel.ChargeStatus !== 4) {
        store.setFieldsValue({ isLockReport: true });
      }
    }
    if (api.isCaseManager() && !!viewModel.Id) {
      if (viewModel.ChargeStatus !== 4) {
        store.setFieldsValue({ isLockReport_CM: true });
      }
    }
    const { Doctor, AssessedDoctor } = viewModel;

    store.handleChangeSearchOpt(
      {
        companyId: viewModel.Client ? viewModel.Client.Id || null : null,
      },
      'searchOptBookingCM',
    );
    store.handleChangeSearchOpt(
      {
        keyword: viewModel.Claimant ? viewModel.Claimant.FullName : null,
      },
      'searchOptClaimant',
    );
    store.handleChangeSearchOpt(
      {
        companyId: viewModel.Client ? viewModel.Client.Id || null : null,
      },
      'searchOptBookingSolicitor',
    );
    store.handleChangeSearchOpt(
      {
        companyId: viewModel.ServiceNotesandFeeObj ? viewModel.ServiceNotesandFeeObj.PayingClientId || null : null,
      },
      'searchOptPayingCM',
    );
    store.handleChangeSearchOpt(
      {
        companyId: viewModel.PClient ? viewModel.PClient?.Id || null : null,
      },
      'searchOptPCM',
    );
    store.handleChangeSearchOpt(
      {
        keyword: Doctor ? Doctor.Id : 0,
      },
      'searchOptOriginalSpecialist',
    );
    store.handleChangeSearchOpt(
      {
        keyword: AssessedDoctor ? AssessedDoctor.Id : '',
      },
      'searchOptSpecialist',
    );
    store.setFieldsValue({
      loading: true,
      supplementaryInfo: {
        ...viewModel,
        DoctorId: viewModel.Doctor ? viewModel.Doctor.Id || null : null,
        AssessedDoctorId: viewModel.AssessedDoctor ? viewModel.AssessedDoctor.Id || null : null,
        ClientId: viewModel.Client ? viewModel.Client.Id || null : null,
        CaseManagerId: viewModel.CaseManager ? viewModel.CaseManager.Id || null : null,
        BookingSolicitorId: viewModel.BookingSolicitor ? viewModel.BookingSolicitor.Id || null : null,
        PClientId: viewModel.PClient ? viewModel.PClient.Id || null : null,
        PCaseManagerId: viewModel.PCaseManager ? viewModel.PCaseManager.Id || null : null,
        SubBookingCM: [viewModel.SubCaseManager1, viewModel.SubCaseManager3, viewModel.SubCaseManager2].filter(i => i),
        ClaimantId: viewModel.Claimant ? viewModel.Claimant.Id || null : null,
        ServiceNotesandFeeObj: {
          AdminExtraFeeAmount: null,
          AdminExtraFeeTaxPercentage: null,
          CReferenceId: null,
          CommissionOnTopRate: null,
          CommissionRate: null,
          CommissionRateType: 0,
          Description: null,
          HasOtherFee: false,
          IncludeAdminExtraFeeToDoctorCommission: false,
          IncludeManualFee1ToDoctorCommission: false,
          IncludeServiceItemFeeToDoctorCommission: false,
          InvoiceAttachment: null,
          OtherFee1Description: null,
          OtherFee1Label: null,
          OtherFee1TaxPercentage: null,
          OtherFee1Value: null,
          OtherFee2Description: null,
          OtherFee2Label: null,
          OtherFee2TaxPercentage: null,
          OtherFee2Value: null,
          PayingCaseManagerId: null,
          PayingClientId: null,
          SameBookingCM: false,
          SameClientAccEmail: false,
          SendInvoice: null,
          SendInvoice2: null,
          SelectedServiceItemIds: '[]',
          ListServiceItems: null,
        },
      },
    });

    const { supplementaryInfo } = store;

    store.handleChangeServiceNotesandFeeObj({ CommissionRateType: 0 });
    store.handleChangeSuppValue({
      ChargeStatus: 4,
      CancelledChargeStatus: 4,
      DateRequest: moment(),
      ReasonCancellation: 1,
      NotifyCancellationToCM: true,
      F_RDCSameBookingCM: true,
      //RequestExpectedCompletionDate: moment(supplementaryInfo.DateRequest).add(7, 'days'),
      AssignedStaffId: api.currentUser.data.id,
      PICcancelled: api.currentUser.data.id,
      PICcompleted: api.currentUser.data.id,
      PICsentToProvider: api.currentUser.data.id,
      PICcorrectedReport: api.currentUser.data.id,
      SelectedPaperworkToDoctorBies: [],
      DictationOption: 1,
    });
  };

  onOriginalCaseNoBlur = () => {
    const { supplementaryInfo } = store; //data
    if (!supplementaryInfo.OriginalCaseNoInput) {
      return;
    }
    return customFetch('/Supplementary/CheckOriginalCaseNo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ caseNo: supplementaryInfo.OriginalCaseNoInput }),
    }).then(response => {
      if (response.status === 'success') {
        if (response.exist) {
          let existItems = '';
          response.listExist.forEach(i => {
            existItems += i.CaseNo + ' - ' + i.StatusStaffText + ', ';
          });
          let message = '';
          if (response.listExist.length > 1) {
            message =
              'Supplementary requests (' +
              existItems.substring(0, existItems.length - 2) +
              ') have already been created for this case. Do you want to proceed?';
          } else {
            message =
              'A supplementary request (' +
              existItems.substring(0, existItems.length - 2) +
              ') has already been created for this case. Do you want to proceed?';
          }
          store.toggleModalConfirm(true, {
            message,
            onOk: () => {
              store.setFieldsValue({
                loading: true,
              });
              this.loadCaseFromOriginal(response.viewModel);
              setTimeout(() => {
                store.setFieldsValue({
                  loading: false,
                });
              });
            },
            functionCallbackCancel: () => {
              store.handleChangeSuppValue({ OriginalCaseNoInput: '' });
            },
          });
        } else {
          store.setFieldsValue({
            loading: true,
          });
          this.loadCaseFromOriginal(response.viewModel);
          setTimeout(() => {
            store.setFieldsValue({
              loading: false,
            });
          });
        }
      } else {
        store.toggleModalConfirm(true, {
          hideWarningIcon: true,
          message: 'Original report not found in current system',
        });
        store.handleChangeSuppValue({ OriginalCaseNoInput: '' });
      }
    });
  };

  renderDoctorProviderNote = doctorInfo => {
    if (!doctorInfo) return [];
    return [
      {
        no: doctorInfo.ProviderNumber,
        note: doctorInfo.ProviderNumberNote,
      },
      {
        no: doctorInfo.ProviderNumber2,
        note: doctorInfo.ProviderNumber2Note,
      },
      {
        no: doctorInfo.ProviderNumber3,
        note: doctorInfo.ProviderNumber3Note,
      },
      {
        no: doctorInfo.ProviderNumber4,
        note: doctorInfo.ProviderNumber4Note,
      },
    ];
  };

  @action handleChangeModeMultipleObj = (_, event, fieldName, lengthLimit) => {
    const data = event.map(i => i.props.data);
    const data_ = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null)).filter(i => i.Id);
    if (lengthLimit) {
      if (data_.length <= lengthLimit) {
        if (fieldName === 'SubBookingCMId') {
          return store.handleChangeSuppValue({ SubBookingCM: data_ });
        }
        return (store[fieldName] = data_);
      } else return;
    }
    return (store[fieldName] = data_);
  };

  openOriginalCase = () => {
    const itemModel = toJS(store.supplementaryInfo);
    if (itemModel.CaseNo?.startsWith('A') && itemModel.AssessmentId) {
      localStorage.setItem('AssessmentCaseId', `Case ${itemModel.CaseNo}`);
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${itemModel.AssessmentId}&step=0`);
      });
    } else if (itemModel.CaseNo?.startsWith('F') && itemModel.FileReviewId) {
      localStorage.setItem('FileReviewCaseId', `Case ${itemModel.CaseNo}`);
      dashboardStore.close('/view/add-edit-file-review-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-file-review-2?id=${itemModel.FileReviewId}&step=0`);
      });
    }
  };

  render() {
    const { renderPopup } = this;
    const {
      supplementaryInfo,
      specialists,
      suppliers,
      specialistSelected,
      originalSpecialistSelected,
      originalSpecialists,
      bookingClients,
      bookingClientSelected,
      bookingCMSelected,
      bookingCMs,
      bookingSolicitors,
      bookingSolicitorSelected,
      deliverySecondCMs,
      pClientSelected,
      pClients,
      pCMSelected,
      pCMs,
      subBookingCMs,
      claimantSelected,
      claimants,
      typeOfClaims,
      additionalServiceSupplierObj,
      bookingSubClients,
      bookingSubClientSelected,
    } = store; //data
    const {
      handleChangeDatePicker,
      handleChangeFieldInput,
      itemToArray,
      handleChangeFieldSelect,
      handleChangeFieldRadio,
      handleSearch,
      handleScroll,
      handleChangeFieldInputNumber,
      handleChangeFieldCheckbox,
      arrayToArray,
      handleOpenAddEdit,
    } = store; //function
    const itemModel = toJS(supplementaryInfo);
    const originalCaseCompletedAttachments = itemModel.Attachments ? itemModel.Attachments.filter(att => att.Type === 22) : [];
    const originalCaseAmendedAttachments = itemModel.Attachments ? itemModel.Attachments.filter(att => att.Type === 23) : [];

    return (
      <Form name="claim-details" className="grid-style">
        {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 1
                <button className="field-actions" onClick={() => store.openModalHistory('PostSupplementary')}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={supplementaryInfo.AssignedStaffId || undefined}
              onChange={handleChangeFieldSelect('AssignedStaffId')}
            >
              {store.staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <>
              Request Date{' '}
              {api.isAdminOrMagStaffOrAccounting() &&
                renderPopup(
                  'Supplementary Reports request confirmation will be sent to CMs (MAIL-85) and specialists (MAIL-86)',
                )}
            </>
          }
        >
          <DatePicker
            size="large"
            value={renderDate(itemModel.DateRequest)}
            format="DD/MM/YYYY"
            disabled={itemModel.IsLocked}
            onChange={handleChangeDatePicker('DateRequest')}
          />
        </Form.Item>
        <Divider className="fluid-field" />
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <HeaderWithoutCheck
                title="Original Report"
                text={
                  originalSpecialistSelected
                    ? `${originalSpecialistSelected.Title} ${originalSpecialistSelected.FullName}`
                    : ''
                }
                isRequired={false}
              />
            }
          >
            <Form.Item label="Specialist">
              <Select
                size="large"
                value={itemModel.DoctorId || undefined}
                onChange={handleChangeFieldSelect('DoctorId')}
                disabled={!!itemModel.CaseNo || itemModel.IsLocked}
                onSearch={handleSearch('searchOptOriginalSpecialist')}
                onPopupScroll={handleScroll('searchOptOriginalSpecialist')}
                showSearch
                allowClear
                filterOption="children"
              >
                {itemToArray(originalSpecialistSelected, originalSpecialists, 'Id').map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.Title} {i.FullName} {api.isAdminOrMagStaffOrAccounting() ? '-' + i.BusinessEmail : ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Provider No">
              <Select
                size="large"
                value={itemModel.SelectedDoctorProviderNoIdx || null}
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={handleChangeFieldSelect('SelectedDoctorProviderNoIdx')}
                disabled={!!itemModel.CaseNo || itemModel.IsLocked || !originalSpecialistSelected?.Id}
              >
                {this.renderDoctorProviderNote(originalSpecialistSelected).map(({ no, note }, idx) =>
                  no ? (
                    <Select.Option key={no} value={idx + 1}>
                      {no} {note ? ` - ${note}` : ''}
                    </Select.Option>
                  ) : null,
                )}
              </Select>
            </Form.Item>
            {!itemModel.CaseNo ? (
              <Form.Item
                label={
                  <>
                    Original Case No{' '}
                    <button className={'field-actions'} onClick={this.onOriginalCaseNoBlur}>
                      Check
                    </button>
                  </>
                }
              >
                <Input
                  size="large"
                  value={itemModel.OriginalCaseNoInput}
                  onChange={handleChangeFieldInput('OriginalCaseNoInput')}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <>
                    Original Case No{' '}
                    <button className={'field-actions'} onClick={this.openOriginalCase}>
                      View
                    </button>
                  </>
                }
              >
                <Input size="large" disabled value={itemModel.CaseNo} />
              </Form.Item>
            )}
            <Form.Item label="Report Completed Date">
              <DatePicker
                size="large"
                value={renderDate(itemModel.CompletionDate)}
                disabled={itemModel.IsLocked}
                format="DD/MM/YYYY"
                onChange={handleChangeDatePicker('CompletionDate')}
              />
            </Form.Item>
            {(api.isAdminOrMagStaffOrAccounting() || api.isTypist()) && <Form.Item label="Attach Final Report" className="add-file-container posstion-IsTypist-label">
              <FieldUpload
                multiple
                type={22}
                disabled={true}
                disabledDelete={true}
              />
            </Form.Item>}
            {(api.isAdminOrMagStaffOrAccounting() || api.isTypist()) && <Form.Item label="Attached Amended Report" className="add-file-container posstion-IsTypist-label">
              <FieldUpload
                multiple
                type={23}
                disabled={true}
                disabledDelete={true}
              />
            </Form.Item>}
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <HeaderWithoutCheck
                title="Assessing Specialist"
                text={
                  specialistSelected
                    ? `${specialistSelected.Title} ${specialistSelected.FullName} - ${specialistSelected.Specialty}`
                    : ''
                }
                isRequired={false}
              />
            }
          >
            <Form.Item
              required={api.isAdminOrMagStaffOrAccounting()}
              validateStatus={api.isAdminOrMagStaffOrAccounting() && !itemModel.AssessedDoctorId ? 'error' : null}
              help={api.isAdminOrMagStaffOrAccounting() && !itemModel.AssessedDoctorId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Specialist
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'AssessedDoctorId')}>
                            Add Specialist
                          </Menu.Item>
                          {!!itemModel.AssessedDoctorId && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'AssessedDoctorId')}>
                              Edit Specialist
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.AssessedDoctorId || undefined}
                onChange={handleChangeFieldSelect('AssessedDoctorId')}
                disabled={store.isLockPaperword || api.isTypist()}
                onSearch={handleSearch('searchOptSpecialist')}
                onPopupScroll={handleScroll('searchOptSpecialist')}
                showSearch
                allowClear
                filterOption="children"
              >
                {itemToArray(specialistSelected, specialists, 'Id').map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.Title} {i.FullName} {api.isAdminOrMagStaffOrAccounting() ? '-' + i.BusinessEmail : ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Provider No">
              <Select
                size="large"
                value={itemModel.SelectedAssessedDoctorProviderNoIdx || null}
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={handleChangeFieldSelect('SelectedAssessedDoctorProviderNoIdx')}
                disabled={itemModel.IsLocked || !specialistSelected?.Id}
              >
                {this.renderDoctorProviderNote(specialistSelected).map(({ no, note }, idx) =>
                  no ? (
                    <Select.Option key={no} value={idx + 1}>
                      {no} {note ? ` - ${note}` : ''}
                    </Select.Option>
                  ) : null,
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Claimant Status">
              <Radio.Group
                value={itemModel.ClaimantStatus}
                disabled={store.isLockPaperword}
                onChange={handleChangeFieldRadio('ClaimantStatus')}
              >
                <Radio value={0}>Old Claimant</Radio>
                <Radio value={1}>New Claimant</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Dictation and Report Typing by">
              <Radio.Group
                value={itemModel.DictationOption}
                onChange={handleChangeFieldRadio('DictationOption')}
                disabled={store.isLockReport}
              >
                <Radio value={0}>Medicolegal Provider</Radio>
                <Radio value={1}>Specialist</Radio>
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <HeaderWithoutCheck
                title="Booking Client and CM"
                statuses={[
                  {
                    key: 'supp-bc',
                    text: bookingClientSelected?.Name ? `Client: ${bookingClientSelected.Name}` : '',
                  },
                  {
                    key: 'supp-bcm',
                    text: bookingCMSelected?.FullName ? `Booking CM: ${bookingCMSelected.FullName}` : '',
                  },
                ]}
              />
            }
          >
            <Form.Item
              required
              validateStatus={!itemModel.ClientId ? 'error' : null}
              help={!itemModel.ClientId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking Client
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'ClientId')}>Add Client</Menu.Item>
                          {!!itemModel.ClientId && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'ClientId')}>Edit Client</Menu.Item>
                          )}
                          <Menu.Item onClick={() => store.openModalCompanyHistory()}>Recent Changes</Menu.Item>
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.ClientId || undefined}
                onChange={handleChangeFieldSelect('ClientId')}
                disabled={!api.isAdminOrMagStaffOrAccounting()} //|| itemModel.CaseStatus > api.suppStatusEnum.REPORT_COMPLETED
                allowClear
                onSearch={handleSearch('searchOptBookingClient')}
                onPopupScroll={handleScroll('searchOptBookingClient')}
                showSearch
                filterOption="children"
              >
                {itemToArray(bookingClientSelected, bookingClients, 'Id').map(({ Id, Name, StateName }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {Name} - {StateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required={!api.isDoctor()}
              validateStatus={!itemModel.CaseManagerId ? 'error' : null}
              help={!itemModel.CaseManagerId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking CM
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      disabled={!itemModel.ClientId}
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'CaseManagerId')}>Add CM</Menu.Item>
                          {!!itemModel.CaseManagerId && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'CaseManagerId')}>Edit CM</Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.CaseManagerId || undefined}
                onChange={handleChangeFieldSelect('CaseManagerId')}
                disabled={!api.isAdminOrMagStaffOrAccounting()} //|| itemModel.CaseStatus > api.suppStatusEnum.REPORT_COMPLETED
                onSearch={handleSearch('searchOptBookingCM')}
                onPopupScroll={handleScroll('searchOptBookingCM')}
                showSearch
                allowClear
                filterOption="children"
              >
                {itemToArray(bookingCMSelected, bookingCMs, 'Id').map(({ Id, FullName, Email }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Booking Solicitor">
              <Select
                size="large"
                value={itemModel.BookingSolicitorId || undefined}
                onChange={handleChangeFieldSelect('BookingSolicitorId')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                onSearch={handleSearch('searchOptBookingSolicitor')}
                onPopupScroll={handleScroll('searchOptBookingSolicitor')}
                showSearch
                allowClear
                filterOption="children"
              >
                {itemToArray(bookingSolicitorSelected, bookingSolicitors, 'Id').map(({ Id, FullName, Email }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label=""></Form.Item>
            {api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() ? (
              <React.Fragment>
                <Form.Item label="Sub-Booking Client">
                  <Select
                    size="large"
                    value={itemModel.SubClientId || undefined}
                    onChange={handleChangeFieldSelect('SubClientId')}
                    allowClear
                    onSearch={handleSearch('searchOptBookingSubClient')}
                    onPopupScroll={handleScroll('searchOptBookingSubClient')}
                    showSearch
                    filterOption="children"
                    disabled={api.isCaseManager()}
                  >
                    {itemToArray(bookingSubClientSelected, bookingSubClients, 'Id').map(({ Id, Name, StateName }) => (
                      <Select.Option value={Id} key={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Sub-Booking CM / Solicitor">
                  <Select
                    size="large"
                    value={itemModel.SubBookingCM.map(i => i.Id) || []}
                    mode="multiple"
                    showArrow
                    onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'SubBookingCMId', 3)}
                    disabled={itemModel.IsLocked || api.isCaseManager()}
                    onSearch={handleSearch('searchOptSubBookingCMs')}
                    onPopupScroll={handleScroll('searchOptSubBookingCMs')}
                    showSearch
                    filterOption="children"
                    allowClear
                    autoClearSearchValue
                  >
                    {arrayToArray(
                      [itemModel.SubCaseManager1, itemModel.SubCaseManager3, itemModel.SubCaseManager2].filter(i => i),
                      subBookingCMs,
                      'Id',
                    ).map(i => (
                      <Select.Option value={i.Id} key={i.Id} data={JSON.stringify(i)}>
                        {i.Id} - {i.FullName} - {i.Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </React.Fragment>
            ) : (
              <div />
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>
                        Paying Client
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item onClick={() => handleOpenAddEdit('add', 'PClientId')}>
                                Add Paying Client
                              </Menu.Item>
                              {!!itemModel.PClientId && (
                                <Menu.Item onClick={() => handleOpenAddEdit('edit', 'PClientId')}>
                                  Edit Paying Client
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <button className="field-actions">
                            Add/Edit <Icon type="down" />
                          </button>
                        </Dropdown>
                        {':'}
                      </span>
                      <Checkbox
                        checked={itemModel.PCSameBookingClient}
                        className="checkbox-IsTypist"
                        onChange={handleChangeFieldCheckbox('PCSameBookingClient')}
                        disabled={itemModel.IsLocked}
                      >
                        Same as Booking Client
                      </Checkbox>
                    </span>
                  }
                >
                  <Select
                    size="large"
                    value={itemModel.PClientId || undefined}
                    onChange={handleChangeFieldSelect('PClientId')}
                    disabled={itemModel.IsLocked || itemModel.PCSameBookingClient}
                    onSearch={handleSearch('searchOptPClient')}
                    onPopupScroll={handleScroll('searchOptPClient')}
                    showSearch
                    allowClear
                    filterOption="children"
                  >
                    {itemToArray(pClientSelected, pClients, 'Id').map(({ Id, Name, StateName }) => (
                      <Select.Option value={Id} key={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <React.Fragment>
                      <span style={{ marginRight: '10px' }}>
                        Paying CM
                        <Dropdown
                          disabled={!itemModel.PClientId}
                          overlay={
                            <Menu>
                              <Menu.Item onClick={() => handleOpenAddEdit('add', 'PCaseManagerId')}>
                                Add Paying CM
                              </Menu.Item>
                              {!!itemModel.PCaseManagerId && (
                                <Menu.Item onClick={() => handleOpenAddEdit('edit', 'PCaseManagerId')}>
                                  Edit Paying CM
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <button className="field-actions">
                            Add/Edit <Icon type="down" />
                          </button>
                        </Dropdown>
                        {':'}
                      </span>
                      <Checkbox
                        checked={itemModel.PMSameBookingCM}
                        className="checkbox-IsTypist"
                        onChange={handleChangeFieldCheckbox('PMSameBookingCM')}
                        disabled={itemModel.IsLocked}
                      >
                        Same as Booking CM
                      </Checkbox>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    value={itemModel.PCaseManagerId || undefined}
                    onChange={handleChangeFieldSelect('PCaseManagerId')}
                    disabled={itemModel.IsLocked || itemModel.PMSameBookingCM}
                    onSearch={handleSearch('searchOptPCM')}
                    onPopupScroll={handleScroll('searchOptPCM')}
                    showSearch
                    allowClear
                    filterOption="children"
                  >
                    {itemToArray(pCMSelected, pCMs, 'Id').map(({ Id, FullName, Email }) => (
                      <Select.Option value={Id} key={Id}>
                        {Id} - {FullName} - {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </>
            )}{' '}
            <Form.Item label="Purchase Order No">
              <Input
                size="large"
                value={itemModel.PurchaseOrderNo}
                onChange={handleChangeFieldInput('PurchaseOrderNo')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
              />
            </Form.Item>
            <Form.Item label="Authorised Purchase Amount">
              <InputNumber
                size="large"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={itemModel.AuthorisedPurchaseAmount}
                onChange={handleChangeFieldInputNumber('AuthorisedPurchaseAmount')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || api.isTypist()}
              />
            </Form.Item>
            <Form.Item
              className="posstion-IsTypist-label"
              label={
                <span>
                  <span style={{ marginRight: '10px' }}>Client Receiving Contact 1:</span>
                  {!api.isDoctor() && (
                    <Checkbox
                      checked={itemModel.F_RDCSameBookingCM}
                      className="checkbox-IsTypist"
                      onChange={handleChangeFieldCheckbox('F_RDCSameBookingCM')}
                      disabled={(store.isLockReport_CM && !bookingCMSelected?.Id) || api.isTypist()}
                    >
                      Same as Booking CM
                    </Checkbox>
                  )}
                </span>
              }
            >
              <Select
                size="large"
                value={itemModel.F_RDCCaseManagerId || undefined}
                onChange={handleChangeFieldSelect('F_RDCCaseManagerId')}
                disabled={store.isLockReport_CM || itemModel.F_RDCSameBookingCM || api.isTypist()}
                onSearch={handleSearch('searchOptBookingCM')}
                onPopupScroll={handleScroll('searchOptBookingCM')}
                showSearch
                allowClear
                filterOption="children"
              >
                {itemToArray(store.deliveryContactFirstSelected, bookingCMs, 'Id').map(({ Id, FullName, Email }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Client Receiving Contact 2">
              <Select
                size="large"
                value={itemModel.S_RDCCaseManagerId || undefined}
                onChange={handleChangeFieldSelect('S_RDCCaseManagerId')}
                disabled={store.isLockReport_CM || api.isTypist()}
                onSearch={handleSearch('searchOptReportDeliveryContactSecond')}
                onPopupScroll={handleScroll('searchOptReportDeliveryContactSecond')}
                showSearch
                allowClear
                filterOption="children"
              >
                {deliverySecondCMs.map(({ Id, FullName, Email }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Additional Service Supplier">
                <Select
                  size="large"
                  value={itemModel.AdditionalServiceSupplierId || undefined}
                  onChange={handleChangeFieldSelect('AdditionalServiceSupplierId')}
                  disabled={store.isLockReport_CM || api.isTypist()}
                  onSearch={handleSearch('searchOptSuppliers')}
                  onPopupScroll={handleScroll('searchOptSuppliers')}
                  showSearch
                  allowClear
                  filterOption="children"
                >
                  {itemToArray(additionalServiceSupplierObj, suppliers, 'Id').map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.Title} {i.FullName} - {i.BusinessEmail}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            header={
              <HeaderWithoutCheck
                title="Claimant Info"
                text={
                  claimantSelected
                    ? `${claimantSelected.FullName} - DoB: ${renderDate(claimantSelected.DOB || '', false, false)}`
                    : ''
                }
              />
            }
          >
            <Form.Item
              required
              validateStatus={!itemModel.ClaimantId ? 'error' : null}
              help={!itemModel.ClaimantId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Claimant
                  {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'ClaimantId')}>Add Claimant</Menu.Item>
                          {!!itemModel.ClaimantId && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'ClaimantId')}>Edit Claimant</Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.ClaimantId || undefined}
                onChange={handleChangeFieldSelect('ClaimantId')}
                disabled={itemModel.IsLocked}
                onSearch={handleSearch('searchOptClaimant')}
                onPopupScroll={handleScroll('searchOptClaimant')}
                showSearch
                filterOption="children"
                allowClear
              >
                {itemToArray(claimantSelected, claimants, 'Id').map(({ Id, FullName, Title }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {Title} {FullName} - {renderDate(claimantSelected?.DOB || '', false, false)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Birth">
              <Input size="large" disabled value={renderDate(claimantSelected?.DOB || '', false, false)} />
            </Form.Item>
            <Form.Item label="Mobile phone">
              <Input size="large" disabled value={claimantSelected?.Telephone} />
            </Form.Item>
            <Form.Item label="Email">
              <Input size="large" disabled value={claimantSelected?.Email} />
            </Form.Item>
            <div />
          </Collapse.Panel>
          <Collapse.Panel
            key="5"
            header={
              <HeaderWithoutCheck
                title="Claim No - Claim Type"
                text={itemModel.ClaimNo?.length > 0 ? `Claim No: ${itemModel.ClaimNo}` : ''}
              />
            }
          >
            <Form.Item
              label="Reference/Claim No"
              required
              validateStatus={!itemModel.ClaimNo ? 'error' : null}
              help={!itemModel.ClaimNo ? 'This field is required' : ''}
            >
              <Input
                size="large"
                disabled={itemModel.IsLocked}
                value={itemModel.ClaimNo}
                onChange={handleChangeFieldInput('ClaimNo')}
              />
            </Form.Item>
            <Form.Item label="Client File No">
              <Input
                size="large"
                disabled={itemModel.IsLocked}
                value={itemModel.ClientRequestNo}
                onChange={handleChangeFieldInput('ClientRequestNo')}
              />
            </Form.Item>
            <Form.Item
              label="Type of Claim"
              required
              validateStatus={!itemModel.ReportId ? 'error' : null}
              help={!itemModel.ReportId ? 'This field is required' : ''}
            >
              <Select
                size="large"
                value={itemModel.ReportId || undefined}
                onChange={handleChangeFieldSelect('ReportId')}
                disabled={itemModel.IsLocked}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {typeOfClaims && itemModel.ReportId && !typeOfClaims.find(i => i.Id === itemModel.ReportId) && (
                  <Select.Option key={itemModel.TypeOfClaim.Id} value={itemModel.TypeOfClaim.Id}>
                    {itemModel.TypeOfClaim.Name}
                  </Select.Option>
                )}
                {typeOfClaims.map(({ Id, Name }, idx) => (
                  <Select.Option value={Id} key={idx}>
                    {Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Injury">
              <DatePicker
                size="large"
                value={renderDate(itemModel.InjuriedDate)}
                format="DD/MM/YYYY"
                disabled={itemModel.IsLocked}
                onChange={handleChangeDatePicker('InjuriedDate')}
              />
            </Form.Item>
            <Form.Item label="Injury Description" className="fluid-field">
              <Input.TextArea
                size="large"
                rows={3}
                disabled={itemModel.IsLocked}
                value={itemModel.InjuryDescription}
                onChange={handleChangeFieldInput('InjuryDescription')}
              />
            </Form.Item>
            <Form.Item label="Employer Of Claimant">
              <Input
                size="large"
                disabled={itemModel.IsLocked}
                value={itemModel.EmployerOfClaimant}
                onChange={handleChangeFieldInput('EmployerOfClaimant')}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="6"
            header={
              <Header
                title="IRO/ILARS"
                isChecked={itemModel.WiroIlars}
                text={
                  <React.Fragment>
                    IRO Funded{' '}
                    {itemModel.IROFundingApproved && (
                      <span>
                        {' '}
                        - <i aria-hidden="true" className="green check icon"></i> Funding Approved
                      </span>
                    )}
                  </React.Fragment>
                }
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={itemModel.WiroIlars}
                onChange={handleChangeFieldCheckbox('WiroIlars')}
                className="checkbox-session"
                disabled={
                  api.isTypist() ||
                  api.isDoctor() ||
                  itemModel.CaseStatus === 11 ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.CaseStatus === 13
                }
              >
                Report Funded by IRO/ILARS
              </Checkbox>
            </div>{' '}
            <Form.Item>
              <Checkbox
                style={{ marginTop: '13px' }}
                checked={itemModel.IROFundingApproved}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WiroIlars}
                onChange={handleChangeFieldCheckbox('IROFundingApproved')}
              >
                IRO Funding Approved
                {this.renderPopup(
                  'Documents will not be sent to specialist until IRO funding approved (Mail-168)',
                )}{' '}
                {!!itemModel.IROFundingApprovedDate && itemModel.IROFundingApproved && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.IROFundingApprovedDate
                        ? renderDate(itemModel.IROFundingApprovedDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.IROFundingApprovedBy ? ` - By ${itemModel.IROFundingApprovedBy}` : ''}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item label={'IRO Funding Approval Date'} required={itemModel.IROFundingApproved}>
              <DatePicker
                size="large"
                value={renderDate(itemModel.IROFundingApprovalDate)}
                format="DD/MM/YYYY"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WiroIlars}
                onChange={handleChangeDatePicker('IROFundingApprovalDate')}
              />
            </Form.Item>
            <Form.Item label="Attach Funding Approval (Only 1 document allowed)" className="add-file-container">
              <FieldUpload
                multiple={false}
                type={17}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WiroIlars}
                disabledDelete={itemModel.IsLocked}
              />
            </Form.Item>
            <Form.Item label="ILARS Ref">
              <Input
                size="large"
                disabled={
                  api.isTypist() ||
                  api.isDoctor() ||
                  itemModel.CaseStatus === 11 ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.CaseStatus === 13 ||
                  !itemModel.WiroIlars
                }
                value={itemModel.IlarsRef}
                onChange={handleChangeFieldInput('IlarsRef')}
              />
            </Form.Item>
            <Form.Item label="Billing Item Code">
              <Select
                disabled={
                  api.isTypist() ||
                  api.isDoctor() ||
                  itemModel.CaseStatus === 11 ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.CaseStatus === 13 ||
                  !itemModel.WiroIlars
                }
                size="large"
                value={itemModel.IMSApprovalCode || undefined}
                allowClear
                onChange={handleChangeFieldSelect('IMSApprovalCode')}
              >
                {store.imsCodes.map(({ Id, PaymentClassificationCode, ServiceDescription }) => (
                  <Select.Option key={Id} value={PaymentClassificationCode}>
                    {PaymentClassificationCode} - {ServiceDescription}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="IRO Principal Lawyer">
              <Select
                size="large"
                disabled={
                  api.isTypist() ||
                  api.isDoctor() ||
                  itemModel.CaseStatus === 11 ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.CaseStatus === 13 ||
                  !itemModel.WiroIlars
                }
                value={itemModel.IlarsWiroSolicitor || undefined}
                onChange={handleChangeFieldSelect('IlarsWiroSolicitor')}
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {itemModel.IlarsWiroSolicitorObj &&
                  !(store.WIROPrincipleLawyers || []).find(i => i.Id == itemModel.IlarsWiroSolicitorObj.Id) && (
                    <Select.Option
                      key={itemModel.IlarsWiroSolicitorObj.Id}
                      value={itemModel.IlarsWiroSolicitorObj.Id}
                      style={{ display: 'none' }}
                    >
                      {itemModel.IlarsWiroSolicitorObj.Id} - {itemModel.IlarsWiroSolicitorObj.FullName}
                    </Select.Option>
                  )}
                {store.WIROPrincipleLawyers.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.FullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Staff Booking Notes
                {this.renderPopup('Only staff can see/update staff notes.')}
              </React.Fragment>
            }
          >
            <Input.TextArea
              rows={3}
              onChange={handleChangeFieldInput('ClaimReqBookingComment')}
              value={itemModel.ClaimReqBookingComment}
              disabled={api.isTypist()}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() || api.isDoctor()) && (
          <Form.Item
            label={
              <React.Fragment>
                CM Booking Notes to Staff/Specialist
                {this.renderPopup('Booking CM can add notes to staff/specialist. Specialist can view notes.')}
              </React.Fragment>
            }
            className="fluid-field"
          >
            <Input.TextArea
              rows={3}
              disabled={api.isDoctor()}
              value={supplementaryInfo.CMNotes}
              onChange={handleChangeFieldInput('CMNotes')}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
          <Form.Item
            label={
              <React.Fragment>
                Specialist Notes to Staff
                {this.renderPopup('Specialist can add/update notes. Only staff view specialist notes.')}
              </React.Fragment>
            }
            className="fluid-field"
          >
            <Input.TextArea
              rows={3}
              value={supplementaryInfo.SpecialistNotes}
              onChange={handleChangeFieldInput('SpecialistNotes')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default ClaimDetailsForm;
