import React from 'react';
import { Form, Radio, DatePicker, Input, Select, Checkbox, notification } from 'antd';
import { observer } from 'mobx-react';
import { toJS, action } from 'mobx';

import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import AccountManagers from './AccountManagers';

const clientType = [
  {
    Id: 0,
    Text: 'Business Ultimate',
  },
  {
    Id: 1,
    Text: 'Business Direct-Essential',
  },
  {
    Id: 2,
    Text: 'Business Direct-Extra',
  },
];

const businessType = [
  { id: 1, value: 'Insurer' },
  { id: 6, value: 'Self Insurer' },
  { id: 2, value: 'Law Defendant' },
  { id: 3, value: 'Law Plaintiff' },
  { id: 4, value: 'Law Practice' },
  { id: 7, value: 'Interpreter Provider' },
  { id: 8, value: 'Funding Provider' },
  { id: 5, value: 'Other' },
];

@observer
class ServiceAndIndustryTypes extends React.Component {
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
        return moment(dateString, 'DD/MM/YYYY');
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };
  renderDate = (type, fromOrTo) => {
    const data = toJS(AddEditStore.clientInfo);
    switch (type) {
      case 1:
        return fromOrTo === 'from' ? data.EffectiveMAGDirectEssentialFrom : data.EffectiveMAGDirectEssentialTo;
      case 2:
        return fromOrTo === 'from' ? data.EffectiveMAGDirectExtraFrom : data.EffectiveMAGDirectExtraTo;
      case 0:
        return fromOrTo === 'from' ? data.EffectiveMAGUltimateFrom : data.EffectiveMAGUltimateTo;
      default:
        return null;
    }
  };
  renderFeeOrGST = (type, feeOrGST) => {
    const data = toJS(AddEditStore.clientInfo);
    switch (type) {
      case 1:
        return feeOrGST === 'fee' ? data.MAGDirectEssentialServiceFees : data.MAGDirectEssentialServiceFeesGST;
      case 2:
        return feeOrGST === 'fee' ? data.MAGDirectExtraServiceFees : data.MAGDirectExtraServiceFeesGST;
      case 0:
        return feeOrGST === 'fee' ? data.MAGUltimateServiceFees : data.MAGUltimateServiceFeesGST;
      default:
        return 0;
    }
  };
  renderTypeHandleChangeFeeOrGST = (type, feeOrGST) => {
    switch (type) {
      case 1:
        return feeOrGST === 'fee' ? 'MAGDirectEssentialServiceFees' : 'MAGDirectEssentialServiceFeesGST';
      case 2:
        return feeOrGST === 'fee' ? 'MAGDirectExtraServiceFees' : 'MAGDirectExtraServiceFeesGST';
      case 0:
        return feeOrGST === 'fee' ? 'MAGUltimateServiceFees' : 'MAGUltimateServiceFeesGST';
      default:
        return 0;
    }
  };
  renderTypeHandleChange = (type, fromOrTo) => {
    switch (type) {
      case 1:
        return fromOrTo === 'from' ? 'EffectiveMAGDirectEssentialFrom' : 'EffectiveMAGDirectEssentialTo';
      case 2:
        return fromOrTo === 'from' ? 'EffectiveMAGDirectExtraFrom' : 'EffectiveMAGDirectExtraTo';
      case 0:
        return fromOrTo === 'from' ? 'EffectiveMAGUltimateFrom' : 'EffectiveMAGUltimateTo';
      default:
        return null;
    }
  };
  renderBussinessUnit = businessUnits => {
    if (businessUnits) {
      if (businessUnits.length === 0) {
        return toJS(businessUnits);
      } else if (typeof businessUnits[0] === 'object') {
        return toJS(businessUnits).map(i => i.id);
      } else {
        return toJS(businessUnits);
      }
    }
    return [];
  };
  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  renderDoctorType = type => {
    if (type) {
      const result = type.split(',');
      return result;
    }
    return [];
  };

  renderErrorFieldName = () => {
    const data = toJS(AddEditStore.clientInfo);
    switch (data.ClientType2) {
      case 0:
        return 'EffectiveMAGUltimateFrom';
      case 1:
        return 'EffectiveMAGDirectEssentialFrom';
      case 2:
        return 'EffectiveMAGDirectExtraFrom';
      default:
        return '';
    }
  };

  handleChangeDatePicker = key =>
    action(event => {
      const data = toJS(AddEditStore.clientInfo);
      if (data.ClientType2 === null) {
        return notification.error({
          message: 'Error',
          description: 'Please select a client type',
        });
      }
      AddEditStore.clientInfo[key] = event;
      const fieldName = this.renderErrorFieldName();
      if (event) {
        AddEditStore.removeError(fieldName);
      } else {
        AddEditStore.addError(fieldName, 'This field is required');
      }

      AddEditStore.removeErrorEffective(data.ClientType2);
    });

  render() {
    const data = toJS(AddEditStore.clientInfo);
    const { lookupConfig } = AddEditStore;
    return (
      <>
        <AccountManagers />
        <Form name="service-and-industry-types">
          {/* {false && <Form.Item
            label="Client Type"
            validateStatus={AddEditStore.errors['ClientType2'] ? 'error' : null}
            help={AddEditStore.errors['ClientType2']}
            className="fluid-field"
            required
          >
            <Radio.Group value={data.ClientType2} onChange={AddEditStore.handleFieldChange('ClientType2')}>
              {clientType.map(i => (
                <Radio key={i.Id} value={i.Id}>
                  {i.Text}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>}
          {false && <Form.Item
            label="Effective from"
            validateStatus={AddEditStore.errors[`${this.renderErrorFieldName()}`] ? 'error' : null}
            help={AddEditStore.errors[`${this.renderErrorFieldName()}`]}
            required
          >
            <DatePicker
              size="large"
              value={this.convertDate(this.renderDate(data.ClientType2, 'from'))}
              onChange={this.handleChangeDatePicker(this.renderTypeHandleChange(data.ClientType2, 'from'))}
              format="DD/MM/YYYY"
            />
          </Form.Item>}
          {false && <Form.Item label="Effective to">
            <DatePicker
              size="large"
              value={this.convertDate(this.renderDate(data.ClientType2, 'to'))}
              onChange={this.handleChangeDatePicker(this.renderTypeHandleChange(data.ClientType2, 'to'))}
              format="DD/MM/YYYY"
            />
          </Form.Item>} */}
          <Form.Item
            label="Business Type"
            className="fluid-field"
            validateStatus={AddEditStore.errors['OtherClientType'] ? 'error' : null}
            help={AddEditStore.errors['OtherClientType']}
            required
          >
            <Radio.Group onChange={AddEditStore.handleFieldChange('ClientType')} value={data.ClientType}>
              {businessType.map(i => (
                <Radio key={i.id} value={i.id}>
                  {i.value}
                </Radio>
              ))}
            </Radio.Group>
            {data.ClientType === 5 && (
              <Input
                className="business-text"
                value={data.OtherClientType}
                disabled={data.ClientType !== 5}
                onChange={AddEditStore.handleFieldChange('OtherClientType')}
              />
            )}
          </Form.Item>

          <Form.Item className="fluid-field" label="iCare Referrer/Insurer">
            <div className="fluid-field subscribe-box">
              <Checkbox onChange={AddEditStore.handleChecked('IcareInsurer')} checked={data.IcareInsurer}>
                Yes
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item label="Primary Segment">
            <Select
              size="large"
              value={this.renderDoctorType(data.PrimarySegments)}
              onChange={AddEditStore.handleFieldChange('PrimarySegments')}
              mode="multiple"
              showArrow
            >
              {lookupConfig &&
                lookupConfig.priItemList.map((i, idx) => (
                  <Select.Option key={idx} value={i}>
                    {i}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Secondary Segment">
            <Select
              size="large"
              value={this.renderDoctorType(data.SecondarySegments)}
              onChange={AddEditStore.handleFieldChange('SecondarySegments')}
              mode="multiple"
              showArrow
            >
              {lookupConfig &&
                lookupConfig.scdItemList.map((i, idx) => (
                  <Select.Option key={idx} value={i}>
                    {i}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Allow searching for Specialist Types" className="fluid-field" style={{ marginBottom: 0 }}>
            <Checkbox.Group
              onChange={AddEditStore.handleFieldChange('DoctorTypesForSearching')}
              value={this.renderDoctorType(data.DoctorTypesForSearching)}
            >
              <Checkbox value="P">P</Checkbox>
              <Checkbox value="D">D</Checkbox>
              <Checkbox value="P&D">P & D</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default ServiceAndIndustryTypes;
