import { Tooltip, Spin } from 'antd';
import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

import * as api from '../../stores/api';
import customFetch from '../../utils/customFetch';
import { EnhancedLink as Link } from './accordionUtils';

class NextTaskSection extends React.Component {
  isEmpty = [];
  defaultActive = Array.from({ length: this.props.accordionItems.length }).map((_, idx) => idx);

  state = {
    active: this.defaultActive,
    loading: true,
    data: null,
  };

  async componentDidMount() {
    const responses = await Promise.all(
      api.isAdminOrMagStaffOrAccounting()
        ? this.getDataForAd()
        : api.isDoctor()
        ? this.getDataForDr()
        : this.getDataForCm(),
    );

    this.setState({
      data: responses.map(res => res?.itemList || (!!res.length ? res : [])),
      loading: false,
    });
  }

  getDataForAd = () => [
    customFetch('/MedicalService/GetDynamicOverDueAssessmentStatus', {
      method: 'POST',
    }),
    customFetch('/FileReview/GetDynamicOverDueFileReviewStatus', {
      method: 'POST',
    }),
    customFetch('/Supplementary/GetDynamicOverDueSupplementaryStatus', {
      method: 'POST',
    }),
    customFetch('/api/CaseStatusWithOverDueCount'),
  ];

  getDataForCm = () => [
    customFetch('/MedicalService/GetDynamicAssessmentStatus', {
      method: 'POST',
    }),
    customFetch('/FileReview/GetDynamicFileReviewStatus', {
      method: 'POST',
    }),
    customFetch('/Supplementary/GetDynamicSupplementaryStatus', {
      method: 'POST',
    }),
    api.isTypist() ? [] : customFetch('/api/CaseStatusWithUsageCount?casetype=6'),
  ];

  getDataForDr = () => [
    customFetch('/MedicalService/GetDynamicAssessmentNextStatus', {
      method: 'POST',
    }),
    customFetch('/FileReview/GetDynamicFileReviewNextStatus', {
      method: 'POST',
    }),
    customFetch('/Supplementary/GetDynamicSupplementaryNextStatus', {
      method: 'POST',
    }),
  ];

  handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const { active } = this.state;

    if (active.includes(index)) {
      this.setState({ active: active.filter(i => i !== index) });
    } else {
      this.setState({ active: [...active, index] });
    }
  };

  renderAccordion = () => (
    <Accordion>
      {this.props.accordionItems.map(({ title, cmUrl, adminUrl, drUrl }, idx) => {
        const isCm = api.isCaseManager();
        const isAdmin = api.isAdminOrMagStaffOrAccounting();

        const data = (this.state.data[idx] || []).reduce((acc, item) => {
          acc = [...acc, item];
          return acc;
        }, []);

        this.isEmpty = [...this.isEmpty, !data.length];

        return data ? (
          <React.Fragment key={idx}>
            <Accordion.Title index={idx} active={this.state.active.includes(idx)} onClick={this.handleClick}>
              <div>
                <Icon name="dropdown" />
                {title}
              </div>
              <Link to={isCm ? cmUrl : isAdmin ? adminUrl : drUrl}>View</Link>
            </Accordion.Title>
            {this.renderAccordionContent(data, idx, `${isCm ? cmUrl : isAdmin ? adminUrl : drUrl}`, title)}
          </React.Fragment>
        ) : (
          this.renderEmptyMessage()
        );
      })}
    </Accordion>
  );

  renderAccordionContent = (data, idx, pathName, title) => (
    <Accordion.Content active={this.state.active.includes(idx)}>
      {data.map(data => {
        if (api.isCaseManager()) {
          return this.renderCmContent(data, idx, pathName, title);
        } else if (api.isDoctor()) {
          return this.renderDrContent(data, idx, pathName, title);
        } else {
          return this.renderAdminContent(data, idx, pathName, title);
        }
      })}
    </Accordion.Content>
  );

  getStatusTooltip = (statusLabel, title) => {
    if (title === 'Clinical Record Retrievals') {
      switch (statusLabel) {
        case 'Send Request to Specialist':
          return `
            Contact CMs to send paperwork to CR
            provider for clinical file retrieval
            within 24 hours of receiving the request.
            More than 24 hours considered Overdue.
          `;
        case 'Pay Specialist Prepayment':
          return `
            Pay CR provider within 24 hours of receiving invoice.
            More than 24 hours considered Overdue.
          `;
        case 'Send CR Files (ReqS)':
          return `
            Contact CR provider to send requested clinical files
            to forward to client within 48 hours of sending CR request.
            More than 48 hours considered Overdue.
          `;
        case 'Send CR Files (PrP)':
          return `
            Contact CR provider to send requested clinical files
            to forward to client within 48 hours of paying invoice.
            More than 48 hours considered Overdue.
          `;
        case 'Bill Service (ReS)':
          return `
            Bill Clients within 48 hours of sending clinical files to Clients.
            More than 48 hours considered Overdue.
          `;
        case 'Bill Service (CwF)':
          return `
            Bill Clients within 48 hours of receiving case cancellation with fees.
            More than 48 hours considered Overdue.
          `;
        default:
          return '';
      }
    }
    switch (statusLabel) {
      // IME
      case 'Confirm Appointments':
        return `
          Contact specialists or CMs to confirm appointment
          slots within 48 hours of request received.
          More than 48 hours considered Overdue.
        `;
      case 'Send Paperwork to Specialists':
        return `
          Contact CMs to send paperwork to
          specialists for confirmed appointments.
          If no paperwork sent for appointments scheduled
          in less than 2 weeks considered Overdue.
        `;
      case 'Update Attendances':
        return `
          Contact specialists or claimants to
          confirm attendance status within 48
          hours of appointment time.
          More than 48 hours considered Overdue.
        `;
      case 'Collect Dictations':
        return `
          Contact Specialists to collect their dictations
          within 48 hours of appointment time.
          More than 48 hours considered Overdue.
        `;
      case 'Send Report Draft to Specialists':
        return `
          Send report drafts to specialists for review
          within 48 hours of dictations received.
          More than 48 hours considered Overdue.
        `;
      case 'Collect Corrected Reports':
        return `
          Contact Specialists to send their corrected
          reports within 48 hours of sending report drafts.
          More than 48 hours considered Overdue.
        `;
      case 'Bill Service (ReS)':
      case 'Bill Service (ReC)':
        return `
          Bill Clients within 48 hours of
          sending reports to Clients.
          More than 48 hours considered Overdue.
        `;
      case 'Bill Service (CwF)':
      case 'Bill Service (CaC)':
        return `
          Bill Clients within 48 hours of
          receiving case cancellation with fees.
          More than 48 hours considered Overdue.
        `;

      // FR/Supp
      case 'Send Requests to Specialist':
      case 'Send Requests to Specialists':
        return `
          Contact CMs to send paperwork to
          specialists for file reviews within
          48 hours of receiving the request.
          More than 48 hours considered Overdue.
        `;
      case 'Receive Dictations':
        return `
          Contact Specialists to collect their dictations
          within 48 hours of sending file reviews.
          More than 48 hours considered Overdue.
        `;
      case 'Send Report Drafts':
        return `
          Send report drafts to specialists for review
          within 48 hours of dictations received.
          More than 48 hours considered Overdue.
        `;
      default:
        return '';
    }
  };

  renderAdminContent = ({ Status, Count, status, count }, idx, pathName, title) => {
    const statusLabel = Status || status;
    if (statusLabel) {
      return (
        <div key={statusLabel} className="content-item">
          <span className="Text-truncate">
            <Tooltip placement="left" title={this.getStatusTooltip(statusLabel, title)}>
              <Link style={{ color: '#1fc0da' }} to={`${pathName}?nextTaskDue=${Status || status}`}>
                {statusLabel}
              </Link>
            </Tooltip>
          </span>
          <span>({Count || count})</span>
        </div>
      );
    } else {
      return '';
    }
  };

  renderCmContent = ({ NextStatus, Status, Count, status, nextStatus, count }, idx, pathName) => {
    const statusLabel = NextStatus || nextStatus;
    if (!statusLabel) {
      return null;
    }
    return (
      <div key={statusLabel} className="content-item">
        <span className="Text-truncate" title={statusLabel}>
          <Link style={{ color: '#1fc0da' }} to={`${pathName}?nextTaskDue=${Status || status}`}>
            {statusLabel}
          </Link>
        </span>
        <span>({Count || count})</span>
      </div>
    );
  };

  renderDrContent = ({ Status, Count, status, count }, idx, pathName) => {
    const statusLabel = Status || status;
    return (
      <div key={statusLabel} className="content-item">
        <span className="Text-truncate" title={statusLabel}>
          <Link style={{ color: '#1fc0da' }} to={`${pathName}?nextTaskDue=${Status || status}`}>
            {statusLabel}
          </Link>
        </span>
        <span>({Count || count})</span>
      </div>
    );
  };

  renderEmptyMessage = () => {
    const itemLength = this.props.accordionItems.length;
    if (itemLength === this.isEmpty.length && this.isEmpty.every(i => i)) {
      return (
        <div className="section-empty-message" key="empty-message">
          No tasks
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <section className="action-required-section">
        <h3 className="section-title">{api.isAdminOrMagStaffOrAccounting() ? 'Next Task Overdue' : 'Tasks Due'}</h3>
        {this.state.loading ? <Spin /> : this.renderAccordion()}
      </section>
    );
  }
}

export default NextTaskSection;
