import { DatePicker, Form, Input, Modal, Radio, Select, notification } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';
import customFetch from '../../../../utils/customFetch';
import * as api from '@stores/api';
import { formatDate } from '../../../../utils/functions';
import DoctorDetailsStore from './DoctorDetailsStore';

@observer
class ModalConversation extends React.Component {
  @observable conversation = this.props.data || {
    Id: 0,
    DoctorId: DoctorDetailsStore.dataDoctor.Id,
    SentDate: moment(),
    Subject: '',
    Content: '',
    ReceiverType: 3,
    Receiver: null,
    ReceiverName: '',
    CallerSenderType: 3,
    CallerSender: null,
    CallerSenderName: '',
    ConversationType: 0,
    allowCMToView: false,
    allowDoctorToView: false,
  };

  @observable callerOrSenderHasTypeCM = false;
  @observable callerIsCM = 0;
  @observable receiverIsCM = 0;

  @observable searchStringCM = '';

  callerReceiverType = [
    { text: 'CaseManager', value: 1 },
    { text: 'Specialist', value: 2 },
    { text: 'Staff', value: 3 },
  ];

  conversationType = [
    { text: 'Phone', value: 0 },
    { text: 'Email', value: 1 },
    { text: 'Email (Send Now)', value: 4 },
    { text: 'Meeting', value: 2 },
    { text: 'Private Notes', value: 5 },
    { text: 'Others', value: 3 },
  ];

  convertDateUTC = dateString => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    var utcServerTime = dataConversationLogLoadList.utcServerTime;
    var utcLocalMinutes = new Date().getTimezoneOffset();
    var addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    return moment(formatDate(dateString, true), 'DD MMM YYYY, HH:mm').add(addminutes, 'minutes');
  };

  @action componentDidMount() {
    if (!!this.conversation.Id) {
      this.conversation = {
        ...this.conversation,
        SentDate: this.convertDateUTC(this.conversation.SentDate),
        ReceiverType:
          this.callerReceiverType.find(t => {
            return t.text === this.conversation.ReceiverType;
          })?.value || 0,
        Receiver: this.conversation.Receiver || null,
        CallerSenderType:
          this.callerReceiverType.find(t => {
            return t.text === this.conversation.CallerSenderType;
          })?.value || 0,
        CallerSender: this.conversation.CallerSender || null,
        ConversationType:
          this.conversationType?.find(t => {
            return t.text === this.conversation.TypeLog;
          })?.value || 0,
        allowCMToView: this.conversation.AllowCMToView,
        allowDoctorToView: this.conversation.AllowDoctorToView,
      };
      if (this.conversation.ReceiverType !== 3) {
        this.conversation.Receiver = parseInt(this.conversation.Receiver);
      }
      if (this.conversation.CallerSenderType !== 3) {
        this.conversation.CallerSender = parseInt(this.conversation.CallerSender);
      }
      if (this.conversation.CallerSenderType === 1 || this.conversation.ReceiverType === 1) {
        this.callerOrSenderHasTypeCM = true;
        if (this.conversation.CallerSenderType === 1) {
          this.callerIsCM = this.conversation.CallerSender;
        }
        if (this.conversation.ReceiverType === 1) {
          this.receiverIsCM = this.conversation.Receiver;
        }
      }
    }
  }

  @action handleCancel = () => {
    DoctorDetailsStore.toggleModal(false)();
  };

  handleSave = () => {
    const data = toJS(this.conversation);
    if (!data.SentDate || !data.Subject || !data.Receiver || !data.CallerSender) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 5,
      });
    }
    const { dataDoctor, dataCMs, dataStaffs, fetchConversations } = DoctorDetailsStore;
    let receiver, callerSender;
    if (data.ReceiverType === 1) {
      receiver = dataCMs.find(i => i.value === data.Receiver);
    } else if (data.ReceiverType === 2) {
      receiver = {
        text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
        value: dataDoctor.Id,
      };
    } else if (data.ReceiverType === 3) {
      receiver = dataStaffs.find(i => i.value === data.Receiver);
    }
    if (data.CallerSenderType === 1) {
      callerSender = dataCMs.find(i => i.value === data.CallerSender);
    } else if (data.CallerSenderType === 2) {
      callerSender = {
        text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
        value: dataDoctor.Id,
      };
    } else if (data.CallerSenderType === 3) {
      callerSender = dataStaffs.find(i => i.value === data.CallerSender);
    }
    customFetch('/MedicalDoctor/ConversationLog_Submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        CallerSender: data.CallerSender,
        CallerSenderType: data.CallerSenderType,
        Content: data.Content,
        ConversationType: data.ConversationType,
        DoctorId: data.DoctorId,
        Id: data.Id,
        Receiver: data.Receiver,
        ReceiverType: data.ReceiverType,
        Subject: data.Subject,
        allowCMToView: data.allowCMToView,
        allowDoctorToView: data.allowDoctorToView,
        SentDate: data.SentDate.format('YYYY-MM-DD'),
        hour: data.SentDate.hour(),
        minute: data.SentDate.minute(),
        ReceiverName: receiver.text,
        CallerSenderName: callerSender.text,
        receiverId: receiver.value,
        callerSenderId: callerSender.value,
      }),
    }).then(res => {
      if (res.status === 'success') {
        fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Add Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while saving data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
      this.handleCancel();
    });
  };

  setFieldValue = name =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      this.conversation[name] = value;
    });

  @action toggleAllowDoctorToView = e => {
    this.conversation.allowDoctorToView = e.target.checked;
  };

  @action setReceiverType = event => {
    const type = event.target.value;
    this.conversation.ReceiverType = type;
    if (type === 2) {
      this.conversation.Receiver = DoctorDetailsStore.dataDoctor.Id;
    } else if (type === 1) {
      this.conversation.Receiver = this.receiverIsCM;
    } else {
      this.conversation.Receiver = null;
    }
  };

  @action setCallerSenderType = event => {
    const type = event.target.value;
    this.conversation.CallerSenderType = type;
    if (type === 2) {
      this.conversation.CallerSender = DoctorDetailsStore.dataDoctor.Id;
    } else if (type === 1) {
      this.conversation.CallerSender = this.callerIsCM;
    } else {
      this.conversation.CallerSender = null;
    }
  };

  @action searchCM = keyword => {
    const { fetchCaseManager, dataCaseManager } = DoctorDetailsStore;
    fetchCaseManager(keyword, dataCaseManager.length);
    this.searchStringCM = keyword;
  };

  handleScroll = event => {
    const { fetchCaseManager, dataCaseManager } = DoctorDetailsStore;
    if (this.conversation.ReceiverType !== 1) {
      return;
    }
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      fetchCaseManager(this.searchStringCM, dataCaseManager.length + 20);
    }
  };

  renderReceiverSelect = () => {
    const { dataDoctor } = DoctorDetailsStore;
    const { ReceiverType } = this.conversation;
    let options = [];
    switch (ReceiverType) {
      case 1:
        options = DoctorDetailsStore.dataCMs;
        break;
      case 2:
        options = [
          {
            text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
            value: dataDoctor.Id,
          },
        ];
        break;
      case 3:
        options = DoctorDetailsStore.dataStaffs;
        break;
      default:
        return null;
    }

    return (
      <Select
        size="large"
        value={this.conversation.Receiver}
        onChange={this.setFieldValue('Receiver')}
        onPopupScroll={this.handleScroll}
        onSearch={this.searchCM}
        optionFilterProp="children"
        showSearch={ReceiverType === 1}
        disabled={ReceiverType === 2 || ReceiverType === 1}
      >
        {options.map(i => (
          <Select.Option key={i.value} value={i.value}>
            {i.text}
          </Select.Option>
        ))}
      </Select>
    );
  };

  renderCallerSenderSelect = () => {
    const { dataDoctor } = DoctorDetailsStore;
    const { CallerSenderType } = this.conversation;
    let options = [];
    switch (CallerSenderType) {
      case 1:
        options = DoctorDetailsStore.dataCMs;
        break;
      case 2:
        options = [
          {
            text: `${dataDoctor.FirstName} ${dataDoctor.LastName}`,
            value: dataDoctor.Id,
          },
        ];
        break;
      case 3:
        options = DoctorDetailsStore.dataStaffs;
        break;
      default:
        return null;
    }

    return (
      <Select
        size="large"
        value={this.conversation.CallerSender}
        onChange={this.setFieldValue('CallerSender')}
        onPopupScroll={this.handleScroll}
        onSearch={this.searchCM}
        optionFilterProp="children"
        showSearch={CallerSenderType === 1}
        disabled={CallerSenderType === 2 || CallerSenderType === 1}
      >
        {options.map(i => (
          <Select.Option key={i.value} value={i.value}>
            {i.text}
          </Select.Option>
        ))}
      </Select>
    );
  };

  render() {
    const { open } = DoctorDetailsStore;
    return (
      <Modal
        title="Add New"
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={600}
        visible={open}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" onClick={this.handleCancel}>
              Cancel
            </Button>
          </React.Fragment>
        }
      >
        <div className="Modal-Conversation">
          <Form>
            <Form.Item label="Correspondence Type">
              <Select
                size="large"
                value={this.conversation.ConversationType}
                onChange={this.setFieldValue('ConversationType')}
              >
                {this.conversationType.map(i => (
                  <Select.Option key={i.value} value={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date/Time"
              required
              validateStatus={!this.conversation.SentDate ? 'error' : null}
              help={!this.conversation.SentDate ? 'This field is required' : null}
            >
              <DatePicker
                size="large"
                showTime={{ format: 'HH:mm' }}
                format="DD/MM/YYYY - HH:mm"
                value={this.conversation.SentDate}
                onChange={this.setFieldValue('SentDate')}
              />
            </Form.Item>
            <Form.Item
              label="Subject"
              required
              validateStatus={!this.conversation.Subject ? 'error' : null}
              help={!this.conversation.Subject ? 'This field is required' : null}
            >
              <Input size="large" value={this.conversation.Subject} onChange={this.setFieldValue('Subject')} />
            </Form.Item>
            <Form.Item label="Content">
              <Input.TextArea rows={4} value={this.conversation.Content} onChange={this.setFieldValue('Content')} />
            </Form.Item>
            <Form.Item
              label="Caller/Sender"
              required
              validateStatus={!this.conversation.CallerSender ? 'error' : null}
              help={!this.conversation.CallerSender ? 'This field is required' : null}
            >
              <Radio.Group value={this.conversation.CallerSenderType} onChange={this.setCallerSenderType}>
                {this.callerOrSenderHasTypeCM && <Radio value={1}>Case Manager</Radio>}
                <Radio value={2}>Specialists</Radio>
                <Radio value={3}>Staff</Radio>
              </Radio.Group>
              {this.renderCallerSenderSelect()}
            </Form.Item>
            <Form.Item
              label="Receiver"
              required
              validateStatus={!this.conversation.Receiver ? 'error' : null}
              help={!this.conversation.Receiver ? 'This field is required' : null}
            >
              <Radio.Group value={this.conversation.ReceiverType} onChange={this.setReceiverType}>
                {this.callerOrSenderHasTypeCM && <Radio value={1}>Case Manager</Radio>}
                <Radio value={2}>Specialists</Radio>
                <Radio value={3}>Staff</Radio>
              </Radio.Group>
              {this.renderReceiverSelect()}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ModalConversation;
