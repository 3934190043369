import dashboardStore from '@stores/dashboard';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import fileReviewStore from './fileReviewStore';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'FileReviewNumber' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Claim No', sortkey: 'ClaimantNo' },
  { title: 'Type Of Claim', sortKey: 'TypeOfClaimnt' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Case Progress', sortKey: 'CaseProgress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
];

const caseFlowText = [
  'Case Created',
  'Request Received',
  'Request Sent to Provider',
  'Dictations Sent to MAG',
  'Report Draft Sent to Provider',
  'Corrected Report Sent to MAG',
  'Reports Sent to CMs',
  'Cancelled No Fee',
  'Cancelled With Fee',
  'Service Billed',
  'Service Paid',
];

@observer
class FileReviewTable extends React.Component {
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Document':
        return 1;
      case 'Cancellation':
        return 2;
      case 'Corespondence':
        return 4;
      default:
        return 0;
    }
  };
  openCaseDetail = (id, type) => () => {
    const caseNo = `Case F-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('FileReviewCaseId', caseNo);
    dashboardStore.close('/view/add-edit-file-review-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-file-review-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  openModalCaseProgress = (id, caseNo) => () => {
    fileReviewStore.toggleModal(true, { id, caseNo })();
  };

  renderTableBody = () => {
    return fileReviewStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item onClick={this.openCaseDetail(Id, 'CaseDetails')}>View Request Details</Menu.Item>
                <Menu.Item onClick={this.openCaseDetail(Id, 'Document')}>View Case Documents</Menu.Item>
                <Menu.Item onClick={this.openCaseDetail(Id, 'Document')}>Upload Dictations & Reports</Menu.Item>
                {/* <Menu.Item onClick={this.openCaseDetail(Id, 'Cancellation')}>
                  View Case Cancellation
                </Menu.Item> */}
                <Menu.Item onClick={this.openCaseDetail(Id, 'Corespondence')}>View Case Correspondence</Menu.Item>
              </Menu>
            }
          >
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id)}>
            {record.FileReviewNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.Claimant}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>
          <h5 className="ma-0">{record.CaseManager}</h5>
          {record.IsDirect && <div>{record.CMEmail}</div>}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={this.openModalCaseProgress(Id, record.FileReviewNumber)}>
            {caseFlowText[record.CaseProgress]}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    fileReviewStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = fileReviewStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={fileReviewStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={fileReviewStore.totalItems}
          currentPage={fileReviewStore.curPage}
          totalPage={fileReviewStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default FileReviewTable;
