import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';

import Modal from './Modal';
import Store from './Store';
import MainTable from './MainTable';
import Toolbar from './Toolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class TargetAudiences extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/target-audience', true);
      return;
    }
    Store.fetchModule(true);
    Store.fetchModuleFilter();
  }
  render() {
    const { data, loading, open, dataModule, loadingModule } = Store;
    return (
      <div className={`Table-${(loading && !data) || (loadingModule && !dataModule) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingModule && !dataModule) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            <MainTable />
            {open && <Modal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default observer(TargetAudiences);
