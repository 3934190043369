import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import moment from 'moment';

import Modal from '../../../shared/Modal/';
import store from '../Store';
import * as api from '@stores/api';
import DragDropFile from './DragDropFile';
import { ReplaceFileNameUpload } from '@utils/functions';
import { LOCAL_CONSTANT, UPLOAD_FILE_RULE } from '../type';
import { renderDate } from '../../Shared';

@observer
class UploadComponent extends React.Component {
  handleRemoveFile = id => {
    store.handleRemoveFile(id);
  };

  @action handleDraftAttachmentUploadComplete = () => {
    const itemModel = toJS(store.assessmentInfo);
    const specialist = toJS(store.specialist);
    store.assessmentInfo = {
      ...itemModel,
      ReportDraftReceivedDate: moment(),
    };

    if (api.isAdminOrMagStaffOrAccounting()) {
      const itemModel_ = toJS(store.assessmentInfo);
      store.assessmentInfo = {
        ...itemModel_,
        ReportDraftSentDate: moment(),
        ReportDraftReceived: true,
      };

      if (!itemModel.EmailReportDraftToDoctorBE || !itemModel.EmailReportDraftToDoctor) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to attach report draft to notification email to provider?',
          onOk: () =>
            action(() => {
              store.assessmentInfo = {
                ...toJS(store.assessmentInfo),
                EmailReportDraftToDoctorBE: true,
                EmailReportDraftToDoctor: true,
              };
            })(),
        })();
      }
    }
  };

  handlePaperworkAttachmentOtherUploadComplete = reportType => {
    const itemModel = toJS(store.assessmentInfo);
    const attachmentsPaperworkOther = [
      ...store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_PaperworkOther),
    ];

    const attachmentsPaperwork = [
      ...store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork),
    ];
    if (reportType === LOCAL_CONSTANT.ReportType_Paperwork) {
      store.handleChangeValue('PaperworkReceivedDate', moment());
    }
    //check total pages > 20
    if (!!attachmentsPaperworkOther.length || !!attachmentsPaperwork.length) {
      const totalPagePaperwork = !!attachmentsPaperwork.length
        ? attachmentsPaperwork.map(i => i.NumberOfPages).reduce((a, b) => a + b)
        : 0;
      const totalPagePaperworkOther = !!attachmentsPaperworkOther.length
        ? attachmentsPaperworkOther.map(i => i.NumberOfPages).reduce((a, b) => a + b)
        : 0;
      if (totalPagePaperwork + totalPagePaperworkOther > 20) {
        store.setFieldsValue({
          assessmentInfo: { ...itemModel, IsLongPaperwork: true },
        });
      }
      store.totalPages = totalPagePaperwork + totalPagePaperworkOther;
    }
    store.handleChangeValue('IsUpdatedPaperwork', true);
    if (itemModel.AllowDoctorToViewPaperwork === false && !api.isCaseManager()) {
      if (!itemModel.WifroIlars || (itemModel.WifroIlars && itemModel.IROFundingApproved)) {
        store.toggleModal(true, {
          message: 'Do you want to send paperwork to specialist?',
          modalType: 'confirm',
          onOk: () => {
            store.handleChangeValue('AllowDoctorToViewPaperwork', true);
            store.handleChangeValue('PaperWorkReceived', true);
            store.handleChangeValue('OtherMethodSendingDate', moment());
            if (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) {
              store.onPaperworkReceivedChange();
            }

            setTimeout(() => {
              store.onAllowDoctorToViewPaperworkChange();
              if (api.isCaseManager() || api.isDoctor()) {
                store.toggleModal(true, {
                  modalType: 'confirm',
                  message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
                  hideWarningIcon: true,
                })();
              }
            });
          },
          functionCallbackCancel: () => {
            setTimeout(() => {
              if (api.isCaseManager() || api.isDoctor()) {
                store.toggleModal(true, {
                  modalType: 'confirm',
                  message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
                  hideWarningIcon: true,
                })();
              }
            });
          },
        })();
      }
    }
  };

  @action handleAfterUploadFinalReport = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.ReportCompleted) {
      store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to send report to CMs?',
        onOk: () => {
          store.handleChangeValue('ReportCompleted', true);
          if (store.assessmentInfo.F_RDCCaseManagerId) {
            store.handleChangeValue('IsAllowCMSolicitorViewReport', true);
          }
          if (store.assessmentInfo.S_RDCCaseManagerId) {
            store.handleChangeValue('IsAllowCMViewReport', true);
          }
          return store.handleReportCompletedChange();
        },
      })();
    }
  };

  @action handleUpload = (fileList, reportType) => {
    const { functionCallback } = store.modalParams;
    store.assessmentInfo = {
      ...toJS(store.assessmentInfo),
      loadingUpload: true,
    };

    const attachments = store.assessmentInfo?.AssessmentAttachment
      ? toJS(store.assessmentInfo.AssessmentAttachment)
      : [];
    const dictationFiles = !!attachments.length ? toJS(attachments).filter(i => i.ReportType === LOCAL_CONSTANT.ReportType_Dictation)
      : [];
    const attachment = fileList.map(i => ({
      FileName: i.name,
      Id: 0,
      NumberOfPages: i.numberOfPages,
      ReportType: reportType,
      Title: reportType === LOCAL_CONSTANT.ReportType_Dictation
          ? ReplaceFileNameUpload(
              'Assessment',
              store.assessmentInfo.AssessmentNumber,
              store.claimant?.FullName || '',
              store.specialist?.Title || '',
              store.specialist?.FullName || '',
              store.assessmentInfo.DateFrom ? moment(store.assessmentInfo.DateFrom).format('DD/MM/YYYY') : '',
              store.assessmentInfo.AssessmentMethod,
              store.clinic?.Name || '',
              !!dictationFiles.length ? dictationFiles.length + 1 : 1,
            )
          : i.title,
      CreateDate: moment(),
    }));
    const newAssessmentAttachment = [...attachment, ...attachments].map((i, idx) => ({ ...i, key: idx }));
    store.assessmentInfo = {
      ...toJS(store.assessmentInfo),
      loadingUpload: false,
      AssessmentAttachment: newAssessmentAttachment,
    };

    if (reportType === LOCAL_CONSTANT.ReportType_PaperworkOther || reportType === LOCAL_CONSTANT.ReportType_Paperwork) {
      return this.handlePaperworkAttachmentOtherUploadComplete(reportType);
    }
    if (reportType === 3) {
      return this.handleDraftAttachmentUploadComplete();
    }

    if (reportType === LOCAL_CONSTANT.ReportType_ReportReturnedFromDoctor) {
      if (!store.assessmentInfo.ReportCompletedDate) {
        store.handleChangeValue('ReportCompletedDate', moment());
      }
      if (!store.assessmentInfo.NotifyCorrectedReportToStaff) {
        store.handleChangeValue('NotifyCorrectedReportToStaff', true);
      }
      if (!store.assessmentInfo.NotifyCorrectedReportToSpecialist) {
        store.handleChangeValue('NotifyCorrectedReportToSpecialist', true);
      }
      return store.handleChangeValue('ReportReturnedFromDoctor', true);
    }
    if (reportType === LOCAL_CONSTANT.ReportType_Completed) {
      // const itemModel = toJS(store.assessmentInfo);
      this.handleAfterUploadFinalReport();
      // if (!itemModel.IsAllowCMViewReport) {
      //   if (!store.Report_Delivery_Contact_1?.Email && !store.Report_Delivery_Contact_2?.Email) {
      //     store.showNotificationError('At least one Report Delivery Contact needs to be completed.');
      //   } else if (!(itemModel.DelayPrepaymentRequired && !itemModel.ClientPrepaymentPaidDate)) {
      //     ['IsAllowCMViewReport', 'ReportCompleted'].map(i => store.handleChangeValue(i, true));
      //     return store.handleReportCompletedChange();
      //   }
      // }
    }

    if (reportType === LOCAL_CONSTANT.ReportType_AmendedReportCompleted) {
      const itemModel = toJS(store.assessmentInfo);
      if (
        itemModel.IsAllowCMViewAmendedReport === false &&
        !api.isDoctor() &&
        !(itemModel.DelayPrepaymentRequired && !itemModel.ClientPrepaymentPaidDate)
      ) {
        if (!store.Report_Delivery_Contact_1?.Email && !store.Report_Delivery_Contact_2?.Email) {
          store.showNotificationError('At least one Report Delivery Contact needs to be completed.');
        } else {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Do you want to send amended reports to CMs?',
            onOk: () => {
              if (itemModel.F_RDCCaseManagerId) {
                store.handleChangeValue('IsAllowCMSolicitorViewAmendedReport', true);
              }
              if (itemModel.S_RDCCaseManagerId) {
                store.handleChangeValue('IsAllowCMViewAmendedReport', true);
              }
            },
          })();
        }
      }
    }
    if (reportType === LOCAL_CONSTANT.ReportType_Dictation) {
      //Dictation Attachment
      if (api.isDoctor()) {
        store.toggleModal(true, {
          message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
          modalType: 'confirm',
          hideWarningIcon: true,
        })();
      }
      store.handleChangeValue('DictationReceived', true);
      store.handleChangeValue('DictationReceivedDate', moment());
      store.handleChangeValue('SendConfirmationToSpecialist', true);
      functionCallback && functionCallback();
      return;
    }

    if (reportType === 11) {
      if (!store.assessmentInfo.EmailInvoiceToMAG) {
        store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to send specialist invoice to Medicolegal Provider?',
          onOk: () => store.handleChangeValue('EmailInvoiceToMAG', true),
        });
      }
    }

    functionCallback && functionCallback();
    return;
  };

  render() {
    const { open, modalParams, assessmentInfo } = store;
    const { reportType, disabled, disabledDelete, uploadTitle, disableDragDrop, multiple } = modalParams;
    const uploadRule = UPLOAD_FILE_RULE.find(i => i.reportType.indexOf(reportType) !== -1);
    const rule = uploadRule?.rule || null;
    const attachments = assessmentInfo.AssessmentAttachment.filter(({ ReportType }) => ReportType === reportType);
    const title =
      attachments && !!attachments.length
        ? `${attachments.length} ${attachments.length > 1 ? 'attachments' : 'attachment'} ${
            attachments.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={store.toggleModal(false, {})}
        className="custom-modal"
        footer={
          <React.Fragment>
            <Button color="blue" onClick={store.toggleModal(false, {})}>
              OK
            </Button>
          </React.Fragment>
        }
        title={title}
      >
        <DragDropFile
          onUploadFile={this.handleUpload}
          onDeleteFile={this.handleRemoveFile}
          type={reportType}
          accept={rule ? rule.fileExtension : 'application/pdf'}
          maxSize={rule ? parseInt(rule.maxSize.replace('MB')) : 50}
          multiple={multiple}
          attachments={attachments}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          disableDragDrop={disableDragDrop}
        />
      </Modal>
    );
  }
}
export default UploadComponent;
