import dashboardStore from '@stores/dashboard';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import Content from '../SalesCallReportForCM/Content';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import SalesCallReportStore from './SalesCallReportStore';
import { getActivityType, getConversationLogContent } from '../../../utils/functions';

const columnsSpecialist = [
  { title: 'ID' },
  { title: 'Specialist' },
  { title: 'Content' },
  { title: 'Activity Type' },
  { title: 'Activity PIC', sortKey: 'CallBy' },
  { title: 'Activity Date', sortKey: `CallDate` },
  { title: 'Account Manager' },
];

@observer
class SalesCallReportTable extends React.Component {
  openCaseDetails = (record, step = 0) => e => {
    e.preventDefault();
    const id = record.DoctorId;
    const stogrageName = 'DoctorName';
    const url = '/view/add-edit-doctor-2';
    const value = record.DoctorFullName;
    localStorage.setItem(stogrageName, value);
    dashboardStore.close(url, true);
    setTimeout(() => {
      dashboardStore.open(`${url}?id=${id}&step=${step}&action=edit`);
    });
  };

  renderTableBodySpecialists = () => {
    return SalesCallReportStore.data.itemList.map((record, idx) => (
      <Table.Row key={idx}>
        <Table.Cell className="Cell-link" onClick={this.openCaseDetails(record)} selectable>
          <Link to="#" onClick={this.openCaseDetails(record)}>
            {record.DoctorId}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <div>
            <span style={{ fontWeight: 'bold' }}>{record.DoctorFullName}</span>
          </div>
          <div>
            <span>
              {record.DoctorPhoneNumber}
              {record.DoctorOfficePhone ? ` - ${record.DoctorOfficePhone}` : ''}
            </span>
          </div>
          <div>
            <span>{record.DoctorEmail}</span>
          </div>
          <div>
            <span style={{ color: `${record.DoctorHasContract ? 'green' : 'red'}` }}>
              Contract Ready: {record.DoctorHasContract ? 'Yes' : 'No'}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link" style={{ minWidth: '250px' }}>
          <Content
            Notes={record.Notes}
            onViewMore={async () => {
              const content = await getConversationLogContent(record.Id, 'MedicalDoctor').then(res => res?.content);
              SalesCallReportStore.setDataFieldsValue(idx, content);
            }}
          />
        </Table.Cell>
        <Table.Cell>{getActivityType(record.TypeLog)}</Table.Cell>
        <Table.Cell>{record.CallBy}</Table.Cell>
        <Table.Cell>{record.CallDate}</Table.Cell>
        <Table.Cell>{record.ClientServiceOfficer}</Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    SalesCallReportStore.refetch({ curPage: page - 1 });
  };

  render() {
    const { loading, data } = SalesCallReportStore;
    const columns = columnsSpecialist;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={SalesCallReportStore.sortColumn}
          sortDirection={SalesCallReportStore.sortDirection}
          onSort={SalesCallReportStore.handleSort}
        />
        <Table.Body>
          {data.itemList?.length ? this.renderTableBodySpecialists() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          totalItems={SalesCallReportStore.totalItem}
          currentPage={SalesCallReportStore.curPage + 1}
          totalPage={SalesCallReportStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default SalesCallReportTable;
