import React from 'react';
import { Button } from 'semantic-ui-react';

function ButtonSubmit(props) {
  const { icon, children, content, loading } = props;

  return (
    <Button
      type="submit"
      color="blue"
      {...props}
      labelPosition={icon ? 'right' : null}
      content={children || content}
      disabled={loading}
    />
  );
}

export default ButtonSubmit;
